import React, { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { MaterialReactTable, MRT_ActionMenuItem } from 'material-react-table';
import { useMemo } from 'react';
import { MRT_Localization_PT } from 'material-react-table/locales/pt';
import AddIcon from '@mui/icons-material/Add';
import DetailsBox from '../../../interface/components/DetailsBox';
import { Search } from '@mui/icons-material';
import moment from 'moment/moment';
import DialogDistribuidor from './dialog';
import { MdiIcon } from '@components';
import { mdiPencil } from '@mdi/js';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#049a9b' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    height: '100%'
}));
const getDetailsEntryList = (distribuidor) => distribuidor == null
    ? []
    : [
        { title: 'Designação', content: distribuidor.designacao },
        { title: 'NIF', content: distribuidor.nif },
        { title: 'Email', content: distribuidor.email },
        { title: 'Email Alternativo', content: distribuidor.email2 || 'N/D' },
        { title: 'Email Alternativo 2', content: distribuidor.email3 || 'N/D' },
        { title: 'Contacto', content: distribuidor.contacto },
        { title: 'Morada', content: distribuidor.morada },
        { title: 'Código Postal', content: distribuidor.cep },
        { title: 'Localidade', content: distribuidor.localidade },
        { title: 'Observações', content: distribuidor.observacoes }
    ];
const GasDistribuidorDetailsView = ({ $state, GasService, UIService }) => {
    const [id, setId] = useState($state.params.id);
    const [isLoading, setIsLoading] = useState(false);
    const [distribuidor, setDistribuidor] = useState(null);
    const [detalhesEntryList, setDetalhesEntryList] = useState([]);
    // Dialog edit distribuidor
    const [editDialog, setEditDialog] = useState({
        open: false
    });
    const onDialogClose = async (item) => {
        setEditDialog({ open: false });
        if (item) {
            setIsLoading(true);
            try {
                d = await GasService.updateDistribuidor(item);
                setDistribuidor(d);
            }
            catch (error) {
                console.log(error);
            }
            fetchData();
        }
    };
    const fetchData = async () => {
        setIsLoading(true);
        let d;
        try {
            d = await findDistribuidor(id);
        }
        catch (error) {
            setDistribuidor(null);
            setIsLoading(false);
            UIService.addToast('Erro ao consultar Distribuidor. Verifique a ligação');
            $state.go('app.gas.distribuidores.list');
            throw error;
        }
        setDistribuidor(d);
        setDetalhesEntryList(getDetailsEntryList(d));
        setIsLoading(false);
    };
    useEffect(() => {
        if (!isLoading)
            fetchData();
    }, [distribuidor]);
    const findDistribuidor = async (id) => {
        let data;
        try {
            data = await GasService.findDistribuidor(id);
        }
        catch (error) {
            console.log(error);
            throw error;
        }
        return data;
    };
    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50
        },
        {
            accessorKey: 'numeroProcesso',
            header: 'Processo',
            size: 50
        },
        {
            accessorKey: 'agendamento.data',
            header: 'Data de Inspeção',
            size: 200,
            Cell: ({ renderedCellValue, row }) => (row.original.agendamento && moment(row.original.agendamento.data).isValid() ? moment(row.original.agendamento.data).format('DD/MM/YYYY') : 'N/D')
        },
        {
            accessorKey: 'agendamento.hora',
            header: 'Hora',
            size: 50,
            Cell: ({ renderedCellValue, row }) => (row.original.agendamento && row.original.agendamento.hora ? row.original.agendamento.hora.substring(0, 5) : 'N/D')
        },
        {
            accessorKey: 'agendamento.duracao',
            header: 'Duração (min)',
            size: 80
        },
        {
            accessorKey: 'agendamento.tecnico.name',
            header: 'Técnico',
            size: 140
        },
        {
            accessorKey: 'estado.designacao',
            header: 'Estado',
            size: 140
        },
        {
            accessorKey: 'tipoGas.designacao',
            header: 'Tipo de Gás',
            size: 140
        },
        {
            accessorKey: 'tipoInstalacao.designacao',
            header: 'Tipo de Instalação',
            size: 140
        },
        {
            accessorKey: 'tipoInspecao.designacao',
            header: 'Tipo de Inspeção',
            size: 140
        }
    ], []);
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = 'Não há registos a serem exibidos';
    // Icon to edit
    const editDistribuidorIcon = (<Tooltip title='Editar'>
      <IconButton onClick={() => {
            setEditDialog({
                open: true,
                initial: distribuidor
            });
        }}>
        <MdiIcon path={mdiPencil}/>
      </IconButton>
    </Tooltip>);
    return (<Fragment>
      <DialogDistribuidor open={editDialog.open} initial={editDialog.initial} onClose={onDialogClose}/>
      <h1 className='section-title mt-2' id='services'>
        <span>Distribuidor {distribuidor && distribuidor.designacao}</span>
      </h1>
      <ol className='breadcrumb text-left'>
        <li>Gás</li>
        <li className='active'>Distribuidor</li>
      </ol>
      {isLoading ? (<Box sx={{ display: 'flex', justifyContent: 'center', height: '2vh' }}>
          <CircularProgress size={60} color='primary'/>
        </Box>) : (<Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 6 } }}>
            <Item>{distribuidor != null && <DetailsBox title='Detalhes' entries={detalhesEntryList} action={editDistribuidorIcon}></DetailsBox>}</Item>
          </Grid>
          {/*<Grid item xs={12} md={6} sx={{marginBottom: {xs: 6}}}>*/}
          {/*  <Item>*/}
          {/*  </Item>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <Item>
              <Grid container justifyContent='space-between' alignItems='center' sx={{ mb: 3 }}>
                <Grid item>
                  <Typography variant='h6' sx={{ color: 'black', fontWeight: 'bold' }}>
                    Processos <Chip label={distribuidor && distribuidor.processos ? distribuidor.processos.length : 0} color='primary' size='small'/>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h6' sx={{ color: 'black', fontWeight: 'bold' }}>
                    <AddIcon />
                  </Typography>
                </Grid>
              </Grid>
              {distribuidor && distribuidor.processos && distribuidor.processos.length > 0 ? (<MaterialReactTable columns={columns} data={distribuidor.processos} enableTopToolbar={false} // Removes the tollbar from the table
             initialState={{
                    showGlobalFilter: true,
                    density: 'compact',
                    pagination: {
                        pageIndex: 0,
                        pageSize: 20
                    }
                }} localization={localization} muiPaginationProps={{
                    rowsPerPageOptions: [20, 50, 100]
                }} enableRowActions={true} positionActionsColumn={'last'} renderRowActionMenuItems={({ row, table }) => [
                    <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
                     icon={<Search />} key='search' label='Ver detalhes' onClick={() => $state.go('app.gas.processos.details', { id: row.original.id })} table={table}/>
                ]}/>) : ('')}
            </Item>
          </Grid>
        </Grid>)}
    </Fragment>);
};
export default GasDistribuidorDetailsView;
