export function routes($stateProvider) {
    $stateProvider
        .state('app.obler.pecas-pendentes', {
        url: '/pecas-pendentes',
        abstract: true,
        template: '<ui-view></ui-view>'
    })
        .state('app.obler.pecas-pendentes.list', {
        role: ['oblerVerProcessos'],
        url: '?{page:int}&{limit:int}&sortBy&filter',
        template: '<list-pecas-pendentes></list-pecas-pendentes>',
        title: 'Listagem de peças pendentes',
        params: {
            page: {
                value: 1,
                dynamic: true
            },
            limit: {
                value: 20,
                dynamic: true
            },
            sortBy: {
                array: true,
                dynamic: true
            },
            filter: {
                dynamic: true
            }
        }
    })
        .state('app.obler.orcamentos', {
        url: '/orcamentos',
        abstract: true,
        template: '<ui-view></ui-view>'
    })
        .state('app.obler.orcamentos.details', {
        url: '/:id',
        role: ['oblerVerProcessos'],
        template: '<details-orcamentos></details-orcamentos>',
        title: 'Visualizar orçamento'
    })
        .state('app.obler.orcamentos.list', {
        role: ['oblerVerProcessos'],
        url: '?{page:int}&{limit:int}&sortBy&filter',
        template: '<list-orcamentos></list-orcamentos>',
        title: 'Listagem de orçamentos',
        params: {
            page: {
                value: 1,
                dynamic: true
            },
            limit: {
                value: 20,
                dynamic: true
            },
            sortBy: {
                array: true,
                dynamic: true
            },
            filter: {
                dynamic: true
            }
        }
    });
}
routes.$inject = ['$stateProvider'];
