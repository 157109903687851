export function routes($stateProvider) {
  $stateProvider
    .state('app.ativos.locais', {
      url: '/locais',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.ativos.locais.list', {
      url: '/',
      controller: 'AtvLocaisListController',
      controllerAs: 'vm',
      template: require('./directory/directory.view.html'),
      title: 'Local',
      role: 'atvListarLocais'
    })
    .state('app.ativos.locais.details', {
      url: '/{id}',
      controller: 'AtvLocaisDetailsController',
      controllerAs: 'vm',
      template: require('./details/details.view.html'),
      role: 'atvListarLocais'
    })
  ;
}

routes.$inject = ['$stateProvider'];
