import moment from "moment";
export default class PadService {
  constructor($rootScope, $q, $http, UIService, FileUploader, AuthenticationService, PadLinhaProcesso, PadLinhaProcessoEstado, PadProcessoEstado, PadConfig, PadArtigo, PadProjeto, PadProcesso, PadProcessoAnexo, PadProcessoFornecedor, PadProcessoFornecedorAnexo, AtvFornecedor, AtvFornecedorPrimavera, PadCriterio, PadCondicaoPagamento, PadAnexoPendente, PadAvaliacao, PadCentroCusto, PRIIEPArtigo, PRIAJLArtigo, PRIOBLERArtigo, PRIIEPFornecedores, PRIAJLFornecedores, PRIOBLERFornecedores, PRIIEPCondpag, PRIAJLCondpag, PRIOBLERCondpag, PRIIEPPlanocontas, PRIAJLPlanocontas, PRIOBLERPlanocontas) {
    this.$q = $q;
    this.$http = $http;
    this.UI = UIService;
    this.FileUploader = FileUploader;
    this.Auth = AuthenticationService;
    this.PadLinhaProcesso = PadLinhaProcesso;
    this.PadLinhaProcessoEstado = PadLinhaProcessoEstado;
    this.PadProcessoEstado = PadProcessoEstado;
    this.PadConfig = PadConfig;
    this.PadArtigo = PadArtigo;
    this.PadProjeto = PadProjeto;
    this.PadProcesso = PadProcesso;
    this.PadProcessoAnexo = PadProcessoAnexo;
    this.PadProcessoFornecedor = PadProcessoFornecedor;
    this.PadProcessoFornecedorAnexo = PadProcessoFornecedorAnexo;
    this.AtvFornecedor = AtvFornecedor;
    this.AtvFornecedorPrimavera = AtvFornecedorPrimavera;
    this.PadCriterio = PadCriterio;
    this.PadCondicaoPagamento = PadCondicaoPagamento;
    this.PadAnexoPendente = PadAnexoPendente;
    this.PadAvaliacao = PadAvaliacao;
    this.PadCentroCusto = PadCentroCusto;
    this.PRIIEPArtigo = PRIIEPArtigo;
    this.PRIAJLArtigo = PRIAJLArtigo;
    this.PRIOBLERArtigo = PRIOBLERArtigo;
    this.PRIIEPFornecedores = PRIIEPFornecedores;
    this.PRIAJLFornecedores = PRIAJLFornecedores;
    this.PRIOBLERFornecedores = PRIOBLERFornecedores;
    this.PRIIEPCondpag = PRIIEPCondpag;
    this.PRIAJLCondpag = PRIAJLCondpag;
    this.PRIOBLERCondpag = PRIOBLERCondpag;
    this.PRIIEPPlanocontas = PRIIEPPlanocontas;
    this.PRIAJLPlanocontas = PRIAJLPlanocontas;
    this.PRIOBLERPlanocontas = PRIOBLERPlanocontas;
    this.user = this.Auth.getUser();

    // By default, envURL is production (to account for fails)
    this.envURL = "https://inspecoeseletricas.pt";

    // Get environment for URL for files
    // Doing this here only because we only use this.envURL in the view
    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      if (data != null && data.environment != null) {
        let env = data.environment;
        if (env === 'staging') {
          this.envURL = "https://staging-ie.iep.pt"
        } else if (env === 'production') { // For completeness
          this.envURL = "https://inspecoeseletricas.pt";
        } else {
          this.envURL = "https://dev-iep-ie.streamline.pt";
        }
      }
    });
  }

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({ key: data.key, value: data.value });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({ key: data.key, value: whereObject[data.key] });
        delete whereObject[data.key];
        whereOrObject.push({ key: data.key, value: data.value });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  // Check if id exists for Model
  fieldValueExists = (field, value, Model) => {
    let defer = this.$q.defer();
    let where = {};
    where[field] = value;
    Model.count({
      where: where
    }).$promise.then((data) => {
      defer.resolve(data.count > 0);
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };

  // Check if partialData - {key1: data1, key2: data2} is already used in Model, returns count
  // If exceptId, do not search for id: exceptId
  usedPartialDataForModel = (partialData, Model, exceptId) => {
    let defer = this.$q.defer();
    let and = [];
    Object.keys(partialData).forEach(k => {
      let o = {};
      o[k] = partialData[k];
      and.push(o);
    });
    // Exclude exceptId
    if (exceptId)
      and.push({ id: { neq: exceptId } });

    let where = { and: and };
    Model.count({
      where: where
    }).$promise.then((res) => {
      defer.resolve(res);
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };

  // Check if Centros de Custo used are active. If not, refuse to go through
  validateActiveCurrentYearCentrosCusto = (linhas) => {
    let defer = this.$q.defer();
    if (linhas.length === 0) {
      this.UI.addToast("Sem linhas de processo a verificar, adicione pelo menos uma");
      defer.reject();
    }

    let tasks = [];
    for (let i = 0; i < linhas.length; i++) {
      tasks.push(this.PadCentroCusto.findById({ id: linhas[i].centroCustoId }).$promise);
    }

    this.$q.all(tasks).then((res) => {
      if (res && res.length > 0) {
        let existsInvalid = false;
        let currentYear = Number(moment().format('YYYY'));
        for (let i = 0; i < res.length; i++) {
          // Check if inactive
          if (!res[i].active) {
            existsInvalid = true;
            this.UI.addToast("Centro de Custo " + res[i].centro + " - " + res[i].ano + " está inativo. Altere-o antes de submeter");
          }
          // Check if the right year
          if (res[i].ano !== currentYear) {
            existsInvalid = true;
            this.UI.addToast("Centro de Custo " + res[i].centro + " - " + res[i].ano + " não é do ano corrente. Altere-o antes de submeter");
          }
        }
        // If at least one inactive exists, reject everything
        if (existsInvalid) {
          defer.reject();
        } else {
          defer.resolve();
        }
      } else {
        this.UI.addToast("Erro de verificação de centros de custo. Verifique a ligação");
        defer.reject();
      }
    }).catch(error => {
      console.log(error);
      defer.reject();
    });

    return defer.promise;
  };

  // Go through an array of field, value and models, and resolve/reject if all fields and values exist/not exist in those tables
  validateMultiData = (data) => {
    let multiDefer = this.$q.defer();

    let tasks = [];
    for (let i = 0; i < data.length; i++) {
      let defer = this.$q.defer();

      this.fieldValueExists(data[i].field, data[i].value, data[i].model).then((exists) => {
        if (exists) {
          if (data[i].resolveOnExist) { // Check if it's a "Exists, so resolve" or "Exists, so reject"
            defer.resolve();
          }
          else {
            this.UI.addToast(data[i].error);
            defer.reject();
          }
        } else {
          if (data[i].resolveOnExist) {
            this.UI.addToast(data[i].error);
            defer.reject();
          } else {
            defer.resolve();
          }
        }
      }).catch(err => {
        this.UI.addToast("Não foi possível verificar dados. Verifique a ligação");
        defer.reject();
      });
      tasks.push(defer.promise);
    }

    this.$q.all(tasks).then(x => {
      multiDefer.resolve(tasks);
    }).catch(err => {
      multiDefer.reject(err);
    });

    return multiDefer.promise;
  };

  // Create all multiple data for processo, referenced by referenceId (ex. eventoId)
  createMultipleData = (Model, data, referenceId, reference) => {
    let multipleDataDefer = this.$q.defer();

    // Create multiple Data
    let tasks = [];
    for (let i = 0; i < data.length; i++) {
      let defer = this.$q.defer();
      let item = data[i];
      item.id = 0;
      item[reference] = referenceId;

      // item.criadoPorId = this.user.id;
      // item.dataCriacao = moment().format();

      Model.create(item).$promise.then((newItem) => {
        defer.resolve(newItem);
      }).catch((error) => {
        console.log(error);
        defer.reject(error);
      });
      tasks.push(defer.promise);
    }

    // After all data is created
    this.$q.all(tasks).then((res) => {
      multipleDataDefer.resolve(tasks);
    }).catch(err => {
      console.log(err);
      multipleDataDefer.reject(err);
    });

    return multipleDataDefer.promise;
  };

  // Update data with criadoPor, dataCriacao; data is array of objects
  updateMultipleData = (Model, data) => {
    let multipleDataDefer = this.$q.defer();

    // Create multiple Data
    let tasks = [];
    for (let i = 0; i < data.length; i++) {
      let defer = this.$q.defer();
      let item = data[i];

      // item.criadoPorId = this.user.id;
      // item.dataCriacao = moment().format();

      Model.upsert(item).$promise.then((newItem) => {
        defer.resolve(newItem);
      }).catch((error) => {
        console.log(error);
        defer.reject(error);
      });
      tasks.push(defer.promise);
    }

    // After all data is updated
    this.$q.all(tasks).then((res) => {
      multipleDataDefer.resolve(tasks);
    }).catch(err => {
      console.log(err);
      multipleDataDefer.reject(err);
    });

    return multipleDataDefer.promise;
  };

  // Check if values of fields exist in data and are not null
  validateMandatoryData = (data, fields) => {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < fields.length; j++) {
        if (data[i][fields[j]] == null)
          return false;
      }
    }
    return true;
  };

  getPrimaveraArtigo = (entidadeProprietariaId) => {
    switch (entidadeProprietariaId) {
      case 1:
        return this.PRIIEPArtigo;
      case 2:
        return this.PRIAJLArtigo;
      case 3:
        return this.PRIOBLERArtigo;
      default:
        return this.PRIIEPArtigo;
    }
  };

  getPrimaveraProjeto = (entidadeProprietariaId) => {
    switch (entidadeProprietariaId) {
      case 1:
        return this.PRIIEPPlanocontas;
      case 2:
        return this.PRIAJLPlanocontas;
      case 3:
        return this.PRIOBLERPlanocontas;
      default:
        return this.PRIIEPPlanocontas;
    }
  };

  getPrimaveraFornecedor = (entidadeProprietariaId) => {
    switch (entidadeProprietariaId) {
      case 1:
        return this.PRIIEPFornecedores;
      case 2:
        return this.PRIAJLFornecedores;
      case 3:
        return this.PRIOBLERFornecedores;
      default:
        return this.PRIIEPFornecedores;
    }
  };

  getPrimaveraCondicaoPagamento = (entidadeProprietariaId) => {
    switch (entidadeProprietariaId) {
      case 1:
        return this.PRIIEPCondpag;
      case 2:
        return this.PRIAJLCondpag;
      case 3:
        return this.PRIOBLERCondpag;
      default:
        return this.PRIIEPCondpag;
    }
  }

  // Create or return the id of Artigo from the local PadArtigo table
  getArtigoId = (artigo, entidadeProprietariaId) => {
    let defer = this.$q.defer();
    if (!artigo || !artigo.artigo || !entidadeProprietariaId)
      defer.reject();
    this.PadArtigo.find({
      filter: {
        where: {
          and: [{ active: 1 }, { codigo: artigo.artigo }, { entidadeProprietariaId: entidadeProprietariaId }]
        }
      }
    }).$promise.then((foundArtigo) => {
      if (foundArtigo.length > 0) {
        defer.resolve(foundArtigo[0].id);
      } else { // No artigo found, create it
        this.PadArtigo.create({
          id: 0,
          entidadeProprietariaId: entidadeProprietariaId,
          codigo: artigo.artigo,
          designacao: artigo.descricao,
          active: 1
        }).$promise.then((createdArtigo) => {
          defer.resolve(createdArtigo.id);
        }).catch((error) => {
          defer.reject(error);
        });
      }
    }).catch((error) => {
      console.log(error);
      defer.reject(error);
    });

    return defer.promise;
  };

  // Create or return the id of Artigo from the local PadArtigo table
  // Since projeto can be empty, simply return null if bad projeto enters
  getProjetoId = (projeto, entidadeProprietariaId) => {
    let defer = this.$q.defer();

    if (projeto == null) {
      defer.resolve(null);
    } else { // projeto has something, make sure it's workable
      if (!projeto.ano || !projeto.conta || !entidadeProprietariaId)
        defer.reject();
      this.PadProjeto.find({
        filter: {
          where: {
            and: [{ active: 1 }, { ano: projeto.ano }, { conta: projeto.conta }, { entidadeProprietariaId: entidadeProprietariaId }]
          }
        }
      }).$promise.then((foundProjeto) => {
        if (foundProjeto.length > 0) {
          defer.resolve(foundProjeto[0].id);
        } else { // No projeto found, create it
          this.PadProjeto.create({
            id: 0,
            entidadeProprietariaId: entidadeProprietariaId,
            ano: projeto.ano,
            conta: projeto.conta,
            descricao: projeto.descricao,
            active: 1
          }).$promise.then((createdProjeto) => {
            defer.resolve(createdProjeto.id);
          }).catch((error) => {
            defer.reject(error);
          });
        }
      }).catch((error) => {
        console.log(error);
        defer.reject(error);
      });
    }

    return defer.promise;
  };

  // Create and return the id of PadCondicaoPagamento for fornecedor
  getCondicaoPagamentoId = (condicaoPagamento, entidadeProprietariaId) => {
    let defer = this.$q.defer();
    if (!condicaoPagamento || !condicaoPagamento.condpag || !entidadeProprietariaId)
      defer.reject();

    // Check to see if it already exists
    this.PadCondicaoPagamento.find({
      filter: {
        where: {
          and: [{ condpag: condicaoPagamento.condpag }, { entidadeProprietariaId: entidadeProprietariaId }, { active: 1 }]
        }
      }
    }).$promise.then((condicoes) => {
      if (condicoes && condicoes.length > 0) { // PadCondicaoPagamento already exists, use that id
        defer.resolve(condicoes[0].id);
      } else { // PadCondicaoPagamento does not exist, refetch it and import it.
        let PRICondpag = this.getPrimaveraCondicaoPagamento(entidadeProprietariaId);
        PRICondpag.find({
          filter: {
            where: {
              condpag: condicaoPagamento.condpag
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            let c = res[0];
            this.PadCondicaoPagamento.create({
              id: 0,
              condpag: c.condpag,
              entidadeProprietariaId: entidadeProprietariaId,
              descricao: c.descricao,
              dataUltimaAtualizacao: moment(c.dataultimaactualizacao).format(),
              cduDescricao2: c.cduDescricao2,
              active: 1
            }).$promise.then((newCondicaoPagamento) => {
              defer.resolve(newCondicaoPagamento.id);
            }).catch((error) => {
              console.log(error);
              defer.reject(error);
            });
          } else { // Should never happen
            let e = new Error('Not found');
            e.statusCode = 404;
            defer.reject(e);
          }
        }).catch((error) => {
          console.log(error);
          defer.reject(error);
        });
      }
    }).catch((error) => {
      console.log(error);
      defer.reject(error);
    });
    return defer.promise;
  };

  // Create or return the ids of AtvFornecedor / AtvFornecedorPrimavera for fornecedor
  getFornecedorIds = (fornecedorPrimavera, entidadeProprietariaId) => {
    let defer = this.$q.defer();
    if (!fornecedorPrimavera || !fornecedorPrimavera.numcontrib || !fornecedorPrimavera.fornecedor || !entidadeProprietariaId)
      defer.reject();

    // Check to see if it already exists
    this.AtvFornecedorPrimavera.find({
      filter: {
        where: {
          and: [{ refFornecedorPrimavera: fornecedorPrimavera.fornecedor }, { entidadeProprietariaId: entidadeProprietariaId }, { active: 1 }]
        },
        include: {
          relation: 'fornecedor',
          scope: {
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((fornecedoresPrimavera) => {
      if (fornecedoresPrimavera && fornecedoresPrimavera.length > 0) { // FornecedorPrimavera already exists, use those ids
        defer.resolve({ fornecedorId: fornecedoresPrimavera[0].fornecedor.id, fornecedorPrimaveraId: fornecedoresPrimavera[0].id });
      } else { // Fornecedor does not exist, refetch it and import it.
        let PRIFornecedor = this.getPrimaveraFornecedor(entidadeProprietariaId);
        PRIFornecedor.find({
          filter: {
            where: {
              fornecedor: fornecedorPrimavera.fornecedor
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            let f = res[0];
            // Check if we have a fornecedor with the same NIF
            this.AtvFornecedor.find({
              filter: {
                where: {
                  and: [{ nif: f.numcontrib }, { active: 1 }]
                }
              }
            }).$promise.then((fornecedorSGI) => {
              if (fornecedorSGI && fornecedorSGI.length > 0) { // Found AtvFornecedor, use it
                this.AtvFornecedorPrimavera.create({
                  id: 0,
                  nome: f.nome,
                  fornecedorId: fornecedorSGI[0].id,
                  refFornecedorPrimavera: f.fornecedor,
                  entidadeProprietariaId: entidadeProprietariaId,
                  contactoTelefonico: f.tel,
                  morada: f.morada,
                  cp4: f.cp ? f.cp.split("-")[0] : null,
                  cp3: f.cp ? f.cp.split("-")[1] : null,
                  cploc: f.cploc,
                  observacoes: f.notas,
                  atualizadoa: f.dataultimaactualizacao,
                  localidade: f.local,
                  pais: f.pais,
                  active: 1
                }).$promise.then((newFornecedorPrimavera) => {
                  defer.resolve({ fornecedorId: fornecedorSGI[0].id, fornecedorPrimaveraId: newFornecedorPrimavera.id });
                }).catch((error) => {
                  console.log(error);
                  defer.reject(error);
                });
              } else { // No AtvFornecedor was found for NIF, create AtvFornecedor and associate it with AtvFornecedorPrimavera
                this.AtvFornecedor.create({
                  id: 0,
                  nome: f.nome,
                  nif: f.numcontrib,
                  observacoes: f.notas,
                  atualizadoa: moment().isDST() ? moment.utc().add(1, 'h') : moment.utc(),
                  active: 1
                }).$promise.then((atvFornecedor) => {
                  this.AtvFornecedorPrimavera.create({
                    id: 0,
                    nome: f.nome,
                    fornecedorId: atvFornecedor.id,
                    refFornecedorPrimavera: f.fornecedor,
                    entidadeProprietariaId: entidadeProprietariaId,
                    contactoTelefonico: f.tel,
                    morada: f.morada,
                    cp4: f.cp ? f.cp.split("-")[0] : null,
                    cp3: f.cp ? f.cp.split("-")[1] : null,
                    cploc: f.cploc,
                    observacoes: f.notas,
                    atualizadoa: f.dataultimaactualizacao,
                    localidade: f.local,
                    pais: f.pais,
                    active: 1
                  }).$promise.then((fornecedorP) => {
                    defer.resolve({ fornecedorId: atvFornecedor.id, fornecedorPrimaveraId: fornecedorP.id });
                  }).catch((error) => {
                    console.log(error);
                    defer.reject(error);
                  });
                }).catch((error) => {
                  console.log(error);
                  defer.reject(error);
                });
              }
            }).catch((error) => {
              console.log(error);
              defer.reject(error);
            });
          } else {
            defer.reject(error);
          }
        }).catch((error) => {
          console.log(error);
          defer.reject(error);
        });
      }
    }).catch((error) => {
      console.log(error);
      defer.reject(error);
    });

    return defer.promise;
  };

  // Create Linha Processo and initial state
  createLinhaProcesso = (linha) => {
    let defer = this.$q.defer();
    linha.estadoId = 1; // Criação
    linha.precoTotalOrcamentado = Math.round(linha.precoUnitarioOrcamentado * linha.quantidade * 100) / 100; // Recalculate total to avoid potential issues
    // When creating, make sure these are the same, because they should be at this time
    linha.precoUnitarioContratado = linha.precoUnitarioOrcamentado;
    linha.precoTotalContratado = linha.precoTotalOrcamentado;

    this.PadLinhaProcesso.create(linha).$promise.then((createdLinha) => {
      this.PadLinhaProcessoEstado.create({
        id: 0,
        linhaProcessoId: createdLinha.id,
        estadoId: 1,
        data: moment().format(),
        criadoPorId: this.user.id,
        observacoes: 'Criado'
      }).$promise.then(() => {
        defer.resolve(createdLinha);
      }).catch(error => {
        console.log(error);
        defer.reject(error);
      });
    }).catch((error) => {
      console.log(error);
      defer.reject(error);
    });

    return defer.promise;
  };

  // Create all linhas for processo, referenced by processoId
  createLinhasProcesso = (linhas, processoId, entidadeProprietariaId) => {
    let defer = this.$q.defer();
    let tasks = [];

    linhas.forEach(linha => {
      let deferLinha = this.$q.defer();

      // Verifica se projeto existe, se existe usa o id da linha correspondente, se não cria e usa o id
      this.getProjetoId(linha.projeto, entidadeProprietariaId).then((projetoId) => {

        // Verificar se artigo existe, se existe usa o id da linha correspondente, se não cria e usa o id
        this.getArtigoId(linha.artigo, entidadeProprietariaId).then((artigoId) => {
          linha.id = 0;
          // Use id or null (since it's optional)
          linha.projetoId = projetoId ? projetoId : null;

          linha.artigoId = artigoId;
          linha.processoId = processoId;

          // Criar linha
          this.createLinhaProcesso(linha).then((createdLinha) => {
            // Change state again
            this.PadLinhaProcessoEstado.create({
              id: 0,
              linhaProcessoId: createdLinha.id,
              estadoId: 2,
              data: moment().format(),
              criadoPorId: this.user.id,
              observacoes: 'Aguarda aprovação Nível 1'
            }).$promise.then(() => {
              // Change state for processo
              createdLinha.estadoId = 2;
              this.PadLinhaProcesso.upsert(createdLinha).$promise.then(() => {
                deferLinha.resolve();
              }).catch(error => {
                console.log(error);
                defer.reject(error);
              });
            }).catch(error => {
              console.log(error);
              deferLinha.reject(error);
            })
          }).catch((error) => {
            console.log(error);
            deferLinha.reject(error);
          });
        }).catch(error => {
          console.log(error);
          deferLinha.reject(error);
        });
      }).catch(error => {
        console.log(error);
        deferLinha.reject(error);
      });

      tasks.push(deferLinha.promise);
    });


    // After all data is updated
    this.$q.all(tasks).then((res) => {
      defer.resolve(tasks);
    }).catch(err => {
      console.log(err);
      defer.reject(err);
    });
    return defer.promise;
  };

  // Create all fornecedores for processo, referenced by processoId
  createFornecedores = (fornecedores, processoId, entidadeProprietariaId) => {
    let defer = this.$q.defer();
    let tasks = [];

    this.PadConfig.findOne({}).$promise.then((config) => {
      fornecedores.forEach(fornecedor => {
        let deferFornecedor = this.$q.defer();

        // Verifica se existe condição de Pagamento para EP, senão cria e devolve ID
        this.getCondicaoPagamentoId(fornecedor.condicaoPagamento, entidadeProprietariaId).then((condicaoPagamentoId) => {

          // Verificar se fornecedorPrimavera e fornecedor existe, se existe usa os ids das linhas correspondentes, se não cria e usa os ids da criação
          this.getFornecedorIds(fornecedor.fornecedorPrimavera, entidadeProprietariaId).then((fornecedoresIds) => {
            fornecedor.id = 0;
            fornecedor.processoId = processoId;

            delete fornecedor.condicaoPagamento; // So it doesn't interfear with new id
            fornecedor.condicaoPagamentoId = condicaoPagamentoId;

            fornecedor.fornecedorId = fornecedoresIds.fornecedorId;
            fornecedor.fornecedorPrimaveraId = fornecedoresIds.fornecedorPrimaveraId;

            // Criar Fornecedor
            this.PadProcessoFornecedor.create(fornecedor).$promise.then((createdFornecedor) => {

              // If it's fornecedor selecionado, add it to processo object
              if (fornecedor.fornecedorSelecionado) {
                //Update Attributes a dar erro, vamos via upsert (@antonio.gomes, depois tenta fazer undo disto, acho que é o lbServices a passar-se)
                this.PadProcesso.findById({ id: processoId }).$promise.then(processo => {
                  processo.fornecedorSelecionadoId = createdFornecedor.id;
                  processo.criterioId = fornecedor.criterio.id;
                  processo.criterioObservacoes = fornecedor.criterioObservacoes;
                  this.PadProcesso.upsert(processo).$promise.then(r => {
                    // If anexo exists or is mandatory add it
                    if (createdFornecedor.total > config.valorAprovacaoNivel3 || fornecedor.uuid.length > 0) {
                      this.PadProcessoFornecedorAnexo.create({
                        id: 0,
                        processoFornecedorId: createdFornecedor.id,
                        tipoId: 1, // Proposta
                        nome: fornecedor.uuid,
                        ative: 1
                      }).$promise.then(() => {
                        // If it's fornecedor selecionado, add it to processo object
                        deferFornecedor.resolve();
                      }).catch(error => {
                        console.log(error);
                        deferFornecedor.reject(error);
                      });
                    } else {
                      // If it's fornecedor selecionado, add it to processo object
                      deferFornecedor.resolve();
                    }
                  }).catch(error => {
                    console.log(error);
                    deferFornecedor.reject(error);
                  });
                }).catch(error => {
                  console.log(error);
                  deferFornecedor.reject(error);
                });
                /*
                this.PadProcesso.prototype$updateAttributes(
                  { id: processoId },
                  { fornecedorSelecionadoId: createdFornecedor.id, criterioId: fornecedor.criterio.id, criterioObservacoes: fornecedor.criterioObservacoes }
                ).$promise.then(() => {
                  // If anexo exists or is mandatory add it
                  if (createdFornecedor.total > config.valorAprovacaoNivel3 || fornecedor.uuid.length > 0) {
                    this.PadProcessoFornecedorAnexo.create({
                      id: 0,
                      processoFornecedorId: createdFornecedor.id,
                      tipoId: 1, // Proposta
                      nome: fornecedor.uuid,
                      ative: 1
                    }).$promise.then(() => {
                      // If it's fornecedor selecionado, add it to processo object
                      deferFornecedor.resolve();
                    }).catch(error => {
                      console.log(error);
                      deferFornecedor.reject(error);
                    });
                  } else {
                    // If it's fornecedor selecionado, add it to processo object
                    deferFornecedor.resolve();
                  }
                }).catch(error => {
                  console.log(error);
                  deferFornecedor.reject(error);
                });
                */
              } else {
                // If anexo exists or is mandatory add it
                if (createdFornecedor.total > config.valorAprovacaoNivel3 || fornecedor.uuid.length > 0) {
                  this.PadProcessoFornecedorAnexo.create({
                    id: 0,
                    processoFornecedorId: createdFornecedor.id,
                    tipoId: 1, // Proposta
                    nome: fornecedor.uuid,
                    ative: 1
                  }).$promise.then(() => {
                    // If it's fornecedor selecionado, add it to processo object
                    deferFornecedor.resolve();
                  }).catch(error => {
                    console.log(error);
                    deferFornecedor.reject(error);
                  });
                } else {
                  deferFornecedor.resolve();
                }
              }
            }).catch((error) => {
              console.log(error);
              deferFornecedor.reject(error);
            });
          }).catch(error => {
            console.log(error);
            deferFornecedor.reject(error);
          });
        }).catch(error => {
          console.log(error);
          deferFornecedor.reject(error);
        });

        tasks.push(deferFornecedor.promise);
      });

      // After all data is updated
      this.$q.all(tasks).then((res) => {
        defer.resolve(tasks);
      }).catch(err => {
        console.log(err);
        defer.reject(err);
      });

    }).catch(error => {
      console.log(error);
      defer.reject(error);
    });
    return defer.promise;
  };

  // Add one fornecedor at a later stage (negociador)
  createFornecedor = (fornecedor, processoId, entidadeProprietariaId) => {
    let defer = this.$q.defer();

    this.PadConfig.findOne({}).$promise.then((config) => {
      this.PadProcesso.findById({id: processoId, filter: {
          fields: {id: true, estadoId: true}
        }
      }).$promise.then(p => {

        // Verifica se existe condição de Pagamento para EP, senão cria e devolve ID
        this.getCondicaoPagamentoId(fornecedor.condicaoPagamento, entidadeProprietariaId).then((condicaoPagamentoId) => {

          // Verificar se fornecedorPrimavera e fornecedor existe, se existe usa os ids das linhas correspondentes, se não cria e usa os ids da criação
          this.getFornecedorIds(fornecedor.fornecedorPrimavera, entidadeProprietariaId).then((fornecedoresIds) => {
            fornecedor.id = 0;
            fornecedor.processoId = processoId;

            delete fornecedor.condicaoPagamento; // So it doesn't interfear with new id
            fornecedor.condicaoPagamentoId = condicaoPagamentoId;

            fornecedor.fornecedorId = fornecedoresIds.fornecedorId;
            fornecedor.fornecedorPrimaveraId = fornecedoresIds.fornecedorPrimaveraId;

            // Criar Fornecedor
            this.PadProcessoFornecedor.create(fornecedor).$promise.then((createdFornecedor) => {

              this.PadProcessoEstado.create({
                id: 0,
                processoId: processoId,
                estadoId: p.estadoId, // Keep the same state
                data: moment().format(),
                criadoPorId: this.user.id,
                observacoes: `Inserção de Proposta ${fornecedor.fornecedorPrimavera.nome}; Total: ${fornecedor.total}€; (ID SGI: ${createdFornecedor.id})`
              }).$promise.then((estado) => {

                // If it's fornecedor selecionado, add it to processo object
                if (fornecedor.fornecedorSelecionado) {
                  //Update Attributes a dar erro, vamos via upsert (@antonio.gomes, depois tenta fazer undo disto, acho que é o lbServices a passar-se)
                  this.PadProcesso.findById({id: processoId}).$promise.then(processo => {
                    processo.fornecedorSelecionadoId = createdFornecedor.id;
                    processo.criterioId = fornecedor.criterio.id;
                    processo.criterioObservacoes = fornecedor.criterioObservacoes;
                    this.PadProcesso.upsert(processo).$promise.then(r => {
                      // If anexo exists or is mandatory add it
                      if (createdFornecedor.total > config.valorAprovacaoNivel3 || fornecedor.uuid.length > 0) {
                        this.PadProcessoFornecedorAnexo.create({
                          id: 0,
                          processoFornecedorId: createdFornecedor.id,
                          tipoId: 1, // Proposta
                          nome: fornecedor.uuid,
                          ative: 1
                        }).$promise.then(() => {
                          // If it's fornecedor selecionado, add it to processo object
                          defer.resolve();
                        }).catch(error => {
                          this.UI.addToast("Erro na criação de anexo da proposta");
                          console.log(error);
                          defer.reject(error);
                        });
                      } else {
                        // If it's fornecedor selecionado, add it to processo object
                        defer.resolve();
                      }
                    }).catch(error => {
                      this.UI.addToast("Erro na atribuição de fornecedor selecionado");
                      console.log(error);
                      defer.reject(error);
                    });
                  }).catch(error => {
                    this.UI.addToast("Erro na procura de processo associado. Verifique a ligação");
                    console.log(error);
                    defer.reject(error);
                  });
                } else {
                  // If anexo exists or is mandatory add it
                  if (createdFornecedor.total > config.valorAprovacaoNivel3 || fornecedor.uuid.length > 0) {
                    this.PadProcessoFornecedorAnexo.create({
                      id: 0,
                      processoFornecedorId: createdFornecedor.id,
                      tipoId: 1, // Proposta
                      nome: fornecedor.uuid,
                      ative: 1
                    }).$promise.then(() => {
                      // If it's fornecedor selecionado, add it to processo object
                      defer.resolve();
                    }).catch(error => {
                      this.UI.addToast("Erro na criação de anexo da proposta");
                      console.log(error);
                      defer.reject(error);
                    });
                  } else {
                    defer.resolve();
                  }
                }
              }).catch((error) => {
                this.UI.addToast("Erro na criação de alteração de estado de processo. Verifique a ligação");
                console.log(error);
                defer.reject(error);
              });
            }).catch((error) => {
              this.UI.addToast("Erro na criação de proposta de fornecedor. Verifique a ligação");
              console.log(error);
              defer.reject(error);
            });
          }).catch(error => {
            this.UI.addToast("Erro na obtenção de referência de fornecedor. Verifique a ligação");
            console.log(error);
            defer.reject(error);
          });
        }).catch(error => {
          this.UI.addToast("Erro na obtenção de condições de pagamento. Verifique a ligação");
          console.log(error);
          defer.reject(error);
        });
      }).catch(error => {
        this.UI.addToast("Erro na consulta de estado de processo. Verifique a ligação");
        console.log(error);
        defer.reject(error);
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Erro na busca de configuração PAD. Verifique a ligação");
      defer.reject(error);
    });
    return defer.promise;
  };

  // Should never happen, but if it does, can be fixed here
  fixMissingFornecedorSelecionado = (processoId) => {
    let defer = this.$q.defer();

    this.PadProcesso.findById({
      id: processoId,
      filter: {
        include: [{
          relation: 'fornecedorSelecionado',
          scope: {
            where: {
              active: 1
            }
          }
        }, {
          relation: 'fornecedores',
          scope: {
            where: {
              active: 1
            },
            include: ['fornecedor']
          }
        }]
      }
    }).$promise.then((p) => {
      if (p.fornecedorSelecionado) {
        defer.resolve();
      } else {
        // We can automatically fix if there's only one
        if (p.fornecedores.length === 1) {
          this.PadProcesso.prototype$updateAttributes({ id: p.id }, { fornecedorSelecionadoId: p.fornecedores[0].id }).$promise.then(() => {
            defer.resolve();
          }).catch(error => {
            defer.reject(error);
          });
        } else { // We need to ask which one is selecionado
          this.PadCriterio.find({}).$promise.then(criterios => {
            // Create modal to select fornecedor
            p.fornecedores.forEach(f => f.nome = f.fornecedor.nome);
            let options = {
              size: "lg",
              template: require("./processos/new/confirm.fornecedor.dialog.html"),
              controller: ["$scope", "$dialog", ($scope, $dialog) => {
                $scope.label = "Seleção de Fornecedor";
                $scope.sublabel = "Ocorreu um erro no processo de seleção de fornecedor para o processo. Por favor selecione-o novamente:"
                $scope.fornecedores = p.fornecedores;
                $scope.criterios = criterios;
                $scope.criterioId = null;
                $scope.criterioObservacoes = null;

                $scope.auxFornecedor = {
                  selected: undefined,
                  infiniteScroll: { numToAdd: 20, currentItems: 20 }
                };

                $scope.auxCriterio = {
                  selected: undefined,
                  infiniteScroll: { numToAdd: 20, currentItems: 20 }
                };

                //Infinite Scroll Magic
                $scope.addMoreItems = (infiniteScroll) => {
                  infiniteScroll.currentItems += infiniteScroll.numToAdd;
                };

                $scope.ok = () => {
                  $dialog.close($scope);
                };

                $scope.cancel = () => {
                  $dialog.dismiss("cancel");
                };
              }]
            };

            let modal = this.UI.showDialog(options);
            modal.then((ok) => {
              if (ok.auxFornecedor && ok.auxFornecedor.selected && ok.auxCriterio && ok.auxCriterio.selected) {
                this.PadProcesso.prototype$updateAttributes({ id: p.id }, {
                  fornecedorSelecionadoId: ok.auxFornecedor.selected.id,
                  criterioId: ok.auxCriterio.selected.id,
                  criterioObservacoes: ok.criterioObservacoes
                }).$promise.then(() => {
                  defer.resolve();
                }).catch(error => {
                  console.log(error);
                  defer.reject(error);
                });
              } else {
                defer.reject();
              }
            }).catch(() => {
              defer.reject();
            });
          }).catch(error => {
            console.log(error);
            defer.reject(error);
          });
        }
      }
    }).catch((error) => {
      console.log(error);
      defer.reject(error);
    });

    return defer.promise;
  };

  // Returns the color style for valor (for estados of Processo)
  getStyleProcesso = (valor) => {
    switch (valor) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 11:
      case 14:
      case 15:
        return { "text-transform": "uppercase", "font-weight": 500, color: "#4d575d" };
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 12:
      case 13:
      case 16:
        return { "text-transform": "uppercase", "font-weight": 500, color: "#388e3c" };
      case 17:
      case 18:
        return { "text-transform": "uppercase", "font-weight": 500, color: "#d32f2f" };
      default:
        return { "text-transform": "uppercase", "font-weight": 500, color: "#4d575d" };
    }
  };

  // Returns the color style for valor (for estados of Linhas de Processo)
  getStyleLinhaProcesso = (valor) => {
    switch (valor) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return { "text-transform": "uppercase", "font-weight": 500, color: "#4d575d" };
      case 6:
      case 7:
      case 8:
      case 9:
      case 12:
        return { "text-transform": "uppercase", "font-weight": 500, color: "#388e3c" };
      case 10:
      case 11:
        return { "text-transform": "uppercase", "font-weight": 500, color: "#d32f2f" };
      default:
        return { "text-transform": "uppercase", "font-weight": 500, color: "#4d575d" };
    }
  };


  setProcessoTimelineColor = (h) => {
    switch (h.estado.id) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 11:
      case 14:
      case 15:
        return "project-standby";
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 12:
      case 13:
      case 16:
        return "project-info";
      case 17:
      case 18:
        return "project-notification";
      default:
        return "project-standby";
    }
  };

  setLinhaProcessoTimelineColor = (h) => {
    switch (h.estado.id) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "project-standby";
      case 6:
      case 7:
      case 8:
      case 9:
      case 12:
        return "project-info";
      case 10:
      case 11:
        return "project-notification";
      default:
        return "project-standby";
    }
  };

  generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  // Update cabimentos when evaluating processo Nivel 2
  updateCabimentos = (cabimentos) => {
    let defer = this.$q.defer();
    let tasks = [];
    Object.keys(cabimentos).forEach(c => {
      let deferCabimento = this.$q.defer();
      this.PadLinhaProcesso.findById({ id: Number(c) }).$promise.then((l) => {
        l.cabimento = cabimentos[c];
        this.PadLinhaProcesso.upsert(l).$promise.then(() => {
          deferCabimento.resolve(c);
        }).catch(error => {
          console.log(error);
          deferCabimento.reject(error);
        })
      }).catch(e => {
        deferCabimento.reject(e);
      });
      tasks.push(deferCabimento.promise);
    });
    this.$q.all(tasks).then((res) => {
      defer.resolve();
    }).catch(e => {
      // Do something when at least one count fails
      console.log(e);
      defer.reject();
    });
    return defer.promise;
  };

  // Special case for evaluateMultipleProcessos - case evaluating nivel 2
  evaluateProcessoNivel2 = (processo, value) => {
    let defer = this.$q.defer();
    let wait = this.UI.showWaiting();
    this.PadProcesso.findById({
      id: processo.id, filter: {
        include: {
          relation: 'linhas',
          scope: {
            where: {
              estadoId: 3
            },
            include: ['artigo', 'centroCusto']
          }
        }
      }
    }).$promise.then(p => {
      this.PadLinhaProcesso.getFinancialDataMultiple({ linhas: p.linhas }).$promise.then((data) => {
        wait.close();
        let options = {
          size: "lg",
          template: require("./pendentes/directory/evaluate.nivel2.dialog.html"),
          controller: ["$scope", "$dialog", "$filter", ($scope, $dialog, $filter) => {
            $scope.title = (value ? "Aprovação de Processo - " : "Reprovação de Processo - ") + p.linhas.length + " Linha" + (p.linhas.length === 1 ? "" : "s");
            $scope.linhas = p.linhas;
            $scope.linhas.forEach(l => {
              let o = data.data.find(x => x.id === l.id);
              if (o) l.orcamento = o.data;
            });
            $scope.cabimentos = {};
            p.linhas.forEach(l => $scope.cabimentos[l.id] = false); // Fill in all as false (default)
            $scope.approving = (value === true);

            $scope.canShowProgressOrcamento = (x) => x.data && x.data.movimentosTotal != null && x.data.orcamentoTotalAno != null && x.data.gastosAprovados != null;

            $scope.getTooltipOrcamento = (x) => {
              if (x.data == null) return "Sem informação disponível"; // Should not come here
              let tooltipString = "Movimentos Ano: ";
              tooltipString += (x.data.movimentosTotal == null) ? 'N/D' : $filter('currency')(x.data.movimentosTotal, '€', 2);
              tooltipString += " ; Gastos Aprovados SGI: ";
              tooltipString += (x.data.gastosAprovados == null) ? 'N/D' : $filter('currency')(x.data.gastosAprovados, '€', 2);
              tooltipString += " ; Orçamento Total Ano: ";
              tooltipString += (x.data.orcamentoTotalAno == null) ? 'N/D' : $filter('currency')(x.data.orcamentoTotalAno, '€', 2);
              return tooltipString;
            };

            // x - orcamento object (with data inside)
            $scope.progressWidth = (x, type) => {
              let width0, width1;
              if (x.data.movimentosTotal + x.data.gastosAprovados <= x.data.orcamentoTotalAno) {
                width0 = x.data.movimentosTotal / x.data.orcamentoTotalAno * 100;
                width1 = x.data.gastosAprovados / x.data.orcamentoTotalAno * 100;
                if (type === 0) return width0; else return width1;
              } else { // We need to adjust the scale to show everything properly
                width0 = x.data.movimentosTotal / (x.data.movimentosTotal + x.data.gastosAprovados) * 100;
                width1 = x.data.gastosAprovados / (x.data.movimentosTotal + x.data.gastosAprovados) * 100;
                if (type === 0) return width0; else return width1;
              }
            };

            // x - linha object with orcamento.data inside
            $scope.enquadramentoOrcamentalDanger = (x) => {
              return x.orcamento.data.gastosAprovados + x.orcamento.data.movimentosTotal > x.orcamento.data.orcamentoTotalAno;
            };

            // x - linha object with orcamento.data inside
            $scope.enquadramentoOrcamentalWarning = (x) => {
              return (x.orcamento.data.gastosAprovados + x.orcamento.data.movimentosTotal < x.orcamento.data.orcamentoTotalAno) && (x.precoTotalContratado + x.orcamento.data.gastosAprovados + x.orcamento.data.movimentosTotal > x.orcamento.data.orcamentoTotalAno);
            };

            $scope.ok = () => {
              $dialog.close($scope);
            };

            $scope.cancel = () => {
              $dialog.dismiss("cancel");
            };
          }]
        };

        let modal = this.UI.showDialog(options);
        modal.then((ok) => {
          let evaluateStrings = value ? ["aprovar", "Aprovação", "aprovado"] : ["reprovar", "Reprovação", "reprovado"];
          this.updateCabimentos(ok.cabimentos).then(() => {
            // Evaluate the process
            // Recheck if all linhas have the same state
            this.PadProcesso.find({
              filter: {
                fields: { id: true, estadoId: true },
                where: {
                  and: [{ active: 1 }, { id: processo.id }]
                }
              }
            }).$promise.then((procs) => {
              // Check if all procs exist and estados are the same
              if (procs.length === 1 && !procs.some(x => x.estadoId !== processo.estadoId)) {
                let wait = this.UI.showWaiting();
                this.PadProcesso.evaluate({
                  params: {
                    ids: [processo.id],
                    originalState: processo.estadoId,
                    value: value,
                    observacoes: ok.observacoes,
                    nivel2: true
                  }
                }).$promise.then(res => {
                  wait.close();
                  let errors = res.status.filter(x => x.error);
                  if (errors.length > 0) {
                    let title = `Erros de ${evaluateStrings[1]}`;
                    let introText = "Ocorreram os seguintes erros:";
                    let instance = this.UI.showDialog({
                      size: 'md',
                      template: require('../interface/modals/show-list.html'),
                      controller: ['$scope', ($scope) => {
                        $scope.title = title;
                        $scope.introText = introText;
                        $scope.list = errors;
                        $scope.ok = function () {
                          $scope.$close();
                        };
                      }]
                    });
                    instance.finally(() => {
                      defer.resolve();
                    })
                  } else {
                    this.UI.addToast(`Processo ${evaluateStrings[2]} com sucesso`);
                    defer.resolve();
                  }
                }).catch(error => {
                  console.log(error);
                  wait.close();
                });
              } else {
                let alert = this.UI.showAlert("Houve pelo menos um processo com estado entretanto alterado nos selecionados. Por favor tente novamente.");
                alert.finally(() => {
                  defer.reject();
                });
              }
            }).catch((error) => {
              console.log(error);
              let alert = this.UI.showAlert("Não foi possível consultar informação de processos. Verifique a ligação");
              alert.finally(() => {
                defer.reject();
              });
            });
          }).catch(error => {
            console.log(error);
            this.UI.addToast("Erro a atualizar cabimentos. Verifique a ligação");
            defer.reject();
          });
        }).catch(() => {
          defer.reject();
        });
      }).catch(error => {
        this.UI.addToast("Erro na procura de informação financeira");
        console.log(error);
        defer.reject();
      });
    }).catch(error => {
      wait.close();
      this.UI.addToast("Erro na procura do processo. Verifique a ligação");
      console.log(error);
      defer.reject(error);
    });
    return defer.promise;
  };


  // Evaluate processos with id in array ids = [{id: ..., estadoId: ...}, ...] with value (true|false)
  evaluateMultipleProcessos = (ids, value) => {
    let defer = this.$q.defer();

    // Automatically reject approving multiple processos nivel 2 e nivel 3 (estados 3 e 4)
    if ((ids[0].estadoId === 3 && ids.length !== 1) || (ids[0].estadoId === 4 && ids.length !== 1)) {
      this.UI.addToast("Apenas pode aprovar 1 processo de cada vez");
      defer.reject();
    } else {
      let waitConfig = this.UI.showWaiting();
      // Get Config and first Processo object (only used because of Nivel 3)
      this.PadConfig.findOne({}).$promise.then((config) => {
        this.PadProcesso.findById({ id: ids[0].id }).$promise.then((processoNivel3) => {
          waitConfig.close();
          // Special Nivel 2 case - only one at a time and different modal
          if (ids[0].estadoId === 3) {
            let result = this.UI.showConfirm("Tem a certeza que pretende avaliar este processo?");
            result.then(() => {
              this.evaluateProcessoNivel2(ids[0], value).then(() => {
                defer.resolve();
              }).catch(error => {
                console.log(error);
                defer.reject();
              })
            }).catch(() => {
              defer.reject();
            });
          } else { // All other approval cases
            let options = {
              size: "lg",
              template: require("./pendentes/directory/evaluate.dialog.html"),
              controller: ["$scope", "$dialog", ($scope, $dialog) => {
                $scope.title = (value ? "Aprovação de Processo" : "Reprovação de Processo") + (ids.length !== 1 ? "s" : "");
                $scope.nivel3 = ids[0].estadoId === 4; // Check if it's Nível 3
                $scope.simnao = [{ name: "Sim", value: 1 }, { name: "Não", value: 0 }];
                $scope.approving = (value === true);
                // Only have to show this when processo was not going to nivel 4 anyway
                // Only let go to nivel4 processos with entidadeProprietaria IEP
                $scope.canForceEvaluationNivel4 = processoNivel3.entidadeProprietariaId === 1 && $scope.nivel3 && $scope.approving && (processoNivel3.total < config.valorAprovacaoNivel3);
                $scope.forceEvaluationNivel4 = { check: false };

                $scope.auxCabimento = {
                  selected: undefined,
                  infiniteScroll: { numToAdd: 20, currentItems: 20 }
                };

                //Infinite Scroll Magic
                $scope.infiniteScroll = {};
                $scope.infiniteScroll.numToAdd = 20;
                $scope.infiniteScroll.currentItems = 20;

                $scope.addMoreItems = function () {
                  $scope.infiniteScroll.currentItems += $scope.infiniteScroll.numToAdd;
                };

                $scope.ok = () => {
                  $dialog.close($scope);
                };

                $scope.cancel = () => {
                  $dialog.dismiss("cancel");
                };
              }]
            };

            let modal = this.UI.showDialog(options);

            modal.then((ok) => {
              let processCountString = ids.length > 1 ? `estes ${ids.length} processos` : `este processo`;
              let evaluateStrings = value ? ["aprovar", "Aprovação", "aprovado"] : ["reprovar", "Reprovação", "reprovado"];
              let warning = `Tem a certeza que pretende ${evaluateStrings[0]} ${processCountString}? Esta decisão não pode ser alterada.`;
              let result = this.UI.showConfirm(warning);
              result.then(() => {
                // Recheck if all linhas have the same state
                let wait = this.UI.showWaiting();
                this.PadProcesso.find({
                  filter: {
                    fields: { id: true, estadoId: true },
                    where: {
                      and: [{ active: 1 }, { id: { inq: ids.map(x => x.id) } }]
                    }
                  }
                }).$promise.then((procs) => {
                  // Check if all procs exist and estados are the same
                  if (procs.length === ids.length && !procs.some(x => x.estadoId !== ids[0].estadoId)) {
                    this.PadProcesso.evaluate({
                      params: {
                        ids: ids.map(x => x.id),
                        originalState: ids[0].estadoId,
                        value: value,
                        observacoes: ok.observacoes,
                        nivel2: ok.auxCabimento.selected ? ok.auxCabimento.selected.value : undefined,
                        forceEvaluationNivel4: ok.forceEvaluationNivel4.check || false
                      }
                    }).$promise.then(res => {
                      wait.close();
                      let errors = res.status.filter(x => x.error);
                      if (errors.length > 0) {
                        let title = `Erros de ${evaluateStrings[1]}`;
                        let introText = "Ocorreram os seguintes erros:";
                        let instance = this.UI.showDialog({
                          size: 'md',
                          template: require('../interface/modals/show-list.html'),
                          controller: ['$scope', ($scope) => {
                            $scope.title = title;
                            $scope.introText = introText;
                            $scope.list = errors;
                            $scope.ok = function () {
                              $scope.$close();
                            };
                          }]
                        });
                        instance.finally(() => {
                          defer.resolve();
                        })
                      } else {
                        if (ids.length === 1)
                          this.UI.addToast(`Processo ${evaluateStrings[2]} com sucesso`);
                        else
                          this.UI.addToast(`Processos ${evaluateStrings[2]}s com sucesso`);
                        defer.resolve();
                      }
                    }).catch(error => {
                      console.log(error);
                      this.UI.addToast("Erro na avaliação de processo");
                      wait.close();
                    });
                  } else {
                    wait.close();
                    let alert = this.UI.showAlert("Houve pelo menos um processo com estado entretanto alterado nos selecionados. Por favor tente novamente.");
                    alert.finally(() => {
                      defer.reject();
                    });
                  }
                }).catch((error) => {
                  wait.close();
                  console.log(error);
                  let alert = this.UI.showAlert("Não foi possível consultar informação de processos. Verifique a ligação");
                  alert.finally(() => {
                    defer.reject();
                  });
                });
              }).catch(() => {
                defer.reject();
              });
            }).catch(() => {
              defer.reject();
            });
          }
        }).catch(error => {
          waitConfig.close();
          console.log(error);
          this.UI.addToast("Não foi possível obter processo a avaliar. Verifique a ligação");
        });
      }).catch(error => {
        waitConfig.close();
        console.log(error);
        this.UI.addToast("Erro na leitura de configuração PAD. Verifique a ligação");
        defer.reject();
      });
    }
    return defer.promise;
  };

  // Evaluate linhas de processos with id in array ids = [{id: ..., estadoId: ...}, ...] with value (true|false)
  evaluateMultipleLinhas = (ids, value) => {
    let defer = this.$q.defer();
    let options = {
      size: "lg",
      template: require("./pendentes/directory/evaluate.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.title = (value ? "Aprovação de linha" : "Reprovação de linha") + (ids.length !== 1 ? "s" : "");

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((ok) => {
      let processCountString = ids.length > 1 ? `estas ${ids.length} linhas` : `esta linha`;
      let evaluateStrings = value ? ["aprovar", "Aprovação", "aprovada"] : ["reprovar", "Reprovação", "reprovada"];
      let warning = `Tem a certeza que pretende ${evaluateStrings[0]} ${processCountString}? Esta decisão não pode ser alterada.`;
      let result = this.UI.showConfirm(warning);
      result.then(() => {
        // Recheck if all linhas have the same state
        this.PadLinhaProcesso.find({
          filter: {
            fields: { id: true, estadoId: true },
            where: {
              and: [{ active: 1 }, { id: { inq: ids.map(x => x.id) } }]
            }
          }
        }).$promise.then((lprocs) => {
          // Check if all lprocs exist and estados are the same
          if (lprocs.length === ids.length && !lprocs.some(x => x.estadoId !== ids[0].estadoId)) {
            let wait = this.UI.showWaiting();
            this.PadLinhaProcesso.evaluate({
              params: {
                ids: ids.map(x => x.id),
                originalState: ids[0].estadoId,
                value: value,
                observacoes: ok.observacoes
              }
            }).$promise.then(res => {
              wait.close();
              let errors = res.status.filter(x => x.error);
              if (errors.length > 0) {
                let title = `Erros de ${evaluateStrings[1]}`;
                let introText = "Ocorreram os seguintes erros:";
                let instance = this.UI.showDialog({
                  size: 'md',
                  template: require('../interface/modals/show-list.html'),
                  controller: ['$scope', ($scope) => {
                    $scope.title = title;
                    $scope.introText = introText;
                    $scope.list = errors;
                    $scope.ok = function () {
                      $scope.$close();
                    };
                  }]
                });
                instance.finally(() => {
                  defer.resolve();
                })
              } else {
                if (ids.length === 1)
                  this.UI.addToast(`Linha ${evaluateStrings[2]} com sucesso`);
                else
                  this.UI.addToast(`Linhas ${evaluateStrings[2]}s com sucesso`);
                defer.resolve();
              }
            }).catch(error => {
              console.log(error);
              wait.close();
              let alert = this.UI.showAlert("Ocorreu um erro nos dados enviados à plataforma. Por favor tente novamente.");
              alert.finally(() => {
                defer.reject();
              });
            });
          } else {
            let alert = this.UI.showAlert("Houve pelo menos uma linha com estado entretanto alterado nas selecionadas. Por favor tente novamente.");
            alert.finally(() => {
              defer.reject();
            });
          }
        }).catch((error) => {
          console.log(error);
          let alert = this.UI.showAlert("Não foi possível consultar informação de linhas de processos. Verifique a ligação");
          alert.finally(() => {
            defer.reject();
          });
        });
      }).catch(() => {
        defer.reject();
      });
    }).catch(() => {
      defer.reject();
    });
    return defer.promise;
  };

  // Validate processos with id in array ids = [{id: ..., estadoId: ..., nivel1AvaliadoPorId: ...}, ...]
  negotiateMultipleProcessos = (ids) => {
    let defer = this.$q.defer();
    let options = {
      size: "lg",
      template: require("./pendentes/directory/validate.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.title = "Validação de Negociação de Processo" + (ids.length !== 1 ? "s" : "");

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((ok) => {
      let processCountString = ids.length > 1 ? `destes ${ids.length} processos` : `deste processo`;
      let evaluateStrings = ["validar negociação", "Validação de Negociação ", "validado"];
      let warning = `Tem a certeza que pretende ${evaluateStrings[0]} ${processCountString}? Esta decisão não pode ser alterada.`;
      let result = this.UI.showConfirm(warning);
      result.then(() => {
        // Recheck if all linhas have the same state
        this.PadProcesso.find({
          filter: {
            fields: { id: true, estadoId: true, nivel1AvaliadoPorId: true },
            where: {
              and: [{ active: 1 }, { id: { inq: ids.map(x => x.id) } }]
            }
          }
        }).$promise.then((procs) => {
          // Check if all procs exist and estados are the same (Aguarda Nível 1 and already avaliado nível 1)
          if (procs.length === ids.length && !procs.some(x => (x.estadoId !== ids[0].estadoId || x.nivel1AvaliadoPorId == null)) && ids[0].estadoId === 2) {
            let wait = this.UI.showWaiting();
            this.PadProcesso.negotiate({
              params: {
                ids: ids.map(x => x.id),
                originalState: ids[0].estadoId,
                observacoes: ok.observacoes || null
              }
            }).$promise.then(res => {
              wait.close();
              let errors = res.status.filter(x => x.error);
              if (errors.length > 0) {
                let title = `Erros de ${evaluateStrings[1]}`;
                let introText = "Ocorreram os seguintes erros:";
                let instance = this.UI.showDialog({
                  size: 'md',
                  template: require('../interface/modals/show-list.html'),
                  controller: ['$scope', ($scope) => {
                    $scope.title = title;
                    $scope.introText = introText;
                    $scope.list = errors;
                    $scope.ok = function () {
                      $scope.$close();
                    };
                  }]
                });
                instance.finally(() => {
                  defer.resolve();
                })
              } else {
                if (ids.length === 1)
                  this.UI.addToast(`Processo ${evaluateStrings[2]} com sucesso`);
                else
                  this.UI.addToast(`Processos ${evaluateStrings[2]}s com sucesso`);
                defer.resolve();
              }
            }).catch(error => {
              console.log(error);
              wait.close();
              let alert = this.UI.showAlert("Ocorreu um erro nos dados enviados à plataforma. Por favor tente novamente.");
              alert.finally(() => {
                defer.reject();
              });
            });
          } else {
            let alert = this.UI.showAlert("Houve pelo menos um processo com estado entretanto alterado nos selecionados. Por favor tente novamente.");
            alert.finally(() => {
              defer.reject();
            });
          }
        }).catch((error) => {
          console.log(error);
          let alert = this.UI.showAlert("Não foi possível consultar informação de processos. Verifique a ligação");
          alert.finally(() => {
            defer.reject();
          });
        });
      }).catch(() => {
        defer.reject();
      });
    }).catch(() => {
      defer.reject();
    });
    return defer.promise;
  };

  // Validate processos with id in array ids = [{id: ..., estadoId: ...}, ...]
  validateMultipleProcessos = (ids) => {
    let defer = this.$q.defer();
    let options = {
      size: "lg",
      template: require("./pendentes/directory/validate.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.title = "Validação de Processo" + (ids.length !== 1 ? "s" : "");

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((ok) => {
      let processCountString = ids.length > 1 ? `estes ${ids.length} processos` : `este processo`;
      let evaluateStrings = ["validar", "Validação", "validado"];
      let warning = `Tem a certeza que pretende ${evaluateStrings[0]} ${processCountString}? Esta decisão não pode ser alterada.`;
      let result = this.UI.showConfirm(warning);
      result.then(() => {
        // Recheck if all linhas have the same state
        this.PadProcesso.find({
          filter: {
            fields: { id: true, estadoId: true },
            where: {
              and: [{ active: 1 }, { id: { inq: ids.map(x => x.id) } }]
            }
          }
        }).$promise.then((procs) => {
          // Check if all procs exist and estados are the same (Adjudicado)
          if (procs.length === ids.length && !procs.some(x => x.estadoId !== ids[0].estadoId) && ids[0].estadoId === 12) {
            let wait = this.UI.showWaiting();
            this.PadProcesso.validate({
              params: {
                ids: ids.map(x => x.id),
                originalState: ids[0].estadoId,
                observacoes: ok.observacoes || null
              }
            }).$promise.then(res => {
              wait.close();
              let errors = res.status.filter(x => x.error);
              if (errors.length > 0) {
                let title = `Erros de ${evaluateStrings[1]}`;
                let introText = "Ocorreram os seguintes erros:";
                let instance = this.UI.showDialog({
                  size: 'md',
                  template: require('../interface/modals/show-list.html'),
                  controller: ['$scope', ($scope) => {
                    $scope.title = title;
                    $scope.introText = introText;
                    $scope.list = errors;
                    $scope.ok = function () {
                      $scope.$close();
                    };
                  }]
                });
                instance.finally(() => {
                  defer.resolve();
                })
              } else {
                if (ids.length === 1)
                  this.UI.addToast(`Processo ${evaluateStrings[2]} com sucesso`);
                else
                  this.UI.addToast(`Processos ${evaluateStrings[2]}s com sucesso`);
                defer.resolve();
              }
            }).catch(error => {
              console.log(error);
              wait.close();
              let alert = this.UI.showAlert("Ocorreu um erro nos dados enviados à plataforma. Por favor tente novamente.");
              alert.finally(() => {
                defer.reject();
              });
            });
          } else {
            let alert = this.UI.showAlert("Houve pelo menos um processo com estado entretanto alterado nos selecionados. Por favor tente novamente.");
            alert.finally(() => {
              defer.reject();
            });
          }
        }).catch((error) => {
          console.log(error);
          let alert = this.UI.showAlert("Não foi possível consultar informação de processos. Verifique a ligação");
          alert.finally(() => {
            defer.reject();
          });
        });
      }).catch(() => {
        defer.reject();
      });
    }).catch(() => {
      defer.reject();
    });
    return defer.promise;
  };

  // Evaluate fornecedores of processos with id in array ids = [{id: ..., estadoId: ...}, ...]
  evaluateFornecedorMultipleProcessos = (ids) => {
    let defer = this.$q.defer();
    this.PadAvaliacao.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((avaliacoes) => {
      let options = {
        size: "lg",
        template: require("./pendentes/directory/evaluate.fornecedor.dialog.html"),
        controller: ["$scope", "$dialog", ($scope, $dialog) => {
          $scope.title = "Avaliação de Fornecedor" + (ids.length !== 1 ? "es" : "");

          $scope.avaliacoes = avaliacoes;

          $scope.auxQualidade = {
            selected: undefined,
            infiniteScroll: { numToAdd: 20, currentItems: 20 }
          };

          $scope.auxPrazo = {
            selected: undefined,
            infiniteScroll: { numToAdd: 20, currentItems: 20 }
          };

          // Observacoes are mandatory if prazo or quantidade <= 2
          $scope.observacoesRequired = () => {
            if ($scope.auxQualidade.selected && $scope.auxPrazo.selected) {
              return $scope.auxQualidade.selected.valor <= 2 || $scope.auxPrazo.selected.valor <= 2;
            } else {
              return false;
            }
          };

          //Infinite Scroll Magic
          $scope.infiniteScroll = {};
          $scope.infiniteScroll.numToAdd = 20;
          $scope.infiniteScroll.currentItems = 20;

          $scope.addMoreItems = function () {
            $scope.infiniteScroll.currentItems += $scope.infiniteScroll.numToAdd;
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss("cancel");
          };
        }]
      };

      let modal = this.UI.showDialog(options);

      modal.then((ok) => {
        let processCountString = ids.length > 1 ? `destes ${ids.length} processos` : `deste processo`;
        let evaluateStrings = ["avaliar", "Avaliação", "avaliado"];
        let warning = `Tem a certeza que pretende ${evaluateStrings[0]} o fornecedor ${processCountString}? Esta decisão não pode ser alterada.`;
        let result = this.UI.showConfirm(warning);
        result.then(() => {
          // Recheck if all linhas have the same state
          this.PadProcesso.find({
            filter: {
              fields: { id: true, estadoId: true },
              where: {
                and: [{ active: 1 }, { id: { inq: ids.map(x => x.id) } }]
              }
            }
          }).$promise.then((procs) => {
            // Check if all procs exist and estados are the same
            if (procs.length === ids.length && !procs.some(x => x.estadoId !== ids[0].estadoId)) {
              let wait = this.UI.showWaiting();
              this.PadProcesso.evaluateFornecedor({
                params: {
                  ids: ids.map(x => x.id),
                  originalState: ids[0].estadoId,
                  avaliacaoQualidadeId: ok.auxQualidade.selected.id,
                  avaliacaoPrazoId: ok.auxPrazo.selected.id,
                  observacoes: ok.observacoes
                }
              }).$promise.then(res => {
                wait.close();
                let errors = res.status.filter(x => x.error);
                if (errors.length > 0) {
                  let title = `Erros de ${evaluateStrings[1]}`;
                  let introText = "Ocorreram os seguintes erros:";
                  let instance = this.UI.showDialog({
                    size: 'md',
                    template: require('../interface/modals/show-list.html'),
                    controller: ['$scope', ($scope) => {
                      $scope.title = title;
                      $scope.introText = introText;
                      $scope.list = errors;
                      $scope.ok = function () {
                        $scope.$close();
                      };
                    }]
                  });
                  instance.finally(() => {
                    defer.resolve();
                  })
                } else {
                  if (ids.length === 1)
                    this.UI.addToast(`Processo ${evaluateStrings[2]} com sucesso`);
                  else
                    this.UI.addToast(`Processos ${evaluateStrings[2]}s com sucesso`);
                  defer.resolve();
                }
              }).catch(error => {
                console.log(error);
                wait.close();
                let alert = this.UI.showAlert("Ocorreu um erro nos dados enviados à plataforma. Por favor tente novamente.");
                alert.finally(() => {
                  defer.reject();
                });
              });
            } else {
              let alert = this.UI.showAlert("Houve pelo menos um processo com estado entretanto alterado nos selecionados. Por favor tente novamente.");
              alert.finally(() => {
                defer.reject();
              });
            }
          }).catch((error) => {
            console.log(error);
            let alert = this.UI.showAlert("Não foi possível consultar informação de processos. Verifique a ligação");
            alert.finally(() => {
              defer.reject();
            });
          });
        }).catch(() => {
          defer.reject();
        });
      }).catch(() => {
        defer.reject();
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro na consulta de avaliações. Verifique a ligação");
      defer.reject(error);
    });

    return defer.promise;
  };

  // Faturar processos with id in array ids = [{id: ..., estadoId: ...}, ...]
  faturarMultipleProcessos = (ids) => {
    // ONLY ONE SHOULD BE HERE, SINCE WE ARE DOING NUMBERS AND VALUES
    if (ids.length !== 1) {
      this.UI.addToast("Só se pode registar 1 fatura de cada vez");
      return;
    }
    let defer = this.$q.defer();
    let options = {
      size: "lg",
      template: require("./pendentes/directory/faturar.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.title = "Faturação de Processo" + (ids.length !== 1 ? "s" : "");
        $scope.fatura = {};

        $scope.newFileName = '';
        $scope.uuid = this.generateUUID();
        $scope.dragString = "Arrastar o ficheiro da fatura para aqui";
        $scope.isCreatingAnexo = false;
        $scope.hasNumerosInventario = false;

        $scope.uploader = new this.FileUploader({
          url: this.envURL + '/api/Upload/pad/upload',
          queueLimit: 1,
          formData: [{ key: 'value' }]
        });

        // Filtro para verificar se é PDF ou ZIP
        let fileTypeFilter = {
          name: 'verifyPDF_ZIP',
          fn: (item, options) => {
            let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            let ok = '|pdf|zip|'.indexOf(type) !== -1;
            if (!ok) this.UI.addToast("O ficheiro tem que ser no formato PDF ou ZIP");
            return ok;
          }
        };

        let fileSizeFilter = {
          name: 'fileSizeFilter',
          fn: (item) => {
            if (item.size > 52428800) {
              this.UI.addToast("Tamanho máximo para o ficheiro é de 50MB");
            }
            return item.size <= 52428800; // 50 MiB to bytes
          }
        };

        // Inserir filtro
        $scope.uploader.filters.push(fileTypeFilter, fileSizeFilter);

        // Uploader functions
        $scope.uploader.onAfterAddingFile = (item) => {
          let partes = item.file.name.split(".");
          $scope.newFileName = $scope.uuid + "." + partes[partes.length - 1];

          if ($scope.uploader.queue.length > 1)
            $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
        };

        $scope.uploader.onWhenAddingFileFailed = (item) => {
          // ... It's dealt in the filters already
        };

        $scope.uploader.onErrorItem = (response, status, headers) => {
          this.UI.addToast("Erro ao carregar ficheiro. Tente novamente ou verifique o ficheiro");
        };

        $scope.uploader.onBeforeUploadItem = (item) => {
          item.file.name = $scope.newFileName;
        };

        // After file was correctly uploaded
        $scope.uploader.onSuccessItem = (item) => {
          $scope.isCreatingAnexo = true;
          this.PadProcessoAnexo.create({
            id: 0,
            processoId: ids[0].id,
            tipoId: 3, // Fatura
            nome: $scope.newFileName,
            active: 1
          }).$promise.then((f) => {
            $scope.isCreatingAnexo = false;
            $dialog.close($scope);
          }).catch((error) => {
            $scope.isCreatingAnexo = false;
            console.log(error);
            this.UI.addToast("Erro no carregamento do ficheiro. Tente novamente ou verifique ligação");
          });
        };

        $scope.ok = () => {
          // If file was added, add it to anexos
          if ($scope.uploader.queue.length > 0) {
            $scope.uploader.uploadAll();
            // Closing the dialog is done on onSuccessItem()
          } else {
            // If no file was provided, just close it
            $dialog.close($scope);
          }
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((ok) => {
      // Do not ask for confirmation because we uploaded the file already...
      let wait = this.UI.showWaiting();
      // Recheck if all linhas have the same state
      this.PadProcesso.find({
        filter: {
          fields: { id: true, estadoId: true },
          where: {
            and: [{ active: 1 }, { id: { inq: ids.map(x => x.id) } }]
          }
        }
      }).$promise.then((procs) => {
        // Check if all procs exist and estados are the same
        if (procs.length === ids.length && !procs.some(x => x.estadoId !== ids[0].estadoId)) {
          this.PadProcesso.faturar({
            params: {
              ids: ids.map(x => x.id),
              originalState: ids[0].estadoId,
              faturaNumero: ok.fatura.faturaNumero,
              faturaTotal: ok.fatura.faturaTotal,
              faturaObservacoes: ok.fatura.faturaObservacoes || null,
              numerosInventario: ok.hasNumerosInventario ? ok.fatura.numerosInventario : null
            }
          }).$promise.then(res => {
            wait.close();
            let errors = res.status.filter(x => x.error);
            if (errors.length > 0) {
              let title = `Erros de registo de fatura`;
              let introText = "Ocorreram os seguintes erros:";
              let instance = this.UI.showDialog({
                size: 'md',
                template: require('../interface/modals/show-list.html'),
                controller: ['$scope', ($scope) => {
                  $scope.title = title;
                  $scope.introText = introText;
                  $scope.list = errors;
                  $scope.ok = function () {
                    $scope.$close();
                  };
                }]
              });
              instance.finally(() => {
                defer.resolve();
              })
            } else {
              this.UI.addToast(`Registo de Fatura foi realizado com sucesso`);
              defer.resolve();
            }
          }).catch(error => {
            console.log(error);
            wait.close();
            let alert = this.UI.showAlert("Ocorreu um erro nos dados enviados à plataforma. Por favor tente novamente.");
            alert.finally(() => {
              defer.reject();
            });
          });
        } else {
          wait.close();
          let alert = this.UI.showAlert("O estado do processo foi entretanto alterado. Por favor tente novamente.");
          alert.finally(() => {
            defer.reject();
          });
        }
      }).catch((error) => {
        wait.close();
        console.log(error);
        let alert = this.UI.showAlert("Não foi possível consultar informação do processo. Verifique a ligação");
        alert.finally(() => {
          defer.reject();
        });
      });
    }).catch(() => {
      defer.reject();
    });

    return defer.promise;
  };

  validateFornecedores = (fornecedores) => {
    let multiDefer = this.$q.defer();

    this.PadConfig.findOne({
    }).$promise.then((config) => {
      let tasks = [];
      for (let i = 0; i < fornecedores.length; i++) {
        let defer = this.$q.defer();

        // Check if anexo is mandatory for the value of proposta or if the file is supposed to exist
        if (fornecedores[i].total > config.valorAprovacaoNivel3 || fornecedores[i].uuid.length > 0) {
          // Check if file exists
          this.PadAnexoPendente.findOne({
            filter: {
              where: {
                and: [{ active: 1 }, { userId: this.user.id }, { uuid: fornecedores[i].uuid }]
              }
            }
          }).$promise.then((pendente) => {
            this.$http.get(this.envURL + "/api/upload/pad/files/" + fornecedores[i].uuid).then((result) => {
              if (result.data != null && !result.data.hasOwnProperty("error")) {
                defer.resolve();
              } else {
                this.UI.addToast("Erro de Fornecedor (" + (i + 1) + ") :" + fornecedores[i].fornecedorPrimavera.nome + ": Ficheiro de Proposta inválido");
                defer.reject();
              }
            }, (err) => {
              console.log(err);
              this.UI.addToast("Erro de verificação de proposta de Fornecedor (" + (i + 1) + "). Verifique a ligação ou resubmeta o ficheiro");
              defer.reject();
            });
          }).catch((error) => {
            if (error.status === 404) {
              // If not found, check if it's mandatory or not
              if (fornecedores[i].total > config.valorAprovacaoNivel3) {
                this.UI.addToast("Proposta " + (i + 1) + " para Fornecedor " + fornecedores[i].fornecedorPrimavera.nome + " não encontrada. Resubmeta-a");
                console.log(error);
                defer.reject();
              } else {
                // If we have not found one, but one is not necessary due to total
                defer.resolve();
              }
            } else {
              this.UI.addToast("Erro de verificação de Fornecedor. Verifique a ligação");
              console.log(error);
              defer.reject();
            }
          });
        } else {
          defer.resolve();
        }
        tasks.push(defer.promise);
      }

      this.$q.all(tasks).then(x => {
        multiDefer.resolve(tasks);
      }).catch(err => {
        multiDefer.reject(err);
      });

    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro na obtenção de dados de configuração PAD");
      multiDefer.reject(error);
    });

    return multiDefer.promise;
  };

  // Cancel processos with id in array ids = [{id: ..., estadoId: ...}, ...]
  cancelMultipleProcessos = (ids) => {
    let defer = this.$q.defer();
    let options = {
      size: "lg",
      template: require("./pendentes/directory/cancel.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.title = "Cancelamento de Processo" + (ids.length !== 1 ? "s" : "");

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((ok) => {
      let processCountString = ids.length > 1 ? `estes ${ids.length} processos` : `este processo`;
      let evaluateStrings = ["anular", "Anulamento", "anulado"];
      let warning = `Tem a certeza que pretende ${evaluateStrings[0]} ${processCountString}? Esta decisão não pode ser alterada.`;
      let result = this.UI.showConfirm(warning);
      result.then(() => {
        // Recheck if all processos have the same state and are from the user
        this.PadProcesso.find({
          filter: {
            fields: { id: true, estadoId: true },
            where: {
              and: [{ active: 1 }, { id: { inq: ids.map(x => x.id) } }, { criadoPorId: this.user.id }]
            }
          }
        }).$promise.then((procs) => {
          // Check if all procs exist and estados are the same (< Adjudicado)
          if (procs.length === ids.length && !procs.some(x => x.estadoId !== ids[0].estadoId) && ids[0].estadoId < 12) {
            let wait = this.UI.showWaiting();
            this.PadProcesso.cancel({
              params: {
                ids: ids.map(x => x.id),
                originalState: ids[0].estadoId,
                observacoes: ok.observacoes
              }
            }).$promise.then(res => {
              wait.close();
              let errors = res.status.filter(x => x.error);
              if (errors.length > 0) {
                let title = `Erros de ${evaluateStrings[1]}`;
                let introText = "Ocorreram os seguintes erros:";
                let instance = this.UI.showDialog({
                  size: 'md',
                  template: require('../interface/modals/show-list.html'),
                  controller: ['$scope', ($scope) => {
                    $scope.title = title;
                    $scope.introText = introText;
                    $scope.list = errors;
                    $scope.ok = function () {
                      $scope.$close();
                    };
                  }]
                });
                instance.finally(() => {
                  defer.resolve();
                })
              } else {
                if (ids.length === 1)
                  this.UI.addToast(`Processo ${evaluateStrings[2]} com sucesso`);
                else
                  this.UI.addToast(`Processos ${evaluateStrings[2]}s com sucesso`);
                defer.resolve();
              }
            }).catch(error => {
              console.log(error);
              wait.close();
              let alert = this.UI.showAlert("Ocorreu um erro nos dados enviados à plataforma. Por favor tente novamente.");
              alert.finally(() => {
                defer.reject();
              });
            });
          } else {
            let alert = this.UI.showAlert("Houve pelo menos um processo que não pode ser anulado devido a um dos seguintes fatores: Não foi criado por si; o seu estado foi entretanto alterado; não se encontra num estado anulável. Por favor tente novamente.");
            alert.finally(() => {
              defer.reject();
            });
          }
        }).catch((error) => {
          console.log(error);
          let alert = this.UI.showAlert("Não foi possível consultar informação de processos. Verifique a ligação");
          alert.finally(() => {
            defer.reject();
          });
        });
      }).catch(() => {
        defer.reject();
      });
    }).catch(() => {
      defer.reject();
    });
    return defer.promise;
  };


  // True if a process can be evaluated
  canEvaluate = (processo) => {
    if (processo.estadoId === 2) {
      return processo.nivel1AvaliadoPorId == null;
    } else {
      return (processo.estadoId > 2 && processo.estadoId < 6);
    }
  };

  canNegotiate = (processo) => (processo.estadoId === 2 && processo.nivel1AvaliadoPorId != null);

  // True if a process can be validated
  canValidate = (processo) => processo.estadoId === 12;

  // True if a process can be validated
  canEvaluateFornecedor = (processo) => processo.estadoId === 14;

  // True if a process can be faturado
  canFaturar = (processo) => processo.estadoId === 15;

  // True if a process can be cancelled
  canCancel = (processo) => processo && (processo.estadoId < 12 && processo.criadoPorId === this.user.id);

}

PadService.$inject = ['$rootScope', '$q', '$http', 'UIService', 'FileUploader', 'AuthenticationService', 'PadLinhaProcesso', 'PadLinhaProcessoEstado', 'PadProcessoEstado', 'PadConfig', 'PadArtigo', 'PadProjeto', 'PadProcesso', 'PadProcessoAnexo', 'PadProcessoFornecedor', 'PadProcessoFornecedorAnexo', 'AtvFornecedor', 'AtvFornecedorPrimavera', 'PadCriterio', 'PadCondicaoPagamento', 'PadAnexoPendente', 'PadAvaliacao', 'PadCentroCusto', 'PRIIEPArtigo', 'PRIAJLArtigo', 'PRIOBLERArtigo', 'PRIIEPFornecedores', 'PRIAJLFornecedores', 'PRIOBLERFornecedores', 'PRIIEPCondpag', 'PRIAJLCondpag', 'PRIOBLERCondpag', 'PRIIEPPlanocontas', 'PRIAJLPlanocontas', 'PRIOBLERPlanocontas'];
