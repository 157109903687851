export default class FuncionaAdminController {
  constructor($q, $state, UIService, Funcionario, FunTecnico, FunTipoServico, FunLote) {
    this.$q = $q;
    this.$state = $state;
    this.UI = UIService;
    this.Funcionario = Funcionario;
    this.FunTecnico = FunTecnico;
    this.FunTipoServico = FunTipoServico;
    this.FunLote = FunLote;

    this.showTecnicosFab = true;
    this.showTiposServicoFab = false;

    this.optTecnicos = {
      model: this.FunTecnico,
      type: 'FunTecnicos',
      // Total from the db for the query
      total: 0,
      // Order of the first item shown
      start: 0,
      // Order of the last item shown
      end: 0,
      // Current "page" to view
      currentPage: 0,
      // Number of items to show at the same time. Return this value from db query
      perPage: 100,
      // Choices for number of items per page
      numberItemChoices: [10, 50, 100],
      // While data from db is loading
      loaded: true,
      // Default order of data
      order: [{id: "ASC"}],
      // Filters always applied to data (ex. active:1) Always assumes that we are using AND fields
      defaultFilter: [{active: 1}],
      // Applied filters to data (filterLayout, what the user types, filter what we actually search for
      filterLayout: {},
      // Include data in main data
      include: ['funcionario'],
      // Error
      error: null
    };

    this.optTiposServico = {
      model: this.FunTipoServico,
      type: 'FunTiposServico',
      // Total from the db for the query
      total: 0,
      // Order of the first item shown
      start: 0,
      // Order of the last item shown
      end: 0,
      // Current "page" to view
      currentPage: 0,
      // Number of items to show at the same time. Return this value from db query
      perPage: 100,
      // Choices for number of items per page
      numberItemChoices: [10, 50, 100],
      // While data from db is loading
      loaded: true,
      // Default order of data
      order: [{designacao: "ASC"}],
      // Filters always applied to data (ex. active:1) Always assumes that we are using AND fields
      defaultFilter: [{active: 1}],
      // Applied filters to data (filterLayout, what the user types, filter what we actually search for
      filterLayout: {},
      // Include data in main data
      include: [],
      // Error
      error: null
    };

    this.optLotes = {
      model: this.FunLote,
      type: 'FunLotes',
      // Total from the db for the query
      total: 0,
      // Order of the first item shown
      start: 0,
      // Order of the last item shown
      end: 0,
      // Current "page" to view
      currentPage: 0,
      // Number of items to show at the same time. Return this value from db query
      perPage: 100,
      // Choices for number of items per page
      numberItemChoices: [10, 50, 100],
      // While data from db is loading
      loaded: true,
      // Default order of data
      order: [{lote: "ASC", distrito: "ASC"}],
      // Filters always applied to data (ex. active:1) Always assumes that we are using AND fields
      defaultFilter: [{active: 1}],
      // Applied filters to data (filterLayout, what the user types, filter what we actually search for
      filterLayout: {},
      // Include data in main data
      include: [],
      // Error
      error: null
    };

    this.onFilter(this.optTecnicos, false);
    this.onFilter(this.optTiposServico, false);
    this.onFilter(this.optLotes, false);
  }

  configLoading = () => !this.optTecnicos.loaded && !this.optTiposServico.loaded && !this.optLotes.loaded;

  updateFab = (f) => {
    if (f === 'Tecnicos') { // Técnicos
      this.showTecnicosFab = true;
      this.showTiposServicoFab = false;
    } else if (f === 'TiposServico') { // Tipos de Serviço
      this.showTecnicosFab = false;
      this.showTiposServicoFab = true;
    } else if (f === 'Lotes') { // Lotes
      this.showTecnicosFab = false;
      this.showTiposServicoFab = false;
    }
  };

  // When user chooses number of items to see in the list
  onNumberItems = (opt, items) => {
    opt.perPage = items;
    this.onFilter(opt, false);
  };

  // Returns an object with the data for filter to be applied for fields to search
  parseFilterLayout = (opt) => {
    let filter = {};

    ///////////////////////////////////////////////////
    // Has to be adapted case to case on copy elsewhere. Probably only one type is necessary
    ///////////////////////////////////////////////////
    switch(opt.type) {
      case 'FunTecnicos':
        Object.keys(opt.filterLayout).forEach(k => {
          if (!_.isEmpty(opt.filterLayout[k])) {
            filter[k] = {like: '%' + opt.filterLayout[k].replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + '%'};
          }
        });
        break;
      case 'FunTiposServico':
        Object.keys(opt.filterLayout).forEach(k => {
          if (!_.isEmpty(opt.filterLayout[k])) {
            filter[k] = {like: '%' + opt.filterLayout[k].replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + '%'};
          }
        });
        break;
      case 'FunLotes':
        Object.keys(opt.filterLayout).forEach(k => {
          if (!_.isEmpty(opt.filterLayout[k])) {
            filter[k] = {like: '%' + opt.filterLayout[k].replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + '%'};
          }
        });
        break;
      default:
        Object.keys(opt.filterLayout).forEach(k => {
          if (!_.isEmpty(opt.filterLayout[k])) {
            filter[k] = {like: '%' + opt.filterLayout[k].replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + '%'};
          }
        });
        break;
    }
    return filter;
  };

  // When user clicks on column header to sort
  onSorting = (opt, field) => {
    // If not sorting by the field
    let f = opt.order.find(x => Object.keys(x)[0] === field);
    if (!f) {
      f = {};
      opt.order = [];
      f[field] = 'ASC';
      opt.order.push(f);
    } else { // If sorting by, change order
      f[field] = f[field] === 'ASC' ? 'DESC' : 'ASC';
    }
    this.onFilter(opt, false);
  };

  // Returns true/false if sorting by field
  isSortingBy = (opt, field) => {
    return opt.order.find(x => Object.keys(x)[0] === field);
  };

  // Returns true/false if it's sorting by type ('ASC'/'DESC')
  isSortingType = (opt, field, type) => {
    let f = opt.order.find(x => Object.keys(x)[0] === field);
    if (!f) return false;
    else return f[field] === type;
  };

  onFilter = (opt, pagination) => {
    opt.loaded = false;
    // So we always keep a copy of the original for next time
    let defaultFilter = angular.copy(opt.defaultFilter);

    // By default only search active data
    let searchObject = {and: defaultFilter};
    // Add filters to search
    Object.keys(opt.filterLayout).forEach(k => {
      if (k.value) {
        searchObject.and.push(k);
      }
    });

    // Parse filters done by the user
    let userFilters = this.parseFilterLayout(opt);
    Object.keys(userFilters).forEach(k => {
      let newFilter = {};
      newFilter[k] = userFilters[k];
      searchObject.and.push(newFilter);
    });

    let itemsToSkip = 0;
    // If we have pagination, we have to skip appropriately
    if (pagination) {
      itemsToSkip = opt.currentPage * opt.perPage; //
    } else {
      opt.currentPage = 0; // Reset to 1st page
    }

    // Deal with order
    let order = '';
    opt.order.forEach((o,i) => {
      let key = Object.keys(o)[0]; // The key to sort by
      if (i === 0) order += key + " " + o[key];
      else order += ', ' + key + " " + o[key];
    });

    opt.model.count({
      fields: {id: true},
      where: searchObject
    }).$promise.then((total) => {
      opt.total = total.count;

      // Do the search
      opt.model.find({
        filter: {
          where: searchObject,
          order: order,
          limit: opt.perPage,
          skip: itemsToSkip,
          include: opt.include
        }
      }).$promise.then((res) => {
        opt.data = res;
        opt.start = opt.total === 0 ? 0 : opt.currentPage * opt.perPage + 1;
        opt.end = opt.total === 0 ? 0: opt.currentPage * opt.perPage + opt.data.length;
        opt.loaded = true;

      }).catch((error) => {
        console.log(error);
        opt.error = error;
        opt.currentPage = 0;
        opt.start = 0;
        opt.end = 0;
        opt.data = null;
        opt.total = 0;
        opt.loaded = true;
      });
    }).catch((error) => {
      console.log(error);
      opt.error = error;
      opt.currentPage = 0;
      opt.start = 0;
      opt.end = 0;
      opt.data = null;
      opt.total = 0;
      opt.loaded = true;
    });
  };

  previous = (opt) => {
    if(opt.currentPage > 0) {
      opt.currentPage--;
      this.onFilter(opt, true);
    }
  };

  next = (opt) => {
    if (opt.currentPage < this.totalPages(opt) - 1) {
      opt.currentPage++;
      this.onFilter(opt, true);
    }
  };

  totalPages = (opt) => {
    return Math.ceil(opt.total / opt.perPage);
  };

  // Técnicos
  addTecnico = () => {
    let wait = this.UI.showWaiting();
    this.Funcionario.find({
      filter: {
        where: {
          active: true
        },
        order: 'name ASC'
      }
    }).$promise.then((funcionarios) => {
      funcionarios.sort((a, b) => a.name.localeCompare(b.name));
      wait.close();
      let options = {
        template: require('./edit.tecnicos.dialog.html'),
        controller: ["$scope", "$dialog", ($scope, $dialog) => {
          $scope.label = "Adicionar Código de Técnico";
          $scope.isEditing = false;
          $scope.tecnico = {};

          $scope.funcionarios = funcionarios;

          $scope.auxTecnico = {
            selected: undefined,
            infiniteScroll: {numToAdd: 20, currentItems: 20}
          };

          // Infinite Scroll magic
          $scope.addMoreItems = (infiniteScroll) => {
            infiniteScroll.currentItems += infiniteScroll.numToAdd;
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss("cancel");
          };
        }]
      };
      let modal = this.UI.showDialog(options);

      modal.then(ok => {
        if (ok.auxTecnico.selected) {
          this.optTecnicos.loaded = false;
          // Set code to uppercase by default
          ok.tecnico.codigo = ok.tecnico.codigo.trim().toUpperCase();

          // Check if codigo or funcionario already exist
          this.FunTecnico.find({
            filter: {
              where: {
                and: [{active: 1}, {or: [{funcionarioId: ok.auxTecnico.selected.id}, {codigo: ok.tecnico.codigo}]}]
              }
            }
          }).$promise.then((t) => {
            if (t.length > 0) {
              let alert = this.UI.showAlert("Código / Técnico introduzido já se encontra registado. Verifique os dados e tente novamente");
              alert.finally(() => {
                this.onFilter(this.optTecnicos, false);
              });
            } else {
              this.FunTecnico.create({
                id: 0,
                funcionarioId: ok.auxTecnico.selected.id,
                codigo: ok.tecnico.codigo,
                active: 1
              }).$promise.then(() => {
                this.UI.addToast("Código de Técnico criado com sucesso.");
                this.onFilter(this.optTecnicos, false);
              }).catch(err => {
                console.log(err);
                this.UI.addToast("Erro na criação de Código de Técnico. Verifique a ligação.");
                this.onFilter(this.optTecnicos, false);
              });
            }
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro na procura de Códigos de Técnicos. Verifique a ligação.");
            this.onFilter(this.optTecnicos, false);
          });
        }
      }).catch(() => {
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro na procura de Colaboradores. Verifique a ligação.");
    });
  };

  editTecnico = (t) => {
    let options = {
      size: 'md',
      template: require('./edit.tecnicos.dialog.html'),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.label = "Editar Código de Técnico";
        $scope.isEditing = true;
        $scope.tecnico = angular.copy(t);
        $scope.nomeTecnico = t.funcionario.name;

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };
      }]
    };
    let modal = this.UI.showDialog(options);

    modal.then(ok => {
      this.optTecnicos.loaded = false;
      // Set code to uppercase by default
      ok.tecnico.codigo = ok.tecnico.codigo.trim().toUpperCase();
      // Check if codigo or funcionario already exist
      this.FunTecnico.find({
        filter: {
          where: {
            and: [{id: {neq: ok.tecnico.id}}, {active: 1}, {codigo: ok.tecnico.codigo}]
          }
        }
      }).$promise.then((tecnicos) => {
        if (tecnicos.length > 0) {
          let alert = this.UI.showAlert("Código introduzido já se encontra registado. Verifique os dados e tente novamente");
          alert.finally(() => {
            this.onFilter(this.optTecnicos, false);
          });
        } else {
          // Update codigo to tecnico
          this.FunTecnico.prototype$updateAttributes({ id: t.id }, {codigo: ok.tecnico.codigo}).$promise.then(() => {
            this.UI.addToast("Código de Técnico atualizado com sucesso");
            this.onFilter(this.optTecnicos, false);
          }).catch(error => {
            console.log(error);
            this.UI.addToast("Erro na alteração de Código de Técnico. Verifique a ligação.");
            this.onFilter(this.optTecnicos, false);
          });
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro na procura de Códigos de Técnicos. Verifique a ligação.");
        this.onFilter(this.optTecnicos, false);
      });
    }).catch(() => {
    });
  };

  confirmDeleteTecnico = (i) => {
    let title = "Eliminar Código de Técnico";
    let warning = "Tem a certeza que pretende remover o código deste técnico?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeTecnico(i);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeTecnico = (t) => {
    this.optTecnicos.loaded = false;
    // Find again parte do corpo to delete so we can update it
    this.FunTecnico.findOne({
      filter: {
        where: {
          id: t.id,
          active: 1
        }
      }
    }).$promise.then((tecnico) => {
      tecnico.active = 0;
      tecnico.$save().then((res) => {
        this.UI.addToast("Código de Técnico eliminado com sucesso");
        this.onFilter(this.optTecnicos, false);
      }, (error) => {
        console.log(error);
        this.UI.addToast("Erro de eliminação. Verifique a ligação.");
        this.onFilter(this.optTecnicos, false);
      });
    }).catch((error) => {
      console.log(error);
      this.onFilter(this.optTecnicos, false);
      this.UI.addToast("Não foi possível eliminar código de técnico. Verifique se ainda existe.");
    });
  };

  // Tipos de Serviço
  addTipoServico = () => {
    let options = {
      template: require('./edit.tipo.servico.dialog.html'),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.label = "Adicionar Tipo de Serviço";
        $scope.isEditing = false;
        $scope.tipoServico = {};

        $scope.ok = () => {
          if (!$scope.tipoServico.designacao
            || isNaN($scope.tipoServico.preco1) || $scope.tipoServico.preco1 < 0
            || isNaN($scope.tipoServico.preco2) || $scope.tipoServico.preco2 < 0
            || isNaN($scope.tipoServico.preco3) || $scope.tipoServico.preco3 < 0
            || isNaN($scope.tipoServico.preco4) || $scope.tipoServico.preco4 < 0) {
            this.UI.addToast("Dados inválidos para tipo de serviço");
          } else {
            $dialog.close($scope);
          }
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };
      }]
    };
    let modal = this.UI.showDialog(options);

    modal.then(ok => {
      this.optTiposServico.loaded = false;
      // Set code to uppercase by default
      ok.tipoServico.designacao = ok.tipoServico.designacao.trim().toUpperCase();

      // Check if tipo servico with designacao already exists
      this.FunTipoServico.find({
        filter: {
          where: {
            and: [{active: 1}, {designacao: ok.tipoServico.designacao}]
          }
        }
      }).$promise.then((t) => {
        if (t.length > 0) {
          let alert = this.UI.showAlert("Tipo de Serviço introduzido já se encontra registado. Verifique os dados e tente novamente");
          alert.finally(() => {
            this.onFilter(this.optTiposServico, false);
          });
        } else {
          this.FunTipoServico.create({
            id: 0,
            designacao: ok.tipoServico.designacao,
            preco1: ok.tipoServico.preco1,
            preco2: ok.tipoServico.preco2,
            preco3: ok.tipoServico.preco3,
            preco4: ok.tipoServico.preco4,
            active: 1
          }).$promise.then(() => {
            this.UI.addToast("Tipo de Serviço criado com sucesso");
            this.onFilter(this.optTiposServico, false);
          }).catch(err => {
            console.log(err);
            this.UI.addToast("Erro na criação de Tipo de Serviço. Verifique a ligação.");
            this.onFilter(this.optTiposServico, false);
          });
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro na procura de Tipos de Serviço. Verifique a ligação.");
        this.onFilter(this.optTiposServico, false);
      });
    }).catch(() => {
    });
  };

  editTipoServico = (t) => {
    let options = {
      template: require('./edit.tipo.servico.dialog.html'),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.label = "Editar Tipo de Serviço";
        $scope.isEditing = true;
        $scope.tipoServico = angular.copy(t);

        $scope.ok = () => {
          if (!$scope.tipoServico.designacao
            || isNaN($scope.tipoServico.preco1) || $scope.tipoServico.preco1 < 0
            || isNaN($scope.tipoServico.preco2) || $scope.tipoServico.preco2 < 0
            || isNaN($scope.tipoServico.preco3) || $scope.tipoServico.preco3 < 0
            || isNaN($scope.tipoServico.preco4) || $scope.tipoServico.preco4 < 0) {
            this.UI.addToast("Dados inválidos para Tipo de Serviço");
          } else {
            $dialog.close($scope);
          }
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };
      }]
    };
    let modal = this.UI.showDialog(options);

    modal.then(ok => {
      this.optTiposServico.loaded = false;
      // Set code to uppercase by default
      ok.tipoServico.designacao = ok.tipoServico.designacao.trim().toUpperCase();

      // Check if tipo servico with designacao already exists
      this.FunTipoServico.find({
        filter: {
          where: {
            and: [{active: 1}, {id: {neq: t.id}}, {designacao: ok.tipoServico.designacao}]
          }
        }
      }).$promise.then((t) => {
        if (t.length > 0) {
          let alert = this.UI.showAlert("Tipo de Serviço introduzido já se encontra registado. Verifique os dados e tente novamente");
          alert.finally(() => {
            this.onFilter(this.optTiposServico, false);
          });
        } else {
          this.FunTipoServico.upsert(ok.tipoServico).$promise.then(() => {
            this.UI.addToast("Tipo de Serviço editado com sucesso");
            this.onFilter(this.optTiposServico, false);
          }).catch(err => {
            console.log(err);
            this.UI.addToast("Erro na edição de Tipo de Serviço. Verifique a ligação.");
            this.onFilter(this.optTiposServico, false);
          });
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro na procura de Tipos de Serviço. Verifique a ligação.");
        this.onFilter(this.optTiposServico, false);
      });
    }).catch(() => {
    });
  };

  confirmDeleteTipoServico = (i) => {
    let title = "Eliminar Tipo de Serviço";
    let warning = "Tem a certeza que pretende remover o Tipo de Serviço?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeTipoServico(i);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeTipoServico = (t) => {
    this.optTiposServico.loaded = false;
    // Find again parte do corpo to delete so we can update it
    this.FunTipoServico.findOne({
      filter: {
        where: {
          id: t.id,
          active: 1
        }
      }
    }).$promise.then((tipoServico) => {
      tipoServico.active = 0;
      tipoServico.$save().then((res) => {
        this.UI.addToast("Tipo de Serviço eliminado com sucesso");
        this.onFilter(this.optTiposServico, false);
      }, (error) => {
        console.log(error);
        this.UI.addToast("Erro de eliminação. Verifique a ligação.");
        this.onFilter(this.optTiposServico, false);
      });
    }).catch((error) => {
      console.log(error);
      this.onFilter(this.optTiposServico, false);
      this.UI.addToast("Não foi possível eliminar tipo de serviço. Verifique se ainda existe.");
    });
  };

}

FuncionaAdminController.$inject = ['$q', '$state', 'UIService', 'Funcionario', 'FunTecnico', 'FunTipoServico', 'FunLote'];
