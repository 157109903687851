import moment from 'moment';
export default class OblerRevampService {
    $q;
    OblProcesso;
    OblAgendamento;
    OblProduto;
    OblProcessoOrcamentacao;
    OblProcessoFotografia;
    OblAlteracaoestado;
    FuncionarioEntidadeProprietaria;
    AuthenticationService;
    constructor($q, OblProcesso, OblAgendamento, OblProduto, OblProcessoOrcamentacao, OblProcessoFotografia, OblAlteracaoestado, FuncionarioEntidadeProprietaria, AuthenticationService) {
        this.$q = $q;
        this.OblProcesso = OblProcesso;
        this.OblAgendamento = OblAgendamento;
        this.OblProduto = OblProduto;
        this.OblProcessoOrcamentacao = OblProcessoOrcamentacao;
        this.OblProcessoFotografia = OblProcessoFotografia;
        this.OblAlteracaoestado = OblAlteracaoestado;
        this.FuncionarioEntidadeProprietaria = FuncionarioEntidadeProprietaria;
        this.AuthenticationService = AuthenticationService;
    }
    changeState = (oi, oldState, newState, notes) => {
        return this.OblAlteracaoestado.create({
            id: 0,
            oiId: oi,
            estadoInicial: oldState,
            estadoFinal: newState,
            dataAlteracao: moment.utc(),
            funcionarioId: this.AuthenticationService.getId(),
            observacoes: notes || ''
        }).$promise;
    };
    findOrcamentos(query) {
        return this.OblProcesso.table(query).$promise;
    }
    createProcessoWithAgendamento(data, dataAgendamento, tecnicoId) {
        data.criadoPor = this.AuthenticationService.getId();
        const defer = this.$q.defer();
        this.OblProcesso.create(data).$promise.then(r => {
            this.OblAgendamento.create({
                id: 0,
                active: 1,
                tecnicoId: tecnicoId,
                agendadoa: moment().format(),
                agendadoporId: data.criadoPor,
                processoId: r.id,
                data: dataAgendamento.format('YYYY-MM-DD'),
                hora: dataAgendamento.format('HH:mm'),
                duracao: 60
            }).$promise.then(a => {
                defer.resolve(a);
            });
        });
        return defer.promise;
    }
    findTecnicos(date) {
        const defer = this.$q.defer();
        this.FuncionarioEntidadeProprietaria.find({
            filter: {
                where: {
                    entidadeProprietariaId: 3,
                    active: true
                },
                include: {
                    relation: 'funcionario',
                    scope: {
                        include: {
                            relation: 'ausencias',
                            scope: {
                                where: {
                                    dataInicio: {
                                        lte: date.toISOString()
                                    },
                                    dataFim: {
                                        gte: date.toISOString()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
            .$promise.then(f => {
            const users = [];
            f.forEach(r => {
                if (r.funcionario) {
                    if (r.funcionario.ausencias.length == 0) {
                        r.funcionario.disabled = false;
                        users.push(r.funcionario);
                    }
                }
            });
            defer.resolve(users.sort((a, b) => a.name.localeCompare(b.name)));
        })
            .catch(e => {
            defer.reject(e);
        });
        return defer.promise;
    }
    options() {
        return this.OblProcesso.options().$promise;
    }
    patch(id, partial) {
        return this.OblProcesso.patch({ id }, partial).$promise;
    }
    getOrcamento(id) {
        return this.OblProcesso.findOne({
            filter: {
                where: {
                    id
                },
                include: [
                    {
                        relation: 'Ordemintervencao',
                        scope: {
                            include: 'Valencia'
                        }
                    },
                    {
                        relation: 'Agendamento',
                        scope: {
                            include: 'Funcionario'
                        }
                    }
                ]
            }
        }).$promise;
    }
    getFotografias(id) {
        return this.OblProcessoFotografia.find({
            filter: {
                where: {
                    active: 1,
                    processoId: id
                }
            }
        }).$promise;
    }
    getOrcamentoMateriais(id) {
        return this.OblProcessoOrcamentacao.find({
            filter: {
                where: {
                    active: 1,
                    processoId: id
                },
                include: ['RececionadoPor']
            }
        }).$promise;
    }
    autoComplete(field, value) {
        const where = {};
        where[field] = {
            like: `%${value}%`
        };
        return this.OblProduto.find({
            filter: {
                where: where,
                limit: 250
            }
        }).$promise;
    }
}
OblerRevampService.$inject = [
    '$q',
    'OblProcesso',
    'OblAgendamento',
    'OblProduto',
    'OblProcessoOrcamentacao',
    'OblProcessoFotografia',
    'OblAlteracaoestado',
    'FuncionarioEntidadeProprietaria',
    'AuthenticationService'
];
