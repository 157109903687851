export default class SusEneListInterventionController {
  constructor($q, $state, SusEneOrdemintervencao, SusEneCliente, UIService, SusEneProcessoService) {
    this.$q = $q;
    this.$state = $state;
    this.SusEneOrdemintervencao = SusEneOrdemintervencao;
    this.SusEneCliente = SusEneCliente;
    this.UI = UIService;
    this.SusEneProcessoService = SusEneProcessoService;

    // If loadData() finished or not
    this.dataLoaded = false;

    this.clientes = [];
    this.simnao = [{ name: "Sim", value: 1 }, { name: "Não", value: 0 }];
    this.filtersLoaded = [0, 1];

    this.opt = $state.params;

    // Total processes for currently being displayed
    this.total = 0;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [
      {
        displayName: 'ID SGI',
        name: 'id',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Documento',
        name: 'no',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Ano',
        name: 'ano',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Cliente',
        name: 'cliente',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Email',
        name: 'email',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Enq. de Contrato?',
        name: 'contrato',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Data de Receção',
        name: 'dataRececao',
        exportDate: 'DD-MM-YYYY',
        visible: true
      },
      {
        displayName: 'Observações',
        name: 'observacoes',
        visible: false,
        sortable: true
      }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: 'SusEneOrdemintervencao.id',
        name: 'ID SGI',
        type: 'o'
      },
      {
        id: 'SusEneOrdemintervencao.no',
        name: 'Documento',
        type: 'o'
      },
      {
        id: 'SusEneOrdemintervencao.ano',
        name: 'Ano',
        type: 'o'
      },
      {
        id: 'SusEneOrdemintervencao.clienteId',
        name: 'Cliente',
        type: 's',
        list: this.clientes
      },
      {
        id: 'SusEneOrdemintervencao.email',
        name: 'Email',
        type: 'o'
      },
      {
        id: 'SusEneOrdemintervencao.contrato',
        name: 'Enq. de Contrato?',
        type: 's',
        list: this.simnao
      },
      {
        id: 'SusEneOrdemintervencao.dataRececao',
        name: 'Data de Receção',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'SusEneOrdemintervencao.observacoes',
        name: 'Observações',
        type: 'o'
      }
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('SUSENEOrdensIntervencaoDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('SUSENEOrdensIntervencaoDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('SUSENEOrdensIntervencaoFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('SUSENEOrdensIntervencaoFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('SUSENEOrdensIntervencaoFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'SusEneOrdemintervencao.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'SusEneOrdemintervencao.contrato')
          f.column.selected.list = this.simnao;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getOrdensIntervencao();
  };

  clearFilter = () => {
    localStorage.removeItem('SUSENEOrdensIntervencaoFilter');
    this.customFilters = [];
    this.getOrdensIntervencao();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  getOrdensIntervencao = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {
      'SusEneOrdemintervencao.active': 1
    });

    this.SusEneOrdemintervencao.table({
      params: {
        fields: [
          'SusEneOrdemintervencao.id as id',
          'SusEneOrdemintervencao.no as no',
          'SusEneOrdemintervencao.ano as ano',
          'SusEneOrdemintervencao.dataRececao as dataRececao',
          'SusEneOrdemintervencao.contrato as contrato',
          'SusEneOrdemintervencao.observacoes as observacoes',
          'SusEneOrdemintervencao.email as email',
          'SusEneCliente.nome as cliente'
        ],
        from: ['SusEneOrdemintervencao', 'SusEneCliente'],
        referencesOrigin: [],
        references: [undefined, 'SusEneOrdemintervencao.clienteId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      // res.data.forEach(r => {
      //   let tmp = r.tipo === "Metrologia" ? 'M' : 'E';
      //   r.processo = `${tmp}-${r.iidAno}-${("0000" + r.iidProc).slice(-4)}`;
      // });

      this.ois = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível obter OIs. Verifique a ligação");
    })
  };

  exportOIs = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, { 'SusEneOrdemintervencao.active': 1 });
    this.SusEneOrdemintervencao.exportOIs({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'SusEneOrdemintervencao.id as id',
          'SusEneOrdemintervencao.no as no',
          'SusEneOrdemintervencao.ano as ano',
          'SusEneOrdemintervencao.contrato as contrato',
          'SusEneOrdemintervencao.observacoes as observacoes',
          'SusEneOrdemintervencao.email as email',
          'SusEneOrdemintervencao.dataRececao as dataRececao',
          'SusEneCliente.nome as cliente'
        ],
        from: ['SusEneOrdemintervencao', 'SusEneCliente'],
        referencesOrigin: [],
        references: [undefined, 'SusEneOrdemintervencao.clienteId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'OIsExportadas.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar OIs. Por favor tente mais tarde.");
    });
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.ois, x => x.selected), x => {
        return { key: 'SusEneOrdemintervencao.id', value: x.id };
      });
    let whereFields = this.setWhereFields(this.customFilters, { 'SusEneOrdemintervencao.active': 1 }, orsLiteral);
    this.SusEneOrdemintervencao.exportOIs({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'SusEneOrdemintervencao.id as id',
          'SusEneOrdemintervencao.no as no',
          'SusEneOrdemintervencao.ano as ano',
          'SusEneOrdemintervencao.contrato as contrato',
          'SusEneOrdemintervencao.observacoes as observacoes',
          'SusEneOrdemintervencao.email as email',
          'SusEneOrdemintervencao.dataRececao as dataRececao',
          'SusEneCliente.nome as cliente'
        ],
        from: ['SusEneOrdemintervencao', 'SusEneCliente'],
        referencesOrigin: [],
        references: [undefined, 'SusEneOrdemintervencao.clienteId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'OIsExportadas.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar OIs. Por favor tente mais tarde.");
    });
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('SUSENEOrdensIntervencaoDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  loadData = () => {
    this.SusEneCliente.find({filter: {where: {active: true}, order: 'nome ASC'}}).$promise.then(clientes => {
      clientes.forEach(t => {
        t.name = t.nome;
      });
      this.clientes = clientes;
      this.filtersLoaded[0] = 1;
    }).catch(e => {
      console.log(e);
      this.clientes = [];
      this.filtersLoaded[0] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Clientes).");
    });
  };


  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.susene.interventions.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getOrdensIntervencao();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.susene.interventions.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getOrdensIntervencao();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.susene.interventions.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getOrdensIntervencao();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.clientes.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'SusEneOrdemintervencao.clienteId')
        f.list = this.clientes;
      if (f.id === 'SusEneOrdemintervencao.contrato')
        f.list = this.simnao;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'SusEneOrdemintervencao.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'SusEneOrdemintervencao.contrato')
          f.column.selected.list = this.simnao;

      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('SUSENEOrdensIntervencaoFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.susene.interventions.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getOrdensIntervencao();
  };

  hasSelect = () => {
    return _.some(this.ois, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.ois.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.ois.length;
    } else { // Remove all selections
      this.ois.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.ois.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.ois, r => r.selected).length;
    this.allSelected = this.nSelected >= this.ois.length;
    this.everythingSelected = false;
  };

  viewOI = r => {
    this.$state.go('app.susene.interventions.detail', {id: r.id});
  };

  // If id, it's single, if nothing it's multiple and go through "selected"
  confirmRemoveOI = (oiId) => {
    this.SusEneOrdemintervencao.findById({id : oiId, filter: {
        where: {
          active: true
        },
        include: {
          relation: 'processos',
          scope: {
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((oi) => {
      let plural = oi.processos.length === 1 ? "1 processo" : `${oi.processos.length} processos`;
      let processString = oi.processos.length > 0 ? ` Esta operação irá remover ${plural}` : "";
      let confirmationString = `Tem a certeza que pretende remover esta OI?${processString}`;
      let confirm = this.UI.showConfirm(confirmationString);
      confirm.then(() => {
        let wait = this.UI.showWaiting();
        let ids = oi.processos.map(x => x.id);
        if (ids.length > 0) { // There are processes to remove, remove them first
          this.SusEneProcessoService.removeProcessos(ids).then(status => {

            let errors = status.filter(x => x.error);
            if (errors.length > 0) {
              wait.close();
              let title = "Erros de Remoção";
              let introText = "Ocorreram os seguintes erros na remoção da OI:";
              let instance = this.UI.showDialog({
                size: 'md',
                template: require('../../../interface/modals/show-list.html'),
                controller: ['$scope', ($scope) => {
                  $scope.title = title;
                  $scope.introText = introText;
                  $scope.list = errors;
                  $scope.ok = function () {
                    $scope.$close();
                  };
                }]
              });
              instance.finally(() => {
                this.getOrdensIntervencao();
              });
            } else { // No errors in removing process, we can now remove OI
              this.removeOI(oi.id, wait).then(() => {
                this.UI.addToast("OI removida com sucesso");
                this.getOrdensIntervencao();
              }).catch(error => {}); // Already dealt inside
            }
          }).catch(error => {
            wait.close();
            console.log(error);
            this.UI.showAlert("Erro na eliminação de processos. Verifique a ligação");
          });
        } else { // No processes exist for OI, just remove the OI object
          this.removeOI(oi.id, wait).then(() => {
            this.UI.addToast("OI removida com sucesso");
            this.getOrdensIntervencao();
          }).catch(error => {}); // Already dealt inside
        }
      }).catch(() => {});
    }).catch((error) => {
      console.log(error);
      if (error.status === 404) {
        let alert = this.UI.showAlert("OI não encontrada. Verifique se já foi eliminada");
        alert.finally(() => {this.getOrdensIntervencao();});
      } else {
        let alert = this.UI.showAlert("Não foi possível ler OI. Verifique a ligação");
        alert.finally(() => {this.getOrdensIntervencao();});
      }
    });
  };

  // Remove OI object (with active)
  // wait object is so it's closed only when appropriate
  removeOI = (oiId, wait) => {
    let defer = this.$q.defer();
    this.SusEneOrdemintervencao.findById({id : oiId, filter: {
        where: {
          active: true
        },
        include: {
          relation: 'processos',
          scope: {
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((oi) => {
      if (oi.processos.length > 0) { // Should not happen
        wait.close();
        let alert = this.UI.showAlert("OI ainda tem processos por eliminar. Tente novamente");
        alert.finally(() => {defer.reject();});
      } else {
        oi.active = 0;
        this.SusEneOrdemintervencao.upsert(oi).$promise.then(() => {
          wait.close();
          defer.resolve();
        }).catch(err => {
          wait.close();
          console.log(err);
          let alert = this.UI.showAlert("Erro ao remover a OI.");
          alert.finally(() => {defer.reject();});
        });
      }
    }).catch((error) => {
      wait.close();
      console.log(error);
      if (error.status === 404) {
        let alert = this.UI.showAlert("OI não encontrada. Verifique se já foi eliminada");
        alert.finally(() => {defer.reject();});
      } else {
        let alert = this.UI.showAlert("Não foi possível ler OI. Verifique a ligação");
        alert.finally(() => {defer.reject();});
      }
    });
    return defer.promise;
  };
}

SusEneListInterventionController.$inject = ['$q', '$state', 'SusEneOrdemintervencao', 'SusEneCliente', 'UIService', 'SusEneProcessoService'];
