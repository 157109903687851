export default class OblProcessosListController {
  constructor($state, OblProcesso, Group, OblMotivo, UIService) {
    this.$state = $state;
    this.OblProcesso = OblProcesso;
    this.OblMotivo = OblMotivo;
    this.Group = Group;
    this.UI = UIService;

    this.dataLoaded = false;

    this.resultados = [
      { name: "Sim", value: 1 },
      { name: "Não", value: 0 }
    ];

    this.execucoes = [
      { name: "Não executado/Aguarda submissão", value: 0 },
      { name: "Executado", value: 1 },
    ];
    this.inspetores = [];
    this.motivos = [];
    this.filtersLoaded = [0, 0]; // Resultados already loaded

    this.opt = $state.params;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [{
      displayName: 'ID SGI',
      name: 'id',
      visible: false,
      sortable: true
    },
    {
      displayName: 'Nº Processo',
      name: 'numProcesso',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Nº Parceiro',
      name: 'parceiro',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Tipo',
      name: 'tipoId',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Proprietário',
      name: 'nomeClienteFinal',
      visible: true,
      sortable: true
    },
    {
      displayName: 'NIF Proprietário',
      name: 'nifClienteFinal',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Morada',
      name: 'morada',
      visible: true,
      sortable: true
    },
    {
      displayName: 'CP4',
      name: 'cp4',
      visible: true,
      sortable: true
    },
    {
      displayName: 'CP3',
      name: 'cp3',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Localidade',
      name: 'localidade',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Data de Inspeção',
      name: 'agendamentoData',
      exportDate: 'DD-MM-YYYY',
      visible: true
    },
    {
      displayName: 'Hora de Inspeção',
      name: 'agendamentoHora',
      // exportDate: 'HH:mm:ss', Cannot be used because we can't directly use it as a moment
      visible: true
    },
    {
      displayName: 'Inspetor',
      name: 'inspetor',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Visita efetuada?',
      name: 'validado',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Resultado',
      name: 'realizada',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Motivo',
      name: 'motivo',
      visible: true,
      sortable: false
    }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 'o'
    },
    {
      val: 'c',
      name: 'Começa por',
      type: 'o'
    },
    {
      val: 'd',
      name: 'Termina com',
      type: 'o'
    },
    {
      val: 'e',
      name: 'Contém',
      type: 'o'
    },
    {
      val: 'a',
      name: 'Igual (=)',
      type: 'd'
    },
    {
      val: 'b',
      name: 'Diferente de (≠)',
      type: 'd'
    },
    {
      val: 'c',
      name: 'Posterior a (>)',
      type: 'd'
    },
    {
      val: 'd',
      name: 'Anterior a (<)',
      type: 'd'
    },
    {
      val: 'e',
      name: 'Posterior ou igual (≥)',
      type: 'd'
    },
    {
      val: 'f',
      name: 'Anterior ou igual (≤)',
      type: 'd'
    },
    {
      val: 'a',
      name: 'Igual',
      type: 's'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 's'
    }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [{
      id: 'OblProcesso.id',
      name: 'ID SGI',
      type: 'o'
    },
    {
      id: 'OblOrdemintervencao.numeroProcesso',
      name: 'Nº Processo',
      type: 'o'
    },
    {
      id: 'OblOrdemintervencao.parceiro',
      name: 'Nº Parceiro',
      type: 'o'
    },
    {
      id: 'OblAgendamento.data',
      name: 'Data de Inspeção',
      type: 'd',
      format: "YYYY-MM-DD",
      displayFormat: "DD/MM/YYYY"
    },
    {
      id: 'OblAgendamento.hora',
      name: 'Hora de Inspeção',
      type: 'd',
      format: "HH:mm",
      displayFormat: "HH:mm"
    },
    {
      id: 'Funcionario.id',
      name: 'Inspetor',
      type: 's',
      list: this.inspetores
    },
    {
      id: 'OblProcesso.validado',
      name: 'Visita efetuada?',
      type: 's',
      list: this.resultados
    },
    {
      id: 'OblProcesso.realizada',
      name: 'Resultado',
      type: 's',
      list: this.execucoes
    },
    {
      id: 'OblMotivo.id',
      name: 'Motivo',
      type: 's',
      list: this.motivos
    }
    ];


    // Find relevant displayColumns from local storage
    if (localStorage.getItem('OblProcessoDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('OblProcessoDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('OblProcessoFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('OblProcessoFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('OblProcessoFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'Funcionario.id')
          f.column.selected.list = this.inspetores;
        if (f.column.selected.id === 'OblMotivo.id')
          f.column.selected.list = this.motivos;
        if (f.column.selected.id === 'OblProcesso.validado')
          f.column.selected.list = this.resultados;
        if (f.column.selected.id === 'OblProcesso.realizada')
          f.column.selected.list = this.execucoes;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getProcessos();
  }

  getResultado = (row) => {
    if (row.validado == 0) {
      if (row.realizada == 1) {
        return "Aguarda validação"
      }
      return "Aguarda submissão";
    }
    if (row.validado == 1) {
      if (row.realizada == 0) {
        return "Não executado";
      }
      if (row.realizada == 1) {
        if (row.tipoId == 2 && row.extra == 0) {
          return `Executado ${row.aceite == 2 ? `(Aguarda resposta de cliente)` : row.aceite == 0 ? "(Rejeitado)" : "(Aprovado)" }`;
        } else {
          return "Executado";
        }
      }
    }
  };

  getStyle = (row) => {
    if (row.validado == 0) {
      return {
        "font-weight": 500,
        color: "#4d575d"
      };
    }
    if (row.validado == 1 && row.realizada == 1) {
      return {
        "font-weight": 500,
        color: "#388e3c"
      };
    }
    if (row.validado == 1 && row.realizada == 0) {
      return {
        "font-weight": 500,
        color: "#d32f2f"
      };
    }
  };

  clearFilter = () => {
    localStorage.removeItem('OblProcessoFilter');
    this.customFilters = [];
    this.getProcessos();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table2() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  getProcessos = () => {
    this.tableLoading = true;


    let whereFields = this.setWhereFields(this.customFilters, {
      'OblProcesso.active': 1
    });

    this.OblProcesso.table2({
      params: {
        fields: [
          'OblProcesso.id as id',
          'OblProcesso.tipoId as tipoId',
          'OblProcesso.extra as extra',
          'OblProcesso.oiId as oiId',
          'OblOrdemintervencao.numeroProcesso as numProcesso',
          'OblOrdemintervencao.parceiro as parceiro',
          'OblOrdemintervencao.nomeCliente as nomeClienteFinal',
          'OblOrdemintervencao.nifCliente as nifClienteFinal',
          'OblOrdemintervencao.morada as morada',
          'OblOrdemintervencao.cp4 as cp4',
          'OblOrdemintervencao.cp3 as cp3',
          'OblOrdemintervencao.localidade as localidade',
          'OblAgendamento.data as agendamentoData',
          'OblAgendamento.hora as agendamentoHora',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as inspetor',
          'OblProcesso.validado as validado',
          'OblProcesso.aceite as aceite',
          'OblProcesso.realizada as realizada',
          'OblMotivo.designacao as motivo',
        ],
        from: ['OblProcesso', 'OblOrdemintervencao', 'OblAgendamento', 'Funcionario', 'OblMotivo',],
        referencesOrigin: [undefined, undefined, 'OblAgendamento.processoId', 'OblAgendamento.tecnicoId', undefined],
        references: [undefined, 'OblProcesso.oiId', 'OblProcesso.id', 'Funcionario.id', 'OblProcesso.motivoId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      res.data.forEach(i => {
        if (i.cp4 && !i.cp3) {
          i.codigoPostal = i.cp4;
        }
        if (i.cp4 && i.cp3) {
          i.codigoPostal = `${('0000' + i.cp4).slice(-4)}-${('000' + i.cp3).slice(-3)}`;
        }
        if (i.cp3 != null && !isNaN(i.cp3))
          i.cp3 = ('000' + i.cp3).slice(-3);
      });

      this.pedidos = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((error) => {
      console.log(error);
    });
  };

  exportProcessos = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, { 'OblProcesso.active': 1 });
    this.OblProcesso.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'OblProcesso.id as id',
          'OblProcesso.tipoId as tipoId',
          'OblProcesso.extra as extra',
          'OblProcesso.oiId as oiId',
          'OblOrdemintervencao.numeroProcesso as numProcesso',
          'OblOrdemintervencao.parceiro as parceiro',
          'OblOrdemintervencao.nomeCliente as nomeClienteFinal',
          'OblOrdemintervencao.nifCliente as nifClienteFinal',
          'OblOrdemintervencao.morada as morada',
          'OblOrdemintervencao.cp4 as cp4',
          'OblOrdemintervencao.cp3 as cp3',
          'OblOrdemintervencao.localidade as localidade',
          'OblAgendamento.data as agendamentoData',
          'OblAgendamento.hora as agendamentoHora',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as inspetor',
          'OblProcesso.validado as validado',
          'OblProcesso.aceite as aceite',
          'OblMotivo.designacao as motivo',
        ],
        from: ['OblProcesso', 'OblOrdemintervencao', 'OblAgendamento', 'Funcionario', 'OblMotivo',],
        referencesOrigin: [undefined, undefined, 'OblAgendamento.processoId', 'OblAgendamento.tecnicoId', undefined],
        references: [undefined, 'OblProcesso.oiId', 'OblProcesso.id', 'Funcionario.id', 'OblProcesso.motivoId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'processosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
    });
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.pedidos, x => x.selected), x => {
        return { key: 'OblProcesso.id', value: x.id };
      });
    let whereFields = this.setWhereFields(this.customFilters, { 'OblProcesso.active': 1 }, orsLiteral);
    this.OblProcesso.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'OblProcesso.id as id',
          'OblProcesso.tipoId as tipoId',
          'OblProcesso.extra as extra',
          'OblProcesso.oiId as oiId',
          'OblOrdemintervencao.numeroProcesso as numProcesso',
          'OblOrdemintervencao.parceiro as parceiro',
          'OblOrdemintervencao.nomeCliente as nomeClienteFinal',
          'OblOrdemintervencao.nifCliente as nifClienteFinal',
          'OblOrdemintervencao.morada as morada',
          'OblOrdemintervencao.cp4 as cp4',
          'OblOrdemintervencao.cp3 as cp3',
          'OblOrdemintervencao.localidade as localidade',
          'OblAgendamento.data as agendamentoData',
          'OblAgendamento.hora as agendamentoHora',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as inspetor',
          'OblProcesso.validado as validado',
          'OblProcesso.aceite as aceite',
          'OblMotivo.designacao as motivo',
        ],
        from: ['OblProcesso', 'OblOrdemintervencao', 'OblAgendamento', 'Funcionario', 'OblMotivo',],
        referencesOrigin: [undefined, undefined, 'OblAgendamento.processoId', 'OblAgendamento.tecnicoId', undefined],
        references: [undefined, 'OblProcesso.oiId', 'OblProcesso.id', 'Funcionario.id', 'OblProcesso.motivoId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'processosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
    });
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('OblProcessoDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  loadData = () => {
    this.OblMotivo.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(r => {
      r = r.map(a => {
        return {
          value: a.id,
          name: a.designacao
        }
      });
      this.motivos = _.orderBy(r, 'designacao', 'asc');
      this.filtersLoaded[0] = 1;
      this.Group.findOne({
        filter: {
          where: {
            name: {
              like: 'Obler'
            }
          },
          include: {
            relation: 'usergroup',
            scope: {
              include: 'user'
            }
          }
        }
      }).$promise.then((g) => {
        if (g && g.usergroup) {
          g.usergroup.forEach(u => {
            this.inspetores.push(u.user);
          });

          this.inspetores = _.orderBy(this.inspetores, 'name', 'asc');
          this.filtersLoaded[1] = 1;
        }
      }).catch(err => {
        console.log(err);
        this.inspetores = [];
        this.filtersLoaded[1] = 1;
        this.UI.addToast("Erro de carregamento de dados para filtragem (Inspetores).");
      });
    }).catch(err => {
      console.log(err);
      this.motivos = [];
      this.filtersLoaded[0] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Motivos).");
    });
  };

  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.obler.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.obler.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.obler.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.inspetores.length && !this.resultados.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'Funcionario.id')
        f.list = this.inspetores;
      if (f.id === 'OblMotivo.id')
        f.list = this.motivos;
      if (f.id === 'OblProcesso.validado')
        f.list = this.resultados;
      if (f.id === 'OblProcesso.realizada')
        f.list = this.execucoes;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'Funcionario.id')
          f.column.selected.list = this.inspetores;
        if (f.column.selected.id === 'OblMotivo.id')
          f.column.selected.list = this.motivos;
        if (f.column.selected.id === 'OblProcesso.validado')
          f.column.selected.list = this.resultados;
        if (f.column.selected.id === 'OblProcesso.realizada')
          f.column.selected.list = this.execucoes;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('OblProcessoFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.obler.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getProcessos();
  };

  hasSelect = () => {
    return _.some(this.pedidos, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.pedidos.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.pedidos.length;
    } else { // Remove all selections
      this.pedidos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.pedidos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.pedidos, r => r.selected).length;
    this.allSelected = this.nSelected >= this.pedidos.length;
    this.everythingSelected = false;
  };

  getTipo = (row) => {
    if (row.tipoId == 2 && row.extra == 0) {
      return "Orçamento";
    }
    return "Visita técnica";
  }
}

OblProcessosListController.$inject = ['$state', 'OblProcesso', 'Group', 'OblMotivo', 'UIService'];
