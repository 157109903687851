export default class LmeListWarehouseController {
  constructor($state, UIService, AuthService, LmeAmostra, LmeAmostraService, LmeCliente, LmeMarca, Table, LmeAmostraAlteracaoEstado, LmeAmostraAlteracaoLocal, LmeEstadoAmostra, LmeLocal, PRIClientes, PRIAJLClientes, AtvEntidadeProprietaria) {
    this.$state = $state;
    this.UI = UIService;
    this.Auth = AuthService;
    this.LmeAmostra = LmeAmostra;
    this.LmeAmostraService = LmeAmostraService;
    this.LmeLocal = LmeLocal;
    this.LmeCliente = LmeCliente;
    this.PRIClientes = PRIClientes;
    this.PRIAJLClientes = PRIAJLClientes;
    this.LmeAmostraAlteracaoEstado = LmeAmostraAlteracaoEstado;
    this.LmeAmostraAlteracaoLocal = LmeAmostraAlteracaoLocal;
    this.LmeEstadoAmostra = LmeEstadoAmostra;
    this.LmeMarca = LmeMarca;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;

    this.dataLoaded = false;

    this.clientes = [];
    this.marcas = [];
    this.estados = [];
    this.locais = [];

    this.filtersLoaded = [0, 0, 0, 0]; // No pre-loaded data

    this.opt = $state.params;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [
      {
        displayName: 'ID SGI',
        name: 'id',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Nº Série',
        name: 'nSerie',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Ref. Cliente',
        name: 'refCliente',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Cliente',
        name: 'cliente',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Equipamento',
        name: 'equipamento',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Marca',
        name: 'marca',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Modelo',
        name: 'modelo',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Estado',
        name: 'estado',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Local',
        name: 'local',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Observações',
        name: 'observacoes',
        visible: false,
        sortable: true
      }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: 'LmeAmostra.id',
        name: 'ID SGI',
        type: 'o'
      },
      {
        id: 'LmeAmostra.nSerie',
        name: 'Nº Série',
        type: 'o'
      },
      {
        id: 'LmeAmostra.refCliente',
        name: 'Ref. Cliente',
        type: 'o'
      },
      {
        id: 'LmeAmostra.clienteId',
        name: 'Cliente',
        type: 's',
        list: this.clientes
      },
      {
        id: 'LmeAmostra.equipamento',
        name: 'Equipamento',
        type: 'o'
      },
      {
        id: 'LmeAmostra.marcaId',
        name: 'Marca',
        type: 's',
        list: this.marcas
      },
      {
        id: 'LmeAmostra.modelo',
        name: 'Modelo',
        type: 'o'
      },
      {
        id: 'LmeAmostra.estadoId',
        name: 'Estado',
        type: 's',
        list: this.estados
      },
      {
        id: 'LmeAmostra.localId',
        name: 'Local',
        type: 's',
        list: this.locais
      },
      {
        id: 'LmeAmostra.observacoes',
        name: 'Observações',
        type: 'o'
      }
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('LmeAmostrasDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('LmeAmostrasDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('LmeAmostrasFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('LmeAmostrasFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('LmeAmostrasFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'LmeAmostra.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'LmeAmostra.marcaId')
          f.column.selected.list = this.marcas;
        if (f.column.selected.id === 'LmeAmostra.estadoId')
          f.column.selected.list = this.estados;
        if (f.column.selected.id === 'LmeAmostra.localId')
          f.column.selected.list = this.locais;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getAmostras();
  }

  clearFilter = () => {
    localStorage.removeItem('LmeAmostrasFilter');
    this.customFilters = [];
    this.getAmostras();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  getAmostras = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {
      'LmeAmostra.active': 1
    });

    this.LmeAmostra.table({
      params: {
        fields: [
          'LmeAmostra.id as id',
          'LmeAmostra.nSerie as nSerie',
          'LmeAmostra.equipamento as equipamento',
          'LmeAmostra.modelo as modelo',
          'LmeAmostra.refCliente as refCliente',
          'LmeAmostra.estadoId as estadoId',
          'LmeAmostra.localId as localId',
          'LmeAmostra.observacoes as observacoes',
          'LmeCliente.nome as cliente',
          'LmeMarca.descricao as marca',
          'LmeEstadoAmostra.descricao as estado',
          'LmeEstadoAmostra.inside as estadoInside',
          'LmeLocal.designacao as local',
          'LmeLocal.inside as localInside'
        ],
        from: ['LmeAmostra', 'LmeCliente', 'LmeMarca', 'LmeEstadoAmostra', 'LmeLocal'],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined],
        references: [undefined, 'LmeAmostra.clienteId', 'LmeAmostra.marcaId', 'LmeAmostra.estadoId', 'LmeAmostra.localId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      // res.data.forEach(i => {
      // });

      this.amostras = res.data;
      this.total = total;
      this.allSelected = false;
      this.tableLoading = false;
    }).catch((error) => {
      this.UI.addToast("Não foi possível ler amostras. Verifique a ligação");
      console.log(error);
    });
  };

  exportAmostras = () => {
    let confirm = this.UI.showConfirm(`Tem a certeza que pretende exportar ${this.total === 1 ? (this.total + ' amostra') : (this.total + ' amostras')}?`);
    confirm.then(() => {
      let wait = this.UI.showWaiting();
      let whereFields = this.setWhereFields(this.customFilters, {'LmeAmostra.active': 1});
      this.LmeAmostra.exportAmostras({
        displayColumns: this.displayColumns,
        params: {
          fields: [ // edit getAmostras() too
            'LmeAmostra.id as id',
            'LmeAmostra.nSerie as nSerie',
            'LmeAmostra.equipamento as equipamento',
            'LmeAmostra.modelo as modelo',
            'LmeAmostra.refCliente as refCliente',
            'LmeAmostra.observacoes as observacoes',
            'LmeCliente.nome as cliente',
            'LmeMarca.descricao as marca',
            'LmeEstadoAmostra.descricao as estado',
            'LmeLocal.designacao as local'
          ],
          from: ['LmeAmostra', 'LmeCliente', 'LmeMarca', 'LmeEstadoAmostra', 'LmeLocal'],
          referencesOrigin: [undefined, undefined, undefined, undefined, undefined],
          references: [undefined, 'LmeAmostra.clienteId', 'LmeAmostra.marcaId', 'LmeAmostra.estadoId', 'LmeAmostra.localId'],
          aliases: [],
          where: whereFields.where,
          whereLiteral: whereFields.whereLiteral,
          whereDates: whereFields.whereDates,
          whereOr: whereFields.whereOr,
          whereOrLiteral: whereFields.whereOrLiteral,
          order: this.opt.order,
          sort: this.opt.sort,
          // limit: this.opt.items,
          // skip: (this.opt.page - 1) * this.opt.items,
        }
      }).$promise.then(res => {
        if (res.count) {
          wait.close();
          let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
          let b = document.createElement('a');
          b.href = url;
          b.download = 'amostrasExportadas.xlsx';
          b.click();
        } else {
          this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
        }
      }).catch((e) => {
        wait.close();
        console.log(e);
        this.tableLoading = false;
        this.UI.addToast("Não foi possível exportar amostras. Por favor tente mais tarde.");
      });
    }).catch(() => {});
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few amostras selected only
    if (this.hasSelect())
      orsLiteral = _.map(_.filter(this.amostras, x => x.selected), x => {
        return { key: 'LmeAmostra.id', value: x.id };
      });
    let whereFields = this.setWhereFields(this.customFilters, { 'LmeAmostra.active': 1 }, orsLiteral);
    this.LmeAmostra.exportAmostras({
      displayColumns: this.displayColumns,
      params: {
        fields: [ // edit getAmostras() too
          'LmeAmostra.id as id',
          'LmeAmostra.nSerie as nSerie',
          'LmeAmostra.equipamento as equipamento',
          'LmeAmostra.modelo as modelo',
          'LmeAmostra.refCliente as refCliente',
          'LmeAmostra.observacoes as observacoes',
          'LmeCliente.nome as cliente',
          'LmeMarca.descricao as marca',
          'LmeEstadoAmostra.descricao as estado',
          'LmeLocal.designacao as local'
        ],
        from: ['LmeAmostra', 'LmeCliente', 'LmeMarca', 'LmeEstadoAmostra', 'LmeLocal'],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined],
        references: [undefined, 'LmeAmostra.clienteId', 'LmeAmostra.marcaId', 'LmeAmostra.estadoId', 'LmeAmostra.localId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'amostrasExportadas.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar amostras. Por favor tente mais tarde.");
    });
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('LmeAmostrasDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  loadData = () => {
    this.LmeCliente.find({
      filter: {
        fields: {id: true, nome: true},
        where: {
          active: true
        },
        order: 'nome ASC'
      }
    }).$promise.then((clientes) => {
      clientes.forEach(t => {
        t.name = t.nome;
      })
      this.clientes = clientes;
      this.filtersLoaded[0] = 1;
    }).catch(err => {
      this.clientes = [];
      this.filtersLoaded[0] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Clientes).");
      console.log(err);
    });

    this.LmeMarca.find({
      filter: {
        where: {
          active: true
        },
        order: 'descricao ASC'
      }
    }).$promise.then(marcas => {
      marcas.forEach(t => {
        t.name = t.descricao;
      });
      this.marcas = marcas;
      this.filtersLoaded[1] = 1;
    }).catch(e => {
      this.marcas = [];
      this.filtersLoaded[1] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Marcas).");
    });

    this.LmeEstadoAmostra.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(estados => {
      estados.forEach(t => {
        t.name = t.descricao;
      });
      this.estados = estados;
      this.filtersLoaded[2] = 1;
    }).catch(e => {
      console.log(e);
      this.estados = [];
      this.filtersLoaded[2] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Estados).");
    });

    this.LmeLocal.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(locais => {
      locais.forEach(t => {
        t.name = t.designacao;
      });
      this.locais = locais;
      this.filtersLoaded[3] = 1;
    }).catch(e => {
      this.locais = [];
      this.filtersLoaded[3] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Locais).");
    });
  };

  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.lab.warehouse.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getAmostras();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.lab.warehouse.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getAmostras();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.lab.warehouse.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getAmostras();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }
    if (!this.clientes.length && !this.marcas.length && !this.estados.length && !this.locais.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'LmeAmostra.clienteId')
        f.list = this.clientes;
      if (f.id === 'LmeAmostra.marcaId')
        f.list = this.marcas;
      if (f.id === 'LmeAmostra.estadoId')
        f.list = this.estados;
      if (f.id === 'LmeAmostra.localId')
        f.list = this.locais;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'LmeAmostra.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'LmeAmostra.marcaId')
          f.column.selected.list = this.marcas;
        if (f.column.selected.id === 'LmeAmostra.estadoId')
          f.column.selected.list = this.estados;
        if (f.column.selected.id === 'LmeAmostra.localId')
          f.column.selected.list = this.locais;
      }
    });

    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('LmeAmostrasFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.lab.warehouse.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getAmostras();
  };

  hasSelect = () => {
    return _.some(this.amostras, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.amostras.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.amostras.length;
    } else { // Remove all selections
      this.amostras.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
  };

  selectItem = () => {
    this.nSelected = _.filter(this.amostras, r => r.selected).length;
    this.allSelected = this.nSelected >= this.amostras.length;
  };

  addAmostra = () => {
    if (this.marcas.length === 0) {
      this.UI.addToast("A carregar marcas, por favor tente mais tarde.");
      return;
    }
    this.AtvEntidadeProprietaria.find({
      filter: {
        where: {
          active: true,
          id: {lt: 3}
        }
      }
    }).$promise.then((empresas) => {
      let options = {
        size: 'lg',
        template: require('./new.dialog.html'),
        controller: ['$dialog', '$scope', (dialog, scope) => {
          // If Empresa is selected or not - used to fetch clientes
          scope.empresaSelected = false;
          // If Clientes have been fetched or not
          scope.clientesLoaded = false;
          scope.errorClientesLoaded = false;
          scope.marcas = this.marcas;
          scope.empresas = empresas;
          scope.clientes = [];

          scope.auxEmpresa = {
            selected: undefined,
            infiniteScroll: {numToAdd: 20, currentItems: 20}
          };

          scope.auxCliente = {
            selected: undefined,
            infiniteScroll: {numToAdd: 20, currentItems: 20}
          };

          scope.nSerieModelo = false;
          scope.amostra = {
            id: 0,
            estadoId: 1,
            localId: 1,
            active: true
          };

          scope.onEmpresaSelected = (item) => {
            scope.empresaSelected = true;
            scope.clientesLoaded = false;
            scope.errorClientesLoaded = false;
            // Reset auxCliente
            scope.auxCliente = {
              selected: undefined,
              infiniteScroll: {numToAdd: 20, currentItems: 20}
            };
            // Get the right table to fetch clientes from IEP, AJL
            let PRIClientes;
            if (!item.id || item.id === 1)
              PRIClientes = this.PRIClientes;
            else
              PRIClientes = this.PRIAJLClientes;

            PRIClientes.find({
              filter: {
                order: 'nome ASC'
              }
            }).$promise.then((clientes) => {
              scope.clientes = clientes;
              scope.clientesLoaded = true;
              scope.errorClientesLoaded = false;
            }).catch((error) => {
              console.log(error);
              scope.clientesLoaded = true;
              scope.errorClientesLoaded = true;
            });
          };

          //Infinite Scroll Magic
          scope.addMoreItems = (infiniteScroll) => {
            infiniteScroll.currentItems += infiniteScroll.numToAdd;
          };

          scope.togglenSerieModelo = () => {
            if (scope.nSerieModelo)
              scope.amostra.modelo = '';
            else scope.amostra.modelo = 'N/A';
          };

          scope.ok = () => {
            dialog.close(scope);
          };

          scope.cancel = () => {
            dialog.dismiss('cancel');
          };
        }]
      };

      let modal = this.UI.showDialog(options);

      modal.then((res) => {
        this.loaded = false;
        // Check if Cliente exists. If not, create it, if exists, update it.
        if (res.auxCliente.selected) {
          this.LmeCliente.find({
            filter: {
              where: {
                and: [{empresaId: res.auxEmpresa.selected.id}, {refClientePrimavera: res.auxCliente.selected.cliente}, {active: 1}]
              }
            }
          }).$promise.then((c) => {
            if (c.length === 0) { // Cliente doesn't exist, create it.
              this.LmeCliente.create({
                id: 0,
                empresaId: res.auxEmpresa.selected.id,
                nome: res.auxCliente.selected.nome,
                nif: res.auxCliente.selected.numcontrib,
                cp4: res.auxCliente.selected.facCp.split("-")[0],
                cp3: res.auxCliente.selected.facCp.split("-")[1],
                localidade: res.auxCliente.selected.facCploc,
                contactoTelefonico: res.auxCliente.selected.facTel,
                morada: res.auxCliente.selected.facMor,
                observacoes: res.auxCliente.selected.notas,
                atualizadoa: res.auxCliente.selected.dataultimaactualizacao,
                refClientePrimavera: res.auxCliente.selected.cliente,
                active: 1
              }).$promise.then((clienteCreated) => {
                res.amostra.cliente = clienteCreated;
                this.prepareAmostraCreation(res);
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("De momento não é possível adicionar a amostra. Por favor tente mais tarde.");
              });
            } else { // Cliente exists, update it.
              c[0].nome = res.auxCliente.selected.nome;
              c[0].nif = res.auxCliente.selected.numcontrib;
              c[0].cp4 = res.auxCliente.selected.facCp.split("-")[0];
              c[0].cp3 = res.auxCliente.selected.facCp.split("-")[1];
              c[0].localidade = res.auxCliente.selected.facCploc;
              c[0].contactoTelefonico = res.auxCliente.selected.facTel;
              c[0].morada = res.auxCliente.selected.facMor;
              c[0].observacoes = res.auxCliente.selected.notas;
              c[0].atualizadoa = res.auxCliente.selected.dataultimaactualizacao;
              c[0].refClientePrimavera = res.auxCliente.selected.cliente;
              c[0].$save().then((updatedCliente) => {
                res.amostra.cliente = updatedCliente;
                this.prepareAmostraCreation(res);
              }).catch(err => {
                console.log(err);
                this.UI.addToast("Falha na adição de amostra. Por favor tente mais tarde.");
              });
            }
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("De momento não é possível adicionar a amostra. Por favor tente mais tarde.");
          });
        }
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível carregar Empresas. Verifique a ligação");
    });

  };

  prepareAmostraCreation = (res) => {
    // Deal with multiple nSeries
    let nSeries = [];
    // Split into an array of nSeries
    if (res.amostra.nSerie != null)
      nSeries = _.uniq(res.amostra.nSerie.split(/[\t\n\ ,]+/));
    else {
      if (_.isEmpty(res.amostra.refCliente)) {
        let confirm = this.UI.showConfirm("Não preencheu nº série nem referência interna do cliente para esta amostra. Tem a certeza?");
        confirm.then((r) => {
          this.createAmostra(res, null);
        });
      } else { // No NSerie but with ref cliente
        this.createAmostra(res, null);
      }
    }
    // Create records for each amostra sharing the same information except nSerie
    nSeries.forEach((n) => {
      this.createAmostra(res, n);
    });
  };

  // actually creates the amostra
  // res : result of modal, n: nSerie to use (or null)
  createAmostra = (res, n) => {
    let amostra = angular.copy(res.amostra);
    // Use provided nSerie
    amostra.nSerie = n;
    // If checkbox, copy nSerie to modelo
    if (res.nSerieModelo)
      amostra.modelo = n;

    if (angular.isUndefined(amostra.marca)) {
      this.LmeAmostra.create(amostra).$promise.then((amostraCreated) => {
        this.UI.addToast('Amostra adicionada com sucesso');
        this.getAmostras();
      }).catch(() => {
        this.UI.addToast('Erro ao adicionar amostra');
      });
    } else {
      amostra.marca.active = 1;
      this.LmeMarca.upsert(amostra.marca).$promise.then((marca) => {
        amostra.clienteId = amostra.cliente.id;
        amostra.marcaId = marca.id;
        this.LmeAmostra.create(amostra).$promise.then((amostraCreated) => {
          this.UI.addToast('Amostra adicionada com sucesso');
          this.getAmostras();
        }).catch(() => {
          this.UI.addToast('Erro ao adicionar amostra');
        });
      }).catch(() => {
        if (amostra.marca.id === 0) {
          this.UI.addToast('Erro ao adicionar marca');
        }
      });
    }
  };

  // If id, it's single, if nothing it's multiple and go through "selected"
  confirmRemoveAmostras = (id) => {
    let ids;
    if (id) ids = [id];
    else ids = this.amostras.map(x => {if (x.selected) return x.id}).filter(x => x);
    if (ids.length === 0) return;
    if (ids.length > 20) {
      this.UI.showAlert("Não é possível remover mais de 20 amostras ao mesmo tempo.");
    } else {
      let plural = ids.length === 1 ? ` amostra?` : `s ${ids.length} amostras?`;
      let confirm = this.UI.showConfirm("Tem a certeza que pretende remover esta" + plural);
      confirm.then(() => {
        let wait = this.UI.showWaiting();
        this.LmeAmostraService.removeAmostras(ids).then(status => {
          wait.close();

          let errors = status.filter(x => x.error);
          if (errors.length > 0) {
            let title = "Erros de Remoção";
            let introText = "Ocorreram os seguintes erros na remoção de amostras:";
            let instance = this.UI.showDialog({
              size: 'md',
              template: require('../../../interface/modals/show-list.html'),
              controller: ['$scope', ($scope) => {
                $scope.title = title;
                $scope.introText = introText;
                $scope.list = errors;
                $scope.ok = function () {
                  $scope.$close();
                };
              }]
            });
            instance.finally(() => {
              this.getAmostras();
            });
          } else {
            if (ids.length === 1)
              this.UI.addToast("Amostra removida com sucesso");
            else
              this.UI.addToast("Amostras removidas com sucesso");
            this.getAmostras();
          }
        }).catch(error => {
          wait.close();
          console.log(error);
          this.UI.showAlert("Erro na eliminação de amostras. Verifique a ligação");
        });
      });
    }
  };

  // Change state for amostras
  changeStateSelected = () => {
    let amostraIds = [];
    // if a few amostras selected only
    if (this.hasSelect()) {
      amostraIds = this.amostras.filter(x => x.selected).map(x => { return { id: x.id, estadoId: x.estadoId, estadoInside: x.estadoInside } });

      // Check if all have the same Estado
      if (amostraIds.some(x => x.estadoId !== amostraIds[0].estadoId)) { // Reject changing different origins
        this.UI.showAlert("Alteração em massa de estado obriga a que todas as amostras selecionadas tenham que ter o mesmo estado inicial. Por favor escolha amostras com o mesmo estado inicial.");
      } else {
        this.LmeAmostraService.changeStateMultiple(amostraIds, {id: amostraIds[0].estadoId, inside: amostraIds[0].estadoInside}).then(() => {
          this.getAmostras();
        }).catch(() => {
          this.getAmostras();
        });
      }
    } else {
      this.UI.addToast("É necessário selecionar algumas amostras para alterar estado");
    }
  };

  // Change local for amostras
  changeLocalSelected = () => {
    let amostraIds = [];
    // if a few amostras selected only
    if (this.hasSelect()) {
      amostraIds = this.amostras.filter(x => x.selected).map(x => {
        return { id: x.id, localId: x.localId, localInside: x.localInside, estadoId: x.estadoId, estadoInside: x.estadoInside};
      });

      // Check if all have the same Local
      if (amostraIds.some(x => (x.localId !== amostraIds[0].localId) || (x.estadoId !== amostraIds[0].estadoId))) { // Reject changing different origins and states
        this.UI.showAlert("Alteração em massa de local obriga a que todas as amostras selecionadas tenham que ter o mesmo local e estado inicial. Por favor escolha amostras do mesmo local e com o mesmo estado.");
      } else {
        this.LmeAmostraService.changeLocalMultiple(amostraIds,
          {id: amostraIds[0].localId, inside: amostraIds[0].localInside},
          {id: amostraIds[0].estadoId, inside: amostraIds[0].estadoInside}).then(() => {
          this.getAmostras();
        }).catch(() => {
          this.getAmostras();
        });
      }
    } else {
      this.UI.addToast("É necessário selecionar algumas amostras para alterar local");
    }
  };
}

LmeListWarehouseController.$inject = ['$state', 'UIService', 'AuthenticationService', 'LmeAmostra', 'LmeAmostraService', 'LmeCliente', 'LmeMarca', 'NgTableParams', 'LmeAmostraAlteracaoEstado', 'LmeAmostraAlteracaoLocal', 'LmeEstadoAmostra', 'LmeLocal', 'PRIClientes', 'PRIAJLClientes', 'AtvEntidadeProprietaria'];
