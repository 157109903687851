export function routes($stateProvider) {
  $stateProvider
    .state('app.crm.clientes', {
      url: '/clientes',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.crm.clientes.list', {
      url: '/',
      controller: 'CrmClientesListController',
      controllerAs: 'vm',
      template: require('./directory/directory.view.html'),
      title: 'Clientes',
      //role: 'crmVerClientes',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "dataCriacao",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.crm.clientes.details', {
      url: '/{id}',
      controller: 'CrmClientesDetailsController',
      controllerAs: 'vm',
      template: require('./details/details.view.html'),
      //role: 'crmVerClientes',
    })
    .state('app.crm.clientes.new', {
      url: '/novo',
      title: 'Adicionar Cliente',
      template: require('./new/new.view.html'),
      controller: 'CrmClienteCreateController',
      controllerAs: 'vm',
      //role: 'crmCriarClientes',
    })
    .state('app.crm.clientes.new.1', {
      template: require('./new/cliente.1.form.html')
    })
    .state('app.crm.clientes.new.2', {
      template: require('./new/cliente.2.form.html')
    })
    // .state('app.crm.clientes.new.3', {
    //   template: require('./new/clientes.3.form.html')
    // })

  ;
}

routes.$inject = ['$stateProvider'];
