export default class PadPendentesListController {
  constructor($state, Group, UIService, Funcionario, PadProcesso, PadService, AtvEntidadeProprietaria, PadEstadoProcesso, PadTipoDespesa, AtvFornecedor, PadAvaliacao, PadUrgencia, PadCriterio, PadProcessoFornecedor) {
    this.$state = $state;
    this.Group = Group;
    this.UI = UIService;
    this.Funcionario = Funcionario;
    this.PadProcesso = PadProcesso;
    this.PadService = PadService;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.PadEstadoProcesso = PadEstadoProcesso;
    this.PadTipoDespesa = PadTipoDespesa;
    this.AtvFornecedor = AtvFornecedor;
    this.PadAvaliacao = PadAvaliacao;
    this.PadUrgencia = PadUrgencia;
    this.PadCriterio = PadCriterio;
    this.PadProcessoFornecedor = PadProcessoFornecedor;

    this.dataLoaded = false;

    this.simnao = [{ name: "Sim", value: 1 }, { name: "Não", value: 0 }];
    this.empresas = [];
    this.estados = [];
    this.tiposDespesa = [];
    this.fornecedores = [];
    this.avaliacoes = [];
    this.urgencias = [];
    this.funcionarios = [];
    this.criterios = [];

    this.filtersLoaded = [1, 0, 0, 0, 0, 0, 0, 0, 0];

    this.opt = $state.params;

    this.displayColumns = [
      { displayName: 'ID SGI', name: 'id', visible: false, sortable: true },
      { displayName: 'Nº Processo', name: 'numeroProcesso', visible: true, sortable: true },
      { displayName: 'Designação', name: 'designacao', visible: true, sortable: true, width: 400 },
      { displayName: 'Empresa', name: 'empresa', visible: true, sortable: true },
      { displayName: 'Centro de Decisão', name: 'centroDecisao', visible: true, sortable: true },
      { displayName: 'Estado', name: 'estado', visible: true, sortable: true },
      { displayName: 'Tipo de Despesa', name: 'tipoDespesa', visible: true, sortable: true },
      { displayName: 'Total Pré-Neg.', name: 'totalPreNegocial', visible: false, sortable: true },
      { displayName: 'Total', name: 'total', visible: true, sortable: true },
      { displayName: 'Cabimento?', name: 'cabimento', visible: false, sortable: true },
      { displayName: 'Fornecedor Selecionado', name: 'fornecedorSelecionado', visible: false, sortable: true },
      { displayName: 'NIF Fornecedor', name: 'nif', visible: false, sortable: true },
      { displayName: 'Justificação', name: 'justificacao', visible: false, sortable: true },
      { displayName: 'Urgência', name: 'urgencia', visible: true, sortable: true },
      { displayName: 'Criado por', name: 'criadoPor', visible: false, sortable: true },
      { displayName: 'Data', name: 'data',  exportDate: 'DD-MM-YYYY', visible: true, sortable: true},
      { displayName: 'Observações', name: 'observacoes', visible: false, sortable: true },
      { displayName: 'Critério', name: 'criterio', visible: false, sortable: true }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [{
      id: 'PadProcesso.id',
      name: 'ID SGI',
      type: 'o'
    },
    {
      id: 'PadProcesso.numeroProcesso',
      name: 'Nº Processo',
      type: 'o'
    },
    {
      id: 'PadProcesso.designacao',
      name: 'Designação',
      type: 'o'
    },
    {
      id: 'PadProcesso.entidadeProprietariaId',
      name: 'Empresa',
      type: 's',
      list: this.empresas
    },
    {
      id: 'PadProcesso.estadoId',
      name: 'Estado',
      type: 's',
      list: this.estados
    },
    {
      id: 'PadProcesso.tipoDespesaId',
      name: 'Tipo de Despesa',
      type: 's',
      list: this.tiposDespesa
    },
    {
      id: 'PadProcessoFornecedor.totalPreNegocial',
      name: 'Total Pré-Neg.',
      type: 'o'
    },
    {
      id: 'PadProcesso.total',
      name: 'Total',
      type: 'o'
    },
    {
      id: 'PadProcesso.cabimento',
      name: 'Cabimento?',
      type: 's',
      list: this.simnao
    },
    {
      id: 'PadProcessoFornecedor.fornecedorId',
      name: 'Fornecedor Selecionado',
      type: 's',
      list: this.fornecedores
    },
    {
      id: 'AtvFornecedor.nif',
      name: 'NIF Fornecedor',
      type: 'o'
    },
    {
      id: 'PadProcesso.justificacao',
      name: 'Justificação',
      type: 'o'
    },
    {
      id: 'PadProcesso.urgenciaId',
      name: 'Urgência',
      type: 's',
      list: this.urgencias
    },
    {
      id: 'PadProcesso.criadoPorId',
      name: 'Criado por',
      type: 's',
      list: this.funcionarios
    },
    {
      id: 'PadProcesso.data',
      name: 'Data',
      type: 'd',
      format: "YYYY-MM-DD",
      displayFormat: "DD/MM/YYYY"
    },
    {
      id: 'PadProcesso.observacoes',
      name: 'Observações',
      type: 'o'
    },
    {
      id: 'PadProcesso.criterioId',
      name: 'Critério',
      type: 's',
      list: this.criterios
    }
    ];


    // Find relevant displayColumns from local storage
    if (localStorage.getItem('PADPendentesDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('PADPendentesDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('PADPendentesFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('PADPendentesFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('PADPendentesFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'PadProcesso.entidadeProprietariaId')
          f.column.selected.list = this.empresas;
        if (f.column.selected.id === 'PadProcesso.estadoId')
          f.column.selected.list = this.estados;
        if (f.column.selected.id === 'PadProcesso.tipoDespesaId')
          f.column.selected.list = this.tiposDespesa;
        if (f.column.selected.id === 'PadProcesso.cabimento')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'PadProcessoFornecedor.fornecedorId')
          f.column.selected.list = this.fornecedores;
        if (f.column.selected.id === 'PadProcesso.urgenciaId')
          f.column.selected.list = this.urgencias;
        if (f.column.selected.id === 'PadProcesso.criadoPorId')
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === 'PadProcesso.criterioId')
          f.column.selected.list = this.criterios;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getProcessos();
  }

  clearFilter = () => {
    localStorage.removeItem('PADPendentesFilter');
    this.customFilters = [];
    this.getProcessos();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  getProcessos = () => {
    this.tableLoading = true;
    let whereFields = this.setWhereFields(this.customFilters, {'PadProcesso.active': 1});
    this.PadProcesso.pendentesTable({
      params: {
        fields: [ // edit exportProcessos() too
          'PadProcesso.id as id',
          'PadProcesso.numeroProcesso as numeroProcesso',
          'PadProcesso.cabimento as cabimento',
          'PadProcesso.designacao as designacao',
          'PadProcesso.justificacao as justificacao',
          'PadProcesso.data as data',
          'PadProcesso.observacoes as observacoes',
          'PadProcesso.total as total',
          'PadProcesso.estadoId as estadoId',
          'AtvEntidadeProprietaria.sigla as empresa',
          'PadCentroDecisao.designacao as centroDecisao',
          'PadEstadoProcesso.designacao as estado',
          'PadTipoDespesa.designacao as tipoDespesa',
          'AtvFornecedor.nome as fornecedorSelecionado',
          'AtvFornecedor.nif as nif',
          'PadUrgencia.designacao as urgencia',
          'Funcionario.name as criadoPor',
          'PadCriterio.designacao as criterio',
          'PadProcessoFornecedor.totalPreNegocial as totalPreNegocial',
          'PadProcesso.nivel1AvaliadoPorId as nivel1AvaliadoPorId' // Only for approval situations, no need to show this
        ],
        from: ['PadProcesso', 'AtvEntidadeProprietaria', 'PadEstadoProcesso', 'PadTipoDespesa', 'PadProcessoFornecedor', 'PadUrgencia', 'PadCriterio', 'Funcionario', 'PadCentroDecisao', 'AtvFornecedor'],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'AtvFornecedor.id'],
        references: [undefined, 'PadProcesso.entidadeProprietariaId', 'PadProcesso.estadoId', 'PadProcesso.tipoDespesaId', 'PadProcesso.fornecedorSelecionadoId', 'PadProcesso.urgenciaId', 'PadProcesso.criterioId', 'PadProcesso.criadoPorId', 'PadProcesso.centroDecisaoId', 'PadProcessoFornecedor.fornecedorId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      res.data.forEach(i => {

      });

      this.processos = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro na busca de processos. Verifique a ligação");
    });
  };

  exportProcessos = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, { 'PadProcesso.active': 1 });
    this.PadProcesso.exportProcessosPendentes({
      displayColumns: this.displayColumns,
      params: {
        fields: [ // edit getProcessos() too
          'PadProcesso.id as id',
          'PadProcesso.numeroProcesso as numeroProcesso',
          'PadProcesso.cabimento as cabimento',
          'PadProcesso.designacao as designacao',
          'PadProcesso.justificacao as justificacao',
          'PadProcesso.data as data',
          'PadProcesso.observacoes as observacoes',
          'PadProcesso.total as total',
          'AtvEntidadeProprietaria.sigla as empresa',
          'PadCentroDecisao.designacao as centroDecisao',
          'PadEstadoProcesso.designacao as estado',
          'PadTipoDespesa.designacao as tipoDespesa',
          'AtvFornecedor.nome as fornecedorSelecionado',
          'AtvFornecedor.nif as nif',
          'PadUrgencia.designacao as urgencia',
          'Funcionario.name as criadoPor',
          'PadCriterio.designacao as criterio',
          'PadProcessoFornecedor.totalPreNegocial as totalPreNegocial'
        ],
        from: ['PadProcesso', 'AtvEntidadeProprietaria', 'PadEstadoProcesso', 'PadTipoDespesa', 'PadProcessoFornecedor', 'PadUrgencia', 'PadCriterio', 'Funcionario', 'PadCentroDecisao', 'AtvFornecedor'],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'AtvFornecedor.id'],
        references: [undefined, 'PadProcesso.entidadeProprietariaId', 'PadProcesso.estadoId', 'PadProcesso.tipoDespesaId', 'PadProcesso.fornecedorSelecionadoId', 'PadProcesso.urgenciaId', 'PadProcesso.criterioId', 'PadProcesso.criadoPorId', 'PadProcesso.centroDecisaoId', 'PadProcessoFornecedor.fornecedorId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'processosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
    });
  };


  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.processos, x => x.selected), x => {
        return { key: 'PadProcesso.id', value: x.id };
      });
    let whereFields = this.setWhereFields(this.customFilters, { 'PadProcesso.active': 1 }, orsLiteral);
    this.PadProcesso.exportProcessosPendentes({
      displayColumns: this.displayColumns,
      params: {
        fields: [ // edit getProcessos() too
          'PadProcesso.id as id',
          'PadProcesso.numeroProcesso as numeroProcesso',
          'PadProcesso.cabimento as cabimento',
          'PadProcesso.designacao as designacao',
          'PadProcesso.justificacao as justificacao',
          'PadProcesso.data as data',
          'PadProcesso.observacoes as observacoes',
          'PadProcesso.total as total',
          'AtvEntidadeProprietaria.sigla as empresa',
          'PadCentroDecisao.designacao as centroDecisao',
          'PadEstadoProcesso.designacao as estado',
          'PadTipoDespesa.designacao as tipoDespesa',
          'AtvFornecedor.nome as fornecedorSelecionado',
          'AtvFornecedor.nif as nif',
          'PadUrgencia.designacao as urgencia',
          'Funcionario.name as criadoPor',
          'PadCriterio.designacao as criterio',
          'PadProcessoFornecedor.totalPreNegocial as totalPreNegocial'
        ],
        from: ['PadProcesso', 'AtvEntidadeProprietaria', 'PadEstadoProcesso', 'PadTipoDespesa', 'PadProcessoFornecedor', 'PadUrgencia', 'PadCriterio', 'Funcionario', 'PadCentroDecisao', 'AtvFornecedor'],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'AtvFornecedor.id'],
        references: [undefined, 'PadProcesso.entidadeProprietariaId', 'PadProcesso.estadoId', 'PadProcesso.tipoDespesaId', 'PadProcesso.fornecedorSelecionadoId', 'PadProcesso.urgenciaId', 'PadProcesso.criterioId', 'PadProcesso.criadoPorId', 'PadProcesso.centroDecisaoId', 'PadProcessoFornecedor.fornecedorId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'processosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
    });
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('PADPendentesDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  loadData = () => {
    this.AtvEntidadeProprietaria.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then(empresas => {
      empresas.forEach(t => {
        t.name = t.designacao;
      });
      this.empresas = empresas;
      this.filtersLoaded[1] = 1;
    }).catch(e => {
      this.empresas = [];
      this.filtersLoaded[1] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Empresas).");
    });

    this.PadEstadoProcesso.find({
    }).$promise.then(estados => {
      estados.forEach(t => {
        t.name = t.designacao;
      });
      this.estados = estados;
      this.filtersLoaded[2] = 1;
    }).catch(e => {
      this.estados = [];
      this.filtersLoaded[2] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Estados).");
    });

    this.PadTipoDespesa.find({
    }).$promise.then(tiposDespesa => {
      tiposDespesa.forEach(t => {
        t.name = t.designacao;
      });
      this.tiposDespesa = tiposDespesa;
      this.filtersLoaded[3] = 1;
    }).catch(e => {
      this.tiposDespesa = [];
      this.filtersLoaded[3] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Tipos de Despesa).");
    });

    this.AtvFornecedor.find({
    }).$promise.then(fornecedores => {
      fornecedores.sort((a, b) => a.nome.localeCompare(b.nome));
      fornecedores.forEach(t => {
        t.name = t.nome;
      });
      this.fornecedores = fornecedores;
      this.filtersLoaded[4] = 1;
    }).catch(e => {
      this.fornecedores = [];
      this.filtersLoaded[4] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Fornecedores).");
    });

    this.PadAvaliacao.find({
    }).$promise.then(avaliacoes => {
      avaliacoes.forEach(t => {
        t.name = t.designacao;
      });
      this.avaliacoes = avaliacoes;
      this.filtersLoaded[5] = 1;
    }).catch(e => {
      this.avaliacoes = [];
      this.filtersLoaded[5] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Avaliações).");
    });

    this.PadUrgencia.find({
    }).$promise.then(urgencias => {
      urgencias.forEach(t => {
        t.name = t.designacao;
      });
      this.urgencias = urgencias;
      this.filtersLoaded[6] = 1;
    }).catch(e => {
      this.urgencias = [];
      this.filtersLoaded[6] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Urgências).");
    });

    this.PadCriterio.find({
    }).$promise.then(criterios => {
      criterios.forEach(t => {
        t.name = t.designacao;
      });
      this.criterios = criterios;
      this.filtersLoaded[7] = 1;
    }).catch(e => {
      this.criterios = [];
      this.filtersLoaded[7] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Critérios).");
    });

    this.Funcionario.find({
      filter: {
        where: {
          active: true
        },
        order: 'name ASC'
      }
    }).$promise.then((funcionarios) => {
      funcionarios.sort((a, b) => a.name.localeCompare(b.name));
      this.funcionarios = funcionarios;
      this.filtersLoaded[8] = 1;
    }).catch(e => {
      this.funcionarios = [];
      this.filtersLoaded[8] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Colaboradores).");
    });
  };

  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.pad.pendentes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.pad.pendentes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.pad.pendentes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.empresas.length && !this.estados.length && !this.tiposDespesa.length && !this.fornecedores.length && !this.avaliacoes.length && !this.urgencias.length && !this.criterios.length && !this.funcionarios.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'PadProcesso.entidadeProprietariaId')
        f.list = this.empresas;
      if (f.id === 'PadProcesso.estadoId')
        f.list = this.estados;
      if (f.id === 'PadProcesso.tipoDespesaId')
        f.list = this.tiposDespesa;
      if (f.id === 'PadProcesso.cabimento')
        f.list = this.simnao;
      if (f.id === 'PadProcessoFornecedor.fornecedorId')
        f.list = this.fornecedores;
      if (f.id === 'PadProcesso.urgenciaId')
        f.list = this.urgencias;
      if (f.id === 'PadProcesso.criadoPorId')
        f.list = this.funcionarios;
      if (f.id === 'PadProcesso.criterioId')
        f.list = this.criterios;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'PadProcesso.entidadeProprietariaId')
          f.column.selected.list = this.empresas;
        if (f.column.selected.id === 'PadProcesso.estadoId')
          f.column.selected.list = this.estados;
        if (f.column.selected.id === 'PadProcesso.tipoDespesaId')
          f.column.selected.list = this.tiposDespesa;
        if (f.column.selected.id === 'PadProcesso.cabimento')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'PadProcessoFornecedor.fornecedorId')
          f.column.selected.list = this.fornecedores;
        if (f.column.selected.id === 'PadProcesso.urgenciaId')
          f.column.selected.list = this.urgencias;
        if (f.column.selected.id === 'PadProcesso.criadoPorId')
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === 'PadProcesso.criterioId')
          f.column.selected.list = this.criterios;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('PADPendentesFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.pad.pendentes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getProcessos();
  };

  hasSelect = () => {
    return _.some(this.processos, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.processos.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.processos.length;
    } else { // Remove all selections
      this.processos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.processos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.processos, r => r.selected).length;
    this.allSelected = this.nSelected >= this.processos.length;
    this.everythingSelected = false;
  };

  outputCabimento = (cabimento) => {
    if (cabimento == null) return '—';
    if (cabimento === 1) return 'Sim';
    if (cabimento === 0) return 'Não';
  };


  // TODO - Merge these next functions?

  // True if selection has all processos be able to be evaluated
  canEvaluateSelection = () => {
    let selecionados = this.processos.filter(x => x.selected);
    let evaluate = true;
    for (let i = 0; i < selecionados.length; i++) {
      if (!this.PadService.canEvaluate(selecionados[i])) {
        evaluate = false;
        break;
      }
    }
    return evaluate;
  };

  // True if selection has all processos be able to be approved negotiation
  canNegotiateSelection = () => {
    let selecionados = this.processos.filter(x => x.selected);
    let negotiate = true;
    for (let i = 0; i < selecionados.length; i++) {
      if (!this.PadService.canNegotiate(selecionados[i])) {
        negotiate = false;
        break;
      }
    }
    return negotiate;
  };

  // True if selection has all processos be able to be validated
  canValidateSelection = () => {
    let selecionados = this.processos.filter(x => x.selected);
    let evaluate = true;
    for (let i = 0; i < selecionados.length; i++) {
      if (!this.PadService.canValidate(selecionados[i])) {
        evaluate = false;
        break;
      }
    }
    return evaluate;
  }

  // True if selection has all processos be able to be evaluated for fornecedor
  canEvaluateFornecedorSelection = () => {
    let selecionados = this.processos.filter(x => x.selected);
    let evaluate = true;
    for (let i = 0; i < selecionados.length; i++) {
      if (!this.PadService.canEvaluateFornecedor(selecionados[i])) {
        evaluate = false;
        break;
      }
    }
    return evaluate;
  };

  // True if selection has all processos be able to be registered receipts
  // No point in allowing more than one.
  canFaturarSelection = () => {
    let selecionados = this.processos.filter(x => x.selected);
    if (selecionados.length > 1) return false;
    let evaluate = true;
    for (let i = 0; i < selecionados.length; i++) {
      if (!this.PadService.canFaturar(selecionados[i])) {
        evaluate = false;
        break;
      }
    }
    return evaluate;
  };

  // True if selection has all processos be able to be canceled
  canCancelSelection = () => {
    let selecionados = this.processos.filter(x => x.selected);
    let evaluate = true;
    for (let i = 0; i < selecionados.length; i++) {
      if (!this.PadService.canCancel(selecionados[i])) {
        evaluate = false;
        break;
      }
    }
    return evaluate;
  };

  // Evaluate single processo
  evaluate = (x, value) => {
    this.PadService.evaluateMultipleProcessos([{id: x.id, estadoId: x.estadoId}], value).then(() => {
      this.getProcessos();
    }).catch(() => {
      this.getProcessos();
    });
  };

  // Evaluate multiple processos
  evaluateSelected = (value) => {
    let processoIds = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected) {
      processoIds = this.processos.filter(x => x.selected).map(x => { return { id: x.id, estadoId: x.estadoId } });

      // Check if all have the same Estado
      if (processoIds.some(x => x.estadoId !== processoIds[0].estadoId)) { // Reject changing different origins
        this.UI.showAlert("Alteração em massa obriga a que todos os processos tenham que ter o mesmo estado inicial. Por favor escolha processos com o mesmo estado inicial.");
      } else {
        this.PadService.evaluateMultipleProcessos(processoIds, value).then(() => {
          this.getProcessos();
        }).catch(() => {
          this.getProcessos();
        });
      }
    } else {
      if (!this.everythingSelected)
        this.UI.addToast("Não é possível selecionar todos os processos para avaliar");
      else
        this.UI.addToast("É necessário selecionar alguns processos para avaliar");
    }
  };

  // Avançar negotiate of single processo
  negotiate = (x) => {
    this.PadService.negotiateMultipleProcessos([{id: x.id, estadoId: x.estadoId, nivel1AvaliadoPorId: x.nivel1AvaliadoPorId}]).then(() => {
      this.getProcessos();
    }).catch(() => {
      this.getProcessos();
    });
  };

  // Negotiate multiple processos
  negotiateSelected = () => {
    let processoIds = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected) {
      processoIds = this.processos.filter(x => x.selected).map(x => { return { id: x.id, estadoId: x.estadoId, nivel1AvaliadoPorId: x.nivel1AvaliadoPorId } });

      // Check if all have the same Estado and if it's the proper state and already needs negotiation
      if (processoIds.some(x => (x.estadoId !== processoIds[0].estadoId || x.nivel1AvaliadoPorId == null)) || processoIds[0].estadoId !== 2) { // Reject changing different origins or not the right state to negotiate
        this.UI.showAlert("Alteração em massa obriga a que todos os processos tenham que ter o mesmo estado e já tenham sido aprovados pelo responsável técnico. Por favor escolha processos com o mesmo estado inicial.");
      } else {
        this.PadService.negotiateMultipleProcessos(processoIds).then(() => {
          this.getProcessos();
        }).catch(() => {
          this.getProcessos();
        });
      }
    } else {
      if (!this.everythingSelected)
        this.UI.addToast("Não é possível selecionar todos os processos para validação de negociador");
      else
        this.UI.addToast("É necessário selecionar alguns processos para validação de negociador");
    }
  };

  // Validate single processo
  validate = (x) => {
    this.PadService.validateMultipleProcessos([{id: x.id, estadoId: x.estadoId}]).then(() => {
      this.getProcessos();
    }).catch(() => {
      this.getProcessos();
    });
  };

  // Validate multiple processos
  validateSelected = () => {
    let processoIds = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected) {
      processoIds = this.processos.filter(x => x.selected).map(x => { return { id: x.id, estadoId: x.estadoId } });

      // Check if all have the same Estado and if it's Adjudicado
      if (processoIds.some(x => x.estadoId !== processoIds[0].estadoId) || processoIds[0].estadoId !== 12) { // Reject changing different origins or not the right state
        this.UI.showAlert("Alteração em massa obriga a que todos os processos tenham que ter o mesmo estado inicial (Recepcionado). Por favor escolha processos com o mesmo estado inicial.");
      } else {
        this.PadService.validateMultipleProcessos(processoIds).then(() => {
          this.getProcessos();
        }).catch(() => {
          this.getProcessos();
        });
      }
    } else {
      if (!this.everythingSelected)
        this.UI.addToast("Não é possível selecionar todos os processos para validar");
      else
        this.UI.addToast("É necessário selecionar alguns processos para validar");
    }
  };

  // Evaluate fornecedor for single processo
  evaluateFornecedor = (x) => {
    this.PadService.evaluateFornecedorMultipleProcessos([{id: x.id, estadoId: x.estadoId}]).then(() => {
      this.getProcessos();
    }).catch(() => {
      this.getProcessos();
    });
  };

  // Validate multiple processos
  evaluateFornecedorSelected = () => {
    let processoIds = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected) {
      processoIds = this.processos.filter(x => x.selected).map(x => { return { id: x.id, estadoId: x.estadoId } });

      // Check if all have the same Estado and if it's Aguarda Avaliação
      if (processoIds.some(x => x.estadoId !== processoIds[0].estadoId) || processoIds[0].estadoId !== 14) { // Reject changing different origins or not the right state
        this.UI.showAlert("Alteração em massa obriga a que todos os processos tenham que ter o mesmo estado inicial (Aguarda Avaliação). Por favor escolha processos com o mesmo estado inicial.");
      } else {
        this.PadService.evaluateFornecedorMultipleProcessos(processoIds).then(() => {
          this.getProcessos();
        }).catch(() => {
          this.getProcessos();
        });
      }
    } else {
      if (!this.everythingSelected)
        this.UI.addToast("Não é possível selecionar todos os processos para avaliar fornecedor");
      else
        this.UI.addToast("É necessário selecionar alguns processos para avaliar fornecedor");
    }
  };

  // Faturar for single processo
  faturar = (x) => {
    this.PadService.faturarMultipleProcessos([{id: x.id, estadoId: x.estadoId}]).then(() => {
      this.getProcessos();
    }).catch(() => {
      this.getProcessos();
    });
  };

  // Validate multiple processos
  faturarSelected = () => {
    let processoIds = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected) {
      processoIds = this.processos.filter(x => x.selected).map(x => { return { id: x.id, estadoId: x.estadoId } });
      // Can only do this to one
      if (processoIds.length > 1) {
        this.UI.addToast("Só é possível registar faturas para 1 processo de cada vez");
      } else {
        // Check if all have the same Estado and if it's Aguarda Fatura
        if (processoIds.some(x => x.estadoId !== processoIds[0].estadoId) || processoIds[0].estadoId !== 15) { // Reject changing different origins or not the right state
          this.UI.showAlert("Alteração em massa obriga a que o processo tenha que ter o estado inicial Aguarda Avaliação. Por favor escolha processo esse estado inicial.");
        } else {
          this.PadService.faturarMultipleProcessos(processoIds).then(() => {
            this.getProcessos();
          }).catch(() => {
            this.getProcessos();
          });
        }
      }
    } else {
      if (!this.everythingSelected)
        this.UI.addToast("Não é possível selecionar todos os processos para registar fatura");
      else
        this.UI.addToast("É necessário selecionar um processo para registar fatura");
    }
  };

  // Cancel multiple processos
  cancelSelected = () => {
    let processoIds = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected) {
      processoIds = this.processos.filter(x => x.selected).map(x => { return { id: x.id, estadoId: x.estadoId } });

      // Check if all have the same Estado and if it's Adjudicado
      if (processoIds.some(x => x.estadoId !== processoIds[0].estadoId) || processoIds[0].estadoId < 12) { // Reject changing different origins or not the right state
        this.UI.showAlert("Alteração em massa obriga a que todos os processos tenham que ter o mesmo estado inicial e não esteja já Adjudicado. Por favor escolha processos com o mesmo estado inicial.");
      } else {
        this.PadService.cancelMultipleProcessos(processoIds).then(() => {
          this.getProcessos();
        }).catch(() => {
          this.getProcessos();
        });
      }
    } else {
      if (!this.everythingSelected)
        this.UI.addToast("Não é possível selecionar todos os processos para cancelar");
      else
        this.UI.addToast("É necessário selecionar alguns processos para cancelar");
    }
  };


}

PadPendentesListController.$inject = ['$state', 'Group', 'UIService', 'Funcionario', 'PadProcesso', 'PadService', 'AtvEntidadeProprietaria', 'PadEstadoProcesso', 'PadTipoDespesa', 'AtvFornecedor', 'PadAvaliacao', 'PadUrgencia', 'PadCriterio', 'PadProcessoFornecedor'];
