import React, { useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Grid from '@mui/system/Unstable_Grid';
export function StateChangeDialog({ atual, lista, onClose, ...props }) {
    const validationSchema = yup.object({
        id: yup.number().required('O campo é obrigatório'),
        observacoes: yup.string().optional().max(255, 'Máximo de 255 caracteres')
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            console.log(values);
            onClose(values);
        }
    });
    useEffect(() => {
        formik.resetForm();
        formik.setFieldValue('id', atual);
    }, [atual]);
    return (<Dialog {...props} fullWidth maxWidth={'md'}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Box>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} xs={12}>
              <TextField label='Estado' name='id' fullWidth defaultValue={formik.values?.id} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.id && Boolean(formik.errors.id)} helperText={formik.touched.id && formik.errors.id} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {lista.map(x => (<MenuItem value={x.id} key={x.id}>
                    {x.label}
                  </MenuItem>))}
              </TextField>
            </Grid>
            <Grid lg={12} xs={12}>
              <TextField label='Observações' name='observacoes' defaultValue={formik.values?.observacoes} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.observacoes && Boolean(formik.errors.observacoes)} helperText={formik.touched.observacoes && formik.errors.observacoes}/>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button type='button' onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button disabled={formik.isSubmitting || !formik.isValid} onClick={() => {
            formik.handleSubmit();
        }} variant='contained'>
          OK
        </Button>
      </DialogActions>
    </Dialog>);
}
