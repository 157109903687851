export function routes($stateProvider) {
  $stateProvider
    .state('app.lab.interventions', {
      url: '/ordens-intervencao',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.lab.interventions.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Ordens de Intervenção',
      template: require('./directory/directory.view.html'),
      controller: 'LmeListInterventionController',
      controllerAs: 'vm',
      role: 'oiLMEList',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.lab.interventions.new', {
      url: '/nova',
      controller: 'NewInterventionController',
      title: 'Adicionar OI',
      controllerAs: 'vm',
      role: ['oiLMEList','oiLMEImport', 'oiLMEEdit'],
      template: require('./new/new.view.html'),
    })
    .state('app.lab.interventions.detail', {
      title: 'Detalhes da OI',
      url: '/{id}',
      controller: 'LmeDetailInterventionController',
      controllerAs: 'vm',
      role: 'oiLMEList',
      template: require('./details/details.view.html')
    })
  ;
}

routes.$inject = ['$stateProvider'];
