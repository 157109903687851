import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
const DialogFaturacaoProcesso = ({ open, initial, onClose }) => {
    const validationSchema = yup.object({
        faturaNome: yup.string().required('Nome é obrigatório'),
        faturaNif: yup.string().required('NIF é obrigatório'),
        faturaEmail: yup.string().email('Email inválido').notRequired(),
        faturaMorada: yup.string().required('Morada é obrigatória'),
        faturaContacto: yup.string().nullable(),
        faturaCep: yup.string().matches(/^\d{4}-\d{3}$/, 'Código Postal deve estar no formato XXXX-YYY').notRequired(),
        faturaLocalidade: yup.string().required('O campo é obrigatório').max(255, 'Limite de caracteres ultrapassado'),
        faturaConcelho: yup.string().required('Concelho é obrigatório'),
        faturaDistrito: yup.string().required('Distrito é obrigatório'),
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            onClose(values);
        }
    });
    useEffect(() => {
        formik.resetForm();
        formik.setValues(initial);
    }, [initial]);
    return (<form>
      <Dialog open={open} maxWidth={'lg'} fullWidth>
        <DialogTitle>Editar Informação de Faturação</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Nome' name='faturaNome' defaultValue={formik.values?.faturaNome} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.faturaNome && Boolean(formik.errors.faturaNome)} helperText={formik.touched.faturaNome && formik.errors.faturaNome}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={3} md={3} xs={12}>
              <TextField label='NIF' name='faturaNif' defaultValue={formik.values?.faturaNif} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.faturaNif && Boolean(formik.errors.faturaNif)} helperText={formik.touched.faturaNif && formik.errors.faturaNif}/>
            </Grid>
            <Grid lg={3} md={3} xs={12}>
              <TextField label='Contacto' name='faturaContacto' defaultValue={formik.values?.faturaContacto} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.faturaContacto && Boolean(formik.errors.faturaContacto)} helperText={formik.touched.faturaContacto && formik.errors.faturaContacto}/>
            </Grid>
            <Grid lg={6} md={6} xs={12}>
              <TextField label='Email' name='faturaEmail' defaultValue={formik.values?.faturaEmail} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.faturaEmail && Boolean(formik.errors.faturaEmail)} helperText={formik.touched.faturaEmail && formik.errors.faturaEmail}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Morada' name='faturaMorada' defaultValue={formik.values?.faturaMorada} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.faturaMorada && Boolean(formik.errors.faturaMorada)} helperText={formik.touched.faturaMorada && formik.errors.faturaMorada}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={3} md={2} xs={12}>
              <TextField label='Código Postal' name='faturaCep' defaultValue={formik.values?.faturaCep} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.faturaCep && Boolean(formik.errors.faturaCep)} helperText={formik.touched.faturaCep && formik.errors.faturaCep}/>
            </Grid>
            <Grid lg={3} md={4} xs={12}>
              <TextField label='Localidade' name='faturaLocalidade' defaultValue={formik.values?.faturaLocalidade} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.faturaLocalidade && Boolean(formik.errors.faturaLocalidade)} helperText={formik.touched.faturaLocalidade && formik.errors.faturaLocalidade}/>
            </Grid>
            <Grid lg={3} md={3} xs={12}>
              <TextField label='Concelho' name='faturaConcelho' defaultValue={formik.values?.faturaConcelho} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.faturaConcelho && Boolean(formik.errors.faturaConcelho)} helperText={formik.touched.faturaConcelho && formik.errors.faturaConcelho}/>
            </Grid>
            <Grid lg={3} md={3} xs={12}>
              <TextField label='Distrito' name='faturaDistrito' defaultValue={formik.values?.faturaDistrito} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.faturaDistrito && Boolean(formik.errors.faturaDistrito)} helperText={formik.touched.faturaDistrito && formik.errors.faturaDistrito}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogFaturacaoProcesso;
