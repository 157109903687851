import angular from 'angular';
import { routes } from './routes';
import { react2angular } from "react2angular";
import CrmPessoasDetailsView from './details';
import CrmPessoasListView from './list';
import CrmPessoasAddView from '../pessoas/form';
import { useContext } from '@components';
export default angular.module('app.crm.pessoas', [])
    .config(routes)
    .component("crmPessoasDetailsView", react2angular(useContext.use(CrmPessoasDetailsView), [], ["$state", "CrmService", "UIService"]))
    .component("crmPessoasListView", react2angular(CrmPessoasListView, [], ["$state", "CrmService"]))
    .component('crmPessoasAddView', react2angular(CrmPessoasAddView, [], ['$state', 'CrmService']))
    .name;
