import angular, { isArray } from 'angular';
let urlBase = '/api';
let authHeader = 'authorization';
let models = require('./models.json');

function getHost(url) {
  let m = url.match(/^(?:https?:)?\/\/([^\/]+)/);
  return m ? m[1] : null;
}

let urlBaseHost = getHost(urlBase) || location.host;

let lbservices = angular.module('lbServices', ['ngResource']);

const staticMethods = [
  { n: 'create', u: '', m: 'POST', a: 0 },
  { n: 'createMany', u: '', m: 'POST', a: 1 },
  { n: 'patchOrCreate', u: '', m: 'PATCH', a: 0 },
  { n: 'replaceOrCreate', u: 'replaceOrCreate', m: 'POST', a: 0 },
  { n: 'upsertWithWhere', u: 'upsertWithWhere', m: 'POST', a: 0 },
  { n: 'exists', u: ':id/exists', m: 'GET', a: 0 },
  { n: 'findById', u: ':id', m: 'GET', a: 0 },
  { n: 'replaceById', u: ':id/replace', m: 'POST', a: 0 },
  { n: 'find', u: '', m: 'GET', a: 1 },
  { n: 'findOne', u: 'findOne', m: 'GET', a: 0 },
  { n: 'updateAll', u: 'update', m: 'POST', a: 0 },
  { n: 'deleteById', u: ':id', m: 'DELETE', a: 0 },
  { n: 'count', u: 'count', m: 'GET', a: 0 },
  { n: 'prototype$patchAttributes', u: ':id', m: 'PATCH', a: 0 },
  { n: 'createChangeStream', u: 'change-stream', m: 'POST', a: 0 }
];

function buildObject(model, LoopBackAuth) {
  let properties = {},
    methods = {};
  model.relations.forEach(relation => {
    if (relation.type == 1) {
      properties['findRelated' + relation.name] = {
        url: urlBase + '/' + model.plural + '/:id/' + relation.name,
        method: 'GET'
      };
    } else if (relation.type == 2) {
      const relationMethods = [
        { n: '__findById__', m: 'GET', t: 1 },
        { n: '__destroyById__', m: 'DELETE', t: 1 },
        { n: '__updateById__', m: 'PUT', t: 1 },
        { n: '__get__', m: 'GET', t: 2 },
        { n: '__create__', m: 'POST', t: 3 },
        { n: '__delete__', m: 'DELETE', t: 3 },
        { n: '__count__', m: 'GET', t: 3 }
      ];

      relationMethods.forEach(method => {
        let pName = 'prototype$' + method.n + relation.name;
        properties[pName] = { method: method.m };
        if (relation.t == 2) properties[pName].isArray = true;
        properties[pName].url = urlBase + '/' + model.plural + '/:id/' + relation.name;
        if (relation.t == 1) {
          properties[pName].params = { fk: '@fk' };
          properties[pName].url += '/:fk';
        }
      });
    }
  });

  staticMethods.forEach(method => {
    properties[method.n] = {
      url: urlBase + '/' + model.plural + '/' + method.u,
      method: method.m,
      isArray: method.a
    };
    if (method.a) properties[method.n].isArray = true;
  });

  model.customMethods.forEach(method => {
    properties[method.n] = {
      url: urlBase + '/' + model.plural + '/' + method.u,
      method: method.m,
      isArray: method.a
    };
    if (method.m == 'all') {
      properties[method.n].method = 'POST';
    }
    if (method.n == 'login' && model.isUser) {
      properties[method.n].interceptor = {
        response: function (response) {
          let accessToken = response.data;
          LoopBackAuth.setUser(accessToken.id, accessToken.userId, accessToken.user);
          LoopBackAuth.rememberMe = response.config.params.rememberMe !== false;
          LoopBackAuth.save();
          return response.resource;
        }
      };
      properties[method.n].params = { include: 'user' };
    }
    if (method.n == 'logout' && model.isUser) {
      properties[method.n].interceptor = {
        response: function (response) {
          LoopBackAuth.clearUser();
          LoopBackAuth.clearStorage();
          return response.resource;
        },
        responseError: function (responseError) {
          LoopBackAuth.clearUser();
          LoopBackAuth.clearStorage();
          return responseError.resource;
        }
      };
    }
  });

  return { properties: properties, methods: methods };
}

models.forEach(model => {
  lbservices.factory(model.name, [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      const propertiesObject = buildObject(model, LoopBackAuth);
      let R = LoopBackResource(urlBase + '/' + model.plural + '/:id', { id: '@id' }, propertiesObject.properties);

      R['upsert'] = R['patchOrCreate'];
      R['updateOrCreate'] = R['patchOrCreate'];
      R['patchOrCreateWithWhere'] = R['upsertWithWhere'];
      R['update'] = R['updateAll'];
      R['destroyById'] = R['deleteById'];
      R['removeById'] = R['deleteById'];
      R['prototype$updateAttributes'] = R['prototype$patchAttributes'];
      R['updateAttributes'] = R['prototype$patchAttributes'];
      R['patch'] = R['prototype$patchAttributes'];

      if (model.isUser) {
        R.getCachedCurrent = function () {
          var data = LoopBackAuth.currentUserData;
          return data ? new R(data) : null;
        };
        R.isAuthenticated = function () {
          return this.getCurrentId() != null;
        };
        R.getCurrentId = function () {
          return LoopBackAuth.currentUserId;
        };
      }
      R.modelName = model.name;

      return R;
    }
  ]);
});

lbservices
  .factory('LoopBackAuth', function () {
    var props = ['accessTokenId', 'currentUserId', 'rememberMe'];
    var propsPrefix = '$LoopBack$';

    function LoopBackAuth() {
      var self = this;
      props.forEach(function (name) {
        self[name] = load(name);
      });
      this.currentUserData = null;
    }

    LoopBackAuth.prototype.save = function () {
      var self = this;
      var storage = this.rememberMe ? localStorage : sessionStorage;
      props.forEach(function (name) {
        save(storage, name, self[name]);
      });
    };

    LoopBackAuth.prototype.setUser = function (accessTokenId, userId, userData) {
      this.accessTokenId = accessTokenId;
      this.currentUserId = userId;
      this.currentUserData = userData;
    };

    LoopBackAuth.prototype.clearUser = function () {
      this.accessTokenId = null;
      this.currentUserId = null;
      this.currentUserData = null;
    };

    LoopBackAuth.prototype.clearStorage = function () {
      props.forEach(function (name) {
        save(sessionStorage, name, null);
        save(localStorage, name, null);
      });
    };

    return new LoopBackAuth();

    function save(storage, name, value) {
      try {
        var key = propsPrefix + name;
        if (value == null) value = '';
        storage[key] = value;
      } catch (err) {
        console.log('Cannot access local/session storage:', err);
      }
    }

    function load(name) {
      var key = propsPrefix + name;
      return localStorage[key] || sessionStorage[key] || null;
    }
  })
  .config([
    '$httpProvider',
    function ($httpProvider) {
      $httpProvider.interceptors.push('LoopBackAuthRequestInterceptor');
    }
  ])
  .factory('LoopBackAuthRequestInterceptor', [
    '$q',
    'LoopBackAuth',
    function ($q, LoopBackAuth) {
      return {
        request: function (config) {
          var host = getHost(config.url);
          if (host && host !== urlBaseHost) {
            return config;
          }

          if (LoopBackAuth.accessTokenId) {
            config.headers[authHeader] = LoopBackAuth.accessTokenId;
          } else if (config.__isGetCurrentUser__) {
            var res = {
              body: { error: { status: 401 } },
              status: 401,
              config: config,
              headers: function () {
                return undefined;
              }
            };
            return $q.reject(res);
          }
          return config || $q.when(config);
        }
      };
    }
  ])
  .provider('LoopBackResource', function LoopBackResourceProvider() {
    this.setAuthHeader = function (header) {
      authHeader = header;
    };

    this.getAuthHeader = function () {
      return authHeader;
    };

    this.setUrlBase = function (url) {
      urlBase = url;
      urlBaseHost = getHost(urlBase) || location.host;
    };

    this.getUrlBase = function () {
      return urlBase;
    };

    this.$get = [
      '$resource',
      function ($resource) {
        var LoopBackResource = function (url, params, actions) {
          var resource = $resource(url, params, actions);

          resource.prototype.$save = function (success, error) {
            var result = resource.upsert.call(this, {}, this, success, error);
            return result.$promise || result;
          };
          return resource;
        };

        LoopBackResource.getUrlBase = function () {
          return urlBase;
        };

        LoopBackResource.getAuthHeader = function () {
          return authHeader;
        };

        return LoopBackResource;
      }
    ];
  });

export default lbservices.name;
