export default class ElevadorService {
  constructor($q, ElvElevador, ElvProcesso, UIService) {
    this.UI = UIService;
    this.ElvElevador = ElvElevador;
    this.ElvProcesso = ElvProcesso;
    this.$q = $q;
  }

  removeElevador = (elevador) => {
    let defer = this.$q.defer();
    this.ElvProcesso.find({
      filter: {
        where: {
          and: [{active: 1}, {elevadorId: elevador.id}]
        }
      }
    }).$promise.then((procs) => {
      if (procs && procs.length > 0) { // Processos exist, can't remove
        let plural = procs.length === 1 ? "" : "s";
        let alert = this.UI.showAlert("Não é possível apagar Elevador: Há " + procs.length + " processo" + plural + " ativo" + plural + " para este Elevador.");
        alert.finally(() => {
          defer.reject();
        });
      } else { // No processos ativos, can remove
        this.ElvElevador.findOne({
          filter: {
            where: {
              id: elevador.id
            }
          }
        }).$promise.then((elev) => {
          if (elev) {
            elev.active = 0;
            this.ElvElevador.upsert(elev).$promise.then(o => {
              this.UI.addToast("Elevador removido com sucesso");
              defer.resolve(o);
            }).catch(error => {
              defer.reject(error);
            });
          } else {
            this.UI.addToast("Não foi possível encontrar o Elevador. Verifique se já foi apagado");
            defer.reject();
          }
        }).catch((error) => {
          this.UI.addToast("Não foi possível encontrar o Elevador. Verifique se já foi apagado");
          defer.reject(error);
        });
      }
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };
}

ElevadorService.$inject = ['$q', 'ElvElevador', 'ElvProcesso', 'UIService'];
