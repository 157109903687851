import moment from "moment";

export default class CreatePedidoController {
  constructor(UIService, $state, $scope, ElvProcesso, ElvCliente, ElvEstado, ElvEquipamentoTipo, $q, $filter, ElvCondominio, ElvEmie, ElvElevador, ElvProcessoElevador, ElvAgendamento, AuthenticationService) {
    this.UI = UIService;
    this.state = $state;
    this.user = AuthenticationService.getUser();
    this.id = $state.params.id;
    this.loaded = false;
    this.ElvProcesso = ElvProcesso;
    this.ElvCliente = ElvCliente;
    this.ElvEstado = ElvEstado;
    this.ElvEquipamentoTipo = ElvEquipamentoTipo;
    this.ElvCondominio = ElvCondominio;
    this.ElvProcessoElevador = ElvProcessoElevador;
    this.ElvElevador = ElvElevador;
    this.ElvEmie = ElvEmie;
    this.ElvAgendamento = ElvAgendamento;
    this.$q = $q;
    this.$filter = $filter;
    this.getCliente();
    this.getEstado();
    this.getTipoElevador();

    this.data = {
      form: 1,
      details: {
        id: 0
      },
      emie: {},
      elevador: [],
      condominio: {}
    };
    $state.transitionTo('app.elv.processos.new' + this.data.form);
  }

  getCliente = () => {
    this.loaded = false;
    this.ElvCliente.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(res => {
      this.clientes = res;
      this.loaded = true;
    })
  };

  getEstado = () => {
    this.loaded = false;
    this.ElvEstado.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(res => {
      this.estado = res;
      this.loaded = true;
    })
  };

  getTipoElevador = () => {
    this.loaded = false;
    this.ElvEquipamentoTipo.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(res => {
      this.elevadorTipo = res;
      this.loaded = true;
    })
  };

  setForm = num => {
    this.data.form = num;
    this.state.transitionTo('app.elv.processos.new.' + this.data.form);
  };

  AutofillEMAFromNIF = () => {
    if (this.data.emie.nif && this.data.emie.nif.toString().length === 9) {
      this.ElvEmie.findOne({
        filter: {
          where: {
            nif: this.data.emie.nif,
            active: true
          }
        }
      }).$promise.then(res => {
        this.data.emie.nome = res.nome;
        this.data.emie.email = res.email;
        this.data.emie.telemovel = res.telemovel;
        this.data.emie.morada = res.morada;
        this.data.emie.cp4 = res.cp4;
        this.data.emie.cp3 = res.cp3;
        this.data.emie.localidade = res.localidade;
        this.data.emie.observacoes = res.observacoes;
      })
    } else {
      this.data.emie.nome = null;
      this.data.emie.email = null;
      this.data.emie.telemovel = null;
      this.data.emie.morada = null;
      this.data.emie.cp4 = null;
      this.data.emie.cp3 = null;
      this.data.emie.localidade = null;
      this.data.emie.observacoes = null;
    }
  };

  autofillCondominioFromNIF = () => {
    if (this.data.condominio.nif && this.data.condominio.nif.toString().length === 9) {
      this.ElvCondominio.findOne({
        filter: {
          where: {
            nif: this.data.condominio.nif,
            active: true
          }
        }
      }).$promise.then(res => {
        this.data.condominio.nome = res.nome;
        this.data.condominio.email = res.email;
        this.data.condominio.telemovel = res.telemovel;
        this.data.condominio.morada = res.morada;
        this.data.condominio.cp4 = res.cp4;
        this.data.condominio.cp3 = res.cp3;
        this.data.condominio.localidade = res.localidade;
        this.data.condominio.observacoes = res.observacoes;
      })
    } else {
      this.data.condominio.nome = null;
      this.data.condominio.email = null;
      this.data.condominio.telemovel = null;
      this.data.condominio.morada = null;
      this.data.condominio.cp4 = null;
      this.data.condominio.cp3 = null;
      this.data.condominio.localidade = null;
      this.data.condominio.observacoes = null;
    }
  };

  createElevador = (emie, condominio) => {
    let tasks = [];
    let tasksPedido = [];
    let elevadoresCriados = [];
    this.data.elevador.forEach(elevador => {
      let defer = this.$q.defer();
      this.ElvElevador.find({
        filter: {
          where: {
            numeroCamarario: elevador.numeroCamarario,
            // marcaId: elevador.marca.id,
            active: true
          }
        }
      }).$promise.then(res => {
        let e = {
          numeroCamarario: elevador.numeroCamarario,
          tipoId: elevador.tipo.id,
         // marcaId: elevador.marca.id,
          morada: elevador.morada,
          cp4: elevador.cp4,
          cp3: elevador.cp3,
          localidade: elevador.localidade,
          modelo: elevador.modelo,
          observacoes: elevador.observacoes,
          active: 1
        };
        if (res && res.length === 0) { // No Elevador found, we need to create an entry for it
          e.id = 0; // We are creating a new one, use id = 0 to force creation
        } else { // Elevador already exists, we should update the information on it
          e.id = res[0].id; // Use existing id so we only update information
        }
        this.ElvElevador.upsert(e).$promise.then((elevadorCreated) => {
          elevadoresCriados.push(elevadorCreated);
          defer.resolve(elevador);
        }).catch(err => {
          defer.reject(elevador);
        });
      });
      tasks.push(defer.promise);
    });
    this.$q.all(tasks).then((res) => {
      this.ElvProcesso.create({
        id: 0,
        dataProcesso: this.data.details.dataProcesso,
        clienteId: this.data.details.cliente.id,
        emieId: emie.id,
        condominioId: condominio.id,
        elevadorId: res.id,
        observacoes: this.data.details.observacoes,
        estadoId: 1,
        nip: this.data.details.nip,
        criadoPorId: this.user.id,
        active: 1
      }).$promise.then((res) => {
        elevadoresCriados.forEach(elv => {
          let deferPedido = this.$q.defer();
          let pedElevador = {
            id: 0,
            processoId: res.id,
            elevadorId: elv.id,
            active: 1
          };
          this.ElvProcessoElevador.upsert(pedElevador).$promise.then((pedidoElevador) => {
            deferPedido.resolve(elv);
          }).catch(err => {
            console.log(err);
            deferPedido.reject(elv);
          });
          tasksPedido.push(deferPedido.promise);
        });
        this.$q.all(tasksPedido).then((res) => {
          this.state.go('app.elv.processos.list');
          this.UI.addToast('Pedido adicionado com sucesso!');
        });
      }).catch(err => {
        console.log(err);
        this.UI.addToast('Não foi possível adicionar o pedido!');
      });
    }).catch(err => {
      console.log(err);
    });
  };

  createEma = (condominio) => {
    this.ElvEmie.find({
      filter: {
        where: {
          nif: this.data.emie.nif,
          active: true
        }
      }
    }).$promise.then(res => {
      let e = {
        nome: this.data.emie.nome,
        nif: this.data.emie.nif,
        email: this.data.emie.email,
        telemovel: this.data.emie.telemovel,
        morada: this.data.emie.morada,
        cp4: this.data.emie.cp4,
        cp3: this.data.emie.cp3,
        localidade: this.data.emie.localidade,
        observacoes: this.data.emie.observacoes,
        active: 1
      };
      if (res && res.length === 0) { // No EMIE found, we need to create an entry for it
        e.id = 0; // We are creating a new one, use id = 0 to force creation
      } else { // EMIE already exists, we should update the information on it
        e.id = res[0].id; // Use existing id so we only update information
      }
      this.ElvEmie.upsert(e).$promise.then((emie) => {
        this.createElevador(emie, condominio);
      });
    });
  };

  submit = () => {
    let confirm = this.UI.showConfirm('Tem a certeza que deseja adicionar este pedido?');
    confirm.then(res => {
      this.ElvCondominio.find({
        filter: {
          where: {
            nif: this.data.condominio.nif,
            active: true
          }
        }
      }).$promise.then(res => {
        let c = {
          nome: this.data.condominio.nome,
          nif: this.data.condominio.nif,
          email: this.data.condominio.email,
          telemovel: this.data.condominio.telemovel,
          morada: this.data.condominio.morada,
          cp4: this.data.condominio.cp4,
          cp3: this.data.condominio.cp3,
          localidade: this.data.condominio.localidade,
          observacoes: this.data.condominio.observacoes,
          active: 1
        };
        if (res && res.length === 0) { // No Condominio found, we need to create an entry for it
          c.id = 0; // We are creating a new one, use id = 0 to force creation
        } else { // Condominio already exists, we should update the information on it
          c.id = res[0].id; // Use existing id so we only update information
        }
        this.ElvCondominio.upsert(c).$promise.then((condominio) => {
          this.createEma(condominio);
        });
      }).catch(err => {
        console.log(err);
        this.UI.addToast('Não foi possível criar o condomínio!');
      });
    })
  };

  addElevador = () => {
    let options = {
      size: 'lg',
      template: require('./elevador.dialog.html'),
      controller: 'ElvElevadorCreationController',
      controllerAs: 'm'
    };

    let modal = this.UI.showDialog(options);
    modal.then((res) => {
      if (res) {
        this.data.elevador.push(res);
      }
    })
  };

  removeElevador = r => {
    let confirm = this.UI.showConfirm('Deseja remover elevador?');
    confirm.then(res => {
      if (res) {
        this.data.elevador.splice(r);
      }
    })
  };
}

CreatePedidoController.$inject = ['UIService', '$state', '$scope', 'ElvProcesso', 'ElvCliente', 'ElvEstado', 'ElvEquipamentoTipo', '$q', '$filter', 'ElvCondominio', 'ElvEmie', 'ElvElevador', 'ElvProcessoElevador', 'ElvAgendamento', 'AuthenticationService'];
