import angular from 'angular';
import { routes } from './routes';
import { react2angular } from 'react2angular';
import CrmEmpresasDetailsView from './details/index';
import CrmEmpresasListView from './list';
import CrmEmpresasAddView from './form';
import { useContext } from '@components';
export default angular
    .module('app.crm.empresas', [])
    .config(routes)
    //.component('crmEmpresasDetailsView', react2angular(useContext.use(CrmEmpresasDetailsView), [], ['$state', 'CrmService', 'UIService']))  not working
    .component('crmEmpresasDetailsView', react2angular(useContext.use(CrmEmpresasDetailsView), [], ['$state', 'CrmService', 'UIService']))
    .component('crmEmpresasListView', react2angular(CrmEmpresasListView, [], ['$state', 'CrmService']))
    .component('crmEmpresasAddView', react2angular(CrmEmpresasAddView, [], ['$state', 'CrmService'])).name;
