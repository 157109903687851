import { Autocomplete, Box, Breadcrumbs, Button, Link, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Table, WaitDialog } from '@components';
import { useModal } from 'mui-modal-provider';
import { DialogValidacao } from './validacao';
const GasRelatoriosListView = ({ $state, GasService, UIService }) => {
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const { showModal } = useModal();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [options, setOptions] = useState({
        filter: [],
        sort: []
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    });
    const [filter, setFilter] = useState([]);
    const [sort, setSort] = useState(() => loadParams());
    const [resultados, setResultados] = useState([]);
    const [tecnicos, setTecnicos] = useState([]);
    const [listas, setListas] = useState([]);
    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: '#',
            size: 50,
            Cell: ({ renderedCellValue, row }) => (<Link underline='none' color='default' sx={{ fontWeight: 'bold' }} target={'_blank'} href={$state.href('app.administration.relatorios.edit', { id: row.original.coreListaRelatorioRespostaId }, { absolute: true })}>
            {renderedCellValue}
          </Link>)
        },
        {
            accessorKey: 'Processo.numeroProcesso',
            header: 'Processo',
            defaultFilter: 'contains',
            size: 160,
            Cell: ({ renderedCellValue, row }) => (<Link underline='none' color='default' sx={{ fontWeight: 'bold' }} target={'_blank'} href={$state.href('app.administration.relatorios.edit', { id: row.original.coreListaRelatorioRespostaId }, { absolute: true })}>
            {renderedCellValue}
          </Link>)
        },
        {
            accessorKey: 'Processo.numeroParceiro',
            header: 'Nº Parceiro',
            size: 100
        },
        {
            accessorKey: 'CoreListaRelatorioResposta.coreListaRelatorioId',
            header: 'Modelo de relatório',
            filterSelectOptions: listas.map(r => {
                return {
                    label: `${r.titulo} - ${r.subtitulo}`,
                    value: r.id
                };
            }),
            size: 100,
            Cell: ({ row }) => {
                return row.original.CoreListaRelatorioResposta.CoreListaRelatorio?.titulo;
            },
            Edit: ({ row }) => {
                return (<Autocomplete disablePortal id={'autoComplete'} key={'autoComplete'} value={row._valuesCache['role'] || null} onChange={(_, value) => {
                        row._valuesCache['role'] = value;
                        row.toggleSelected(row._valuesCache['role']);
                    }} isOptionEqualToValue={(option, value) => option._id === value._id} getOptionLabel={option => option.title} fullWidth options={listas || []} renderOption={(props, option, { selected }) => <Box>Olá!</Box>} renderInput={params => <TextField key={'autoCompleteText'} {...params} label={'Label'}/>}/>);
            }
        },
        {
            accessorKey: 'CoreListaRelatorioResposta.resultadoId',
            header: 'Resultado',
            filterSelectOptions: resultados.map(r => {
                return {
                    label: r.designacao,
                    value: r.id
                };
            }),
            Cell: ({ row }) => {
                return row.original.CoreListaRelatorioResposta.CoreListaRelatorioResultado?.designacao;
            }
        },
        {
            accessorKey: 'CoreListaRelatorioResposta.versao',
            header: 'Versão',
            size: 75
        },
        {
            accessorKey: 'CoreListaRelatorioResposta.dataSubmissao',
            header: 'Data de submissão',
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (value != undefined)
                    return moment(value).format('DD-MM-YYYY [às] HH:mm');
                return '-';
            }
        },
        {
            accessorKey: 'CoreListaRelatorioResposta.tecnicoId',
            header: 'Submetido por',
            filterSelectOptions: tecnicos.map(r => {
                return {
                    label: r.name,
                    value: r.id
                };
            }),
            Cell: ({ row }) => {
                return row.original.CoreListaRelatorioResposta.Tecnico?.name;
            }
        },
        {
            accessorKey: 'CoreListaRelatorioResposta.validado',
            header: 'Validado?',
            Cell: ({ row }) => {
                return row.original.CoreListaRelatorioResposta.validado == 1 ? 'Sim' : 'Não';
            }
        },
        {
            accessorKey: 'CoreListaRelatorioResposta.dataValidacao',
            header: 'Data de validação',
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (value != undefined)
                    return moment(value).format('DD-MM-YYYY [às] HH:mm');
                return '-';
            }
        },
        {
            accessorKey: 'CoreListaRelatorioResposta.validadoPorId',
            header: 'Validado por',
            filterSelectOptions: tecnicos.map(r => {
                return {
                    label: r.name,
                    value: r.id
                };
            }),
            Cell: ({ row }) => {
                return row.original.CoreListaRelatorioResposta.ValidadoPor?.name;
            }
        },
        {
            accessorKey: 'numeroCertificado',
            header: 'Nº Certificado',
            size: 100
        }
    ], [tecnicos, resultados, listas]);
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    const excelGen = async (id, array) => {
        const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
        let filename = `gas-relatorio-${id ? 'selecao' : 'exportacao'}-${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
        let filters = {};
        filter.forEach(r => {
            filters[r.id] = `${getType(r.type)}:${r.value}`;
        });
        if (id) {
            filters['id'] = `$in:${array.join(',')}`;
        }
        else {
            // Aplica filtro temporal
        }
        let sorter = [];
        sort.forEach(r => {
            sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
        });
        try {
            let o = await GasService.export({
                columns: [
                    {
                        accessorKey: 'id',
                        header: 'ID SGI'
                    },
                    {
                        accessorKey: 'Processo.numeroProcesso',
                        header: 'Processo'
                    },
                    {
                        accessorKey: 'Processo.numeroParceiro',
                        header: 'Número Parceiro'
                    },
                    {
                        accessorKey: 'Processo.agendamento.data',
                        header: 'Data Agendamento'
                    },
                    {
                        accessorKey: 'Processo.agendamento.hora',
                        header: 'Hora Agendamento'
                    },
                    {
                        accessorKey: 'Processo.nomeCliente',
                        header: 'Nome Cliente'
                    },
                    {
                        accessorKey: 'Processo.nifCliente',
                        header: 'NIF'
                    },
                    {
                        accessorKey: 'Processo.emailCliente',
                        header: 'Email'
                    },
                    {
                        accessorKey: 'Processo.contactoCliente',
                        header: 'Contacto'
                    },
                    {
                        accessorKey: 'Processo.moradaCliente',
                        header: 'Morada'
                    },
                    {
                        accessorKey: 'Processo.cep',
                        header: 'Código Postal'
                    },
                    {
                        accessorKey: 'Processo.concelhoCliente',
                        header: 'Concelho'
                    },
                    {
                        accessorKey: 'Processo.distritoCliente',
                        header: 'Distrito'
                    },
                    {
                        accessorKey: 'Processo.cui',
                        header: 'CUI'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.pagamentoMontante',
                        header: 'Valor cobrado'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.pagamentoRecibo',
                        header: 'Número do recibo provisório'
                    },
                    {
                        accessorKey: 'Processo.tipoInspecao.designacao',
                        header: 'Tipo de inspeção'
                    },
                    {
                        accessorKey: 'Processo.tipoGas.designacao',
                        header: 'Tipo de gás'
                    },
                    {
                        accessorKey: 'Processo.tipoInstalacao.designacao',
                        header: 'Tipo de instalação'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.CoreListaRelatorio.titulo',
                        header: 'Lista'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.CoreListaRelatorio.Norma.referencia',
                        header: 'Procedimento'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.CoreListaRelatorioResultado.designacao',
                        header: 'Resultado'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.Motivo.designacao',
                        header: 'Motivo'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.versao',
                        header: 'Versão'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.dataSubmissao',
                        header: 'Data de submissão'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.Tecnico.name',
                        header: 'Submetido por'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.validado',
                        header: 'Validado?'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.dataValidacao',
                        header: 'Data de validação'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.ValidadoPor.name',
                        header: 'Validado por'
                    },
                    {
                        accessorKey: 'CoreListaRelatorioResposta.observacoes',
                        header: 'Observações'
                    }
                ],
                filter: filters,
                sortBy: sorter
            });
            if (o) {
                // Decode Base64 and convert to a Blob
                const binary = atob(o.$data);
                const bytes = new Uint8Array(binary.length);
                for (let i = 0; i < binary.length; i++) {
                    bytes[i] = binary.charCodeAt(i);
                }
                // Create a Blob and save it as an Excel file
                const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }
        }
        catch (e) {
            if (e.data && e.data.error) {
                UIService.addToast(e.data.error.message);
            }
            else {
                UIService.addToast('Ocorreu um erro ao exportar dados!');
            }
        }
        wait.destroy();
    };
    const initialFetch = async () => {
        if (loading) {
            setLoading(false);
            const lists = await GasService.getCoreListasRelatorio(6);
            setListas(lists);
            const opt = await GasService.options();
            setOptions(opt);
            const results = await GasService.getResultados();
            setResultados(results);
            const tec = await GasService.getTecnicosFuncionario();
            setTecnicos(tec);
        }
    };
    const fetchData = async () => {
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            const aux = await GasService.findAll({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            // Update URL
            setData(aux);
        }
        catch (e) { }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, filter, sort]);
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>Listagem de relatórios</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Gás
          </Link>
          <Link underline='hover' color='text.primary' aria-current='page'>
            Relatórios
          </Link>
        </Breadcrumbs>
      </Box>

      <Box>
        <Table onDoubleClick={row => {
            window.open($state.href('app.administration.relatorios.edit', { id: row.coreListaRelatorioRespostaId }, { absolute: true }));
        }} storageKey={$state.current.name} data={data} columns={columns} initial={{
            sort: sort,
            filter: filter
        }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setFilter} onSort={setSort} selectable={row => {
            return row.original?.CoreListaRelatorioResposta?.validado == 0 && row.original?.CoreListaRelatorioResposta?.dataSubmissao != undefined && row.original?.CoreListaRelatorioResposta?.realizada == 1;
        }} selectActions={selected => {
            return [
                <Button onClick={async () => {
                        if (selected.length > 0) {
                            showModal(DialogValidacao, {
                                multiple: true,
                                onClose: async (res) => {
                                    if (res) {
                                        const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                                        for (let i = 0; i < selected.length; i++) {
                                            try {
                                                await GasService.validateRelatorio(selected[i]);
                                            }
                                            catch (e) {
                                                UIService.addToast('Ocorreu um erro ao validar relatório');
                                            }
                                        }
                                        wait.destroy();
                                    }
                                    fetchData();
                                }
                            });
                        }
                    }}>
                Validar
              </Button>,
                <Button onClick={async () => {
                        if (selected.length > 0) {
                            excelGen(true, selected);
                        }
                    }}>
                Exportar
              </Button>
            ];
        }} onExport={async () => {
            excelGen(false, []);
        }} cellActionItems={({ row, table }) => {
            return row.original.CoreListaRelatorioResposta.validado == 0
                ? [
                    <MenuItem key={`row.view.${row.id}`} onClick={async () => {
                            showModal(DialogValidacao, {
                                multiple: false,
                                onClose: async (res) => {
                                    if (res) {
                                        try {
                                            const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                                            await GasService.validateRelatorio(row.id);
                                            wait.destroy();
                                        }
                                        catch (e) {
                                            UIService.addToast('Ocorreu um erro ao validar relatório');
                                        }
                                    }
                                    fetchData();
                                }
                            });
                        }}>
                    Validar relatório
                  </MenuItem>
                ]
                : undefined;
        }}/>
      </Box>
    </Box>);
};
export default GasRelatoriosListView;
