export default class LmeProcessDetailEditController {
  constructor($scope, $dialog, $timeout, LmeLaboratorio, LmeProcessoCaracteristica, data) {
    this.loaded = false;
    this.from = data;
    this.scope = $scope;
    this.dialog = $dialog;
    this.t = $timeout;
    this.defaults = {};
    // Setups
    this.LmeLaboratorio = LmeLaboratorio;
    this.LmeProcessoCaracteristica = LmeProcessoCaracteristica;
    this.loadData();
    // Defaults
    this.arrays = {
      laboratorios: [],
      atividades: [],
      caracteristicas: []
    };
    this.subcontratacoes = [{id: 1, value: 'Sim', val: 1}, {id: 2, value: 'Não', val: 0}];
    // Re-use the same array
    this.acreditacoes = this.subcontratacoes;
    this.insitus = this.subcontratacoes;
  }

  loadData = () => {
    this.loaded = false;
    this.LmeLaboratorio.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(labs => {
      this.defaults.laboratorios = labs;
      this.LmeProcessoCaracteristica.find({
        filter: {
          where: {
            active: true
          }
        }
      }).$promise.then((caracteristicas) => {
        this.defaults.caracteristicas = caracteristicas;
        this.arrays.caracteristicas = caracteristicas;

        this.loaded = true;

        let t = this.from.Tipo;
        this.arrays.laboratorios = labs.filter(lab => {
          return lab.tipoId === t.id;
        });
        let l = undefined;
        labs.forEach(o => {
          if (this.from.Laboratorio.id === o.id)
            l = o;
        });
        let s = undefined;
        this.subcontratacoes.forEach(o => {
          if (this.from.subcontratacao === o.val) {
            s = o;
          }
        });
        let ins = undefined;
        this.insitus.forEach(o => {
          if (this.from.inSitu === o.val) {
            ins = o;
          }
        });

        this.data = {
          tipo: t,
          laboratorio: l,
          norma: this.from.norma,
          valor: this.from.valor,
          subcontratacao: s,
          inSitu: ins
        };

        if (t.id === 1) {
          let ac = undefined;
          this.acreditacoes.forEach(o => {
            if (this.from.acreditado === o.val) {
              this.data.acreditado = o;
            }
          });
        }
        if (t.id === 2) {
          this.data.natureza = this.from.natureza;
          this.data.Caracteristica = this.from.Caracteristica;
        }

      });
    });
  };


  isOk = () => {
    return this.loaded ? angular.isDefined(this.data.norma) && angular.isDefined(this.data.atividade) : false;
  };

  ok = () => {
    if (this.loaded) {
      this.dialog.close(this.data);
    } else
      this.dialog.dismiss('cancel');
  };

  cancel = () => {
    this.dialog.dismiss('cancel');
  };
}

LmeProcessDetailEditController.$inject = ['$scope', '$dialog', '$timeout', 'LmeLaboratorio', 'LmeProcessoCaracteristica', 'data'];
