import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, MenuItem, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
const DialogAgendamentoProcesso = ({ initial, onClose, tecnicos, ...props }) => {
    const validationSchema = yup.object({
        dataAgendamento: yup.date().required('Data/Hora é obrigatória'),
        tecnicoId: yup
            .number()
            .oneOf(tecnicos.map(x => x.funcionario?.id), 'Preencha um técnico válido')
            .required('Técnico é obrigatório'),
        duracao: yup.number().integer('Duração tem que ser um número inteiro').positive('Duração não pode ser negativa').required('Duração é um campo obrigatório')
    });
    const formik = useFormik({
        initialValues: {
            duracao: 60,
            processoId: initial?.processoId,
        },
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            // Put values back to agendamento correctly
            onClose(values);
        }
    });
    useEffect(() => {
        formik.resetForm();
        formik.setValues(initial);
    }, [initial]);
    return (<form>
      <Dialog {...props} maxWidth={'md'} fullWidth>
        <DialogTitle>Editar Agendamento</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={3} md={3} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker label='Data/Hora' format='DD/MM/YYYY HH:mm' value={formik.values?.dataAgendamento} onChange={value => formik.setFieldValue('dataAgendamento', value, true)} slotProps={{
            textField: {
                variant: 'outlined',
                id: 'dataAgendamento',
                error: formik.touched.dataAgendamento && Boolean(formik.errors.dataAgendamento),
                fullWidth: true,
                helperText: formik.touched.dataAgendamento && String(formik.errors.dataAgendamento)
            }
        }}/>
              </LocalizationProvider>
            </Grid>
            <Grid lg={2} md={2} xs={12}>
              <TextField label='Duração' name='duracao' defaultValue={formik.values?.duracao} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.duracao && Boolean(formik.errors.duracao)} helperText={formik.touched.duracao && formik.errors.duracao} InputProps={{ endAdornment: <InputAdornment position='end'>min</InputAdornment> }}/>
            </Grid>
            <Grid lg={7} md={7} xs={12}>
              <TextField label='Técnico' name='tecnicoId' value={formik.values?.tecnicoId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.tecnicoId && Boolean(formik.errors.tecnicoId)} helperText={formik.touched.tecnicoId && formik.errors.tecnicoId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {tecnicos &&
            tecnicos.map(x => (<MenuItem value={x.funcionarioId} key={x.funcionarioId}>
                      {x.funcionario.name}
                    </MenuItem>))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogAgendamentoProcesso;
