export function routes($stateProvider) {
  $stateProvider
    .state('app.ativos', {
      url: '/ativos',
      manutencao: false,
      abstract: true,
      template: '<div ui-view></div>'
    })
  ;
}

routes.$inject = ['$stateProvider'];
