import {routes} from './warehouse.routes';
import LmeListWarehouseController from "./directory/directory.controller";
import LmeDetailsWarehouseController from "./details/details.controller";
import LmeAmostraService from "./warehouse.service";
import LMEAmostraFileUploaderController from "./details/files.dialog.controller";

export default angular.module('app.lab.warehouse', [])
  .config(routes)
  .controller('LmeListWarehouseController', LmeListWarehouseController)
  .controller('LmeDetailsWarehouseController', LmeDetailsWarehouseController)
  .controller('LMEAmostraFileUploaderController', LMEAmostraFileUploaderController)
  .service('LmeAmostraService', LmeAmostraService)
  .name;
