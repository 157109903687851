export default class AtvAtivosService {
  constructor($q, UIService, AuthenticationService, AtvAtivo, AtvInteracao, AtvLocal, AtvEntidadeProprietaria, AtvFornecedorPrimavera, FuncionarioEntidadeProprietaria, PRIIEPFornecedores, PRIIEPFichaccusto, PRIIEPPlanocentros, PRIIEPFichas, PRIAJLFornecedores, PRIAJLFichaccusto, PRIAJLPlanocentros, PRIAJLFichas, PRIOBLERFornecedores, PRIOBLERFichaccusto, PRIOBLERPlanocentros, PRIOBLERFichas) {
    this.$q = $q;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.AtvAtivo = AtvAtivo;
    this.AtvInteracao = AtvInteracao;
    this.AtvLocal = AtvLocal;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.AtvFornecedorPrimavera = AtvFornecedorPrimavera;
    this.FuncionarioEntidadeProprietaria = FuncionarioEntidadeProprietaria;
    this.PRIIEPFornecedores = PRIIEPFornecedores;
    this.PRIIEPFichaccusto = PRIIEPFichaccusto;
    this.PRIIEPPlanocentros = PRIIEPPlanocentros;
    this.PRIIEPFichas = PRIIEPFichas;
    this.PRIAJLFornecedores = PRIAJLFornecedores;
    this.PRIAJLFichaccusto = PRIAJLFichaccusto;
    this.PRIAJLPlanocentros = PRIAJLPlanocentros;
    this.PRIAJLFichas = PRIAJLFichas;
    this.PRIOBLERFornecedores = PRIOBLERFornecedores;
    this.PRIOBLERFichaccusto = PRIOBLERFichaccusto;
    this.PRIOBLERPlanocentros = PRIOBLERPlanocentros;
    this.PRIOBLERFichas = PRIOBLERFichas;

    this.simnao2 = [{name:"Não Aplicável", value: 2}, {name: "Sim", value: 1}, {name: "Não", value: 0}];

    // Maximum number of clones in one go
    this.maxClones = 999;
    ///////////////////
    // CHANGE this.perms to true IF WE ARE TO USE USER PERMS FOR ATIVOS!!
    //////////////////////////////////////////////////
    this.perms = false;
  }

  usingPerms = () => {
    return this.perms;
  };

  // Return AtvEntidadeProprietaria entities that the user can see
  getEntidadesProprietariasPermitidas = () => {
    return new Promise((resolve, reject) => {
      this.AtvEntidadeProprietaria.find({
        filter: {
          where: {
            active: 1
          },
          order: 'ordem ASC'
        }
      }).$promise.then((entidadesProprietarias) => {
        if (this.perms) {
          let eps = [];
          this.FuncionarioEntidadeProprietaria.find({
            filter: {
              where: {
                fornecedorId: this.Auth.getId(),
                active: 1
              }
            }
          }).$promise.then((feps) => {
            feps = feps.map(r => r.entidadeProprietariaId);
            entidadesProprietarias.forEach(e => {
              if (feps.includes(e.id)) {
                eps.push(e);
              }
            });
            resolve(eps);
          }).catch((error) => {
            reject(error);
          });
        } else {
          resolve(entidadesProprietarias);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  };

  // Set wherePerms that the user can see with key (AtvEntidadeProprietaria)
  setWherePermFields = (whereFields, key) => {
    return new Promise((resolve, reject) => {
      let wherePerms = [];
      if (this.perms) {
        this.FuncionarioEntidadeProprietaria.find({
          filter: {
            where: {
              funcionarioId: this.Auth.getId(),
              active: 1
            }
          }
        }).$promise.then((feps) => {
          feps.forEach(f => {
            wherePerms.push({key: key, value: f.entidadeProprietariaId});
          });
          if (wherePerms.length === 0)
            wherePerms.push({key: key, value: -1});
          whereFields.wherePerms = wherePerms;
          resolve(whereFields);
        }).catch((error) => {
          reject(error);
        });
      } else {
        whereFields.wherePerms = wherePerms;
        resolve(whereFields);
      }
    });
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({key: data.key, value: data.value});
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({key: data.key, value: whereObject[data.key]});
        delete whereObject[data.key];
        whereOrObject.push({key: data.key, value: data.value});
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({key: data.key, comp: f.values.selected.val, value: f.value, format: f.column.selected.format});
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({key: data.key, comp: f.values.selected.val, value: f.value, format: f.column.selected.format});
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {where: where, whereDates: whereDates, whereNextDates: whereNextDates, whereLiteral: whereLiteral, whereOr: whereOr, whereOrLiteral: whereOrLiteral};
  };

  getPrimaveraFornecedorForEntidadeProprietaria = (entidadeProprietaria) => {
    if (entidadeProprietaria && entidadeProprietaria.sigla) {
      switch (entidadeProprietaria.sigla) {
        case "IEP":
          return this.PRIIEPFornecedores;
        case "AJL":
          return this.PRIAJLFornecedores;
        case "OBL":
          return this.PRIOBLERFornecedores;
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  getImobilizadoSource = (numImobilizado) => {
    if (numImobilizado != null && numImobilizado.length > 0) {
      switch (numImobilizado.split(/(\d+)/)[0]) { // Check the text part of the NumImobilizado
        case "IEP":
          return {
            PRIFichaccusto: this.PRIIEPFichaccusto,
            PRIPlanocentros: this.PRIIEPPlanocentros,
            PRIFichas: this.PRIIEPFichas
          };
        case "AJL":
          return {
            PRIFichaccusto: this.PRIAJLFichaccusto,
            PRIPlanocentros: this.PRIAJLPlanocentros,
            PRIFichas: this.PRIAJLFichas
          };
        case "OBL":
          return {
            PRIFichaccusto: this.PRIOBLERFichaccusto,
            PRIPlanocentros: this.PRIOBLERPlanocentros,
            PRIFichas: this.PRIOBLERFichas
          };
        default: // By default, just use IEP Primavera
          return {
            PRIFichaccusto: this.PRIIEPFichaccusto,
            PRIPlanocentros: this.PRIIEPPlanocentros,
            PRIFichas: this.PRIIEPFichas
          };
      }
    }
    // By default, just use IEP Primavera
    return {
      PRIFichaccusto: this.PRIIEPFichaccusto,
      PRIPlanocentros: this.PRIIEPPlanocentros,
      PRIFichas: this.PRIIEPFichas
    };
  };

  getPRIFornecedoresForEntidadeProprietaria = (entidadeProprietaria) => {
    let defer = this.$q.defer();
    let PRIFornecedor = this.getPrimaveraFornecedorForEntidadeProprietaria(entidadeProprietaria);
    if (PRIFornecedor !== null) {
      PRIFornecedor.find({
        filter: {
          fields: {fornecedor: true, nome: true},
          where: {},
          order: 'nome ASC'
        }
      }).$promise.then((fornecedores) => {
        defer.resolve(fornecedores);
      }).catch(error => {
        defer.reject(error);
      });
    } else {
      defer.resolve([]);
    }
    return defer.promise;
  };

  addInteracaoModalOptions = (data, interacoesTipo) => {
    // Return options object
    return {
      size: 'lg',
      template: require('./interacoes/interacao.new.dialog.html'),
      controller: ['$dialog', '$scope', 'AtvLocal', 'Funcionario', 'AtvResultado', (dialog, scope, AtvLocal, Funcionario, AtvResultado) => {
        scope.title = "Nova Interação - Ativo " + data.iidDisplay;
        scope.dateOptions = {
          dataInteracao: {
            format: 'DD/MM/YYYY HH:mm',
            defaultDate: moment().isDST() ? moment().add(1, 'h') : moment()
          },
          dataIntervencao: {
            format: 'DD/MM/YYYY',
            defaultDate: moment().isDST() ? moment().add(1, 'h') : moment()
          },
        };

        // Don't select dates before last interacao

        if (data.ultimaInteracao) {
          let minDate = moment(data.ultimaInteracao.dataInteracao).utc();
          if (minDate.isValid()) {
            // Need to make sure they don't overlap.
            if (scope.dateOptions.dataInteracao.defaultDate.isBefore(minDate))
              scope.dateOptions.dataInteracao.defaultDate = minDate.add(1,'s');
            scope.dateOptions.dataInteracao.minDate = minDate;
          }
        }

        scope.interacoesTipo = interacoesTipo;
        scope.locais = null;
        scope.funcionarios = null;
        scope.resultados = null;
        scope.fornecedores = null;
        scope.designacaoIntervencao = null;
        scope.showDataIntervencao = false;
        scope.selectedFuncionarioAfterInteracao = false;
        scope.interacao = {dataInteracao: null, fornecedorExterno: null};
        scope.auxInteracaoTipo = {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}};
        scope.auxLocal = {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}};
        scope.auxFuncionario = {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}};
        scope.auxResultado = {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}};
        scope.auxTipoEntrega = {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}};
        scope.auxFornecedor = {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}};

        // Choose interacoes that use entregas to funcionarios
        scope.tiposEntrega = [{id: 8, designacao: "Entrega"}, {id: 11, designacao: "Empréstimo"}];

        scope.getLocaisForEntidadeProprietaria = (entidadeProprietariaId) => {
          return AtvLocal.find({
            filter: {
              where: {
                entidadeProprietariaId: entidadeProprietariaId,
                active: 1
              },
              order: 'designacao ASC'
            }
          }).$promise;
        };

        scope.getFuncionarios = () => {
          return Funcionario.find({
            filter: {
              where: {
                active: 1
              },
              order: 'name ASC'
            }
          }).$promise;
        };

        scope.getResultadosForInteracaoTipo = (interacaoTipoId) => {
          return AtvResultado.find({
            filter: {
              where: {
                interacaoTipoId: interacaoTipoId,
                active: 1
              },
              order: 'ordem ASC'
            }
          }).$promise;
        };

        scope.getPRIFornecedoresForEntidadeProprietaria = this.getPRIFornecedoresForEntidadeProprietaria;

        scope.onInteracaoTipoSelected = (item) => {
          switch (item.id) {
            case 1: // Movimentação
              scope.getLocaisForEntidadeProprietaria(data.entidadeProprietariaId).then((locais) => {
                scope.locais = locais;
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("Não foi possível obter lista de locais. Por favor tente mais tarde");
              });
              break;
            case 2: // Recebido de Calibração
            case 3: // Recebido de Manutenção
            case 4: // Recebido de Verificação
            case 10: // Recebido de Empréstimo
              scope.getLocaisForEntidadeProprietaria(data.entidadeProprietariaId).then((locais) => {
                scope.locais = locais;
                scope.getFuncionarios().then((funcionarios) => {
                  scope.funcionarios = funcionarios;
                  scope.getResultadosForInteracaoTipo(item.id).then((resultados) => {
                    scope.resultados = resultados;
                  }).catch((error) => {
                    console.log(error);
                    this.UI.addToast("Não foi possível obter lista de resultados. Por favor tente mais tarde");
                  });
                }).catch((error) => {
                  console.log(error);
                  this.UI.addToast("Não foi possível obter lista de funcionários. Por favor tente mais tarde");
                });
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("Não foi possível obter lista de locais. Por favor tente mais tarde");
              });
              break;
            case 5: // Enviado para Calibração
            case 6: // Enviado para Manutenção
            case 7: // Enviado para Verificação
              scope.getPRIFornecedoresForEntidadeProprietaria(data.entidadeProprietaria).then((fornecedores) => {
                scope.fornecedores = fornecedores;
              }).catch(error => {
                console.log(error);
                this.UI.addToast("Não foi possível obter fornecedores. Por favor tente mais tarde");
              });
              break;
            case 8: // Entrega
            case 11: // Empréstimo
              scope.getFuncionarios().then((funcionarios) => {
                scope.funcionarios = funcionarios;
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("Não foi possível obter lista de funcionários. Por favor tente mais tarde");
              });
              break;
            case 9: // Devolução
              scope.getLocaisForEntidadeProprietaria(data.entidadeProprietariaId).then((locais) => {
                scope.locais = locais;
                scope.getFuncionarios().then((funcionarios) => {
                  scope.funcionarios = funcionarios;
                }).catch((error) => {
                  console.log(error);
                  this.UI.addToast("Não foi possível obter lista de funcionários. Por favor tente mais tarde");
                });
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("Não foi possível obter lista de locais. Por favor tente mais tarde");
              });
              break;
          }
        };

        scope.onDataInteracaoUpdate = () => {
          if (!moment(scope.interacao.dataInteracao).isValid())
            scope.interacao.dataInteracao = null;
        };

        scope.onDataIntervencaoUpdate = () => {
          if (!moment(scope.interacao.dataIntervencao).isValid())
            scope.interacao.dataIntervencao = null;
        };

        scope.onResultadoSelected = (item) => {
          if (item.resultado === "Não realizada") {
            scope.showDataIntervencao = false;
            scope.interacao.dataIntervencao = null;
          } else { // All other have date to be defined
            let auxTextIntervencao = scope.auxInteracaoTipo.selected.designacao.split(" ");
            scope.designacaoIntervencao = auxTextIntervencao[auxTextIntervencao.length -1];
            scope.showDataIntervencao = true;
          }
        };

        // When choosing Local when Local and Funcionario are available to choose
        scope.onLocalSelected = (item) => {
          scope.selectedFuncionarioAfterInteracao = false;
          scope.auxFuncionario.selected = null;
          scope.auxTipoEntrega.selected = null;
          scope.interacao.notificar = null;
        };

        // When choosing Funcionario when Local and Funcionario are available to choose
        // Choose type of entrega
        scope.onFuncionarioSelected = (item) => {
          if (!scope.auxTipoEntrega.selected) {
            // Pick the default tipo de entrega with the current interacao type
            // (emprestimo -> emprestimo, anything else -> entrega)
            if (scope.auxInteracaoTipo.selected.id === 10)
              scope.auxTipoEntrega.selected = scope.tiposEntrega.find(x => x.id === 11);
            else
              scope.auxTipoEntrega.selected = scope.tiposEntrega.find(x => x.id === 8);
            scope.interacao.notificar = true;
            scope.selectedFuncionarioAfterInteracao = true;
          }
        };

        // Returns true if form is ok to publish
        scope.validForm = () => {
          // If we don't have a valid interacao date, return false
          return moment(scope.interacao.dataInteracao).isValid();
        };

        // Infinite Scroll magic
        scope.addMoreItems = (infiniteScroll) => {
          infiniteScroll.currentItems += infiniteScroll.numToAdd;
        };

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };
  };

  afterAddInteracao = (res, ativoId) => {
    return new Promise((resolve, reject) => {
      // Check if we still have a valid ativo
      this.AtvAtivo.findOne({
        filter: {
          where: {
            id: ativoId,
            active: 1
          }
        }
      }).$promise.then((data) => {
        // Check if we still have a valid interação tipo Id
        this.AtvInteracao.getUsableInteracoesTipo({
          ativoId: data.id
        }).$promise.then((interacoesTipo) => {
          if (interacoesTipo.find(i => i.id === res.auxInteracaoTipo.selected.id)) {
            switch (res.auxInteracaoTipo.selected.id) {
              case 1: // Movimentação
                // Check if Local still exists
                this.AtvLocal.find({
                  filter: {
                    where: {
                      id: res.auxLocal.selected.id,
                      active: 1
                    }
                  }
                }).$promise.then((local) => {
                  if (local && local.length > 0) {
                    data.local = res.auxLocal.selected;
                    data.localId = res.auxLocal.selected.id;
                    data.$save().then((ativo) => {
                      this.AtvInteracao.create({
                        id: 0,
                        ativoId: ativo.id,
                        interacaoTipoId: res.auxInteracaoTipo.selected.id,
                        dataRegisto: moment().isDST() ? moment().add(1, 'h') : moment(),
                        dataInteracao: res.interacao.dataInteracao,
                        funcionarioId: this.Auth.getId(),
                        localId: res.auxLocal.selected.id,
                        observacoes: res.interacao.observacoes,
                        active: 1
                      }).$promise.then((interacao) => {
                        this.UI.addToast("Interação criada com sucesso");
                        resolve(interacao);
                      }).catch((error) => {
                        this.loaded = true;
                        console.log(error);
                        this.UI.addToast("Não foi possível criar interação, mas ativo foi atualizado");
                        reject(error);
                      });
                    }, (error) => {
                      this.loaded = true;
                      console.log(error);
                      this.UI.addToast("Não foi possível atualizar local. Por favor tente mais tarde");
                      reject(error);
                    });
                  } else {
                    this.loaded = true;
                    this.UI.addToast("Local já não existe para esta entidade proprietária.");
                    reject();
                  }
                }).catch((error) => {
                  this.loaded = true;
                  console.log(error);
                  this.UI.addToast("Não foi possível verificar existência de local. Por favor tente mais tarde.");
                  reject(error);
                });
                break;
              case 2: // Recebido de Calibração
              case 3: // Recebido de Manutenção
              case 4: // Recebido de Verificação
              case 10: // Recebido de Empréstimo
                if (res.auxLocal.selected) {
                  // Check if Local still exists
                  this.AtvLocal.find({
                    filter: {
                      where: {
                        id: res.auxLocal.selected.id,
                        active: 1
                      }
                    }
                  }).$promise.then((local) => {
                    if (local && local.length > 0) {
                      // SPECIAL CASE FOR Recebido de Empréstimo - always remove afetoa (since it was returned)
                      // Other cases it's not really necessary
                      if (res.auxInteracaoTipo.selected.id === 10) {
                        delete data.afetoa;
                        data.afetoaId = null;
                      }
                      data.local = res.auxLocal.selected;
                      data.localId = res.auxLocal.selected.id;
                      data.$save().then((ativo) => {
                        let d = moment().isDST() ? moment().add(1, 'h') : moment();
                        this.AtvInteracao.create({
                          id: 0,
                          ativoId: ativo.id,
                          interacaoTipoId: res.auxInteracaoTipo.selected.id, // Recebido
                          resultadoId: res.auxResultado.selected.id,
                          dataRegisto: d,
                          dataInteracao: res.interacao.dataInteracao,
                          funcionarioId: this.Auth.getId(),
                          localId: null,
                          dataIntervencao: res.interacao.dataIntervencao,
                          observacoes: res.interacao.observacoes,
                          active: 1
                        }).$promise.then((interacao1) => {
                          this.AtvInteracao.create({
                            id: 0,
                            ativoId: ativo.id,
                            interacaoTipoId: 1, // Movimentação
                            dataRegisto: d.add(1, 's'), // So it's actually registered after
                            dataInteracao: res.interacao.dataInteracao.add(1, 's'),
                            funcionarioId: this.Auth.getId(),
                            localId: res.auxLocal.selected.id,
                            observacoes: res.interacao.observacoes,
                            active: 1
                          }).$promise.then((interacao2) => {
                            this.UI.addToast("Interação criada com sucesso");
                            resolve(interacao2);
                          }).catch((error) => {
                            this.loaded = true;
                            console.log(error);
                            this.UI.addToast("Não foi possível concluir a interação de movimentação. Por favor realize-a mais tarde.");
                            reject(error);
                          });
                        }).catch((error) => {
                          this.loaded = true;
                          console.log(error);
                          this.UI.addToast("Não foi possível criar interação, mas ativo foi atualizado");
                          reject(error);
                        });
                      }, (error) => {
                        this.loaded = true;
                        console.log(error);
                        this.UI.addToast("Não foi possível atualizar local. Por favor tente mais tarde");
                        reject(error);
                      });
                    } else {
                      this.loaded = true;
                      this.UI.addToast("Local já não existe para esta entidade proprietária.");
                      reject();
                    }
                  }).catch((error) => {
                    this.loaded = true;
                    console.log(error);
                    this.UI.addToast("Não foi possível verificar existência de local. Por favor tente mais tarde.");
                    reject(error);
                  });
                } else { // We are using Funcionario
                  data.afetoa = res.auxFuncionario.selected;
                  data.afetoaId = res.auxFuncionario.selected.id;
                  data.$save().then((ativo) => {
                    let d = moment().isDST() ? moment().add(1, 'h') : moment();
                    this.AtvInteracao.create({
                      id: 0,
                      ativoId: ativo.id,
                      interacaoTipoId: res.auxInteracaoTipo.selected.id, // Recebido
                      resultadoId: res.auxResultado.selected.id,
                      dataRegisto: d,
                      dataInteracao: res.interacao.dataInteracao,
                      funcionarioId: this.Auth.getId(),
                      atribuidoaId: null,
                      dataIntervencao: res.interacao.dataIntervencao,
                      observacoes: res.interacao.observacoes,
                      active: 1
                    }).$promise.then((interacao1) => {
                      this.AtvInteracao.create({
                        id: 0,
                        ativoId: ativo.id,
                        interacaoTipoId: res.auxTipoEntrega.selected.id, // Entrega or Emprestimo
                        dataRegisto: d.add(1, 's'), // So it's actually registered after
                        dataInteracao: res.interacao.dataInteracao.add(1, 's'),
                        funcionarioId: this.Auth.getId(),
                        atribuidoaId: res.auxFuncionario.selected.id,
                        duracao: res.interacao.duracao ? res.interacao.duracao : null,
                        notificar: res.interacao.notificar ? res.interacao.notificar : null,
                        observacoes: res.interacao.observacoes,
                        active: 1
                      }).$promise.then((interacao2) => {
                        // If Entrega / Devolução, alert of notification
                        this.UI.addToast("Interação criada com sucesso");
                        if (interacao2.notificationSent === 1) {
                          this.UI.addToast("Notificação por email enviada");
                        } else if (interacao2.notificationSent === 0) {
                          this.UI.showAlert("Interação de entrega realizada, mas ocorreu um erro no envio da notificação por email.\nPor favor faça esse envio manualmente.");
                        }
                        resolve(interacao2);
                      }).catch((error) => {
                        this.loaded = true;
                        console.log(error);
                        this.UI.addToast("Não foi possível concluir a interação de entrega. Por favor realize-a mais tarde.");
                        reject(error);
                      });
                    }).catch((error) => {
                      this.loaded = true;
                      console.log(error);
                      this.UI.addToast("Não foi possível criar interação, mas ativo foi atualizado");
                      reject(error);
                    });
                  }, (error) => {
                    this.loaded = true;
                    console.log(error);
                    this.UI.addToast("Não foi possível atualizar local. Por favor tente mais tarde");
                    reject(error);
                  });
                }
                break;
              case 5: // Enviado para Calibração
              case 6: // Enviado para Manutenção
              case 7: // Enviado para Verificação
                data.afetoa = null;
                data.afetoaId = null;
                data.local = null;
                data.localId = null;
                data.$save().then((ativo) => {
                  let hasFornecedorExterno = !!res.interacao.fornecedorExterno;
                  if (hasFornecedorExterno) {
                    this.AtvFornecedorPrimavera.getFornecedorPrimaveraId({
                      params: {
                        entidadeProprietariaId: data.entidadeProprietariaId,
                        fornecedor: res.auxFornecedor.selected.fornecedor
                      }
                    }).$promise.then(afp => {
                      this.AtvInteracao.create({
                        id: 0,
                        ativoId: ativo.id,
                        interacaoTipoId: res.auxInteracaoTipo.selected.id,
                        dataRegisto: moment().isDST() ? moment().add(1, 'h') : moment(),
                        dataInteracao: res.interacao.dataInteracao,
                        funcionarioId: this.Auth.getId(),
                        fornecedorExterno: true, // false or true
                        fornecedorPrimaveraId: afp.id,
                        observacoes: res.interacao.observacoes,
                        active: 1
                      }).$promise.then((interacao) => {
                        this.UI.addToast("Interação criada com sucesso");
                        resolve(interacao);
                      }).catch((error) => {
                        this.loaded = true;
                        console.log(error);
                        this.UI.addToast("Não foi possível criar interação, mas ativo foi atualizado");
                        reject(error);
                      });
                    }).catch(error => {
                      console.log(error);
                      this.UI.addToast("Não foi possível criar interação. Erro na criação de fornecedor");
                    });
                  } else { // No Fornecedor Externo, regular interacao only
                    this.AtvInteracao.create({
                      id: 0,
                      ativoId: ativo.id,
                      interacaoTipoId: res.auxInteracaoTipo.selected.id,
                      dataRegisto: moment().isDST() ? moment().add(1, 'h') : moment(),
                      dataInteracao: res.interacao.dataInteracao,
                      funcionarioId: this.Auth.getId(),
                      fornecedorExterno: false,
                      fornecedorPrimaveraId: null,
                      observacoes: res.interacao.observacoes,
                      active: 1
                    }).$promise.then((interacao) => {
                      this.UI.addToast("Interação criada com sucesso");
                      resolve(interacao);
                    }).catch((error) => {
                      this.loaded = true;
                      console.log(error);
                      this.UI.addToast("Não foi possível criar interação, mas ativo foi atualizado");
                      reject(error);
                    });
                  }
                }, (error) => {
                  this.loaded = true;
                  console.log(error);
                  this.UI.addToast("Não foi possível atualizar ativo. Por favor tente mais tarde");
                  reject(error);
                });
                break;
              case 8: // Entrega
              case 11: // Empréstimo
                data.local = null;
                data.localId = null;
                data.afetoa = res.auxFuncionario.selected;
                data.afetoaId = res.auxFuncionario.selected.id;
                data.$save().then((ativo) => {
                  this.AtvInteracao.create({
                    id: 0,
                    ativoId: ativo.id,
                    interacaoTipoId: res.auxInteracaoTipo.selected.id,
                    dataRegisto: moment().isDST() ? moment().add(1, 'h') : moment(),
                    dataInteracao: res.interacao.dataInteracao,
                    funcionarioId: this.Auth.getId(),
                    atribuidoaId: res.auxFuncionario.selected.id,
                    duracao: res.interacao.duracao ? res.interacao.duracao : null,
                    notificar: res.interacao.notificar ? res.interacao.notificar : null,
                    observacoes: res.interacao.observacoes,
                    active: 1
                  }).$promise.then((interacao) => {
                    // If Entrega / Devolução, alert of notification
                    this.UI.addToast("Interação criada com sucesso");
                    if (interacao.notificationSent === 1) {
                      this.UI.addToast("Notificação por email enviada");
                    } else if (interacao.notificationSent === 0) {
                      this.UI.showAlert("Interação de entrega realizada, mas ocorreu um erro no envio da notificação por email.\nPor favor faça esse envio manualmente.");
                    }
                    resolve(interacao);
                  }).catch((error) => {
                    this.loaded = true;
                    console.log(error);
                    this.UI.addToast("Não foi possível criar interação, mas ativo foi atualizado");
                    reject(error);
                  });
                }, (error) => {
                  this.loaded = true;
                  console.log(error);
                  this.UI.addToast("Não foi possível atualizar local. Por favor tente mais tarde");
                  reject(error);
                });
                break;
              case 9: // Devolução
                if (res.auxLocal.selected) {
                  // Check if Local still exists
                  this.AtvLocal.find({
                    filter: {
                      where: {
                        id: res.auxLocal.selected.id,
                        active: 1
                      }
                    }
                  }).$promise.then((local) => {
                    if (local && local.length > 0) {
                      data.afetoa = null;
                      data.afetoaId = null;
                      data.local = res.auxLocal.selected;
                      data.localId = res.auxLocal.selected.id;
                      data.$save().then((ativo) => {
                        let d = moment().isDST() ? moment().add(1, 'h') : moment();
                        this.AtvInteracao.create({
                          id: 0,
                          ativoId: ativo.id,
                          interacaoTipoId: res.auxInteracaoTipo.selected.id, // Devolução
                          dataRegisto: d,
                          dataInteracao: res.interacao.dataInteracao,
                          motivoDevolucao: res.interacao.motivoDevolucao,
                          funcionarioId: this.Auth.getId(),
                          localId: null,
                          observacoes: res.interacao.observacoes,
                          active: 1
                        }).$promise.then((interacao1) => {
                          // If Entrega / Devolução, alert of notification
                          this.UI.addToast("Interação criada com sucesso");
                          if (interacao1.notificationSent === 1) {
                            this.UI.addToast("Notificação por email enviada");
                          } else if (interacao1.notificationSent === 0) {
                            this.UI.showAlert("Interação de devolução realizada, mas ocorreu um erro no envio da notificação por email.\nPor favor faça esse envio manualmente.");
                          }
                          this.AtvInteracao.create({
                            id: 0,
                            ativoId: ativo.id,
                            interacaoTipoId: 1, // Movimentação
                            dataRegisto: d.add(1, 's'), // So it's actually registered after
                            dataInteracao: res.interacao.dataInteracao.add(1, 's'),
                            funcionarioId: this.Auth.getId(),
                            localId: res.auxLocal.selected.id,
                            observacoes: res.interacao.observacoes,
                            active: 1
                          }).$promise.then((interacao2) => {
                            this.UI.addToast("Interação criada com sucesso");
                            resolve(interacao2);
                          }).catch((error) => {
                            this.loaded = true;
                            console.log(error);
                            this.UI.addToast("Não foi possível concluir a interação de movimentação. Por favor realize-a mais tarde.");
                            reject(error);
                          });
                        }).catch((error) => {
                          this.loaded = true;
                          console.log(error);
                          this.UI.addToast("Não foi possível criar interação, mas ativo foi atualizado");
                          reject(error);
                        });
                      }, (error) => {
                        this.loaded = true;
                        console.log(error);
                        this.UI.addToast("Não foi possível atualizar local. Por favor tente mais tarde");
                        reject(error);
                      });
                    } else {
                      this.loaded = true;
                      this.UI.addToast("Local já não existe para esta entidade proprietária.");
                      reject();
                    }
                  }).catch((error) => {
                    this.loaded = true;
                    console.log(error);
                    this.UI.addToast("Não foi possível verificar existência de local. Por favor tente mais tarde.");
                    reject(error);
                  });
                } else { // We are using Funcionario
                  data.local = null;
                  data.localId = null;
                  data.afetoa = res.auxFuncionario.selected;
                  data.afetoaId = res.auxFuncionario.selected.id;
                  data.$save().then((ativo) => {
                    let d = moment().isDST() ? moment().add(1, 'h') : moment();
                    this.AtvInteracao.create({
                      id: 0,
                      ativoId: ativo.id,
                      interacaoTipoId: res.auxInteracaoTipo.selected.id, // Devolução
                      dataRegisto: d,
                      dataInteracao: res.interacao.dataInteracao,
                      motivoDevolucao: res.interacao.motivoDevolucao,
                      funcionarioId: this.Auth.getId(),
                      atribuidoaId: null,
                      observacoes: res.interacao.observacoes,
                      active: 1
                    }).$promise.then((interacao1) => {
                      // If Entrega / Devolução, alert of notification
                      this.UI.addToast("Interação criada com sucesso");
                      if (interacao1.notificationSent === 1) {
                        this.UI.addToast("Notificação por email enviada");
                      } else if (interacao1.notificationSent === 0) {
                        this.UI.showAlert("Interação de devolução realizada, mas ocorreu um erro no envio da notificação por email.\nPor favor faça esse envio manualmente.");
                      }
                      this.AtvInteracao.create({
                        id: 0,
                        ativoId: ativo.id,
                        interacaoTipoId: res.auxTipoEntrega.selected.id, // Entrega or Emprestimo
                        dataRegisto: d.add(1, 's'),
                        dataInteracao: res.interacao.dataInteracao.add(1, 's'),
                        funcionarioId: this.Auth.getId(),
                        atribuidoaId: res.auxFuncionario.selected.id,
                        duracao: res.interacao.duracao ? res.interacao.duracao : null,
                        notificar: res.interacao.notificar ? res.interacao.notificar : null,
                        observacoes: res.interacao.observacoes,
                        active: 1
                      }).$promise.then((interacao2) => {
                        // If Entrega / Devolução, alert of notification
                        this.UI.addToast("Interação criada com sucesso");
                        if (interacao2.notificationSent === 1) {
                          this.UI.addToast("Notificação por email enviada");
                        } else if (interacao2.notificationSent === 0) {
                          this.UI.showAlert("Interação de entrega realizada, mas ocorreu um erro no envio da notificação por email.\nPor favor faça esse envio manualmente.");
                        }
                        resolve(interacao2);
                      }).catch((error) => {
                        this.loaded = true;
                        console.log(error);
                        this.UI.addToast("Não foi possível concluir a interação de movimentação. Por favor realize-a mais tarde.");
                        reject(error);
                      });
                    }).catch((error) => {
                      this.loaded = true;
                      console.log(error);
                      this.UI.addToast("Não foi possível criar interação, mas ativo foi atualizado");
                      reject(error);
                    });
                  }, (error) => {
                    this.loaded = true;
                    console.log(error);
                    this.UI.addToast("Não foi possível atualizar local. Por favor tente mais tarde");
                    reject(error);
                  });
                }
                break;
            }
          } else {
            this.loaded = true;
            this.UI.addToast("Esta interação já não é válida para este ativo.");
            resolve();
          }
        }).catch(error => {
          this.loaded = true;
          console.log(error);
          this.UI.addToast("Erro na verificação de tipo de interação. Por favor tente mais tarde");
          reject(error);
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível encontrar o ativo. Por favor recarregue a página");
        reject(error);
      });
    });
  };

  cloneAtivoOptions = (locais) => {
    let maxClones = this.maxClones;
    return {
      size: 'md',
      template: require('./ativos/clone.dialog.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {
        $scope.nvezes = 1;
        $scope.maxClones = maxClones;
        $scope.locais = locais;

        $scope.auxLocal = {
          selected: null,
          infiniteScroll: {numToAdd: 20, currentItems: 20}
        };

        // Infinite Scroll magic
        $scope.addMoreItems = (infiniteScroll) => {
          infiniteScroll.currentItems += infiniteScroll.numToAdd;
        };

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.isOk = () => {
          return angular.isDefined($scope.nvezes) && $scope.nvezes > 0 && $scope.auxLocal.selected;
        }
      }]
    };
  };

  afterModalCloneAtivo = (r, res) => {
    return new Promise((resolve, reject) => {
      // Maximum number of clonings possible in one go
      if (res.nvezes > this.maxClones) res.nvezes = this.maxClones;
      (async () => {
        let clonagens = 0;
        for (let i = 0; i < res.nvezes; i++) {
          try {
            await this.AtvAtivo.clone({id: r.id, localId: res.auxLocal.selected.id}).$promise;
            clonagens++;
          } catch (e) {
            this.UI.addToast("Ocorreu um erro a clonar o ativo");
            console.log(e);
          }
        }
        let msgClone = "Ativo clonado " + clonagens + " vez";
        if (clonagens !== 1)
          msgClone += "es";
        this.UI.addToast(msgClone);
        resolve(clonagens);
      })();
    });
  };

  getCalibracaoExteriorValue = (id) => {
    let c = this.simnao2.find(x => x.value === id);
    if (c) {
      return c.name;
    } else return '-';
  };

  ativoDifferences = (oldData, newData) => {
    // Check native ativo relevant fields
    let differences = "";

    // Deal with text-base data
    differences += this.ativoDifference(differences, "Designação", oldData.designacao, newData.ativo.designacao);
    differences += this.ativoDifference(differences, "Referência", oldData.referencia, newData.ativo.referencia);
    differences += this.ativoDifference(differences, "Nº Série", oldData.numSerie, newData.ativo.numSerie);
    differences += this.ativoDifference(differences, "Modelo", oldData.modelo, newData.ativo.modelo);
    differences += this.ativoDifference(differences, "Especificações Técnicas", oldData.especificacoesTecnicas, newData.ativo.especificacoesTecnicas);
    differences += this.ativoDifference(differences, "Nº Imobilizado", oldData.numImobilizado, newData.ativo.numImobilizado);
    differences += this.ativoDifference(differences, "Valor de Compra", oldData.valorCompra, newData.ativo.valorCompra);
    differences += this.ativoDifference(differences, "MAC Address", oldData.macAddress, newData.ativo.macAddress);
    differences += this.ativoDifference(differences, "IMEI", oldData.imei, newData.ativo.imei);
    differences += this.ativoDifference(differences, "Firmware", oldData.firmware, newData.ativo.firmware);
    differences += this.ativoDifference(differences, "Licença de Software", oldData.licencaSoftware, newData.ativo.licencaSoftware);
    differences += this.ativoDifference(differences, "Acessórios", oldData.acessorios, newData.ativo.acessorios);
    differences += this.ativoDifference(differences, "Calibração no Exterior", this.getCalibracaoExteriorValue(oldData.calibracaoExterior), this.getCalibracaoExteriorValue(newData.ativo.calibracaoExterior));
    differences += this.ativoDifference(differences, "Observações", oldData.observacoes, newData.ativo.observacoes);
    differences += this.ativoDifference(differences, "Prazo de Validade", oldData.prazoValidade, newData.ativo.prazoValidade);
    differences += this.ativoDifference(differences, "Periodicidade de Calibração", oldData.periodicidadeCalibracao, newData.ativo.periodicidadeCalibracao);
    differences += this.ativoDifference(differences, "Periodicidade de Manutenção", oldData.periodicidadeManutencao, newData.ativo.periodicidadeManutencao);
    differences += this.ativoDifference(differences, "Periodicidade de Verificação", oldData.periodicidadeVerificacao, newData.ativo.periodicidadeVerificacao);

    // dete data
    differences += this.ativoDifference(differences, "Data de Compra",
      moment(oldData.dataCompra).isValid() ? moment(oldData.dataCompra).utc().startOf('d').format('DD/MM/YYYY') : null,
      moment(newData.ativo.dataCompra).isValid() ? moment(newData.ativo.dataCompra).utc().startOf('d').format('DD/MM/YYYY') : null);

    differences += this.ativoDifference(differences, "Data de Fabrico",
      moment(oldData.dataFabrico).isValid() ? moment(oldData.dataFabrico).utc().startOf('d').format('DD/MM/YYYY') : null,
      moment(newData.ativo.dataFabrico).isValid() ? moment(newData.ativo.dataFabrico).utc().startOf('d').format('DD/MM/YYYY') : null);

    differences += this.ativoDifference(differences, "Data de Fim de Garantia",
      moment(oldData.dataFimGarantia).isValid() ? moment(oldData.dataFimGarantia).utc().startOf('d').format('DD/MM/YYYY') : null,
      moment(newData.ativo.dataFimGarantia).isValid() ? moment(newData.ativo.dataFimGarantia).utc().startOf('d').format('DD/MM/YYYY') : null);

    differences += this.ativoDifference(differences, "Data de Validade",
      moment(oldData.dataValidade).isValid() ? moment(oldData.dataValidade).utc().startOf('d').format('DD/MM/YYYY') : null,
      moment(newData.ativo.dataValidade).isValid() ? moment(newData.ativo.dataValidade).utc().startOf('d').format('DD/MM/YYYY') : null);

    differences += this.ativoDifference(differences, "Data de Abate",
      moment(oldData.dataAbate).isValid() ? moment(oldData.dataAbate).utc().startOf('d').format('DD/MM/YYYY') : null,
      moment(newData.ativo.dataAbate).isValid() ? moment(newData.ativo.dataAbate).utc().startOf('d').format('DD/MM/YYYY') : null);

    differences += this.ativoDifference(differences, "Última Calibração",
      moment(oldData.ultimaCalibracao).isValid() ? moment(oldData.ultimaCalibracao).utc().startOf('d').format('DD/MM/YYYY') : null,
      moment(newData.ativo.ultimaCalibracao).isValid() ? moment(newData.ativo.ultimaCalibracao).utc().startOf('d').format('DD/MM/YYYY') : null);

    differences += this.ativoDifference(differences, "Última Manutenção",
      moment(oldData.ultimaManutencao).isValid() ? moment(oldData.ultimaManutencao).utc().startOf('d').format('DD/MM/YYYY') : null,
      moment(newData.ativo.ultimaManutencao).isValid() ? moment(newData.ativo.ultimaManutencao).utc().startOf('d').format('DD/MM/YYYY') : null);

    differences += this.ativoDifference(differences, "Última Verificação",
      moment(oldData.ultimaVerificacao).isValid() ? moment(oldData.ultimaVerificacao).utc().startOf('d').format('DD/MM/YYYY') : null,
      moment(newData.ativo.ultimaVerificacao).isValid() ? moment(newData.ativo.ultimaVerificacao).utc().startOf('d').format('DD/MM/YYYY') : null);

    // ui-select data
    // if (newData.auxTipoAtivo)
    //   differences += this.auxDataDifference(differences, "Tipo de Ativo",
    //     oldData.tipoAtivoId,
    //     oldData.tipoAtivo ? oldData.tipoAtivo.designacao : null,
    //     newData.auxTipoAtivo.selected ? newData.auxTipoAtivo.selected.id : null,
    //     newData.auxTipoAtivo.selected ? newData.auxTipoAtivo.selected.designacao : null);

    if (newData.auxMarca)
      differences += this.auxDataDifference(differences, "Marca",
        oldData.marcaId,
        oldData.marca ? oldData.marca.designacao : null,
        newData.auxMarca.selected ? newData.auxMarca.selected.id : null,
        newData.auxMarca.selected ? newData.auxMarca.selected.designacao : null);

    // This case is different: if not auxFornecedor.selected, don't bother to check, as it doesn't change
    if (oldData.fornecedorPrimavera && newData.auxFornecedor && newData.auxFornecedor.selected)
      differences += this.auxDataDifference(differences, "Fornecedor",
        oldData.fornecedorPrimavera.refFornecedorPrimavera,
        oldData.fornecedorPrimavera.nome + " (" + oldData.fornecedorPrimavera.refFornecedorPrimavera + ")",
        newData.auxFornecedor.selected ? newData.auxFornecedor.selected.fornecedor : null,
        newData.auxFornecedor.selected ? newData.auxFornecedor.selected.nome + " (" + newData.auxFornecedor.selected.fornecedor + ")" : null);

    if (newData.auxTamanho)
      differences += this.auxDataDifference(differences, "Tamanho",
        oldData.tamanhoId,
        oldData.tamanho ? oldData.tamanho.tamanho : null,
        newData.auxTamanho.selected ? newData.auxTamanho.selected.id : null,
        newData.auxTamanho.selected ? newData.auxTamanho.selected.tamanho : null);

    if (newData.auxEntidadeProprietaria)
      differences += this.auxDataDifference(differences, "Entidade Proprietária",
        oldData.entidadeProprietariaId,
        oldData.entidadeProprietaria ? oldData.entidadeProprietaria.designacao : null,
        newData.auxEntidadeProprietaria.selected ? newData.auxEntidadeProprietaria.selected.id : null,
        newData.auxEntidadeProprietaria.selected ? newData.auxEntidadeProprietaria.selected.designacao : null);

    if (newData.auxPropriedade)
      differences += this.auxDataDifference(differences, "Área Organizacional",
        oldData.propriedadeId,
        oldData.propriedade ? oldData.propriedade.designacao : null,
        newData.auxPropriedade.selected ? newData.auxPropriedade.selected.id : null,
        newData.auxPropriedade.selected ? newData.auxPropriedade.selected.designacao : null);

    if (newData.auxLocal)
      differences += this.auxDataDifference(differences, "Local",
        oldData.localId,
        oldData.local ? oldData.local.designacao : null,
        newData.auxLocal.selected ? newData.auxLocal.selected.id : null,
        newData.auxLocal.selected ? newData.auxLocal.selected.designacao : null);

    if (newData.auxSubfamilia)
      differences += this.auxDataDifference(differences, "Subfamília",
        oldData.subfamiliaId,
        oldData.subfamilia ? oldData.subfamilia.designacao : null,
        newData.auxSubfamilia.selected ? newData.auxSubfamilia.selected.id : null,
        newData.auxSubfamilia.selected ? newData.auxSubfamilia.selected.designacao : null);

    if (newData.auxEstadoCompra)
      differences += this.auxDataDifference(differences, "Estado na Compra",
        oldData.estadoCompraId,
        oldData.estadoCompra ? oldData.estadoCompra.designacao : null,
        newData.auxEstadoCompra.selected ? newData.auxEstadoCompra.selected.id : null,
        newData.auxEstadoCompra.selected ? newData.auxEstadoCompra.selected.designacao : null);

    return differences;
  };

  ativoDifference = (differences, name, oldValue, newValue) => {
    let d = "";
    if (oldValue !== newValue) {
      d += differences.length > 0 ? "\n" : "";
      d += "Alteração de " + name + ": " + (oldValue ? oldValue : '-') + " para " + (newValue ? newValue : '-');
    }
    return d;
  };

  auxDataDifference = (differences, name, oldId, oldDesignacao, newId, newDesignacao) => {
    let d = "";
    if (oldId !== newId) {
      d += differences.length > 0 ? "\n" : "";
      d += "Alteração de " + name + ": " + (oldDesignacao ? oldDesignacao : "-") + " para " + (newDesignacao ? newDesignacao : '-');
    }
    return d;
  };

  // To track differences in interacoes and save it to estado (for now only observacoes and file changes - this one is not dealt here)
  interacaoDifferences = (oldData, newData) => {
    // Check native ativo relevant fields
    let differences = "";

    // Deal with text-base data. Using ativoDifference because it's independent
    differences += this.ativoDifference(differences, "Observações", oldData.observacoes, newData.observacoes);

    return differences;
  };
}

AtvAtivosService.$inject = ['$q', 'UIService', 'AuthenticationService', 'AtvAtivo', 'AtvInteracao', 'AtvLocal', 'AtvEntidadeProprietaria', 'AtvFornecedorPrimavera', 'FuncionarioEntidadeProprietaria', 'PRIIEPFornecedores', 'PRIIEPFichaccusto', 'PRIIEPPlanocentros', 'PRIIEPFichas', 'PRIAJLFornecedores', 'PRIAJLFichaccusto', 'PRIAJLPlanocentros', 'PRIAJLFichas', 'PRIOBLERFornecedores', 'PRIOBLERFichaccusto', 'PRIOBLERPlanocentros', 'PRIOBLERFichas'];
