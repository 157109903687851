export function routes($stateProvider) {
  $stateProvider
    .state('app.agendamentos', {
      url: '/agendamentos',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.agendamentos.list', {
      url: '/?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Agendamento de Inspeções Elétricas',
      template: require('./directory/directory.view.html'),
      controller: 'DirectorySchedulingController',
      controllerAs: 'vm',
      role: 'agendaIeList',
      params: {
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        },
        type: {
          value: 0,
          dynamic: true
        }
      }
    })
    .state('app.agendamentos.calendarizacao', {
      url: '/calendarizacao',
      title: 'Calendarização',
      template: require('./calendarizacao/calendar.view.html'),
      controller: 'CalendarizacaoController',
      controllerAs: 'vm',
      role: 'agendaIeEdit'
    });
}

routes.$inject = ['$stateProvider'];
