import _ from "lodash";

export default class DashboardController {
  constructor($state, $timeout, UIService, AuthorizationService, AuthenticationService, DashboardIEService, DashboardFormacaoService, DashboardPADService) {
    this.$state = $state;
    this.$timeout = $timeout;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.user = AuthenticationService.getUser();
    this.DashboardIE = DashboardIEService;
    this.DashboardFormacao = DashboardFormacaoService;
    this.DashboardPAD = DashboardPADService;

    // Only load IE data if it belongs to IE
    if (AuthorizationService.belongsTo([4,5,8])) {
      // Loaders for IE - Gestores
      this.pedidosLoading = true;
      this.requisitantesLoading = true;
      this.pagamentosLoading = true;
      this.estadosLoading = true;
      this.getIEStats();
      // Loaders for IE - Técnicos
      this.ieCalendarOptions = this.getIEScheduleConfig();
      // Update event clicker to use Schedules
      this.ieCalendarOptions.eventClick = (calEvent, jsEvent, view) => {
        let ag = _.find(this.ieCalendarEvents, {'id': calEvent.id});
        this.$state.go('app.pedidos.details', {id: ag.pedidoId});
      };

      this.getIESchedules();
    }
    //Only load For data if it belongs to For
    if (AuthorizationService.belongsTo([11])) {
      this.formandosLoading = true;
      this.horasLoading = true;
      this.getForStats();
    }

    // PADs
    this.padsLoading = true;
    this.DashboardPAD.getDashboard().then(padData => {
      this.padData = padData;
      this.padsLoading = false;
    }).catch(error => {
      // Should never get here anyway
      console.log(error);
      this.padsLoading = false;
    });
  };

  showIEChart = (period, type) => {
    this[period].show.pedidos = false;
    this[period].show.requisitantes = false;
    this[period].show.pagamentos = false;
    this[period].show.pago = false;
    this[period].show[type] = true;
  };
  getIEStats = () => {

    this.ieStatsTabs = [{
      cId: 'daily',
      name: 'Diária',
    }, {
      cId: 'weekly',
      name: 'Semanal',
    }, {
      cId: 'monthly',
      name: 'Mensal'
    }, {
      cId: 'yearly',
      name: 'Anual'
    }, {
      cId: 'total',
      name: 'Total'
    }];

    this.weekly = {
      show: {
        pedidos: true,
        requisitantes: false,
        pagamentos: false,
        pago: false
      }
    };

    this.monthly = {
      show: {
        pedidos: true,
        requisitantes: false,
        pagamentos: false,
        pago: false
      }
    };

    this.yearly = {
      show: {
        pedidos: true,
        requisitantes: false,
        pagamentos: false,
        pago: false
      }
    };

    // Requests
    this.DashboardIE.getPedidos().then(res => {
      this.iePedidos = res;
      this.ieStatsTabs[1].cPedidos = this.DashboardIE.createChartFor(res.data.weekly.data, res.data.weekly.labels, 'weekly', 'Pedidos');
      this.ieStatsTabs[2].cPedidos = this.DashboardIE.createChartFor(res.data.monthly.data, res.data.monthly.labels, 'monthly', 'Pedidos');
      this.ieStatsTabs[3].cPedidos = this.DashboardIE.createChartFor(res.data.yearly.data, res.data.yearly.labels, 'yearly', 'Pedidos');
      this.pedidosLoading = false;
    });

    this.DashboardIE.getRequisitantes().then(res => {
      this.ieRequisitantes = res;
      this.ieStatsTabs[1].cClientes = this.DashboardIE.createChartFor(res.data.weekly.data, res.data.weekly.labels, 'weekly', 'Clientes');
      this.ieStatsTabs[2].cClientes = this.DashboardIE.createChartFor(res.data.monthly.data, res.data.monthly.labels, 'monthly', 'Clientes');
      this.ieStatsTabs[3].cClientes = this.DashboardIE.createChartFor(res.data.yearly.data, res.data.yearly.labels, 'yearly', 'Clientes');
      this.requisitantesLoading = false;
    });

    if (this.AuthorizationService.canPerform('analytics')) {
      this.DashboardIE.getPagamentos().then(res => {
        this.iePagamentos = res;
        this.ieStatsTabs[1].cPagamentos = this.DashboardIE.createChartFor(res.data.weekly.data, res.data.weekly.labels, 'weekly', 'Pagamentos');
        this.ieStatsTabs[1].cPago = this.DashboardIE.createChartFor(res.data.weekly.data2, res.data.weekly.labels, 'weekly', 'Pago');
        this.ieStatsTabs[2].cPagamentos = this.DashboardIE.createChartFor(res.data.monthly.data, res.data.monthly.labels, 'monthly', 'Pagamentos');
        this.ieStatsTabs[2].cPago = this.DashboardIE.createChartFor(res.data.monthly.data2, res.data.monthly.labels, 'monthly', 'Pago');
        this.ieStatsTabs[3].cPagamentos = this.DashboardIE.createChartFor(res.data.yearly.data, res.data.yearly.labels, 'yearly', 'Pagamentos');
        this.ieStatsTabs[3].cPago = this.DashboardIE.createChartFor(res.data.yearly.data2, res.data.yearly.labels, 'yearly', 'Pago');
        this.pagamentosLoading = false;
      });
    }
    this.DashboardIE.getEstados().then(res => {
      this.ieEstados = res;
      this.ieStatsTabs[4].cEstado = this.DashboardIE.createChartFor(res.data, res.labels, '', 'Estado');
      this.estadosLoading = false;
    })

    // TODO: Estados...
  };
  getIESchedules = () => {
    this.DashboardIE.getSchedules().then(sched => {
      this.ieCalendarEvents = sched;
    });
  };
  getIEScheduleConfig = () => {
    return this.DashboardIE.getScheduleConfig();
  };

  sumArray = (array) => {
    if (!array) return "-";
    return array.reduce((a,b) => a + b);
  };

  /** FORMACAO **/
  forChartOptions = {
    layout: {
      padding: {
        top: 24,
        right: 0,
        left: 0,
        bottom: 0
      }
    },
    tooltips: {
      enabled: true
    },
    hover: {
      animationDuration: 1
    },
  };

  selectForTab = (tab) => {
    this.forTab = tab;
  };
  getForStats = () => {
    this.forStatsTabs = [{
      cId: 'monthly',
      name: 'Mensal',
    }, {
      cId: 'quarterly',
      name: 'Trimestral',
    }, {
      cId: 'semesterly',
      name: 'Semestral'
    }, {
      cId: 'yearly',
      name: 'Anual'
    }, {
      cId: 'total',
      name: 'Total'
    }];
    this.DashboardFormacao.getFormandos().then(res => {
      this.forFormandos = res;
      this.formandosLoading = false;
    });
    this.DashboardFormacao.getHorasFormacao().then(res => {
      this.forHoras = res;
      this.horasLoading = false;
    })
  };

}

DashboardController.$inject = ['$state', '$timeout', 'UIService', 'AuthorizationService', 'AuthenticationService', 'DashboardIEService', 'DashboardFormacaoService', 'DashboardPADService'];
