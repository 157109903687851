import React, { useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';
function ParametroDialog({ initial, open, onClose }) {
    const validationSchema = yup.object({
        codigo: yup.string().required('O campo é obrigatório'),
        designacao: yup.string().required('O campo é obrigatório'),
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            onClose(values);
        }
    });
    useEffect(() => {
        formik.resetForm();
        formik.setValues(initial);
    }, [initial]);
    return (<Dialog fullWidth maxWidth={'lg'} open={open}>
      <DialogTitle>Gestão de parâmetro</DialogTitle>
      <DialogContent>
        <Box>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={3} xs={4}>
              <TextField label='Código' name='codigo' fullWidth defaultValue={formik.values?.codigo} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.codigo && Boolean(formik.errors.codigo)} helperText={formik.touched.codigo && formik.errors.codigo}/>
            </Grid>
            <Grid lg={9} xs={8}>
              <TextField label='Designação' name='designacao' defaultValue={formik.values?.designacao} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button type='button' onClick={() => onClose(undefined)}>
          Cancelar
        </Button>
        <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
          OK
        </Button>
      </DialogActions>
    </Dialog>);
}
export default ParametroDialog;
