export default class ATVInteracoesFileUploaderController {
  constructor($dialog, AtvDocumentoTipo, FileUploader, AtvInteracaoDocumento, UIService, interacaoId, ativoId, documento) {
    this.dialog = $dialog;
    this.UI = UIService;
    this.interacaoId = interacaoId;
    this.ativoId = ativoId;
    this.AtvInteracaoDocumento = AtvInteracaoDocumento;
    this.progress = 0;
    this.uploading = false;
    this.fileChanged = false; // See if file was changed to another file
    this.isEditing = angular.isDefined(documento);
    this.documento = documento;
    this.touchedFile = false; // If we are looking at a use
    this.emptyFile = !this.isEditing; // true is there's a file in the queue, false otherwise
    this.auxDocumentoTipo = {
      selected: documento ? documento.documentoTipo : null,
      infiniteScroll: {numToAdd: 20, currentItems: 20}
    };

    AtvDocumentoTipo.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(res => {
      this.tipos = res;
      this.loaded = true;
    });
    // Setup file uploader
    this.uploader = new FileUploader({
      url: '/api/Upload/atv/upload',
      queueLimit: 1
    });

    // Force queue limit = 1
    this.uploader.onAfterAddingFile = (item) => {
      this.fileChanged = true;
      this.touchedFile = true;
      this.emptyFile = false;
      item.file.nomeOriginal = item.file.name;
      if (this.uploader.queue.length > 1) {
        this.uploader.queue.splice(0, this.uploader.queue.length - 1);
      }
    };

    this.uploader.onWhenAddingFileFailed = () => {
      this.UI.addToast('Erro ao adicionar ficheiro');
      this.cancel();
    };

    this.uploader.onBeforeUploadItem = (item) => {
      let partes = item.file.name.split(".");
      item.file.name = this.generateUUID();
      if (partes.length > 1) item.file.name += "." + partes[partes.length - 1];
    };

    this.uploader.onSuccessItem = (item) => {
      if (this.isEditing) { // Editing documento, "remove" old, add new
        this.AtvInteracaoDocumento.find({
          filter: {
            where: {
              id: documento.id,
              active: 1
            }
          }
        }).$promise.then((d) => {
          if (d && d.length > 0) {
            // "Remove" older entry for file
            d[0].active = 0;
            d[0].$save().then((res) => {
              // Create new entry with replacement
              this.AtvInteracaoDocumento.create({
                id: 0,
                interacaoId: this.interacaoId,
                ativoId: this.ativoId,
                documentoTipoId: this.auxDocumentoTipo.selected.id,
                nomeOriginal: item.file.nomeOriginal,
                nome: item.file.name,
                active: 1
              }).$promise.then((res) => {
                this.dialog.close({res: res});
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("Não foi possível atualizar registo do documento");
              });
            }, (error) => {
              console.log(error);
              this.UI.addToast("Não foi possível guardar documento atualizado");
            });
          } else {
            this.UI.addToast("Não foi possível encontrar documento.");
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar documento");
        });
      } else { // New documento
        this.AtvInteracaoDocumento.create({
          id: 0,
          interacaoId: this.interacaoId,
          ativoId: this.ativoId,
          documentoTipoId: this.auxDocumentoTipo.selected.id,
          nomeOriginal: item.file.nomeOriginal,
          nome: item.file.name,
          active: true
        }).$promise.then((res) => {
          this.dialog.close({res: res});
        }).catch(error => {
          console.log(error);
          this.UI.addToast("Não foi possível carregar ficheiro");
        });
      }
    };

    this.uploader.onProgressItem = (item, progress) => {
      this.progress = progress;
    };

    this.uploader.onErrorItem = (response, status, headers) => {
      console.log(response);
      self.UI.addToast("Erro ao carregar ficheiro");
    };
  }

  disabledOk = () => {
    if (this.isEditing && (!this.touchedFile || !this.emptyFile)) return false;
    return this.uploader.queue.length === 0;
  };

  ok = () => {
    if (this.fileChanged) { // File changed
      this.uploading = true;
      this.progress = 0;
      this.uploader.uploadAll();
    } else { // File did not change, save other data
      this.AtvInteracaoDocumento.find({
        filter: {
          where: {
            id: this.documento.id,
            active: 1
          }
        }
      }).$promise.then((d) => {
        if (d && d.length > 0) {
          d[0].documentoTipo = this.auxDocumentoTipo.selected;
          d[0].documentoTipoId = this.auxDocumentoTipo.selected.id;
          d[0].$save().then((res) => {
            this.dialog.close({res: res});
          }, (error) => {
            console.log(error);
            this.UI.addToast("Não foi possível atualizar documento");
          });
        } else {
          this.UI.addToast("Não foi possível encontrar documento");
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível atualizar o documento");
      });
    }
  };

  clearFile = () => {
    this.emptyFile = true;
    this.touchedFile = true;
    if (this.uploader.queue && this.uploader.queue.length > 0)
      this.uploader.clearQueue();
  };

  cancel = () => {
    this.dialog.dismiss('cancel');
  };

  generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  // Infinite Scroll magic
  addMoreItems = (infiniteScroll) => {
    infiniteScroll.currentItems += infiniteScroll.numToAdd;
  };
}

ATVInteracoesFileUploaderController.$inject = ['$dialog', 'AtvDocumentoTipo', 'FileUploader', 'AtvInteracaoDocumento', 'UIService', 'interacaoId', 'ativoId', 'documento'];
