export default class AtvAtivosDetailsController {
  constructor($http, $filter, $state, UIService, AuthenticationService, AtvAtivosService, AtvAtivo, AtvAtivoAlteracaoEstado, AtvFornecedor, AtvFornecedorPrimavera, FuncionarioEntidadeProprietaria, Group, AtvLocal, AtvMarca, AtvPartesCorpo, AtvAtivoPartesCorpo, AtvPropriedade, AtvEntidadeProprietaria, AtvEstadoCompra, AtvTamanhoTipo, AtvTamanho, AtvTipoAtivo, AtvInteracao, AtvInteracaoTipo, AtvInteracoesService, AtvAtivoDocumento, AtvInteracaoDocumento, AtvSubfamilia) {
    this.$http = $http;
    this.$filter = $filter;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.AtvAtivosService = AtvAtivosService;
    this.$state = $state;
    this.AtvAtivo = AtvAtivo;
    this.AtvAtivoAlteracaoEstado = AtvAtivoAlteracaoEstado;
    this.FuncionarioEntidadeProprietaria = FuncionarioEntidadeProprietaria;
    this.Group = Group;
    this.AtvLocal = AtvLocal;
    this.AtvMarca = AtvMarca;
    this.AtvFornecedor = AtvFornecedor;
    this.AtvFornecedorPrimavera = AtvFornecedorPrimavera;
    this.AtvPartesCorpo = AtvPartesCorpo;
    this.AtvAtivoPartesCorpo = AtvAtivoPartesCorpo;
    this.AtvPropriedade = AtvPropriedade;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.AtvEstadoCompra = AtvEstadoCompra;
    this.AtvTamanhoTipo = AtvTamanhoTipo;
    this.AtvTamanho = AtvTamanho;
    // this.AtvTipoAtivo = AtvTipoAtivo;
    this.AtvInteracao = AtvInteracao;
    this.AtvInteracaoTipo = AtvInteracaoTipo;
    this.AtvInteracoesService = AtvInteracoesService;
    this.AtvAtivoDocumento = AtvAtivoDocumento;
    this.AtvInteracaoDocumento = AtvInteracaoDocumento;
    this.AtvSubfamilia = AtvSubfamilia;
    this.showEstadosPanel = false;
    this.simnao2 = [{name:"Não Aplicável", value: 2}, {name: "Sim", value: 1}, {name: "Não", value: 0}];
    this.loaded = false;

    // Default opt state - populate later when we have all states
    this.optInteracoes = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "dataRegisto",
      sort: "desc",
      filter: {},
      filterLayout: {}
    };
    this.interacoes = null;
    this.interacoesLoading = true;

    this.id = $state.params.id;
    this.motivosSuspensao = null;
    if (this.AtvAtivosService.usingPerms()) {
      this.FuncionarioEntidadeProprietaria.find({
        filter: {
          where: {
            funcionarioId: this.Auth.getId(),
            active: 1
          }
        }
      }).$promise.then((feps) => {
        feps = feps.map(f => f.entidadeProprietariaId);
        this.AtvAtivo.find({
          filter: {
            where: {
              id: this.id,
              entidadeProprietariaId: {inq: feps},
              active: 1
            }
          }
        }).$promise.then((a) => {
          if (a && a.length > 0) {
            this.loadData();
          } else {
            this.$state.go('app.ativos.ativos.list');
            this.UI.addToast("Não tem permissões para ver detalhes do Ativo.");
          }
        }).catch((error) => {
          console.log(error);
          this.$state.go('app.ativos.ativos.list');
          this.UI.addToast("Não foi possível encontrar ativo.");
        });
      }).catch((error) => {
        console.log(error);
        this.$state.go('app.ativos.ativos.list');
        this.UI.addToast("Erro ao verificar permissões. Por favor tente mais tarde.");
      });
    } else {
      this.loadData();
    }
    this.limit = 3;
  }

  toggleEstadosPanel = () => {
    this.showEstadosPanel = !this.showEstadosPanel;
  };

  setTimelineColor = (h) => {
    switch (h.estado.designacao) {
      case "Interação":
        return "project-standby";
      case "Criado":
        return "project-info";
      case "Alteração de dados":
      case "Abatido":
        return "project-notification";
      default:
        return "project-standby";
    }
  };

  limitTo = () => {
    if (angular.isDefined(this.limit)) {
      this.limit = undefined;
    } else {
      this.limit = 3;
    }
  };

  loadData = () => {
    this.loaded = false;
    this.AtvAtivo.findOne({
      filter: {
        where: {
          id: this.id,
          active: true
        },
        include: [{
          relation: 'afetoa',
        }, {
          relation: 'ativoBase',
          scope: {
            where: {
              active: 1
            },
            include: {
              relation: 'documentos',
              scope: {
                where: {
                  active: 1
                },
                order: 'id desc',
                include: {
                  relation: 'documentoTipo',
                }
              }
            }
          }
        }, {
          relation: 'criadopor',
        }, {
          relation: 'entidadeProprietaria',
        }, {
          relation: 'estadoCompra',
        }, {
          relation: 'marca',
        }, {
          relation: 'propriedade',
        }, {
          relation: 'tamanho',
          scope: {
            include: {
              relation: 'tamanhoTipo',
            }
          }
        // }, {
        //   relation: 'tipoAtivo',
        //   scope: {
        //     where: {
        //       active: 1
        //     }
        //   }
        }, {
          relation: 'familia',
        }, {
        }, {
          relation: 'subfamilia',
        }, {
          relation: 'fornecedor',
        }, {
          relation: 'fornecedorPrimavera',
          scope: {
            include: {
              relation: 'entidadeProprietaria',
              scope: {
                where: {
                  active: 1
                }
              }
            }
          }
        }, {
          relation: 'ativoPartesCorpo',
          scope: {
            where: {
              active: 1
            },
            include: {
              relation: 'parteCorpo',
              scope: {
                where: {
                  active: 1
                }
              }
            }
          }
        }, {
          relation: 'local',
        }, {
          relation: 'ultimaInteracao',
          scope: {
            where: {
              active: 1
            },
            include: {
              relation: 'tipo',
            }
          }
        }, {
          relation: 'documentos',
          scope: {
            where: {
              active: 1
            },
            order: 'id desc',
            include: {
              relation: 'documentoTipo',
            }
          }
        }, {
          relation: 'documentosInteracao',
          scope: {
            where: {
              active: 1
            },
            order: 'id desc',
            include: {
              relation: 'documentoTipo',
            }
          }
        }, {
          relation: 'alteracoesEstado',
          scope: {
            where: {
              active: 1
            },
            order: 'dataAlteracao desc, id desc',
            include: [{
              relation: 'estado',
            }, {
              relation: 'funcionario',
            }, {
              relation: 'interacao',
              scope: {
                where: {
                  active: 1
                },
                include: [{
                  relation: 'funcionario',
                }, {
                  relation: 'local',
                }, {
                  relation: 'resultado',
                }, {
                  relation: 'tipo',
                }, {
                  relation: 'atribuidoa',
                }]
              }
            }]
          }
        }]
      }
    }).$promise.then(res => {
      this.data = res;

      // Construct proxima intervencao for display
      if (this.data.ultimaCalibracao && this.data.periodicidadeCalibracao)
        this.data.proximaCalibracao = moment(this.data.ultimaCalibracao).utc().add(this.data.periodicidadeCalibracao, 'd').format("DD/MM/YYYY");
      if (this.data.ultimaManutencao && this.data.periodicidadeManutencao)
        this.data.proximaManutencao = moment(this.data.ultimaManutencao).utc().add(this.data.periodicidadeManutencao, 'd').format("DD/MM/YYYY");
      if (this.data.ultimaVerificacao && this.data.periodicidadeVerificacao)
        this.data.proximaVerificacao = moment(this.data.ultimaVerificacao).utc().add(this.data.periodicidadeVerificacao, 'd').format("DD/MM/YYYY");

      // Load documentos of Ativo
      this.data.documentos.forEach(d => {
        this.$http.get('/api/Upload/atv/files/' + d.nome).then(file => {
          if (file.data != null && !file.data.hasOwnProperty('error')) {
            d.atime = file.data.atime;
            d.ctime = file.data.ctime;
            d.fileSize = file.data.size;
          }
        }, (err) => {
          console.log(err);
        });
      });

      // Load documentos of Ativo Base if they exist
      if (this.data.ativoBase && this.data.ativoBase.documentos) {
        this.data.ativoBase.documentos.forEach(d => {
          this.$http.get('/api/Upload/atv/files/' + d.nome).then(file => {
            if (file.data != null && !file.data.hasOwnProperty('error')) {
              d.atime = file.data.atime;
              d.ctime = file.data.ctime;
              d.fileSize = file.data.size;
            }
          }, (err) => {
            console.log(err);
          });
        });
      }

      // Load documentos of Interacoes
      this.data.documentosInteracao.forEach(d => {
        this.$http.get('/api/Upload/atv/files/' + d.nome).then(file => {
          if (file.data != null && !file.data.hasOwnProperty('error')) {
            d.atime = file.data.atime;
            d.ctime = file.data.ctime;
            d.fileSize = file.data.size;
          }
        }, (err) => {
          console.log(err);
        });
      });

      this.data.alteracoesEstado.forEach(a => {
        a.dataAlteracao = moment(a.dataAlteracao).utc();
      });

      this.loaded = true;
    }).catch((error) => {
      console.log(error);
      this.$state.go('app.ativos.ativos.list');
      this.UI.addToast('Erro ao carregar ativo');
    });

    this.getInteracoesForAtivo();

  };

  editInformacao = () => {
    let self = this;
    let cenas = this.UI.showWaiting();
    this.AtvEntidadeProprietaria.find({
      filter: {
        where: {
          active: true
        },
        order: 'ordem ASC'
      }
    }).$promise.then(entidadesProprietarias => {
      this.AtvEstadoCompra.find({
        filter: {
          where: {
            active: true
          },
          order: 'ordem ASC'
        }
      }).$promise.then(estadosCompra => {
        this.AtvPartesCorpo.find({
          filter: {
            where: {
              active: true
            },
            order: 'ordem ASC'
          }
        }).$promise.then(partesCorpo => {
        this.AtvSubfamilia.find({
          filter: {
            where: {
              familiaId: this.data.familiaId,
              active: true
            },
            order: 'ordem ASC'
          }
        }).$promise.then(subfamilias => {
          cenas.close();
          let options = {
            size: 'lg',
            template: require('./informacao.dialog.html'),
            controller: ['$scope', ($scope) => {
              $scope.ativo = angular.copy(self.data);
              $scope.oldImobilizado = $scope.ativo.numImobilizado; // So we can know if it changed after OK
              $scope.usedImobilizado = false;
              $scope.auxEstadoCompra = {
                selected: $scope.ativo.estadoCompra,
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };
              $scope.currentEntidadeProprietaria = $scope.ativo.entidadeProprietaria;
              $scope.auxEntidadeProprietaria = {
                selected: $scope.currentEntidadeProprietaria,
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };
              $scope.auxPropriedade = {
                selected: $scope.ativo.propriedade ? $scope.ativo.propriedade : null,
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };
              $scope.auxLocal = {
                selected: $scope.ativo.local ? $scope.ativo.local : null,
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };
              $scope.auxSubfamilia = {
                selected: $scope.ativo.subfamilia ? $scope.ativo.subfamilia : null,
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };
              $scope.auxPartesCorpo = {
                selected: [],
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };
              // Cycle through ativoPartesCorpo and fill auxPartesCorpo with entries that come from relation
              $scope.ativo.ativoPartesCorpo.forEach(p => {
                $scope.auxPartesCorpo.selected.push(p.parteCorpo);
              });

              // Infinite Scroll magic
              $scope.addMoreItems = (infiniteScroll) => {
                infiniteScroll.currentItems += infiniteScroll.numToAdd;
              };
              $scope.subfamilias = subfamilias;
              $scope.entidadesProprietarias = entidadesProprietarias;
              $scope.estadosCompra = estadosCompra;
              $scope.partesCorpo = partesCorpo;

              $scope.onEntidadeProprietariaSelected = ($item) => {
                // EntidadeProprietaria is mandatory, so always goes to else
                if (!$item) {
                  $scope.currentEntidadeProprietaria = null;
                  $scope.propriedades = [];
                  $scope.auxPropriedade.selected = null;
                } else {
                  if (!$scope.currentEntidadeProprietaria || ($scope.currentEntidadeProprietaria && $scope.currentEntidadeProprietaria.id !== $item.id)) {
                    $scope.currentEntidadeProprietaria = $item;
                    $scope.updatePropriedade($item.id, true);
                    $scope.updateLocal($item.id, true);
                  }
                }
              };

              $scope.updatePropriedade = (entidadeProprietariaId, update) => {
                if (update) // Clean new if we are updating
                  $scope.auxPropriedade.selected = null;
                this.AtvPropriedade.find({
                  filter: {
                    where: {
                      entidadeProprietariaId: entidadeProprietariaId,
                      active: 1
                    },
                    order: 'ordem ASC'
                  }
                }).$promise.then((propriedades) => {
                  $scope.propriedades = propriedades;
                }).catch((error) => {
                  $scope.propriedades = [];
                  console.log(error);
                  this.UI.addToast("Não foi possível carregar áreas organizacionais. Por favor tente mais tarde");
                });
              };

              // Load propriedades of used entidadeProprietaria initially
              $scope.updatePropriedade($scope.ativo.entidadeProprietariaId, false);

              $scope.updateLocal = (entidadeProprietariaId, update) => {
                if (update) // Clean new if we are updating
                  $scope.auxLocal.selected = null;
                this.AtvLocal.find({
                  filter: {
                    where: {
                      entidadeProprietariaId: entidadeProprietariaId,
                      active: 1
                    },
                    order: 'designacao ASC'
                  }
                }).$promise.then((locais) => {
                  $scope.locais = locais;
                }).catch((error) => {
                  $scope.Locals = [];
                  console.log(error);
                  this.UI.addToast("Não foi possível carregar locais. Por favor tente mais tarde");
                });
              };

              // Load propriedades of used entidadeProprietaria initially
              $scope.updateLocal($scope.ativo.entidadeProprietariaId, false);


              // When editing Imobilizado, update Centro Custos
              $scope.setImobilizado = () => {
                // Check if already used
                this.AtvAtivo.find({
                  filter: {
                    fields: {id: true, numImobilizado: true, active: true},
                    where: {
                      id: {neq: $scope.ativo.id},
                      numImobilizado: $scope.ativo.numImobilizado,
                      active: 1
                    }
                  }
                }).$promise.then((res) => {
                  $scope.usedImobilizado = res.length > 0;
                  let PRIData = this.AtvAtivosService.getImobilizadoSource($scope.ativo.numImobilizado);
                  if (PRIData !== null) {
                    PRIData.PRIFichaccusto.find({
                      filter: {
                        where: {
                          bem: $scope.ativo.numImobilizado
                        }
                      }
                    }).$promise.then((fichasccusto) => {
                      if (fichasccusto && fichasccusto.length > 0) {
                        let fichaccusto = fichasccusto[0];
                        $scope.ativo.centroCustoCentro = fichaccusto.ccusto;
                        $scope.ativo.centroCustoAno = fichaccusto.exercicio;
                        PRIData.PRIPlanocentros.find({
                          filter: {
                            where: {
                              centro: fichaccusto.ccusto,
                              ano: fichaccusto.exercicio
                            }
                          }
                        }).$promise.then((planoscentros) => {
                          if (planoscentros && planoscentros.length > 0) {
                            let planocentro = planoscentros[0];
                            $scope.ativo.centroCustoDescricao = planocentro.descricao;
                          } else {
                            $scope.ativo.centroCustoDescricao = null;
                          }
                        }).catch((error) => {
                          console.log(error);
                          this.UI.addToast("Não foi possível obter dados do Primavera. Por favor tente mais tarde.");
                        });
                      } else { // Does not exist, wipe out CentroCusto information
                        $scope.ativo.centroCustoCentro = null;
                        $scope.ativo.centroCustoAno = null;
                        $scope.ativo.centroCustoDescricao = null;
                      }
                    }).catch((error) => {
                      console.log(error);
                      this.UI.addToast("Não foi possível obter dados do Primavera. Por favor tente mais tarde.");
                    });
                    // PRIData.PRIFichas.find({
                    //   filter: {
                    //     where: {
                    //       ficha: $scope.ativo.numImobilizado
                    //     }
                    //   }
                    // }).$promise.then((res) => {
                    //   if (res && res.length > 0) {
                    //     let ficha = res[0];
                    //     console.log(ficha);
                    //
                    //   } else { // Does not exist, wipe out CentroCusto information
                    //
                    //   }
                    // }).catch((error) => {
                    //   console.log(error);
                    //   this.UI.addToast("Não foi possível obter dados do Primavera. Por favor tente mais tarde.");
                    // });
                  } else {
                    this.UI.addToast("Não foi possível verificar imobilizado no Primavera. Por favor tente mais tarde.");
                  }
                }).catch((error) => {
                  console.log(error);
                  this.UI.addToast("Não foi possível verificar imobilizado no SGI. Por favor recarregue a página.");
                });
              };

              $scope.ok = () => {
                $scope.$close($scope);
              };

              $scope.cancel = () => $scope.$dismiss('cancel');
            }]
          };

          this.UI.showDialog(options).then(res => {
            this.loaded = false;
            if (res) {
              // Check if already using NumImobilizado elsewhere before inserting
              this.AtvAtivo.find({
                filter: {
                  fields: {id: true, numImobilizado: true, active: true},
                  where: {
                    id: {neq: res.ativo.id},
                    numImobilizado: res.ativo.numImobilizado,
                    active: 1
                  }
                }
              }).$promise.then((used) => {
                // Check if we already were using numImobilizado (unless we are already using it)
                if (used && used.length > 0 && res.oldImobilizado !== res.ativo.numImobilizado) {
                  this.UI.addToast("Imobilizado já foi inserido e não pode ser reutilizado. Por favor verifique os dados.");
                } else {
                  // If we are not using automatic numbering of ativo, but using numImobilizado for it, we need to make sure the number of Ativo === numImobilizado
                  // DEPRECATED by renumbering of ativos
                  // if (this.data.ativoPrefixoId === null) {
                  //   res.ativo.iidAtivo = null;
                  //   res.ativo.iidDisplay = res.ativo.numImobilizado;
                  // }
                  let differences = this.AtvAtivosService.ativoDifferences(this.data, res);
                  angular.extend(this.data, res.ativo);
                  // Deal with Estado na Compra
                  if (res.auxEstadoCompra.selected) {
                    this.data.estadoCompra = res.auxEstadoCompra.selected;
                    this.data.estadoCompraId = res.auxEstadoCompra.selected.id;
                  }
                  // Deal with Entidade Proprietaria
                  if (res.auxEntidadeProprietaria.selected) {
                    this.data.entidadeProprietaria = res.auxEntidadeProprietaria.selected;
                    this.data.entidadeProprietariaId = res.auxEntidadeProprietaria.selected.id;
                  }
                  // Deal with Propriedade
                  if (res.auxPropriedade.selected) {
                    this.data.propriedade = res.auxPropriedade.selected;
                    this.data.propriedadeId = res.auxPropriedade.selected.id;
                  } else {
                    this.data.propriedade = null;
                    this.data.propriedadeId = null;
                  }
                  // Deal with Local
                  if (res.auxLocal.selected) {
                    this.data.local = res.auxLocal.selected;
                    this.data.localId = res.auxLocal.selected.id;
                  } else {
                    this.data.local = null;
                    this.data.localId = null;
                  }
                  // Deal with Subfamília
                  if (res.auxSubfamilia.selected) {
                    this.data.subfamilia = res.auxSubfamilia.selected;
                    this.data.subfamiliaId = res.auxSubfamilia.selected.id;
                  } else {
                    this.data.subfamilia = null;
                    this.data.subfamiliaId = null;
                  }

                  // Deal with Partes do Corpo
                  // Remove old partes do corpo a proteger
                  this.AtvAtivoPartesCorpo.delete({
                    where: {
                      ativoId: this.id
                    }
                  }).$promise.then(() => {
                    // Add partes do corpo a proteger
                    let tasks = [];
                    res.auxPartesCorpo.selected.forEach(r => {
                      tasks.push(this.AtvAtivoPartesCorpo.create({
                        id: 0,
                        ativoId: this.id,
                        partesCorpoId: r.id,
                        active: 1
                      }).$promise);
                    });
                    Promise.all(tasks).then((r) => {
                      // Just update the ativo
                      this.updateAtivo(differences);
                    }).catch(err => {
                      console.log(err);
                      this.UI.addToast("Erro de atualização (Partes do corpo). Por favor tente mais tarde");
                      this.loaded = false;
                    });
                  }).catch((error) => {
                    console.log(error);
                    this.UI.addToast("Não foi possível configurar partes do corpo a proteger. Por favor tente mais tarde");
                    this.loaded = false;
                  });
                }
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("Não foi possível verificar se Imobilizado existe. Por favor recarregue a página");
                this.loaded = false;
              });
            }
          });
        }).catch(err => {
          console.log(err);
          this.UI.addToast("Não foi possível ler Subfamílias. Por favor tente mais tarde");
        });
        }).catch(err => {
          console.log(err);
          this.UI.addToast("Não foi possível ler Partes do Corpo. Por favor tente mais tarde");
        });
      }).catch(err => {
        console.log(err);
        this.UI.addToast("Não foi possível ler Estados de Compra. Por favor tente mais tarde");
      });
    }).catch(err => {
      console.log(err);
      this.UI.addToast("Não foi possível ler Entidades Proprietárias. Por favor tente mais tarde");
    });
  };

  editDatas = () => {
    let self = this;
    let options = {
      size: 'lg',
      template: require('./datas.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.ativo = angular.copy(self.data);
        $scope.dateOptions = {
          dataCompra: {format: 'DD/MM/YYYY'},
          dataFabrico: {format: 'DD/MM/YYYY'},
          dataFimGarantia: {format: 'DD/MM/YYYY'},
          dataValidade: {format: 'DD/MM/YYYY'},
          dataAbate: {format: 'DD/MM/YYYY'},
          ultimaCalibracao: {format: 'DD/MM/YYYY'},
          ultimaManutencao: {format: 'DD/MM/YYYY'},
          ultimaVerificacao: {format: 'DD/MM/YYYY'}
        };

        $scope.onDateUpdate = (dateType) => {
          let candidatesDataFabrico, candidatesDataCompra, candidatesDataFimGarantia, candidatesDataValidade,
            candidatesDataAbate;
          switch (dateType) {
            case 'dataCompra':
              candidatesDataFabrico = [$scope.ativo.dataCompra, $scope.ativo.dataFimGarantia, $scope.ativo.dataValidade, $scope.ativo.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
              if (candidatesDataFabrico && candidatesDataFabrico.length > 0) $scope.dateOptions.dataFabrico.maxDate = moment.min(candidatesDataFabrico); else $scope.dateOptions.dataFabrico.maxDate = false;
              candidatesDataAbate = [$scope.ativo.dataFabrico, $scope.ativo.dataCompra].filter(x => moment.isMoment(x) && x.isValid());
              if (candidatesDataAbate && candidatesDataAbate.length > 0) $scope.dateOptions.dataAbate.minDate = moment.max(candidatesDataAbate); else $scope.dateOptions.dataAbate.minDate = false;
              break;
            case 'dataFabrico':
              candidatesDataCompra = [$scope.ativo.dataFabrico, $scope.ativo.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
              if (candidatesDataCompra && candidatesDataCompra.length > 0) $scope.dateOptions.dataCompra.minDate = moment.max(candidatesDataCompra); else $scope.dateOptions.dataCompra.minDate = false;
              candidatesDataFimGarantia = [$scope.ativo.dataFabrico].filter(x => moment.isMoment(x) && x.isValid());
              if (candidatesDataFimGarantia && candidatesDataFimGarantia.length > 0) $scope.dateOptions.dataFimGarantia.minDate = moment.max(candidatesDataFimGarantia); else $scope.dateOptions.dataFimGarantia.minDate = false;
              candidatesDataValidade = [$scope.ativo.dataFabrico].filter(x => moment.isMoment(x) && x.isValid());
              if (candidatesDataValidade && candidatesDataValidade.length > 0) $scope.dateOptions.dataValidade.minDate = moment.max(candidatesDataValidade); else $scope.dateOptions.dataValidade.minDate = false;
              candidatesDataAbate = [$scope.ativo.dataFabrico, $scope.ativo.dataCompra].filter(x => moment.isMoment(x) && x.isValid());
              if (candidatesDataAbate && candidatesDataAbate.length > 0) $scope.dateOptions.dataAbate.minDate = moment.max(candidatesDataAbate); else $scope.dateOptions.dataAbate.minDate = false;
              break;
            case 'dataFimGarantia':
              candidatesDataFabrico = [$scope.ativo.dataCompra, $scope.ativo.dataFimGarantia, $scope.ativo.dataValidade, $scope.ativo.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
              if (candidatesDataFabrico && candidatesDataFabrico.length > 0) $scope.dateOptions.dataFabrico.maxDate = moment.min(candidatesDataFabrico); else $scope.dateOptions.dataFabrico.maxDate = false;
              break;
            case 'dataValidade':
              candidatesDataFabrico = [$scope.ativo.dataCompra, $scope.ativo.dataFimGarantia, $scope.ativo.dataValidade, $scope.ativo.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
              if (candidatesDataFabrico && candidatesDataFabrico.length > 0) $scope.dateOptions.dataFabrico.maxDate = moment.min(candidatesDataFabrico); else $scope.dateOptions.dataFabrico.maxDate = false;
              break;
            case 'dataAbate':
              candidatesDataFabrico = [$scope.ativo.dataCompra, $scope.ativo.dataFimGarantia, $scope.ativo.dataValidade, $scope.ativo.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
              if (candidatesDataFabrico && candidatesDataFabrico.length > 0) $scope.dateOptions.dataFabrico.maxDate = moment.min(candidatesDataFabrico); else $scope.dateOptions.dataFabrico.maxDate = false;
              candidatesDataCompra = [$scope.ativo.dataFabrico, $scope.ativo.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
              if (candidatesDataCompra && candidatesDataCompra.length > 0) $scope.dateOptions.dataCompra.minDate = moment.max(candidatesDataCompra); else $scope.dateOptions.dataCompra.minDate = false;
              break;
          }
        };

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    };
    this.UI.showDialog(options).then(res => {
      this.loaded = false;
      if (res) {
        let abatido = false;
        if (this.data.dataAbate == null && res.ativo.dataAbate) {
          abatido = true;
        }
        let differences = this.AtvAtivosService.ativoDifferences(this.data, res);
        angular.extend(this.data, res.ativo);

        this.updateAtivo(differences, abatido);
      }
    });
  };

  editAtivo = () => {
    let self = this;
    let cenas = this.UI.showWaiting();
    let PRIFornecedor = this.AtvAtivosService.getPrimaveraFornecedorForEntidadeProprietaria(this.data.entidadeProprietaria);
    this.AtvTamanhoTipo.find({
      filter: {
        where: {
          active: 1
        },
        order: 'designacao ASC'
      }
    }).$promise.then((tamanhosTipo) => {
      // this.AtvTipoAtivo.find({
      //   filter: {
      //     where: {
      //       active: true
      //     }
      //   }
      // }).$promise.then(tiposAtivo => {
        this.AtvMarca.find({
          filter: {
            where: {
              active: true
            }
          }
        }).$promise.then(marcas => {
          PRIFornecedor.find({
            filter: {
              fields: {fornecedor: true, nome: true},
              where: {},
              order: 'nome ASC'
            }
          }).$promise.then(fornecedores => {
            cenas.close();
            let options = {
              size: 'lg',
              template: require('./details.dialog.html'),
              controller: ['$scope', ($scope) => {
                $scope.ativo = angular.copy(self.data);
                // $scope.auxTipoAtivo = {
                //   selected: $scope.ativo.tipoAtivo,
                //   infiniteScroll: {numToAdd: 20, currentItems: 20}
                // };
                $scope.auxMarca = {
                  selected: $scope.ativo.marca,
                  infiniteScroll: {numToAdd: 20, currentItems: 20}
                };
                $scope.auxFornecedor = {
                  selected: null,
                  infiniteScroll: {numToAdd: 20, currentItems: 20}
                };

                $scope.currentTamanhoTipo = $scope.ativo.tamanho ? $scope.ativo.tamanho.tamanhoTipo : null;
                $scope.auxTamanhoTipo = {
                  selected: $scope.currentTamanhoTipo,
                  infiniteScroll: {numToAdd: 20, currentItems: 20}
                };
                $scope.auxTamanho = {
                  selected: $scope.ativo.tamanho ? $scope.ativo.tamanho : null,
                  infiniteScroll: {numToAdd: 20, currentItems: 20}
                };
                $scope.tamanhosTipo = tamanhosTipo;
                // $scope.tiposAtivo = tiposAtivo;
                $scope.marcas = marcas;
                $scope.fornecedores = fornecedores;
                $scope.simnao2 = this.simnao2;

                $scope.auxCalibracaoExterior = {
                  selected: this.simnao2.find(x => x.value === $scope.ativo.calibracaoExterior),
                  infiniteScroll: {numToAdd: 20, currentItems: 20}
                };

                // Infinite Scroll magic
                $scope.addMoreItems = (infiniteScroll) => {
                  infiniteScroll.currentItems += infiniteScroll.numToAdd;
                };

                // For Marcas, update value selected
                $scope.refreshMarcas = (aux, search) => {
                  if (search.length > 0) {
                    let aux = $scope.marcas.find(m => m.designacao.toUpperCase() === search.toUpperCase());
                    if (!aux)
                      aux = {
                        id: 0,
                        designacao: search,
                        active: 1
                      };
                    $scope.auxMarca.selected = aux;
                  } else {
                    // If we have a *new* one, erase it
                    if ($scope.auxMarca.selected && $scope.auxMarca.selected.id === 0)
                      $scope.auxMarca.selected = null;
                  }
                };

                $scope.onTamanhoTipoSelected = ($item) => {
                  // Removed TamanhoTipo, wipe Tamanho
                  if (!$item) {
                    $scope.currentTamanhoTipo = null;
                    $scope.tamanhos = [];
                    $scope.auxTamanho.selected = null;
                  } else { // Updating TamanhoTipo, clear Tamanho and select appropriate list of tamanhos
                    if (!$scope.currentTamanhoTipo || ($scope.currentTamanhoTipo && $scope.currentTamanhoTipo.id !== $item.id)) {
                      $scope.currentTamanhoTipo = $item;
                      $scope.updateTamanho($item.id, true);
                    }
                  }
                };

                $scope.updateTamanho = (tamanhoTipoId, update) => {
                  if (update) // Clean new if we are updating
                    $scope.auxTamanho.selected = null;
                  this.AtvTamanho.find({
                    filter: {
                      where: {
                        tamanhoTipoId: tamanhoTipoId,
                        active: 1
                      },
                      order: 'ordem ASC'
                    }
                  }).$promise.then((tamanhos) => {
                    $scope.tamanhos = tamanhos;
                  }).catch((error) => {
                    $scope.tamanhos = [];
                    console.log(error);
                    this.UI.addToast("Não foi possível carregar tamanhos. Por favor tente mais tarde");
                  });
                };

                // Load tamanhos of same type initially
                if ($scope.ativo.tamanho)
                  $scope.updateTamanho($scope.ativo.tamanho.tamanhoTipoId, false);

                $scope.ok = () => {
                  $scope.$close($scope);
                };

                $scope.cancel = () => $scope.$dismiss('cancel');
              }]
            };
            this.UI.showDialog(options).then(res => {
              this.loaded = false;
              if (res) {
                // Deal with Calibracao Exterior right away to account ativo differences
                if (res.auxCalibracaoExterior.selected) {
                  res.ativo.calibracaoExterior = res.auxCalibracaoExterior.selected.value;
                }

                let differences = this.AtvAtivosService.ativoDifferences(this.data, res);
                angular.extend(this.data, res.ativo);

                // Deal with Tipo de Ativo
                // if (res.auxTipoAtivo.selected) {
                //   this.data.tipoAtivo = res.auxTipoAtivo.selected;
                //   this.data.tipoAtivoId = res.auxTipoAtivo.selected.id;
                // }
                // Deal with Tamanho
                if (res.auxTamanho.selected) {
                  this.data.tamanho = res.auxTamanho.selected;
                  this.data.tamanhoId = res.auxTamanho.selected.id;
                } else { // Remove tamanho if not selected
                  this.data.tamanho = null;
                  this.data.tamanhoId = null;
                }

                if (res.auxMarca.selected) { // Marca selected
                  if (res.auxMarca.selected.id > 0) { // Marca already exists
                    this.data.marca = res.auxMarca.selected;
                    this.data.marcaId = res.auxMarca.selected.id;
                    this.updateFornecedorAtivo(res, differences);
                  } else if (res.auxMarca.selected.id === 0) { // New Marca
                    this.AtvMarca.find({
                      filter: {
                        where: {
                          designacao: res.auxMarca.selected.designacao,
                          active: 1
                        }
                      }
                    }).$promise.then((m) => {
                      if (m && m.length > 0) {
                        this.data.marca = m[0];
                        this.data.marcaId = m[0].id;
                        this.updateFornecedorAtivo(res, differences);
                      } else {
                        this.AtvMarca.create(res.auxMarca.selected).$promise.then((marcaCriada) => {
                          this.data.marca = marcaCriada;
                          this.data.marcaId = marcaCriada.id;
                          this.updateFornecedorAtivo(res, differences);
                        }).catch((error) => {
                          console.log(error);
                          this.UI.addToast("Não foi possível criar marca. Marca não atualizada. Por favor tente mais tarde");
                          this.updateFornecedorAtivo(res, differences);
                        });
                      }
                    }).catch((error) => {
                      console.log(error);
                      this.UI.addToast("Não foi possível criar marca. Marca não atualizada. Por favor tente mais tarde");
                      this.updateFornecedorAtivo(res, differences);
                    });
                  }
                } else { // Marca disabled
                  this.data.marca = null;
                  this.data.marcaId = null;
                  this.updateFornecedorAtivo(res, differences);
                }
              }
            });
          }).catch(err => {
            console.log(err);
            this.UI.addToast("Não foi possível ler Fornecedores. Por favor tente mais tarde");
          });
        }).catch(err => {
          console.log(err);
          this.UI.addToast("Não foi possível ler Marcas. Por favor tente mais tarde");
        });
      // }).catch(err => {
      //   console.log(err);
      //   this.UI.addToast("Não foi possível ler Tipos de Ativo. Por favor tente mais tarde");
      // });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível ler Tipos de Tamanhos. Por favor tente mais tarde");
    });
  };

  updateFornecedorAtivo = (res, differences) => {
    // Deal with Fornecedor
    if (res.auxFornecedor.selected) {
      this.AtvFornecedorPrimavera.find({
        filter: {
          where: {
            refFornecedorPrimavera: res.auxFornecedor.selected.fornecedor,
            entidadeProprietariaId: this.data.entidadeProprietariaId,
            active: 1
          },
          include: {
            relation: 'fornecedor',
            scope: {
              where: {
                active: 1
              }
            }
          }
        }
      }).$promise.then((fornecedores) => {
        if (fornecedores && fornecedores.length > 0) { // Exists, so use it
          this.data.fornecedor = fornecedores[0].fornecedor;
          this.data.fornecedorId = fornecedores[0].fornecedor.id;
          this.data.fornecedorPrimavera = fornecedores[0];
          this.data.fornecedorPrimaveraId = fornecedores[0].id;
          this.updateAtivo(differences);
        } else { // Does not exist, import it
          let PRIFornecedor = this.AtvAtivosService.getPrimaveraFornecedorForEntidadeProprietaria(this.data.entidadeProprietaria);
          if (PRIFornecedor != null) {
            PRIFornecedor.find({
              filter: {
                where: {
                  fornecedor: res.auxFornecedor.selected.fornecedor
                }
              }
            }).$promise.then((res) => {
              if (res && res.length > 0) {
                let f = res[0];
                // Check if we have a fornecedor with the same NIF
                this.AtvFornecedor.find({
                  filter: {
                    where: {
                      nif: f.numcontrib,
                      active: 1
                    }
                  }
                }).$promise.then((fornecedorSGI) => {
                  if (fornecedorSGI && fornecedorSGI.length > 0) { // Found AtvFornecedor, use it
                    this.AtvFornecedorPrimavera.create({
                      id: 0,
                      nome: f.nome,
                      fornecedorId: fornecedorSGI[0].id,
                      refFornecedorPrimavera: f.fornecedor,
                      entidadeProprietariaId: this.data.entidadeProprietariaId,
                      contactoTelefonico: f.tel,
                      morada: f.morada,
                      cp4: f.cp ? f.cp.split("-")[0] : null,
                      cp3: f.cp ? f.cp.split("-")[1] : null,
                      cploc: f.cploc,
                      observacoes: f.notas,
                      atualizadoa: f.dataultimaactualizacao,
                      localidade: f.local,
                      pais: f.pais,
                      active: 1
                    }).$promise.then((newFornecedorPrimavera) => {
                      this.UI.addToast("Fornecedor importado com sucesso");
                      this.data.fornecedor = fornecedorSGI[0];
                      this.data.fornecedorId = fornecedorSGI[0].id;
                      this.data.fornecedorPrimavera = newFornecedorPrimavera;
                      this.data.fornecedorPrimaveraId = newFornecedorPrimavera.id;
                      this.updateAtivo(differences);
                    }).catch((error) => {
                      console.log(error);
                      this.UI.addToast("Não foi possível importar fornecedor.");
                      this.updateAtivo(differences);
                      this.loaded = false;
                    });
                  } else { // No AtvFornecedor was found for NIF, create AtvFornecedor and associate it with AtvFornecedorPrimavera
                    this.AtvFornecedor.create({
                      id: 0,
                      nome: f.nome,
                      nif: f.numcontrib,
                      observacoes: f.notas,
                      atualizadoa: moment().isDST() ? moment().add(1, 'h') : moment(),
                      active: 1
                    }).$promise.then((atvFornecedor) => {
                      this.AtvFornecedorPrimavera.create({
                        id: 0,
                        nome: f.nome,
                        fornecedorId: atvFornecedor.id,
                        refFornecedorPrimavera: f.fornecedor,
                        entidadeProprietariaId: this.data.entidadeProprietariaId,
                        contactoTelefonico: f.tel,
                        morada: f.morada,
                        cp4: f.cp ? f.cp.split("-")[0] : null,
                        cp3: f.cp ? f.cp.split("-")[1] : null,
                        cploc: f.cploc,
                        observacoes: f.notas,
                        atualizadoa: f.dataultimaactualizacao,
                        localidade: f.local,
                        pais: f.pais,
                        active: 1
                      }).$promise.then((fornecedor) => {
                        this.UI.addToast("Fornecedor importado com sucesso");
                        this.data.fornecedor = atvFornecedor;
                        this.data.fornecedorId = atvFornecedor.id;
                        this.data.fornecedorPrimavera = fornecedor;
                        this.data.fornecedorPrimaveraId = fornecedor.id;
                        this.updateAtivo(differences);
                      }).catch((error) => {
                        console.log(error);
                        this.UI.addToast("Não foi possível importar fornecedor.");
                        this.loaded = false;
                      });
                    }).catch((error) => {
                      console.log(error);
                      this.UI.addToast("Não foi possível importar fornecedor. Por favor tente mais tarde");
                    });
                  }
                }).catch((error) => {
                  console.log(error);
                  this.UI.addToast("Não foi possível encontrar fornecedor. Por favor tente mais tarde");
                  this.updateAtivo(differences + "\nErro a alterar fornecedor.");
                });
              } else {
                this.UI.addToast("Fornecedor não encontrado para Entidade Proprietária. Por favor tente mais tarde");
                this.updateAtivo(differences + "\nErro a alterar Entidade Proprietária");
              }
            }).catch((error) => {
              console.log(error);
              this.UI.addToast("Não foi possível atualizar fornecedor.");
              this.updateAtivo(differences + "\nErro a alterar fornecedor");
            });
          } else {
            this.UI.addToast("Não foi possível atualizar fornecedor. Entidade Proprietária não encontrada");
            this.updateAtivo(differences + "\nErro a alterar fornecedor");
          }
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível alterar fornecedor. Por favor tente mais tarde");
        this.updateAtivo(differences + "\nErro a alterar fornecedor");
      });
    } else { // Just update the rest
      this.updateAtivo(differences);
    }
  };

  // differences - differences in editions ; abatido: if ativo is abatido (create state for it)
  updateAtivo = (differences, abatido) => {
    this.data.$save().then(() => {
      // See if there's differences and save it
      if (differences && differences.length > 0) {
        this.AtvAtivoAlteracaoEstado.create({
          id: 0,
          ativoEstadoId: 3, // Alteração de dados
          dataAlteracao: moment().isDST() ? moment().add(1, 'h') : moment(),
          ativoId: this.data.id,
          funcionarioId: this.Auth.getId(),
          alteracoes: differences,
          active: true
        }).$promise.then((res) => {
          if (abatido) { // Create state for abate of ativo
            this.AtvAtivoAlteracaoEstado.create({
              id: 0,
              ativoEstadoId: 4, // Abatido
              dataAlteracao: moment().isDST() ? moment().add(1, 'h') : moment(),
              ativoId: this.data.id,
              funcionarioId: this.Auth.getId(),
              alteracoes: null,
              active: true
            }).$promise.then((resAbatido) => {
              this.loadData();
              this.UI.addToast("Ativo editado com sucesso");
            }).catch((error) => {
              console.log(error);
              this.UI.addToast("Ativo editado, não foi possível criar estado abatido.");
              this.loadData();
            });
          } else {
            this.loadData();
            this.UI.addToast("Ativo editado com sucesso");
          }
        }).catch((error) => {
          console.log(error);
          this.loadData();
          this.UI.addToast("Ativo editado com sucesso. Erro ao guardar alteração de estado.");
        });
      } else {
        this.loadData();
        this.UI.addToast("Ativo editado com sucesso");
      }
    }).catch(() => {
      this.loadData();
      this.UI.addToast("Ocorreu um erro ao editar ativo");
    });
  };

  editObservations = () => {
    let a = angular.copy(this.data);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = a.observacoes;

        $scope.ok = () => $scope.$close($scope.observacoes || null);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      a.observacoes = res;
      let differences = this.AtvAtivosService.ativoDifferences(this.data, {ativo: a});
      this.data.observacoes = res;
      this.updateAtivo(differences);
    }).catch(() => {});
  };

  addInteracao = () => {
    this.AtvInteracao.getUsableInteracoesTipo({
      ativoId: parseInt(this.id)
    }).$promise.then((interacoesTipo) => {
      let options = this.AtvAtivosService.addInteracaoModalOptions(this.data, interacoesTipo);
      let modal = this.UI.showDialog(options);
      modal.then((res) => {
        this.loaded = false;
        if (res) {
          this.AtvAtivosService.afterAddInteracao(res, this.id).then(i => {
            this.loadData();
          }).catch(err => {
            console.log(err);
            this.loaded = true;
          });
        }
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível obter tipos de interação. Por favor tente mais tarde");
    });
  };

  getInteracoesForAtivo = () => {
    this.interacoesLoading = true;
    let whereLiteral = {
      'AtvInteracao.ativoId': this.id,
      'AtvInteracao.active': 1
    };
    let where = {};
    Object.keys(this.optInteracoes.filter).forEach(f => {
      if (this.optInteracoes.filter[f])
        where[f] = this.optInteracoes.filter[f];
    });
    this.AtvInteracao.table({
      params: {
        fields: [
          'AtvInteracao.id as id',
          'AtvInteracaoTipo.designacao as interacaoTipo',
          'AtvResultado.resultado as resultado',
          'AtvInteracao.dataRegisto as dataRegisto',
          'AtvInteracao.dataInteracao as dataInteracao',
          'AtvInteracao.motivoDevolucao as motivoDevolucao',
          'AtvLocal.designacao as local',
          'Funcionario.name as colaborador',
          'Funcionario2.name as atribuidoa'
        ],
        from: ['AtvInteracao', 'AtvInteracaoTipo', 'AtvResultado', 'AtvLocal', 'Funcionario', 'Funcionario'],
        references: [undefined, 'AtvInteracao.interacaoTipoId', 'AtvInteracao.resultadoId', 'AtvInteracao.localId', 'AtvInteracao.funcionarioId', 'AtvInteracao.atribuidoa_id'],
        aliases: [null, null, null, null, null, 'Funcionario2'],
        where: where,
        whereLiteral: whereLiteral,
        order: this.optInteracoes.order,
        sort: this.optInteracoes.sort,
        limit: this.optInteracoes.items,
        skip: (this.optInteracoes.page - 1) * this.optInteracoes.items,
      }
    }).$promise.then(res => {
      let page = this.optInteracoes.page;
      let items = this.optInteracoes.items;

      let total = res.count;

      this.optInteracoes.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.optInteracoes.start - 1 + items) >= total) {
        this.optInteracoes.end = total;
      } else {
        this.optInteracoes.end = Number.parseInt(this.optInteracoes.start - 1 + items);
      }

      this.interacoes = res.interacoes;
      // Process dates for UTC compliance
      this.interacoes.forEach(i => {
        i.dataRegistoLayout = moment(i.dataRegisto).utc().format("DD/MM/YYYY HH:mm:ss");
        i.dataInteracaoLayout = moment(i.dataInteracao).utc().format("DD/MM/YYYY HH:mm:ss");
      });
      this.optInteracoes.total = total;
      this.interacoesLoading = false;
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler interações. Por favor tente mais tarde");
    });
  };

  // Deal with dates
  applyFilterInteracoes = (field) => {
    // If field is a date field
    if (field === 'AtvInteracao.dataInteracao') {
      let parsedDate = this.optInteracoes.filterLayout[field].split(' ');
      if (parsedDate[0].includes('/'))
        parsedDate[0] = parsedDate[0].split('/').reverse().join('-');
      this.optInteracoes.filter[field] = parsedDate.join(' ');
    } else {
      this.optInteracoes.filter[field] = angular.copy(this.optInteracoes.filterLayout[field]);
    }
    this.optInteracoes.page = 1;
    this.getInteracoesForAtivo();
  };

  interacoesSort = keyname => {
    if (this.optInteracoes.order === keyname)
      this.optInteracoes.page = 1;
    this.optInteracoes.order = keyname;
    this.optInteracoes.sort = this.optInteracoes.sort === 'asc' ? 'desc' : 'asc';
    this.getInteracoesForAtivo();
  };

  interacoesItem = val => {
    this.optInteracoes.items = val;
    this.optInteracoes.page = 1;
    this.getInteracoesForAtivo();
  };

  interacoesPage = sum => {
    this.optInteracoes.page += sum;
    if (this.optInteracoes.page < 1)
      this.optInteracoes.page = 1;
    if (this.optInteracoes.page > Math.ceil(this.optInteracoes.total / this.optInteracoes.items))
      this.optInteracoes.page = Math.ceil(this.optInteracoes.total / this.optInteracoes.items);
    this.getInteracoesForAtivo();
  };

  viewInteracao = interacaoId => {
    this.$state.go('app.ativos.interacoes.details', {id: interacaoId});
  };

  // Format bytes into correct units
  formatBytes = (bytes, decimals) => {
    if (bytes == null) return "N/D";
    if (bytes === 0) return '0 Bytes';
    let k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  addAnexo = () => {
    let options = {
      size: 'lg',
      controller: 'AtvAtivosFileUploaderController',
      controllerAs: 'm',
      template: require('./files.dialog.html'),
      resolve: {
        ativoId: () => {
          return this.data.id;
        },
        documento: () => {
          return undefined;
        }
      }
    };

    let dialog = this.UI.showDialog(options);
    dialog.then(res => {
      if (res) {
        // Update state with file added
        this.AtvAtivoAlteracaoEstado.create({
          id: 0,
          ativoEstadoId: 3, // Alteração de dados
          dataAlteracao: moment().isDST() ? moment().add(1, 'h') : moment(),
          ativoId: this.data.id,
          funcionarioId: this.Auth.getId(),
          alteracoes: `Adição de anexo ${res.res.nomeOriginal}`,
          active: true
        }).$promise.then((s) => {
          this.loadData();
          this.UI.addToast('Documento carregado com sucesso');
        }).catch(error => {
          console.log(error);
          this.loadData();
          this.UI.addToast('Documento carregado com sucesso');
        });
      }
    });
  };

  editAnexo = (f) => {
    this.AtvAtivoDocumento.find({
      filter: {
        where: {
          id: f.id,
          active: 1
        },
        include: {
          relation: 'documentoTipo',
          scope: {
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((documento) => {
      if (documento && documento.length > 0) {
        let options = {
          size: 'lg',
          controller: 'AtvAtivosFileUploaderController',
          controllerAs: 'm',
          template: require('./files.dialog.html'),
          resolve: {
            ativoId: () => {
              return this.data.id;
            },
            documento: () => {
              return documento[0];
            }
          }
        };

        let dialog = this.UI.showDialog(options);
        dialog.then(res => {
          if (res) {
            // Update state with file edited
            this.AtvAtivoAlteracaoEstado.create({
              id: 0,
              ativoEstadoId: 3, // Alteração de dados
              dataAlteracao: moment().isDST() ? moment().add(1, 'h') : moment(),
              ativoId: this.data.id,
              funcionarioId: this.Auth.getId(),
              alteracoes: `Edição de anexo ${documento[0].nomeOriginal} para ${res.res.nomeOriginal}`,
              active: true
            }).$promise.then((s) => {
              this.loadData();
              this.UI.addToast('Documento atualizado com sucesso');
            }).catch(error => {
              console.log(error);
              this.loadData();
              this.UI.addToast('Documento atualizado com sucesso');
            });
          }
        });
      } else {
        this.UI.addToast("Anexo não encontrado. Por favor recarregue a página");
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar documento. Por favor recarregue a página");
    });
  };

  confirmRemoveAnexo = (f) => {
    let title = "Remover Anexo";
    let warning = `Tem a certeza que pretende remover o documento ${f.nomeOriginal}?`;
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeAnexo(f);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  removeAnexo = (f) => {
    this.AtvAtivoDocumento.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((documento) => {
      documento.active = 0;
      documento.$save().then((res) => {
        // Update state with file removed
        this.AtvAtivoAlteracaoEstado.create({
          id: 0,
          ativoEstadoId: 3, // Alteração de dados
          dataAlteracao: moment().isDST() ? moment().add(1, 'h') : moment(),
          ativoId: this.data.id,
          funcionarioId: this.Auth.getId(),
          alteracoes: `Removido anexo ${documento.nomeOriginal}`,
          active: true
        }).$promise.then((s) => {
          this.loadData();
          this.UI.addToast("Documento removido com sucesso");
        }).catch(error => {
          console.log(error);
          this.loadData();
          this.UI.addToast('Documento removido com sucesso');
        });
      }, (error) => {
        console.log(error);
        this.UI.addToast("Não foi possível remover documento. Por favor recarregue a página");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar documento. Por favor recarregue a página");
    });
  };

  checkCloneAtivo = () => {
    // Pre-check if ativo has numImobilizado. Don't bother go through if it has.
    this.AtvAtivo.findOne({
      filter: {
        where: {
          id: this.data.id,
          active: 1
        }
      }
    }).$promise.then((atv) => {
      if (atv.numImobilizado)
        this.UI.addToast("Não é possível clonar ativos com Nº de Imobilizado definido");
      else
        this.cloneAtivo();
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar ativo. Por favor recarregue a página");
    });
  };

  cloneAtivo = () => {
    let cenas = this.UI.showWaiting();
    this.AtvLocal.find({
      filter: {
        where: {
          entidadeProprietariaId: this.data.entidadeProprietariaId,
          active: 1
        }
      }
    }).$promise.then((locais) => {
      cenas.close();
      let options = this.AtvAtivosService.cloneAtivoOptions(locais);
      let modal = this.UI.showDialog(options);
      modal.then(res => {
        if (res) {
          this.loaded = false;
          this.AtvAtivosService.afterModalCloneAtivo(this.data, res).then(clonagens => {
            this.loadData();
          }).catch(error => {
            console.log(error);
          });
        }
      });
    }).catch(error => {
      this.UI.addToast("Não foi possível obter locais para ativos. Por favor tente mais tarde");
      console.log(error);
    });
  };

}

AtvAtivosDetailsController.$inject = ['$http', '$filter', '$state', 'UIService', 'AuthenticationService', 'AtvAtivosService', 'AtvAtivo', 'AtvAtivoAlteracaoEstado', 'AtvFornecedor', 'AtvFornecedorPrimavera', 'FuncionarioEntidadeProprietaria', 'Group', 'AtvLocal', 'AtvMarca', 'AtvPartesCorpo', 'AtvAtivoPartesCorpo', 'AtvPropriedade', 'AtvEntidadeProprietaria', 'AtvEstadoCompra', 'AtvTamanhoTipo', 'AtvTamanho', 'AtvTipoAtivo', 'AtvInteracao', 'AtvInteracaoTipo', 'AtvInteracoesService', 'AtvAtivoDocumento', 'AtvInteracaoDocumento', 'AtvSubfamilia'];
