import angular from 'angular';
import {routes} from './elv.routes'

import Agendamentos from "./agendamentos";
import Calendarizacao from "./calendarizacao";
import Processos from "./processos";
import Clientes from "./clientes";
import Elevadores from "./elevador";
import Relatorios from "./relatorios";
import OrdensIntervencao from "./ordensintervencao"
import Emie from "./emie";
import Condominios from "./condominio";
import Normas from "./normas";
import Topicos from "./topicosverificacao";
import Pontos from "./pontosverificacao";
import ListasVerificacao from "./listasverificacao";
import OIService from "./ordensintervencao/ordensintervencao.service";
import ElevadorService from "./elevador/elevador.service";

export default angular.module('app.elevadores', [Agendamentos, Calendarizacao, Processos, Clientes, Elevadores, Emie, Condominios, Relatorios, OrdensIntervencao, Normas, ListasVerificacao, Topicos, Pontos])
  .config(routes)
  .service('OIService', OIService)
  .service('ElevadorService', ElevadorService)
  .name;
