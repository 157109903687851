export default class AuthorizationService {
  constructor($rootScope, $q, Rolemap, AuthenticationService) {
    this.$rootScope = $rootScope;
    this.Rolemap = Rolemap;
    this.AuthenticationService = AuthenticationService;
    this.$q = $q;
    this.roles = [];
    this.groups = [];

    $rootScope.$on('$stateChangeStart', () => {
      if (AuthenticationService.isAuthenticated())
        this.loadACLs();
    });

    if (AuthenticationService.isAuthenticated())
      this.loadACLs();
  }

  getAllowed = () => {
    return this.roles;
  };

  // Obtem lista de todas as funcionalidades presentes nas roles associadas ao user...
  loadACLs = () => {
    const user = this.AuthenticationService.getUser();
    if (_.isEmpty(user))
      return;
    let k = [];
    let j = [];
    user.groups.forEach(g => {
      j.push(g);
      g.rolegroup.forEach(r => {
        if (r.active === true)
          k.push(r.role.name);
      });
    });
    // Important, otherwise we will have an exception in canPerform...
    k.push('$authenticated');
    this.roles = _.uniqBy(k);
    this.groups = _.uniqWith(j, _.isEqual);
  };

  isAuthenticated = () => {
    return this.AuthenticationService.isAuthenticated() && this.roles.length > 0;
  };

  // Verifica se o utilizador pode executar conjunto de roles...
  canPerform = (roles) => {
    if (Array.isArray(roles)) {
      for (let i = 0; i < roles.length; i++) {
        if (this.roles.indexOf(roles[i]) === -1)
          return false;
      }
      return true;
    } else {
      return _.includes(this.roles, roles);
    }
  };

  // Verifica se o utilizador pode executar uma de um conjunto de roles...
  hasOne = (roles) => {
    if (Array.isArray(roles)) {
      for (let i = 0; i < roles.length; i++) {
        if (this.roles.indexOf(roles[i]) !== -1)
          return true;
      }
      return false;
    } else {
      return _.includes(this.roles, roles);
    }
  };

  // Verifica se o utilizador pertence a um grupo
  belongsTo = (group) => {
    if (Array.isArray(group)) {
      let found = false;
      this.groups.forEach(og => {
        group.forEach(eg => {
          if (_.isEqual(eg, og) || og.name === eg || eg === og.id) {
            found = true;
          }
        });
      });
      return found;
    } else {
      return _.some(this.groups, g => {
        return _.isEqual(g,group) || g.name === group || g.id === group;
      });
    }
  };
}

AuthorizationService.$inject = ['$rootScope', '$q', 'Rolemap', 'AuthenticationService'];
