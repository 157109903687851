export default class AtvEntidadesProprietariasListController {
  constructor($q, $state, UIService, AuthorizationService, AtvEntidadeProprietaria, AtvPropriedade, AtvAtivo, AtvLocal) {
    this.$q = $q;
    this.state = $state;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.AtvPropriedade = AtvPropriedade;
    this.AtvAtivo = AtvAtivo;
    this.AtvLocal = AtvLocal;
    this.loaded = false;
    this.eps = [];
    this.loadData();

    this.epsOptions = {
      containment: 'parent',
      tolerance: 'pointer',
      handle: '.pmd-move-handle',
      'ui-floating': true,
      stop: (e, ui) => {
        for (let i = 0; i < this.eps.length; i++) {
          this.eps[i].ordem = i + 1;
          this.AtvEntidadeProprietaria.upsert(this.eps[i]).$promise.then((res) => {
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não foi possível reordenar entidades proprietárias. Por favor tente mais tarde");
            this.loadData();
          });
        }
      }
    }
  }

  loadData = () => {
    this.loaded = false;
    this.AtvEntidadeProprietaria.find({
      filter: {
        where: {
          active: 1
        },
        order: 'ordem ASC',
        include: [{
          relation: 'propriedades',
          scope: {
            fields: {id: true},
            where: {
              active: 1
            }
          }
        }, {
          relation: 'locais',
          scope: {
            fields: {id: true},
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then(e => {
      this.eps = e;
      this.loaded = true;
    }).catch(() => {
      this.loaded = true;
      this.UI.addToast('Erro ao carregar entidades proprietárias');
    });
  };

  addEntidadeProprietaria = () => {
    let options = {
      size: 'lg',
      template: require('./new.dialog.html'),
      controller: ['$dialog', '$scope', 'AtvFamilia', (dialog, scope, AtvFamilia) => {
        scope.title = "Nova Entidade Proprietária";
        scope.ep = {};

        scope.ok = () => {
          AtvFamilia.find({
            filter: {
              where: {
                prefixo: scope.ep.sigla.toUpperCase(),
                active: 1
              }
            }
          }).$promise.then((siglas) => {
            if (siglas && siglas.length > 0) {
              this.UI.addToast("Sigla já está a ser usada como prefixo de família.");
            } else {
              dialog.close(scope);
            }
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não é possível verificar existência de sigla. Por favor tente mais tarde");
          });
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.ep) {
        res.ep.id = 0;
        res.ep.sigla = res.ep.sigla.toUpperCase();
        this.AtvEntidadeProprietaria.count({
          where: {
            active: 1
          }
        }).$promise.then((n) => {
          res.ep.ordem = n.count + 1;
          this.AtvEntidadeProprietaria.create(res.ep).$promise.then((res) => {
            this.UI.addToast("Entidade Proprietária criada com sucesso");
            this.loadData();
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro na criação de entidade proprietária. Por favor tente mais tarde.");
          });
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na criação de entidade proprietária. Por favor tente mais tarde");
        });
      }
    });
  };

  editEntidadeProprietaria = (r) => {
    let options = {
      size: 'lg',
      template: require('./new.dialog.html'),
      controller: ['$dialog', '$scope', 'AtvFamilia', (dialog, scope, AtvFamilia) => {
        scope.title = "Editar Entidade Proprietária";
        scope.ep = angular.copy(r);

        scope.ok = () => {
          AtvFamilia.find({
            filter: {
              where: {
                prefixo: scope.ep.sigla.toUpperCase(),
                active: 1
              }
            }
          }).$promise.then((siglas) => {
            if (siglas && siglas.length > 0) {
              this.UI.addToast("Sigla já está a ser usada como prefixo de família.");
            } else {
              dialog.close(scope);
            }
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não é possível verificar existência de sigla. Por favor tente mais tarde");
          });
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.ep) {
        this.AtvEntidadeProprietaria.upsert(res.ep).$promise.then((res) => {
          this.UI.addToast("Entidade Proprietária editada com sucesso");
          this.loadData();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na edição de entidade proprietária. Por favor tente mais tarde.");
        });
      }
    });
  };

  checkDeleteEntidadeProprietaria = (f) => {
    let cenas = this.UI.showWaiting();
    this.AtvEntidadeProprietaria.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((entidadeProprietariaToRemove) => {
      // Find if we have something that might block deletion
      this.AtvPropriedade.count({
        fields: {id: true},
        where: {
          entidadeProprietariaId: entidadeProprietariaToRemove.id,
          active: 1
        }
      }).$promise.then((propriedades) => {
        this.AtvAtivo.count({
          fields: {id: true},
          where: {
            active: 1,
            entidadeProprietariaId: entidadeProprietariaToRemove.id
          }
        }).$promise.then((ativos) => {
          this.AtvLocal.count({
            fields: {id: true},
            where: {
              active: 1,
              entidadeProprietariaId: entidadeProprietariaToRemove.id
            }
          }).$promise.then((locais) => {
            cenas.close();
            if (propriedades.count + ativos.count + locais.count > 0) {
              let msg = "Não é possível remover entidade proprietária.\nExiste" + ((propriedades.count + ativos.count + locais.count > 1) ? "m " : " ");
              msg += propriedades.count > 0 ? (propriedades.count + " propriedade" + (propriedades.count !== 1 ? "s " : " ")) : "";
              msg += ativos.count > 0 && propriedades.count > 0 ? "e " : "";
              msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")) : "";
              msg += locais.count > 0 && (propriedades.count > 0 || ativos.count > 0) ? "e " : "";
              msg += locais.count > 0 ? (locais.count + " loca" + (ativos.count !== 1 ? "is " : "l ")) : "";
              msg += "associado" + (propriedades.count + ativos.count + locais.count> 1 ? "s" : "") + " a esta entidade proprietária.";
              this.UI.showAlert(msg);
            } else {
              this.confirmDeleteEntidadeProprietaria(f);
            }
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("De momento não é possível remover entidade proprietária. Por favor tente mais tarde");
          });

        }).catch((error) => {
          console.log(error);
          this.UI.addToast("De momento não é possível remover entidade proprietária. Por favor tente mais tarde");
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("De momento não é possível remover entidade proprietária. Por favor tente mais tarde");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("De momento não é possível remover entidade proprietária. Por favor tente mais tarde");
    });
  };

  confirmDeleteEntidadeProprietaria = (f) => {
    let title = "Eliminar Entidade Proprietária";
    let warning = "Tem a certeza que pretende remover esta entidade proprietária?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeEntidadeProprietaria(f);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeEntidadeProprietaria = (f) => {
    this.loaded = false;
    // Find again entidade proprietária to delete so we can update it
    this.AtvEntidadeProprietaria.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((epToRemove) => {
      epToRemove.active = 0;
      epToRemove.$save().then((res) => {
        this.AtvEntidadeProprietaria.find({
          filter: {
            where: {
              active: 1
            },
            order: 'ordem ASC'
          }
        }).$promise.then((ents) => {
          let tasks = [];
          for (let i = 0; i < ents.length; i++) {
            ents[i].ordem = i + 1;
            let defer = this.AtvEntidadeProprietaria.upsert(ents[i]).$promise;
            tasks.push(defer.promise);
          }
          this.$q.all(tasks).then((res) => {
            this.UI.addToast("Entidade Proprietária eliminada com sucesso!");
            this.loadData();
          }).catch(err => {
            console.log(err);
          });
        }).catch((error) => {
          console.log(error);
        });
      }, (error) => {
        console.log(error);
        this.loaded = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível eliminar a entidade proprietária. Verifique se ainda existe.");
      this.loadData();
    });
  };
}

AtvEntidadesProprietariasListController.$inject = ['$q', '$state', 'UIService', 'AuthorizationService', 'AtvEntidadeProprietaria', 'AtvPropriedade', 'AtvAtivo', 'AtvLocal'];
