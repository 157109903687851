export default class ListaClausulaService {
    $q;
    CoreListaRelatorioDefeitoTipo;
    CoreListaRelatorioDefeito;
    constructor($q, CoreListaRelatorioDefeitoTipo, CoreListaRelatorioDefeito) {
        this.$q = $q;
        this.CoreListaRelatorioDefeitoTipo = CoreListaRelatorioDefeitoTipo;
        this.CoreListaRelatorioDefeito = CoreListaRelatorioDefeito;
    }
    findAll(query) {
        return this.CoreListaRelatorioDefeitoTipo.table(query).$promise;
    }
    findOne(id) {
        return this.CoreListaRelatorioDefeitoTipo.findOne({
            filter: {
                where: {
                    id
                },
                include: ['Defeitos', 'Departamento']
            }
        }).$promise;
    }
    options() {
        return this.CoreListaRelatorioDefeitoTipo.options().$promise;
    }
    patch(id, data) {
        return this.CoreListaRelatorioDefeitoTipo.patch({ id }, data).$promise;
    }
    count(id) {
        return this.CoreListaRelatorioDefeito.count({
            where: {
                coreListaRelatorioDefeitoTipoId: id
            }
        }).$promise;
    }
}
ListaClausulaService.$inject = ['$q', 'CoreListaRelatorioDefeitoTipo', 'CoreListaRelatorioDefeito'];
