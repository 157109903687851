export default class FormacaoFormadoresController {
  constructor($state, UIService, AuthenticationService, NgTableParams, FormacaoFormador) {
    this.$state = $state;
    this.UI = UIService;
    this.FormacaoFormador = FormacaoFormador;
    this.NgTableParams = NgTableParams;

    this.tableData = null;
    this.selected = 0;
    this.formadores = null;
    this.formadoresLoading = true;

    this.estados = [{
      id: 1,
      title: 'Ativo'
    }, {
      id: 2,
      title: 'Inativo'
    }];

    this.getFormadores();
  };

  hasSelect = () => {
    return _.some(this.formadores, function (r) {
      return r.selected === true;
    });
  };

  selectItem = () => {
    this.selected = _.filter(this.formadores, function (r) {
      return r.selected === true;
    }).length;
  };

  getFormadores = () => {
    this.FormacaoFormador.find({
      filter: {
        where: {
          active: 1
        },
        order: 'nome desc',
        limit: 1000
      }
    }).$promise.then((res) => {
      res = _.filter(res, (r) => {
        r.selected = false;
        r.contactos = "" + r.telefone + " " + r.telemovel;
        return r;
      });
      this.formadores = res;
      let NgTableParams = this.NgTableParams;
      this.tableData = new NgTableParams({
        sorting: {
          nome: 'desc'
        }
      }, {
        dataset: res,
      });

      this.formadoresLoading = false;
    }).catch((err) => {
      this.formadoresLoading = false;
      console.log(err);
      this.UI.addToast("Não foi possível obter formadores. Por favor tente mais tarde.");
    });
  };

  addFormador = () => {
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./formador.dialog.html'),
      controller: ['$scope', function ($scope) {
        $scope.label = "Novo Formador";
        // Data to change on the modal
        $scope.formador = {};
        $scope.create = true;

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.formadoresLoading = true;

      // Create the new Formador with correct data from modal
      this.FormacaoFormador.create({
        id: 0,
        nome: ok.formador.nome,
        email: ok.formador.email,
        contacto: ok.formador.contacto,
        morada: ok.formador.morada,
        dataNascimento: ok.formador.dataNascimento ? moment(ok.formador.dataNascimento).format('YYYY-MM-DD') : null,
        observacoes: ok.formador.observacoes,
        estado: 1,
        active: 1
      }).$promise.then((r) => {
        // Update the related model because it vanishes upon $save()
        this.formadoresLoading = true;
        this.getFormadores();
        this.UI.addToast("Formador criado com sucesso.");
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  confirmDeleteFormador = (f) => {
  };

  removeFormador = (f) => {
    this.formadoresLoading = true;
    // Find again formador to delete so we can update it
    this.FormacaoFormador.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((formadorToRemove) => {
      this.FormacaoFormadorModulo.cleanFormador({formador: formadorToRemove.id}).$promise.then((res) => {
        formadorToRemove.active = 0;
        formadorToRemove.$save().then((res) => {

          this.getFormadores();
          this.UI.addToast("Formador eliminado com sucesso.");
        }, (error) => {
          console.log(error);
          this.modulosLoading = false;
          this.formadoresLoading = false;
          this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
        });
      }).catch((error) => {
        console.log(error);
        this.formadoresLoading = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      this.formadoresLoading = false;
      this.UI.addToast("Não foi possível eliminar o formador. Verifique se ainda existe.");
    });
  };

  // Export a single id or multiple (no arguments)
  exportSelected = (id) => {
    this.formadoresLoading = true;
    let singleFormador = false;
    let formadoresExportar = [];

    if (id !== undefined) {
      // Do single export
      formadoresExportar = [{'id': id}];
      singleFormador = true;
    } else {
      // Do multi export
      formadoresExportar = _.filter(this.formadores, function (r) {
        return r.selected === true;
      });
    }

    let postData = {formadores: _.map(formadoresExportar, 'id')};

    this.FormacaoFormador.selectiveExport(postData).$promise.then(result => {
      let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + result.fileData;
      let b = document.createElement('a');
      b.href = url;

      if (singleFormador) {
        b.download = 'formador_' + id + '.xlsx';
      } else {
        b.download = 'formadoresSelecionadas.xlsx';
      }
      b.click();
      this.formadoresLoading = false;
    }).catch((err) => {
      console.log("Error:", err);
      this.formadoresLoading = false;
    });
  };
}

FormacaoFormadoresController.$inject = ['$state', 'UIService', 'AuthenticationService', 'NgTableParams', 'FormacaoFormador'];
