export default class AtvColaboradoresDetailsController {
  constructor($http, $filter, $state, UIService, AuthenticationService, AtvAtivosService, AtvAtivo, AtvInteracao, Funcionario) {
    this.$http = $http;
    this.$filter = $filter;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.AtvAtivosService = AtvAtivosService;
    this.AtvAtivo = AtvAtivo;
    this.AtvInteracao = AtvInteracao;
    this.Funcionario = Funcionario;
    this.$state = $state;
    this.funcionario = null;

    this.loaded = false;

    // Default opt state - populate later when we have all states
    this.optAtivos = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "id",
      sort: "desc",
      filter: {}
    };
    this.ativos = null;
    this.ativosLoading = true;

    // Default opt state - populate later when we have all states
    this.optInteracoes = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "dataRegisto",
      sort: "desc",
      filter: {},
      filterLayout: {}
    };
    this.interacoes = null;
    this.interacoesLoading = true;

    this.id = $state.params.id;
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.Funcionario.findOne({
      fields: {id: true, name: true, email: true, active: true},
      filter: {
        where: {
          id: this.id,
          active: true
        },
        include: {
          relation: 'funcionariosEntidadesProprietarias',
          scope: {
            where: {
              active: 1
            },
            order: 'dataAtribuicao ASC',
            include: [{
              relation: 'entidadeProprietaria',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'atribuidoPor',
              scope: {
                where: {
                  active: 1
                }
              }
            }]
          }
        }
      }
    }).$promise.then(res => {
      this.data = res;

      this.loaded = true;
    }).catch((error) => {
      console.log(error);
      this.$state.go('app.ativos.ativos.list');
      this.UI.addToast('Erro ao carregar ativo');
    });

    this.getAtivosForColaborador();
    this.getInteracoesForColaborador();
  };

  gerarDocumento = () => {
    let colaboradorId = this.id;
    let wait = this.UI.showWaiting();
    this.AtvAtivo.find({
      filter: {
        fields: {id: true, entidadeProprietariaId: true, active: true},
        where: {
          afetoaId: this.id,
          active: 1
        },
        include: {
          relation: 'entidadeProprietaria',
          scope: {
            fields: {sigla: true},
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((res) => {
      let groups = _.groupBy(res, 'entidadeProprietariaId');
      let siglas = {};
      Object.keys(groups).forEach(k => {
        siglas[k] = (groups[k][0] && groups[k][0].entidadeProprietaria.sigla) ? groups[k][0].entidadeProprietaria.sigla : '';
      });

      wait.close();
      let options = {
        size: 'md',
        template: require('./gerar.documento.dialog.html'),
        controller: ['$scope', ($scope) => {
          $scope.groups = Object.keys(groups);
          $scope.siglas = siglas;
          $scope.colaboradorId = colaboradorId;
          $scope.filename = 'documento_entrega_' + _.snakeCase(this.data.name);

          $scope.generate = (entidadeProprietariaId) => {
            let wait = this.UI.showWaiting();
            this.AtvAtivo.gerarDocumento({
              id: Number(colaboradorId),
              entidadeProprietariaId: Number(entidadeProprietariaId)
            }).$promise.then(res => {
              wait.close();
            });
          };

          $scope.cancel = () => $scope.$dismiss('cancel');
        }]
      };

      this.UI.showDialog(options).then(res => {
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível verificar ativos. Por favor tente mais tarde");
    });
  };

  getAtivosForColaborador = () => {
    this.ativosLoading = true;
    let whereLiteral = {
      'AtvAtivo.afetoaId': this.id,
      'AtvAtivo.active': 1
    };
    let where = {};
    Object.keys(this.optAtivos.filter).forEach(f => {
      if (this.optAtivos.filter[f])
        where[f] = this.optAtivos.filter[f];
    });

    this.AtvAtivosService.setWherePermFields({}, 'AtvAtivo.entidadeProprietariaId').then(w => {
      let wherePerms = w.wherePerms;
      this.AtvAtivo.table({
        params: {
          fields: [
            'AtvAtivo.id as id',
            'AtvAtivo.iidDisplay as iidDisplay',
            'AtvAtivo.designacao as designacao',
            'AtvAtivo.referencia as referencia',
            'AtvMarca.designacao as marca',
            'AtvFornecedorPrimavera.refFornecedorPrimavera as refFornecedorPrimavera',
            'AtvEntidadeProprietaria.designacao as entidadeProprietaria',
            'AtvEP2.sigla as sigla'
          ],
          from: ['AtvAtivo', 'AtvMarca', 'AtvFornecedorPrimavera', 'AtvEntidadeProprietaria', 'AtvEntidadeProprietaria'],
          references: [undefined, 'AtvAtivo.marcaId', 'AtvAtivo.fornecedorPrimaveraId', 'AtvAtivo.entidadeProprietariaId', 'AtvFornecedorPrimavera.entidadeProprietariaId'],
          aliases: [null, null, null, null, 'AtvEP2'],
          where: where,
          whereLiteral: whereLiteral,
          wherePerms: wherePerms,
          order: this.optAtivos.order,
          sort: this.optAtivos.sort,
          limit: this.optAtivos.items,
          skip: (this.optAtivos.page - 1) * this.optAtivos.items,
        }
      }).$promise.then(res => {
        let page = this.optAtivos.page;
        let items = this.optAtivos.items;

        let total = res.count;

        this.optAtivos.start = total > 0 ? (page - 1) * items + 1 : 0;
        if ((this.optAtivos.start - 1 + items) >= total) {
          this.optAtivos.end = total;
        } else {
          this.optAtivos.end = Number.parseInt(this.optAtivos.start - 1 + items);
        }

        this.ativos = res.ativos;
        this.optAtivos.total = total;
        this.ativosLoading = false;
      }).catch((e) => {
        console.log(e);
        this.UI.addToast("Não foi possível ler ativos. Por favor tente mais tarde");
      });
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler permissões. Por favor tente mais tarde");
    });
  };

  applyFilterAtivos = () => {
    this.optAtivos.page = 1;
    this.getAtivosForColaborador();
  };

  ativosSort = keyname => {
    if (this.optAtivos.order === keyname)
      this.optAtivos.page = 1;
    this.optAtivos.order = keyname;
    this.optAtivos.sort = this.optAtivos.sort === 'asc' ? 'desc' : 'asc';
    this.getAtivosForColaborador();
  };

  ativosItem = val => {
    this.optAtivos.items = val;
    this.optAtivos.page = 1;
    this.getAtivosForColaborador();
  };

  ativosPage = sum => {
    this.optAtivos.page += sum;
    if (this.optAtivos.page < 1)
      this.optAtivos.page = 1;
    if (this.optAtivos.page > Math.ceil(this.optAtivos.total / this.optAtivos.items))
      this.optAtivos.page = Math.ceil(this.optAtivos.total / this.optAtivos.items);
    this.getAtivosForColaborador();
  };

  viewAtivo = r => {
    this.$state.go('app.ativos.ativos.details', {id: r.id});
  };

  getInteracoesForColaborador = () => {
    this.interacoesLoading = true;
    let whereLiteral = {
      'AtvInteracao.funcionarioId': this.id,
      'AtvInteracao.active': 1
    };
    let where = {};
    Object.keys(this.optInteracoes.filter).forEach(f => {
      if (this.optInteracoes.filter[f])
        where[f] = this.optInteracoes.filter[f];
    });
    this.AtvInteracao.table({
      params: {
        fields: [
          'AtvInteracao.id as id',
          'AtvInteracaoTipo.designacao as interacaoTipo',
          'AtvResultado.resultado as resultado',
          'AtvInteracao.dataRegisto as dataRegisto',
          'AtvInteracao.dataInteracao as dataInteracao',
          'AtvInteracao.motivoDevolucao as motivoDevolucao',
          'AtvLocal.designacao as local',
          'Funcionario.name as colaborador',
          'Funcionario2.name as atribuidoa'
        ],
        from: ['AtvInteracao', 'AtvInteracaoTipo', 'AtvResultado', 'AtvLocal', 'Funcionario', 'Funcionario'],
        references: [undefined, 'AtvInteracao.interacaoTipoId', 'AtvInteracao.resultadoId', 'AtvInteracao.localId', 'AtvInteracao.funcionarioId', 'AtvInteracao.atribuidoa_id'],
        aliases: [null, null, null, null, null, 'Funcionario2'],
        where: where,
        whereLiteral: whereLiteral,
        order: this.optInteracoes.order,
        sort: this.optInteracoes.sort,
        limit: this.optInteracoes.items,
        skip: (this.optInteracoes.page - 1) * this.optInteracoes.items,
      }
    }).$promise.then(res => {
      let page = this.optInteracoes.page;
      let items = this.optInteracoes.items;

      let total = res.count;

      this.optInteracoes.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.optInteracoes.start - 1 + items) >= total) {
        this.optInteracoes.end = total;
      } else {
        this.optInteracoes.end = Number.parseInt(this.optInteracoes.start - 1 + items);
      }

      this.interacoes = res.interacoes;
      // Process dates for UTC compliance
      this.interacoes.forEach(i => {
        i.dataRegistoLayout = moment(i.dataRegisto).utc().format("DD/MM/YYYY HH:mm:ss");
        i.dataInteracaoLayout = moment(i.dataInteracao).utc().format("DD/MM/YYYY HH:mm:ss");
      });
      this.optInteracoes.total = total;
      this.interacoesLoading = false;
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler interações. Por favor tente mais tarde");
    });
  };

  // Deal with dates
  applyFilterInteracoes = (field) => {
    // If field is a date field
    if (field === 'AtvInteracao.dataInteracao') {
      let parsedDate = this.optInteracoes.filterLayout[field].split(' ');
      if (parsedDate[0].includes('/'))
        parsedDate[0] = parsedDate[0].split('/').reverse().join('-');
      this.optInteracoes.filter[field] = parsedDate.join(' ');
    } else {
      this.optInteracoes.filter[field] = angular.copy(this.optInteracoes.filterLayout[field]);
    }
    this.optInteracoes.page = 1;
    this.getInteracoesForAtivo();
  };

  interacoesSort = keyname => {
    if (this.optInteracoes.order === keyname)
      this.optInteracoes.page = 1;
    this.optInteracoes.order = keyname;
    this.optInteracoes.sort = this.optInteracoes.sort === 'asc' ? 'desc' : 'asc';
    this.getInteracoesForColaborador();
  };

  interacoesItem = val => {
    this.optInteracoes.items = val;
    this.optInteracoes.page = 1;
    this.getInteracoesForColaborador();
  };

  interacoesPage = sum => {
    this.optInteracoes.page += sum;
    if (this.optInteracoes.page < 1)
      this.optInteracoes.page = 1;
    if (this.optInteracoes.page > Math.ceil(this.optInteracoes.total / this.optInteracoes.items))
      this.optInteracoes.page = Math.ceil(this.optInteracoes.total / this.optInteracoes.items);
    this.getInteracoesForColaborador();
  };

  viewInteracao = interacaoId => {
    this.$state.go('app.ativos.interacoes.details', {id: interacaoId});
  };

}

AtvColaboradoresDetailsController.$inject = ['$http', '$filter', '$state', 'UIService', 'AuthenticationService', 'AtvAtivosService', 'AtvAtivo', 'AtvInteracao', 'Funcionario'];
