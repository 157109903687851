export function routes($stateProvider) {
  $stateProvider
    .state('app.gas.relatorios', {
      url: '/relatorios',
      abstract: true
    })
    .state('app.gas.relatorios.list', {
      url: '?{page:int}&{limit:int}&sortBy&filter',
      title: 'Relatórios',
      template: '<gas-relatorios-list-view></gas-relatorios-list-view>',
      role: ['gasRelatorioList'],
      params: {
        page: {
          value: 1,
          dynamic: true
        },
        limit: {
          value: 20,
          dynamic: true
        },
        sortBy: {
          array: true,
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    });
}

routes.$inject = ['$stateProvider'];
