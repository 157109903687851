import React, { useEffect, useState } from 'react';
import { Box, Typography, Breadcrumbs, Link, Card, CardContent, CardHeader, TextField, ListItemAvatar, Avatar, ListItemText, Tooltip, Fab, IconButton } from '@mui/material';
import { mdiCheckBold, mdiContentSave, mdiDelete, mdiPencil, mdiPlus, mdiWindowRestore, mdiWrench } from '@mdi/js';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import moment from 'moment';
import { MdiIcon, DraggableList, DynamicCard, ConfirmDialog, WaitDialog } from '@components';
import { reorder } from '../../util';
import { useModal } from 'mui-modal-provider';
import ParametroDialog from './dialogs/parametro';
import ValidacaoDialog from './dialogs/validacao';
import { UploadDialog } from './dialogs/ficheiro';
import * as yup from 'yup';
import { useFormik } from 'formik';
import EnsaioDialog from './dialogs/ensaio';
import EquipamentoDialog from './dialogs/equipamento';
import DialogBase from './dialogs/base';
const DetailsRelatorios = ({ $state, ListaRelatorioService, UIService }) => {
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [results, setResults] = useState([]);
    const [data, setData] = useState();
    const { showModal } = useModal();
    const [parametros, setParametros] = useState([]);
    const [ensaios, setEnsaios] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);
    const [ficheiros, setficheiros] = useState([]);
    const [validacao, setValidacao] = useState([]);
    const [folder, setFolder] = useState('');
    const validationSchema = yup.object({
        titulo: yup.string().required('O campo é obrigatório'),
        subtitulo: yup.string().nullable(),
        config: yup.string().nullable()
    });
    const formik = useFormik({
        initialValues: data,
        validationSchema,
        enableReinitialize: true,
        validateOnMount: false,
        onSubmit: async (d) => {
            // save data
            const o = await ListaRelatorioService.patch($state.params.id, {
                titulo: d.titulo,
                subtitulo: d.subtitulo
            });
        }
    });
    const publish = async () => {
        const d = await UIService.showConfirm('Tem a certeza que deseja publicar esta lista? Esta ação não pode ser revertida!');
        if (d) {
            const r = await ListaRelatorioService.publish($state.params.id);
            fetchData();
        }
    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const aux = await ListaRelatorioService.findOne($state.params.id);
            setEditing(aux.dataPublicacao == undefined);
            setData(aux);
            setficheiros(aux.Ficheiros || []);
            setValidacao(aux.Validacoes || []);
            const r = await ListaRelatorioService.findResultados(aux.coreDepartamentoId);
            setResults(r);
            const f = await ListaRelatorioService.getUploadFolder(aux.coreDepartamentoId);
            setFolder(f);
            setParametros(aux.Parametros);
            setEnsaios(aux.Ensaios);
            setEquipamentos(aux.Equipamentos);
            // Formik
            formik.resetForm();
            formik.setFieldValue('titulo', aux.titulo);
            formik.setFieldValue('subtitulo', aux.subtitulo);
            setLoading(false);
        }
        catch (e) {
            console.log(e);
        }
    };
    const onParametroDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination)
            return;
        // Altera ordem
        const newItems = reorder(parametros, source.index, destination.index);
        newItems.forEach((r, i, a) => {
            r.ordem = i + 1;
        });
        setParametros(newItems);
    };
    const onEnsaioDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination)
            return;
        // Altera ordem
        const newItems = reorder(ensaios, source.index, destination.index);
        newItems.forEach((r, i, a) => {
            r.ordem = i + 1;
        });
        setEnsaios(newItems);
    };
    const onEquipamentoDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination)
            return;
        // Altera ordem
        const newItems = reorder(equipamentos, source.index, destination.index);
        newItems.forEach((r, i, a) => {
            r.ordem = i + 1;
        });
        setEquipamentos(newItems);
    };
    useEffect(() => {
        if (!loading)
            fetchData();
    }, [data]);
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>{loading ? 'A carregar...' : data && data.titulo}</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Motor de relatórios
          </Link>
          <Link underline='hover' onClick={() => $state.go('app.administration.lists.list')} color='text.primary' aria-current='page'>
            Modelo de relatório
          </Link>
          <Link underline='none' color='inherit'>
            {$state.params.id}
          </Link>
        </Breadcrumbs>
      </Box>
      <Box>
        {!loading ? (<Box>
            <Grid container spacing={1}>
              <Grid lg={9} xs={12}>
                <Card sx={{ mb: 1 }}>
                  <CardHeader title='Detalhes' action={<Tooltip title={'Propriedades'}>
                        <IconButton aria-label='settings' onClick={() => {
                    showModal(DialogBase, {
                        initial: {
                            assinatura: Boolean(data?.assinatura),
                            imagens: Boolean(data?.imagens),
                            pagamento: Boolean(data?.pagamento)
                        },
                        onClose: async (r) => {
                            formik.setFieldValue('config', r);
                        }
                    });
                }}>
                          <MdiIcon path={mdiWrench}/>
                        </IconButton>
                      </Tooltip>}/>
                  <CardContent>
                    <Grid container spacing={2} sx={{ mb: 1 }}>
                      <Grid lg={8} md={7} xs={12}>
                        <TextField label='Procedimento' disabled={true} fullWidth value={data?.Norma ? `${data?.Norma?.referencia} - ${data?.Norma?.designacao}` : ''}/>
                      </Grid>
                      <Grid lg={2} md={2} xs={4}>
                        <TextField label='Versão' disabled={true} fullWidth value={data?.versao}/>
                      </Grid>
                      <Grid lg={2} md={3} xs={8}>
                        <TextField label='Departamento' disabled={true} fullWidth value={data?.Departamento?.designacao ?? ''}/>
                      </Grid>
                      <Grid lg={12} md={12} xs={12}>
                        <TextField label='IG' name='titulo' InputProps={{
                readOnly: !editing
            }} fullWidth defaultValue={formik.values?.titulo ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.titulo && Boolean(formik.errors.titulo)} helperText={formik.touched.titulo && formik.errors.titulo}/>
                      </Grid>
                      <Grid lg={12} md={12} xs={12}>
                        <TextField label='Designação' name='subtitulo' InputProps={{
                readOnly: !editing
            }} fullWidth defaultValue={formik.values?.subtitulo ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.subtitulo && Boolean(formik.errors.subtitulo)} helperText={formik.touched.subtitulo && formik.errors.subtitulo}/>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid lg={3} xs={12}>
                <Card sx={{ mb: 1 }}>
                  <CardHeader title='Informação'/>
                  <CardContent>
                    <Grid container spacing={2} sx={{ mb: 1 }}>
                      <Grid lg={12} xs={4}>
                        <TextField label='Publicado?' disabled={true} fullWidth value={data?.dataPublicacao == undefined ? 'Não' : 'Sim'}/>
                      </Grid>
                      <Grid lg={12} xs={8}>
                        <TextField label='Data de publicação' disabled={true} fullWidth value={data?.dataPublicacao == undefined ? '' : moment(data.dataPublicacao).format('DD-MM-YYYY [às] HH:mm')}/>
                      </Grid>
                      <Grid lg={12} xs={12}>
                        <TextField label='Publicado por' disabled={true} fullWidth value={data?.Funcionario == undefined ? '' : data.Funcionario.name}/>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid md={5} xs={12}>
                <DynamicCard sx={{ mb: 1 }} loading={loading} title='Ficheiros a gerar' actions={editing
                ? [
                    {
                        icon: mdiPlus,
                        label: 'Adicionar',
                        callback: async () => {
                            const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                            let resultados = await ListaRelatorioService.findResultados(data.coreDepartamentoId);
                            wait.destroy();
                            showModal(UploadDialog, {
                                ListaRelatorioService,
                                folder,
                                allowed: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
                                list: resultados,
                                title: 'Adiciona ficheiro',
                                onClose: (_) => {
                                    if (_) {
                                        ListaRelatorioService.postFicheiro({
                                            id: 0,
                                            designacao: _.designacao,
                                            coreListaRelatorioId: $state.params.id,
                                            ficheiro: _.file,
                                            valores: JSON.stringify(_.resultados),
                                            validado: 0,
                                            active: 1
                                        })
                                            .then(r => {
                                            setficheiros([...ficheiros, r]);
                                            UIService.addToast('Ficheiro adicionado com sucesso');
                                        })
                                            .catch(e => {
                                            UIService.addToast('Não foi possível adicionar ficheiro');
                                        });
                                    }
                                }
                            });
                        }
                    }
                ]
                : undefined} variant='list' content={ficheiros
                .filter(r => r.active == 1)
                .map((r, i) => {
                return {
                    label: r.designacao,
                    actions: (<Box>
                            {editing && (<Tooltip title='Editar ficheiro'>
                                <IconButton onClick={async () => {
                                const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                                let resultados = await ListaRelatorioService.findResultados(data.coreDepartamentoId);
                                wait.destroy();
                                showModal(UploadDialog, {
                                    ListaRelatorioService,
                                    folder,
                                    allowed: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
                                    initial: {
                                        designacao: r.designacao,
                                        file: r.ficheiro,
                                        resultados: JSON.parse(r.valores)
                                    },
                                    list: resultados,
                                    title: 'Editar ficheiro',
                                    onClose: (_) => {
                                        if (_) {
                                            ListaRelatorioService.patchFicheiro(r.id, {
                                                designacao: _.designacao,
                                                ficheiro: _.file,
                                                valores: JSON.stringify(_.resultados)
                                            })
                                                .then(r => {
                                                let a = [...ficheiros];
                                                a[i] = r;
                                                setficheiros(a);
                                                UIService.addToast('Ficheiro atualizado com sucesso');
                                            })
                                                .catch(e => {
                                                UIService.addToast('Não foi possível atualizado ficheiro');
                                            });
                                        }
                                    }
                                });
                            }}>
                                  <MdiIcon path={mdiPencil}/>
                                </IconButton>
                              </Tooltip>)}
                            {editing && (<Tooltip title='Eliminar ficheiro'>
                                <IconButton onClick={async () => {
                                const wait = showModal(ConfirmDialog, {
                                    title: 'Eliminar ficheiro',
                                    message: `Tem a certeza que pretende eliminar o ficheiro "${r.designacao}"?`,
                                    onClose: _ => {
                                        if (_) {
                                            ListaRelatorioService.patchFicheiro(r.id, { active: 0 })
                                                .then(r => {
                                                let a = [...ficheiros];
                                                a[i].active = 0;
                                                setficheiros(a);
                                                UIService.addToast('Ficheiro eliminado com sucesso');
                                            })
                                                .catch(e => {
                                                UIService.addToast('Não foi possível eliminar ficheiro');
                                            });
                                        }
                                    }
                                });
                            }}>
                                  <MdiIcon path={mdiDelete}/>
                                </IconButton>
                              </Tooltip>)}
                          </Box>)
                };
            })}/>
                <DynamicCard sx={{ mb: 1 }} loading={loading} title='Resultados' variant='list' content={results.map(r => {
                return {
                    label: r.designacao,
                    sublabel: r.observacoes
                };
            })}/>
              </Grid>
              <Grid md={7} xs={12}>
                <DynamicCard sx={{ mb: 1 }} loading={loading} actions={editing
                ? [
                    {
                        icon: mdiPlus,
                        label: 'Adicionar',
                        callback: async () => {
                            const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                            let tiposComDefeitos = await ListaRelatorioService.findDefeitosByTipo(data.coreDepartamentoId);
                            let ensaios = await ListaRelatorioService.findEnsaiosOfList($state.params.id);
                            wait.destroy();
                            showModal(ValidacaoDialog, {
                                resultados: results,
                                initial: {},
                                defeitos: tiposComDefeitos,
                                ensaios: ensaios,
                                onClose: (data) => {
                                    if (data) {
                                        ListaRelatorioService.postValidacao({
                                            id: 0,
                                            coreListaRelatorioId: $state.params.id,
                                            validador: data.validador,
                                            on: data.on,
                                            observacoes: data.observacoes,
                                            coreListaRelatorioResultadoId: data.coreListaRelatorioResultadoId,
                                            designacao: data.designacao
                                        })
                                            .then(_ => {
                                            let a = [...validacao];
                                            a.push(_);
                                            setValidacao(a);
                                            UIService.addToast('Regra de validação adicionada com sucesso');
                                        })
                                            .catch(e => {
                                            UIService.addToast('Ocorreu um erro ao adicionar regra de validação');
                                        });
                                    }
                                }
                            });
                        }
                    }
                ]
                : undefined} title='Regras de validação' variant='list' content={validacao.map((r, i) => {
                return {
                    label: r.designacao,
                    sublabel: r.observacoes,
                    actions: (<Box>
                          {editing && (<IconButton onClick={async () => {
                                const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                                let tiposComDefeitos = await ListaRelatorioService.findDefeitosByTipo(data.coreDepartamentoId);
                                let ensaios = await ListaRelatorioService.findEnsaiosOfList($state.params.id);
                                wait.destroy();
                                showModal(ValidacaoDialog, {
                                    resultados: results,
                                    initial: r,
                                    defeitos: tiposComDefeitos,
                                    ensaios: ensaios,
                                    onClose: (data) => {
                                        if (data) {
                                            ListaRelatorioService.patchValidacao(r.id, {
                                                validador: data.validador,
                                                on: data.on,
                                                observacoes: data.observacoes,
                                                coreListaRelatorioResultadoId: data.coreListaRelatorioResultadoId,
                                                designacao: data.designacao,
                                                active: 1
                                            })
                                                .then(_ => {
                                                let a = [...validacao];
                                                a[i] = r;
                                                setValidacao(a);
                                                UIService.addToast('Regra de validação atualizada com sucesso');
                                            })
                                                .catch(e => {
                                                UIService.addToast('Ocorreu um erro ao atualizar regra de validação');
                                            });
                                        }
                                    }
                                });
                            }}>
                              <MdiIcon path={mdiPencil}/>
                            </IconButton>)}
                        </Box>)
                };
            })}/>
              </Grid>
            </Grid>
            <h1>Corpo do relatório</h1>
            <Grid container spacing={1}>
              <Grid md={12} xs={12}>
                <Card sx={{ mb: 1 }}>
                  <CardHeader title='Parâmetros' subheader='Pode alterar a ordem arrastando o item para cima ou para baixo. Para novas entradas deverá guardar a lista antes de poder adicionar pontos.' action={editing && (<Tooltip title='Adicionar novo parâmetro'>
                          <IconButton aria-label='settings' onClick={() => {
                    showModal(ParametroDialog, {
                        initial: {},
                        onClose: data => {
                            if (data) {
                                let a = [...parametros];
                                data.ordem = a.length + 1;
                                ListaRelatorioService.createParametro({ ...data, coreListaRelatorioId: $state.params.id, id: 0, active: 1 })
                                    .then(x => {
                                    a.push(x);
                                    UIService.addToast('Parâmetro adicionado com sucesso');
                                    setParametros(a);
                                })
                                    .catch(e => {
                                    UIService.addToast('Ocorreu um erro ao adicionar parâmetro');
                                });
                            }
                        }
                    });
                }}>
                            <MdiIcon path={mdiPlus}/>
                          </IconButton>
                        </Tooltip>)}/>
                  <DraggableList disabled={!editing} itemKey={'id'} items={parametros.filter(r => r.active).sort((a, b) => a.ordem - b.ordem)} render={item => {
                return [
                    <ListItemAvatar>
                          <Avatar>{item.codigo}</Avatar>
                        </ListItemAvatar>,
                    <ListItemText onClick={() => {
                            $state.go('app.administration.lists.view-parametro', { lista: $state.params.id, id: item.id });
                        }} primary={item.designacao}/>
                ];
            }} action={(item, i) => {
                return (editing && (<Box sx={{ display: 'flex' }}>
                            <Tooltip title='Editar parâmetro'>
                              <IconButton onClick={() => {
                        $state.go('app.administration.lists.view-parametro', { lista: $state.params.id, id: item.id });
                    }}>
                                <MdiIcon path={mdiPencil}/>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Eliminar parâmetro'>
                              <IconButton onClick={() => {
                        UIService.showConfirm(`Deseja eliminar o parâmetro ${item.codigo} - ${item.designacao}?`).then(r => {
                            if (r) {
                                let aux = [...parametros];
                                aux[i].active = 0;
                                aux = aux.filter(r => r.active);
                                reorder(aux, 0, aux.length - 1);
                                setParametros(aux);
                                // Eliminar parametro
                                ListaRelatorioService.saveParametro(item.id, { active: 0 }).then(() => {
                                    UIService.addToast('Parâmetro eliminado com sucesso');
                                });
                            }
                        });
                    }}>
                                <MdiIcon path={mdiDelete}/>
                              </IconButton>
                            </Tooltip>
                          </Box>));
            }} onDragEnd={onParametroDragEnd}/>
                </Card>
                <Card sx={{ mb: 1 }}>
                  <CardHeader title='Ensaios' action={editing && (<Tooltip title='Adicionar novo ensaio'>
                          <IconButton aria-label='settings' onClick={() => {
                    showModal(EnsaioDialog, {
                        initial: {},
                        onClose: data => {
                            if (data) {
                                let a = [...ensaios];
                                data.ordem = a.length + 1;
                                ListaRelatorioService.createEnsaio({ ...data, coreListaRelatorioId: $state.params.id, id: 0, active: 1 })
                                    .then(x => {
                                    a.push(x);
                                    UIService.addToast('Ensaio adicionado com sucesso');
                                    setEnsaios(a);
                                })
                                    .catch(e => {
                                    UIService.addToast('Ocorreu um erro ao adicionar ensaio');
                                });
                            }
                        }
                    });
                }}>
                            <MdiIcon path={mdiPlus}/>
                          </IconButton>
                        </Tooltip>)}/>
                  <DraggableList disabled={!editing} itemKey={'id'} items={ensaios.filter(r => r.active).sort((a, b) => a.ordem - b.ordem)} render={item => {
                return [
                    <ListItemAvatar>
                          <Avatar>{item.codigo}</Avatar>
                        </ListItemAvatar>,
                    <ListItemText onClick={() => {
                            $state.go('app.administration.lists.view-ensaio', { lista: $state.params.id, id: item.id });
                        }} primary={item.designacao}/>
                ];
            }} action={(item, i) => {
                return (editing && (<Box sx={{ display: 'flex' }}>
                            <Tooltip title='Editar ensaio'>
                              <IconButton onClick={() => {
                        $state.go('app.administration.lists.view-ensaio', { lista: $state.params.id, id: item.id });
                    }}>
                                <MdiIcon path={mdiPencil}/>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Eliminar ensaio'>
                              <IconButton onClick={() => {
                        UIService.showConfirm(`Deseja eliminar o ensaio ${item.codigo} - ${item.designacao}?`).then(r => {
                            if (r) {
                                let aux = [...ensaios];
                                aux[i].active = 0;
                                aux = aux.filter(r => r.active);
                                reorder(aux, 0, aux.length - 1);
                                setEnsaios(aux);
                                // Eliminar parametro
                                ListaRelatorioService.saveEnsaio(item.id, { active: 0 }).then(() => {
                                    UIService.addToast('Ensaio eliminado com sucesso');
                                });
                            }
                        });
                    }}>
                                <MdiIcon path={mdiDelete}/>
                              </IconButton>
                            </Tooltip>
                          </Box>));
            }} onDragEnd={onEnsaioDragEnd}/>
                </Card>
                <Card sx={{ mb: 1 }}>
                  <CardHeader title='Equipamentos' action={editing && (<Tooltip title='Adicionar novo equipamento'>
                          <IconButton aria-label='settings' onClick={() => {
                    showModal(EquipamentoDialog, {
                        initial: {},
                        onClose: data => {
                            if (data) {
                                let a = [...equipamentos];
                                data.ordem = a.length + 1;
                                ListaRelatorioService.createEquipamento({ ...data, coreListaRelatorioId: $state.params.id, id: 0, active: 1 })
                                    .then(x => {
                                    a.push(x);
                                    UIService.addToast('Equipamento adicionado com sucesso');
                                    setEquipamentos(a);
                                })
                                    .catch(e => {
                                    UIService.addToast('Ocorreu um erro ao adicionar equipamento');
                                });
                            }
                        }
                    });
                }}>
                            <MdiIcon path={mdiPlus}/>
                          </IconButton>
                        </Tooltip>)}/>
                  <DraggableList disabled={!editing} itemKey={'id'} items={equipamentos.filter(r => r.active).sort((a, b) => a.ordem - b.ordem)} render={item => {
                return [
                    <ListItemAvatar>
                          <Avatar>{item.codigo}</Avatar>
                        </ListItemAvatar>,
                    <ListItemText onClick={() => {
                            $state.go('app.administration.lists.view-equipamento', { lista: $state.params.id, id: item.id });
                        }} primary={item.designacao}/>
                ];
            }} action={(item, i) => {
                return (editing && (<Box sx={{ display: 'flex' }}>
                            <Tooltip title='Editar equipamento'>
                              <IconButton onClick={() => {
                        $state.go('app.administration.lists.view-equipamento', { lista: $state.params.id, id: item.id });
                    }}>
                                <MdiIcon path={mdiPencil}/>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Eliminar equipamento'>
                              <IconButton onClick={() => {
                        UIService.showConfirm(`Deseja eliminar o equipamento $${item.designacao}?`).then(r => {
                            if (r) {
                                let aux = [...equipamentos];
                                aux[i].active = 0;
                                aux = aux.filter(r => r.active);
                                reorder(aux, 0, aux.length - 1);
                                setEquipamentos(aux);
                                // Eliminar parametro
                                ListaRelatorioService.saveEquipamento(item.id, { active: 0 }).then(() => {
                                    UIService.addToast('Equipamento eliminado com sucesso');
                                });
                            }
                        });
                    }}>
                                <MdiIcon path={mdiDelete}/>
                              </IconButton>
                            </Tooltip>
                          </Box>));
            }} onDragEnd={onEquipamentoDragEnd}/>
                </Card>
              </Grid>
            </Grid>
          </Box>) : undefined}
        {!loading && (<Box sx={{
                position: 'fixed',
                margin: 2,
                bottom: 0,
                right: 0,
                display: 'flex',
                gap: 1
            }}>
            {data?.dataPublicacao == undefined && (<Tooltip title='Publicar'>
                <Fab color='success' onClick={() => publish()}>
                  <MdiIcon path={mdiCheckBold}/>
                </Fab>
              </Tooltip>)}
            {data?.dataPublicacao == undefined && (<Tooltip title={'Guardar alterações'}>
                <Fab color='error' onClick={async () => {
                    if (formik.isValid) {
                        const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                        await formik.submitForm();
                        wait.destroy();
                        UIService.addToast('Lista atualizada com sucesso');
                        fetchData();
                    }
                    else {
                        UIService.addToast('Corrija os erros apresentados no ecrã');
                    }
                }}>
                  <MdiIcon path={mdiContentSave}/>
                </Fab>
              </Tooltip>)}
            {
            // TODO: Clonar
            }
            {data?.dataPublicacao && false ? (<Tooltip title='Clonar'>
                <Fab variant='extended'>
                  <MdiIcon path={mdiWindowRestore} sx={{ mr: 1 }}/>
                  Clonar
                </Fab>
              </Tooltip>) : undefined}
          </Box>)}
      </Box>
    </Box>);
};
export default DetailsRelatorios;
