import angular from 'angular';

import Agendamentos from "./agendamentos";
import Mensagens from "./mensagens";
import Noticias from "./noticias";
import Pedidos from "./pedidos";
import Requisitantes from "./requisitantes";

export default angular.module('app.ie', [Agendamentos, Mensagens, Noticias, Pedidos, Requisitantes])
  .name;
