export default class DirectoryPedidosReportController {
  constructor($state, UIService, IeRelatorio, AuthenticationService, Group, Tipoimovel) {
    this.$state = $state;
    this.UI = UIService;
    this.Group = Group;
    this.Tipoimovel = Tipoimovel;
    this.IeRelatorio = IeRelatorio;
    this.Auth = AuthenticationService;

    this.tableLoading = true;

    this.simnao = [{name: "Sim", value: 1}, {name: "Não", value: 0}];
    this.resultados = [
      {name: "AGUARDA SUBMISSÃO", value: 0, css: {fontWeight: 500, color: '#4d575d'}},
      {name: "APROVADA", value: 1, css: {fontWeight: 500, color: '#388e3c'}},
      {name: "APROVADA, COM DEFICIÊNCIAS", value: 2, css: {fontWeight: 500, color: '#388e3c'}},
      {name: "INST.C/DEFICIÊNCIAS P/ SUPERAR 60 DIAS", value: 3, css: {fontWeight: 500, color: '#ffa000'}},
      {name: "REPROVADA", value: 4, css: {fontWeight: 500, color: '#d32f2f'}}
    ];
    this.funcionarios = [];
    this.tiposImovel = [];
    this.filtersLoaded = [1, 1, 0, 0];

    this.opt = $state.params;
    // Total processes for currently being displayed
    this.total = 0;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [
      {
        displayName: "ID SGI",
        name: "id",
        visible: false,
        sortable: true
      },
      {
        displayName: "Nº Relatório",
        name: "identificacao",
        visible: true,
        sortable: false
      },
      {
        displayName: "Referente a ",
        name: "nota",
        visible: true,
        sortable: true
      },
      {
        displayName: "Nº revisão",
        name: "versao",
        visible: true,
        sortable: false
      },
      {
        displayName: "Resultado",
        name: "resultado",
        visible: true,
        sortable: false
      },
      {
        displayName: "Data de submissão",
        name: "submissao",
        visible: true,
        sortable: false
      },
      {
        displayName: "ID Pedido SGI",
        name: "pedidoId",
        visible: false,
        sortable: true
      },
      {
        displayName: "ID Pedido",
        name: "pedidoIdentificacaoInterna",
        visible: true,
        sortable: true
      },
      {
        displayName: "Data de inspeção",
        name: "dataInspecao",
        visible: true,
        sortable: false
      },
      {
        displayName: "Hora de inspeção",
        name: "horaInspecao",
        visible: false,
        sortable: false
      },
      {
        displayName: "Inspetor",
        name: "colaborador",
        visible: true,
        sortable: true
      },
      {
        displayName: "Validado?",
        name: "validado",
        visible: true,
        sortable: true
      },
      {
        displayName: "Data/Hora validação",
        name: "validadoa",
        visible: false,
        sortable: true
      },
      {
        displayName: "Validado por",
        name: "validadopor",
        visible: false,
        sortable: true
      },
      {
        displayName: "Data de conclusão",
        name: "dataConclusao",
        visible: false,
        sortable: true
      },
      {
        displayName: "Tipo de Imóvel",
        name: "tipoimovel",
        visible: false,
        sortable: true
      },
      {
        displayName: "NIP",
        name: "nip",
        visible: false,
        sortable: true
      },
      {
        displayName: "CPE",
        name: "cpe",
        visible: false,
        sortable: true
      },
      {
        displayName: "Potência",
        name: "potencia",
        visible: false,
        sortable: true
      },
      {
        displayName: "Morada",
        name: "morada",
        visible: false,
        sortable: true
      },
      {
        displayName: "Localidade",
        name: "localidade",
        visible: false,
        sortable: true
      },
      {
        displayName: "CP4",
        name: "cp4",
        visible: false,
        sortable: true
      },
      {
        displayName: "CP3",
        name: "cp3",
        visible: false,
        sortable: true
      },
      {
        displayName: "Nome Téc. Resp.",
        name: "nomeTecnico",
        visible: false,
        sortable: true
      },
      {
        displayName: "Nº DGEG Téc. Resp.",
        name: "nTecnico",
        visible: false,
        sortable: true
      }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: "IeRelatorio.id",
        name: "ID SGI",
        type: "o"
      },
      {
        id: "IeRelatorio.identificacao",
        name: "Nº Relatório",
        type: "o"
      },
      {
        id: "IeRelatorio.resultado",
        name: "Resultado",
        type: "s",
        list: this.resultados
      },
      {
        id: "IeRelatorio.submissao",
        name: "Data de submissão",
        format: "YYYY-MM-DD HH:mm",
        displayFormat: "DD/MM/YYYY HH:mm",
        type: "d"
      },
      {
        id: "Pedidoinspeccao.id",
        name: "ID Pedido SGI",
        type: "o"
      },
      {
        id: "Pedidoinspeccao.identificacaoInterna",
        name: "ID Pedido",
        type: "o"
      },
      {
        id: "Agendamento.data",
        name: "Data de inspeção",
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY",
        type: "d"
      },
      {
        id: "IeRelatorio.tecnicoId",
        name: "Inspetor",
        type: "s",
        list: this.funcionarios
      },
      {
        id: "IeRelatorio.validado",
        name: "Validado?",
        type: "s",
        list: this.simnao
      },
      {
        id: "IeRelatorio.validadoa",
        name: "Data/Hora validação",
        format: "YYYY-MM-DD HH:mm",
        displayFormat: "DD/MM/YYYY HH:mm",
        type: "d"
      },
      {
        id: "IeRelatorio.validadoporId",
        name: "Validado por",
        type: "s",
        list: this.funcionarios
      },
      {
        id: "Pedidoinspeccao.dataConclusao",
        name: "Data de conclusão",
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY HH:mm",
        type: "d"
      },
      {
        id: "Fraccao.tipoimovelId",
        name: "Tipo de imóvel",
        type: "s",
        list: this.tiposImovel
      },
      {
        id: "Dadosinstalacao.nip",
        name: "NIP",
        type: "o"
      },
      {
        id: "Fraccao.cpe",
        name: "CPE",
        type: "o"
      },
      {
        id: "Fraccao.potencia",
        name: "Potência",
        type: "o"
      },
      {
        id: "Dadosinstalacao.morada",
        name: "Morada",
        type: "o"
      },
      {
        id: "Dadosinstalacao.codigoPostal3",
        name: "Localidade",
        type: "o"
      },
      {
        id: "Dadosinstalacao.codigoPostal1",
        name: "CP4",
        type: "o"
      },
      {
        id: "Dadosinstalacao.codigoPostal2",
        name: "CP3",
        type: "o"
      },
      {
        id: "Tecnico.nome",
        name: "Nome Téc. Resp.",
        type: "o"
      },
      {
        id: "Tecnico.nTecnico",
        name: "Nº DGEG Téc. Resp.",
        type: "o"
      }
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem("IeRelatorioDisplayColumns")) {
      let cols = JSON.parse(localStorage.getItem("IeRelatorioDisplayColumns"));
      if (cols && cols.length > 0) {
        cols.forEach((c) => {
          let i = this.displayColumns.findIndex((x) => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem("IeRelatorioFilter")) {
      this.customFilters = JSON.parse(localStorage.getItem("IeRelatorioFilter"));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach((filter) => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find((f) => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast("Não foi possível carregar filtros");
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem("IeRelatorioFilter", JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === "IeRelatorio.tecnicoId")
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === "IeRelatorio.resultado")
          f.column.selected.list = this.resultados;
        if (f.column.selected.id === "IeRelatorio.validadoporId")
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === "IeRelatorio.validado")
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === "Fraccao.tipoimovelId")
          f.column.selected.list = this.tiposImovel;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === "d") {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false; // NOT USED IN THIS VIEW

    this.loadData();

    this.getRelatorios();
  }

  isColumnVisible = (column) => {
    let f = this.displayColumns.find((x) => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  canSelect = (row) => {
    return true;
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find((x) => x.key === data.key)) {
      // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value,
      });
    } else {
      // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) {
        // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key],
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value,
        });
      } else {
        // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach((f) => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (
        f.column.selected.id.split(".").filter(Boolean).length === 1 && f.column.selected.type === "d") {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else {
        // All other cases where fields exist in database
        if (f.column.selected.type === "o") {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === "d") {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format,
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id) data.value = f.value.selected.id;
            else data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral,
    };
  };

  loadData = () => {
    this.Group.findOne({
      filter: {
        where: {
          name: {
            like: "Inspetor%IE",
          },
        },
        include: {
          relation: "usergroup",
          scope: {
            include: "user",
          },
        },
      },
    }).$promise.then((g) => {
      let funcionarios = [];
      g.usergroup.forEach((u) => {
        funcionarios.push(u.user);
      });

      funcionarios = _.orderBy(funcionarios, "name", "asc");
      this.filtersLoaded[2] = 1;
      this.funcionarios = funcionarios;
    }).catch((e) => {
      this.filtersLoaded[2] = 1;
      this.funcionarios = [];
    });

    this.Tipoimovel.find({
    }).$promise.then(tiposImovel => {
      tiposImovel.forEach(t => {
        t.name = t.designacao;
      });
      this.tiposImovel = tiposImovel;
      this.filtersLoaded[3] = 1;
    }).catch(e => {
      this.tiposImovel = [];
      this.filtersLoaded[3] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Tipos de Imóvel).");
    });
  };

  isDateValid = (date) => {
    return moment(date).isValid();
  };

  clearFilter = () => {
    localStorage.removeItem('IeRelatorioFilter');
    this.customFilters = [];
    this.getRelatorios();
  };

  getRelatorios = () => {
    this.opt = this.opt || this.defaultOpt;

    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {'Agendamento.active': 1});
    this.IeRelatorio.table({
      params: {
        fields: [
          "IeRelatorio.id as id",
          "IeRelatorio.identificacao as identificacao",
          "IeRelatorio.nota as nota",
          "IeRelatorio.versao as versao",
          "IeRelatorio.resultado as resultado",
          "IeRelatorio.submissao as submissao",
          "Pedidoinspeccao.id as pedidoId",
          "Pedidoinspeccao.identificacaoInterna as pedidoIdentificacaoInterna",
          "Pedidoinspeccao.dataConclusao as dataConclusao",
          "Agendamento.data as dataInspecao",
          "Agendamento.hora as horaInspecao",
          "Funcionario.name as colaborador",
          "IeRelatorio.validado as validado",
          "IeRelatorio.validadoa as validadoa",
          "Funcionario2.name as validadopor",
          "Tipoimovel.designacao as tipoimovel",
          "Dadosinstalacao.nip as nip",
          "Fraccao.cpe as cpe",
          "Fraccao.potencia as potencia",
          "Dadosinstalacao.morada as morada",
          "Dadosinstalacao.codigoPostal3 as localidade",
          "Dadosinstalacao.codigoPostal1 as cp4",
          "Dadosinstalacao.codigoPostal2 as cp3",
          "Tecnico.nome as nomeTecnico",
          "Tecnico.nTecnico as nTecnico"
        ],
        from: ["IeRelatorio", "Funcionario", "Pedidoinspeccao", "Agendamento", "Funcionario", "Fraccao", "Tipoimovel", "Dadosinstalacao", "Tecnico"],
        referencesOrigin: [undefined, undefined, undefined, "Agendamento.pedidoId", undefined, undefined, "Tipoimovel.id", "Dadosinstalacao.id", "Tecnico.pedidoinspeccaoId"],
        references: [undefined, "IeRelatorio.tecnicoId", "IeRelatorio.pedidoId", "IeRelatorio.pedidoId", "IeRelatorio.validadoporId", "IeRelatorio.fracaoId", "Fraccao.tipoimovelId", "Fraccao.dadosinstalacaoId", "Pedidoinspeccao.id"],
        aliases: [undefined, undefined, undefined, undefined, "Funcionario2"],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      },
    }).$promise.then((res) => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if (this.start - 1 + items >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process data for output
      res.data.forEach(r => {
        if (r.resultado >= 0 && r.resultado <= 4)
          r.resultado = this.resultados[r.resultado];
      });

      this.relatorios = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((e) => {
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível carregar relatórios com os parâmetros fornecidos.");
    });
  };

  exportRelatorios = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, {'Agendamento.active': 1});
    this.IeRelatorio.exportRelatorios({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          "IeRelatorio.id as id",
          "IeRelatorio.identificacao as identificacao",
          "IeRelatorio.nota as nota",
          "IeRelatorio.versao as versao",
          "IeRelatorio.resultado as resultado",
          "IeRelatorio.submissao as submissao",
          "Pedidoinspeccao.id as pedidoId",
          "Pedidoinspeccao.identificacaoInterna as pedidoIdentificacaoInterna",
          "Pedidoinspeccao.dataConclusao as dataConclusao",
          "Agendamento.data as dataInspecao",
          "Agendamento.hora as horaInspecao",
          "Funcionario.name as colaborador",
          "IeRelatorio.validado as validado",
          "IeRelatorio.validadoa as validadoa",
          "Funcionario2.name as validadopor",
          "Tipoimovel.designacao as tipoimovel",
          "Dadosinstalacao.nip as nip",
          "Fraccao.cpe as cpe",
          "Fraccao.potencia as potencia",
          "Dadosinstalacao.morada as morada",
          "Dadosinstalacao.codigoPostal3 as localidade",
          "Dadosinstalacao.codigoPostal1 as cp4",
          "Dadosinstalacao.codigoPostal2 as cp3",
          "Tecnico.nome as nomeTecnico",
          "Tecnico.nTecnico as nTecnico"
        ],
        from: ["IeRelatorio", "Funcionario", "Pedidoinspeccao", "Agendamento", "Funcionario", "Fraccao", "Tipoimovel", "Dadosinstalacao", "Tecnico"],
        referencesOrigin: [undefined, undefined, undefined, "Agendamento.pedidoId", undefined, undefined, "Tipoimovel.id", "Dadosinstalacao.id", "Tecnico.pedidoinspeccaoId"],
        references: [undefined, "IeRelatorio.tecnicoId", "IeRelatorio.pedidoId", "IeRelatorio.pedidoId", "IeRelatorio.validadoporId", "IeRelatorio.fracaoId", "Fraccao.tipoimovelId", "Fraccao.dadosinstalacaoId", "Pedidoinspeccao.id"],
        aliases: [undefined, undefined, undefined, undefined, "Funcionario2"],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      },
    }).$promise.then(res => {
      wait.close();
      if (res.count) {
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'relatoriosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar relatórios. Por favor tente mais tarde.");
    });
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.relatorios, x => x.selected), x => {
        return { key: 'IeRelatorio.id', value: x.id };
      });
    let whereFields = this.setWhereFields(this.customFilters, { 'Agendamento.active': 1 }, orsLiteral);
    this.IeRelatorio.exportRelatorios({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          "IeRelatorio.id as id",
          "IeRelatorio.identificacao as identificacao",
          "IeRelatorio.nota as nota",
          "IeRelatorio.versao as versao",
          "IeRelatorio.resultado as resultado",
          "IeRelatorio.submissao as submissao",
          "Pedidoinspeccao.id as pedidoId",
          "Pedidoinspeccao.identificacaoInterna as pedidoIdentificacaoInterna",
          "Pedidoinspeccao.dataConclusao as dataConclusao",
          "Agendamento.data as dataInspecao",
          "Agendamento.hora as horaInspecao",
          "Funcionario.name as colaborador",
          "IeRelatorio.validado as validado",
          "IeRelatorio.validadoa as validadoa",
          "Funcionario2.name as validadopor",
          "Tipoimovel.designacao as tipoimovel",
          "Dadosinstalacao.nip as nip",
          "Fraccao.cpe as cpe",
          "Fraccao.potencia as potencia",
          "Dadosinstalacao.morada as morada",
          "Dadosinstalacao.codigoPostal3 as localidade",
          "Dadosinstalacao.codigoPostal1 as cp4",
          "Dadosinstalacao.codigoPostal2 as cp3",
          "Tecnico.nome as nomeTecnico",
          "Tecnico.nTecnico as nTecnico"
        ],
        from: ["IeRelatorio", "Funcionario", "Pedidoinspeccao", "Agendamento", "Funcionario", "Fraccao", "Tipoimovel", "Dadosinstalacao", "Tecnico"],
        referencesOrigin: [undefined, undefined, undefined, "Agendamento.pedidoId", undefined, undefined, "Tipoimovel.id", "Dadosinstalacao.id", "Tecnico.pedidoinspeccaoId"],
        references: [undefined, "IeRelatorio.tecnicoId", "IeRelatorio.pedidoId", "IeRelatorio.pedidoId", "IeRelatorio.validadoporId", "IeRelatorio.fracaoId", "Fraccao.tipoimovelId", "Fraccao.dadosinstalacaoId", "Pedidoinspeccao.id"],
        aliases: [undefined, undefined, undefined, undefined, "Funcionario2"],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      },
    }).$promise.then(res => {
      wait.close();
      if (res.count) {
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'relatoriosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar relatórios. Por favor tente mais tarde.");
    });
  };

  hasSelect = () => {
    return _.some(this.relatorios, (a) => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) {
      // Not all are selected, select all
      this.relatorios.forEach((a) => {
        a.selected = true;
      });
      this.nSelected = this.relatorios.filter((a) => a.selected).length;
    } else {
      // Remove all selections
      this.relatorios.forEach((a) => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    // this.everythingSelected = false;
  };

  // NOT USED IN THIS VIEW
  selectEverything = (type) => {
  //   if (!type) {
  //     this.relatorios.forEach((a) => {
  //       a.selected = false;
  //     });
  //     this.nSelected = 0;
  //     this.allSelected = false;
  //   }
  //   this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.relatorios, (r) => r.selected).length;
    this.allSelected = this.nSelected >= this.relatorios.length;
    // this.everythingSelected = false;
  };

  sort = (key) => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname) this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === "asc" ? "desc" : "asc";
    this.$state.go("app.pedidos.reports.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getRelatorios();
  };

  item = (val) => {
    this.opt.items = val;
    this.$state.go("app.pedidos.reports.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getRelatorios();
  };

  page = (sum) => {
    this.opt.page += sum;
    if (this.opt.page < 1) this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go("app.pedidos.reports.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getRelatorios();
  };

  validateSelected = () => {
    let ids = this.relatorios.filter((x) => x.selected).map((x) => x.id);

    this.UI.showConfirm("Tem a certeza que pretende validar " + ids.length + (ids.length === 1 ? " relatório?" : " relatórios?")).then((res) => {
      if (res) {
        let wait = this.UI.showWaiting();

        // Find all ids and check if they can be validated or not

        this.IeRelatorio.validatePdfs({ids: ids}).$promise.then((res) => {
          wait.close();
          let message = "";
          if (res.validated === 0) {
            message = "Não foram validados relatórios.\n\n";
          } else
            message = (res.validated === 1 ? "Foi validado 1 relatório.\n\n" : "Foram validados " + res.validated + " relatórios.\n\n");

          if (res.noResult > 0) {
            message += res.noResult + (res.noResult === 1 ? " relatório" : " relatórios") + " a aguardar submissão.\n\n";
          }

          if (res.alreadyValid > 0) {
            message += res.alreadyValid + (res.alreadyValid === 1 ? " relatório" : " relatórios") + " já previamente " + (res.alreadyValid === 1 ? "validado" : "validados") + "\n\n";
          }
          this.UI.showAlert(message);
          this.getRelatorios();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na validação de relatório. Por favor recarregue a página.");
          this.loaded = true;
        });
      }
    }).catch((err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.simnao.length && !this.funcionario.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach((f) => {
      if (f.id === "IeRelatorio.tecnicoId")
        f.list = this.funcionarios;
      if (f.id === "IeRelatorio.resultado")
        f.list = this.resultados;
      if (f.id === "IeRelatorio.validadoporId")
        f.list = this.funcionarios;
      if (f.id === "IeRelatorio.validado")
        f.list = this.simnao;
      if (f.id === "Fraccao.tipoimovelId")
        f.list = this.tiposImovel;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === "IeRelatorio.tecnicoId")
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === "IeRelatorio.resultado")
          f.column.selected.list = this.resultados;
        if (f.column.selected.id === "IeRelatorio.validadoporId")
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === "IeRelatorio.validado")
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === "Fraccao.tipoimovelId")
          f.column.selected.list = this.tiposImovel;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === "s") i.value = {};
    else i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, (f) => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === "d") {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
        f.value = moment().isDST() ? f.value.add(-1, 'h') : f.value; // Fix time for moment
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem("IeRelatorioFilter", JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go("app.pedidos.reports.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });

    this.getRelatorios();
  };

  selectVisibleColumns = () => {
    let options = {
      size: "md",
      template: require("./columns.dialog.html"),
      controller: ["$dialog", "$scope", (dialog, scope) => {
          scope.title = "Editar Campos Visíveis";
          scope.displayColumns = angular.copy(this.displayColumns);

          scope.ok = () => {
            dialog.close(scope);
          };

          scope.cancel = () => {
            dialog.dismiss("cancel");
          };
        },
      ],
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem("IeRelatorioDisplayColumns", JSON.stringify(this.displayColumns));
      }
    });
  };
}

DirectoryPedidosReportController.$inject = ["$state", "UIService", "IeRelatorio", "AuthenticationService", "Group", "Tipoimovel"];
