export default class OblOINewController {
  constructor($state, $http, UIService, OblValencia, OblOrdemintervencao) {
    this.$state = $state;
    this.$http = $http;
    this.OblValencia = OblValencia;
    this.OblOrdemintervencao = OblOrdemintervencao;
    this.UI = UIService;

    this.aux = {};

    this.data = {
      id: 0,
      estadoId: 2,
      active: true,
      origem: 1,
      parceiro: "",
    };

    this.loadData();
    this.infiniteScroll = {};
    this.infiniteScroll.numToAdd = 20;
    this.infiniteScroll.currentItems = 20;
  }

  addMoreItems = () => {
    this.infiniteScroll.currentItems += this.infiniteScroll.numToAdd;
  };

  copyClient = () => {
    let c = angular.copy(this.aux.cliente);
    this.data.nomeCliente = c.nome;
    this.data.nifCliente = c.nif;
    this.data.emailCliente = c.email;
    this.data.tlmCliente = c.telemovel;
  }

  loadData = () => {
    this.OblValencia.find({ filter: { where: { active: true }, include: 'categoria' } }).$promise.then(valencias => {
      // To be able to search in dropdown by categoria too (needs linear object)
      this.valencias = [];
      valencias.forEach(v => {
        this.valencias.push({
          id: v.id,
          name: `${v.categoria.designacao} - ${v.designacao}`
        });
      });
      this.loaded = true;
    });
  }

  validate = () => {
    let messages = [];
    if (!this.aux.cliente) {
      messages.push("Selecione um cliente");
    }
    if (!this.data.nomeCliente) {
      messages.push("Introduza o cliente final");
    }
    if (!this.aux.valencia) {
      messages.push("Seleccione uma valência");
    }
    if (!this.data.morada) {
      messages.push("Introduza a morada");
    }
    if (!this.data.cp4 || !this.data.cp3) {
      messages.push("Introduza o código postal");
    }
    if (this.aux.cliente.parceiro && !this.data.parceiro) {
      messages.push("Introduza o código de parceiro");
    }
    if (!this.data.descricaoAvaria) {
      messages.push("Introduza uma breve descrição da avaria");
    }
    if (messages.length == 0)
      return true;
    this.UI.showFormMessage(messages);
    return false;
  }

  save = () => {
    if (this.validate()) {
      // fill remaining data
      this.data.clienteId = this.aux.cliente.id;
      this.data.valenciaId = this.aux.valencia.id;
      this.data.data = moment.utc();
      this.data.ultimaAlteracao = moment.utc();
      if (this.data.parceiro.trim().length > 0) {
        this.data.origem = 2;
      }
      this.OblOrdemintervencao.create(this.data).$promise.then(r => {
        this.UI.addToast("Ordem de intervenção adicionada");
        this.$state.go('app.obler.oi.details', { id: r.id });
      }).catch(e => {
        this.UI.addToast("Ocorreu um erro ao gravar ordem de intervenção");
      });
    }
  }

  pickClient = (num) => {
    this.UI.showDialog({
      template: require('./client.dialog.html'),
      controller: ['OblCliente', '$scope', (OblCliente, $scope) => {

        $scope.aux = {};

        $scope.items = [];

        $scope.fetch = ($select, $evt) => {
          if (!$evt) {
            $scope.items = [];
          } else {
            $evt.stopPropagation();
            $evt.preventDefault();
          }
          if ($select.search.length < 2) return;
          OblCliente.find({
            filter: {
              where: {
                or: [{
                  nif: {
                    like: `%${$select.search}%`
                  }
                },
                {
                  email: {
                    like: `%${$select.search}%`
                  }
                },
                {
                  nome: {
                    like: `%${$select.search}%`
                  }
                },
                ]

              }, limit: 100
            }
          }).$promise.then(r => {
            $scope.items = r;
          });
        }

        $scope.loaded = true;

        $scope.ok = () => {
          $scope.data = {
            cliente: $scope.aux.cliente
          };
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }

      }]
    }).then(r => {
      if (r) {
        // Copia dados de cliente
        if (num == 1) {
          this.aux.cliente = angular.copy(r.cliente);
        } else {
          this.data.nomeCliente = r.cliente.nome;
          this.data.nifCliente = r.cliente.nif;
          this.data.emailCliente = r.cliente.email;
          this.data.tlmCliente = r.cliente.telemovel;
        }
        this.UI.showConfirm('Utilizar morada de cliente?').then(_ => {
          if (_) {
            this.data.morada = r.cliente.morada;
            this.data.cp4 = r.cliente.cp4;
            this.data.cp3 = r.cliente.cp3;
            this.formatZipCode();
          }
        });
      }
    })
  }

  formatZipCode = () => {
    if (this.data.cp4.trim().length < 4) {
      return;
    }
    if (this.data.cp4 === null) {
    } else if (this.data.cp4 !== null && this.data.cp3 === undefined) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" + this.data.cp4,
          cp3: "000"
        }
      }).then(response => {
        this.data.localidade = response.data.result;
      });

    } else if (this.data.cp4 !== null && this.data.cp3 !== undefined) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" + this.data.cp4,
          cp3: ("000" + this.data.cp3).slice(-3)
        }
      }).then(response => {
        this.data.localidade = response.data.result;
      });
    }
  };

  watchZip = (key) => {
    if (this.data.cp4.trim().length == 4 && (key.which >= 48 && key.which <= 57)) {
      document.getElementById("cp3").focus();
    }
  }
}

OblOINewController.$inject = ['$state', '$http', 'UIService', 'OblValencia', 'OblOrdemintervencao'];
