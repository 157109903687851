import { Box, IconButton, Tooltip } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT } from 'material-react-table/locales/pt';
import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { MdiIcon } from './../MdiIcon';
import { mdiFilterOff, mdiFilterVariant, mdiFilterVariantRemove, mdiViewColumn } from '@mdi/js';
import { PaginationFilterType } from '../../../../types/PaginationFilterType';
import { FieldsDialog } from './dialog';
let filters = [];
let keys = {};
let sorted = [];
export function Table({ storageKey, data, columns, onPaginate, onSort, onFilter, onDoubleClick, selectable, selectActions, isLoading, options, cellActionItems, withRowStyle, initial }) {
    data = data ?? {
        data: [],
        meta: { currentPage: 0, itemsPerPage: 20, totalItems: 0, totalPages: 0 },
        links: {}
    };
    options = options ?? { filter: [], sort: [] };
    const [columnStatus, setColumnStatus] = useState(JSON.parse(localStorage.getItem(storageKey) || '{}'));
    const [open, setOpen] = useState(false);
    const [cols, setCols] = useState(columns);
    const [showFilter, setShowFilter] = useState(true);
    const [columnFilterFns, setColumnFilterFns] = useState({});
    const [selected, setSelected] = useState({});
    const [columnFilter, setColumnFilter] = useState(() => (initial && initial.filter ? initial.filter : []));
    const [columnSort, setColumnSort] = useState(initial && initial.sort ? initial.sort : []);
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = 'Não há registos a serem exibidos';
    const checkStorage = () => {
        function compare(array1, array2) {
            array1.sort();
            array2.sort();
            for (var i = 0; i < array1.length; i++) {
                if (array1[i] !== array2[i])
                    return false;
            }
            return true;
        }
        if (!compare(Object.keys(columnStatus), Object.keys(keys)) || !Object.keys(columnStatus).length) {
            localStorage.setItem(storageKey, JSON.stringify(keys));
        }
    };
    useEffect(() => {
        // First time, set all column filters as equal to avoid undefined
        if (!Object.keys(columnFilterFns).length) {
            const a = {};
            cols.forEach(r => {
                a[r.id] = 'equals';
            });
            setColumnFilterFns(a);
        }
        if (!Object.keys(keys).length) {
            cols.forEach(r => {
                keys[`${r.accessorKey}`] = true;
            });
        }
        checkStorage();
        // Array filter Validation
        const aux = columnFilter.map(filter => {
            const filterFn = columnFilterFns[filter.id];
            if (filterFn == 'empty' || filterFn == 'notEmpty') {
                return { id: filter.id, value: ' ', type: filterFn };
            }
            if (filter.value != '') {
                let value = filter.value;
                if (filter.value instanceof moment) {
                    value = moment(filter.value).format();
                }
                if (filter.value === 'true' || filter.value === 'false')
                    value = Number(filter.value === 'true');
                return {
                    id: filter.id,
                    value: value,
                    type: filterFn || 'equals'
                };
            }
            return { id: filter.id, value: ' ', type: filterFn };
        }) || [];
        if (JSON.stringify(aux) !== JSON.stringify(filters)) {
            filters = aux;
            onFilter(aux);
        }
        columns.forEach(r => {
            r.enableColumnFilter = false;
        });
        // Filter Validation for each collumn
        if (options.filter) {
            const keys = options.filter;
            for (let i = 0; i < keys.length; i++) {
                const column = columns.find(r => r.accessorKey == keys[i].name);
                if (column != undefined) {
                    column.enableColumnFilter = true;
                    column.filterFn = 'equals';
                    if (keys[i].type.toUpperCase() == PaginationFilterType.BOOLEAN) {
                        column.filterVariant = 'checkbox';
                    }
                    if (keys[i].type.toUpperCase() == PaginationFilterType.TEXT) {
                        column.columnFilterModeOptions = ['equals', 'notEquals', 'contains', 'startsWith', 'empty', 'notEmpty'];
                    }
                    if (keys[i].type.toUpperCase() == PaginationFilterType.NUMBER) {
                        column.columnFilterModeOptions = ['equals', 'notEquals', 'greaterThan', 'greaterThanOrEqualTo', 'lessThan', 'lessThanOrEqualTo'];
                    }
                    if (keys[i].type.toUpperCase() == PaginationFilterType.DATE) {
                        column.columnFilterModeOptions = ['equals', 'notEquals', 'greaterThan', 'greaterThanOrEqualTo', 'lessThan', 'lessThanOrEqualTo', 'empty', 'notEmpty'];
                        column.filterVariant = 'datetime';
                    }
                    if (keys[i].type.toUpperCase() == PaginationFilterType.SELECT) {
                        column.filterVariant = 'select';
                        column.columnFilterModeOptions = [];
                        if (column.filterSelectOptions == undefined) {
                            throw new Error('Por favor especifique a propriedade filterSelectOptions na definição da coluna ' + column.accessorKey);
                        }
                    }
                    if (keys[i].type.toUpperCase() == PaginationFilterType.MULTI_SELECT) {
                        column.filterVariant = 'multi-select';
                        column.columnFilterModeOptions = [];
                        if (column.filterSelectOptions == undefined) {
                            throw new Error('Por favor especifique a propriedade filterSelectOptions na definição da coluna ' + column.accessorKey);
                        }
                    }
                    if (keys[i].type.toUpperCase() == PaginationFilterType.DATE_RANGE) {
                        column.columnFilterModeOptions = [];
                        column.filterVariant = 'datetime-range';
                    }
                }
            }
            const result = [];
            Array.prototype.push.apply(result, columns);
            setCols(result);
        }
        columns.forEach(r => {
            r.enableSorting = false;
        });
        const aux2 = columnSort.map(sort => {
            return { id: sort.id, desc: sort.desc };
        });
        if (JSON.stringify(aux2) !== JSON.stringify(sorted)) {
            sorted = aux2;
            onSort(aux2);
        }
        if (options.sort) {
            const keySort = options.sort;
            const sortedColumns = columns.map(col => {
                if (keySort.includes(col.accessorKey)) {
                    col.enableSorting = true;
                    let a = { sortable: true };
                    const k = Object.keys(col);
                    const v = Object.values(col);
                    for (let i = 0; i < Object.keys(col).length; i++) {
                        a[k[i]] = v[i];
                    }
                    return a;
                }
                return col;
            });
            setCols(sortedColumns);
        }
    }, [options, columnStatus, columnFilterFns, columnFilter, columnSort, onFilter, onSort, columns]);
    const getSelectedIds = () => {
        let s = Object.entries(selected).map(([k, v]) => [Number(k), v]);
        let r = [];
        s.forEach(row => {
            if (row[1] == true) {
                r.push(row[0]);
            }
        });
        console.log(r);
        return r;
    };
    return (<LocalizationProvider dateAdapter={AdapterMoment}>
      <FieldsDialog storageKey={storageKey} open={open} columns={columns} onSubmit={data => {
            setColumnStatus(data);
        }} close={() => setOpen(false)}/>
      <MaterialReactTable renderToolbarInternalActions={({ table }) => selectable && getSelectedIds().length > 0 ? (selectActions(getSelectedIds())) : (<Box>
              {columnFilter.length > 0 && (<Tooltip title='Limpar filtro(s) aplicado(s)'>
                  <IconButton onClick={() => {
                    setColumnFilterFns({});
                    setColumnFilter([]);
                }}>
                    <MdiIcon path={mdiFilterOff}/>
                  </IconButton>
                </Tooltip>)}
              <Tooltip title='Mostrar/ocultar filtros'>
                <IconButton onClick={() => {
                setShowFilter(!showFilter);
            }}>
                  <MdiIcon path={showFilter ? mdiFilterVariantRemove : mdiFilterVariant}/>
                </IconButton>
              </Tooltip>
              <Tooltip title='Mostrar/ocultar colunas'>
                <IconButton onClick={() => {
                setOpen(true);
            }}>
                  <MdiIcon path={mdiViewColumn}/>
                </IconButton>
              </Tooltip>
            </Box>)} getRowId={row => {
            const a = row;
            return a.id;
        }} columns={cols} data={data.data} rowCount={data.meta.totalItems} pageCount={data.meta.totalPages} state={{
            density: 'compact',
            rowSelection: selected,
            pagination: {
                pageIndex: data.meta.currentPage > 0 ? data.meta.currentPage - 1 : 0,
                pageSize: data.meta.itemsPerPage
            },
            columnFilterFns: columnFilterFns,
            columnFilters: columnFilter,
            isLoading,
            showColumnFilters: showFilter,
            sorting: columnSort.map(sort => ({ id: sort.id, desc: sort.desc })),
            columnVisibility: columnStatus
        }} localization={localization} muiCircularProgressProps={{
            color: 'secondary',
            thickness: 5,
            size: 55
        }} muiFilterCheckboxProps={{
            size: 'small'
        }} muiTableBodyCellProps={({ row }) => ({
            onDoubleClick: event => {
                onDoubleClick(row.original);
            },
            sx: {
                cursor: 'pointer' //you might want to change the cursor too when adding an onClick
            }
        })} manualPagination={true} onPaginationChange={onPaginate} manualSorting={true} onSortingChange={setColumnSort} enableColumnResizing={true} enableMultiRowSelection enableSelectAll={true} selectAllMode='page' enableRowSelection={selectable != undefined ? row => selectable(row) : false} enableBatchRowSelection={selectable != undefined} onRowSelectionChange={setSelected} layoutMode='grid' enableRowActions={true} enableStickyHeader={true} enableMultiSort={true} enableColumnFilters={true} enableColumnFilterModes={true} enableColumnOrdering={true} onColumnFilterFnsChange={setColumnFilterFns} manualFiltering={true} onColumnFiltersChange={setColumnFilter} positionActionsColumn='last' displayColumnDefOptions={{
            'mrt-row-actions': {
                header: '',
                grow: false
            },
            'mrt-row-select': {
                grow: false
            }
        }} renderRowActionMenuItems={cellActionItems} muiTableBodyRowProps={({ row }) => (withRowStyle ? withRowStyle(row.original) : {})}/>
    </LocalizationProvider>);
}
