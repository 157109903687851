export default class FormacaoLocaisController {
  constructor($state, UIService, NgTableParams, FormacaoLocal) {
    this.$state = $state;
    this.UI = UIService;
    this.FormacaoLocal = FormacaoLocal;
    this.NgTableParams = NgTableParams;

    this.hasLoaded = false;
    this.loadData();
  };

  loadData = () => {
    this.hasLoaded = false;
    this.FormacaoLocal.find({
      filter: {
        where: {
          active: 1
        },
        include: 'espaco',
        order: 'designacao asc',

      }
    }).$promise.then((res) => {
      let NgTableParams = this.NgTableParams;
      this.tableData = new NgTableParams({
        sorting: {
          designacao: 'asc'
        }
      }, {
        dataset: res,
      });
      this.hasLoaded = true;
    }).catch((err) => {
      console.log(err);
      this.hasLoaded = true;
      this.UI.addToast("Ocorreu um erro ao obter locais de formação.");
    });
  };

  confirmDeleteLocal = (i) => {
    let title = "Eliminar Local";
    let warning = "Tem a certeza que pretende remover este local?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeEspaco(i);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeEspaco = (s) => {
    this.hasLoaded = true;
    this.FormacaoLocal.findOne({
      filter: {
        where: {
          id: s.id,
          active: 1
        }
      }
    }).$promise.then((localToRemove) => {

      console.log(localToRemove);

      localToRemove.active = 0;
      localToRemove.$save().then((res) => {
        this.UI.addToast("Local eliminado com sucesso!");
        this.loadData();
      }, (error) => {
        console.log(error);
        this.hasLoaded = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.hasLoaded = false;
      this.UI.addToast("Não foi possível eliminar o local. Verifique se ainda existe.");
    });
  };

  addLocal = () => {
    let self = this;

    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./location.dialog.html'),
      controller: ['$scope', function ($scope) {
        $scope.local = {};
        $scope.local.id = 0;
        $scope.local.designacao = '';
        $scope.local.observacoes = '';
        $scope.local.active = 1;
        $scope.label = 'Adicionar local';

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.hasLoaded = true;
      this.FormacaoLocal.create(ok.local).$promise.then(r => {
        this.loadData();
        this.UI.addToast("Local inserido com sucesso!");
      }).catch(error => {
        console.log(error);
        this.hasLoaded = false;
        this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };
}

FormacaoLocaisController.$inject = ['$state', 'UIService', 'NgTableParams', 'FormacaoLocal'];
