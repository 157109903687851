export function routes($stateProvider) {
  $stateProvider
    .state('app.pad.processo', {
      url: '/processos',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.pad.processo.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Processos',
      template: require('./directory/directory.view.html'),
      controller: 'PadProcessoListController',
      controllerAs: 'vm',
      role: '$internal',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.pad.processo.concluidos', {
      url: '/concluidos?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Processos Concluídos',
      template: require('./concluidos/directory.view.html'),
      controller: 'PadProcessoConcluidosListController',
      controllerAs: 'vm',
      role: '$internal',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.pad.processo.emcurso', {
      url: '/emcurso?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Processos Em Curso',
      template: require('./emcurso/directory.view.html'),
      controller: 'PadProcessoEmCursoListController',
      controllerAs: 'vm',
      role: '$internal',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.pad.processo.details', {
      url: '/{id}',
      title: 'Processo',
      controller: 'PadProcessosDetailsController',
      controllerAs: 'vm',
      template: require('./details/details.view.html'),
      role: '$internal',
    })
    .state('app.pad.processo.linha', {
      url: '/{id}/{linha}',
      title: 'Linha de Processo',
      controller: 'PadProcessosDetailsLinhaController',
      controllerAs: 'vm',
      template: require('./linhas/details.view.html'),
      role: '$internal',
    })
    .state('app.pad.processo.new', {
      url: '/novo',
      title: 'Adicionar Processo',
      template: require('./new/new.view.html'),
      controller: 'PadProcessoCreateController',
      controllerAs: 'vm',
      role: '$internal',
    })
    .state('app.pad.processo.new.1', {
      template: require('./new/processo.1.form.html')
    })
    .state('app.pad.processo.new.2', {
      template: require('./new/processo.2.form.html')
    })
    .state('app.pad.processo.new.3', {
      template: require('./new/processo.3.form.html')
    })
  ;
}

routes.$inject = ['$stateProvider'];
