import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Switch, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
export const FieldsDialog = ({ open, columns, storageKey, onSubmit, close }) => {
    const [keys, setKeys] = useState({});
    const theme = useTheme();
    useEffect(() => {
        const storedKeys = JSON.parse(localStorage.getItem(storageKey) || '{}');
        setKeys(storedKeys);
    }, [storageKey]);
    const getKeyName = (key) => {
        return columns.find(r => r.accessorKey === key)?.header;
    };
    const handleKeyCheck = (event) => {
        const newKeys = { ...keys };
        newKeys[event.target.name] = event.target.checked;
        setKeys(newKeys);
    };
    return (<>
      {<ThemeProvider theme={theme}>
          <Dialog open={open} onClose={close} maxWidth='sm' fullWidth>
            <DialogTitle>Editar campos visíveis</DialogTitle>

            <DialogContent>
              <Alert severity='info'>Utilize os botões para alterar os campos a mostrar na tabela.</Alert>
              <Paper elevation={0} sx={{ mt: 2, background: theme.palette.mode === 'dark' ? undefined : theme.palette.action.selected }}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '90px' }}>Mostrar?</TableCell>
                      <TableCell align='left'>Campo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(keys).map(key => (<TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component='th' scope='row'>
                          <Switch name={key} edge='end' onChange={handleKeyCheck} checked={keys[key]}/>
                        </TableCell>
                        <TableCell align='left'>{getKeyName(key)}</TableCell>
                      </TableRow>))}
                  </TableBody>
                </Table>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                close();
            }}>
                Cancelar
              </Button>
              <Button onClick={() => {
                if (onSubmit) {
                    localStorage.setItem(storageKey, JSON.stringify(keys));
                    onSubmit(keys);
                }
                close();
            }} variant='outlined'>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>}
    </>);
};
