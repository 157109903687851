import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_ActionMenuItem } from "material-react-table";
import { Box, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import { Search } from "@mui/icons-material";
const GasProcessosListView = ({ $state, GasService, UIService }) => {
    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [tableError, setTableError] = useState({ show: false, title: "", body: "" });
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([{ id: 'numeroProcesso', desc: true }]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20
    });
    // Fetch data from API
    const fetchData = async () => {
        if (!data.length) {
            setIsLoading(true);
        }
        else {
            setIsRefetching(true);
        }
        let tableData;
        try {
            tableData = await findProcessos({
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                sorting: sorting,
                columnFilters: columnFilters,
                globalFilter: globalFilter || '',
            });
            setData(tableData.data);
            setRowCount(tableData.count);
        }
        catch (error) {
            console.log(error);
            setData([]);
            setIsLoading(false);
            setTableError({
                show: true,
                title: (error && error.response && error.response.data) ? error.response.data.error : "Erro",
                body: (error & error.response && error.response.data) ? error.response.data.message : "Ocorreu um erro na busca de dados. Verifique a ligação."
            });
            throw error;
        }
        setIsLoading(false);
        setIsRefetching(false);
    };
    useEffect(() => {
        fetchData();
    }, [columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);
    const findProcessos = async (params) => {
        let tableData;
        try {
            tableData = await GasService.findProcessos(params);
        }
        catch (error) {
            console.log(error);
            throw error;
        }
        return tableData;
    };
    const importProcessos = () => {
        UIService.showDialog({
            template: require('./import/dialog.html'),
            controller: 'GasImporterController',
            controllerAs: 'vm'
        }).then(r => {
            UIService.addToast("Ficheiro importado com sucesso");
            fetchData();
        }).catch(e => {
            if (e === 'error') {
                UIService.addToast("Não foi possível importar ficheiro");
                fetchData();
            }
            else {
                fetchData();
            }
        });
    };
    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 100,
        },
        {
            accessorKey: 'numeroProcesso',
            header: 'Nº Processo',
            size: 100,
        },
        {
            accessorKey: 'numeroParceiro',
            header: 'Nº Parceiro',
            size: 100,
        },
        {
            accessorKey: 'agendamento.data',
            header: 'Data de Inspeção',
            size: 150,
            Cell: ({ renderedCellValue, row }) => ((row.original.agendamento && row.original.agendamento.data && moment(row.original.agendamento.data).isValid()) ? moment(row.original.agendamento.data).format("DD/MM/YYYY") : 'N/D'),
        },
        {
            accessorKey: "agendamento.hora",
            header: "Hora",
            size: 50,
            Cell: ({ renderedCellValue, row }) => ((row.original.agendamento && row.original.agendamento.hora) ? row.original.agendamento.hora.substring(0, 5) : 'N/D'),
        },
        {
            accessorKey: "agendamento.duracao",
            header: "Duração (min)",
            size: 80,
        },
        {
            accessorKey: "agendamento.tecnico.name",
            header: "Técnico",
            size: 140,
        },
        {
            accessorKey: "estado.designacao",
            header: "Estado",
            size: 140,
        },
        {
            accessorKey: "cui",
            header: "CUI",
            size: 200,
        },
        {
            accessorKey: "tipoGas.designacao",
            header: "Tipo de Gás",
            size: 140,
        },
        {
            accessorKey: "tipoInstalacao.designacao",
            header: "Tipo de Instalação",
            size: 140,
        },
        {
            accessorKey: "tipoInspecao.designacao",
            header: "Tipo de Inspeção",
            size: 140,
        },
        {
            accessorKey: 'distribuidor.designacao',
            header: 'Distribuidor',
            size: 150,
        },
        {
            accessorKey: 'ord.designacao',
            header: 'ORD',
            size: 150,
        },
        {
            accessorKey: 'agendamentoNaHora',
            header: 'Agendamento na Hora',
            size: 75,
            Cell: ({ renderedCellValue, row }) => (row.original.agendamentoNaHora ? "Sim" : "Não"),
        },
        {
            accessorKey: 'dataImportacao',
            header: 'Data de Importação',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (moment(row.original.dataImportacao).isValid() ? moment(row.original.dataImportacao).format("DD/MM/YYYY") : 'N/D'),
        },
    ], []);
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = 'Não há registos a serem exibidos';
    return (<Box>
      <h1 className="section-title mt-2" id="services">
        <span>Processos</span>
      </h1>
      <ol className="breadcrumb text-left">
        <li>Gás</li>
        <li className="active">Processos
        </li>
      </ol>
      <MaterialReactTable 
    // table={table}
    columns={columns} data={data} initialState={{
            pagination: {
                pageIndex: 0,
                pageSize: 20
            },
            columnVisibility: {
                id: false,
                criadoPor: false,
                dataCriacao: false,
                ultimaAlteracao: false,
                observacoes: false
            }
        }} state={{
            density: 'compact',
            showAlertBanner: tableError.show,
            isLoading: isLoading,
            showProgressBars: isRefetching,
            columnFilters,
            globalFilter,
            pagination,
            sorting,
        }} muiPaginationProps={{
            rowsPerPageOptions: [20, 50, 100]
        }} muiCircularProgressProps={{
            color: 'secondary',
            thickness: 5,
            size: 55,
        }} muiToolbarAlertBannerProps={tableError.show ? {
            color: "error",
            children: (<div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {tableError.title}
                </Typography>
                <Typography>
                  {tableError.body}
                </Typography>
              </div>)
        } : undefined} muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
                $state.go('app.gas.processos.details', { id: row.original.id });
            },
            sx: {
                cursor: 'pointer'
            }
        })} localization={localization} enableFullScreenToggle={false} enableDensityToggle={false} rowCount={rowCount} manualPagination={true} manualFiltering={true} manualSorting={true} onColumnFiltersChange={setColumnFilters} onGlobalFilterChange={setGlobalFilter} onPaginationChange={setPagination} onSortingChange={setSorting} enableRowActions={true} positionActionsColumn={'last'} renderRowActionMenuItems={({ row, table }) => [
            <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
             icon={<Search />} key="search" label="Ver detalhes" onClick={() => $state.go('app.gas.processos.details', { id: row.original.id })} table={table}/>,
        ]}/>
      {/*FAB*/}
      <div className="bottom-right-corner">
        <Tooltip title={"Importar Processos"} placement={"top"}>
          <a className="btn btn-sm pmd-btn-fab pmd-btn-raised pmd-ripple-effect btn-danger" onClick={() => importProcessos()}>
            <i className="mdi mdi-upload"></i>
          </a>
        </Tooltip>
      </div>

    </Box>);
};
export default GasProcessosListView;
