export default class ProcessCreationController {
  constructor($scope, $dialog, $timeout, ElvOrdemintervencao, ElvEmie, ElvCondominio, ElvElevador, ElvEquipamentoTipo) {
    this.loaded = false;
    this.scope = $scope;
    this.dialog = $dialog;
    this.t = $timeout;
    this.defaults = {};
    // Setups
    this.ElvOrdemintervencao = ElvOrdemintervencao;
    this.ElvEmie = ElvEmie;
    this.ElvCondominio = ElvCondominio;
    this.ElvEquipamentoTipo = ElvEquipamentoTipo;
    this.ElvElevador = ElvElevador;
    this.loadData();
    // Defaults
    this.arrays = {
      emies: [],
      condominios: [],
      elevadores: [],
      equipamentos: []
    };

    this.data = {
      emie: undefined,
      dataProcesso: undefined,
      equipamento: undefined,
      condominio: undefined,
      nip: undefined,
      elevador: undefined,
      observacoes: undefined
    };
  }

  autofillElevadorFromnumeroCamarario = () => {
    if (this.data.elevador.numeroCamarario) {
      this.ElvElevador.find({
        filter: {
          where: {
            numeroCamarario: this.data.elevador.numeroCamarario,
            active: true
          },
          include: [{
            relation: 'equipamento',
            scope: {
              where: {
                active: 1
              }
            }
          },
            {
              relation: 'pedElev',
              scope: {
                where: {
                  active: 1
                }
              }
            }]
        }
      }).$promise.then(res => {
        if (res && res.length > 0) { // Found something
          this.data.equipamento = this.data.equipamento || {};
          this.data.equipamento = res[0].equipamento;
          this.data.elevador.morada = res[0].morada;
          this.data.elevador.cp4 = res[0].cp4;
          this.data.elevador.cp3 = res[0].cp3;
          this.data.elevador.localidade = res[0].localidade;
          this.data.elevador.observacoes = res[0].observacoes;
        } else { // Found Nothing. Empty prefill
          this.data.equipamento = this.data.equipamento || {};
          this.data.elevador.morada = null;
          this.data.elevador.cp4 = null;
          this.data.elevador.cp3 = null;
          this.data.elevador.localidade = null;
          this.data.elevador.observacoes = null;
        }
      }).catch((err) => { // Error occured. Empty prefill
        console.log(err);
        this.data.equipamento = null;
        this.data.elevador.morada = null;
        this.data.elevador.cp4 = null;
        this.data.elevador.cp3 = null;
        this.data.elevador.localidade = null;
        this.data.elevador.observacoes = null;
      });
    } else {
      this.data.equipamento = null;
      this.data.elevador.morada = null;
      this.data.elevador.cp4 = null;
      this.data.elevador.cp3 = null;
      this.data.elevador.localidade = null;
      this.data.elevador.observacoes = null;
    }
  };

  loadData = () => {
    this.loaded = false;
    this.ElvEmie.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(emies => {
      this.defaults.emies = emies;
      this.arrays.emies = emies;
      this.ElvCondominio.find({
        filter: {
          where: {
            active: true
          }
        }
      }).$promise.then(condominios => {
        this.defaults.condominios = condominios;
        this.arrays.condominios = condominios;
        this.ElvElevador.find({
          filter: {
            where: {
              active: true
            }
          }
        }).$promise.then(elevadores => {
          this.defaults.elevadores = elevadores;
          this.arrays.elevadores = elevadores;

          this.ElvEquipamentoTipo.find({
            filter: {
              where: {
                active: true
              }
            }
          }).$promise.then(equipamentos => {
            this.defaults.equipamentos = equipamentos;
            this.arrays.equipamentos = equipamentos;

            this.loaded = true;
          });
        });
      })
    });
  };

  ok = () => {
    if (this.loaded) {
      this.dialog.close(this.data);
    } else
      this.dialog.dismiss('cancel');
  };

  cancel = () => {
    this.dialog.dismiss('cancel');
  };
}

ProcessCreationController.$inject = ['$scope', '$dialog', '$timeout', 'ElvOrdemintervencao', 'ElvEmie', 'ElvCondominio', 'ElvElevador', 'ElvEquipamentoTipo'];
