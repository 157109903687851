export function routes($stateProvider) {
  $stateProvider
    .state('app.gas.ords', {
      url: '/ords',
      abstract: true
    })
    .state('app.gas.ords.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'ORDs',
      template: '<gas-ords-list-view></gas-ords-list-view>',
      // role: ['gasVerOrds'],
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.gas.ords.details', {
      url: '/{id:int}',
      title: 'Detalhes do ORD',
      template: '<gas-ord-details-view></gas-ord-details-view>',
      // role: ['gasVerOrds'],
    });
}

routes.$inject = ['$stateProvider'];
