import React, { useEffect, useState } from 'react';
import { Avatar, Box, Breadcrumbs, Fab, IconButton, Link, MenuItem, Typography } from '@mui/material';
import { MdiIcon, Table } from '@components';
import { mdiPlus } from '@mdi/js';
import moment from 'moment';
const CrmPessoasListView = ({ $state, CrmService }) => {
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const [data, setData] = useState(); // dados dos Contactos
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState([]); // filtros a aplicar na tabela
    // exemplo -> [{ id: 'nome', value: 'Pedro', type: 'contains' }, { id: 'email', value: 'gmail', type: 'contains' }]
    const [sort, setSort] = useState(() => loadParams()); // ordenação da tabela
    // exemplo -> [{id: 'ultimoContacto', desc: false}]
    const [options, setOptions] = useState({
        filter: [],
        sort: [] // exemplo -> ['id', 'nome', 'ultimoContacto', 'contactoTelefonico', 'nif', 'email', 'morada', 'localidade']
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    });
    function stringToColor(string) {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        return color;
    }
    function stringAvatar(name) {
        // Verifica se o nome é válido e tem pelo menos uma palavra
        const nameParts = name.split(' ');
        const firstInitial = nameParts[0] ? nameParts[0][0] : '';
        const secondInitial = nameParts[1] ? nameParts[1][0] : '';
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${firstInitial}${secondInitial}`,
        };
    }
    // Filtragem dentro de cada coluna
    // Modo de filtro (igual a, contém, começa com... )
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    // ir para detalhes da pessoa (envia id da empresa)
    const onCellView = (cell) => {
        $state.go('app.crm.pessoas.details', { id: cell.id });
    };
    const initialFetch = async () => {
        if (loading) {
            // opt -> vai buscar os campos filtraveis
            const opt = await CrmService.optionsContactos();
            setOptions(opt);
            setLoading(false);
        }
    };
    const fetchData = async () => {
        setError(false);
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            const aux = await CrmService.findAllContactos({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            // Update URL
            setData(aux);
        }
        catch (e) {
            console.log(e);
            setError(true);
        }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, filter, sort]);
    return (<Box sx={{ mt: 2, pr: 1, pl: 1 }}>
      <Breadcrumbs aria-label='breadcrumb'>
        <Link underline='hover' color='inherit' href='/'>
          <Typography variant='body2'>CRM</Typography>
        </Link>
        <Link underline='hover' color='inherit' href='/material-ui/getting-started/installation/'>
          <Typography variant='body2'>Pessoas</Typography>
        </Link>
      </Breadcrumbs>

      <Typography variant='h4' sx={{ color: 'black', mt: 2 }}>
        Pessoas
      </Typography>
      <Box sx={{
            mt: 2,
            borderRadius: '15px',
            border: '1px solid #e0e0e0',
            overflow: 'hidden',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' // Adiciona uma leve sombra
        }}>
        <Table onDoubleClick={row => {
            onCellView(row);
        }} storageKey={$state.current.name} data={data} columns={[
            /* {
              accessorKey: 'id',
              header: 'ID',
              size: 20
            }, */
            {
                accessorKey: 'avatar',
                header: '',
                size: 30,
                enableSorting: false,
                enableColumnActions: false,
                enableColumnDragging: false,
                Cell: ({ row }) => (<Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%' // Garante que ocupe toda a altura da célula
                    }}>
                  <Avatar alt={row.original.nome} {...stringAvatar(row.original.nome)}/>
                </Box>)
            },
            {
                accessorKey: 'nome',
                header: 'Nome',
                size: 200
            },
            {
                accessorKey: 'dataUltimoContacto',
                header: 'Último contacto',
                size: 120,
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    if (value != undefined)
                        return moment(value).format('DD-MM-YYYY');
                    return '';
                }
            },
            {
                accessorKey: 'contactoTelefonico',
                header: 'Contacto',
                size: 100
            },
            {
                accessorKey: 'email',
                header: 'Email',
                size: 150
            },
            {
                accessorKey: 'cargo',
                header: 'Cargo',
                size: 150
            },
            {
                accessorKey: 'refClientePrimavera',
                header: 'Empresa',
                size: 150
            }
        ]} initial={{
            sort: sort,
            filter: filter
        }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setFilter} onSort={setSort} cellActionItems={({ row, table }) => {
            return [
                <MenuItem key={`row.view.${row.id}`} onClick={() => /* onCellView(row.original) */ console.log(row.original)}>
                Visualizar
              </MenuItem>
            ];
        }}></Table>
      </Box>

      <Fab aria-label='add' color='error' sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000
        }}>
        <IconButton onClick={() => $state.go('app.crm.pessoas.formCreate')}>
          <MdiIcon path={mdiPlus} sx={{ color: '#ffffff' }}/>
        </IconButton>
      </Fab>
    </Box>);
};
export default CrmPessoasListView;
