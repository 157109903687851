import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/system/Unstable_Grid';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, InputAdornment, MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
const showDate = (date, format = 'DD/MM/YYYY') => {
    if (moment(date).isValid()) {
        return moment(date).format(format);
    }
    else
        return '-';
};
const GasProcessoNewView = ({ $state, GasService, UIService }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    // Data for selects
    const [ords, setOrds] = useState([]);
    const [distribuidores, setDistribuidores] = useState([]);
    const [tiposGas, setTiposGas] = useState([]);
    const [tiposInstalacao, setTiposInstalacao] = useState([]);
    const [tiposInspecao, setTiposInspecao] = useState([]);
    const [tecnicos, setTecnicos] = useState([]);
    // Loading NIF
    const [nifLoading, setNifLoading] = useState(false);
    const fetchData = async () => {
        setIsLoading(true);
        // Fetch data for selects
        // ORDs
        let fetchedOrds;
        try {
            fetchedOrds = await GasService.getOrds();
        }
        catch (error) {
            console.log(error);
            UIService.addToast('Erro na procura de ORDs');
        }
        // Distribuidores
        let fetchedDistribuidores;
        try {
            fetchedDistribuidores = await GasService.getDistribuidores();
        }
        catch (error) {
            console.log(error);
            UIService.addToast('Erro na procura de Distribuidores');
        }
        // Tipos de Gás
        let fetchedTiposGas;
        try {
            fetchedTiposGas = await GasService.getTiposGas();
        }
        catch (error) {
            console.log(error);
            UIService.addToast('Erro na procura de Tipos de Gás');
        }
        // Tipos de Instalação
        let fetchedTiposInstalacao;
        try {
            fetchedTiposInstalacao = await GasService.getTiposInstalacao();
        }
        catch (error) {
            console.log(error);
            UIService.addToast('Erro na procura de Tipos de Instalação');
        }
        // Tipos de Inspeção
        let fetchedTiposInspecao;
        try {
            fetchedTiposInspecao = await GasService.getTiposInspecao();
        }
        catch (error) {
            console.log(error);
            UIService.addToast('Erro na procura de Tipos de Inspeção');
        }
        // Técnicos
        let fetchedTecnicos;
        try {
            fetchedTecnicos = await GasService.getTecnicos();
        }
        catch (error) {
            console.log(error);
        }
        setOrds(fetchedOrds);
        setDistribuidores(fetchedDistribuidores);
        setTiposGas(fetchedTiposGas);
        setTiposInstalacao(fetchedTiposInstalacao);
        setTiposInspecao(fetchedTiposInspecao);
        setTecnicos(fetchedTecnicos);
        if (fetchedOrds && fetchedOrds.length > 0 &&
            fetchedDistribuidores && fetchedDistribuidores.length > 0 &&
            fetchedTiposGas && fetchedTiposGas.length > 0 &&
            fetchedTiposInstalacao && fetchedTiposInstalacao.length > 0 &&
            fetchedTiposInspecao && fetchedTiposInspecao.length > 0 &&
            fetchedTecnicos && fetchedTecnicos.length > 0) {
            setIsLoading(false);
        }
        else {
            // No point in continue, reroute to lista de processos
            UIService.addToast('De momento não é possível criar novo processo manualmente');
            $state.go('app.gas.processos.list');
        }
    };
    const validationSchema = yup.object({
        dataAgendamento: yup.date().required('Data/Hora é obrigatória').typeError('Data inválida'),
        agendamento: yup.object({
            duracao: yup.number().integer('Duração tem que ser um número inteiro').positive('Duração não pode ser negativa').required('Duração é um campo obrigatório'),
            tecnicoId: yup.number().oneOf(tecnicos.map(x => x.funcionario?.id), 'Preencha um técnico válido').required('Técnico é obrigatório'),
        }),
        processo: yup.object({
            tipoGasId: yup.number().oneOf(ords.map(x => x.id), 'Tipo de Gás inválido').required('Tipo de Gás é obrigatório'),
            tipoInstalacaoId: yup.number().oneOf(tiposInstalacao.map(x => x.id), 'Tipo de Instalação inválido').required('Tipo de instalação é obrigatório'),
            tipoInspecaoId: yup.number().oneOf(tiposInspecao.map(x => x.id), 'Tipo de Inspeção inválido').required('Tipo de inspeção é obrigatório'),
            distribuidorId: yup.number().oneOf(distribuidores.map(x => x.id), 'Distribuidor inválido').required('Distribuidor é obrigatório'),
            ordId: yup.number().oneOf(ords.map(x => x.id), 'ORD inválido').required('ORD é obrigatório'),
            total: yup.number().typeError('Total tem de ser um número').test('is-decimal', 'Total tem de ter no máximo duas casas decimais', (value) => Number.isInteger(value * 100)).required("Total é obrigatório"),
            cui: yup.string().matches(/^[A-Za-z]{2}\d{16}[A-Za-z]{2}$/, 'CUI Inválido').required('CUI é obrigatório'),
            nomeCliente: yup.string().required('Nome é obrigatório'),
            nifCliente: yup.string().required('NIF é obrigatório'),
            emailCliente: yup.string().email('Email inválido').notRequired(),
            moradaCliente: yup.string().required('Morada é obrigatória'),
            localidadeCliente: yup.string().required('Localidade é obrigatória'),
            cep: yup.string().matches(/^\d{4}-\d{3}$/, 'Código Postal deve estar no formato XXXX-YYY').notRequired(),
            concelhoCliente: yup.string().required('Concelho é obrigatório'),
            distritoCliente: yup.string().required('Distrito é obrigatório'),
        })
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        validateOnChange: true,
        onSubmit: async (values) => {
            // Put values back to agendamento correctly
            values.agendamento.data = values.dataAgendamento.format("YYYY-MM-DD");
            values.agendamento.hora = values.dataAgendamento.format("HH:mm:00");
            // Value used to simplify date calculations
            let wait = UIService.showWaiting();
            GasService.createProcesso(values).then((result) => {
                wait.close();
                if (result.result !== 1) {
                    let alert = UIService.showAlert(GasService.explainStat(result.result, result.extra));
                    alert.finally(() => {
                        $state.go('app.gas.processos.list');
                    });
                }
                else {
                    UIService.addToast("Processo criado com sucesso");
                    $state.go('app.gas.processos.list');
                }
            }).catch(error => {
                console.log(error);
                wait.close();
            });
        }
    });
    useEffect(() => {
        if (!isLoading)
            fetchData();
    }, []);
    const handleNifChange = async (event) => {
        let cliente;
        // Only check if NIF has at least 9 chars defined
        if (event.target.value.length >= 9) {
            setNifLoading(true);
            try {
                cliente = await GasService.getGasClienteFinalForNif(event.target.value);
            }
            catch (error) {
                console.log(error);
            }
            if (cliente && cliente.nif) {
                formik.handleChange(event);
                await formik.setFieldValue('processo.emailCliente', cliente.email, true);
                await formik.setFieldValue('processo.nomeCliente', cliente.nome, true);
                await formik.setFieldValue('processo.moradaCliente', cliente.morada, true);
                await formik.setFieldValue('processo.cep', cliente.cep, true);
                await formik.setFieldValue('processo.localidadeCliente', cliente.localidade, true);
                await formik.setFieldValue('processo.concelhoCliente', cliente.concelho, true);
                await formik.setFieldValue('processo.distritoCliente', cliente.distrito, true);
                await formik.setFieldTouched('processo.emailCliente', true);
                await formik.setFieldTouched('processo.nomeCliente', true);
                await formik.setFieldTouched('processo.moradaCliente', true);
                await formik.setFieldTouched('processo.cep', true);
                await formik.setFieldTouched('processo.localidadeCliente', true);
                await formik.setFieldTouched('processo.concelhoCliente', true);
                await formik.setFieldTouched('processo.distritoCliente', true);
            }
            else { // Just handle the change without anything else
                formik.handleChange(event);
            }
            setNifLoading(false);
        }
        else { // Just handle the change without anything else
            formik.handleChange(event);
        }
    };
    return (<Box>
      <h1 className='section-title mt-2' id='services'>
        <span>Novo Processo</span>
      </h1>
      <ol className='breadcrumb text-left'>
        <li>Gás</li>
        <li className='active'>Novo Processo</li>
      </ol>
      {isLoading ? (<Box sx={{ display: 'flex', justifyContent: 'center', height: '2vh' }}>
          <CircularProgress size={60} color='primary'/>
        </Box>) : (<form>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={4} md={4} xs={12}>
              <TextField label='Tipo de Gás' name='processo.tipoGasId' value={formik.values?.processo?.tipoGasId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.tipoGasId && Boolean(formik.errors.processo?.tipoGasId)} helperText={formik.touched.processo?.tipoGasId && formik.errors.processo?.tipoGasId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {tiposGas &&
                tiposGas.map(x => (<MenuItem value={x.id} key={x.id}>
                      {x.designacao}
                    </MenuItem>))}
              </TextField>
            </Grid>
            <Grid lg={4} md={4} xs={12}>
              <TextField label='Tipo de Instalação' name='processo.tipoInstalacaoId' value={formik.values?.processo?.tipoInstalacaoId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.tipoInstalacaoId && Boolean(formik.errors.processo?.tipoInstalacaoId)} helperText={formik.touched.processo?.tipoInstalacaoId && formik.errors.processo?.tipoInstalacaoId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {tiposInstalacao &&
                tiposInstalacao.map(x => (<MenuItem value={x.id} key={x.id}>
                      {x.designacao}
                    </MenuItem>))}
              </TextField>
            </Grid>
            <Grid lg={4} md={4} xs={12}>
              <TextField label='Tipo de Inspeção' name='processo.tipoInspecaoId' value={formik.values?.processo?.tipoInspecaoId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.tipoInspecaoId && Boolean(formik.errors.processo?.tipoInspecaoId)} helperText={formik.touched.processo?.tipoInspecaoId && formik.errors.processo?.tipoInspecaoId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {tiposInspecao &&
                tiposInspecao.map(x => (<MenuItem value={x.id} key={x.id}>
                      {x.designacao}
                    </MenuItem>))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={3} md={4} xs={12}>
              <TextField label='CUI' name='processo.cui' defaultValue={formik.values?.processo?.cui} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.cui && Boolean(formik.errors.processo?.cui)} helperText={formik.touched.processo?.cui && formik.errors.processo?.cui}/>
            </Grid>
            <Grid lg={3} md={4} xs={12}>
              <TextField label='ORD' name='processo.ordId' value={formik.values?.processo?.ordId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.ordId && Boolean(formik.errors.processo?.ordId)} helperText={formik.touched.processo?.ordId && formik.errors.processo?.ordId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {ords &&
                ords.map(x => (<MenuItem value={x.id} key={x.id}>
                      {x.designacao}
                    </MenuItem>))}
              </TextField>
            </Grid>
            <Grid lg={3} md={4} xs={12}>
              <TextField label='Distribuidor' name='processo.distribuidorId' value={formik.values?.processo?.distribuidorId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.distribuidorId && Boolean(formik.errors.processo?.distribuidorId)} helperText={formik.touched.processo?.distribuidorId && formik.errors.processo?.distribuidorId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {distribuidores &&
                distribuidores.map(x => (<MenuItem value={x.id} key={x.id}>
                      {x.designacao}
                    </MenuItem>))}
              </TextField>
            </Grid>
            <Grid display="flex" justifyContent="center" alignItems="center" lg={3} md={4} xs={12}>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={Boolean(formik.values?.processo?.distribuidorPresente)} onChange={event => {
                    formik.setFieldValue('processo.distribuidorPresente', Boolean(event.target.checked), true);
                }} name="checked" color="primary"/>} label="Distribuidor Presente?"/>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={3} md={3} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker label='Data/Hora' format='DD/MM/YYYY HH:mm' value={formik.values?.dataAgendamento} onChange={value => {
                // Fix helperText not showing cause component was not marked as touched!
                formik.setFieldValue('dataAgendamento', value, true);
            }} slotProps={{
                textField: {
                    variant: 'outlined',
                    id: 'dataAgendamento',
                    name: 'dataAgendamento',
                    onBlur: formik.handleBlur,
                    error: formik.touched.dataAgendamento && Boolean(formik.errors.dataAgendamento),
                    fullWidth: true,
                    helperText: formik.touched.dataAgendamento && Boolean(formik.errors.dataAgendamento) && String(formik.errors.dataAgendamento) // Use boolean cause string([]) = "undefined"
                }
            }}/>
              </LocalizationProvider>
            </Grid>
            <Grid lg={2} md={2} xs={12}>
              <TextField label='Duração' name='agendamento.duracao' type='number' defaultValue={formik.values?.agendamento?.duracao} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.agendamento?.duracao && Boolean(formik.errors.agendamento?.duracao)} helperText={formik.touched.agendamento?.duracao && formik.errors.agendamento?.duracao} InputProps={{ endAdornment: <InputAdornment position='end'>min</InputAdornment> }}/>
            </Grid>
            <Grid lg={2} md={2} xs={12}>
              <TextField label='Total' name='processo.total' type='number' defaultValue={formik.values?.processo?.total} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.total && Boolean(formik.errors.processo?.total)} helperText={formik.touched.processo?.total && formik.errors.processo?.total} InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}/>
            </Grid>
            <Grid lg={5} md={5} xs={12}>
              <TextField label='Técnico' name='agendamento.tecnicoId' value={formik.values?.agendamento?.tecnicoId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.agendamento?.tecnicoId && Boolean(formik.errors.agendamento?.tecnicoId)} helperText={formik.touched.agendamento?.tecnicoId && formik.errors.agendamento?.tecnicoId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {tecnicos &&
                tecnicos.map(x => (<MenuItem value={x.funcionario.id} key={x.funcionario.id}>
                      {x.funcionario.name}
                    </MenuItem>))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Observações do Processo' name='processo.observacoes' defaultValue={formik.values?.processo?.observacoes} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.observacoes && Boolean(formik.errors.processo?.observacoes)} helperText={formik.touched.processo?.observacoes && formik.errors.processo?.observacoes}/>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid lg={4} md={4} xs={12}>
                <TextField label='NIF do Cliente' name='processo.nifCliente' defaultValue={formik.values?.processo?.nifCliente} 
        // onChange={formik.handleChange}
        onChange={handleNifChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.nifCliente && Boolean(formik.errors.processo?.nifCliente)} helperText={formik.touched.processo?.nifCliente && formik.errors.processo?.nifCliente}/>
              </Grid>
            <Grid lg={8} md={8} xs={12}>
              <TextField label='Email do Cliente' name='processo.emailCliente' value={formik.values?.processo?.emailCliente ?? ""} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.emailCliente && Boolean(formik.errors.processo?.emailCliente)} helperText={formik.touched.processo?.emailCliente && formik.errors.processo?.emailCliente} disabled={nifLoading} InputProps={{
                endAdornment: <React.Fragment>{nifLoading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Nome do Cliente' name='processo.nomeCliente' value={formik.values?.processo?.nomeCliente ?? ""} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.nomeCliente && Boolean(formik.errors.processo?.nomeCliente)} helperText={formik.touched.processo?.nomeCliente && formik.errors.processo?.nomeCliente} disabled={nifLoading} InputProps={{
                endAdornment: <React.Fragment>{nifLoading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Morada' name='processo.moradaCliente' value={formik.values?.processo?.moradaCliente ?? ""} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.moradaCliente && Boolean(formik.errors.processo?.moradaCliente)} helperText={formik.touched.processo?.moradaCliente && formik.errors.processo?.moradaCliente} disabled={nifLoading} InputProps={{
                endAdornment: <React.Fragment>{nifLoading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={2} md={2} xs={12}>
              <TextField label='Código Postal' name='processo.cep' value={formik.values?.processo?.cep ?? ""} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.cep && Boolean(formik.errors.processo?.cep)} helperText={formik.touched.processo?.cep && formik.errors.processo?.cep} disabled={nifLoading} InputProps={{
                endAdornment: <React.Fragment>{nifLoading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
            </Grid>
            <Grid lg={4} md={4} xs={12}>
              <TextField label='Localidade' name='processo.localidadeCliente' value={formik.values?.processo?.localidadeCliente ?? ""} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.localidadeCliente && Boolean(formik.errors.processo?.localidadeCliente)} helperText={formik.touched.processo?.localidadeCliente && formik.errors.processo?.localidadeCliente} disabled={nifLoading} InputProps={{
                endAdornment: <React.Fragment>{nifLoading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
            </Grid>
            <Grid lg={3} md={3} xs={12}>
              <TextField label='Concelho' name='processo.concelhoCliente' value={formik.values?.processo?.concelhoCliente ?? ""} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.concelhoCliente && Boolean(formik.errors.processo?.concelhoCliente)} helperText={formik.touched.processo?.concelhoCliente && formik.errors.processo?.concelhoCliente} disabled={nifLoading} InputProps={{
                endAdornment: <React.Fragment>{nifLoading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
            </Grid>
            <Grid lg={3} md={3} xs={12}>
              <TextField label='Distrito' name='processo.distritoCliente' value={formik.values?.processo?.distritoCliente ?? ""} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.distritoCliente && Boolean(formik.errors.processo?.distritoCliente)} helperText={formik.touched.processo?.distritoCliente && formik.errors.processo?.distritoCliente} disabled={nifLoading} InputProps={{
                endAdornment: <React.Fragment>{nifLoading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
            </Grid>
          </Grid>

          <Box position='fixed' bottom='20px' right='20px' zIndex='1000'>
            <Button onClick={() => {
                formik.handleSubmit();
            }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
              Criar Processo
            </Button>
          </Box>
        </form>)}
    </Box>);
};
export default GasProcessoNewView;
