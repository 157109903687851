import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, ListItem, ListItemText, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { MdiIcon } from '@components';
import { mdiChevronRight, mdiChevronUp } from '@mdi/js';
let params = [];
const DialogPonto = ({ initial, defeitos, onClose, ...props }) => {
    const [list, setList] = useState([]);
    const [preview, setPreview] = useState(false);
    const validationSchema = yup.object({
        codigo: yup.string().required('O campo é obrigatório'),
        designacao: yup.string().required('O campo é obrigatório'),
        na: yup.boolean().nullable(),
        defeitos: yup.array(yup.number())
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            let a = {
                designacao: values.designacao,
                defeitos: JSON.stringify(values.defeitos),
                codigo: values.codigo,
                na: values.na
            };
            onClose(a);
        }
    });
    useEffect(() => {
        formik.resetForm();
        formik.setValues({ codigo: initial.codigo, designacao: initial.designacao, na: initial.na || 0, defeitos: [] });
        if (initial) {
            if (initial.defeitos) {
                let a = JSON.parse(initial.defeitos);
                params = [...defeitos.filter(r => a.includes(r.id))];
                formik.setFieldValue('defeitos', a);
            }
        }
        setList(defeitos);
    }, [initial]);
    return (<form>
      <Dialog {...props} maxWidth={'lg'} fullWidth>
        <DialogTitle>Adicionar ponto de verificação</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={3} md={3} xs={6}>
              <TextField label='Código' name='codigo' fullWidth value={formik.values?.codigo} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.codigo && Boolean(formik.errors.codigo)} helperText={formik.touched.codigo && formik.errors.codigo}/>
            </Grid>

            <Grid lg={3} md={3} xs={6}>
              <TextField label='Adicionar opção N/A?' name='na' fullWidth value={formik.values?.na} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.na && Boolean(formik.errors.na)} helperText={formik.touched.na && formik.errors.na} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {[
            { id: 0, label: 'Não' },
            { id: 1, label: 'Sim' }
        ].map(x => (<MenuItem value={x.id} key={x.id}>
                    {x.label}
                  </MenuItem>))}
              </TextField>
            </Grid>
            <Grid lg={6} md={6} xs={12}>
              <Autocomplete id='defeitos' multiple limitTags={3} defaultValue={params} disabled={list.length == 0} options={list} getOptionLabel={(list) => `${list.designacao}`} getOptionDisabled={option => formik.values.defeitos.includes(option.id)} onChange={(_, value) => {
            formik.setFieldValue('defeitos', value.map(r => r.id));
        }} onOpen={formik.handleBlur} renderOption={(props, option) => (<ListItem {...props} key={option.id}>
                    <ListItemText primary={option.designacao}></ListItemText>
                  </ListItem>)} renderTags={(list, props) => list.map((option, index) => {
            return <Chip {...props({ index })} key={`${index}`} label={option.designacao}/>;
        })} renderInput={params => (<TextField {...params} fullWidth onBlur={formik.handleBlur} error={formik.touched.defeitos && Boolean(formik.errors.defeitos)} helperText={formik.touched.defeitos && formik.errors.defeitos} label='Selecionar tipo de defeito' name='defeitos'/>)}/>
              <FormHelperText>Preencha o campo apenas se for desejar filtrar os defeitos por tipo em caso de não conformidade</FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Designação' name='designacao' value={formik.values?.designacao} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
              <FormHelperText>
                <Box>
                  Para criar campos de inserção, utilize um dos formatos abaixo, será convertido no formulário web/app para o tipo selecionado:
                </Box>
                <Box>
                  <Chip size='small' color='primary' variant='outlined' label={`[Texto]`}/> - Para inserção de texto livre
                </Box>
                <Box>
                  <Chip size='small' color='primary' variant='outlined' label={`[Numero]`}/> - Para inserção numérica
                </Box>
                <Box>
                  <Chip size='small' color='primary' variant='outlined' label={`[Imagem]`}/> - Para seleção de imagem
                </Box>
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12}>
              <Stack direction='row' spacing={2} alignItems={'center'}>
                <Box>{!preview ? 'Mostrar' : 'Ocultar'} pré-visualização</Box>
                <IconButton onClick={() => {
            setPreview(!preview);
        }}>
                  <MdiIcon path={preview ? mdiChevronUp : mdiChevronRight}/>
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        {preview && (<DialogContent sx={{
                backgroundColor: '#e5e5e5'
            }}>
            <ListItem>
              {formik.values.codigo}&nbsp;-&nbsp;
              {formik.values.designacao.split(/\s+/).map((r, index) => {
                /// REGEX -> verificar de r tem [___Texto]
                if (/Texto\]/.test(r)) {
                    return <TextField sx={{ mr: 0.5 }} variant='outlined' size='small'/>;
                }
                if (/Numero\]/.test(r)) {
                    return <TextField sx={{ mr: 0.5 }} variant='outlined' type='number' size='small'/>;
                }
                /// REGEX -> verificar de r tem [___Imagem]
                if (/Imagem\]/.test(r)) {
                    return (<Button variant='outlined' size='small'>
                      Imagem
                    </Button>);
                }
                return <Typography>{r}&nbsp;</Typography>;
            })}
            </ListItem>
          </DialogContent>)}
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogPonto;
