import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Collapse, Checkbox } from '@mui/material';
export default function ResponsiveStepper({ pessoasAssociadas, handleRemove, removePessoas, $state, checkedList }) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(3); // Default to 3 items per page.
    // Dynamically calculate the number of items per page based on screen width.
    useEffect(() => {
        const calculateItemsPerPage = () => {
            const width = window.innerWidth;
            if (width > 1300)
                return 5; // Large screens: 5 items per page.
            if (width > 1000)
                return 4; // Medium screens: 4 items per page.
            if (width > 700)
                return 3; // Medium screens: 3 items per page.
            if (width > 400)
                return 2; // Medium screens: 3 items per page.
            return 1; // Small screens: 1 item per page.
        };
        setItemsPerPage(calculateItemsPerPage());
        window.addEventListener('resize', () => setItemsPerPage(calculateItemsPerPage()));
        return () => {
            window.removeEventListener('resize', () => setItemsPerPage(calculateItemsPerPage()));
        };
    }, []);
    const totalSteps = Math.ceil(pessoasAssociadas.length / itemsPerPage);
    const handleNext = () => {
        if (activeStep < totalSteps - 1) {
            setActiveStep(activeStep + 1);
        }
    };
    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };
    // Calculate the visible items for the current step.
    const startIndex = activeStep * itemsPerPage;
    const visibleItems = pessoasAssociadas.slice(startIndex, startIndex + itemsPerPage);
    return (<Box sx={{ maxWidth: 800, margin: '0 auto', textAlign: 'center' }}>
      {/* Visible items */}
      <Box sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            padding: 2
        }}>
        {visibleItems.map(pessoa => (<Box key={pessoa.id} sx={{
                flex: '0 0 250px',
                padding: '16px',
                textAlign: 'center'
            }}>
            {removePessoas && (<Collapse in={removePessoas}>
                <Checkbox checked={checkedList.includes(pessoa.id)} onChange={() => {
                    handleRemove(pessoa.id);
                }}/>
              </Collapse>)}
            <Typography variant='h6' sx={{ mb: 1 }}>
              {pessoa.nomeCoreContactoAssociado}
            </Typography>
            <Typography variant='body2' sx={{ mb: 2, height: '20%' }}>
              {pessoa.contactoAssociado?.cliente?.nome || 'Sem empresa associada'}
            </Typography>
            <Box component='img' onClick={() => {
                $state.go('app.crm.pessoas.details', { id: pessoa.idCoreContactoAssociado });
            }} src={pessoa.contactoAssociado?.userImage ? `/api/Upload/crm/download/${pessoa.contactoAssociado.userImage}` : 'assets/images/crm_user.png'} alt='Imagem da pessoa' sx={{
                width: 80,
                height: 80,
                borderRadius: '50%',
                objectFit: 'cover',
            }}/>
          </Box>))}
      </Box>

      {/* Stepper */}
      <MobileStepper variant='dots' steps={totalSteps} position='static' activeStep={activeStep} nextButton={<Button size='small' onClick={handleNext} disabled={activeStep === totalSteps - 1}>
            Próximo
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>} backButton={<Button size='small' onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Anterior
          </Button>}/>
    </Box>);
}
