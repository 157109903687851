export default class FormacaoEmpresaDetailsController {
  constructor($state, UIService, FormacaoEmpresa, FormacaoFormandoEmpresa, FormacaoEdicao) {
    this.$state = $state;
    this.id = $state.params.id;
    this.UI = UIService;
    this.FormacaoEmpresa = FormacaoEmpresa;
    this.FormacaoEdicao = FormacaoEdicao;
    this.FormacaoFormandoEmpresa = FormacaoFormandoEmpresa;
    this.loaded = false;
    this.loadData();
  }

  removeEmpr = p => {
    this.UI.showConfirm('Deseja eliminar registo?').then(ok => {
      if (ok) {
        this.FormacaoFormandoEmpresa.delete(p).$promise.then(r => {
          this.UI.addToast('Registo eliminado com sucesso');
          this.loadData();
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao eliminar registo!');
        })
      }
    });
  };

  editObservations = () => {
    let a = angular.copy(this.empresa);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = a.observacoes;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.empresa.observacoes = res;
      this.empresa.$save().then(() => {
        this.UI.addToast('Dados alterados com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro ao editar dados');
        this.loadData();
      })
    })
  };

  editDetails = () => {
    let a = angular.copy(this.empresa);
    let edit = this.UI.showDialog({
      size: 'lg',
      template: require('./edit.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.empresa = {
          designacao: a.designacao,
          responsavel: a.responsavel,
          email: a.email,
          telefone: a.telefone,
          morada: a.morada,
          cp4: a.cp4,
          cp3: a.cp3,
          localidade: a.localidade
        };

        $scope.ok = () => {
          $scope.$close($scope.empresa);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    });

    edit.then(res => {
      this.empresa.designacao = res.designacao;
      this.empresa.responsavel = res.responsavel;
      this.empresa.email = res.email;
      this.empresa.telefone = res.telefone;
      this.empresa.morada = res.morada;
      this.empresa.cp4 = res.cp4;
      this.empresa.cp3 = res.cp3;
      this.empresa.localidade = res.localidade;
      this.empresa.$save().then(() => {
        this.UI.addToast('Dados alterados com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro ao editar empresa');
        this.loadData();
      })
    })
  };

  assignFormandos = () => {
    let a = angular.copy(this.empresa.pessoas);
    let nin = [];
    a.forEach(f => {
      nin.push(f.formandoId);
    });
    let dialog = this.UI.showDialog({
      size: 'lg',
      controller: ['$scope', 'FormacaoFormando', ($scope, FormacaoFormando) => {

        $scope.data = {
          selected: []
        };

        FormacaoFormando.find({
          filter: {
            where: {
              id: {
                nin: nin
              },
              active: true
            }
          }
        }).$promise.then(res => {
          $scope.hasLoaded = true;
          $scope.formandos = res;
        });
        $scope.cancel = () => $scope.$dismiss('cancel');

        $scope.ok = () => {
          $scope.$close($scope.data);
        };

        //Infinite Scroll Magic
        $scope.infiniteScroll = {};
        $scope.infiniteScroll.numToAdd = 20;
        $scope.infiniteScroll.currentItems = 20;

        $scope.addMoreItems = function () {
          $scope.infiniteScroll.currentItems += $scope.infiniteScroll.numToAdd;
        };
      }],
      template: require('./formando.dialog.html')
    });

    dialog.then(res => {
      if (res.selected.length > 0) {
        res.selected.forEach(s => {
          let data = {
            id: 0,
            formandoId: s.id,
            empresaId: this.id,
            active: true,
          };
          this.FormacaoFormandoEmpresa.create(data);
        });
        this.loadData();
        this.UI.addToast('Formandos inscritos com sucesso');
      }
    });
  };

  loadData = () => {
    this.loaded = false;
    this.FormacaoEmpresa.findOne({
      filter: {
        where: {
          active: true,
          id: this.id
        },
        include: [{
          relation: 'pessoas',
          scope: {
            include: 'formando'
          }
        }, {
          relation: 'edicoes',
          scope: {
            include: ['curso', {
              relation: 'tipo',
              scope: {
                where: {
                  active: 1
                }
              }
            }]
          }
        }]
      }
    }).$promise.then(res => {
      this.empresa = res;
      // Sort by formando name (localeCompare so we can deal with accented chars)
      this.empresa.pessoas.sort((a,b) => a.formando.nome.localeCompare(b.formando.nome));
      if (this.empresa.id === 57 || this.empresa.id === 66) {
          this.FormacaoEdicao.find({
            filter: {
              where: {
                tipoId: 1
              },
              include: 'curso'
            },
          }).$promise.then(f => {
            this.empresa.edicoes = this.empresa.edicoes.concat(f);
            this.empresa.edicoes = _.uniqBy(this.empresa.edicoes, 'id');
            this.loaded = true;
          })
      } else {
        this.loaded = true;
      }
    }).catch(() => {
      this.UI.addToast('Não foi possível carregar empresa');
      this.$state.go('app.formacao.empresas.list');
    })
  }
}

FormacaoEmpresaDetailsController.$inject = ["$state", "UIService", "FormacaoEmpresa", "FormacaoFormandoEmpresa", "FormacaoEdicao"];
