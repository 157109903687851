export function BacklogInterceptor($rootScope, AuthenticationService, $state, $http, Backlog) {

    $rootScope.$on('$stateChangeSuccess', () => {
      let loggedIn = AuthenticationService.isAuthenticated() || false;
      if(loggedIn) {
        $rootScope.backuser = AuthenticationService.getUser();
        if ($rootScope.backuser != null && $rootScope.backuser.id !== 1) {
          Backlog.register({
            postData: {
              funcionarioId: $rootScope.backuser.id,
              state: $state.current.name
            }
          }).$promise.then((res) => {
          }).catch((err) => {
            console.log(err);
          });
        }
      }
    });
}

BacklogInterceptor.$inject = ['$rootScope', 'AuthenticationService', '$state', '$http', 'Backlog'];
