import AtvAtivosService from "../../../ativos/ativos.service";

export default class PadFornecedoresDetailsController {
  constructor($state, UIService, PadProcesso, AuthorizationService, AtvFornecedorPrimavera, AtvAtivosService) {
    this.state = $state;
    this.UI = UIService;
    this.PadProcesso = PadProcesso;
    this.AuthorizationService = AuthorizationService;
    this.AtvFornecedorPrimavera = AtvFornecedorPrimavera;
    this.AtvAtivosService = AtvAtivosService;
    this.loaded = false;

    // Default opt state - populate later when we have all states
    this.optPads = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "id",
      sort: "desc",
      filter: {}
    };
    this.pads = null;
    this.padsLoading = true;

    this.id = $state.params.id;
    if (angular.isUndefined($state.params.id)) {
      $state.go('app.pad.fornecedores.list');
      UIService.addToast('Não foi possível carregar fornecedores');
    }
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.AtvFornecedorPrimavera.findOne({
      filter: {
        where: {
          active: true,
          id: this.id,
        },
        include: [{
          relation: 'entidadeProprietaria',
          scope: {
            where: {
              active: 1
            }
          }
        }, {
          relation: 'fornecedor',
          scope: {
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then(res => {
      if (res && res.fornecedor && res.entidadeProprietaria) {
        res.cPostal = res.cp4;
        if (res.cp3) res.cPostal += "-" + ("000" + res.cp3).slice(-3);
        if (res.cploc) res.cPostal += " " + res.cploc;
      }

      this.data = res;
      this.loaded = true;
    });
    this.getPadsForFornecedor();
  };

  editObservations = () => {
    let obs = angular.copy(this.data.observacoes);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = obs;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.loaded = false;
      this.data.observacoes = res;
      this.data.$save().then(() => {
        this.UI.addToast('Observações guardadas com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro a guardar as observações. Por favor tente mais tarde.');
        this.loadData();
      })
    })
  };

  resyncFornecedor = (refFornecedorPrimavera, entidadeProprietariaId) => {
    this.AtvFornecedorPrimavera.findOne({
      filter: {
        where: {
          and: [{refFornecedorPrimavera: refFornecedorPrimavera}, {entidadeProprietariaId: entidadeProprietariaId}]
        },
        include: {
          relation: 'entidadeProprietaria',
          scope: {
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((fp) => {
      if (!fp.refFornecedorPrimavera) {
        this.UI.addToast("Não foi possível atualizar fornecedor");
      } else {
        let PRIFornecedor = this.AtvAtivosService.getPrimaveraFornecedorForEntidadeProprietaria(fp.entidadeProprietaria);
        if (PRIFornecedor != null) {
          PRIFornecedor.find({
            filter: {
              where: {
                fornecedor: fp.refFornecedorPrimavera
              }
            }
          }).$promise.then((res) => {
            if (res && res.length > 0) {
              let f = res[0];
              fp.nome = f.nome;
              fp.nif = f.numcontrib;
              fp.contactoTelefonico = f.tel;
              fp.morada = f.morada;
              fp.cp4 = f.cp ? f.cp.split("-")[0] : null;
              fp.cp3 = f.cp ? f.cp.split("-")[1] : null;
              fp.cploc = f.cploc;
              fp.refFornecedorPrimavera = f.fornecedor;
              fp.observacoes = f.notas;
              fp.atualizadoa = f.dataultimaactualizacao;
              fp.localidade = f.local;
              fp.pais = f.pais;
              fp.$save().then((updatedFornecedor) => {
                if (this.data.nome !== updatedFornecedor.nome) {
                  let message = 'Deseja atualizar o nome do Fornecedor de "' + this.data.nome + '" para "' + updatedFornecedor.nome + '" ?';
                  let confirm = this.UI.showConfirm(message);
                  confirm.then(() => { // OK
                    this.data.nome = updatedFornecedor.nome;
                    this.data.$save().then((res) => {
                      this.loadData();
                      this.UI.addToast('Sincronização realizada com sucesso.');
                      this.UI.addToast('Nome de Fornecedor atualizado.');
                    }, (error) => {
                      console.log(error);
                      this.loadData();
                      this.UI.addToast('Sincronização realizada com sucesso.');
                      this.UI.addToast('Não foi possível atualizar o Nome do Fornecedor no SGI.');
                    });
                  }).catch(() => { // Cancel
                    this.loadData();
                    this.UI.addToast('Sincronização realizada com sucesso.');
                  });
                } else {
                  this.loadData();
                  this.UI.addToast('Sincronização realizada com sucesso.');
                }
              }).catch(err => {
                console.log(err);
                this.UI.addToast("Falha na gravação do fabricante. Por favor tente mais tarde.");
              });
            } else {
              this.UI.addToast("Não foi possível atualizar fornecedor do Primavera.");
            }
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não foi possível atualizar fornecedor do Primavera.");
          });
        } else {
          this.UI.addToast("Não foi possível atualizar fornecedor. Entidade Proprietária não encontrada");
        }
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar fornecedor.");
    });
  };

  getPadsForFornecedor = () => {
    // If no permissions, return
    if (!this.AuthorizationService.canPerform('padNivel2') && !this.AuthorizationService.canPerform('padNivel3') && !this.AuthorizationService.canPerform('padNivel4')) {
      return;
    }
    this.padsLoading = true;
    let whereLiteral = {
      'PadProcessoFornecedor.fornecedorPrimaveraId': this.id,
      'PadProcessoFornecedor.active': 1,
    };
    let where = {};
    Object.keys(this.optPads.filter).forEach(f => {
      if (this.optPads.filter[f])
        where[f] = this.optPads.filter[f];
    });

    this.PadProcesso.table({
      params: {
        fields: [
          'PadProcesso.id as id',
          'PadProcesso.numeroProcesso as numeroProcesso',
          'PadProcesso.designacao as designacao',
          'PadProcesso.total as total',
          'PadProcesso.avaliacaoObservacoes as avaliacaoObservacoes',
          'PadProcesso.data as data',
          'PadAvaliacao.designacao as avaliacaoQualidade',
          'PadAvaliacao2.designacao as avaliacaoPrazo'
        ],
        from: ['PadProcesso', 'PadProcessoFornecedor', 'PadAvaliacao', 'PadAvaliacao'],
        referencesOrigin: [undefined, 'PadProcessoFornecedor.id', undefined, undefined],
        references: [undefined, 'PadProcesso.fornecedorSelecionadoId', 'PadProcesso.avaliacaoQualidadeId', 'PadProcesso.avaliacaoPrazoId'],
        aliases: [undefined, undefined, undefined, 'PadAvaliacao2'],
        padFornecedores: true, // To show other processes
        where: where,
        whereLiteral: whereLiteral,
        order: this.optPads.order,
        sort: this.optPads.sort,
        limit: this.optPads.items,
        skip: (this.optPads.page - 1) * this.optPads.items,
      }
    }).$promise.then(res => {
      let page = this.optPads.page;
      let items = this.optPads.items;

      let total = res.count;

      this.optPads.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.optPads.start - 1 + items) >= total) {
        this.optPads.end = total;
      } else {
        this.optPads.end = Number.parseInt(this.optPads.start - 1 + items);
      }

      this.pads = res.data;
      this.optPads.total = total;
      this.padsLoading = false;
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler PADs. Por favor tente mais tarde");
    });
  };

  applyFilterPads = () => {
    this.optPads.page = 1;
    this.getPadsForFornecedor();
  };

  padsSort = keyname => {
    if (this.optPads.order === keyname)
      this.optPads.page = 1;
    this.optPads.order = keyname;
    this.optPads.sort = this.optPads.sort === 'asc' ? 'desc' : 'asc';
    this.getPadsForFornecedor();
  };

  padsItem = val => {
    this.optPads.items = val;
    this.optPads.page = 1;
    this.getPadsForFornecedor();
  };

  padsPage = sum => {
    this.optPads.page += sum;
    if (this.optPads.page < 1)
      this.optPads.page = 1;
    if (this.optPads.page > Math.ceil(this.optPads.total / this.optPads.items))
      this.optPads.page = Math.ceil(this.optPads.total / this.optPads.items);
    this.getPadsForFornecedor();
  };

  viewPad = r => {
    this.state.go('app.pad.processo.details', {id: r.id});
  };
}
PadFornecedoresDetailsController.$inject = ['$state', 'UIService', 'PadProcesso', 'AuthorizationService', 'AtvFornecedorPrimavera', 'AtvAtivosService'];
