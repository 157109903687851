import _ from 'lodash';

export default class CheckController {
  constructor($rootScope, AuthenticationService, $state,  Parametro, Pedidoinspeccao, Pagamento) {
    this.Parametro = Parametro;
    this.Pedidos = Pedidoinspeccao;
    this.Pagamento = Pagamento;
    this.$state = $state;

    this.user = AuthenticationService.getUser();

    // Redirect if user not dev
    if(this.user.id !== 1) {
      this.$state.go('app.dashboard');
    }
    else {
      this.data = [];
      this.getPedidos();
    }
  }

  getPedidos = () => {
    this.Pedidos.find({
      filter: {
        where: {id: {gt: 500}},
        order: 'id desc',
        include: ['Pagamento', 'Estado', 'Requisitante', {
          relation: 'Dadosinstalacao',
          scope: {
            include: ['Tipoimovel', 'Fraccao']
          }
        }]
      }
    }).$promise.then(res => {
      this.Parametro.findOne({
        filter: {
          where: {
            id: 1
          }
        }
      }).$promise.then((parametro) => {
        if (parametro != null) {
          // Valores de referência para cálculo: IVA: 1.23 Valor Unitário: 41.65
          this.iva = parametro.iva + 1;
          this.valorUnitario = parametro.valorUnitario;

          this.data = _.filter(res, (r) => {
            r.totalPagamento = 0;
            r.parametroEntity = parametro.entidade;
            r.parametroSub = parametro.subentidade;
            // Quick maths
            let dadosInstalacao = r.Dadosinstalacao || {};
            r.montante = 0;
            if (!_.isEmpty(dadosInstalacao)) {
              // 1º - Verificar se instalação colectiva
              if (dadosInstalacao.potencia !== null) {
                if (dadosInstalacao.potencia > 0) {
                  r.montante += this.valorUnitario;
                }
              }
              // 2º - Verificar se serviços comuns
              if (dadosInstalacao.potenciaComuns !== null) {
                if (dadosInstalacao.potenciaComuns <= 41.4) {
                  r.montante += this.valorUnitario;
                } else {
                  r.montante += dadosInstalacao.potenciaComuns;
                }
              }
              // 3º - Calcular frações
              dadosInstalacao.Fraccao.forEach(f => {
                if (f.potencia <= 41.4) {
                  r.montante += this.valorUnitario;
                } else {
                  r.montante += f.potencia;
                }
              });
              // Apply discounts
              r.descontoAplicar = 0;
              let requisitante = r.Requisitante;
              r.descontoAplicar = requisitante.desconto || 0;
              // Pre-registration ?
              let dataRegisto = new Date(requisitante.dataRegisto);
              // Caso o desconto do utilizador esteja a 0 e este tem pré-registo, atribui desconto de 10%
              if (r.descontoAplicar === 0 && dataRegisto.getFullYear() <= 2017 && (new Date()).getFullYear() <= 2018) {
                r.descontoAplicar = 0.1;
              }
              if (r.descontoAplicar != 0) {
                r.montante *= (1 - r.descontoAplicar);
              }
              // Reinspeccao?
              if (r.reinspeccao == 1) {
                r.montante *= 0.5;
              }
              r.totalPagamento = Math.round(parseFloat((r.montante * Math.pow(10, 2)).toFixed(2))) / Math.pow(10, 2);
              r.totalPagamento *= this.iva;
              r.totalPagamento = (Math.round(parseFloat((r.totalPagamento * Math.pow(10, 2)).toFixed(2))) / Math.pow(10,2)).toFixed(2);
              let pagamento = r.Pagamento || {};
              r.conforme = true;
              if (!_.isEmpty(pagamento)) {
                r.conforme = (r.totalPagamento == pagamento.montante);

                // Não apresenta montantes com valor null (que não deve acontecer...)
                if (pagamento.montante === null) {
                  r.conforme = true;
                } else {
                  if (Math.abs(pagamento.montante - r.totalPagamento) <= 10) {
                    r.conforme = true;
                  }
                }
              }
            }
            return r.conforme != true;
          });
        } else {
          console.log("Error: Parametro null");
        }
      }).catch((error) => {
        console.log(error);
      });
    })
  };

  // Reset reference with right amount (r.totalPagamento)
  resetReferenciaMontante = (r) => {
    if (r!= null && r.Pagamento != null) {
      let newRef = this.generateRef(r.id, Number(r.totalPagamento), r.parametroEntity, r.parametroSub);

      let instance =  this.UI.showDialog({
        template: require('./modal-confirm-ref-replace.html'),
        controller: ['$scope', function ($scope) {
          $scope.pedidoId = r.id;
          $scope.oldMontante = r.Pagamento.montante;
          $scope.oldReference = r.Pagamento.referencia;
          $scope.newMontante = r.totalPagamento;
          $scope.newReference = newRef;

          $scope.ok = function () {
            $scope.$close('ok');
          };

          $scope.cancel = function () {
            $scope.$dismiss('cancel');
          };
        }]
      });

      instance.then(ok => {
        // OK - Update pagamento in DB
        if (ok = 'ok')
          this.updatePagamentoReferencia(r, newRef);
      },(err) => {
        console.log(err);
      });
    }
  };

  updatePagamentoReferencia = (r, newRef) => {
    this.Pagamento.findOne({
      filter: {
        where: {
          active: 1,
          pedidoId: r.id
        }
      }
    }).$promise.then((p) => {
      if (p!= null) {
        p.montante = r.totalPagamento;
        p.referencia = newRef;
        p.$save().then((err) => {
          this.getPedidos();
        });
      }
    });
  };

  generateRef = (pedidoId, value, entity, sub) => {
    entity = entity.toString();
    sub = sub.toString();
    const ccCalculator = [51, 73, 17, 89, 38, 62, 45, 53, 15, 50, 5, 49, 34, 81, 76, 27, 90, 9, 30, 3];

    pedidoId = pedidoId % 10000; // Últimos 4 digitos
    pedidoId = this.padWithZeros(pedidoId, 4);
    value = this.padWithZeros(value, 8);

    let digit = entity + sub + pedidoId + value;
    let result = 0;
    for (let i = 0; i < ccCalculator.length; i++) {
      result += (ccCalculator[i] * Number(digit[i]));
    }
    let finalResult = 98 - (result % 97);
    let cc = this.padWithZeros(finalResult, 2);
    let reference = sub + pedidoId + cc;
    return Number(reference);
  };

  padWithZeros = (number, length) => {
    let string = number.toString();
    string = string.replace(',', '');
    string = string.replace('.', '');
    while (string.length < length) {
      string = '0' + string;
    }
    return string;
  };

}

CheckController
  .$inject = ['$rootScope', 'AuthenticationService', '$state ', 'Parametro', 'Pedidoinspeccao', 'Pagamento'];
