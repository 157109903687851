export default class CodigosArtigoSusEneAdminController {
  constructor(SusEneCodigoArtigo, SusEneLaboratorio, UIService) {
    this.UI = UIService;
    this.SusEneCodigoArtigo = SusEneCodigoArtigo;
    this.SusEneLaboratorio = SusEneLaboratorio;
    this.codArtigosSusEneLoading = true;
    this.codArtigosSusEne = null;
    this.showCodArtigoSusEneFab = false;
    this.getCodArtigosSusEne();
  }

  updateFab = (f) => {
    if (f === 'codArtigoSusEne') { // Códigos de Artigo SE Fab
      this.showCodArtigoSusEneFab = true;
      // ...
    } /*else if (f === 'codArtigo...') { // Códigos de Artigo ... Fab
      this.showCodArtigoSusEneFab = false;
      // ...
    }*/
  };

  // IEP
  getCodArtigosSusEne = () => {
    this.codArtigosSusEneLoading = true;
    this.SusEneCodigoArtigo.find({
      filter: {
        where: {
          active: 1
        },
        order: 'codigo ASC',
        include: {
          relation: 'laboratorio',
          scope: {
            where: {active: 1}
          }
        }
      }
    }).$promise.then((res) => {
      this.codArtigosSusEne = res;
      this.codArtigosSusEneLoading = false;
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro ao carregar Códigos de Artigo SE. Verifique a ligação");
    })
  };

  confirmDeleteCodArtigoSusEne = (i) => {
    let title = "Eliminar Cód. Artigo SE";
    let warning = "Tem a certeza que pretende remover este código de artigo?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeCodArtigoSusEne(i);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeCodArtigoSusEne = (s) => {
    this.codArtigoSusEneLoading = true;
    // Find again codigo artigo to delete so we can update it
    this.SusEneCodigoArtigo.findOne({
      filter: {
        where: {
          id: s.id,
          active: 1
        }
      }
    }).$promise.then((codToRemove) => {
      codToRemove.active = 0;
      codToRemove.$save().then((res) => {
        this.UI.addToast("Cód. Artigo eliminado com sucesso!");
        this.getCodArtigosSusEne();
      }, (error) => {
        console.log(error);
        this.codArtigosSusEneLoading = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.codArtigoSusEneLoading = false;
      this.UI.addToast("Não foi possível eliminar o cód. artigo. Verifique se ainda existe.");
    });
  };

  addCodArtigoSusEne = () => {
    let cenas = this.UI.showWaiting();
    this.SusEneLaboratorio.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((labs) => {
      cenas.close();
      let instance = this.UI.showDialog({
        size: 'md',
        template: require('./add.codartigo.dialog.html'),
        controller: ['$scope', function ($scope) {
          $scope.label = "Adicionar Código de Artigo SE";
          $scope.blockCodigoEdit = false;
          $scope.labs = labs;

          $scope.codArtigo = {};
          $scope.codArtigo.id = 0;
          $scope.codArtigo.descricao = null;

          $scope.auxLaboratorio = {selected: null};

          $scope.ok = function () {
            // Send scope values for processing
            $scope.$close($scope);
          };

          $scope.cancel = function () {
            $scope.$dismiss('cancel');
          };
        }]
      });

      instance.then((ok) => {
        this.codArtigoSusEneLoading = true;
        ok.codArtigo.codigo = ok.codArtigo.codigo.toUpperCase();
        this.SusEneCodigoArtigo.find({
          filter: {
            where: {
              codigo: ok.codArtigo.codigo
            }
          }
        }).$promise.then((c) => {
          if (c && c.length > 0) { // One already exists
            if (c[0].active) { // And is active
              this.UI.addToast("Esse código já existe na lista de códigos.");
              this.getCodArtigosSusEne();
            } else { // It is not active. Re-use it.
              c[0].laboratorioId = ok.auxLaboratorio.selected.id;
              c[0].descricao = ok.codArtigo.descricao;
              c[0].active = 1;
              c[0].$save().then((res) => {
                this.UI.addToast("Código de artigo adicionado com sucesso");
                this.getCodArtigosSusEne();
              }, (error) => {
                console.log(error);
                this.UI.addToast("Não foi possível adicionar cód. artigos. Por favor tente mais tarde.");
              });
            }
          } else { // Just create it.
            ok.codArtigo.laboratorioId = ok.auxLaboratorio.selected.id;
            ok.codArtigo.active = 1;
            this.SusEneCodigoArtigo.create(ok.codArtigo).$promise.then(r => {
              this.getCodArtigosSusEne();
              this.UI.addToast("Cód. Artigo inserido com sucesso.");
            }).catch(error => {
              console.log(error);
              this.codArtigoSusEneLoading = false;
              this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
            });
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível adicionar cód. artigos. Por favor tente mais tarde.");
        });

      }, (err) => {
        // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
        if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
          console.log(err);
      });
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar laboratórios para adicionar cód. artigos. Por favor tente mais tarde.");
    });
  };

  editCodArtigoSusEne = (c) => {
    let cenas = this.UI.showWaiting();
    this.SusEneLaboratorio.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((labs) => {
      cenas.close();
      let instance = this.UI.showDialog({
        size: 'md',
        template: require('./add.codartigo.dialog.html'),
        controller: ['$scope', function ($scope) {
          $scope.label = "Editar Código de Artigo SE";
          $scope.blockCodigoEdit = true;
          $scope.labs = labs;

          $scope.codArtigo = c;

          $scope.auxLaboratorio = {selected: c.laboratorio};

          $scope.ok = function () {
            // Send scope values for processing
            $scope.$close($scope);
          };

          $scope.cancel = function () {
            $scope.$dismiss('cancel');
          };
        }]
      });

      instance.then((ok) => {
        this.codArtigoSusEneLoading = true;
        ok.codArtigo.codigo = ok.codArtigo.codigo.toUpperCase();
        this.SusEneCodigoArtigo.find({
          filter: {
            where: {
              codigo: ok.codArtigo.codigo
            }
          }
        }).$promise.then((c) => {
          if (c && c.length > 0) { // One already exists
            if (!c[0].active) { // And is active
              this.UI.addToast("Este código foi eliminado. Por favor volte a criá-lo, se aplicável.");
              this.getCodArtigosSusEne();
            } else { // It is active. Update it.
              c[0].laboratorioId = ok.auxLaboratorio.selected.id;
              c[0].descricao = ok.codArtigo.descricao;
              c[0].$save().then((res) => {
                this.UI.addToast("Dados editados com sucesso");
                this.getCodArtigosSusEne();
              }, (error) => {
                console.log(error);
                this.UI.addToast("Não foi possível editar dados. Por favor tente mais tarde.");
              });
            }
          } else { // Code does not exist to edit.
            this.UI.addToast("Este código não existe. Por favor crie-o, se aplicável.");
            this.getCodArtigosSusEne();
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível adicionar cód. artigos. Por favor tente mais tarde.");
        });

      }, (err) => {
        // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
        if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
          console.log(err);
      });
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar laboratórios para adicionar cód. artigos. Por favor tente mais tarde.");
    });
  };
}

CodigosArtigoSusEneAdminController.$inject = ['SusEneCodigoArtigo', 'SusEneLaboratorio', 'UIService'];
