import moment from 'moment';
export default class OblerPendentesService {
    $q;
    OblProcessoOrcamentacao;
    AuthenticationService;
    constructor($q, OblProcessoOrcamentacao, AuthenticationService) {
        this.$q = $q;
        this.OblProcessoOrcamentacao = OblProcessoOrcamentacao;
        this.AuthenticationService = AuthenticationService;
    }
    findAll(query) {
        return this.OblProcessoOrcamentacao.table(query).$promise;
    }
    options() {
        return this.OblProcessoOrcamentacao.options().$promise;
    }
    patch(id, data) {
        return this.OblProcessoOrcamentacao.patch({ id }, data).$promise;
    }
    validate(id) {
        return this.OblProcessoOrcamentacao.validate({ id }).$promise;
    }
    create(data) {
        data.requisitanteId = this.AuthenticationService.getId();
        data.dataRequisicao = moment().format();
        return this.OblProcessoOrcamentacao.create(data).$promise;
    }
}
OblerPendentesService.$inject = ['$q', 'OblProcessoOrcamentacao', 'AuthenticationService'];
