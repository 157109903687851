export default class DirectoryTopicosController {
  constructor(UIService, AuthorizationService, ElvTopicoVerificacao, Table) {
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.ElvTopicoVerificacao = ElvTopicoVerificacao;
    this.Table = Table;
    this.loaded = false;
    this.loadData();
    this.topicos = [];
  }

  loadData = () => {
    this.loaded = false;
    this.ElvTopicoVerificacao.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(res => {
      this.topicos = res;
      this.tableData = new this.Table({
        sorting: {
          id: 'desc'
        }
      }, {
        dataset: this.topicos,
      });
      console.log(this.topicos);
      this.loaded = true;
    }).catch((error) => {
      console.log(error);
      this.loaded = true;
      this.UI.addToast('Erro ao carregar tópicos.');
    });
  };

  addTopico = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./topico.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.label = "Adicionar Tópico de Verificação";

        $scope.topico = {
          id: 0,
          active: true,
          nome: ""
        };

        $scope.ok = () => {
          $scope.$close($scope.topico);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    }).then(res => {
      if (res) {
        this.ElvTopicoVerificacao.create(res).$promise.then((topico) => {
          this.loadData();
          this.UI.addToast('Tópico de verificação inserido com sucesso!');
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Erro ao adicionar tópico de verificação.');
        })
      }
    });
  };

  editTopico = (rr) => {
    let cenas = this.UI.showWaiting();
    this.ElvTopicoVerificacao.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((rcm) => {
      cenas.close();
      this.UI.showDialog({
        size: 'lg',
        template: require('./topico.dialog.html'),
        controller: ['$scope', '$dialog', function ($scope, $dialog) {
          $scope.label = "Editar Tópico de Verificação";

          $scope.topico = {
            id: rr.id,
            nome: rr.nome,
            active: true
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      }).then(r => {
        if (r) {
          this.loaded = false;
          this.ElvTopicoVerificacao.findOne({
            filter: {
              where: {
                id: rr.id,
                active: 1
              }
            }
          }).$promise.then((topico) => {
            topico.nome = r.topico.nome;
            topico.$save().then((res) => {
              this.loadData();
              this.UI.addToast('Tópico atualizado com sucesso');
            }, (error) => {
              console.log(error);
              this.UI.addToast('Erro ao atualizar tópico');
              this.loadData();
            });
          }).catch((error) => {
            console.log(error);
            this.UI.addToast('Não foi possível encontrar este tópico.');
            this.loadData();
          });
        }
      })
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar tópicos de verificação. Por favor tente mais tarde.");
    });
  };

  removeTopico = (topico) => {
    let confirm = this.UI.showConfirm('Deseja remover o tópico de verificação?');
    confirm.then(res => {
      if (res) {
        topico.active = 0;
        this.ElvTopicoVerificacao.upsert(topico).$promise.then(() => {
          this.loadData();
          this.UI.addToast('O tópico foi removido com sucesso.');
        });
      }
    })
  };

}

DirectoryTopicosController.$inject = ['UIService', 'AuthorizationService', 'ElvTopicoVerificacao', 'NgTableParams'];
