export default class DirectoryPedidosIEController {
  constructor($q, $state, $http, AuthorizationService, AuthenticationService, Pedidoinspeccao, Estado, Requisitante, Group, Tipoimovel, UIService) {
    this.$q = $q;
    this.$state = $state;
    this.$http = $http;
    this.AuthorizationService = AuthorizationService;
    this.Auth = AuthenticationService;
    this.Pedidoinspeccao = Pedidoinspeccao;
    this.Estado = Estado;
    this.Requisitante = Requisitante;
    this.Tipoimovel = Tipoimovel;
    this.Group = Group;
    this.UI = UIService;

    this.dataLoaded = false;

    this.estados = [];
    this.requisitantes = [];
    this.inspetores = [];
    this.tiposImovel = [];
    this.simnao = [{ name: "Sim", value: 1 }, { name: "Não", value: 0 }];
    this.filtersLoaded = [1, 0, 0, 0, 0];

    this.opt = $state.params;

    this.displayColumns = [
      { displayName: 'ID SGI', name: 'id', visible: false, sortable: true },
      { displayName: 'Visto', name: 'visto', visible: true, sortable: false },
      { displayName: 'Nº Processo', name: 'numProcesso', visible: true, sortable: true },
      { displayName: 'NIP', name: 'nip', visible: true, sortable: true },
      { displayName: 'Pot. Total (kVA)', name: 'potTotal', visible: true, sortable: true },
      { displayName: 'Nº Fracções', name: 'fraccoes', visible: true, sortable: true },
      { displayName: 'Reinspeção?', name: 'reinspeccao', visible: false, sortable: true },
      { displayName: 'ITED?', name: 'pedidotelecomunicacoes', visible: false, sortable: true },
      { displayName: 'Cliente', name: 'cliente', visible: true, sortable: true },
      { displayName: 'Nome Téc. Resp.', name: 'tecnicoNome', visible: false, sortable: true },
      { displayName: 'Telem. Téc. Resp.', name: 'tecnicoTelemovel', visible: false, sortable: true },
      { displayName: 'NIF Téc. Resp.', name: 'tecnicoNif', visible: false, sortable: true },
      { displayName: 'Nº DGEG Téc. Resp.', name: 'tecnicoNTecnico', visible: false, sortable: true },
      { displayName: 'Morada', name: 'morada', visible: true, sortable: true },
      { displayName: 'CP4', name: 'cp4', visible: true, sortable: true },
      { displayName: 'CP3', name: 'cp3', visible: true, sortable: false },
      { displayName: 'Localidade', name: 'localidade', visible: true, sortable: true },
      { displayName: 'Estado', name: 'estado', visible: true, sortable: true },
      { displayName: 'Data de Criação', name: 'dataPedido', exportDate: 'DD-MM-YYYY HH:mm:ss', visible: false }, // Data de Criação do Pedido
      { displayName: 'Data de Pagamento', name: 'dataPagamento', exportDate: 'DD-MM-YYYY HH:mm:ss', visible: false }, // Data de Pagamento
      { displayName: 'Data de Inspeção', name: 'agendamentoData', exportDate: 'DD-MM-YYYY', visible: false }, // Data de Agendamento
      { displayName: 'Hora de Inspeção', name: 'agendamentoHora', visible: false }, // Hora de Agendamento // exportDate: 'HH:mm:ss', Cannot be used because we can't directly use it as a moment
      { displayName: 'Data de Conclusão', name: 'dataConclusao', exportDate: 'DD-MM-YYYY HH:mm:ss', visible: false }, // Data de Criação do Pedido
      { displayName: 'Inspetor', name: 'inspetor', visible: false, sortable: true },
      { displayName: 'Observações', name: 'observacoes', visible: false, sortable: true }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 'o'
    },
    {
      val: 'c',
      name: 'Começa por',
      type: 'o'
    },
    {
      val: 'd',
      name: 'Termina com',
      type: 'o'
    },
    {
      val: 'e',
      name: 'Contém',
      type: 'o'
    },
    {
      val: 'a',
      name: 'Igual (=)',
      type: 'd'
    },
    {
      val: 'b',
      name: 'Diferente de (≠)',
      type: 'd'
    },
    {
      val: 'c',
      name: 'Posterior a (>)',
      type: 'd'
    },
    {
      val: 'd',
      name: 'Anterior a (<)',
      type: 'd'
    },
    {
      val: 'e',
      name: 'Posterior ou igual (≥)',
      type: 'd'
    },
    {
      val: 'f',
      name: 'Anterior ou igual (≤)',
      type: 'd'
    },
    {
      val: 'a',
      name: 'Igual',
      type: 's'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 's'
    }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [{
      id: 'Pedidoinspeccao.id',
      name: 'ID SGI',
      type: 'o'
    },
    {
      id: 'Pedidoinspeccao.visto',
      name: 'Visto',
      type: 's',
      list: this.simnao
    },
    {
      id: 'Pedidoinspeccao.identificacaoInterna',
      name: 'Nº Processo',
      type: 'o'
    },
    {
      id: 'Dadosinstalacao.nip',
      name: 'NIP',
      type: 'o'
    },
    {
      id: 'Dadosinstalacao.potenciaTotalEdificio',
      name: 'Pot. Total (kVA)',
      type: 'o'
    },
    {
      id: 'Pedidoinspeccao.reinspeccao',
      name: 'Reinspeção?',
      type: 's',
      list: this.simnao
    },
    {
      id: 'Pedidoinspeccao.pedidotelecomunicacoes',
      name: 'ITED?',
      type: 's',
      list: this.simnao
    },
    {
      id: 'Dadosinstalacao.codigoPostal1',
      name: 'Nº Cód. Postal',
      type: 'o'
    },
    {
      id: 'Dadosinstalacao.codigoPostal2',
      name: 'Ext. Cód. Postal',
      type: 'o'
    },
    {
      id: 'Dadosinstalacao.codigoPostal3',
      name: 'Localidade',
      type: 'o',
    },
    {
      id: 'Pedidoinspeccao.requisitanteId',
      name: 'Cliente',
      type: 's',
      list: this.requisitantes
    },
    {
      id: 'Tecnico.nome',
      name: 'Nome Téc. Resp.',
      type: 'o'
    },
    {
      id: 'Tecnico.telemovel',
      name: 'Telem. Téc. Resp.',
      type: 'o'
    },
    {
      id: 'Tecnico.nif',
      name: 'NIF Téc. Resp.',
      type: 'o'
    },
    {
      id: 'Tecnico.nTecnico',
      name: 'Nº DGEG Téc. Resp.',
      type: 'o'
    },
    {
      id: 'Fraccao.tipoimovelId',
      name: 'Tipo de instalação',
      type: 's',
      list: this.tiposImovel
    },
    {
      id: 'Pedidoinspeccao.estadoId',
      name: 'Estado',
      type: 's',
      list: this.estados
    },
    {
      id: 'Pedidoinspeccao.dataPedido',
      name: 'Data de Criação', // Data de Criação de Pedido
      type: 'd',
      format: "YYYY-MM-DD HH:mm:ss",
      displayFormat: "DD/MM/YYYY HH:mm:ss"
    },
    {
      id: 'Pagamento.dataPagamento',
      name: 'Data de Pagamento', // Data de Pagamento
      type: 'd',
      format: "YYYY-MM-DD HH:mm:ss",
      displayFormat: "DD/MM/YYYY HH:mm:ss"
    },
    {
      id: 'Agendamento.data',
      name: 'Data de Inspeção', // Data de Agendamento
      type: 'd',
      format: "YYYY-MM-DD",
      displayFormat: "DD/MM/YYYY"
    },
    {
      id: 'Agendamento.hora',
      name: 'Hora de Inspeção', // Hora de Agendamento
      type: 'd',
      format: "HH:mm",
      displayFormat: "HH:mm"
    },
    {
      id: 'Pedidoinspeccao.dataConclusao',
      name: 'Data de Conclusão', // Data de Conclusao
      type: 'd',
      format: "YYYY-MM-DD HH:mm:ss",
      displayFormat: "DD/MM/YYYY HH:mm:ss"
    },
    {
      id: 'Funcionario.id',
      name: 'Inspetor',
      type: 's',
      list: this.inspetores
    },
    {
      id: 'Pedidoinspeccao.observacoes',
      name: 'Observações',
      type: 'o'
    }
    ];


    // Find relevant displayColumns from local storage
    if (localStorage.getItem('IePedidoDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('IePedidoDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('IePedidoFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('IePedidoFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('IePedidoFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column && f.column.selected) {
          if (f.column.selected.id === 'Pedidoinspeccao.visto')
            f.column.selected.list = this.simnao;
          if (f.column.selected.id === 'Pedidoinspeccao.requisitanteId')
            f.column.selected.list = this.requisitantes;
          if (f.column.selected.id === 'Pedidoinspeccao.estadoId')
            f.column.selected.list = this.estados;
          if (f.column.selected.id === 'Funcionario.id')
            f.column.selected.list = this.inspetores;
          if (f.column.selected.id === 'Pedidoinspeccao.reinspeccao')
            f.column.selected.list = this.simnao;
          if (f.column.selected.id === 'Pedidoinspeccao.pedidotelecomunicacoes')
            f.column.selected.list = this.simnao;
          if (f.column.selected.id === 'Fraccao.tipoimovelId')
            f.column.selected.list = this.tiposImovel;
        }
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getProcessos();
  }

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  clearFilter = () => {
    localStorage.removeItem('IePedidoFilter');
    this.customFilters = [];
    this.getProcessos();
  };

  getProcessos = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, { 'Pedidoinspeccao.active': 1 });

    //TODO: Migrate codigoPostal to field with snake case on MySQL
    this.Pedidoinspeccao.table({
      params: {
        fields: [ // edit exportProcessos() too
          'Pedidoinspeccao.id as id',
          'Pedidoinspeccao.identificacaoInterna as numProcesso',
          'Pedidoinspeccao.estadoId as estadoId',
          'Pedidoinspeccao.reinspeccao as reinspeccao',
          'Pedidoinspeccao.pedidotelecomunicacoes as pedidotelecomunicacoes',
          'Pedidoinspeccao.dataPedido as dataPedido',
          'Pedidoinspeccao.dataConclusao as dataConclusao',
          'Pedidoinspeccao.observacoes as observacoes',
          'Pedidoinspeccao.visto as visto',
          'Dadosinstalacao.noFraccoes as fraccoes',
          'Dadosinstalacao.potenciaTotalEdificio as potTotal',
          'Dadosinstalacao.nip as nip',
          'Dadosinstalacao.morada as morada',
          'Requisitante.nome as cliente',
          'Tecnico.nome as tecnicoNome',
          'Tecnico.telemovel as tecnicoTelemovel',
          'Tecnico.nif as tecnicoNif',
          'Tecnico.nTecnico as tecnicoNTecnico',
          'Dadosinstalacao.codigoPostal1 as cp4',
          'Dadosinstalacao.codigoPostal2 as cp3',
          'Dadosinstalacao.codigoPostal3 as localidade',
          'Estado.designacao as estado',
          'Agendamento.data as agendamentoData',
          'Agendamento.hora as agendamentoHora',
          'Pagamento.dataPagamento as dataPagamento',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as inspetor'
        ],
        from: ['Pedidoinspeccao', 'Estado', 'Dadosinstalacao', 'Requisitante', 'Agendamento', 'Funcionario', 'Tecnico', 'Pagamento', 'Fraccao'],
        referencesOrigin: [undefined, undefined, 'Dadosinstalacao.pedidoinspeccaoId', undefined, 'Agendamento.pedidoId', 'Agendamento.tecnicoId', 'Tecnico.pedidoinspeccaoId', 'Pagamento.pedidoId', 'Dadosinstalacao.id'],
        references: [undefined, 'Pedidoinspeccao.estadoId', 'Pedidoinspeccao.id', 'Pedidoinspeccao.requisitanteId', 'Pedidoinspeccao.id', 'Funcionario.id', 'Pedidoinspeccao.id', 'Pedidoinspeccao.id', 'Fraccao.dadosinstalacaoId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      res.data.forEach(i => {
        if (i.cp4 && !i.cp3) {
          i.codigoPostal = i.cp4;
        }
        if (i.cp4 && i.cp3) {
          i.codigoPostal = `${('0000' + i.cp4).slice(-4)}-${('000' + i.cp3).slice(-3)}`;
        }
      });

      this.pedidos = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((error) => {
      console.log(error);
      this.UI.showAlert("Erro na busca de pedidos de inspeção.\nPor favor verifique a ligação ou tente mais tarde.");
    });
  };

  exportProcessos = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, { 'Pedidoinspeccao.active': 1 });
    this.Pedidoinspeccao.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [ // edit newGetProcessos() too
          'Pedidoinspeccao.id as id',
          'Pedidoinspeccao.identificacaoInterna as numProcesso',
          'Pedidoinspeccao.estadoId as estadoId',
          'Pedidoinspeccao.reinspeccao as reinspeccao',
          'Pedidoinspeccao.pedidotelecomunicacoes as pedidotelecomunicacoes',
          'Pedidoinspeccao.dataPedido as dataPedido',
          'Pedidoinspeccao.dataConclusao as dataConclusao',
          'Pedidoinspeccao.observacoes as observacoes',
          'Pedidoinspeccao.visto as visto',
          'Dadosinstalacao.noFraccoes as fraccoes',
          'Dadosinstalacao.potenciaTotalEdificio as potTotal',
          'Dadosinstalacao.nip as nip',
          'Dadosinstalacao.morada as morada',
          'Requisitante.nome as cliente',
          'Tecnico.nome as tecnicoNome',
          'Tecnico.telemovel as tecnicoTelemovel',
          'Tecnico.nif as tecnicoNif',
          'Tecnico.nTecnico as tecnicoNTecnico',
          'Dadosinstalacao.codigoPostal1 as cp4',
          'Dadosinstalacao.codigoPostal2 as cp3',
          'Dadosinstalacao.codigoPostal3 as localidade',
          'Estado.designacao as estado',
          'Agendamento.data as agendamentoData',
          'Agendamento.hora as agendamentoHora',
          'Pagamento.dataPagamento as dataPagamento',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as inspetor'
        ],
        from: ['Pedidoinspeccao', 'Estado', 'Dadosinstalacao', 'Requisitante', 'Agendamento', 'Funcionario', 'Tecnico', 'Pagamento'],
        referencesOrigin: [undefined, undefined, 'Dadosinstalacao.pedidoinspeccaoId', undefined, 'Agendamento.pedidoId', 'Agendamento.tecnicoId', 'Tecnico.pedidoinspeccaoId', 'Pagamento.pedidoId'],
        references: [undefined, 'Pedidoinspeccao.estadoId', 'Pedidoinspeccao.id', 'Pedidoinspeccao.requisitanteId', 'Pedidoinspeccao.id', 'Funcionario.id', 'Pedidoinspeccao.id', 'Pedidoinspeccao.id'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'pedidosInspecaoExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar pedidos de inspeção. Por favor tente mais tarde.");
    });
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.pedidos, x => x.selected), x => {
        return { key: 'Pedidoinspeccao.id', value: x.id };
      });
    let whereFields = this.setWhereFields(this.customFilters, { 'Pedidoinspeccao.active': 1 }, orsLiteral);
    this.Pedidoinspeccao.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [ // edit newGetProcessos() too
          'Pedidoinspeccao.id as id',
          'Pedidoinspeccao.identificacaoInterna as numProcesso',
          'Pedidoinspeccao.estadoId as estadoId',
          'Pedidoinspeccao.reinspeccao as reinspeccao',
          'Pedidoinspeccao.pedidotelecomunicacoes as pedidotelecomunicacoes',
          'Pedidoinspeccao.dataPedido as dataPedido',
          'Pedidoinspeccao.dataConclusao as dataConclusao',
          'Pedidoinspeccao.observacoes as observacoes',
          'Pedidoinspeccao.visto as visto',
          'Dadosinstalacao.noFraccoes as fraccoes',
          'Dadosinstalacao.potenciaTotalEdificio as potTotal',
          'Dadosinstalacao.nip as nip',
          'Dadosinstalacao.morada as morada',
          'Requisitante.nome as cliente',
          'Tecnico.nome as tecnicoNome',
          'Tecnico.telemovel as tecnicoTelemovel',
          'Tecnico.nif as tecnicoNif',
          'Tecnico.nTecnico as tecnicoNTecnico',
          'Dadosinstalacao.codigoPostal1 as cp4',
          'Dadosinstalacao.codigoPostal2 as cp3',
          'Dadosinstalacao.codigoPostal3 as localidade',
          'Estado.designacao as estado',
          'Agendamento.data as agendamentoData',
          'Agendamento.hora as agendamentoHora',
          'Pagamento.dataPagamento as dataPagamento',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as inspetor'
        ],
        from: ['Pedidoinspeccao', 'Estado', 'Dadosinstalacao', 'Requisitante', 'Agendamento', 'Funcionario', 'Tecnico', 'Pagamento'],
        referencesOrigin: [undefined, undefined, 'Dadosinstalacao.pedidoinspeccaoId', undefined, 'Agendamento.pedidoId', 'Agendamento.tecnicoId', 'Tecnico.pedidoinspeccaoId', 'Pagamento.pedidoId'],
        references: [undefined, 'Pedidoinspeccao.estadoId', 'Pedidoinspeccao.id', 'Pedidoinspeccao.requisitanteId', 'Pedidoinspeccao.id', 'Funcionario.id', 'Pedidoinspeccao.id', 'Pedidoinspeccao.id'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'pedidosInspecaoExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar pedidos de inspeção. Por favor tente mais tarde.");
    });
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('IePedidoDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  loadData = () => {
    this.Estado.find({
      filter: {
        where: {
          active: true
        },
        order: 'ordem ASC'
      }
    }).$promise.then(estados => {
      estados.forEach(t => {
        t.name = t.designacao;
      });
      this.estados = estados;
      this.filtersLoaded[1] = 1;
    }).catch(e => {
      this.estados = [];
      this.filtersLoaded[1] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Estados).");
    });

    this.Requisitante.find({
      filter: {
        fields: {
          id: true,
          nome: true,
        },
        where: {
          active: true
        },
      }
    }).$promise.then(clientes => {
      clientes.forEach(t => {
        t.name = t.nome;
      });
      this.requisitantes = clientes;
      this.filtersLoaded[2] = 1;
    }).catch(e => {
      this.requisitantes = [];
      this.filtersLoaded[2] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Clientes).");
    });

    this.Group.findOne({
      filter: {
        where: {
          name: {
            like: 'Inspetor%IE'
          }
        },
        include: {
          relation: 'usergroup',
          scope: {
            include: 'user'
          }
        }
      }
    }).$promise.then((g) => {
      if (g && g.usergroup) {
        g.usergroup.forEach(u => {
          this.inspetores.push(u.user);
        });

        this.inspetores = _.orderBy(this.inspetores, 'name', 'asc');
        this.filtersLoaded[3] = 1;
      }
    }).catch(err => {
      console.log(err);
      this.filtersLoaded[3] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Inspetores).");
    });

    this.Tipoimovel.find({
      filter: {
        where: {
          active: true
        },
        order: 'designacao ASC'
      }
    }).$promise.then(tiposImovel => {
      tiposImovel.forEach(t => {
        t.name = t.designacao;
      });
      this.tiposImovel = tiposImovel;
      this.filtersLoaded[4] = 1;
    }).catch(e => {
      this.tiposImovel = [];
      this.filtersLoaded[4] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Tipos de Imóvel).");
    });
  };

  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.pedidos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.pedidos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.pedidos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.requisitantes.length && !this.estados.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'Pedidoinspeccao.visto')
        f.list = this.simnao;
      if (f.id === 'Pedidoinspeccao.requisitanteId')
        f.list = this.requisitantes;
      if (f.id === 'Pedidoinspeccao.estadoId')
        f.list = this.estados;
      if (f.id === 'Funcionario.id')
        f.list = this.inspetores;
      if (f.id === 'Pedidoinspeccao.reinspeccao')
        f.list = this.simnao;
      if (f.id === 'Pedidoinspeccao.pedidotelecomunicacoes')
        f.list = this.simnao;
      if (f.id === 'Fraccao.tipoimovelId')
        f.list = this.tiposImovel;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'Pedidoinspeccao.visto')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'Pedidoinspeccao.requisitanteId')
          f.column.selected.list = this.requisitantes;
        if (f.column.selected.id === 'Pedidoinspeccao.estadoId')
          f.column.selected.list = this.estados;
        if (f.column.selected.id === 'Funcionario.id')
          f.column.selected.list = this.inspetores;
        if (f.column.selected.id === 'Pedidoinspeccao.reinspeccao')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'Pedidoinspeccao.pedidotelecomunicacoes')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'Fraccao.tipoimovelId')
          f.column.selected.list = this.tiposImovel;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('IePedidoFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.pedidos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getProcessos();
  };

  hasSelect = () => {
    return _.some(this.pedidos, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.pedidos.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.pedidos.length;
    } else { // Remove all selections
      this.pedidos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.pedidos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.pedidos, r => r.selected).length;
    this.allSelected = this.nSelected >= this.pedidos.length;
    this.everythingSelected = false;
  };

  // Async function to add visto to pedidos
  verifyPedido = (pedidoId, visto) => {
    let defer = this.$q.defer();
    this.Pedidoinspeccao.findById({id: pedidoId}).$promise.then(p => {
      if (visto) {
        p.visto = 1;
        p.verificadoPorId = this.Auth.getId();
      } else {
        p.visto = 0;
        p.verificadoPorId = null;
      }
      this.Pedidoinspeccao.upsert(p).$promise.then(() => {
        defer.resolve(pedidoId);
      }).catch(error => {
        console.log(error);
        defer.reject(error);
      });
    }).catch(error => {
      console.log(error);
      defer.reject(error);
    });
    return defer.promise;
  };

  // Interface called function to add visto
  verify = (pedido) => {
    if (this.AuthorizationService.canPerform('pedidoIeVerificar')) {
      let wait = this.UI.showWaiting();
      // Toggle the value of visto
      this.verifyPedido(pedido.id, !pedido.visto).then(() => {
        wait.close();
        this.UI.addToast("Pedido verificado com sucesso");
        this.getProcessos();
      }).catch(error => {
        wait.close();
        console.log(error);
        this.UI.addToast("Erro na colocação de visto no pedido. Verifique a ligação.");
      });
    } else {
      this.UI.addToast("Sem permissões para verificar pedido");
    }
  };

  // Allow multiple verifications (if they are all the same)
  verifyMultiple = () => {
    if (this.AuthorizationService.canPerform('pedidoIeVerificar')) {
      // Check if all selected have the same visto state
      let pedidosSelected = this.pedidos.filter(x => x.selected);
      if (pedidosSelected && pedidosSelected.length > 0) {
        let initialVisto = pedidosSelected[0].visto;
        if (pedidosSelected.some(x => x.visto !== initialVisto)) {
          this.UI.showAlert("Não é possível verificar pedidos múltiplos com valores distintos\nSelecione pedidos com o mesmo estado de verificação.");
        } else {
          // All pedidos have the same visto, use it to change multiple at once
          let wait = this.UI.showWaiting();
          let tasks = [];
          pedidosSelected.forEach(p => {
            let defer = this.$q.defer();
            this.verifyPedido(p.id, !initialVisto).then(() => {
              defer.resolve(p.id);
            }).catch(error => {
              defer.reject(error);
            });
            tasks.push(defer.promise);
          });
          this.$q.all(tasks).then((res) => {
            wait.close();
            this.UI.addToast((!initialVisto === true) ? "Pedido verificado com sucesso" : "Removida verificação de pedido com sucesso");
            this.getProcessos();
          }).catch(error => {
            wait.close();
            this.getProcessos();
            console.log(error);
            this.UI.addToast("Erro na colocação de visto num pedido. Verifique a ligação.");
          });
        }
      }
    } else {
      this.UI.addToast("Sem permissões para verificar pedido");
    }
  };
}

DirectoryPedidosIEController.$inject = ['$q', '$state', '$http', 'AuthorizationService', 'AuthenticationService', 'Pedidoinspeccao', 'Estado', 'Requisitante', 'Group', 'Tipoimovel', 'UIService'];
