export default class AtvCreateAtivoController {
  constructor(AuthenticationService, UIService, AtvAtivosService, $http, $state, $scope, AtvAtivo, AtvAtivoPartesCorpo, AtvPartesCorpo, AtvLocal, AtvFornecedor, AtvFornecedorPrimavera, AtvMarca, AtvTipoAtivo, AtvTamanhoTipo, AtvTamanho, AtvPropriedade, AtvEntidadeProprietaria, AtvEstadoCompra, AtvAtivoPrefixo, AtvFamilia) {
    this.UI = UIService;
    this.AtvAtivosService = AtvAtivosService;
    this.user = AuthenticationService.getUser();
    this.state = $state;
    this.loaded = false;
    this.AtvAtivo = AtvAtivo;
    this.AtvAtivoPartesCorpo = AtvAtivoPartesCorpo;
    this.AtvPartesCorpo = AtvPartesCorpo;
    this.AtvLocal = AtvLocal;
    this.AtvFornecedor = AtvFornecedor;
    this.AtvFornecedorPrimavera = AtvFornecedorPrimavera;
    this.AtvPropriedade = AtvPropriedade;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.AtvEstadoCompra = AtvEstadoCompra;
    this.AtvTamanhoTipo = AtvTamanhoTipo;
    this.AtvTamanho = AtvTamanho;
    // this.AtvTipoAtivo = AtvTipoAtivo;
    this.AtvMarca = AtvMarca;
    // this.AtvAtivoPrefixo = AtvAtivoPrefixo;
    this.AtvFamilia = AtvFamilia;
    this.fornecedores = null;
    this.entidadesProprietarias = null;
    this.propriedades = null;
    this.tiposAtivo = null;
    this.tamanhosTipo = null;
    this.tamanhos = null;
    this.estadosCompra = null;
    this.partesCorpo = null;
    this.locais = null;
    this.prefixos = null;
    this.simnao2 = [{name:"Não Aplicável", value: 2}, {name: "Sim", value: 1}, {name: "Não", value: 0}];
    this.emptyData = {
      form: 1,
      details: {},
      usedImobilizado: false, // If numImobilizado was already imported and therefore can't be used
      usingImobilizado: false, // If we are using a valid numImobilizado from Primavera
      // copyImobilizado: false, // If we want to copy numImobilizado to num Ativo instead of using another number
      auxTipoAtivo: {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}},
      auxMarca: {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}},
      auxEntidadeProprietaria: {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}},
      auxPropriedade: {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}},
      auxEstadoCompra: {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}},
      auxFornecedor: {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}},
      auxTamanhoTipo: {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}},
      auxTamanho: {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}},
      auxPartesCorpo: {selected: [], infiniteScroll: {numToAdd: 20, currentItems: 20}},
      auxLocal: {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}},
      // auxPrefixo: {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}},
      auxFamilia: {selected: null, infiniteScroll: {numToAdd: 20, currentItems: 20}},
      auxCalibracaoExterior: {selected: this.simnao2[0], infiniteScroll: {numToAdd: 20, currentItems: 20}},
    };

    this.currentEntidadeProprietaria = null;
    this.currentTamanhoTipo = null;

    this.dateOptions = {
      dataCompra: {format: 'DD/MM/YYYY'},
      dataFabrico: {format: 'DD/MM/YYYY'},
      dataFimGarantia: {format: 'DD/MM/YYYY'},
      dataValidade: {format: 'DD/MM/YYYY'},
      dataAbate: {format: 'DD/MM/YYYY'},
      ultimaCalibracao: {format: 'DD/MM/YYYY'},
      ultimaManutencao: {format: 'DD/MM/YYYY'},
      ultimaVerificacao: {format: 'DD/MM/YYYY'}
    };

    // Load data to fill ui-selects
    this.loadAuxData();

    $scope.$watch(() => {
      return this.data;
    }, (val) => {
      if (angular.isDefined(val)) {
        localStorage.setItem('ATVnewAtivoForm', JSON.stringify(val));
      }
    }, true);
  }

  loadAuxData = () => {
    this.AtvEntidadeProprietaria.find({
      filter: {
        where: {
          ative: 1
        }
      }
    }).$promise.then((entidadesProprietarias) => {
      this.entidadesProprietarias = entidadesProprietarias;
      this.AtvTamanhoTipo.find({
        filter: {
          where: {
            active: 1
          },
          order: 'designacao ASC'
        }
      }).$promise.then((tamanhosTipo) => {
        this.tamanhosTipo = tamanhosTipo;
        // this.AtvTipoAtivo.find({
        //   filter: {
        //     where: {
        //       active: true
        //     },
        //     order: 'designacao ASC'
        //   }
        // }).$promise.then(tiposAtivo => {
        //   this.tiposAtivo = tiposAtivo;
        this.AtvFamilia.find({
          filter: {
            where: {
              active: true
            },
            order: 'ordem ASC'
          }
        }).$promise.then(familias => {
          this.familias = familias;
          this.AtvMarca.find({
            filter: {
              where: {
                active: true
              },
              order: 'designacao ASC'
            }
          }).$promise.then(marcas => {
            this.marcas = marcas;
            this.AtvEstadoCompra.find({
              filter: {
                where: {
                  active: true
                },
                order: 'ordem ASC'
              }
            }).$promise.then(estadosCompra => {
              this.estadosCompra = estadosCompra;
              this.AtvTamanhoTipo.find({
                filter: {
                  where: {
                    active: true
                  },
                  order: 'designacao ASC'
                }
              }).$promise.then(tamanhosTipo => {
                this.tamanhosTipo = tamanhosTipo;
                this.AtvPartesCorpo.find({
                  filter: {
                    where: {
                      active: true
                    },
                    order: 'ordem ASC'
                  }
                }).$promise.then(partesCorpo => {
                  this.partesCorpo = partesCorpo;
                  // this.AtvAtivoPrefixo.find({
                  //   filter: {
                  //     where: {
                  //       active: true
                  //     },
                  //     order: 'ordem ASC'
                  //   }
                  // }).$promise.then(prefixos => {
                  //   this.prefixos = prefixos;
                    this.loadDataFromStorage();
                    this.loaded = true;
                  // }).catch(err => {
                  //   console.log(err);
                  //   this.UI.addToast("Erro ao carregar Prefixos de Ativos. Por favor tente mais tarde");
                  //   this.state.go('app.ativos.ativos.list');
                  // });
                }).catch(err => {
                  console.log(err);
                  this.UI.addToast("Erro ao carregar Partes do Corpo. Por favor tente mais tarde");
                  this.state.go('app.ativos.ativos.list');
                });
              }).catch(err => {
                console.log(err);
                this.UI.addToast("Erro ao carregar Tipos de Tamanho. Por favor tente mais tarde");
                this.state.go('app.ativos.ativos.list');
              });
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Erro ao carregar Estados de Compra. Por favor tente mais tarde");
              this.state.go('app.ativos.ativos.list');
            });
          }).catch(err => {
            console.log(err);
            this.UI.addToast("Erro ao carregar Marcas. Por favor tente mais tarde");
            this.state.go('app.ativos.ativos.list');
          });
        }).catch(err => {
          console.log(err);
          this.UI.addToast("Erro ao carregar Famílias. Por favor tente mais tarde");
          this.state.go('app.ativos.ativos.list');
        });
        // }).catch(err => {
        //   console.log(err);
        //   this.UI.addToast("Erro ao carregar Tipos de Ativo. Por favor tente mais tarde");
        //   this.state.go('app.ativos.ativos.list');
        // });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro ao carregar Tipos de Tamanhos. Por favor tente mais tarde");
        this.state.go('app.ativos.ativos.list');
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro ao carregar Entidades Proprietárias. Por favor tente mais tarde");
      this.state.go('app.ativos.ativos.list');
    });
  };

  // Infinite Scroll magic
  addMoreItems = (infiniteScroll) => {
    infiniteScroll.currentItems += infiniteScroll.numToAdd;
  };

  // For Marcas, update value selected
  refreshMarcas = (aux, search) => {
    if (search.length > 0) {
      let aux = this.marcas.find(m => m.designacao.toUpperCase() === search.toUpperCase());
      if (!aux)
        aux = {
          id: 0,
          designacao: search,
          active: 1
        };
      this.data.auxMarca.selected = aux;
    } else {
      // If we have a *new* one, erase it
      if (this.data.auxMarca.selected && this.data.auxMarca.selected.id === 0)
        this.data.auxMarca.selected = null;
    }
  };

  loadDataFromStorage = () => {
    if (localStorage.getItem('ATVnewAtivoForm')) {
      let storageData = JSON.parse(localStorage.getItem('ATVnewAtivoForm'));
      // Only use what is required
      this.data = {};
      if (storageData.form) this.data.form = storageData.form; else this.data.form = angular.copy(this.emptyData.form);
      if (storageData.details) this.data.details = storageData.details; else this.data.details = angular.copy(this.emptyData.details);
      if (storageData.usedImobilizado) this.data.usedImobilizado = storageData.usedImobilizado; else this.data.usedImobilizado = angular.copy(this.emptyData.usedImobilizado);
      if (storageData.usingImobilizado) this.data.usingImobilizado = storageData.usingImobilizado; else this.data.usingImobilizado = angular.copy(this.emptyData.usingImobilizado);
      // if (storageData.copyImobilizado) this.data.copyImobilizado = storageData.copyImobilizado; else this.data.copyImobilizado = angular.copy(this.emptyData.copyImobilizado);
      // if (storageData.auxTipoAtivo) this.data.auxTipoAtivo = storageData.auxTipoAtivo; else this.data.auxTipoAtivo = angular.copy(this.emptyData.auxTipoAtivo);
      if (storageData.auxMarca) this.data.auxMarca = storageData.auxMarca; else this.data.auxMarca = angular.copy(this.emptyData.auxMarca);
      if (storageData.auxEntidadeProprietaria) this.data.auxEntidadeProprietaria = storageData.auxEntidadeProprietaria; else this.data.auxEntidadeProprietaria = angular.copy(this.emptyData.auxEntidadeProprietaria);
      if (storageData.auxPropriedade) this.data.auxPropriedade = storageData.auxPropriedade; else this.data.auxPropriedade = angular.copy(this.emptyData.auxPropriedade);
      if (storageData.auxEstadoCompra) this.data.auxEstadoCompra = storageData.auxEstadoCompra; else this.data.auxEstadoCompra = angular.copy(this.emptyData.auxEstadoCompra);
      if (storageData.auxFornecedor) this.data.auxFornecedor = storageData.auxFornecedor; else this.data.auxFornecedor = angular.copy(this.emptyData.auxFornecedor);
      if (storageData.auxTamanhoTipo) this.data.auxTamanhoTipo = storageData.auxTamanhoTipo; else this.data.auxTamanhoTipo = angular.copy(this.emptyData.auxTamanhoTipo);
      if (storageData.auxTamanho) this.data.auxTamanho = storageData.auxTamanho; else this.data.auxTamanho = angular.copy(this.emptyData.auxTamanho);
      if (storageData.auxPartesCorpo) this.data.auxPartesCorpo = storageData.auxPartesCorpo; else this.data.auxPartesCorpo = angular.copy(this.emptyData.auxPartesCorpo);
      if (storageData.auxLocal) this.data.auxLocal = storageData.auxLocal; else this.data.auxLocal = angular.copy(this.emptyData.auxLocal);
      // if (storageData.auxPrefixo) this.data.auxPrefixo = storageData.auxPrefixo; else this.data.auxPrefixo = angular.copy(this.emptyData.auxPrefixo);
      if (storageData.auxFamilia) this.data.auxFamilia = storageData.auxFamilia; else this.data.auxFamilia = angular.copy(this.emptyData.auxFamilia);
      if (storageData.auxCalibracaoExterior) this.data.auxCalibracaoExterior = storageData.auxCalibracaoExterior; else this.data.auxCalibracaoExterior = angular.copy(this.emptyData.auxCalibracaoExterior);

      // If Entidade Proprietaria was selected, but no propriedade or local or fornecedor was defined
      if (this.data.auxEntidadeProprietaria.selected) {
        this.AtvPropriedade.find({
          filter: {
            where: {
              entidadeProprietariaId: this.data.auxEntidadeProprietaria.selected.id,
              active: 1
            },
            order: 'ordem ASC'
          }
        }).$promise.then((propriedades) => {
          this.propriedades = propriedades;
          this.AtvLocal.find({
            filter: {
              where: {
                entidadeProprietariaId: this.data.auxEntidadeProprietaria.selected.id,
                active: 1
              },
              order: 'designacao ASC'
            }
          }).$promise.then((locais) => {
            this.locais = locais;
            let PRIFornecedor = this.AtvAtivosService.getPrimaveraFornecedorForEntidadeProprietaria(this.data.auxEntidadeProprietaria.selected);
            PRIFornecedor.find({
              filter: {
                fields: {fornecedor: true, nome: true},
                where: {},
                order: 'nome ASC'
              }
            }).$promise.then(fornecedores => {
              this.fornecedores = fornecedores;
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Erro ao carregar Fornecedores do Primavera. Por favor tente mais tarde");
              this.state.go('app.ativos.ativos.list');
            });
          }).catch((error) => {
            this.locais = [];
            console.log(error);
            this.UI.addToast("Não foi possível carregar locais. Por favor recarregue a página");
          });
        }).catch((error) => {
          this.propriedades = [];
          console.log(error);
          this.UI.addToast("Não foi possível carregar propriedades. Por favor recarregue a página");
        });
      }

      // If Tamanho Tipo was selected, but no tamanho was defined
      if (this.data.auxTamanhoTipo.selected) {
        this.AtvTamanho.find({
          filter: {
            where: {
              tamanhoTipoId: this.data.auxTamanhoTipo.selected.id,
              active: 1
            },
            order: 'ordem ASC'
          }
        }).$promise.then((tamanhos) => {
          this.tamanhos = tamanhos;
        }).catch((error) => {
          this.tamanhos = [];
          console.log(error);
          this.UI.addToast("Não foi possível carregar tamanhos. Por favor recarregue a página");
        });
      }
    } else {
      this.data = angular.copy(this.emptyData);
    }
    this.state.transitionTo('app.ativos.ativos.new.' + this.data.form);
  };

  // When toggling Copy Imobilizado, always clean the prefixo ui-selectm
  // toggleCopyImobilizado = () => {
  //   this.data.auxPrefixo.selected = null;
  // };

  setForm = num => {
    this.data.form = num;
    this.state.transitionTo('app.ativos.ativos.new.' + this.data.form);
  };

  cleanForm = () => {
    // Clean filled data
    this.data = angular.copy(this.emptyData);
    // Clean choices for fields that would not be available if everything is clean
    this.propriedades = [];
    this.locais = [];
    this.fornecedores = [];
    this.state.transitionTo('app.ativos.ativos.new.' + this.data.form);
  };

  // When editing Imobilizado, update Centro Custos
  setImobilizado = () => {
    // Check if already been imported
    this.AtvAtivo.find({
      filter: {
        fields: {id: true, numImobilizado: true, active: true},
        where: {
          numImobilizado: this.data.details.numImobilizado,
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.data.usedImobilizado = res.length > 0;
      let PRIData = this.AtvAtivosService.getImobilizadoSource(this.data.details.numImobilizado);
      if (PRIData !== null) {
        PRIData.PRIFichaccusto.find({
          filter: {
            where: {
              bem: this.data.details.numImobilizado
            }
          }
        }).$promise.then((fichasccusto) => { // Imobilizado found
          // this.data.auxPrefixo.selected = null;
          this.data.usingImobilizado = true;
          // this.data.copyImobilizado = this.data.usingImobilizado && !this.data.usedImobilizado;
          if (fichasccusto && fichasccusto.length > 0) {
            let fichaccusto = fichasccusto[0];
            this.data.details.centroCustoCentro = fichaccusto.ccusto;
            this.data.details.centroCustoAno = fichaccusto.exercicio;
            PRIData.PRIPlanocentros.find({
              filter: {
                where: {
                  centro: fichaccusto.ccusto,
                  ano: fichaccusto.exercicio
                }
              }
            }).$promise.then((planoscentros) => {
              if (planoscentros && planoscentros.length > 0) {
                let planocentro = planoscentros[0];
                this.data.details.centroCustoDescricao = planocentro.descricao;
              } else {
                this.data.details.centroCustoDescricao = null;
              }
            }).catch((error) => {
              console.log(error);
              this.UI.addToast("Não foi possível obter dados do Primavera. Por favor tente mais tarde.");
            });
          } else { // Does not exist, wipe out CentroCusto information
            this.data.usingImobilizado = false;
            // this.data.copyImobilizado = this.data.usingImobilizado && !this.data.usedImobilizado;
            // this.data.auxPrefixo.selected = null;
            this.data.details.centroCustoCentro = null;
            this.data.details.centroCustoAno = null;
            this.data.details.centroCustoDescricao = null;
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível obter dados do Primavera. Por favor tente mais tarde.");
        });

        // Find designacao from Primavera
        PRIData.PRIFichas.find({
          filter: {
            where: {
              ficha: this.data.details.numImobilizado
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            let ficha = res[0];
            this.data.details.designacao = ficha.descricao;
          } else { // Does not exist, clean designacao
            // this.data.details.designacao = null;
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível obter dados do Primavera. Por favor tente mais tarde.");
        });
      } else {
        this.UI.addToast("Não foi possível verificar Imobilizado no Primavera. Por favor tente mais tarde.");
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível verificar a existência de Imobilizado no SGI. Por favor recarregue a página");
      this.data.usedImobilizado = false;
    });
  };

  onEntidadeProprietariaSelected = ($item) => {
    // EntidadeProprietaria is mandatory, so always goes to else
    if (!$item) {
      this.currentEntidadeProprietaria = null;
      this.propriedades = [];
      $scope.auxPropriedade.selected = null;
      this.fornecedores = [];
      $scope.auxFornecedor.selected = null;
    } else {
      if (!this.currentEntidadeProprietaria || (this.currentEntidadeProprietaria && this.currentEntidadeProprietaria.id !== $item.id)) {
        this.currentEntidadeProprietaria = $item;
        this.updatePropriedade($item.id);
        this.updateLocal($item.id);
        this.updateFornecedor($item); // We need the whole $item here, no mistake
      }
    }
  };

  updatePropriedade = (entidadeProprietariaId) => {
    if (this.data.auxPropriedade.selected)
      this.data.auxPropriedade.selected = null;
    this.AtvPropriedade.find({
      filter: {
        where: {
          entidadeProprietariaId: entidadeProprietariaId,
          active: 1
        },
        order: 'ordem ASC'
      }
    }).$promise.then((propriedades) => {
      this.propriedades = propriedades;
    }).catch((error) => {
      this.propriedades = [];
      console.log(error);
      this.UI.addToast("Não foi possível carregar áreas organizacionais. Por favor recarregue a página");
    });
  };

  updateLocal = (entidadeProprietariaId) => {
    if (this.data.auxLocal.selected)
      this.data.auxLocal.selected = null;
    this.AtvLocal.find({
      filter: {
        where: {
          entidadeProprietariaId: entidadeProprietariaId,
          active: 1
        },
        order: 'designacao ASC'
      }
    }).$promise.then((locais) => {
      this.locais = locais;
    }).catch((error) => {
      this.locais = [];
      console.log(error);
      this.UI.addToast("Não foi possível carregar locais. Por favor recarregue a página");
    });
  };

  updateFornecedor = (entidadeProprietaria) => {
    if (this.data.auxFornecedor.selected)
      this.data.auxFornecedor.selected = null;
    let PRIFornecedor = this.AtvAtivosService.getPrimaveraFornecedorForEntidadeProprietaria(entidadeProprietaria);
    PRIFornecedor.find({
      filter: {
        fields: {fornecedor: true, nome: true},
        where: {},
        order: 'nome ASC'
      }
    }).$promise.then(fornecedores => {
      this.fornecedores = fornecedores;
    }).catch((error) => {
      this.fornecedores = [];
      console.log(error);
      this.UI.addToast("Não foi possível carregar fornecedores. Por favor recarregue a página");
    });
  };

  onTamanhoTipoSelected = ($item) => {
    // Removed TamanhoTipo, wipe Tamanho
    if (!$item) {
      this.currentTamanhoTipo = null;
      this.tamanhos = [];
      this.data.auxTamanho.selected = null;
    } else { // Updating TamanhoTipo, clear Tamanho and select appropriate list of tamanhos
      if (!this.currentTamanhoTipo || (this.currentTamanhoTipo && this.currentTamanhoTipo.id !== $item.id)) {
        this.currentTamanhoTipo = $item;
        this.updateTamanho($item.id, true);
      }
    }
  };

  updateTamanho = (tamanhoTipoId) => {
    this.data.auxTamanho.selected = null;
    this.AtvTamanho.find({
      filter: {
        where: {
          tamanhoTipoId: tamanhoTipoId,
          active: 1
        },
        order: 'ordem ASC'
      }
    }).$promise.then((tamanhos) => {
      this.tamanhos = tamanhos;
    }).catch((error) => {
      this.tamanhos = [];
      console.log(error);
      this.UI.addToast("Não foi possível carregar tamanhos. Por favor recarregue a página");
    });
  };

  onDateUpdate = (dateType) => {
    let candidatesDataFabrico, candidatesDataCompra, candidatesDataFimGarantia, candidatesDataValidade,
      candidatesDataAbate;
    switch (dateType) {
      case 'dataCompra':
        candidatesDataFabrico = [this.data.details.dataCompra, this.data.details.dataFimGarantia, this.data.details.dataValidade, this.data.details.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
        if (candidatesDataFabrico && candidatesDataFabrico.length > 0) this.dateOptions.dataFabrico.maxDate = moment.min(candidatesDataFabrico); else this.dateOptions.dataFabrico.maxDate = false;
        candidatesDataAbate = [this.data.details.dataFabrico, this.data.details.dataCompra].filter(x => moment.isMoment(x) && x.isValid());
        if (candidatesDataAbate && candidatesDataAbate.length > 0) this.dateOptions.dataAbate.minDate = moment.max(candidatesDataAbate); else this.dateOptions.dataAbate.minDate = false;
        break;
      case 'dataFabrico':
        candidatesDataCompra = [this.data.details.dataFabrico, this.data.details.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
        if (candidatesDataCompra && candidatesDataCompra.length > 0) this.dateOptions.dataCompra.minDate = moment.max(candidatesDataCompra); else this.dateOptions.dataCompra.minDate = false;
        candidatesDataFimGarantia = [this.data.details.dataFabrico].filter(x => moment.isMoment(x) && x.isValid());
        if (candidatesDataFimGarantia && candidatesDataFimGarantia.length > 0) this.dateOptions.dataFimGarantia.minDate = moment.max(candidatesDataFimGarantia); else this.dateOptions.dataFimGarantia.minDate = false;
        candidatesDataValidade = [this.data.details.dataFabrico].filter(x => moment.isMoment(x) && x.isValid());
        if (candidatesDataValidade && candidatesDataValidade.length > 0) this.dateOptions.dataValidade.minDate = moment.max(candidatesDataValidade); else this.dateOptions.dataValidade.minDate = false;
        candidatesDataAbate = [this.data.details.dataFabrico, this.data.details.dataCompra].filter(x => moment.isMoment(x) && x.isValid());
        if (candidatesDataAbate && candidatesDataAbate.length > 0) this.dateOptions.dataAbate.minDate = moment.max(candidatesDataAbate); else this.dateOptions.dataAbate.minDate = false;
        break;
      case 'dataFimGarantia':
        candidatesDataFabrico = [this.data.details.dataCompra, this.data.details.dataFimGarantia, this.data.details.dataValidade, this.data.details.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
        if (candidatesDataFabrico && candidatesDataFabrico.length > 0) this.dateOptions.dataFabrico.maxDate = moment.min(candidatesDataFabrico); else this.dateOptions.dataFabrico.maxDate = false;
        break;
      case 'dataValidade':
        candidatesDataFabrico = [this.data.details.dataCompra, this.data.details.dataFimGarantia, this.data.details.dataValidade, this.data.details.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
        if (candidatesDataFabrico && candidatesDataFabrico.length > 0) this.dateOptions.dataFabrico.maxDate = moment.min(candidatesDataFabrico); else this.dateOptions.dataFabrico.maxDate = false;
        break;
      case 'dataAbate':
        candidatesDataFabrico = [this.data.details.dataCompra, this.data.details.dataFimGarantia, this.data.details.dataValidade, this.data.details.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
        if (candidatesDataFabrico && candidatesDataFabrico.length > 0) this.dateOptions.dataFabrico.maxDate = moment.min(candidatesDataFabrico); else this.dateOptions.dataFabrico.maxDate = false;
        candidatesDataCompra = [this.data.details.dataFabrico, this.data.details.dataAbate].filter(x => moment.isMoment(x) && x.isValid());
        if (candidatesDataCompra && candidatesDataCompra.length > 0) this.dateOptions.dataCompra.minDate = moment.max(candidatesDataCompra); else this.dateOptions.dataCompra.minDate = false;
        break;
    }
  };

  createMarcaFornecedorAtivo = () => {
    if (this.data.auxMarca && this.data.auxMarca.selected) {
      if (this.data.auxMarca.selected.id > 0) { // Using and existing marca, see if it's still there and use the id
        this.AtvMarca.find({
          filter: {
            where: {
              id: this.data.auxMarca.selected.id,
              active: 1
            }
          }
        }).$promise.then((marcas) => {
          if (marcas && marcas.length > 0) { // Criar com uma marca que já existe e está válida
            this.createFornecedorAtivo(marcas[0].id);
          } else { // Tentar criar com uma marca que já existiu, mas agora não é válida
            this.UI.addToast("Marca não existe em base de dados");
            this.loaded = true;
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível registar marca");
          this.loaded = true;
        });
      } else { // New Marca. Try to find it, if it does not exist, create it and use it
        this.AtvMarca.find({
          filter: {
            where: {
              designacao: this.data.auxMarca.selected.designacao,
              active: 1
            }
          }
        }).$promise.then((marcas) => {
          if (marcas && marcas.length > 0) { // Trying to create a new marca but one already exists of same name. Use it.
            this.createFornecedorAtivo(marcas[0].id);
          } else {
            this.AtvMarca.create(this.data.auxMarca.selected).$promise.then((m) => {
              this.UI.addToast("Marca inserida com sucesso");
              this.createFornecedorAtivo(m.id);
            }).catch((error) => {
              console.log(error);
              this.UI.addToast("Não foi possível criar marca para este ativo");
              this.loaded = true;
            });
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível criar marca para este ativo");
          this.loaded = true;
        });
      }
    } else {
      this.createFornecedorAtivo(null);
    }
  };

  createFornecedorAtivo = (marcaId) => {
    if (this.data.auxFornecedor && this.data.auxFornecedor.selected) {
      this.AtvFornecedorPrimavera.find({
        filter: {
          where: {
            refFornecedorPrimavera: this.data.auxFornecedor.selected.fornecedor,
            entidadeProprietariaId: this.data.entidadeProprietariaId,
            active: 1
          },
          include: {
            relation: 'fornecedor',
            scope: {
              where: {
                active: 1
              }
            }
          }
        }
      }).$promise.then((fornecedoresPrimavera) => {
        if (fornecedoresPrimavera && fornecedoresPrimavera.length > 0) { // FornecedorPrimavera already exists, use those ids
          this.createAtivo(marcaId, fornecedoresPrimavera[0].fornecedor.id, fornecedoresPrimavera[0].id);
        } else { // Fornecedor does not exist, find it again and import it.
          let PRIFornecedor = this.AtvAtivosService.getPrimaveraFornecedorForEntidadeProprietaria(this.data.auxEntidadeProprietaria.selected);
          PRIFornecedor.find({
            filter: {
              where: {
                fornecedor: this.data.auxFornecedor.selected.fornecedor
              }
            }
          }).$promise.then((res) => {
            if (res && res.length > 0) {
              let f = res[0];
              // Check if we have a fornecedor with the same NIF
              this.AtvFornecedor.find({
                filter: {
                  where: {
                    nif: f.numcontrib,
                    active: 1
                  }
                }
              }).$promise.then((fornecedorSGI) => {
                if (fornecedorSGI && fornecedorSGI.length > 0) { // Found AtvFornecedor, use it
                  this.AtvFornecedorPrimavera.create({
                    id: 0,
                    nome: f.nome,
                    fornecedorId: fornecedorSGI[0].id,
                    refFornecedorPrimavera: f.fornecedor,
                    entidadeProprietariaId: this.data.auxEntidadeProprietaria.selected.id,
                    contactoTelefonico: f.tel,
                    morada: f.morada,
                    cp4: f.cp ? f.cp.split("-")[0] : null,
                    cp3: f.cp ? f.cp.split("-")[1] : null,
                    cploc: f.cploc,
                    observacoes: f.notas,
                    atualizadoa: f.dataultimaactualizacao,
                    localidade: f.local,
                    pais: f.pais,
                    active: 1
                  }).$promise.then((newFornecedorPrimavera) => {
                    this.UI.addToast("Fornecedor importado com sucesso");
                    this.createAtivo(marcaId, fornecedorSGI[0].id, newFornecedorPrimavera.id);
                  }).catch((error) => {
                    console.log(error);
                    this.UI.addToast("Não foi possível criar novo fornecedor");
                    this.loaded = true;
                  });
                } else { // No AtvFornecedor was found for NIF, create AtvFornecedor and associate it with AtvFornecedorPrimavera
                  this.AtvFornecedor.create({
                    id: 0,
                    nome: f.nome,
                    nif: f.numcontrib,
                    observacoes: f.notas,
                    atualizadoa: moment().isDST() ? moment().add(1, 'h') : moment(),
                    active: 1
                  }).$promise.then((atvFornecedor) => {
                    this.AtvFornecedorPrimavera.create({
                      id: 0,
                      nome: f.nome,
                      fornecedorId: atvFornecedor.id,
                      refFornecedorPrimavera: f.fornecedor,
                      entidadeProprietariaId: this.data.auxEntidadeProprietaria.selected.id,
                      contactoTelefonico: f.tel,
                      morada: f.morada,
                      cp4: f.cp ? f.cp.split("-")[0] : null,
                      cp3: f.cp ? f.cp.split("-")[1] : null,
                      cploc: f.cploc,
                      observacoes: f.notas,
                      atualizadoa: f.dataultimaactualizacao,
                      localidade: f.local,
                      pais: f.pais,
                      active: 1
                    }).$promise.then((fornecedor) => {
                      this.UI.addToast("Fornecedor importado com sucesso");
                      this.createAtivo(marcaId, atvFornecedor.id, fornecedor.id);
                    }).catch((error) => {
                      console.log(error);
                      this.UI.addToast("Não foi possível importar fornecedorPrimavera.");
                      this.loaded = true;
                    });
                  }).catch((error) => {
                    console.log(error);
                    this.UI.addToast("Não foi possível criar fornecedor.");
                    this.loaded = true;
                  });
                }
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("Não foi possível pesquisar fornecedores. Por favor tente mais tarde");
                this.loaded = true;
              });
            } else {
              this.UI.addToast("Fornecedor não existe no Primavera");
              this.loaded = true;
            }
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não foi possível verificar existência de fornecedor. Por favor tente mais tarde.");
            this.loaded = true;
          });
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível verificar existência de fornecedor. Por favor tente mais tarde.");
        this.loaded = true;
      });
    } else {
      this.createAtivo(marcaId);
    }
  };

  // Do the checks to create Ativo (done in processCreateAtivo)
  createAtivo = (marcaId, fornecedorId, fornecedorPrimaveraId) => {
    // If numImobilizado is filled, check if we have it already
    if (this.data.details.numImobilizado) {
      this.AtvAtivo.find({
        filter: {
          fields: {id: true, numImobilizado: true, active: true},
          where: {
            numImobilizado: this.data.details.numImobilizado,
            active: 1
          }
        }
      }).$promise.then((res) => {
        if (res && res.length > 0) { // Already used NumImobilizado. Can't do this.
          this.UI.addToast("Já existe um ativo com esse nº imobilizado. Por favor verifique os dados");
        } else { // NumImobilizado not used. Proceed to ID creation.
          // if (this.data.copyImobilizado) {
          //   // Using imobilizado, null for prefixoId
          //   this.processCreateAtivo(marcaId, fornecedorId, fornecedorPrimaveraId, null, null);
          //
          //
          // } else { // Not using numImobilizado for ID, verify prefix exists and find latest
            this.addNewIndexAtivo(marcaId, fornecedorId, fornecedorPrimaveraId);
          // }
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível verificar existência de Nº Imobilizado. Por favor tente mais tarde");
        this.loaded = true;
      });
    } else { // Not using imobilizado
      this.addNewIndexAtivo(marcaId, fornecedorId, fornecedorPrimaveraId);
    }
  };

  addNewIndexAtivo = (marcaId, fornecedorId, fornecedorPrimaveraId) => {
    // this.AtvAtivoPrefixo.findOne({
    //   filter: {
    //     where: {
    //       id: this.data.auxPrefixo.selected.id,
    //       active: 1
    //     }
    //   }
    // }).$promise.then((prefixo) => {
    this.AtvFamilia.findOne({
      filter: {
        where: {
          id: this.data.auxFamilia.selected.id,
          active: 1
        }
      }
    }).$promise.then((familia) => {
      // Find highest index for that prefix
      this.AtvAtivo.find({
        filter: {
          where: {
            familiaId: familia.id,
            active: 1
          },
          order: 'iid_ativo DESC',
          limit: 1
        }
      }).$promise.then((ativoIndex) => {
        if (ativoIndex && ativoIndex.length > 0) {
          let index = parseInt(ativoIndex[0].iidAtivo) + 1;

          // add +1 to index
          this.processCreateAtivo(marcaId, fornecedorId, fornecedorPrimaveraId, familia, index);


        } else {

          // No ativo found, start with 1
          this.processCreateAtivo(marcaId, fornecedorId, fornecedorPrimaveraId, familia, 1);


        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível verificar índice para criação de ativo. Por favor tente mais tarde");
        this.loaded = true;
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar o prefixo do ativo. Por favor verifique os dados");
      this.loaded = true;
    });
  };

  // Actually creates ativo if all checks are done
  processCreateAtivo = (marcaId, fornecedorId, fornecedorPrimaveraId, familia, iidAtivo) => {
    let numImobilizado = (this.data.details.numImobilizado && this.data.details.numImobilizado.length > 0) ? this.data.details.numImobilizado.toUpperCase() : null;
    let atv = {
      id: 0,
      // ativoPrefixoId: ativoPrefixo ? ativoPrefixo.id : null,
      iidAtivo: iidAtivo,
      // iidDisplay: ativoPrefixo ? ativoPrefixo.prefixo + iidAtivo : numImobilizado,
      iidDisplay: `${familia.prefixo}${iidAtivo.toString().padStart(5, '0')}`,
      numImobilizado: numImobilizado,
      entidadeProprietariaId: this.data.auxEntidadeProprietaria.selected ? this.data.auxEntidadeProprietaria.selected.id : null,
      criadoporId: this.user.id,
      // tipoAtivoId: this.data.auxTipoAtivo.selected ? this.data.auxTipoAtivo.selected.id : null,
      designacao: this.data.details.designacao,
      referencia: this.data.details.referencia ? this.data.details.referencia : null,
      numSerie: this.data.details.numSerie ? this.data.details.numSerie : null,
      observacoes: this.data.details.observacoes ? this.data.details.observacoes : null,
      estadoCompraId: this.data.auxEstadoCompra.selected ? this.data.auxEstadoCompra.selected.id : null,
      marcaId: marcaId,
      modelo: this.data.details.modelo ? this.data.details.modelo : null,
      fornecedorId: fornecedorId,
      fornecedorPrimaveraId: fornecedorPrimaveraId,
      ativoBaseId: null,
      valorCompra: this.data.details.valorCompra ? this.data.details.valorCompra : null,
      especificacoesTecnicas: this.data.details.especificacoesTecnicas ? this.data.details.especificacoesTecnicas : null,
      dataCompra: this.data.details.dataCompra ? this.data.details.dataCompra : null,
      dataFabrico: this.data.details.dataFabrico ? this.data.details.dataFabrico : null,
      dataFimGarantia: this.data.details.dataFimGarantia ? this.data.details.dataFimGarantia : null,
      dataValidade: this.data.details.dataValidade ? this.data.details.dataValidade : null,
      dataAbate: this.data.details.dataAbate ? this.data.details.dataAbate : null,
      prazoValidade: this.data.details.prazoValidade ? this.data.details.prazoValidade : null,
      ultimaCalibracao: this.data.details.ultimaCalibracao ? this.data.details.ultimaCalibracao : null,
      ultimaManutencao: this.data.details.ultimaManutencao ? this.data.details.ultimaManutencao : null,
      ultimaVerificacao: this.data.details.ultimaVerificacao ? this.data.details.ultimaVerificacao : null,
      periodicidadeCalibracao: this.data.details.periodicidadeCalibracao ? this.data.details.periodicidadeCalibracao : null,
      periodicidadeManutencao: this.data.details.periodicidadeManutencao ? this.data.details.periodicidadeManutencao : null,
      periodicidadeVerificacao: this.data.details.periodicidadeVerificacao ? this.data.details.periodicidadeVerificacao : null,
      tamanhoId: this.data.auxTamanho.selected ? this.data.auxTamanho.selected.id : null,
      afetoaId: null,
      propriedadeId: this.data.auxPropriedade.selected ? this.data.auxPropriedade.selected.id : null,
      localId: this.data.auxLocal.selected ? this.data.auxLocal.selected.id : null,
      centroCustoCentro: this.data.details.centroCustoCentro ? this.data.details.centroCustoCentro : null,
      centroCustoAno: this.data.details.centroCustoAno ? this.data.details.centroCustoAno : null,
      centroCustoDescricao: this.data.details.centroCustoDescricao ? this.data.details.centroCustoDescricao : null,
      macAddress: this.data.details.macAddress ? this.data.details.macAddress : null,
      imei: this.data.details.imei ? this.data.details.imei : null,
      firmware: this.data.details.firmware ? this.data.details.firmware : null,
      licencaSoftware: this.data.details.licencaSoftware ? this.data.details.licencaSoftware : null,
      acessorios: this.data.details.acessorios ? this.data.details.acessorios : null,
      calibracaoExterior: this.data.auxCalibracaoExterior.selected ? this.data.auxCalibracaoExterior.selected.value : 2,
      familiaId: this.data.auxFamilia.selected ? this.data.auxFamilia.selected.id : null,
      active: 1
    };
    this.AtvAtivo.create(atv).$promise.then((atv) => {
      // Register Partes do Corpo after Ativo creation if necessary
      if (this.data.auxPartesCorpo && this.data.auxPartesCorpo.selected.length > 0) {
        let tasks = [];
        this.data.auxPartesCorpo.selected.forEach(r => {
          tasks.push(this.AtvAtivoPartesCorpo.create({
            id: 0,
            ativoId: atv.id,
            partesCorpoId: r.id,
            active: 1
          }).$promise);
        });
        Promise.all(tasks).then((r) => {
          localStorage.removeItem('ATVnewAtivoForm');
          this.state.go('app.ativos.ativos.list');
          this.UI.addToast('Ativo adicionado com sucesso');
        }).catch(err => {
          console.log(err);
          localStorage.removeItem('ATVnewAtivoForm');
          this.state.go('app.ativos.ativos.list');
          this.UI.addToast("Ativo adicionado. Não foi possível registar partes do corpo.");
        });
      } else {
        localStorage.removeItem('ATVnewAtivoForm');
        this.state.go('app.ativos.ativos.list');
        this.UI.addToast('Ativo adicionado com sucesso');
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível criar ativo. Por favor tente mais tarde");
      this.loaded = true;
    });
  };

  submit = () => {
    if (this.isValid()) {
      // Check if already using NumImobilizado before inserting
      if (this.data.details.numImobilizado && this.data.details.numImobilizado.length > 0) {
        this.AtvAtivo.find({
          filter: {
            fields: {id: true, numImobilizado: true, active: true},
            where: {
              numImobilizado: this.data.details.numImobilizado,
              active: 1
            }
          }
        }).$promise.then((res) => {
          if (res.length > 0) {
            this.UI.addToast("Imobilizado já foi inserido e não pode ser reutilizado. Por favor verifique os dados.");
          } else {
            this.loaded = false;
            this.createMarcaFornecedorAtivo();
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível verificar se Imobilizado existe. Por favor recarregue a página");
        });
      } else { // Not using numImobilizado, proceed
        this.loaded = false;
        this.createMarcaFornecedorAtivo();
      }
    } else {
      this.UI.addToast("Preencha todos os campos de carácter obrigatório do formulário");
    }
  };

  isValid = () => {
    // TODO Add test of integrity for data
    return true;
    // return this.data.details && this.data.details.nome && this.data.details.dataInscricao;
  }
}

AtvCreateAtivoController.$inject = ['AuthenticationService', 'UIService', 'AtvAtivosService', '$http', '$state', '$scope', 'AtvAtivo', 'AtvAtivoPartesCorpo', 'AtvPartesCorpo', 'AtvLocal', 'AtvFornecedor', 'AtvFornecedorPrimavera', 'AtvMarca', 'AtvTipoAtivo', 'AtvTamanhoTipo', 'AtvTamanho', 'AtvPropriedade', 'AtvEntidadeProprietaria', 'AtvEstadoCompra', 'AtvAtivoPrefixo', 'AtvFamilia'];
