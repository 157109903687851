export function routes($stateProvider) {
  $stateProvider
    .state('app.obler', {
      url: '/obler',
      abstract: true,
      template: '<div ui-view></div>'
    })

    .state('app.obler.calendario', {
      url: '/calendario?{funcionario:int}&{completed:int}&{open:int}',
      title: 'Calendarização',
      role: ['oblerVerCalendarizacao'],
      template: require('./calendarizacao/view.html'),
      controller: 'OblCalendarizacaoController',
      controllerAs: 'vm',
      params: {
        completed: {
          value: 1,
          dynamic: true,
        },
        open: {
          value: 1,
          dynamic: true,
        },
      }
    })
    .state('app.obler.clientes', {
      url: '/clientes',
      abstract: true
    })
    .state('app.obler.clientes.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Clientes',
      role: ['oblerVerClientes'],
      template: require('./clientes/list/view.html'),
      controller: 'OblClientesListController',
      controllerAs: 'vm',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.obler.clientes.details', {
      url: '/{id:int}',
      title: 'Detalhes de Cliente',
      template: require('./clientes/details/view.html'),
      role: ['oblerVerClientes'],
      controller: 'OblClientesDetailsController',
      controllerAs: 'vm',
    })
    .state('app.obler.processos', {
      url: '/folhas',
      abstract: true
    })
    .state('app.obler.processos.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Listagem de Folhas de Trabalho',
      role: ['oblerVerProcessos'],
      controller: 'OblProcessosListController',
      controllerAs: 'vm',
      template: require('./processos/list/view.html'),
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    });
}

routes.$inject = ['$stateProvider'];
