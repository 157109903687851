import React, { useEffect, useMemo, useState } from 'react';
import { Box, Breadcrumbs, Button, Fab, Link, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { MdiIcon, Table, WaitDialog } from '@components';
import { mdiPlus, mdiUpload } from '@mdi/js';
import { useModal } from 'mui-modal-provider';
import ExportDialog, { Choice } from './export';
const GasProcessosListView = ({ $state, GasService, UIService }) => {
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const { showModal } = useModal();
    const [data, setData] = useState();
    const [estados, setEstados] = useState([]);
    const [gas, setGas] = useState([]);
    const [instalacao, setInstalacao] = useState([]);
    const [inspecao, setInspecao] = useState([]);
    const [ord, setOrd] = useState([]);
    const [distribuidor, setDistribuidor] = useState([]);
    const [tecnicos, setTecnicos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [options, setOptions] = useState({
        filter: [],
        sort: []
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    });
    const [filter, setFilter] = useState([]);
    const [sort, setSort] = useState(() => loadParams());
    const importProcessos = () => {
        UIService.showDialog({
            template: require('./import/dialog.html'),
            controller: 'GasImporterController',
            controllerAs: 'vm'
        })
            .then(r => {
            UIService.addToast('Ficheiro importado com sucesso');
            fetchData();
        })
            .catch(e => {
            if (e === 'error') {
                UIService.addToast('Não foi possível importar ficheiro');
                fetchData();
            }
            else {
                fetchData();
            }
        });
    };
    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: '#',
            size: 100,
            Cell: ({ renderedCellValue, row }) => (<Link underline='none' color='default' sx={{ fontWeight: 'bold' }} target={'_blank'} href={$state.href('app.gas.processos.details', { id: row.original.id }, { absolute: true })}>
            {renderedCellValue}
          </Link>)
        },
        {
            accessorKey: 'numeroProcesso',
            header: 'Nº Processo',
            size: 100,
            Cell: ({ renderedCellValue, row }) => (<Link underline='none' color='default' sx={{ fontWeight: 'bold' }} target={'_blank'} href={$state.href('app.gas.processos.details', { id: row.original.id }, { absolute: true })}>
            {renderedCellValue}
          </Link>)
        },
        {
            accessorKey: 'numeroParceiro',
            header: 'Nº Parceiro',
            size: 100
        },
        {
            accessorKey: 'agendamento.data',
            header: 'Data de Inspeção',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (row.original.agendamento && row.original.agendamento.data && moment(row.original.agendamento.data).isValid() ? moment(row.original.agendamento.data).format('DD/MM/YYYY') : 'N/D')
        },
        {
            accessorKey: 'agendamento.hora',
            header: 'Hora',
            size: 50,
            Cell: ({ renderedCellValue, row }) => (row.original.agendamento && row.original.agendamento.hora ? row.original.agendamento.hora.substring(0, 5) : 'N/D')
        },
        {
            accessorKey: 'agendamento.duracao',
            header: 'Duração (min)',
            size: 80
        },
        {
            accessorKey: 'agendamento.tecnicoId',
            exportKey: 'agendamento.tecnico.name',
            header: 'Técnico',
            filterSelectOptions: tecnicos.map(r => {
                return {
                    label: r.name,
                    value: r.id
                };
            }),
            size: 140,
            Cell: ({ row }) => row.original?.agendamento?.tecnico?.name
        },
        {
            accessorKey: 'estadoId',
            exportKey: 'estado.designacao',
            header: 'Estado',
            filterSelectOptions: estados.map(r => {
                return {
                    label: r.designacao,
                    value: r.id
                };
            }),
            size: 140,
            Cell: ({ row }) => row.original?.estado?.designacao
        },
        {
            accessorKey: 'cui',
            header: 'CUI',
            size: 200
        },
        {
            accessorKey: 'tipoGasId',
            exportKey: 'tipoGas.designacao',
            header: 'Tipo de Gás',
            filterSelectOptions: gas.map(r => {
                return {
                    label: r.designacao,
                    value: r.id
                };
            }),
            size: 140,
            Cell: ({ row }) => row.original?.tipoGas?.designacao
        },
        {
            accessorKey: 'tipoInstalacaoId',
            exportKey: 'tipoInstalacao.designacao',
            header: 'Tipo de Instalação',
            filterSelectOptions: instalacao.map(r => {
                return {
                    label: r.designacao,
                    value: r.id
                };
            }),
            size: 140,
            Cell: ({ row }) => row.original?.tipoInstalacao?.designacao
        },
        {
            accessorKey: 'tipoInspecaoId',
            exportKey: 'tipoInspecao.designacao',
            header: 'Tipo de Inspeção',
            filterSelectOptions: inspecao.map(r => {
                return {
                    label: r.designacao,
                    value: r.id
                };
            }),
            size: 140,
            Cell: ({ renderedCellValue, row }) => row.original?.tipoInspecao?.designacao
        },
        {
            accessorKey: 'distribuidorId',
            exportKey: 'distribuidor.designacao',
            header: 'Distribuidor',
            filterSelectOptions: distribuidor.map(r => {
                return {
                    label: r.designacao,
                    value: r.id
                };
            }),
            size: 150,
            Cell: ({ renderedCellValue, row }) => row.original?.distribuidor?.designacao
        },
        {
            accessorKey: 'ordId',
            exportKey: 'ord.designacao',
            header: 'ORD',
            filterSelectOptions: ord.map(r => {
                return {
                    label: r.designacao,
                    value: r.id
                };
            }),
            size: 150,
            Cell: ({ renderedCellValue, row }) => row.original?.ord?.designacao
        },
        {
            accessorKey: 'agendamentoNaHora',
            header: 'Agendamento na Hora',
            size: 75,
            Cell: ({ renderedCellValue, row }) => (row.original.agendamentoNaHora ? 'Sim' : 'Não')
        },
        {
            accessorKey: 'dataImportacao',
            header: 'Data de Importação',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (moment(row.original.dataImportacao).isValid() ? moment(row.original.dataImportacao).format('DD/MM/YYYY') : 'N/D')
        }
    ], [gas, estados, instalacao, inspecao, ord, distribuidor, tecnicos]);
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    const initialFetch = async () => {
        if (loading) {
            setLoading(false);
            const opt = await GasService.optionsProcessos();
            setOptions(opt);
            // Técnicos
            const tec = await GasService.getTecnicosFuncionario();
            setTecnicos(tec);
            // Estado
            const estado = await GasService.getEstados();
            setEstados(estado);
            // Tipo de gás
            const tiposGas = await GasService.getTiposGas();
            setGas(tiposGas);
            // Tipo de instalação
            const tiposInst = await GasService.getTiposInstalacao();
            setInstalacao(tiposInst);
            // Tipo de inspecao
            const tiposInsp = await GasService.getTiposInspecao();
            setInspecao(tiposInsp);
            // ORD
            const ords = await GasService.getOrds();
            setOrd(ords);
            // Distribuidor
            const dist = await GasService.getDistribuidores();
            setDistribuidor(dist);
            console.log(opt);
        }
    };
    const fetchData = async () => {
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            const aux = await GasService.findProcessos({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            // Update URL
            console.log(aux);
            setData(aux);
        }
        catch (e) { }
        setRefetching(false);
    };
    const excelGen = async (id, array) => {
        const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
        let filename = `gas-processos-${id ? 'selecao' : 'exportacao'}-${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
        let filters = {};
        filter.forEach(r => {
            filters[r.id] = `${getType(r.type)}:${r.value}`;
        });
        if (id) {
            filters['id'] = `$in:${array.join(',')}`;
        }
        else {
            // Aplica filtro temporal
        }
        let sorter = [];
        sort.forEach(r => {
            sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
        });
        try {
            let o = await GasService.exportProcessos({
                columns: columns.map(r => {
                    return {
                        accessorKey: r.exportKey ?? r.accessorKey,
                        header: r.header
                    };
                }),
                filter: filters,
                sortBy: sorter
            });
            if (o) {
                // Decode Base64 and convert to a Blob
                const binary = atob(o.$data);
                const bytes = new Uint8Array(binary.length);
                for (let i = 0; i < binary.length; i++) {
                    bytes[i] = binary.charCodeAt(i);
                }
                // Create a Blob and save it as an Excel file
                const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }
        }
        catch (e) {
            if (e && e.data) {
                UIService.addToast(e.data.message);
            }
            else {
                UIService.addToast('Ocorreu um erro ao exportar dados!');
            }
        }
        wait.destroy();
    };
    const primaveraGen = async () => {
        const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
        let filename = `export-faturacao-${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
        let filters = {};
        filter.forEach(r => {
            filters[r.id] = `${getType(r.type)}:${r.value}`;
        });
        let sorter = [];
        sort.forEach(r => {
            sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
        });
        try {
            let o = await GasService.exportProcessosFatura({
                filter: filters,
                sortBy: sorter
            });
            if (o) {
                // Decode Base64 and convert to a Blob
                const binary = atob(o.$data);
                const bytes = new Uint8Array(binary.length);
                for (let i = 0; i < binary.length; i++) {
                    bytes[i] = binary.charCodeAt(i);
                }
                // Create a Blob and save it as an Excel file
                const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }
        }
        catch (e) {
            if (e && e.data) {
                UIService.addToast(e.data.message);
            }
            else {
                UIService.addToast('Ocorreu um erro ao exportar dados!');
            }
        }
        wait.destroy();
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, filter, sort]);
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>Listagem de processos</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Gás
          </Link>
          <Link underline='hover' color='text.primary' aria-current='page'>
            Processos
          </Link>
        </Breadcrumbs>
      </Box>

      <Box>
        <Table onDoubleClick={row => {
            window.open($state.href('app.gas.processos.details', { id: row.id }, { absolute: true }));
        }} storageKey={$state.current.name} data={data} columns={columns} initial={{
            sort: sort,
            filter: filter
        }} isLoading={refetching} options={options} onExport={async () => {
            showModal(ExportDialog, {
                maxWidth: 'sm',
                onClose: async (choice) => {
                    console.log(choice);
                    if (choice == Choice.PROCESSO) {
                        excelGen(false, []);
                    }
                    if (choice == Choice.FATURA) {
                        primaveraGen();
                    }
                }
            });
            //
        }} selectActions={selected => {
            return [
                <Button onClick={async () => {
                        if (selected.length > 0) {
                            excelGen(true, selected);
                        }
                    }}>
                Exportar
              </Button>
            ];
        }} selectable={row => {
            return true;
        }} onPaginate={setPagination} onFilter={setFilter} onSort={setSort}/>
      </Box>
      <Box sx={{
            position: 'fixed',
            margin: 2,
            bottom: 0,
            right: 0,
            display: 'flex',
            gap: 1
        }}>
        <Tooltip title='Adicionar'>
          <Fab color='success' onClick={() => {
            $state.go('app.gas.processos.add');
        }}>
            <MdiIcon path={mdiPlus}/>
          </Fab>
        </Tooltip>
        <Tooltip title={'Importação'}>
          <Fab color='error' onClick={async () => {
            importProcessos();
        }}>
            <MdiIcon path={mdiUpload}/>
          </Fab>
        </Tooltip>
      </Box>
    </Box>);
};
export default GasProcessosListView;
