import { routes } from './routes';

import FunProcessosListController from './list/controller';
import FunProcessosDetailsController from './details/controller';
import FunImporterController  from './list/import/controller';
import FunProcessoForm from './form'
import ProcessosList from './list'
import { useContext } from '@components';
import { react2angular } from 'react2angular';


export default angular.module('app.funciona.processos', [])
  .config(routes)
  .controller('FunProcessosListController', FunProcessosListController)
  .controller('FunProcessosDetailsController', FunProcessosDetailsController)
  .controller('FunImporterController', FunImporterController)
  .component("funProcessoForm", react2angular(useContext.use(FunProcessoForm), [], ["$state", "FuncionaService", "UIService"]))
  .component("processosList", react2angular(useContext.use(ProcessosList), [], ["$state", "FuncionaService", "UIService"]))
  .name;
