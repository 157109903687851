export function routes($stateProvider) {
  $stateProvider
    .state('app.crm.cotacoes', {
      url: '/cotacoes',
      abstract: true
    })
    .state('app.crm.cotacoes.details', {
      url: '/{id:int}',
      title: 'Detalhes da Cotação',
      template: '<crm-cotacoes-details-view></crm-cotacoes-details-view>',
      params: {
        page: {
          value: 1,
          dynamic: true
        },
        limit: {
          value: 20,
          dynamic: true
        },
        sortBy: {
          array: true,
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.crm.cotacoes.formEdit', {
      url: '/formEdit/{id:int}',
      title: 'Formulário pessoa',
      template: '<crm-cotacao-form></crm-cotac-form>',
      // role: ['gasVerClientes'],
    })
    .state('app.crm.cotacoes.formCreate', {
      url: '/formCreate',
      title: 'Formulário pessoa',
      template: '<crm-cotacao-form></crm-cotac-form>',
      // role: ['gasVerClientes'],
    });
}

routes.$inject = ['$stateProvider'];
