import angular from 'angular';
import tooltipHelper from './tooltipHelper';

export default angular.module('app.interface.tooltips', [tooltipHelper])
  .provider('$tooltip', function () {
    // The default options tooltip and popover.
    let defaultOptions = {
      placement: 'top',
      animation: true,
      popupDelay: 0,
      appendToBody: true
    };

    // The options specified to the provider globally.
    let globalOptions = {};

    this.options = function (value) {
      angular.extend(globalOptions, value);
    };

    function snake_case(name) {
      let regexp = /[A-Z]/g;
      let separator = '-';
      return name.replace(regexp, function (letter, pos) {
        return (pos ? separator : '') + letter.toLowerCase();
      });
    }
    this.$get = ['$window', '$compile', '$timeout', '$parse', '$document', '$position', function ($window, $compile, $timeout, $parse, $document, $position) {
      return function $tooltip(type, prefix, defaultTriggerShow, defaultTriggerHide) {
        let options = angular.extend({}, defaultOptions, globalOptions);
        let directiveName = snake_case(type);

        let template =
          '<' + directiveName + '-popup ' +
          'title="{{tt_title}}" ' +
          'content="{{tt_content}}" ' +
          'placement="{{tt_placement}}" ' +
          'animation="tt_animation()" ' +
          'is-open="tt_isOpen"' +
          '>' +
          '</' + directiveName + '-popup>';

        return {
          restrict: 'A',
          scope: true,
          link: function link(scope, element, attrs) {
            let tooltip = $compile(template)(scope);
            let transitionTimeout;
            let popupTimeout;
            let $body;

            attrs.$observe(type, function (val) {
              scope.tt_content = val;
            });
            attrs.$observe('tooltipPlacement', function (val) {
              scope.tt_placement = angular.isDefined(val) ? val : options.placement;
            });

            attrs.$observe('tooltipAnimation', function (val) {
              scope.tt_animation = angular.isDefined(val) ? $parse(val) : function () {
                return options.animation;
              };
            });

            attrs.$observe('popupDelay', function (val) {
              let delay = parseInt(val, 10);
              scope.tt_popupDelay = !isNaN(delay) ? delay : options.popupDelay;
            });

            // By default, the tooltip is not open.
            scope.tt_isOpen = false;

            //show the tooltip with delay if specified, otherwise show it immediately
            function showWithDelay() {
              if (scope.tt_popupDelay) {
                popupTimeout = $timeout(show, scope.tt_popupDelay);
              } else {
                scope.$apply(show);
              }
            }

            // Show the tooltip popup element.
            function show() {
              let position, ttPosition;

              // Don't show empty tooltips.
              if (!scope.tt_content) {
                return;
              }

              // If there is a pending remove transition, we must cancel it, lest the
              // tooltip be mysteriously removed.
              if (transitionTimeout) {
                $timeout.cancel(transitionTimeout);
              }

              // Set the initial positioning.
              tooltip.css({top: 0, left: 0, display: 'block'});

              // Now we add it to the DOM because need some info about it. But it's not
              // visible yet anyway.
              if (options.appendToBody) {
                $body = $body || $document.find('body');
                $body.append(tooltip);
              } else {
                element.after(tooltip);
              }

              // Get the position of the directive element.
              //position = getPosition( element );
              position = $position.position(element);
              if (options.appendToBody) {
                position.top = element.offset().top;
                position.left = element.offset().left;
              }

              // Get the height and width of the tooltip so we can center it.
              ttPosition = {
                width: tooltip.prop('offsetWidth'),
                height: tooltip.prop('offsetHeight')
              };

              // Calculate the tooltip's top and left coordinates to center it with
              // this directive.
              switch (scope.tt_placement) {
                case 'right':
                  ttPosition.top = (position.top + position.height / 2 - ttPosition.height / 2) + 'px';
                  ttPosition.left = (position.left + position.width) + 'px';
                  break;
                case 'bottom':
                  ttPosition.top = (position.top + position.height) + 'px';
                  ttPosition.left = (position.left + position.width / 2 - ttPosition.width / 2) + 'px';
                  break;
                case 'left':
                  ttPosition.top = (position.top + position.height / 2 - ttPosition.height / 2) + 'px';
                  ttPosition.left = (position.left - ttPosition.width) + 'px';
                  break;
                default:
                  ttPosition.top = (position.top - ttPosition.height) + 'px';
                  ttPosition.left = (position.left + position.width / 2 - ttPosition.width / 2) + 'px';
                  break;
              }

              // Now set the calculated positioning.
              tooltip.css({top: ttPosition.top, left: ttPosition.left});

              // And show the tooltip.
              scope.tt_isOpen = true;

              // If we change state, remove tooltip :)
              scope.$on('$stateChangeStart', () => {
                hide();
              });

              // If parent element is destroyed, remove tooltip :)
              element.on('$destroy', () => {
                hide();
              });
            }

            // Hide the tooltip popup element.
            function hide() {
              scope.tt_isOpen = false;

              $timeout.cancel(popupTimeout);
              if (angular.isDefined(scope.tt_animation) && scope.tt_animation()) {
                transitionTimeout = $timeout(function () {
                  tooltip.remove();
                }, 500);
              } else {
                tooltip.remove();
              }
            }

            if (!angular.isDefined(defaultTriggerHide)) {
              element.bind(defaultTriggerShow, function toggleTooltipBind() {
                if (!scope.tt_isOpen) {
                  showWithDelay();
                } else {
                  scope.$apply(hide);
                }
              });
            } else {
              element.bind(defaultTriggerShow, function showTooltipBind() {
                showWithDelay();
              });
              element.bind(defaultTriggerHide, function hideTooltipBind() {
                scope.$apply(hide);
              });
            }
          }
        };
      };
    }];
  })

  .directive('tooltipPopup', function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {content: '@', placement: '@', animation: '&', isOpen: '&'},
      template: require('./tooltip-popup.html')
    };
  })

  .directive('tooltip', ['$tooltip', function ($tooltip) {
    return $tooltip('tooltip', 'tooltip', 'mouseenter', 'mouseleave');
  }])

  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Tooltip',
      description: 'Display message on element mouse hover',
      version: '1.0.3'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])

  .name;
