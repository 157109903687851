import { Box, Breadcrumbs, Fab, Link, MenuItem, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Table } from '../../../../interface/components/table';
import { MdiIcon, WaitDialog } from '@components';
import { useModal } from 'mui-modal-provider';
import DialogRelatorio from './dialog';
import { mdiPlus } from '@mdi/js';
const ListRelatorios = ({ $state, ListaRelatorioService, UIService }) => {
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [options, setOptions] = useState({
        filter: [],
        sort: []
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    });
    const [filter, setFilter] = useState([]);
    const [sort, setSort] = useState(() => loadParams());
    const [departamentos, setDepartamentos] = useState([]);
    const { showModal } = useModal();
    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: '#',
            size: 50
        },
        {
            accessorKey: 'coreDepartamentoId',
            header: 'Departamento',
            size: 75,
            filterSelectOptions: departamentos.map(r => {
                return {
                    value: r.id,
                    label: r.designacao
                };
            }),
            Cell: ({ row }) => {
                return row.original.Departamento?.designacao;
            }
        },
        {
            accessorKey: 'coreListaNormaId',
            header: 'Procedimento',
            size: 120,
            Cell: ({ row }) => {
                return row.original.Norma?.referencia;
            }
        },
        {
            accessorKey: 'versao',
            header: 'Versão',
            size: 75
        },
        {
            accessorKey: 'dataPublicacao',
            header: 'Data de publicação',
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (value != undefined)
                    return moment(value).format('DD-MM-YYYY [às] HH:mm');
                return '-';
            }
        },
        {
            accessorKey: 'Funcionario.id',
            header: 'Publicado por',
            filterSelectOptions: [
                { label: 'António Gomes', value: 114 },
                { label: 'Tiago Ribeiro', value: 112 }
            ],
            Cell: ({ row }) => {
                return row.original.Funcionario?.name;
            }
        },
        {
            accessorKey: 'titulo',
            header: 'IG'
        },
        {
            accessorKey: 'subtitulo',
            header: 'Designação'
        }
    ], [departamentos]);
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    const onCellView = (cell) => {
        $state.go('app.administration.lists.view', { id: cell.id });
    };
    const initialFetch = async () => {
        if (loading) {
            const opt = await ListaRelatorioService.options();
            setOptions(opt);
            const dep = await ListaRelatorioService.findDepartamentos();
            setDepartamentos(dep);
            setLoading(false);
        }
    };
    const fetchData = async () => {
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            const aux = await ListaRelatorioService.findAll({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            // Update URL
            setData(aux);
        }
        catch (e) {
            console.log(e);
        }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, filter, sort]);
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>Listagem</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          {/* <Link underline='none' color='inherit'>
          Administração
        </Link> */}
          <Link underline='none' color='inherit'>
            Motor de relatórios
          </Link>
          <Link underline='hover' color='text.primary' aria-current='page'>
            Modelos de relatórios
          </Link>
        </Breadcrumbs>
      </Box>

      <Box>
        <Table onDoubleClick={row => {
            console.log(row);
            onCellView(row);
        }} storageKey={$state.current.name} data={data} columns={columns} initial={{
            sort: sort,
            filter: filter
        }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setFilter} onSort={setSort} cellActionItems={({ row, table }) => {
            return [
                <MenuItem key={`row.view.${row.id}`} onClick={() => onCellView(row.original)}>
                Visualizar
              </MenuItem>
            ];
        }}></Table>
      </Box>
      {!loading && (<Box sx={{
                position: 'fixed',
                margin: 2,
                bottom: 0,
                right: 0,
                display: 'flex',
                gap: 1
            }}>
          {!loading && (<Tooltip title={'Adicionar novo'}>
              <Fab color={'error'} onClick={async () => {
                    const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                    let normas = await ListaRelatorioService.findAvailableNormas();
                    wait.destroy();
                    if (normas.length > 0) {
                        showModal(DialogRelatorio, {
                            departamentos: departamentos,
                            normas: normas,
                            onClose: (data) => {
                                if (data) {
                                    ListaRelatorioService.create({
                                        id: 0,
                                        coreDepartamentoId: data.coreDepartamentoId,
                                        coreListaNormaId: data.coreListaNormaId,
                                        titulo: data.titulo,
                                        subtitulo: data.subtitulo,
                                        versao: 1,
                                        active: 1
                                    })
                                        .then(() => {
                                        UIService.addToast('Adicionado novo modelo de relatório');
                                        fetchData();
                                    })
                                        .catch(e => {
                                        UIService.addToast('Ocorreu um erro ao adicionar modelo de relatório');
                                    });
                                }
                            }
                        });
                    }
                    else {
                        UIService.addToast('Não existem procedimentos disponíveis para adicionar um novo modelo de relatório');
                    }
                }}>
                <MdiIcon path={mdiPlus}/>
              </Fab>
            </Tooltip>)}
        </Box>)}
    </Box>);
};
export default ListRelatorios;
