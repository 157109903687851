export default class AtvFornecedoresDetailsController {
  constructor(AtvFornecedor, AtvFornecedorPrimavera, AtvAtivo, AtvAtivosService, $state, UIService, AuthorizationService) {
    this.UI = UIService;
    this.state = $state;
    this.AuthorizationService = AuthorizationService;
    this.AtvFornecedor = AtvFornecedor;
    this.AtvFornecedorPrimavera = AtvFornecedorPrimavera;
    this.AtvAtivo = AtvAtivo;
    this.AtvAtivosService = AtvAtivosService;
    this.loaded = false;

    // Default opt state - populate later when we have all states
    this.optAtivos = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "id",
      sort: "desc",
      filter: {}
    };
    this.ativos = null;
    this.ativosLoading = true;

    this.id = $state.params.id;
    if (angular.isUndefined($state.params.id)) {
      $state.go('app.ativos.fornecedores.list');
      UIService.addToast('Não foi possível carregar fornecedores');
    }
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.AtvFornecedor.findOne({
      filter: {
        where: {
          active: true,
          id: this.id,
        },
        include: {
          relation: 'fornecedoresPrimavera',
          scope: {
            where: {
              active: 1
            },
            include: {
              relation: 'entidadeProprietaria',
              scope: {
                where: {
                  active: 1
                }
              }
            }
          }
        }
      }
    }).$promise.then(res => {
      if (res && res.fornecedoresPrimavera && res.fornecedoresPrimavera.length > 0) {
        res.fornecedoresPrimavera.forEach(f => {
          f.cPostal = f.cp4;
          if (f.cp3) f.cPostal += "-" + ("000" + f.cp3).slice(-3);
          if (f.cploc) f.cPostal += " " + f.cploc;
        });
      }

      this.data = res;
      this.loaded = true;
    });
    this.getAtivosForFornecedor();
  };

  editObservations = () => {
    let obs = angular.copy(this.data.observacoes);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = obs;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.loaded = false;
      this.data.observacoes = res;
      this.data.$save().then(() => {
        this.UI.addToast('Observações guardadas com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro a guardar as observações. Por favor tente mais tarde.');
        this.loadData();
      })
    })
  };

  resyncFornecedor = (refFornecedorPrimavera, entidadeProprietariaId) => {
    this.AtvFornecedorPrimavera.findOne({
      filter: {
        where: {
          refFornecedorPrimavera: refFornecedorPrimavera,
          entidadeProprietariaId: entidadeProprietariaId
        },
        include: {
          relation: 'entidadeProprietaria',
          scope: {
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((fp) => {
      if (!fp.refFornecedorPrimavera) {
        this.UI.addToast("Não foi possível atualizar fornecedor");
      } else {
        let PRIFornecedor = this.AtvAtivosService.getPrimaveraFornecedorForEntidadeProprietaria(fp.entidadeProprietaria);
        if (PRIFornecedor != null) {
          PRIFornecedor.find({
            filter: {
              where: {
                fornecedor: fp.refFornecedorPrimavera
              }
            }
          }).$promise.then((res) => {
            if (res && res.length > 0) {
              let f = res[0];
              fp.nome = f.nome;
              fp.nif = f.numcontrib;
              fp.contactoTelefonico = f.tel;
              fp.morada = f.morada;
              fp.cp4 = f.cp ? f.cp.split("-")[0] : null;
              fp.cp3 = f.cp ? f.cp.split("-")[1] : null;
              fp.cploc = f.cploc;
              fp.refFornecedorPrimavera = f.fornecedor;
              fp.observacoes = f.notas;
              fp.atualizadoa = f.dataultimaactualizacao;
              fp.localidade = f.local;
              fp.pais = f.pais;
              fp.$save().then((updatedFornecedor) => {
                if (this.data.nome !== updatedFornecedor.nome) {
                  let message = 'Deseja atualizar o nome do Fornecedor de "' + this.data.nome + '" para "' + updatedFornecedor.nome + '" ?';
                  let confirm = this.UI.showConfirm(message);
                  confirm.then(() => { // OK
                    this.data.nome = updatedFornecedor.nome;
                    this.data.$save().then((res) => {
                      this.loadData();
                      this.UI.addToast('Sincronização realizada com sucesso.');
                      this.UI.addToast('Nome de Fornecedor atualizado.');
                    }, (error) => {
                      console.log(error);
                      this.loadData();
                      this.UI.addToast('Sincronização realizada com sucesso.');
                      this.UI.addToast('Não foi possível atualizar o Nome do Fornecedor no SGI.');
                    });
                  }).catch(() => { // Cancel
                    this.loadData();
                    this.UI.addToast('Sincronização realizada com sucesso.');
                  });
                } else {
                  this.loadData();
                  this.UI.addToast('Sincronização realizada com sucesso.');
                }
              }).catch(err => {
                console.log(err);
                this.UI.addToast("Falha na gravação do fabricante. Por favor tente mais tarde.");
              });
            } else {
              this.UI.addToast("Não foi possível atualizar fornecedor do Primavera.");
            }
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não foi possível atualizar fornecedor do Primavera.");
          });
        } else {
          this.UI.addToast("Não foi possível atualizar fornecedor. Entidade Proprietária não encontrada");
        }
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar fornecedor.");
    });
  };

  getAtivosForFornecedor = () => {
    this.ativosLoading = true;
    let whereLiteral = {
      'AtvAtivo.fornecedorId': this.id,
      'AtvAtivo.active': 1
    };
    let where = {};
    Object.keys(this.optAtivos.filter).forEach(f => {
      if (this.optAtivos.filter[f])
        where[f] = this.optAtivos.filter[f];
    });

    this.AtvAtivosService.setWherePermFields({}, 'AtvAtivo.entidadeProprietariaId').then(w => {
      let wherePerms = w.wherePerms;
      this.AtvAtivo.table({
        params: {
          fields: [
            'AtvAtivo.id as id',
            'AtvAtivo.iidDisplay as iidDisplay',
            'AtvAtivo.designacao as designacao',
            'AtvAtivo.referencia as referencia',
            'AtvMarca.designacao as marca',
            'AtvFornecedorPrimavera.refFornecedorPrimavera as refFornecedorPrimavera',
            'AtvEntidadeProprietaria.designacao as entidadeProprietaria',
            'AtvEP2.sigla as sigla'
          ],
          from: ['AtvAtivo', 'AtvMarca', 'AtvFornecedorPrimavera', 'AtvEntidadeProprietaria', 'AtvEntidadeProprietaria'],
          references: [undefined, 'AtvAtivo.marcaId', 'AtvAtivo.fornecedorPrimaveraId', 'AtvAtivo.entidadeProprietariaId', 'AtvFornecedorPrimavera.entidadeProprietariaId'],
          aliases: [null, null, null, null, 'AtvEP2'],
          where: where,
          whereLiteral: whereLiteral,
          wherePerms: wherePerms,
          order: this.optAtivos.order,
          sort: this.optAtivos.sort,
          limit: this.optAtivos.items,
          skip: (this.optAtivos.page - 1) * this.optAtivos.items,
        }
      }).$promise.then(res => {
        let page = this.optAtivos.page;
        let items = this.optAtivos.items;

        let total = res.count;

        this.optAtivos.start = total > 0 ? (page - 1) * items + 1 : 0;
        if ((this.optAtivos.start - 1 + items) >= total) {
          this.optAtivos.end = total;
        } else {
          this.optAtivos.end = Number.parseInt(this.optAtivos.start - 1 + items);
        }

        this.ativos = res.ativos;
        this.optAtivos.total = total;
        this.ativosLoading = false;
      }).catch((e) => {
        console.log(e);
        this.UI.addToast("Não foi possível ler ativos. Por favor tente mais tarde");
      });
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler permissões. Por favor tente mais tarde");
    });
  };

  applyFilterAtivos = () => {
    this.optAtivos.page = 1;
    this.getAtivosForFornecedor();
  };

  ativosSort = keyname => {
    if (this.optAtivos.order === keyname)
      this.optAtivos.page = 1;
    this.optAtivos.order = keyname;
    this.optAtivos.sort = this.optAtivos.sort === 'asc' ? 'desc' : 'asc';
    this.getAtivosForFornecedor();
  };

  ativosItem = val => {
    this.optAtivos.items = val;
    this.optAtivos.page = 1;
    this.getAtivosForFornecedor();
  };

  ativosPage = sum => {
    this.optAtivos.page += sum;
    if (this.optAtivos.page < 1)
      this.optAtivos.page = 1;
    if (this.optAtivos.page > Math.ceil(this.optAtivos.total / this.optAtivos.items))
      this.optAtivos.page = Math.ceil(this.optAtivos.total / this.optAtivos.items);
    this.getAtivosForFornecedor();
  };

  viewAtivo = r => {
    this.state.go('app.ativos.ativos.details', {id: r.id});
  };
}
AtvFornecedoresDetailsController.$inject = ['AtvFornecedor', 'AtvFornecedorPrimavera', 'AtvAtivo', 'AtvAtivosService', '$state', 'UIService', 'AuthorizationService'];
