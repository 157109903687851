import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js';
import { Box, Stack } from '@mui/material';
import SimpleTable from './simpleTable';
const GraficoExemplo = ({ title, bgColor, borderColor, type, showTable }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [labels, setLabels] = useState([]);
    const [capacityValues, setCapacityValues] = useState([]);
    //let labels = [];
    //let capacityValues = [];
    // Função para gerar valores aleatórios
    const generateRandomData = (numPoints) => {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - numPoints + 1);
        for (let i = 0; i < numPoints; i++) {
            const date = new Date(startDate);
            date.setMonth(startDate.getMonth() + i);
            // Formatar a data para "YYYY-MM"
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
            setLabels(oldArray => [...oldArray, formattedDate]);
            setCapacityValues(oldArray => [...oldArray, Math.floor(Math.random() * 1000) + 50]);
        }
    };
    const createChart = () => {
        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                const plugin = {
                    id: 'customCanvasBackgroundColor',
                    beforeDraw: (chart, args, options) => {
                        const { ctx } = chart;
                        ctx.save();
                        ctx.globalCompositeOperation = 'destination-over';
                        ctx.fillStyle = options.color || '#99ffff';
                        ctx.fillRect(0, 0, chart.width, chart.height);
                        ctx.restore();
                    }
                };
                const dataChart = {
                    labels: labels,
                    datasets: [
                        {
                            label: title,
                            backgroundColor: bgColor,
                            borderColor: borderColor,
                            borderWidth: 2,
                            fill: false,
                            data: capacityValues,
                            type: type // 'bar', 'line'...
                        }
                    ]
                };
                chartInstanceRef.current = new Chart(ctx, {
                    type: type,
                    data: dataChart,
                    options: {
                        responsive: true,
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                boxWidth: 20,
                                padding: 15
                            }
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }
                            ]
                        },
                        plugins: {
                            customCanvasBackgroundColor: {
                                color: 'white'
                            }
                        }
                    },
                    plugins: [plugin]
                });
            }
        }
    };
    useEffect(() => {
        if (labels.length == 0 && capacityValues.length == 0) {
            generateRandomData(7);
        }
        createChart();
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [labels, capacityValues]);
    return (<Box>
      <Stack direction='column' spacing={2}>
        <Box sx={{
            borderRadius: '15px',
            border: '1px solid #e0e0e0',
            overflow: 'hidden',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            bgcolor: 'white'
        }}>
          <canvas ref={chartRef}/>
        </Box>
        {showTable ? <SimpleTable colunas={labels} dados={capacityValues}></SimpleTable> : <Box></Box>}
      </Stack>
    </Box>);
};
export default GraficoExemplo;
