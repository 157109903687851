export default class CreateNewsController {

  constructor($rootScope, $state, Noticia, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.title = $state.current.title;
    this.Noticia = Noticia;
    this.UI = UIService;

    this.n = {
      active: 0,
    };
    this.button = "Adicionar";
  }

  addNoticia = () => {
    this.n.id = 0;
    this.n.descCurta = this.n.descricao;
    this.n.data = new Date();

    this.Noticia.create(this.n).$promise.then(() => {
      console.log("adicionada");
      this.UI.addToast("Notícia inserida");
      this.$state.go('app.noticias.list');
    }).catch((err) => {
      console.log(err);
      this.UI.addToast("Erro ao inserir notícia. Verifique ligação");
    });
  };
}

CreateNewsController.$inject = ['$rootScope', '$state', 'Noticia', 'UIService'];
