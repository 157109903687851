import { routes } from './pad.routes'

import Processos from './processos';
import Pendentes from './pendentes';
import Fornecedores from './fornecedores';

import PadService from "./pad.service";

export default angular.module('app.pad', [Processos, Pendentes, Fornecedores])
  .config(routes)
  .service('PadService', PadService)
  .name;
