export default class FormacaoCursosCurrentEditionsController {
  constructor($rootScope, $state, $filter, AuthenticationService, NgTableParams, FormacaoEdicao, Group, Toast, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.FormacaoEdicao = FormacaoEdicao;
    this.UI = UIService;
    this.NgTableParams = NgTableParams;

    this.tableData = null;
    this.edicoesLoading = true;
    this.edicoes = null;
    this.getEdicoesAtuais();
  };

  // Get all available cursos
  getEdicoesAtuais = () => {
    this.FormacaoEdicao.find({
      filter: {
        where: {
          datafim: {gte: moment.utc().format('YYYY-MM-DD')},
          active: 1
        },
        include: [{
          relation: 'curso',
          where: {
            active: 1
          }
        }, {
          relation: 'estado'
        }, {
          relation: 'tipo',
          scope: {
            where: {
              active: 1
            }
          }
        }],
        order: 'datafim desc',
      }
    }).$promise.then((res) => {
      res.forEach(r => {
        r.edicaoLayout = `${r.no} / ${r.ano}`;
        r.tipoLayout = r.tipo ? r.tipo.designacao : '&mdash;';
        r.tipoLayout += r.online ? ' (Online)' : '';
      });
      this.edicoes = res;
      let NgTableParams = this.NgTableParams;
      this.tableData = new NgTableParams({
        sorting: 'edicaoNum desc'
      }, {
        dataset: res
      });
      this.edicoesLoading = false;
    }).catch((err) => {
      console.log(err);
      this.UI.addToast('Erro de leitura de edições. Por favor tente mais tarde.');
      this.edicoesLoading = false;
    });
  };
}

FormacaoCursosCurrentEditionsController.$inject = ['$rootScope', '$state', '$filter', 'AuthenticationService', 'NgTableParams', 'FormacaoEdicao', 'Group', 'Toast', 'UIService'];
