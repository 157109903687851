import angular from 'angular';
import { routes } from './routes';
import { react2angular } from "react2angular";
import GasOiListView from './list';
import GasOiDetailsView from "./details";
export default angular.module('app.gas.oi', [])
    .config(routes)
    .component("gasOiDetailsView", react2angular(GasOiDetailsView, [], ["$state", "GasService", "UIService"]))
    .component("gasOiListView", react2angular(GasOiListView, [], ["$state", "GasService"]))
    .name;
