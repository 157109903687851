import _ from 'lodash';
import moment from 'moment';

export default class DirectoryRequisitantesController {
  constructor($rootScope, $state, $sce, $filter,  NgTableParams, Requisitante, UIService, AuthorizationService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$sce = $sce;
    this.$filter = $filter;
    this.UI = UIService;
    this.Requisitante = Requisitante;
    this.NgTableParams = NgTableParams;
    this.AuthorizationService = AuthorizationService;

    this.search = "";
    this.clients = [];
    this.selected = 0;
    this.select = false;

    this.requisitantesLoad = true;

    this.getClients();
  };


  hasSelect = () => {
    return _.some(this.clients, function (r) {
      return r.selected == true;
    });
  };

  setDiscount = (r) => {
    let instance = this.UI.showDialog({
      template: require('./modal-set-discount.html'),
      controller: ['$scope', function ($scope) {

        $scope.desconto = r.desconto * 100;

        $scope.ok = function () {
          $scope.$close($scope.desconto);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((desconto) => {
      // Check permissions just in case
      if (this.AuthorizationService.canPerform('descontoIeEdit')) {
        r.desconto = desconto / 100;
        r.$save().then((res) => {
          this.UI.addToast("Desconto guardado com sucesso");
        }, (error) => {
          console.log(error);
          this.UI.addToast("Não foi possível alterar desconto para cliente. Por favor recarregue a página.");
        });
      } else {
        this.UI.addToast("Sem permissões para alterar desconto");
      }
    }).catch(() => {

    });
  };

  download = id => {
    this.requisitantesLoad = true;
    this.Requisitante.export({
      id: id
    }).$promise.then(result => {
      let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + result.fileData;
      let a = document.createElement('a');
      a.href = url;
      a.download = 'requisitante_' + id + '.xlsx';
      a.click();
      this.requisitantesLoad = false;
    });
  };

  massDownload = () => {
    this.requisitantesLoad = true;
    this.Requisitante.massExport({}).$promise.then(res => {
      let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
      let b = document.createElement('a');
      b.href = url;
      b.download = 'requisitantes.xlsx';
      b.click();
      this.requisitantesLoad = false;
    }).catch((err) => {
      console.log(err);
    });
  };

  hasDiscount = (r) => {
    if (r.desconto == 0) {
      if (moment(r.dataRegisto).isBefore('2018-01-01', 'year') && moment().isBefore('2019-01-01', 'year')) {
        r.descontoDescricao = '10% (Pré-registo)';
        return true;
      } else {
        return false;
      }
    } else {
      r.descontoDescricao = '' + (r.desconto * 100) + '%';
      return true;
    }
  };

  selectAll = () => {
    let status = this.select;
    this.selected = _.filter(this.clients, function (r) {
      return (r.selected = status);
    }).length;
  };

  selectItem = () => {
    this.selected = _.filter(this.clients, function (r) {
      return r.selected == true;
    }).length;
  };

  getClients = () => {
    this.Requisitante.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      res.forEach(function (r) {
        r.selected = false;
      });
      this.clients = res;
      let NgTableParams = this.NgTableParams;
      this.tableData = new NgTableParams({
        sorting: {
          id: 'desc'
        }
      }, {
        dataset: res,
      });
      this.requisitantesLoad = false;
    }).catch((err) => {
      //TODO
    });
  };
}

DirectoryRequisitantesController.$inject = ['$rootScope', '$state', '$sce', '$filter', 'NgTableParams', 'Requisitante', 'UIService', 'AuthorizationService'];
