import angular from 'angular';

import React, { FC } from 'react';
import { react2angular } from 'react2angular';

import pmdTabs from './directives/pmdTabs';
import pmdProgress from './directives/pmdProgress';
import pmdAccordion from './directives/pmdAccordion';

import { useContext } from '@components';

import { routes } from './interface.routes';
import LayoutController from './layout/layout.controller';
import UIService from './UI.service';
import pmdAvatar from './directives/pmdAvatar';
import pmdAlert from './directives/pmdAlert';
import pmdPickers from './directives/pmdPickers';
import pmdDialog from './directives/pmdDialog';
import pmdScrollbar from './directives/pmdScrollbar';
import pmdCalendar from './directives/pmdCalendar';
import DashboardIEService from './dashboard/dashboardIE.service';
import DashboardController from './dashboard/dashboard.controller';
import pmdPdfViewer from './directives/pmdPdf/pmdPdfViewer';
import pmdTypeahead from './directives/pmdTypeahead';
import pmdSelect from './directives/pmdSelect';
import pmdTooltip from './directives/pmdTooltip';
import pmdInfiniteScroll from './directives/pmdInfiniteScroll';
import pmdDropdown from './directives/pmdDropdown';
import DummyController from './dummy';
import DashboardFormacaoService from './dashboard/dashboardFor.service';
import DashboardPADService from './dashboard/dashboardPAD.service';

export default angular
  .module('app.interface', [
    pmdDropdown,
    pmdPickers,
    pmdTabs,
    pmdProgress,
    pmdAccordion,
    pmdAvatar,
    pmdAlert,
    pmdDialog,
    pmdScrollbar,
    pmdCalendar,
    pmdPdfViewer,
    pmdTypeahead,
    pmdSelect,
    pmdTooltip,
    pmdInfiniteScroll
  ])
  .config(routes)
  .service('UIService', UIService)
  .service('DashboardIEService', DashboardIEService)
  .service('DashboardFormacaoService', DashboardFormacaoService)
  .service('DashboardPADService', DashboardPADService)
  .controller('DummyController', DummyController)
  .controller('LayoutController', LayoutController)
  .controller('DashboardController', DashboardController)
  .directive('ngThumb', [
    '$window',
    function ($window) {
      var helper = {
        support: !!($window.FileReader && $window.CanvasRenderingContext2D),
        isFile: function (item) {
          return angular.isObject(item) && item instanceof $window.File;
        },
        isImage: function (file) {
          var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
          return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
        }
      };

      return {
        restrict: 'A',
        template: '<canvas/>',
        link: function (scope, element, attributes) {
          if (!helper.support) return;
          var params = scope.$eval(attributes.ngThumb);
          if (!helper.isFile(params.file)) return;
          if (!helper.isImage(params.file)) return;

          var canvas = element.find('canvas');
          var reader = new FileReader();

          reader.onload = onLoadFile;
          reader.readAsDataURL(params.file);

          function onLoadFile(event) {
            var img = new Image();
            img.onload = onLoadImage;
            img.src = event.target.result;
          }

          function onLoadImage() {
            var width = params.width || (this.width / this.height) * params.height;
            var height = params.height || (this.height / this.width) * params.width;
            canvas.attr({ width: width, height: height });
            canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
          }
        }
      };
    }
  ])
  .filter('dateDiff', () => {
    return (value, preholder) => {
      // Calculate diference from given date to now
      return moment(value).fromNow(preholder);
    };
  })
  .filter('skip', () => {
    return (input, start) => {
      // Limit exists, but skip doesn't...
      start = +start; // Parse to int :)
      return input.splice(start);
    };
  })
  .filter('nl2br', [
    '$sanitize',
    function ($sanitize) {
      var tag = /xhtml/i.test(document.doctype) ? '<br />' : '<br>';
      return function (msg) {
        // ngSanitize's linky filter changes \r and \n to &#10; and &#13; respectively
        msg = (msg + '').replace(/(\r\n|\n\r|\r|\n|&#10;&#13;|&#13;&#10;|&#10;|&#13;)/g, tag + '$1');
        return $sanitize(msg);
      };
    }
  ])
  .filter('secondsToTime', function () {
    function padTime(t) {
      return t < 10 ? '0' + t : t;
    }

    return function (_seconds) {
      if (typeof _seconds !== 'number' || _seconds < 0) return '00:00:00';

      var hours = Math.floor(_seconds / 3600),
        minutes = Math.floor((_seconds % 3600) / 60),
        seconds = Math.floor(_seconds % 60);

      return padTime(hours) + ':' + padTime(minutes) + ':' + padTime(seconds);
    };
  })
  .directive('maxlines', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elem, attrs, ngModel) {
        let maxLines = 1;
        attrs.$observe('maxlines', function (val) {
          maxLines = parseInt(val);
        });
        ngModel.$validators.maxlines = function (modelValue, viewValue) {
          let numLines = (modelValue || '').split('\n').length;
          return numLines <= maxLines;
        };
        attrs.$observe('maxlinesPreventEnter', function (preventEnter) {
          // if attribute value starts with 'f', treat as false. Everything else is true
          preventEnter = (preventEnter || '').toLocaleLowerCase().indexOf('f') !== 0;
          if (preventEnter) {
            addKeypress();
          } else {
            removeKeypress();
          }
        });

        function addKeypress() {
          elem.on('keypress', function (event) {
            // test if adding a newline would cause the validator to fail
            if (event.keyCode == 13 && !ngModel.$validators.maxlines(ngModel.$modelValue + '\n', ngModel.$viewValue + '\n')) {
              event.preventDefault();
            }
          });
        }

        function removeKeypress() {
          elem.off('.maxlines');
        }

        scope.$on('$destroy', removeKeypress);
      }
    };
  })
  .filter('timeHuman', () => {
    return number => {
      let num = Math.floor(number);
      let decimals = number - num;
      let readable = decimals.toFixed(1) * 60;
      return decimals === 0 ? num : `${num}:${readable}`;
    };
  })
  .filter('semantic', () => {
    return partsum => {
      let patch = partsum % 1000;
      partsum = Math.floor(partsum / 1000);
      let minor = partsum % 1000;
      partsum = Math.floor(partsum / 1000);
      let major = partsum % 1000;
      return `${major}.${minor}.${patch}`;
    };
  })
  .filter('moment', function () {
    return function (input, momentFn /*, param1, param2, ...param n */) {
      var args = Array.prototype.slice.call(arguments, 2),
        momentObj = moment.utc(input);
      return momentObj[momentFn].apply(momentObj, args);
    };
  })
  .filter('moment2', function () {
    return function (input, momentFn /*, param1, param2, ...param n */) {
      var args = Array.prototype.slice.call(arguments, 2),
        momentObj = moment(input);
      return momentObj[momentFn].apply(momentObj, args);
    };
  })
  .filter('groupBy', function ($parse) {
    return _.memoize(function (items, field) {
      var getter = $parse(field);
      return _.groupBy(items, function (item) {
        return getter(item);
      });
    });
  })
  .component('muiDialogProvider', react2angular(useContext.component, [])).name;
