import React, { useState } from 'react';
import { MdiIcon } from '@components';
import { mdiChevronDown, mdiClose, mdiChevronUp } from '@mdi/js';
import { Avatar, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemAvatar, ListItemText, styled, Typography } from '@mui/material';
import moment from 'moment';
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
}));
function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
}
function stringAvatar(name) {
    const nameParts = name.split(' ');
    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: nameParts.length > 1 ? `${nameParts[0][0]}${nameParts[1][0]}` : `${nameParts[0][0]}`
    };
}
const History = ({ lista, openHistory, onClose }) => {
    const [expandedItems, setExpandedItems] = useState({});
    const toggleExpand = (id) => {
        setExpandedItems(prev => ({
            ...prev,
            [id]: !prev[id] // Alterna entre expandido e recolhido
        }));
    };
    return (<Drawer PaperProps={{
            sx: { width: '400px' }
        }} variant='temporary' anchor='right' open={openHistory} onClose={onClose}>
      <DrawerHeader>
        <IconButton onClick={onClose}>
          <MdiIcon path={mdiClose} size={1}/>
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {lista.length > 0 &&
            lista.map(r => (<React.Fragment key={`drawer-li-${r.id}`}>
              <ListItem button onClick={() => toggleExpand(r.id)}>
                <ListItemAvatar>
                  <Avatar {...stringAvatar(r.user)}/>
                </ListItemAvatar>
                <ListItemText primary={r.estado} secondary={`${r.user}, ${moment(r.data).format('DD/MM/YYYY [às] HH:mm')}`}/>
                <IconButton>
                  <MdiIcon path={expandedItems[r.id] ? mdiChevronUp : mdiChevronDown} size={1}/>
                </IconButton>
              </ListItem>
              <Collapse in={expandedItems[r.id]} timeout='auto' unmountOnExit>
                <List sx={{ bgcolor: '#f9f9f9', borderRadius: 1, padding: 1, marginX: 2 }}>
                  {r.observacoes ? (r.observacoes.split('\n').map((obs, index) => (<React.Fragment key={index}>
                        <ListItem sx={{ paddingY: 0.5 }}>
                          <Typography variant='caption' color='textSecondary'>
                            {obs}
                          </Typography>
                        </ListItem>
                        {index < r.observacoes.split('\n').length - 1}
                      </React.Fragment>))) : (<ListItem>
                      <Typography variant='body2' color='textSecondary'>
                        Sem observações
                      </Typography>
                    </ListItem>)}
                </List>
              </Collapse>
            </React.Fragment>))}
      </List>
    </Drawer>);
};
export default History;
