import 'regenerator-runtime/runtime'

export default class OIService {
  constructor($q, ElvOrdemintervencao, ElvProcesso, UIService) {
    this.UI = UIService;
    this.ElvOrdemintervencao = ElvOrdemintervencao;
    this.ElvProcesso = ElvProcesso;
    this.$q = $q;
  }

  removeOi = (oi) => {
    let defer = this.$q.defer();
    this.ElvProcesso.find({
      filter: {
        where: {
          and: [{active: 1}, {oiId: oi.id}]
        }
      }
    }).$promise.then((procs) => {
      if (procs && procs.length > 0) { // Processos exist, can't remove
        let plural = procs.length === 1 ? "" : "s";
        let alert = this.UI.showAlert("Não é possível apagar Ordem de Intervenção: Há " + procs.length + " processo" + plural + " ativo" + plural + " para esta OI.");
        alert.finally(() => {
          defer.reject();
        });
      } else { // No processos ativos, can remove
        this.ElvOrdemintervencao.findOne({
          filter: {
            where: {
              id: oi.id
            }
          }
        }).$promise.then((oi) => {
          if (oi) {
            oi.active = 0;
            this.ElvOrdemintervencao.upsert(oi).$promise.then(o => {
              this.UI.addToast("OI removida com sucesso");
              defer.resolve(o);
            }).catch(error => {
              defer.reject(error);
            });
          } else {
            this.UI.addToast("Não foi possível encontrar a OI. Verifique se já foi apagada");
            defer.reject();
          }
        }).catch((error) => {
          this.UI.addToast("Não foi possível encontrar a OI. Verifique se já foi apagada");
          defer.reject(error);
        });
      }
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };


  async register(oi, processes) {
    let error = false;
    let defer = this.$q.defer();
    this.ElvOrdemintervencao.create(oi).$promise.then(async (res) => {
      for (let i = 0; i < processes.length; i++) {
        let p = processes[i];
        let a;
        if (p.codigo === 'INS.SELV') {
          a = 1;
        } else if (p.codigo === 'INS.AELV') {
          a = 2;
        } else if (p.codigo === 'INS.RELV') {
          a = 3;
        } else if (p.codigo === 'INS.IELV') {
          a = 4;
        } else if (p.codigo === 'INS.ATEV') {
          a = 5;
        } else {
          a = 6;
        }
        p.id = 0;
        p.oiId = res.id;
        p.active = true;
        p.emieId = null;
        p.c = p.codigo;
        p.condominioId = null;
        p.elevadorId = null;
        p.clienteId = oi.clienteId;
        p.numeroProcesso = 'ELV-PRC';
        p.dataProcesso = moment();
        p.artigoId = a;
        p.estadoId = 1; // Aguarda amostra
        p.quantidade = p.quantidade || 1;
        try {
          await this.createProcess(p);
        } catch (error) {
          this.UI.addToast("Ocorreu um erro na criação do processo. Por favor tente mais tarde.");
          error = true;
          break;
        }
      }
      if (!error)
        defer.resolve();
      else
        defer.reject();
    }).catch(err => {
      console.log(err);
      defer.reject(err);
    });
    return defer.promise;
  };

  async createProcess(p) {
    let error = false;
    let prom = new Promise(async (resolve, reject) => {
      for (let j = 0; j < p.quantidade; j++) {
        try {
          await this.ElvProcesso.create(p).$promise;
        } catch (error) {
          console.log(error);
          error = true;
        }
      }
      if (!error)
        resolve(p);
      else
        reject(p);
    });
    return prom;
  };
}

OIService.$inject = ['$q', 'ElvOrdemintervencao', 'ElvProcesso', 'UIService'];
