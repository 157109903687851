export function routes($stateProvider) {
  $stateProvider
    .state('app.crm.dashboard', {
      url: '/dashboard',
      abstract: true
    })
    .state('app.crm.dashboard.main', {
      url: '/main',
      title: 'Dashboard',
      template: '<crm-dashboard-view></crm-dashboard-view>',
    });
}

routes.$inject = ['$stateProvider'];