export default class DetalhesIntervencoesController {
  constructor($state, $q, $timeout, UIService, $filter, ElvElevador, ElvOrdemintervencao, ElvProcesso, ElvEquipamentoTipo, ElvEmie, ElvCondominio, ElvCliente, PRIClientes, $http) {
    this.id = $state.params.id;
    this.$q = $q;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$filter = $filter;
    this.$http = $http;
    this.UI = UIService;
    this.defaults = {};
    this.state = $state;
    this.tipo = null;
    this.condominio = null;
    this.emie = null;
    this.ElvOrdemintervencao = ElvOrdemintervencao;
    this.ElvProcesso = ElvProcesso;
    this.ElvEquipamentoTipo = ElvEquipamentoTipo;
    this.ElvElevador = ElvElevador;
    this.ElvEmie = ElvEmie;
    this.ElvCondominio = ElvCondominio;
    this.ElvCliente = ElvCliente;
    this.PRIClientes = PRIClientes;
    this.loaded = false;
    this.tipos = null;
    this.condominios = null;
    this.emies = null;
    this.getTipos();
    this.getCondominios();
    this.getEmie();
    this.loadData();
  }

  getTipos = () => {
    this.ElvEquipamentoTipo.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(tipos => {
      this.tipos = tipos;
      this.defaults.tipos = tipos;
      this.loaded = true;
    });
  };

  getCondominios = () => {
    this.ElvCondominio.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(condominios => {
      this.condominios = condominios;
      this.defaults.condominios = condominios;
      this.loaded = true;
    });
  };

  getEmie = () => {
    this.ElvEmie.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(emies => {
      this.emies = emies;
      this.defaults.emies = emies;
      this.loaded = true;
    });
  };

  loadData = () => {
    this.loaded = false;
    this.ElvOrdemintervencao.findOne({
      filter: {
        where: {
          id: this.id,
          active: true
        },
        include: [
          {
            relation: 'cliente',
            scope: {
              where: {
                active: true
              }
            }
          },
          {
            relation: 'origem',
            scope: {
              include: 'linhas'
            }
          },
          {
            relation: 'pedidos',
            scope: {
              where: {
                active: true
              },
              include:
                [{
                  relation: 'estado',
                  scope: {
                    where: {
                      active: true
                    }
                  }
                },
                  {
                    relation: 'artigo',
                    scope: {
                      where: {
                        active: true
                      }
                    }
                  },
                  {
                    relation: 'emie',
                    scope: {
                      where: {
                        active: true
                      }
                    }
                  },
                  {
                    relation: 'condominio',
                    scope: {
                      where: {
                        active: true
                      }
                    }
                  },
                  {
                    relation: 'elevadorPed',
                    scope: {
                      where: {
                        active: true
                      }
                    }
                  }
                ]
            }
          }
        ]
      }
    }).$promise.then(res => {
      this.data = res;
      this.loaded = true;
    }).catch(() => {
      this.UI.addToast('Erro ao carregar ordem de intervenção');
    });
  };

  autoFillProcessFromnumeroCamarario = () => {
    if (this.data.elevadorPed.numeroCamarario) {
      this.ElvElevador.find({
        filter: {
          where: {
            numeroCamarario: this.data.elevadorPed.numeroCamarario,
            active: true
          },
          include: {
            relation: 'tipo',
            scope: {
              where: {
                active: 1
              }
            }
          }
        }
      }).$promise.then(res => {
        if (res && res.length > 0) { // Found something
          this.data.elevadorPed.morada = res[0].morada;
          this.data.elevadorPed.cp4 = res[0].cp4;
          this.data.elevadorPed.cp3 = res[0].cp3;
          this.data.elevadorPed.localidade = res[0].localidade;
          this.data.elevadorPed.observacoes = res[0].observacoes;
        } else { // Found Nothing. Empty prefill
          this.data.elevadorPed.morada = null;
          this.data.elevadorPed.cp4 = null;
          this.data.elevadorPed.cp3 = null;
          this.data.elevadorPed.localidade = null;
          this.data.elevadorPed.observacoes = null;
        }
      }).catch((err) => { // Error occured. Empty prefill
        console.log(err);
        this.data.elevadorPed.morada = null;
        this.data.elevadorPed.cp4 = null;
        this.data.elevadorPed.cp3 = null;
        this.data.elevadorPed.localidade = null;
        this.data.elevadorPed.observacoes = null;
      });
    } else {
      this.data.elevadorPed.morada = null;
      this.data.elevadorPed.cp4 = null;
      this.data.elevadorPed.cp3 = null;
      this.data.elevadorPed.localidade = null;
      this.data.elevadorPed.observacoes = null;
    }
  };

  editDetails = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./edit-oi.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.observacoes = angular.copy(this.data.observacoes);

        $scope.ok = () => {
          $scope.$close($scope.observacoes);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        }
      }]
    }).then(res => {
      this.data.observacoes = res;
      this.data.$save().then(() => {
        this.loadData();
        this.UI.addToast('Ordem de Intervenção editada com sucesso!');
      }).catch(() => {
        this.UI.addToast('Erro ao editar Ordem de Intervenção!');
      });
    }).catch(() => {
    });
  };

  resyncClient = (id) => {
    this.ElvCliente.findOne({
      filter: {
        where: {
          id: id
        }
      }
    }).$promise.then((c) => {
      if (!c.refClientePrimavera) {
        this.UI.addToast("Não foi possível atualizar cliente.");
      } else {
        this.PRIClientes.find({
          filter: {
            where: {
              cliente: c.refClientePrimavera
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            c.nome = res[0].nome;
            c.nif = res[0].numcontrib;
            c.cp4 = res[0].facCp.split("-")[0];
            c.cp3 = res[0].facCp.split("-")[1];
            c.localidade = res[0].facCploc;
            c.telemovel = res[0].facTel;
            c.morada = res[0].facMor;
            c.observacoes = res[0].notas;
            c.atualizadoa = res[0].dataultimaactualizacao;
            c.refClientePrimavera = res[0].cliente;
            c.email = res[0].cduEmail;
            c.$save().then((updatedCliente) => {
              this.loadData();
              this.UI.addToast('Sincronização realizada com sucesso.');
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Falha na gravação do cliente. Por favor tente mais tarde.");
            });
          } else {
            this.UI.addToast("Não foi possível atualizar cliente.");
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar cliente.");
        });
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar cliente.");
    });
  };

  deleteProcess = r => {
    let result = this.UI.showConfirm('Deseja remover o processo?');
    result.then(res => {
      if (res) {
        r.active = false;
        this.ElvProcesso.upsert(r).$promise.then(() => {
          this.UI.addToast('Processo removido');
          this.loadData();
        }).catch(() => {
          this.UI.addToast('Erro ao remover processo');
        });
      }
    })
  };

  addProcess = () => {
    let self = this;
    let tips = this.tipos;
    let condomin = this.condominios;
    let ent = this.emies;
    let options = {
      size: 'lg',
      template: require('./add-processo.dialog.html'),
      controllerAs: 'm',
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.processo = {};
        $scope.newFileName = '';

        $scope.tipos = tips;
        $scope.tipo = {selected: null};
        $scope.condominios = condomin;
        $scope.condominio = {selected: null};
        $scope.emies = ent;
        $scope.emie = {selected: null};

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {
      this.ElvElevador.find({
        filter: {
          where: {
            numeroCamarario: ok.m.data.elevadorPed.numeroCamarario,
            active: true
          }
        }
      }).$promise.then(res => {
        let p = {
          id: 0,
          numeroCamarario: ok.m.data.elevadorPed.numeroCamarario,
          equipamentoId: ok.equipamento.selected.id,
          morada: ok.m.data.elevadorPed.morada,
          cp4: ok.m.data.elevadorPed.cp4,
          cp3: ok.m.data.elevadorPed.cp3,
          codigoPostal: `${ok.m.data.elevadorPed.cp4}-${ok.m.data.elevadorPed.cp3}`,
          localidade: ok.m.data.elevadorPed.localidade,
          observacoes: ok.m.data.observacoes,
          active: 1
        };
        if (res && res.length === 0) { // No EMIE found, we need to create an entry for it
          p.id = 0; // We are creating a new one, use id = 0 to force creation
        } else { // EMIE already exists, we should update the information on it
          p.id = res[0].id; // Use existing id so we only update information
        }
        this.ElvElevador.upsert(p).$promise.then((res) => {
          let t = {
            id: 0,
            dataProcesso: moment(),
            clienteId: this.data.cliente.id,
            emieId: ok.emie.selected.id,
            condominioId: ok.condominio.selected.id,
            elevadorId: res.id,
            numeroProcesso: res.numeroCamarario,
            oiId: this.id,
            estadoId: 1,
            inspetorId: null,
            criadoporId: null,
            observacoes: ok.m.data.observacoes,
            active: 1
          };
          this.ElvProcesso.create(t).$promise.then((emie) => {
            this.UI.addToast('Processo adicionado com sucesso!');
            this.loadData();
          });
        });
      }).catch(err => {
        console.log(err);
        this.UI.addToast('Não foi possível adicionar o processo!');
      });
    })
  };
}

DetalhesIntervencoesController.$inject = ['$state', '$q', '$timeout', 'UIService', '$filter', 'ElvElevador', 'ElvOrdemintervencao', 'ElvProcesso', 'ElvEquipamentoTipo', 'ElvEmie', 'ElvCondominio', 'ElvCliente', 'PRIClientes', '$http'];
