import angular from 'angular';
import { routes } from './routes';
import { react2angular } from 'react2angular';
// Relatórios
import ListRelatorios from './listas/list';
import ListaRelatorioService from './listas/service';
import EditRelatorios from './relatorios';
import DetailsRelatorios from './listas/details';
import DetailsRelatorioParametro from './listas/details/parametro';
import DetailsRelatorioEnsaio from './listas/details/ensaio';
// cláusulas
import ListClausulas from './clausulas/list';
import ListaClausulaService from './clausulas/service';
import DetailsClausulas from './clausulas/details';
import { useContext } from '@components';
import ListResultados from './resultados/list';
import ListaResultadoService from './resultados/service';
import DetailsRelatorioEquipamento from './listas/details/equipamento';
import ListNormas from './normas/list';
import ListaNormaService from './normas/service';
export default angular
    .module('app.administration.lists', [])
    .config(routes)
    .service('ListaRelatorioService', ListaRelatorioService)
    .service('ListaClausulaService', ListaClausulaService)
    .service('ListaResultadoService', ListaResultadoService)
    .service('ListaNormaService', ListaNormaService)
    // Cláusulas
    .component('listClausulas', react2angular(useContext.use(ListClausulas), [], ['$state', 'ListaClausulaService', 'UIService']))
    .component('detailsClausula', react2angular(DetailsClausulas, [], ['$state', 'ListaClausulaService']))
    // Resultados
    .component('listResultados', react2angular(useContext.use(ListResultados), [], ['$state', 'ListaResultadoService', 'UIService']))
    // Normas
    .component('listNormas', react2angular(useContext.use(ListNormas), [], ['$state', 'ListaNormaService', 'UIService']))
    // Relatórios
    .component('listRelatorio', react2angular(useContext.use(ListRelatorios), [], ['$state', 'ListaRelatorioService', 'UIService']))
    .component('detailsRelatorio', react2angular(useContext.use(DetailsRelatorios), [], ['$state', 'ListaRelatorioService', 'UIService']))
    .component('editRelatorio', react2angular(useContext.use(EditRelatorios), [], ['$state', 'ListaRelatorioService', 'UIService']))
    .component('detailsRelatorioParametro', react2angular(useContext.use(DetailsRelatorioParametro), [], ['$state', 'ListaRelatorioService', 'UIService']))
    .component('detailsRelatorioEquipamento', react2angular(useContext.use(DetailsRelatorioEquipamento), [], ['$state', 'ListaRelatorioService', 'UIService']))
    .component('detailsRelatorioEnsaio', react2angular(useContext.use(DetailsRelatorioEnsaio), [], ['$state', 'ListaRelatorioService', 'UIService'])).name;
