import {routes} from "./pedidos.routes";

import DirectoryPedidosIEController from "./directory/controller";
import DetailPedidosController from "./details/details.controller";
import CheckController from "./check/check.controller";
import Relatorios from "./report";

require('ui-select/dist/select.min.css');

export default angular.module('app.pedidos', [Relatorios])
  .config(routes)
  .controller('DirectoryPedidosIEController', DirectoryPedidosIEController)
  .controller('DetailPedidosController', DetailPedidosController)
  .controller('CheckController', CheckController)
  .name;
