function routing($urlRouterProvider, $locationProvider, LoopBackResourceProvider) {
  let url = window.location.host;
  if (url.indexOf(':3000') >= 0) {
    url.indexOf('http') >= 0 ? (url = url) : url= 'http://' + url;
    LoopBackResourceProvider.setUrlBase(url + '/api');
  } else {
    LoopBackResourceProvider.setUrlBase('/api');
    // Remove all console logs if we are in production
    console.log = function() {};
  }
  if ((navigator.userAgent.toLowerCase().indexOf('msie') !== -1)) {
    if(parseInt(navigator.userAgent.toLowerCase().split('msie')[1]) <=  9) {
      $locationProvider.html5Mode(false);
    }
  } else {
    $locationProvider.html5Mode(true);
  }



}

routing.$inject = ['$urlRouterProvider', '$locationProvider', 'LoopBackResourceProvider'];

export default routing;
