import angular from 'angular';
import { routes } from './routes';
import { react2angular } from "react2angular";
import GasDistribuidorDetailsView from './details';
import GasDistribuidoresListView from './list';
export default angular.module('app.gas.distribuidores', [])
    .config(routes)
    .component("gasDistribuidorDetailsView", react2angular(GasDistribuidorDetailsView, [], ["$state", "GasService", "UIService"]))
    .component("gasDistribuidoresListView", react2angular(GasDistribuidoresListView, [], ["$state", "GasService"]))
    .name;
