export default class ChangelogController {
  constructor($q, $http, $state, $rootScope, $filter, UIService, CoreAprovacaoVersao) {
    this.$q = $q;
    this.loaded = false;
    this.HTTP = $http;
    this.$filter = $filter;
    this.UI = UIService;
    this.CoreAprovacaoVersao = CoreAprovacaoVersao;

    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      $rootScope.status = data;
      let versions = data.version.split('.');
      this.currentVersionSgi = versions.map(x => x.padStart(3,'0')).join('');
      this.streamlineAppId = data.streamlineAppId;
      this.streamlineSgiId = data.streamlineSgiId;
      this.loadSgiAppData();
    });

    this.limitToValue = 10;
  }


  loadSgiAppData = () => {
    this.loaded = false;

    let deferSgi = this.loadDataSgi();
    let deferApp = this.loadDataApp();

    this.$q.all([deferSgi, deferApp]).then(() => {
      // Get aprovacao info for versions of everything we got
      this.CoreAprovacaoVersao.find({
        filter: {
          where: {
            and: [
              {active: 1},
              {or: [{appId: this.streamlineSgiId}, {appId: this.streamlineAppId}]}
            ]
          },
          include: "funcionario"
        }
      }).$promise.then((aprovacoes) => {
        // Match aprovacoes with versions SGI + APP
        this.versionsSgi.forEach(v => {
          v.aprovacao = aprovacoes.find(a => a.appId === v.appId && a.moduleId === v.moduleId && v.id === a.versioningId);
        });
        this.versionsApp.forEach(v => {
          v.aprovacao = aprovacoes.find(a => a.appId === v.appId && a.moduleId === v.moduleId && v.id === a.versioningId);
        });
        this.selectedTab = 'SGI';
        this.loaded = true;
      }).catch((error) => {
        this.loaded = true;
        console.log(error);
        this.UI.showAlert("Não foi possível obter informação de aprovação de versões. Verifique a ligação.");
      });
    }).catch(error => {
      console.log(error);
      this.loaded = true;
      this.UI.showAlert("Ocorreu um erro ao obter versões. Por favor verifique ligação.");
    });
  };


  loadDataSgi = () => {
    let defer = this.$q.defer();

    // Get modules first
    this.HTTP({
      method: 'GET',
      url: 'https://cc.streamline.pt/api/applications/findOne',
      params: {
        filter: {
          where:{
            id: this.streamlineSgiId
          },
          include:["modules", {
            relation: "versions",
            scope: {
              include: "module",
              order: "version DESC"
            }
          }]
        }
      }
    }).then(res => {
      let data = res.data;
      this.modulesSgi = data.modules;

      // Remove versions that are newer than current
      this.versionsSgi = data.versions.filter(v => v.version <= this.currentVersionSgi);

      this.modulesSgi.map(r => {
        r.latestVersion = this.versionsSgi.find(v => v.moduleId === r.id); // Find the first, it is the newest
      });
      this.modulesSgi = this.modulesSgi.filter(r => r.latestVersion !== undefined); // Remove modules without latest version

      this.versionSgi = this.versionsSgi[0]; // Version is the first one on the list to see

      // Attach each version to the correct module
      this.modulesSgi.forEach(m => {
        m.changelogsModule = this.versionsSgi.filter(v => v.moduleId === m.id);
      });

      // Instead of selectModuleSgi() filtering by module, show all versions to start with
      // this.selectModuleSgi(this.modulesSgi.find(m => m.id === this.versionSgi.moduleId));
      this.limitSgi = this.limitToValue;
      this.showAllSgi = false;
      this.filterSgi = "";
      this.filteredModuleSgiVersions = this.versionsSgi;

      defer.resolve();
    }).catch(error => {
      console.log(error);
      this.UI.showAlert("Erro na obtenção de changelogs do SGI. Por favor verifique ligação.");
      defer.reject();
    });
    return defer.promise;
  };

  loadDataApp = () => {
    let defer = this.$q.defer();

    // Get modules first
    this.HTTP({
      method: 'GET',
      url: 'https://cc.streamline.pt/api/applications/findOne',
      params: {
        filter: {
          where:{
            id: this.streamlineAppId
          },
          include:["modules", {
            relation: "versions",
            scope: {
              include: "module",
              order: "version DESC"
            }
          }]
        }
      }
    }).then(res => {
      let data = res.data;
      this.modulesApp = data.modules;

      // Do not remove unpublished versions, since we don't know how to get the latest
      this.versionsApp = data.versions;

      this.modulesApp.map(r => {
        r.latestVersion = this.versionsApp.find(v => v.moduleId === r.id); // Find the first, it is the newest
      });
      this.modulesApp = this.modulesApp.filter(r => r.latestVersion !== undefined); // Remove modules without latest version

      this.versionApp = this.versionsApp[0]; // Version is the first one on the list to see

      // Attach each version to the correct module
      this.modulesApp.forEach(m => {
        m.changelogsModule = this.versionsApp.filter(v => v.moduleId === m.id);
      });

      // Instead of selectModuleApp() filtering by module, show all versions to start with
      // this.selectModuleApp(this.modulesApp.find(m => m.id === this.versionApp.moduleId));
      this.limitApp = this.limitToValue;
      this.showAllApp = false;
      this.filterApp = "";
      this.filteredModuleAppVersions = this.versionsApp;

      defer.resolve();
    }).catch(error => {
      console.log(error);
      this.UI.showAlert("Erro na obtenção de changelogs da App. Por favor verifique ligação.");
      defer.reject();
    });
    return defer.promise;
  };

  selectModuleSgi = (module) => {
    this.moduleSgi = module;
    this.versionSgi = !!module.latestVersion ? module.latestVersion : undefined;
    this.limitSgi = this.limitToValue;
    this.showAllSgi = false;
    this.filterSgi = "";
    this.filteredModuleSgiVersions = module.changelogsModule;
  };

  isModuleSgiSelected = module => module.id === this.moduleSgi.id;
  isModuleAppSelected = module => module.id === this.moduleApp.id;

  selectModuleApp = (module) => {
    this.moduleApp = module;
    this.versionApp = !!module.latestVersion ? module.latestVersion : undefined;
    this.limitApp = this.limitToValue;
    this.showAllApp = false;
    this.filterApp = "";
    this.filteredModuleAppVersions = module.changelogsModule;
  };

  limitToSgi = () => {
    if (this.filterSgi.length === 0) {
      if (!this.showAllSgi) {
        this.limitSgi = undefined;
        this.showAllSgi = true;
      } else {
        this.limitSgi = this.limitToValue;
        this.showAllSgi = false;
      }
    }
  };

  limitToApp = () => {
    if (this.filterApp.length === 0) {
      if (!this.showAllApp) {
        this.limitApp = undefined;
        this.showAllApp = true;
      } else {
        this.limitApp = this.limitToValue;
        this.showAllApp = false;
      }
    }
  };

  // When clicking to select version
  selectVersionSgi = version => this.versionSgi = version;
  selectVersionApp = version => this.versionApp = version;

  // Check if version is selected
  isVersionSgiSelected = version => version.id === this.versionSgi.id;
  // Check if version is selected
  isVersionAppSelected = version => version.id === this.versionApp.id;

  // Return the mdi-* icon for module name
  getIconForModule = (moduleName) => {
    switch (moduleName) {
      case 'CORE':
        //CORE
        return 'mdi-chart-donut';
      case 'FOR':
        // FORMACAO
        return 'mdi-teach';
      case 'IE':
        // IE
        return 'mdi-power-plug';
      case 'LME':
      case 'LAB':
        // LME
        return 'mdi-ruler-square';
      // ELEVADORES
      case 'ELV':
        return 'mdi-arrow-up-down-bold-outline';
      // FINANCEIRO
      case 'FIN':
        return 'mdi-bank';
      // ATIVOS
      case 'ATV':
        return 'mdi-cube-outline';
      // PAD
      case 'PAD':
        return 'mdi-currency-eur';
      // Obler
      case 'OBL':
      case 'OBLER':
        return 'mdi-warehouse';
      // Obler
      case 'GAS':
        return 'mdi-fire';
      // Obler
      case 'FUN':
        return 'mdi-tools';
      default:
        return 'mdi-text-box-outline';
    }
  };

  // Returns string with decoded html that appears in added, improvements, bugfixes so it's "readable" and searchable
  decodeChanges = (text) => {
    let cleanText = text.replace(/<[^>]*>/g, '');
    let textArea = document.createElement('textarea');
    textArea.innerHTML = cleanText;
    cleanText = textArea.value.toLowerCase();
    textArea.remove();
    // return cleanText.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // If we want to remove accents
    return cleanText;
  };

  filteringSgi = () => {
    if (this.filterSgi.length === 0) {
      // this.filteredModuleSgiVersions = this.moduleSgi.changelogsModule;
      this.filteredModuleSgiVersions = this.versionsSgi;
      this.limitSgi = this.limitToValue;
      this.showAllSgi = false;
    } else {
      this.filteredModuleSgiVersions = this.versionsSgi.filter(v => {
        // Accept version number
        if (this.$filter('semantic')(v.version).includes(this.filterSgi)) return true;
        // Accept module name
        if (v.module && v.module.value) {
          if (v.module.value.toLowerCase().includes(this.filterSgi.toLowerCase())) return true;
        }
        if (v.bugfixes && v.bugfixes.length > 0) {
          if (this.decodeChanges(v.bugfixes).includes(this.filterSgi.toLowerCase())) return true;
        }
        if (v.added && v.added.length > 0) {
          if (this.decodeChanges(v.added).includes(this.filterSgi.toLowerCase())) return true;
        }
        if (v.improvements && v.improvements.length > 0) {
          if (this.decodeChanges(v.improvements).includes(this.filterSgi.toLowerCase())) return true;
        }
        return false;
      });
      this.limitSgi = undefined;
      this.showAllSgi = true;
    }
  };

  filteringApp = () => {
    if (this.filterApp.length === 0) {
      // this.filteredModuleAppVersions = this.moduleApp.changelogsModule;
      this.filteredModuleAppVersions = this.versionsApp;
      this.limitApp = this.limitToValue;
      this.showAllApp = false;
    } else {
      this.filteredModuleAppVersions = this.versionsApp.filter(v => {
        // Accept version number
        if (this.$filter('semantic')(v.version).includes(this.filterApp)) return true;
        // Accept module name
        if (v.module && v.module.value) {
          if (v.module.value.toLowerCase().includes(this.filterApp.toLowerCase())) return true;
        }
        if (v.bugfixes && v.bugfixes.length > 0) {
          if (this.decodeChanges(v.bugfixes).includes(this.filterApp.toLowerCase())) return true;
        }
        if (v.added && v.added.length > 0) {
          if (this.decodeChanges(v.added).includes(this.filterApp.toLowerCase())) return true;
        }
        if (v.improvements && v.improvements.length > 0) {
          if (this.decodeChanges(v.improvements).includes(this.filterApp.toLowerCase())) return true;
        }
        return false;
      });
      this.limitApp = undefined;
      this.showAllApp = true;
    }
  };

  switchTab = (tab) => {
    switch(tab) {
      case 'SGI': this.selectedTab = 'SGI'; break;
      case 'APP': this.selectedTab = 'APP'; break;
    }
  };
}

ChangelogController.$inject = ['$q', '$http', '$state', '$rootScope', '$filter', 'UIService', 'CoreAprovacaoVersao'];
