import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { useFormik } from 'formik';
export function DialogAssinatura({ initial, ListaRelatorioService, onClose, ...props }) {
    const [files, setFiles] = useState(null);
    const [loading, setLoading] = useState(true);
    const paramSchema = yup.object({
        file: yup.string().required('O campo é obrigatório')
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: paramSchema,
        validateOnMount: false,
        onSubmit: async (d) => {
            // Carrega ficheiro se não é dummy (isto é, se não é o ficheiro inicial)
            if (files.dummy == false) {
                // Upload file
                await ListaRelatorioService.uploadTemplate('Upload', 'photos', files);
            }
            else {
                // O dropzone faz weird shits, por isso se dummy, garante-se o nome do ficheiro...
                d.file = initial;
            }
            onClose(d.file);
        }
    });
    const prep = async (initial) => {
        if (initial) {
            // Load file
            let f = new File([], initial, { type: ListaRelatorioService.getType(initial) });
            f.dummy = true;
            setFiles(f);
            formik.setFieldValue('file', initial);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };
    useEffect(() => {
        formik.resetForm();
        prep(initial);
    }, [initial]);
    return (<Dialog {...props} maxWidth={'lg'} fullWidth>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DropzoneArea initialFiles={[files]} filesLimit={1} dropzoneText='Arraste um ficheiro para esta janela ou clique para adicionar' onChange={files => {
            if (files.length > 0) {
                let t = files.pop();
                let f = new File([t], `${v4()}.${t.extension()}`, { type: t.type });
                f.dummy = t.dummy || false;
                formik.setFieldValue('file', f.name);
                setFiles(f);
            }
            else {
                formik.setFieldValue('file', undefined);
                setFiles(null);
            }
        }} onDelete={_ => {
            formik.setFieldValue('file', undefined);
            setFiles(null);
        }} acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']} showFileNames={false} showAlerts={false} showPreviewsInDropzone={true} useChipsForPreview={true} previewChipProps={{
            color: 'default',
            variant: 'filled'
        }}/>
      </DialogContent>
      <DialogActions>
        <Button type='button' onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button variant='contained' disabled={!formik.isValid} onClick={() => {
            formik.submitForm();
        }}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>);
}
