import { Box, Breadcrumbs, Link, MenuItem, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Table, WaitDialog } from '../../../interface/components';
import { useModal } from 'mui-modal-provider';
const ListPecasPendentes = ({ $state, OblerPendentesService, UIService }) => {
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const { showModal } = useModal();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [tecnicos, setTecnicos] = useState([]);
    const [options, setOptions] = useState({
        filter: [],
        sort: []
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    });
    const [filter, setFilter] = useState([]);
    const [sort, setSort] = useState(() => loadParams());
    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: '#',
            size: 50
        },
        {
            accessorKey: 'Processo.Ordemintervencao.numeroProcesso',
            header: 'Processo',
            size: 160
        },
        {
            accessorKey: 'Processo.Ordemintervencao.parceiro',
            header: 'Parceiro',
            size: 160
        },
        {
            accessorKey: 'compra',
            header: 'Cód. Compra',
            size: 160
        },
        {
            accessorKey: 'codigo',
            header: 'Cod. Artigo'
        },
        {
            accessorKey: 'designacao',
            header: 'Designação'
        },
        {
            accessorKey: 'fornecedor',
            header: 'Fornecedor'
        },
        {
            accessorKey: 'quantidade',
            header: 'Quantidade'
        },
        {
            accessorKey: 'prazo',
            header: 'Prazo (dias)',
            Cell: ({ cell, row }) => {
                const value = cell.getValue();
                if (value != undefined) {
                    const isDone = row.original.rececionado == 1 || row.original.rececionadoPor != null;
                    if (isDone) {
                        return (<Tooltip title={`Já rececionado (prazo ${value} dias)`}>
                  <Box>&mdash;</Box>
                </Tooltip>);
                    }
                    return value;
                }
                return (<Tooltip title={`Sem prazo estabelecido`}>
              <Box>&mdash;</Box>
            </Tooltip>);
            }
        },
        {
            accessorKey: 'foraPrazo',
            header: 'Fora de Prazo?',
            Cell: ({ row }) => {
                if (row.original.foraPrazo == null) {
                    return (<Tooltip title={`Sem informação de prazo definido`}>
                <Box>&mdash;</Box>
              </Tooltip>);
                }
                if (row.original.rececionado == 1 || row.original.rececionadoPor != null)
                    return (<Tooltip title={`Rececionado ${row.original.foraPrazo ? 'fora de prazo' : 'dentro de prazo'}`}>
                <Box>&mdash;</Box>
              </Tooltip>);
                return row.original.foraPrazo == 1 ? 'Fora de prazo' : 'Dentro de prazo';
            }
        },
        {
            accessorKey: 'preco',
            header: 'Preço (€)',
            type: ''
        },
        {
            accessorKey: 'dataRequisicao',
            header: 'Data de requisição',
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (value != undefined)
                    return moment(value).format('DD-MM-YYYY [às] HH:mm');
                return '-';
            }
        },
        {
            accessorKey: 'requisitanteId',
            exportKey: 'Requisitante.name',
            header: 'Requisitante',
            filterSelectOptions: tecnicos.map(r => {
                return {
                    label: r.name,
                    value: r.id
                };
            }),
            Cell: ({ row }) => {
                return row.original.Requisitante?.name;
            }
        },
        {
            accessorKey: 'rececionado',
            header: 'Rececionado?',
            Cell: ({ row }) => {
                return row.original.rececionado == 1 ? 'Sim' : 'Não';
            }
        },
        {
            accessorKey: 'dataRececao',
            header: 'Data de receção',
            Cell: ({ cell }) => {
                const value = cell.getValue();
                if (value != undefined)
                    return moment(value).format('DD-MM-YYYY [às] HH:mm');
                return '-';
            }
        },
        {
            accessorKey: 'rececionadoId',
            exportKey: 'RececionadoPor.name',
            header: 'Rececionado Por',
            filterSelectOptions: tecnicos.map(r => {
                return {
                    label: r.name,
                    value: r.id
                };
            }),
            Cell: ({ row }) => {
                return row.original.RececionadoPor?.name;
            }
        }
    ], [tecnicos]);
    const validate = async (row) => {
        UIService.showConfirm(`Confirma receção da peça ${row.designacao} referente ao processo ${row.Processo.Ordemintervencao.numeroProcesso}? Esta ação não pode ser anulada!`).then(r => {
            try {
                OblerPendentesService.validate(row.id);
            }
            catch (e) {
                console.log();
            }
            setRefetching(true);
            fetchData();
        });
    };
    const excelGen = async (id, array) => {
        const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
        let filename = `obler-pecas-${id ? 'selecao' : 'exportacao'}-${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
        let filters = {};
        filter.forEach(r => {
            filters[r.id] = `${getType(r.type)}:${r.value}`;
        });
        if (id) {
            filters['id'] = `$in:${array.join(',')}`;
        }
        else {
            // Aplica filtro temporal
        }
        let sorter = [];
        sort.forEach(r => {
            sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
        });
        try {
            let o = await OblerPendentesService.export({
                columns: columns.map(r => {
                    return {
                        accessorKey: r.exportKey ?? r.accessorKey,
                        header: r.header
                    };
                }),
                filter: filters,
                sortBy: sorter
            });
            if (o) {
                // Decode Base64 and convert to a Blob
                const binary = atob(o.$data);
                const bytes = new Uint8Array(binary.length);
                for (let i = 0; i < binary.length; i++) {
                    bytes[i] = binary.charCodeAt(i);
                }
                // Create a Blob and save it as an Excel file
                const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }
        }
        catch (e) {
            if (e && e.data) {
                UIService.addToast(e.data.message);
            }
            else {
                UIService.addToast('Ocorreu um erro ao exportar dados!');
            }
        }
        wait.destroy();
    };
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    const initialFetch = async () => {
        if (loading) {
            const list = await OblerPendentesService.findAllTecnicos();
            const opt = await OblerPendentesService.options();
            setOptions(opt);
            setTecnicos(list);
            setLoading(false);
        }
    };
    const fetchData = async () => {
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            const aux = await OblerPendentesService.findAll({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            // Update URL
            setData(aux);
        }
        catch (e) { }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, filter, sort]);
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>Listagem</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Obler
          </Link>
          <Link underline='hover' color='text.primary' aria-current='page'>
            Peças pendentes
          </Link>
        </Breadcrumbs>
      </Box>

      <Box>
        <Table onDoubleClick={row => {
            $state.go('app.obler.orcamentos.details', {
                id: row.processoId
            });
        }} storageKey={$state.current.name} data={data} withRowStyle={row => {
            if (row.rececionado == 1 || row.rececionadoId != null || row.prazo == null) {
                return {};
            }
            return row.foraPrazo
                ? {
                    sx: {
                        backgroundColor: '#B00020',
                        '& td': {
                            color: '#FFF'
                        }
                    }
                }
                : {};
        }} columns={columns} initial={{
            sort: sort,
            filter: filter
        }} cellActionItems={({ row }) => {
            const items = [];
            if (row.original.rececionadoId == null) {
                items.push(<MenuItem key={`row.edit.${row.id}`} onClick={() => validate(row.original)}>
                  Validar
                </MenuItem>);
            }
            items.push(<MenuItem key={`row.edit.${row.id}`} onClick={() => $state.go('app.obler.orcamentos.details', { id: row.processoId })}>
                Ir para processo
              </MenuItem>);
            return items;
        }} onExport={async () => {
            excelGen(false, []);
        }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setFilter} onSort={setSort}/>
      </Box>
    </Box>);
};
export default ListPecasPendentes;
