import { mdiDotsHorizontal, mdiDotsVertical } from '@mdi/js';
import { Card, CardActions, CardContent, CardHeader, IconButton, ImageList, ImageListItem, List, ListItem, ListItemAvatar, ListItemText, Pagination, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MdiIcon } from './MdiIcon';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { v4 } from 'uuid';
export const DynamicCard = ({ loading, title, subheader, tableCellActions = [], actions = [], paginate = 10, variant = 'default', content, component, headers = [], rows = [], sx = {} }) => {
    const [page, setPage] = useState(1);
    const random = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    return (<Card sx={sx}>
      <CardHeader title={loading ? <Skeleton width={random(40, 380)}/> : title} subheader={subheader ? loading ? <Skeleton width={random(80, 460)}/> : subheader : undefined} action={actions.length > 0 ? (loading ? (<Skeleton variant='circular' width={40} height={40}/>) : actions.length > 1 ? (<IconButton>
                // TODO: Make menu
                <MdiIcon path={mdiDotsVertical}/>
              </IconButton>) : actions.length > 0 ? (<Tooltip title={actions[0].label}>
                <IconButton onClick={actions[0].callback}>
                  <MdiIcon path={actions[0].icon}/>
                </IconButton>
              </Tooltip>) : undefined) : undefined}/>
      {variant == 'component' && <CardContent sx={{ p: 0 }}>{component}</CardContent>}
      {variant == 'list' && (<CardContent sx={{ p: 0 }}>
          <List dense={true}>
            {content
                .filter(r => r != undefined)
                .slice((page - 1) * paginate, (page - 1) * paginate + paginate)
                .map((row) => {
                return (<ListItem key={v4()} secondaryAction={row.actions}>
                    {row.avatar && <ListItemAvatar>{row.avatar}</ListItemAvatar>}
                    <ListItemText primary={row.label} secondary={row.sublabel}/>
                  </ListItem>);
            })}
          </List>
        </CardContent>)}
      {variant == 'default' && (<CardContent>
          {content
                .filter(r => r != undefined)
                .map(row => {
                if (typeof row == 'string') {
                    return <Typography key={v4()}>{row}</Typography>;
                }
                else {
                    return (<Grid container key={v4()}>
                    <Grid xs={12} sm={4}>
                      <Typography sx={{ fontWeight: 'bold' }}>{loading ? <Skeleton width={random(30, 170)}/> : row.label}</Typography>
                    </Grid>
                    <Grid sm></Grid>
                    <Grid xs={12} sm={6}>
                      {React.isValidElement(row) ? row : <Typography>{loading ? <Skeleton width={random(10, 240)}/> : row.value}</Typography>}
                    </Grid>
                  </Grid>);
                }
            })}
        </CardContent>)}
      {variant == 'gallery' && (<CardContent>
          <ImageList sx={{ height: `${content.length > 4 ? 368 : 172}px`, overflowY: content.length > 8 ? 'auto' : 'hidden' }} cols={4} rowHeight={160}>
            {content.map((row, i) => (<ImageListItem sx={{ cursor: 'grab' }} key={`img-${i}${row}`} onClick={() => {
                    window.open(row, '_blank', 'noreferrer');
                }}>
                <img src={`${row}`} loading='lazy'/>
              </ImageListItem>))}
          </ImageList>
        </CardContent>)}
      {variant == 'table' && (<CardContent>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {headers.map(r => {
                return <TableCell align={r.align ?? 'left'}>{loading ? <Skeleton width={random(20, 140)}/> : r.label}</TableCell>;
            })}
                {tableCellActions.length > 0 ? <TableCell sx={{ width: 40 }}></TableCell> : undefined}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array(random(1, 9)).map(r => {
                    return (<TableRow>
                        <TableCell align={r.align ?? 'left'}>{loading ? <Skeleton width={random(20, 140)}/> : r.label}</TableCell>
                        {tableCellActions.length > 0 ? (<TableCell sx={{ width: 40 }}>
                            <Skeleton variant='circular' width={40} height={40}/>
                          </TableCell>) : undefined}
                      </TableRow>);
                })
                : content.map((r, i, a) => {
                    return (<TableRow key={`row${i}`}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <IconButton onClick={() => { }}>
                            <MdiIcon path={mdiDotsHorizontal}/>
                          </IconButton>
                        </TableCell>
                      </TableRow>);
                })}
            </TableBody>
          </Table>
        </CardContent>)}
      {variant == 'list' && content.filter(r => r != undefined).length > paginate && (<CardActions>
          <Pagination count={Math.ceil(content.filter(r => r != undefined).length / paginate)} onChange={(_, p) => {
                setPage(p);
            }} shape='rounded'/>
        </CardActions>)}
    </Card>);
};
