export default class DetalhesEmieController {
  constructor($state, UIService, $http, ElvEmie) {
    this.UI = UIService;
    this.$http = $http;
    this.id = $state.params.id;
    this.ElvEmie = ElvEmie;
    this.loaded = false;
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.ElvEmie.findOne({
      filter: {
        where: {
          active: true,
          id: this.id,
        },
        include:
          {
            relation: 'Processo',
            scope: {
              where: {
                active: true
              },
              include: [{
                relation: 'elevadorPed',
                scope: {
                  where: {
                    active: true
                  }
                }
              },
                {
                  relation: 'condominio',
                  scope: {
                    where: {
                      active: true
                    }
                  }
                },
                {
                  relation: 'estado',
                  scope: {
                    where: {
                      active: true
                    }
                  }
                },
              ]
            }
          }
      }
    }).$promise.then(res => {
      this.data = res;
      this.formatZipCode();
      this.loaded = true;
    })
  };


  formatZipCode = () => {
    if (this.data.cp4 === null) {
      this.data.formatedZipCode = "N/D";
    } else if (this.data.cp3 === null) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" + this.data.cp4,
          cp3: null
        }
      }).then(response => {
        this.data.localidade = response.data.result;
        this.data.formatedZipCode = this.data.cp4 + " " + this.data.localidade;
      });

    } else {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" + this.data.cp4,
          cp3: ("000" + this.data.cp3).slice(-3)
        }
      }).then(response => {
        this.data.cp3 = ("000" + this.data.cp3).slice(-3);
        this.data.localidade = response.data.result;
        this.data.formatedZipCode = this.data.cp4 + "-" + this.data.cp3 + " " + this.data.localidade;
      });
    }
  };

  editEma = () => {
    let emie = angular.copy(this.data);
    let options = {
      size: 'lg',
      template: require('./edit-emie.dialog.html'),
      controller: ['$scope', '$dialog', 'ElvEmie', '$http', ($scope, $dialog, ElvEmie, $http) => {

        $scope.emie = {
          id: emie.id,
          nome: emie.nome,
          nif: emie.nif,
          email: emie.email,
          telemovel: emie.telemovel,
          morada: emie.morada,
          cp4: emie.cp4,
          cp3: emie.cp3,
          localidade: emie.localidade,
          observacoes: emie.observacoes,
          active: true
        };

        $scope.getLocalidade = function () {
          $scope.emie.localidade = "";
          let cp4 = parseInt($scope.emie.cp4);
          let cp3 = parseInt($scope.emie.cp3);
          if (!Number.isInteger(cp4)) {
            $scope.emie.cp3 = "";
          } else if (cp4.toString().length !== 4) {
            $scope.emie.cp3 = "";
          } else if (cp4.toString().length === 4) {
            if (Number.isInteger(cp3)) {
              if ($scope.emie.cp3.toString().length === 3) {
                $http.post('/api/public/getLocalidade', {
                  cp4: $scope.emie.cp4,
                  cp3: $scope.emie.cp3
                }).then(response => {
                  $scope.emie.localidade = response.data.result;
                });
              }
            } else {
              $http.post('/api/public/getLocalidade', {
                cp4: $scope.emie.cp4,
                cp3: null
              }).then(response => {
                $scope.emie.localidade = response.data.result;
              });
            }
          }
        };
        $scope.emie = emie;
        $scope.getLocalidade();

        $scope.isOk = () => {
          let o = $scope.emie;
          return o.nome !== "" && o.nif !== "" && o.morada !== "" && o.cp3 !== "" && o.cp4 !== "";
        };

        $scope.ok = () => {
          delete $scope.emie.localidade;
          $dialog.close($scope.emie);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }],
    };
    let modal = this.UI.showDialog(options);
    modal.then(res => {
      if (res) {
        let cp4 = parseInt(res.cp4);
        let cp3 = parseInt(res.cp3);
        if (Number.isNaN(cp4)) {
          res.cp4 = null;
          res.cp3 = null;
        } else {
          res.cp4 = cp4;
        }
        if (Number.isNaN(cp3)) {
          res.cp3 = null;
        } else {
          res.cp3 = cp3;
        }

        if (!res.email2) res.email2 = null;
        if (!res.email3) res.email3 = null;

        this.ElvEmie.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast('EMIE atualizada com sucesso!');
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro a atualizar a EMIE.');
        });
      }
    });
  };

  editObservacoes = () => {
    let c = angular.copy(this.data);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {
        $scope.observacoes = c.observacoes;

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.ok = () => {
          $dialog.close($scope.observacoes);
        }
      }]
    });
    dialog.then((res) => {
        c.observacoes = res;
        c.$save().then(() => {
          this.loadData();
          this.loaded = false;
          this.UI.addToast('EMIE atualizado com sucesso!');
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro ao atualizar EMIE.');
        });
      }
    );
  };
}

DetalhesEmieController.$inject = ['$state', 'UIService', '$http', 'ElvEmie'];
