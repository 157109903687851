import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
const DialogOrd = ({ open, initial, onClose }) => {
    const validationSchema = yup.object({
        designacao: yup.string().required('O campo é obrigatório'),
        nif: yup.string().min(9, 'NIF tem de ter 9 dígitos').required('O campo é obrigatório'),
        email: yup.string().email('Email inválido').required('O campo é obrigatório'),
        email2: yup.string().email('Email inválido').notRequired(),
        email3: yup.string().email('Email inválido').notRequired(),
        contacto: yup.string().notRequired(),
        observacoes: yup.string().notRequired()
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            onClose(values);
        }
    });
    useEffect(() => {
        formik.setValues(initial);
    }, [initial]);
    return (<form>
      <Dialog open={open} maxWidth={'lg'} fullWidth>
        <DialogTitle>Editar Operador da Rede de Distribuição</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={8} md={8} xs={8}>
              <TextField label='Designação' name='designacao' fullWidth defaultValue={formik.values?.designacao} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
            </Grid>
            <Grid lg={4} md={4} xs={4}>
              <TextField label='NIF' name='nif' defaultValue={formik.values?.nif} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.nif && Boolean(formik.errors.nif)} helperText={formik.touched.nif && formik.errors.nif}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={4} md={4} xs={12}>
              <TextField label='Email' name='email' defaultValue={formik.values?.email} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email}/>
            </Grid>
            <Grid lg={4} md={4} xs={12}>
              <TextField label='Email Alternativo' name='email2' defaultValue={formik.values?.email2} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.email2 && Boolean(formik.errors.email2)} helperText={formik.touched.email2 && formik.errors.email2}/>
            </Grid>
            <Grid lg={4} md={4} xs={12}>
              <TextField label='Email Alternativo 2' name='email3' defaultValue={formik.values?.email3} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.email3 && Boolean(formik.errors.email3)} helperText={formik.touched.email3 && formik.errors.email3}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Contacto' name='contacto' defaultValue={formik.values?.contacto} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.contacto && Boolean(formik.errors.contacto)} helperText={formik.touched.contacto && formik.errors.contacto}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Observações' name='observacoes' defaultValue={formik.values?.observacoes} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.observacoes && Boolean(formik.errors.observacoes)} helperText={formik.touched.observacoes && formik.errors.observacoes}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogOrd;
