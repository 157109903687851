export default class FormacaoCursosController {
  constructor($state, $filter, AuthenticationService, NgTableParams, FormacaoCurso, UIService) {
    this.$state = $state;
    this.$filter = $filter;
    this.FormacaoCurso = FormacaoCurso;
    this.UI = UIService;
    this.NgTableParams = NgTableParams;
    this.tableData = null;
    this.hasLoaded = false;
    this.loadData();
  };

  download = (c) => {
    let wait = this.UI.showWaiting();
    this.FormacaoCurso.exportCourse({ id: c.id }).$promise.then(result => {
      let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + result.fileData;
      let b = document.createElement('a');
      b.href = url;
      b.download = `Curso_C${("0000000" + c.id).slice(-7)}.xlsx`;
      b.click();
      wait.close();
    }).catch(() => {
      this.UI.addToast('Ocorreu um erro ao gerar ficheiro!');
    });
  }


  loadData = () => {
    this.hasLoaded = false;
    this.FormacaoCurso.find({
      filter: {
        where: {
          active: true
        },
        include: [{
          relation: 'areaCategoria',
          scope: {
            include: 'area'
          }
        }],
        order: 'datacriacao desc',
      }
    }).$promise.then(res => {
      this.cursos = res;
      let NgTableParams = this.NgTableParams;
      this.tableData = new NgTableParams({
        sorting: {
          datacriacao: 'desc'
        }
      }, {
        dataset: res,
      });
      this.hasLoaded = true;
    }).catch(() => {
      this.hasLoaded = true;
      this.UI.addToast('Ocorreu um erro ao carregar cursos');
    });
  };


  addCurso = () => {
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./new.dialog.html'),
      controller: ['$scope', 'FormacaoAreaCategoria', function ($scope, FormacaoAreaCategoria) {
        FormacaoAreaCategoria.find({
          filter: {
            where: {
              active: true
            },
            include: 'area'
          }
        }).$promise.then(res => {
          res.forEach(r => {
            r.info = `(${r.area.codigo}) ${r.area.designacao}`;
          });
          $scope.areas = res;
        });
        // Data to change on the modal
        $scope.curso = {
          id: 0,
          estado: 1,
          active: true,
          categoria: {}
        };
        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope.curso);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(res => {
      if (res) {
        res.areaCategoriaId = res.categoria.selected.id;
        this.FormacaoCurso.create(res).$promise.then(() => {
          this.UI.addToast('Curso adicionado com sucesso');
          this.loadData();
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao adicionar curso');
        });
      }
    }).catch(() => {});
  };
}

FormacaoCursosController.$inject = ['$state', '$filter', 'AuthenticationService', 'NgTableParams', 'FormacaoCurso', 'UIService'];
