import React, { useEffect, useState } from 'react';
import Grid from '@mui/system/Unstable_Grid';
import { Alert, Avatar, Box, Breadcrumbs, Button, Card, CardHeader, Checkbox, CircularProgress, Fab, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, Link, List, ListItem, ListItemText, MenuItem, Select, Stack, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { mdiPlusCircleOutline, mdiContentSave, mdiMinusCircleOutline, mdiPencil, mdiWindowRestore, mdiImagePlus, mdiFileDocument, mdiMagnify, mdiCheckBold } from '@mdi/js';
import moment from 'moment';
import Icon from '@mdi/react';
import { useModal } from 'mui-modal-provider';
import { DynamicCard, MdiIcon, WaitDialog } from '@components';
import InputComponent from './inputComponent';
import { DialogDefeitos } from './dialogDefeitos';
import { DialogImage } from './dialogImage';
import { DialogValidacao } from './dialogValidacao';
import { DialogResultado } from './dialogResultado';
import { DialogRequired } from './dialogRequired';
const EditRelatorios = ({ $state, ListaRelatorioService, UIService }) => {
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [data, setData] = useState();
    const [tabParametroIndex, setTabParametroIndex] = useState(0);
    const [tabEnsaioIndex, setTabEnsaioIndex] = useState(0);
    const [tabEquipamentoIndex, setTabEquipamentoIndex] = useState(0);
    const [respostas, setRespostas] = useState();
    const [defects, setDefects] = useState([]);
    const [detalhes, setDetalhes] = useState();
    const [showObservations, setShowObservations] = useState({});
    const [addObservations, setAddObservations] = useState({});
    const [observations, setObservations] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [newImageUrl, setNewImageUrl] = useState(null);
    const [showMotivos, setShowMotivos] = useState();
    const [listaMotivos, setListaMotivos] = useState([]);
    const [missingParametros, setMissingParametros] = useState([]); // Guardar parametros não preenchidos
    const [missingEnsaios, setMissingEnsaios] = useState([]); // Guardar ensaios não preenchidos
    const { showModal } = useModal();
    const isEditing = async () => {
        // If we are saving changes
        if (editing) {
            if (missingParametros.length > 0 || missingEnsaios.length > 0 || (showMotivos && data.motivoId == null)) {
                showModal(DialogRequired, {
                    parametros: missingParametros,
                    ensaios: missingEnsaios,
                    realizada: !showMotivos,
                    motivo: data.motivoId
                });
            }
            else {
                const d = await UIService.showConfirm('Tem a certeza que deseja guardar as alterações?');
                for (let key in showObservations) {
                    if (showObservations.hasOwnProperty(key)) {
                        showObservations[key] = false;
                    }
                }
                for (let key in addObservations) {
                    if (addObservations.hasOwnProperty(key)) {
                        addObservations[key] = false;
                    }
                }
                if (d) {
                    // Save data
                    try {
                        if (!moment(data.dataSubmissao).isValid()) {
                            await ListaRelatorioService.saveDataSubmissao(data, moment().format());
                        }
                        // Calculo do resultado, defini-se a 0 para "recalcular"
                        data.resultadoId = 0;
                        const r = await ListaRelatorioService.saveResposta(data);
                        if (selectedFile != null) {
                            await ListaRelatorioService.uploadTemplate('upload', 'photos', selectedFile);
                        }
                    }
                    catch (error) {
                        console.log(error);
                        UIService.addToast('Ocorreu um erro ao guardar os dados. Verifique a ligação e tente novamente');
                    }
                    if (missingParametros.length == 0 && missingEnsaios.length == 0) {
                        setEditing(!editing);
                    }
                    fetchData();
                }
            }
        }
        else {
            const updatedResultado = {
                ...data,
                resultadoId: 0
            };
            setData(updatedResultado);
            setEditing(true);
        }
    };
    const confirmNewVersion = async () => {
        const d = await UIService.showConfirm('Tem a certeza que pretende criar uma nova versão do relatório?');
        if (d) {
            // Save data
            try {
                let resposta = await ListaRelatorioService.cloneResposta(data);
                $state.go('app.administration.relatorios.edit', { id: resposta.id }, { notify: true, reload: true });
            }
            catch (error) {
                console.log(error);
                try {
                    await UIService.showAlert('Ocorreu um erro ao clonar resposta. Verifique a ligação e tente novamente');
                }
                finally {
                    fetchData();
                }
            }
        }
    };
    const fetchData = async () => {
        setLoading(true);
        // Should not be able to do anything
        if (!$state.params.id) {
            setLoading(false);
            UIService.showAlert('Relatório inválido.');
            return;
        }
        // Fetch lista data
        let fetchedCoreListaRelatorioResposta;
        try {
            fetchedCoreListaRelatorioResposta = await ListaRelatorioService.getCoreListaRelatorioResposta($state.params.id);
            setRespostas(JSON.parse(fetchedCoreListaRelatorioResposta.respostas));
            setData(fetchedCoreListaRelatorioResposta);
            // push parametros não preenchidos para o missingParametros - para obrigar a preencher todos
            fetchedCoreListaRelatorioResposta.CoreListaRelatorio.Parametros.map(param => {
                param.Pontos.map(ponto => {
                    const x = JSON.parse(fetchedCoreListaRelatorioResposta.respostas).parametros.find(resposta => resposta.id == ponto.id);
                    if (ponto.designacao.indexOf('[Texto]') > -1) {
                        //console.log(x);
                        if (x.pontos.length == 0) {
                            setMissingParametros(oldArray => [...oldArray, ponto]);
                        }
                        else {
                            x.pontos.map(p => {
                                if (p == '') {
                                    setMissingParametros(oldArray => [...oldArray, ponto]);
                                }
                            });
                        }
                    }
                    if (x.valor == '') {
                        setMissingParametros(oldArray => [...oldArray, ponto]);
                    }
                });
            });
            // push ensaios não preenchidos para o missingEnsaios - para obrigar a preencher todos
            fetchedCoreListaRelatorioResposta.CoreListaRelatorio.Ensaios.map(ensaio => {
                ensaio.Pontos.map(ponto => {
                    const x = JSON.parse(fetchedCoreListaRelatorioResposta.respostas).parametros.find(resposta => resposta.id == ponto.id);
                    if (x.valor == '') {
                        setMissingEnsaios(oldArray => [...oldArray, ponto]);
                    }
                });
            });
            const defeitos = await ListaRelatorioService.findDefeitosByTipo(fetchedCoreListaRelatorioResposta.CoreListaRelatorio.coreDepartamentoId);
            setDefects(defeitos);
            const motivosList = await ListaRelatorioService.getMotivos();
            setListaMotivos(motivosList);
            // Mapear as propriedades do `fetchedCoreListaRelatorioResposta` para `Detalhes`
            const detalhesData = {
                versao: fetchedCoreListaRelatorioResposta.versao || 1,
                dataSubmissao: fetchedCoreListaRelatorioResposta.dataSubmissao ? moment(fetchedCoreListaRelatorioResposta.dataSubmissao).format('YYYY-MM-DD') : 'N/D',
                tecnicoId: fetchedCoreListaRelatorioResposta.tecnicoId,
                validado: fetchedCoreListaRelatorioResposta.validado,
                realizada: fetchedCoreListaRelatorioResposta.realizada,
                motivoId: fetchedCoreListaRelatorioResposta.motivoId,
                resultadoId: fetchedCoreListaRelatorioResposta.resultadoId,
                observacoes: fetchedCoreListaRelatorioResposta.observacoes || 'N/D'
            };
            // Atualizar o estado `detalhes` com os valores mapeados
            setDetalhes(detalhesData);
        }
        catch (error) {
            console.log(error);
            UIService.addToast('Erro na consulta de Relatório.');
            // Como voltar atrás em caso de erro?
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    const onDialogDefeitosClose = (defeitos, i, pontoId, lastToggleValue) => {
        if (defeitos) {
            const newRespostas = {
                ...respostas,
                parametros: respostas.parametros.map(param => (param.id === pontoId ? { ...param, defeitos: defeitos, valor: 'NC' } : param))
            };
            // Atualizar o estado respostas
            setRespostas(newRespostas);
            // Update global data too
            setData({ ...data, respostas: JSON.stringify(newRespostas) });
        }
        else if (lastToggleValue !== undefined) {
            const restoredRespostas = {
                ...respostas,
                parametros: respostas.parametros.map((param, index) => (param.id === pontoId ? { ...param, valor: lastToggleValue } : param))
            };
            // Atualizar o estado respostas com o valor anterior
            setRespostas(restoredRespostas);
            // Atualizar os dados globais
            setData({ ...data, respostas: JSON.stringify(restoredRespostas) });
        }
    };
    const handleTabParametroChange = (event, newValue) => {
        setTabParametroIndex(newValue);
    };
    const handleTabEnsaioChange = (event, newValue) => {
        setTabEnsaioIndex(newValue);
    };
    const handleTabEquipamentoChange = (event, newValue) => {
        setTabEquipamentoIndex(newValue);
    };
    const handleParametroChange = (i, newValue, defeitosValidacao, pontoId, toggleValue) => {
        if (newValue != null) {
            // Create a copy of the respostas object
            const newRespostas = {
                ...respostas,
                parametros: respostas.parametros.map((param, index) => (index === i ? { ...param, valor: newValue } : param) // Change the index, keep the rest
                )
            };
            // Update respostas
            setRespostas(newRespostas);
            // Update global data too
            setData({ ...data, respostas: JSON.stringify(newRespostas) });
            // Open dialog if newValue is "NC"
            if (newValue === 'NC') {
                handleDefeitos(defeitosValidacao, i, pontoId, toggleValue);
            }
            else {
                //setDefectsList(l => l.filter(item => item.pontoId !== pontoId));
                const updatedRespostas = {
                    ...newRespostas,
                    parametros: newRespostas.parametros.map(param => (param.id === pontoId ? { ...param, defeitos: [] } : param))
                };
                setRespostas(updatedRespostas);
                setData({ ...data, respostas: JSON.stringify(updatedRespostas) });
            }
        }
        else {
            // Do nothing, we can't unselect...
        }
    };
    const handleImage = (file, index, pontoId) => {
        if (file == null) {
            respostas.parametros.find(x => x.id == pontoId).pontos = [];
            setSelectedFile(null);
            setNewImageUrl(null);
            return;
        }
        else if (!file.dummy) {
            respostas.parametros.find(x => x.id == pontoId).pontos[index] = file.name; /// filtar pelo id -> alterar [''] para nome da imagem
            setNewImageUrl(URL.createObjectURL(file));
            setSelectedFile(file);
        }
        const formData = new FormData();
        formData.append('file', file);
    };
    const handleDefeitos = async (validacao, i, pontoId, toggleValue) => {
        let loadDefects = [];
        respostas.parametros.map(param => {
            if (param.id == pontoId) {
                param.defeitos.map(defeito => {
                    loadDefects.push(defeito);
                });
            }
        });
        showModal(DialogDefeitos, {
            number: i,
            pontoId: pontoId,
            validation: validacao,
            title: 'Defeitos',
            defeitos: defects,
            loadDefects: loadDefects,
            lastToggleValue: toggleValue,
            type: 'Defeitos',
            onClose: (d, i) => onDialogDefeitosClose(d, i, pontoId, toggleValue)
        });
    };
    const handleEnsaioChange = (newValue, i, pontoId) => {
        if (newValue != null) {
            if (newValue) {
                setMissingEnsaios(prev => prev.filter(missing => missing.id !== pontoId));
            }
            else if (newValue == '') {
                // quando remove o valor da caixa de texto
                // push ensaios não preenchidos para o missingEnsaios - para obrigar a preencher todos
                let temp;
                let adicionaPonto;
                data.CoreListaRelatorio.Ensaios.map(ensaio => {
                    temp = ensaio.Pontos.find(ponto => ponto.id == pontoId);
                    if (temp) {
                        adicionaPonto = temp;
                    }
                });
                setMissingEnsaios(oldArray => [...oldArray, adicionaPonto]);
            }
            // Create a copy of the respostas object
            const newRespostas = {
                ...respostas,
                ensaios: respostas.ensaios.map((param, index) => (index === i ? { ...param, valor: newValue } : param) // Change the index, keep the rest
                )
            };
            // Update respostas
            setRespostas(newRespostas);
            // Update global data too
            setData({ ...data, respostas: JSON.stringify(newRespostas) });
        }
        else {
            // Do nothing, we can't unselect...
        }
    };
    const handleEquipamentoChange = (i, newValue) => {
        if (newValue != null) {
            // Create a copy of the respostas object
            const newRespostas = {
                ...respostas,
                equipamentos: respostas.equipamentos.map((param, index) => (index === i ? { ...param, valor: newValue } : param) // Change the index, keep the rest
                )
            };
            // Update respostas
            setRespostas(newRespostas);
            // Update global data too
            setData({ ...data, respostas: JSON.stringify(newRespostas) });
        }
        else {
            // Do nothing, we can't unselect...
        }
    };
    const handleObservacoes = (value, itemId) => {
        if (value === 'obs') {
            setAddObservations(prevState => ({
                ...prevState,
                [itemId]: !prevState[itemId]
            }));
        }
        else {
            setShowObservations(prevState => ({
                ...prevState,
                [itemId]: !prevState[itemId]
            }));
        }
    };
    const addObservacao = pontoId => {
        const newObservation = observations.find(o => o.pontoId === pontoId);
        // Create a copy of the respostas object
        const newRespostas = {
            ...respostas,
            parametros: respostas.parametros.map(param => (param.id === pontoId ? { ...param, observacoes: newObservation ? newObservation.obs : param.observacoes } : param))
        };
        // Update respostas
        setRespostas(newRespostas);
        // Update global data too
        setData({ ...data, respostas: JSON.stringify(newRespostas) });
    };
    const handlePontos = (event, pontoId, pontosIndex) => {
        //pontosIndex é para quando há mais do que uma textfield nos parametros
        const newRespostas = {
            ...respostas,
            parametros: respostas.parametros.map(param => {
                if (param.id === pontoId) {
                    // Se o array de pontos estiver vazio, adicionar o novo valor
                    if (param.pontos.length === 0) {
                        return {
                            ...param,
                            pontos: [event.target.value]
                        };
                    }
                    // Atualiza o ponto no índice correto se o array não estiver vazio
                    return {
                        ...param,
                        pontos: param.pontos.map((ponto, index) => (index === pontosIndex ? event.target.value : ponto))
                    };
                }
                return param;
            })
        };
        let ponto, res;
        if (event.target.value === '') {
            data.CoreListaRelatorio.Parametros.map(param => {
                const temp = param.Pontos.find(p => p.id == pontoId);
                if (temp) {
                    ponto = temp;
                }
            });
            res = newRespostas.parametros.find(resposta => resposta.id == pontoId);
            if (ponto.designacao.indexOf('[Texto]') > -1) {
                if (res.pontos.length == 0) {
                    setMissingParametros(oldArray => [...oldArray, ponto]);
                }
                else {
                    res.pontos.map(p => {
                        if (p == '') {
                            console.log(p);
                            setMissingParametros(oldArray => [...oldArray, ponto]);
                        }
                    });
                }
            }
            if (res.valor == '') {
                setMissingParametros(oldArray => [...oldArray, ponto]);
            }
        }
        else {
            setMissingParametros(oldArray => oldArray.filter(p => p.id !== pontoId));
        }
        // Atualizar respostas
        setRespostas(newRespostas);
        // Atualizar dados globais também
        setData({ ...data, respostas: JSON.stringify(newRespostas) });
    };
    const handleDetalhesChange = (key, value) => {
        const updatedDetalhes = {
            ...data,
            [key]: value
        };
        setData(updatedDetalhes);
    };
    const handleRealizadaChange = (event) => {
        const isChecked = event.target.checked;
        handleDetalhesChange('realizada', isChecked ? 1 : 0);
        setShowMotivos(!isChecked);
    };
    const onDialogValidacaoClose = async (res) => {
        if (res) {
            const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
            try {
                await ListaRelatorioService.validateResposta(data.id);
                // console.log(r);
            }
            catch (error) {
                UIService.addToast('Ocorreu um erro ao guardar os dados. Verifique a ligação e tente novamente');
            }
            wait.destroy();
            fetchData();
        }
    };
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>{loading ? 'A carregar...' : data?.CoreListaRelatorio.titulo}</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Relatórios
          </Link>
          <Link underline='none' color='inherit'>
            {loading ? <CircularProgress size={16}/> : 'Editar Relatório'}
          </Link>
        </Breadcrumbs>
      </Box>
      <Box>
        {!loading && editing ? (<Alert sx={{ mb: 1, mt: 1 }} severity='error'>
            A editar relatório, utilize o botão no canto inferior direito para guardar alterações
          </Alert>) : undefined}
        {!loading && data && data.validado ? (<Alert sx={{ mb: 1, mt: 1 }} severity='success'>
            Este relatório encontra-se validado. Para fazer alterações, clique no botão "Clonar" para criar uma nova versão em rascunho.
          </Alert>) : undefined}
      </Box>
      {loading || !data ? (<Box sx={{ display: 'flex', justifyContent: 'center', height: '2vh' }}>
          <CircularProgress size={60} color='primary'/>
        </Box>) : (<Box>
          {data && (<Grid container spacing={1}>
              <Grid md={6} xs={12}>
                <DynamicCard sx={{ mb: 1 }} title='Informação geral' loading={loading} content={[
                    {
                        label: 'Versão',
                        value: data?.versao
                    },
                    {
                        label: 'Data de Submissão',
                        value: data && data.dataSubmissao ? moment(data.dataSubmissao).format('DD-MM-YYYY') : 'N/D'
                    },
                    {
                        label: 'Técnico',
                        value: data?.Tecnico.name || 'N/D'
                    },
                    {
                        label: 'Validado',
                        value: data?.validado ? 'Sim' : 'Não'
                    },
                    ...(data?.validado
                        ? [
                            {
                                label: 'Validado por',
                                value: data?.ValidadoPor?.name || 'N/D'
                            }
                        ]
                        : []),
                    ...(data?.validado
                        ? [
                            {
                                label: 'Data de Validação',
                                value: data && data.dataValidacao ? moment(data.dataValidacao).format('DD-MM-YYYY') : 'N/D'
                            }
                        ]
                        : []),
                    {
                        label: 'Resultado',
                        value: (<Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
                          <Typography>{data && data.dataSubmissao ? data?.CoreListaRelatorioResultado?.designacao : 'A aguardar submissão'}</Typography>{' '}
                          {data?.CoreListaRelatorioResultado?.valor === 0 || data?.CoreListaRelatorioResultado?.valor === 2 ? (<IconButton onClick={() => {
                                    const resultado = JSON.parse(data.avaliacao);
                                    const erros = { parametros: [], ensaios: [] };
                                    resultado.parametros.map(parametros => {
                                        if (parametros.resultado) {
                                            parametros.pontos.map(ponto => {
                                                data.CoreListaRelatorio.Parametros.map(params => {
                                                    let erro = params.Pontos.find(p => p.id == ponto.idPonto);
                                                    if (erro)
                                                        erros.parametros.push({ codigo: erro.codigo, designacao: erro.designacao });
                                                });
                                            });
                                        }
                                    });
                                    resultado.ensaios.map(ensaio => {
                                        if (ensaio.resultado) {
                                            erros.ensaios.push({ codigo: ensaio.observacoes, designacao: ensaio.aValidar });
                                        }
                                    });
                                    showModal(DialogResultado, {
                                        motivos: resultado,
                                        erros: erros
                                    });
                                }}>
                              <MdiIcon path={mdiMagnify}/>
                            </IconButton>) : (<Box></Box>)}
                        </Stack>)
                    },
                    {
                        label: 'Observações',
                        value: data?.observacoes || 'N/D'
                    }
                ]}/>
              </Grid>
              <Grid md={6} xs={12}>
                <DynamicCard sx={{ mb: 1 }} title='Ficheiros gerados' variant='list' loading={loading} content={data.Ficheiros.filter(r => {
                    // Se não está validado, só mostra os ficheiros que ficam concluídos na validação
                    if (data.validado == 1) {
                        return true;
                    }
                    else {
                        return r.RelatorioFicheiro?.validado == 0;
                    }
                }).map(r => {
                    return {
                        avatar: (<Avatar>
                          <MdiIcon path={mdiFileDocument}/>
                        </Avatar>),
                        label: r.RelatorioFicheiro.designacao,
                        actions: [
                            <Box>
                          {!loading && (<Tooltip title='Visualizar ficheiro'>
                              <IconButton onClick={() => {
                                        window.open(`/api/Upload/output/download/${r.ficheiro}`, '_blank');
                                    }}>
                                <MdiIcon path={mdiMagnify}/>
                              </IconButton>
                            </Tooltip>)}
                        </Box>
                        ]
                    };
                })}/>
              </Grid>
            </Grid>)}
          <Grid container spacing={1}>
            <Grid md={12} xs={12}>
              {/* <DynamicCard
              sx={{ mb: 1 }}
              title='Fotografias'
              variant='gallery'
              loading={loading}
              content={
                JSON.parse(data.imagens) == null ? (
                  <Box></Box>
                ) : (
                  JSON.parse(data.imagens).map(img => {
                    <Box
                      component='img'
                      src={`/common/uploads/photos/${img.image}`}
                      alt='Uploaded'
                      //style={{ maxWidth: "50%", marginTop: "10px" }}
                      sx={{
                        maxWidth: '500px',
                        maxHeight: '500px',
                        width: 'auto',
                        height: 'auto'
                      }}></Box>;
                  })
                )
              }
            /> */}
            </Grid>
            <Grid md={12} xs={12}>
              <Card sx={{ mb: 1 }}>
                <CardHeader title='Detalhes' subheader=''/>
                <Grid justifyContent='center' alignItems='center' container spacing={2} sx={{ margin: 2 }}>
                  <Grid lg={4} md={4} xs={12} sx={{ mt: 1 }}>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox disabled={!editing} checked={data.realizada === 1} onChange={handleRealizadaChange} name='checked' color='primary'/>} label='Realizada?'/>
                    </FormGroup>
                  </Grid>
                  <Grid lg={8} md={8} xs={12} sx={{ mt: 1 }}>
                    {data.realizada == 0 || data.realizada == null ? (<FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>Motivo</InputLabel>
                        <Select disabled={!editing} required={showMotivos} labelId='demo-simple-select-label' id='demo-simple-select' value={data.motivoId || ''} label='Motivo' onChange={e => handleDetalhesChange('motivoId', e.target.value)} fullWidth>
                          {listaMotivos?.map(item => (<MenuItem key={item.id} value={item.id}>
                              {item.designacao}
                            </MenuItem>))}
                        </Select>
                      </FormControl>) : (<Box></Box>)}
                  </Grid>

                  <TextField id='outlined-basic' disabled={!editing} label='Observações' variant='outlined' defaultValue={data.observacoes} onChange={e => handleDetalhesChange('observacoes', e.target.value)} fullWidth/>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          {data?.CoreListaRelatorio.Parametros.length > 0 && (<Grid container spacing={1}>
              <Grid md={12} xs={12}>
                <Card sx={{ mb: 1 }}>
                  <CardHeader title='Parâmetros' subheader=''/>
                  <Box>
                    <Tabs scrollButtons={true} allowScrollButtonsMobile={true} variant='scrollable' value={tabParametroIndex} onChange={handleTabParametroChange} aria-label='Tab Relatório'>
                      {data?.CoreListaRelatorio.Parametros.map(parametro => {
                    return <Tab key={parametro.id} label={`${parametro.codigo} - ${parametro.designacao}`}/>;
                })}
                    </Tabs>
                    <List>
                      {data?.CoreListaRelatorio.Parametros[tabParametroIndex]?.Pontos.map(ponto => {
                    let i = respostas.parametros.findIndex(x => x.id === ponto.id);
                    const pontoRespostas = respostas.parametros[i].pontos;
                    let pontosIndex = -1;
                    return (<List key={ponto.ordem}>
                            <ListItem key={ponto.id} style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {ponto.codigo}&nbsp;-&nbsp;
                              {ponto.designacao.split(/\s+/).map((r, index) => {
                            /// REGEX -> verificar de r tem [Texto]
                            if (/\[Texto\]/.test(r)) {
                                pontosIndex++;
                                const currentIndex = pontosIndex;
                                return (<React.Fragment key={index}>
                                      &nbsp;
                                      <TextField size='small' required variant='outlined' onChange={event => handlePontos(event, ponto.id, currentIndex)} defaultValue={pontoRespostas[currentIndex]} disabled={!editing}/>
                                      &nbsp;&nbsp;
                                    </React.Fragment>);
                            }
                            /// REGEX -> verificar de r tem [Numero]
                            if (/\[Numero\]/.test(r)) {
                                pontosIndex++;
                                return (<React.Fragment key={index}>
                                      &nbsp;
                                      <TextField size='small' required variant='outlined' defaultValue={pontoRespostas[pontosIndex]} inputProps={{ type: 'number' }} disabled={!editing}/>
                                      &nbsp;&nbsp;
                                    </React.Fragment>);
                            }
                            /// REGEX -> verificar de r tem [Data/Hora]
                            if (/\[Data\/Hora\]/.test(r)) {
                                pontosIndex++;
                                return (<React.Fragment key={index}>
                                      &nbsp;
                                      <TextField size='small' required variant='outlined' defaultValue={pontoRespostas[pontosIndex]} 
                                //inputProps={{ type: 'number' }}
                                disabled={!editing}/>
                                      &nbsp;&nbsp;
                                    </React.Fragment>);
                            }
                            /// REGEX -> verificar de r tem [Imagem]
                            if (/\[Imagem\]/.test(r)) {
                                pontosIndex++;
                                return (<React.Fragment key={index}>
                                      &nbsp;
                                      <Tooltip title={pontoRespostas.length == 0 ? 'Adicionar imagem' : 'Alterar imagem'}>
                                        <Button variant='outlined' size='small' disabled={!editing} onClick={() => {
                                        showModal(DialogImage, {
                                            image: pontoRespostas[pontosIndex],
                                            index: pontosIndex,
                                            onClose: (file, index) => handleImage(file, index, ponto.id)
                                        });
                                    }}>
                                          <MdiIcon path={mdiImagePlus}/>
                                        </Button>
                                      </Tooltip>
                                      &nbsp;&nbsp;
                                    </React.Fragment>);
                            }
                            return (<Typography key={index} style={{
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    wordBreak: 'break-word'
                                    //marginBottom: '8px'
                                }}>
                                    {r}&nbsp;
                                  </Typography>);
                        })}
                            </ListItem>
                            <ListItem>
                              <Stack direction={'column'} justifyContent='space-between' sx={{ width: '100%' }} spacing={2}>
                                <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                                  <ToggleButtonGroup size='small' exclusive onChange={(event, newValue) => handleObservacoes(newValue, ponto.id)}>
                                    <ToggleButton sx={{ width: 50 }} value='showObs'>
                                      {showObservations[ponto.id] ? <Icon path={mdiMinusCircleOutline} size={1}/> : <Icon path={mdiPlusCircleOutline} size={1}/>}
                                    </ToggleButton>
                                    <ToggleButton sx={{ width: 50 }} value='obs' disabled={!editing}>
                                      Obs
                                    </ToggleButton>
                                  </ToggleButtonGroup>

                                  <ToggleButtonGroup size='small' value={i < 0 ? undefined : respostas?.parametros[i]?.valor} // If we can't find the index (should not happen)
                     disabled={!editing} exclusive onChange={(event, newValue) => {
                            handleParametroChange(i, newValue, ponto.defeitos, ponto.id, respostas?.parametros[i]?.valor);
                            if (respostas?.parametros[i]?.valor === 'NC' && newValue === null) {
                                handleDefeitos(ponto.defeitos, i, ponto.id, respostas?.parametros[i]?.valor);
                            }
                            if (newValue) {
                                setMissingParametros(prev => prev.filter(missing => missing.id !== ponto.id));
                            }
                        }}>
                                    <ToggleButton sx={{ width: 50 }} value='C' color='success'>
                                      C
                                    </ToggleButton>
                                    <ToggleButton sx={{ width: 50 }} value='NC' color='error'>
                                      NC
                                    </ToggleButton>
                                    <ToggleButton sx={{ width: 50 }} value='NA' color='info'>
                                      NA
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </Stack>
                                {addObservations[ponto.id] ? (<Box>
                                    <Stack direction={'row'} spacing={2}>
                                      <TextField id='standard-multiline-static' label='Observações Internas' defaultValue={i >= 0 ? respostas.parametros[i].observacoes : ''} multiline rows={4} variant='standard' fullWidth onChange={event => {
                                const newObservacoes = event.target.value;
                                setObservations(prevState => {
                                    const newState = [...prevState];
                                    const obsIndex = newState.findIndex(obs => obs.pontoId === ponto.id);
                                    if (obsIndex > -1) {
                                        newState[obsIndex] = { pontoId: ponto.id, obs: newObservacoes };
                                    }
                                    else {
                                        newState.push({ pontoId: ponto.id, obs: newObservacoes });
                                    }
                                    return newState;
                                });
                            }}/>
                                      <Button onClick={() => addObservacao(i + 1)}>Guardar</Button>
                                    </Stack>
                                  </Box>) : (<Box></Box>)}
                                {showObservations[ponto.id] ? (<List sx={{ width: '100%', bgcolor: '#e5e5e5' }} dense>
                                    {respostas.parametros[i].pontos.map(p => {
                                /// REGEX -> se p for string com .png / jpeg ... mostra o botão
                                if (/([a-zA-Z0-9-_]+\.(jpeg|jpg|png|bmp))/.test(p)) {
                                    return (<ListItem>
                                            <Button disabled={pontoRespostas.length == 0 ? true : false} onClick={() => {
                                            newImageUrl == null ? window.open(`/api/upload/photos/download/${pontoRespostas[pontosIndex]}`, '_blank') : window.open(newImageUrl, '_blank');
                                        }}>
                                              {' '}
                                              Visualizar imagem
                                            </Button>
                                          </ListItem>);
                                }
                                else {
                                    return <Box></Box>;
                                }
                            })}
                                    {defects.map((d, index) => {
                                const param = respostas.parametros.find(param => param.id === ponto.id);
                                if (param) {
                                    if (param.defeitos.length === 0) {
                                        return (<ListItem key={index}>
                                              <ListItemText primary={<Box sx={{ fontWeight: 'bold' }}>{d.designacao}</Box>} secondary='Sem Defeitos'/>
                                            </ListItem>);
                                    }
                                    else {
                                        const defectItems = d.Defeitos.filter(def => param.defeitos.some(dl => dl.id === def.id)).map((def, defIndex) => {
                                            const defectData = param.defeitos.find(dl => dl.id === def.id);
                                            return (<ListItem key={def.id}>
                                                <ListItemText primary={`${def.titulo} - ${def.subtitulo}`} secondary={defectData ? defectData.nota : ''}/>
                                              </ListItem>);
                                        });
                                        if (defectItems.length === 0) {
                                            return (<ListItem key={index}>
                                                <ListItemText primary={<Box sx={{ fontWeight: 'bold' }}>{d.designacao}</Box>} secondary='Sem Defeitos'/>
                                              </ListItem>);
                                        }
                                        return (<React.Fragment key={index}>
                                              <ListItem>
                                                <ListItemText primary={<Box sx={{ fontWeight: 'bold' }}>{d.designacao}</Box>}/>
                                              </ListItem>
                                              {defectItems}
                                            </React.Fragment>);
                                    }
                                }
                                return null;
                            })}
                                    <ListItem>
                                      <ListItemText primary={<Box sx={{ fontWeight: 'bold' }}>Observações</Box>} secondary={respostas.parametros.find(x => x.id === ponto.id).observacoes === '' ? 'Sem observações' : respostas.parametros.find(x => x.id === ponto.id).observacoes}/>
                                    </ListItem>
                                  </List>) : (<Box></Box>)}
                              </Stack>
                            </ListItem>
                          </List>);
                })}
                    </List>
                  </Box>
                </Card>
              </Grid>
            </Grid>)}
          {data?.CoreListaRelatorio.Ensaios.length > 0 && (<Grid container spacing={1}>
              <Grid md={12} xs={12}>
                <Card sx={{ mb: 1 }}>
                  <CardHeader title='Ensaios' subheader=''/>
                  <Box>
                    <Tabs scrollButtons={true} allowScrollButtonsMobile={true} variant='scrollable' value={tabEnsaioIndex} onChange={handleTabEnsaioChange} aria-label='Tab Relatório'>
                      {data?.CoreListaRelatorio.Ensaios.map(ensaio => {
                    return <Tab key={ensaio.id} label={`${ensaio.codigo} - ${ensaio.designacao}`}/>;
                })}
                    </Tabs>
                    <List>
                      {data?.CoreListaRelatorio.Ensaios[tabEnsaioIndex]?.Pontos.map(ponto => {
                    let i = respostas.ensaios.findIndex(x => x.id === ponto.id);
                    return (<ListItem key={ponto.id}>
                            <ListItemText onClick={() => { }} primary={`${ponto.codigo} - ${ponto.designacao}`}/>
                            <InputComponent tipo={ponto.tipo} formato={ponto.formato} inputChange={newValue => handleEnsaioChange(newValue, i, ponto.id)} value={respostas.ensaios[i].valor == undefined ? '' : respostas.ensaios[i].valor} index={i} sx={{ width: 250 }} inputProps={{
                            disable: !editing
                        }}/>
                          </ListItem>);
                })}
                    </List>
                  </Box>
                </Card>
              </Grid>
            </Grid>)}
          {data?.CoreListaRelatorio.Equipamentos.length > 0 && (<Grid container spacing={1}>
              <Grid md={12} xs={12}>
                <Card sx={{ mb: 1 }}>
                  <CardHeader title='Equipamentos' subheader=''/>
                  <Box>
                    <Tabs scrollButtons={true} allowScrollButtonsMobile={true} variant='scrollable' value={tabEquipamentoIndex} onChange={handleTabEquipamentoChange} aria-label='Tab Relatório'>
                      {data?.CoreListaRelatorio.Equipamentos.map(equipamento => {
                    return <Tab key={equipamento.id} label={`${equipamento.designacao}`}/>;
                })}
                    </Tabs>
                    <List>
                      {data?.CoreListaRelatorio.Equipamentos[tabEquipamentoIndex]?.Pontos.map(ponto => {
                    let i = respostas.equipamentos.findIndex(x => x.id === ponto.id);
                    return (<ListItem key={ponto.id}>
                            <ListItemText onClick={() => { }} primary={`${ponto.codigo} - ${ponto.designacao}`}/>
                            {/*TODO - Fix to proper TextField*/}
                            <TextField disabled={!editing} sx={{ width: 200 }} value={respostas.equipamentos[i].valor} onChange={event => {
                            handleEquipamentoChange(i, event.target.value);
                        }}/>
                          </ListItem>);
                })}
                    </List>
                  </Box>
                </Card>
              </Grid>
            </Grid>)}
        </Box>)}
      {!loading && (<Box sx={{
                position: 'fixed',
                margin: 2,
                bottom: 0,
                right: 0,
                display: 'flex',
                gap: 1
            }}>
          {!data?.validado && (<Tooltip title={editing ? 'Guardar alterações' : 'Editar'}>
              <Fab color={editing ? 'error' : 'primary'} onClick={() => isEditing()}>
                <MdiIcon path={editing ? mdiContentSave : mdiPencil}/>
              </Fab>
            </Tooltip>)}
          {!data?.validado &&
                !editing &&
                data.dataSubmissao && ( /// && permissões para validar
            <Tooltip title={'Validar relatório'} onClick={() => showModal(DialogValidacao, {
                    title: 'Validar relatório do relatório',
                    onClose: onDialogValidacaoClose
                })}>
                <Fab color={'success'}>
                  <MdiIcon path={mdiCheckBold}/>
                </Fab>
              </Tooltip>)}
          {data?.validado ? (<Tooltip title='Nova versão'>
              <Fab variant='extended' onClick={() => confirmNewVersion()}>
                <MdiIcon path={mdiWindowRestore} sx={{ mr: 1 }}/>
                Clonar
              </Fab>
            </Tooltip>) : undefined}
        </Box>)}
    </Box>);
};
export default EditRelatorios;
