export default class FormacaoCursoEdicaoController {
  constructor($state, UIService, FormacaoEdicao, FormacaoEdicaoAvaliacao, FormacaoInscricao, FormacaoEdicaoLeccionacao) {
    this.$state = $state;
    this.UI = UIService;
    this.FormacaoEdicao = FormacaoEdicao;
    this.FormacaoEdicaoAvaliacao = FormacaoEdicaoAvaliacao;
    this.FormacaoInscricao = FormacaoInscricao;
    this.FormacaoEdicaoLeccionacao = FormacaoEdicaoLeccionacao;
    this.id = $state.params.id;
    this.ed = $state.params.ed;
    // Using this to reduce html generated by tabset, since all content is equals but only filtered
    this.inscritosEstado = 1;
    // Redirect if something is not defined
    if (!this.id) {
      this.$state.go('app.formacao.cursos.list');
    } else {
      if (!this.ed) {
        this.$state.go('app.formacao.cursos.details({id:' + this.id + '})');
      }
    }
    this.hasLoaded = false;
    this.loadData();
  }

  loadData = () => {
    this.hasLoaded = false;
    this.FormacaoEdicao.findOne({
      filter: {
        where: {
          id: this.ed,
          cursoId: this.id
        },
        include: [
          {
            relation: 'local',
            scope: {
              where: {
                active: true
              }
            }
          },
          {
            relation: 'tipo',
            scope: {
              where: {
                active: true
              }
            }
          }, 'estado', 'empresa',
          {
            relation: 'curso',
            scope: {
              include: {
                relation: 'areaCategoria',
                scope: {
                  include: 'area'
                }
              }
            }
          },
          {
            relation: 'avaliacoes',
            scope: {
              include: {
                relation: 'formador',
                scope: {
                  include: {
                    relation: 'leccionacoes',
                    scope: {
                      where: {
                        edicaoId: this.ed
                      },
                      include: 'modulo'
                    }
                  }
                }
              }
            }
          },
          {
            relation: 'inscricoes',
            scope: {
              include: ['formando']
            }
          }
        ]
      }
    }).$promise.then(res => {
      if (res) {
        this.edicao = res;
        if (this.edicao.inscricoes && this.edicao.inscricoes.length > 0) {
          // Sort by formando name (localeCompare so we can deal with accented chars)
          this.edicao.inscricoes.sort((a, b) => a.formando.nome.localeCompare(b.formando.nome));
        }
        this.hasLoaded = true;
      } else {
        this.UI.addToast('Ocorreu um erro a carregar edição');
        this.$state.go('app.formacao.cursos.details({id:' + this.id + '})');
      }
    }).catch(() => {
      this.UI.addToast('Ocorreu um erro a carregar edição');
      this.$state.go('app.formacao.cursos.details({id:' + this.id + '})');
    });
  };

  getAreaFormacao = () => {
    if (this.hasLoaded) {
      if (angular.isDefined(this.edicao.curso.areaCategoria)) {
        return "<p>" + this.edicao.curso.areaCategoria.designacao + "</p><p class='hidden-sm'>(" + this.edicao.curso.areaCategoria.area.codigo + ") " + this.edicao.curso.areaCategoria.area.designacao + "</p>";
      } else {
        return "<p>N/D</p>";
      }
    } else {
      return "<p>N/D</p>";
    }
  };

  getFormandosEstado = estado => {
    let total = 0;
    this.edicao.inscricoes.forEach(i => {
      if (i.formando)
        i.formando.name = i.formando.nome;
      if (i.estadoId === estado) total++;
    });
    return total;
  };

  addFormador = () => {
    let ativos = [];
    this.edicao.avaliacoes.forEach(a => {
      ativos.push(a.formadorId);
    });
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./add-formadores.dialog.html'),
      controller: ['$scope', '$dialog', 'FormacaoFormador', ($scope, $dialog, FormacaoFormador) => {
        $scope.hasLoaded = false;

        $scope.data = {
          selected: []
        };

        FormacaoFormador.find({
          filter: {
            where: {
              active: true,
              id: {
                nin: ativos
              }
            }
          }
        }).$promise.then(r => {
          $scope.hasLoaded = true;
          $scope.formadores = r;
        });

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.ok = () => {
          $dialog.close($scope.data);
        }
      }]
    });

    dialog.then(res => {
      if (res) {
        if (res.selected.length > 0) {
          res.selected.forEach(r => {
            this.FormacaoEdicaoAvaliacao.create({
              id: 0,
              edicaoId: this.ed,
              formadorId: r.id
            });
          });
          this.loadData();
          this.UI.addToast('Formadores adicionados com sucesso');
        }
      }
    });
  };

  editEdicao = () => {
    let ed = angular.copy(this.edicao);
    this.UI.showDialog({
      size: 'lg',
      template: require('./edit.dialog.html'),
      controller: ['$scope', 'FormacaoLocal', 'FormacaoEdicaoEstado', ($scope, FormacaoLocal, FormacaoEdicaoEstado) => {
        $scope.locaisLoaded = false;
        $scope.estadosLoaded = false;

        FormacaoLocal.find({
          filter: {
            where: {
              active: true
            }
          }
        }).$promise.then(l => {
          $scope.locaisLoaded = true;
          $scope.locais = l;
        });

        FormacaoEdicaoEstado.find({
        }).$promise.then(e => {
          $scope.estadosLoaded = true;
          $scope.estados = e;
        });

        $scope.edicao = {
          ano: ed.ano,
          no: ed.no,
          datainicio: ed.datainicio,
          datafim: ed.datafim,
          observacoes: ed.observacoes,
          tipoId: ed.tipoId,
          online: ed.online === 1
        };

        $scope.auxLocal = {
          selected: ed.local
        };

        $scope.auxEstado = {
          selected: ed.estado
        };

        $scope.ok = () => {
          $scope.edicao.localId = $scope.auxLocal.selected.id;
          $scope.edicao.estadoId = $scope.auxEstado.selected.id;
          $scope.$close($scope.edicao);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    }).then(res => {
      if (res) {
        this.hasLoaded = false;
        this.edicao.ano = res.ano.format('YYYY');
        this.edicao.no = res.no;
        this.edicao.datainicio = res.datainicio.format('YYYY-MM-DD');
        this.edicao.datafim = res.datafim.format('YYYY-MM-DD');
        this.edicao.observacoes = res.observacoes;
        this.edicao.online = res.online;
        this.edicao.estadoId = res.estadoId;
        if (this.edicao.estado)
          this.edicao.estado.id = res.estadoId;
        this.edicao.localId = res.localId;
        if (this.edicao.local)
          this.edicao.local.id = res.localId;
        this.edicao.$save().then(() => {
          this.loadData();
          this.UI.addToast('Edição editada com sucesso');
        }).catch((error) => {
          console.log(error);
          this.hasLoaded = true;
          this.UI.addToast('Erro ao editar edição');
        });
      }
    }).catch(() => {});
  };

  exportFormandos = () => {
    if (this.edicao.tipoId !== 3) {
      // Check if empresa is filled
    }
    let wait = this.UI.showWaiting();
    this.FormacaoEdicao.exportFormandos({
      id: this.ed
    }).$promise.then((result) => {
      let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + result.fileData;
      let b = document.createElement('a');
      b.href = url;
      b.download = `Formandos_A${("0000000" + this.ed).slice(-7)}.xlsx`;
      b.click();
      wait.close();
    }).catch(() => {
      wait.close();
      this.UI.addToast('Ocorreu um erro ao gerar ficheiro!');
    });
  };

  removeFormador = f => {
    this.UI.showConfirm(`Deseja remover ${f.formador.nome} da lista de formadores atribuídos a esta edição?`).then(res => {
      if (res) {
        // Remove modulos atribuidos ao formador
        this.FormacaoEdicaoLeccionacao.destroyAll({
          where: {
            edicaoId: this.ed,
            formadorId: f.formadorId,
          }
        }).$promise.then(() => {
          // Remove formador association with edition
          this.FormacaoEdicaoAvaliacao.delete(f).$promise.then(() => {
            this.loadData();
            this.UI.addToast('Formador removido com sucesso');
          }).catch(() => {
            this.UI.addToast('Ocorreu um erro ao remover formador da edição');
          });
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao remover formador da edição');
        });
      }
    });
  };

  evaluate = f => {
    let c = angular.copy(f);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./eval-formador.dialog.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {
        $scope.data = {
          desempenho: c.desempenho,
          avaliacaoGlobal: c.avaliacaoGlobal
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.ok = () => {
          $dialog.close($scope.data);
        }
      }]
    });

    dialog.then(res => {
      if (res) {
        c.avaliacaoGlobal = res.avaliacaoGlobal || null;
        c.desempenho = res.desempenho || null;
        this.FormacaoEdicaoAvaliacao.upsert(c).$promise.then(() => {
          this.UI.addToast('Avaliação registada com sucesso');
          this.loadData();
        }).catch((err) => {
          this.UI.addToast('Ocorreu um erro ao registar avaliação');
        })
      }
    })
  };

  notaDiscriminada = nota => {
    if (!nota) {
      return "N/D";
    }
    let info = "";
    if (nota <= 25) {
      info = "Muito Fraco";
    } else if (nota <= 50) {
      info = "Fraco";
    } else if (nota <= 65) {
      info = "Razoável"
    } else if (nota <= 80) {
      info = "Bom";
    } else {
      info = "Muito Bom";
    }
    return `${nota} (${info})`;
  };

  assignModule = (f) => {
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./assign-module.dialog.html'),
      controller: ['$scope', '$dialog', 'FormacaoModulo', ($scope, $dialog, Modulos) => {

        $scope.data = {
          selected: []
        };

        $scope.selectAll = () => {
          $scope.data.selected = [];
          $scope.modulos.forEach(m => {
            $scope.data.selected.push(m);
          });
        };

        $scope.hasLoaded = false;

        // Modulos leccionados
        let leccionados = [];
        if (f.formador.leccionacoes.length > 0) {
          f.formador.leccionacoes.forEach(f => {
            leccionados.push(f.modulo);
          });
        }

        Modulos.find({
          filter: {
            where: {
              cursoId: this.id
            }
          }
        }).$promise.then(modulos => {
          modulos.forEach(m => {
            leccionados.forEach(l => {
              if (m.id === l.id) {
                $scope.data.selected.push(m);
              }
            });
          });
          $scope.modulos = modulos;
          $scope.hasLoaded = true;
        });

        $scope.ok = () => {
          if ($scope.hasLoaded)
            $dialog.close($scope.data);
          else
            $dialog.dismiss('cancel');
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }]
    });

    dialog.then(res => {
      // As module assignment only takes in 2/3 modules on average, it's easier to update this way
      // Remove all first
      this.FormacaoEdicaoLeccionacao.destroyAll({
        where: {
          edicaoId: this.ed,
          formadorId: f.formadorId,
        }
      }).$promise.then(() => {
        // Insert new ones
        if (res.selected.length > 0) {
          res.selected.forEach(r => {
            this.FormacaoEdicaoLeccionacao.create({
              edicaoId: this.ed,
              formadorId: f.formadorId,
              moduloId: r.id
            }).$promise.then().catch(() => {
            });
          });
        }
        this.loadData();
        this.UI.addToast('Atribuição de módulos efetuada com sucesso');
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro ao atualizar lista de atribuição de módulos');
      });
    });
  };

  addFormando = () => {
    let inscricoes = [];
    this.edicao.inscricoes.forEach(i => {
      inscricoes.push(i.formandoId);
    });
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./add-formandos.dialog.html'),
      controller: ['$scope', '$dialog', 'FormacaoFormando', 'FormacaoFormandoEmpresa', 'FormacaoInscricaoEstado', ($scope, $dialog, FormacaoFormando, FormacaoFormandoEmpresa, FormacaoInscricaoEstado) => {
        // Using to filter by property
        $scope.prop = "nome";

        if (this.edicao.tipoId === 1) {
          // Interna
          $scope.prop = "name";
          $scope.nota = "Lista populada por formandos associados ao Grupo IEP.";
        } else if (this.edicao.tipoId === 2) {
          // Intra
          $scope.nota = "Lista populada por formandos associados à empresa.";
        } else {
          $scope.nota = "Lista populada por formandos.";
        }

        //Infinite Scroll Magic
        $scope.infiniteScroll = {};
        $scope.infiniteScroll.numToAdd = 20;
        $scope.infiniteScroll.currentItems = 20;

        $scope.addMoreItems = function () {
          $scope.infiniteScroll.currentItems += $scope.infiniteScroll.numToAdd;
        };

        $scope.hasLoaded = false;

        $scope.data = {
          selected: [],
          estado: {
            selected: undefined
          }
        };

        FormacaoInscricaoEstado.find({}).$promise.then(estados => {
          $scope.data.estado.selected = estados[1];
          $scope.estados = estados;
        });

        let empresa = {};
        if (this.edicao.tipoId === 2) {
          empresa = this.edicao.empresaId;
        } else {
          empresa = {
            inq: [57,66]
          };
        }

        if (this.edicao.tipoId !== 3) {
          FormacaoFormandoEmpresa.find({
            filter: {
              where: {
                active: true,
                empresaId: empresa,
                formandoId: {
                  nin: inscricoes
                }
              },
              include: 'formando',
            }
          }).$promise.then((data) => {
            $scope.formandos = [];
            data.forEach(d => {
              d.formando.name = d.formando.nome;
              $scope.formandos.push(d.formando);
            });
            $scope.hasLoaded = true;
          });
        } else {
          FormacaoFormando.find({
            filter: {
              where: {
                active: true,
                id: {
                  nin: inscricoes
                }
              }
            }
          }).$promise.then(formandos => {
            formandos.forEach(f => {
              f.name = f.nome;
            });
            $scope.formandos = formandos;
            $scope.hasLoaded = true;
          });
        }


        $scope.hasLoaded = false;

        $scope.ok = () => {
          if ($scope.hasLoaded)
            $dialog.close($scope.data);
          else
            $dialog.dismiss('cancel');
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }]
    });

    dialog.then(res => {
      if (res.selected.length > 0) {
        res.selected.forEach(s => {
          let data = {
            id: 0,
            edicaoId: this.ed,
            estadoId: res.estado.selected.id,
            dataInscricao: moment().utc(),
            formandoId: s.id,
            active: true,
          };
          this.FormacaoInscricao.create(data);
        });
        this.loadData();
        this.UI.addToast('Formandos inscritos com sucesso');
      }
    });
  };

  editInscricao = f => {
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./register.dialog.html'),
      controller: ['$scope', '$dialog', 'FormacaoInscricaoEstado', ($scope, $dialog, FormacaoInscricaoEstado) => {
        $scope.hasLoaded = false;

        $scope.estado = {
          selected: {}
        };

        FormacaoInscricaoEstado.find({}).$promise.then(estados => {
          estados.forEach(e => {
            if (f.estadoId === e.id) {
              $scope.estado.selected = e;
            }
          });
          $scope.estados = estados;
          $scope.hasLoaded = true;
        });

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.ok = () => {
          $dialog.close($scope.estado);
        }
      }]
    });

    dialog.then(res => {
      if (res) {
        f.estado = res.selected;
        f.estadoId = res.selected.id;
        this.FormacaoInscricao.upsert(f).$promise.then(() => {
          this.UI.addToast('Estado de inscrição alterado com sucesso');
          this.loadData();
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao alterar estado de inscrição');
        });
      }
    })
  };

  removeFormando = f => {
    this.UI.showConfirm(`Deseja remover ${f.formando.nome} da lista de formandos inscritos a esta edição?`).then(res => {
      if (res) {
        this.FormacaoInscricao.delete(f).$promise.then(() => {
          this.UI.addToast('Inscrição removida com sucesso');
          this.loadData();
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao remover inscrição');
        });
      }
    });
  };
}

FormacaoCursoEdicaoController.$inject = ['$state', 'UIService', 'FormacaoEdicao', 'FormacaoEdicaoAvaliacao', 'FormacaoInscricao', 'FormacaoEdicaoLeccionacao'];
