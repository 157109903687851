import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Breadcrumbs, Button, Chip, CircularProgress, Collapse, IconButton, Link, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';
import { MRT_Localization_PT } from 'material-react-table/locales/pt';
import GraficoExemplo from '../../components/grafico';
import TableData from '../../components/table';
import { MdiIcon } from '@components';
import { mdiChevronDown, mdiChevronUp, mdiPencil, mdiTable } from '@mdi/js';
import Interacoes from '../../components/interacoes';
import DocumentosAssociados from '../../components/documentosAssociados';
import PessoasAssociadasEmpresa from '../../components/pessoasAssociadasEmpresa';
const CrmEmpresasDetailsView = ({ $state, CrmService }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [empresa, setEmpresa] = useState(null);
    const [showTables, setShowTables] = useState(false);
    const [obs, setObs] = useState(false);
    const [block, setBlock] = useState(false);
    const [social, setSocial] = useState(null);
    const fetchData = async () => {
        try {
            const getEmpresa = await CrmService.findEmpresaById($state.params.id);
            //console.log(getEmpresa);
            if (getEmpresa?.redesSociais) {
                const parsedSocial = JSON.parse(getEmpresa.redesSociais);
                setSocial(parsedSocial);
            }
            else {
                setSocial([]);
            }
            setEmpresa(getEmpresa);
        }
        catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        if (empresa == null)
            fetchData();
    }, [empresa]);
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////  Dados
    const dadosPedidosCotacao = [
        { id: 1, area: 'LME', vendedor: 'Pedro', descricao: 'Calibração', data: '04/02/2024' },
        { id: 2, area: 'IE', vendedor: 'Arlindo', descricao: 'Inspeção Elétrica', data: '03/05/2023' },
        { id: 3, area: 'LME', vendedor: 'Andreia', descricao: 'Ensaio', data: '05/03/2022' }
    ];
    const colunasPedidosCotacao = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50
        },
        {
            accessorKey: 'area',
            header: 'Área',
            size: 100
        },
        {
            accessorKey: 'vendedor',
            header: 'Vendedor',
            size: 150
        },
        {
            accessorKey: 'descricao',
            header: 'Descrição',
            size: 200
        },
        {
            accessorKey: 'data',
            header: 'Data',
            size: 100
        }
    ], []);
    const dadosOiProcessos = [
        { id: 1, data: '04/02/2021', servico: 'Ensaio', area: 'LME', observacoes: 'Cliente não gostou do valor' },
        { id: 2, data: '03/03/2024', servico: 'Calibração', area: 'LME', observacoes: 'Esclarecimento de dúvidas cotação' },
        { id: 3, data: '21/02/2024', servico: 'Formação', area: 'FOR', observacoes: 'Cliente pretende ser contactado' }
    ];
    const colunasOiProcessos = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50
        },
        {
            accessorKey: 'data',
            header: 'Data',
            size: 100
        },
        {
            accessorKey: 'servico',
            header: 'Serviço',
            size: 150
        },
        {
            accessorKey: 'area',
            header: 'Área',
            size: 100
        },
        {
            accessorKey: 'observacoes',
            header: 'Observações',
            size: 250
        }
    ], []);
    const dadosOportunidades = [
        { id: 1, servico: 'Ensaio', area: 'LME', observacoes: 'Cliente tem mais ensaios a fazer', colaboradorOrigem: 'Maria' },
        { id: 2, servico: 'Calibração', area: 'LME', observacoes: 'Esclarecimento de dúvidas cotação', colaboradorOrigem: 'Arlindo' },
        { id: 3, servico: 'Formação', area: 'FOR', observacoes: 'Cliente pretende ser contactado', colaboradorOrigem: 'Marjorie' }
    ];
    const colunasOportunidades = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50
        },
        {
            accessorKey: 'servico',
            header: 'Serviço',
            size: 150
        },
        {
            accessorKey: 'area',
            header: 'Área',
            size: 100
        },
        {
            accessorKey: 'observacoes',
            header: 'Observações',
            size: 250
        },
        {
            accessorKey: 'colaboradorOrigem',
            header: 'Colaborador Origem',
            size: 250
        }
    ], []);
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = 'Não há registos a serem exibidos';
    return (<Box sx={{ pl: 1, pr: 1 }}>
      {empresa ? (<Box>
          <Breadcrumbs aria-label='breadcrumb' sx={{ mt: 2 }}>
            <Link underline='hover' color='inherit' href='/'>
              <Typography variant='body2'>CRM</Typography>
            </Link>
            <Link underline='hover' color='inherit' href={'/crm/empresas'}>
              <Typography variant='body2'>Clientes</Typography>
            </Link>
            <Typography variant='body2'>{empresa.nome}</Typography>
          </Breadcrumbs>
          {isLoading ? (<Box sx={{ display: 'flex', justifyContent: 'center', height: '2vh' }}>
              <CircularProgress size={60} color='primary'/>
            </Box>) : (<Grid container spacing={2} justifyContent='flex-start' alignItems='center'>
              <Grid item xs={12} sm={12} md={6} lg={3.5}>
                <Stack sx={{ height: '100%', width: '100%' }}>
                  <Box 
            //onClick={() => window.open('https://www.streamline.pt/', '_blank')}
            component='img' src={empresa.imagem ? `/api/Upload/crm/download/${empresa.imagem}` : '/api/Upload/crm/download/noImage.png'} alt='Uploaded' justifyContent='center' alignItems='flex-start' sx={{
                    maxWidth: '400px',
                    maxHeight: '400px',
                    width: 'auto',
                    height: 'auto'
                }}></Box>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={8.5}>
                <Grid container justifyContent='flex-start' alignItems='center' sx={{ height: '100%' }} spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant='h4' sx={{ color: 'black' }}>
                      {empresa.nome}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Stack direction='row' justifyContent='flex-start' spacing={2} sx={{ ml: -1 }}>
                      {social &&
                    Object.entries(social).map(([key, value]) => (<IconButton key={key} component='a' href={value} target='_blank' rel='noopener noreferrer'>
                            <Box component='img' src={`assets/images/crm_${key}.png`} alt={key} sx={{
                            maxWidth: '22px',
                            maxHeight: '22px',
                            width: 'auto',
                            height: 'auto'
                        }}/>
                          </IconButton>))}
                    </Stack>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={2}>
                    <Stack direction='row' spacing={2} justifyContent='flex-start' alignItems='center'>
                      <Typography variant='body1'>Bloqueado</Typography>
                      {block ? <Chip label='Sim' color='error' size='small'/> : <Chip label='Não' color='success' size='small'/>}
                    </Stack>
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={3}>
                    <Stack direction='row' spacing={2} justifyContent='flex-start' alignItems='center'>
                      <Typography variant='body1'>Bloqueado MyIEP</Typography>
                      {block ? <Chip label='Sim' color='error' size='small'/> : <Chip label='Não' color='success' size='small'/>}
                    </Stack>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Tooltip title='Editar dados' placement='top'>
                      <IconButton color='primary' onClick={() => $state.go('app.crm.empresas.formEdit', { id: $state.params.id })}>
                        <MdiIcon path={mdiPencil} sx={{ color: '#1976D2' }}/>
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={6} sm={6} md={3}>
                    <Typography variant='caption'>Morada</Typography>
                    <Typography sx={{ color: 'black' }}>{!empresa.morada ? 'Desconhecido' : empresa.morada}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <Typography variant='caption'>Contacto</Typography>
                    <Typography sx={{ color: 'black' }}>{!empresa.contactoTelefonico ? 'Desconhecido' : empresa.contactoTelefonico}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={2}>
                    <Typography variant='caption'>NIF</Typography>
                    <Typography sx={{ color: 'black' }}>{!empresa.nif ? 'Desconhecido' : empresa.nif}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Typography variant='caption'>Email</Typography>
                    <Typography sx={{ color: 'black' }}>{!empresa.email ? 'Desconhecido' : empresa.email}</Typography>
                  </Grid>

                  <Grid item xs={6} sm={6} md={3}>
                    <Typography variant='caption'>Setor</Typography>
                    <Typography sx={{ color: 'black' }}>Privado</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <Typography variant='caption'>Condições de pagamento</Typography>
                    <Typography sx={{ color: 'black' }}>30 dias</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={2}>
                    <Typography variant='caption'>CAE</Typography>
                    <Typography sx={{ color: 'black' }}>{!empresa.cae ? 'Desconhecido' : empresa.cae}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={2}>
                    <Typography variant='caption'>Saldo</Typography>
                    <Typography sx={{ color: 'black' }}>63,00 €</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={2}></Grid>
                </Grid>
                {empresa.observacoes ? (<Grid container justifyContent='flex-start' alignItems='flex-start' spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item xs={11} sm={11} md={11}>
                      <Typography variant='caption'>Observações</Typography>
                      <Collapse in={obs} collapsedSize={20}>
                        <Typography sx={{
                        color: 'black',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        transition: 'max-height 0.3s ease-in-out',
                        WebkitLineClamp: obs ? 'none' : 1,
                        whiteSpace: 'pre-wrap'
                    }}>
                          {empresa.observacoes}
                        </Typography>
                      </Collapse>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <Stack justifyContent='flex-start' alignItems='flex-start'>
                        <IconButton aria-label='expand' onClick={() => setObs(!obs)}>
                          <MdiIcon path={obs ? mdiChevronUp : mdiChevronDown} // Alterna o ícone
                 sx={{ color: '#0288d1' }}/>
                        </IconButton>
                      </Stack>
                    </Grid>
                  </Grid>) : (<></>)}
              </Grid>
              <Grid item xs={12}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                  <Typography variant='h6' sx={{ color: 'black' }}>
                    Indicadores
                  </Typography>

                  <Stack direction='row'>
                    <Tooltip title={!showTables ? 'Mostrar tabelas' : 'Esconder tabelas'}>
                      <Switch onChange={() => setShowTables(!showTables)}/>
                    </Tooltip>
                    <MdiIcon path={mdiTable} sx={{ color: showTables ? '#0288d1' : 'default' }}></MdiIcon>
                  </Stack>
                </Stack>
                <Box>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <GraficoExemplo title={'Processos em aberto €'} bgColor={'rgba(250, 20, 20, 0.2)'} borderColor={'rgba(250, 20, 20, 1)'} type={'line'} showTable={showTables}/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <GraficoExemplo title={'Valor propostas emitidas €'} bgColor={'rgba(75, 192, 75, 0.2)'} borderColor={'rgba(75, 192, 75, 1)'} type={'line'} showTable={showTables}/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <GraficoExemplo title={'Volume faturado €'} bgColor={'rgba(20, 60, 245, 0.2)'} borderColor={'rgba(20, 60, 245, 1)'} type={'line'} showTable={showTables}/>
                      </Grid>

                      <Grid item xs={false} sm={false} md={2}></Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <GraficoExemplo title={'Propostas em aberto'} bgColor={'rgba(255, 165, 0, 0.2)'} borderColor={'rgba(255, 165, 0, 1)'} type={'bar'} showTable={showTables}/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <GraficoExemplo title={'PCA'} bgColor={'rgba(150, 0, 255, 0.2)'} borderColor={'rgba(150, 0, 255, 1)'} type={'bar'} showTable={showTables}/>
                      </Grid>
                      <Grid item xs={false} sm={false} md={2}></Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Stack direction='column' spacing={4}>

                  <PessoasAssociadasEmpresa $state={$state} CrmService={CrmService} client={empresa.id}/>

                  <Interacoes $state={$state} CrmService={CrmService} client={empresa.id}/>



                  <TableData title={'Pedidos de cotação'} columns={colunasPedidosCotacao} data={dadosPedidosCotacao} addButton={true}></TableData>

                  <Button onClick={() => $state.go('app.crm.cotacoes.formCreate')} variant='contained'> Teste </Button>

                  <TableData title={'Propostas'} columns={colunasPedidosCotacao} data={dadosPedidosCotacao} folder={true}></TableData>

                  <TableData title={'OI & Processos em curso'} columns={colunasOiProcessos} data={dadosOiProcessos}></TableData>

                  <TableData title={'Oportunidades'} columns={colunasOportunidades} data={dadosOportunidades} addButton={true}></TableData>

                  <DocumentosAssociados $state={$state} CrmService={CrmService} client={empresa.id}/>
                </Stack>
              </Grid>
            </Grid>)}
        </Box>) : (<Stack direction='row' justifyContent='center' alignItems='flex-start' sx={{ mt: 4 }}>
          <CircularProgress size={50}/>
        </Stack>)}
    </Box>);
};
export default CrmEmpresasDetailsView;
