export default class DetailPedidosController {
  constructor($rootScope, $q, $state, $http, $filter, AuthorizationService, FileUploader, AuthenticationService, $sce, $stateParams, $timeout, Pedidoinspeccao, Pagamento, Tipopagamento, Estado, Alteracaoestado, Funcionario, Tecnico, Dadosinstalacao, Dadosfactura, Fraccao, Tipoimovel, TipoDocumento, PedidoDocumento, Group, Agendamento, UIService, $anchorScroll, $location, Promotor, Projeto, Exploracao, IeRelatorio) {
    this.$rootScope = $rootScope;
    this.$q = $q;
    this.$state = $state;
    this.$http = $http;
    this.$filter = $filter;
    this.AuthorizationService = AuthorizationService;
    this.FileUploader = FileUploader;
    this.user = AuthenticationService.getUser();
    this.$sce = $sce;
    this.id = Number.parseInt($stateParams.id);
    this.$timeout = $timeout;
    this.UI = UIService;
    this.Pedidoinspeccao = Pedidoinspeccao;
    this.Pagamento = Pagamento;
    this.Tipopagamento = Tipopagamento;
    this.Estado = Estado;
    this.Alteracaoestado = Alteracaoestado;
    this.Funcionario = Funcionario;
    this.Tecnico = Tecnico;
    this.Promotor = Promotor;
    this.Projeto = Projeto;
    this.Exploracao = Exploracao;
    this.Dadosinstalacao = Dadosinstalacao;
    this.Dadosfactura = Dadosfactura;
    this.Dadosinstalacao = Dadosinstalacao;
    this.Fraccao = Fraccao;
    this.Tipoimovel = Tipoimovel;
    this.TipoDocumento = TipoDocumento;
    this.PedidoDocumento = PedidoDocumento;
    this.Group = Group;
    this.Agendamento = Agendamento;
    this.IeRelatorio = IeRelatorio;
    this.$anchorScroll = $anchorScroll;
    this.$location = $location;
    this.dataLoaded = false;
    this.anexosLoading = true;

    // HereMap Stuff
    this.mapLoading = true;
    this.hereOptions = {
      width: 500,
      height: 300,
      draggable: true,
      resize: true,
      zoom: 18,
      maxZoom: 2,
      coords: {
        latitude: 0,
        longitude: 0
      }
    };
    this.hereMarkers = [];

    // Variables to trigger map refresh when loading
    this.instalacaoMapLoaded = false;
    this.instalacaoMapDataLoaded = false;

    this.hereMap = null;

    // Current pedido
    this.r = {};

    // Data for modals
    this.estados = null;
    this.tiposimovel = null;
    this.tipospagamento = null;

    this.funcionario = {};
    this.docs = [];
    this.fraccoes = [];
    this.alteracaoestado = {};
    this.designacao = '';
    // By default, envURL is production (to account for fails)
    this.envURL = "https://inspecoeseletricas.pt";

    // Get environment for URL for files
    // Doing this here only because we only use this.envURL in the view
    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      if (data != null && data.environment != null) {
        let env = data.environment;
        if (env === 'staging') {
          this.envURL = "https://staging-ie.iep.pt"
        } else if (env === 'production') { // For completeness
          this.envURL = "https://inspecoeseletricas.pt";
        } else {
          this.envURL = "https://dev-iep-ie.streamline.pt";
        }
      }
    });

    this.hasPerms().then(x => {
      // Dados de edição
      this.filterRevisions = true;
      this.getRequest();
      this.getEstados();
      this.getTipoimovel();
      this.getTipopagamento();
    }).catch(() => {
      $state.go($state.previous.name, $state.previous.params);
    });
  }

  syncFraccoes = () => {
    let wait = this.UI.showWaiting();
    this.Dadosinstalacao.syncFraccoes({
      id: this.r.Dadosinstalacao.id
    }).$promise.then(r => {
      wait.close();
      this.$state.refresh();
      this.UI.addToast("Frações sincronizadas com dados de instalação");
    })
  };

  hasPerms = () => {
    let defer = this.$q.defer();
    if (this.AuthorizationService.canPerform('pedidoIeListAll')) {
      defer.resolve(true);
    } else {
      this.Pedidoinspeccao.findOne({
        filter: {
          fields: { id: true },
          where: {
            id: this.id
          },
          include: {
            relation: 'Agendamento',
            scope: {
              fields: { tecnicoId: true },
              where: {
                active: 1
              }
            }
          }
        }
      }).$promise.then((p) => {
        if (p && p.Agendamento && p.Agendamento.tecnicoId === this.user.id) {
          defer.resolve(true);
        } else {
          defer.reject(false);
          this.UI.addToast("Sem permissões para ver o pedido");
        }
      }).catch(() => {
        defer.reject(false);
        this.UI.addToast("Pedido não encontrado");
      });
    }

    return defer.promise;
  };

  showOlderRevisions = () => {
    this.filterRevisions = !this.filterRevisions;
    this.showRelatorios();
  };

  // Use array outputRelatorios to show the latest ones or all
  showRelatorios = () => {
    if (this.filterRevisions) {
      if (this.r.Relatorios.length <= 0)
        this.r.outputRelatorios = [];
      this.r.outputRelatorios = Object.values(this.r.Relatorios.reduce((r, o) => (o.versao < (r[o.identificacao] || {}).versao || (r[o.identificacao] = o), r), {}));
    } else
      return this.r.outputRelatorios = this.r.Relatorios;
  };


  generateIID = () => {
    // Generate iid automatically
    let identificacaoInterna = moment.utc().format("YYYYMM").toString();
    this.Pedidoinspeccao.findOne({
      filter: {
        where: {
          identificacaoInterna: {
            like: identificacaoInterna + "%"
          }
        },
        order: 'identificacaoInterna desc'
      }
    }).$promise.then(lastId => {
      if (lastId) {
        let num = lastId.identificacaoInterna.slice(-4);
        num = Number(num) + 1;
        identificacaoInterna += ("0000" + num).slice(-4);
      } else {
        identificacaoInterna += "0001";
      }
      this.r.identificacaoInterna = identificacaoInterna;
      this.r.estadoId = 2;
      delete this.r.estado;
      this.r.$save().then(() => {
        this.getRequest();
        this.getEstados();
        this.getTipoimovel();
        this.getTipopagamento();
      });
    }).catch(err => {
      this.r.identificacaoInterna = identificacaoInterna + "0001";
      this.r.$save().then(() => {
        this.getRequest();
        this.getEstados();
        this.getTipoimovel();
        this.getTipopagamento();
        this.UI.addToast('Número de identificação interna atualizado');
      }).catch(() => {
        this.UI.addToast('Erro ao gerar número de identificação interna');
      });
    });
  };

  giveIID = () => {
    if (this.r.estadoId === 1) {
      let result = this.UI.showConfirm('Não existe informação de pagamento para este pedido. Deseja continuar?');
      result.then(res => {
        if (res) {
          this.generateIID();
        }
      });
    } else {
      this.generateIID();
    }
  };

  // Get all Tipospagamento for the modal
  getTipopagamento = () => {
    this.Tipopagamento.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((tipos) => {
      this.tipospagamento = tipos;
    }).catch((error) => {
      console.log(error);
    });
  };

  // Get all Tipoimovel for the modal
  getTipoimovel = () => {
    this.Tipoimovel.find({
      filter: {
        where: {
          active: 1
        },
        order: 'designacao ASC'
      },
    }).$promise.then((tipos) => {
      this.tiposimovel = tipos;
    }).catch((error) => {
      console.log(error);
    });
  };

  // Get all Estados for the modal
  getEstados = () => {
    this.Estado.find({
      filter: {
        where: {
          active: 1
        },
        order: 'ordem ASC'
      }
    }).$promise.then((estados) => {
      this.estados = estados;
    }).catch((error) => {
      console.log(error);
    });
  };

  // Report status
  getReportStatus = (rel) => {
    if (!this.dataLoaded) {
      rel.reportStyle = {};
      return "";
    } else {
      if (!rel.json.realizada) {
        rel.reportStyle = {
          fontWeight: 500,
          color: '#d32f2f'
        };
        return `Instalação Reprovada (Motivo: ${rel.observacoes || ' -'})`.toUpperCase();
      } else if (rel.json.deficiencias.some(d => d.deficiencia.tipoId === 4 && (d.r === 0 || d.r === 2))) {
        // Procura se tem deficiencias do tipo G
        rel.reportStyle = {
          fontWeight: 500,
          color: '#d32f2f'
        };
        return "Instalação Reprovada".toUpperCase();
      } else if (rel.json.deficiencias.some(d => d.deficiencia.tipoId === 1 && (d.r === 0 || d.r === 2))) {
        // Procura se tem deficiencias do tipo NG-1
        rel.reportStyle = {
          fontWeight: 500,
          color: '#ffa000'
        };
        return "Inst.c/deficiências para superar em 60 dias".toUpperCase();
      } else if (rel.json.deficiencias.some(d => (d.deficiencia.tipoId === 2 || d.deficiencia.tipoId === 3) && (d.r === 0 || d.r === 2))) {
        // Procura se tem deficiencias do tipo NG-2(D)
        rel.reportStyle = {
          fontWeight: 500,
          color: '#388e3c'
        };
        return "Instalação Aprovada, com deficiências".toUpperCase();
      } else {
        rel.reportStyle = {
          fontWeight: 500,
          color: '#388e3c'
        };
        return "Instalação Aprovada".toUpperCase();
      }
    }
  };

  editReference = () => {
    let paymentInfo = angular.copy(this.r.Pagamento);
    this.UI.showDialog({
      template: require('./modal-edit-referencia.html'),
      controller: ['$scope', $scope => {
        $scope.data = paymentInfo;

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };

        $scope.ok = () => {
          $scope.$close($scope.data);
        };
      }]
    }).then(res => {
      this.Pagamento.upsert(res).$promise.then(() => {
        this.getRequest();
        this.UI.addToast("Informação de pagamento alterada");
      }).catch(() => {
        this.getRequest();
        this.UI.addToast("Erro ao alterar informação de pagamento");
      });
    });
  };

  // Fetch pedido for this detail view
  getRequest = (msg) => {
    this.dataLoaded = false;
    if (this.id) {
      this.Pedidoinspeccao.findOne({
        filter: {
          where: {
            id: this.id,
            active: 1
          },
          include: [
            {
              relation: 'Relatorios',
              scope: {
                order: 'identificacao DESC, versao DESC'
              }
            },
            {
              relation: 'Promotor',
              scope: {
                where: {
                  active: 1
                }
              }
            },
            {
              relation: 'Projeto',
              scope: {
                where: {
                  active: 1
                }
              }
            },
            {
              relation: 'Exploracao',
              scope: {
                where: {
                  active: 1
                }
              }
            },
            {
              relation: 'Requisitante',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'Estado',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'Tecnico',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'Dadosfactura',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'Dadosinstalacao',
              scope: {
                include: [{
                  relation: 'Tipoimovel',
                  where: {
                    active: 1
                  }
                }, {
                  relation: 'Fraccao',
                  scope: {
                    where: {
                      active: 1
                    },
                    include: 'Tipoimovel'
                  }
                }]
              }
            }, {
              relation: 'PedidoDocumento',
              scope: {
                where: {
                  active: 1
                },
                order: 'tipodocumentoId ASC, nome ASC',
                include: [{
                  relation: 'TipoDocumento',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }, {
                  relation: 'fracao',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }]
              }
            }, {
              relation: 'Pagamento',
              scope: {
                include: {
                  relation: 'Tipopagamento',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }
              }
            }, {
              relation: 'Alteracaoestado',
              scope: {
                order: 'dataAlteracao DESC',
                limit: 3,
                include: ['EstadoInicial', 'EstadoFinal', 'Funcionario']
              }
            }, {
              relation: 'Agendamento',
              scope: {
                where: {
                  active: 1
                },
                include: ['Funcionario', 'AgendadoPor']
              }
            }
          ]
        }
      }).$promise.then((res) => {
        this.r = res;

        // Código Postal Instalação
        if (this.r.Dadosinstalacao != null && this.r.Dadosinstalacao.codigoPostal1 != null && this.r.Dadosinstalacao.codigoPostal2 != null) {
          this.cpInstalacaoLayout = this.r.Dadosinstalacao.codigoPostal1 + '-' + this.r.Dadosinstalacao.codigoPostal2
        } else {
          this.cpInstalacaoLayout = 'N/D';
        }

        // Código Postal facturação
        if (this.r.Dadosfactura != null && this.r.Dadosfactura.codigoPostal1 != null && this.r.Dadosfactura.codigoPostal2 != null) {
          this.cpFaturacaoLayout = this.r.Dadosfactura.codigoPostal1 + '-' + this.r.Dadosfactura.codigoPostal2;
        } else {
          this.cpFaturacaoLayout = 'N/D';
        }

        // Código Postal técnico
        if (this.r.Tecnico != null && this.r.Tecnico.codigoPostal1 != null && this.r.Tecnico.codigoPostal2 != null) {
          this.cpTecnicoLayout = this.r.Tecnico.codigoPostal1 + '-' + this.r.Tecnico.codigoPostal2;
        } else {
          this.cpTecnicoLayout = 'N/D';
        }

        // We need to parse morada to exclude extraneous chars. Use global filter
        let parseMorada = this.$filter('removeAcentos')(this.r.Dadosinstalacao.morada);
        parseMorada = parseMorada.replace(/[^a-zA-Z0-9]/g, ' ');
        // Get map coordinates for CPs
        let mapsUrl = '/api/codigopostal?cp1=' +
          this.r.Dadosinstalacao.codigoPostal1 + "&cp2=" + this.r.Dadosinstalacao.codigoPostal2 + "&cp3=" + (this.r.Dadosinstalacao.distrito ? this.r.Dadosinstalacao.distrito : this.r.Dadosinstalacao.codigoPostal3) +
          "&gps=1&morada=" + parseMorada;

        this.$http.get(mapsUrl).then((result) => {
          if (result.data != null && !result.data.hasOwnProperty('error')) {
            this.instalacaoLat = result.data.lat;
            this.instalacaoLng = result.data.lng;
            this.instalacaoRelevance = result.data.relevance;
            this.instalacaoMapDataLoaded = true;
            if (this.instalacaoMapLoaded) {
              this.onMapDataReady(this.instalacaoLat, this.instalacaoLng, this.instalacaoRelevance);
            }
          } else {
            // Error happened, don't show map...
            this.instalacaoMapDataLoaded = false;
          }
        }, (err) => {
          console.log(err);
        });

        this.fraccoes = this.r.Dadosinstalacao.Fraccao;

        if (this.r.Pagamento != null) {
          this.desconto = this.r.Pagamento.desconto;
          this.date = new Date(this.r.Pagamento.dataLimite);
          this.dataLimite2 = moment(this.date).subtract(6, 'd');
          this.dataLimite2 = moment(this.dataLimite2).format('DD-MM-YYYY');
          this.ref = this.r.Pagamento.referencia.toString();
          this.ref = this.ref.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ');
        } else {
          this.desconto = 0;
          this.date = null;
          this.dataLimite2 = null;
          this.ref = null;
        }
        // Parse relatorios to parse JSON object
        this.r.Relatorios.forEach(rel => {
          rel.json = JSON.parse(rel.json);
        });
        // Set which relatorios are shown
        this.r.outputRelatorios = [];
        this.showRelatorios();

        this.docs = this.r.PedidoDocumento;
        // Get extra docs information
        this.getAnexosInfo();

        this.anexosLoading = false;

        this.dataLoaded = true;
        // If we have an update instead of a normal first read
        if (msg)
          this.UI.addToast(msg);
      }).catch((err) => {
        console.log(err);
        this.UI.addToast("Não é possível carregar pedido neste momento. Por favor tente mais tarde.");
        this.$state.go('app.pedidos.list');
      });
    } else { // id is undefined, just go to list
      this.$state.go('app.pedidos.list');
    }
  };

  onMapReady = (heremap) => {
    this.hereMap = heremap;
    this.instalacaoMapLoaded = true;
    if (this.instalacaoMapDataLoaded)
      this.onMapDataReady(this.instalacaoLat, this.instalacaoLng, this.instalacaoRelevance);
  };

  // Called when we have both a map and the data to show it on
  onMapDataReady = (lat, lng, relevance) => {

    if (this.hereMap != null) {
      this.mapLoading = false;
      let localMap = this.hereMap;

      // Using timeout so that we render it after it becomes visible...
      this.$timeout(() => {
        localMap.refresh();
        this.hereMap.setCenter({
          lat: lat,
          lng: lng
        });
        // If we are not exactly sure, zoom out a little more
        if (relevance < 0.85)
          this.hereMap.setZoom(14);
        else {
          // Add marker and zoom if we are reasonably sure...
          this.hereMap.setZoom(18);
          this.hereMarkers = [];
          this.hereMarkers.push({
            pos: {
              lat: lat,
              lng: lng
            }
          });
          this.hereMap.updateMarkers(this.hereMarkers);
        }
      }, 500);
    }
  };

  // Get Anexos access and size information
  getAnexosInfo = () => {
    this.docs.forEach((d) => {
      let url = "";
      if (d.tipodocumentoId < 7 || d.tipodocumentoId > 9)
        url = this.envURL + "/api/Upload/files/files/" + d.nome;
      else {
        url = this.envURL + "/api/Upload/ie-reports/files/" + d.nome;
      }
      this.$http.get(url).then((result) => {
        if (result.data != null && !result.data.hasOwnProperty('error')) {
          d.atime = result.data.atime;
          d.mtime = result.data.mtime;
          d.fileSize = result.data.size;
        }
      }, (err) => {
        console.log(err);
      });
    });
  };

  // Format bytes into correct units
  formatBytes = (bytes, decimals) => {
    if (bytes == null) return "N/D";
    if (bytes === 0) return '0 Bytes';
    let k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  editTipoPagamento = () => {
    if (!this.AuthorizationService.canPerform('pagamentosIeEdit')) {
      this.UI.addToast("Não tem permissões para editar esta informação.");
      return;
    }
    if (this.tipospagamento == null) {
      this.UI.addToast("De momento não é possível editar tipo de pagamento. Pode favor tente mais tarde");
      return;
    }
    let self = this;
    let instance = this.UI.showDialog({
      template: require('./modal-edit-tipo-pagamento.html'),
      controller: ['$scope', function ($scope) {
        // Data to change on the modal
        $scope.dataPagamento = self.r.Pagamento.dataPagamento;
        $scope.newTipoPagamento = self.r.Pagamento.Tipopagamento;
        $scope.auxTipoPagamento = self.tipospagamento;

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      // let changed = this.checkDataChanges(ok.r);

      if (ok.newTipoPagamento != null) {
        this.dataLoaded = false;
        this.Pagamento.findOne({
          filter: {
            where: {
              id: this.r.Pagamento.id
            }
          }
        }).$promise.then((pagamento) => {
          // Update pagamento model and save
          pagamento.dataPagamento = ok.dataPagamento;
          pagamento.Tipopagamento = ok.newTipoPagamento;
          pagamento.tipoPagamentoId = ok.newTipoPagamento.id;
          pagamento.$save().then((p) => {
            // Just to be safe, we are reloading everything...
            this.getRequest("Tipo de pagamento atualizado com sucesso.");

          }, (e) => {
            this.dataLoaded = true;
            console.log(e);
            this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
          });
        }).catch((error) => {
          this.dataLoaded = true;
          console.log(error);
          this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
        });
      } else {
        this.UI.addToast("Não foi possível atualizar tipo de pagamento. Por favor tente mais tarde.");
      }
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editDet = () => {
    let dialog = this.UI.showDialog({
      template: require('./details.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.estados = this.estados;

        $scope.opt = {
          estado: this.estados.find(r => r.id === this.r.estadoId),
          observacoes: '',

        };

        $scope.ok = () => {
          $scope.$close($scope.opt);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        }
      }]
    });
    dialog.then(res => {
      this.dataLoaded = false;
      // Check if estadoId is different than the chosen one
      this.Pedidoinspeccao.findOne({
        filter: {
          where: {
            id: this.r.id
          },
          include: {
            relation: 'PedidoDocumento',
            scope: {
              where: {
                and: [{ active: 1 }, { tipodocumentoId: 8 }] // Only fetch finished reports, for state 7 check
              }
            }
          }
        }
      }).$promise.then((old) => {

        if (res.estado.id !== old.estadoId) {
          if (res.estado.id === 2) { // Change to "Aguarda Agendamento"
            if (old.estadoId === 1) { // Vindo de "Aguarda Pagamento", coloca pedido como "Pago"
              this.Pagamento.findOne({
                filter: {
                  where: {
                    pedidoId: this.r.id,
                    active: true
                  }
                }
              }).$promise.then(p => {
                if (p) {
                  p.pago = 1;
                  p.$save();
                  this.changeState(res);
                }
              }).catch(() => {
                // Do nothing
              });
            } else { // Qualquer outro estado para este, apaga agendamento, se existir
              this.Agendamento.removeAgendamento({
                params: {
                  pedidoId: this.r.id
                }
              }).$promise.then(() => {
                this.changeState(res);
              }).catch(error => {
                console.log(error);
                let msg = "Ocorreu um erro na alteração de estado";
                if (error && error.data && error.data.error) {
                  msg += ": ";
                  msg +=  error.data.error.details ? error.data.error.details.message : error.data.error.message;
                }
                let alert = this.UI.showAlert(msg);
                alert.finally(() => {
                  this.getRequest(""); // Reload just in case
                });
              });
            }
          } else if (res.estado.id === 9) { // Change to 'Certificado Emitido'
            // Only go ahead if we have permissions
            if (!this.AuthorizationService.canPerform('reportsIeValidate')) {
              this.getRequest("Não tem permissões para efetuar essa alteração.");
              return;
            }
            this.Pedidoinspeccao.createCertificate({ id: this.r.id }).$promise.then(p => {
              this.UI.addToast("Certificado criado com sucesso");
              this.changeState(res);
            }).catch(error => {
              console.log(error);
              this.UI.showAlert("Erro na geração de certificado.\n\nVerifique se todos os relatórios de frações existem");
            });
          } else if (res.estado.id === 7) { // Pedido concluído
            if (old.PedidoDocumento && old.PedidoDocumento.length > 0) {
              // Email report to requisitante
              this.Pedidoinspeccao.sendReport({ id: this.r.id }).$promise.then(p => {
                this.UI.addToast("Email de relatório enviado.");
                this.changeState(res);
              }).catch(error => {
                console.log(error);
                this.UI.showAlert("Não foi possível enviar email com o relatório para o requisitante.");
                this.getRequest();
              });
            } else { // No final report found, asking for confirmation to finish and make observations mandatory
              this.confirmConclusionWithoutReport(res);
            }
          } else { // All other cases, just change state
            this.changeState(res);
          }
        } else {
          this.UI.addToast("O pedido já se encontra neste estado.");
          this.getRequest();
          this.getEstados();
          this.getTipoimovel();
          this.getTipopagamento();
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível encontrar este pedido. Recarregue a página");
        this.getRequest();
      });
    });
  };

  // Does the change state so it can be called in multiple places
  changeState = (res) => {
    this.Alteracaoestado.to({
      id: this.id,
      state: res.estado.id,
      userId: this.user.id,
      obs: res.observacoes
    }).$promise.then(r => {
      this.UI.addToast('Alteração de estado efetuada com sucesso');
      this.getRequest();
      this.getEstados();
      this.getTipoimovel();
      this.getTipopagamento();
    }).catch(() => {
      this.UI.addToast('Ocorreu um erro ao alterar estado do pedido');
      this.getRequest();
      this.getEstados();
      this.getTipoimovel();
      this.getTipopagamento();
    });
  };

  // Open dialog to confirm concluding process without report
  confirmConclusionWithoutReport = (res) => {
    let confirmDialog = this.UI.showDialog({
      template: require('./modal-confirm-conclusion.html'),
      controller: ['$scope', $scope => {

        $scope.observacoes = res.observacoes;

        $scope.ok = () => {
          $scope.$close($scope.observacoes);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        }
      }]
    });
    confirmDialog.then(confirmation => {
      if (confirmation) {
        res.observacoes = confirmation;
        this.changeState(res);
      } else { // Should never happen
        this.UI.addToast("Observações são obrigatórias");
        this.dataLoaded = true;
      }
    }).catch(err => { // Cancelled, return to viewing the process
      this.dataLoaded = true;
    });
  };

  createAlteracaoEstado = (oldEstado, ok) => {
    this.Alteracaoestado.create({
      id: 0,
      dataAlteracao: new Date(),
      funcionarioId: this.user.id,
      pedidoId: this.r.id,
      estadoInicial: oldEstado,
      estadoFinal: this.r.Estado.id,
      observacoes: ok.newEstadoObservacoes,
      active: 1
    }).$promise.then((res) => {
      this.UI.addToast("Estado do pedido alterado com sucesso.");
      // Process other modal info for pedido specific data

      // Check if we have differences to save for differences
      if (this.r.identificacaoInterna !== ok.detalhes.identificacaoInterna || oldEstado !== this.r.estadoId) {
        // If string values are empty, use NULL so it's db consistent
        this.r.identificacaoInterna = _.isEmpty(ok.detalhes.identificacaoInterna) ? null : ok.detalhes.identificacaoInterna;

        // If changed, save data
        this.r.$save().then((r) => {
          // Update the related model because it vanishes upon $save()
          this.dataLoaded = false;
          this.getRequest("Pedido atualizado com sucesso.");
        }, (error) => {
          console.log(error);
          this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
        });
      } else {
        // We didn't change the request, only alteração de estado, just update anyway
        this.dataLoaded = false;
        this.getRequest();
      }
    }).catch((error) => {
      this.dataLoaded = true;
      console.log(error);
      this.UI.addToast("Não foi possível guardar a alteração de estado. Por favor tente mais tarde.");
    });

  };

  editObservacoes = () => {
    let self = this;
    let instance = this.UI.showDialog({
      template: require('./modal-edit-observacoes.html'),
      controller: ['$scope', function ($scope) {
        // Data to change on the modal
        $scope.pedido = {};
        $scope.pedido.observacoes = self.r.observacoes;

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      // let changed = this.checkDataChanges(ok.r);
      // If string values are empty, use NULL so it's db consistent
      this.r.observacoes = _.isEmpty(ok.pedido.observacoes) ? null : ok.pedido.observacoes;

      this.dataLoaded = false;
      // If changed, save data
      this.r.$save().then((r) => {
        // Update the related model because it vanishes upon $save()
        this.dataLoaded = false;
        this.getRequest("Pedido atualizado com sucesso.");

        // if (changed)
        //   this.UI.addToast(" Atualizado.");
      }, (error) => {
        this.dataLoaded = true;
        this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editTecnico = () => {
    let self = this;
    let instance = this.UI.showDialog({
      template: require('./modal-edit-tecnico.html'),
      controller: ['$scope', '$http', function ($scope, $http) {
        // Data to change on the modal
        $scope.tecnico = {};
        $scope.tecnico.nome = self.r.Tecnico.nome;
        $scope.tecnico.nif = self.r.Tecnico.nif;
        $scope.tecnico.nTecnico = self.r.Tecnico.nTecnico;
        $scope.tecnico.email = self.r.Tecnico.email;
        $scope.tecnico.telemovel = self.r.Tecnico.telemovel;
        $scope.tecnico.morada = self.r.Tecnico.morada;
        $scope.tecnico.codigoPostal1 = self.r.Tecnico.codigoPostal1;
        $scope.tecnico.codigoPostal2 = self.r.Tecnico.codigoPostal2;
        $scope.tecnico.codigoPostal3 = self.r.Tecnico.codigoPostal3;
        $scope.tecnico.impic = self.r.Tecnico.impic;
        $scope.tecnico.nomeEi = self.r.Tecnico.nomeEi;
        $scope.tecnico.nDgegEi = self.r.Tecnico.nDgegEi;

        $scope.getLocalidade = function () {
          if ($scope.tecnico.codigoPostal1 && $scope.tecnico.codigoPostal1.toString().length === 4) {
            $http.post('/api/public/getLocalidade', {
              cp4: $scope.tecnico.codigoPostal1,
              cp3: $scope.tecnico.codigoPostal2
            }).then(response => {
              $scope.tecnico.codigoPostal3 = response.data.result;
              $scope.tecnico.concelho = response.data.concelho;
              $scope.tecnico.distrito = response.data.distrito;
            });
          }
        };

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      // let changed = this.checkDataChanges(ok.r);
      this.dataLoaded = false;
      this.Tecnico.findOne({
        filter: {
          where: {
            id: this.r.Tecnico.id
          }
        }
      }).$promise.then((tecnico) => {
        // Update tecnico model and save
        // Process strings from modal (we guarantee they are not null)
        tecnico.nome = ok.tecnico.nome;
        tecnico.nTecnico = ok.tecnico.nTecnico;
        tecnico.email = ok.tecnico.email;
        tecnico.nif = ok.tecnico.nif;
        tecnico.telemovel = ok.tecnico.telemovel;
        tecnico.morada = ok.tecnico.morada;
        tecnico.codigoPostal1 = ok.tecnico.codigoPostal1;
        tecnico.codigoPostal2 = ok.tecnico.codigoPostal2;
        tecnico.codigoPostal3 = ok.tecnico.codigoPostal3;
        tecnico.impic = ok.tecnico.impic;
        tecnico.nomeEi = ok.tecnico.nomeEi;
        tecnico.nDgegEi = ok.tecnico.nDgegEi;

        tecnico.$save().then((p) => {
          // Just to be safe, we are reloading everything...
          this.getRequest("Dados de Técnico atualizados com sucesso.");

        }, (e) => {
          this.dataLoaded = true;
          console.log(e);
          this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
        });
      }).catch((error) => {
        this.dataLoaded = true;
        console.log(error);
        this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editDadosFatura = () => {
    let self = this;
    let instance = this.UI.showDialog({
      template: require('./modal-edit-dadosfatura.html'),
      controller: ['$scope', '$http', function ($scope, $http) {
        // Data to change on the modal
        $scope.dadosfatura = {};
        $scope.dadosfatura.nome = self.r.Dadosfactura.nome;
        $scope.dadosfatura.nif = self.r.Dadosfactura.nif;
        $scope.dadosfatura.telefone = self.r.Dadosfactura.telefone;
        $scope.dadosfatura.email = self.r.Dadosfactura.email;
        $scope.dadosfatura.morada = self.r.Dadosfactura.morada;
        $scope.dadosfatura.codigoPostal1 = self.r.Dadosfactura.codigoPostal1;
        $scope.dadosfatura.codigoPostal2 = self.r.Dadosfactura.codigoPostal2;
        $scope.dadosfatura.codigoPostal3 = self.r.Dadosfactura.codigoPostal3;
        $scope.dadosfatura.concelho = self.r.Dadosfactura.concelho;
        $scope.dadosfatura.distrito = self.r.Dadosfactura.distrito;

        $scope.getLocalidade = function () {
          if ($scope.dadosfatura.codigoPostal1 && $scope.dadosfatura.codigoPostal1.toString().length === 4) {
            $http.post('/api/public/getLocalidade', {
              cp4: $scope.dadosfatura.codigoPostal1,
              cp3: $scope.dadosfatura.codigoPostal2
            }).then(response => {
              $scope.dadosfatura.codigoPostal3 = response.data.result;
              $scope.dadosfatura.concelho = response.data.concelho;
              $scope.dadosfatura.distrito = response.data.distrito;
            });
          }
        };

        // Copied and adapted from IE project
        // Returns only true or false depending on validity of NIF
        $scope.validNIF = (nif) => {
          // Returning true to hide message (there's no point of testing if nif is undefined or not with correct length)
          // WARNING: RELIES ON MINLENGTH to be defined and VERIFIED!!
          if (nif === undefined || nif.length < 9) {
            return false;
          }
          let comparador = 0;
          nif = nif.toString();
          // Alternative ECMA2016
          // if(!['1', '2', '3', '5', '6', '8'].includes(nif.substr(0,1)) &&
          // 	!['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0,2))) {
          // Using for IE compatibility
          if (!(['1', '2', '3', '5', '6', '8'].indexOf(nif.substr(0, 1)) > -1) &&
            !(['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].indexOf(nif.substr(0, 2)) > -1)) {
            return false;
          }
          let total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
          let modulo11 = total - parseInt(total / 11) * 11;
          if (modulo11 == 1 || modulo11 == 0)
            comparador = 0;
          else
            comparador = 11 - modulo11;
          return nif[8] == comparador;
        };

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      // let changed = this.checkDataChanges(ok.r);
      this.dataLoaded = false;
      this.Dadosfactura.findOne({
        filter: {
          where: {
            id: this.r.Dadosfactura.id
          }
        }
      }).$promise.then((dadosfatura) => {
        // Update tecnico model and save
        // Process strings from modal (we guarantee they are not null)

        dadosfatura.nome = ok.dadosfatura.nome;
        dadosfatura.nif = ok.dadosfatura.nif;
        dadosfatura.telefone = ok.dadosfatura.telefone;
        dadosfatura.email = ok.dadosfatura.email;
        dadosfatura.morada = ok.dadosfatura.morada;
        dadosfatura.codigoPostal1 = ok.dadosfatura.codigoPostal1;
        dadosfatura.codigoPostal2 = ok.dadosfatura.codigoPostal2;
        dadosfatura.codigoPostal3 = ok.dadosfatura.codigoPostal3;
        dadosfatura.concelho = ok.dadosfatura.concelho;
        dadosfatura.distrito = ok.dadosfatura.distrito;

        dadosfatura.$save().then((d) => {
          // Just to be safe, we are reloading everything...
          this.getRequest("Dados de Faturação atualizados com sucesso.");

        }, (e) => {
          this.dataLoaded = true;
          console.log(e);
          this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
        });
      }).catch((error) => {
        this.dataLoaded = true;
        console.log(error);
        this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editDadosInstalacao = () => {
    if (this.tiposimovel == null) {
      this.UI.addToast("De momento não é possível editar instalação. Por favor tente mais tarde.");
      return;
    }
    let self = this;
    let instance = this.UI.showDialog({
      template: require('./modal-edit-dadosinstalacao.html'),
      controller: ['$scope', '$http', function ($scope, $http) {
        // Data to change on the modal
        $scope.dadosinstalacao = {};

        $scope.tiposimovel = self.tiposimovel;
        $scope.auxTipo = self.r.Dadosinstalacao.Tipoimovel;
        $scope.dadosinstalacao.morada = self.r.Dadosinstalacao.morada;
        $scope.dadosinstalacao.codigoPostal1 = self.r.Dadosinstalacao.codigoPostal1;
        $scope.dadosinstalacao.codigoPostal2 = self.r.Dadosinstalacao.codigoPostal2;
        $scope.dadosinstalacao.codigoPostal3 = self.r.Dadosinstalacao.codigoPostal3;
        $scope.dadosinstalacao.concelho = self.r.Dadosinstalacao.concelho;
        $scope.dadosinstalacao.distrito = self.r.Dadosinstalacao.distrito;
        $scope.dadosinstalacao.nip = self.r.Dadosinstalacao.nip;
        $scope.dadosinstalacao.potenciaTotalEdificio = self.r.Dadosinstalacao.potenciaTotalEdificio;

        $scope.getLocalidade = function () {
          if ($scope.dadosinstalacao.codigoPostal1 && $scope.dadosinstalacao.codigoPostal1.toString().length === 4) {
            $http.post('/api/public/getLocalidade', {
              cp4: $scope.dadosinstalacao.codigoPostal1,
              cp3: $scope.dadosinstalacao.codigoPostal2
            }).then(response => {
              $scope.dadosinstalacao.codigoPostal3 = response.data.result;
              $scope.dadosinstalacao.concelho = response.data.concelho;
              $scope.dadosinstalacao.distrito = response.data.distrito;
            });
          }
        };

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      // let changed = this.checkDataChanges(ok.r);
      this.dataLoaded = false;
      this.Dadosinstalacao.findOne({
        filter: {
          where: {
            id: this.r.Dadosinstalacao.id
          }
        }
      }).$promise.then((dadosinstalacao) => {
        // Update tecnico model and save
        // Process strings from modal (we guarantee they are not null)
        dadosinstalacao.tipoimovelId = ok.auxTipo.id;
        dadosinstalacao.morada = ok.dadosinstalacao.morada;
        dadosinstalacao.codigoPostal1 = ok.dadosinstalacao.codigoPostal1;
        dadosinstalacao.codigoPostal2 = ok.dadosinstalacao.codigoPostal2;
        dadosinstalacao.codigoPostal3 = ok.dadosinstalacao.codigoPostal3;
        dadosinstalacao.concelho = ok.dadosinstalacao.concelho;
        dadosinstalacao.distrito = ok.dadosinstalacao.distrito;
        dadosinstalacao.nip = ok.dadosinstalacao.nip;
        dadosinstalacao.potenciaTotalEdificio = ok.dadosinstalacao.potenciaTotalEdificio;

        dadosinstalacao.$save().then((d) => {
          // Just to be safe, we are reloading everything...
          this.getRequest("Dados de Instalação atualizados com sucesso.");

        }, (e) => {
          this.dataLoaded = true;
          console.log(e);
          this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
        });
      }).catch((error) => {
        this.dataLoaded = true;
        console.log(error);
        this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editPromotor = () => {
    let instance = this.UI.showDialog({
      template: require('./modal-edit-promotor.html'),
      controller: ['$scope', $scope => {
        if (this.r.Promotor) {
          $scope.promotor = this.r.Promotor;
        } else {
          $scope.promotor = {
            id: 0,
            active: true
          }
        }
        $scope.cancel = () => {
          $scope.$dismiss();
        };

        $scope.ok = () => {
          $scope.$close($scope.promotor);
        }
      }]
    });
    instance.then(r => {
      r.pedidoId = this.id;
      this.Promotor.upsert(r).$promise.then(c => {
        this.getRequest();
        this.UI.addToast("Promotor / Entidade Proprietária / Entidade Exploradora atualizado com sucesso");
      }).catch(() => {
        this.UI.addToast("Ocorreu um erro ao atualizar Promotor / Entidade Proprietária / Entidade Exploradora");
      });
    });
  };

  editProjeto = () => {
    let instance = this.UI.showDialog({
      template: require('./modal-edit-responsavel-projeto.html'),
      controller: ['$scope', $scope => {
        if (this.r.Projeto) {
          $scope.projeto = this.r.Projeto;
        } else {
          $scope.projeto = {
            id: 0,
            active: true
          }
        }
        $scope.cancel = () => {
          $scope.$dismiss();
        };

        $scope.ok = () => {
          $scope.$close($scope.projeto);
        }
      }]
    });
    instance.then(r => {
      r.pedidoId = this.id;
      this.Projeto.upsert(r).$promise.then(c => {
        this.getRequest();
        this.UI.addToast("Técnico Responsável pelo Projeto atualizado com sucesso");
      }).catch(() => {
        this.UI.addToast("Ocorreu um erro ao atualizar Técnico Responsável pelo Projeto");
      });
    });
  };

  editExploracao = () => {
    let instance = this.UI.showDialog({
      template: require('./modal-edit-responsavel-exploracao.html'),
      controller: ['$scope', $scope => {
        if (this.r.Projeto) {
          $scope.exploracao = this.r.Exploracao;
        } else {
          $scope.exploracao = {
            id: 0,
            active: true
          }
        }
        $scope.cancel = () => {
          $scope.$dismiss();
        };

        $scope.ok = () => {
          $scope.$close($scope.exploracao);
        }
      }]
    });
    instance.then(r => {
      r.pedidoId = this.id;
      this.Exploracao.upsert(r).$promise.then(c => {
        this.getRequest();
        this.UI.addToast("Técnico Responsável pela Exploração atualizado com sucesso");
      }).catch(() => {
        this.UI.addToast("Ocorreu um erro ao atualizar Técnico Responsável pela Exploração");
      });
    });
  };

  editFraccao = (f) => {
    if (this.tiposimovel == null) {
      this.UI.addToast("De momento não é possível editar fração. Por favor tente mais tarde.");
      return;
    }
    let instance = this.UI.showDialog({
      template: require('./modal-edit-fraccao.html'),
      controller: ['$scope', ($scope) => {
        $scope.modalTitle = "Editar Fracção";
        // Data to change on the modal
        $scope.fraccao = angular.copy(f);
        $scope.tiposimovel = this.tiposimovel;
        $scope.auxTipo = f.Tipoimovel;

        // Copied and adapted from IE project
        // Validate CPE (20 digits, first 2 and last must be 2 letters, 16 digits in between)
        $scope.validCPE = this.validCPE;

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.dataLoaded = false;
      this.Fraccao.findOne({
        filter: {
          where: {
            id: f.id
          }
        }
      }).$promise.then((fraccao) => {
        // Update fraccao model and save
        // Process strings from modal (we guarantee they are not null)
        fraccao.fracao = ok.fraccao.fracao;
        fraccao.potencia = ok.fraccao.potencia;
        fraccao.monotri = ok.fraccao.monotri;
        if (ok.auxTipo.id === 54) {
          ok.fraccao.cpe = null;
        }
        if (ok.auxTipo.id !== 38) {
          ok.fraccao.observacoes = null;
        }
        if (ok.fraccao.cpe != null)
          ok.fraccao.cpe = ok.fraccao.cpe.toUpperCase();
        fraccao.cpe = ok.fraccao.cpe;
        fraccao.observacoes = ok.fraccao.observacoes;
        fraccao.Tipoimovel = ok.auxTipo;
        fraccao.tipoImovelId = ok.auxTipo.id;

        fraccao.$save().then((d) => {
          // Just to be safe, we are reloading everything...
          this.getRequest("Fracção atualizada com sucesso.");

        }, (e) => {
          this.dataLoaded = true;
          this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
        });
      }).catch((error) => {
        this.dataLoaded = true;
        this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
      });
    }, (err) => { });
  };

  confirmDeleteFraccao = (f) => {
    let title = "Eliminar Fracção";
    let warning = "Tem a certeza que pretende remover esta fracção deste pedido?\nPoderá ter que se eliminar relatórios criados para esta fração.";
    let instance = this.UI.showDialog({
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeFraccao(f);
    }, (err) => { });
  };

  removeFraccao = (f) => {
    this.dataLoaded = false;
    this.Dadosinstalacao.removeFraccao({
      params: {
        pedidoId: this.r.id,
        fraccaoId: f.id
      }
    }).$promise.then(() => {
      this.getRequest("Fração removida com sucesso");
    }).catch(error => {
      console.log(error);
      let msg = "Ocorreu um erro na remoção de fração";
      if (error && error.data && error.data.error) {
        msg += ": ";
        msg +=  error.data.error.details ? error.data.error.details.message : error.data.error.message;
      }
      let alert = this.UI.showAlert(msg);
      alert.finally(() => {
        this.getRequest("");
      });
    });
  };

  addFraccao = () => {
    if (this.tiposimovel == null) {
      this.UI.addToast("De momento não é possível criar frações. Por favor tente mais tarde.");
      return;
    }
    let instance = this.UI.showDialog({
      template: require('./modal-edit-fraccao.html'),
      controller: ['$scope', ($scope) => {
        $scope.modalTitle = "Adicionar Edição";
        // Data to change on the modal
        $scope.fraccao = {};
        $scope.tiposimovel = this.tiposimovel;
        $scope.auxTipo = null;

        $scope.validCPE = this.validCPE;

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.dataLoaded = false;
      this.Dadosinstalacao.findOne({
        filter: {
          where: {
            id: this.r.Dadosinstalacao.id
          }
        }
      }).$promise.then((dadosinstalacao) => {
        // Increment noFraccoes from dadosInstalacao
        dadosinstalacao.noFraccoes++;
        dadosinstalacao.$save().then((newDadosInstalacao) => {
          if (ok.fraccao.tipoimovelId === 54) {
            ok.fraccao.cpe = null;
          }
          if (ok.fraccao.tipoimovelId !== 38) {
            ok.fraccao.observacoes = null;
          }

          //Create new entity
          this.Fraccao.create({
            id: 0,
            fracao: ok.fraccao.fracao,
            potencia: ok.fraccao.potencia,
            dadosinstalacaoId: dadosinstalacao.id,
            monotri: ok.fraccao.monotri,
            cpe: ok.fraccao.cpe ? ok.fraccao.cpe.toUpperCase() : null,
            tipoimovelId: ok.auxTipo.id,
            observacoes: ok.fraccao.observacoes,
            active: 1
          }).$promise.then((res) => {
            // Success. Update pedido
            this.getRequest("Fracção inserida com sucesso.");
          }).catch((errSaveFrac) => {
            // Error saving Fraccao after adding value to dadosinstalacao
            // We should revert the ++ of dadosInstalacao. Using the new var
            newDadosInstalacao.noFraccoes--;
            newDadosInstalacao.$save().then((res) => {
              this.dataLoaded = true;
              this.UI.addToast("Não foi possível inserir nova fracção. Por favor tente mais tarde.");
            }, (errorSaveNewDi) => {
              this.dataLoaded = true;
              this.UI.addToast("Não foi possível inserir nova fracção. Por favor tente mais tarde.");
            });
          });
        }, (errorSaveDi) => {
          this.dataLoaded = true;
          this.UI.addToast("Não foi possível inserir nova fracção. Por favor tente mais tarde.");
        });
      }).catch((error) => {
        this.dataLoaded = true;
        this.UI.addToast("Não foi possível inserir nova fracção. Por favor tente mais tarde.");
      });
    }).catch((err) => { });
  };

  showTipoImovel = (f) => {
    if (f.tipoimovelId === 38) {
      return f.observacoes;
    } else {
      if (f.Tipoimovel) return f.Tipoimovel.designacao;
      else return 'N/D';
    }
  };

  // If data is selected for modal, make sure the correct "Tecnico", "Data", "Hora" is selected if applicable
  // MODIFIED from agendamentos (only one pedido at all times, so simplifications are made
  preFillCorrectModalData = (pedido) => {
    if (pedido == null || pedido.Agendamento == null)
      return {
        data: null,
        hora: null,
        tecnico: null
      };
    return {
      data: pedido.Agendamento.data,
      hora: pedido.Agendamento && pedido.Agendamento.hora ? pedido.Agendamento.hora : null,
      tecnico: pedido.Agendamento.Funcionario
    }
  };

  // Allow changing Inspetor of agendamento without rescheduling to the client
  editarAgendamento = () => {
    let cenas = this.UI.showWaiting();
    this.Group.findOne({
      filter: {
        where: {
          name: {
            like: "Inspetor%IE",
          },
        },
        include: {
          relation: "usergroup",
          scope: {
            include: "user",
          },
        },
      },
    }).$promise.then((g) => {
      let funcionarios = [];
      g.usergroup.forEach((u) => {
        funcionarios.push(u.user);
      });

      this.Pedidoinspeccao.findOne({
        filter: {
          where: {
            id: this.id,
          },
          include: [
            {
              relation: "Agendamento",
              scope: {
                where: {
                  active: 1
                },
                include: {
                  relation: 'Funcionario',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }
              }
            }
          ]
        }
      }).$promise.then((p) => {
        cenas.close();
        if (p.estadoId === 4 && p.Agendamento != null && p.Agendamento.Funcionario != null) { // 4 - Agendado
          let auxFuncionario = angular.copy(p.Agendamento.Funcionario);
          let options = {
            size: "md",
            template: require("./modal-edit-agendamento.html"),
            controller: ["$scope", "$dialog", ($scope, $dialog) => {
              $scope.pedidoId = p.id;
              $scope.agendamento = {
                data: p.Agendamento.data,
                hora: p.Agendamento.hora.substring(0, 5)
              };

              $scope.funcionarios = funcionarios;

              $scope.auxFuncionario = {
                selected: auxFuncionario,
                infiniteScroll: {
                  numToAdd: 20,
                  currentItems: 20
                }
              };

              // Infinite Scroll magic
              $scope.addMoreItems = (infiniteScroll) => {
                infiniteScroll.currentItems += infiniteScroll.numToAdd;
              };

              $scope.ok = () => {
                $dialog.close($scope.auxFuncionario);
              };

              $scope.cancel = () => {
                $dialog.dismiss("cancel");
              };
            }]
          };
          let modal = this.UI.showDialog(options);
          modal.then(ok => {
            if (ok != null && ok.selected.id !== p.Agendamento.tecnicoId) {
              this.dataLoaded = false;
              // Change Agendamento object
              p.Agendamento.Funcionario = ok.selected;
              p.Agendamento.tecnicoId = ok.selected.id;
              p.Agendamento.agendadoa = new Date();
              p.Agendamento.agendadoporId = this.user.id;
              p.Agendamento.isUpdating = true; // Only used to trigger not sending email with agendamento - isNewInstance is not working
              this.Agendamento.upsert(p.Agendamento).$promise.then(a => {
                this.Alteracaoestado.create({
                  id: 0,
                  dataAlteracao: moment.utc(),
                  pedidoId: this.id,
                  estadoInicial: p.estadoId, // Agendado
                  estadoFinal: p.estadoId, // Agendado
                  funcionarioId: this.user.id,
                  observacoes: 'Alteração de Inspetor',
                  active: 1
                }).$promise.then((alteracao) => {
                  this.UI.addToast("Alteração de inspetor realizada com sucesso");
                  this.getRequest();
                }).catch((err) => {
                  console.log(err);
                  this.UI.addToast("Agendamento atualizado. Erro a atualizar o estado.");
                });
              }).catch(err => {
                console.log(err);
                this.UI.addToast("Erro na gravação da atualização do agendamento.");
              });
            } else {
              this.UI.addToast("Agendamento sem alterações");
            }
          }).catch((err) => {
            console.log(err);
          });
        } else {
          this.UI.addToast("Não é possível alterar o inspetor neste processo. Verifique o estado do processo.");
        }
      }).catch(error => {
        this.UI.addToast("Não foi possível encontrar processo. Por favor recarregue a página");
        console.log(error);
        cenas.close();
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Erro na leitura de dados. Por favor tente novamente");
      cenas.close();
    });
  };

  // Change request state to something else
  setAgendamentoToPedidoAndUpdate = (pid, value) => {

    this.Pedidoinspeccao.findOne({
      filter: {
        where: {
          id: pid,
          active: 1
        }
      }
    }).$promise.then((res) => {
      if (res != null) {
        let newDate = new Date();
        newDate.setHours(newDate.getHours() - newDate.getTimezoneOffset() / 60);

        this.Alteracaoestado.create({
          id: 0,
          dataAlteracao: newDate,
          pedidoId: res.id,
          estadoInicial: res.estadoId,
          estadoFinal: value,
          funcionarioId: this.user.id,
          observacoes: "Agendamento",
          active: 1
        }).$promise.then((alteracao) => {

        }).catch((err) => { });

        // Not chained because there's no need to be sequential(?)
        res.estadoId = value;
        res.$save().then((r) => {
          // Update everything to refresh tables;

          this.getRequest();

        });
      }
    });
  };

  //Sort so that "Outro" goes to the bottom (we don't want to mess with ids)
  sortAnexosTypes = (res) => {
    res.sort((a, b) => {
      if (a.nome === 'Outro' && b.nome !== 'Outro') {
        return 1; // move a to the end
      }
      if (a.nome !== 'Outro' && b.nome === 'Outro') {
        return -1; // move b to the end
      }
      // if neither a nor b is 'Outro', sort by id
      return a.id - b.id;
    });
    // So we can sort by this order in the modal later
    res.forEach((r,i) => {r.order = i;});
    return res;
  };

  addAnexo = () => {
    let waiter = this.UI.showWaiting();
    this.TipoDocumento.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      res = this.sortAnexosTypes(res);
      waiter.close();
      if (res.length > 0) {
        let self = this;
        let instance = this.UI.showDialog({
          template: require('./modal-new-anexo.html'),
          controller: ['$scope', function ($scope) {
            $scope.tipodocumentos = res;
            $scope.auxTipo = null;
            // Generate new file name to apply later
            $scope.uuid = self.generateUUID() + ".pdf";

            $scope.uploader = new self.FileUploader({
              url: self.envURL + '/api/Upload/files/upload',
              queueLimit: 1
            });

            // Filtro para verificar se é pdf
            let filter = {
              name: 'verifyPDF',
              fn: function (item, options) {
                let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|pdf|'.indexOf(type) !== -1;
              }
            };

            // Inserir filtro
            $scope.uploader.filters.push(filter);

            $scope.uploader.onAfterAddingFile = (fileItem) => {
              if ($scope.uploader.queue.length > 1)
                $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
            };
            $scope.uploader.onWhenAddingFileFailed = (fileItem) => {
              self.UI.addToast("Por favor, envie um ficheiro em formato PDF");
            };

            $scope.uploader.onSuccessItem = (response, status, headers) => {
              self.UI.addToast("Ficheiro enviado com sucesso");
              self.refreshAnexos();
            };

            // Error while uploading, fix db
            $scope.uploader.onErrorItem = (response, status, headers) => {

              // Remove data from database
              self.PedidoDocumento.findOne({
                filter: {
                  where: {
                    nome: $scope.uuid,
                    active: 1
                  }
                }
              }).$promise.then((res) => {
                // Found item, "delete" it
                res.active = 0;
                res.$save().then((r) => {
                  self.UI.addToast("Erro de envio. Por favor tente mais tarde.");
                });
              }).catch((error) => {
                self.UI.addToast("Erro de envio. Por favor apague o anexo e reenvie o ficheiro.");
              });
            };


            // Change filename to a random one before uploading and check if upload folder needs to change
            $scope.uploader.onBeforeUploadItem = (item) => {
              item.file.name = $scope.uuid;
              if ($scope.auxTipo.id >= 7 && $scope.auxTipo.id <= 9) // If we are uploading a report, change url to 'ie-reports'
                item.url = self.envURL + '/api/Upload/ie-reports/upload';
            };

            $scope.ok = function () {
              // Try to insert new file into database
              $scope.$close($scope);
            };

            $scope.cancel = function () {
              $scope.$dismiss('cancel');
            };
          }]
        });

        instance.then((ok) => {
          // Check if we already have a file of this type in the database
          this.PedidoDocumento.find({
            filter: {
              where: {
                pedidoinspeccaoId: this.r.id,
                tipodocumentoId: ok.auxTipo.id,
                active: 1
              }
            }
          }).$promise.then((documents) => {
            // If we have a file of this type, ask to replace
            if (documents.length > 0) {
              let confirm = this.UI.showConfirm("Pretende substituir o Anexo '" + ok.auxTipo.nome + "' já existente para este pedido?");
              confirm.then((go) => {
                if (go) {
                  this.replaceAnexo(ok, documents);
                }
              });
            } else {
              // If not, insert new file into db and if successful add file to container
              this.insertNewAnexo(ok);
            }
          }).catch((error) => {
            this.UI.addToast("De momento não é possível adicionar anexos. Por favor tente mais tarde.");
          });

        }).catch((err) => { });
      } else {
        this.UI.addToast("De momento não é possível adicionar anexos. Por favor tente mais tarde.");
      }
    }).catch((error) => {
      waiter.close();
      this.UI.addToast("De momento não é possível adicionar anexos. Por favor tente mais tarde.");
    });
  };

  insertNewAnexo = (ok) => {
    this.PedidoDocumento.create({
      id: 0,
      pedidoinspeccaoId: this.r.id,
      tipodocumentoId: ok.auxTipo.id,
      nome: ok.uuid,
      active: 1
    }).$promise.then((res) => {
      ok.uploader.uploadAll();
    }).catch((error) => {
      this.UI.addToast("De momento não é possível adicionar anexos. Por favor tente mais tarde.");
    });
  };

  editAnexo = (anexo) => {
    let waiter = this.UI.showWaiting();
    this.TipoDocumento.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      if (res.length > 0) {
        // We should fetch the anexo again just in case someone else removed it
        this.PedidoDocumento.findOne({
          filter: {
            where: {
              id: anexo.id
            },
            include: 'TipoDocumento'
          }
        }).$promise.then((a) => {
          let self = this;
          let instance = this.UI.showDialog({
            template: require('./modal-edit-anexo.html'),
            controller: ['$scope', function ($scope) {
              waiter.close();
              $scope.tipodocumentos = res;
              $scope.auxTipo = a.TipoDocumento;
              // Generate new file name to apply later
              $scope.uuid = self.generateUUID() + ".pdf";

              $scope.uploader = new self.FileUploader({
                url: self.envURL + '/api/Upload/files/upload',
                queueLimit: 1
              });

              // Filtro para verificar se é pdf
              let filter = {
                name: 'verifyPDF',
                fn: function (item, options) {
                  let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                  return '|pdf|'.indexOf(type) !== -1;
                }
              };

              // Inserir filtro
              $scope.uploader.filters.push(filter);

              $scope.uploader.onAfterAddingFile = (fileItem) => {
                if ($scope.uploader.queue.length > 1)
                  $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
              };
              $scope.uploader.onWhenAddingFileFailed = (fileItem) => {
                self.UI.addToast("Por favor, envie um ficheiro em formato PDF");
              };

              $scope.uploader.onSuccessItem = (response, status, headers) => {
                self.UI.addToast("Ficheiro enviado com sucesso");
                self.refreshAnexos();
              };

              // Error while uploading, fix db
              $scope.uploader.onErrorItem = (response, status, headers) => {
                // Remove data from database
                self.PedidoDocumento.findOne({
                  filter: {
                    where: {
                      nome: $scope.uuid,
                      active: 1
                    }
                  }
                }).$promise.then((res) => {
                  // Found item, "delete" it
                  res.active = 0;
                  res.$save().then((r) => {
                    self.UI.addToast("Erro de envio. Por favor tente mais tarde.");
                  });
                }).catch((error) => {
                  self.UI.addToast("Erro de envio. Por favor apague o anexo e reenvie o ficheiro.");
                });
              };

              // Change filename to a random one before uploading and check if upload folder needs to change
              $scope.uploader.onBeforeUploadItem = (item) => {
                item.file.name = $scope.uuid;
                if ($scope.auxTipo.id >= 7 && $scope.auxTipo.id <= 9) // If we are uploading a report, change url to 'ie-reports'
                  item.url = self.envURL + '/api/Upload/ie-reports/upload';
              };

              $scope.ok = function () {
                // Try to insert new file into database
                $scope.$close($scope);
              };

              $scope.cancel = function () {
                $scope.$dismiss('cancel');
              };
            }]
          });

          instance.then((ok) => {
            // Check if we already have a file of this type in the database
            this.PedidoDocumento.find({
              filter: {
                where: {
                  pedidoinspeccaoId: this.r.id,
                  tipodocumentoId: ok.auxTipo.id,
                  active: 1
                }
              }
            }).$promise.then((documents) => {
              // Replace the file and entry
              if (documents.length > 0) {
                this.replaceAnexo(ok, documents);
              } else {
                this.UI.addToast("Não foi possível substituir o anexo. Por favor recarregue a página.");
              }
            }).catch((error) => {
              this.UI.addToast("De momento não é possível editar o anexo. Por favor tente mais tarde.");
            });
          }).catch((err) => { });
        }).catch((error) => {
          waiter.close();
          this.UI.addToast("Não foi possível editar este anexo. Por favor recarregue a página.");
        });
      } else {
        waiter.close();
        this.UI.addToast("De momento não é possível editar o anexo. Por favor tente mais tarde.");
      }
    }).catch((error) => {
      waiter.close();
      this.UI.addToast("De momento não é possível editar o anexo. Por favor tente mais tarde.");
    });
  };

  // Coming from addAnexo, arguments are ok scope and documents is array of docs of same type (to inactivate)
  replaceAnexo = (ok, documents) => {
    // documents should always be only one, but still...
    documents.forEach((doc) => {
      doc.active = 0;
      doc.$save();
    });

    this.PedidoDocumento.create({
      id: 0,
      pedidoinspeccaoId: this.r.id,
      tipodocumentoId: ok.auxTipo.id,
      nome: ok.uuid,
      active: 1
    }).$promise.then((res) => {
      ok.uploader.uploadAll();
    }).catch((error) => {
      this.UI.addToast("De momento não é possível adicionar anexos. Por favor tente mais tarde.");
    });
  };

  removeAnexo = (anexo) => {
    let waiter = this.UI.showWaiting();
    this.PedidoDocumento.findOne({
      filter: {
        where: {
          id: anexo.id
        },
        include: 'TipoDocumento'
      }
    }).$promise.then((res) => {
      waiter.close();
      if (res.TipoDocumento != null) {
        let confirm = this.UI.showConfirm("Pretende remover o Anexo '" + res.TipoDocumento.nome + "' deste pedido?");
        confirm.then((go) => {
          if (go) {
            res.active = 0;
            res.$save().then((r) => {
              this.UI.addToast("Anexo removido com sucesso.");
              this.refreshAnexos();
            }, (e) => {
              this.UI.addToast("De momento não foi possível apagar o anexo. Por favor tente mais tarde.");
            });
          }
        });
      } else {
        this.UI.addToast("De momento não foi possível apagar o anexo. Por favor tente mais tarde.")
      }

    }).catch((error) => {
      waiter.close();
      this.UI.addToast("De momento não foi possível apagar o anexo. Por favor tente mais tarde.")
    });
  };

  // Refresh list of anexos for pedido
  refreshAnexos = () => {
    this.anexosLoading = true;
    this.PedidoDocumento.find({
      filter: {
        where: {
          pedidoinspeccaoId: this.r.id,
          active: 1
        },
        include: 'TipoDocumento',
        order: 'tipodocumentoId ASC, nome ASC'
      }
    }).$promise.then((res) => {
      this.docs = res;
      this.getAnexosInfo();
      this.anexosLoading = false;
    }).catch((error) => {
      this.anexosLoading = false;
    });

  };

  // UUID para o nome de ficheiros
  generateUUID = () => {
    let uuid = "",
      i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  // Copied and adapted from IE project
  // Validate CPE (20 digits, first 2 and last must be 2 letters, 16 digits in between)
  validCPE = (cpe) => {
    // Returning true to hide message (there's no point of testing if cpe is undefined or not with correct length)
    // WARNING: RELIES ON MINLENGTH to be defined and VERIFIED!!
    if (cpe === undefined || cpe === null || cpe.length < 20)
      return false;
    let regex = /^[A-Za-z]{2}[0-9]{16}[A-Za-z]{2}/i;

    // !! so we return true or false
    return !!cpe.match(regex);
  };

  addRelatorio = () => {
    this.dataLoaded = false;
    this.IeRelatorio.criarRelatorio({
      tecnicoId: this.user.id,
      pedidoId: this.id
    }).$promise.then((res) => {
      let aux = (res.length === 1 ? '' : 's');
      this.getRequest(res.length + " Relatório" + aux + " criado" + aux + " com sucesso");
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro na criação de relatório(s)");
    });
  };

  getProjectFolderPath = () => {
    // Path to network for IE
    if (!this.r)
      return "";
    let year = moment(this.r.dataPedido).year();
    let folder = "Processos";
    let network_path = "servernas\\comum\ ins\\IE\\Vistorias";
    if (this.r.identificacaoInterna) {
      return `\\\\${network_path}\\${year}\\${folder}\\${this.r.identificacaoInterna}`;
    } else {
      return `\\\\${network_path}\\${year}\\${folder}`;
    }
  };

  // Copy URL to clipboard on click
  copyProjectFolder = () => {
    if (this.r) {
      let url = this.getProjectFolderPath();

      if (!navigator.clipboard) { // Alternative solution
        let tempInput = document.createElement('input');
        tempInput.type = 'text';
        tempInput.value = url;
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // For mobile devices
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        this.UI.addToast("Caminho copiado para Área de Transferência");
      } else { // Navigator API solution
        navigator.clipboard.writeText(url).then(() => {
          this.UI.addToast("Caminho copiado para Área de Transferência");
        }).catch(err => {
          this.UI.addToast("Erro na cópia para Área de Transferência");
        });
      }
    } else this.UI.addToast("Sem informação a copiar");
  };

  // Async function to add visto to pedidos
  verifyPedido = (pedidoId, visto) => {
    let defer = this.$q.defer();
    this.Pedidoinspeccao.findById({id: pedidoId}).$promise.then(p => {
      if (visto) {
        p.visto = 1;
        p.verificadoPorId = this.user.id;
      } else {
        p.visto = 0;
        p.verificadoPorId = null;
      }
      this.Pedidoinspeccao.upsert(p).$promise.then(() => {
        defer.resolve(pedidoId);
      }).catch(error => {
        console.log(error);
        defer.reject(error);
      });
    }).catch(error => {
      console.log(error);
      defer.reject(error);
    });
    return defer.promise;
  };

  // Interface called function to add visto
  verify = () => {
    if (this.AuthorizationService.canPerform('pedidoIeVerificar')) {
      let wait = this.UI.showWaiting();
      // Toggle the value of visto
      this.verifyPedido(this.r.id, !this.r.visto).then(() => {
        wait.close();
        this.UI.addToast((!this.r.visto === true) ? "Pedido verificado com sucesso" : "Removida verificação de pedido com sucesso");
        this.getRequest();
      }).catch(error => {
        wait.close();
        console.log(error);
        this.UI.addToast("Erro na alteração de verificação do pedido. Verifique a ligação.");
      });
    } else {
      this.UI.addToast("Sem permissões para verificar pedido");
    }
  };
}

DetailPedidosController.$inject = ['$rootScope', '$q', '$state', '$http', '$filter', 'AuthorizationService', 'FileUploader', 'AuthenticationService', '$sce', '$stateParams', '$timeout', 'Pedidoinspeccao', 'Pagamento', 'Tipopagamento', 'Estado', 'Alteracaoestado', 'Funcionario', 'Tecnico', 'Dadosinstalacao', 'Dadosfactura', 'Fraccao', 'Tipoimovel', 'TipoDocumento', 'PedidoDocumento', 'Group', 'Agendamento', 'UIService', '$anchorScroll', '$location', 'Promotor', 'Projeto', 'Exploracao', 'IeRelatorio'];
