import {routes} from './ativos.routes';
import AtvAtivosListController from "./directory/directory.controller";
import AtvAtivosDetailsController from "./details/details.controller";
import AtvCreateAtivoController from "./new/new.controller";
import AtvAtivosFileUploaderController from "./details/files.dialog.controller";
import AtvAtivosOsMeusAtivosController from "./meusativos/details.controller";

export default angular.module('app.ativos.ativos', [])
  .config(routes)
  .controller('AtvAtivosListController', AtvAtivosListController)
  .controller('AtvAtivosDetailsController', AtvAtivosDetailsController)
  .controller('AtvCreateAtivoController', AtvCreateAtivoController)
  .controller('AtvAtivosFileUploaderController', AtvAtivosFileUploaderController)
  .controller('AtvAtivosOsMeusAtivosController', AtvAtivosOsMeusAtivosController)
  .name;
