export default class ListaNormaService {
    $q;
    CoreListaNorma;
    CoreDepartamento;
    constructor($q, CoreListaNorma, CoreDepartamento) {
        this.$q = $q;
        this.CoreListaNorma = CoreListaNorma;
        this.CoreDepartamento = CoreDepartamento;
    }
    findAll(query) {
        return this.CoreListaNorma.table(query).$promise;
    }
    findOne(id) {
        return this.CoreListaNorma.findOne({
            filter: {
                where: {
                    id
                }
            }
        }).$promise;
    }
    options() {
        return this.CoreListaNorma.options().$promise;
    }
    patch(id, data) {
        return this.CoreListaNorma.patch({ id }, data).$promise;
    }
    departamentos() {
        // FIXME: CoreDepartamento tem que ser ligado ao utilizador (fazer vista? Falar com o FM)
        return this.CoreDepartamento.find({
            filter: {
                where: {
                    active: true
                }
            }
        }).$promise;
    }
    create(data) {
        return this.CoreListaNorma.create(data).$promise;
    }
}
ListaNormaService.$inject = ['$q', 'CoreListaNorma', 'CoreDepartamento'];
