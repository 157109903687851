export default class FormacaoCursoEdicaoAgendamentoController {
  constructor($rootScope, $timeout, $window, $state, $stateParams, $filter, AuthenticationService, NgTableParams, FormacaoEdicao, FormacaoSessao, FormacaoEspaco, Avatar, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$window = $window;
    this.$filter = $filter;
    this.user = AuthenticationService.getUser();
    this.FormacaoEdicao = FormacaoEdicao;
    this.FormacaoSessao = FormacaoSessao;
    this.FormacaoEspaco = FormacaoEspaco;
    this.NgTableParams = NgTableParams;

    this.Avatar = Avatar;
    this.search = "";
    this.selected = 0;
    this.select = false;

    this.UI = UIService;
    this.id = $stateParams.id;
    this.ed = $stateParams.ed;
    this.espacos = null;
    this.edicao = null;
    this.edicaoLoading = true;
    this.calendarEvents = [];

    // Redirect if something is not defined
    if (!this.id) {
      this.$state.go('app.formacao.cursos.list');
    } else {
      if (!this.ed) {
        this.$state.go('app.formacao.cursos.details({id:' + this.id + '})');
      }
    }

    this.addEvent = (id, title, start, end, color) => {
      this.calendarEvents.push({
        id: id,
        title: title,
        start: start,
        end: end,
        color: color,
      });
    };

    this.editEvent = (id, title, start, end, color) => {
      let evEdit = this.calendarEvents.find(e => e.id === id);
      evEdit.title = title;
      evEdit.start = start;
      evEdit.end = end;
      evEdit.color = color;
    };

    this.removeEvent = (id) => {
      _.remove(this.calendarEvents, c => c.id === id);
      this.UI.addToast("Sessão removida com sucesso.");
    };

    // Called when move or resize an event on the map
    // Event is the actual event with id, start, end...
    this.updateEvent = event => {
      let e = this.calendarEvents.find(ev => ev.id === event.id);
      // Backup if things go wrong
      let oldStart = angular.copy(e.start);
      let oldEnd = angular.copy(e.end);
      e.start = moment.utc(event.start);
      e.end = moment.utc(event.end);

      this.FormacaoSessao.findOne({
        filter: {
          where: {
            id: event.id,
            active: 1
          }
        }
      }).$promise.then((sessao) => {
        sessao.datahorainicio = e.start;
        sessao.duracao = e.end.diff(e.start, 'm');
        sessao.$save().then((res) => {
          self.UI.addToast("Sessão atualizada com sucesso.");
        }, (error) => {
          // If we couldn't save, revert
          self.UI.addToast("Não foi possível guardar as alterações. Por favor tente mais tarde.");
          e.start = oldStart;
          e.end = oldEnd;
        });
      }).catch((error) => {
        // Element is not available anymore, delete the event entry
        this.UI.addToast("Não foi possível encontrar esta sessão. Recarregue a página.");
        _.remove(this.calendarEvents, o => o.id === event.id);
      });
    };

    let self = this;
    this.calendarOptions = {
      eventClick: (event) => {
        this.editSessao(event);
      },
      editable: true,
      draggable: true,
      droppable: true,
      // Event was resized on the map
      eventResize: (event) => {
        this.updateEvent(event);
      },
      // Event was dropped here from elsewhere in the map
      eventDrop: (event) => {
        this.updateEvent(event);
      },
      // New event was dropped in the map
      drop: (start, element) => {
        let moduloId = element.target.attributes['module-id'].value;
        this.addEventModal(moduloId, start);
      },
      defaultView: 'agendaWeek',
      locale: 'pt',
      themeSystem: 'bootstrap4',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay,listMonth'
      },
      navLinks: true,
      defaultTimedEventDuration: '01:00:00',
      allDaySlot: false,
      displayEventEnd: true,
      timeFormat: "HH:mm",
      minTime: "08:00:00",
      maxTime: "24:00:00",
      height: 'auto',
      slotDuration: "00:15:00",
      slotLabelFormat: "HH:mm",
      views: {
        agendaWeek: {
          columnHeaderFormat: "DD/MM"
        }
      },
      firstDay: 1,
      nowIndicator: true,
      weekends: false,
      weekNumberTitle: 'Sem.',
      eventLimitText: 'sess.',
      noEventsMessage: 'Sem sessões a apresentar.',
      weekNumbers: true,
      eventLimit: true,
      buttonText: {
        today: 'Hoje',
        month: 'Mensal',
        week: 'Semanal',
        day: 'Diário',
        list: 'Lista'
      }
    };

    this.getEdicao();
  };

  // When a new event is dropped, new it on the DB
  addEventModal = (moduloId, start) => {
    let self = this;
    let modulo = this.edicao.modulos.find(m => m.id == moduloId);
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./session.dialog.html'),
      controller: ['$scope', '$filter', ($scope) => {
        $scope.intra = self.edicao.tipoId === 2;
        // Used so we can decide to show Sumário or not
        $scope.newSessao = true;
        $scope.modalTitle = "Nova Sessão de Formação";
        $scope.moduloDesignacao = modulo.designacao;

        $scope.auxEspaco = {
          selected: {}
        };

        // Data to change on the modal
        $scope.espacos = self.espacos;

        $scope.sessao = {};
        // Valor por defeito, sujeito a mudança
        $scope.sessao.duracao = 60;
        $scope.sessao.datahorainicio = start;

        $scope.ok = () => {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      let newInstance = {
        id: 0,
        numero: ok.sessao.numero,
        sumario: null,
        observacoes: _.isEmpty(ok.sessao.observacoes) ? null : ok.sessao.observacoes,
        datahorainicio: ok.sessao.datahorainicio,
        duracao: ok.sessao.duracao,
        active: 1,
        moduloId: moduloId
      };
      if (this.edicao.tipoId !== 2) {
        newInstance.espacoId = ok.auxEspaco.selected.id;
      } else {
        newInstance.espacoId = null;
      }
      this.FormacaoSessao.create(newInstance).$promise.then((s) => {
        let end = angular.copy(ok.sessao.datahorainicio).add(ok.sessao.duracao, 'm');
        let eventText = "Módulo " + modulo.order + "\nSessão de Formação Nº" + s.numero + "\n";
        let color = this.Avatar.getColor(modulo.designacao);
        // Add the event with the id of the db register
        this.addEvent(s.id, eventText, ok.sessao.datahorainicio, end, color);
        modulo.sessoes.push(s);
        this.UI.addToast("Sessão adicionada com sucesso.");
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não é possível adicionar sessão. Por favor tente mais tarde.");
      });
    }).catch(() => {
    });
  };

  // Redirect back if an error happens, to course or to list
  redirectState = () => {
    if (!this.id)
      this.$state.go('app.formacao.cursos.list');
    else
      this.$state.go('app.formacao.cursos.details', { id: this.id });
  };

  editSessao = (event) => {
    let self = this;
    let sessionId = event.id;
    if ((this.espacos === null || this.espacos.length === 0) && this.edicao.tipoId !== 2) {
      this.UI.addToast("De momento não é possível editar sessões. Por favor tente mais tarde.");
      return;
    }
    let modulo, sessao, moduloFound = false;
    // Find modulo that has specific sessionId
    for (let i = 0; i < this.edicao.modulos.length; i++) {
      for (let j = 0; j < this.edicao.modulos[i].sessoes.length; j++) {
        if (this.edicao.modulos[i].sessoes[j].id === sessionId) {
          modulo = this.edicao.modulos[i];
          sessao = this.edicao.modulos[i].sessoes[j];
          moduloFound = true;
          break;
        }
      }
      if (moduloFound)
        break;
    }

    // Don't continue if we can't find modulo or sessao
    if (modulo == null || sessao == null) {
      this.UI.addToast("De momento não é possível editar sessão. Por favor tente mais tarde.");
      return;
    }

    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./session.dialog.html'),
      controller: ['$scope', '$filter', ($scope) => {
        // Used so we can decide to show Sumário or not
        $scope.intra = self.edicao.tipoId === 2;
        $scope.newSessao = false;
        $scope.modalTitle = "Editar Sessão de Formação";
        $scope.moduloDesignacao = modulo.designacao;

        // Data to change on the modal
        // We only need espacos if we are not in intra
        if (!$scope.intra) {
          $scope.espacos = this.espacos;
          $scope.auxEspaco = {
            selected: sessao.espaco
          };
        }
        $scope.sessao = {};
        // Valor por defeito, sujeito a mudança
        $scope.sessao.duracao = sessao.duracao || 60;
        $scope.sessao.datahorainicio = moment.utc(angular.copy(event.start));
        $scope.sessao.numero = sessao.numero;
        $scope.sessao.observacoes = sessao.observacoes;
        $scope.sessao.sumario = sessao.sumario;

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };

        $scope.remove = function () {
          // Add a flag to remove and deal with it on the instance result
          $scope.removeSessao = true;
          $scope.$close($scope);
        }
      }]
    });

    instance.then((ok) => {
      // Return from the modal
      if (ok.removeSessao) {
        // Remove Sessao and associated Event
        this.FormacaoSessao.findOne({
          filter: {
            where: {
              id: sessao.id,
              active: 1
            }
          }
        }).$promise.then((sessaoRemove) => {
          sessaoRemove.active = 0;
          sessaoRemove.$save().then((res) => {
            // Removed from database, remove event from view too
            this.removeEvent(sessaoRemove.id);
          }, (error) => {
            this.UI.addToast("De momento não foi possível remover a sessão. Por favor tente mais tarde.");
          });
        }).catch((error) => {

        });
        return;
      }
      this.FormacaoSessao.findOne({
        filter: {
          where: {
            id: sessionId,
            active: 1
          }
        }
      }).$promise.then((s) => {
        // Update data with stuff from modal
        s.numero = ok.sessao.numero;
        s.sumario = _.isEmpty(ok.sessao.sumario) ? null : ok.sessao.sumario;
        s.observacoes = _.isEmpty(ok.sessao.observacoes) ? null : ok.sessao.observacoes;
        s.datahorainicio = ok.sessao.datahorainicio;
        s.duracao = ok.sessao.duracao;
        if (!ok.intra)
          s.espacoId = ok.auxEspaco.id;
        else
          s.espacoId = null;
        s.moduloId = modulo.id;
        // Save data to database
        s.$save().then((res) => {
          sessao = res;
          console.log(res);
          // Change the card to match the saved data
          let start = moment.utc(s.datahorainicio);
          let end = moment.utc(s.datahorainicio).add(s.duracao, 'm');
          let eventText = "Módulo " + modulo.order + "\nSessão de Formação Nº" + s.numero + "\n";
          if (!ok.intra) {
            s.espaco = ok.auxEspaco;
            eventText += s.espaco.nome;
          }
          let color = this.Avatar.getColor(modulo.designacao);
          // Add the event with the id of the db register
          this.editEvent(s.id, eventText, start, end, color);
          this.updateSessaoInModulo(s);
          this.UI.addToast("Sessão editada com sucesso.");
        }, (error) => {
          this.UI.addToast("Não foi possível editar sessão. Por favor tente mais tarde.");
          console.log(error);
        });
      }).catch((error) => {
        this.UI.addToast("Não foi possível editar sessão. Por favor tente mais tarde.");
        console.log(error);
      });
    }).catch((err) => {
    });
  };

  // Update the array variable with the information so we don't have to reload everything
  updateSessaoInModulo = (sessao) => {
    let moduloFound = false;
    for (let i = 0; i < this.edicao.modulos.length; i++) {
      for (let j = 0; j < this.edicao.modulos[i].sessoes.length; j++) {
        if (this.edicao.modulos[i].sessoes[j].id === sessao.id) {
          this.edicao.modulos[i].sessoes[j] = sessao;
          moduloFound = true;
          break;
        }
      }
      if (moduloFound) break;
    }
  };

  getEdicao = () => {
    this.FormacaoEdicao.findOne({
      filter: {
        where: {
          id: this.ed,
        },
        include: [{
          relation: 'modulos',
          scope: {
            where: {
              active: 1
            },
            include: {
              relation: 'sessoes',
              scope: {
                where: {
                  active: 1
                },
                include: {
                  relation: 'espaco',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }
              }
            }
          }
        }, {
          relation: 'curso',
          scope: {
            where: {
              active: 1
            },
          }
        }, {
          relation: 'local',
          scope: {
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then((res) => {
      this.edicao = res;

      if (this.edicao.modulos.length > 0) {
        this.edicao.modulos.forEach(m => {
          m.sessoes.forEach(s => {
            let agStart = moment.utc(s.datahorainicio);
            let agEnd = moment.utc(s.datahorainicio).add(s.duracao, 'm');
            let eventText;
            eventText = "Módulo " + m.order + "\nSessão de Formação Nº" + s.numero + "\n";
            if (this.edicao.tipoId !== 2)
              eventText += s.espaco.designacao;

            this.calendarEvents.push({
              id: s.id,
              title: eventText,
              start: agStart,
              end: agEnd,
              color: this.Avatar.getColor(m.designacao) // TODO - Use modulo color (?)
            });
          });
        });
        if (res.tipoId !== 2) {
          // Ignora espaços se fora inter-empresa
          this.getEspacos();
        }
        this.edicaoLoading = false;
      } else {
        this.edicaoLoading = false;
        this.UI.addToast('É necessário adicionar Módulos antes de criar Sessões.');
        // Redirect on error
        this.redirectState();
      }
    }).catch((error) => {
      console.log(error);
      this.edicaoLoading = false;
      this.UI.addToast('Erro de leitura da edição do curso. Por favor tente mais tarde.');
      // Redirect on error
      //this.redirectState();
    });
  };

  getEspacos = () => {
    this.FormacaoEspaco.find({
      filter: {
        where: {
          localId: this.edicao.local.id,
          active: 1
        },
      }
    }).$promise.then((res) => {
      this.espacos = res;
    }).catch((error) => {
    });
  };

};

FormacaoCursoEdicaoAgendamentoController.$inject = ['$rootScope', '$timeout', '$window', '$state', '$stateParams', '$filter', 'AuthenticationService', 'NgTableParams', 'FormacaoEdicao', 'FormacaoSessao', 'FormacaoEspaco', 'Avatar', 'UIService'];
