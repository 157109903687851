export default class FuncionaService {
    $rootScope;
    $q;
    FunProcesso;
    FunAgendamento;
    FunTipoServico;
    FunTipoInstalacao;
    Funcionario;
    FunTecnico;
    FunMotivo;
    FunAlteracaoestadoProcesso;
    FunEstadoProcesso;
    envURL;
    user;
    constructor($rootScope, $q, AuthenticationService, FunProcesso, FunAgendamento, FunTipoServico, FunTipoInstalacao, Funcionario, FunTecnico, FunMotivo, FunAlteracaoestadoProcesso, FunEstadoProcesso) {
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.FunProcesso = FunProcesso;
        this.FunAgendamento = FunAgendamento;
        this.FunTipoServico = FunTipoServico;
        this.FunTipoInstalacao = FunTipoInstalacao;
        this.Funcionario = Funcionario;
        this.FunTecnico = FunTecnico;
        this.FunMotivo = FunMotivo;
        this.FunAlteracaoestadoProcesso = FunAlteracaoestadoProcesso;
        this.FunEstadoProcesso = FunEstadoProcesso;
        this.user = AuthenticationService.getUser();
        // By default, envURL is production (to account for fails)
        this.envURL = 'https://inspecoeseletricas.pt';
        // Get environment for URL for files
        // Doing this here only because we only use this.envURL in the view
        this.$rootScope.$on('$receivedServerStatus', (evt, data) => {
            if (data != null && data.environment != null) {
                let env = data.environment;
                if (env === 'staging') {
                    this.envURL = 'https://staging-ie.iep.pt';
                }
                else if (env === 'production') {
                    // For completeness
                    this.envURL = 'https://inspecoeseletricas.pt';
                }
                else {
                    this.envURL = 'https://dev-iep-ie.streamline.pt';
                }
            }
        });
    }
    getUserId() {
        return this.user.id;
    }
    findProcessoById = (id) => {
        let defer = this.$q.defer();
        this.FunProcesso.findOne({ filter: { where: { id: id } } })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    findAgendamentoByProcessoId = (id) => {
        let defer = this.$q.defer();
        this.FunAgendamento.findOne({ filter: { where: { id: id } } })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getTipoServico = () => {
        let defer = this.$q.defer();
        this.FunTipoServico.find()
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getTipoInstalacao = () => {
        let defer = this.$q.defer();
        this.FunTipoInstalacao.find()
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getMotivos = () => {
        let defer = this.$q.defer();
        this.FunMotivo.find()
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getTecnicoById = (id) => {
        let defer = this.$q.defer();
        this.FunTecnico.findOne({ filter: { where: { funcionarioId: id }, include: [{ relation: 'funcionario' }] } })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getFuncionarioById = (id) => {
        let defer = this.$q.defer();
        this.Funcionario.findOne({ filter: { where: { id: id } } })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getTecnicos = async (search, page, limit) => {
        let defer = this.$q.defer();
        try {
            // 1. find técnicos pelo código
            const tecnicosPorCodigo = await this.FunTecnico.find({
                filter: {
                    where: { codigo: { like: `%${search}%` } },
                    include: [{ relation: 'funcionario' }],
                    skip: page * limit,
                    limit: limit
                }
            }).$promise;
            // 2. find funcionários pelo nome
            const funcionarios = await this.Funcionario.find({
                filter: {
                    where: { name: { like: `%${search}%` } } // Filtrar pelo nome do funcionário
                }
            }).$promise;
            // 3. Obter os IDs dos funcionários encontrados
            const funcionarioIds = funcionarios.map((func) => func.id);
            // 4. find técnicos associados aos IDs dos funcionários
            const tecnicosPorNome = await this.FunTecnico.find({
                filter: {
                    where: { funcionarioId: { inq: funcionarioIds } },
                    include: [{ relation: 'funcionario' }],
                    skip: page * limit,
                    limit: limit
                }
            }).$promise;
            // 5. Concatenar os resultados das duas pesquisas, evitando duplicações
            const resultMap = new Map();
            [...tecnicosPorCodigo, ...tecnicosPorNome].forEach((tecnico) => {
                resultMap.set(tecnico.id, {
                    id: tecnico.funcionario.id,
                    name: tecnico.funcionario?.name || '',
                    codigo: tecnico.codigo
                });
            });
            // 6. Converter o Map num array e limitar os resultados
            const resultArray = Array.from(resultMap.values());
            const paginatedResult = resultArray.slice(page * limit, (page + 1) * limit);
            defer.resolve(paginatedResult);
        }
        catch (error) {
            defer.reject(error);
        }
        return defer.promise;
    };
    saveAlteracaoEstado = (values) => {
        values.funcionarioId = this.getUserId();
        let defer = this.$q.defer();
        this.FunAlteracaoestadoProcesso.upsert(values)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    saveProcesso = (values) => {
        let defer = this.$q.defer();
        this.FunProcesso.upsert(values)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    saveAgendamento = (values) => {
        let defer = this.$q.defer();
        this.FunAgendamento.upsert(values)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getEstados = () => {
        return this.FunEstadoProcesso.find({
            filter: {
                where: {
                    active: true
                }
            }
        }).$promise;
    };
    getTecnicosFuncionario = () => {
        let defer = this.$q.defer();
        this.FunProcesso.getTecnicosForTable()
            .$promise.then(data => {
            data.filter(x => x.funcionario != null).map(r => r.funcionario);
            defer.resolve(data.filter(x => x.funcionario != null).map(r => r.funcionario));
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    findAll(query) {
        return this.FunProcesso.table(query).$promise;
    }
    options() {
        return this.FunProcesso.options().$promise;
    }
    exportProcessos(query) {
        return this.FunProcesso.export(query).$promise;
    }
}
FuncionaService.$inject = ['$rootScope', '$q', 'AuthenticationService', 'FunProcesso', 'FunAgendamento', 'FunTipoServico', 'FunTipoInstalacao', 'Funcionario', 'FunTecnico', 'FunMotivo', 'FunAlteracaoestadoProcesso', 'FunEstadoProcesso'];
