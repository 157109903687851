export function routes($stateProvider) {

  $stateProvider
    .state('app.administration', {
      url: '/administracao',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.adminIE', {
      url:'/ie?{tab:string}&{term:string}&{page:int}',
      title: 'Administração - Inspeções Elétricas',
      template: require('./ie/definicoes.view.html'),
      controller: 'IeDefinicoesController',
      controllerAs: 'vm',
      role: 'configIe'
    })
    .state('app.administration.custos', {
      url:'/custos',
      title: 'Administração - Custos',
      template: require('./custos/custos.view.html'),
      controller: 'CustosAdminController',
      controllerAs: 'vm',
      role: 'configLme'
    })
    .state('app.administration.adminOBL', {
      url:'/obler',
      title: 'Administração - Obler',
      template: require('./obler/view.html'),
      controller: 'OblerAdminController',
      controllerAs: 'vm',
      role: ['oblerAdmin'],
    })
    .state('app.administration.codigosArtigo', {
      url:'/codigosartigo',
      title: 'Códigos de Artigo Lab.',
      template: require('./codigosArtigo/codigosArtigo.view.html'),
      controller: 'CodigosArtigoAdminController',
      controllerAs: 'vm',
      role: 'configLme'
    })
    .state('app.administration.adminATV', {
      url:'/ativos',
      title: 'Administração - Ativos',
      template: require('./ativos/ativos.view.html'),
      controller: 'AtvAdminController',
      controllerAs: 'vm',
      role: 'atvConfig'
    })
    .state('app.administration.elv', {
      url:'/elv',
      title: 'Administração - Elevadores',
      template: require('./elv/view.html'),
      controller: 'ElvAdminController',
      controllerAs: 'vm',
      role: 'configElv'
    })
    .state('app.administration.pad', {
      url:'/pad',
      title: 'Administração - PAD',
      template: require('./pad/view.html'),
      controller: 'PadAdminController',
      controllerAs: 'vm',
      role: 'configPad'
    })
    .state('app.administration.funciona', {
      url:'/funciona',
      title: 'Administração - Funciona',
      template: require('./funciona/view.html'),
      controller: 'FuncionaAdminController',
      controllerAs: 'vm',
      role: 'funcionaAdmin'
    })
    .state('app.administration.gas', {
      url:'/gas',
      title: 'Gás',
      template: require('./gas/view.html'),
      controller: 'GasAdminController',
      controllerAs: 'vm',
      // role: 'gasAdmin'
    })
    .state('app.administration.main', {
      url: '/',
      template: require('./main/main.view.html'),
      controller: 'ManagementController',
      controllerAs: 'vm',
      role: 'controlPanel'
    })
    .state('app.administration.system', {
      url: '/system',
      template: require('./system/system.view.html'),
      controller: 'SystemController',
      controllerAs: 'vm',
      role: 'systemInfo'
    })

    .state('app.administration.users', {
      url: '/users',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.users.add', {
      url: '/add',
      title: 'Administração - Utilizadores',
      template: require('./main/users/add/add.view.html'),
      controller: 'UserAdminController',
      controllerAs: 'vm'
    })
    .state('app.administration.users.edit', {
      url: '/edit/:id',
      title: 'Administração - Utilizadores',
      template: require('./main/users/edit/edit.view.html'),
      controller: 'UserEditAdminController',
      controllerAs: 'vm'
    })
    .state('app.administration.roles', {
      url: '/roles',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.administration.roles.edit', {
      url: '/edit/:id',
      template: require('./main/roles/edit/edit.view.html'),
      controller: 'RoleEditController',
      title: 'Administração - Cargos',
      controllerAs: 'vm'
    })
    .state('app.administration.roles.add', {
      url: '/add',
      template: require('./main/roles/add/add.view.html'),
      title: 'Administração - Cargos',
      controller: 'RoleAddController',
      controllerAs: 'vm'
    })
  ;
}

routes.$inject = ['$stateProvider'];
