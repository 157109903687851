export default class DetalhesElevadorController {
  constructor($state, UIService, $http, ElvElevador, ElvElevadorDocumento, FileUploader, ElvEquipamentoTipo, ElvCliente, ElvCondominio, ElvEmie, ElvElevadorTipo) {
    this.$state = $state;
    this.UI = UIService;
    this.$http = $http;
    this.id = $state.params.id;
    this.ElvElevador = ElvElevador;
    this.ElvElevadorDocumento = ElvElevadorDocumento;
    this.loaded = false;
    this.FileUploader = FileUploader;
    this.ElvEquipamentoTipo = ElvEquipamentoTipo;
    this.ElvCliente = ElvCliente;
    this.ElvCondominio = ElvCondominio;
    this.ElvEmie = ElvEmie;
    this.ElvElevadorTipo = ElvElevadorTipo;
    this.UI = UIService;
    this.images = null;
    this.file = null;
    this.removableFileTypeIndexes = [1, 2, 3, 4, 5, 8]; // Ids de documentos editáveis + imagens
    this.loadData();
    //Infinite Scroll Magic
    this.infiniteScroll = {};
    this.infiniteScroll.numToAdd = 20;
    this.infiniteScroll.currentItems = 20;
  }

  addMoreItems = () => {
    this.infiniteScroll.currentItems += this.infiniteScroll.numToAdd;
  };

  loadData = () => {
    this.loaded = false;
    if (this.id) {
      this.ElvElevador.findOne({
        filter: {
          where: {
            active: true,
            id: this.id,
          },
          include: [
            {
              relation: 'equipamento',
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: 'concelho',
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: 'tipo',
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: 'marca',
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: 'cliente',
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: 'pedidoRel',
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: 'emie',
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: 'condominio',
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: 'pedElev',
              scope: {
                where: {
                  active: true
                },
                order: 'id desc',
                include: ['cliente', 'estado', 'elevadorPed', {
                  relation: "Documentacao",
                  scope: {
                    where: {
                      tipoDocumento: 7, active: 1
                    },
                    include: ["TipoDocumento", "Processo"]
                  }
                }]
              }
            },
            {
              relation: 'Documentacao',
              scope: {
                where: {
                  active: true
                },
                include: 'TipoDocumento'
              }
            },
          ]
        }
      }).$promise.then(res => {
        this.data = res;
        this.formatZipCode();
        this.loaded = true;
        res.Documentacao.forEach((doc) => {
          this.$http.get("/api/upload/elv/files/" + doc.nome).then((result) => {
            if (result.data != null && !result.data.hasOwnProperty("error")) {
              doc.info = result.data;
            }
          }, (err) => {
            console.log(err);
          });
        });
        res.AnexoDocumento = [];
        res.pedElev.forEach(r => {
          r.Documentacao.forEach(d => {
            res.AnexoDocumento.push(d);
          })
        });
        res.AnexoDocumento.forEach((doc) => {
          this.$http.get("/api/upload/elv/files/" + doc.nome).then((result) => {
            if (result.data != null && !result.data.hasOwnProperty("error")) {
              doc.info = result.data;
            }
          }, (err) => {
            console.log(err);
          });
        });
      }).catch(error => {
        console.log(error);
        this.UI.addToast("Não foi possível ler o elevador.");
        this.$state.go('app.elv.elevadores.list');
      });
    } else { // id is undefined, just move to the list
      this.$state.go('app.elv.elevadores.list');
    }
  };

  generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  // Format bytes into correct units
  formatBytes = (bytes, decimals) => {
    if (bytes == null) return "N/D";
    if (bytes === 0) return '0 Bytes';
    let k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  formatZipCode = () => {
    if (this.data.cp4 === null) {
      this.data.formatedZipCode = "N/D";
    } else if (this.data.cp4 !== null && this.data.cp3 === null) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" + this.data.cp4,
          cp3: null
        }
      }).then(response => {
        this.data.localidade = response.data.result;
        this.data.formatedZipCode = this.data.cp4 + " " + this.data.localidade;
      });

    } else if (this.data.cp4 !== null && this.data.cp3 !== null) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" + this.data.cp4,
          cp3: ("000" + this.data.cp3).slice(-3)
        }
      }).then(response => {
        this.data.cp3 = ("000" + this.data.cp3).slice(-3);
        this.data.localidade = response.data.result;
        this.data.formatedZipCode = this.data.cp4 + "-" + this.data.cp3 + " " + this.data.localidade;
      });
    }
  };

  editElevador = () => {
    let elevador = angular.copy(this.data);
    let cenas = this.UI.showWaiting();

    this.ElvEquipamentoTipo.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(equipamentos => {
      this.ElvCliente.find({
        filter: {
          where: {
            active: true
          }
        }
      }).$promise.then(clientes => {
        this.ElvCondominio.find({
          filter: {
            where: {
              active: true
            }
          }
        }).$promise.then(condominios => {
          this.ElvEmie.find({
            filter: {
              where: {
                active: true
              }
            }
          }).$promise.then(emies => {
            this.ElvElevadorTipo.find({
              filter: {
                where: {
                  active: true
                }
              }
            }).$promise.then(tipos => {
              let options = {
                size: 'lg',
                template: require('./edit-elevador.dialog.html'),
                controller: ['$scope', '$dialog', '$http', ($scope, $dialog, $http) => {
                  $scope.equipamentos = equipamentos;
                  $scope.clientes = clientes;
                  $scope.condominios = condominios;
                  $scope.emies = emies;
                  $scope.tipos = tipos;

                  $scope.auxEquipamento = {
                    selected: angular.copy(elevador.equipamento),
                    infiniteScroll: { numToAdd: 20, currentItems: 20 }
                  };

                  $scope.auxCliente = {
                    selected: angular.copy(elevador.cliente),
                    infiniteScroll: { numToAdd: 20, currentItems: 20 }
                  };

                  $scope.auxCondominio = {
                    selected: angular.copy(elevador.condominio),
                    infiniteScroll: { numToAdd: 20, currentItems: 20 }
                  };

                  $scope.auxEmie = {
                    selected: angular.copy(elevador.emie),
                    infiniteScroll: { numToAdd: 20, currentItems: 20 }
                  };

                  $scope.auxTipo = {
                    selected: angular.copy(elevador.tipo),
                    infiniteScroll: { numToAdd: 20, currentItems: 20 }
                  };

                  //Infinite Scroll Magic
                  $scope.addMoreItems = (infiniteScroll) => {
                    infiniteScroll.currentItems += infiniteScroll.numToAdd;
                  };

                  $scope.getLocalidade = function () {
                    $scope.elevador.localidade = "";
                    let cp4 = parseInt($scope.elevador.cp4);
                    let cp3 = parseInt($scope.elevador.cp3);
                    if (!Number.isInteger(cp4)) {
                      $scope.elevador.cp3 = "";
                    } else if (cp4.toString().length !== 4) {
                      $scope.elevador.cp3 = "";
                    } else if (cp4.toString().length === 4) {
                      if (Number.isInteger(cp3)) {
                        if ($scope.elevador.cp3.toString().length === 3) {
                          $http.post('/api/public/getLocalidade', {
                            cp4: $scope.elevador.cp4,
                            cp3: $scope.elevador.cp3
                          }).then(response => {
                            $scope.elevador.localidade = response.data.result;
                          });
                        }
                      } else {
                        $http.post('/api/public/getLocalidade', {
                          cp4: $scope.elevador.cp4,
                          cp3: null
                        }).then(response => {
                          $scope.elevador.localidade = response.data.result;
                        });
                      }
                    }
                  };

                  $scope.elevador = elevador;
                  $scope.getLocalidade();

                  $scope.isOk = () => {
                    let o = $scope.elevador;
                    return o.numeroCamarario !== "" && o.morada !== "" && o.cp3 !== "" && o.cp4 !== "";
                  };

                  $scope.ok = () => {
                    $scope.elevador.equipamentoId = $scope.auxEquipamento.selected.id;
                    $scope.elevador.equipamento = $scope.auxEquipamento.selected;
                    $scope.elevador.condominioId = $scope.auxCondominio.selected ? $scope.auxCondominio.selected.id : null;
                    $scope.elevador.condominio = $scope.auxCondominio.selected;
                    $scope.elevador.clienteId = $scope.auxCliente.selected.id;
                    $scope.elevador.cliente.id = $scope.auxCliente.selected.id;
                    $scope.elevador.emieId = $scope.auxEmie.selected.id;
                    $scope.elevador.emie = $scope.auxEmie.selected;
                    $scope.elevador.tipo = $scope.auxTipo.selected;
                    $scope.elevador.tipoId = $scope.auxTipo.selected ? $scope.auxTipo.selected.id : null;
                    $dialog.close($scope.elevador);
                  };

                  $scope.cancel = () => {
                    $dialog.dismiss('cancel');
                  }
                }],
              };

              cenas.close();
              let modal = this.UI.showDialog(options);
              modal.then(res => {
                if (res) {
                  let cp4 = parseInt(res.cp4);
                  let cp3 = parseInt(res.cp3);
                  if (Number.isNaN(cp4)) {
                    res.cp4 = null;
                    res.cp3 = null;
                  } else {
                    res.cp4 = cp4;
                  }
                  if (Number.isNaN(cp3)) {
                    res.cp3 = null;
                  } else {
                    res.cp3 = cp3;
                  }
                  this.ElvElevador.upsert(res).$promise.then(() => {
                    this.loadData();
                    this.UI.addToast('Elevador atualizado com sucesso!');
                  }).catch((err) => {
                    console.log(err);
                    this.UI.addToast('Ocorreu um erro a atualizar o elevador.');
                  });
                }
              });

            }).catch(error => {
              console.log(error);
              this.UI.addToast("Não foi possível carregar Tipos de Elevador. Tente novamente");
            });
          }).catch(error => {
            console.log(error);
            this.UI.addToast("Não foi possível carregar EMIEs. Tente novamente");
          });

        }).catch(error => {
          console.log(error);
          this.UI.addToast("Não foi possível carregar Condomínios. Tente novamente");
        });

      }).catch(error => {
        console.log(error);
        this.UI.addToast("Não foi possível carregar Clientes. Tente novamente");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Não foi possível carregar Tipos de Equipamento. Tente novamente");
    });
  };

  addAnexo = () => {
    let options = {
      size: 'lg',
      controller: 'ELVElevadorFileUploaderController',
      controllerAs: 'm',
      template: require('./files.dialog.html'),
      resolve: {
        pID: () => {
          return this.data.id;
        },
        file: () => {
          return undefined;
        }
      }
    };
    let dialog = this.UI.showDialog(options);
    dialog.then(res => {
      if (res) {
        this.loadData();
        this.UI.addToast('Ficheiro carregado com sucesso!');
      }
    });
  };

  removeAnexo = (file) => {
    if (!file.TipoDocumento.id || !this.removableFileTypeIndexes.includes(file.TipoDocumento.id)) {
      this.UI.showAlert("Não é possível remover documentos deste tipo.");
      return;
    }
    let confirm = this.UI.showConfirm('Deseja remover o ficheiro?');
    confirm.then(res => {
      if (res) {
        file.active = 0;
        this.ElvElevadorDocumento.upsert(file).$promise.then(() => {
          this.loadData();
          this.UI.addToast('O ficheiro foi removido com sucesso.');
        });
      }
    });
  };

  addImage = () => {
    let self = this;
    let options = {
      size: 'lg',
      template: require('./images.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.images = {};
        $scope.label = "Adicionar imagem";
        $scope.newFileName = '';
        $scope.oldFileName = '';

        $scope.uuid = self.generateUUID();

        $scope.uploader = new self.FileUploader({
          url: '/api/Upload/elv/upload',
          queueLimit: 1,
          formData: [
            {
              key: 'value'
            }
          ]
        });

        let filter = {
          name: 'verifyIMG',
          fn: function (item, options) {
            return item.type.indexOf("image/") !== -1;
          }
        };
        $scope.uploader.filters.push(filter);

        $scope.uploader.onAfterAddingFile = (item) => {
          let partes = item.file.name.split(".");
          $scope.newFileName = $scope.uuid + "." + partes[partes.length - 1];

          if ($scope.uploader.queue.length > 1)
            $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
          $scope.oldFileName = $scope.uploader.queue[0].file.name;
        };

        $scope.uploader.onWhenAddingFileFailed = (img) => {
          self.UI.addToast("Por favor, carregue uma imagem");
        };

        $scope.uploader.onErrorItem = (response, status, headers) => {
          self.UI.addToast("A imagem não foi carregada com sucesso");
        };

        $scope.uploader.onBeforeUploadItem = (item) => {
          item.file.name = $scope.newFileName;
        };

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {
      this.ElvElevadorDocumento.create({
        id: 0,
        elevadorId: this.id,
        tipoDocumento: 3,
        nome: ok.newFileName,
        nomeAntigo: ok.oldFileName,
        active: 1
      }).$promise.then((res) => {
        if (ok.newFileName) {
          // File exists, upload it
          ok.uploader.uploadAll();
          this.loadData();
        } else {
          // No file, just update the list
          this.loadData();
        }
        this.UI.addToast("Imagem adicionada com sucesso!");
      }).catch(err => {
        this.UI.addToast("Erro a adicionar imagem.");
        console.log(err);
      });
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };

  editObservacoes = () => {
    let c = angular.copy(this.data);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {
        $scope.observacoes = c.observacoes;

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.ok = () => {
          $dialog.close($scope.observacoes);
        }
      }]
    });
    dialog.then((res) => {
      c.observacoes = res;
      c.$save().then(() => {
        this.loadData();
        this.UI.addToast('Elevador atualizado com sucesso!');
      }).catch((err) => {
        console.log(err);
        this.UI.addToast('Ocorreu um erro ao atualizar elevador.');
      });
    }
    );
  };
}

DetalhesElevadorController.$inject = ['$state', 'UIService', '$http', 'ElvElevador', 'ElvElevadorDocumento', 'FileUploader', 'ElvEquipamentoTipo', 'ElvCliente', 'ElvCondominio', 'ElvEmie', 'ElvElevadorTipo'];
