export default class DetalhesClientesElevadorController {
  constructor(ElvCliente, ElvClienteTipo, $state, UIService, $http, PRIClientes) {
    this.UI = UIService;
    this.$http = $http;
    this.id = $state.params.id;
    this.ElvCliente = ElvCliente;
    this.ElvClienteTipo = ElvClienteTipo;
    this.PRIClientes = PRIClientes;
    this.loaded = false;
    this.clienteTipo = null;
    this.loadData();
    this.getTipo();
  }

  loadData = () => {
    this.loaded = false;
    this.ElvCliente.findOne({
      filter: {
        where: {
          active: true,
          id: this.id,
        },
        include: [{
          relation: 'tipo',
          where: {
            active: 1
          }
        },
          {
            relation: 'ordemIntervencao',
            where: {
              active: 1
            }
          },
          {
            relation: 'elevadores',
            where: {
              active: 1
            }
          }]
      }
    }).$promise.then(res => {
      this.data = res;
      this.formatZipCode();
      this.loaded = true;
    })
  };

  getTipo = () => {
    this.loaded = false;
    this.ElvClienteTipo.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(res => {
      this.clienteTipo = res;
      this.loaded = true;
    })
  };


  formatZipCode = () => {
    if (this.data.cp4 === null) {
      this.data.formatedZipCode = "N/D";
    } else if (this.data.cp4 !== null && this.data.cp3 === null) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" + this.data.cp4,
          cp3: null
        }
      }).then(response => {
        this.data.localidade = response.data.result;
        this.data.formatedZipCode = this.data.cp4 + " " + this.data.localidade;
      }).catch(error => {
        // At least show something
        this.data.formatedZipCode = this.data.cp4 + "-" + this.data.cp3 + " " + this.data.localidade;
      });

    } else if (this.data.cp4 !== null && this.data.cp3 !== null) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" + this.data.cp4,
          cp3: ("000" + this.data.cp3).slice(-3)
        }
      }).then(response => {
        this.data.cp3 = ("000" + this.data.cp3).slice(-3);
        this.data.localidade = response.data.result;
        this.data.formatedZipCode = this.data.cp4 + "-" + this.data.cp3 + " " + this.data.localidade;
      }).catch(error => {
        // At least show something
        this.data.formatedZipCode = this.data.cp4 + "-" + this.data.cp3 + " " + this.data.localidade;
      });
    }
  };

  editObservacoes = () => {
    let c = angular.copy(this.data);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {
        $scope.observacoes = c.observacoes;

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.ok = () => {
          $dialog.close($scope.observacoes);
        }
      }]
    });

    dialog.then((res) => {
        c.observacoes = res;
        c.$save().then(() => {
          this.loadData();
          this.UI.addToast('Cliente atualizado com sucesso!');
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro ao atualizar cliente.');
        });
      }
    );
  };

  editDetalhes = () => {
    let copy = angular.copy(this.data);
    let options = {
      size: 'lg',
      template: require('./details.dialog.html'),
      controller: ['$dialog', '$scope', '$http', 'ElvClienteTipo', function ($dialog, $scope, $http, ElvClienteTipo) {

        $scope.cliente = {
          id: this.id,
          nif: copy.nif,
          nome: copy.nome,
          email: copy.email,
          telemovel: copy.telemovel,
          morada: copy.morada,
          cp4: copy.cp4,
          cp3: copy.cp3,
          homepage: copy.homepage,
          active: true,
        };

        $scope.auxTipo = {
          selected: angular.copy(copy.tipo)
        };

        ElvClienteTipo.find({
          filter: {
            where: {
              active: true
            }
          }
        }).$promise.then(tipos => {
          $scope.tipos = tipos;
        });

        $scope.getLocalidade = function () {
          $scope.cliente.localidade = "";
          let cp4 = parseInt($scope.cliente.cp4);
          let cp3 = parseInt($scope.cliente.cp3);
          if (!Number.isInteger(cp4)) {
            $scope.cliente.cp3 = "";
          } else if (cp4.toString().length !== 4) {
            $scope.cliente.cp3 = "";
          } else if (cp4.toString().length === 4) {
            if (Number.isInteger(cp3)) {
              if ($scope.cliente.cp3.toString().length === 3) {
                $http.post('/api/public/getLocalidade', {
                  cp4: $scope.cliente.cp4,
                  cp3: $scope.cliente.cp3
                }).then(response => {
                  $scope.cliente.localidade = response.data.result;
                });
              }
            } else {
              $http.post('/api/public/getLocalidade', {
                cp4: $scope.cliente.cp4,
                cp3: null
              }).then(response => {
                $scope.cliente.localidade = response.data.result;
              });
            }
          }
        };

        $scope.cliente = copy;
        $scope.getLocalidade();

        $scope.isOk = () => {
          let o = $scope.cliente;
          return o.designacao !== "" && o.nif !== "" && o.email !== "";
        };

        $scope.ok = () => {
          delete $scope.cliente.localidade;
          $scope.cliente.tipoId = $scope.auxTipo.selected.id;
          $scope.cliente.tipo.id = $scope.auxTipo.selected.id;
          $dialog.close($scope.cliente);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }],
    };
    let modal = this.UI.showDialog(options);
    modal.then(res => {
      if (res) {
        let cp4 = parseInt(res.cp4);
        let cp3 = parseInt(res.cp3);
        if (Number.isNaN(cp4)) {
          res.cp4 = null;
          res.cp3 = null;
        } else {
          res.cp4 = cp4;
        }
        if (Number.isNaN(cp3)) {
          res.cp3 = null;
        } else {
          res.cp3 = cp3;
        }
        this.ElvCliente.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Dados do cliente atualizados com sucesso!');
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Erro ao atualizar dados do cliente.')
        });
      }
    });
  };

  resyncClient = () => {
    this.ElvCliente.findOne({
      filter: {
        where: {
          id: this.data.id
        }
      }
    }).$promise.then((c) => {
      if (!c.refClientePrimavera) {
        this.UI.addToast("Não foi possível atualizar cliente.");
      } else {
        this.PRIClientes.find({
          filter: {
            where: {
              cliente: c.refClientePrimavera
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            c.nome = res[0].nome;
            c.nif = res[0].numcontrib;
            c.cp4 = res[0].facCp.split("-")[0];
            c.cp3 = res[0].facCp.split("-")[1];
            c.localidade = res[0].facCploc;
            c.telemovel = res[0].facTel;
            c.morada = res[0].facMor;
            c.observacoes = res[0].notas;
            c.atualizadoa = res[0].dataultimaactualizacao;
            c.refClientePrimavera = res[0].cliente;
            c.email = res[0].cduEmail;
            c.$save().then((updatedCliente) => {
              this.loadData();
              this.UI.addToast('Sincronização realizada com sucesso.');
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Falha na gravação do cliente. Por favor tente mais tarde.");
            });
          } else {
            this.UI.addToast("Não foi possível atualizar cliente.");
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar cliente.");
        });
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar cliente.");
    });
  };

}

DetalhesClientesElevadorController.$inject = ['ElvCliente', 'ElvClienteTipo', '$state', 'UIService', '$http', 'PRIClientes'];
