import angular from 'angular';

import {routes} from "./clientes.routes";
import DirectoryClientesElevadorController from "./directory/directory.controller";
import DetalhesClientesElevadorController from "./details/details.controller";

export default angular.module('app.elv.clientes', [])
  .config(routes)
  .controller('DirectoryClientesElevadorController', DirectoryClientesElevadorController)
  .controller('DetalhesClientesElevadorController', DetalhesClientesElevadorController)
  .name;
