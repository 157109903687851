export default class OblViaturaDetailsController {
  constructor(
    $state,
    $q,
    OblViatura,
    OblViaturaGuia,
    AuthenticationService,
    UIService
  ) {
    this.$state = $state;
    this.$q = $q;
    this.OblViatura = OblViatura;
    this.OblViaturaGuia = OblViaturaGuia;
    this.Auth = AuthenticationService;
    this.UI = UIService;

    this.limit = 3;

    this.id = $state.params.id;
    if (!this.id) {
      this.$state.go("app.obler.viaturas.list");
    } else {
      this.loadData();
    }
  }

  getHistoryMessage = (h) => {
    return `Abatido ${h.quantidade} ${h.Produto.unidade.toLowerCase()} de ${
      h.Produto.designacao
    }`;
  };

  viewGuiaHistorico = () => {
    this.UI.showDialog({
      template: require("./at.dialog.html"),
      controller: [
        "$scope",
        "OblViaturaGuia",
        ($scope, OblViaturaGuia) => {
          $scope.currentItems = 0;
          $scope.guias = [];

          $scope.addMoreItems = () => {
            OblViaturaGuia.find({
              filter: {
                where: {
                  viaturaId: this.id,
                  active: 1,
                },
                include: "FolhaAbate",
                order: "data DESC",
                limit: 5,
                skip: $scope.currentItems,
              },
            }).$promise.then((r) => {
              $scope.guias.push(...r);
              $scope.currentItems += r.length;
            });
          };

          $scope.addMoreItems();

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    });
  };

  getGuia = () => {
    if (!this.data.Guias.length) {
      this.UI.addToast("A viatura não tem nenhuma guia de transporte!");
      return;
    }
  };

  sync = () => {
    if (!this.data.primaveraId) {
      this.UI.addToast("Referência Primavera não definida!");
      return;
    }
    this.UI.showConfirm(
      "Deseja forçar sincronização da viatura com o Primavera? Esta ação pode demorar algum tempo."
    ).then((r) => {
      if (r) {
        this.UI.addToast("Sincronização inicializada. Por favor aguarde...");
        let dialog = this.UI.showWaiting();
        this.OblViatura.sync({ id: this.id }).$promise.then((_) => {
          this.UI.addToast("Sincronização concluída");
          dialog.close();
          this.loadData();
        });
      }
    });
  };

  geraGuiaAT = () => {
    if (!this.data.primaveraId) {
      this.UI.addToast("Referência Primavera não definida!");
      return;
    }
    this.UI.showConfirm(
      "Deseja criar uma nova Guia de Transporte? Esta ação irá substituir a guia anterior"
    ).then((r) => {
      if (r) {
        let dialog = this.UI.showWaiting();
        this.OblViatura.guiaAT({ id: this.id }).$promise.then((_) => {
          dialog.close();
          this.UI.addToast("Guia AT criada");
          this.loadData();
        });
      }
    });
  };

  loadData = () => {
    this.loaded = false;
    this.OblViatura.findOne({
      filter: {
        where: {
          id: this.id,
        },
        include: [
          {
            relation: "Guias",
            scope: {
              where: {
                active: true,
              },
              include: "Funcionario",
              limit: 1,
              order: "data DESC",
            },
          },
          {
            relation: "Historico",
            scope: {
              where: {
                active: true,
              },
              order: "data DESC",
              include: ["Produto", "Funcionario"],
            },
          },
          {
            relation: "Condutor",
            scope: {
              where: {
                active: true,
              },
            },
          },
          {
            relation: "Stock",
            scope: {
              where: {
                active: true,
              },
              include: {
                relation: "Produto",
                scope: {
                  where: {
                    active: true,
                  },
                  include: {
                    relation: "Fornecedor",
                    scope: {
                      where: {
                        active: true,
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
    }).$promise.then((r) => {
      this.OblViaturaGuia.count({
        where: {
          viaturaId: this.id,
          active: 1,
        },
      }).$promise.then((c) => {
        this.numGuias = c.count;
        this.data = r;
        this.loaded = true;
      });
    });
  };

  editObservations = () => {
    let data = angular.copy(this.data);
    this.UI.showDialog({
      template: require("./observations.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.data = data.observacoes;

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    })
      .then((r) => {
        this.OblViatura.prototype$updateAttributes(
          { id: this.id },
          {
            observacoes: r,
          }
        )
          .$promise.then((_) => {
            this.UI.addToast("Observações alteradas com sucesso");
            this.data.observacoes = r;
          })
          .catch((e) => {
            this.UI.addToast("Não foi possível alterar observações");
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  editDetails = () => {
    let details = {
      id: this.data.id,
      marca: this.data.marca,
      modelo: this.data.modelo,
      matricula: this.data.matricula,
      observacoes: this.data.observacoes,
      tecnicoId: this.data.tecnicoId,
      Condutor: this.data.Condutor,
      active: true,
    };

    this.UI.showDialog({
      template: require("./details.dialog.html"),
      controller: [
        "$scope",
        "FuncionarioEntidadeProprietaria",
        ($scope, FuncionarioEntidadeProprietaria) => {
          $scope.data = details;

          $scope.tecnicosLoaded = false;

          FuncionarioEntidadeProprietaria.find({
            filter: {
              where: {
                entidadeProprietariaId: 3,
                active: true,
              },
              include: {
                relation: "funcionario",
              },
            },
          }).$promise.then((f) => {
            $scope.users = [];
            f.forEach((r) => {
              if (r.funcionario) {
                $scope.users.push(r.funcionario);
              }
            });
            $scope.users = $scope.users.sort((a, b) =>
              a.name.localeCompare(b.name)
            );
            $scope.tecnicosLoaded = true;
          });

          $scope.ok = () => {
            $scope.data.tecnicoId = $scope.data.Condutor.id;
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    })
      .then((data) => {
        console.log(data);
        this.OblViatura.prototype$updateAttributes(
          { id: this.id },
          {
            marca: data.marca,
            modelo: data.modelo,
            matricula: data.matricula,
            tecnicoId: data.tecnicoId,
          }
        ).$promise.then((_) => {
          this.UI.addToast("Dados da viatura atualizados");
          this.loadData();
        });
      })
      .catch((e) => {
        this.UI.addToast("Ocorreu um erro ao atualizar dados");
        this.loadData();
      });
  };

  limitTo = () => {
    if (angular.isDefined(this.limit)) {
      this.limit = undefined;
    } else {
      this.limit = 3;
    }
  };
}

OblViaturaDetailsController.$inject = [
  "$state",
  "$q",
  "OblViatura",
  "OblViaturaGuia",
  "AuthenticationService",
  "UIService",
];
