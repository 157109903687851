import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, TextField, List, ListItem, ListItemText, ListItemButton, Grid, IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useModal } from 'mui-modal-provider';
import { DialogAdicionarDefeito } from './dialogAdicionarDefeito';
import { mdiDelete } from '@mdi/js';
import { MdiIcon } from '@components';
export function DialogDefeitos({ number, pontoId, validation, defeitos, loadDefects, type, lastToggleValue, onClose, ...props }) {
    const validationArray = validation ? JSON.parse(validation) : [];
    const filteredDefeitos = validationArray.length === 0 ? defeitos : defeitos.filter(defeito => validationArray.includes(defeito.id));
    const initialTabValue = filteredDefeitos.length > 0 ? filteredDefeitos[0].id : 0;
    const [tabValue, setTabValue] = useState(initialTabValue);
    const [listaDefetios, setListaDefeitos] = useState([]); // Array dos defeitos
    const [selectedDefects, setSelectedDefects] = useState([]); // Array dos defeitos
    const [notas, setNotas] = useState([]); /// Array das notas dos defeitos (cada nota corresponde ao defeito com a mesmo posição do array)
    const currentDefects = defeitos.find(defeito => defeito.id === tabValue)?.Defeitos || []; // Array com os defeitos existentes de cada tab
    const defectType = defeitos.find(defeito => defeito.id === tabValue)?.designacao; // Titulo do defeito a enviar para a modal
    const defectsForCurrentTab = selectedDefects
        .map((defeito, index) => ({ defeito, index }))
        .filter(({ defeito }) => defeito.coreListaRelatorioDefeitoTipoId === tabValue); // Array com os defeitos adicionados agrupado pelas tabs
    const { showModal } = useModal();
    useEffect(() => {
        /// Carregar defeitos já selecionados
        if (loadDefects.length > 0) {
            defeitos.map(defeitoList => {
                defeitoList.Defeitos.map(d => {
                    for (let i = 0; i < loadDefects.length; i++) {
                        if (d.id === loadDefects[i].id) {
                            addDefect(d, loadDefects[i].nota);
                        }
                    }
                });
            });
        }
    }, []);
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleOk = () => {
        onClose(listaDefetios, number, pontoId);
    };
    const addDefect = (newDefect, nota) => {
        if (newDefect != undefined) {
            const newNota = nota ?? '';
            setListaDefeitos(prevLista => [...prevLista, { id: newDefect.id, pontoId: pontoId, nota: newNota }]);
            setSelectedDefects(prevDefect => [...prevDefect, newDefect]);
            if (nota == undefined) {
                setNotas(prevNota => [...prevNota, '']);
            }
            else {
                setNotas(prevNota => [...prevNota, nota]);
            }
        }
    };
    const editDefect = (newDefect, newNota, position) => {
        // remove defeito insere novo na mesma posicao
        if (newDefect != undefined && position != undefined) {
            setSelectedDefects(prevDefects => prevDefects.map((defeito, index) => (index === position ? newDefect : defeito)));
            setNotas(prevNotas => prevNotas.map((n, index) => (index === position ? newNota : n)));
        }
        else {
            setNotas(prevNotas => prevNotas.map((n, index) => (index === position ? newNota : n)));
            if (newDefect != undefined) {
                setListaDefeitos(prevLista => prevLista.map((def, index) => (index === position ? { id: newDefect.id, pontoId: pontoId, nota: newNota ?? '' } : def)));
            }
        }
    };
    const removeDefect = (idx) => {
        setSelectedDefects(prevDefect => prevDefect.filter((defect, index) => index !== idx));
        setNotas(prevNota => prevNota.filter((n, index) => index !== idx));
        setListaDefeitos(prevLista => prevLista.filter((_, index) => index !== idx));
    };
    return (<Dialog {...props} maxWidth={'lg'} fullWidth>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        {type === 'Defeitos' ? (<Tabs value={tabValue} onChange={handleChange} aria-label='basic tabs example'>
            {filteredDefeitos.map(defeito => (<Tab key={defeito.id} label={defeito.designacao} value={defeito.id}/>))}
          </Tabs>) : (<Box>
            <TextField id='standard-multiline-static' label='Observações Internas' multiline rows={4} variant='standard' fullWidth/>
          </Box>)}
        {<Box mt={2}>
            {defectsForCurrentTab.length === 0 ? ('Sem defeitos a apresentar!') : (<List dense>
                {defectsForCurrentTab.map(({ defeito, index }) => (<Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs={11}>
                      <ListItemButton onClick={() => {
                        showModal(DialogAdicionarDefeito, {
                            title: defectType,
                            defeitos: currentDefects,
                            selected: defeito,
                            note: notas[index] == undefined ? '' : notas[index],
                            indexPosition: index,
                            onClose: editDefect
                        });
                    }}>
                        <ListItemText primary={`${defeito.titulo} - ${defeito.subtitulo}`} secondary={notas[index] == '' ? '' : `Nota: ${notas[index]}`}/>
                      </ListItemButton>
                    </Grid>
                    <Grid item xs={1}>
                      <ListItem key={defeito.id} secondaryAction={<Tooltip title={'Remover defeito'}>
                            <IconButton onClick={() => removeDefect(index)}>
                              <MdiIcon path={mdiDelete}/>
                            </IconButton>
                          </Tooltip>}></ListItem>
                    </Grid>
                  </Grid>))}
              </List>)}
          </Box>}
      </DialogContent>
      <DialogActions>
        <Button type='button' onClick={() => onClose(undefined, number, pontoId, lastToggleValue)}>
          Cancelar
        </Button>
        <Button type='button' onClick={() => {
            showModal(DialogAdicionarDefeito, {
                title: defectType,
                defeitos: currentDefects,
                onClose: addDefect
            });
        }}>
          Adicionar Defeito
        </Button>
        <Button variant='contained' onClick={handleOk} disabled={listaDefetios.length === 0 ? true : false}>
          OK
        </Button>
      </DialogActions>
    </Dialog>);
}
