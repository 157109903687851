export function routes($stateProvider) {
  $stateProvider
    .state('app', {
      template: require('./layout/layout.view.html'),
      controller: 'LayoutController',
      controllerAs: 'vm'
    })
    .state('auth', {
      template: '<div class="loginBg" ui-view></div>'
    })
    .state('app.dashboard', {
      url: '/dashboard',
      title: 'Dashboard',
      template: require('./dashboard/dashboard.view.html'),
      controller: 'DashboardController',
      controllerAs: 'vm',
    });
}

routes.$inject = ['$stateProvider'];
