export function routes($stateProvider) {
  $stateProvider
    .state('auth.login', {
      url: '/login',
      title: 'Login',
      template: require('./login/login.view.html'),
      controller: 'LoginController',
      controllerAs: 'vm',
    })
    .state('auth.forgot-password', {
      url: '/forgot-password',
      title: 'Esqueceu-se da Password',
      template: '',
      controller: '',
      controllerAs: 'vm',
    })
    .state('app.settings', {
      url: '/administracao',
      title: 'Administração',
      template: require('./settings/settings.view.html'),
      controller: 'SettingsController',
      controllerAs: 'vm',
    })
    .state('app.profile', {
      url: '/perfil',
      title: 'Perfil',
      template: require('./profile/profile.view.html'),
      controller: 'ProfileController',
      controllerAs: 'vm'
    })
  ;
}

export function routing($urlRouterProvider, $locationProvider) {
  //$locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise('/dashboard');
}

routing.$inject  = ['$urlRouterProvider', '$locationProvider'];
routes.$inject = ['$stateProvider'];
