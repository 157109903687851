import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
export function AlertDialog({ message, onClose, ...props }) {
    return (<Dialog {...props} fullWidth maxWidth={'md'}>
      <DialogTitle>{props.title}</DialogTitle>
      {message && <DialogContent>{message}</DialogContent>}
      <DialogActions>
        <Button onClick={() => {
            onClose(true);
        }} variant='contained'>
          OK
        </Button>
      </DialogActions>
    </Dialog>);
}
