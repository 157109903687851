export default class AtvLocaisListController {
  constructor($q, $state, UIService, AuthorizationService, AtvLocal, AtvAtivo, AtvEntidadeProprietaria) {
    this.$q = $q;
    this.state = $state;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.AtvLocal = AtvLocal;
    this.AtvAtivo = AtvAtivo;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.loaded = false;
    this.locais = [];
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.AtvLocal.find({
      filter: {
        where: {
          active: 1
        },
        include: [{
          relation: 'entidadeproprietaria',
          scope: {
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then(t => {
      this.locais = t;
      this.loaded = true;
    }).catch(() => {
      this.loaded = true;
      this.UI.addToast('Erro ao carregar locais');
    });
  };

  addLocal = () => {
    let cenas = this.UI.showWaiting();
    this.AtvEntidadeProprietaria.find({
      filter: {
        where: {
          active: 1
        },
        order: 'ordem ASC'
      }
    }).$promise.then((eps) => {
      cenas.close();
      let options = {
        size: 'lg',
        template: require('./new.dialog.html'),
        controller: ['$dialog', '$scope', (dialog, scope) => {
          scope.title = "Novo Local";
          scope.local = {};
          scope.eps = eps;
          scope.auxEntidadeProprietaria = {selected: null};

          scope.ok = () => {
            dialog.close(scope);
          };

          scope.cancel = () => {
            dialog.dismiss('cancel');
          };
        }]
      };

      let modal = this.UI.showDialog(options);

      modal.then((res) => {
        if (res && res.local) {
          res.local.id = 0;
          res.local.entidadeProprietariaId = res.auxEntidadeProprietaria.selected.id;
          res.local.active = 1;
          this.AtvLocal.create(res.local).$promise.then((res) => {
            this.UI.addToast("Local criado com sucesso");
            this.loadData();
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro na criação de local. Por favor tente mais tarde.");
          });
        }
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar entidades proprietárias. Verifique a ligação");
      cenas.close();
    });
  };

  editLocal = (r) => {
    let cenas = this.UI.showWaiting();
    this.AtvEntidadeProprietaria.find({
      filter: {
        where: {
          active: 1
        },
        order: 'ordem ASC'
      }
    }).$promise.then((eps) => {
      cenas.close();
      let options = {
        size: 'lg',
        template: require('./new.dialog.html'),
        controller: ['$dialog', '$scope', (dialog, scope) => {
          scope.title = "Editar Local";
          scope.local = angular.copy(r);
          scope.eps = eps;
          scope.auxEntidadeProprietaria = {selected: scope.local.entidadeproprietaria};

          scope.ok = () => {
            dialog.close(scope);
          };

          scope.cancel = () => {
            dialog.dismiss('cancel');
          };
        }]
      };

      let modal = this.UI.showDialog(options);

      modal.then((res) => {
        if (res && res.local) {
          res.local.entidadeProprietariaId = res.auxEntidadeProprietaria.selected.id;
          res.local.entidadeproprietaria = res.auxEntidadeProprietaria.selected;
          res.local.active = 1;
          this.AtvLocal.upsert(res.local).$promise.then((res) => {
            this.UI.addToast("Local editado com sucesso");
            this.loadData();
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro na edição de local. Por favor tente mais tarde.");
          });
        }
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar entidades proprietárias. Verifique a ligação");
      cenas.close();
    });
  };

  checkDeleteLocal = (a) => {
    let cenas = this.UI.showWaiting();
    this.AtvLocal.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((localToRemove) => {
      // Find if we have something that might block deletion
      this.AtvAtivo.count({
        fields: {id: true},
        where: {
          active: 1,
          localId: localToRemove.id
        }
      }).$promise.then((ativos) => {
        cenas.close();
        if (ativos.count > 0) {
          let msg = "Não é possível remover este local.\nExiste" + (ativos.count > 1 ? "m " : " ");
          msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")) : "";
          msg += "associado" + (ativos.count > 1 ? "s" : "") + " a este local.";
          this.UI.showAlert(msg);
        } else {
          this.confirmDeleteLocal(a);
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("De momento não é possível remover locais. Por favor tente mais tarde");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("De momento não é possível remover locais. Por favor tente mais tarde");
    });
  };

  confirmDeleteLocal = (a) => {
    let title = "Eliminar Local";
    let warning = "Tem a certeza que pretende remover este local?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeLocal(a);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeLocal = (a) => {
    this.loaded = false;
    // Find again familia to delete so we can update it
    this.AtvLocal.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((localToRemove) => {
      localToRemove.active = 0;
      localToRemove.$save().then((res) => {
        this.UI.addToast("Local eliminado com sucesso!");
        this.loadData();
      }, (error) => {
        console.log(error);
        this.loaded = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível eliminar o local. Verifique se ainda existe.");
      this.loadData();
    });
  };
}

AtvLocaisListController.$inject = ['$q', '$state', 'UIService', 'AuthorizationService', 'AtvLocal', 'AtvAtivo', 'AtvEntidadeProprietaria'];
