export default class AtvAtivosListController {
  constructor($filter, $q, $state, AuthenticationService, Group, AtvAtivo, AtvAtivosService, AtvInteracao, AtvEntidadeProprietaria, AtvPropriedade, AtvLocal, AtvEstadoCompra, AtvFamilia, AtvSubfamilia, AtvTipoAtivo, AtvTamanho, AtvMarca, AtvFornecedor, AtvAtivoPrefixo, AtvInteracaoTipo, AtvAtivoAlteracaoEstado, Funcionario, UIService) {
    this.$filter = $filter;
    this.$q = $q;
    this.$state = $state;
    this.Auth = AuthenticationService;
    this.Group = Group;
    this.AtvAtivo = AtvAtivo;
    this.AtvAtivosService = AtvAtivosService;
    this.AtvInteracao = AtvInteracao;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.AtvPropriedade = AtvPropriedade;
    this.AtvLocal = AtvLocal;
    this.AtvEstadoCompra = AtvEstadoCompra;
    this.AtvFamilia = AtvFamilia;
    this.AtvSubfamilia = AtvSubfamilia;
    // this.AtvTipoAtivo = AtvTipoAtivo;
    this.AtvTamanho = AtvTamanho;
    this.AtvMarca = AtvMarca;
    this.AtvFornecedor = AtvFornecedor;
    // this.AtvAtivoPrefixo = AtvAtivoPrefixo;
    this.AtvInteracaoTipo = AtvInteracaoTipo;
    this.AtvAtivoAlteracaoEstado = AtvAtivoAlteracaoEstado;
    this.Funcionario = Funcionario;
    this.UI = UIService;
    this.opt = $state.params;
    this.ativos = [];
    this.entidadesProprietarias = [];
    this.propriedades = [];
    this.locais = [];
    this.estadosCompra = [];
    this.familias = [];
    this.subfamilias = [];
    // this.tiposAtivo = [];
    this.tamanhos = [];
    this.marcas = [];
    this.fornecedores = [];
    // this.prefixos = [];
    this.interacoesTipo = [];
    this.funcionarios = [];
    this.simnao2 = [{name:"Não Aplicável", value: 2}, {name: "Sim", value: 1}, {name: "Não", value: 0}];

    // If loadData() finished or not
    this.dataLoaded = false;
    // If loadData() fails for some reason
    this.errorLoadingData = false;

    this.listOpt = [];
    // Total processes for currently being displayed
    this.total = 0;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [
      {val: 'a', name: 'Igual', type: 'o'},
      {val: 'b', name: 'Diferente', type: 'o'},
      {val: 'c', name: 'Começa por', type: 'o'},
      {val: 'd', name: 'Termina com', type: 'o'},
      {val: 'e', name: 'Contém', type: 'o'},
      {val: 'a', name: 'Igual (=)', type: 'd'},
      {val: 'b', name: 'Diferente de (≠)', type: 'd'},
      {val: 'c', name: 'Posterior a (>)', type: 'd'},
      {val: 'd', name: 'Anterior a (<)', type: 'd'},
      {val: 'e', name: 'Posterior ou igual (≥)', type: 'd'},
      {val: 'f', name: 'Anterior ou igual (≤)', type: 'd'},
      {val: 'x', name: 'Não preenchido', type: 'd'},
      {val: 'y', name: 'Preenchido', type: 'd'},
      {val: 'a', name: 'Igual', type: 's'},
      {val: 'b', name: 'Diferente', type: 's'}
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      // {
      //   id: 'AtvAtivo.ativoPrefixoId',
      //   name: 'Prefixo',
      //   type: 's',
      //   list: this.prefixos
      // },
      {
        id: 'AtvAtivo.iidDisplay',
        name: 'ID Ativo',
        type: 'o'
      },
      {
        id: 'AtvAtivo.iidOldDisplay',
        name: 'Anterior Nº Ativo',
        type: 'o'
      },
      {
        id: 'AtvAtivo.iidLegacy',
        name: 'Antiga Referência',
        type: 'o'
      },
      {
        id: 'AtvAtivo.numImobilizado',
        name: 'Nº Imobilizado',
        type: 'o'
      },
      {
        id: 'AtvAtivo.designacao',
        name: 'Designação',
        type: 'o'
      },
      {
        id: 'AtvAtivo.referencia',
        name: 'Referência',
        type: 'o'
      },
      // {
      //   id: 'AtvAtivo.tipoAtivoId',
      //   name: 'Tipo de Ativo',
      //   type: 's',
      //   list: this.tiposAtivo
      // },
      {
        id: 'AtvAtivo.fornecedorId',
        name: 'Fornecedor',
        type: 's',
        list: this.fornecedores
      },
      {
        id: 'AtvAtivo.entidadeProprietariaId',
        name: 'Entidade Proprietária',
        type: 's',
        list: this.entidadesProprietarias
      },
      {
        id: 'AtvAtivo.propriedadeId',
        name: 'Área Organizacional',
        type: 's',
        list: this.propriedades
      },
      {
        id: 'AtvAtivo.familiaId',
        name: 'Família',
        type: 's',
        list: this.familias
      },
      {
        id: 'AtvAtivo.subfamiliaId',
        name: 'Subfamília',
        type: 's',
        list: this.subfamilias
      },
      {
        id: 'AtvAtivo.localId',
        name: 'Local',
        type: 's',
        list: this.locais
      },
      {
        id: 'AtvAtivo.estadoCompraId',
        name: 'Estado na Compra',
        type: 's',
        list: this.estadosCompra
      },
      {
        id: 'AtvInteracao.interacaoTipoId',
        name: 'Última Interação',
        type: 's',
        list: this.interacoesTipo
      },
      {
        id: 'AtvAtivo.dataCompra',
        name: 'Data de Compra',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'AtvAtivo.dataFabrico',
        name: 'Data de Fabrico',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'AtvAtivo.dataFimGarantia',
        name: 'Data de Fim de Garantia',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'AtvAtivo.dataValidade',
        name: 'Data de Validade',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'AtvAtivo.prazoValidade',
        name: 'Prazo de validade',
        type: 'o'
      },
      {
        id: 'AtvAtivo.dataAbate',
        name: 'Data de Abate',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'AtvAtivo.ultimaCalibracao',
        name: 'Última Calibração',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'AtvAtivo.ultimaManutencao',
        name: 'Última Manutenção',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'AtvAtivo.ultimaVerificacao',
        name: 'Última Verificação',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'proximaCalibracao',
        name: 'Próxima Calibração',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'proximaManutencao',
        name: 'Próxima Manutenção',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'proximaVerificacao',
        name: 'Próxima Verificação',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'dataFimEmprestimo',
        name: 'Fim de Empréstimo',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'AtvAtivo.periodicidadeCalibracao',
        name: 'Periodicidade de Calibração',
        type: 'o'
      },
      {
        id: 'AtvAtivo.periodicidadeManutencao',
        name: 'Periodicidade de Manutenção',
        type: 'o'
      },
      {
        id: 'AtvAtivo.periodicidadeVerificacao',
        name: 'Periodicidade de Verificação',
        type: 'o'
      },
      {
        id: 'AtvAtivo.tamanhoId',
        name: 'Tamanho',
        type: 's',
        list: this.tamanhos
      },
      {
        id: 'AtvAtivo.marcaId',
        name: 'Marca',
        type: 's',
        list: this.marcas
      },
      {
        id: 'AtvAtivo.modelo',
        name: 'Modelo',
        type: 'o'
      },
      {
        id: 'AtvAtivo.numSerie',
        name: 'Nº Série',
        type: 'o'
      },
      {
        id: 'AtvAtivo.macAddress',
        name: 'MAC Address',
        type: 'o'
      },
      {
        id: 'AtvAtivo.imei',
        name: 'IMEI',
        type: 'o'
      },
      {
        id: 'AtvAtivo.firmware',
        name: 'Firmware',
        type: 'o'
      },
      {
        id: 'AtvAtivo.licencaSoftware',
        name: 'Licença de Software',
        type: 'o'
      },
      {
        id: 'AtvAtivo.especificacoesTecnicas',
        name: 'Especificações Técnicas',
        type: 'o'
      },
      {
        id: 'AtvAtivo.acessorios',
        name: 'Acessórios',
        type: 'o'
      },
      {
        id: 'AtvAtivo.calibracaoExterior',
        name: 'Calibração Exterior',
        type: 's',
        list: this.simnao2
      },
      {
        id: 'AtvAtivo.observacoes',
        name: 'Observações',
        type: 'o'
      },
      {
        id: 'AtvAtivo.afetoaId',
        name: 'Afeto a',
        type: 's',
        list: this.funcionarios
      }
    ];

    this.displayColumns = [
      {displayName: 'ID SGI', name: 'id', visible: false},
      // {displayName: 'Prefixo', name: 'prefixo', visible: false},
      {displayName: 'Nº Ativo', name: 'iidAtivo', visible: false},
      {displayName: 'ID do Ativo', name: 'iidDisplay', visible: true},
      {displayName: 'Antigo Nº Ativo', name: 'iidOldDisplay', visible: false},
      {displayName: 'Antiga Referência', name: 'iidLegacy', visible: false},
      {displayName: 'Nº Imobilizado', name: 'numImobilizado', visible: false},
      {displayName: 'Designação', name: 'designacao', visible: true},
      {displayName: 'Referência', name: 'referencia', visible: false},
      {displayName: 'Entidade Proprietária', name: 'entidadeProprietaria', visible: false},
      {displayName: 'Área Organizacional', name: 'propriedade', visible: false},
      {displayName: 'Família', name: 'familia', visible: false},
      {displayName: 'Subfamília', name: 'subfamilia', visible: false},
      {displayName: 'Local', name: 'local', visible: false},
      // {displayName: 'Tipo de Ativo', name: 'tipoAtivo', visible: true},
      {displayName: 'Fornecedor', name: 'fornecedor', visible: false},
      {displayName: 'Estado na Compra', name: 'estadoCompra', visible: true},
      {displayName: 'Última Interação', name: 'interacaoTipo', visible: false},
      {displayName: 'Tamanho', name: 'tamanho', visible: false},
      {displayName: 'Marca', name: 'marca', visible: true},
      {displayName: 'Modelo', name: 'modelo', visible: true},
      {displayName: 'Afeto a', name: 'afetoa', visible: true},
      {displayName: 'Data de Fabrico', name: 'dataFabrico', exportDate: 'DD-MM-YYYY', visible: false},
      {displayName: 'Data de Compra', name: 'dataCompra', exportDate: 'DD-MM-YYYY', visible: true},
      {displayName: 'Fim da Garantia', name: 'dataFimGarantia', exportDate: 'DD-MM-YYYY', visible: false},
      {displayName: 'Data de Validade', name: 'dataValidade', exportDate: 'DD-MM-YYYY', visible: false},
      {displayName: 'Prazo de Validade', name: 'prazoValidade', exportDate: 'DD-MM-YYYY', visible: false},
      {displayName: 'Data de Abate', name: 'dataAbate', exportDate: 'DD-MM-YYYY', visible: false},
      {displayName: 'Periodicidade de Calibração', name: 'periodicidadeCalibracao', visible: false},
      {displayName: 'Periodicidade de Manutenção', name: 'periodicidadeManutencao', visible: false},
      {displayName: 'Periodicidade de Verificação', name: 'periodicidadeVerificacao', visible: false},
      {displayName: 'Última Calibração', name: 'ultimaCalibracao', exportDate: 'DD-MM-YYYY', visible: false},
      {displayName: 'Última Manutenção', name: 'ultimaManutencao', exportDate: 'DD-MM-YYYY', visible: false},
      {displayName: 'Última Verificação', name: 'ultimaVerificacao', exportDate: 'DD-MM-YYYY', visible: false},
      {displayName: 'Próxima Calibração', name: 'proximaCalibracao', exportDate: 'DD-MM-YYYY', visible: false},
      {displayName: 'Próxima Manutenção', name: 'proximaManutencao', exportDate: 'DD-MM-YYYY', visible: false},
      {displayName: 'Próxima Verificação', name: 'proximaVerificacao', exportDate: 'DD-MM-YYYY', visible: false},
      {displayName: 'Fim de Empréstimo', name: 'dataFimEmprestimo', exportDate: 'DD-MM-YYYY HH:mm:ss', visible: false},
      {displayName: 'Nº Série', name: 'numSerie', visible: false},
      {displayName: 'MAC Address', name: 'macAddress', visible: false},
      {displayName: 'IMEI', name: 'imei', visible: false},
      {displayName: 'Firmware', name: 'firmware', visible: false},
      {displayName: 'Licença de Software', name: 'licencaSoftware', visible: false},
      {displayName: 'Especificações Técnicas', name: 'especificacoesTecnicas', visible: false},
      {displayName: 'Acessórios', name: 'acessorios', visible: false},
      {displayName: 'Calibração no Exterior', name: 'calibracaoExterior', visible: false},
      {displayName: 'Observações', name: 'observacoes', visible: false},
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('ATVGestaoAtivosDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('ATVGestaoAtivosDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('ATVGestaoAtivosFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('ATVGestaoAtivosFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('ATVGestaoAtivosFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'AtvAtivo.entidadeProprietariaId')
          f.column.selected.list = this.entidadesProprietarias;
        if (f.column.selected.id === 'AtvAtivo.propriedadeId')
          f.column.selected.list = this.propriedades;
        if (f.column.selected.id === 'AtvAtivo.familiaId')
          f.column.selected.list = this.familias;
        if (f.column.selected.id === 'AtvAtivo.subfamiliaId')
          f.column.selected.list = this.subfamilias;
        if (f.column.selected.id === 'AtvAtivo.localId')
          f.column.selected.list = this.locais;
        // if (f.column.selected.id === 'AtvAtivo.tipoAtivoId')
        //   f.column.selected.list = this.tiposAtivo;
        if (f.column.selected.id === 'AtvAtivo.fornecedorId')
          f.column.selected.list = this.fornecedores;
        // if (f.column.selected.id === 'AtvAtivo.ativoPrefixoId')
        //   f.column.selected.list = this.prefixos;
        if (f.column.selected.id === 'AtvAtivo.estadoCompraId')
          f.column.selected.list = this.estadosCompra;
        if (f.column.selected.id === 'AtvInteracao.interacaoTipoId')
          f.column.selected.list = this.interacoesTipo;
        if (f.column.selected.id === 'AtvAtivo.tamanhoId')
          f.column.selected.list = this.tamanhos;
        if (f.column.selected.id === 'AtvAtivo.marcaId')
          f.column.selected.list = this.marcas;
        if (f.column.selected.id === 'AtvAtivo.afetoaId')
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === 'AtvAtivo.calibracaoExterior')
          f.column.selected.list = this.simnao2;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd' && f.values.selected.val !== 'x' && f.values.selected.val !== 'y') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.newGetAtivos();
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('ATVGestaoAtivosDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  newGetAtivos = () => {
    this.tableLoading = true;
    let whereFields = this.AtvAtivosService.setWhereFields(this.customFilters, {'AtvAtivo.active': 1});
    this.AtvAtivosService.setWherePermFields(whereFields, 'AtvAtivo.entidadeProprietariaId').then(w => {
      whereFields = w;
      this.AtvAtivo.table({
        params: {
          fields: [ // edit exportAtivos() too
            'AtvAtivo.id as id',
            // 'AtvAtivoPrefixo.prefixo as prefixo',
            'AtvAtivo.iidAtivo as iidAtivo',
            'AtvAtivo.iidDisplay as iidDisplay',
            'AtvAtivo.iidOldDisplay as iidOldDisplay',
            'AtvAtivo.iidLegacy as iidLegacy',
            'AtvAtivo.numImobilizado as numImobilizado',
            'AtvAtivo.designacao as designacao',
            'AtvAtivo.referencia as referencia',
            'AtvAtivo.entidadeProprietariaId as entidadeProprietariaId',
            'AtvEntidadeProprietaria.designacao as entidadeProprietaria',
            'AtvPropriedade.designacao as propriedade',
            'AtvLocal.designacao as local',
            // 'AtvTipoAtivo.designacao as tipoAtivo',
            'AtvFornecedor.nome as fornecedor',
            'AtvEstadoCompra.designacao as estadoCompra',
            'AtvInteracaoTipo.designacao as interacaoTipo',
            'AtvTamanho.tamanho as tamanho',
            'AtvMarca.designacao as marca',
            'AtvAtivo.modelo as modelo',
            'Funcionario.name as afetoa',
            'AtvAtivo.dataFabrico as dataFabrico',
            'AtvAtivo.dataCompra as dataCompra',
            'AtvAtivo.dataFimGarantia as dataFimGarantia',
            'AtvAtivo.dataAbate as dataAbate',
            'AtvAtivo.dataValidade as dataValidade',
            'AtvAtivo.prazoValidade as prazoValidade',
            'AtvAtivo.periodicidadeCalibracao as periodicidadeCalibracao',
            'AtvAtivo.periodicidadeManutencao as periodicidadeManutencao',
            'AtvAtivo.periodicidadeVerificacao as periodicidadeVerificacao',
            'AtvAtivo.ultimaCalibracao as ultimaCalibracao',
            'AtvAtivo.ultimaManutencao as ultimaManutencao',
            'AtvAtivo.ultimaVerificacao as ultimaVerificacao',
            'AtvAtivo.numSerie as numSerie',
            'AtvAtivo.macAddress as macAddress',
            'AtvAtivo.imei as imei',
            'AtvAtivo.firmware as firmware',
            'AtvAtivo.licencaSoftware as licencaSoftware',
            'AtvAtivo.especificacoesTecnicas as especificacoesTecnicas',
            'AtvAtivo.acessorios as acessorios',
            'AtvAtivo.calibracaoExterior as calibracaoExterior',
            'AtvAtivo.observacoes as observacoes',
            'AtvFamilia.designacao as familia',
            'AtvSubfamilia.designacao as subfamilia'
          ],
          nextDateFields: [
            {base: 'AtvAtivo.ultimaCalibracao', interval: 'AtvAtivo.periodicidadeCalibracao', as: 'proximaCalibracao'},
            {base: 'AtvAtivo.ultimaManutencao', interval: 'AtvAtivo.periodicidadeManutencao', as: 'proximaManutencao'},
            {base: 'AtvAtivo.ultimaVerificacao', interval: 'AtvAtivo.periodicidadeVerificacao', as: 'proximaVerificacao'},
            {base: 'AtvInteracao.dataInteracao', interval: 'AtvInteracao.duracao', as: 'dataFimEmprestimo'}
          ],
          from: ['AtvAtivo', /*'AtvAtivoPrefixo',*/ 'AtvEntidadeProprietaria', 'AtvPropriedade', 'AtvLocal', /*'AtvTipoAtivo',*/ 'AtvFornecedor', 'AtvEstadoCompra', 'AtvInteracao', 'AtvInteracaoTipo', 'AtvTamanho', 'AtvMarca', 'Funcionario', 'AtvFamilia', 'AtvSubfamilia'],
          references: [undefined, /*'AtvAtivo.ativoPrefixoId',*/ 'AtvAtivo.entidadeProprietariaId', 'AtvAtivo.propriedadeId', 'AtvAtivo.localId', /*'AtvAtivo.tipoAtivoId',*/ 'AtvAtivo.fornecedorId', 'AtvAtivo.estadoCompraId', 'AtvAtivo.interacaoId', 'AtvInteracao.interacaoTipoId', 'AtvAtivo.tamanhoId', 'AtvAtivo.marcaId', 'AtvAtivo.afetoaId', 'AtvAtivo.familiaId', 'AtvAtivo.subfamiliaId'],
          where: whereFields.where,
          whereLiteral: whereFields.whereLiteral,
          whereDates: whereFields.whereDates,
          whereNextDates: whereFields.whereNextDates,
          whereOr: whereFields.whereOr,
          whereOrLiteral: whereFields.whereOrLiteral,
          wherePerms: whereFields.wherePerms,
          order: this.opt.order,
          sort: this.opt.sort,
          limit: this.opt.items,
          skip: (this.opt.page - 1) * this.opt.items,
        }
      }).$promise.then(res => {
        let page = this.opt.page;
        let items = this.opt.items;

        let total = res.count;

        this.start = total > 0 ? (page - 1) * items + 1 : 0;
        if ((this.start - 1 + items) >= total) {
          this.end = total;
        } else {
          this.end = Number.parseInt(this.start - 1 + items);
        }
        res.ativos.forEach(a => {
          switch(a.calibracaoExterior) {
            case 2: a.calibracaoExterior = 'N/A'; break;
            case 1: a.calibracaoExterior = 'Sim'; break;
            case 0: a.calibracaoExterior = 'Não'; break;
            default: a.calibracaoExterior = '';
          }
        });

        this.ativos = res.ativos;
        this.total = total;
        this.tableLoading = false;

      }).catch((e) => {
        console.log(e);
        this.tableLoading = false;
        this.UI.addToast("Não foi possível carregar ativos com os parâmetros fornecidos.");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Não foi possível ler permissões. Por favor tente mais tarde");
    });
  };

  getColumnsToExport = () => {
    let defer = this.$q.defer();
    let options = {
      size: 'md',
      template: require('./columns.export.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Escolha de Colunas a Exportar";
        scope.displayColumns = angular.copy(this.displayColumns);
        scope.allVisible = scope.displayColumns.every(x => x.visible);

        scope.selectAll = () => {
          scope.displayColumns.forEach(x => {x.visible = scope.allVisible;});
        };

        scope.checkPressed = () => {
          scope.allVisible = scope.displayColumns.every(x => x.visible);
        };

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        defer.resolve(res.displayColumns);
      } else {
        defer.reject();
      }
    });
    return defer.promise;
  };

  exportAtivos = () => {
    this.getColumnsToExport().then(columnsToExport => {
      if (!columnsToExport.some(x => x.visible)) {
        this.UI.addToast("Sem colunas a exportar");
        return;
      }
      let wait = this.UI.showWaiting();
      let whereFields = this.AtvAtivosService.setWhereFields(this.customFilters, {'AtvAtivo.active': 1});
      this.AtvAtivosService.setWherePermFields(whereFields, 'AtvAtivo.entidadeProprietariaId').then(w => {
        whereFields = w;
        this.AtvAtivo.exportAtivos({
          displayColumns: columnsToExport,
          params: {
            fields: [ // edit newGetAtivos() too
              'AtvAtivo.id as id',
              // 'AtvAtivoPrefixo.prefixo as prefixo',
              'AtvAtivo.iidAtivo as iidAtivo',
              'AtvAtivo.iidDisplay as iidDisplay',
              'AtvAtivo.iidOldDisplay as iidOldDisplay',
              'AtvAtivo.iidLegacy as iidLegacy',
              'AtvAtivo.numImobilizado as numImobilizado',
              'AtvAtivo.designacao as designacao',
              'AtvAtivo.referencia as referencia',
              'AtvAtivo.entidadeProprietariaId as entidadeProprietariaId',
              'AtvEntidadeProprietaria.designacao as entidadeProprietaria',
              'AtvPropriedade.designacao as propriedade',
              'AtvLocal.designacao as local',
              // 'AtvTipoAtivo.designacao as tipoAtivo',
              'AtvFornecedor.nome as fornecedor',
              'AtvEstadoCompra.designacao as estadoCompra',
              'AtvInteracaoTipo.designacao as interacaoTipo',
              'AtvTamanho.tamanho as tamanho',
              'AtvMarca.designacao as marca',
              'AtvAtivo.modelo as modelo',
              'Funcionario.name as afetoa',
              'AtvAtivo.dataFabrico as dataFabrico',
              'AtvAtivo.dataCompra as dataCompra',
              'AtvAtivo.dataFimGarantia as dataFimGarantia',
              'AtvAtivo.dataAbate as dataAbate',
              'AtvAtivo.dataValidade as dataValidade',
              'AtvAtivo.prazoValidade as prazoValidade',
              'AtvAtivo.periodicidadeCalibracao as periodicidadeCalibracao',
              'AtvAtivo.periodicidadeManutencao as periodicidadeManutencao',
              'AtvAtivo.periodicidadeVerificacao as periodicidadeVerificacao',
              'AtvAtivo.ultimaCalibracao as ultimaCalibracao',
              'AtvAtivo.ultimaManutencao as ultimaManutencao',
              'AtvAtivo.ultimaVerificacao as ultimaVerificacao',
              'AtvAtivo.numSerie as numSerie',
              'AtvAtivo.macAddress as macAddress',
              'AtvAtivo.imei as imei',
              'AtvAtivo.firmware as firmware',
              'AtvAtivo.licencaSoftware as licencaSoftware',
              'AtvAtivo.especificacoesTecnicas as especificacoesTecnicas',
              'AtvAtivo.acessorios as acessorios',
              'AtvAtivo.calibracaoExterior as calibracaoExterior',
              'AtvAtivo.observacoes as observacoes',
              'AtvFamilia.designacao as familia',
              'AtvSubfamilia.designacao as subfamilia'
            ],
            nextDateFields: [
              {base: 'AtvAtivo.ultimaCalibracao', interval: 'AtvAtivo.periodicidadeCalibracao', as: 'proximaCalibracao'},
              {base: 'AtvAtivo.ultimaManutencao', interval: 'AtvAtivo.periodicidadeManutencao', as: 'proximaManutencao'},
              {base: 'AtvAtivo.ultimaVerificacao', interval: 'AtvAtivo.periodicidadeVerificacao', as: 'proximaVerificacao'},
              {base: 'AtvInteracao.dataInteracao', interval: 'AtvInteracao.duracao', as: 'dataFimEmprestimo'}
            ],
            from: ['AtvAtivo', /*'AtvAtivoPrefixo',*/ 'AtvEntidadeProprietaria', 'AtvPropriedade', 'AtvLocal', /*'AtvTipoAtivo',*/ 'AtvFornecedor', 'AtvEstadoCompra', 'AtvInteracao', 'AtvInteracaoTipo', 'AtvTamanho', 'AtvMarca', 'Funcionario', 'AtvFamilia', 'AtvSubfamilia'],
            references: [undefined, /*'AtvAtivo.ativoPrefixoId',*/ 'AtvAtivo.entidadeProprietariaId', 'AtvAtivo.propriedadeId', 'AtvAtivo.localId', /*'AtvAtivo.tipoAtivoId',*/ 'AtvAtivo.fornecedorId', 'AtvAtivo.estadoCompraId', 'AtvAtivo.interacaoId', 'AtvInteracao.interacaoTipoId', 'AtvAtivo.tamanhoId', 'AtvAtivo.marcaId', 'AtvAtivo.afetoaId', 'AtvAtivo.familiaId', 'AtvAtivo.subfamiliaId'],
            where: whereFields.where,
            whereLiteral: whereFields.whereLiteral,
            whereDates: whereFields.whereDates,
            whereNextDates: whereFields.whereNextDates,
            whereOr: whereFields.whereOr,
            whereOrLiteral: whereFields.whereOrLiteral,
            wherePerms: whereFields.wherePerms,
            order: this.opt.order,
            sort: this.opt.sort
            // limit: this.opt.items,
            // skip: (this.opt.page - 1) * this.opt.items,
          }
        }).$promise.then(res => {
          if (res.count) {
            wait.close();
            let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
            let b = document.createElement('a');
            b.href = url;
            b.download = 'ativosExportados.xlsx';
            b.click();
          } else {
            this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
          }
        }).catch((e) => {
          wait.close();
          console.log(e);
          this.UI.addToast("Não foi possível exportar ativos. Por favor tente mais tarde.");
        });
      }).catch(error => {
        console.log(error);
        this.UI.addToast("Não foi possível ler permissões. Por favor tente mais tarde");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Não foi possível obter colunas a exportar");
    });
  };

  exportSelected = () => {
    this.getColumnsToExport().then(columnsToExport => {
      if (!columnsToExport.some(x => x.visible)) {
        this.UI.addToast("Sem colunas a exportar");
        return;
      }
      let wait = this.UI.showWaiting();
      let orsLiteral = [];
      // if a few ativos selected only
      if (this.hasSelect() && !this.everythingSelected)
        orsLiteral = _.map(_.filter(this.ativos, x => x.selected), x => {return {key: 'AtvAtivo.id', value: x.id};});
      let whereFields = this.AtvAtivosService.setWhereFields(this.customFilters, {'AtvAtivo.active': 1}, orsLiteral);
      this.AtvAtivosService.setWherePermFields(whereFields, 'AtvAtivo.entidadeProprietariaId').then(w => {
        whereFields = w;
        this.AtvAtivo.exportAtivos({
          displayColumns: columnsToExport,
          params: {
            fields: [ // edit newGetAtivos() too
              'AtvAtivo.id as id',
              // 'AtvAtivoPrefixo.prefixo as prefixo',
              'AtvAtivo.iidAtivo as iidAtivo',
              'AtvAtivo.iidDisplay as iidDisplay',
              'AtvAtivo.iidOldDisplay as iidOldDisplay',
              'AtvAtivo.iidLegacy as iidLegacy',
              'AtvAtivo.numImobilizado as numImobilizado',
              'AtvAtivo.designacao as designacao',
              'AtvAtivo.referencia as referencia',
              'AtvAtivo.entidadeProprietariaId as entidadeProprietariaId',
              'AtvEntidadeProprietaria.designacao as entidadeProprietaria',
              'AtvPropriedade.designacao as propriedade',
              'AtvLocal.designacao as local',
              // 'AtvTipoAtivo.designacao as tipoAtivo',
              'AtvFornecedor.nome as fornecedor',
              'AtvEstadoCompra.designacao as estadoCompra',
              'AtvInteracaoTipo.designacao as interacaoTipo',
              'AtvTamanho.tamanho as tamanho',
              'AtvMarca.designacao as marca',
              'AtvAtivo.modelo as modelo',
              'Funcionario.name as afetoa',
              'AtvAtivo.dataFabrico as dataFabrico',
              'AtvAtivo.dataCompra as dataCompra',
              'AtvAtivo.dataFimGarantia as dataFimGarantia',
              'AtvAtivo.dataAbate as dataAbate',
              'AtvAtivo.dataValidade as dataValidade',
              'AtvAtivo.prazoValidade as prazoValidade',
              'AtvAtivo.periodicidadeCalibracao as periodicidadeCalibracao',
              'AtvAtivo.periodicidadeManutencao as periodicidadeManutencao',
              'AtvAtivo.periodicidadeVerificacao as periodicidadeVerificacao',
              'AtvAtivo.ultimaCalibracao as ultimaCalibracao',
              'AtvAtivo.ultimaManutencao as ultimaManutencao',
              'AtvAtivo.ultimaVerificacao as ultimaVerificacao',
              'AtvAtivo.numSerie as numSerie',
              'AtvAtivo.macAddress as macAddress',
              'AtvAtivo.imei as imei',
              'AtvAtivo.firmware as firmware',
              'AtvAtivo.licencaSoftware as licencaSoftware',
              'AtvAtivo.especificacoesTecnicas as especificacoesTecnicas',
              'AtvAtivo.acessorios as acessorios',
              'AtvAtivo.calibracaoExterior as calibracaoExterior',
              'AtvAtivo.observacoes as observacoes',
              'AtvFamilia.designacao as familia',
              'AtvSubfamilia.designacao as subfamilia'
            ],
            nextDateFields: [
              {base: 'AtvAtivo.ultimaCalibracao', interval: 'AtvAtivo.periodicidadeCalibracao', as: 'proximaCalibracao'},
              {base: 'AtvAtivo.ultimaManutencao', interval: 'AtvAtivo.periodicidadeManutencao', as: 'proximaManutencao'},
              {base: 'AtvAtivo.ultimaVerificacao', interval: 'AtvAtivo.periodicidadeVerificacao', as: 'proximaVerificacao'},
              {base: 'AtvInteracao.dataInteracao', interval: 'AtvInteracao.duracao', as: 'dataFimEmprestimo'}
            ],
            from: ['AtvAtivo', /*'AtvAtivoPrefixo',*/ 'AtvEntidadeProprietaria', 'AtvPropriedade', 'AtvLocal', /*'AtvTipoAtivo',*/ 'AtvFornecedor', 'AtvEstadoCompra', 'AtvInteracao', 'AtvInteracaoTipo', 'AtvTamanho', 'AtvMarca', 'Funcionario', 'AtvFamilia', 'AtvSubfamilia'],
            references: [undefined, /*'AtvAtivo.ativoPrefixoId',*/ 'AtvAtivo.entidadeProprietariaId', 'AtvAtivo.propriedadeId', 'AtvAtivo.localId', /*'AtvAtivo.tipoAtivoId',*/ 'AtvAtivo.fornecedorId', 'AtvAtivo.estadoCompraId', 'AtvAtivo.interacaoId', 'AtvInteracao.interacaoTipoId', 'AtvAtivo.tamanhoId', 'AtvAtivo.marcaId', 'AtvAtivo.afetoaId', 'AtvAtivo.familiaId', 'AtvAtivo.subfamiliaId'],
            where: whereFields.where,
            whereLiteral: whereFields.whereLiteral,
            whereDates: whereFields.whereDates,
            whereNextDates: whereFields.whereNextDates,
            whereOr: whereFields.whereOr,
            whereOrLiteral: whereFields.whereOrLiteral,
            wherePerms: whereFields.wherePerms,
            order: this.opt.order,
            sort: this.opt.sort
            // limit: this.opt.items,
            // skip: (this.opt.page - 1) * this.opt.items,
          }
        }).$promise.then(res => {
          if (res.count) {
            wait.close();
            let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
            let b = document.createElement('a');
            b.href = url;
            b.download = 'ativosExportados.xlsx';
            b.click();
          } else {
            this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
          }
        }).catch((e) => {
          wait.close();
          console.log(e);
          this.UI.addToast("Não foi possível exportar ativos. Por favor tente mais tarde.");
        });
      }).catch(error => {
        console.log(error);
        this.UI.addToast("Não foi possível ler permissões. Por favor tente mais tarde");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Não foi possível obter colunas a exportar");
    });
  };

  loadData = () => {
    this.Funcionario.find({filter: {where: {active: true}, order: 'name ASC'}}).$promise.then(funcionarios => {
      // funcionarios.forEach(t => {
      //   t.name = t.designacao;
      // });
      this.funcionarios = funcionarios;
      this.AtvMarca.find({filter: {where: {active: true}, order: 'designacao ASC'}}).$promise.then(marcas => {
        marcas.forEach(t => {
          t.name = t.designacao;
        });
        this.marcas = marcas;
        this.AtvTamanho.find({
          filter: {
            where: {active: true},
            order: 'tamanhoTipoId ASC, ordem ASC'
          }
        }).$promise.then(tamanhos => {
          tamanhos.forEach(t => {
            t.name = t.tamanho;
          });
          this.tamanhos = tamanhos;
          this.AtvEstadoCompra.find({
            filter: {
              where: {active: true},
              order: 'ordem ASC'
            }
          }).$promise.then(estadosCompra => {
            estadosCompra.forEach(t => {
              t.name = t.designacao;
            });
            this.estadosCompra = estadosCompra;
            this.AtvInteracaoTipo.find({
              filter: {
                where: {active: true},
                order: 'ordem ASC'
              }
            }).$promise.then(interacoesTipo => {
              interacoesTipo.forEach(t => {
                t.name = t.designacao;
              });
              this.interacoesTipo = interacoesTipo;
              this.AtvFornecedor.find({
                filter: {
                  where: {active: true},
                  order: 'nome ASC'
                }
              }).$promise.then(fornecedores => {
                fornecedores.forEach(t => {
                  t.name = t.nome;
                });
                this.fornecedores = fornecedores;
                // this.AtvTipoAtivo.find({
                //   filter: {
                //     where: {active: true},
                //     order: 'designacao ASC'
                //   }
                // }).$promise.then(tipos => {
                //   tipos.forEach(t => {
                //     t.name = t.designacao;
                //   });
                //   this.tiposAtivo = tipos;
                  this.AtvAtivosService.getEntidadesProprietariasPermitidas().then((entidades) => {
                    entidades.forEach(e => {
                      e.name = e.designacao;
                    });
                    this.entidadesProprietarias = entidades;
                    this.AtvPropriedade.find({
                      filter: {
                        where: {active: true},
                        order: 'entidadeProprietariaId ASC, ordem ASC'
                      }
                    }).$promise.then((propriedades) => {
                      propriedades.forEach(e => {
                        e.name = e.designacao;
                      });
                      this.propriedades = propriedades;
                      this.AtvLocal.find({
                        filter: {
                          where: {active: true},
                          order: 'entidadeProprietariaId ASC, designacao ASC'
                        }
                      }).$promise.then((locais) => {
                        locais.forEach(e => {
                          e.name = e.designacao;
                        });
                        this.locais = locais;
                        // this.AtvAtivoPrefixo.find({
                        //   filter: {
                        //     where: {active: true},
                        //     order: 'ordem ASC'
                        //   }
                        // }).$promise.then((prefixos) => {
                        //   prefixos.forEach(e => {
                        //     e.name = e.prefixo;
                        //   });
                        //   this.prefixos = prefixos;
                          this.AtvFamilia.find({
                            filter: {
                              where: {active: true},
                              order: 'ordem ASC'
                            }
                          }).$promise.then((familias) => {
                            familias.forEach(e => {
                              e.name = e.designacao;
                            });
                            this.familias = familias;
                            this.AtvSubfamilia.find({
                              filter: {
                                where: {active: true},
                                order: 'familiaId ASC, ordem ASC'
                              }
                            }).$promise.then((subfamilias) => {
                              subfamilias.forEach(e => {
                                e.name = e.designacao;
                              });
                              this.subfamilias = subfamilias;

                              this.columns.forEach(f => {
                                if (f.id === 'AtvAtivo.entidadeProprietariaId')
                                  f.list = this.entidadesProprietarias;
                                if (f.id === 'AtvAtivo.propriedadeId')
                                  f.list = this.propriedades;
                                if (f.id === 'AtvAtivo.familiaId')
                                  f.list = this.familias;
                                if (f.id === 'AtvAtivo.subfamiliaId')
                                  f.list = this.subfamilias;
                                if (f.id === 'AtvAtivo.localId')
                                  f.list = this.locais;
                                // if (f.id === 'AtvAtivo.tipoAtivoId')
                                //   f.list = this.tiposAtivo;
                                if (f.id === 'AtvAtivo.fornecedorId')
                                  f.list = this.fornecedores;
                                // if (f.id === 'AtvAtivo.ativoPrefixoId')
                                //   f.list = this.prefixos;
                                if (f.id === 'AtvAtivo.estadoCompraId')
                                  f.list = this.estadosCompra;
                                if (f.id === 'AtvInteracao.interacaoTipoId')
                                  f.list = this.interacoesTipo;
                                if (f.id === 'AtvAtivo.tamanhoId')
                                  f.list = this.tamanhos;
                                if (f.id === 'AtvAtivo.marcaId')
                                  f.list = this.marcas;
                                if (f.id === 'AtvAtivo.afetoaId')
                                  f.list = this.funcionarios;
                                if (f.id === 'AtvAtivo.calibracaoExterior')
                                  f.list = this.simnao2;
                              });
                              // Everything is loaded
                              this.dataLoaded = true;
                            }).catch(e => {
                              console.log(e);
                              this.errorLoadingData = true;
                              this.UI.addToast("Erro de carregamento de dados para filtragem (Subfamílias). Recarregue a página.");
                            });
                          }).catch(e => {
                            console.log(e);
                            this.errorLoadingData = true;
                            this.UI.addToast("Erro de carregamento de dados para filtragem (Famílias). Recarregue a página.");
                          });
                        // }).catch(e => {
                        //   console.log(e);
                        //   this.errorLoadingData = true;
                        //   this.UI.addToast("Erro de carregamento de dados para filtragem (Prefixos). Recarregue a página.");
                        // });
                      }).catch(e => {
                        console.log(e);
                        this.errorLoadingData = true;
                        this.UI.addToast("Erro de carregamento de dados para filtragem (Locais). Recarregue a página.");
                      });
                    }).catch(e => {
                      console.log(e);
                      this.errorLoadingData = true;
                      this.UI.addToast("Erro de carregamento de dados para filtragem (Propriedades). Recarregue a página.");
                    });
                  }).catch(e => {
                    console.log(e);
                    this.errorLoadingData = true;
                    this.UI.addToast("Erro de carregamento de dados para filtragem (Entidades Proprietárias). Recarregue a página.");
                  });
                // }).catch(e => {
                //   console.log(e);
                //   this.errorLoadingData = true;
                //   this.UI.addToast("Erro de carregamento de dados para filtragem (Tipos). Recarregue a página.");
                // });
              }).catch(e => {
                console.log(e);
                this.errorLoadingData = true;
                this.UI.addToast("Erro de carregamento de dados para filtragem (Fornecedores). Recarregue a página.");
              });
            }).catch(e => {
              console.log(e);
              this.errorLoadingData = true;
              this.UI.addToast("Erro de carregamento de dados para filtragem (Tipos de Interação). Recarregue a página.");
            });
          }).catch(e => {
            console.log(e);
            this.errorLoadingData = true;
            this.UI.addToast("Erro de carregamento de dados para filtragem (Estados de Compra). Recarregue a página.");
          });
        }).catch(e => {
          console.log(e);
          this.errorLoadingData = true;
          this.UI.addToast("Erro de carregamento de dados para filtragem (Tamanhos). Recarregue a página.");
        });
      }).catch(e => {
        console.log(e);
        this.errorLoadingData = true;
        this.UI.addToast("Erro de carregamento de dados para filtragem (Marcas). Recarregue a página.");
      });
    }).catch(e => {
      console.log(e);
      this.errorLoadingData = true;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Colaboradores). Recarregue a página.");
    });
  };

  openFilter = () => {
    // Check if we can open
    if (this.errorLoadingData) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    if (!this.dataLoaded) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'AtvAtivo.entidadeProprietariaId')
          f.column.selected.list = this.entidadesProprietarias;
        if (f.column.selected.id === 'AtvAtivo.propriedadeId')
          f.column.selected.list = this.propriedades;
        if (f.column.selected.id === 'AtvAtivo.familiaId')
          f.column.selected.list = this.familias;
        if (f.column.selected.id === 'AtvAtivo.subfamiliaId')
          f.column.selected.list = this.subfamilias;
        if (f.column.selected.id === 'AtvAtivo.localId')
          f.column.selected.list = this.locais;
        // if (f.column.selected.id === 'AtvAtivo.tipoAtivoId')
        //   f.column.selected.list = this.tiposAtivo;
        if (f.column.selected.id === 'AtvAtivo.fornecedorId')
          f.column.selected.list = this.fornecedores;
        // if (f.column.selected.id === 'AtvAtivo.ativoPrefixoId')
        //   f.column.selected.list = this.prefixos;
        if (f.column.selected.id === 'AtvAtivo.estadoCompraId')
          f.column.selected.list = this.estadosCompra;
        if (f.column.selected.id === 'AtvInteracao.interacaoTipoId')
          f.column.selected.list = this.interacoesTipo;
        if (f.column.selected.id === 'AtvAtivo.tamanhoId')
          f.column.selected.list = this.tamanhos;
        if (f.column.selected.id === 'AtvAtivo.marcaId')
          f.column.selected.list = this.marcas;
        if (f.column.selected.id === 'AtvAtivo.afetoaId')
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === 'AtvAtivo.calibracaoExterior')
          f.column.selected.list = this.simnao2;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  clearFilter = () => {
    localStorage.removeItem('ATVGestaoAtivosFilter');
    this.customFilters = [];
    this.newGetAtivos();
  };

  applyFilter = () => {
    // Parse values from sidebar
    // Filter custom filters: If column exists and (it's a date filter, that has the dropdown selected) or (it has a value set)
    // Verification for legitimate date filters without value filled in is done later
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && ((f.column.selected && f.column.selected.type === 'd' && f.values && f.values.selected) || !_.isEmpty(f.value)));
    this.customFilters = [];
    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Special case when it's null checking
      if (f.column.selected.type === 'd' && (f.values.selected.val === 'x' || f.values.selected.val === 'y')) {
        this.customFilters.push(f);
      } else if (f.value) { // Only proceed if we have a value (wasn't checking in the first filter, it is now)
        // Fix date filters to be moment()
        if (f.column.selected.type === 'd') {
          f.value = moment(f.value).utc();
          f.value.second(0); // We don't care about seconds
        }
        this.customFilters.push(f);
      }
    });

    localStorage.setItem('ATVGestaoAtivosFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.ativos.ativos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.newGetAtivos();
  };

  hasSelect = () => {
    return _.some(this.ativos, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.ativos.forEach(a => {a.selected = true;});
      this.nSelected = this.ativos.length;
    } else { // Remove all selections
      this.ativos.forEach(a => {a.selected = false;});
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.ativos.forEach(a => {a.selected = false;});
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.ativos, r => r.selected).length;
    this.allSelected = this.nSelected >= this.ativos.length;
    this.everythingSelected = false;
  };

  sort = keyname => {
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.ativos.ativos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.newGetAtivos();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.ativos.ativos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.newGetAtivos();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.ativos.ativos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.newGetAtivos();
  };

  viewAtivo = r => {
    this.$state.go('app.ativos.ativos.details', {id: r.id});
  };

  checkCloneAtivo = r => {
    // Pre-check if ativo has numImobilizado. Don't bother go through if it has.
    this.AtvAtivo.findOne({
      filter: {
        where: {
          id: r.id,
          active: 1
        }
      }
    }).$promise.then((atv) => {
      if (atv.numImobilizado)
        this.UI.addToast("Não é possível clonar ativos com Nº de Imobilizado definido");
      else
        this.cloneAtivo(r);
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar ativo. Por favor recarregue a página");
    });
  };

  cloneAtivo = r => {
    let cenas = this.UI.showWaiting();
    this.AtvLocal.find({
      filter: {
        where: {
          entidadeProprietariaId: r.entidadeProprietariaId,
          active: 1
        }
      }
    }).$promise.then((locais) => {
      cenas.close();
      let options = this.AtvAtivosService.cloneAtivoOptions(locais);
      let modal = this.UI.showDialog(options);
      modal.then(res => {
        if (res) {
          this.tableLoading = true;
          this.AtvAtivosService.afterModalCloneAtivo(r, res).then(clonagens => {
            this.newGetAtivos();
          }).catch(error => {
            console.log(error);
          });
        }
      });
    }).catch(error => {
      this.UI.addToast("Não foi possível obter locais para ativos. Por favor tente mais tarde");
      console.log(error);
    });
  };

  addInteracao = (x) => {
    if (x.dataAbate) {
      this.UI.addToast("Ativo encontra-se abatido, logo não sujeito a interações");
      return;
    }
    this.AtvAtivo.findOne({
      filter: {
        where: {
          id: x.id,
          active: 1
        },
        include: [{
          relation: 'entidadeProprietaria',
          scope: {
            where: {
              active: 1
            }
          }
        }, {
          relation: 'ultimaInteracao',
          scope: {
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then((ativo) => {
      this.AtvInteracao.getUsableInteracoesTipo({
        ativoId: ativo.id
      }).$promise.then((interacoesTipo) => {
        let options = this.AtvAtivosService.addInteracaoModalOptions(ativo, interacoesTipo);
        let modal = this.UI.showDialog(options);
        modal.then((res) => {
          this.tableLoading = true;
          if (res) {
            this.AtvAtivosService.afterAddInteracao(res, ativo.id).then(i => {
              this.newGetAtivos();
            }).catch(err => {
              console.log(err);
              this.tableLoading = false;
            });
          }
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível obter tipos de interação. Por favor tente mais tarde");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar o ativo");
    });
  };

  // changeTipoAtivo = () => {
  //   let wait = this.UI.showWaiting();
  //   this.AtvTipoAtivo.find({
  //     filter: {
  //       where: {
  //         active: 1
  //       },
  //       order: 'designacao ASC'
  //     }
  //   }).$promise.then((tiposAtivo) => {
  //     wait.close();
  //     let options = {
  //       size: 'lg',
  //       template: require('./tipoativo.edit.dialog.html'),
  //       controller: ['$scope', ($scope) => {
  //         $scope.title = "Alterar Tipo de Ativo para " + this.nSelected + " Ativo";
  //         $scope.title += this.nSelected === 1 ? "" : "s";
  //         $scope.tiposAtivo = tiposAtivo;
  //         $scope.auxTipoAtivo = {
  //           selected: null,
  //           infiniteScroll: {numToAdd: 20, currentItems: 20}
  //         };
  //
  //         // Infinite Scroll magic
  //         $scope.addMoreItems = (infiniteScroll) => {
  //           infiniteScroll.currentItems += infiniteScroll.numToAdd;
  //         };
  //
  //         $scope.ok = () => {
  //           $scope.$close($scope);
  //         };
  //
  //         $scope.cancel = () => $scope.$dismiss('cancel');
  //       }]
  //     };
  //     this.UI.showDialog(options).then(res => {
  //       if (res) {
  //         this.tableLoading = true;
  //         // Deal with Tipo de Ativo
  //         if (res.auxTipoAtivo.selected) {
  //           let selectedAtivos = _.filter(this.ativos, x => x.selected);
  //           let tasks = [];
  //           for (let i = 0; i < selectedAtivos.length; i++) {
  //             let defer = this.$q.defer();
  //             this.AtvAtivo.findOne({
  //               filter: {
  //                 where: {
  //                   id: selectedAtivos[i].id,
  //                   active: 1
  //                 },
  //                 include: {
  //                   relation: 'tipoAtivo',
  //                   scope: {
  //                     where: {
  //                       active: 1
  //                     }
  //                   }
  //                 }
  //               }
  //             }).$promise.then((atv) => {
  //               let oldTipoAtivoDesignacao = atv.tipoAtivo.designacao || '-';
  //               atv.tipoAtivo = res.auxTipoAtivo.selected;
  //               atv.tipoAtivoId = res.auxTipoAtivo.selected.id;
  //               atv.$save().then((savedAtivo) => {
  //                 this.AtvAtivoAlteracaoEstado.create({
  //                   id: 0,
  //                   ativoEstadoId: 3, // Alteração de dados
  //                   dataAlteracao: moment().isDST() ? moment().add(1, 'h') : moment(),
  //                   ativoId: savedAtivo.id,
  //                   funcionarioId: this.Auth.getId(),
  //                   alteracoes: "Alteração de Tipo de Ativo: (" + oldTipoAtivoDesignacao + " para " + res.auxTipoAtivo.selected.designacao + ")",
  //                   active: 1
  //                 }).$promise.then((alteracaoEstado) => {
  //                   defer.resolve(savedAtivo.id);
  //                 }).catch((error) => {
  //                   console.log(error);
  //                   defer.reject(savedAtivo.id);
  //                 });
  //               }, (error) => {
  //                 console.log(error);
  //                 defer.reject(atv.id);
  //               });
  //             }).catch((error) => {
  //               console.log(error);
  //               defer.reject(selectedAtivos[i].id);
  //             });
  //             tasks.push(defer.promise);
  //           }
  //           this.$q.all(tasks).then((res) => {
  //             this.UI.addToast("Alterações realizadas com sucesso!");
  //             this.newGetAtivos();
  //           }).catch(err => {
  //             console.log(err);
  //             this.UI.addToast("Erro na alteração de tipos de dados. Por favor tente mais tarde");
  //           });
  //         }
  //       }
  //     });
  //   }).catch((error) => {
  //     console.log(error);
  //     this.UI.addToast("Não foi possível obter tipos de ativo. Por favor tente mais tarde.");
  //     wait.close();
  //   });
  // };
}

AtvAtivosListController.$inject = ['$filter', '$q', '$state', 'AuthenticationService', 'Group', 'AtvAtivo', 'AtvAtivosService', 'AtvInteracao', 'AtvEntidadeProprietaria', 'AtvPropriedade', 'AtvLocal', 'AtvEstadoCompra', 'AtvFamilia', 'AtvSubfamilia', 'AtvTipoAtivo', 'AtvTamanho', 'AtvMarca', 'AtvFornecedor', 'AtvAtivoPrefixo', 'AtvInteracaoTipo', 'AtvAtivoAlteracaoEstado', 'Funcionario', 'UIService'];
