export default class RhService {
    $rootScope;
    $q;
    RhAvaliacao;
    RhAvaliacaoEstado;
    RhAvaliacaoCiclo;
    Funcionario;
    RhAvaliacaoQualitativa;
    RhAlteracaoestadoAvaliacao;
    user;
    constructor($rootScope, $q, AuthenticationService, RhAvaliacao, RhAvaliacaoEstado, RhAvaliacaoCiclo, Funcionario, RhAvaliacaoQualitativa, RhAlteracaoestadoAvaliacao) {
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.RhAvaliacao = RhAvaliacao;
        this.RhAvaliacaoEstado = RhAvaliacaoEstado;
        this.RhAvaliacaoCiclo = RhAvaliacaoCiclo;
        this.Funcionario = Funcionario;
        this.RhAvaliacaoQualitativa = RhAvaliacaoQualitativa;
        this.RhAlteracaoestadoAvaliacao = RhAlteracaoestadoAvaliacao;
        this.user = AuthenticationService.getUser();
    }
    getUserId() {
        return this.user.id;
    }
    findAll(query) {
        return this.RhAvaliacao.table(query).$promise;
    }
    options() {
        return this.RhAvaliacao.options().$promise;
    }
    exportProcessos(query) {
        return this.RhAvaliacao.export(query).$promise;
    }
    generateUUID = () => {
        let uuid = '', i, random;
        for (i = 0; i < 32; i++) {
            random = (Math.random() * 16) | 0;
            if (i == 8 || i == 12 || i == 16 || i == 20) {
                uuid += '-';
            }
            uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
        }
        return uuid;
    };
    getNomeFuncionarioById = () => {
        let defer = this.$q.defer();
        this.Funcionario.findOne({ filter: { where: { id: this.getUserId() }, fields: { id: true, name: true } } })
            .$promise.then(data => {
            defer.resolve({ id: data?.id, nome: data?.name }); // Retorna apenas o nome ou uma string vazia se não encontrar
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getFuncionarioById = (idFunc) => {
        let defer = this.$q.defer();
        this.Funcionario.findOne({ filter: { where: { id: idFunc } } })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getEstados = () => {
        return this.RhAvaliacaoEstado.find({
            filter: {
                where: {
                    active: true
                }
            }
        }).$promise;
    };
    getCicloById = (idCiclo) => {
        return this.RhAvaliacaoCiclo.findOne({
            filter: {
                where: {
                    and: [{ id: idCiclo }, { active: 1 }]
                }
            }
        }).$promise;
    };
    getClassificacoes = () => {
        return this.RhAvaliacaoQualitativa.find({
            filter: {
                where: {
                    active: true
                }
            }
        }).$promise;
    };
    getFuncionarios = async (search, page, limit) => {
        let defer = this.$q.defer();
        let idUser = this.getUserId();
        try {
            // 1. find funcionarios pelo código
            const tecnicosPorCodigo = await this.Funcionario.find({
                filter: {
                    where: { and: [{ numeroMecanografico: { like: `%${search}%` } }, { id: { neq: idUser } }, { active: 1 }] },
                    skip: page * limit,
                    limit: limit
                }
            }).$promise;
            // 2. find funcionários pelo nome
            const funcionarios = await this.Funcionario.find({
                filter: {
                    where: { and: [{ name: { like: `%${search}%` } }, { id: { neq: idUser } }, { active: 1 }] } // Filtrar pelo nome do funcionário
                }
            }).$promise;
            // 5. Concatenar os resultados das duas pesquisas, evitando duplicações
            const resultMap = new Map();
            [...tecnicosPorCodigo, ...funcionarios].forEach((funcionario) => {
                resultMap.set(funcionario.id, {
                    id: funcionario.id,
                    name: funcionario?.name || '',
                    numeroMecanografico: funcionario.numeroMecanografico
                });
            });
            // 6. Converter o Map num array e limitar os resultados
            const resultArray = Array.from(resultMap.values());
            const paginatedResult = resultArray.slice(page * limit, (page + 1) * limit);
            defer.resolve(paginatedResult);
        }
        catch (error) {
            defer.reject(error);
        }
        return defer.promise;
    };
    getAvaliacaoById = (id) => {
        let defer = this.$q.defer();
        this.RhAvaliacao.getAvaliacao({ params: { id: id } })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getCiclos = () => {
        return this.RhAvaliacaoCiclo.find({
            filter: {
                where: {
                    active: true
                }
            }
        }).$promise;
    };
    patchAvaliacao(id, observacoesAvaliado, estadoId) {
        return this.RhAvaliacao.patch({ id, observacoesAvaliado, estadoId }).$promise;
    }
    saveAvaliacao = async (values, alteracao) => {
        let defer = this.$q.defer();
        this.RhAvaliacao.saveAvaliacao({ params: { values, alteracao } })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getAlteracoesById = (idAlteracao) => {
        return this.RhAlteracaoestadoAvaliacao.find({
            filter: {
                where: {
                    and: [{ avaliacaoId: idAlteracao }, { active: 1 }]
                },
                include: 'Funcionario'
            }
        }).$promise;
    };
}
RhService.$inject = ['$rootScope', '$q', 'AuthenticationService', 'RhAvaliacao', 'RhAvaliacaoEstado', 'RhAvaliacaoCiclo', 'Funcionario', 'RhAvaliacaoQualitativa', 'RhAlteracaoestadoAvaliacao'];
