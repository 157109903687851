export default class LmeAmostraService {
  constructor($q, UIService, AuthenticationService, LmeAmostra, LmeEstadoAmostra, LmeAmostraAlteracaoEstado, LmeAmostraAlteracaoLocal, LmeProcessoAmostra, LmeLocal) {
    this.$q = $q;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.LmeAmostra = LmeAmostra;
    this.LmeEstadoAmostra = LmeEstadoAmostra;
    this.LmeAmostraAlteracaoEstado = LmeAmostraAlteracaoEstado;
    this.LmeAmostraAlteracaoLocal = LmeAmostraAlteracaoLocal;
    this.LmeProcessoAmostra = LmeProcessoAmostra;
    this.LmeLocal = LmeLocal;
  }

  // Change local for amostras with id in array ids = [{id: ..., localId: ..., localInside: ...}, ...]
  // localOriginal is an object with (at least) {id: ..., inside: ... }
  // localOriginal is an object with (at least) {id: .., inside: ... }
  changeLocalMultiple = (ids, localOriginal, estadoOriginal) => {
    let defer = this.$q.defer();
    let wait = this.UI.showWaiting();
    // Open modal to pick state and obs
    this.LmeLocal.find({
      filter: {
        where: {
          and: [{active: true}, {id: {neq: localOriginal.id}}]
        }
      }
    }).$promise.then(locais => {
      this.LmeEstadoAmostra.find({
        filter: {
          where: {
            and: [{active: true}, {id: {neq: estadoOriginal.id}}]
          }
        }
      }).$promise.then((estados) => {
        wait.close();
        let options = {
          size: "lg",
          template: require("./directory/movement.dialog.html"),
          controller: ["$scope", "$dialog", ($scope, $dialog) => {
            $scope.locais = locais;
            $scope.estadosSaida = estados.filter(x => !x.inside);
            $scope.estadosEntrada = estados.filter(x => x.inside);

            // Used just for estado of amostra
            $scope.amostra = {
              estado: estadoOriginal
            };

            $scope.isInside = (estado) => {
              // Returns true if amostra is located inside, false otherwise
              return estado && estado.inside;
            };

            $scope.local = {
              novoEstado: {
                selected: undefined,
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              },
              novoLocal: {
                selected: undefined,
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              },
              observacoes: null
            };

            // Infinite Scroll magic
            $scope.addMoreItems = (infiniteScroll) => {
              infiniteScroll.currentItems += infiniteScroll.numToAdd;
            };

            $scope.ok = () => {
              $dialog.close($scope);
            };

            $scope.cancel = () => {
              $dialog.dismiss("cancel");
            };
          }]
        };

        let modal = this.UI.showDialog(options);

        modal.then((ok) => {
          let processCountString = ids.length > 1 ? `destas ${ids.length} amostras` : `desta amostra`;
          let warning = `Tem a certeza que pretende alterar o local ${processCountString} para: ${ok.local.novoLocal.selected.designacao}?`;

          let result = this.UI.showConfirm(warning);
          result.then(() => {
            // Recheck if all pedidos have the same state
            this.LmeAmostra.find({
              filter: {
                fields: {id: true, localId: true, estadoId: true},
                where: {
                  and: [{active: 1}, {id: {inq: ids.map(x => x.id)}}]
                }
              }
            }).$promise.then((procs) => {
              // Check if all procs exist and estados are the same
              if (procs.length === ids.length && !procs.some(x => (x.localId !== ids[0].localId) || (x.estadoId !== ids[0].estadoId))) {
                let wait = this.UI.showWaiting();
                // Check if we need new estado or not
                let novoEstado = null;
                // If it was out and is now in, use new estado
                if (!localOriginal.inside && ok.local.novoLocal.selected.inside) {
                  novoEstado = ok.local.estadoEntrada.selected.id;
                } else if(localOriginal.inside && !ok.local.novoLocal.selected.inside) {
                  // If it was inside and is now outside, change estado to new estado
                  novoEstado = ok.local.estadoSaida.selected.id;
                } // Else don't change estado

                this.LmeAmostraAlteracaoLocal.to({
                  params: {
                    ids: ids.map(x => x.id),
                    local: ok.local.novoLocal.selected.id,
                    state: novoEstado,
                    userId: this.Auth.getId(),
                    observacoes: ok.local.observacoes || null
                  }
                }).$promise.then(res => {
                  wait.close();
                  let errors = res.status.filter(x => x.error);
                  if (errors.length > 0) {
                    let title = "Erros de Alteração de Local";
                    let introText = "Ocorreram os seguintes erros na alteração de local:";
                    let instance = this.UI.showDialog({
                      size: 'md',
                      template: require('../../interface/modals/show-list.html'),
                      controller: ['$scope', ($scope) => {
                        $scope.title = title;
                        $scope.introText = introText;
                        $scope.list = errors;
                        $scope.ok = function () {
                          $scope.$close();
                        };
                      }]
                    });
                    instance.finally(() => {defer.resolve();});
                  } else {
                    if (ids.length === 1)
                      this.UI.addToast("Local de amostra alterado com sucesso");
                    else
                      this.UI.addToast("Local de amostras alterado com sucesso");
                    defer.resolve();
                  }
                }).catch(error => {
                  console.log(error);
                  wait.close();
                  let alert = this.UI.showAlert("Não foi possível encontrar estado / local. Verifique a ligação.");
                  alert.finally(() => {defer.reject();});
                });
              } else {
                let alert = this.UI.showAlert("Houve pelo menos uma alteração de local/estado nas amostras selecionadas. Por favor tente novamente.");
                alert.finally(() => {defer.reject();});
              }
            }).catch((error) => {
              console.log(error);
              let alert = this.UI.showAlert("Não foi possível consultar informação de amostras. Verifique a ligação");
              alert.finally(() => {defer.reject();});
            });
          }).catch(() => {defer.reject();});
        }).catch(() => {defer.reject();});
      }).catch((error) => {
        wait.close();
        console.log(error);
        let alert = this.UI.showAlert("Erro de carregamento de possíveis estados de destino.");
        alert.finally(() => {defer.reject();});
      });
    }).catch(e => {
      wait.close();
      console.log(e);
      let alert = this.UI.showAlert("Erro de carregamento de possíveis locais de destino.");
      alert.finally(() => {defer.reject();});
    });
    return defer.promise;
  };


  // Change the state for amostras with id in array ids = [{id: ..., estadoId: ..., estadoInside: ...}, ...]
  // estadoOriginal is an object with (at least) {id: ..., inside: ... }
  changeStateMultiple = (ids, estadoOriginal) => {
    let defer = this.$q.defer();
    let wait = this.UI.showWaiting();
    // Open modal to pick state and obs
    this.LmeEstadoAmostra.find({
      filter: {
        where: {
          and: [{active: true}, {id: {neq: estadoOriginal.id}}]
        }
      }
    }).$promise.then(estados => {
      this.LmeLocal.find({
        filter: {
          where: {
            inside: 1
          }
        }
      }).$promise.then((locaisInside) => {
        wait.close();
        let options = {
          size: "lg",
          template: require("./directory/state.dialog.html"),
          controller: ["$scope", "$dialog", ($scope, $dialog) => {
            $scope.estados = estados;
            $scope.locaisInside = locaisInside;

            // Used just for estado of amostra
            $scope.amostra = {
              estado: estadoOriginal
            };

            $scope.isInside = (estado) => {
              // Returns true if amostra is located inside, false otherwise
              return estado && estado.inside;
            };

            $scope.state = {
              novoEstado: {
                selected: undefined,
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              },
              novoLocal: {
                selected: undefined,
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              },
              observacoes: null
            };

            // Infinite Scroll magic
            $scope.addMoreItems = (infiniteScroll) => {
              infiniteScroll.currentItems += infiniteScroll.numToAdd;
            };

            $scope.ok = () => {
              $dialog.close($scope);
            };

            $scope.cancel = () => {
              $dialog.dismiss("cancel");
            };
          }]
        };

        let modal = this.UI.showDialog(options);

        modal.then((ok) => {
          let processCountString = ids.length > 1 ? `destas ${ids.length} amostras` : `desta amostra`;
          let warning = `Tem a certeza que pretende alterar o estado ${processCountString} para: ${ok.state.novoEstado.selected.descricao}?`;

          let result = this.UI.showConfirm(warning);
          result.then(() => {
            // Recheck if all pedidos have the same state
            this.LmeAmostra.find({
              filter: {
                fields: {id: true, estadoId: true},
                where: {
                  and: [{active: 1}, {id: {inq: ids.map(x => x.id)}}]
                }
              }
            }).$promise.then((procs) => {
              // Check if all procs exist and estados are the same
              if (procs.length === ids.length && !procs.some(x => x.estadoId !== ids[0].estadoId)) {
                let wait = this.UI.showWaiting();
                // Check if we need new local or not
                let novoLocal = null;
                // If it was out and is now in, use local
                if (!estadoOriginal.inside && ok.state.novoEstado.selected.inside) {
                  novoLocal = ok.state.novoLocal.selected.id;
                } else if(estadoOriginal.inside && !ok.state.novoEstado.selected.inside) {
                  // If it was inside and is now outside, change local to "fora instalações"
                  novoLocal = 4; // Fora das instalações
                } // Else don't change local

                // TODO - Check if novoEstado é "Para levantamento" e mover a amostra para X

                this.LmeAmostraAlteracaoEstado.to({
                  params: {
                    ids: ids.map(x => x.id),
                    state: ok.state.novoEstado.selected.id,
                    local: novoLocal,
                    userId: this.Auth.getId(),
                    observacoes: ok.state.observacoes || null
                  }
                }).$promise.then(res => {
                  wait.close();
                  let errors = res.status.filter(x => x.error);
                  if (errors.length > 0) {
                    let title = "Erros de Alteração de Estado";
                    let introText = "Ocorreram os seguintes erros na alteração de estado:";
                    let instance = this.UI.showDialog({
                      size: 'md',
                      template: require('../../interface/modals/show-list.html'),
                      controller: ['$scope', ($scope) => {
                        $scope.title = title;
                        $scope.introText = introText;
                        $scope.list = errors;
                        $scope.ok = function () {
                          $scope.$close();
                        };
                      }]
                    });
                    instance.finally(() => {defer.resolve();});
                  } else {
                    if (ids.length === 1)
                      this.UI.addToast("Estado de amostra alterado com sucesso");
                    else
                      this.UI.addToast("Estados de amostras alterados com sucesso");
                    defer.resolve();
                  }
                }).catch(error => {
                  console.log(error);
                  wait.close();
                  let alert = this.UI.showAlert("Não foi possível encontrar estado / local. Verifique a ligação.");
                  alert.finally(() => {defer.reject();});
                });
              } else {
                let alert = this.UI.showAlert("Houve pelo menos uma alteração de estado nas amostras selecionadas. Por favor tente novamente.");
                alert.finally(() => {defer.reject();});
              }
            }).catch((error) => {
              console.log(error);
              let alert = this.UI.showAlert("Não foi possível consultar informação de amostras. Verifique a ligação");
              alert.finally(() => {defer.reject();});
            });
          }).catch(() => {defer.reject();});
        }).catch(() => {defer.reject();});
      }).catch((error) => {
        wait.close();
        console.log(error);
        let alert = this.UI.showAlert("Erro de carregamento de possíveis locais de destino.");
        alert.finally(() => {defer.reject();});
      });
    }).catch(e => {
      wait.close();
      console.log(e);
      let alert = this.UI.showAlert("Erro de carregamento de dados de Estados.");
      alert.finally(() => {defer.reject();});
    });
    return defer.promise;
  };

  removeAmostras = (ids) => {
    let defer = this.$q.defer();
    if (!ids || ids.length === 0) defer.reject();

    this.LmeProcessoAmostra.find({
      filter: {
        where: {
          and: [ {amostraId: {inq: ids}}, {active: 1}]
        }
      }
    }).$promise.then((processosAmostras) => {
      let status = [];
      let tasks = [];
      ids.forEach(id => {
        let deferDelete = this.$q.defer();
        let processesForAmostra = processosAmostras.filter(x => x.amostraId === id);
        if (processesForAmostra.length === 0) { // Safe to delete, find and update it
          this.LmeAmostra.findById({id: id}).$promise.then((amostra) => {
            amostra.active = 0;
            this.LmeAmostra.upsert(amostra).$promise.then(() => {
              status.push({
                error: false,
                amostraId: id
              });
              deferDelete.resolve(id);
            }).catch(error => {
              status.push({
                error: true,
                amostraId: id,
                message: `Erro de remoção da amostra ${id}`
              });
              deferDelete.resolve(id);
            });
          }).catch((error) => {
            let message;
            if (error.status === 404) message = `Amostra ${id} não encontrada`;
            else message = `Erro de procura de amostra ${id}`;
            status.push({
              error: true,
              amostraId: id,
              message: message
            });
            deferDelete.resolve(id);
          });
        } else { // Can't delete, process exists
          status.push({
            error: true,
            amostraId: id,
            message: processesForAmostra.length === 1 ? `Existe 1 processo associado à amostra Nº ${id}` : `Existem ${processesForAmostra.length} processos associados à amostra Nº ${id}`
          });
          deferDelete.resolve(id);
        }
        tasks.push(deferDelete.promise);
      });

      this.$q.all(tasks).then((res) => {
        defer.resolve(status);
      }).catch(error => {
        defer.reject(error);
      });
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };
}
LmeAmostraService.$inject = ['$q', 'UIService', 'AuthenticationService', 'LmeAmostra', 'LmeEstadoAmostra', 'LmeAmostraAlteracaoEstado', 'LmeAmostraAlteracaoLocal', 'LmeProcessoAmostra', 'LmeLocal'];
