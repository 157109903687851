import moment from 'moment';
const ignore = [108, 540];
export default class OblerPendentesService {
    $q;
    OblProcessoOrcamentacao;
    AuthenticationService;
    FuncionarioEntidadeProprietaria;
    constructor($q, OblProcessoOrcamentacao, AuthenticationService, FuncionarioEntidadeProprietaria) {
        this.$q = $q;
        this.OblProcessoOrcamentacao = OblProcessoOrcamentacao;
        this.AuthenticationService = AuthenticationService;
        this.FuncionarioEntidadeProprietaria = FuncionarioEntidadeProprietaria;
    }
    findAll(query) {
        return this.OblProcessoOrcamentacao.table(query).$promise;
    }
    options() {
        return this.OblProcessoOrcamentacao.options().$promise;
    }
    patch(id, data) {
        return this.OblProcessoOrcamentacao.patch({ id }, data).$promise;
    }
    validate(id) {
        return this.OblProcessoOrcamentacao.validate({ id }).$promise;
    }
    export(query) {
        return this.OblProcessoOrcamentacao.export(query).$promise;
    }
    create(data) {
        data.requisitanteId = this.AuthenticationService.getId();
        data.dataRequisicao = moment().format();
        return this.OblProcessoOrcamentacao.create(data).$promise;
    }
    findAllTecnicos() {
        const defer = this.$q.defer();
        this.FuncionarioEntidadeProprietaria.find({
            filter: {
                where: {
                    entidadeProprietariaId: 3,
                    active: true
                },
                include: {
                    relation: 'funcionario',
                    scope: {
                        where: {
                            active: true,
                            blocked: false
                        }
                    }
                }
            }
        })
            .$promise.then(f => {
            const users = [];
            f.forEach(r => {
                if (r.funcionario && !ignore.includes(r.funcionario.id)) {
                    users.push(r.funcionario);
                }
            });
            defer.resolve(users.sort((a, b) => a.name.localeCompare(b.name)));
        })
            .catch(e => {
            defer.reject(e);
        });
        return defer.promise;
    }
}
OblerPendentesService.$inject = ['$q', 'OblProcessoOrcamentacao', 'AuthenticationService', 'FuncionarioEntidadeProprietaria'];
