import moment from "moment";

export default class FunProcessosListController {
  constructor($state, FunProcesso, FunTecnico, FunTipoInstalacao, FunTipoServico, FunEstadoProcesso, FunMotivo, UIService) {
    this.$state = $state;
    this.FunProcesso = FunProcesso;
    this.FunTecnico = FunTecnico;
    this.FunTipoInstalacao = FunTipoInstalacao;
    this.FunTipoServico = FunTipoServico;
    this.FunEstadoProcesso = FunEstadoProcesso;
    this.FunMotivo = FunMotivo;
    this.UI = UIService;

    this.dataLoaded = false;

    this.simnao = [{ name: "Sim", value: 1 }, { name: "Não", value: 0 }];
    this.tecnicos = [];
    this.tiposInstalacao = [];
    this.tiposServico = [];
    this.motivos = [];
    this.estados = [];
    this.filtersLoaded = [1, 0, 0, 0, 0, 0]; // Resultados already loaded

    this.opt = $state.params;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [{
      displayName: 'ID SGI',
      name: 'id',
      visible: false,
      sortable: true
    },
    {
      displayName: 'Nº Processo',
      name: 'numeroProcesso',
      visible: true,
      sortable: true
    },
    {
      displayName: 'ID Tarefa',
      name: 'tarefaId',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Data de Agendamento',
      name: 'agendamentoData',
      exportDate: 'DD-MM-YYYY',
      visible: true
    },
    {
      displayName: 'Hora Agendada',
      name: 'agendamentoHora',
      // exportDate: 'HH:mm:ss', Cannot be used because we can't directly use it as a moment
      visible: true
    },
    {
      displayName: 'Técnico',
      name: 'tecnico',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Tipo Instalação',
      name: 'tipoInstalacao',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Tipo Serviço',
      name: 'tipoServico',
      visible: true,
      sortable: true
    },
    {
      displayName: 'OI',
      name: 'codigo',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Realizado',
      name: 'realizado',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Justificação',
      name: 'justificacao',
      visible: false,
      sortable: false
    },
    {
      displayName: 'Razão de fecho',
      name: 'motivo',
      visible: false,
      sortable: true
    },
    {
      displayName: 'Pediu Reposição',
      name: 'pediuReposicao',
      visible: false,
      sortable: true
    },
    {
      displayName: 'Estado',
      name: 'estado',
      visible: true,
      sortable: true
    }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 'o'
    },
    {
      val: 'c',
      name: 'Começa por',
      type: 'o'
    },
    {
      val: 'd',
      name: 'Termina com',
      type: 'o'
    },
    {
      val: 'e',
      name: 'Contém',
      type: 'o'
    },
    {
      val: 'a',
      name: 'Igual (=)',
      type: 'd'
    },
    {
      val: 'b',
      name: 'Diferente de (≠)',
      type: 'd'
    },
    {
      val: 'c',
      name: 'Posterior a (>)',
      type: 'd'
    },
    {
      val: 'd',
      name: 'Anterior a (<)',
      type: 'd'
    },
    {
      val: 'e',
      name: 'Posterior ou igual (≥)',
      type: 'd'
    },
    {
      val: 'f',
      name: 'Anterior ou igual (≤)',
      type: 'd'
    },
    {
      val: 'a',
      name: 'Igual',
      type: 's'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 's'
    }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [{
      id: 'FunProcesso.id',
      name: 'ID SGI',
      type: 'o'
    },
    {
      id: 'FunProcesso.numeroProcesso',
      name: 'Nº Processo',
      type: 'o'
    },
    {
      id: 'FunProcesso.tarefaId',
      name: 'ID Tarefa',
      type: 'o'
    },
    {
      id: 'FunAgendamento.data',
      name: 'Data de Agendamento',
      type: 'd',
      format: "YYYY-MM-DD",
      displayFormat: "DD/MM/YYYY"
    },
    {
      id: 'FunAgendamento.hora',
      name: 'Hora de Agendamento',
      type: 'd',
      format: "HH:mm",
      displayFormat: "HH:mm"
    },
    {
      id: 'FunProcesso.funcionarioId',
      name: 'Técnico',
      type: 's',
      list: this.tecnicos
    },
    {
      id: 'FunOrdemintervencao.codigo',
      name: 'OI',
      type: 'o'
    },
    {
      id: 'FunProcesso.tipoInstalacaoId',
      name: 'Tipo Instalação',
      type: 's',
      list: this.tiposInstalacao
    },
    {
      id: 'FunProcesso.tipoServicoId',
      name: 'Tipo Serviço',
      type: 's',
      list: this.tiposServico
    },
    {
      id: 'FunProcesso.realizado',
      name: 'Realizado',
      type: 's',
      list: this.simnao
    },
    {
      id: 'FunProcesso.justificacao',
      name: 'Justificação',
      type: 'o'
    },
    {
      id: 'FunProcesso.motivoId',
      name: 'Razão de fecho',
      type: 's',
      list: this.motivos
    },
    {
      id: 'FunProcesso.pediuReposicao',
      name: 'Pediu Reposição',
      type: 's',
      list: this.simnao
    },
    {
      id: 'FunProcesso.estadoId',
      name: 'Estado',
      type: 's',
      list: this.estados
    }
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('FunProcessoDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('FunProcessoDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('FunProcessoFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('FunProcessoFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('FunProcessoFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'FunProcesso.funcionarioId')
          f.column.selected.list = this.tecnicos;
        if (f.column.selected.id === 'FunProcesso.tipoInstalacaoId')
          f.column.selected.list = this.tiposInstalacao;
        if (f.column.selected.id === 'FunProcesso.tipoServicoId')
          f.column.selected.list = this.tiposServico;
        if (f.column.selected.id === 'FunProcesso.realizado')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'FunProcesso.motivoId')
          f.column.selected.list = this.motivos;
        if (f.column.selected.id === 'FunProcesso.pediuReposicao')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'FunProcesso.estadoId')
          f.column.selected.list = this.estados;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value);
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getProcessos();
  };

  getRealizado = (valor) => {
    // Do not return anything if it's not valid, so it's written as mdash in the view
    if (valor !== null) {
      let value = this.simnao.find((r) => r.value === valor);
      if (value) {
        return value.name.toUpperCase();
      }
    }
  };

  getStyle = (valor) => {
    if (valor === null) {
      return {
        "font-weight": 500,
        color: "#4d575d"
      };
    }
    if (valor === 1) {
      return {
        "font-weight": 500,
        color: "#388e3c"
      };
    }
    if (valor === 0) {
      return {
        "font-weight": 500,
        color: "#d32f2f"
      };
    }
  };

  clearFilter = () => {
    localStorage.removeItem('FunProcessoFilter');
    this.customFilters = [];
    this.getProcessos();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table2() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  getProcessos = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {
      'FunProcesso.active': 1
    });

    this.FunProcesso.table2({
      params: {
        fields: [
          'FunProcesso.id as id',
          'FunProcesso.numeroProcesso as numeroProcesso',
          'FunProcesso.tarefaId as tarefaId',
          'FunProcesso.oiId as oiId',
          'FunProcesso.concelhoCliente as concelho',
          'FunProcesso.distritoCliente as distrito',
          'FunProcesso.codigoPostal as codigoPostal',
          'FunProcesso.dataAtribuicao as dataAtribuicao',
          'FunProcesso.dataPrimeiroAgendamento as dataPrimeiroAgendamento',
          'FunProcesso.dataImportacao as dataImportacao',
          'FunProcesso.realizado as realizado',
          'FunProcesso.justificacao as justificacao',
          'FunProcesso.pediuReposicao as pediuReposicao',
          'FunOrdemintervencao.codigo as codigo',
          'FunAgendamento.data as agendamentoData',
          'FunAgendamento.hora as agendamentoHora',
          'FunTipoInstalacao.designacao as tipoInstalacao',
          'FunTipoServico.designacao as tipoServico',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as tecnico',
          'FunMotivo.designacao as motivo',
          'FunEstadoProcesso.designacao as estado'
        ],
        from: ['FunProcesso', 'FunOrdemintervencao', 'FunAgendamento', 'FunTipoInstalacao', 'FunTipoServico', 'Funcionario', 'FunMotivo', 'FunEstadoProcesso'],
        referencesOrigin: [undefined, undefined, 'FunAgendamento.processoId', undefined, undefined, 'FunAgendamento.tecnicoId', undefined, undefined],
        references: [undefined, 'FunProcesso.oiId', 'FunProcesso.id', 'FunProcesso.tipoInstalacaoId', 'FunProcesso.tipoServicoId', 'Funcionario.id', 'FunProcesso.motivoId', 'FunProcesso.estadoId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      res.data.forEach(i => {
        if (i.cp4 && !i.cp3) {
          i.codigoPostal = i.cp4;
        }
        if (i.cp4 && i.cp3) {
          i.codigoPostal = `${('0000' + i.cp4).slice(-4)}-${('000' + i.cp3).slice(-3)}`;
        }
        if (i.cp3 != null && !isNaN(i.cp3))
          i.cp3 = ('000' + i.cp3).slice(-3);
      });

      this.processos = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((error) => {
      console.log(error);
    });
  };

  exportProcessos = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, { 'FunProcesso.active': 1 });
    this.FunProcesso.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'FunProcesso.id as id',
          'FunProcesso.numeroProcesso as numeroProcesso',
          'FunProcesso.tarefaId as tarefaId',
          'FunProcesso.oiId as oiId',
          'FunProcesso.concelhoCliente as concelho',
          'FunProcesso.distritoCliente as distrito',
          'FunProcesso.codigoPostal as codigoPostal',
          'FunProcesso.dataAtribuicao as dataAtribuicao',
          'FunProcesso.dataPrimeiroAgendamento as dataPrimeiroAgendamento',
          'FunProcesso.dataImportacao as dataImportacao',
          'FunProcesso.realizado as realizado',
          'FunProcesso.justificacao as justificacao',
          'FunProcesso.pediuReposicao as pediuReposicao',
          'FunOrdemintervencao.codigo as codigo',
          'FunAgendamento.data as agendamentoData',
          'FunAgendamento.hora as agendamentoHora',
          'FunTipoInstalacao.designacao as tipoInstalacao',
          'FunTipoServico.designacao as tipoServico',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as tecnico',
          'FunMotivo.designacao as motivo',
          'FunEstadoProcesso.designacao as estado'
        ],
        from: ['FunProcesso', 'FunOrdemintervencao', 'FunAgendamento', 'FunTipoInstalacao', 'FunTipoServico', 'Funcionario', 'FunMotivo', 'FunEstadoProcesso'],
        referencesOrigin: [undefined, undefined, 'FunAgendamento.processoId', undefined, undefined, 'FunAgendamento.tecnicoId', undefined, undefined],
        references: [undefined, 'FunProcesso.oiId', 'FunProcesso.id', 'FunProcesso.tipoInstalacaoId', 'FunProcesso.tipoServicoId', 'Funcionario.id', 'FunProcesso.motivoId', 'FunProcesso.estadoId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'processosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
    });
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.processos, x => x.selected), x => {
        return { key: 'FunProcesso.id', value: x.id };
      });
    let whereFields = this.setWhereFields(this.customFilters, { 'FunProcesso.active': 1 }, orsLiteral);
    this.FunProcesso.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'FunProcesso.id as id',
          'FunProcesso.numeroProcesso as numeroProcesso',
          'FunProcesso.tarefaId as tarefaId',
          'FunProcesso.oiId as oiId',
          'FunProcesso.concelhoCliente as concelho',
          'FunProcesso.distritoCliente as distrito',
          'FunProcesso.codigoPostal as codigoPostal',
          'FunProcesso.dataAtribuicao as dataAtribuicao',
          'FunProcesso.dataPrimeiroAgendamento as dataPrimeiroAgendamento',
          'FunProcesso.dataImportacao as dataImportacao',
          'FunProcesso.realizado as realizado',
          'FunProcesso.justificacao as justificacao',
          'FunProcesso.pediuReposicao as pediuReposicao',
          'FunOrdemintervencao.codigo as codigo',
          'FunAgendamento.data as agendamentoData',
          'FunAgendamento.hora as agendamentoHora',
          'FunTipoInstalacao.designacao as tipoInstalacao',
          'FunTipoServico.designacao as tipoServico',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as tecnico',
          'FunMotivo.designacao as motivo',
          'FunEstadoProcesso.designacao as estado'
        ],
        from: ['FunProcesso', 'FunOrdemintervencao', 'FunAgendamento', 'FunTipoInstalacao', 'FunTipoServico', 'Funcionario', 'FunMotivo', 'FunEstadoProcesso'],
        referencesOrigin: [undefined, undefined, 'FunAgendamento.processoId', undefined, undefined, 'FunAgendamento.tecnicoId', undefined, undefined],
        references: [undefined, 'FunProcesso.oiId', 'FunProcesso.id', 'FunProcesso.tipoInstalacaoId', 'FunProcesso.tipoServicoId', 'Funcionario.id', 'FunProcesso.motivoId', 'FunProcesso.estadoId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'processosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
    });
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('FunProcessoDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  loadData = () => {
    this.FunTecnico.find({
      filter: {
        where: {
          active: 1
        },
        include: {
          relation: 'funcionario'
        }
      }
    }).$promise.then((ft) => {
      if (ft && ft.length > 0) {
        this.tecnicos = ft.map(x => x.funcionario);
      }
      this.filtersLoaded[1] = 1;
    }).catch(err => {
      console.log(err);
      this.tecnicos = [];
      this.filtersLoaded[1] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Técnicos).");
    });

    this.FunTipoInstalacao.find({
    }).$promise.then(tiposInstalacao => {
      tiposInstalacao.forEach(t => {
        t.name = t.designacao;
      });
      this.tiposInstalacao = tiposInstalacao;
      this.filtersLoaded[2] = 1;
    }).catch(e => {
      this.tiposInstalacao = [];
      this.filtersLoaded[2] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Tipos de Instalação).");
    });

    this.FunTipoServico.find({
    }).$promise.then(tiposServico => {
      tiposServico.forEach(t => {
        t.name = t.designacao;
      });
      this.tiposServico = tiposServico;
      this.filtersLoaded[3] = 1;
    }).catch(e => {
      this.tiposServico = [];
      this.filtersLoaded[3] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Tipos de Serviço).");
    });

    this.FunMotivo.getMotivos().$promise.then(motivos => {
      motivos.forEach(t => {
        t.name = t.designacao;
      });
      this.motivos = motivos;
      this.filtersLoaded[4] = 1;
    }).catch(e => {
      this.motivos = [];
      this.filtersLoaded[4] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Razões de fecho).");
    });

    this.FunEstadoProcesso.find({
    }).$promise.then(estados => {
      estados.forEach(t => {
        t.name = t.designacao;
      });
      this.estados = estados;
      this.filtersLoaded[5] = 1;
    }).catch(e => {
      this.estados = [];
      this.filtersLoaded[5] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Estados de Processo).");
    });

  };

  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.funciona.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.funciona.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.funciona.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.tecnicos.length && !this.tiposInstalacao.length && !this.tiposServico.length && !this.motivos.length && !this.estados.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'FunProcesso.funcionarioId')
        f.list = this.tecnicos;
      if (f.id === 'FunProcesso.tipoInstalacaoId')
        f.list = this.tiposInstalacao;
      if (f.id === 'FunProcesso.tipoServicoId')
        f.list = this.tiposServico;
      if (f.id === 'FunProcesso.realizado')
        f.list = this.simnao;
      if (f.id === 'FunProcesso.motivoId')
        f.list = this.motivos;
      if (f.id === 'FunProcesso.pediuReposicao')
        f.list = this.simnao;
      if (f.id === 'FunProcesso.estadoId')
        f.list = this.estados;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'FunProcesso.funcionarioId')
          f.column.selected.list = this.tecnicos;
        if (f.column.selected.id === 'FunProcesso.tipoInstalacaoId')
          f.column.selected.list = this.tiposInstalacao;
        if (f.column.selected.id === 'FunProcesso.tipoServicoId')
          f.column.selected.list = this.tiposServico;
        if (f.column.selected.id === 'FunProcesso.realizado')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'FunProcesso.motivoId')
          f.column.selected.list = this.motivos;
        if (f.column.selected.id === 'FunProcesso.pediuReposicao')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'FunProcesso.estadoId')
          f.column.selected.list = this.estados;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value);
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('FunProcessoFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.funciona.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getProcessos();
  };

  hasSelect = () => {
    return _.some(this.processos, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.processos.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.processos.length;
    } else { // Remove all selections
      this.processos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.processos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.processos, r => r.selected).length;
    this.allSelected = this.nSelected >= this.processos.length;
    this.everythingSelected = false;
  };

  import = () => {
    this.UI.showDialog({
      template: require('./import/dialog.html'),
      controller: 'FunImporterController',
      controllerAs: 'vm'
    }).then(r => {
      this.UI.addToast("Ficheiro importado com sucesso");
      this.getProcessos();
    }).catch(e => {
      if (e === 'error') {
        this.UI.addToast("Não foi possível importar ficheiro");
        this.getProcessos();
      } else {
        this.getProcessos();
      }
    });
  }

  // add = () => {
  //   this.UI.showDialog({
  //     template: require('./processo.dialog.html'),
  //     controller: ['$scope', 'FunTipoServico', 'FuncionarioEntidadeProprietaria', ($scope, FunTipoServico, FuncionarioEntidadeProprietaria) => {
  //
  //       $scope.aux = {}
  //
  //       $scope.processoLoaded = false;
  //       $scope.tecnicosLoaded = false;
  //
  //       FunTipoServico.find({
  //         filter: {
  //           where: {
  //             active: true
  //           }
  //         }
  //       }).$promise.then(r => {
  //         $scope.tipos = r;
  //         $scope.processoLoaded = true;
  //       });
  //
  //       $scope.$watch(() => {
  //         return $scope.data.agData
  //       }, (val) => {
  //         $scope.tecnicosLoaded = false;
  //         FuncionarioEntidadeProprietaria.find({
  //           filter: {
  //             where: {
  //               entidadeProprietariaId: 3,
  //               active: true
  //             },
  //             include: {
  //               relation: 'funcionario',
  //               scope: {
  //                 include: {
  //                   relation: 'ausencias',
  //                   scope: {
  //                     where: {
  //                       dataInicio: {
  //                         lte: $scope.data.agData
  //                       },
  //                       dataFim: {
  //                         gte: $scope.data.agData
  //                       }
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }).$promise.then(f => {
  //           $scope.users = [];
  //           f.forEach(r => {
  //             if (r.funcionario) {
  //               if (r.funcionario.ausencias.length == 0) {
  //                 r.funcionario.disabled = false;
  //                 $scope.users.push(r.funcionario);
  //               }
  //             }
  //           });
  //           $scope.users = $scope.users.sort((a, b) => a.name.localeCompare(b.name));
  //           $scope.tecnicosLoaded = true;
  //         });
  //       });
  //
  //
  //       $scope.data = {
  //         duracao: 30
  //       }
  //
  //
  //       $scope.dateOptions = {
  //         minDate: moment(),
  //         format: 'YYYY-MM-DD'
  //       }
  //
  //       $scope.hourOptions = {
  //         format: 'HH:mm'
  //       }
  //
  //       $scope.ok = () => {
  //         $scope.data.tipoId = $scope.aux.tipo.id;
  //         $scope.data.funcionarioId = $scope.aux.user.id;
  //         $scope.$close($scope.data);
  //       };
  //       $scope.cancel = () => { $scope.$dismiss(); };
  //     }]
  //   }).then(r => {
  //     if (r) {
  //       this.loaded = false;
  //       let userId = this.Auth.getId();
  //       // Cria processo e agenda
  //       this.FunProcesso.create({
  //         id: 0,
  //         oiId: this.id,
  //         validado: false,
  //         active: true,
  //         criadoPor: userId,
  //         data: moment.utc(),
  //         funcionarioId: r.funcionarioId,
  //         tipoId: r.tipoId
  //       }).$promise.then(processo => {
  //         this.FunAgendamento.create({
  //           id: 0,
  //           duracao: r.duracao,
  //           agendadoa: moment.utc(),
  //           data: r.agData.format("YYYY-MM-DD"),
  //           hora: r.agHora.format("HH:mm:00"),
  //           tecnicoId: r.funcionarioId,
  //           agendadoporId: userId,
  //           processoId: processo.id,
  //           active: true
  //         }).$promise.then(agendamento => {
  //           if (r.tipoId == 2) {
  //             this.changeState(4);
  //           } else if (r.tipoId == 3) {
  //             this.changeState(9);
  //           } else {
  //             this.UI.addToast("Agendamento efetuado");
  //             this.loadData();
  //           }
  //
  //         }).catch(e => {
  //           this.UI.addToast('Ocorreu um erro ao agendar processo');
  //           this.loadData();
  //         });
  //       }).catch(e => {
  //         this.UI.addToast('Ocorreu um erro ao adicionar processo');
  //         this.loadData();
  //       });
  //     }
  //   });
  // }
}

FunProcessosListController.$inject = ['$state', 'FunProcesso', 'FunTecnico', 'FunTipoInstalacao', 'FunTipoServico', 'FunEstadoProcesso', 'FunMotivo', 'UIService'];
