export default class DashboardFormacaoService {
  constructor($q, $filter, FormacaoFormando, FormacaoEdicao) {
    this.$q = $q;
    this.$filter = $filter;
    this.Formando = FormacaoFormando;
    this.Edicoes = FormacaoEdicao;
  }

  getFormandos = () => {
    let defer = this.$q.defer();
    this.Formando.dashboard().$promise.then(req => defer.resolve(req)).catch(err => defer.reject(err));
    return defer.promise;
  };

  getHorasFormacao = () => {
    let defer = this.$q.defer();
    this.Edicoes.dashboard().$promise.then(req => defer.resolve(req)).catch(err => defer.reject(err));
    return defer.promise;
  };
}

DashboardFormacaoService.$inject = ['$q', '$filter', 'FormacaoFormando', 'FormacaoEdicao'];
