export default class UserAdminController {
  constructor($rootScope, $state, AdministrationService, UIService, Funcionario) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.Administration = AdministrationService;
    this.UI = UIService;
    this.Funcionario = Funcionario;

    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      this.status = data;
    });

    this.type = 'password';
    this.iconType = 'mdi-lock';
    this.user = {
      imap: false,
      email: '',
      name: '',
      password: '',
      numeroMecanografico: null,
      cartaoAcesso: null
    };
    this.sendEmail = false;
  }

  randomizePassword = () => {
    let chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    let pass = "";
    let size = Math.floor(Math.random() * (16 - 8 + 1)) + 8;
    for (let x = 0; x < size; x++) {
      let i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    this.user.password = pass;
  };

  showPassword = () => {
    if (this.type.includes('password')) {
      this.type = 'text';
      this.iconType = 'mdi-lock-open';
    } else {
      this.type = 'password';
      this.iconType = 'mdi-lock';
    }
  };

  createAccount = () => {
    this.user.email = this.user.email.trim();
    if (this.user.imap) {
      this.randomizePassword();
    }

    // Check if email has already been used
    this.Funcionario.find({
      filter: {
        where: {
          email: this.user.email
        }
      }
    }).$promise.then((existing) => {
      if (existing.length === 0) {
        this.Administration.createUser(this.user).then(() => {
          this.UI.addToast('Utilizador adicionado com sucesso');
          this.$state.go('app.administration.main');
        });
      } else { // User with that email already exists
        let errorMessage = "Já existe um colaborador com esse email registado: " + existing[0].name;
        let createdAt = moment.utc(existing[0].createdAt);
        if (createdAt.isValid())
          errorMessage += ", criado a " + createdAt.format('DD/MM/YYYY');
        errorMessage += ". Caso o utilizador já não exista, altere o email original para evitar conflitos.";
        alert = this.UI.showAlert(errorMessage);
        alert.finally(() => {
          // Do nothing
        }).catch(() => {});
      }
    }).catch((error) => {
      console.log(error);
    });
  }
}

UserAdminController.$inject = ['$rootScope', '$state', 'AdministrationService', 'UIService', 'Funcionario'];
