export default class DirectoryCondominioController {
  constructor($q, $state, UIService, AuthenticationService, ElvCondominio) {
    this.$q = $q;
    this.$state = $state;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.ElvCondominio = ElvCondominio;

    this.tableLoading = true;

    // this.tipos = [];
    // this.filtersLoaded = [0, 0, 0, 0, 0];

    this.opt = $state.params;

    this.opt = this.opt || this.defaultOpt;

    // Total processes for currently being displayed
    this.total = 0;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [
      {
        displayName: "ID SGI",
        name: "id",
        visible: false,
        sortable: true
      },
      {
        displayName: "Nome",
        name: "nome",
        visible: true,
        sortable: true,
        width: 250
      },
      {
        displayName: "NIF",
        name: "nif",
        visible: true,
        sortable: true
      },
      {
        displayName: "Email",
        name: "email",
        visible: true,
        sortable: true
      },
      {
        displayName: "Contacto Tel.",
        name: "telemovel",
        visible: true,
        sortable: false
      },
      {
        displayName: "Morada",
        name: "morada",
        visible: true,
        sortable: false
      },
      {
        displayName: "CP4",
        name: "cp4",
        visible: true,
        sortable: true
      },
      {
        displayName: "CP3",
        name: "cp3",
        visible: true,
        sortable: true
      },
      {
        displayName: "Localidade",
        name: "localidade",
        visible: true,
        sortable: true
      },
      {
        displayName: "Observações",
        name: "observacoes",
        visible: false,
        sortable: true
      }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: "ElvCondominio.id",
        name: "ID SGI",
        type: "o"
      },
      {
        id: "ElvCondominio.nome",
        name: "Nome",
        type: "o"
      },
      {
        id: "ElvCondominio.nif",
        name: "NIF",
        type: "o"
      },
      {
        id: "ElvCondominio.email",
        name: "Email",
        type: "o"
      },
      {
        id: "ElvCondominio.telemovel",
        name: "Contacto Tel.",
        type: "o"
      },
      {
        id: "ElvCondominio.morada",
        name: "Morada",
        type: "o"
      },
      {
        id: "ElvCondominio.cp4",
        name: "CP4",
        type: "o"
      },
      {
        id: "ElvCondominio.cp3",
        name: "CP3",
        type: "o"
      },
      {
        id: "ElvCondominio.localidade",
        name: "Localidade",
        type: "o"
      },
      {
        id: "ElvCondominio.observacoes",
        name: "Observações",
        type: "o"
      }
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem("ElvCondominioDisplayColumns")) {
      let cols = JSON.parse(localStorage.getItem("ElvCondominioDisplayColumns"));
      if (cols && cols.length > 0) {
        cols.forEach((c) => {
          let i = this.displayColumns.findIndex((x) => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem("ElvCondominioFilter")) {
      this.customFilters = JSON.parse(localStorage.getItem("ElvCondominioFilter"));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach((filter) => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {},
            };
            data.column.selected = this.columns.find((f) => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast("Não foi possível carregar filtros");
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem("ElvCondominioFilter", JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        // if (f.column.selected.id === "ElvCondominio.tipoId")
        //   f.column.selected.list = this.tipos;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === "d") {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    this.loadData();

    this.getCondominios();
  }

  isColumnVisible = (column) => {
    let f = this.displayColumns.find((x) => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  clearFilter = () => {
    localStorage.removeItem('ElvCondominioFilter');
    this.customFilters = [];
    this.getCondominios();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find((x) => x.key === data.key)) {
      // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value,
      });
    } else {
      // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) {
        // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key],
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value,
        });
      } else {
        // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach((f) => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split(".").filter(Boolean).length === 1 && f.column.selected.type === "d") {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format,
        });
      } else {
        // All other cases where fields exist in database
        if (f.column.selected.type === "o") {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === "d") {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format,
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id) data.value = f.value.selected.id;
            else data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral,
    };
  };

  loadData = () => {
    // Nothing to load
  };

  getCondominios = () => {
    this.tableLoading = true;
    let whereFields = this.setWhereFields(this.customFilters, {'ElvCondominio.active': 1});

    this.ElvCondominio.table({
      params: {
        fields: [
          "ElvCondominio.id as id",
          "ElvCondominio.nome as nome",
          "ElvCondominio.nif as nif",
          "ElvCondominio.email as email",
          "ElvCondominio.telemovel as telemovel",
          "ElvCondominio.morada as morada",
          "ElvCondominio.cp4 as cp4",
          "ElvCondominio.cp3 as cp3",
          "ElvCondominio.localidade as localidade",
          "ElvCondominio.observacoes as observacoes"
        ],
        from: ["ElvCondominio"],
        referencesOrigin: [undefined],
        references: [undefined],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      },
    })
      .$promise.then((res) => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if (this.start - 1 + items >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      this.condominios = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((e) => {
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível carregar condomínios com os parâmetros fornecidos.");
    });
  };

  hasSelect = () => {
    return _.some(this.condominios, (a) => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) {
      // Not all are selected, select all
      this.condominios.forEach((a) => {
        a.selected = true;
      });
      this.nSelected = this.condominios.filter((a) => a.selected).length;
    } else {
      // Remove all selections
      this.condominios.forEach((a) => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    // this.everythingSelected = false;
  };

  // NOT USED IN THIS VIEW
  selectEverything = (type) => {
    //   if (!type) {
    //     this.condominios.forEach((a) => {
    //       a.selected = false;
    //     });
    //     this.nSelected = 0;
    //     this.allSelected = false;
    //   }
    //   this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.condominios, (r) => r.selected).length;
    this.allSelected = this.nSelected >= this.condominios.length;
    // this.everythingSelected = false;
  };

  sort = (key) => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname) this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === "asc" ? "desc" : "asc";
    this.$state.go("app.elv.condominios.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getCondominios();
  };

  item = (val) => {
    this.opt.items = val;
    this.$state.go("app.elv.condominios.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getCondominios();
  };

  page = (sum) => {
    this.opt.page += sum;
    if (this.opt.page < 1) this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go("app.elv.condominios.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getCondominios();
  };

  openFilter = () => {
    // let result = this.filtersLoaded.reduce((a, b) => a + b, 0);
    //
    // if (result !== this.filtersLoaded.length) {
    //   this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
    //   return;
    // }

    // if (!this.tipos.length) {
    //   this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
    //   return;
    // }

    this.columns.forEach((f) => {
      // if (f.id === "ElvCondominio.tipoId")
      //   f.list = this.tipos;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        // if (f.column.selected.id === "ElvCondominio.tipoId")
        //   f.column.selected.list = this.tipos;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === "s") i.value = {};
    else i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, (f) => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === "d") {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem("ElvCondominioFilter", JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go("app.elv.condominios.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });

    this.getCondominios();
  };

  selectVisibleColumns = () => {
    let options = {
      size: "md",
      template: require("./columns.dialog.html"),
      controller: ["$dialog", "$scope", (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss("cancel");
        };
      },
      ],
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem("ElvCondominioDisplayColumns", JSON.stringify(this.displayColumns));
      }
    });
  };

  addCondominio = () => {
    let ElvCondominio = this.ElvCondominio;
    let options = {
      size: 'lg',
      template: require('./condominio.dialog.html'),
      controller: ['$dialog', '$scope', '$http', ($dialog, $scope, $http) => {

        $scope.o = {
          id: 0,
          nome: "",
          nif: "",
          email: "",
          active: true
        };
        $scope.checkingNif = false;
        $scope.nifExists = false;
        $scope.nifMessage = null;

        $scope.checkNif = () => {
          $scope.checkingNif = true;
          if (_.isEmpty($scope.o.nif)) {
            $scope.checkingNif = false;
            $scope.nifExists = false;
            $scope.nifMessage = null;
            return;
          }
          ElvCondominio.count({
            where: {
              nif: $scope.o.nif,
              active: 1
            }
          }).$promise.then((res) => {
            $scope.checkingNif = false;
            if (res.count !== 0) {
              $scope.nifExists = true;
              $scope.nifMessage = "Este NIF já se encontra registado para outro condomínio.";
            } else {
              $scope.nifExists = false;
              $scope.nifMessage = null;
            }
          }).catch((error) => {
            $scope.checkingNif = false;
            console.log(error);
            $scope.nifMessage = "Não foi possível verificar existência de NIF.";
          });
        };

        $scope.getLocalidade = () => {
          $scope.o.localidade = "";
          let cp4 = parseInt($scope.o.cp4);
          let cp3 = parseInt($scope.o.cp3);
          if (!Number.isInteger(cp4)) {
            $scope.o.cp3 = "";
          } else if (cp4.toString().length !== 4) {
            $scope.o.cp3 = "";
          } else if (cp4.toString().length === 4) {
            if (Number.isInteger(cp3)) {
              if ($scope.o.cp3.toString().length === 3) {
                $http.post('/api/public/getLocalidade', {
                  cp4: $scope.o.cp4,
                  cp3: $scope.o.cp3
                }).then(response => {
                  $scope.o.localidade = response.data.result;
                });
              }
            } else {
              $http.post('/api/public/getLocalidade', {
                cp4: $scope.o.cp4,
                cp3: null
              }).then(response => {
                $scope.o.localidade = response.data.result;
              });
            }
          }
        };

        $scope.isOk = () => {
          let o = $scope.o;
          return o.nome && o.morada && !$scope.checkingNif && $scope.nifMessage == null && !$scope.nifExists;
        };

        $scope.ok = () => {
          $dialog.close($scope.o);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }],
    };
    let modal = this.UI.showDialog(options);
    modal.then(res => {
      if (res) {
        let cp4 = parseInt(res.cp4);
        let cp3 = parseInt(res.cp3);
        if (Number.isNaN(cp4)) {
          res.cp4 = null;
          res.cp3 = null;
        } else {
          res.cp4 = cp4;
        }
        if (Number.isNaN(cp3)) {
          res.cp3 = null;
        } else {
          res.cp3 = cp3;
        }
        this.ElvCondominio.create(res).$promise.then(() => {
          this.getCondominios();
          this.UI.addToast('Condomínio inserido com sucesso');
        }).catch((err) => {
          console.log(err);
          this.UI.addToast('Erro ao inserir Condomínio');
        });
      }
    });
  };
}
DirectoryCondominioController.$inject = ['$q', '$state', 'UIService', 'AuthenticationService', 'ElvCondominio'];
