export default class OblArmazemDetailsController {
  constructor($state, $q, $http, UIService, AuthenticationService, OblArmazem) {
    this.$state = $state;
    this.$http = $http;
    this.$q = $q;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.OblArmazem = OblArmazem;
    this.limit = 3;

    this.id = $state.params.id;
    if (!this.id) {
      this.$state.go("app.obler.armazem.list");
    } else {
      this.loadData();
    }
  }

  sync = () => {
    if (!this.data.primaveraId) {
      this.UI.addToast("Referência Primavera não definida!");
      return;
    }
    this.UI.showConfirm(
      "Deseja forçar sincronização do armazém com o Primavera? Esta ação pode demorar alguns minutos"
    ).then((r) => {
      if (r) {
        this.UI.addToast("Sincronização inicializada. Por favor aguarde...");
        this.OblArmazem.sync({ id: this.id }).$promise.then((_) => {
          this.UI.addToast("Sincronização concluída");
          this.loadData();
        });
      }
    });
  };

  getHistoryMessage = (h) => {
    switch (h.tiporeposicaoId) {
      case 1: {
        return `Abatido ${h.quantidade} ${h.Produto.unidade} de ${h.Produto.designacao}`;
      }
      case 2: {
        return `Adicionado ${h.quantidade} ${h.Produto.unidade} de ${h.Produto.designacao}`;
      }
      case 3: {
        return `Adicionado ${h.quantidade} ${h.Produto.unidade} de ${h.Produto.designacao}`;
      }
      case 4: {
        return `Enviado ${h.quantidade} ${h.Produto.unidade} de ${h.Produto.designacao} para viatura ${h.Viatura.matricula}`;
      }
      case 5: {
        return `Recebido ${h.quantidade} ${h.Produto.unidade} de ${h.Produto.designacao} de ${h.Viatura.matricula}`;
      }
      default: {
        return "";
      }
    }
  };

  editDetails = () => {
    this.UI.showDialog({
      template: require("./dialog.html"),
      controller: [
        "$scope",
        "$http",
        ($scope, $http) => {
          $scope.data = angular.copy(this.data);

          console.log($scope.data);

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          $scope.getLocalidade = function () {
            $scope.data.localidade = "";
            let cp4 = parseInt($scope.data.cp4);
            let cp3 = parseInt($scope.data.cp3);
            if (!Number.isInteger(cp4)) {
              $scope.data.cp3 = "";
            } else if (cp4.toString().length !== 4) {
              $scope.data.cp3 = "";
            } else if (cp4.toString().length === 4) {
              if (Number.isInteger(cp3)) {
                if ($scope.data.cp3.toString().length === 3) {
                  $http
                    .post("/api/public/getLocalidade", {
                      cp4: $scope.data.cp4,
                      cp3: $scope.data.cp3,
                    })
                    .then((response) => {
                      $scope.data.localidade = response.data.result;
                    });
                }
              } else {
                $http
                  .post("/api/public/getLocalidade", {
                    cp4: $scope.data.cp4,
                    cp3: null,
                  })
                  .then((response) => {
                    $scope.data.localidade = response.data.result;
                  });
              }
            }
          };
        },
      ],
    })
      .then((r) => {
        if (r) {
          this.OblArmazem.prototype$updateAttributes(
            { id: this.id },
            {
              designacao: r.designacao,
              morada: r.morada,
              cp4: r.cp4,
              cp3: r.cp3,
              localidade: r.localidade,
            }
          ).$promise.then((_) => {
            this.UI.addToast("Dados alterados com sucesso");
            this.loadData();
          });
        }
      })
      .catch((e) => {});
  };

  formatZipCode = () => {
    if (this.data.cp4 === null) {
      this.data.formatedZipCode = "N/D";
    } else if (this.data.cp4 !== null && this.data.cp3 === null) {
      this.$http({
        url: "/api/public/getLocalidade",
        method: "POST",
        async: true,
        data: {
          cp4: "" + this.data.cp4,
          cp3: null,
        },
      })
        .then((response) => {
          this.data.localidade = response.data.result;
          this.data.formatedZipCode =
            this.data.cp4 + " " + this.data.localidade;
        })
        .catch((e) => {
          this.data.formatedZipCode =
            this.data.cp4 + "-" + this.data.cp3 + " " + this.data.localidade;
        });
    } else if (this.data.cp4 !== null && this.data.cp3 !== null) {
      this.$http({
        url: "/api/public/getLocalidade",
        method: "POST",
        async: true,
        data: {
          cp4: "" + this.data.cp4,
          cp3: ("000" + this.data.cp3).slice(-3),
        },
      })
        .then((response) => {
          this.data.cp3 = ("000" + this.data.cp3).slice(-3);
          this.data.localidade = response.data.result;
          this.data.formatedZipCode =
            this.data.cp4 + "-" + this.data.cp3 + " " + this.data.localidade;
        })
        .catch((e) => {
          this.data.formatedZipCode =
            this.data.cp4 + "-" + this.data.cp3 + " " + this.data.localidade;
        });
    }
  };

  loadData = () => {
    this.OblArmazem.findOne({
      filter: {
        where: {
          id: this.id,
          active: true,
        },
        include: [
          {
            relation: "Stock",
            scope: {
              where: {
                active: true,
              },
              include: {
                relation: "Produto",
                scope: {
                  where: {
                    active: true,
                  },
                  include: [
                    {
                      relation: "Fornecedor",
                      scope: {
                        where: {
                          active: true,
                        },
                      },
                    },
                    {
                      relation: "Familia",
                      scope: {
                        where: {
                          active: true,
                        },
                      },
                    },
                    {
                      relation: "Grupo",
                      scope: {
                        where: {
                          active: true,
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    })
      .$promise.then((r) => {
        this.data = r;
        this.formatZipCode();
        this.loaded = true;
      })
      .catch((e) => {
        this.UI.addToast("Não foi possível carregar armazém");
      });
  };

  limitTo = () => {
    if (angular.isDefined(this.limit)) {
      this.limit = undefined;
    } else {
      this.limit = 3;
    }
  };
}

OblArmazemDetailsController.$inject = [
  "$state",
  "$q",
  "$http",
  "UIService",
  "AuthenticationService",
  "OblArmazem",
];
