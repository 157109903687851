import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
export const MuiSelect = ({ size = 'medium', label, name, prop = 'id', freeSolo, defaultValue, helperText, error, onChange, onBlur, optionLabel }) => {
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    return (<Autocomplete size={size} freeSolo={freeSolo} loading={loading} open={open} onOpen={() => {
            setOpen(true);
        }} onClose={() => {
            setOpen(false);
        }} options={options} getOptionLabel={optionLabel} noOptionsText={'Nenhum resultado encontrado!'} loadingText={'A carregar...'} filterOptions={x => x} // Prevent throttling requests
     filterSelectedOptions defaultValue={defaultValue} inputValue={inputValue} onInputChange={(event, value) => {
            onChange(value);
            setInputValue(value);
            if (freeSolo) {
                onChange(value);
            }
        }} onChange={(event, value) => {
            onChange(value ?? undefined);
        }} onBlur={onBlur} renderInput={params => (<TextField {...params} InputProps={{
                ...params.InputProps,
                endAdornment: <React.Fragment>{loading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }} label={label} fullWidth defaultValue={defaultValue} error={error} helperText={helperText}/>)}/>);
};
