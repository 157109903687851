export default class PadProcessoDetailsController {
  constructor(
    $rootScope,
    $http,
    $state,
    $q,
    $stateParams,
    Funcionario,
    FileUploader,
    UIService,
    AuthorizationService,
    AuthenticationService,
    PadService,
    PadProcesso,
    PadLinhaProcesso,
    PadLinhaProcessoEstado,
    PadProcessoFornecedor,
    PadCriterio,
    PadProcessoAnexo,
    PadProcessoObservacaoFinanceira,
    PadConfig
  ) {
    this.$http = $http;
    this.$state = $state;
    this.$q = $q;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.Auth = AuthenticationService;
    this.user = AuthenticationService.getUser();
    this.Funcionario = Funcionario;
    this.FileUploader = FileUploader;
    this.PadService = PadService;
    this.PadProcesso = PadProcesso;
    this.PadLinhaProcesso = PadLinhaProcesso;
    this.PadLinhaProcessoEstado = PadLinhaProcessoEstado;
    this.PadProcessoFornecedor = PadProcessoFornecedor;
    this.PadCriterio = PadCriterio;
    this.PadProcessoAnexo = PadProcessoAnexo;
    this.PadProcessoObservacaoFinanceira = PadProcessoObservacaoFinanceira;
    this.PadConfig = PadConfig;
    this.loaded = false;
    this.id = $stateParams.id;

    // By default, envURL is production (to account for fails)
    this.envURL = 'https://inspecoeseletricas.pt';

    // Get environment for URL for files
    // Doing this here only because we only use this.envURL in the view
    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      if (data != null && data.environment != null) {
        let env = data.environment;
        if (env === 'staging') {
          this.envURL = 'https://staging-ie.iep.pt';
        } else if (env === 'production') {
          // For completeness
          this.envURL = 'https://inspecoeseletricas.pt';
        } else {
          this.envURL = 'https://dev-iep-ie.streamline.pt';
        }
      }
    });

    this.removableFileTypeIndexes = [3, 4, 5]; // Ids de documentos editáveis + imagens
    this.showEstadosProcesso = false;
    this.showEstadosLinhaProcesso = false;
    this.filterRevisions = true;

    //Infinite Scroll Magic
    this.infiniteScroll = {};
    this.infiniteScroll.numToAdd = 20;
    this.infiniteScroll.currentItems = 20;

    this.optLinhasProcesso = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: 'id',
      sort: 'asc',
      filter: {},
      filterLayout: {}
    };
    this.linhasProcesso = null;
    this.linhasProcessoLoading = true;

    this.optFornecedores = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: 'id',
      sort: 'asc',
      filter: {},
      filterLayout: {}
    };
    this.fornecedores = null;
    this.fornecedoresLoading = true;

    this.optObservacoesFinanceiras = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: 'id',
      sort: 'desc',
      filter: {},
      filterLayout: {}
    };
    this.observacoesFinanceiras = null;
    this.observacoesFinanceirasLoading = true;

    this.data = null;

    this.getProcesso();

    // this.hasPerms().then(() => {
    //   this.getProcesso();
    // }).catch(() => {
    //   $state.go($state.previous.name, $state.previous.params);
    // });
  }

  /**
   * Pedido por JE no dia 24/07 - Se PAD Nível 3 ou PAD Negociador, então deixa alterar este campo.
   * @returns boolean
   */
  canEditJustificacao = () => {
    return this.AuthorizationService.hasOne(['padNivel3', 'padNegociador']);
  };

  editJustificacao = () => {
    this.UI.showDialog({
      template: require('./edit.observacoes.dialog.html'),
      controller: [
        '$scope',
        $scope => {
          $scope.title = 'Editar justificação';
          $scope.label = 'Justificação';
          $scope.data = angular.copy(this.data.justificacao);

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          $scope.ok = () => {
            $scope.$close($scope.data);
          };
        }
      ]
    }).then(r => {
      if (r) {
        this.PadService.update(this.id, { justificacao: r })
          .then(_ => {
            this.PadService.estadoJustificacao(this.id, this.data.estadoId, angular.copy(this.data.justificacao)).then(a => {
              this.getProcesso();
              this.UI.addToast('Justificação atualizada com sucesso');
            });
          })
          .catch(_ => {
            this.UI.addToast('Erro de atualização de justificação. Recarregue a página');
          });
      }
    });
  };

  /**
   * Pedido por JL no dia 10/01/2025 - Se PAD Nível 3 ou PAD Negociador, então deixa alterar estes campos.
   * @returns boolean
   */
  canEditDesignacao = () => {
    return this.AuthorizationService.hasOne(['padNegociador']);
  };

  editDesignacao = () => {
    this.UI.showDialog({
      template: require('./edit.observacoes.dialog.html'),
      controller: [
        '$scope',
        $scope => {
          $scope.title = 'Editar designação';
          $scope.label = 'Designação';
          $scope.data = angular.copy(this.data.designacao);

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          $scope.ok = () => {
            $scope.$close($scope.data);
          };
        }
      ]
    }).then(r => {
      if (r) {
        this.PadService.update(this.id, { designacao: r })
          .then(_ => {
            this.PadService.estadoDesignacao(this.id, this.data.estadoId, angular.copy(this.data.designacao)).then(a => {
              this.getProcesso();
              this.UI.addToast('Designação atualizada com sucesso');
            });
          })
          .catch(_ => {
            this.UI.addToast('Erro de atualização de designação. Recarregue a página');
          });
      }
    });
  };

  canEditObservacoes = () => {
    return this.AuthorizationService.hasOne(['padNegociador']);
  };

  editObservacoes = () => {
    this.UI.showDialog({
      template: require('./edit.observacoes.dialog.html'),
      controller: [
        '$scope',
        $scope => {
          $scope.title = 'Editar observações';
          $scope.label = 'Observações';
          $scope.data = angular.copy(this.data.observacoes);

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          $scope.ok = () => {
            $scope.$close($scope.data);
          };
        }
      ]
    }).then(r => {
      if (r) {
        this.PadService.update(this.id, { observacoes: r })
          .then(_ => {
            this.PadService.estadoObservacoes(this.id, this.data.estadoId, angular.copy(this.data.observacoes)).then(a => {
              this.getProcesso();
              this.UI.addToast('Observações atualizadas com sucesso');
            });
          })
          .catch(_ => {
            this.UI.addToast('Erro de atualização de observações. Recarregue a página');
          });
      }
    });
  };

  canEditObservacoesNotaEncomenda = () => {
    return this.AuthorizationService.hasOne(['padNegociador']);
  };

  editObservacoesNotaEncomenda = () => {
    this.UI.showDialog({
      template: require('./edit.observacoes.dialog.html'),
      controller: [
        '$scope',
        $scope => {
          $scope.title = 'Editar observações para Nota de Encomenda';
          $scope.label = 'Observações';
          $scope.data = angular.copy(this.data.observacoesNotaEncomenda);

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          $scope.ok = () => {
            $scope.$close($scope.data);
          };
        }
      ]
    }).then(r => {
      if (r) {
        this.PadService.update(this.id, { observacoesNotaEncomenda: r }).then(_ => {
          this.PadService.estadoObservacoesNotaEncomenda(this.id, this.data.estadoId, angular.copy(this.data.observacoesNotaEncomenda)).then(a => {
            if (this.data.estadoId >= 12 && this.data.anexos && this.data.anexos.some(x => x.active === 1 && x.tipoId === 2)) {
              this.recreateNotaEncomenda(true); // ask to recreate and reload
            } else {
              this.getProcesso();
              this.UI.addToast('Observações atualizadas com sucesso');
            }
          });
        }).catch(_ => {
          this.UI.addToast('Erro de atualização de observações. Recarregue a página');
        });
      }
    });
  };

  getProcesso = () => {
    this.loaded = false;
    if (this.id) {
      this.PadProcesso.getPadProcesso({id: this.id}).$promise.then(res => {
        this.data = res;

        this.getLinhasProcessoForProcesso();
        this.getFornecedoresForProcesso();

        if (this.data.estadoId >= 12) {
          this.getObservacoesFinanceirasForProcesso();
        }

        // Exclude old Notas Encomenda (anexos tipo 2) from display
        if (res.anexos && res.anexos.length > 0) {
          res.anexos = res.anexos.filter(anexo => anexo.tipoId !== 2 || anexo.id === Math.max(...res.anexos.filter(a => a.tipoId === 2).map(a => a.id)));
        }
        // Moved here since it depends on anexos
        this.getNotificationsForNotasEncomenda();

        res.anexos.forEach(doc => {
          this.$http.get('/api/upload/pad/files/' + doc.nome).then(
            result => {
              if (result.data != null && !result.data.hasOwnProperty('error')) {
                doc.info = result.data;
              }
            },
            err => {
              console.log(err);
            }
          );
        });
        // If processo does not have fornecedor selecionado (for some unknown reason)
        if (this.data.fornecedorSelecionado) {
          this.loaded = true;
        } else {
          // Fill in the information now
          this.PadService.fixMissingFornecedorSelecionado(this.data.id)
            .then(() => {
              this.getProcesso();
            })
            .catch(error => {
              console.log(error);
              this.UI.addToast('Erro de atualização de fornecedor selecionado. Recarregue a página');
              this.$state.go('app.pad.processo.list');
            });
        }
      }).catch(err => {
        console.log(err);
        this.$state.go('app.pad.processo.list');
        if (err.status === 401) {
          this.UI.addToast('Não tem permissões para visualizar o processo.');
        } else {
          this.UI.addToast('Não foi possível obter esse processo. Verifique os dados');
        }
      });
    } else {
      this.UI.addToast('Não foi possível obter esse processo. Verifique os dados');
      this.$state.go('app.pad.processo.list');
    }
  };

  // Get Core Notification information for reports sent to the client and append it to reports object
  getNotificationsForNotasEncomenda = () => {
    if (this.data && this.data.anexos && this.data.anexos.length > 0) {
      for (let i = 0; i < this.data.anexos.length; i++) {
        if (this.data.anexos[i].tipoId === 2 && this.data.anexos[i].nome) {
          this.PadProcesso.getCoreNotifications({
            params: {
              numeroProcesso: this.data.numeroProcesso,
              nome: this.data.anexos[i].nome
            }
          })
            .$promise.then(res => {
              this.data.anexos[i].notifications = res.notifications;
              this.data.anexos[i].notificationsLoaded = true;
            })
            .catch(error => {
              console.log(error);
              this.data.anexos[i].notifications = null; // To show there's an error getting them.
              this.data.anexos[i].notificationsLoaded = true;
            });
        }
      }
    }
  };

  showEmailNotifications = anexo => {
    // If necessary, add more documento types
    if (!anexo || !anexo.notifications || anexo.notifications.length === 0) {
      this.UI.addToast('Sem notificações de envio');
      return;
    }

    let options = {
      size: 'lg',
      template: require('./email_notifications.dialog.html'),
      controller: [
        '$scope',
        '$dialog',
        ($scope, $dialog) => {
          $scope.label = 'Emails enviados com esta Nota de Encomenda - ' + this.data.numeroProcesso;
          $scope.anexo = anexo;

          // Date is stored as real date, so just output it directly
          $scope.realDateOutput = timestamp => {
            let validTimestamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

            if (!validTimestamp.test(timestamp)) {
              return '-';
            }

            // Split the date part into year, month, and day, and time
            let [datePart, timePart] = timestamp.split('T');
            let [year, month, day] = datePart.split('-');
            // Return the formatted timestamp string
            return `${day}/${month}/${year} ${timePart.substring(0, 5)}`;
          };

          $scope.ok = () => {
            $dialog.close();
          };
        }
      ]
    };

    let dialog = this.UI.showDialog(options);
    dialog
      .then(ok => {
        // Do nothing
      })
      .catch(() => {});
  };

  generateUUID = () => {
    let uuid = '',
      i,
      random;
    for (i = 0; i < 32; i++) {
      random = (Math.random() * 16) | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += '-';
      }
      uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
    }
    return uuid;
  };

  // Format bytes into correct units
  formatBytes = (bytes, decimals) => {
    if (bytes == null) return 'N/D';
    if (bytes === 0) return '0 Bytes';
    let k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  toggleEstadosProcesso = () => {
    if (this.showEstadosProcesso) {
      this.showEstadosProcesso = false;
    } else {
      if (this.showEstadosLinhaProcesso) {
        // If the other aside is open, close it first
        this.toggleEstadosLinhaProcesso();
      }
      this.showEstadosProcesso = true;
    }
  };

  toggleEstadosLinhaProcesso = x => {
    if (this.showEstadosLinhaProcesso) {
      this.showEstadosLinhaProcesso = false;
      this.selectedLinha = null;
    } else {
      if (this.showEstadosProcesso) {
        // If the other aside is open, close it first
        this.toggleEstadosProcesso();
      }
      this.showEstadosLinhaProcesso = true;
      this.selectedLinha = x;
    }
  };

  // Linhas de Processo
  getLinhasProcessoForProcesso = () => {
    this.linhasProcessoLoading = true;
    let whereLiteral = {
      'PadLinhaProcesso.processoId': this.id
    };
    let where = {};
    Object.keys(this.optLinhasProcesso.filter).forEach(f => {
      if (this.optLinhasProcesso.filter[f]) where[f] = this.optLinhasProcesso.filter[f];
    });
    this.PadLinhaProcesso.table({
      params: {
        fields: [
          'PadLinhaProcesso.id as id',
          'PadLinhaProcesso.processoId as processoId',
          'PadLinhaProcesso.estadoId as estadoId',
          'PadLinhaProcesso.quantidade as quantidade',
          'PadLinhaProcesso.caracteristicas as caracteristicas',
          'PadLinhaProcesso.precoUnitarioContratado as precoUnitarioContratado',
          'PadLinhaProcesso.precoTotalContratado as precoTotalContratado',
          'PadLinhaProcesso.observacoes as observacoes',
          'PadLinhaProcesso.cabimento as cabimento',
          'PadCentroCusto.designacao as centroCusto',
          'PadArtigo.designacao as artigo',
          'PadEstadoLinhasProcesso.designacao as estado'
        ],
        from: ['PadLinhaProcesso', 'PadCentroCusto', 'PadArtigo', 'PadEstadoLinhasProcesso'],
        references: [undefined, 'PadLinhaProcesso.centroCustoId', 'PadLinhaProcesso.artigoId', 'PadLinhaProcesso.estadoId'],
        where: where,
        whereLiteral: whereLiteral,
        order: this.optLinhasProcesso.order,
        sort: this.optLinhasProcesso.sort,
        limit: this.optLinhasProcesso.items,
        skip: (this.optLinhasProcesso.page - 1) * this.optLinhasProcesso.items
      }
    })
      .$promise.then(res => {
        let page = this.optLinhasProcesso.page;
        let items = this.optLinhasProcesso.items;

        let total = res.count;

        this.optLinhasProcesso.start = total > 0 ? (page - 1) * items + 1 : 0;
        if (this.optLinhasProcesso.start - 1 + items >= total) {
          this.optLinhasProcesso.end = total;
        } else {
          this.optLinhasProcesso.end = Number.parseInt(this.optLinhasProcesso.start - 1 + items);
        }

        this.linhasProcesso = res.data;
        this.optLinhasProcesso.total = total;

        // Process sub-data
        let tasks = [];
        this.linhasProcesso.forEach(x => {
          let defer = this.$q.defer();
          this.PadLinhaProcessoEstado.find({
            filter: {
              where: {
                linhaProcessoId: x.id
              },
              order: 'id DESC',
              include: [
                {
                  relation: 'estado',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                },
                {
                  relation: 'criadoPor',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }
              ]
            }
          })
            .$promise.then(estados => {
              x.estados = estados;
              defer.resolve();
            })
            .catch(error => {
              defer.reject(error);
            });
          tasks.push(defer.promise);
        });

        this.$q
          .all(tasks)
          .then(() => {
            this.linhasProcessoLoading = false;
          })
          .catch(e => {
            // Do something when at least one count fails
            console.log(e);
            this.UI.addToast('Erro ao carregar estados de linhas de processo. Verifique a ligação');
            this.linhasProcessoLoading = false;
          });
      })
      .catch(e => {
        console.log(e);
        this.UI.addToast('Não foi possível ler linhas de processo. Verifique a ligação');
      });
  };

  linhasProcessoSort = keyname => {
    if (this.optLinhasProcesso.order === keyname) this.optLinhasProcesso.page = 1;
    this.optLinhasProcesso.order = keyname;
    this.optLinhasProcesso.sort = this.optLinhasProcesso.sort === 'asc' ? 'desc' : 'asc';
    this.getLinhasProcessoForProcesso();
  };

  linhasProcessoItem = val => {
    this.optLinhasProcesso.items = val;
    this.optLinhasProcesso.page = 1;
    this.getLinhasProcessoForProcesso();
  };

  linhasProcessoPage = sum => {
    this.optLinhasProcesso.page += sum;
    if (this.optLinhasProcesso.page < 1) this.optLinhasProcesso.page = 1;
    if (this.optLinhasProcesso.page > Math.ceil(this.optLinhasProcesso.total / this.optLinhasProcesso.items))
      this.optLinhasProcesso.page = Math.ceil(this.optLinhasProcesso.total / this.optLinhasProcesso.items);
    this.getLinhasProcessoForProcesso();
  };

  // Fornecedores
  getFornecedoresForProcesso = () => {
    this.fornecedoresLoading = true;
    let whereLiteral = {
      'PadProcessoFornecedor.processoId': this.id
    };
    let where = {};
    Object.keys(this.optFornecedores.filter).forEach(f => {
      if (this.optFornecedores.filter[f]) where[f] = this.optFornecedores.filter[f];
    });
    this.PadProcessoFornecedor.table({
      params: {
        fields: [
          'PadProcessoFornecedor.id as id',
          'PadProcessoFornecedor.processoId as processoId',
          'PadProcessoFornecedor.fornecedorId as fornecedorId',
          'PadProcessoFornecedor.fornecedorPrimaveraId as fornecedorPrimaveraId',
          'PadProcessoFornecedor.totalPreNegocial as totalPreNegocial',
          'PadProcessoFornecedor.total as total',
          'PadCondicaoPagamento.descricao as condicaoPagamento',
          'PadProcessoFornecedor.prazo as prazo',
          'PadProcessoFornecedor.email as email',
          'PadProcessoFornecedor.refProposta as refProposta',
          'AtvFornecedor.nome as fornecedor',
          'PadProcessoFornecedorAnexo.nome as uuid',
          'AtvFornecedorPrimavera.padAvaliacoesMedia as padAvaliacoesMedia',
          'AtvFornecedorPrimavera.padAvaliacoes as padAvaliacoes'
        ],
        from: ['PadProcessoFornecedor', 'AtvFornecedor', 'PadCondicaoPagamento', 'PadProcessoFornecedorAnexo', 'AtvFornecedorPrimavera'],
        referencesOrigin: [undefined, undefined, undefined, 'PadProcessoFornecedorAnexo.processoFornecedorId', undefined],
        references: [
          undefined,
          'PadProcessoFornecedor.fornecedorId',
          'PadProcessoFornecedor.condicaoPagamentoId',
          'PadProcessoFornecedor.id',
          'PadProcessoFornecedor.fornecedorPrimaveraId'
        ],
        where: where,
        whereLiteral: whereLiteral,
        order: this.optFornecedores.order,
        sort: this.optFornecedores.sort,
        limit: this.optFornecedores.items,
        skip: (this.optFornecedores.page - 1) * this.optFornecedores.items
      }
    })
      .$promise.then(res => {
        let page = this.optFornecedores.page;
        let items = this.optFornecedores.items;

        let total = res.count;

        this.optFornecedores.start = total > 0 ? (page - 1) * items + 1 : 0;
        if (this.optFornecedores.start - 1 + items >= total) {
          this.optFornecedores.end = total;
        } else {
          this.optFornecedores.end = Number.parseInt(this.optFornecedores.start - 1 + items);
        }

        // Process sub-data
        res.data.forEach(x => {});

        this.fornecedores = res.data;
        this.optFornecedores.total = total;
        this.fornecedoresLoading = false;
      })
      .catch(e => {
        console.log(e);
        this.UI.addToast('Não foi possível ler fornecedores. Verifique a ligação');
      });
  };

  fornecedoresSort = keyname => {
    if (this.optFornecedores.order === keyname) this.optFornecedores.page = 1;
    this.optFornecedores.order = keyname;
    this.optFornecedores.sort = this.optFornecedores.sort === 'asc' ? 'desc' : 'asc';
    this.getFornecedoresForProcesso();
  };

  fornecedoresItem = val => {
    this.optFornecedores.items = val;
    this.optFornecedores.page = 1;
    this.getFornecedoresForProcesso();
  };

  fornecedoresPage = sum => {
    this.optFornecedores.page += sum;
    if (this.optFornecedores.page < 1) this.optFornecedores.page = 1;
    if (this.optFornecedores.page > Math.ceil(this.optFornecedores.total / this.optFornecedores.items))
      this.optFornecedores.page = Math.ceil(this.optFornecedores.total / this.optFornecedores.items);
    this.getFornecedoresForProcesso();
  };

  // Observações Financeiras
  getObservacoesFinanceirasForProcesso = () => {
    this.observacoesFinanceirasLoading = true;
    let whereLiteral = {
      'PadProcessoObservacaoFinanceira.processoId': this.id,
      'PadProcessoObservacaoFinanceira.active': 1
    };
    let where = {};
    Object.keys(this.optObservacoesFinanceiras.filter).forEach(f => {
      if (this.optObservacoesFinanceiras.filter[f]) where[f] = this.optObservacoesFinanceiras.filter[f];
    });
    this.PadProcessoObservacaoFinanceira.table({
      params: {
        fields: [
          'PadProcessoObservacaoFinanceira.id as id',
          'PadProcessoObservacaoFinanceira.processoId as processoId',
          'PadProcessoObservacaoFinanceira.observacao as observacao',
          'PadProcessoObservacaoFinanceira.dataCriacao as dataCriacao',
          'PadProcessoObservacaoFinanceira.atualizadoa as atualizadoa',
          'PadProcessoObservacaoFinanceira.criadoPorId as criadoPorId',
          'PadProcessoObservacaoFinanceira.atualizadoPorId as atualizadoPorId',
          'Funcionario.name as criadoPor',
          'Funcionario2.name as atualizadoPor'
        ],
        from: ['PadProcessoObservacaoFinanceira', 'PadProcesso', 'Funcionario', 'Funcionario'],
        references: [
          undefined,
          'PadProcessoObservacaoFinanceira.processoId',
          'PadProcessoObservacaoFinanceira.criadoPorId',
          'PadProcessoObservacaoFinanceira.atualizadoPorId'
        ],
        aliases: [undefined, undefined, undefined, 'Funcionario2'],
        where: where,
        whereLiteral: whereLiteral,
        order: this.optObservacoesFinanceiras.order,
        sort: this.optObservacoesFinanceiras.sort,
        limit: this.optObservacoesFinanceiras.items,
        skip: (this.optObservacoesFinanceiras.page - 1) * this.optObservacoesFinanceiras.items
      }
    })
      .$promise.then(res => {
        let page = this.optObservacoesFinanceiras.page;
        let items = this.optObservacoesFinanceiras.items;

        let total = res.count;

        this.optObservacoesFinanceiras.start = total > 0 ? (page - 1) * items + 1 : 0;
        if (this.optObservacoesFinanceiras.start - 1 + items >= total) {
          this.optObservacoesFinanceiras.end = total;
        } else {
          this.optObservacoesFinanceiras.end = Number.parseInt(this.optObservacoesFinanceiras.start - 1 + items);
        }

        // Process sub-data
        // res.data.forEach(x => {
        //
        // });

        this.observacoesFinanceiras = res.data;
        this.optObservacoesFinanceiras.total = total;
        this.observacoesFinanceirasLoading = false;
      })
      .catch(e => {
        console.log(e);
        this.UI.addToast('Não foi possível ler observações financeiras. Verifique a ligação');
      });
  };

  observacoesFinanceirasSort = keyname => {
    if (this.optObservacoesFinanceiras.order === keyname) this.optObservacoesFinanceiras.page = 1;
    this.optObservacoesFinanceiras.order = keyname;
    this.optObservacoesFinanceiras.sort = this.optObservacoesFinanceiras.sort === 'asc' ? 'desc' : 'asc';
    this.getObservacoesFinanceirasForProcesso();
  };

  observacoesFinanceirasItem = val => {
    this.optObservacoesFinanceiras.items = val;
    this.optObservacoesFinanceiras.page = 1;
    this.getObservacoesFinanceirasForProcesso();
  };

  observacoesFinanceirasPage = sum => {
    this.optObservacoesFinanceiras.page += sum;
    if (this.optObservacoesFinanceiras.page < 1) this.optObservacoesFinanceiras.page = 1;
    if (this.optObservacoesFinanceiras.page > Math.ceil(this.optObservacoesFinanceiras.total / this.optObservacoesFinanceiras.items))
      this.optObservacoesFinanceiras.page = Math.ceil(this.optObservacoesFinanceiras.total / this.optObservacoesFinanceiras.items);
    this.getObservacoesFinanceirasForProcesso();
  };

  addObservacaoFinanceira = () => {
    let options = {
      template: require('./edit.observacoes.dialog.html'),
      controller: [
        '$scope',
        $scope => {
          $scope.title = 'Observações Financeiras';
          $scope.label = 'Observações';

          // Data to change on the modal
          $scope.data = null;

          $scope.ok = function () {
            // Send scope values for processing
            $scope.$close($scope);
          };

          $scope.cancel = function () {
            $scope.$dismiss('cancel');
          };
        }
      ]
    };

    let modal = this.UI.showDialog(options);

    modal
      .then(ok => {
        this.observacoesFinanceirasLoading = true;
        if (ok && ok.data && ok.data.length > 0) {
          // Create data
          let dataObservacao = moment().format();
          let observacao = {
            id: 0,
            observacao: ok.data,
            processoId: this.id,
            criadoPorId: this.user.id,
            dataCriacao: dataObservacao,
            atualizadoPorId: this.user.id,
            atualizadoa: dataObservacao
          };
          this.PadProcessoObservacaoFinanceira.create(observacao)
            .$promise.then(() => {
              this.UI.addToast('Observações criadas com sucesso');
              this.getObservacoesFinanceirasForProcesso();
            })
            .catch(error => {
              console.log(error);
              this.UI.addToast('Erro de criação de observação financeira. Verifique a ligação');
              this.getObservacoesFinanceirasForProcesso();
            });
        } else {
          this.UI.addToast('Erro de criação de observação financeira. Verifique a ligação');
          this.getObservacoesFinanceirasForProcesso();
        }
      })
      .catch(() => {});
  };

  editObservacaoFinanceira = o => {
    let options = {
      template: require('./edit.observacoes.dialog.html'),
      controller: [
        '$scope',
        $scope => {
          $scope.title = 'Observações Financeiras';
          $scope.label = 'Observações';

          // Data to change on the modal
          $scope.data = angular.copy(o.observacao);

          $scope.ok = function () {
            // Send scope values for processing
            $scope.$close($scope);
          };

          $scope.cancel = function () {
            $scope.$dismiss('cancel');
          };
        }
      ]
    };

    let modal = this.UI.showDialog(options);

    modal
      .then(ok => {
        this.observacoesFinanceirasLoading = true;
        if (ok && ok.data && ok.data.length > 0) {
          // Update data
          o.observacao = ok.data;
          o.atualizadoPorId = this.user.id;
          o.atualizadoa = moment().format();
          delete o.atualizadoPor;
          delete o.criadoPor;
          this.PadProcessoObservacaoFinanceira.upsert(o)
            .$promise.then(() => {
              this.UI.addToast('Observações editadas com sucesso');
              this.getObservacoesFinanceirasForProcesso();
            })
            .catch(error => {
              console.log(error);
              this.UI.addToast('Erro de edição de observação financeira. Verifique a ligação');
              this.getObservacoesFinanceirasForProcesso();
            });
        } else {
          this.UI.addToast('Erro de edição de observação financeira. Verifique a ligação');
          this.getObservacoesFinanceirasForProcesso();
        }
      })
      .catch(() => {});
  };

  confirmDeleteObservacaoFinanceira = o => {
    let title = 'Eliminar Observação Financeira';
    let warning = 'Tem a certeza que pretende remover esta observação?';
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: [
        '$scope',
        function ($scope) {
          $scope.title = title;
          $scope.message = warning;
          $scope.ok = function () {
            $scope.$close();
          };
          $scope.cancel = function () {
            $scope.$dismiss('cancel');
          };
        }
      ]
    });

    instance.then(
      () => {
        this.removeObservacaoFinanceira(o);
      },
      err => {
        if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click') console.log(err);
      }
    );
  };

  removeObservacaoFinanceira = o => {
    this.observacoesFinanceirasLoading = true;
    // Find again tamanho to delete so we can update it
    this.PadProcessoObservacaoFinanceira.findById({ id: o.id })
      .$promise.then(observacao => {
        observacao.active = 0;
        observacao
          .$save()
          .then(res => {
            this.UI.addToast('Observação eliminada com sucesso');
            this.getObservacoesFinanceirasForProcesso();
          })
          .catch(error => {
            this.UI.addToast('Erro ao eliminar observação. Verifique se ainda existe.');
            console.log(error);
            this.getObservacoesFinanceirasForProcesso();
          });
      })
      .catch(error => {
        console.log(error);
        this.UI.addToast('Não foi possível eliminar o tamanho. Verifique se ainda existe.');
        this.getObservacoesFinanceirasForProcesso();
      });
  };

  hasAnexos = () => this.data && this.data.anexos && this.data.anexos.length > 0;

  addAnexo = () => {
    let options = {
      size: 'lg',
      controller: 'PadProcessoFileUploaderController',
      controllerAs: 'm',
      template: require('./files.dialog.html'),
      resolve: {
        pID: () => {
          return this.data.id;
        },
        file: () => {
          return undefined;
        }
      }
    };
    let dialog = this.UI.showDialog(options);
    dialog.then(res => {
      if (res) {
        this.getProcesso();
        this.UI.addToast('Ficheiro carregado com sucesso');
      }
    });
  };

  removeAnexo = file => {
    if (!file.tipo.id || !this.removableFileTypeIndexes.includes(file.tipo.id)) {
      this.UI.showAlert('Não é possível remover documentos deste tipo.');
      return;
    }
    let confirm = this.UI.showConfirm('Deseja remover o ficheiro?');
    confirm.then(res => {
      if (res) {
        file.active = 0;
        this.PadProcessoAnexo.upsert(file)
          .$promise.then(() => {
            this.getProcesso();
            this.UI.addToast('O ficheiro foi removido com sucesso');
          })
          .catch(error => {
            console.log(error);
            this.UI.addToast('Erro ao remover ficheiro. Verifique a ligação.');
          });
      }
    });
  };

  addImage = () => {
    let options = {
      size: 'lg',
      template: require('./images.dialog.html'),
      controller: [
        '$scope',
        '$dialog',
        ($scope, $dialog) => {
          $scope.images = {};
          $scope.label = 'Adicionar imagem';
          $scope.newFileName = '';

          $scope.uuid = this.generateUUID();

          $scope.uploader = new this.FileUploader({
            url: '/api/Upload/pad/upload',
            queueLimit: 1,
            formData: [
              {
                key: 'value'
              }
            ]
          });

          let filter = {
            name: 'verifyIMG',
            fn: function (item, options) {
              return item.type.indexOf('image/') !== -1;
            }
          };
          $scope.uploader.filters.push(filter);

          $scope.uploader.onAfterAddingFile = item => {
            let partes = item.file.name.split('.');
            $scope.newFileName = $scope.uuid + '.' + partes[partes.length - 1];

            if ($scope.uploader.queue.length > 1) $scope.uploader.queue.splice(0, $scope.uploader.queue.splice.length - 1);
            $scope.oldFileName = $scope.uploader.queue[0].file.name;
          };

          $scope.uploader.onWhenAddingFileFailed = img => {
            this.UI.addToast('Por favor, carregue uma imagem');
          };

          $scope.uploader.onErrorItem = (response, status, headers) => {
            this.UI.addToast('A imagem não foi carregada com sucesso');
          };

          $scope.uploader.onBeforeUploadItem = item => {
            item.file.name = $scope.newFileName;
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }
      ]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate
      .then(ok => {
        this.PadProcessoAnexo.create({
          id: 0,
          processoId: this.data.id,
          tipoId: 4, // Imagem
          nome: ok.newFileName,
          active: 1
        })
          .$promise.then(res => {
            if (ok.newFileName) {
              // File exists, upload it
              ok.uploader.uploadAll();
              this.getProcesso();
            } else {
              // No file, just update the list
              this.getProcesso();
            }
            this.UI.addToast('Imagem adicionada com sucesso');
          })
          .catch(err => {
            this.UI.addToast('Erro a adicionar imagem.');
            console.log(err);
          });
      })
      .catch(error => {
        if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click') console.log(error);
      });
  };

  sendToEmail = documento => {
    // If necessary, add more documento types
    if (documento.tipo.id !== 2) {
      this.UI.addToast('Não é possível enviar email deste tipo de documento');
      return;
    }

    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./email.dialog.html'),
      controller: [
        '$scope',
        '$dialog',
        ($scope, $dialog) => {
          $scope.label = 'Emails para envio de ' + (documento.tipo.id === 2 ? 'Nota de Encomenda' : 'Ficheiro');
          $scope.emails = [];
          $scope.hasAnexoNotaEncomenda = this.data.anexos.findIndex(x => x.tipoId === 5) !== -1; // Anexo à Nota de Encomenda
          $scope.sendAnexoNotaEncomenda = { check: $scope.hasAnexoNotaEncomenda };

          $scope.validEmail = email => {
            const re =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
          };

          if ($scope.validEmail(this.data.fornecedorSelecionado.email))
            $scope.emails.push({ id: 1, selected: false, description: 'Fornecedor', email: this.data.fornecedorSelecionado.email });
          if ($scope.validEmail(this.data.criadoPor.email)) $scope.emails.push({ id: 2, selected: false, description: 'Requisitante', email: this.data.criadoPor.email });
          $scope.emails.push({ id: 3, selected: false, description: 'Outro(s)', email: null });

          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };

          $scope.hasSelection = () => $scope.emails.some(x => x.selected);

          $scope.ok = () => {
            // Check if Outro is selected, and if it is, if email is valid, otherwise return selection
            let outro = $scope.emails.find(x => x.id === 3);
            if (outro.selected) {
              if (outro.email && outro.email.length > 0) {
                // Check if there are multiple emails by splitting the email string
                let emails = outro.email.split(/[,;]/).map(email => email.trim()).filter(email => email);

                // Validate each email in the list
                let invalidEmails = emails.filter(email => !$scope.validEmail(email));

                if (invalidEmails.length > 0) {
                  // If any email is invalid, show a toast message
                  this.UI.addToast("Um ou mais emails no campo Outro são inválidos");
                } else {
                  // If all emails are valid, remove the original Outro email from $scope.emails
                  $scope.emails = $scope.emails.filter(x => x.id !== 3);

                  // Add valid emails to the list
                  emails.forEach(email => {
                    // Check if email already exists in $scope.emails
                    if (!$scope.emails.some(x => x.selected && x.email === email)) {
                      $scope.emails.push({
                        id: null, // No need for ID
                        email: email,
                        selected: true
                      });
                    }
                  });

                  // Close the dialog with selected emails
                  $dialog.close({
                    sendAnexoNotaEncomenda: $scope.sendAnexoNotaEncomenda.check,
                    emails: $scope.emails.filter(x => x.selected)
                  });
                }
              } else {
                this.UI.addToast("Outro Email selecionado, mas não preenchido");
              }
            } else {
              $dialog.close({
                sendAnexoNotaEncomenda: $scope.sendAnexoNotaEncomenda.check,
                emails: $scope.emails.filter(x => x.selected)
              });
            }
          };
        }
      ]
    });
    dialog.then(ok => {
      let wait = this.UI.showWaiting();
      let tasks = [];
      if (documento.tipo.id === 2) {
        // Nota de Encomenda
        ok.emails.forEach(email => {
          let defer = this.$q.defer();
          this.PadProcesso.notifyNotaEncomenda({
            params: {
              processoId: this.id,
              submittedEmail: email.email,
              sendAnexoNotaEncomenda: ok.sendAnexoNotaEncomenda,
              documentoId: documento.id
            }
          })
            .$promise.then(r => {
              this.UI.addToast('Email com Nota de Encomenda enviado para ' + email.email);
              defer.resolve(r);
            })
            .catch(error => {
              console.log(error);
              this.UI.addToast('Erro no envio de Nota de Encomenda para ' + email.email + '. Verifique a ligação');
              defer.reject(error);
            });
          tasks.push(defer.promise);
        });
        this.$q
          .all(tasks)
          .then(res => {
            wait.close();
            this.getProcesso();
          })
          .catch(error => {
            wait.close();
            console.log(error);
          });
      }
    });
  };

  cancelProcesso = () => {
    this.PadService.cancelMultipleProcessos([{ id: this.data.id, estadoId: this.data.estadoId }])
      .then(() => {
        this.getProcesso();
      })
      .catch(() => {
        this.getProcesso();
      });
  };

  outputCabimento = cabimento => {
    if (cabimento == null) return '—';
    if (cabimento === 1) return 'Sim';
    if (cabimento === 0) return 'Não';
  };

  editAlternativo = () => {
    let wait = this.UI.showWaiting();
    this.Funcionario.find({
      filter: {
        where: {
          active: true
        },
        order: 'name ASC'
      }
    })
      .$promise.then(funcionarios => {
        funcionarios.sort((a, b) => a.name.localeCompare(b.name));
        wait.close();
        let options = {
          template: require('./alternativo.dialog.html'),
          controller: [
            '$scope',
            '$dialog',
            ($scope, $dialog) => {
              $scope.label = 'Editar Colaborador Alternativo para Processo ' + this.data.numeroProcesso;
              $scope.funcionarios = funcionarios;

              $scope.auxAlternativo = {
                selected: angular.copy(this.data.alternativo),
                infiniteScroll: { numToAdd: 20, currentItems: 20 }
              };

              // Infinite Scroll magic
              $scope.addMoreItems = infiniteScroll => {
                infiniteScroll.currentItems += infiniteScroll.numToAdd;
              };

              $scope.ok = () => {
                $dialog.close($scope);
              };

              $scope.cancel = () => {
                $dialog.dismiss('cancel');
              };
            }
          ]
        };
        let modal = this.UI.showDialog(options);

        modal
          .then(ok => {
            wait = this.UI.showWaiting();
            this.PadProcesso.findById({ id: this.id })
              .$promise.then(processo => {
                if (ok.auxAlternativo.selected) {
                  processo.alternativoId = ok.auxAlternativo.selected.id;
                } else {
                  processo.alternativoId = null;
                }
                this.PadProcesso.upsert(processo)
                  .$promise.then(p => {
                    wait.close();
                    this.UI.addToast('Colaborador definido com sucesso');
                    this.getProcesso();
                  })
                  .catch(error => {
                    wait.close();
                    console.log(error);
                    this.UI.addToast('Erro ao guardar permissões para processo PAD. Verifique a ligação');
                    this.getProcesso();
                  });
              })
              .catch(error => {
                wait.close();
                console.log(error);
                this.UI.addToast('Erro na busca de processo PAD. Verifique a ligação');
                this.getProcesso();
              });
          })
          .catch(() => {});
      })
      .catch(error => {
        console.log(error);
        this.UI.addToast('Erro na procura de Colaboradores. Verifique a ligação.');
      });
  };

  // Check if processo is Adjudicado and linha is Concluída (approved). Also, must have permissions to do so
  canChangePrecoContratado = linha => {
    if (this.data && linha && this.data.estadoId <= 12 && (linha.estadoId <= 9 || linha.estadoId === 12)) {
      return !!(
        this.data.criadoPorId === this.user.id ||
        this.data.alternativoId === this.user.id ||
        this.AuthorizationService.canPerform('padNegociador')
      );
    } else {
      return false;
    }
  };

  // Only allow negociador to change this
  canChangeCondicoesPagamento = () => {
    if (this.data && this.data.estadoId === 12) {
      return !!this.AuthorizationService.canPerform('padNegociador');
    } else {
      return false;
    }
  };

  canRecreateNotaEncomenda = () => {
    if (this.data && this.data.estadoId === 12) {
      return !!this.AuthorizationService.canPerform('padNegociador');
    } else {
      return false;
    }
  };

  canAddFornecedor = () => {
    if (this.data && this.data.estadoId <= 12) {
      return !!this.AuthorizationService.canPerform('padNegociador');
    } else {
      return false;
    }
  };

  canChangeFornecedorSelecionado = () => {
    if (this.data && this.data.estadoId <= 12) {
      return !!this.AuthorizationService.canPerform('padNegociador');
    } else {
      return false;
    }
  };

  canChangeCaracteristicas = linha => {
    if (this.data && linha && this.data.estadoId <= 12 && (linha.estadoId <= 9 || linha.estadoId === 12)) {
      return !!this.AuthorizationService.canPerform('padNegociador');
    } else {
      return false;
    }
  };

  editCondicoesPagamento = () => {
    if (this.data.fornecedorSelecionado && this.data.fornecedorSelecionado.condicaoPagamento) {
      // Get condições pagamento
      let PRICondpag = this.PadService.getPrimaveraCondicaoPagamento(this.data.entidadeProprietariaId);
      PRICondpag.find({
        filter: {
          order: 'condpag ASC'
        }
      })
        .$promise.then(condicoesPagamento => {
          // Show dialog
          let options = {
            size: 'lg',
            template: require('./edit.condicoes.pagamento.dialog.html'),
            controller: [
              '$dialog',
              '$scope',
              ($dialog, $scope) => {
                $scope.label = 'Alteração de Condições de Pagamento para Processo';
                $scope.fornecedorSelecionado = angular.copy(this.data.fornecedorSelecionado);
                $scope.condicoesPagamento = condicoesPagamento;

                $scope.auxCondicaoPagamento = {
                  selected: $scope.condicoesPagamento.find(c => c.condpag === $scope.fornecedorSelecionado.condicaoPagamento.condpag),
                  infiniteScroll: { numToAdd: 20, currentItems: 20 }
                };

                //Infinite Scroll Magic
                $scope.addMoreItems = infiniteScroll => {
                  infiniteScroll.currentItems += infiniteScroll.numToAdd;
                };

                $scope.ok = () => {
                  $dialog.close($scope);
                };

                $scope.cancel = () => {
                  $dialog.dismiss('cancel');
                };
              }
            ]
          };
          let modal = this.UI.showDialog(options);

          modal
            .then(ok => {
              if (this.data.fornecedorSelecionado.condicaoPagamento.id) {
                let wait = this.UI.showWaiting();
                this.PadService.getCondicaoPagamentoId(ok.auxCondicaoPagamento.selected, this.data.entidadeProprietariaId)
                  .then(condicaoPagamentoId => {
                    this.PadLinhaProcesso.changeCondicaoPagamento({
                      params: {
                        fornecedorSelecionadoId: this.data.fornecedorSelecionadoId,
                        condicaoPagamentoId: condicaoPagamentoId,
                        prazo: ok.fornecedorSelecionado.prazo
                      }
                    })
                      .$promise.then(data => {
                        wait.close();
                        if (data.changed) {
                          this.UI.addToast('Condições de Pagamento alteradas com sucesso');
                          this.getProcesso();
                        } else {
                          this.UI.addToast('Sem alterações registadas');
                        }
                      })
                      .catch(error => {
                        console.log(error);
                        wait.close();
                        let errorMessage = 'Não foi possível alterar as condições de pagamento do processo.';
                        if (error.data && error.data.error && error.data.error.message) errorMessage += `\n${error.data.error.message}`;
                        this.UI.showAlert(errorMessage);
                      });
                  })
                  .catch(error => {
                    wait.close();
                    console.log(error);
                    this.UI.addToast('Erro na alteração de condições de pagamento. Verifique a ligação');
                  });
              } else {
                this.UI.addToast('Sem alterações a efetuar');
              }
            })
            .catch(() => {});
        })
        .catch(error => {
          console.log(error);
          this.UI.addToast('Erro na obtenção de condições de pagamento. Verifique a ligação');
        });
    } else {
      this.UI.addToast('Erro na obtenção de informação de fornecedor/condição de pagamento. Recarregue a página');
    }
  };

  getChangePrecoString = linha =>
    linha &&
    linha.estadoId === 3 &&
    this.data.nivel1AvaliadoPorId != null &&
    this.data.estadoId === 2 &&
    this.AuthorizationService.canPerform('padNegociador')
      ? 'Alterar Preço'
      : 'Reduzir Preço';

  changePrecoContratado = linha => {
    // Show dialog
    let options = {
      size: 'lg',
      template: require('./edit.preco.dialog.html'),
      controller: [
        '$dialog',
        '$scope',
        ($dialog, $scope) => {
          $scope.isNegociacao =
            linha &&
            linha.estadoId === 3 &&
            this.data.nivel1AvaliadoPorId != null &&
            this.data.estadoId === 2 &&
            this.AuthorizationService.canPerform('padNegociador');
          $scope.label = `${$scope.isNegociacao ? 'Alteração' : 'Redução'} de Preço de Linha de Processo`;
          $scope.oldLinha = linha;
          $scope.linha = {
            quantidade: linha.quantidade,
            precoUnitarioContratado: linha.precoUnitarioContratado,
            precoTotalContratado: linha.precoTotalContratado
          };

          $scope.updateTotal = () => {
            if ($scope.linha.precoUnitarioContratado === undefined || $scope.linha.quantidade === undefined) return;
            if ($scope.linha.precoUnitarioContratado >= 0 && $scope.linha.quantidade >= 0)
              $scope.linha.precoTotalContratado = Math.round($scope.linha.precoUnitarioContratado * $scope.linha.quantidade * 100) / 100;
            else $scope.linha.precoTotalContratado = 0;
          };

          $scope.ok = () => {
            if ($scope.linha.precoUnitarioContratado > 0) {
              $dialog.close($scope);
            } else {
              this.UI.addToast('Preço unitário tem que ser superior a 0');
            }
          };

          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }
      ]
    };
    let modal = this.UI.showDialog(options);

    modal
      .then(ok => {
        if (ok.linha.precoUnitarioContratado !== linha.precoUnitarioContratado) {
          let wait = this.UI.showWaiting();
          // Create remote method to deal with this
          this.PadLinhaProcesso.changePreco({
            params: {
              linhaId: linha.id,
              linha: ok.linha
            }
          })
            .$promise.then(data => {
              wait.close();
              this.UI.addToast('Preço contratado alterado com sucesso');
              this.getProcesso();
            })
            .catch(error => {
              console.log(error);
              wait.close();
              let errorMessage = 'Não foi possível alterar o valor da linha de processo.';
              if (error.data && error.data.error && error.data.error.message) errorMessage += `\n${error.data.error.message}`;
              this.UI.showAlert(errorMessage);
            });
        } else {
          this.UI.addToast('Não foram efetuadas alterações');
        }
      })
      .catch(() => {});
  };

  changeCaracteristicas = linha => {
    // Show dialog
    let options = {
      size: 'lg',
      template: require('./edit.caracteristicas.dialog.html'),
      controller: [
        '$dialog',
        '$scope',
        ($dialog, $scope) => {
          $scope.label = 'Edição de Características de Linha de Processo';
          $scope.caracteristicas = angular.copy(linha.caracteristicas);

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }
      ]
    };
    let modal = this.UI.showDialog(options);

    modal
      .then(ok => {
        if (ok.caracteristicas !== linha.caracteristicas) {
          let wait = this.UI.showWaiting();
          this.PadLinhaProcesso.changeCaracteristicas({
            params: {
              linhaId: linha.id,
              caracteristicas: ok.caracteristicas
            }
          })
            .$promise.then(data => {
              wait.close();
              if (data.changed) {
                this.UI.addToast('Características alteradas com sucesso');
                this.getProcesso();
              } else {
                this.UI.addToast('Sem alterações registadas');
              }
            })
            .catch(error => {
              console.log(error);
              wait.close();
              let errorMessage = 'Não foi possível alterar as características da linha do processo.';
              if (error.data && error.data.error && error.data.error.message) errorMessage += `\n${error.data.error.message}`;
              this.UI.showAlert(errorMessage);
            });
        } else {
          this.UI.addToast('Sem alterações registadas');
        }
      })
      .catch(() => {});
  };

  // Optional reload field (to use when adding observacoes nota encomenda)
  recreateNotaEncomenda = (reload) => {
    let confirm = this.UI.showConfirm('Deseja recriar a nota de encomenda para este processo?');
    confirm
      .then(() => {
        // OK
        let wait = this.UI.showWaiting();
        this.PadProcesso.recreateNotaEncomenda({
          params: {
            processoId: this.data.id
          }
        })
          .$promise.then(() => {
            wait.close();
            this.UI.addToast('Nota de Encomenda recriada com sucesso');
            this.getProcesso();
          })
          .catch(error => {
            wait.close();
            console.log(error);
            this.UI.addToast('Erro na criação de nota de encomenda. Verifique a ligação');
            if (reload) this.getProcesso();
          });
      })
      .catch(() => {
        // Cancel
        if (reload) this.getProcesso();
      });
  };

  // Adicionar um novo fornecedor para processo
  addFornecedor = () => {
    // If Tipo Despesa == 1 (Operacional), FX* , else FI*
    let tipoDespesaFornecedor = this.data.tipoDespesaId === 1 ? 'FX%' : 'FI%';

    let wait = this.UI.showWaiting();
    // Get the right table to fetch fornecedores from IEP, AJL, OBLER
    let PRIFornecedores = this.PadService.getPrimaveraFornecedor(this.data.entidadeProprietariaId);
    let PRICondpag = this.PadService.getPrimaveraCondicaoPagamento(this.data.entidadeProprietariaId);

    // Check if exists a fornecedor selecionado already
    let fornecedorSelecionado = this.data.fornecedorSelecionado;
    let existsFornecedorSelecionado = !!fornecedorSelecionado;

    this.PadConfig.findOne({})
      .$promise.then(config => {
        PRIFornecedores.find({
          filter: {
            where: {
              fornecedor: { like: tipoDespesaFornecedor }
            },
            order: 'nome ASC'
          }
        })
          .$promise.then(fornecedores => {
            fornecedores.sort((a, b) => a.nome.localeCompare(b.nome));
            PRICondpag.find({
              filter: {
                order: 'condpag ASC'
              }
            })
              .$promise.then(condicoesPagamento => {
                this.PadCriterio.find({
                  filter: {
                    where: {
                      active: 1
                    }
                  }
                })
                  .$promise.then(criterios => {
                    wait.close();
                    let options = {
                      size: 'lg',
                      template: require('./edit.fornecedor.dialog.html'),
                      controller: [
                        '$dialog',
                        '$scope',
                        'AtvFornecedorPrimavera',
                        (dialog, scope, AtvFornecedorPrimavera) => {
                          scope.label = 'Adicionar Fornecedor de Processo';
                          scope.dragString = 'Arrastar o ficheiro para aqui';
                          scope.isEditing = false;
                          scope.fornecedores = fornecedores;
                          scope.existsFornecedorSelecionado = existsFornecedorSelecionado;
                          scope.condicoesPagamento = condicoesPagamento;
                          scope.criterios = criterios;
                          scope.newFileName = '';
                          scope.finishedUploading = false;

                          scope.anexos = []; // For editing only
                          // Info to show when selecting fornecedor
                          scope.avaliacaoFornecedor = null;

                          scope.uuid = this.PadService.generateUUID();

                          scope.uploader = new this.FileUploader({
                            url: this.envURL + '/api/Upload/pad/upload',
                            queueLimit: 1,
                            formData: [{ key: 'value' }]
                          });

                          // Filtro para verificar se é PDF ou ZIP
                          let fileTypeFilter = {
                            name: 'verifyPDF_ZIP',
                            fn: (item, options) => {
                              let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                              let ok = '|pdf|zip|'.indexOf(type) !== -1;
                              if (!ok) this.UI.addToast('A proposta tem que ser no formato PDF ou ZIP');
                              return ok;
                            }
                          };

                          let fileSizeFilter = {
                            name: 'fileSizeFilter',
                            fn: item => {
                              if (item.size > 52428800) {
                                this.UI.addToast('Tamanho máximo para ficheiro de proposta é de 50MB');
                              }
                              return item.size <= 52428800; // 50 MiB to bytes
                            }
                          };

                          // Inserir filtro
                          scope.uploader.filters.push(fileTypeFilter, fileSizeFilter);

                          // Uploader functions
                          scope.uploader.onAfterAddingFile = item => {
                            let partes = item.file.name.split('.');
                            scope.newFileName = scope.uuid + '.' + partes[partes.length - 1];

                            if (scope.uploader.queue.length > 1) scope.uploader.queue.splice(0, scope.uploader.queue.splice.length - 1);
                          };

                          scope.uploader.onWhenAddingFileFailed = item => {
                            // ... It's dealt in the filters already
                          };

                          scope.uploader.onErrorItem = (response, status, headers) => {
                            this.UI.addToast('Erro ao carregar ficheiro. Tente novamente ou verifique o ficheiro');
                          };

                          scope.uploader.onBeforeUploadItem = item => {
                            item.file.name = scope.newFileName;
                          };

                          // After file was correctly uploaded
                          scope.uploader.onSuccessItem = item => {
                            scope.finishedUploading = true;
                            dialog.close(scope);
                          };

                          scope.auxFornecedor = {
                            selected: undefined,
                            infiniteScroll: { numToAdd: 20, currentItems: 20 }
                          };

                          scope.auxCondicaoPagamento = {
                            selected: undefined,
                            infiniteScroll: { numToAdd: 20, currentItems: 20 }
                          };

                          scope.auxCriterio = {
                            selected: undefined,
                            infiniteScroll: { numToAdd: 20, currentItems: 20 }
                          };

                          scope.fornecedor = {
                            id: 0,
                            total: undefined,
                            totalPreNegocial: undefined,
                            prazo: undefined
                          };

                          // Only require anexo when proposta > 500
                          scope.requiresProposta = () => scope.fornecedor && scope.fornecedor.total > config.valorAprovacaoNivel3;

                          //Infinite Scroll Magic
                          scope.addMoreItems = infiniteScroll => {
                            infiniteScroll.currentItems += infiniteScroll.numToAdd;
                          };

                          scope.getAvaliacaoFornecedor = item => {
                            // Clear previous info
                            scope.avaliacaoFornecedor = null;
                            AtvFornecedorPrimavera.getAvaliacaoFornecedor({
                              params: {
                                entidadeProprietariaId: this.data.entidadeProprietariaId,
                                refFornecedorPrimavera: item.fornecedor
                              }
                            })
                              .$promise.then(avaliacaoFornecedor => {
                                scope.avaliacaoFornecedor = avaliacaoFornecedor;
                              })
                              .catch(error => {
                                scope.avaliacaoFornecedor = null;
                                console.log(error);
                                this.UI.addToast('Não foi possível consultar avaliação de fornecedor');
                              });
                          };

                          scope.ok = () => {
                            // If totalPreNegocial is filled and is a number that is < total, show message
                            if (
                              scope.fornecedor.totalPreNegocial &&
                              !isNaN(scope.fornecedor.totalPreNegocial) &&
                              scope.fornecedor.totalPreNegocial < scope.fornecedor.total
                            ) {
                              this.UI.addToast('Valor pré-negociação não deve ser inferior ao valor final da proposta');
                              return;
                            }
                            if (scope.uploader.queue.length > 0) {
                              scope.uploader.uploadAll();
                              // Closing the dialog is done on onSuccessItem()
                            } else {
                              dialog.close(scope);
                            }
                          };

                          scope.cancel = () => {
                            dialog.dismiss('cancel');
                          };
                        }
                      ]
                    };

                    let modal = this.UI.showDialog(options);

                    modal
                      .then(ok => {
                        // Check if Cliente exists. If not, create it, if exists, update it.
                        if (ok.auxFornecedor.selected && ok.auxCondicaoPagamento.selected) {
                          let fornecedorToCreate = {
                            fornecedorPrimavera: ok.auxFornecedor.selected,
                            totalPreNegocial: ok.fornecedor.totalPreNegocial,
                            total: ok.fornecedor.total,
                            condicaoPagamento: ok.auxCondicaoPagamento.selected,
                            prazo: ok.fornecedor.prazo,
                            email: ok.fornecedor.email || null,
                            refProposta: ok.fornecedor.refProposta || null,
                            uuid: ok.newFileName,
                            fornecedorSelecionado: ok.fornecedor.fornecedorSelecionado || false,
                            criterio: ok.fornecedor.fornecedorSelecionado ? ok.auxCriterio.selected : null,
                            criterioObservacoes: ok.fornecedor.fornecedorSelecionado ? ok.fornecedor.criterioObservacoes : null
                          };
                          let wait = this.UI.showWaiting();
                          this.PadService.createFornecedor(fornecedorToCreate, this.data.id, this.data.entidadeProprietariaId)
                            .then(() => {
                              wait.close();
                              this.UI.addToast('Proposta inserida com sucesso');
                              this.getProcesso();
                            })
                            .catch(() => {
                              wait.close();
                              // Errors dealt in service
                            });
                        }
                      })
                      .catch(() => {});
                  })
                  .catch(error => {
                    wait.close();
                    console.log(error);
                    this.UI.addToast('Erro no carregamento de Critérios de Adjudicação. Verifique a ligação');
                  });
              })
              .catch(error => {
                wait.close();
                console.log(error);
                this.UI.addToast('Erro no carregamento de Condições de Pagamento. Verifique a ligação');
              });
          })
          .catch(error => {
            wait.close();
            console.log(error);
            this.UI.addToast('Erro no carregamento de Fornecedores. Verifique a ligação');
          });
      })
      .catch(error => {
        wait.close();
        console.log(error);
        this.UI.addToast('Erro no carregamento de Configuração. Verifique a ligação.');
      });
  };

  changeFornecedorSelecionado = fornecedor => {
    this.PadCriterio.find({
      filter: {
        where: {
          active: 1
        }
      }
    })
      .$promise.then(criterios => {
        let options = {
          size: 'md',
          template: require('./confirm.fornecedor.selecionado.html'),
          controller: [
            '$dialog',
            '$scope',
            ($dialog, $scope) => {
              $scope.label = 'Alteração de Proposta Selecionada - ' + this.data.numeroProcesso;
              $scope.fornecedorSelecionado = angular.copy(this.data.fornecedorSelecionado);
              $scope.criterios = criterios;
              $scope.fornecedor = fornecedor;
              $scope.criterioObservacoes = this.data.criterioObservacoes;

              $scope.auxCriterio = {
                selected: criterios.find(c => c.id === this.data.criterioId),
                infiniteScroll: { numToAdd: 20, currentItems: 20 }
              };

              //Infinite Scroll Magic
              $scope.addMoreItems = infiniteScroll => {
                infiniteScroll.currentItems += infiniteScroll.numToAdd;
              };

              $scope.ok = () => {
                $dialog.close($scope);
              };

              $scope.cancel = () => {
                $dialog.dismiss('cancel');
              };
            }
          ]
        };
        let modal = this.UI.showDialog(options);

        modal
          .then(ok => {
            if (ok.auxCriterio.selected && ok.criterioObservacoes.trim().length > 0) {
              let wait = this.UI.showWaiting();
              this.PadProcesso.changeFornecedorSelecionado({
                params: {
                  fornecedorId: fornecedor.id,
                  processoId: this.data.id,
                  criterioId: ok.auxCriterio.selected.id,
                  criterioObservacoes: ok.criterioObservacoes
                }
              })
                .$promise.then(() => {
                  wait.close();
                  this.getProcesso();
                })
                .catch(error => {
                  wait.close();
                  console.log(error);
                  this.UI.addToast('Erro na alteração de proposta. Verifique a ligação');
                });
            } else {
              this.UI.addToast('Erro no preenchimento de critérios de adjudicação. Tente novamente');
            }
          })
          .catch(() => {});
      })
      .catch(error => {
        console.log(error);
        this.UI.addToast('Não foi possível obter critérios de adjudicação. Verifique a ligação');
      });
  };
}
PadProcessoDetailsController.$inject = [
  '$rootScope',
  '$http',
  '$state',
  '$q',
  '$stateParams',
  'Funcionario',
  'FileUploader',
  'UIService',
  'AuthorizationService',
  'AuthenticationService',
  'PadService',
  'PadProcesso',
  'PadLinhaProcesso',
  'PadLinhaProcessoEstado',
  'PadProcessoFornecedor',
  'PadCriterio',
  'PadProcessoAnexo',
  'PadProcessoObservacaoFinanceira',
  'PadConfig'
];
