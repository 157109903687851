export default class PadProcessosDetailsLinhaController {
  constructor($http, $state, $q, $stateParams, UIService, AuthorizationService, AuthenticationService, PadService, PadProcesso, PadLinhaProcesso, PadLinhaProcessoEstado, PadProcessoFornecedor) {
    this.$http = $http;
    this.$state = $state;
    this.$q = $q;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.Auth = AuthenticationService;
    this.user = AuthenticationService.getUser();
    this.PadService = PadService;
    this.PadProcesso = PadProcesso;
    this.PadLinhaProcesso = PadLinhaProcesso;
    this.PadLinhaProcessoEstado = PadLinhaProcessoEstado;
    this.PadProcessoFornecedor = PadProcessoFornecedor;
    this.loaded = false;
    this.id = $stateParams.linha;
    this.processoId = $stateParams.id;

    this.images = null;
    this.showEstadosProcesso = false;
    this.showEstadosLinhaProcesso = false;
    this.filterRevisions = true;

    //Infinite Scroll Magic
    this.infiniteScroll = {};
    this.infiniteScroll.numToAdd = 20;
    this.infiniteScroll.currentItems = 20;

    this.optLinhasProcesso = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "id",
      sort: "asc",
      filter: {},
      filterLayout: {}
    };
    this.linhasProcesso = null;
    this.linhasProcessoLoading = true;

    this.optFornecedores = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "id",
      sort: "asc",
      filter: {},
      filterLayout: {}
    };
    this.fornecedores = null;
    this.fornecedoresLoading = true;

    this.data = null;

    this.getLinhaProcesso();

    // this.hasPerms().then(() => {
    //   this.getProcesso();
    // }).catch(() => {
    //   $state.go($state.previous.name, $state.previous.params);
    // });
  }

  // hasPerms = () => {
  //   let defer = this.$q.defer();
  //   if (this.AuthorizationService.canPerform('elvListarTodosProcessos')) {
  //     defer.resolve(true);
  //   } else {
  //     this.ElvProcesso.findOne({
  //       filter: {
  //         fields: { id: true },
  //         where: {
  //           id: this.id
  //         },
  //         include: {
  //           relation: 'Agendamento',
  //           scope: {
  //             fields: { tecnicoId: true },
  //             where: {
  //               active: 1
  //             }
  //           }
  //         }
  //       }
  //     }).$promise.then((p) => {
  //       if (p && p.Agendamento && p.Agendamento.tecnicoId === this.user.id) {
  //         defer.resolve(true);
  //       } else {
  //         defer.reject(false);
  //         this.UI.addToast("Sem permissões para ver o processo");
  //       }
  //     }).catch(() => {
  //       defer.reject(false);
  //       this.UI.addToast("Processo não encontrado");
  //     });
  //   }
  //   return defer.promise;
  // };

  getLinhaProcesso = () => {
    this.loaded = false;
    if (this.id) {
      this.PadLinhaProcesso.findOne({
        filter: {
          where: {
            active: 1,
            id: this.id
          },
          include: [
            {
              relation: 'processo',
              scope: {
                where: {
                  active: 1
                },
                include: [
                  {
                    relation: 'criadoPor',
                    scope: {
                      where: {
                        active: 1
                      }
                    }
                  }, {
                    relation: 'entidadeProprietaria',
                    scope: {
                      where: {
                        active: 1
                      }
                    }
                  }, {
                    relation: 'tipoDespesa',
                    scope: {
                      where: {
                        active: 1
                      }
                    }
                  }, {
                    relation: 'urgencia',
                    scope: {
                      where: {
                        active: 1
                      }
                    }
                  }, {
                    relation: 'estadoAtual',
                    scope: {
                      where: {
                        active: 1
                      }
                    }
                  },
                  {
                    relation: 'estados',
                    scope: {
                      where: {
                        active: 1
                      },
                      order: 'id DESC',
                      include: [{
                        relation: 'criadoPor',
                        scope: {
                          where: {
                            active: 1
                          }
                        }
                      }, {
                        relation: 'estado',
                        scope: {
                          where: {
                            active: 1
                          }
                        }
                      }]
                    }
                  }, {
                    relation: 'fornecedorSelecionado',
                    scope: {
                      where: {
                        active: 1
                      },
                      include: 'condicaoPagamento'
                    }
                  }
                ]
              }
            }, {
              relation: 'centroCusto',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'estadoAtual',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'artigo',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'projeto',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'estados',
              scope: {
                where: {
                  active: 1
                },
                order: 'id DESC',
                include: [{
                  relation: 'criadoPor',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }, {
                  relation: 'estado',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }]
              }
            }
          ]
        }
      }).$promise.then((res) => {
        this.data = res;

        // this.getLinhasProcessoForProcesso();
        this.getFornecedoresForProcesso();

        // res.Documentacao.forEach((doc) => {
        //   this.$http.get("/api/upload/elv/files/" + doc.nome).then((result) => {
        //     if (result.data != null && !result.data.hasOwnProperty("error")) {
        //       doc.info = result.data;
        //     }
        //   }, (err) => {
        //     console.log(err);
        //   });
        // });

        this.loaded = true;
      }).catch((err) => {
        console.log(err);
        this.$state.go("app.pad.processo.details", {id: this.processoId});
        this.UI.addToast("Não foi possível obter essa linha. Verifique os dados");
      });
    } else {
      this.UI.addToast("Não foi possível obter essa linha. Verifique os dados");
      this.$state.go("app.pad.processo.details", {id: this.processoId});
    }
  };

  // Format bytes into correct units
  formatBytes = (bytes, decimals) => {
    if (bytes == null) return "N/D";
    if (bytes === 0) return "0 Bytes";
    let k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  toggleEstadosProcesso = () => {
    if (this.showEstadosProcesso) {
      this.showEstadosProcesso = false;
    } else {
      if (this.showEstadosLinhaProcesso) { // If the other aside is open, close it first
        this.toggleEstadosLinhaProcesso();
      }
      this.showEstadosProcesso = true;
    }
  };

  toggleEstadosLinhaProcesso = (x) => {
    if (this.showEstadosLinhaProcesso) {
      this.showEstadosLinhaProcesso = false;
      this.selectedLinha = null;
    } else {
      if (this.showEstadosProcesso) { // If the other aside is open, close it first
        this.toggleEstadosProcesso();
      }
      this.showEstadosLinhaProcesso = true;
      this.selectedLinha = x;
    }
  };

  // Fornecedores
  getFornecedoresForProcesso = () => {
    this.fornecedoresLoading = true;
    let whereLiteral = {
      'PadProcessoFornecedor.processoId': this.data.processoId
    };
    let where = {};
    Object.keys(this.optFornecedores.filter).forEach(f => {
      if (this.optFornecedores.filter[f])
        where[f] = this.optFornecedores.filter[f];
    });
    this.PadProcessoFornecedor.table({
      params: {
        fields: [
          'PadProcessoFornecedor.id as id',
          'PadProcessoFornecedor.processoId as processoId',
          'PadProcessoFornecedor.fornecedorId as fornecedorId',
          'PadProcessoFornecedor.fornecedorPrimaveraId as fornecedorPrimaveraId',
          'PadProcessoFornecedor.totalPreNegocial as totalPreNegocial',
          'PadProcessoFornecedor.total as total',
          'PadCondicaoPagamento.descricao as condicaoPagamento',
          'PadProcessoFornecedor.prazo as prazo',
          'PadProcessoFornecedor.email as email',
          'PadProcessoFornecedor.refProposta as refProposta',
          'AtvFornecedor.nome as fornecedor',
          'PadProcessoFornecedorAnexo.nome as uuid',
          'AtvFornecedorPrimavera.padAvaliacoesMedia as padAvaliacoesMedia',
          'AtvFornecedorPrimavera.padAvaliacoes as padAvaliacoes',
        ],
        from: ['PadProcessoFornecedor', 'AtvFornecedor', 'PadCondicaoPagamento', 'PadProcessoFornecedorAnexo', 'AtvFornecedorPrimavera'],
        referencesOrigin: [undefined, undefined, undefined, 'PadProcessoFornecedorAnexo.processoFornecedorId', undefined],
        references: [undefined, 'PadProcessoFornecedor.fornecedorId', 'PadProcessoFornecedor.condicaoPagamentoId', 'PadProcessoFornecedor.id', 'PadProcessoFornecedor.fornecedorPrimaveraId'],
        where: where,
        whereLiteral: whereLiteral,
        order: this.optFornecedores.order,
        sort: this.optFornecedores.sort,
        limit: this.optFornecedores.items,
        skip: (this.optFornecedores.page - 1) * this.optFornecedores.items,
      }
    }).$promise.then(res => {
      let page = this.optFornecedores.page;
      let items = this.optFornecedores.items;

      let total = res.count;

      this.optFornecedores.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.optFornecedores.start - 1 + items) >= total) {
        this.optFornecedores.end = total;
      } else {
        this.optFornecedores.end = Number.parseInt(this.optFornecedores.start - 1 + items);
      }


      // Process sub-data
      res.data.forEach(x => {

      });

      this.fornecedores = res.data;
      this.optFornecedores.total = total;
      this.fornecedoresLoading = false;

    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler fornecedores. Verifique a ligação");
    });
  };

  fornecedoresSort = keyname => {
    if (this.optFornecedores.order === keyname)
      this.optFornecedores.page = 1;
    this.optFornecedores.order = keyname;
    this.optFornecedores.sort = this.optFornecedores.sort === 'asc' ? 'desc' : 'asc';
    this.getFornecedoresForProcesso();
  };

  fornecedoresItem = val => {
    this.optFornecedores.items = val;
    this.optFornecedores.page = 1;
    this.getFornecedoresForProcesso();
  };

  fornecedoresPage = sum => {
    this.optFornecedores.page += sum;
    if (this.optFornecedores.page < 1)
      this.optFornecedores.page = 1;
    if (this.optFornecedores.page > Math.ceil(this.optFornecedores.total / this.optFornecedores.items))
      this.optFornecedores.page = Math.ceil(this.optFornecedores.total / this.optFornecedores.items);
    this.getFornecedoresForProcesso();
  };


  // Check if processo is Adjudicado and linha is Concluída (approved)
  canChangePrecoContratado = () => {
    if (this.data && this.data.estadoId === 12 && this.data.processo.estadoId === 12) {
      return !!(this.data.processo.criadoPorId === this.user.id || this.data.processo.alternativoId === this.user.id || this.AuthorizationService.canPerform('padNegociador'));
    } else {
      return false;
    }
  };

  changePrecoContratado = () => {
    let linha = angular.copy(this.data);
    // Show dialog
    let options = {
      size: 'lg',
      template: require('./edit.preco.dialog.html'),
      controller: ['$dialog', '$scope', ($dialog, $scope) => {
        $scope.label = "Redução de Preço de Linha de Processo";
        $scope.oldLinha = linha;
        $scope.linha = {
          quantidade: linha.quantidade,
          precoUnitarioContratado: linha.precoUnitarioContratado,
          precoTotalContratado: linha.precoTotalContratado
        };

        $scope.updateTotal = () => {
          if ($scope.linha.precoUnitarioContratado === undefined || $scope.linha.quantidade === undefined) return;
          if ($scope.linha.precoUnitarioContratado >= 0 && $scope.linha.quantidade >= 0)
            $scope.linha.precoTotalContratado = Math.round($scope.linha.precoUnitarioContratado * $scope.linha.quantidade * 100) / 100;
          else
            $scope.linha.precoTotalContratado = 0;
        };

        $scope.ok = () => {
          if ($scope.linha.precoUnitarioContratado > 0) {
            $dialog.close($scope);
          } else {
            this.UI.addToast("Preço unitário tem que ser superior a 0");
          }
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };
    let modal = this.UI.showDialog(options);

    modal.then((ok) => {
      if (ok.linha.precoUnitarioContratado !== linha.precoUnitarioContratado) {
        let wait = this.UI.showWaiting();
        // Create remote method to deal with this
        this.PadLinhaProcesso.changePreco({
          params: {
            linhaId: linha.id,
            linha: ok.linha
          }
        }).$promise.then((data) => {
          wait.close();
          this.UI.addToast("Preço contratado alterado com sucesso");
          this.getLinhaProcesso();
        }).catch(error => {
          console.log(error);
          wait.close();
          let errorMessage = "Não foi possível alterar o valor da linha de processo.";
          if (error.data && error.data.error && error.data.error.message)
            errorMessage += `\n${error.data.error.message}`;
          this.UI.showAlert(errorMessage);
        });
      } else {        this.UI.addToast("Não foram efetuadas alterações");
      }
    }).catch(() => {
    });
  };

}

PadProcessosDetailsLinhaController.$inject = ['$http', '$state', '$q', '$stateParams', 'UIService', 'AuthorizationService', 'AuthenticationService', 'PadService', 'PadProcesso', 'PadLinhaProcesso', 'PadLinhaProcessoEstado', 'PadProcessoFornecedor'];
