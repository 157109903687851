import React from 'react';
import { Stack, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
export var Choice;
(function (Choice) {
    Choice[Choice["PROCESSO"] = 1] = "PROCESSO";
    Choice[Choice["FATURA"] = 2] = "FATURA";
})(Choice || (Choice = {}));
const ExportDialog = ({ onClose, ...props }) => {
    return (<form>
      <Dialog {...props} fullWidth>
        <DialogTitle>Exportar</DialogTitle>
        <DialogContent>
          <Stack direction='row' spacing={2}>
            <Button type='button' variant='contained' onClick={() => onClose(Choice.PROCESSO)}>
              Processo
            </Button>

            <Button type='button' variant='contained' onClick={() => onClose(Choice.FATURA)}>
              Faturação
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default ExportDialog;
