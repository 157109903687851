import moment from 'moment';
import axios from 'axios';
export default class GasService {
    $rootScope;
    $q;
    GasProcesso;
    GasEstadoProcesso;
    GasProcessoAlteracaoestado;
    GasOrdemintervencao;
    GasClienteFinal;
    GasDistribuidor;
    GasRelatorio;
    GasOrd;
    GasTipoGas;
    GasTipoInstalacao;
    GasTipoInspecao;
    GasTecnico;
    GasAgendamento;
    CoreListaRelatorio;
    CoreListaRelatorioResultado;
    CoreListaRelatorioResposta;
    envURL;
    user;
    constructor($rootScope, $q, AuthenticationService, GasProcesso, GasEstadoProcesso, GasProcessoAlteracaoestado, GasOrdemintervencao, GasClienteFinal, GasDistribuidor, GasRelatorio, GasOrd, GasTipoGas, GasTipoInstalacao, GasTipoInspecao, GasTecnico, GasAgendamento, CoreListaRelatorio, CoreListaRelatorioResultado, CoreListaRelatorioResposta) {
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.GasProcesso = GasProcesso;
        this.GasEstadoProcesso = GasEstadoProcesso;
        this.GasProcessoAlteracaoestado = GasProcessoAlteracaoestado;
        this.GasOrdemintervencao = GasOrdemintervencao;
        this.GasClienteFinal = GasClienteFinal;
        this.GasDistribuidor = GasDistribuidor;
        this.GasRelatorio = GasRelatorio;
        this.GasOrd = GasOrd;
        this.GasTipoGas = GasTipoGas;
        this.GasTipoInstalacao = GasTipoInstalacao;
        this.GasTipoInspecao = GasTipoInspecao;
        this.GasTecnico = GasTecnico;
        this.GasAgendamento = GasAgendamento;
        this.CoreListaRelatorio = CoreListaRelatorio;
        this.CoreListaRelatorioResultado = CoreListaRelatorioResultado;
        this.CoreListaRelatorioResposta = CoreListaRelatorioResposta;
        this.user = AuthenticationService.getUser();
        // By default, envURL is production (to account for fails)
        this.envURL = 'https://inspecoeseletricas.pt';
        // Get environment for URL for files
        // Doing this here only because we only use this.envURL in the view
        this.$rootScope.$on('$receivedServerStatus', (evt, data) => {
            if (data != null && data.environment != null) {
                let env = data.environment;
                if (env === 'staging') {
                    this.envURL = 'https://staging-ie.iep.pt';
                }
                else if (env === 'production') {
                    // For completeness
                    this.envURL = 'https://inspecoeseletricas.pt';
                }
                else {
                    this.envURL = 'https://dev-iep-ie.streamline.pt';
                }
            }
        });
    }
    getUserId() {
        return this.user.id;
    }
    findAll(query) {
        return this.GasRelatorio.table(query).$promise;
    }
    export(query) {
        return this.GasRelatorio.export(query).$promise;
    }
    options() {
        return this.GasRelatorio.options().$promise;
    }
    getResultados() {
        return this.CoreListaRelatorioResultado.find({
            filter: {
                where: {
                    coreDepartamentoId: 6,
                    active: 1
                }
            }
        }).$promise;
    }
    getInfoCodigoPostal(cp4, cp3) {
        let defer = this.$q.defer();
        if (cp4.length === 4) {
            if (cp3.length === 3) {
                axios
                    .post('/api/public/getLocalidade', {
                    cp4: cp4,
                    cp3: cp3
                })
                    .then(r => {
                    defer.resolve(r.data);
                })
                    .catch(e => {
                    defer.reject(e);
                });
            }
            else {
                axios
                    .post('/api/public/getLocalidade', {
                    cp4: cp4,
                    cp3: null
                })
                    .then(r => {
                    defer.resolve(r.data);
                })
                    .catch(e => {
                    defer.reject(e);
                });
            }
        }
        return defer.promise;
    }
    alterarEstadoProcesso(id, estado, observacoes) {
        let defer = this.$q.defer();
        this.GasProcesso.findById({ id })
            .$promise.then(r => {
            this.GasProcessoAlteracaoestado.create({
                id: 0,
                dataAlteracao: moment().format(),
                processoId: id,
                estadoInicial: r.estadoId,
                estadoFinal: estado,
                observacoes: observacoes,
                funcionarioId: this.getUserId(),
                active: 1
            }).$promise.then(() => {
                this.GasProcesso.patch({ id }, { estadoId: estado })
                    .$promise.then(data => {
                    defer.resolve(data);
                })
                    .catch(error => {
                    defer.reject(error);
                });
            });
        })
            .catch(e => defer.reject(e));
        return defer.promise;
    }
    // Explain import stat
    // Copied from processos/list/import/controller.js. If changing, change there as well
    explainStat = (value, extra) => {
        switch (value) {
            case 0:
                return `Falha na importação${extra ? ' (' + extra + ')' : ''}`;
            case 10:
                return 'Importação com falhas (Em falta: Atualização da OI)';
            case 100:
                return 'Importação com falhas (Em falta: Registo de Alteração Estado de Processo - Importação)';
            case 110:
                return 'Importação com falhas (Em falta: Atualização da OI + Registo de Alteração de Estado de Processo - Importação';
            case 1000:
                return 'Importação com falhas (Em falta: Registo de Alteração Estado de Processo - Agendado)';
            case 1010:
                return 'Importação com falhas (Em falta: Atualização da OI + Registo de Alteração Estado de Processo - Agendado)';
            case 1100:
                return 'Importação com falhas (Em falta: Registo de Alterações de Estado de Processo: Importação, Agendado)';
            case 1110:
                return 'Importação com falhas (Em falta: Atualização da OI + Registo de Alterações de Estado de Processo: Importação, Agendado)';
            case 10000:
                return 'Importação com falhas (Em falta: Agendamento)';
            case 10010:
                return 'Importação com falhas (Em falta: Agendamento + Atualização da OI)';
            case 10100:
                return 'Importação com falhas (Em falta: Agendamento + Registo de Alteração Estado de Processo - Importação)';
            case 10110:
                return 'Importação com falhas (Em falta: Agendamento + Atualização da OI + Registo de Alteração de Estado de Processo - Importação';
            case 11000:
                return 'Importação com falhas (Em falta: Agendamento + Registo de Alteração Estado de Processo - Agendado)';
            case 11010:
                return 'Importação com falhas (Em falta: Agendamento + Atualização da OI + Registo de Alteração Estado de Processo - Agendado)';
            case 11100:
                return 'Importação com falhas (Em falta: Agendamento + Registo de Alterações de Estado de Processo: Importação, Agendado)';
            case 11110:
                return 'Importação com falhas (Em falta: Agendamento + Atualização da OI + Registo de Alterações de Estado de Processo: Importação, Agendado)';
            case 20:
                return 'Reimportação com falhas (Em falta: Atualização da OI)';
            case 200:
                return 'Reimportação com falhas (Em falta: Alteração de Estado)';
            case 220:
                return 'Reimportação com falhas (Em falta: Atualização da OI + Alteração de Estado)';
            case 2000:
                return 'Reimportação com falhas (Em falta: Atualização de Agendamento)';
            case 2020:
                return 'Reimportação com falhas (Em falta: Atualização de Agendamento + Atualização da OI)';
            case 2200:
                return 'Reimportação com falhas (Em falta: Atualização de Agendamento + Alteração de Estado)';
            case 2220:
                return 'Reimportação com falhas (Em falta: Atualização de Agendamento + Alteração de Estado + Atualização da OI)';
            case 20000:
                return 'Reimportação com falhas (Em falta: Atualização de Cliente)';
            case 20020:
                return 'Reimportação com falhas (Em falta: Atualização da OI + Atualização de Cliente)';
            case 20200:
                return 'Reimportação com falhas (Em falta: Alteração de Estado + Atualização de Cliente)';
            case 20220:
                return 'Reimportação com falhas (Em falta: Atualização da OI + Alteração de Estado + Atualização de Cliente)';
            case 22000:
                return 'Reimportação com falhas (Em falta: Atualização de Agendamento + Atualização de Cliente)';
            case 22020:
                return 'Reimportação com falhas (Em falta: Atualização de Agendamento + Atualização da OI + Atualização de Cliente)';
            case 22200:
                return 'Reimportação com falhas (Em falta: Atualização de Agendamento + Alteração de Estado + Atualização de Cliente)';
            case 22220:
                return 'Reimportação com falhas (Em falta: Atualização de Agendamento + Alteração de Estado + Atualização da OI + Atualização de Cliente)';
            case 1000000:
                return 'Importação não realizada (Relatório já preenchido)';
            case 1:
                return 'Importação bem sucedida';
            case 2:
                return 'Reimportação bem sucedida';
            default:
                return 'Erro de importação';
        }
    };
    // Ordens de Intervenção
    findOIs = (params) => {
        let defer = this.$q.defer();
        this.GasOrdemintervencao.table({ params: params })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    findOI = (id) => {
        let defer = this.$q.defer();
        this.GasOrdemintervencao.findById({
            id: id,
            filter: {
                where: {
                    active: 1
                },
                include: [
                    {
                        relation: 'processos',
                        scope: {
                            where: {
                                active: 1
                            },
                            include: [
                                {
                                    relation: 'agendamento',
                                    scope: {
                                        where: {
                                            active: 1
                                        },
                                        include: 'tecnico'
                                    }
                                },
                                'tipoInstalacao',
                                'tipoInspecao',
                                'tipoGas',
                                'estado'
                            ]
                        }
                    },
                    'estado',
                    'tipo'
                ]
            }
        })
            .$promise.then(oi => {
            defer.resolve(oi);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    updateOi = oi => {
        let defer = this.$q.defer();
        // TODO - Replace this with updateAttributes only for observacoes
        this.GasOrdemintervencao.patch({ id: oi.id }, oi)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // Processos
    // Create processo (manual)
    createProcesso = (values) => {
        let defer = this.$q.defer();
        this.GasProcesso.criarProcessoManual({ params: values })
            .$promise.then(p => {
            defer.resolve(p);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    removeProcesso = processo => {
        let defer = this.$q.defer();
        processo.active = 0;
        this.GasProcesso.upsert(processo)
            .$promise.then(p => {
            defer.resolve(p);
        })
            .catch(error => {
            defer.reject(error);
        });
    };
    findProcesso = (id) => {
        let defer = this.$q.defer();
        this.GasProcesso.findById({
            id: id,
            filter: {
                where: {
                    active: 1
                },
                include: [
                    'ordemintervencao',
                    'tipoInstalacao',
                    'tipoInspecao',
                    'tipoGas',
                    'estado',
                    'distribuidor',
                    'ord',
                    {
                        relation: 'alteracoesEstado',
                        scope: {
                            include: ['inicial', 'final', 'funcionario']
                        }
                    },
                    {
                        relation: 'relatorios',
                        scope: {
                            where: {
                                active: 1
                            },
                            order: 'versao DESC',
                            include: {
                                relation: 'CoreListaRelatorioResposta',
                                scope: {
                                    order: 'versao DESC'
                                }
                            }
                        }
                    },
                    {
                        relation: 'agendamento',
                        scope: {
                            where: {
                                active: 1
                            },
                            include: 'tecnico'
                        }
                    }
                ]
            }
        })
            .$promise.then(p => {
            defer.resolve(p);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    optionsProcessos() {
        return this.GasProcesso.options().$promise;
    }
    findProcessos(query) {
        return this.GasProcesso.table(query).$promise;
    }
    exportProcessos(query) {
        return this.GasProcesso.export(query).$promise;
    }
    exportProcessosFatura(query) {
        return this.GasProcesso.exportFatura(query).$promise;
    }
    updateProcesso = (processo) => {
        let defer = this.$q.defer();
        this.GasProcesso.patch({ id: processo.id }, processo)
            .$promise.then(p => {
            defer.resolve(p);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // Clientes Finais
    createClienteFinal = clienteFinal => {
        let defer = this.$q.defer();
        this.GasClienteFinal.create(clienteFinal)
            .$promise.then(p => {
            defer.resolve(p);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    removeClienteFinal = clienteFinal => {
        let defer = this.$q.defer();
        clienteFinal.active = 0;
        this.GasClienteFinal.upsert(clienteFinal)
            .$promise.then(p => {
            defer.resolve(p);
        })
            .catch(error => {
            defer.reject(error);
        });
    };
    findClienteFinal = (id) => {
        let defer = this.$q.defer();
        this.GasClienteFinal.findById({
            id: id,
            filter: {
                where: {
                    active: 1
                },
                include: [
                    {
                        relation: 'processos',
                        scope: {
                            where: {
                                active: 1
                            },
                            include: [
                                {
                                    relation: 'agendamento',
                                    scope: {
                                        where: {
                                            active: 1
                                        },
                                        include: 'tecnico'
                                    }
                                },
                                'tipoInstalacao',
                                'tipoInspecao',
                                'tipoGas',
                                'estado'
                            ]
                        }
                    }
                ]
            }
        })
            .$promise.then(c => {
            defer.resolve(c);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    findClientesFinais = (params) => {
        let defer = this.$q.defer();
        this.GasClienteFinal.table({ params: params })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    updateClienteFinal = (clienteFinal) => {
        let defer = this.$q.defer();
        this.GasClienteFinal.patch({ id: clienteFinal.id }, clienteFinal)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // Relatórios
    findRelatorio = id => {
        let defer = this.$q.defer();
        this.GasRelatorio.findById({
            id: id,
            filter: {
                where: {
                    active: 1
                },
                include: [
                    {
                        relation: 'processo',
                        scope: {
                            where: {
                                active: 1
                            },
                            include: [
                                {
                                    relation: 'agendamento',
                                    scope: {
                                        where: {
                                            active: 1
                                        },
                                        include: 'tecnico'
                                    }
                                },
                                'tipoInstalacao',
                                'tipoInspecao',
                                'tipoGas',
                                'estado'
                            ]
                        }
                    },
                    {
                        relation: 'agendamento',
                        scope: {
                            include: 'tecnico'
                        }
                    },
                    'funcionario',
                    'lista'
                ]
            }
        })
            .$promise.then(r => {
            defer.resolve(r);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    findRelatorios = (params) => {
        let defer = this.$q.defer();
        this.GasRelatorio.table({ params: params })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // Agendamentos
    findAgendamento(id) {
        return this.GasAgendamento.findById({ id }).$promise;
    }
    inScheduling(query) {
        return this.GasAgendamento.inScheduling(query).$promise;
    }
    scheduled(query) {
        return this.GasAgendamento.scheduled(query).$promise;
    }
    agendamentosOptions() {
        return this.GasAgendamento.options().$promise;
    }
    // Distribuidores
    findDistribuidor = id => {
        let defer = this.$q.defer();
        this.GasDistribuidor.findById({
            id: id,
            filter: {
                where: {
                    active: 1
                },
                include: [
                    {
                        relation: 'processos',
                        scope: {
                            where: {
                                active: 1
                            },
                            include: [
                                {
                                    relation: 'agendamento',
                                    scope: {
                                        where: {
                                            active: 1
                                        },
                                        include: 'tecnico'
                                    }
                                },
                                'tipoInstalacao',
                                'tipoInspecao',
                                'tipoGas',
                                'estado'
                            ]
                        }
                    }
                ]
            }
        })
            .$promise.then(d => {
            defer.resolve(d);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    findDistribuidores = (params) => {
        let defer = this.$q.defer();
        this.GasDistribuidor.table({ params: params })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    updateDistribuidor = (distribuidor) => {
        let defer = this.$q.defer();
        this.GasDistribuidor.patch({ id: distribuidor.id }, distribuidor)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getEstados = () => {
        return this.GasEstadoProcesso.find({
            filter: {
                where: {
                    active: true
                }
            }
        }).$promise;
    };
    // For selections
    getDistribuidores = () => {
        let defer = this.$q.defer();
        this.GasDistribuidor.find({
            filter: {
                where: {
                    active: 1
                }
            }
        })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // ORDs
    findOrd = id => {
        let defer = this.$q.defer();
        this.GasOrd.findById({
            id: id,
            filter: {
                where: {
                    active: 1
                },
                include: [
                    {
                        relation: 'processos',
                        scope: {
                            where: {
                                active: 1
                            },
                            include: [
                                {
                                    relation: 'agendamento',
                                    scope: {
                                        where: {
                                            active: 1
                                        },
                                        include: 'tecnico'
                                    }
                                },
                                'tipoInstalacao',
                                'tipoInspecao',
                                'tipoGas',
                                'estado'
                            ]
                        }
                    }
                ]
            }
        })
            .$promise.then(d => {
            defer.resolve(d);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    findOrds = (params) => {
        let defer = this.$q.defer();
        this.GasOrd.table({ params: params })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    updateOrd = (ord) => {
        let defer = this.$q.defer();
        this.GasOrd.patch({ id: ord.id }, ord)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // For selections
    getOrds = () => {
        let defer = this.$q.defer();
        this.GasOrd.find({
            filter: {
                where: {
                    active: 1
                }
            }
        })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // For selections
    getTiposGas = () => {
        let defer = this.$q.defer();
        this.GasTipoGas.find({
            filter: {
                where: {
                    active: 1
                }
            }
        })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getTiposInstalacao = () => {
        let defer = this.$q.defer();
        this.GasTipoInstalacao.find({
            filter: {
                where: {
                    active: 1
                }
            }
        })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getTiposInspecao = () => {
        let defer = this.$q.defer();
        this.GasTipoInspecao.find({
            filter: {
                where: {
                    active: 1
                }
            }
        })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getTecnicos = () => {
        let defer = this.$q.defer();
        this.GasTecnico.find({
            filter: {
                where: {
                    active: 1
                },
                include: 'funcionario'
            }
        })
            .$promise.then(data => {
            // Safeguard that funcionario exists for all
            data = data.filter(x => x.funcionario != null);
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getTecnicosFuncionario = () => {
        let defer = this.$q.defer();
        this.GasTecnico.find({
            filter: {
                where: {
                    active: 1
                },
                include: 'funcionario'
            }
        })
            .$promise.then(data => {
            // Safeguard that funcionario exists for all
            data = data.filter(x => x.funcionario != null);
            defer.resolve(data.map(r => r.funcionario));
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getTecnicoById = (tecnicoId) => {
        let defer = this.$q.defer();
        this.GasTecnico.find({
            filter: {
                where: {
                    funcionarioId: tecnicoId
                },
                include: 'funcionario',
                limit: 1
            }
        })
            .$promise.then(data => {
            if (data && data.length > 0 && data[0].funcionario) {
                defer.resolve(data[0]);
            }
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // Update agendamento. agendamento may be brand new
    updateAgendamento = (item) => {
        let defer = this.$q.defer();
        // Fill agendado por, agendadoa automatically
        item.agendadoa = moment().format();
        delete item.agendadoPor;
        item.agendadoporId = this.user.id;
        item.active = 1;
        this.GasAgendamento.find({
            filter: {
                where: {
                    processoId: item.processoId,
                    active: 1
                }
            }
        })
            .$promise.then(res => {
            if (res && res.length > 0) {
                this.GasAgendamento.patch({ id: res[0].id }, item)
                    .$promise.then(data => {
                    // If Processo is Em Agendamento, update it
                    this.GasProcesso.findById({ id: data.processoId })
                        .$promise.then(processo => {
                        if (processo) {
                            let updateObject = {
                                funcionarioId: res[0].tecnicoId
                            };
                            if (processo.estadoId === 1) {
                                updateObject.estadoId = 2;
                            }
                            this.GasProcesso.patch({ id: processo.id }, updateObject)
                                .$promise.then(p => {
                                defer.resolve(data);
                            })
                                .catch(error => {
                                console.log(error);
                                defer.resolve(data);
                            });
                        }
                        else {
                            // Do nothing
                            defer.resolve(data);
                        }
                    })
                        .catch(error => {
                        console.log(error);
                        defer.resolve(data);
                    });
                })
                    .catch(error => {
                    defer.reject(error);
                });
            }
            else {
                // No agendamento exists, create one
                this.GasAgendamento.create({
                    id: 0,
                    ...item
                })
                    .$promise.then(data => {
                    // If Processo is Em Agendamento, update it
                    this.GasProcesso.findById({ id: data.processoId })
                        .$promise.then(processo => {
                        if (processo && processo.estadoId === 1) {
                            this.GasProcesso.patch({ id: processo.id }, { estadoId: 2 })
                                .$promise.then(p => {
                                defer.resolve(data);
                            })
                                .catch(error => {
                                console.log(error);
                                defer.resolve(data);
                            });
                        }
                        else {
                            // Do nothing
                            defer.resolve(data);
                        }
                    })
                        .catch(error => {
                        console.log(error);
                        defer.resolve(data);
                    });
                })
                    .catch(error => {
                    defer.reject(error);
                });
            }
        })
            .catch(error => {
            console.log(error);
        });
        return defer.promise;
    };
    getAgendamentoForProcesso = (processoId) => {
        let defer = this.$q.defer();
        this.GasAgendamento.find({
            filter: {
                where: {
                    and: [{ processoId: processoId }, { active: 1 }]
                },
                limit: 1
            }
        })
            .$promise.then(data => {
            if (data && data.length > 0) {
                defer.resolve(data[0]);
            }
            else {
                // If there's no valid agendamento
                defer.resolve({});
            }
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getGasClienteFinalForNif = (nif) => {
        let defer = this.$q.defer();
        this.GasClienteFinal.find({
            filter: {
                where: {
                    and: [{ nif: nif }, { active: 1 }]
                },
                limit: 1
            }
        })
            .$promise.then(data => {
            if (data && data.length > 0) {
                defer.resolve(data[0]);
            }
            else {
                // If there's no valid agendamento
                defer.resolve(null);
            }
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getCoreListasRelatorio = (departamentoId) => {
        let defer = this.$q.defer();
        this.CoreListaRelatorio.find({
            filter: {
                where: {
                    and: [{ departamentoId: departamentoId }, { active: 1 }, { dataPublicacao: { neq: null } }]
                },
                include: ['Norma'],
                order: 'coreListaNormaId DESC, versao DESC'
            }
        })
            .$promise.then(data => {
            // Filter the array to keep only the latest version for each the same norma
            let listas = data.reduce((acc, obj) => {
                if (!acc.find(x => x.coreListaNormaId === obj.coreListaNormaId)) {
                    acc.push(obj);
                }
                return acc;
            }, []);
            defer.resolve(listas);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    getResposta(id) {
        return this.CoreListaRelatorio.find({
            filter: {
                where: {
                    id
                },
                include: [
                    {
                        relation: 'Parametros',
                        scope: {
                            include: [
                                {
                                    relation: 'Pontos',
                                    scope: {
                                        where: {
                                            active: 1
                                        },
                                        order: 'ordem ASC'
                                    }
                                }
                            ]
                        }
                    },
                    {
                        relation: 'Ensaios',
                        scope: {
                            where: {
                                active: 1
                            },
                            order: 'ordem ASC'
                        }
                    },
                    {
                        relation: 'Equipamentos',
                        scope: {
                            where: {
                                active: 1
                            },
                            order: 'ordem ASC'
                        }
                    }
                ]
            }
        }).$promise;
    }
    // devolve core_lista_relatorio_resposta_id
    pendingRelatorio = (processoId) => {
        return this.GasRelatorio.pending({ processoId }).$promise;
    };
    patchRelatorio(id, data) {
        return this.GasRelatorio.patch({ id }, data).$promise;
    }
    createResposta = (coreListaRelatorioId) => {
        let defer = this.$q.defer();
        const newResposta = {
            id: 0,
            resultadoId: 3,
            processado: 0,
            tecnicoId: this.getUserId(),
            validado: 0,
            realizada: null,
            motivoId: null,
            observacoes: null,
            imagens: null,
            coreListaRelatorioId: coreListaRelatorioId
        };
        this.CoreListaRelatorioResposta.create(newResposta)
            .$promise.then((response) => {
            defer.resolve(response);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // Create Relatorio
    createRelatorio = (listaRelatorioId, processoId) => {
        const newRelatorio = {
            id: 0,
            resultadoId: 3,
            processado: 0,
            tecnicoId: this.getUserId(),
            validado: 0,
            realizada: null,
            motivoId: null,
            observacoes: null,
            imagens: null,
            coreListaRelatorioId: listaRelatorioId
        };
        let defer = this.$q.defer();
        /* // Procurar o relatório com a maior versão
        this.GasRelatorio.findOne({
          filter: {
            where: {
              processoId: processoId
            },
            order: 'versao DESC'
          }
        })
          .$promise.then((gasRelatorioMaiorVersao: IGasRelatorio) => {
            if (gasRelatorioMaiorVersao && gasRelatorioMaiorVersao.CoreListaRelatorioResposta) {
              // Se existe um relatório, criar um novo com a versão incrementada
              newRelatorio.versao = (gasRelatorioMaiorVersao.CoreListaRelatorioResposta.versao || 0) + 1;
            } else {
              // Se não existe nenhum relatório, criar um com a versão 1
              newRelatorio.versao = 1;
            } */
        // Criar o novo relatório
        this.CoreListaRelatorioResposta.create(newRelatorio)
            .$promise.then((response) => {
            const newGasRelatorio = {
                id: 0,
                processoId: processoId,
                coreListaRelatorioRespostaId: response.id,
                versao: 1,
                active: 1
            };
            // Associar o novo CoreListaRelatorioResposta ao GasRelatorio
            this.GasRelatorio.create(newGasRelatorio)
                .$promise.then(p => {
                defer.resolve(response); // Retornar o novo relatório criado
            })
                .catch(error => {
                defer.reject(error);
            });
        })
            .catch(error => {
            defer.reject(error);
        }); /*
        })
        .catch(error => {
          defer.reject(error);
        }); */
        return defer.promise;
    };
    validateRelatorio = (id) => {
        return this.GasRelatorio.validate({ id }).$promise;
    };
}
GasService.$inject = [
    '$rootScope',
    '$q',
    'AuthenticationService',
    'GasProcesso',
    'GasEstadoProcesso',
    'GasProcessoAlteracaoestado',
    'GasOrdemintervencao',
    'GasClienteFinal',
    'GasDistribuidor',
    'GasRelatorio',
    'GasOrd',
    'GasTipoGas',
    'GasTipoInstalacao',
    'GasTipoInspecao',
    'GasTecnico',
    'GasAgendamento',
    'CoreListaRelatorio',
    'CoreListaRelatorioResultado',
    'CoreListaRelatorioResposta'
];
