export default class SusEneDetailRelatoriosController {
  constructor($q, $filter, $state, UIService, AuthenticationService,  SusEneService, SusEneProcessoRelatorioDraft, SusEneProcessoRelatorio) {
    this.$q = $q;
    this.$filter = $filter;
    this.$state = $state;
    this.UI = UIService;
    this.SusEneService = SusEneService;
    this.SusEneProcessoRelatorioDraft = SusEneProcessoRelatorioDraft;
    this.SusEneProcessoRelatorio = SusEneProcessoRelatorio;
    this.loaded = false;
    this.user = AuthenticationService.getUser();
    this.id = $state.params.id;
    let ids = $state.params.id.split("-");
    this.ids = {
      empresa: 1,
      tipo: 1,
      ano: ids[2],
      proc: ids[3]
    };

    this.loadData();
    this.showEstadosProcesso = false;
    this.showEstadosRelatorio = false;

    this.observacoesAvaliacao = null;
  }

  toggleEstadosProcesso = () => {
    if (this.showEstadosProcesso) {
      this.showEstadosProcesso = false;
    } else {
      if (this.showEstadosRelatorio) { // If the other aside is open, close it first
        this.toggleEstadosRelatorio();
      }
      this.showEstadosProcesso = true;
    }
  };

  toggleEstadosRelatorio = () => {
    if (this.showEstadosRelatorio) {
      this.showEstadosRelatorio = false;
    } else {
      if (this.showEstadosProcesso) { // If the other aside is open, close it first
        this.toggleEstadosProcesso();
      }
      this.showEstadosRelatorio = true;
    }
  };

// Copy URL to clipboard on click
  copyProjectFolder = () => {
    if (this.data && this.data.empresaId) {
      let url = this.SusEneService.getProjectFolderPath(this.data, this.data.empresaId);

      if (!navigator.clipboard) { // Alternative solution
        let tempInput = document.createElement('input');
        tempInput.type = 'text';
        tempInput.value = url;
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // For mobile devices
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        this.UI.addToast("Caminho copiado para Área de Transferência");
      } else { // Navigator API solution
        navigator.clipboard.writeText(url).then(() => {
          this.UI.addToast("Caminho copiado para Área de Transferência");
        }).catch(err => {
          this.UI.addToast("Erro na cópia para Área de Transferência");
        });
      }
    } else this.UI.addToast("Sem informação a copiar");
  };

  viewPai = () => {
    this.$state.go('app.susene.processes.details', {
      id: this.$filter('suseneFormat')(this.data.Pai)
    });
  };

  viewRaiz = () => {
    this.$state.go('app.susene.processes.details', {
      id: this.$filter('suseneFormat')(this.data.Raiz)
    });
  };

  // Format bytes into correct units
  formatBytes = (bytes, decimals) => {
    if (bytes == null) return "N/D";
    if (bytes === 0) return '0 Bytes';
    let k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  setTimelineColor = (h) => {
    switch (h.final.descricao) {
      case "Aguarda amostra(s)":
      case "Em agendamento":
      case "Agendado":
      case "Aguarda emissão de relatório":
      case "Em emissão de fatura":
        return "project-standby";
      case "Em execução":
      case "Relatório Emitido":
      case "Concluído":
        return "project-info";
      case "Suspenso":
      case "Anulado":
        return "project-notification";
      default:
        return "project-notification";
    }
  };

  setTimelineColorRelatorio = (h) => {
    switch (h.estadoId) {
      case 1: return "project-standby";
      case 2: return "project-notification";
      case 3: return "project-standby";
      case 4: return "project-info";
      default: return "project-notification";
    }
  };

  loadData = () => {
    this.loaded = false;
    if (this.id && !isNaN(this.id)) {

      // Check if user has permissions and if relatorio can be seen

      this.SusEneProcessoRelatorioDraft.getDetalhesDraft({
        params: {
          id: this.id,
          userId: this.user.id
        }
      }).$promise.then((relatorio) => {
        this.data = relatorio.processo;
        this.relatorio = relatorio;

        // Parse data inicio/fim - use a variable to simplify
        this.data.dataInicioProcesso = this.getDataProcesso(0);
        this.data.dataFimProcesso = this.getDataProcesso(1);

        this.loaded = true;
      }).catch(error => {
        console.log(error);
        if (error.status === 404) {
          this.UI.addToast('Erro ao carregar relatório');
        } else if (error.status === 401) {
          this.UI.addToast('Sem permissões para ver este draft de momento.');
        } else {
          this.UI.addToast('Erro na procura de relatório. Verifique a ligação.');
        }
        this.$state.go('app.susene.relatorios.list');
      });
    } else { // Invalid id, go back to list
      this.$state.go('app.susene.relatorios.list');
    }

  };

  // return variable with dates from Historico dataInicio (0) or dataFim (1)
  // Assuming Historico is sorted by dataAlteracao!!
  getDataProcesso = (type) => {
    if (this.data && this.data.Historico && this.data.Historico.length > 0) {
      for (let i = 0; i < this.data.Historico.length; i++) {
        if (this.data.Historico[i]) {
          if (type === 0 && this.data.Historico[i].dataInicio)
            return this.data.Historico[i].dataInicio;
          if (type === 1 && this.data.Historico[i].dataFim)
            return this.data.Historico[i].dataFim;
        }
      }
    }
    return null;
  };

  // Send approved report to client if necessary (modified from processos)
  sendToEmail = (result) => {
    let relatorio = result.relatorio;
    let reportType;
    if (relatorio.Tipo && relatorio.Tipo.designacao)
      reportType = relatorio.Tipo.designacao;
    else
      reportType = 'Relatório';

    // If necessary, add more documento types
    if (!relatorio.uuid || relatorio.uuid.length === 0) {
      this.UI.addToast("Não está definido um ficheiro para envio");
      return;
    }

    let dialog = this.UI.showDialog({
      size: "lg",
      template: require("./email.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.label = `Emails para envio de ${reportType}`;
        $scope.defaultMessageLabel = 'Corpo da mensagem padrão';
        $scope.customMessageLabel = 'Corpo da mensagem personalizada';
        $scope.emails = [];
        $scope.defaultMessageSelected = true;
        $scope.customMessage = null;

        $scope.validEmail = (email) => {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        };

        if ($scope.validEmail(this.data.Ordemintervencao.cliente.email))
          $scope.emails.push({id: 1, selected: false, description: 'Email do Cliente no Primavera', email: this.data.Ordemintervencao.cliente.email});
        if ($scope.validEmail(this.data.Ordemintervencao.email))
          $scope.emails.push({id: 2, selected: false, description: 'Email da OI', email: this.data.Ordemintervencao.email});
        if ($scope.validEmail(this.data.email))
          $scope.emails.push({id: 3, selected: false, description: 'Email do Processo', email: this.data.email});
        $scope.emails.push({id: 10, selected: false, description: 'Outro(s) Email(s) para envio', email: null});

        // // When selecting default/custom message
        $scope.onSelectMessageType = () => {
          if ($scope.defaultMessageSelected) { // Selected
            $scope.customMessage = null;
          } else { // Deselected
            // Do nothing
          }
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };

        $scope.hasSelection = () => $scope.emails.some(x => x.selected);

        $scope.ok = () => {
          // Check if Outro is selected, and if it is, if email is valid, otherwise return selection
          let outro = $scope.emails.find(x => x.id === 10);
          if (outro.selected) {
            if (outro.email && outro.email.length > 0) {
              // Check if there are multiple emails by splitting the email string
              let emails = outro.email.split(/[,;]/).map(email => email.trim()).filter(email => email);

              // Validate each email in the list
              let invalidEmails = emails.filter(email => !$scope.validEmail(email));

              if (invalidEmails.length > 0) {
                // If any email is invalid, show a toast message
                this.UI.addToast("Um ou mais emails no campo Outro são inválidos");
              } else {
                // If all emails are valid, remove the original Outro email from $scope.emails
                $scope.emails = $scope.emails.filter(x => x.id !== 10);

                // Add valid emails to the list
                emails.forEach(email => {
                  // Check if email already exists in $scope.emails
                  if (!$scope.emails.some(x => x.selected && x.email === email)) {
                    $scope.emails.push({
                      id: null, // No need for ID
                      email: email,
                      selected: true
                    });
                  }
                });

                // Close the dialog with selected emails
                $dialog.close({
                  emails: $scope.emails.filter(x => x.selected),
                  message: $scope.customMessage
                });
              }
            } else {
              this.UI.addToast("Outro Email selecionado, mas não preenchido");
            }
          } else {
            $dialog.close({
              emails: $scope.emails.filter(x => x.selected),
              message: $scope.customMessage
            });
          }
        };
      }]
    });
    dialog.then((ok) => {
      if (ok.message)
        ok.message = ok.message.replace(/\n/g, '<br>');
      let wait = this.UI.showWaiting();
      let tasks = [];
      ok.emails.forEach(email => {
        let defer = this.$q.defer();
        this.SusEneProcessoRelatorio.notifyRelatorio({
          params: {
            relatorioId: relatorio.id,
            message: ok.message,
            submittedEmail: email.email
          }
        }).$promise.then(r => {
          this.UI.addToast(`Email com ${reportType} enviado para ${email.email}`);
          defer.resolve(r);
        }).catch(error => {
          console.log(error);
          this.UI.addToast(`Erro no envio de ${reportType} para ${email.email}. Verifique a ligação`);
          defer.reject(error);
        });
        tasks.push(defer.promise);
      });
      this.$q.all(tasks).then((res) => {
        wait.close();
        if (result.nextId) {
          this.$state.go('app.susene.relatorios.details', {id: result.nextId});
        } else { // No next relatorio to evaluate
          this.$state.go('app.susene.relatorios.list');
        }
      }).catch(error => {
        wait.close();
        console.log(error);
        if (result.nextId) {
          this.$state.go('app.susene.relatorios.details', {id: result.nextId});
        } else { // No next relatorio to evaluate
          this.$state.go('app.susene.relatorios.list');
        }
      });
    }).catch(() => { // If cancel sending report, go to next report
      if (result.nextId) {
        this.$state.go('app.susene.relatorios.details', {id: result.nextId});
      } else { // No next relatorio to evaluate
        this.$state.go('app.susene.relatorios.list');
      }
    });
  };

  // Deal with clicking in evaluate buttons
  evaluate = (avaliacao) => {
    if (avaliacao !== 2 && avaliacao !== 3 && avaliacao !== 4)
      return;

    // In case of ajustes, check if observacoes are filled
    if (avaliacao === 3 && (this.observacoesAvaliacao == null || this.observacoesAvaliacao.trim().length === 0)) {
      this.UI.showAlert("É obrigatório preencher a informação associada à decisão para o técnico, no caso de serem necessários ajustes.");
      return;
    }

    let confirmString = 'Tem a certeza que pretende ';
    switch(avaliacao) {
      case 2: confirmString += `anular o relatório ${this.relatorio.refRelatorio}?`; break;
      case 3: confirmString += `requerer nova revisão do relatório ${this.relatorio.refRelatorio}?`; break;
      case 4: confirmString += `aprovar o relatório ${this.relatorio.refRelatorio}?`; break;
    }
    confirmString += '\n Esta decisão não pode ser anulada.';

    let confirm = this.UI.showConfirm(confirmString);
    confirm.then(() => {
      let wait = this.UI.showWaiting();
      this.SusEneProcessoRelatorioDraft.evaluate({
        params: {
          avaliacao: avaliacao,
          relatorioId: this.relatorio.id,
          userId: this.user.id,
          observacoesAvaliacao: this.observacoesAvaliacao
        }
      }).$promise.then((result) => {
        wait.close();
        this.UI.addToast("Avaliação registada com sucesso.");
        if (result.relatorio) {

          this.sendToEmail(result);

          // /////////////////////// Add this if removing previous sendToEmail()
          // if (result.nextId) {
          //   this.$state.go('app.susene.relatorios.details', {id: result.nextId});
          // } else { // No next relatorio to evaluate
          //   this.$state.go('app.susene.relatorios.list');
          // }
          // //////////////////////////////////////////////////////////////////

        } else {
          if (result.nextId) {
            this.$state.go('app.susene.relatorios.details', {id: result.nextId});
          } else { // No next relatorio to evaluate
            this.$state.go('app.susene.relatorios.list');
          }
        }
      }).catch(error => {
        wait.close();
        console.log(error);
        this.UI.showAlert("Erro na avaliação de relatório. Recarregue a página e verifique se já foi avaliado.");
      });
    }).catch(() => {
      // Do nothing
    });
  };

  nextDraft = () => {
    let wait = this.UI.showWaiting();
    this.SusEneProcessoRelatorioDraft.nextDraft({
      params: {
        draftId: this.relatorio.id
      }
    }).$promise.then((result) => {
      wait.close();
      if (result.nextId) {
        this.$state.go('app.susene.relatorios.details', {id: result.nextId});
      } else { // No next relatorio to evaluate
        this.$state.go('app.susene.relatorios.list');
      }
    }).catch((error) => {
      wait.close();
      console.log(error);
      this.UI.addToast("Erro na busca de próximo relatório.");
      this.$state.go('app.susene.relatorios.list');
    });
  };

}

SusEneDetailRelatoriosController.$inject = ['$q', '$filter', '$state', 'UIService', 'AuthenticationService',  'SusEneService', 'SusEneProcessoRelatorioDraft', 'SusEneProcessoRelatorio'];
