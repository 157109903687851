export default class DetalhesCondominioController {
  constructor($state, UIService, $http, ElvCondominio) {
    this.UI = UIService;
    this.$http = $http;
    this.id = $state.params.id;
    this.ElvCondominio = ElvCondominio;
    this.loaded = false;
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.ElvCondominio.findOne({
      filter: {
        where: {
          active: true,
          id: this.id,
        },
        include:
          {
            relation: 'Processo',
            scope: {
              where: {
                active: true
              },
              include: [{
                relation: 'elevadorPed',
                scope: {
                  where: {
                    active: true
                  }
                }
              },
                {
                  relation: 'condominio',
                  scope: {
                    where: {
                      active: true
                    }
                  }
                },
                {
                  relation: 'estado',
                  scope: {
                    where: {
                      active: true
                    }
                  }
                },
              ]
            }
          }
      }
    }).$promise.then(res => {
      this.data = res;
      this.formatZipCode();
      this.loaded = true;
    })
  };

  formatZipCode = () => {
    if (this.data.cp4 === null) {
      this.data.formatedZipCode = "N/D";
    } else if (this.data.cp3 === null) {
      this.data.formatedZipCode = this.data.cp4 + " " + this.data.localidade;
    } else {
      this.data.formatedZipCode = this.data.cp4 + "-" + ('000' + this.data.cp3).slice(-3) + " " + this.data.localidade;
    }
  };

  editCondominio = () => {
    let ElvCondominio = this.ElvCondominio;
    let condominio = angular.copy(this.data);
    let options = {
      size: 'lg',
      template: require('./edit-condominio.dialog.html'),
      controller: ['$scope', '$dialog', '$http', ($scope, $dialog, $http) => {

        $scope.condominio = {
          id: condominio.id,
          nome: condominio.nome,
          nif: condominio.nif,
          email: condominio.email,
          telemovel: condominio.telemovel,
          morada: condominio.morada,
          cp4: condominio.cp4,
          cp3: condominio.cp3,
          localidade: condominio.localidade,
          observacoes: condominio.observacoes,
          active: true
        };
        $scope.checkingNif = false;
        $scope.nifExists = false; // Since we are editing, assume it's ok
        $scope.nifMessage = null;
        $scope.originalNif = $scope.condominio.nif;

        $scope.checkNif = () => {
          // If we are checking for the one that was already there, don't need to go to database
          if ($scope.condominio.nif === $scope.originalNif){
            $scope.nifExists = false;
            $scope.nifMessage = null;
            return;
          }

          $scope.checkingNif = true;
          if (_.isEmpty($scope.condominio.nif)) {
            $scope.checkingNif = false;
            $scope.nifExists = false;
            $scope.nifMessage = null;
            return;
          }
          ElvCondominio.count({
            where: {
              nif: $scope.condominio.nif,
              active: 1
            }
          }).$promise.then((res) => {
            $scope.checkingNif = false;
            if (res.count !== 0) {
              $scope.nifExists = true;
              $scope.nifMessage = "Este NIF já se encontra registado para outro condomínio.";
            } else {
              $scope.nifExists = false;
              $scope.nifMessage = null;
            }
          }).catch((error) => {
            $scope.checkingNif = false;
            console.log(error);
            $scope.nifMessage = "Não foi possível verificar existência de NIF.";
          });
        };

        $scope.getLocalidade = function () {
          $scope.condominio.localidade = "";
          let cp4 = parseInt($scope.condominio.cp4);
          let cp3 = parseInt($scope.condominio.cp3);
          if (!Number.isInteger(cp4)) {
            $scope.condominio.cp3 = "";
          } else if (cp4.toString().length !== 4) {
            $scope.condominio.cp3 = "";
          } else if (cp4.toString().length === 4) {
            if (Number.isInteger(cp3)) {
              if ($scope.condominio.cp3.toString().length === 3) {
                $http.post('/api/public/getLocalidade', {
                  cp4: $scope.condominio.cp4,
                  cp3: $scope.condominio.cp3
                }).then(response => {
                  $scope.condominio.localidade = response.data.result;
                });
              }
            } else {
              $http.post('/api/public/getLocalidade', {
                cp4: $scope.condominio.cp4,
                cp3: null
              }).then(response => {
                $scope.condominio.localidade = response.data.result;
              });
            }
          }
        };

        $scope.isOk = () => {
          let o = $scope.condominio;
          return o.nome && o.morada && !$scope.checkingNif && $scope.nifMessage == null && !$scope.nifExists;
        };

        $scope.ok = () => {
          $dialog.close($scope.condominio);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }],
    };
    let modal = this.UI.showDialog(options);
    modal.then(res => {
      if (res) {
        let cp4 = parseInt(res.cp4);
        let cp3 = parseInt(res.cp3);
        if (Number.isNaN(cp4)) {
          res.cp4 = null;
          res.cp3 = null;
        } else {
          res.cp4 = cp4;
        }
        if (Number.isNaN(cp3)) {
          res.cp3 = null;
        } else {
          res.cp3 = cp3;
        }
        this.ElvCondominio.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Condomínio atualizado com sucesso!');
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro a atualizar o condomínio.');
        });
      }
    });
  };

  editObservacoes = () => {
    let c = angular.copy(this.data);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {
        $scope.observacoes = c.observacoes;

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.ok = () => {
          $dialog.close($scope.observacoes);
        }
      }]
    });
    dialog.then((res) => {
        c.observacoes = res;
        c.$save().then(() => {
          this.loadData();
          this.loaded = false;
          this.UI.addToast('EMIE atualizado com sucesso!');
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro ao atualizar EMIE.');
        });
      }
    );
  };
}

DetalhesCondominioController.$inject = ['$state', 'UIService', '$http', 'ElvCondominio'];
