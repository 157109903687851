let XLSX = require('xlsx');
import _, { result } from 'lodash';
import moment from 'moment';
import 'regenerator-runtime/runtime'

export default class OblImporterController {
  constructor($q, $http, $scope, $dialog, FileUploader, FuncionarioEntidadeProprietaria, OblCliente, OblAssocServico, OblValencia, AuthenticationService, OblOrdemintervencao, OblProcesso, OblAgendamento, OblAlteracaoestado) {
    this.$q = $q;
    this.$http = $http;
    this.$scope = $scope;
    this.$dialog = $dialog;
    this.FuncionarioEntidadeProprietaria = FuncionarioEntidadeProprietaria;
    this.OblCliente = OblCliente;
    this.OblAssocServico = OblAssocServico;
    this.OblValencia = OblValencia;
    this.OblOrdemintervencao = OblOrdemintervencao;
    this.OblProcesso = OblProcesso;
    this.OblAgendamento = OblAgendamento;
    this.OblAlteracaoestado = OblAlteracaoestado;
    this.Auth = AuthenticationService;
    this.uploader = new FileUploader({
      url: '/',
      queueLimit: 1
    });

    // Variáveis de controlo
    this.step = 1; // 1 - Carreg. ficheiro, 2 - Validar dados, 3 - Submissão de dados, 4 - Concluído, 5 - Erros encontrados
    this.messages = []; // Caso haja algum problema, serão adicionadas mensagens com o erro
    this.ignored = 0; // Numero de linhas ignoradas (é ignorado quando a data de agendamento < data de hoje)
    this.data = [];
    this.aux = {};

    this.uploader.filters.push({
      name: 'isSheet',
      fn: function (item, options) {
        return '|application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|'.indexOf(item.type) !== -1;
      }
    });

    this.uploader.onAfterAddingFile = file => {
      let reader = new FileReader();
      reader.onload = e => {
        let data = e.target.result;
        let wb = XLSX.read(data, {
          type: 'binary',
          cellDates: true
        });
        if (wb.SheetNames.length > 0) {
          let rowObj = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });
          // Remove linhas vazias
          _.remove(rowObj, c => c.length == 0);
          // Remove primeira linha (cabeçalho)
          rowObj.shift();
          let data = [];
          rowObj.forEach(row => {
            // Valida a linha (Verifica se os dados existem)
            let result = this.validate(row);
            if (result.success) {
              if (result.ignore == false) {
                this.data.push(result.row);
              } else {
                this.ignored++;
              }
            } else {
              if (this.messages.find(r => r == result.message) == undefined) {
                this.messages.push(result.message);
              }
            }
          });
          if (this.messages.length > 0) {
            this.changeStep(5);
          } else {
            console.log("Linhas encontradas:" + this.data.length);
            console.log("Linhas por ignorar:" + this.ignored);
            console.log("Nenhum problema encontrado na leitura do ficheiro");
            this.changeStep(2);
          }
        } else {
          this.messages.push("O ficheiro submetido não tem folhas para importar");
          this.changeStep(5);
        }
      }
      reader.readAsBinaryString(file._file);
    }

    this.loadData();
  }

  changeStep = val => {
    try {
      this.$scope.$apply(() => {
        this.step = val;
      });
    } catch (e) {
      this.step = val;
    }
  }

  ok = () => {
    if (this.step == 2) {
      // Se step = 2, então começa importação
      this.import();
    } else {
      this.cancel();
    }
  }

  // Carrega toda a informação necessária para registar o pedido (Funcionários, Valencias, Servicos, ...)
  loadData = () => {
    this.loaded = false;
    this.FuncionarioEntidadeProprietaria.find({
      filter: {
        where: {
          entidadeProprietariaId: 3,
          active: true
        },
        include: 'funcionario'
      }
    }).$promise.then(r => {
      this.tecnicos = [];
      r.forEach(f => {
        if (f.funcionario) {
          this.tecnicos.push(f.funcionario);
        }
      });
      this.OblCliente.find({
        filter: {
          where: {
            active: 1,
            parceiro: 1
          }
        }
      }).$promise.then(r => {
        this.clientes = r;
        this.OblAssocServico.find({
          filter: {
            where: {
              active: 1
            }
          }
        }).$promise.then(r => {
          this.tarefas = r;
          this.OblValencia.find({
            filter: {
              where: {
                active: 1
              }
            }
          }).$promise.then(r => {
            this.valencias = r;
            this.loaded = true;
          }).catch(e => { });
        }).catch(e => { });

      }).catch(e => {

      });
    });
  }

  cancel = () => {
    this.$dialog.dismiss();
  }

  // Recebe linha e valida campos necessários e substitui os campos de relação por id's
  validate = (row) => {
    // Tipo de tarefa
    let tarefa = this.tarefas.find(r => r.codigo.toUpperCase() == row[1].toUpperCase());
    if (tarefa != undefined) {
      row[1] = tarefa.tipoId;
    } else {
      return {
        success: false,
        message: `Serviço "${row[1]}" não registado`
      }
    }
    // Valência
    let valencia = this.valencias.find(r => r.codigo.toUpperCase() == row[3].toUpperCase());
    if (valencia != undefined) {
      row[3] = valencia.id;
    } else {
      return {
        success: false,
        message: `Valência "${row[3]}" não registada`
      }
    }
    // Funcionário (Se existir)
    if (row[6] != undefined && row[6].length != 0) {
      let funcionario = this.tecnicos.find(r => r.email.toUpperCase() == row[6].toUpperCase());
      if (funcionario != undefined) {
        row[6] = funcionario.id;
      } else {
        return {
          success: false,
          message: `Nenhum funcionário com o e-mail "${row[6]}" encontrado`
        }
      }
    }
    // Data de agendamento (Se existir)
    if (row[4] != undefined && row[4].length != 0) {
      let date = moment.utc(row[4], "DD/MM/YYYY HH:mm");
      if (date.isValid()) {
        if (date.isBefore(moment.utc(), "day")) {
          return {
            success: true,
            ignore: true
          }
        } else {
          row[4] = date;
        }
      } else {
        return {
          success: false,
          message: `Processo ${row[0]} com formato de data errado!`
        }
      }
    }

    // Converte também a data de criação de tarefa
    if (row[13].length != 0) {
      row[13] = moment.utc(row[13], "DD/MM/YYYY HH:mm");
    } else {
      row[13] = moment.utc();
    }
    return {
      success: true,
      ignore: false,
      row: row
    }
  }

  // Recebe linha excel pura e trata os dados para um unico objeto JSON
  parse = async (row) => {
    let data = {
      parceiro: row[0],
      tipoId: row[1],
      valenciaId: row[3],
      data: row[13],
      cliente: {
        nome: row[9],
        nif: "",
        telemovel: row[11],
        email: row[12]
      },
      morada: {
        rua: row[7],
        cp4: 0,
        cp3: 0,
        localidade: "",
        concelho: "",
        distrito: ""
      },
      agendamento: {
        exists: (row[4] != undefined && row[4].length != 0) || false,
        data: row[4],
        hora: moment.utc(row[4]).format("HH:mm"),
        tecnicoId: row[6],
      }
    };
    // Remove caracteres de texto do NIF cliente (Geralmente vem PTxxxxxxxxx)
    if (row[10]) {
      data.cliente.nif = ("" + row[10]).match(/\d+/g).join([]);
    }
    // Código Postal
    let cps = row[8].split("-"); //Separa XXXX-XXX para [XXXX,XXX]
    let postalData = await this.getLocalidade(cps[0], cps[1]);
    // Dados morada
    data.morada.cp4 = Number.parseInt(cps[0]) || 0;
    data.morada.cp3 = Number.parseInt(cps[1]) || 0;
    data.morada.localidade = postalData.result || "";
    data.morada.concelho = postalData.concelho || "";
    data.morada.distrito = postalData.distrito || "";
    // Devolve objeto JSON com toda a info precisa
    return data;
  }

  getLocalidade = (cp4, cp3) => {
    let defer = this.$q.defer();
    cp4 = parseInt(cp4);
    cp3 = parseInt(cp3);
    if (!Number.isInteger(cp4)) {
      cp3 = "";
    } else if (cp4.toString().length !== 4) {
      cp3 = "";
    } else if (cp4.toString().length === 4) {
      if (Number.isInteger(cp3)) {
        this.$http.post('/api/public/getLocalidade', {
          cp4: cp4,
          cp3: cp3
        }).then(r => {
          defer.resolve(r.data);
        }).catch(e => {
          console.log(e);
          defer.resolve("-");
        });
      } else {
        this.$http.post('/api/public/getLocalidade', {
          cp4: cp4,
          cp3: null
        }).then(r => {
          defer.resolve(r.data);
        }).catch(e => {
          defer.resolve("-");
        });
      }
    }
    return defer.promise;
  };

  // Carrega OI com base no numero de parceiro, se existe devolve OI + info processos e agendamentos
  checkAndLoadOI = (parceiro) => {
    let defer = this.$q.defer();
    this.OblOrdemintervencao.find({
      filter: {
        where: {
          parceiro: parceiro
        },
        limit: 1,
        include: {
          relation: 'Processos',
          scope: {
            limit: 1,
            where: {
              active: true,
            }, include: {
              relation: 'Agendamento',
              scope: {
                where: {
                  active: true
                }
              }
            }
          }
        }
      }
    }).$promise.then(r => {
      if (r.length == 0) {
        defer.resolve(undefined);
      } else {
        defer.resolve(r[0]);
      }
    }).catch(e => {
      defer.resolve(undefined);
    });
    return defer.promise;
  }

  saveOI = (row) => {
    if (row.id == 0) {
      return this.OblOrdemintervencao.create(row).$promise;
    } else {
      return this.OblOrdemintervencao.upsert(row).$promise;
    }

  }

  saveProcesso = (row) => {
    if (row.id == 0) {
      return this.OblProcesso.create(row).$promise;
    } else {
      return this.OblProcesso.upsert(row).$promise;
    }
  }

  saveAgendamento = (row) => {
    if (row.id == 0) {
      return this.OblAgendamento.create(row).$promise;
    } else {
      return this.OblAgendamento.upsert(row).$promise;
    }
  }

  saveAlteracaoEstado = (row) => {
    return this.OblAlteracaoestado.create(row).$promise;
  }

  create = async (row, stat) => {
    // 1) Cria OI
    let oi;
    try {
      oi = await this.saveOI({
        id: 0, // Novo ID na BD
        origem: 2, // Significa que é importado via Excel (isto é, origem != plataforma pedidos)
        visitas: 0,
        data: row.data,
        parceiro: row.parceiro,
        estadoId: 14, // Estado: "Importação de ficheiro",
        clienteId: this.aux.selected.id, // Cliente parceiro selecionado atrás
        valenciaId: row.valenciaId, // Valência
        observacoes: `Importado a ${moment.utc().format("DD-MM-YYYY [às] HH:mm")}`,
        active: 1,
        morada: row.morada.rua,
        cp4: row.morada.cp4,
        cp3: row.morada.cp3,
        localidade: row.morada.localidade,
        concelho: row.morada.concelho,
        distrito: row.morada.distrito,
        nomeCliente: row.cliente.nome,
        nifCliente: row.cliente.nif,
        tlmCliente: row.cliente.telemovel,
        emailCliente: row.cliente.email,
      });
      stat.result += 1000;
    } catch (e) {
      return;
    }
    await this.createProcessoAndAgendamento(row, oi, 14, stat);
  }

  checkAgendamento = async (processoId) => {
    return this.OblAgendamento.find({
      filter: {
        where: {
          processoId: processoId,
          active: 1
        },
        limit: 1
      }
    }).$promise;
  }

  createProcessoAndAgendamento = async (row, oi, state, stat) => {
    // Cria processo
    let processo;
    try {
      processo = await this.saveProcesso({
        id: 0,
        oiId: oi.id,
        dataAlteracao: moment.utc(),
        criadoPor: this.Auth.getId(),
        tipoId: row.tipoId,
        data: row.data,
        active: 1
      });
      stat.result += 100;
    } catch (e) {
      console.log(e);
      return;
    }
    // mapeia OI para o estado certo
    if (row.tipoId == 2) {
      oi.estadoId = 4;
    } else {
      oi.estadoId = 9;
    }
    // Agenda processo (caso exista)
    try {
      await this.saveAgendamento({
        id: 0,
        processoId: processo.id,
        data: row.agendamento.data,
        hora: row.agendamento.hora,
        tecnicoId: row.agendamento.tecnicoId,
        agendadoa: row.data,
        agendadoporId: this.Auth.getId(),
        duracao: 60,
      });
      stat.result += 10;
    } catch (e) {
      console.log(e);
      return;
    }
    // Cria alterações de estado
    try {
      await this.saveAlteracaoEstado({
        id: 0,
        dataAlteracao: moment.utc(),
        oiId: oi.id,
        funcionarioId: this.Auth.getId(),
        estadoInicial: state, // Importação de ficheiro
        estadoFinal: oi.estadoId,
        observacoes: `Importação de ficheiro a ${moment.utc().format("DD-MM-YYYY [às] HH:mm")}`,
        active: 1,
      });
      stat.result += 1;
    } catch (e) {
      console.log(e);
      return;
    }
  }

  update = async (row, oldOI, stat) => {
    // 1) Atualiza dados da OI
    let oi;
    try {
      oi = await this.saveOI({
        id: oldOI.id, // ID registado na BD
        origem: 2, // Significa que é importado via Excel (isto é, origem != plataforma pedidos)
        data: row.data,
        estadoId: oldOI.estadoId, // Estado atual da OI
        clienteId: this.aux.selected.id, // Cliente parceiro selecionado atrás
        valenciaId: row.valenciaId, // Valência
        observacoes: `Atualizado a ${moment.utc().format("DD-MM-YYYY [às] HH:mm")}`,
        active: 1,
        morada: row.morada.rua,
        cp4: row.morada.cp4,
        cp3: row.morada.cp3,
        localidade: row.morada.localidade,
        concelho: row.morada.concelho,
        distrito: row.morada.distrito,
        nomeCliente: row.cliente.nome,
        nifCliente: row.cliente.nif,
        tlmCliente: row.cliente.telemovel,
        emailCliente: row.cliente.email,
      });
      stat.result += 1000;
    } catch (e) {
      console.log(e);
      return;
    }
    // 2) Verifica processos
    let oiProcessos = await this.OblProcesso.find({
      filter: {
        where: {
          oiId: oi.id,
          active: 1
        },
        limit: 1,
        order: 'id DESC'
      }
    }).$promise;
    // 2.1) Caso não exista nenhum cria
    if (oiProcessos.length == 0) {
      await this.createProcessoAndAgendamento(row, oi, 14, stat);
    } else {
      if (oiProcessos[0].validado == false) {
        // Caso exista processo e não esteja validado, atualiza dados do processo + agendamento
        let processo;
        let oldProcesso = angular.copy(oiProcessos[0]);
        // Atualiza dados do processo
        try {
          processo = await this.saveProcesso({
            id: oldProcesso.id,
            oiId: oi.id,
            dataAlteracao: moment.utc(),
            criadoPor: this.Auth.getId(),
            tipoId: row.tipoId,
            data: row.data,
            active: 1
          });
          stat.result += 100;
        } catch (e) {
          console.log(e);
          return;
        }
        // Verifica se existe agendamento e mapeia OI para o estado certo
        if (row.agendamento.exists) {
          if (row.tipoId == 2) {
            oi.estadoId = 4;
          } else {
            oi.estadoId = 9;
          }
        }
        // Agenda processo (caso exista)
        try {
          let agendamento = await this.checkAgendamento(processo.id);
          if (agendamento.length > 0) {
            agendamento = agendamento[0];
            await this.saveAgendamento({
              id: agendamento.id,
              processoId: processo.id,
              data: row.agendamento.data,
              hora: row.agendamento.hora,
              tecnicoId: row.agendamento.tecnicoId,
              agendadoa: row.data,
              agendadoporId: this.Auth.getId(),
              duracao: 60,
              active: 1
            });
          } else {
            await this.saveAgendamento({
              id: 0,
              processoId: processo.id,
              data: row.agendamento.data,
              hora: row.agendamento.hora,
              tecnicoId: row.agendamento.tecnicoId,
              agendadoa: row.data,
              agendadoporId: this.Auth.getId(),
              duracao: 60,
              active: 1
            });
          }
          stat.result += 10;
        } catch (e) {
          console.log(e);
          return;
        }
        // Cria alterações de estado
        try {
          await this.saveAlteracaoEstado({
            id: 0,
            dataAlteracao: moment.utc(),
            oiId: oi.id,
            funcionarioId: this.Auth.getId(),
            estadoInicial: oldOI.estadoId, // Importação de ficheiro
            estadoFinal: oi.estadoId,
            observacoes: `Importação de ficheiro a ${moment.utc().format("DD-MM-YYYY [às] HH:mm")}`,
            active: 1,
          });
          stat.result += 1;
        } catch (e) {
          return;
        }
      } else {
        // Caso contrário, cria novo processo + agendamento
        await this.createProcessoAndAgendamento(row, oi, oldOI.estadoId, stat);
      }
    }
  }

  download = () => {
    let o = angular.copy(this.status);
    o.forEach(r => {
      r.result = this.explainStat(r.result);
    });
    let csv = o.map(d => Object.values(d).join()).join("\n");
    console.log(csv);
    csv.unshift("Nº Parceiro", "Resultado");
    this.UI.addToast("A iniciar download, por favor aguarde...");
    let csvContent = "data:text/csv;charset=utf-8," + csv;
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "resultado.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  import = async () => {
    this.changeStep(3);
    this.done = 0;
    this.status = [];
    for (let line of this.data) {
      let row = await this.parse(line);
      let stat = {
        parceiro: row.parceiro,
        result: 0
      }
      let oi = await this.checkAndLoadOI(row.parceiro);
      if (oi != undefined) {
        await this.update(row, oi, stat);
      } else {
        await this.create(row, stat);
      }
      this.status.push(stat);
      try {
        this.$scope.$apply(() => {
          this.done++;
        });
      } catch (e) {
        this.done++;
      }
    }
    console.log(this.status);
    this.changeStep(4);
  }

  explainStat = value => {
    if (value == 0) {
      return "Falha na importação";
    }
    if (value == 1000) {
      return "Importação com falhas (Em falta: Processo + agendamento)";
    }
    if (value == 1100) {
      return "Importação com falhas (Em falta: Agendamento)";
    }
    if (value == 1110) {
      return "Importação com falhas (Em falta: Alteração de estado)";
    }
    return "Importação bem-sucedida";
  }
}

OblImporterController.$inject = ['$q', '$http', '$scope', '$dialog', 'FileUploader', 'FuncionarioEntidadeProprietaria', 'OblCliente', 'OblAssocServico', 'OblValencia', 'AuthenticationService', 'OblOrdemintervencao', 'OblProcesso', 'OblAgendamento', 'OblAlteracaoestado'];
