export default class DirectorySchedulingElevadoresController {
  constructor($rootScope, $state, $filter, $q, ElvProcessoArtigo, ElvCliente, ElvEmie, ElvCondominio, ElvElevador, ElvProcesso, ElvAgendamento, ElvEstado, ElvAlteracaoEstado, ElvProcessoDocumento, Funcionario, Group, AuthenticationService, NgTableParams, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.user = AuthenticationService.getUser();
    this.ElvCliente = ElvCliente;
    this.ElvEmie = ElvEmie;
    this.ElvElevador = ElvElevador;
    this.ElvCondominio = ElvCondominio;
    this.ElvProcessoArtigo = ElvProcessoArtigo;
    this.NgTableParams = NgTableParams;
    this.opt = $state.params;
    this.UI = UIService;
    this.ElvProcesso = ElvProcesso;
    this.ElvAgendamento = ElvAgendamento;
    this.ElvAlteracaoEstado = ElvAlteracaoEstado;
    this.ElvProcessoDocumento = ElvProcessoDocumento;
    this.Funcionario = Funcionario;
    this.Group = Group;
    this.ElvEstado = ElvEstado;
    this.totalByState = [];
    this.listOpt = [];
    this.selected = 0;
    this.select = false;
    this.clientes = [];
    this.emies = [];
    this.condominios = [];
    this.artigos = [];
    this.inspetores = [];
    this.stateId = this.opt.state;
    this.stateIndex = -1;
    this.allSelected = false;
    this.tableLoading = true;
    this.statesLoading = true;
    this.toSchedule = [];
    this.inScheduling = [];
    this.scheduled = [];
    this.delayed = [];

    // If loadData() finished or not
    this.dataLoaded = false;
    // If loadData() fails for some reason
    this.errorLoadingData = false;

    this.defaultOpt = {
      page: 1,
      items: 20,
      state: 1,
      order: "id",
      sort: "desc",
      filter: undefined
    };
    this.columns = [{
      id: 'ElvProcesso.clienteId',
      name: 'Cliente',
      type: 's',
      list: this.clientes
    },
      {
        id: 'ElvProcesso.numeroProcesso',
        name: 'Nº Processo',
        type: 'o'
      },
      {
        id: 'ElvProcesso.emieId',
        name: 'EMIE',
        type: 's',
        list: this.emies
      },
      {
        id: 'ElvProcesso.condominioId',
        name: 'Condominio',
        type: 's',
        list: this.condominios
      },
      {
        id: 'ElvProcesso.artigoId',
        name: 'Artigo',
        type: 's',
        list: this.artigos
      },
      {
        id: 'ElvProcesso.inspetorId',
        name: 'Inspetor',
        type: 's',
        list: this.inspetores
      },
      {
        id: 'ElvElevador.morada',
        name: 'Morada',
        type: 'o'
      },
      {
        id: 'ElvElevador.numeroCamarario',
        name: 'Nº Camarário',
        type: 'o'
      },
      {
        id: 'ElvElevador.codigoPostal',
        name: 'Código Postal',
        type: 'o'
      },
    ];
    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('ELVAgendamentosFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('ELVAgendamentosFilter'));
    }

    if (this.opt.filter) {
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('ELVAgendamentosFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'ElvProcesso.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'ElvProcesso.emieId')
          f.column.selected.list = this.emies;
        if (f.column.selected.id === 'ElvProcesso.condominioId')
          f.column.selected.list = this.condominios;
        if (f.column.selected.id === 'ElvProcesso.artigoId')
          f.column.selected.list = this.artigos;
        if (f.column.selected.id === 'ElvProcesso.inspetorId')
          f.column.selected.list = this.inspetores;
      }
    });

    this.pedidos = null;

    this.loadData();
    this.getEstados();
  }

  clearFilter = () => {
    localStorage.removeItem('ELVAgendamentosFilter');
    this.customFilters = [];
    this.getPedidos();
  };

  // Returns true if anything is selected in Agendamentos of 'type'
  hasItemsSelected = () => {
    return _.some(this.pedidos, (r) => r.selected === true);
  };

  // Returns number of selected items from table of 'type'
  countSelected = (type) => {
    return _.filter(this.pedidos, (r) => r.selected === true).length;
  };

  // Returns what's selected in array of items
  getSelected = (items) => {
    return _.filter(items, (r) => {
      if (r.selected === true)
        return r;
    });
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('ELVAgendamentosFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    this.getPedidos();
  };

  getEstados = () => {
    this.statesLoading = true;
    this.ElvEstado.find({
      filter: {
        where: {
          active: true,
          id: {
            inq: [1, 2]
          }
        }
      }
    }).$promise.then((res) => {
      this.estadosPedido = res;
      this.estadosPedido.forEach(e => {
        let o = angular.copy(this.defaultOpt);
        o.state = e.id;
        this.listOpt.push(o);
      });

      // stateIndex is the index of the state in the array
      this.stateIndex = this.estadosPedido.findIndex(e => e.id === this.stateId);
      // If we can't find the index, use the first one
      if (this.stateIndex === -1) {
        this.stateId = this.estadosPedido[0].id;
        this.stateIndex = 0;
      }
      this.getCountPedidosEstado();

    }).catch((error) => {
      console.log(error);
      this.UI.addToast("De momento não é possível ver processos. Por favor tente mais tarde.");
    });
  };

  getCountPedidosEstado = () => {
    let tasks = [];
    this.estadosPedido.forEach(e => {
      let defer = this.$q.defer();
      this.ElvProcesso.count({
        fields: {
          id: true
        },
        where: {
          estadoId: e.id,
          active: true
        }
      }).$promise.then((n) => {
        this.totalByState[e.id] = n.count;
        defer.resolve(e.id);
      }).catch(e => {
        defer.reject(e.id);
      });
      tasks.push(defer.promise);
    });
    this.$q.all(tasks).then((res) => {
      // If we are loading states still, we can now show the information
      if (this.statesLoading)
        this.statesLoading = false;
    }).catch(e => {
      // Do something when at least one count fails
      console.log(e);
    });
  };

  agendarPedido = (pedido) => {
    let itens = [];

    if (_.isEmpty(pedido)) {
      itens = this.getSelected(this.pedidos);
    } else {
      itens = pedido;
    }
    if (itens.length === 0) {
      return;
    }

    if (itens.length > 20) {
      let alert = this.UI.showAlert("Por favor selecione no máximo 20 processos para agendar de cada vez para a mesma data para o mesmo inspetor.");
      alert.finally(() => {});
    } else {
      // Get good data for modal to be pre-filled if data is appropriate
      let modalData = this.preFillCorrectModalData(itens);
      let cenas = this.UI.showWaiting();
      this.Group.findOne({
        filter: {
          where: {
            name: {
              like: 'Inspetor%ELV'
            }
          },
          include: {
            relation: 'usergroup',
            scope: {
              include: 'user'
            }
          }
        }
      }).$promise.then((g) => {
        let funcionarios = [];
        g.usergroup.forEach(u => {
          funcionarios.push(u.user);
        });

        funcionarios = _.orderBy(funcionarios, 'name', 'asc');

        // agenda is a variable to hold common data (like dataInspeccao)
        let agenda = {};
        agenda.dataInspeccao = modalData.data;
        // Format date for modal
        if (agenda.dataInspeccao != null)
          agenda.dataInspeccao = moment(agenda.dataInspeccao).format('YYYY-MM-DD');

        cenas.close();
        let instance = this.UI.showDialog({
          size: 'md',
          template: require('./modal-edit-agendamento.html'),
          controller: ['$scope', '$dialog', ($scope, $dialog) => {
            $scope.agenda = agenda;
            $scope.pedidos = itens;
            $scope.funcionarios = funcionarios || [];
            $scope.auxFuncionario = {
              selected: modalData.tecnico,
              infiniteScroll: {
                numToAdd: 20,
                currentItems: 20
              }
            };

            // Infinite Scroll magic
            $scope.addMoreItems = (infiniteScroll) => {
              infiniteScroll.currentItems += infiniteScroll.numToAdd;
            };

            $scope.ok = () => {
              // Check if it's valid dates
              let validDates = true;
              if (!$scope.agenda.dataInspeccao.isValid()) {
                validDates = false;
                $scope.agenda.dataInspeccao = null;
                this.UI.addToast("Data de Inspeção inválida. Preencha novamente");
              }
              for (let i = 0; i < $scope.pedidos.length; i++) {
                if (!$scope.pedidos[i].horaInspeccao.isValid()) {
                  $scope.pedidos[i].horaInspeccao = null;
                  validDates = false;
                  this.UI.addToast("Hora de Inspeção inválida. Preencha novamente");
                }
              }
              if (validDates) {
                $scope.tecnico = $scope.auxFuncionario.selected.id;
                $scope.$close({
                  tecnico: $scope.tecnico,
                  agenda: $scope.agenda,
                  pedidos: $scope.pedidos
                });
              }
            };

            $scope.cancel = () => {
              $scope.pedidos.forEach((p) => {
                p.horaInspeccao = null;
              });
              $dialog.dismiss('cancel');
            };

            $scope.checkDisabled = (a, t) => {
              if (a == null || t == null)
                return true;
              return !a.dataInspeccao;
            }

          }]
        });
        instance.then((agendamento) => {
          let tasks = [];
          this.statesLoading = true;
          agendamento.pedidos.forEach((pedido) => {
            // All deferAgendamento will resolve, so we do not get stuck in one but try all,
            // since the aftermath is the same, reload everything
            let deferAgendamento = this.$q.defer();
            if (!pedido.activeAgendamento) { // If active agendamento exists, do not let it schedule.
              let newDate = new Date();
              this.ElvProcesso.findOne({
                filter: {
                  where: {
                    active: 1,
                    id: pedido.id
                  },
                  include: {
                    relation: 'elevadorPed',
                    scope: {
                      where: {
                        active: true
                      },
                    }
                  },
                }
              }).$promise.then(ped => {
                if (ped.elevadorPed) {
                  // Check if ElvAgendamento object exists or not
                  this.ElvAgendamento.find({
                    filter: {
                      where: {
                        processoId: ped.id
                      },
                      include: 'Pedido'
                    }
                  }).$promise.then(agends => {
                    if (agends && agends.length > 0) { // Agendamento object exists, update it for a new one
                      let agend = agends[0];


                      if (ped.estadoId === 1) { // Por agendar
                        agend.data = moment(agendamento.agenda.dataInspeccao).format('YYYY-MM-DD');
                        agend.hora = pedido.horaInspeccao ? pedido.horaInspeccao.format("HH:mm") : null;
                        agend.tecnicoId = agendamento.tecnico;
                        agend.agendadoa = newDate;
                        agend.agendadoporId = this.user.id;
                        agend.active = 1;

                        // Create Agendamento
                        this.ElvAgendamento.upsert(agend).$promise.then((ag) => {
                          this.ElvProcesso.findOne({
                            filter: {
                              where: {
                                active: 1,
                                id: ag.processoId
                              },
                              include: 'elevadorPed'
                            }
                          }).$promise.then(pedUpdate => {
                            pedUpdate.inspetorId = ag.tecnicoId;
                            pedUpdate.estadoId = 2;
                            pedUpdate.$save().then((res) => {
                              this.ElvAlteracaoEstado.create({
                                id: 0,
                                estadoInicial: 1,
                                estadoFinal: 2,
                                dataAlteracao: new Date(),
                                processoId: res.id,
                                active: true,
                                funcionarioId: this.Auth.getId()
                              }).$promise.then(alteracao => {

                                this.UI.addToast('Processo ' + ped.iidAno + '-' + ped.iidProc + ' agendado.');
                                deferAgendamento.resolve(pedido);

                              }).catch((err) => {
                                console.log(err);
                                this.UI.addToast('Ocorreu um erro a agendar o processo.');
                                deferAgendamento.resolve(pedido);
                              });
                            }).catch(error => {
                              console.log(error);
                              this.UI.addToast('Ocorreu um erro a gravar agendamento do processo.');
                              deferAgendamento.resolve(pedido);
                            });
                          }).catch(error => {
                            console.log(error);
                            this.UI.addToast('Processo a agendar não encontrado. Recarregue a página');
                            deferAgendamento.resolve(pedido);
                          });
                        }).catch(error => {
                          console.log(error);
                          this.UI.addToast('Não foi possível criar agendamento. Verifique a ligação');
                          deferAgendamento.resolve(pedido);
                        });
                      } else { // Processo já agendado(?), update
                        agend.hora = agendamento.hora.format('HH:mm');
                        agend.data = agendamento.data;
                        agend.tecnicoId = agendamento.tecnico;
                        agend.agendadoa = newDate;
                        agend.agendadoporId = this.user.id;
                        this.ElvAgendamento.upsert(agend).$promise.then((a) => {
                          ped.criadoPorId = agend.agendadoporId;
                          this.ElvProcesso.upsert(ped).$promise.then((p) => {
                            this.ElvAlteracaoEstado.create({
                              id: 0,
                              estadoInicial: 1,
                              estadoFinal: 2,
                              dataAlteracao: new Date(),
                              processoId: res.id,
                              active: true,
                              funcionarioId: this.Auth.getId()
                            }).$promise.then(alteracao => {

                              this.UI.addToast('Processo ' + ped.iidAno + '-' + ped.iidProc + ' agendado.');
                              deferAgendamento.resolve(pedido);

                            }).catch((err) => {
                              console.log(err);
                              this.UI.addToast('Ocorreu um erro a agendar o processo (Alteração de Estado).');
                              deferAgendamento.resolve(pedido);
                            });
                          }).catch((err) => {
                            console.log(err);
                            this.UI.addToast('Erro ao obter processo a agendar.');
                            deferAgendamento.resolve(pedido);
                          });
                        }).catch((err) => {
                          console.log(err);
                          this.UI.addToast('Erro ao obter agendamento para atualizar.');
                          deferAgendamento.resolve(pedido);
                        });
                      }
                    } else { // No agendamento object found, assume no agendamento was made, create it
                      let agend = {};
                      agend.id = 0; // To create new
                      agend.processoId = ped.id;
                      agend.data = moment(agendamento.agenda.dataInspeccao).format('YYYY-MM-DD');
                      agend.hora = pedido.horaInspeccao ? pedido.horaInspeccao.format("HH:mm") : null;
                      agend.tecnicoId = agendamento.tecnico;
                      agend.agendadoa = newDate;
                      agend.agendadoporId = this.user.id;
                      agend.active = 1;

                      // Create Agendamento
                      this.ElvAgendamento.upsert(agend).$promise.then((ag) => {
                        this.ElvProcesso.findOne({
                          filter: {
                            where: {
                              active: 1,
                              id: ag.processoId
                            },
                            include: 'elevadorPed'
                          }
                        }).$promise.then(pedUpdate => {
                          pedUpdate.inspetorId = ag.tecnicoId;
                          pedUpdate.estadoId = 2;
                          pedUpdate.$save().then((res) => {
                            this.ElvAlteracaoEstado.create({
                              id: 0,
                              estadoInicial: 1,
                              estadoFinal: 2,
                              dataAlteracao: new Date(),
                              processoId: res.id,
                              active: true,
                              funcionarioId: this.Auth.getId()
                            }).$promise.then(alteracao => {

                              this.UI.addToast('Processo ' + ped.iidAno + '-' + ped.iidProc + ' agendado.');
                              deferAgendamento.resolve(pedido);


                            }).catch((err) => {
                              console.log(err);
                              this.UI.addToast('Ocorreu um erro a agendar o processo.');
                              deferAgendamento.resolve(pedido);
                            });
                          }).catch(err => {
                            console.log(err);
                            this.UI.addToast('Ocorreu um erro a guardar agendamento. (Guardar processo)');
                            deferAgendamento.resolve(pedido);
                          });
                        }).catch(err => {
                          console.log(err);
                          this.UI.addToast('Ocorreu um erro a guardar agendamento. (Carregar processo)');
                          deferAgendamento.resolve(pedido);
                        });
                      }).catch(err => {
                        console.log(err);
                        this.UI.addToast('Ocorreu um erro a guardar agendamento.');
                        deferAgendamento.resolve(pedido);
                      });
                    }
                  }).catch(error => {
                    console.log(error);
                    this.UI.addToast("Não foi possível procurar agendamentos.");
                    deferAgendamento.resolve(pedido);
                  });
                } else {
                  this.UI.addToast('Erro ao editar agendamento. Tem que associar um elevador ao processo primeiro!');
                  deferAgendamento.resolve(pedido);
                }
              }).catch((err) => {
                console.log(err);
                this.UI.addToast('Ocorreu um erro a agendar o processo.');
                deferAgendamento.resolve(pedido);
              });
            } else {
              this.UI.addToast('O pedido já se encontra agendado.');
              deferAgendamento.resolve(pedido);
            }
            tasks.push(deferAgendamento.promise);
          });

          this.$q.all(tasks).then((res) => { // Reload stuff
            this.getPedidos();
            this.getEstados();
          }).catch(err => { // Shouldn't happen, but we need to reload anyway
            console.log(err);
            this.getPedidos();
            this.getEstados();
          });
        });
      }).catch(err => {
        console.log(err);
        this.UI.addToast("Não foi possível carregar inspetores. Tente novamente");
      });
    }
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  getPedidos = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {
      'ElvProcesso.active': 1,
      'ElvProcesso.estadoId': this.opt.state
    });

    this.ElvProcesso.table({
      params: {
        fields: [
          'ElvProcesso.id as id',
          'ElvProcesso.numeroProcesso as numProcesso',
          'ElvProcesso.iid_ano as iidAno',
          'ElvProcesso.iid_proc as iidProc',
          'ElvProcesso.dataProcesso as data_processo',
          'ElvElevador.id as elevadorId',
          'ElvElevador.numeroCamarario as numCamarario',
          'ElvElevador.morada as morada',
          'ElvElevador.cp_4 as cp4',
          'ElvElevador.cp_3 as cp3',
          'ElvCliente.id as clienteId',
          'ElvCliente.nome as cliente',
          'ElvEmie.id as emieId',
          'ElvEmie.nome as emie',
          'ElvCondominio.id as condominioId',
          'ElvCondominio.nome as condominio',
          'ElvProcessoArtigo.codigo as artigoCodigo',
          'Funcionario.name as funcionario',
          'Funcionario.id as funcionarioId',
          'ElvAgendamento.data as data',
          'ElvAgendamento.hora as hora',
          'ElvAgendamento.active as activeAgendamento'
        ],
        from: ['ElvProcesso', 'ElvElevador', 'ElvCliente', 'ElvEmie', 'ElvCondominio', 'ElvEstado', 'ElvProcessoArtigo', 'ElvAgendamento', 'Funcionario'],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'ElvAgendamento.processoId', 'ElvAgendamento.tecnicoId'],
        references: [undefined, 'ElvProcesso.elevadorId', 'ElvProcesso.clienteId', 'ElvProcesso.emieId', 'ElvProcesso.condominioId', 'ElvProcesso.estadoId', 'ElvProcesso.artigoId', 'ElvProcesso.id', 'Funcionario.id'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      // let total = this.totalByState[this.opt.state];
      let total = res.count;
      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      res.data.forEach(i => {
        if (i.cp4 && !i.cp3) {
          i.codigoPostal = i.cp4;
        }
        if (i.cp4 && i.cp3) {
          i.codigoPostal = `${('0000' + i.cp4).slice(-4)}-${('000' + i.cp3).slice(-3)}`;
        }
        if (i.cp3 !=  null && !isNaN(i.cp3))
          i.cp3 = ('000' + i.cp3).slice(-3);
      });

      this.getCountPedidosEstado();
      this.pedidos = res.data;
      this.total = total;
      this.tableLoading = false;
      this.statesLoading = false;
      this.allSelected = false;
    }).catch((error) => {
      console.log(error);
    });
  };

  loadData = () => {
    this.ElvCliente.find({
      filter: {
        where: {
          active: true
        },
        order: 'nome ASC'
      }
    }).$promise.then(clientes => {
      clientes.forEach(t => {
        t.name = t.nome;
      });
      this.clientes = clientes;
      this.ElvEmie.find({
        filter: {
          where: {
            active: true
          },
          order: 'nome ASC'
        }
      }).$promise.then(emies => {
        emies.forEach(e => {
          e.name = e.nome;
        });
        this.emies = emies;
        this.ElvProcessoArtigo.find({
          filter: {
            where: {
              active: true
            },
            order: 'codigo ASC'
          }
        }).$promise.then(artigos => {
          artigos.forEach(e => {
            e.name = e.codigo;
          });
          this.artigos = artigos;
          this.ElvCondominio.find({
            filter: {
              where: {
                active: true
              },
              order: 'nome ASC'
            }
          }).$promise.then((condominios) => {
            condominios.forEach(c => {
              c.name = c.nome;
            });
            this.condominios = condominios;
            this.Funcionario.find({
              filter: {
                where: {
                  active: true
                },
                order: 'name ASC'
              }
            }).$promise.then((inspetores) => {
              inspetores.forEach(c => {
                c.name = c.name;
              });
              this.inspetores = inspetores;
              this.columns.forEach(f => {
                if (f.id === 'ElvProcesso.clienteId')
                  f.list = this.clientes;
                if (f.id === 'ElvProcesso.emieId')
                  f.list = this.emies;
                if (f.id === 'ElvProcesso.condominioId')
                  f.list = this.condominios;
                if (f.id === 'ElvProcesso.inspetorId')
                  f.list = this.inspetores;
                if (f.id === 'ElvProcesso.artigoId')
                  f.list = this.artigos;
              });
              // Everything is loaded
              this.dataLoaded = true;
            }).catch(e => {
              console.log(e);
              this.errorLoadingData = true;
              this.UI.addToast("Erro de carregamento de dados para filtragem (técnicos). Recarregue a página.");
            });
          }).catch(e => {
            console.log(e);
            this.errorLoadingData = true;
            this.UI.addToast("Erro de carregamento de dados para filtragem (Condominios). Recarregue a página.");
          });
        }).catch(e => {
          console.log(e);
          this.errorLoadingData = true;
          this.UI.addToast("Erro de carregamento de dados para filtragem (artigos). Recarregue a página.");
        });
      }).catch(e => {
        console.log(e);
        this.errorLoadingData = true;
        this.UI.addToast("Erro de carregamento de dados para filtragem (EMIEs). Recarregue a página.");
      });
    }).catch(e => {
      console.log(e);
      this.errorLoadingData = true;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Clientes). Recarregue a página.");
    });
  };


  // Change request state to something else
  setAgendamentoToProcessoAndUpdate = (pid, value) => {
    this.statesLoading = false;

    // Show progressbar indicator to load data
    this.schedulingLoad = true;

    this.ElvProcesso.findOne({
      filter: {
        where: {
          id: pid,
          active: 1
        }
      }
    }).$promise.then((res) => {
      if (res != null) {
        let newDate = new Date();
        let oldState = res.estadoId;
        res.inspetorId = null;
        res.estadoId = value;
        this.ElvProcesso.upsert(res).$promise.then((p) => {
          this.ElvAlteracaoEstado.create({
            id: 0,
            dataAlteracao: newDate,
            processoId: res.id,
            estadoInicial: oldState,
            estadoFinal: value,
            funcionarioId: this.user.id,
            observacoes: "Agendamento removido",
            active: 1
          }).$promise.then((alteracao) => {
            this.getPedidos();
            this.statesLoading = false;
          }).catch((err) => {
            console.log(err);
          });
        });
      }
    });
  };

  preFillCorrectModalData = (pedidos) => {
    let data = null;
    let hora = null;
    let tecnico = null;

    if (pedidos.length === 0)
      return {
        data: null,
        hora: null,
        tecnico: null
      };

    if (pedidos[0].Agendamento == null)
      return {
        data: null,
        hora: null,
        tecnico: null
      };

    data = pedidos[0].Agendamento.data;
    tecnico = pedidos[0].Agendamento.Funcionario;
    if (pedidos.length > 1)
      hora = null;
    else {
      hora = pedidos[0].Agendamento.hora || undefined;
    }

    for (let i = 1; i < pedidos.length; i++) {
      if (pedidos[i].Agendamento == null)
        return {
          data: null,
          hora: null,
          tecnico: null
        };
      if (data !== pedidos[i].Agendamento.data)
        data = null;
      if (tecnico != null && tecnico.id !== pedidos[i].Agendamento.Funcionario.id)
        tecnico = null;
    }
    return {
      data: data,
      hora: hora,
      tecnico: tecnico
    }
  };

  // // Export multiple agendamentos at once of 'type'
  // // (empty argument; via this.toSchedule) or only one (coming as argument item)
  exportAgendamentos = (pedido) => {
    let itens = [];
    // If item is empty, we are using selection
    if (_.isEmpty(pedido)) {
      itens = this.getSelected(this.pedidos);
    } else {
      itens = pedido;
    }

    // Send pedidos ID to remote method to get the export
    let postData = {
      pedidos: _.map(itens, 'id')
    };
    this.ElvAgendamento.selectiveExport(postData).$promise.then(result => {
      let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + result.fileData;
      let b = document.createElement('a');
      b.href = url;
      b.download = 'agendamentosSelecionados.xlsx';
      b.click();
    });
  };

  // Reset agendamentos
  resetAgendamentos = (type, item) => {
    let itens = [];

    if (_.isEmpty(item)) {
      itens = this.getSelected(this.pedidos);
    } else {
      itens = item;
    }
    // No point in going if we have no data
    if (itens.length === 0)
      return;

    let instance = this.UI.showDialog({
      template: require('./modal-confirm-agendamento-reset.html'),
      controller: ['$scope', function ($scope) {
        if (itens.length > 1) {
          $scope.resetInfo = "dos " + itens.length + " agendamentos selecionados";
        } else {
          $scope.resetInfo = "do agendamento selecionado";
        }

        $scope.ok = function () {
          $scope.$close('ok');
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      itens.forEach((pedido) => {
        this.ElvAgendamento.findOne({
          filter: {
            where: {
              active: 1,
              processoId: pedido.id
            }
          }
        }).$promise.then((res) => {
          // If we have a result, "delete" it.
          if (!_.isEmpty(res)) {
            // Remove old files
            this.removeMarcacoesOficios(pedido.id).then(r => {
              res.active = 0;
              res.agendadoporId = this.user.id;
              res.$save().then((err) => {
                this.setAgendamentoToProcessoAndUpdate(pedido.id, 1);
                this.statesLoading = false;
                this.UI.addToast("Agendamento desmarcado");
                this.getPedidos();
              }).catch(e => {
                this.UI.showAlert("Erro na desmarcação de agendamento de processo " + pedido.id);
              });
            }).catch(e => {
              this.UI.showAlert("Não foi possível remover ficheiro de marcação/ofício. Recarregue a página");
            });
          } else {
            // No Agendamento available
          }
        })
      }, (err) => {
        // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
        if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
          console.log(err);
      });
    });
  };

  // Remove Marcações Ofícios from Processo no longer used (active = 0)
  removeMarcacoesOficios = (processoId) => {
    let defer = this.$q.defer();
    this.ElvProcessoDocumento.find({
      filter: {
        where: {
          and: [{active: 1}, {processoId: processoId}, {tipoDocumento: {inq: [6, 9]}}] // Marcação, Ofício de Marcação
        }
      }
    }).$promise.then(docs => {
      let tasks = [];
      for (let i = 0; i < docs.length; i++) {
        let deferTask = this.$q.defer();
        docs[i].active = 0;
        this.ElvProcessoDocumento.upsert(docs[i]).$promise.then(d => {
          deferTask.resolve(d);
        }).catch(e => {
          deferTask.reject(e);
        });
        tasks.push(deferTask.promise);
      }

      this.$q.all(tasks).then((res) => {
        defer.resolve(res);
      }).catch(error => {
        defer.reject(error);
      });
    }).catch(error => {
      defer.reject(error);
    });
    return defer.promise;
  };

  openFilter = () => {
    // Check if we can open
    if (this.errorLoadingData) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    if (!this.dataLoaded) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'ElvProcesso.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'ElvProcesso.emieId')
          f.column.selected.list = this.emies;
        if (f.column.selected.id === 'ElvProcesso.condominioId')
          f.column.selected.list = this.condominios;
        if (f.column.selected.id === 'ElvProcesso.inspetorId')
          f.column.selected.list = this.inspetores;
      }
    });

    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  selectTab = (i) => {
    this.listOpt[this.stateIndex] = angular.copy(this.opt);
    this.stateIndex = i;
    this.stateId = this.estadosPedido[i].id;
    this.opt = angular.copy(this.listOpt[this.stateIndex]);
    this.opt.page = 1;

    this.$state.go('app.elv.agendamentos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getPedidos();
  };

  sort = keyname => {
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.elv.agendamentos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getPedidos();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.elv.agendamentos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getPedidos();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.totalByState[this.stateId] / this.opt.items))
      this.opt.page = Math.ceil(this.totalByState[this.stateId] / this.opt.items);
    this.$state.go('app.elv.agendamentos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getPedidos();
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.pedidos.forEach(a => {
        a.selected = true;
      });
    } else { // Remove all selections
      this.pedidos.forEach(a => {
        a.selected = false;
      });
    }
  };

  selectItem = () => {
    let nSelected = _.filter(this.pedidos, r => r.selected).length;
    this.allSelected = nSelected >= this.pedidos.length;
  };

};

DirectorySchedulingElevadoresController.$inject = ['$rootScope', '$state', '$filter', '$q', 'ElvProcessoArtigo', 'ElvCliente', 'ElvEmie', 'ElvCondominio', 'ElvElevador', 'ElvProcesso', 'ElvAgendamento', 'ElvEstado', 'ElvAlteracaoEstado', 'ElvProcessoDocumento', 'Funcionario', 'Group', 'AuthenticationService', 'NgTableParams', 'UIService'];
