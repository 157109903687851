export function routes($stateProvider) {
    $stateProvider
      .state('app.rh.avaliacoes', {
        url: '/avaliacoes',
        abstract: true
      })
      .state('app.rh.avaliacoes.list', {
        url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
        title: 'Avaliações',
        template: '<rh-avaliacao-list-view></rh-avaliacao-list-view>',
        role: ['$authenticated','$internal'],
        params: {
          state: {
            value: 1,
            dynamic: true
          },
          page: {
            value: 1,
            dynamic: true
          },
          items: {
            value: 20,
            dynamic: true
          },
          order: {
            value: 'id',
            dynamic: true
          },
          sort: {
            value: 'desc',
            dynamic: true
          },
          filter: {
            dynamic: true
          }
        }
      })
      .state('app.rh.avaliacoes.formCreate', {
        url: '/adicionar',
        title: 'Nova avaliação',
        template: '<rh-avaliacao-form></rh-avaliacao-form>',
        role: ['$authenticated','$internal'],
      })
      .state('app.rh.avaliacoes.formEdit', {
        url: '/editar/{id:string}',
        title: 'Editar avaliação',
        template: '<rh-avaliacao-form></rh-avaliacao-form>',
        role: ['$authenticated','$internal'],
      });
  }
  
  routes.$inject = ['$stateProvider'];
  