export default class AtvInteracoesListController {
  constructor($filter, $q, $state, AuthenticationService, AtvAtivosService, Group, AtvInteracao, AtvAtivo, AtvLocal, AtvFamilia, AtvTipoAtivo, Funcionario, AtvInteracaoTipo, AtvFornecedor, UIService) {
    this.$filter = $filter;
    this.$q = $q;
    this.$state = $state;
    this.user = AuthenticationService.getUser();
    this.AtvAtivosService = AtvAtivosService;
    this.Group = Group;
    this.AtvInteracao = AtvInteracao;
    this.AtvAtivo = AtvAtivo;
    this.AtvLocal = AtvLocal;
    this.AtvFamilia = AtvFamilia;
    // this.AtvTipoAtivo = AtvTipoAtivo;
    this.Funcionario = Funcionario;
    this.AtvInteracaoTipo = AtvInteracaoTipo;
    this.AtvFornecedor = AtvFornecedor;
    this.UI = UIService;
    this.opt = $state.params;
    this.interacoes = [];
    this.interacoesTipo = [];
    this.locais = [];
    this.familias = [];
    // this.tiposAtivo = [];
    this.funcionarios = [];
    this.fornecedores = [];

    this.simnao = [{name: "Sim", value: 1}, {name: "Não", value: 0}];

    // If loadData() finished or not
    this.dataLoaded = false;
    // If loadData() fails for some reason
    this.errorLoadingData = false;

    this.listOpt = [];
    // Total processes for currently being displayed
    this.total = 0;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "dataRegisto",
      sort: "desc",
      filter: undefined
    };

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [
      {val: 'a', name: 'Igual', type: 'o'},
      {val: 'b', name: 'Diferente', type: 'o'},
      {val: 'c', name: 'Começa por', type: 'o'},
      {val: 'd', name: 'Termina com', type: 'o'},
      {val: 'e', name: 'Contém', type: 'o'},
      {val: 'a', name: 'Igual (=)', type: 'd'},
      {val: 'b', name: 'Diferente de (≠)', type: 'd'},
      {val: 'c', name: 'Posterior a (>)', type: 'd'},
      {val: 'd', name: 'Anterior a (<)', type: 'd'},
      {val: 'e', name: 'Posterior ou igual (≥)', type: 'd'},
      {val: 'f', name: 'Anterior ou igual (≤)', type: 'd'},
      {val: 'a', name: 'Igual', type: 's'},
      {val: 'b', name: 'Diferente', type: 's'}
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: 'AtvAtivo.iidDisplay',
        name: 'ID Ativo',
        type: 'o'
      },
      {
        id: 'AtvInteracao.interacaoTipoId',
        name: 'Tipo de Interação',
        type: 's',
        list: this.interacoesTipo
      },
      {
        id: 'AtvInteracao.dataInteracao',
        name: 'Data de Interação',
        type: 'd',
        format: "YYYY-MM-DD HH:mm:ss",
        displayFormat: "DD/MM/YYYY HH:mm"
      },
      {
        id: 'AtvInteracao.dataRegisto',
        name: 'Data de Registo',
        type: 'd',
        format: "YYYY-MM-DD HH:mm:ss",
        displayFormat: "DD/MM/YYYY HH:mm"
      },
      {
        id: 'AtvInteracao.localId',
        name: 'Local',
        type: 's',
        list: this.locais
      },
      {
        id: 'AtvAtivo.familiaId',
        name: 'Família de Ativo',
        type: 's',
        list: this.familias
      },
      // {
      //   id: 'AtvAtivo.tipoAtivoId',
      //   name: 'Tipo de Ativo',
      //   type: 's',
      //   list: this.tiposAtivo
      // },
      {
        id: 'AtvInteracao.funcionarioId',
        name: 'Realizada por',
        type: 's',
        list: this.funcionarios
      },
      {
        id: 'AtvInteracao.fornecedorExterno',
        name: 'Fornecedor Externo',
        type: 's',
        list: this.simnao
      },
      {
        id: 'AtvFornecedorPrimavera.fornecedorId',
        name: 'Fornecedor',
        type: 's',
        list: this.fornecedores
      },
      {
        id: 'AtvInteracao.observacoes',
        name: 'Observações',
        type: 'o'
      },
      {
        id: 'AtvInteracao.atribuidoaId',
        name: 'Atribuído a',
        type: 's',
        list: this.funcionarios
      }
    ];

    this.displayColumns = [
      {displayName: 'ID SGI', name: 'id', visible: false},
      {displayName: 'ID Ativo', name: 'iidDisplay', visible: true},
      {displayName: 'Data de Registo', name: 'dataRegisto', exportDate: 'DD-MM-YYYY HH:mm:ss', visible: false},
      {displayName: 'Data de Interação', name: 'dataInteracao', exportDate: 'DD-MM-YYYY HH:mm:ss', visible: true},
      {displayName: 'Tipo de Interação', name: 'interacaoTipo', visible: true},
      {displayName: 'Resultado', name: 'resultado', visible: true},
      {displayName: 'Motivo de Devolução', name: 'motivoDevolucao', visible: false},
      {displayName: 'Familia do Ativo', name: 'familia', visible: true},
      // {displayName: 'Tipo do Ativo', name: 'tipoAtivo', visible: true},
      {displayName: 'Local', name: 'local', visible: true},
      {displayName: 'Realizado por', name: 'colaborador', visible: true},
      {displayName: 'Fornecedor Externo', name: 'fornecedorExterno', visible: false},
      {displayName: 'Fornecedor', name: 'fornecedor', visible: false},
      {displayName: 'Observações', name: 'observacoes', visible: false},
      {displayName: 'Atribuído a', name: 'atribuidoa', visible: true}
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('ATVGestaoInteracoesDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('ATVGestaoInteracoesDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('ATVGestaoInteracoesFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('ATVGestaoInteracoesFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('ATVGestaoInteracoesFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'AtvInteracao.interacaoTipoId')
          f.column.selected.list = this.interacoesTipo;
        if (f.column.selected.id === 'AtvInteracao.localId')
          f.column.selected.list = this.locais;
        if (f.column.selected.id === 'AtvAtivo.familiaId')
          f.column.selected.list = this.familias;
        // if (f.column.selected.id === 'AtvAtivo.tipoAtivoId')
        //   f.column.selected.list = this.tiposAtivo;
        if (f.column.selected.id === 'AtvInteracao.funcionarioId')
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === 'AtvAtivo.atribuidoaId')
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === 'AtvInteracao.fornecedorExterno')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'AtvFornecedorPrimavera.fornecedorId')
          f.column.selected.list = this.fornecedores;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.newGetInteracoes();
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('ATVGestaoInteracoesDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  newGetInteracoes = () => {
    this.tableLoading = true;
    let whereFields = this.AtvAtivosService.setWhereFields(this.customFilters, {'AtvInteracao.active': 1});
    this.AtvInteracao.table({
      params: {
        fields: [ // edit exportInteracoes() too
          'AtvInteracao.id as id',
          'AtvAtivo.id as ativoId',
          'AtvAtivo.iidDisplay as iidDisplay',
          'AtvInteracaoTipo.designacao as interacaoTipo',
          'AtvResultado.resultado as resultado',
          'AtvInteracao.dataRegisto as dataRegisto',
          'AtvInteracao.dataInteracao as dataInteracao',
          'AtvInteracao.motivoDevolucao as motivoDevolucao',
          'AtvInteracao.fornecedorExterno as fornecedorExterno',
          'AtvInteracao.observacoes as observacoes',
          // 'AtvTipoAtivo.designacao as tipoAtivo',
          'AtvFamilia.designacao as familia',
          'AtvLocal.designacao as local',
          'Funcionario.name as colaborador',
          'Funcionario2.name as atribuidoa',
          'AtvFornecedor.nome as fornecedor',
          'AtvFornecedorPrimavera.fornecedorId as fornecedorId' // Just for url links to fornecedor
        ],
        from: ['AtvInteracao', 'AtvInteracaoTipo', 'AtvResultado', 'AtvAtivo', /*'AtvTipoAtivo',*/ 'AtvFamilia', 'AtvLocal', 'Funcionario', 'Funcionario', 'AtvFornecedorPrimavera', 'AtvFornecedor'],
        references: [undefined, 'AtvInteracao.interacaoTipoId', 'AtvInteracao.resultadoId', 'AtvInteracao.ativoId', /*'AtvAtivo.tipoAtivoId',*/ 'AtvAtivo.familiaId', 'AtvInteracao.localId', 'AtvInteracao.funcionarioId', 'AtvInteracao.atribuidoa_id', 'AtvInteracao.fornecedorPrimaveraId', 'AtvFornecedorPrimavera.fornecedorId'],
        referencesOrigin: [undefined, undefined, undefined, undefined, /*undefined,*/ undefined, undefined, undefined, undefined, undefined, 'AtvFornecedor.id'],
        aliases: [null, null, null, null, /*null,*/ null, null, null, 'Funcionario2', null, null],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process dates for output
      res.interacoes.forEach(i => {
        i.dataRegisto = moment(i.dataRegisto).utc().format("DD/MM/YYYY HH:mm:ss");
        i.dataInteracao = moment(i.dataInteracao).utc().format("DD/MM/YYYY HH:mm:ss");
      });

      this.interacoes = res.interacoes;
      this.total = total;
      this.tableLoading = false;

    }).catch((e) => {
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível carregar interações com os parâmetros fornecidos.");
    });
  };

  exportInteracoes = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.AtvAtivosService.setWhereFields(this.customFilters, {'AtvInteracao.active': 1});
    this.AtvInteracao.exportInteracoes({
      displayColumns: this.displayColumns,
      params: {
        fields: [ // edit newGetInteracoes() too
          'AtvInteracao.id as id',
          'AtvAtivo.id as ativoId',
          'AtvAtivo.iidDisplay as iidDisplay',
          'AtvInteracaoTipo.designacao as interacaoTipo',
          'AtvResultado.resultado as resultado',
          'AtvInteracao.dataRegisto as dataRegisto',
          'AtvInteracao.dataInteracao as dataInteracao',
          'AtvInteracao.motivoDevolucao as motivoDevolucao',
          'AtvInteracao.fornecedorExterno as fornecedorExterno',
          'AtvInteracao.observacoes as observacoes',
          // 'AtvTipoAtivo.designacao as tipoAtivo',
          'AtvFamilia.designacao as familia',
          'AtvLocal.designacao as local',
          'Funcionario.name as colaborador',
          'Funcionario2.name as atribuidoa',
          'AtvFornecedor.nome as fornecedor'
        ],
        from: ['AtvInteracao', 'AtvInteracaoTipo', 'AtvResultado', 'AtvAtivo', /*'AtvTipoAtivo',*/ 'AtvFamilia', 'AtvLocal', 'Funcionario', 'Funcionario', 'AtvFornecedorPrimavera', 'AtvFornecedor'],
        references: [undefined, 'AtvInteracao.interacaoTipoId', 'AtvInteracao.resultadoId', 'AtvInteracao.ativoId', /*'AtvAtivo.tipoAtivoId',*/ 'AtvAtivo.familiaId', 'AtvInteracao.localId', 'AtvInteracao.funcionarioId', 'AtvInteracao.atribuidoa_id', 'AtvInteracao.fornecedorPrimaveraId', 'AtvFornecedorPrimavera.fornecedorId'],
        referencesOrigin: [undefined, undefined, undefined, undefined, /*undefined,*/ undefined, undefined, undefined, undefined, undefined, 'AtvFornecedor.id'],
        aliases: [null, null, null, null, /*null,*/ null, null, null, 'Funcionario2', null, null],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'interacoesExportadas.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar interações. Por favor tente mais tarde.");
    });
  };


  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few ativos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.interacoes, x => x.selected), x => {return {key: 'AtvInteracao.id', value: x.id};});
    let whereFields = this.AtvAtivosService.setWhereFields(this.customFilters, {'AtvInteracao.active': 1}, orsLiteral);
    this.AtvInteracao.exportInteracoes({
      displayColumns: this.displayColumns,
      params: {
        fields: [ // edit newGetInteracoes() too
          'AtvInteracao.id as id',
          'AtvAtivo.id as ativoId',
          'AtvAtivo.iidDisplay as iidDisplay',
          'AtvInteracaoTipo.designacao as interacaoTipo',
          'AtvResultado.resultado as resultado',
          'AtvInteracao.dataRegisto as dataRegisto',
          'AtvInteracao.dataInteracao as dataInteracao',
          'AtvInteracao.motivoDevolucao as motivoDevolucao',
          'AtvInteracao.fornecedorExterno as fornecedorExterno',
          'AtvInteracao.observacoes as observacoes',
          // 'AtvTipoAtivo.designacao as tipoAtivo',
          'AtvFamilia.designacao as familia',
          'AtvLocal.designacao as local',
          'Funcionario.name as colaborador',
          'Funcionario2.name as atribuidoa',
          'AtvFornecedor.nome as fornecedor'
        ],
        from: ['AtvInteracao', 'AtvInteracaoTipo', 'AtvResultado', 'AtvAtivo', /*'AtvTipoAtivo',*/ 'AtvFamilia', 'AtvLocal', 'Funcionario', 'Funcionario', 'AtvFornecedorPrimavera', 'AtvFornecedor'],
        references: [undefined, 'AtvInteracao.interacaoTipoId', 'AtvInteracao.resultadoId', 'AtvInteracao.ativoId', /*'AtvAtivo.tipoAtivoId',*/ 'AtvAtivo.familiaId', 'AtvInteracao.localId', 'AtvInteracao.funcionarioId', 'AtvInteracao.atribuidoa_id', 'AtvInteracao.fornecedorPrimaveraId', 'AtvFornecedorPrimavera.fornecedorId'],
        referencesOrigin: [undefined, undefined, undefined, undefined, /*undefined,*/ undefined, undefined, undefined, undefined, undefined, 'AtvFornecedor.id'],
        aliases: [null, null, null, null, /*null,*/ null, null, null, 'Funcionario2', null, null],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'interacoesExportadas.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar interações. Por favor tente mais tarde.");
    });
  };

  loadData = () => {
    this.Funcionario.find({filter: {where: {active: true}, order: 'name ASC'}}).$promise.then(funcionarios => {
      // funcionarios.forEach(t => {
      //   t.name = t.designacao;
      // });
      this.funcionarios = funcionarios;
      this.AtvFamilia.find({
        filter: {
          where: {active: true},
          order: 'ordem ASC'
        }
      }).$promise.then(familias => {
        familias.forEach(t => {
          t.name = t.designacao;
        });
        this.familias = familias;
        // this.AtvTipoAtivo.find({
        //   filter: {
        //     where: {active: true},
        //     order: 'designacao ASC'
        //   }
        // }).$promise.then(tiposAtivo => {
        //   tiposAtivo.forEach(t => {
        //     t.name = t.designacao;
        //   });
        //   this.tiposAtivo = tiposAtivo;
          this.AtvInteracaoTipo.find({
            filter: {
              where: {active: true},
              order: 'ordem ASC'
            }
          }).$promise.then(interacoesTipo => {
            interacoesTipo.forEach(t => {
              t.name = t.designacao;
            });
            this.interacoesTipo = interacoesTipo;
            this.AtvLocal.find({
              filter: {
                where: {active: true},
                order: 'entidadeProprietariaId ASC, designacao ASC'
              }
            }).$promise.then((locais) => {
              locais.forEach(e => {
                e.name = e.designacao;
              });
              this.locais = locais;
              this.AtvFornecedor.find({
                filter: {
                  where: {active: true}
                }
              }).$promise.then((fornecedores) => {
                fornecedores.forEach(e => {
                  e.name = e.nome;
                });
                fornecedores.sort((a, b) => a.name.localeCompare(b.name));
                this.fornecedores = fornecedores;

                this.columns.forEach(f => {
                  if (f.id === 'AtvInteracao.interacaoTipoId')
                    f.list = this.interacoesTipo;
                  if (f.id === 'AtvInteracao.localId')
                    f.list = this.locais;
                  if (f.id === 'AtvAtivo.familiaId')
                    f.list = this.familias;
                  // if (f.id === 'AtvAtivo.tipoAtivoId')
                  //   f.list = this.tiposAtivo;
                  if (f.id === 'AtvInteracao.funcionarioId')
                    f.list = this.funcionarios;
                  if (f.id === 'AtvInteracao.atribuidoaId')
                    f.list = this.funcionarios;
                  if (f.id === 'AtvInteracao.fornecedorExterno')
                    f.list = this.simnao;
                  if (f.id === 'AtvFornecedorPrimavera.fornecedorId')
                    f.list = this.fornecedores;
                });
                // Everything is loaded
                this.dataLoaded = true;
              }).catch(e => {
                console.log(e);
                this.errorLoadingData = true;
                this.UI.addToast("Erro de carregamento de dados para filtragem (Fornecedores). Recarregue a página.");
              });
            }).catch(e => {
              console.log(e);
              this.errorLoadingData = true;
              this.UI.addToast("Erro de carregamento de dados para filtragem (Locais). Recarregue a página.");
            });
          }).catch(e => {
            console.log(e);
            this.errorLoadingData = true;
            this.UI.addToast("Erro de carregamento de dados para filtragem (Tipos de Interação). Recarregue a página.");
          });
        // }).catch(e => {
        //   console.log(e);
        //   this.errorLoadingData = true;
        //   this.UI.addToast("Erro de carregamento de dados para filtragem (Tipos de Ativo). Recarregue a página.");
        // });
      }).catch(e => {
        console.log(e);
        this.errorLoadingData = true;
        this.UI.addToast("Erro de carregamento de dados para filtragem (Famílias). Recarregue a página.");
      });
    }).catch(e => {
      console.log(e);
      this.errorLoadingData = true;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Colaboradores). Recarregue a página.");
    });
  };

  openFilter = () => {
    // Check if we can open
    if (this.errorLoadingData) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    if (!this.dataLoaded) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'AtvInteracao.interacaoTipoId')
          f.column.selected.list = this.interacoesTipo;
        if (f.column.selected.id === 'AtvInteracao.localId')
          f.column.selected.list = this.locais;
        if (f.column.selected.id === 'AtvAtivo.familiaId')
          f.column.selected.list = this.familias;
        // if (f.column.selected.id === 'AtvAtivo.tipoAtivoId')
        //   f.column.selected.list = this.tiposAtivo;
        if (f.column.selected.id === 'AtvInteracao.funcionarioId')
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === 'AtvAtivo.atribuidoaId')
          f.column.selected.list = this.funcionarios;
        if (f.column.selected.id === 'AtvAtivo.fornecedorExterno')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'AtvFornecedorPrimavera.fornecedorId')
          f.column.selected.list = this.fornecedores;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  clearFilter = () => {
    localStorage.removeItem('ATVGestaoInteracoesFilter');
    this.customFilters = [];
    this.newGetInteracoes();
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('ATVGestaoInteracoesFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.ativos.interacoes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.newGetInteracoes();
  };

  hasSelect = () => {
    return _.some(this.interacoes, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.interacoes.forEach(a => {a.selected = true;});
      this.nSelected = this.interacoes.length;
    } else { // Remove all selections
      this.interacoes.forEach(a => {a.selected = false;});
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.interacoes.forEach(a => {a.selected = false;});
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.interacoes, r => r.selected).length;
    this.allSelected = this.nSelected >= this.interacoes.length;
    this.everythingSelected = false;
  };

  sort = keyname => {
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.ativos.interacoes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.newGetInteracoes();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.ativos.interacoes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.newGetInteracoes();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.ativos.interacoes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.newGetInteracoes();
  };

  viewInteracao = r => {
    this.$state.go('app.ativos.interacoes.details', {id: r.id});
  };

  addInteracao = () => {
    let options = {
      size: 'md',
      template: require('./add.interacao.ativo.dialog.html'),
      controller: ['$dialog', '$scope', 'AtvAtivo', (dialog, scope, AtvAtivo) => {
        scope.title = "Escolher Ativo para Interação";
        scope.atv = null;
        scope.ativo = null;
        scope.notFound = "ID de Ativo não encontrado";
        scope.noNet = "Não foi possível verificar existência de ativo";
        scope.abatido = "Ativo encontra-se abatido, logo não sujeito a interações";
        scope.errorMessage = null;

        scope.checkAtivo = () => {
          if (scope.atv && scope.atv.length > 3)
            AtvAtivo.find({
              filter: {
                fields: {id: true, entidadeProprietariaId: true, iidDisplay: true, dataAbate: true},
                where: {
                  iidDisplay: scope.atv,
                  active: 1
                }
              }
            }).$promise.then((ativos) => {
              if (ativos && ativos.length === 1) {
                if (ativos[0].dataAbate) {
                  scope.ativo = null;
                  scope.errorMessage = scope.abatido;
                } else {
                  scope.ativo = ativos[0];
                  scope.errorMessage = null;
                }
              } else {
                scope.ativo = null;
                scope.errorMessage = scope.notFound;
              }
            }).catch((error) => {
              console.log(error);
              scope.ativo = null;
              scope.errorMessage = scope.noNet;
            });
        };

        scope.ok = () => {
          dialog.close(scope.ativo);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((ativo) => {
      if (ativo) {
        this.AtvAtivo.findOne({
          filter: {
            where: {
              id: ativo.id,
              active: 1
            },
            include: [{
              relation: 'entidadeProprietaria',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'ultimaInteracao',
              scope: {
                where: {
                  active: 1
                }
              }
            }]
          }
        }).$promise.then((a) => {
          this.addInteracaoToAtivo(a);
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível encontrar ativo.");
        });
      }
    });
  };

  addInteracaoToAtivo = (x) => {
    this.AtvInteracao.getUsableInteracoesTipo({
      ativoId: x.id
    }).$promise.then((interacoesTipo) => {
      let options = this.AtvAtivosService.addInteracaoModalOptions(x, interacoesTipo);
      let modal = this.UI.showDialog(options);
      modal.then((res) => {
        this.tableLoading = true;
        if (res) {
          this.AtvAtivosService.afterAddInteracao(res, x.id).then(i => {
            this.newGetInteracoes();
          }).catch(err => {
            console.log(err);
            this.tableLoading = false;
          });
        }
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível obter tipos de interação. Por favor tente mais tarde");
    });
  };

}

AtvInteracoesListController.$inject = ['$filter', '$q', '$state', 'AuthenticationService', 'AtvAtivosService', 'Group', 'AtvInteracao', 'AtvAtivo', 'AtvLocal', 'AtvFamilia', 'AtvTipoAtivo', 'Funcionario', 'AtvInteracaoTipo', 'AtvFornecedor', 'UIService'];
