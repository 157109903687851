import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
export function DialogValidacao({ onClose, multiple, ...props }) {
    return (<Dialog {...props} maxWidth={'lg'} fullWidth>
      <DialogTitle>Validar relatório{multiple ? 's' : ''}</DialogTitle>
      <DialogContent>Tem a certeza que pretende validar {multiple ? 'os relatórios selecionados' : 'este relatório'}?</DialogContent>
      <DialogActions>
        <Button type='button' onClick={() => { onClose(undefined); }}>
          Cancelar
        </Button>
        <Button type='button' variant='contained' onClick={() => { onClose(true); }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>);
}
