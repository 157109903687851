export default class ElvElevadorCreationController {
  constructor($scope, $dialog, $timeout, ElvElevador, ElvEquipamentoTipo, ElvElevadorMarca) {
    this.loaded = false;
    this.scope = $scope;
    this.dialog = $dialog;
    this.t = $timeout;
    this.defaults = {};
    // Setups
    this.ElvElevador = ElvElevador;
    this.ElvEquipamentoTipo = ElvEquipamentoTipo;
    this.ElvElevadorMarca = ElvElevadorMarca;
    this.loadData();
    // Defaults
    this.arrays = {
      tipos: [],
      marcas: [],
    };

    this.data = {
      tipo: undefined,
      numeroCamarario: undefined,
      morada: undefined,
      cp4: undefined,
      cp3: undefined,
      localidade: undefined,
      // marca: undefined,
      // modelo: undefined,
      observacoes: undefined
    };
  }

  autofillElevadorFromnumeroCamarario = () => {
    if (this.data.numeroCamarario) {
      this.ElvElevador.findOne({
        filter: {
          where: {
            numeroCamarario: this.data.numeroCamarario,
            active: true
          }
        }
      }).$promise.then(res => {
        if (!this.data.tipo) this.data.tipo = {};
        this.data.tipo = res.tipo;
        this.data.morada = res.morada;
        this.data.cp4 = res.cp4;
        this.data.cp3 = res.cp3;
        this.data.localidade = res.localidade;
        // this.data.modelo = res.modelo;
        this.data.observacoes = res.observacoes;
      }).catch((err) => {
        if (this.data.tipo) this.data.tipo = {};
        this.data.morada = null;
        this.data.cp4 = null;
        this.data.cp3 = null;
        this.data.localidade = null;
        // this.data.modelo = null;
        this.data.observacoes = null;
      });
    } else {
      if (this.data.tipo) this.data.tipo = {};
      this.data.morada = null;
      this.data.cp4 = null;
      this.data.cp3 = null;
      this.data.localidade = null;
      // this.data.modelo = null;
      this.data.observacoes = null;
    }
  };

  loadData = () => {
    this.loaded = false;
    this.ElvEquipamentoTipo.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(tipos => {
      this.defaults.tipos = tipos;
      this.arrays.tipos = tipos;
      this.ElvElevadorMarca.find({
        filter: {
          where: {
            active: true
          }
        }
      }).$promise.then(marcas => {
        this.defaults.marcas = marcas;
        this.arrays.marcas = marcas;
        this.loaded = true;
      })
    });
  };

  isOk = () => {
    return angular.isDefined(this.data.numeroCamarario) && angular.isDefined(this.data.tipo) && angular.isDefined(this.data.morada)
      && angular.isDefined(this.data.cp4) && this.data.cp4 > 0 && this.data.cp3 > 0 && angular.isDefined(this.data.cp3) && angular.isDefined(this.data.localidade);
  };

  ok = () => {
    if (this.loaded) {
      this.dialog.close(this.data);
    } else
      this.dialog.dismiss('cancel');
  };

  cancel = () => {
    this.dialog.dismiss('cancel');
  };
}

ElvElevadorCreationController.$inject = ['$scope', '$dialog', '$timeout', 'ElvElevador', 'ElvEquipamentoTipo', 'ElvElevadorMarca'];
