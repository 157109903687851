export function routes($stateProvider) {
  $stateProvider
    .state('app.funciona', {
      url: '/funciona',
      abstract: true,
      template: '<div ui-view></div>'
    })

    .state('app.funciona.calendario', {
      url: '/calendario?{funcionario:int}&{completed:int}&{open:int}',
      title: 'Calendarização',
      role: ['funcionaVerProcessos'],
      template: require('./calendarizacao/view.html'),
      controller: 'FunCalendarizacaoController',
      controllerAs: 'vm',
      params: {
        completed: {
          value: 1,
          dynamic: true,
        },
        open: {
          value: 1,
          dynamic: true,
        },
      }
    })
    .state('app.funciona.clientes', {
      url: '/clientes',
      abstract: true
    })
    .state('app.funciona.clientes.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Clientes',
      role: ['funcionaVerClientes'],
      template: require('./clientes/list/view.html'),
      controller: 'FunClientesListController',
      controllerAs: 'vm',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.funciona.clientes.details', {
      url: '/{id:int}',
      title: 'Detalhes de Cliente',
      template: require('./clientes/details/view.html'),
      role: ['funcionaVerClientes'],
      controller: 'FunClientesDetailsController',
      controllerAs: 'vm',
    });
}

routes.$inject = ['$stateProvider'];
