import angular from 'angular';

import {routes} from "./requisitantes.routes";
import DirectoryRequisitantesController from "./directory/directory.controller";
import EditRequisitantesController from "./edit/edit.controller";

export default angular.module('app.requisitantes', [])
        .config(routes)
        .controller('DirectoryRequisitantesController', DirectoryRequisitantesController)
        .controller('EditRequisitantesController', EditRequisitantesController)
        .name;
