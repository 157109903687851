import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Breadcrumbs, Checkbox, CircularProgress, Fab, Grid, IconButton, Link, ListItem, ListItemText, Stack, TextField, Typography } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { MdiIcon } from '@components';
import { mdiContentSave } from '@mdi/js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { debounce } from 'lodash';
import moment from 'moment';
const CrmPessoasAddView = ({ $state, CrmService }) => {
    const [nome, setNome] = useState('');
    const [pessoa, setPessoa] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [image, setImage] = useState(null);
    const [newImage, setNewImage] = useState(null);
    // Autocomplete
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [autocompleteLoad, setAutocompleteLoad] = useState(false);
    // redes sociais
    // Parse initial social media links from JSON if available
    const initialSocialMediaLinks = editing && pessoa?.redesSociais ? JSON.parse(pessoa.redesSociais) : {};
    const [socialMediaLinks, setSocialMediaLinks] = useState(initialSocialMediaLinks);
    const fetchData = async () => {
        try {
            const getPessoa = await CrmService.findPessoaById($state.params.id);
            if (getPessoa.clienteId !== 1) {
                let empresa = await CrmService.findEmpresaById(getPessoa.clienteId);
                setSelectedValue({ id: empresa.id, name: empresa.nome });
                setInputValue(empresa.nome);
            }
            setPessoa(getPessoa);
        }
        catch (e) {
            console.log(e);
        }
    };
    // Função fetchOptions com debounce
    const fetchOptions = debounce(async (query, setOptions, setAutocompleteLoad) => {
        if (!query) {
            setOptions([]);
            return;
        }
        setAutocompleteLoad(true);
        try {
            const data = await CrmService.findEmpresaByName(query); // Chama o serviço de busca
            setOptions(data); // Atualiza as opções com os resultados
        }
        catch (error) {
            console.error('Erro ao buscar opções:', error);
            setOptions([]); // Reseta opções em caso de erro
        }
        finally {
            setAutocompleteLoad(false); // Finaliza o carregamento
        }
    }, 300); // 300ms de debounce
    useEffect(() => {
        if ($state.params.id != undefined) {
            if (pessoa === null) {
                setEditing(true);
                fetchData();
            }
            if (pessoa) {
                if (pessoa.userImage) {
                    let imageUrl = `/api/Upload/crm/download/${pessoa.userImage}`;
                    setImage(imageUrl); // Define a imagem existente
                }
                const parsedLinks = JSON.parse(pessoa.redesSociais || '{}');
                setSocialMediaLinks(parsedLinks);
                setNome(pessoa.nome);
                setIsLoading(false);
            }
        }
        else {
            setIsLoading(false);
        }
        if (inputValue) {
            fetchOptions(inputValue, setOptions, setAutocompleteLoad);
        }
        else {
            setOptions([]); // Limpa opções se não houver entrada
        }
    }, [pessoa, inputValue]);
    const socialMediaIcons = [
        { src: 'assets/images/crm_facebook.png', alt: 'Facebook', key: 'facebook' },
        { src: 'assets/images/crm_linkedin.png', alt: 'LinkedIn', key: 'linkedin' },
        { src: 'assets/images/crm_twitter.png', alt: 'Twitter', key: 'twitter' },
        { src: 'assets/images/crm_instagram.png', alt: 'Instagram', key: 'instagram' },
        { src: 'assets/images/crm_youtube.png', alt: 'YouTube', key: 'youtube' }
    ];
    const handleSocialMediaChange = (key, value) => {
        setSocialMediaLinks(prev => {
            const updatedLinks = { ...prev };
            if (!value.trim()) {
                delete updatedLinks[key]; // Remove a rede social se o valor for vazio
            }
            else {
                updatedLinks[key] = value; // Atualiza ou adiciona o valor da rede social
            }
            return updatedLinks;
        });
    };
    const validationSchema = yup.object().shape({
        nome: yup.string().required('O Nome é obrigatório').min(2, 'O nome deve ter pelo menos 2 caracteres').max(255, 'O nome deve ter no máximo 255 caracteres'),
        email: yup.string().required('O Email é obrigatório').email('Formato de email inválido')
    });
    const formik = useFormik({
        initialValues: {
            nome: editing && pessoa ? pessoa.nome : null,
            nif: editing && pessoa ? pessoa.nif : null,
            email: editing && pessoa ? pessoa.email : null,
            contactoTelefonico: editing && pessoa ? pessoa.contactoTelefonico : null,
            observacoes: editing && pessoa ? pessoa.observacoes : null,
            id: editing && pessoa ? pessoa.id : 0,
            refClientePrimavera: editing && pessoa ? pessoa.refClientePrimavera : null,
            dataNascimento: editing && pessoa ? pessoa.dataNascimento : null,
            dataUltimoContacto: editing && pessoa ? pessoa.dataUltimoContacto : null,
            userImage: editing && pessoa ? pessoa.userImage : null,
            blocked: editing && pessoa ? pessoa.blocked : 0,
            cargo: editing && pessoa ? pessoa.cargo : null,
            profissao: editing && pessoa ? pessoa.profissao : null,
            interessesPessoais: editing && pessoa ? pessoa.interessesPessoais : null,
            interessesProfissionais: editing && pessoa ? pessoa.interessesProfissionais : null,
            clienteId: editing && pessoa ? pessoa.clienteId : null,
            redesSociais: editing && pessoa ? pessoa.redesSociais : JSON.stringify({}),
            password: null,
            blockedElv: 0,
            blockedIe: 0,
            blockedFor: 0,
            blockedLab: 0
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            //console.log(values);
            values.redesSociais = JSON.stringify(socialMediaLinks);
            submit(values);
        }
    });
    const handleImage = async (values) => {
        const formData = new FormData();
        if (newImage.file) {
            const newFileName = values.userImage ? values.userImage : newImage.file.name;
            formData.append('file', newImage.file, newFileName);
            // Se a imagem for igual, não realiza upload
            if (newImage.dummy) {
                //console.log(values);
                return values;
            }
            else {
                // Atualiza o valor da imagem no objeto `values`
                values.userImage = newFileName;
                try {
                    // Upload da nova imagem
                    const response = await fetch('/api/Upload/crm/upload', {
                        method: 'POST',
                        body: formData
                    });
                    if (response.ok) {
                        const content = await response.json();
                        return content.result;
                    }
                    else {
                        //console.log(response);
                        console.error('Erro ao adicionar imagem');
                    }
                }
                catch (error) {
                    console.error('Error uploading file:', error);
                }
            }
        }
        else {
            return values;
        }
    };
    const submit = async (newValues) => {
        if (newImage == null) {
            newValues.userImage = '';
        }
        else {
            handleImage(newValues);
        }
        try {
            const p = await CrmService.upsertPessoa(newValues);
            $state.go('app.crm.pessoas.details', { id: p.id });
        }
        catch (error) {
            console.log(error);
        }
    };
    const handleTextInput = (event) => {
        const { id, value } = event.target;
        if (event.target.id == 'nome') {
            setNome(event.target.value);
        }
        formik.setFieldValue(id, value);
    };
    return (<Box sx={{ pl: 1, pr: 1 }}>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mt: 2 }}>
        <Link underline='hover' color='inherit' href='/'>
          <Typography variant='body2'>CRM</Typography>
        </Link>
        <Link underline='hover' color='inherit' href={'/crm/pessoas'}>
          <Typography variant='body2'>Contactos</Typography>
        </Link>
        <Link underline='hover' color='inherit'>
          <Typography variant='body2'>Adicionar Contacto</Typography>
        </Link>
      </Breadcrumbs>

      {isLoading ? (<Box sx={{ display: 'flex', justifyContent: 'center', height: '2vh' }}>
          <CircularProgress size={60} color='primary'/>
        </Box>) : (<Box>
          <form onSubmit={formik.handleSubmit}>
            <Typography variant='h4' sx={{ color: 'black', mt: 1 }}>
              {editing ? 'A editar ' + nome : nome === '' ? 'Criar novo Contacto' : 'A criar ' + nome}
            </Typography>

            <Box sx={{ mt: 2 }}>
              <Typography variant='h6' sx={{ color: 'black', mb: 1 }}>
                Informação base
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2}>
                    <TextField fullWidth id='nome' label='Nome *' color='info' onChange={handleTextInput} size='small' value={formik.values.nome} error={Boolean(formik.errors.nome)} helperText={formik.touched.nome && formik.errors.nome} sx={{ bgcolor: '#F5F5F5' }}/>
                    <TextField fullWidth id='email' label='Email *' color='info' size='small' value={formik.values.email} error={Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>

                    <TextField fullWidth id='contactoTelefonico' label='Contacto Telefónico' color='info' size='small' value={formik.values.contactoTelefonico} error={Boolean(formik.errors.contactoTelefonico)} helperText={formik.touched.contactoTelefonico && formik.errors.contactoTelefonico} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>
                    <TextField fullWidth id='profissao' label='Profissão' color='info' size='small' value={formik.values.profissao} error={Boolean(formik.errors.profissao)} helperText={formik.touched.profissao && formik.errors.profissao} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>

                    <Autocomplete disablePortal options={options} getOptionLabel={option => (option.name ? `${option.name}` : '')} value={selectedValue} // O valor completo da opção selecionada
         isOptionEqualToValue={(option, value) => option.id === value.id} onChange={(event, newValue) => {
                if (newValue == null) {
                    setSelectedValue(null);
                }
                else {
                    setSelectedValue(newValue); // Armazena o objeto completo
                    formik.setFieldValue('clienteId', newValue.id);
                }
                formik.setFieldTouched('clienteId', true);
            }} filterOptions={x => x} // Evita o filtro padrão
         onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setOptions([]); // Reseta opções ao mudar busca
            }} renderOption={(props, option) => (<ListItem {...props} key={option.id} dense>
                          <ListItemText primary={option.name} secondary={option.codigo}/>
                        </ListItem>)} renderInput={params => (<TextField {...params} label='Associar empresa atual' variant='outlined' size='small' fullWidth sx={{ bgcolor: '#F5F5F5' }} error={Boolean(formik.errors.clienteId)} helperText={formik.touched.clienteId && formik.errors.clienteId} InputProps={{
                    ...params.InputProps,
                    endAdornment: (<>
                                {autocompleteLoad ? <CircularProgress color='inherit' size={20}/> : null}
                                {params.InputProps.endAdornment}
                              </>)
                }}/>)}/>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Stack spacing={2}>
                    <TextField fullWidth id='nif' label='NIF' color='info' size='small' sx={{ bgcolor: '#F5F5F5' }} value={formik.values.nif} error={Boolean(formik.errors.nif)} helperText={formik.touched.nif && formik.errors.nif} onChange={handleTextInput}/>
                    <TextField fullWidth id='cargo' label='Cargo' color='info' size='small' sx={{ bgcolor: '#F5F5F5' }} value={formik.values.cargo} error={Boolean(formik.errors.cargo)} helperText={formik.touched.cargo && formik.errors.cargo} onChange={formik.handleChange}/>

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker label='Data nascimento' slotProps={{ textField: { size: 'small' } }} sx={{ width: '100%', bgcolor: '#F5F5F5' }} value={formik.values.dataNascimento ? moment(formik.values.dataNascimento, 'DD-MM-YYYY', true) : null} onChange={newValue => {
                formik.setFieldValue('dataNascimento', newValue && moment(newValue).isValid() ? moment(newValue).format('DD-MM-YYYY') : null);
            }}/>
                      <DatePicker label='Data ultimo contacto' slotProps={{ textField: { size: 'small' } }} sx={{ width: '100%', bgcolor: '#F5F5F5' }} value={formik.values.dataUltimoContacto ? moment(formik.values.dataUltimoContacto, 'DD-MM-YYYY', true) : null} onChange={newValue => {
                formik.setFieldValue('dataUltimoContacto', newValue && moment(newValue).isValid() ? moment(newValue).format('DD-MM-YYYY') : null);
            }}/>
                    </LocalizationProvider>

                    <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                      <Typography sx={{ color: 'black' }}> Bloqueado </Typography>
                      <Checkbox defaultChecked={formik.values.blocked === 1 ? true : false} // Checkbox marcado se o valor for 1
         onChange={event => {
                const isChecked = event.target.checked; // Estado booleano do checkbox
                const newValue = isChecked ? 1 : 0; // Converte para 1 ou 0
                formik.setFieldValue('blocked', newValue); // Atualiza o valor no Formik
            }}/>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Box>
                    <DropzoneArea acceptedFiles={['image/jpeg', 'image/png']} initialFiles={image ? [image] : []} // Usa a imagem carregada da pessoa ou deixa vazio
         onChange={files => {
                if (files.length > 0) {
                    let t = files.pop();
                    let f = new File([t], `${v4()}.${t.name.split('.').pop()}`, { type: t.type });
                    setNewImage({ file: f, dummy: false });
                    setImage(URL.createObjectURL(f));
                    //console.log(URL.createObjectURL(f));
                }
                else {
                    setNewImage(null);
                    setImage(null);
                }
            }} showFileNames={false} showAlerts={false} showPreviewsInDropzone={true} 
        //useChipsForPreview={true}
        dropzoneText='Arraste e solte a imagem aqui ou clique' filesLimit={1}/>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TextField fullWidth multiline maxRows={4} id='observacoes' label='Observações' color='info' size='small' value={formik.values.observacoes} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField fullWidth multiline minRows={2} maxRows={4} id='interessesPessoais' label='Interesses Pessoais' color='info' size='small' value={formik.values.interessesPessoais} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField fullWidth multiline minRows={2} maxRows={4} id='interessesProfissionais' label='Interesses Profissionais' color='info' size='small' value={formik.values.interessesProfissionais} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography variant='h6' sx={{ color: 'black', mb: 2 }}>
                Redes sociais
              </Typography>

              <Grid container spacing={2}>
                {socialMediaIcons.map((icon, index) => (<Grid item xs={12} sm={6} md={4} key={index} display='flex' alignItems='center'>
                    <Box component='img' src={icon.src} alt={icon.alt} sx={{
                    maxWidth: '22px',
                    maxHeight: '22px',
                    width: 'auto',
                    height: 'auto'
                }}/>
                    <TextField label={`Insira o link da página do ${icon.alt}`} variant='outlined' fullWidth size='small' sx={{ ml: 2, bgcolor: '#F5F5F5' }} value={socialMediaLinks[icon.key] || ''} onChange={e => handleSocialMediaChange(icon.key, e.target.value)}/>
                  </Grid>))}
              </Grid>
            </Box>
          </form>
          <Fab aria-label='add' color='error' sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                zIndex: 1000
            }}>
            <IconButton onClick={() => formik.handleSubmit()}>
              <MdiIcon path={mdiContentSave} sx={{ color: '#ffffff' }}/>
            </IconButton>
          </Fab>
        </Box>)}
    </Box>);
};
export default CrmPessoasAddView;
