import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import { useFormik } from 'formik';
export const BaseDefault = {
    imagens: true,
    assinatura: false,
    pagamento: false
};
const DialogBase = ({ initial, onClose, ...props }) => {
    const formik = useFormik({
        initialValues: {
            imagens: true,
            assinatura: false,
            pagamento: false
        },
        validateOnMount: false,
        onSubmit: async (values) => {
            onClose(JSON.stringify(values));
        }
    });
    useEffect(() => {
        let base = BaseDefault;
        if (initial) {
            base = { ...base, ...initial };
        }
        formik.resetForm();
        formik.setValues(base);
    }, [initial]);
    return (<form>
      <Dialog {...props} maxWidth={'lg'} fullWidth>
        <DialogTitle>Propriedades base do modelo</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <FormControlLabel control={<Switch checked={formik.values.imagens} onChange={formik.handleChange} onBlur={formik.handleBlur} name='imagens' color='primary'/>} label='Carregamento de imagens?'/>
            </Grid>
            <Grid lg={12} md={12} xs={12}>
              <FormControlLabel control={<Switch checked={formik.values.assinatura} onChange={formik.handleChange} onBlur={formik.handleBlur} name='assinatura' color='primary'/>} label='Requer assinatura de cliente?'/>
            </Grid>
            <Grid lg={12} md={12} xs={12}>
              <FormControlLabel control={<Switch checked={formik.values.pagamento} onChange={formik.handleChange} onBlur={formik.handleBlur} name='pagamento' color='primary'/>} label='Registar dados de pagamento?'/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogBase;
