export default class FormacaoCursoEdicaoModuloController {
  constructor($window, $rootScope, $state, $http, $filter, AuthenticationService, $sce, $stateParams, $timeout, Group, FormacaoModulo, FormacaoFormador, FormacaoFormadorModulo, FormacaoCurso, FormacaoSessao, FormacaoEspaco, FormacaoInscricao, FormacaoPresenca, $anchorScroll, $location, UIService, $q, FormacaoAvaliacao, FormacaoAvaliacaoEscala, FormacaoAvaliacaoTipo, FormacaoAvaliacaoInstrumento) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$http = $http;
    this.$filter = $filter;
    this.user = AuthenticationService.getUser();
    this.$sce = $sce;
    this.$q = $q;
    this.id = $stateParams.id;
    this.cursoId = $stateParams.cursoId;
    this.edicaoId = $stateParams.edicaoId;
    this.$timeout = $timeout;
    this.Group = Group;
    this.FormacaoModulo = FormacaoModulo;
    this.FormacaoFormador = FormacaoFormador;
    this.FormacaoFormadorModulo = FormacaoFormadorModulo;
    this.FormacaoCurso = FormacaoCurso;
    this.FormacaoSessao = FormacaoSessao;
    this.FormacaoEspaco = FormacaoEspaco;
    this.FormacaoInscricao = FormacaoInscricao;
    this.FormacaoPresenca = FormacaoPresenca;
    this.FormacaoAvaliacao = FormacaoAvaliacao;
    this.FormacaoAvaliacaoEscala = FormacaoAvaliacaoEscala;
    this.FormacaoAvaliacaoTipo = FormacaoAvaliacaoTipo;
    this.FormacaoAvaliacaoInstrumento = FormacaoAvaliacaoInstrumento;
    this.$anchorScroll = $anchorScroll;
    this.$location = $location;
    this.UI = UIService;
    this.modulo = null;
    this.espacos = null;
    this.cursos = null;
    this.formadores = null;
    this.formadorLoading = true;
    this.moduloLoading = true;
    this.formadoresLoading = true;
    this.sessoesLoading = true;
    this.instrumentosLoading = true;
    this.escalas = null;
    this.tipos = null;
    this.formandos = [];
    this.instrumentos = null;

    // Redirect back if not defined
    if (!this.id)
      this.goBack();

    this.getModulo(this.id);
    this.getFormadores();
    this.getEscalas();
  }

  getFormadores = () => {
    this.FormacaoFormador.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.formadores = res;
    }).catch((error) => {
      console.log(error);
    });
  };

  getEspacos = () => {
    this.FormacaoEspaco.find({
      filter: {
        where: {
          localId: this.modulo.edicao.local.id,
          active: 1
        },
      }
    }).$promise.then((res) => {
      this.espacos = res;
      this.getInstrumentos();
    }).catch((error) => {
      console.log(error);
    });
  };

  getInstrumentos = () => {
    this.FormacaoAvaliacaoInstrumento.find({
      filter: {
        where: {
          moduloId: this.id,
          active: true
        },
        include: [{
          relation: 'avaliacoes',
          where: {
            active: true
          }
        }, {
          relation: 'escala',
          where: {
            active: true
          }
        }]
      }
    }).$promise.then(res => {
      this.instrumentos = res;
      this.instrumentosLoading = false;
    }).catch(err => {
      console.log(err);
    });
  };

  getModulo = (id) => {
    this.FormacaoModulo.findOne({
      filter: {
        where: {
          id: id,
          active: 1
        },
        include: [{
          relation: 'formadores',
          scope: {
            where: {
              active: 1
            },
            order: 'nome ASC'
          }
        }, {
          relation: 'edicao',
          scope: {
            where: {
              active: 1
            },
            include: [{
              relation: 'curso',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'local',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'inscricoes',
              scope: {
                where: {
                  estadoId: 1,
                  active: 1
                },
                include: {
                  relation: 'formando',
                  where: {
                    active: 1
                  }
                }
              }
            }]
          }
        }, {
          relation: 'sessoes',
          scope: {
            where: {
              active: 1
            },
            order: 'numero ASC',
            include: [{
              relation: 'espaco',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'presencas',
              scope: {
                where: {
                  active: 1
                }
              }
            }]
          }
        }, {
          relation: 'instrumentos',
          scope: {
            where: {
              active: 1
            },
            include: ['escala', 'tipo', 'avaliacoes']
          }
        }]
      }
    }).$promise.then((result) => {
      console.log(result);
      return;
      if (!result) {
        // Move to list if no result is found
        this.UI.addToast('Não é possível ver o formador de momento. Por favor tente mais tarde.');
        if (this.cursoId && this.edicaoId)
          this.$state.go('app.formacao.cursos.edicao.details', {id: this.cursoId, ed: this.edicaoId});
        else
          this.$state.go('app.formacao.cursos.list');
      }

      this.cursoId = result.edicao.cursoId;
      this.edicaoId = result.edicao.id;
      this.modulo = result;

      this.modulo.edicao.inscricoes.forEach(i => {
        this.formandos.push(i.formando);
      });

      this.getEspacos();

      // Calculate number of presencas for layout
      this.modulo.sessoes.forEach(s => {
        this.countPresencasSessao(s);
      });

      // Change to boolean because checkboxes work like that
      // this.formador.contratolab = (this.formador.contratolab === 1);

      this.formadoresLoading = false;
      this.sessoesLoading = false;
      this.moduloLoading = false;

    }).catch((error) => {
      if (error.status === 404)
        this.UI.addToast('Módulo inexistente.');
      else
        this.UI.addToast('Erro de leitura de módulo. Por favor tente mais tarde.');

      this.formadoresLoading = false;
      this.sessoesLoading = false;
      this.moduloLoading = false;

      // Move to list on error
      this.goBack();
    });
  };

  getEscalas = () => {
    this.FormacaoAvaliacaoEscala.find({
      filter: {
        where: {active: true}
      }
    }).$promise.then(res => {
      this.escalas = res;
      this.escalas.sort(function (a, b) {
        return a.max - b.max;
      });
      this.getTipos();
    }).catch(err => {
      console.log(err);
    });
  };

  getTipos = () => {
    this.FormacaoAvaliacaoTipo.find({
      filter: {
        where: {active: true}
      }
    }).$promise.then(res => {
      this.tipos = res;
      this.tipos.sort(function (a, b) {
        return a.designacao.localeCompare(b.designacao);
      });
    }).catch(err => {
      console.log(err);
    });
  };

  goBack = () => {
    if (this.cursoId && this.edicaoId)
      this.$state.go('app.formacao.cursos.edicao.details', {id: this.cursoId, ed: this.edicaoId});
    else
      this.$state.go('app.formacao.cursos.list');
  };

  // Edit modals
  editDetalhes = () => {
    let self = this;
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./modal-edit-detalhes.html'),
      controller: ['$scope', function ($scope) {
        // Data to change on the modal
        $scope.modulo = {};
        $scope.modulo.designacao = self.modulo.designacao;
        $scope.modulo.descricao = self.modulo.descricao;

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.modulo.designacao = _.isEmpty(ok.modulo.designacao) ? null : ok.modulo.designacao;
      // If string values are empty, use NULL so it's db consistent
      this.modulo.descricao = _.isEmpty(ok.modulo.descricao) ? null : ok.modulo.descricao;

      let auxEdicao = this.modulo.edicao;
      let auxSessoes = this.modulo.sessoes;
      let auxFormadores = this.modulo.formadores;

      // If changed, save data
      this.modulo.$save().then((r) => {
        // Update the related model because it vanishes upon $save()
        this.modulo.edicao = auxEdicao;
        this.modulo.sessoes = auxSessoes;
        this.modulo.formadores = auxFormadores;
        this.UI.addToast("Módulo Atualizado.");
      }, (error) => {
        console.log(error);
        this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
        this.getModulo();
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  confirmDeleteLecionacao = (f) => {
    let title = "Eliminar Lecionação";
    let warning = "Tem a certeza que pretende remover este formador deste módulo?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeLecionacao(f);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  removeLecionacao = (f) => {
    this.formadoresLoading = true;
    // Find again formador to delete so we can update it
    this.FormacaoFormador.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((formadorToRemove) => {
      this.FormacaoFormadorModulo.cleanFormadorModulo({
        modulo: Number(this.id),
        formador: f.id
      }).$promise.then((res) => {
        this.updateFormadores("Lecionação eliminada com sucesso.");
      }).catch((error) => {
        console.log(error);
        this.formadoresLoading = false;
        this.UI.addToast("Não foi possível apagar o formador. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      this.formadoresLoading = false;
      this.UI.addToast("Não foi possível apagar o formador. Verifique se ainda existe.");
    });
  };

  addLecionacao = () => {
    let self = this;
    let formadores = angular.copy(this.formadores);
    let selected = angular.copy(this.modulo.formadores);
    formadores = _.differenceWith(formadores, selected, (a, b) => a.id === b.id);
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./modal-add-lecionacao.html'),
      controller: ['$scope', function ($scope) {
        // Data to change on the modal
        $scope.available = formadores;
        $scope.selected = selected;
        // Search filter
        $scope.filter = '';
        // Pagination
        $scope.pagination = {
          perPage: 5,
          current: 0,
          previous: () => {
            if ($scope.pagination.current > 0)
              $scope.pagination.current--;
          },
          onFilter: () => {
            const filteredArray = self.$filter('filter')($scope.available, $scope.filter);
            const afterFilter = Math.ceil(filteredArray.length / $scope.pagination.perPage) - 1;
            if (afterFilter < $scope.pagination.current) {
              $scope.pagination.current = afterFilter;
            }
          },
          next: () => {
            if ($scope.pagination.current < $scope.pagination.total() - 1)
              $scope.pagination.current++;
          },
          total: () => {
            const filteredArray = self.$filter('filter')($scope.available, $scope.filter);
            return Math.ceil(filteredArray.length / $scope.pagination.perPage);
          }
        };

        $scope.select = function (formador) {
          formador.oldIdx = $scope.available.indexOf(formador);
          $scope.selected.push(formador);
          $scope.available.splice(formador.oldIdx, 1);
        };

        $scope.pop = function (formador) {
          $scope.selected.splice($scope.selected.indexOf(formador), 1);
          $scope.available.splice(formador.oldIdx, 0, formador);
        };

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.formadoresLoading = true;
      console.log(ok.selected);
      // Remove previous connections to modulo and add the new ones that were selected
      this.FormacaoFormadorModulo.cleanModulo({modulo: Number(self.id)}).$promise.then((res) => {
        ok.selected.forEach((f) => {
          this.FormacaoFormadorModulo.create({
            formacaoModuloId: this.id,
            formacaoFormadorId: f.id
          }).$promise.then((res) => {
            // this.UI.addToast("Formador " + f.nome + " inserido.");
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro ao inserir " + f.nome + " enquanto formador. Por favor tente mais tarde.");
          });
        });
        this.updateFormadores("Lecionação do Módulo Atualizada.");
      }).catch((err) => {
        console.log(err);
        this.formadoresLoading = false;
        this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
      });
    }).catch((err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  confirmDeleteSessao = (s) => {
    let title = "Eliminar Sessão";
    let warning = "Tem a certeza que pretende remover esta sessão de formação?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeSessao(s);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  confirmDeleteInstrumento = (i) => {
    let title = "Eliminar Instrumento de Avaliação";
    let warning = "Tem a certeza que pretende remover este instrumento de avaliação?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeInstrumento(i);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeSessao = (s) => {
    this.sessoesLoading = true;
    // Find again formador to delete so we can update it
    this.FormacaoSessao.findOne({
      filter: {
        where: {
          id: s.id,
          active: 1
        }
      }
    }).$promise.then((sessaoToRemove) => {
      sessaoToRemove.active = 0;
      sessaoToRemove.$save().then((res) => {
        this.updateSessoes("Sessão eliminada com sucesso.");
      }, (error) => {
        console.log(error);
        this.sessoesLoading = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.moduloLoading = false;
      this.UI.addToast("Não foi possível eliminar a sessão. Verifique se ainda existe.");
    });
  };

  removeInstrumento = (i) => {
    this.instrumentosLoading = true;

    this.FormacaoAvaliacaoInstrumento.findOne({
      filter: {
        where: {
          id: i.id,
          active: 1
        },
        include: 'avaliacoes'
      }
    }).$promise.then((instrumentoToRemove) => {
      if (instrumentoToRemove.avaliacoes.length > 0) {
        this.UI.addToast("Não é possível eliminar o instrumento de avaliação. Verifique se existem avaliações que lhe estão associadas.");
        this.instrumentosLoading = false;
        return;
      }
      instrumentoToRemove.active = 0;
      instrumentoToRemove.$save().then((res) => {
        this.updateInstrumentos("Instrumento de avaliação eliminado com sucesso.");
      }, (error) => {
        console.log(error);
        this.instrumentosLoading = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch(err => {
      console.log(err);
      this.instrumentosLoading = false;
      this.UI.addToast("Não foi possível eliminar o instrumento de avaliação. Verifique se ainda existe.");
    });
  };

  addSessao = () => {
    if (this.espacos === null || this.espacos.length === 0) {
      this.UI.addToast("De momento não é possível adicionar sessões. Por favor tente mais tarde.");
      return;
    }
    let self = this;
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./modal-edit-sessao.html'),
      controller: ['$scope', function ($scope) {
        // Used so we can decide to show Sumário or not
        $scope.newSessao = true;
        $scope.modalTitle = "Nova Sessão de Formação";
        $scope.moduloDesignacao = self.modulo.designacao;

        // Data to change on the modal
        $scope.espacos = self.espacos;
        $scope.auxEspaco = null;

        $scope.sessao = {};
        // Valor por defeito, sujeito a mudança
        $scope.sessao.duracao = 60;

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.sessoesLoading = true;
      // Create new Sessao with form data
      this.FormacaoSessao.create({
        id: 0,
        numero: ok.sessao.numero,
        sumario: null,
        observacoes: _.isEmpty(ok.sessao.observacoes) ? null : ok.sessao.observacoes,
        datahorainicio: ok.sessao.datahorainicio,
        duracao: ok.sessao.duracao,
        active: 1,
        espacoId: ok.auxEspaco.id,
        moduloId: this.modulo.id
      }).$promise.then(s => {
        // Success. Update list
        this.updateSessoes("Sessão criada com sucesso.");
      }).catch((error) => {
        console.log(error);
        this.sessoesLoading = false;
        this.UI.addToast("Não foi possível criar nova sessão. Por favor tente mais tarde.");
      });
    }).catch((err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editSessao = (s) => {
    // TODO - Verify if this should be necessary or not
    if (this.espacos === null || this.espacos.length === 0) {
      this.UI.addToast("De momento não é possível editar a sessão. Por favor tente mais tarde.");
      return;
    }
    let self = this;
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./modal-edit-sessao.html'),
      controller: ['$scope', function ($scope) {
        // Used so we can decide to show Sumário or not
        $scope.newSessao = false;
        $scope.modalTitle = "Editar Sessão de Formação";
        $scope.moduloDesignacao = self.modulo.designacao;

        // Data to change on the modal
        $scope.espacos = self.espacos;
        $scope.auxEspaco = s.espaco;

        $scope.sessao = {};
        $scope.sessao.id = s.id;
        $scope.sessao.numero = s.numero;
        $scope.sessao.duracao = s.duracao;
        $scope.sessao.datahorainicio = s.datahorainicio;
        $scope.sessao.sumario = s.sumario;
        $scope.sessao.observacoes = s.observacoes;

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.sessoesLoading = true;

      this.FormacaoSessao.findOne({
        filter: {
          where: {
            id: ok.sessao.id,
            active: 1
          }
        }
      }).$promise.then((sessaoEdit) => {
        // Only update what we should be updating
        sessaoEdit.numero = ok.sessao.numero;
        sessaoEdit.sumario = _.isEmpty(ok.sessao.sumario) ? null : ok.sessao.sumario;
        sessaoEdit.observacoes = _.isEmpty(ok.sessao.observacoes) ? null : ok.sessao.observacoes;
        sessaoEdit.espacoId = ok.auxEspaco.id;

        sessaoEdit.$save().then((res) => {
          // Success. Update list
          this.updateSessoes("Sessão atualizada com sucesso.");
        }, (error) => {
          console.log(error);
          this.sessoesLoading = false;
          this.UI.addToast("Não foi possível editar a sessão. Por favor tente mais tarde.");
        });
      }).catch((error) => {
        console.log(error);
        this.sessoesLoading = false;
        this.UI.addToast("Não foi possível editar a sessão. Por favor recarregue a página.");
      });
    }).catch((err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  // Count number of faltas/presenças registered for sessao
  countPresencasSessao = (s) => {
    s.presencasLayout = 0;
    s.faltasLayout = 0;

    s.presencas.forEach(p => {
      if (p.tipo === 1) s.presencasLayout++;
      else if (p.tipo === 0) s.faltasLayout++;
    });
  };

  updateInstrumentos = (msg) => {
    this.instrumentosLoading = true;
    this.FormacaoModulo.findOne({
      filter: {
        where: {
          id: this.id,
          active: 1
        },
        include: [{
          relation: 'instrumentos',
          scope: {
            where: {
              active: 1
            },
            order: 'datahora ASC',
            include: ['tipo', 'escala', 'avaliacoes']
          }
        }]
      }
    }).$promise.then((res) => {
      this.modulo.instrumentos = res.instrumentos;
      this.UI.addToast(msg);
      this.instrumentosLoading = false;
    }).catch(err => {
      this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
      this.instrumentosLoading = false;
    });
  };

  updateSessoes = (msg) => {
    this.sessoesLoading = true;
    this.FormacaoSessao.find({
      filter: {
        where: {
          moduloId: this.modulo.id,
          active: 1
        },
        order: 'numero ASC',
        include: [{
          relation: 'espaco',
          scope: {
            where: {
              active: 1
            }
          }
        }, {
          relation: 'presencas',
          scope: {
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then((res) => {
      this.modulo.sessoes = res;

      // Update count for presenças of sessões
      this.modulo.sessoes.forEach(s => {
        this.countPresencasSessao(s);
      });

      this.UI.addToast(msg);
      this.sessoesLoading = false;
    }).catch((err) => {
      this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
      this.sessoesLoading = false;
    });
  };

  updateFormadores = (msg) => {
    this.formadoresLoading = true;
    this.FormacaoModulo.findOne({
      filter: {
        where: {
          id: this.id,
          active: 1
        },
        include: [{
          relation: 'formadores',
          scope: {
            where: {
              active: 1
            },
            order: 'nome ASC'
          }
        }]
      }
    }).$promise.then((res) => {
      this.modulo.formadores = res.formadores;
      this.UI.addToast(msg);
      this.formadoresLoading = false;
    }).catch((err) => {
      this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
      this.formadoresLoading = false;
    });
  };

  editObservacoes = () => {
    let self = this;
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./modal-edit-observacoes.html'),
      controller: ['$scope', function ($scope) {
        // Data to change on the modal
        $scope.modulo = {};
        $scope.modulo.observacoes = self.modulo.observacoes;

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      // If string values are empty, use NULL so it's db consistent
      this.modulo.observacoes = _.isEmpty(ok.modulo.observacoes) ? null : ok.modulo.observacoes;

      let auxEdicao = this.modulo.edicao;
      let auxSessoes = this.modulo.sessoes;
      let auxFormadores = this.modulo.formadores;

      // If changed, save data
      this.modulo.$save().then((r) => {
        // Update the related model because it vanishes upon $save()
        this.modulo.edicao = auxEdicao;
        this.modulo.sessoes = auxSessoes;
        this.modulo.formadores = auxFormadores;
        this.UI.addToast("Módulo Atualizado.");
      }, (error) => {
        console.log(error);
        this.moduloLoading = true;
        this.UI.addToast("Erro de atualização. Por favor tente mais tarde.");
        this.getModulo();
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editPresencas = (s) => {
    let self = this;
    let $q = this.$q;
    let waiter = this.UI.showWaiting();

    // Find formandos that can work
    // estadoId should be 1, which represents the 'success' state (in this case, it means the Inscrição was confirmed)
    this.FormacaoInscricao.find({
      filter: {
        where: {
          estadoId: 1,
          edicaoId: this.modulo.edicao.id,
          active: 1
        },
        include: {
          relation: 'formando',
          scope: {
            where: {
              active: 1
            },
          }
        }
      }
    }).$promise.then((formandos) => {
      // Sort formandos list by its name
      formandos.sort((a, b) => a.formando.nome.localeCompare(b.formando.nome));

      // Fetch previous presencas if they exist, so we can populate presencas data
      this.FormacaoPresenca.find({
        filter: {
          where: {
            sessaoId: s.id,
            active: 1
          }
        }
      }).$promise.then((presencas) => {
        // We have all the info, populate modal data
        let options = {
          size: 'lg',
          template: require('./modal-edit-presencas.html'),
          controller: ['$dialog', '$scope', (dialog, scope) => {

            scope.inscricoes = formandos;
            scope.sessao = s;

            // Checkboxes are empty by default, otherwise with data from presenca previously filled in
            scope.inscricoes.forEach((i) => {
              let p = _.find(presencas, {formandoId: i.formandoId});
              if (p) i.selected = (p.tipo === 1); // true - presença ; false - falta;
              else i.selected = false; // if nothing exists already, assume false
            });

            scope.ok = () => {
              dialog.close(scope);
            };

            scope.cancel = () => {
              dialog.dismiss('cancel');
            };
          }]
        };

        let modal = this.UI.showDialog(options);
        waiter.close();

        modal.then((res) => {
          let inscricoes = res.inscricoes;
          let tasks = [];
          inscricoes.forEach((i) => {
            let defer = $q.defer();
            let p = _.find(presencas, {formandoId: i.formandoId});
            if (p) {
              // Presença already existed, update it only
              p.tipo = i.selected;
              p.$save().then((res) => {
                defer.resolve(res);
              }, (error) => {
                defer.resolve(-1);
                console.log(error);
                this.UI.addToast("Erro ao atualizar presença!");
              });
            } else { // If presença wasn't already created, new it
              self.FormacaoPresenca.create({
                id: 0,
                tipo: i.selected ? 1 : 0,
                formandoId: i.formandoId,
                sessaoId: s.id,
                funcionarioId: self.user.id,
                data: moment().format("YYYY-MM-DD HH:mm"),
                active: 1
              }).$promise.then((res) => {
                defer.resolve(res);
              }).catch((error) => {
                defer.resolve(-1);
                console.log(error);
                this.UI.addToast("Erro ao criar presença!");
              });
            }
            tasks.push(defer.promise);
          });
          $q.all(tasks).then((res) => {
            console.log(res);
            if (_.find(res, -1))
              this.updateSessoes("Ocorreram erros de registo de presença. Por favor tente novamente.");
            else
              this.updateSessoes("Presenças registadas com sucesso.");
          }).catch(err => {
            // When reject is called. Should never happen.
            console.log(err);
            this.UI.addToast("Erro ao criar presença!");
          });
        }).catch(err => {
          // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
          if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
            console.log(err);
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("De momento não é possível registar presenças. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("De momento não é possível registar presenças. Por favor tente mais tarde.");
    });
  };

  addInstrumento = () => {
    if (this.escalas === null || this.tipos === null) {
      this.UI.addToast('Não é possível criar novos instrumentos de avaliação de momento. Por favor tente mais tarde.');
      return;
    }

    let self = this;
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./modal-edit-instrumento.html'),
      controller: ['$scope', function ($scope) {
        $scope.instrumento = {
          id: 0,
          designacao: '',
          escalaId: 0,
          tipoId: 0,
          moduloId: Number(self.id),
          datahora: null
        };
        $scope.escalas = angular.copy(self.escalas);
        $scope.tipos = angular.copy(self.tipos);
        $scope.modalTitle = "Criar Instrumento de Avaliação";
        $scope.newInstrumento = true;

        $scope.ok = function () {
          $scope.$close($scope.instrumento);
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((result) => {
      this.FormacaoAvaliacaoInstrumento.create(result).$promise.then((res) => {
        this.updateInstrumentos("Instrumento de Avaliação criado com sucesso.");
      }).catch(err => {
        console.log(err);
        this.instrumentosLoading = false;
        this.UI.addToast('Não foi possível criar novo instrumento de avaliação. Por favor recarregue a página.');
      });
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  addAvaliacao = (i) => {
    let self = this;
    let index = -1;
    let tasks = [];
    let $q = this.$q;

    if (i.avaliacoes === null || i.avaliacoes === undefined) {
      this.formandos.forEach(f => {
        f.avaliacao = {
          id: 0,
          valor: null,
          observacoes: "",
          instrumentoId: i.id,
          formandoId: f.id
        };
      });
    } else {
      this.formandos.forEach(f => {
        index = i.avaliacoes.findIndex(a => {
          return a.formandoId === f.id;
        });
        if (index >= 0) {
          f.avaliacao = i.avaliacoes[index];
        } else {
          f.avaliacao = {
            id: 0,
            valor: null,
            observacoes: "",
            instrumentoId: i.id,
            formandoId: f.id
          };
        }
      });
    }

    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./modal-edit-avaliacoes.html'),
      controller: ['$scope', function ($scope) {
        $scope.instrumento = i;
        $scope.formandos = angular.copy(self.formandos);
        $scope.avaliacoes = [];
        $scope.modalTitle = "Avaliação - " + self.modulo.designacao;

        $scope.ok = function () {
          $scope.formandos.forEach(f => {
            if(f.avaliacao.valor !== null) {
              $scope.avaliacoes.push(f.avaliacao);
            }
          });
          $scope.$close($scope.avaliacoes);
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        }
      }]
    });

    instance.then((results) => {
      if (results.length > 0) {
        results.forEach(avaliacao => {
          let defer = $q.defer();
          this.FormacaoAvaliacao.upsert(avaliacao).$promise.then(res => {
            defer.resolve(res);
          }).catch(err => {
            defer.resolve(-1);
          });
          tasks.push(defer.promise);
        });
        $q.all(tasks).then((res) => {
          console.log(res);
          if (_.find(res, -1))
            this.updateInstrumentos("Ocorreram erros de registo de avaliações. Por favor tente novamente.");
          else
            this.updateInstrumentos("Avaliações registadas com sucesso.");
        }).catch(err => {
          // When reject is called. Should never happen.
          console.log(err);
          this.UI.addToast("Erro ao criar avaliação!");
        });
      }
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editInstrumento = (i) => {
    if (this.escalas === null || this.tipos === null) {
      this.UI.addToast('Não é possível criar novos instrumentos de avaliação de momento. Por favor tente mais tarde.');
      return;
    }

    let self = this;
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./modal-edit-instrumento.html'),
      controller: ['$scope', function ($scope) {
        $scope.newInstrumento = false;
        $scope.modalTitle = "Editar Instrumento de Avaliação";
        $scope.escalas = angular.copy(self.escalas);
        $scope.tipos = angular.copy(self.tipos);

        $scope.instrumento = {};
        $scope.instrumento.datahora = i.datahora;
        $scope.instrumento.escalaId = i.escalaId;
        $scope.instrumento.tipoId = i.tipoId;
        $scope.instrumento.designacao = i.designacao;
        $scope.instrumento.id = i.id;
        $scope.instrumento.moduloId = i.moduloId;

        $scope.ok = function () {
          $scope.$close($scope.instrumento);
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((updated) => {
      console.log(updated);
      this.instrumentosLoading = true;
      this.FormacaoAvaliacaoInstrumento.findOne({
        filter: {
          where: {
            id: updated.id,
            active: 1
          }
        }
      }).$promise.then((instrumento) => {
        instrumento.designacao = updated.designacao;
        instrumento.$save().then((res) => {
          this.updateInstrumentos("Instrumento de Avaliação atualizado com sucesso.");
        }).catch(error1 => {
          console.log(error1);
          this.instrumentosLoading = false;
          this.UI.addToast("Não foi possível editar o instrumento de avaliação. Por favor tente mais tarde.")
        });
      }).catch((error) => {
        console.log(error);
        this.instrumentosLoading = false;
        this.UI.addToast('Não foi possível editar o instrumento de avaliação. Por favor recarregue a página.');
      });

      this.instrumentosLoading = true;
      this.getInstrumentos();
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };
}

FormacaoCursoEdicaoModuloController.$inject = ['$window', '$rootScope', '$state', '$http', '$filter', 'AuthenticationService', '$sce', '$stateParams', '$timeout', 'Group', 'FormacaoModulo', 'FormacaoFormador', 'FormacaoFormadorModulo', 'FormacaoCurso', 'FormacaoSessao', 'FormacaoEspaco', 'FormacaoInscricao', 'FormacaoPresenca', '$anchorScroll', '$location', 'UIService', '$q', 'FormacaoAvaliacao', 'FormacaoAvaliacaoEscala', 'FormacaoAvaliacaoTipo', 'FormacaoAvaliacaoInstrumento'];
