export default class DetailPedidoReportController {
  constructor($rootScope, $state, $q, AuthenticationService, AuthorizationService, IeRelatorio, UIService, Deficiencia, PontoVerificado, Regulamento, Alteracaoestado) {
    this.$state = $state;
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.Alteracaoestado = Alteracaoestado;
    this.Relatorio = IeRelatorio;
    this.UI = UIService;
    this.Deficiencia = Deficiencia;
    this.PontoVerificado = PontoVerificado;
    this.Regulamento = Regulamento;
    this.loaded = false;
    this.id = this.$state.params.id;
    this.editingReport = false;

    // By default, envURL is production (to account for fails)
    this.envURL = "https://inspecoeseletricas.pt";

    // Get environment for URL for files
    // Doing this here only because we only use this.envURL in the view
    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      if (data != null && data.environment != null) {
        let env = data.environment;
        if (env === 'staging') {
          this.envURL = "https://staging-ie.iep.pt"
        } else if (env === 'production') { // For completeness
          this.envURL = "https://inspecoeseletricas.pt";
        } else {
          this.envURL = "https://dev-iep-ie.streamline.pt";
        }
      }
    });

    this.hasPerms().then(x => {
      this.loadData();
    }).catch(() => {
      $state.go($state.previous.name, $state.previous.params);
    });
  }

  hasPerms = () => {
    let defer = this.$q.defer();
    if (this.AuthorizationService.canPerform('reportsIeListAll')) {
      defer.resolve(true);
    } else {
      this.Relatorio.findOne({
        filter: {
          fields: { id: true, tecnicoId: true },
          where: {
            id: this.id
          }
        }
      }).$promise.then((r) => {
        if (r && r.tecnicoId === this.Auth.getId()) {
          defer.resolve(true);
        } else {
          defer.reject(false);
          this.UI.addToast("Sem permissões para ver o relatório");
        }
      }).catch(() => {
        defer.reject(false);
        this.UI.addToast("Relatório não encontrado");
      });
    }
    return defer.promise;
  };

  editObservations = () => {
    let obs = angular.copy(this.data.observacoes);
    let o = this.UI.showDialog({
      size: "lg",
      template: require("./observations.dialog.html"),
      controller: ["$scope", ($scope) => {
        $scope.observacoes = obs;

        $scope.ok = () => {
          $scope.$close($scope.observacoes);
        };

        $scope.cancel = () => {
          $scope.$dismiss("cancel");
        };
      }]
    });
    o.then((res) => {
      this.data.observacoes = res;
      // Saving data only when pressing save globally.
    });
  };

  showPotencia = () => {
    if (!this.loaded) return;
    return this.data.fracao.potencia + " kVA";
  };

  loadData = () => {
    this.loaded = false;
    this.Regulamento.find({
      filter: {
        where: {
          active: true,
        },
      },
    }).$promise.then((regulamentos) => {
      this.regulamentos = regulamentos;
      this.PontoVerificado.find().$promise.then((pontos) => {
        this.pontos = pontos;
        this.Deficiencia.find({
          filter: {
            where: {
              active: true,
            },
            include: "tipo",
          },
        }).$promise.then((r) => {
          this.deficiencias = r;
          this.Relatorio.findOne({
            filter: {
              where: {
                id: this.$state.params.id,
              },
              include: ["regulamento", "ponto",
                {
                  relation: 'fracao',
                  scope: {
                    where: {
                      active: 1
                    },
                    include: ['Tipoimovel', {
                      relation: 'pedidoDocumento',
                      scope: {
                        where: {
                          active: 1
                        }
                      }
                    }]
                  }
                },
                {
                  relation: "pedido",
                  scope: {
                    include: [
                      "Tecnico",
                      {
                        relation: "Dadosinstalacao",
                        scope: {
                          include: "Tipoimovel",
                        },
                      },
                      "Promotor",
                      "Projeto",
                      "Exploracao",
                    ],
                  },
                },
              ],
            },
          }).$promise.then((report) => {
            report.jsonData = JSON.parse(report.json);
            console.log(report);
            this.data = report;
            this.loaded = true;
          }).catch(() => {
            this.UI.addToast("Erro ao carregar relatório");
            this.$state.go("app.pedidos.reports.list");
            this.loaded = true;
          });
        }).catch(() => {
          this.$state.go("app.pedidos.reports.list");
          this.UI.addToast("Não foi possível carregar deficiências");
        });
      }).catch(() => {
        this.$state.go("app.pedidos.reports.list");
        this.UI.addToast("Erro ao carregar dados de pontos");
      });
    }).catch(() => {
      this.$state.go("app.pedidos.reports.list");
      this.UI.addToast("Erro ao carregar dados de regulamentos");
    });
  };

  showCPE = () => {
    if (!this.loaded) return;
    if (this.data.fracaoId === -2) {
      // Comuns
      return this.data.pedido.Dadosinstalacao.cpeComuns;
    } else if (this.data.fracaoId === -1) {
      // Coletivas
      // return this.data.pedido.Dadosinstalacao.cpe;
      return 'N/A'; // Instalações Coletivas não têm CPE
    } else {
      // Fraccao
      return this.data.fracao.cpe;
    }
  };

  showEntrada = () => {
    if (!this.loaded) return;
    // Fraccao
    return this.data.fracao.monotri == 1 ? "Monofásico" : "Trifásico"; // == is necessary
  };

  editProperties = () => {
    let dialog = this.UI.showDialog({
      size: "lg",
      template: require("./properties.dialog.html"),
      controller: ["$scope", ($scope) => {
        $scope.obj = {
          realizada: this.data.jsonData.realizada === 1,
          projeto: this.data.jsonData.instalacaoComProjeto === 1,
          nova: this.data.jsonData.instalacaoNova === 1,
          existente: this.data.jsonData.instalacaoExistente === 1,
          periodica: this.data.jsonData.periodica === 1,
        };

        $scope.ok = () => {
          $scope.$close($scope.obj);
        };

        $scope.cancel = () => {
          $scope.$dismiss("cancel");
        };
      }]
    });

    dialog.then((res) => {
      // converts true and false to 0 and 1 (we're saving to JSON directly)
      this.data.jsonData.instalacaoComProjeto = +res.projeto;
      this.data.jsonData.instalacaoNova = +res.nova;
      this.data.jsonData.instalacaoExistente = +res.existente;
      this.data.jsonData.periodica = +res.periodica;
      this.data.jsonData.realizada = +res.realizada;
      // Saving data only when pressing save globally.
    });
  };

  generateReport = () => {
    // Check if valid
    let result = this.isValid();

    if (!result.ok) {
      this.UI.showDialog({
        template: require("./erros.dialog.html"),
        controller: ["$scope", ($scope) => {
          $scope.erros = result.errors;
          $scope.ok = () => {
            $scope.$close();
          };
        }]
      });
    } else {
      this.save().then((r) => {
        window.open(`/api/ierelatorios/preview?id=${this.data.id}`, "_blank").focus();
      });
    }
  };

  editReport = () => {
    this.UI.showConfirm("A edição do relatório implica a criação de uma nova versão do mesmo. Tem a certeza?").then((res) => {
      if (res) {
        this.editingReport = true;
      }
    }).catch((err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  // Returns string with things missing from report
  getErrorReport = () => {
    let error = false;
    let message = "Foram detetados os seguintes erros de preenchimento do relatório:";
    if (!this.data.regulamentoId) {
      error = true;
      message += "\nNão foi preenchido o Regulamento;";
    }
    if (!this.data.pontoId) {
      error = true;
      message += "\nNão foi preenchido o Ponto Verificado;";
    }
    if (!this.data.resultado) {
      error = true;
      message += "\nO relatório não tem um resultado válido;"
    }
    if (Object.keys(this.data.jsonData.ensaio).length === 0) {
      error = true;
      message += "\nNão foi preenchida a informação relativa aos Ensaios efetuados";
    }
    if (!error) {
      message = "Não foram detetados erros de preenchimento do relatório.";
    }
    return message;
  };

  changeToSubmitted = () => {
    return this.Alteracaoestado.to({
      id: this.data.pedidoId,
      state: 6, // Submetido pelo inspetor
      userId: this.Auth.getId()
    }).$promise;
  }

  saveReport = () => {
    let message = this.getErrorReport();
    this.UI.showConfirm(message + "\n\nTem a certeza que pretende finalizar a edição do relatório?").then((res) => {
      if (res) {
        this.editingReport = false;
        this.loaded = false;
        // Save data to new instance of report and open that one.
        this.data.id = 0;
        this.data.validado = false;
        this.data.validadoporId = null;
        this.data.validadoa = null;
        this.data.submissao = moment().isDST() ? moment.utc().add(1, 'h') : moment.utc();
        this.data.json = JSON.stringify(this.data.jsonData);
        this.save().then(r => {
          this.UI.addToast("Relatório criado com sucesso.");
          this.changeToSubmitted().then(_ => {
            this.$state.go('app.pedidos.reports.details', { id: r.id });
          }).catch(e => {
            this.$state.go('app.pedidos.reports.details', { id: r.id });
          });
        }).catch(err => {
          console.log(err);
          this.UI.addToast("Erro na gravação de relatório. Por favor recarregue a página.");
          this.loaded = true;
          this.editingReport = true;
        });
      }
    }).catch((err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  validateReport = () => {
    if (this.data.validado == true) {
      // So version stays the same on "before save", eventually save resultado if doesn't exist
      this.data.isValidating = true;
      this.save().then(r => {
        this.Relatorio.validatePdf({ id: this.data.id }).$promise.then(x => {
          this.UI.addToast("Relatório validado com sucesso");
          this.loadData();
        }).catch(err => {
          console.log(err);
        });
      }).catch(err => {
        console.log(err);
        this.UI.addToast("Erro na validação de relatório. Por favor recarregue a página.");
        this.loaded = true;
      });
    } else {
      let message = this.getErrorReport();
      this.UI.showConfirm(message + "\n\nTem a certeza que pretende validar este relatório?").then((res) => {
        if (res) {
          this.loaded = false;

          // So version stays the same on "before save", eventually save resultado if doesn't exist
          this.data.isValidating = true;
          this.save().then(r => {
            this.Relatorio.validatePdf({ id: this.data.id }).$promise.then(x => {
              this.UI.addToast("Relatório validado com sucesso");
              this.loadData();
            }).catch(err => {
              console.log(err);
            });
          }).catch(err => {
            console.log(err);
            this.UI.addToast("Erro na validação de relatório. Por favor recarregue a página.");
            this.loaded = true;
          });
        }
      }).catch((err) => {
        if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
          console.log(err);
      });
    }

  };

  showImages = (deficiencia) => {
    this.UI.showDialog({
      template: require('./images.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.idx = 0;

        $scope.set = (i) => {
          $scope.idx = i;
        }

        $scope.isAPIImage = () => {
          let img = $scope.list[$scope.idx].base64;
          return img.includes("api");
        }

        $scope.open = () => {
          let img = $scope.list[$scope.idx].base64;
          if (img.includes("api")) {
            window.open(`${img}`, '_blank');
          } else {
            let data = `data:image/jpeg;base64,${$scope.list[$scope.idx].base64}`;
            let w = window.open('about:blank');
            let image = new Image();
            image.src = data;
            setTimeout(function () {
              w.document.write(image.outerHTML);
            }, 0);
          }
        }

        $scope.list = deficiencia.imagens;

        $scope.ok = () => {
          $scope.$close();
        }
      }]
    });
  }

  download = () => {
    let validation = this.isValid();
    if (validation.ok) {
      this.UI.showConfirm("Deseja gerar o ficheiro PDF para este relatório? Caso exista um relatório, este irá substituir o anterior.").then((res) => {
        if (res) {
          let wait = this.UI.showWaiting();
          this.Relatorio.generate({
            id: this.id,
          }).$promise.then((data) => {
            wait.close();
            this.loadData();
            this.UI.addToast("PDF gerado com sucesso!");
          }).catch(() => {
            wait.close();
            this.UI.addToast("Ocorreu um erro ao gerar PDF");
          });
        }
      });
    } else {
      let options = {
        size: "lg",
        template: require("./erros.dialog.html"),
        controller: ["$scope", ($scope) => {
          $scope.erros = validation.errors;
          $scope.ok = () => {
            return $scope.$close();
          };
        }]
      };
      this.UI.showDialog(options);
    }
  };

  isValid = () => {
    let canProceed = true;
    let erros = [];
    let data = this.data;
    let dados = this.data.jsonData;
    if (dados.realizada) {
      if (data.pontoId == null) {
        erros.push("Indique o ponto");
        canProceed = false;
      }
      if (data.regulamentoId == null) {
        erros.push("Indique o enquadramento legal");
        canProceed = false;
      }
      if (dados.ensaio.field1 == null) {
        erros.push("Indique a Resistência de terra (Ω)");
        canProceed = false;
      }
      if (dados.ensaio.field2 == null) {
        erros.push("Indique a Res. de Isol. Circuitos (MΩ) / Tensão Ensaio (V DC)");
        canProceed = false;
      }
      if (dados.ensaio.field3 == null) {
        erros.push("Indique a Separação Circuitos (MΩ) / Tensão Ensaio (V DC)");
        canProceed = false;
      }
      if (this.data.fracao.tipoimovelId != 54 && dados.ensaio.field4 == null) {
        erros.push("Indique a Proteção Diferencial (mA)");
        canProceed = false;
      }
    }
    return {
      ok: canProceed,
      errors: erros,
    };
  };

  showModalForDeficiency = (isNew, val, rid) => {
    let deficiencias = angular.copy(this.deficiencias);
    let options = {
      size: "lg",
      template: require("./deficiencia.dialog.html"),
      controller: ["$dialog", "$scope", ($dialog, $scope) => {
        $scope.deficiencias = deficiencias;

        $scope.escolha = {
          selected: {},
        };

        $scope.opt = [
          {
            id: 0,
            descricao: "Não",
          },
          {
            id: 1,
            descricao: "Sim",
          },
          {
            id: 2,
            descricao: "N/A",
          },
        ];

        if (isNew) {
          // Creating
          $scope.data = {
            id: 0,
            relatorioId: rid,
          };
          $scope.temp = {};
        } else {
          // Editing
          $scope.data = {
            id: val.deficiencia.id,
            relatorioId: rid,
            observacoes: val.nota,
          };
          $scope.escolha.selected = deficiencias.filter((f) => {
            return f.id === val.deficiencia.id;
          })[0];
          $scope.conformeProjeto = $scope.opt.filter((f) => {
            return f.id === val.cp;
          })[0];
          $scope.resolvido = $scope.opt.filter((f) => {
            return f.id === val.r;
          })[0];
        }

        //Infinite Scroll Magic
        $scope.infiniteScroll = {};
        $scope.infiniteScroll.numToAdd = 20;
        $scope.infiniteScroll.currentItems = 20;

        $scope.addMoreItems = function () {
          $scope.infiniteScroll.currentItems += $scope.infiniteScroll.numToAdd;
        };

        $scope.isInvalid = () => {
          return (angular.isUndefined($scope.data.deficienciaId) || angular.isUndefined($scope.data.cp) || angular.isUndefined($scope.data.resolvido));
        };

        $scope.$watch("escolha.selected", (val) => {
          if (val) {
            $scope.data.deficienciaId = val.id;
          }
        });

        $scope.$watch("conformeProjeto", (val) => {
          if (angular.isDefined(val)) {
            $scope.data.cp = val.id;
          }
        });

        $scope.$watch("resolvido", (val) => {
          if (angular.isDefined(val)) {
            $scope.data.resolvido = val.id;
          }
        });

        $scope.ok = () => {
          $dialog.close($scope.data);
        };

        $scope.cancel = () => {
          $dialog.dismiss();
        };
      },
      ],
    };
    return this.UI.showDialog(options);
  };

  addDeficiency = () => {
    this.showModalForDeficiency(true, undefined, this.id).then((res) => {
      // Find deficiencia selected in database to put in .json property
      this.Deficiencia.findOne({
        filter: {
          where: {
            id: res.deficienciaId,
            active: 1,
          },
          include: {
            relation: "tipo",
            scope: {
              where: {
                active: 1,
              },
            },
          },
        },
      }).$promise.then((d) => {
        if (!this.data.jsonData.deficiencias) this.data.jsonData.deficiencias = [];
        this.data.jsonData.deficiencias.push({
          deficiencia: {
            id: d.id,
            regra: d.regra,
            designacao: d.designacao,
            tipoId: d.tipoId,
            enquadramentoId: d.enquadramentoId,
            tipo: d.tipo,
          },
          cp: res.cp,
          r: res.resolvido,
          nota: res.observacoes,
        });

        // Saving data only when pressing save globally.

      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro ao registar deficiência. Tente novamente");
        this.loaded = true;
      });
    }).catch(() => {
    });
  };

  editDeficiency = (d) => {
    this.showModalForDeficiency(false, d, this.id).then((res) => {
      // Find deficiencia selected in database to put in .json property
      this.Deficiencia.findOne({
        filter: {
          where: {
            id: res.deficienciaId,
            active: 1,
          },
          include: {
            relation: "tipo",
            scope: {
              where: {
                active: 1,
              },
            },
          },
        },
      }).$promise.then((def) => {
        // Change value passed by the function
        d.deficiencia = {
          id: def.id,
          regra: def.regra,
          designacao: def.designacao,
          tipoId: def.tipoId,
          enquadramentoId: def.enquadramentoId,
          tipo: def.tipo,
        };
        d.cp = res.cp;
        d.r = res.resolvido;
        d.nota = res.observacoes;

        // Saving data only when pressing save globally.


      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro ao registar deficiência. Tente novamente");
        this.loaded = true;
      });
    }).catch(() => {
    });
  };

  removeDeficiency = (d) => {
    this.UI.showConfirm("Deseja remover a deficiência?").then((res) => {
      if (res) {
        this.data.jsonData.deficiencias.splice(this.data.jsonData.deficiencias.indexOf(d), 1);

        // Saving data only when pressing save globally.

      }
    }).catch(() => {
    });
  };

  showResult = () => {
    let options = {
      size: "lg",
      template: require("./result.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.ng2 = 0;
          $scope.ng1 = 0;
          $scope.g = 0;
          $scope.realizada = this.data.json.realizada;
          this.data.json.deficiencias.forEach((d) => {
            if (d.deficiencia.tipoId == 1) {
              $scope.ng1 += 1;
            } else if (d.deficiencia.tipoId == 4) {
              $scope.g += 1;
            } else {
              $scope.ng2 += 1;
            }
          });
        },
      ],
    };
    this.UI.showDialog(options);
  };

  editPonto = () => {
    let ponto = angular.copy(this.data.ponto);
    let options = {
      size: "lg",
      template: require("./point.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.pontos = this.pontos;

        $scope.op = {
          selected: ponto,
        };

        $scope.cancel = () => {
          $dialog.dismiss();
        };

        $scope.ok = () => {
          $dialog.close($scope.op.selected);
        };
      },
      ],
    };

    this.UI.showDialog(options).then((data) => {
      this.data.ponto = data;
      this.data.pontoId = data.id;

      // Saving data only when pressing save globally.

    });
  };

  editRegulamento = () => {
    let regulamentos = angular.copy(this.regulamentos);
    let regulamento = angular.copy(this.data.regulamento);
    let options = {
      size: "lg",
      template: require("./regulation.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.regulamentos = regulamentos;

        $scope.op = {
          selected: regulamento,
        };

        $scope.cancel = () => {
          $dialog.dismiss();
        };

        $scope.ok = () => {
          $dialog.close({
            regulamento: $scope.op.selected,
          });
        };
      }]
    };

    this.UI.showDialog(options).then((data) => {
      this.data.regulamento = data.regulamento;
      this.data.regulamentoId = data.regulamento.id;

      // Saving data only when pressing save globally.

    });
  };

  showModalForEnsaio = () => {
    let ensaio = angular.copy(this.data.jsonData.ensaio);
    if (!ensaio) ensaio = {};
    let options = {
      size: "lg",
      template: require("./ensaio.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.quadro = {};
        $scope.tipoimovelId = this.data.fracao.tipoimovelId;

        $scope.cancel = () => {
          $dialog.dismiss();
        };

        $scope.ok = () => {
          if ($scope.quadro.$valid) {
            $dialog.close($scope.data);
          }
        };
        $scope.data = {
          resistenciaTerra: ensaio.field1,
          resistenciaCircuitos: ensaio.field2,
          separacaoCircuitos: ensaio.field3,
          protecaoDiferencial: ensaio.field4,
        };
      }]
    };
    this.UI.showDialog(options).then((res) => {
      if (!this.data.jsonData.ensaio) this.data.jsonData.ensaio = {};
      this.data.jsonData.ensaio.field1 = res.resistenciaTerra;
      this.data.jsonData.ensaio.field2 = res.resistenciaCircuitos;
      this.data.jsonData.ensaio.field3 = res.separacaoCircuitos;
      this.data.jsonData.ensaio.field4 = res.protecaoDiferencial;

      // Saving data only when pressing save globally.

    });
  };

  save = () => {
    // Remove "expanded" from deficiencias
    let o = angular.copy(this.data.jsonData);
    o.deficiencias.forEach((d) => {
      delete d.expanded;
    });
    this.data.json = JSON.stringify(o);
    return this.Relatorio.upsert(this.data).$promise;
  };

  getReportStatus = () => {
    if (!this.loaded) {
      this.reportStyle = {};
      return "";
    } else {
      if (!this.data.jsonData.realizada) {
        this.reportStyle = {
          fontWeight: 500,
          color: "#d32f2f"
        };
        this.data.resultado = 4;
        return `Instalação Reprovada`;
      } else if (this.data.jsonData.deficiencias.some((d) => d.deficiencia.tipoId === 4 && (d.r === 0 || d.r === 2))) {
        // Procura se tem deficiencias do tipo G
        this.reportStyle = {
          fontWeight: 500,
          color: "#d32f2f",
        };
        this.data.resultado = 4;
        return "Instalação Reprovada";
      } else if (this.data.jsonData.deficiencias.some((d) => d.deficiencia.tipoId === 1 && (d.r === 0 || d.r === 2))) {
        // Procura se tem deficiencias do tipo NG-1
        this.reportStyle = {
          fontWeight: 500,
          color: "#ffa000",
        };
        this.data.resultado = 3;
        return "Inst.c/deficiências para superar em 60 dias";
      } else if (this.data.jsonData.deficiencias.some((d) => (d.deficiencia.tipoId === 2 || d.deficiencia.tipoId === 3) && (d.r === 0 || d.r === 2))) {
        // Procura se tem deficiencias do tipo NG-2(D)
        this.reportStyle = {
          fontWeight: 500,
          color: "#388e3c",
        };
        this.data.resultado = 2;
        return "Instalação Aprovada, com deficiências";
      } else {
        this.reportStyle = {
          fontWeight: 500,
          color: "#388e3c",
        };
        this.data.resultado = 1;
        return "Instalação Aprovada";
      }
    }
  };
}

DetailPedidoReportController.$inject = ["$rootScope", "$state", "$q", "AuthenticationService", "AuthorizationService", "IeRelatorio", "UIService", "IeDeficiencia", "IePontosVerificados", "IeEnquadramentoLegal", "Alteracaoestado"];
