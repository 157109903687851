import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
const uuid = () => `${Math.floor(Math.random() * 16 ** 4).toString(16)}${Math.floor(Math.random() * 16 ** 4).toString(16)}${Math.floor(Math.random() * 16 ** 4).toString(16)}${Math.floor(Math.random() * 16 ** 12).toString(16)}`;
const createSharedContext = (Root = Fragment, isAsync = true) => {
    // Make method accessible by both SharedContext and withSharedContext
    let renderWithSharedContext = () => ({
        update: () => { },
        remove: () => { }
    });
    const SharedContext = rootProps => {
        // List of components and their props to be rendered with react portal in their designated nodes
        const [components, setComponents] = useState([]);
        useEffect(() => {
            renderWithSharedContext = (component) => {
                // Add component to list
                setComponents(prevState => {
                    prevState.push(component);
                    return [...prevState];
                });
                // Return callbacks to update and remove component from list
                return {
                    update: props => {
                        setComponents(prevState => {
                            const prevComponent = prevState.find(c => c?.key === component.key);
                            if (!prevComponent) {
                                return prevState;
                            }
                            prevComponent.props = props;
                            return [...prevState];
                        });
                    },
                    remove: () => {
                        setComponents(prevState => {
                            const index = prevState.findIndex(c => c?.key === component.key);
                            if (index === -1) {
                                return prevState;
                            }
                            prevState[index] = undefined;
                            return [...prevState];
                        });
                    }
                };
            };
        }, []);
        // Return list of react portals wrapped in one or multiple providers
        return (<Root {...rootProps}>
        {components.map(component => {
                if (!component) {
                    return null;
                }
                const { key, node, component: C, props } = component;
                return createPortal(<C key={key} {...props}/>, node);
            })}
      </Root>);
    };
    const useSharedContext = component => {
        // Create as local variable instead of returning inline to fix TSLint
        const UseSharedContext = props => {
            // Create unique key for this instance
            const key = useRef(uuid());
            // Hold reference to rendered hidden DOM node
            const ref = useRef(null);
            // Instance is SharedContext
            const instance = useRef();
            (isAsync ? useEffect : useLayoutEffect)(() => {
                if (instance.current) {
                    // Pass prop updates to instance in SharedContext
                    instance.current.update(props);
                }
            }, [props]);
            (isAsync ? useEffect : useLayoutEffect)(() => {
                if (ref.current && ref.current.parentElement) {
                    // Create instance in SharedContext
                    instance.current = renderWithSharedContext({
                        key: key.current,
                        node: ref.current.parentElement,
                        component,
                        props
                    });
                    // Return callback to unmount component in SharedContext when this component is unmounted
                    return instance.current.remove;
                }
                return;
            }, []);
            // Hidden <div> component only used to get reference in dom
            return <div ref={ref} style={{ display: 'none' }}/>;
        };
        return UseSharedContext;
    };
    return {
        component: SharedContext,
        use: useSharedContext
    };
};
export default createSharedContext;
