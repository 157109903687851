import { routes } from './routes';
import Empresas from './empresas';
import Pessoas from './pessoas';
import Cotacoes from './cotacoes';
import Dashboard from './dashboard';
import ClientesLegacy from './clientes_legacy';
import CrmService from './crm.service.ts';
import CrmLegacyService from './crm.legacy.service';

export default angular.module('app.crm', [Empresas, Pessoas, Cotacoes, Dashboard, ClientesLegacy])
  .config(routes)
  .service("CrmService", CrmService)
  .service("CrmLegacyService", CrmLegacyService)
  .name;
