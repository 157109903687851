export function routes($stateProvider) {
  $stateProvider
    .state('app.rh', {
      url: '/rh',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.rh.events', {
      url: '/events',
      controller: 'RhEventController',
      controllerAs: 'vm',
      role: ['eventCheckIn'],
      title: 'RH - Eventos',
      template: require('./events/view.html')
    })
  ;
}

routes.$inject = ['$stateProvider'];
