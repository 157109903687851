export default class DetalhesListasVerificacaoController {
  constructor($state, $q, UIService, $http, ElvListaVerificacao, ElvListaRelatorio, ElvListaTopicoPonto, ElvTopicoVerificacao) {
    this.UI = UIService;
    this.$http = $http;
    this.$state = $state;
    this.$q = $q;
    this.id = $state.params.id;
    this.ElvListaVerificacao = ElvListaVerificacao;
    this.ElvListaTopicoPonto = ElvListaTopicoPonto;
    this.ElvTopicoVerificacao = ElvTopicoVerificacao;
    this.ElvListaRelatorio = ElvListaRelatorio;
    this.loaded = false;
    this.data = null;
    this.loadData();

    //Infinite Scroll Magic
    this.infiniteScroll = {};
    this.infiniteScroll.numToAdd = 20;
    this.infiniteScroll.currentItems = 20;

    this.topicoOptions = {
      containment: 'parent',
      tolerance: 'pointer',
      handle: '.pmd-move-handle',
      'ui-floating': true,
      stop: (e, ui) => {
        for (let i in this.data.topicoRel) {
          this.data.topicoRel[i].ordemTopico = Number(i) + 1;
          ElvListaTopicoPonto.upsert(this.data.topicoRel[i]);
        }
      }
    };
  }

  addMoreItems = () => {
    this.infiniteScroll.currentItems += this.infiniteScroll.numToAdd;
  };

  loadData = () => {
    this.loaded = false;
    this.ElvListaVerificacao.findOne({
      filter: {
        where: {
          active: true,
          id: this.id,
        },
        include:
          [{
            relation: 'norma',
            scope: {
              where: {
                active: true
              }
            }
          }, {
            relation: 'topicoRel',
            scope: {
              where: {
                active: 1,
                pontoId: 0
              },
              order: 'ordemTopico ASC',
              include: {
                relation: 'topico',
                scope: {
                  where: {
                    active: 1
                  }
                }
              }
            }
          }]
      }
    }).$promise.then(res => {
      this.data = res;
      this.loaded = true;
    })
  };

  addTopico = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./topico.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.label = "Adicionar Tópico de Verificação";

        $scope.topico = {
          id: 0,
          active: true,
          nome: ""
        };

        $scope.ok = () => {
          $scope.$close($scope.topico);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    }).then(res => {
      if (res) {
        this.ElvTopicoVerificacao.create(res).$promise.then((topico) => {
          this.ElvListaTopicoPonto.find({
            filter: {
              where: {
                listaId: this.id,
                active: true,
              },
              order: 'ordemTopico DESC',
              limit: 1
            }
          }).$promise.then((a) => {
            let ordemTopico = 1;
            if (a.length === 1) {
              ordemTopico = a[0].ordemTopico + 1;
            }
            let l = {
              ordemTopico: ordemTopico,
              id: 0,
              listaId: this.id,
              active: true,
              pontoId: 1,
              topicoId: topico.id,
              ordemPonto: null
            };
            this.ElvListaTopicoPonto.create(l).$promise.then((res) => {
              this.loadData();
              this.UI.addToast('Tópico adicionado com sucesso');
            }).catch((error) => {
              console.log(error);
              this.UI.addToast('Erro ao adicionar tópico');
            })
          });
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Erro ao adicionar tópico');
        })
      }
    });
  };

  editTopico = (rr) => {
    let cenas = this.UI.showWaiting();
    this.ElvTopicoVerificacao.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((rcm) => {
      cenas.close();
      this.UI.showDialog({
        size: 'lg',
        template: require('./topico.dialog.html'),
        controller: ['$scope', '$dialog', function ($scope, $dialog) {
          $scope.label = "Editar Tópico de Verificação";

          $scope.topico = {
            id: rr.id,
            nome: rr.nome,
            active: true
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      }).then(r => {
        if (r) {
          this.loaded = false;
          this.ElvTopicoVerificacao.findOne({
            filter: {
              where: {
                id: rr.id,
                active: 1
              }
            }
          }).$promise.then((topico) => {
            topico.nome = r.topico.nome;
            topico.$save().then((res) => {
              this.loadData();
              this.UI.addToast('Tópico atualizado com sucesso');
            }, (error) => {
              console.log(error);
              this.UI.addToast('Erro ao atualizar tópico');
              this.loadData();
            });
          }).catch((error) => {
            console.log(error);
            this.UI.addToast('Não foi possível encontrar este tópico nesta lista de verificação.');
            this.loadData();
          });
        }
      })
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar tópicos de verificação. Por favor tente mais tarde.");
    });
  };

  editObservacoes = () => {
    let c = angular.copy(this.data);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {
        $scope.observacoes = c.observacoes;

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.ok = () => {
          $dialog.close($scope.observacoes);
        }
      }]
    });
    dialog.then((res) => {
        c.observacoes = res;
        c.$save().then(() => {
          this.loadData();
          this.loaded = false;
          this.UI.addToast('Lista de verificação atualizada com sucesso!');
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro ao atualizar a lista de verificação.');
        });
      }
    );
  };

  assignTopicos = () => {
    let a = angular.copy(this.data.topicoRel);
    let nin = [];
    a.forEach(f => {
      nin.push(f.topicoId);
    });
    let dialog = this.UI.showDialog({
      size: 'lg',
      controller: ['$scope', 'ElvTopicoVerificacao', ($scope, ElvTopicoVerificacao) => {
        $scope.label = "Adicionar Tópico de Verificação";

        $scope.data = {
          selected: []
        };

        ElvTopicoVerificacao.find({
          filter: {
            where: {
              id: {
                nin: nin
              },
              active: true
            }
          }
        }).$promise.then(res => {
          $scope.hasLoaded = true;
          $scope.topicos = res;
        });
        $scope.cancel = () => $scope.$dismiss('cancel');

        $scope.ok = () => {
          $scope.$close($scope.data);
        };

        //Infinite Scroll Magic
        $scope.infiniteScroll = {};
        $scope.infiniteScroll.numToAdd = 20;
        $scope.infiniteScroll.currentItems = 20;

        $scope.addMoreItems = function () {
          $scope.infiniteScroll.currentItems += $scope.infiniteScroll.numToAdd;
        };
      }],
      template: require('./topico.dialog.html')
    });
    dialog.then(res => {
      this.ElvListaVerificacao.findOne({
        filter: {
          where: {
            id: this.id,
            active: true,
          },
          include: {
            relation: 'topicoRel',
            scope: {
              where: {
                active: 1
              }
            }
          }
        }
      }).$promise.then((listaVerificacaoAtual) => {
        this.ElvListaRelatorio.find({
          filter: {
            where: {
              listaId: listaVerificacaoAtual.id,
              active: true,
            }
          }
        }).$promise.then((listaRelatorios) => {
          //Se ja existe relatorio cria lista nova com nova versão superior à anterior
          if (listaRelatorios && listaRelatorios.length > 0) {
            let listaNova = {
              id: 0,
              normaId: listaVerificacaoAtual.normaId,
              observacoes: listaVerificacaoAtual.observacoes,
              numeroVersao: listaVerificacaoAtual.numeroVersao + 1,
              active: true,
            };
            this.ElvListaVerificacao.create(listaNova).$promise.then((li) => {
              listaVerificacaoAtual.topicoRel.forEach(t => {
                let data = {
                  id: 0,
                  topicoId: t.topicoId,
                  listaId: li.id,
                  active: true,
                  pontoId: t.pontoId,
                  ordemTopico: t.ordemTopico,
                  ordemPonto: t.ordemPonto
                };
                this.ElvListaTopicoPonto.create(data).$promise.then((listaTopicoPonto) => {
                }).catch((error) => {
                  console.log(error);
                  this.UI.addToast('Erro ao adicionar tópico de verificação.');
                });
              });

              if (res.selected.length > 0) {
                let tasks = [];
                res.selected.forEach((s, i, t) => {
                  let defer = this.$q.defer();
                  let data = {
                    id: 0,
                    listaId: li.id,
                    topicoId: s.id,
                    pontoId: 0,
                    ordemTopico: listaVerificacaoAtual.topicoRel.filter(x => x.pontoId === 0).length + i + 1,
                    ordemPonto: 0,
                    active: true
                  };
                  this.ElvListaTopicoPonto.create(data).$promise.then((res) => {
                    defer.resolve(res);
                  }).catch((error) => {
                    console.log(error);
                    defer.reject(error);
                    this.UI.addToast('Erro ao adicionar tópico');
                  });
                  tasks.push(defer.promise);
                });
                this.$q.all(tasks).then((res) => {
                  this.$state.go('app.elv.listasverificacao.details', {id: li.id});
                }).catch(e => {
                  // Do something when at least one count fails
                });
              }
            }).catch((error) => {
              console.log(error);
              this.UI.addToast('Erro ao criar lista de verificação.');
            });
          } else { //Se não existir relatório adicionam-se tópicos e pontos à versão atual
            if (res.selected.length > 0) {
              let tasks = [];
              res.selected.forEach((s, i, t) => {
                let defer = this.$q.defer();
                let data = {
                  id: 0,
                  listaId: listaVerificacaoAtual.id,
                  topicoId: s.id,
                  pontoId: 0,
                  ordemTopico: listaVerificacaoAtual.topicoRel.filter(x => x.pontoId === 0).length + i + 1,
                  ordemPonto: 0,
                  active: true
                };
                this.ElvListaTopicoPonto.create(data).$promise.then((res) => {
                  defer.resolve(res);
                }).catch((error) => {
                  console.log(error);
                  defer.reject(error);
                  this.UI.addToast('Erro ao adicionar tópico');
                });
                tasks.push(defer.promise);
              });
              this.$q.all(tasks).then((res) => {
                this.loadData();
              }).catch(e => {
                // Do something when at least one count fails
              });
            }
          }
        });
      });
    });
  };

  addDataPublicacao = () => {
    this.ElvListaRelatorio.find({
      filter: {
        where: {
          listaId: this.id,
          active: true,
        }
      }
    }).$promise.then((listaRelatorios) => {
      // Se não existir um relatório com esta versão posso editar a data de publicação
      if (!listaRelatorios || listaRelatorios.length === 0) {
        let c = angular.copy(this.data);
        let dialog = this.UI.showDialog({
          size: 'lg',
          template: require('./version.dialog.html'),
          controller: ['$scope', '$dialog', ($scope, $dialog) => {
            $scope.dataPublicacao = c.dataPublicacao;

            $scope.cancel = () => {
              $dialog.dismiss('cancel');
            };

            $scope.ok = () => {
              $dialog.close($scope.dataPublicacao);
            }
          }]
        });
        dialog.then((res) => {
          c.dataPublicacao = res;
          c.$save().then(() => {
            this.loadData();
            this.loaded = false;
            this.UI.addToast('Data de Publicação adicionada com sucesso!');
          }).catch((err) => {
            console.log(err);
            this.UI.addToast('Ocorreu um erro a adicionar data de publicação.');
          });
        });
      } else { // Se já existir um relatório com esta versão aparece mensagem
        this.UI.addToast('Já existe um relatório para esta versão.');
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast('Erro a encontrar a lista de verificação.');
    });
  };
}

DetalhesListasVerificacaoController.$inject = ['$state', '$q', 'UIService', '$http', 'ElvListaVerificacao', 'ElvListaRelatorio', 'ElvListaTopicoPonto', 'ElvTopicoVerificacao'];
