import { routes } from './routes'

import OblProdutosListController from './list/controller';
import OblProdutosDetailsController from './details/controller';

export default angular.module('app.obler.produtos', [])
  .config(routes)
  .controller('OblProdutosListController', OblProdutosListController)
  .controller('OblProdutosDetailsController', OblProdutosDetailsController)
  .name;
