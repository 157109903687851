export default class DirectoryRelatorioController {
  constructor($state, UIService, ElvRelatorioJson, ElvNorma, AuthenticationService, Group) {
    this.$state = $state;
    this.UI = UIService;
    this.Group = Group;
    this.ElvRelatorioJson = ElvRelatorioJson;
    this.ElvNorma = ElvNorma;
    this.Auth = AuthenticationService;

    this.tableLoading = true;

    this.gerado = [{name: "Sim", value: 1}, {name: "Não", value: 0}];
    this.resultados = [
      { name: "Aprovado (com C3/C2*)", value: 6},
      { name: "Aprovado (com C2*)", value: 5},
      { name: "Reprovado", value: 3},
      { name: "Aprovado (com C3)", value: 2},
      { name: "Aprovado", value: 1},
      { name: "Aguarda submissão", value: 0}
    ];
    this.funcionarios = [];
    this.normas = [];
    this.filtersLoaded = [1, 1, 0, 0];

    this.opt = $state.params;
    // Total processes for currently being displayed
    this.total = 0;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [
      {
        displayName: "ID SGI",
        name: "id",
        visible: false,
        sortable: true
      },
      {
        displayName: "Nº Processo",
        name: "numProcesso",
        visible: true,
        sortable: false
      },
      {
        displayName: "Nº Camarário",
        name: "numCamarario",
        visible: true,
        sortable: true
      },
      {
        displayName: "Nº revisão",
        name: "versao",
        visible: true,
        sortable: false
      },
      {
        displayName: "Norma",
        name: "norma",
        visible: true,
        sortable: true
      },
      {
        displayName: "Resultado",
        name: "resultado",
        visible: true,
        sortable: true
      },
      {
        displayName: "Data de Inspeção",
        name: "dataAgendamento",
        visible: true,
        exportDate: 'DD-MM-YYYY',
        sortable: true
      },
      {
        displayName: "Data de submissão",
        name: "submissao",
        visible: false,
        exportDate: 'DD-MM-YYYY',
        sortable: false
      },
      {
        displayName: "Inspetor",
        name: "colaborador",
        visible: true,
        sortable: true
      },
      {
        displayName: "Validado?",
        name: "validado",
        visible: true,
        sortable: true
      }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');


    this.columns = [
      {
        id: "ElvProcesso.id",
        name: "ID SGI",
        type: "o"
      },
      {
        id: "ElvElevador.numeroCamarario",
        name: "Nº Camarário",
        type: "o"
      },
      {
        id: "ElvRelatorioJson.validado",
        name: "Validado",
        type: "s",
        list: this.gerado
      },
      {
        id: "ElvListaVerificacao.normaId",
        name: "Norma",
        type: "s",
        list: this.normas
      },
      {
        id: "ElvRelatorioJson.resultado",
        name: "Resultado",
        type: "s",
        list: this.resultados
      },
      {
        id: "ElvRelatorioJson.tecnicoId",
        name: "Inspetor",
        type: "s",
        list: this.funcionarios
      },
      {
        id: "ElvAgendamento.data",
        name: "Data de Inspeção",
        type: "d",
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      }
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem("ElvRelatorioDisplayColumns")) {
      let cols = JSON.parse(localStorage.getItem("ElvRelatorioDisplayColumns"));
      if (cols && cols.length > 0) {
        cols.forEach((c) => {
          let i = this.displayColumns.findIndex((x) => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem("ElvRelatorioFilter")) {
      this.customFilters = JSON.parse(localStorage.getItem("ElvRelatorioFilter"));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach((filter) => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {},
            };
            data.column.selected = this.columns.find((f) => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast("Não foi possível carregar filtros");
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem("ElvRelatorioFilter", JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === "ElvRelatorio.inspetorId")
          f.column.selected.list = this.emies;
        if (f.column.selected.id === "ElvListaVerificacao.normaId")
          f.column.selected.list = this.normas;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === "d") {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    this.loadData();

    this.getRelatorios();
  }

  isColumnVisible = (column) => {
    let f = this.displayColumns.find((x) => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  canSelect = (row) => {
    return !row.validado;
  };

  clearFilter = () => {
    localStorage.removeItem('ElvRelatorioFilter');
    this.customFilters = [];
    this.getRelatorios();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find((x) => x.key === data.key)) {
      // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value,
      });
    } else {
      // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) {
        // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key],
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value,
        });
      } else {
        // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach((f) => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split(".").filter(Boolean).length === 1 && f.column.selected.type === "d") {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format,
        });
      } else {
        // All other cases where fields exist in database
        if (f.column.selected.type === "o") {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === "d") {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format,
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id) data.value = f.value.selected.id;
            else data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral,
    };
  };

  loadData = () => {
    this.Group.findOne({
      filter: {
        where: {
          name: {
            like: "Inspetor%ELV",
          },
        },
        include: {
          relation: "usergroup",
          scope: {
            include: "user",
          },
        },
      },
    }).$promise.then((g) => {
      let funcionarios = [];
      g.usergroup.forEach((u) => {
        funcionarios.push(u.user);
      });

      funcionarios = _.orderBy(funcionarios, "name", "asc");
      this.filtersLoaded[2] = 1;
      this.funcionarios = funcionarios;
    }).catch((e) => {
      this.filtersLoaded[2] = 1;
      this.funcionarios = [];
      this.UI.addToast("Erro de carregamento de dados para filtragem (Colaboradores)");
    });

    this.ElvNorma.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((normas) => {
      normas.forEach(n => {
        n.name = n.designacao;
      });
      this.normas = normas;
      this.filtersLoaded[3] = 1;
    }).catch((error) => {
      this.normas = [];
      this.filtersLoaded[3] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Normas)");
    });
  };

  isDateValid = (date) => {
    return moment(date).isValid();
  };

  getRelatorios = () => {
    this.opt = this.opt || this.defaultOpt;

    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {});

    this.ElvRelatorioJson.table({
      params: {
        fields: [
          "ElvRelatorioJson.id as id",
          "ElvElevador.numeroCamarario as numCamarario",
          "ElvRelatorioJson.resultado as resultado",
          "ElvProcesso.iidAno as iidAno",
          "ElvProcesso.id as processoId",
          "ElvProcesso.numeroProcesso as numProcesso",
          "ElvProcesso.iidProc as iidProc",
          "ElvRelatorioJson.submissao as submissao",
          "ElvRelatorioJson.versao as versao",
          "ElvRelatorioJson.validado as validado",
          "ElvRelatorioJson.validadoa as validadoa",
          "Funcionario2.name as validadopor",
          "Funcionario.name as colaborador",
          "ElvAgendamento.data as dataAgendamento",
          "ElvNorma.designacao as norma"
        ],
        from: ["ElvRelatorioJson", "ElvProcesso", "Funcionario", "ElvElevador", "Funcionario", "ElvAgendamento", "ElvListaVerificacao", "ElvNorma"],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined, "ElvAgendamento.processoId", undefined, "ElvListaVerificacao.normaId"],
        references: [undefined, "ElvRelatorioJson.processoId", "ElvRelatorioJson.tecnicoId", "ElvProcesso.elevadorId", "ElvRelatorioJson.validadoporId", "ElvRelatorioJson.processoId", "ElvRelatorioJson.listaId", "ElvNorma.id"],
        aliases: [undefined, undefined, undefined, undefined, "Funcionario2"],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      },
    })
      .$promise.then((res) => {
        let page = this.opt.page;
        let items = this.opt.items;

        let total = res.count;

        this.start = total > 0 ? (page - 1) * items + 1 : 0;
        if (this.start - 1 + items >= total) {
          this.end = total;
        } else {
          this.end = Number.parseInt(this.start - 1 + items);
        }

        this.relatorios = res.data;
        this.total = total;
        this.tableLoading = false;
      })
      .catch((e) => {
        console.log(e);
        this.tableLoading = false;
        this.UI.addToast(
          "Não foi possível carregar relatórios com os parâmetros fornecidos."
        );
      });
  };

  hasSelect = () => {
    return _.some(this.relatorios, (a) => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) {
      // Not all are selected, select all
      this.relatorios.forEach((a) => {
        a.selected = true;
      });
      this.nSelected = this.relatorios.filter((a) => a.selected).length;
    } else {
      // Remove all selections
      this.relatorios.forEach((a) => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    // this.everythingSelected = false;
  };

  // NOT USED IN THIS VIEW
  selectEverything = (type) => {
    //   if (!type) {
    //     this.relatorios.forEach((a) => {
    //       a.selected = false;
    //     });
    //     this.nSelected = 0;
    //     this.allSelected = false;
    //   }
    //   this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.relatorios, (r) => r.selected).length;
    this.allSelected = this.nSelected >= this.relatorios.length;
    // this.everythingSelected = false;
  };

  sort = (key) => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname) this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === "asc" ? "desc" : "asc";
    this.$state.go("app.elv.relatorios.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getRelatorios();
  };

  item = (val) => {
    this.opt.items = val;
    this.$state.go("app.elv.relatorios.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getRelatorios();
  };

  page = (sum) => {
    this.opt.page += sum;
    if (this.opt.page < 1) this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go("app.elv.relatorios.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getRelatorios();
  };

  validateSelected = () => {
    let wait = this.UI.showWaiting();
    let ids = this.relatorios.filter((x) => x.selected).map((x) => x.id);
    let userId = this.Auth.getId();

    this.ElvRelatorioJson.finish({
      ids: ids,
    })
      .$promise.then((r) => {
        wait.close();
        if (!r.length) {
          this.UI.addToast("Todos os relatórios foram validados");
        } else if (r.length === ids.length) {
          this.UI.addToast("Ocorreu um erro ao validar relatórios");
        } else {
          // Buscar numero de resultados que falharam e apresenta dialog
          this.UI.addToast("Não foi possível validar todos os relatórios seleccionados");
        }
        this.getRelatorios();
      })
      .catch((e) => {
        console.log(e);
        wait.close();
      });
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.gerado.length && !this.funcionarios.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach((f) => {
      if (f.id === "ElvRelatorio.gerado") f.list = this.gerado;
      if (f.id === "ElvRelatorio.submetido") f.list = this.gerado;
      if (f.id === "ElvListaVerificacao.normaId") f.list = this.normas;
      if (f.id === "ElvRelatorioJson.tecnicoId") f.list = this.funcionarios;
      if (f.id === "ElvRelatorioJson.validado") f.list = this.gerado;
      if (f.id === "ElvRelatorioJson.resultado") f.list = this.resultados;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === "ElvListaVerificacao.normaId")
          f.column.selected.list = this.normas;
        if (f.column.selected.id === "ElvRelatorioJson.tecnicoId")
          f.column.selected.list = this.funcionarios;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === "s") i.value = {};
    else i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, (f) => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === "d") {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem("ElvRelatorioFilter", JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go("app.elv.relatorios.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });

    this.getRelatorios();
  };

  selectVisibleColumns = () => {
    let options = {
      size: "md",
      template: require("./columns.dialog.html"),
      controller: ["$dialog", "$scope", (dialog, scope) => {
          scope.title = "Editar Campos Visíveis";
          scope.displayColumns = angular.copy(this.displayColumns);

          scope.ok = () => {
            dialog.close(scope);
          };

          scope.cancel = () => {
            dialog.dismiss("cancel");
          };
        },
      ],
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem("ElvRelatorioDisplayColumns", JSON.stringify(this.displayColumns));
      }
    });
  };

  getResultado = (valor) => {
    return this.resultados.find((r) => r.value == valor);
  };

  getStyle = (valor) => {
    if (valor == 0) {
      return {
        "font-weight": 500,
        color: "#4d575d"
      };
    }
    if (valor == 1 || valor == 2 || valor == 5 || valor == 6) {
      return {
        "font-weight": 500,
        color: "#388e3c"
      };
    }
    if (valor == 3) {
      return {
        "font-weight": 500,
        color: "#d32f2f"
      };
    }
  };
}

DirectoryRelatorioController.$inject = ["$state", "UIService", "ElvRelatorioJson", "ElvNorma", "AuthenticationService", "Group"];
