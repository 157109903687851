export default class ManagementController {
  constructor($rootScope, $state, $filter, UIService, AdministrationService, AuthenticationService, Funcionario, AtvEntidadeProprietaria) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;
    this.UI = UIService;
    this.Administration = AdministrationService;
    this.Authentication = AuthenticationService;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.Funcionario = Funcionario;

    this.userData = {
      data: [],
      filter: '',
      pagination: {
        perPage: 10,
        current: 0,
        previous: () => {
          if (this.userData.pagination.current > 0)
            this.userData.pagination.current--;
        },
        onFilter: () => {
          const filteredArray = $filter('filter')(this.userData.data, this.userData.filter);
          const afterFilter = Math.ceil(filteredArray.length / this.userData.pagination.perPage) - 1;
          if (afterFilter < this.userData.pagination.current) {
            this.userData.pagination.current = afterFilter;
          }
        },
        next: () => {
          if (this.userData.pagination.current < this.userData.pagination.total() - 1)
            this.userData.pagination.current++;
        },
        total: () => {
          const filteredArray = $filter('filter')(this.userData.data, this.userData.filter);
          return Math.ceil(filteredArray.length / this.userData.pagination.perPage);
        }
      }
    };
    this.groupData = {
      data: [],
      filter: "",
      pagination: {
        perPage: 10,
        current: 0,
        previous: () => {
          if (this.groupData.pagination.current > 0)
            this.groupData.pagination.current--;
        },
        onFilter: () => {
          const filteredArray = $filter('filter')(this.groupData.data, this.groupData.filter);
          const afterFilter = Math.ceil(filteredArray.length / this.groupData.pagination.perPage) - 1;
          if (afterFilter < this.groupData.pagination.current) {
            this.groupData.pagination.current = afterFilter;
          }
        },
        next: () => {
          if (this.groupData.pagination.current < this.groupData.pagination.total() - 1)
            this.groupData.pagination.current++;
        },
        total: () => {
          const filteredArray = $filter('filter')(this.groupData.data, this.groupData.filter);
          return Math.ceil(filteredArray.length / this.groupData.pagination.perPage);
        }
      }
    };
    this.loaded = false;

    this.loadInfo();
  }

  loadInfo = () => {
    this.loaded = false;
    this.Administration.getUsers().then((users) => {
      this.Administration.getGroups().then((groups) => {
        this.userData.data = users;
        this.groupData.data = groups;
        this.loaded = true;
      })
    });
  };

  isCurrentUser = (user) => {
    return this.Authentication.getId() === user.id;
  };

  addAusencia = (user) => {
    this.UI.showDialog({
      template: require('./users/edit/ausencia.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.items = ['Férias', 'Reunião', 'Consulta médica', 'Outros'];

        $scope.dateOptions = {
          format: 'YYYY-MM-DD',
        }

        $scope.hourOptions = {
          format: 'HH:mm:00',
        }

        $scope.data = {
          id: 0,
          active: true,
          diaInteiro: false,
          funcionarioId: user.id,
          dataInicio: moment.utc(),
          dataFim: moment.utc(),
        };

        $scope.cancel = () => { $scope.$dismiss() };
        $scope.ok = () => { $scope.$close($scope.data) };
      }]
    }).then(r => {
      if (r.motivo.includes("Outros")) {
        r.motivo = r.outro;
      }
      if (!r.diaInteiro) {
        if (r.horaInicio.isValid()) {
          r.horaInicio = r.horaInicio.format("HH:mm:00");
        }
        if (r.horaFim.isValid()) {
          r.horaFim = r.horaFim.format("HH:mm:00");
        }
      } else {
        delete r.horaInicio;
        delete r.horaFim;
      }
      this.FuncionarioAusencia.create(r).$promise.then(_ => {
        this.UI.addToast("Ausência adicionada");
      });
    }).catch(e => {
      console.log(e);
    });
  }

  blockUser = (user) => {
    if (user.blocked || this.Authentication.getId() === user.id) return;
    let confirm = this.UI.showConfirm('Deseja bloquear utilizador?');
    confirm.then(() => {
      // OK
      user.blocked = true;
      user.$save().then(() => {
        this.UI.addToast('Utilizador bloqueado');
      });
    }).catch(() => {
      // Cancel
    });
  };

  blockCondition = (user) => {
    return user.blocked || this.Authentication.getId() === user.id;
  };

  removeUser = (user) => {
    let dialog = this.UI.showConfirm('Deseja remover utilizador ' + user.name + ' do sistema?');
    dialog.then(() => {
      this.Administration.removeUser(user).then((result) => {
        let index = this.userData.data.indexOf(user);
        this.userData.data.splice(index, 1);
        let snackbar = this.UI.addSnackbar('Utilizador removido', 'Anular', true);
        snackbar.then((res) => {
          if (res) {
            this.Administration.createUser(user).then((res) => {
              this.userData.data.splice(index, 0, user);
            });
          }
        })
        //this.loadInfo();
      });
    });
  };

  // Also in edit.controller.js
  editEntidadesProprietarias = (funcionarioId) => {
    let cenas = this.UI.showWaiting();
    this.Administration.getUser(funcionarioId).then((user) => {
      this.AtvEntidadeProprietaria.find({
        filter: {
          where: {
            active: 1
          },
          order: 'ordem ASC'
        }
      }).$promise.then((entidadesProprietarias) => {
        cenas.close();
        let options = this.Administration.afiliacaoOptions(user, entidadesProprietarias);

        this.UI.showDialog(options).then(res => {
          this.loaded = false;
          this.Funcionario.editEntidadesProprietarias({id: Number(user.id), eps: res.auxEntidadesProprietarias.selected}).$promise.then(r => {
            this.loadInfo();
          }).catch(err => {
            console.log(err);
            this.loaded = true;
            this.UI.addToast('Erro ao alterar afiliação. Por favor tente mais tarde');
          });
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível ler Entidades Proprietárias. Por favor tente mais tarde");
      });
    }).catch(err => {
      this.UI.addToast('Erro ao carregar dados de colaborador');
      cenas.close();
    });
  };
};

ManagementController.$inject = ['$rootScope', '$state', '$filter', 'UIService', 'AdministrationService', 'AuthenticationService', 'Funcionario', 'AtvEntidadeProprietaria'];
