import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, ListItem, ListItemText, TextField } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { v4 } from 'uuid';
import './f.extensions';
let params = [];
export function UploadDialog({ onClose, initial, folder, ListaRelatorioService, allowed, list, ...props }) {
    const [files, setFiles] = useState(null);
    const [loading, setLoading] = useState(true);
    const paramSchema = yup.object({
        resultados: yup.array(yup.number()).min(1, 'Selecione pelo menos um resultado').required('O campo é obrigatório'),
        file: yup.string().required('O campo é obrigatório'),
        designacao: yup.string().required('O campo é obrigatório')
    });
    const formik = useFormik({
        initialValues: {
            resultados: []
        },
        validationSchema: paramSchema,
        validateOnMount: false,
        onSubmit: async (d) => {
            // Carrega ficheiro se não é dummy (isto é, se não é o ficheiro inicial)
            if (files.dummy == false) {
                // Upload file
                await ListaRelatorioService.uploadTemplate('Upload', "templates", files);
            }
            else {
                // O dropzone faz weird shits, por isso se dummy, garante-se o nome do ficheiro...
                d.file = initial.file;
            }
            onClose(d);
        }
    });
    const prep = async (initial) => {
        if (initial) {
            if (initial && initial.resultados) {
                params = list.filter(r => initial.resultados.includes(r.id));
                formik.setFieldValue('resultados', initial.resultados);
            }
            if (initial && initial.designacao) {
                formik.setFieldValue('designacao', initial.designacao);
            }
            if (initial.file) {
                // Load file
                let f = new File([], initial.file, { type: ListaRelatorioService.getType(initial.file) });
                f.dummy = true;
                setFiles(f);
                formik.setFieldValue('file', initial.file);
            }
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };
    useEffect(() => {
        formik.resetForm();
        prep(initial);
    }, [initial]);
    return (<Dialog {...props} fullWidth maxWidth={'lg'}>
      <DialogTitle>{props.title}</DialogTitle>
      {!loading && (<DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} xs={12}>
              <DropzoneArea initialFiles={[files]} filesLimit={1} dropzoneText='Arraste um ficheiro para esta janela ou clique para adicionar' onChange={files => {
                if (files.length > 0) {
                    let t = files.pop();
                    let f = new File([t], `${v4()}.${t.extension()}`, { type: t.type });
                    f.dummy = t.dummy || false;
                    formik.setFieldValue('file', f.name);
                    setFiles(f);
                }
                else {
                    formik.setFieldValue('file', undefined);
                    setFiles(null);
                }
            }} onDelete={_ => {
                formik.setFieldValue('file', undefined);
                setFiles(null);
            }} acceptedFiles={allowed} showFileNames={false} showAlerts={false} showPreviewsInDropzone={true} useChipsForPreview={true} previewChipProps={{
                color: 'default',
                variant: 'filled'
            }}/>
            </Grid>
            <Grid lg={6} xs={12}>
              {' '}
              <TextField label='Designação' name='designacao' fullWidth defaultValue={formik.values?.designacao} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
            </Grid>
            <Grid lg={6} xs={12}>
              <Autocomplete id='resultados' multiple limitTags={3} defaultValue={params} disabled={list.length == 0} options={list} getOptionLabel={r => `${r.designacao}`} getOptionDisabled={option => formik.values.resultados.includes(option.id)} onChange={(_, value) => {
                formik.setFieldValue('resultados', value.map(r => r.id));
            }} renderOption={(props, option) => (<ListItem {...props} key={option.id}>
                    <ListItemText primary={option.designacao}></ListItemText>
                  </ListItem>)} renderTags={(list, props) => list.map((option, index) => {
                return <Chip {...props({ index })} key={`${index}`} label={option.designacao}/>;
            })} renderInput={params => (<TextField {...params} fullWidth onBlur={formik.handleBlur} error={formik.touched.resultados && Boolean(formik.errors.resultados)} helperText={formik.touched.resultados && formik.errors.resultados} label='Selecionar resultados' name='resultados'/>)}/>
              <FormHelperText>Selecione os resultados em que será gerado o ficheiro</FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>)}
      <DialogActions>
        <Button type='button' onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button disabled={files == null || !formik.isValid} onClick={() => {
            formik.submitForm();
        }} variant='contained'>
          OK
        </Button>
      </DialogActions>
    </Dialog>);
}
