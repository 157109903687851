import angular from 'angular';
import {routes} from './processes.routes';
import LmeDetailProcessController from "./details/details.controller";
import LmeProcessDetailEditController from "./details/details.dialog.controller";
import LMEProcessFileUploaderController from "./details/files.dialog.controller";
import LmeDirectorySchedulingController from "./directory/directory.controller";
import LmeProcessoService from "./processos.service";

export default angular.module('app.laboratory.processes', [])
  .config(routes)
  .controller('LmeDetailProcessController', LmeDetailProcessController)
  .controller('LmeProcessDetailEditController', LmeProcessDetailEditController)
  .controller('LMEProcessFileUploaderController', LMEProcessFileUploaderController)
  .controller('LmeDirectorySchedulingController', LmeDirectorySchedulingController)
  .service('LmeProcessoService', LmeProcessoService)
  .filter('lmeFormat', function() {
    return function(data) {
      let e = 'IEP';
      if (data.empresaId === 1) {
        e = 'IEP';
      } else if (data.empresaId === 2) {
        e = 'AJL';
      }
      let t;
      if (data.tipoId)
        t = data.tipoId === 1 ? 'M' : 'E';
      else
        if (data.tipo)
          t = data.tipo === "Metrologia" ? 'M' : 'E';
      let ano = data.iidAno;
      let num = ("0000" + data.iidProc) .slice(-4);
      return `${e}-${t}-${ano}-${num}`;
    }
  })
  .name;
