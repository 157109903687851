import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
const InputComponent = ({ tipo, formato, inputChange, value, index, sx, inputProps }) => {
    const handleChange = (newValue) => {
        if (newValue !== null) {
            if (index !== undefined) {
                inputChange(newValue, index);
            }
            else {
                inputChange(newValue);
            }
        }
    };
    switch (tipo) {
        case 'TEXTO':
            return (<TextField disabled={inputProps?.disable} sx={{ ...sx }} value={value} fullWidth={inputProps?.fullWidth} onChange={event => {
                    handleChange(event.target.value);
                }} label={inputProps?.label}/>);
        case 'DATA':
            if (formato === 'HH:mm') {
                // Renderiza TimePicker para formato de hora
                return (<LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker ampm={false} defaultValue={moment(value, 'HH:mm')} onChange={newValue => handleChange(newValue?.format('HH:mm') || '')} disabled={inputProps?.disable} sx={{ ...sx }}/>
          </LocalizationProvider>);
            }
            else if (formato === 'DD/MM/YYYY') {
                // Renderiza DatePicker para formato de data
                return (<LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker defaultValue={moment(value, 'DD/MM/YYYY')} onChange={newValue => handleChange(newValue?.format('DD/MM/YYYY') || '')} disabled={inputProps?.disable} sx={{ ...sx }}/>
          </LocalizationProvider>);
            }
            else if (formato === 'DD/MM/YYYY HH:mm') {
                // Renderiza DateTimePicker para data e hora
                return (<LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker ampm={false} defaultValue={moment(value, 'DD/MM/YYYY HH:mm')} onChange={newValue => handleChange(newValue?.format('DD/MM/YYYY HH:mm') || '')} disabled={inputProps?.disable} sx={{ ...sx }}/>
          </LocalizationProvider>);
            }
        case 'NUMERO':
            return <TextField variant='outlined' onChange={event => handleChange(event.target.value)} value={value} inputProps={{ type: 'number' }} disabled={inputProps?.disable} sx={{ ...sx }} fullWidth={inputProps?.fullWidth}/>;
        default:
            return null;
    }
};
export default InputComponent;
