export default class CustosAdminController {
  constructor(LmeCusto, LmeCodigoArtigo, LmeLaboratorio, UIService) {
    this.loaded = true;
    this.LmeCusto = LmeCusto;
    this.custoLoading = true;
    this.UI = UIService;
    this.custos = null;
    this.showCustosFab = false;
    this.getCustos();
  }

  getCustos = () => {
    this.custoLoading = true;
    this.LmeCusto.find({
      filter: {
        where: {
          active: 1
        },
        include: {
          relation: 'CustoProcesso',
          scope: {
            where: {active: 1}
          }
        }
      }
    }).$promise.then((res) => {
      this.custos = res;
      this.custoLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  confirmDeleteCusto = (i) => {
    let title = "Eliminar Custo";
    let warning = "Tem a certeza que pretende remover este custo?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeCusto(i);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeCusto = (s) => {
    this.custoLoading = true;
    // Find again formador to delete so we can update it
    this.LmeCusto.findOne({
      filter: {
        where: {
          id: s.id,
          active: 1
        }
      }
    }).$promise.then((custoToRemove) => {
      custoToRemove.active = 0;
      custoToRemove.$save().then((res) => {
        this.UI.addToast("Custo eliminado com sucesso!");
        this.getCustos();
      }, (error) => {
        console.log(error);
        this.custoLoading = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.custoLoading = false;
      this.UI.addToast("Não foi possível eliminar o custo. Verifique se ainda existe.");
    });
  };

  addCusto = () => {
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./add.custo.dialog.html'),
      controller: ['$scope', function ($scope) {
        $scope.custo = {
          id: 0,
          designacao: '',
          custoUnitario: '',
          pvp: '',
          unidade: '',
          codigo: null,
          editavel: true,
          faturavel: true,
          active: 1
        };
        $scope.label = "Adicionar custo";

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.custoLoading = true;
      // Remove whitespaces from codigo before and after text and capitalize
      if (ok.custo.codigo != null) {
        ok.custo.codigo = ok.custo.codigo.trim().toUpperCase();
      }
      this.LmeCusto.create(ok.custo).$promise.then(r => {
        this.getCustos();
        this.UI.addToast("Custo inserido com sucesso.");
      }).catch(error => {
        console.log(error);
        this.custoLoading = false;
        this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editCusto = (a) => {
    let options = {
      size: 'md',
      template: require('./add.custo.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.custo = angular.copy(a);
        $scope.label = "Editar custo";

        // Need to replace 0|1 into false|true for the form usage
        $scope.custo.editavel = !!$scope.custo.editavel;
        $scope.custo.faturavel = !!$scope.custo.faturavel;

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((ok) => {
      this.LmeCusto.findOne({
        filter: {
          where: {
            id: ok.custo.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        // Remove whitespaces from codigo before and after text and capitalize
        ok.custo.codigo = ok.custo.codigo.trim().toUpperCase();
        angular.extend(res, ok.custo);
        res.$save().then((r) => {
          this.UI.addToast('Dados alterados com sucesso');
          this.getCustos();
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao editar custo');
          this.getCustos();
        })
      })
    })
  };

  updateFab = (f) => {
    if (f === 'custos') { // Custos Fab
      this.showCustosFab = true;
    }
  };
}

CustosAdminController.$inject = ['LmeCusto', 'LmeCodigoArtigo', 'LmeLaboratorio', 'UIService'];
