export function routes($stateProvider) {

  $stateProvider
    .state('app.consultas', {
      url: '/consultas',
      abstract: true,
      template: '<ui-view></ui-view>'
    })
    .state('app.consultas.acessos', {
      url: '/acessos?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Registo de Acessos',
      template: require('./acessos/acessos.view.html'),
      controller: 'AcessosController',
      role: 'logging',
      controllerAs: 'vm',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.consultas.assinaturas', {
      url: '/assinaturas?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Assinaturas',
      template: require('./assinaturas/assinaturas.view.html'),
      controller: 'AssinaturasController',
      role: 'controlPanel',
      controllerAs: 'vm',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 12,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    // .state('app.consultas.changelog', {
    //   url: '/changelog',
    //   title: 'Changelog',
    //   template: "<changelog-page></changelog-page>",
    //   role: ['$authenticated','$internal']
    // })
    .state('app.consultas.changelog', {
      url: '/changelog',
      title: 'Changelog',
      template: require('./changelog/changelog.view.html'),
      controller: 'ChangelogController',
      role: ['$authenticated','$internal'],
      controllerAs: 'vm'
    })
  ;
}

routes.$inject = ['$stateProvider'];
