import {routes} from './processos.routes';
import PadProcessosListController from "./directory/directory.controller";
import PadProcessosConcluidosListController from "./concluidos/directory.controller";
import PadProcessosEmCursoListController from "./emcurso/directory.controller";
import PadProcessosDetailsController from "./details/details.controller";
import PadProcessoCreateController from "./new/new.controller";
import PadProcessosDetailsLinhaController from "./linhas/details.controller";
import PadProcessoFileUploaderController from "./details/files.dialog.controller";

export default angular.module('app.pad.processo', [])
  .config(routes)
  .controller('PadProcessoListController', PadProcessosListController)
  .controller('PadProcessoConcluidosListController', PadProcessosConcluidosListController)
  .controller('PadProcessoEmCursoListController', PadProcessosEmCursoListController)
  .controller('PadProcessosDetailsController', PadProcessosDetailsController)
  .controller('PadProcessoCreateController', PadProcessoCreateController)
  .controller('PadProcessosDetailsLinhaController', PadProcessosDetailsLinhaController)
  .controller('PadProcessoFileUploaderController', PadProcessoFileUploaderController)
  .name;
