export default class LmeDetailsEntitiesController {
  constructor(LmeSubentidade, $state, UIService, $http) {
    this.UI = UIService;
    this.$http = $http;
    this.id = $state.params.id;
    this.LmeSubentidade = LmeSubentidade;
    this.loaded = false;
    if (angular.isUndefined($state.params.id) || isNaN($state.params.id) || $state.params.id.toString().length === 0) {
      $state.go('app.lab.entities.list');
    } else {
      this.loadData();
    }
  }

  loadData = () => {
    this.loaded = false;
    this.LmeSubentidade.findOne({
      filter: {
        where: {
          active: true,
          id: this.id,
        },
        include: {
          relation: 'subcontratacoes',
          scope: {
            include: {
              relation: 'processo',
              scope: {
                include: 'Estado'
              }
            },
            order: 'id desc'
          }
        }
      }
    }).$promise.then(res => {
      this.data =res;
      this.formatZipCode();
      this.loaded = true;
    })
  };

  formatZipCode = () => {
    if (this.data.cp4 === null) {
      this.data.formatedZipCode = "N/D";
    }
    else if (this.data.cp4 !== null && this.data.cp3 === null) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" +this.data.cp4,
          cp3: null
        }
      }).then(response => {
        this.data.localidade = response.data.result;
        this.data.formatedZipCode = this.data.cp4 + " " + this.data.localidade;
      });

    }
    else if (this.data.cp4 !== null && this.data.cp3 !== null) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" +this.data.cp4,
          cp3: ("000" + this.data.cp3).slice(-3)
        }
      }).then(response => {
        this.data.cp3 = ("000" + this.data.cp3).slice(-3);
        this.data.localidade = response.data.result;
        this.data.formatedZipCode = this.data.cp4 + "-" + this.data.cp3 + " " + this.data.localidade;
      });

    }
  };

  editDetalhes = () => {
    let copy = angular.copy(this.data);
    delete copy.subcontratacoes;
    let options = {
      size: 'lg',
      template: require('./details.dialog.html'),
      controller: ['$dialog', '$scope', '$http', function ($dialog, $scope, $http) {

        $scope.getLocalidade = function () {
          $scope.o.localidade = "";
          let cp4 = parseInt($scope.o.cp4);
          let cp3 = parseInt($scope.o.cp3);
          if (!Number.isInteger(cp4)) {
            $scope.o.cp3 = "";
          } else if (cp4.toString().length !== 4) {
            $scope.o.cp3 = "";
          } else if (cp4.toString().length === 4 ) {
            if (Number.isInteger(cp3)) {
              if ($scope.o.cp3.toString().length === 3) {
                $http.post('/api/public/getLocalidade', {
                  cp4: $scope.o.cp4,
                  cp3: $scope.o.cp3
                }).then(response => {
                  $scope.o.localidade = response.data.result;
                });
              }
            } else {
              $http.post('/api/public/getLocalidade', {
                cp4: $scope.o.cp4,
                cp3: null
              }).then(response => {
                $scope.o.localidade = response.data.result;
              });
            }
          }
        };

        $scope.o = copy;
        $scope.getLocalidade();

        $scope.isOk = () => {
          let o = $scope.o;
          return o.designacao !== "" && o.nif !== "" && o.email !== "";
        };


        $scope.ok = () => {
          delete $scope.o.localidade;
          $dialog.close($scope.o);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }],
    };
    let modal = this.UI.showDialog(options);
    modal.then(res => {
      if (res) {
        let cp4 = parseInt(res.cp4);
        let cp3 = parseInt(res.cp3);
        if (Number.isNaN(cp4)) {
          res.cp4 = null;
          res.cp3 = null;
        } else {
          res.cp4 = cp4;
        }
        if (Number.isNaN(cp3)) {
          res.cp3 = null;
        } else {
          res.cp3 = cp3;
        }
       this.LmeSubentidade.upsert(res).$promise.then(() => {
         this.loadData();
         this.UI.addToast('Dados atualizados com sucesso');
       }).catch(() => {
         this.UI.addToast('Erro ao atualizar dados')
       });
      }
    });
  }
}

LmeDetailsEntitiesController.$inject = ['LmeSubentidade', '$state', 'UIService', '$http'];
