export function routes($stateProvider) {
  $stateProvider
    .state('app.susene', {
      url: '/se',
      abstract: true,
      template: '<div ui-view></div>'
    });
}

routes.$inject = ['$stateProvider'];
