import angular from 'angular';

export default angular.module('app.interface.accordion', [])
  .constant('pmdAccordionConfig', {
    closeOthers: true
  })

  .controller('pmdAccordionController', ['$scope', '$attrs', 'pmdAccordionConfig', function ($scope, $attrs, accordionConfig) {
    // This array keeps track of the accordion groups
    this.groups = [];

    // Ensure that all the groups in this accordion are closed, unless close-others explicitly says not to
    this.closeOthers = function (openGroup) {
      var closeOthers = angular.isDefined($attrs.closeOthers) ?
        $scope.$eval($attrs.closeOthers) : accordionConfig.closeOthers;
      if (closeOthers) {
        angular.forEach(this.groups, function (group) {
          if (group !== openGroup) {
            group.isOpen = false;
          }
        });
      }
    };

    // This is called from the accordion-group directive to add itself to the accordion
    this.addGroup = function (groupScope) {
      var that = this;
      this.groups.push(groupScope);

      groupScope.$on('$destroy', function (event) {
        that.removeGroup(groupScope);
      });
    };

    // This is called from the accordion-group directive when to remove itself
    this.removeGroup = function (group) {
      var index = this.groups.indexOf(group);
      if (index !== -1) {
        this.groups.splice(index, 1);
      }
    };
  }])

  // The accordion directive simply sets up the directive controller
  // and adds an accordion CSS class to itself element.
  .directive('pmdAccordion', function () {
    return {
      controller: 'pmdAccordionController',
      controllerAs: 'accordion',
      transclude: true,
      template: () => "<div role='tablist' class='panel-group pmd-accordion' ng-transclude></div>"
    };
  })

  // The accordion-group directive indicates a block of html that will expand and collapse in an accordion
  .directive('pmdAccordionGroup', function () {
    return {
      replace: true,
      require: '^pmdAccordion',         // We need this directive to be inside an accordion
      transclude: true,              // It transcludes the contents of the directive into the template
      template: () =>
        "<div class='panel panel-default' ng-class='{\"active\": isOpen}'>" +
        "  <div role='tab' id='{{::headingId}}' aria-selected='{{isOpen}}' class='panel-heading' ng-keypress='toggleOpen($event)'>" +
        "    <h4 class='panel-title'>" +
        "      <a  data-toggle='collapse' aria-expanded='{{isOpen}}' aria-controls='{{::panelId}}' data-expandable='{{!isOpen}}' ng-click='toggleOpen()' ng-disabled='isDisabled'>" +
        "        <span pmd-accordion-header>{{heading}}" +
        "          <i class='mdi mdi-arrow-down md-dark pmd-sm pmd-accordion-arrow'></i>" +
        "        </span>" +
        "      </a>" +
        "    </h4>" +
        "  </div>" +
        "  <div id='{{::panelId}}' aria-labelledby='{{::headingId}}' aria-hidden='{{!isOpen}}' role='tabpanel' class='panel-collapse collapse' pmd-collapse='!isOpen'>" +
        "    <div class='panel-body' ng-transclude>" +
        "    </div>" +
        "  </div>" +
        "</div>",
      scope: {
        heading: '@',               // Interpolate the heading attribute onto this scope
        panelClass: '@?',           // Ditto with panelClass
        isOpen: '=?',
        isDisabled: '=?'
      },
      controller: function () {
        this.setHeading = function (element) {
          this.heading = element;
        };
      },
      link: function (scope, element, attrs, accordionCtrl) {
        accordionCtrl.addGroup(scope);

        scope.$watch('isOpen', function (value) {
          if (value) {
            accordionCtrl.closeOthers(scope);
          }
        });

        scope.toggleOpen = function ($event) {
          if (!scope.isDisabled) {
            if (!$event || $event.which === 32) {
              scope.isOpen = !scope.isOpen;
            }
          }
        };

        var id = 'accordiongroup-' + scope.$id + '-' + Math.floor(Math.random() * 10000);
        scope.headingId = id + '-tab';
        scope.panelId = id + '-panel';
      }
    };
  })
  .directive('pmdCollapse', ['$animate', '$q', '$parse', '$injector', function($animate, $q, $parse, $injector) {
    var $animateCss = $injector.has('$animateCss') ? $injector.get('$animateCss') : null;
    return {
      link: function(scope, element, attrs) {
        var expandingExpr = $parse(attrs.expanding),
          expandedExpr = $parse(attrs.expanded),
          collapsingExpr = $parse(attrs.collapsing),
          collapsedExpr = $parse(attrs.collapsed),
          horizontal = false,
          css = {},
          cssTo = {};

        init();

        function init() {
          horizontal = !!('horizontal' in attrs);
          if (horizontal) {
            css = {
              width: ''
            };
            cssTo = {width: '0'};
          } else {
            css = {
              height: ''
            };
            cssTo = {height: '0'};
          }
          if (!scope.$eval(attrs.pmdCollapse)) {
            element.addClass('in')
              .addClass('collapse')
              .attr('aria-expanded', true)
              .attr('aria-hidden', false)
              .css(css);
          }
        }

        function getScrollFromElement(element) {
          if (horizontal) {
            return {width: element.scrollWidth + 'px'};
          }
          return {height: element.scrollHeight + 'px'};
        }

        function expand() {
          if (element.hasClass('collapse') && element.hasClass('in')) {
            return;
          }

          $q.resolve(expandingExpr(scope))
            .then(function() {
              element.removeClass('collapse')
                .addClass('collapsing')
                .attr('aria-expanded', true)
                .attr('aria-hidden', false);

              if ($animateCss) {
                $animateCss(element, {
                  addClass: 'in',
                  easing: 'ease',
                  css: {
                    overflow: 'hidden'
                  },
                  to: getScrollFromElement(element[0])
                }).start()['finally'](expandDone);
              } else {
                $animate.addClass(element, 'in', {
                  css: {
                    overflow: 'hidden'
                  },
                  to: getScrollFromElement(element[0])
                }).then(expandDone);
              }
            }, angular.noop);
        }

        function expandDone() {
          element.removeClass('collapsing')
            .addClass('collapse')
            .css(css);
          expandedExpr(scope);
        }

        function collapse() {
          if (!element.hasClass('collapse') && !element.hasClass('in')) {
            return collapseDone();
          }

          $q.resolve(collapsingExpr(scope))
            .then(function() {
              element
              // IMPORTANT: The width must be set before adding "collapsing" class.
              // Otherwise, the browser attempts to animate from width 0 (in
              // collapsing class) to the given width here.
                .css(getScrollFromElement(element[0]))
                // initially all panel collapse have the collapse class, this removal
                // prevents the animation from jumping to collapsed state
                .removeClass('collapse')
                .addClass('collapsing')
                .attr('aria-expanded', false)
                .attr('aria-hidden', true);

              if ($animateCss) {
                $animateCss(element, {
                  removeClass: 'in',
                  to: cssTo
                }).start()['finally'](collapseDone);
              } else {
                $animate.removeClass(element, 'in', {
                  to: cssTo
                }).then(collapseDone);
              }
            }, angular.noop);
        }

        function collapseDone() {
          element.css(cssTo);// Required so that collapse works when animation is disabled
          element.removeClass('collapsing')
            .addClass('collapse');
          collapsedExpr(scope);
        }

        scope.$watch(attrs.pmdCollapse, function(shouldCollapse) {
          if (shouldCollapse) {
            collapse();
          } else {
            expand();
          }
        });
      }
    };
  }])
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Accordion',
      description: 'Collapsable elements',
      version: '1.0.0'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])
  .name;
