import angular from 'angular';
import { routes } from './administration.routes'

import ManagementController from './main/main.controller';
import UserAdminController from './main/users/add/add.controller';
import UserEditAdminController from './main/users/edit/edit.controller';
import RoleEditController from './main/roles/edit/edit.controller';
import AdministrationService from "./administration.service";
import RoleAddController from "./main/roles/add/add.controller";
import IeDefinicoesController from "./ie/definicoes.controller";
import SystemController from "./system/system.controller";
import CustosAdminController from "./custos/custos.controller";
import OblerAdminController from './obler/controller';
import CodigosArtigoAdminController from "./codigosArtigo/codigosArtigo.controller";
import AtvAdminController from "./ativos/ativos.controller";
import ElvAdminController from "./elv/controller";
import ElvAdminFileUploaderController from "./elv/files.dialog.controller";
import PadAdminController from "./pad/controller";
import FuncionaAdminController from "./funciona/controller";
import GasAdminController from "./gas/controller";

import AdminLista from './listas';

export default angular.module('app.administration', [AdminLista])
  .config(routes)
  .controller('IeDefinicoesController', IeDefinicoesController)
  .controller('ElvAdminController', ElvAdminController)
  .controller('PadAdminController', PadAdminController)
  .controller('FuncionaAdminController', FuncionaAdminController)
  .controller('GasAdminController', GasAdminController)
  .controller('ElvAdminFileUploaderController', ElvAdminFileUploaderController)
  .controller('CustosAdminController', CustosAdminController)
  .controller('OblerAdminController', OblerAdminController)
  .controller('CodigosArtigoAdminController', CodigosArtigoAdminController)
  .controller('AtvAdminController', AtvAdminController)
  .controller('ManagementController', ManagementController)
  .controller('UserAdminController', UserAdminController)
  .controller('RoleEditController', RoleEditController)
  .controller('RoleAddController', RoleAddController)
  .controller('UserEditAdminController', UserEditAdminController)
  .controller('SystemController', SystemController)
  .service('AdministrationService', AdministrationService)
  .name;
