export default class OblProdutosDetailsController {
  constructor($state, $http, OblProduto, OblProdutoPreco, UIService, AuthenticationService) {
    this.$state = $state;
    this.$http = $http;
    this.id = $state.params.id;
    this.OblProduto = OblProduto;
    this.OblProdutoPreco = OblProdutoPreco;
    this.UI = UIService;
    this.Auth = AuthenticationService;

    this.limit = 3;
    this.loadData();
  }

  limitTo = () => {
    if (angular.isDefined(this.limit)) {
      this.limit = undefined;
    } else {
      this.limit = 3;
    }
  };

  details = () => {
    let o = angular.copy(this.data);
    this.UI.showDialog({
      template: require('./details.dialog.html'),
      controller: ['$scope', 'OblFamilia', 'OblGrupo', ($scope, Familia, Grupo) => {
        Familia.find({ filter: { where: { active: true } } }).$promise.then(f => {
          Grupo.find({ filter: { where: { active: true } } }).$promise.then(g => {
            $scope.familias = f;
            $scope.grupos = g;
            $scope.loaded = true;
          }).catch(e => { });
        }).catch(e => { });

        $scope.aux = {
          familia: o.Familia,
          fornecedor: o.Fornecedor,
          grupo: o.Grupo
        }

        $scope.data = {
          designacao: o.designacao,
          unidade: o.unidade
        };

        $scope.ok = () => {
          let r = {
            designacao: $scope.data.designacao,
            unidade: $scope.data.unidade,
            fornecedorId: $scope.aux.fornecedor.id,
            familiaId: $scope.aux.familia.id,
            grupoId: $scope.aux.grupo.id
          }
          $scope.$close(r);
        }

        $scope.cancel = () => { $scope.$dismiss(); }
      }]
    }).then(r => {
      this.OblProduto.prototype$updateAttributes(
        { id: this.id }, r
      ).$promise.then((_) => {
        this.UI.addToast("Detalhes alterados com sucesso");
        this.loadData();
      }).catch(e => {
        console.log(e);
        this.UI.addToast("Não foi possível alterar detalhes");
      })
    }).catch(e => {
      console.log(e);
    })
  }

  updatePrice = () => {
    let data = angular.copy(this.data);
    console.log(data);
    this.UI.showDialog({
      template: require('./price.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.loaded = true;
        $scope.data = {
          id: 0,
          active: true,
          precoTabela: data.precoTabela,
          precoCompra: data.precoCompra,
          precoVenda: data.precoVenda,
          descontoFornecedor: data.descontoFornecedor * 100,
          iva: (data.iva - 1) * 100,
          valor: data.valor,
          produtoId: data.id,
          fornecedorId: data.fornecedorId
        }

        $scope.$watch(() => $scope.data, (val) => {
          if (val.precoVenda && val.iva) {
            let number = val.precoVenda * ((100 + val.iva) / 100);
            val.valor = Math.round((number + Number.EPSILON) * Math.pow(10, 2)) / Math.pow(10, 2);
          }
        }, true);

        $scope.ok = () => {
          let o = angular.copy($scope.data);
          o.iva = (100 + o.iva) / 100;
          o.descontoFornecedor /= 100;
          $scope.$close(o);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(r => {
      r.dataAlteracao = moment.utc();
      this.OblProdutoPreco.create(r).$promise.then(r => {
        this.UI.addToast("Informação preçária atualizada");
        this.data.precoTabela = r.precoTabela;
        this.data.descontoFornecedor = r.descontoFornecedor;
        this.data.precoCompra = r.precoCompra;
        this.data.precoVenda = r.precoVenda;
        this.data.iva = r.iva;
        this.data.valor = r.valor;
      }).catch(e => {
        this.UI.addToast("Ocorreu um erro ao atualizar preçário");
      });
    })
  }

  showPriceHistory = () => {
    let array = angular.copy(this.data.HistoricoPreco);
    let data = array.sort((a, b) => moment(a.dataAlteracao).valueOf() - moment(b.dataAlteracao).valueOf());
    let precoTabela = [];
    let precoCompra = [];
    let precoVenda = [];
    let valor = [];
    let labels = [];
    data.forEach(r => {
      precoTabela.push(r.precoTabela);
      precoCompra.push(r.precoCompra);
      precoVenda.push(r.precoVenda);
      valor.push(r.valor);
      labels.push(moment.utc(r.dataAlteracao).format("DD/MM/YYYY"));
    });

    precoTabela.push(this.data.precoTabela);
    precoCompra.push(this.data.precoCompra);
    precoVenda.push(this.data.precoVenda);
    valor.push(this.data.valor);
    labels.push("Atual");
    this.UI.showDialog({
      template: require('./price.history.html'),
      controller: ['$scope', $scope => {
        $scope.ok = () => {
          $scope.$close();
        }

        $scope.labels = labels;
        $scope.series = ['Preço tabela (€)', 'Preço compra (€)', 'Preço venda (€)', 'Valor (€)'];
        $scope.data = [
          precoTabela,
          precoCompra,
          precoVenda,
          valor
        ];
        $scope.options = {
          legend: {
            display: true,
            position: 'bottom',
            fullWidth: true,
          },
          scales: {
            yAxes: [
              {
                id: 'y-axis-1',
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        };

        $scope.loaded = true;
      }]
    });
  }

  loadData = () => {
    this.loaded = false;
    this.OblProduto.findOne({
      filter: {
        where: {
          id: this.id
        },
        include: [{
          relation: "Fornecedor",
          scope: {
            where: {
              active: true
            }
          }
        }, {
          relation: "Familia",
          scope: {
            where: {
              active: true
            }
          }
        }, {
          relation: "Grupo",
          scope: {
            where: {
              active: true
            }
          }
        }, {
          relation: 'StockViatura',
          scope: {
            where: {
              active: true
            },
            include: 'Viatura'
          }
        }, {
          relation: 'StockArmazem',
          scope: {
            where: {
              active: true
            },
            include: 'Armazem'
          }
        }, {
          relation: 'HistoricoPreco',
          scope: {
            where: {
              order: 'id DESC',
              active: true
            }
          }
        }]
      }
    }).$promise.then(r => {
      this.data = r;
      this.stockArmazem = 0;
      this.stockViatura = 0;
      this.stock = 0;
      r.StockViatura.forEach(o => this.stockViatura += o.quantidade);
      r.StockArmazem.forEach(o => this.stockArmazem += o.quantidade);
      this.stock = this.stockArmazem + this.stockViatura
      this.loaded = true;
    })
  }
}

OblProdutosDetailsController.$inject = ['$state', '$http', 'OblProduto', 'OblProdutoPreco', 'UIService', 'AuthenticationService'];
