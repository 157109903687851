import angular from 'angular';
import { routes } from './routes';
import { react2angular } from 'react2angular';
import OblerRevampService from './orcamentos/service';
import OblerPendentesService from './pecas/service';
import ListOrcamentos from './orcamentos/list';
import DetailsOrcamentos from './orcamentos/details';
import ListPecasPendentes from './pecas';
export default angular
    .module('app.obler.revamp', [])
    .config(routes)
    .service('OblerRevampService', OblerRevampService)
    .service('OblerPendentesService', OblerPendentesService)
    .component('listOrcamentos', react2angular(ListOrcamentos, [], ['$state', 'OblerRevampService', 'UIService']))
    .component('detailsOrcamentos', react2angular(DetailsOrcamentos, [], ['$state', 'OblerRevampService', 'OblerPendentesService', 'UIService']))
    .component('listPecasPendentes', react2angular(ListPecasPendentes, [], ['$state', 'OblerPendentesService', 'UIService']))
    .name;
