import angular from 'angular';
import {routes} from './relatorios.routes';
import SusEneDetailRelatoriosController from "./details/details.controller";
import SusEneDirectoryRelatoriosController from "./directory/directory.controller";

export default angular.module('app.susene.relatorios', [])
  .config(routes)
  .controller('SusEneDetailRelatoriosController', SusEneDetailRelatoriosController)
  .controller('SusEneDirectoryRelatoriosController', SusEneDirectoryRelatoriosController)
  .filter('suseneFormat', function() {
    return function(data) {
      let e = 'IEP';
      let t = 'SE';
      let ano = data.iidAno;
      let num = ("0000" + data.iidProc) .slice(-4);
      return `${e}-${t}-${ano}-${num}`;
    }
  })
  .name;
