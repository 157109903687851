import angular from 'angular';
import { routes } from './routes';
import { react2angular } from "react2angular";
import GasClienteDetailsView from './details';
import GasClientesListView from './list';
export default angular.module('app.gas.clientes', [])
    .config(routes)
    .component("gasClienteDetailsView", react2angular(GasClienteDetailsView, [], ["$state", "GasService", "UIService"]))
    .component("gasClientesListView", react2angular(GasClientesListView, [], ["$state", "GasService"]))
    .name;
