export default class ProfileController {
  constructor($rootScope, $state, $http, AuthenticationService, FileUploader, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$http = $http;
    this.calendarEvents = [];
    this.UI = UIService;
    this.loaded = false;
    this.FileUploader = FileUploader;
    this.Auth = AuthenticationService;
    this.user = AuthenticationService.getUser();
    this.user.ausencias.forEach(ausencia => {
      let o = {
        title: ausencia.motivo,
      }
      if (moment(ausencia.dataInicio).isSame(ausencia.dataFim, 'day')) {
        if (ausencia.diaInteiro) {
          o.start = moment.utc(`${moment(ausencia.dataInicio).format("YYYY-MM-DD")}`).startOf('day');
          o.end = moment.utc(`${moment(ausencia.dataFim).format("YYYY-MM-DD")}`).endOf('day');
        } else {
          let start = moment(`${moment(ausencia.dataInicio).format("YYYY-MM-DD")} ${ausencia.horaInicio}`);
          let end = moment(`${moment(ausencia.dataFim).format("YYYY-MM-DD")} ${ausencia.horaFim}`);
          o.start = start;
          o.end = end;
        }
      } else {
        o.start = ausencia.dataInicio;
        o.end = moment.utc(ausencia.dataFim).add(1, 'day');
        o.className = "hideCalendarTime";
      }
      this.calendarEvents.push(o);
    });
    this.calendarOptions = {
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'month,listMonth'
      },
      locale: 'pt',
      themeSystem: 'bootstrap4',
      navLinks: true,
      defaultTimedEventDuration: '01:00:00',
      allDaySlot: false,
      displayEventEnd: true,
      timeFormat: "HH:mm",
      height: 'auto',
      firstDay: 1,
      nowIndicator: true,
      weekends: true,
      weekNumberTitle: 'Sem.',
      weekNumbers: true,
      eventLimit: true,
      buttonText: {
        today: 'Hoje',
        month: 'Mensal',
        week: 'Semanal',
        day: 'Diário',
        list: 'Lista'
      }
    };

    this.initializeUploader();
  }

  // Image stuff
  initializeUploader = () => {
    this.uploader = new this.FileUploader({
      url: '/api/Upload/assinaturas/upload',
      queueLimit: 1,
      autoUpload: true
    });

    if (this.user.signature) {
      this.$http.get(`/api/Upload/assinaturas/download/${this.user.signature}`, { responseType: "blob" }).then((data, status, headers, config) => {
        const uuid = this.UUID();
        let name = `${uuid}.png`;
        let file = new File([data.data], name, {
          type: 'image/png',
          size: data.data.size,
          lastModifiedDate: new Date()
        });
        // Create "dummy" file and add it to the uploader
        let dummy = new this.FileUploader.FileItem(this.uploader, {
          uploaded: true,
          dummy: true,
          lastModifiedData: new Date(),
          type: 'image/png',
          name: name,
          size: data.data.size
        });
        dummy._file = file;
        this.uploader.queue = [dummy];
      },
        (data, status, headers, config) => {
          this.UI.addToast('Não foi possível carregar assinatura');
        });
    }

    this.loaded = true;

    // Enforce PDF Files
    this.uploader.filters.push({
      name: 'verifyPNG',
      fn: (item, options) => item.type.indexOf("image/png") !== -1
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.file.name = this.UUID() + ".png";
    };

    this.uploader.onWhenAddingFileFailed = (a, b) => {
      if (b.name.includes("queueLimit")) {
        this.uploader.clearQueue();
        this.uploader.addToQueue(a);
      } else {
        this.UI.addToast('Erro ao adicionar ficheiro');
      }
    };

    this.uploader.onSuccessItem = (a, b) => {
      this.loaded = false;
      this.user.signature = a.file.name;
      this.Auth.update('signature', a.file.name).then(r => {
        this.loaded = true;
        this.UI.addToast("Assinatura atualizada!");
      });
    };

    this.uploader.onErrorItem = (a, b) => {
      console.log(a, b);
      this.UI.addToast("Erro ao carregar imagem. Tente novamente");
    }
  };

  UUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  clearSignature = () => {
    this.loaded = false;
    this.user.signature = undefined;
    this.Auth.update('signature', '').then(r => {
      this.loaded = true;
      this.UI.addToast("Assinatura removida!");
    });
  }

  changePassword = () => {
    let options = {
      template: require("./password.dialog.html"),
      controller: ["$scope", ($scope) => {
        $scope.ok = () => {
          this.Auth.changePassword(
            $scope.oldPassword,
            $scope.newPassword
          )
            .then(() => {
              $scope.$close(true);
            })
            .catch((err) => {
              $scope.$close(false);
            });
        };

        $scope.cancel = () => {
          $dialog.dismiss();
        };
      },
      ],
    };
    this.UI.showDialog(options)
      .then((response) => {
        if (response == true) {
          this.UI.addToast("Password alterada");
        } else {
          this.UI.addToast("Não foi possíveç alterar password");
        }

      })
      .catch((err) => { });
  };

  logout = () => {
    this.Auth.logout();
  };

}

ProfileController.$inject = ['$rootScope', '$state', '$http', 'AuthenticationService', 'FileUploader', 'UIService'];
