import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Checkbox, Collapse, Grid, IconButton, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { AutoComplete, MdiIcon, Table } from '@components';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { mdiChevronUp, mdiContentSave, mdiMagnify, mdiPencil, mdiPlus } from '@mdi/js';
import moment from 'moment';
import { InfoDialog } from './infoDialog';
import { useModal } from 'mui-modal-provider';
import { debounce } from 'lodash';
const EmpresasAssociadas = ({ $state, CrmService, user }) => {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [dataEmpresaAssociada, setDataEmpresaAssociada] = useState(); // dados dos clientes
    const [loadingEmpresaAssociada, setLoadingEmpresaAssociada] = useState(true);
    const [refetchingEmpresaAssociada, setRefetchingEmpresaAssociada] = useState(false);
    const [errorEmpresaAssociada, setErrorEmpresaAssociada] = useState(false);
    const [filterEmpresaAssociada, setFilterEmpresaAssociada] = useState([]); // filtros a aplicar na tabela
    const [sortEmpresaAssociada, setSortEmpresaAssociada] = useState([]); // ordenação da tabela
    const [optionsEmpresaAssociada, setOptionsEmpresaAssociada] = useState({
        filter: [],
        sort: [] // exemplo -> ['id', 'nome', 'ultimoContacto', 'contactoTelefonico', 'nif', 'email', 'morada', 'localidade']
    });
    const [paginationEmpresaAssociada, setPaginationEmpresaAssociada] = useState({
        pageIndex: 0,
        pageSize: 5
    });
    const [empresaAssociada, setEmpresaAssociada] = useState(null);
    //const [editing, setEditing] = useState<boolean>(false);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [autoCompleteLoad, setAutoCompleteLoad] = useState(false);
    const [expand, setExpand] = useState(false);
    const [disableDataEntrada, setDisableDataEntrada] = useState(false);
    const [disableDataSaida, setDisableDataSaida] = useState(false);
    const validationSchema = yup.object().shape({
        dataEntrada: yup.string().required('Preenchimento obrigatório'),
        dataSaida: yup
            .string()
            .required('Preenchimento obrigatório')
            .test('is-after', 'A data de saída deve ser posterior à data de entrada', function (value) {
            const { dataEntrada } = this.parent; // Obtém o valor da data de entrada
            // Valida apenas se ambos os valores forem datas válidas (não "Desconhecida" ou "Atualidade")
            if (value === 'Atualidade' || dataEntrada === 'Desconhecida' || !moment(value, 'DD-MM-YYYY', true).isValid() || !moment(dataEntrada, 'DD-MM-YYYY', true).isValid()) {
                return true; // Se for especial ou inválido, passa a validação
            }
            // Verifica se a data de saída é posterior à data de entrada
            return moment(value, 'DD-MM-YYYY').isAfter(moment(dataEntrada, 'DD-MM-YYYY'));
        }),
        idCoreCliente: yup.number().required('Preenchimento obrigatório').min(1, 'Selecione um colaborador válido')
    });
    const { showModal } = useModal();
    const formik = useFormik({
        initialValues: {
            id: empresaAssociada ? empresaAssociada.id : 0,
            idCoreCliente: empresaAssociada ? empresaAssociada.idCoreCliente : 0,
            nomeCoreCliente: empresaAssociada ? empresaAssociada.nomeCoreCliente || '' : '',
            idCoreContacto: empresaAssociada ? empresaAssociada.idCoreContacto : user.id,
            nomeCoreContacto: empresaAssociada ? empresaAssociada.nomeCoreContacto : '',
            areaCoreCliente: empresaAssociada ? empresaAssociada.areaCoreCliente : '',
            dataEntrada: empresaAssociada ? empresaAssociada.dataEntrada : '',
            dataSaida: empresaAssociada ? empresaAssociada.dataSaida : '',
            cargo: empresaAssociada ? empresaAssociada.cargo : '',
            observacoes: empresaAssociada ? empresaAssociada.observacoes : '',
            active: empresaAssociada ? empresaAssociada.active : 1
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            submit(values);
        }
    });
    const submit = async (newValues) => {
        try {
            let x = await CrmService.findEmpresaById(newValues.idCoreCliente);
            newValues.areaCoreCliente = x.area;
            newValues.nomeCoreContacto = user.nome;
            const p = await CrmService.upsertEmpresaAssociada(newValues);
            setRefetchingEmpresaAssociada(true);
            fetchData();
            setExpand(false);
            setOpenSnackBar(true);
            formik.resetForm({
                values: {
                    id: 0,
                    idCoreCliente: 0,
                    nomeCoreCliente: '',
                    idCoreContacto: 0,
                    nomeCoreContacto: '',
                    areaCoreCliente: '',
                    dataEntrada: '',
                    dataSaida: '',
                    observacoes: '',
                    cargo: '',
                    active: 1
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    };
    // Filtragem dentro de cada coluna
    // Modo de filtro (igual a, contém, começa com... )
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    // ir para detalhes da empresa (envia id da empresa)
    const onCellView = (cell) => {
        //$state.go('app.crm.empresas.details', { id: cell.id });
    };
    const initialFetch = async () => {
        if (loadingEmpresaAssociada) {
            // opt -> vai buscar os campos filtraveis
            const opt = await CrmService.optionsEmpresaAssociada();
            setOptionsEmpresaAssociada(opt);
            setLoadingEmpresaAssociada(false);
        }
    };
    const fetchData = async () => {
        setErrorEmpresaAssociada(false);
        try {
            let filters = {};
            filterEmpresaAssociada.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sortEmpresaAssociada.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            // aux vai buscar os dados consoante os filtros
            const aux = await CrmService.findAllEmpresaAssociada({
                limit: paginationEmpresaAssociada.pageSize,
                page: paginationEmpresaAssociada.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            // Update URL
            $state.go($state.current.name, {
                limit: paginationEmpresaAssociada.pageSize,
                page: paginationEmpresaAssociada.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filterEmpresaAssociada).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            setDataEmpresaAssociada(aux);
        }
        catch (e) {
            console.log(e);
            setErrorEmpresaAssociada(true);
        }
        setRefetchingEmpresaAssociada(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetchingEmpresaAssociada) {
            setRefetchingEmpresaAssociada(true);
            fetchData();
        }
    }, [paginationEmpresaAssociada, filterEmpresaAssociada, sortEmpresaAssociada]);
    const handleTextInput = (event) => {
        const { id, value } = event.target;
        formik.setFieldValue(id, value);
    };
    const handleAutoCompleteSearch = useCallback(debounce(async (newValue) => {
        if (!newValue)
            return;
        setAutoCompleteLoad(true);
        try {
            const data = await CrmService.findEmpresaByName(newValue);
            // Supondo que o serviço retorne um array de objetos { id, name }
            setAutoCompleteOptions(data);
        }
        catch (error) {
            console.error('Erro ao procurar colaborador:', error);
        }
        finally {
            setAutoCompleteLoad(false);
        }
    }, 500), // O valor 500 é o tempo de delay em milissegundos
    []);
    const handleAutoCompleteChange = (selectedId, selectedName) => {
        formik.setFieldValue('idCoreCliente', selectedId);
        formik.setFieldValue('nomeCoreCliente', selectedName || '');
    };
    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    return (<Box>
      <Stack direction='column'>
        <Stack direction='row' spacing={1} justifyContent='flex-start' alignItems='center'>
          <Typography variant='h6' sx={{ color: 'black' }}>
            Empresas associadas
          </Typography>
          {expand ? (<Tooltip title={'Cancelar'}>
              <IconButton color='primary' onClick={() => setExpand(!expand)}>
                <MdiIcon path={mdiChevronUp} sx={{ color: '#1976D2' }}/>
              </IconButton>
            </Tooltip>) : (<Box></Box>)}

          <Tooltip title={'Associar nova empresa'}>
            <IconButton color='primary' onClick={() => {
            if (expand) {
                formik.handleSubmit();
            }
            else {
                formik.resetForm();
                setExpand(!expand);
            }
        }}>
              <MdiIcon path={!expand ? mdiPlus : mdiContentSave} sx={{ color: '#1976D2' }}></MdiIcon>
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Collapse in={expand}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                  <Typography>Data desconhecida</Typography>

                  <Checkbox 
    //checked={formik.values.dataEntrada === 'Desconhecida' ? true : false}
    checked={formik.values.dataEntrada === 'Desconhecida'} onClick={() => {
            const isUnknown = formik.values.dataEntrada === 'Desconhecida';
            setDisableDataEntrada(!isUnknown); // Alterna o estado do input
            formik.setFieldValue('dataEntrada', isUnknown ? '' : 'Desconhecida');
        }}/>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                  <Typography>Atualidade</Typography>

                  <Checkbox checked={formik.values.dataSaida === 'Atualidade'} onClick={() => {
            const isCurrent = formik.values.dataSaida === 'Atualidade';
            setDisableDataSaida(!isCurrent); // Alterna o estado do input
            formik.setFieldValue('dataSaida', isCurrent ? '' : 'Atualidade');
        }}/>
                </Stack>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker value={formik.values.dataEntrada ? moment(formik.values.dataEntrada, 'DD-MM-YYYY') : null} onChange={value => {
            const formattedDate = value ? moment(value).format('DD-MM-YYYY') : '';
            formik.setFieldValue('dataEntrada', formattedDate); // Atualiza o campo no Formik
        }} slotProps={{
            textField: {
                size: 'small',
                fullWidth: true,
                label: 'Data de entrada',
                disabled: disableDataEntrada,
                required: true,
                helperText: formik.touched.dataEntrada && formik.errors.dataEntrada,
                error: Boolean(formik.touched.dataEntrada && formik.errors.dataEntrada),
                sx: { bgcolor: '#F5F5F5' }
            }
        }}/>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker value={formik.values.dataSaida ? moment(formik.values.dataSaida, 'DD-MM-YYYY') : null} onChange={value => {
            const formattedDate = value ? moment(value).format('DD-MM-YYYY') : '';
            formik.setFieldValue('dataSaida', formattedDate); // Atualiza o campo no Formik
        }} slotProps={{
            textField: {
                size: 'small',
                fullWidth: true,
                label: 'Data de saída',
                disabled: disableDataSaida,
                required: true,
                helperText: formik.touched.dataSaida && formik.errors.dataSaida,
                error: Boolean(formik.touched.dataSaida && formik.errors.dataSaida),
                sx: { bgcolor: '#F5F5F5' }
            }
        }}/>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={4}>
                <AutoComplete selectList={autoCompleteOptions} // Opções carregadas dinamicamente
     id={formik.values.idCoreCliente} // Valor atual do ID
     onSearch={handleAutoCompleteSearch} onChange={(selectedId, selectedName) => {
            handleAutoCompleteChange(selectedId, selectedName);
        }} label='Empresa' size='small' sx={{ bgcolor: '#F5F5F5' }} loading={autoCompleteLoad} // loading durante a pesquisa
     required={true} value={{
            id: formik.values.idCoreCliente,
            name: formik.values.nomeCoreCliente
        }} helperText={formik.touched.idCoreCliente && formik.errors.idCoreCliente}/>
              </Grid>

              {/* Linha 3: Observações */}
              <Grid item xs={12} md={6} lg={6}>
                <TextField fullWidth id='observacoes' label='Observações' color='info' size='small' multiline={true} maxRows={3} sx={{ bgcolor: '#F5F5F5' }} onChange={handleTextInput} value={formik.values.observacoes} error={formik.touched.observacoes && Boolean(formik.errors.observacoes)} helperText={formik.touched.observacoes && formik.errors.observacoes}/>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextField fullWidth id='cargo' label='Cargo' color='info' size='small' sx={{ bgcolor: '#F5F5F5' }} onChange={handleTextInput} value={formik.values.cargo} error={formik.touched.cargo && Boolean(formik.errors.cargo)} helperText={formik.touched.cargo && formik.errors.cargo}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>

      <Box sx={{
            mt: 2,
            borderRadius: '15px',
            border: '1px solid #e0e0e0',
            overflow: 'hidden',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
        }}>
        <Table onDoubleClick={row => {
            onCellView(row);
        }} storageKey={$state.current.name} data={dataEmpresaAssociada} columns={[
            {
                accessorKey: 'nomeCoreCliente',
                header: 'Empresa',
                size: 100
            },
            {
                accessorKey: 'dataEntrada',
                header: 'Data de entrada',
                size: 100
            },
            {
                accessorKey: 'dataSaida',
                header: 'Data de saída',
                size: 100
            },
            {
                accessorKey: 'cargo',
                header: 'Cargo',
                size: 100
            },
            {
                accessorKey: 'areaCoreCliente',
                header: 'Área da empresa',
                size: 100
            }
        ]} initial={{
            sort: sortEmpresaAssociada,
            filter: filterEmpresaAssociada
        }} isLoading={refetchingEmpresaAssociada} options={optionsEmpresaAssociada} onPaginate={setPaginationEmpresaAssociada} onFilter={setFilterEmpresaAssociada} onSort={setSortEmpresaAssociada} rowActionSize={100} actionItems={({ row }) => [
            <Box key={`action-items-${row.original.id}`} display='flex' gap={1}>
              <Tooltip key={`edit-tooltip-${row.original.id}`} title={'Editar dados'} placement='left'>
                <IconButton color='primary' onClick={() => {
                    formik.setValues(row.original);
                    // Adicionar colaborador à lista de opções, se necessário
                    const colaboradorSelecionado = {
                        id: row.original.idColaborador,
                        name: row.original.nomeColaborador
                    };
                    setDisableDataEntrada(row.original.dataEntrada === 'Desconhecida');
                    setDisableDataSaida(row.original.dataSaida === 'Atualidade');
                    setAutoCompleteOptions(prevOptions => {
                        // Adiciona o colaborador à lista se ele não existir
                        if (!prevOptions.some(option => option.id === colaboradorSelecionado.id)) {
                            return [...prevOptions, colaboradorSelecionado];
                        }
                        return prevOptions;
                    });
                    formik.setTouched({}, false);
                    if (!expand)
                        setExpand(true);
                }}>
                  <MdiIcon path={mdiPencil} sx={{ color: '#1976D2' }}/>
                </IconButton>
              </Tooltip>
              <Tooltip key={`view-tooltip-${row.original.id}`} title={'Ver detalhes'} placement='top'>
                <IconButton color='secondary' onClick={() => {
                    const showData = [
                        { key: 'Empresa', value: row.original.nomeCoreCliente },
                        { key: 'Área da empresa', value: row.original.areaCoreCliente },
                        { key: 'Data de entrada', value: row.original.dataEntrada },
                        { key: 'Data de saída', value: row.original.dataSaida },
                        { key: 'Observações', value: row.original.observacoes }
                    ];
                    showModal(InfoDialog, {
                        title: 'Detalhes da empresa',
                        data: showData
                    });
                }}>
                  <MdiIcon path={mdiMagnify} sx={{ color: '#1976D2' }}/>
                </IconButton>
              </Tooltip>
            </Box>
        ]}></Table>
      </Box>
      <Snackbar open={openSnackBar} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity='success' variant='filled' sx={{ width: '100%' }}>
          Dados alterados com sucesso!
        </Alert>
      </Snackbar>
    </Box>);
};
export default EmpresasAssociadas;
