import angular from 'angular';
import { routes } from './routes';
import { react2angular } from 'react2angular';
// Relatórios
import ListRelatorios from './listas/list';
import ListaRelatorioService from './listas/service';
import EditRelatorios from './relatorios';
import DetailsRelatorios from './listas/details';
import DetailsRelatorioParametro from './listas/details/parametro';
import DetailsRelatorioEnsaio from './listas/details/ensaio';
// cláusulas
import ListClausulas from './clausulas/list';
import ListaClausulaService from './clausulas/service';
import DetailsClausulas from './clausulas/details';
import { useContext } from '@components';
export default angular
    .module('app.administration.lists', [])
    .config(routes)
    .service('ListaRelatorioService', ListaRelatorioService)
    .service('ListaClausulaService', ListaClausulaService)
    // Cláusulas
    .component('listClausulas', react2angular(ListClausulas, [], ['$state', 'ListaClausulaService']))
    .component('detailsClausula', react2angular(DetailsClausulas, [], ['$state', 'ListaClausulaService']))
    // Relatórios
    .component('listRelatorio', react2angular(ListRelatorios, [], ['$state', 'ListaRelatorioService']))
    .component('detailsRelatorio', react2angular(useContext.use(DetailsRelatorios), [], ['$state', 'ListaRelatorioService', 'UIService']))
    .component('editRelatorio', react2angular(useContext.use(EditRelatorios), [], ['$state', 'ListaRelatorioService', 'UIService']))
    .component('detailsRelatorioParametro', react2angular(useContext.use(DetailsRelatorioParametro), [], ['$state', 'ListaRelatorioService', 'UIService']))
    .component('detailsRelatorioEnsaio', react2angular(useContext.use(DetailsRelatorioEnsaio), [], ['$state', 'ListaRelatorioService', 'UIService'])).name;
