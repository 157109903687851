export default class FunClienteDetailsController {
  constructor($state, UIService, FunClienteFinal, FunProcesso) {
    this.$state = $state;
    this.UI = UIService;
    this.FunClienteFinal = FunClienteFinal;
    this.FunProcesso = FunProcesso;

    this.id = $state.params.id;
    if (!this.id) {
      $state.go('app.funciona.clientes.list');
    }

    // Default opt state - populate later when we have all states
    this.optProcessos = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: {}
    };
    this.processos = null;
    this.processosLoading = true;

    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.FunClienteFinal.findById({ id: this.id }).$promise.then(r => {
      this.data = r;
      this.loaded = true;
    }).catch(e => {
      this.UI.addToast("Ocorreu um erro ao carregar cliente");
      this.$state.go('app.funciona.clientes.list');
    });
    this.getProcessosForCliente();
  };

  getProcessosForCliente = () => {
    // If no permissions, return

    // if (!this.AuthorizationService.canPerform('padNivel2') && !this.AuthorizationService.canPerform('padNivel3') && !this.AuthorizationService.canPerform('padNivel4')) {
    //   return;
    // }

    this.processosLoading = true;
    let whereLiteral = {
      'FunProcesso.clienteFinalId': this.id,
      'FunProcesso.active': 1
    };
    let where = {};
    Object.keys(this.optProcessos.filter).forEach(f => {
      if (this.optProcessos.filter[f])
        where[f] = this.optProcessos.filter[f];
    });

    this.FunProcesso.table2({
      params: {
        fields: [
          'FunProcesso.id as id',
          'FunProcesso.oiId as oiId',
          'FunProcesso.numeroProcesso as numeroProcesso',
          'FunProcesso.tarefaId as tarefaId',
          'FunProcesso.funcionarioId as funcionarioId',
          'FunProcesso.dataImportacao as dataImportacao',
          'FunTipoServico.designacao as tipoServico',
          'FunEstadoProcesso.designacao as estadoProcesso',
          'Funcionario.name as importadoPorNome',
          'Funcionario2.name as funcionarioNome'
        ],
        from: ['FunProcesso', 'Funcionario', 'Funcionario', 'FunTipoServico', 'FunEstadoProcesso'],
        references: [undefined, 'FunProcesso.importadoPorId', 'FunProcesso.funcionarioId', 'FunProcesso.tipoServicoId', 'FunProcesso.estadoId'],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined],
        aliases: [undefined, undefined, 'Funcionario2', undefined, undefined],
        where: where,
        whereLiteral: whereLiteral,
        order: this.optProcessos.order,
        sort: this.optProcessos.sort,
        limit: this.optProcessos.items,
        skip: (this.optProcessos.page - 1) * this.optProcessos.items,
      }
    }).$promise.then(res => {
      let page = this.optProcessos.page;
      let items = this.optProcessos.items;

      let total = res.count;

      this.optProcessos.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.optProcessos.start - 1 + items) >= total) {
        this.optProcessos.end = total;
      } else {
        this.optProcessos.end = Number.parseInt(this.optProcessos.start - 1 + items);
      }

      this.processos = res.data;
      this.optProcessos.total = total;
      this.processosLoading = false;
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler Processos. Por favor tente mais tarde");
    });
  };

  processosSort = keyname => {
    if (this.optProcessos.order === keyname)
      this.optProcessos.page = 1;
    this.optProcessos.order = keyname;
    this.optProcessos.sort = this.optProcessos.sort === 'asc' ? 'desc' : 'asc';
    this.getProcessosForCliente();
  };

  processosItem = val => {
    this.optProcessos.items = val;
    this.optProcessos.page = 1;
    this.getProcessosForCliente();
  };

  processosPage = sum => {
    this.optProcessos.page += sum;
    if (this.optProcessos.page < 1)
      this.optProcessos.page = 1;
    if (this.optProcessos.page > Math.ceil(this.optProcessos.total / this.optProcessos.items))
      this.optProcessos.page = Math.ceil(this.optProcessos.total / this.optProcessos.items);
    this.getProcessosForCliente();
  };

  editObservations = () => {
    let obs = angular.copy(this.data.observacoes);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = obs;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.loaded = false;
      this.data.observacoes = res;
      this.data.$save().then(() => {
        this.UI.addToast('Observações guardadas com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro a guardar as observações. Por favor tente mais tarde.');
        this.loadData();
      })
    })
  };
}

FunClienteDetailsController.$inject = ['$state', 'UIService', 'FunClienteFinal', 'FunProcesso'];
