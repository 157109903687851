import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
const DialogEnsaio = ({ open, initial, onClose }) => {
    const validationSchema = yup.object({
        codigo: yup.string().required('O campo é obrigatório'),
        designacao: yup.string().required('O campo é obrigatório'),
        na: yup.boolean()
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            console.log(values);
            onClose(values);
        }
    });
    useEffect(() => {
        formik.setValues(initial);
    }, [initial]);
    return (<form>
      <Dialog open={open} maxWidth={'lg'} fullWidth>
        <DialogTitle>Adicionar ponto de verificação</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={2} md={4} xs={4}>
              <TextField label='Código' name='codigo' fullWidth defaultValue={formik.values?.codigo} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.codigo && Boolean(formik.errors.codigo)} helperText={formik.touched.codigo && formik.errors.codigo}/>
            </Grid>
            <Grid lg={10} md={8} xs={8}>
              <TextField label='Designação' name='designacao' defaultValue={formik.values?.designacao} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogEnsaio;
