import React, { useEffect, useState } from 'react';
import { Alert, Box, Collapse, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { MdiIcon, Table } from '@components';
import { DropzoneArea } from 'mui-file-dropzone';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { mdiChevronUp, mdiContentSave, mdiFileAccount, mdiMagnify, mdiPencil, mdiPlus } from '@mdi/js';
import moment from 'moment';
import { InfoDialog } from './infoDialog';
import { useModal } from 'mui-modal-provider';
import { v4 } from 'uuid';
const DocumentosAssociados = ({ $state, CrmService, user, client }) => {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [data, setData] = useState(); // dados dos clientes
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState([]); // filtros a aplicar na tabela
    const [sort, setSort] = useState([]); // ordenação da tabela
    const [options, setOptions] = useState({
        filter: [],
        sort: [] // exemplo -> ['id', 'nome', 'ultimoContacto', 'contactoTelefonico', 'nif', 'email', 'morada', 'localidade']
    });
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5
    });
    const tipoList = ['PDF', 'Fatura', 'Report', 'Relatório'];
    const [documentoAssociado, setDocumentoAssociado] = useState(null);
    //const [editing, setEditing] = useState<boolean>(false);
    const [expand, setExpand] = useState(false);
    const [anexo, setAnexo] = useState(null);
    const [newAnexo, setNewAnexo] = useState(null);
    const validationSchema = yup.object().shape({
        designacao: yup.string().required('Preenchimento obrigatório'),
        tipo: yup.string().required('Preenchimento obrigatório'),
        uuid: yup.string().required('Obrigatório adicionar um documento')
    });
    const { showModal } = useModal();
    const columns = [
        ...(client === undefined
            ? [
                {
                    accessorKey: 'nomeCoreContacto',
                    header: 'Nome Colaborador',
                    size: 150
                }
            ]
            : []),
        {
            accessorKey: 'designacao',
            header: 'Designação',
            size: 200
        },
        {
            accessorKey: 'tipo',
            header: 'Tipo',
            size: 100
        },
        {
            accessorKey: 'dataRegisto',
            header: 'Data',
            size: 100
        },
        {
            accessorKey: 'tamanho',
            header: 'Tamanho',
            size: 100
        }
    ];
    const formik = useFormik({
        initialValues: {
            id: documentoAssociado ? documentoAssociado.id : 0,
            designacao: documentoAssociado ? documentoAssociado.designacao : '',
            tipo: documentoAssociado ? documentoAssociado.tipo || '' : '',
            active: documentoAssociado ? documentoAssociado.active || 1 : 1,
            tamanho: documentoAssociado ? documentoAssociado.tamanho : '',
            dataRegisto: documentoAssociado ? documentoAssociado.dataRegisto || '' : '',
            dataEdit: documentoAssociado ? documentoAssociado.dataEdit : null,
            observacoes: documentoAssociado ? documentoAssociado.observacoes || '' : '',
            idCoreContacto: documentoAssociado ? documentoAssociado.idCoreContacto : user ? user.id : 0,
            nomeCoreContacto: documentoAssociado ? documentoAssociado.nomeCoreContacto : user ? user.nome : '',
            uuid: ''
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            submit(values);
        }
    });
    const submit = async (newValues) => {
        if (newValues.uuid) {
            handleFile(newValues);
        }
        try {
            const currentDate = moment().format('DD-MM-YYYY');
            if (newValues.dataRegisto == '') {
                newValues.dataRegisto = currentDate;
            }
            else {
                newValues.dataEdit = currentDate;
            }
            const p = await CrmService.upsertDocumentoAssociado(newValues);
            setRefetching(true);
            fetchData();
            setExpand(false);
            setOpenSnackBar(true);
            formik.resetForm({
                values: {
                    id: 0,
                    designacao: '',
                    tipo: '',
                    tamanho: '',
                    active: 1,
                    dataRegisto: '',
                    idCoreContacto: 0,
                    idCoreCliente: 0,
                    nomeCoreContacto: '',
                    observacoes: '',
                    uuid: ''
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    };
    // Filtragem dentro de cada coluna
    // Modo de filtro (igual a, contém, começa com... )
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    const initialFetch = async () => {
        if (loading) {
            // opt -> vai buscar os campos filtraveis
            const opt = await CrmService.optionsDocumentosAssociados();
            setOptions(opt);
            setLoading(false);
        }
    };
    const fetchData = async () => {
        setError(false);
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            if (client !== undefined) {
                filters['idCoreCliente'] = `$eq:${client}`;
            }
            const aux = await CrmService.findAllDocumentosAssociados({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            //console.log(aux);
            // Update URL
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            setData(aux);
        }
        catch (e) {
            console.log(e);
            setError(true);
        }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, filter, sort]);
    const handleTextInput = (event) => {
        const { id, value } = event.target;
        formik.setFieldValue(id, value);
    };
    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    const handleChange = (event) => {
        formik.setFieldValue('tipo', event.target.value);
    };
    const handleFile = async (values) => {
        const formData = new FormData();
        if (newAnexo.file) {
            const newFileName = values.uuid ? values.uuid : newAnexo.file.name;
            formData.append('file', newAnexo.file, newFileName);
            // Se a imagem for igual, não realiza upload
            if (newAnexo.dummy) {
                return values;
            }
            else {
                // Atualiza o valor da imagem no objeto `values`
                values.uuid = newFileName;
                try {
                    // Upload da nova imagem
                    const response = await fetch('/api/Upload/crm/upload', {
                        method: 'POST',
                        body: formData
                    });
                    if (response.ok) {
                        const content = await response.json();
                        return content.result;
                    }
                    else {
                        console.log(response);
                        console.error('Erro ao adicionar ficheiro');
                    }
                }
                catch (error) {
                    console.error('Error uploading file:', error);
                }
            }
        }
        else {
            return values;
        }
    };
    return (<Box>
      <Stack direction='column'>
        <Stack direction='row' justifyContent='flex-start' alignItems='center'>
          <Typography variant='h6' sx={{ color: 'black' }}>
            Documentos associados
          </Typography>
          {client === undefined ? (<Box>
              {expand ? (<Tooltip title={'Cancelar'}>
                  <IconButton color='primary' onClick={() => setExpand(!expand)}>
                    <MdiIcon path={mdiChevronUp} sx={{ color: '#1976D2' }}/>
                  </IconButton>
                </Tooltip>) : (<Box></Box>)}
              <Tooltip title={'Adicionar Documento'}>
                <IconButton color='primary' onClick={() => {
                if (expand) {
                    formik.handleSubmit();
                }
                else {
                    setAnexo(null);
                    formik.resetForm();
                    setExpand(!expand);
                }
            }}>
                  <MdiIcon path={!expand ? mdiPlus : mdiContentSave} sx={{ color: '#1976D2' }}></MdiIcon>
                </IconButton>
              </Tooltip>
            </Box>) : (<></>)}
        </Stack>
      </Stack>

      <Collapse in={expand}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField fullWidth id='designacao' label='Designação' color='info' size='small' sx={{ bgcolor: '#F5F5F5' }} onChange={handleTextInput} value={formik.values.designacao} error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao} required={true}/>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth size='small' sx={{
            bgcolor: '#F5F5F5',
            '& .MuiOutlinedInput-root': {
                borderRadius: '4px'
            }
        }}>
                  <InputLabel id='demo-simple-select-label' color='info' required error={formik.touched.tipo && Boolean(formik.errors.tipo)}>
                    Tipo
                  </InputLabel>
                  <Select labelId='demo-simple-select-label' id='demo-simple-select' label='Tipo ' value={formik.values.tipo} onChange={handleChange} color='info' error={formik.touched.tipo && Boolean(formik.errors.tipo)}>
                    {tipoList.map((item, index) => (<MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <TextField fullWidth id='tamanho' label='Tamanho' color='info' size='small' multiline={true} maxRows={3} sx={{ bgcolor: '#F5F5F5' }} onChange={handleTextInput} value={formik.values.tamanho} error={formik.touched.tamanho && Boolean(formik.errors.tamanho)} helperText={formik.touched.tamanho && formik.errors.tamanho} disabled/>
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth id='observacoes' label='Observações' color='info' size='small' multiline={true} rows={3} sx={{ bgcolor: '#F5F5F5' }} onChange={handleTextInput} value={formik.values.observacoes} error={formik.touched.observacoes && Boolean(formik.errors.observacoes)} helperText={formik.touched.observacoes && formik.errors.observacoes}/>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <DropzoneArea acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']} filesLimit={1} initialFiles={anexo ? [anexo] : []} onChange={files => {
            if (files.length > 0) {
                let t = files.pop();
                const fileExtension = `.${t.name.split('.').pop()}`; // Obtém a extensão do arquivo
                const fileNameWithoutExtension = t.name.replace(fileExtension, ''); // Nome sem extensão
                // Calcula o tamanho do arquivo
                const fileSizeInMB = t.size / (1024 * 1024); // Tamanho em MB
                const fileSizeDisplay = fileSizeInMB >= 1 ? `${fileSizeInMB.toFixed(2)} MB` : `${(t.size / 1024).toFixed(2)} KB`; // Exibe em KB se for menor que 1 MB
                let f = new File([t], `${v4()}.${t.name.split('.').pop()}`, { type: t.type });
                setNewAnexo({ file: f, dummy: false });
                setAnexo(URL.createObjectURL(f));
                formik.setFieldValue('uuid', f.name);
                formik.setFieldValue('designacao', fileNameWithoutExtension);
                formik.setFieldValue('tamanho', fileSizeDisplay);
                if (fileExtension == '.pdf') {
                    formik.setFieldValue('tipo', 'PDF');
                }
            }
            else {
                formik.setFieldValue('anexo', null);
                setNewAnexo(null);
                setAnexo(null);
            }
        }} showFileNamesInPreview={true} showAlerts={false} useChipsForPreview={true} dropzoneText='Arraste e solte o documento aqui ou clique'/>
            {formik.touched.uuid && formik.errors.uuid && (<Typography variant='caption' color='error'>
                {formik.errors.uuid}
              </Typography>)}
          </Grid>
        </Grid>
      </Collapse>

      <Box sx={{
            mt: 1,
            borderRadius: '15px',
            border: '1px solid #e0e0e0',
            overflow: 'hidden',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
        }}>
        <Table storageKey={$state.current.name} data={data} columns={[
            ...(client !== undefined
                ? [
                    {
                        accessorKey: 'nomeCoreContacto',
                        header: 'Nome Colaborador',
                        size: 150
                    }
                ]
                : []),
            {
                accessorKey: 'designacao',
                header: 'Designação',
                size: 200
            },
            {
                accessorKey: 'tipo',
                header: 'Tipo',
                size: 100
            },
            {
                accessorKey: 'dataRegisto',
                header: 'Data',
                size: 100
            },
            {
                accessorKey: 'tamanho',
                header: 'Tamanho',
                size: 100
            }
        ]} initial={{
            sort: sort,
            filter: filter
        }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setFilter} onSort={setSort} rowActionSize={150} actionItems={({ row }) => [
            <Box key={`actions-${row.original.id}`} display='flex' gap={1}>
              {client !== undefined ? (<></>) : (<Tooltip title={'Editar dados'} placement='left'>
                  <IconButton color='primary' onClick={() => {
                        formik.setValues(row.original);
                        if (row.original.uuid) {
                            let fileUrl = `/api/Upload/crm/download/${row.original.uuid}`;
                            setAnexo(fileUrl);
                        }
                        else {
                            setAnexo(null);
                        }
                        formik.setTouched({}, false);
                        if (!expand)
                            setExpand(true);
                    }}>
                    <MdiIcon path={mdiPencil} sx={{ color: '#1976D2' }}/>
                  </IconButton>
                </Tooltip>)}

              <Tooltip title={'Ver documento'} placement='top'>
                <IconButton color='primary' onClick={() => {
                    window.open(`/api/Upload/crm/download/${row.original.uuid}`, '_blank');
                }}>
                  <MdiIcon path={mdiFileAccount} sx={{ color: '#1976D2' }}/>
                </IconButton>
              </Tooltip>
              <Tooltip title={'Ver detalhes'} placement='top'>
                <IconButton color='secondary' onClick={() => {
                    const showData = [
                        { key: 'Designação', value: row.original.designacao },
                        { key: 'Colaborador', value: row.original.nomeCoreContacto },
                        { key: 'Tipo', value: row.original.tipo },
                        { key: 'Data de registo', value: row.original.dataRegisto },
                        { key: 'Tamanho', value: row.original.tamanho },
                        { key: 'Observações', value: row.original.observacoes },
                        { key: 'Ficheiro', value: row.original.uuid }
                    ];
                    if (row.original.dataEdit != null) {
                        showData.push({ key: 'Data de edição', value: row.original.dataEdit });
                    }
                    showModal(InfoDialog, {
                        title: 'Detalhes documento',
                        data: showData
                    });
                }}>
                  <MdiIcon path={mdiMagnify} sx={{ color: '#1976D2' }}/>
                </IconButton>
              </Tooltip>
            </Box>
        ]}></Table>
      </Box>
      <Snackbar open={openSnackBar} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity='success' variant='filled' sx={{ width: '100%' }}>
          Dados alterados com sucesso!
        </Alert>
      </Snackbar>
    </Box>);
};
export default DocumentosAssociados;
