export default class PadAdminController {
  constructor($q, $state, UIService, PadConfig, PadCentroDecisao, PadCentroCusto, Funcionario, AtvEntidadeProprietaria, PRIIEPPlanocentros, PRIAJLPlanocentros, PRIOBLERPlanocentros) {
    this.$q = $q;
    this.$state = $state;
    this.UI = UIService;
    this.PadConfig = PadConfig;
    this.PadCentroDecisao = PadCentroDecisao;
    this.PadCentroCusto = PadCentroCusto;
    this.Funcionario = Funcionario;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.PRIIEPPlanocentros = PRIIEPPlanocentros;
    this.PRIAJLPlanocentros = PRIAJLPlanocentros;
    this.PRIOBLERPlanocentros = PRIOBLERPlanocentros;

    this.padConfigLoading = true;
    this.padCentrosDecisaoLoading = true;
    this.padCentrosCustoLoading = true;
    this.errorPadConfig = false;
    // Used for select only, merge with previous is not possible without redoing everything, only done once
    this.centrosCustoLoading = false;

    this.showConfigFab = true;
    this.showCentrosDecisaoFab = false;
    this.showCentrosCustoFab = false;

    this.config = null;

    // Used for centro custo selection only
    this.anos = [];
    this.auxAno = {
      selected: undefined,
      infiniteScroll: {numToAdd: 20, currentItems: 20}
    };
    this.ano = null;

    this.optCentrosDecisao = {
      model: this.PadCentroDecisao,
      type: 'CentrosDecisao',
      // Total from the db for the query
      total: 0,
      // Order of the first item shown
      start: 0,
      // Order of the last item shown
      end: 0,
      // Current "page" to view
      currentPage: 0,
      // Number of items to show at the same time. Return this value from db query
      perPage: 100,
      // Choices for number of items per page
      numberItemChoices: [10, 50, 100],
      // While data from db is loading
      loaded: true,
      // Default order of data
      order: [{entidadeProprietariaId: "ASC"}, {designacao: "ASC"}],
      // Filters always applied to data (ex. active:1) Always assumes that we are using AND fields
      defaultFilter: [{active: 1}],
      // Applied filters to data (filterLayout, what the user types, filter what we actually search for
      filterLayout: {},
      // Include data in main data
      include: ['entidadeProprietaria', 'responsavelDefault', 'responsavelAtual'],
      // Error
      error: null
    };

    this.optCentrosCusto = {
      model: this.PadCentroCusto,
      type: 'CentrosCusto',
      // Total from the db for the query
      total: 0,
      // Order of the first item shown
      start: 0,
      // Order of the last item shown
      end: 0,
      // Current "page" to view
      currentPage: 0,
      // Number of items to show at the same time. Return this value from db query
      perPage: 100,
      // Choices for number of items per page
      numberItemChoices: [10, 50, 100],
      // While data from db is loading
      loaded: true,
      // Default order of data
      order: [{entidadeProprietariaId: "ASC"}, {centro: "ASC"}, {ano: 'DESC'}],
      // Filters always applied to data (ex. active:1) Always assumes that we are using AND fields
      defaultFilter: [{active: 1}],
      // Applied filters to data (filterLayout, what the user types, filter what we actually search for
      filterLayout: {},
      // Include data in main data
      include: ['entidadeProprietaria', 'responsavelDefault', 'responsavelAtual'],
      // Error
      error: null
    };

    this.onFilter(this.optCentrosDecisao, false);
    // Moved inside loadCentrosCustoData()
    // this.onFilter(this.optCentrosCusto, false);

    this.getConfig(); // getResponsavel() is called inside
    this.loadCentrosCustoData();
  }

  loadCentrosCustoData = () => {
    this.centrosCustoLoading = true;
    // Find Centros Custo data
    this.PadCentroCusto.find({
      filter: {
        fields: {ano: true},
        where: {
          active: 1
        }
      }
    }).$promise.then((anos) => {
      if (anos && anos.length > 0) {
        anos = [...new Set(anos.map(x => x.ano))].sort().reverse();
        this.anos = anos.map(x => {return {ano: x}});
      } else { // Should never happen
        this.anos = [{ano: Number(moment().format('YYYY'))}];
      }
      // Select current by default
      this.auxAno.selected = this.anos.find(x => x.ano === (Number(moment().format('YYYY'))));

      this.centrosCustoLoading = false;
      // Call data
      if (this.auxAno.selected !== undefined) {
        this.onAnoSelected(this.auxAno.selected);
      } else {
        this.onFilter(this.optCentrosCusto, false);
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro ao obter Centros de Custo. Verifique a ligação");
    });
  };

  // When Ano for Centro Custo is selected
  onAnoSelected = (item) => {
    if (item.ano !== this.ano) {
      this.ano = item.ano;
    }
    this.optCentrosCusto.defaultFilter[1] = {ano: this.ano};
    this.onFilter(this.optCentrosCusto, false);
  };

  getConfig = () => {
    this.padConfigLoading = true;
    this.PadConfig.findOne({}).$promise.then((config) => {
      if (config) {
        this.config = config;
        this.errorPadConfig = false;
      } else
        this.errorPadConfig = true;

      this.padConfigLoading = false;
    }).catch((error) => {
      console.log(error);
      this.errorPadConfig = true;
      this.padConfigLoading = false;
    });
  };

  savePadConfig = () => {
    this.padConfigLoading = true;
    this.PadConfig.upsert(this.config).$promise.then((conf) => {
      this.UI.addToast("Configuração PAD atualizada com sucesso");
      this.getConfig();
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar configuração");
      this.padConfigLoading = false;
    });
  };

  configLoading = () => this.padConfigLoading && this.padCentrosDecisaoLoading && this.padCentrosCustoLoading;

  validConfigForm = () => this.config && !isNaN(this.config.valorAprovacaoNivel2) && !isNaN(this.config.valorAprovacaoNivel3);

  updateFab = (f) => {
    if (f === 'config') { // Config
      this.showConfigFab = true;
      this.showCentrosDecisaoFab = false;
      this.showCentrosCustoFab = false;
    } else if (f === 'centrosDecisao') { // Centros de Decisão
      this.showConfigFab = false;
      this.showCentrosDecisaoFab = true;
      this.showCentrosCustoFab = false;
    } else if (f === 'centrosCusto') { // Centros de Custo
      this.showConfigFab = false;
      this.showCentrosDecisaoFab = false;
      this.showCentrosCustoFab = true;
    }
  };

  // When user chooses number of items to see in the list
  onNumberItems = (opt, items) => {
    opt.perPage = items;
    this.onFilter(opt, false);
  };

  // Returns an object with the data for filter to be applied for fields to search
  parseFilterLayout = (opt) => {
    let filter = {};

    ///////////////////////////////////////////////////
    // Has to be adapted case to case on copy elsewhere. Probably only one type is necessary
    ///////////////////////////////////////////////////
    switch(opt.type) {
      case 'CentrosDecisao':
        Object.keys(opt.filterLayout).forEach(k => {
          if (!_.isEmpty(opt.filterLayout[k])) {
            filter[k] = {like: '%' + opt.filterLayout[k].replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + '%'};
          }
        });
        break;
      case 'CentrosCusto':
        Object.keys(opt.filterLayout).forEach(k => {
          if (!_.isEmpty(opt.filterLayout[k])) {
            filter[k] = {like: '%' + opt.filterLayout[k].replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + '%'};
          }
        });
        break;
      default:
        Object.keys(opt.filterLayout).forEach(k => {
          if (!_.isEmpty(opt.filterLayout[k])) {
            filter[k] = {like: '%' + opt.filterLayout[k].replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + '%'};
          }
        });
        break;
    }
    return filter;
  };

  // When user clicks on column header to sort
  onSorting = (opt, field) => {
    // If not sorting by the field
    let f = opt.order.find(x => Object.keys(x)[0] === field);
    if (!f) {
      f = {};
      opt.order = [];
      f[field] = 'ASC';
      opt.order.push(f);
    } else { // If sorting by, change order
      f[field] = f[field] === 'ASC' ? 'DESC' : 'ASC';
    }
    this.onFilter(opt, false);
  };

  // Returns true/false if sorting by field
  isSortingBy = (opt, field) => {
    return opt.order.find(x => Object.keys(x)[0] === field);
  };

  // Returns true/false if it's sorting by type ('ASC'/'DESC')
  isSortingType = (opt, field, type) => {
    let f = opt.order.find(x => Object.keys(x)[0] === field);
    if (!f) return false;
    else return f[field] === type;
  };

  onFilter = (opt, pagination) => {
    opt.loaded = false;
    // So we always keep a copy of the original for next time
    let defaultFilter = angular.copy(opt.defaultFilter);

    // By default only search active data
    let searchObject = {and: defaultFilter};
    // Add filters to search
    Object.keys(opt.filterLayout).forEach(k => {
      if (k.value) {
        searchObject.and.push(k);
      }
    });

    // Parse filters done by the user
    let userFilters = this.parseFilterLayout(opt);
    Object.keys(userFilters).forEach(k => {
      let newFilter = {};
      newFilter[k] = userFilters[k];
      searchObject.and.push(newFilter);
    });

    let itemsToSkip = 0;
    // If we have pagination, we have to skip appropriately
    if (pagination) {
      itemsToSkip = opt.currentPage * opt.perPage; //
    } else {
      opt.currentPage = 0; // Reset to 1st page
    }

    // Deal with order
    let order = '';
    opt.order.forEach((o,i) => {
      let key = Object.keys(o)[0]; // The key to sort by
      if (i === 0) order += key + " " + o[key];
      else order += ', ' + key + " " + o[key];
    });

    opt.model.count({
      fields: {id: true},
      where: searchObject
    }).$promise.then((total) => {
      opt.total = total.count;

      // Do the search
      opt.model.find({
        filter: {
          where: searchObject,
          order: order,
          limit: opt.perPage,
          skip: itemsToSkip,
          include: opt.include
        }
      }).$promise.then((res) => {
        opt.data = res;
        opt.start = opt.total === 0 ? 0 : opt.currentPage * opt.perPage + 1;
        opt.end = opt.total === 0 ? 0: opt.currentPage * opt.perPage + opt.data.length;
        opt.loaded = true;

      }).catch((error) => {
        console.log(error);
        opt.error = error;
        opt.currentPage = 0;
        opt.start = 0;
        opt.end = 0;
        opt.data = null;
        opt.total = 0;
        opt.loaded = true;
      });
    }).catch((error) => {
      console.log(error);
      opt.error = error;
      opt.currentPage = 0;
      opt.start = 0;
      opt.end = 0;
      opt.data = null;
      opt.total = 0;
      opt.loaded = true;
    });
  };

  // Infinite Scroll magic
  addMoreItems = (infiniteScroll) => {
    infiniteScroll.currentItems += infiniteScroll.numToAdd;
  };

  previous = (opt) => {
    if(opt.currentPage > 0) {
      opt.currentPage--;
      this.onFilter(opt, true);
    }
  };

  next = (opt) => {
    if (opt.currentPage < this.totalPages(opt) - 1) {
      opt.currentPage++;
      this.onFilter(opt, true);
    }
  };

  totalPages = (opt) => {
    return Math.ceil(opt.total / opt.perPage);
  };

  // Centros Decisão
  addCentroDecisao = () => {
    let wait = this.UI.showWaiting();
    this.AtvEntidadeProprietaria.find({
      filter: {
        where: {active: 1},
        order: 'id ASC'
      }
    }).$promise.then((entidadesProprietarias) => {
      this.Funcionario.find({
        filter: {
          where: {
            active: true
          },
          order: 'name ASC'
        }
      }).$promise.then((funcionarios) => {
        funcionarios.sort((a, b) => a.name.localeCompare(b.name));
        wait.close();
        let options = {
          size: 'md',
          template: require('./edit.centrodecisao.dialog.html'),
          controller: ["$scope", "$dialog", ($scope, $dialog) => {
            $scope.label = "Editar Centro de Decisão";
            $scope.isEditing = false;
            $scope.centroDecisao = {};

            $scope.funcionarios = funcionarios;
            $scope.entidadesProprietarias = entidadesProprietarias;

            $scope.auxEntidadeProprietaria = {
              selected: undefined,
              infiniteScroll: {numToAdd: 20, currentItems: 20}
            };

            $scope.auxResponsavelDefault = {
              selected: undefined,
              infiniteScroll: {numToAdd: 20, currentItems: 20}
            };

            $scope.auxResponsavelAtual = {
              selected: undefined,
              infiniteScroll: {numToAdd: 20, currentItems: 20}
            };

            // If we change Default, change Atual as well
            $scope.onResponsavelDefaultChange = (item) => {
              if (item.id !== $scope.auxResponsavelAtual.id)
                $scope.auxResponsavelAtual.selected = angular.copy(item);
            };

            // Infinite Scroll magic
            $scope.addMoreItems = (infiniteScroll) => {
              infiniteScroll.currentItems += infiniteScroll.numToAdd;
            };

            $scope.ok = () => {
              $dialog.close($scope);
            };

            $scope.cancel = () => {
              $dialog.dismiss("cancel");
            };
          }]
        };
        let modal = this.UI.showDialog(options);

        modal.then(ok => {
          if (ok.auxEntidadeProprietaria.selected && ok.auxResponsavelDefault.selected && ok.auxResponsavelAtual.selected) {
            this.optCentrosDecisao.loaded = false;
            this.PadCentroDecisao.create({
              id: 0,
              designacao: ok.centroDecisao.designacao,
              responsavelDefaultId: ok.auxResponsavelDefault.selected.id,
              responsavelAtualId: ok.auxResponsavelAtual.selected.id,
              entidadeProprietariaId: ok.auxEntidadeProprietaria.selected.id,
              active: 1
            }).$promise.then(() => {
              this.UI.addToast("Centro de Decisão criado com sucesso.");
              this.onFilter(this.optCentrosDecisao, false);
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Erro na criação do Centro de Decisão. Verifique a ligação.");
              this.onFilter(this.optCentrosDecisao, false);
            });
          }
        }).catch(() => {});
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro na procura de Colaboradores. Verifique a ligação.");
      });
    }).catch(err => {
      console.log(err);
      this.UI.addToast("Erro ao carregar entidades proprietárias. Verifique a sua ligação");
    });
  };

  editCentroDecisao = (c) => {
    let wait = this.UI.showWaiting();
    this.Funcionario.find({
      filter: {
        where: {
          active: true
        },
        order: 'name ASC'
      }
    }).$promise.then((funcionarios) => {
      funcionarios.sort((a, b) => a.name.localeCompare(b.name));
      wait.close();
      let options = {
        size: 'md',
        template: require('./edit.centrodecisao.dialog.html'),
        controller: ["$scope", "$dialog", ($scope, $dialog) => {
          $scope.label = "Editar Centro de Decisão";
          $scope.isEditing = true;
          $scope.centroDecisao = angular.copy(c);

          $scope.funcionarios = funcionarios;

          $scope.auxResponsavelDefault = {
            selected: c.responsavelDefault,
            infiniteScroll: {
              numToAdd: 20,
              currentItems: 20
            }
          };

          $scope.auxResponsavelAtual = {
            selected: c.responsavelAtual,
            infiniteScroll: {
              numToAdd: 20,
              currentItems: 20
            }
          };

          // If we change Default, change Atual as well
          $scope.onResponsavelDefaultChange = (item) => {
            if (item.id !== $scope.auxResponsavelAtual.id)
              $scope.auxResponsavelAtual.selected = angular.copy(item);
          };

          // Infinite Scroll magic
          $scope.addMoreItems = (infiniteScroll) => {
            infiniteScroll.currentItems += infiniteScroll.numToAdd;
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss("cancel");
          };
        }]
      };
      let modal = this.UI.showDialog(options);

      modal.then(ok => {
        this.optCentrosDecisao.loaded = false;
        if (ok.auxResponsavelDefault.selected && ok.auxResponsavelAtual.selected) {
          ok.centroDecisao.responsavelDefaultId = ok.auxResponsavelDefault.selected.id;
          ok.centroDecisao.responsavelAtualId = ok.auxResponsavelAtual.selected.id;
          // Change centroDecisao object - upsert doesn't work with ok.centroDecisao or c
          this.PadCentroDecisao.upsert({
            id: ok.centroDecisao.id,
            designacao: ok.centroDecisao.designacao,
            responsavelDefaultId: ok.centroDecisao.responsavelDefaultId,
            responsavelAtualId: ok.centroDecisao.responsavelAtualId,
            entidadeProprietariaId: ok.centroDecisao.entidadeProprietariaId,
            active: 1
          }).$promise.then(() => {
            this.UI.addToast("Centro de Decisão atualizado");
            this.onFilter(this.optCentrosDecisao, false);
          }).catch(err => {
            console.log(err);
            this.UI.addToast("Erro na gravação da atualização do Centro de Custo.");
            this.onFilter(this.optCentrosDecisao, false);
          });
        }
      }).catch(() => {});
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro na procura de Colaboradores. Verifique a ligação.");
    });
  };

  // Centros de Custo

  editCentroCustos = c => {
    let wait = this.UI.showWaiting();
    this.Funcionario.find({
      filter: {
        where: {
          active: true
        },
        order: 'name ASC'
      }
    }).$promise.then((funcionarios) => {
      funcionarios.sort((a, b) => a.name.localeCompare(b.name));
      wait.close();
      let options = {
        size: "lg",
        template: require("./edit.centrocusto.dialog.html"),
        controller: ["$scope", "$dialog", ($scope, $dialog) => {
          $scope.centroCusto = angular.copy(c);

          $scope.funcionarios = funcionarios;

          $scope.auxResponsavelDefault = {
            selected: c.responsavelDefault,
            infiniteScroll: {
              numToAdd: 20,
              currentItems: 20
            }
          };

          $scope.auxResponsavelAtual = {
            selected: c.responsavelAtual,
            infiniteScroll: {
              numToAdd: 20,
              currentItems: 20
            }
          };

          // If we change Default, change Atual as well
          $scope.onResponsavelDefaultChange = (item) => {
            if (item.id !== $scope.auxResponsavelAtual.id)
              $scope.auxResponsavelAtual.selected = angular.copy(item);
          };

          // Infinite Scroll magic
          $scope.addMoreItems = (infiniteScroll) => {
            infiniteScroll.currentItems += infiniteScroll.numToAdd;
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss("cancel");
          };
        }]
      };
      let modal = this.UI.showDialog(options);
      modal.then(ok => {
        if (ok.auxResponsavelDefault.selected && ok.auxResponsavelAtual.selected) {
          this.optCentrosCusto.loaded = false;
          this.tableLoading = true;
          ok.centroCusto.responsavelDefaultId = ok.auxResponsavelDefault.selected.id;
          ok.centroCusto.responsavelAtualId = ok.auxResponsavelAtual.selected.id;
          // Change centroCusto object - upsert doesn't work with ok.centroCusto or c
          this.PadCentroCusto.upsert({
            id: ok.centroCusto.id,
            centro: ok.centroCusto.centro,
            designacao: ok.centroCusto.designacao,
            ano: ok.centroCusto.ano,
            tipoConta: ok.centroCusto.tipoConta,
            responsavelDefaultId: ok.centroCusto.responsavelDefaultId,
            responsavelAtualId: ok.centroCusto.responsavelAtualId,
            entidadeProprietariaId: ok.centroCusto.entidadeProprietariaId,
            active: 1
          }).$promise.then(() => {
            this.UI.addToast("Centro de Custo atualizado.");
            this.onFilter(this.optCentrosCusto, false);
          }).catch(err => {
            console.log(err);
            this.tableLoading = false;
            this.UI.addToast("Erro na gravação da atualização do Centro de Custo.");
          });
        }
      }).catch(() => {});
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro na procura de Colaboradores. Verifique a ligação.");
    });
  };

  // Removal was removed

  importCentrosCusto = () => {
    let wait = this.UI.showWaiting();
    this.PadCentroCusto.import({}).$promise.then(summary => {
      wait.close();
      let title = "Detalhes de Sincronização";
      let introText = "Foram realizadas as seguintes tarefas para Centros de Custo:";
      let instance = this.UI.showDialog({
        size: 'md',
        template: require('./sincronizacao.details.dialog.html'),
        controller: ['$scope', ($scope) => {
          $scope.title = title;
          $scope.introText = introText;
          $scope.summary = summary;
          $scope.ok = function () {
            $scope.$close();
          };
        }]
      });
      instance.finally(() => {
        this.onFilter(this.optCentrosCusto, false);
      });
    }).catch(error => {
      wait.close();
      console.log(error);
      this.UI.addToast("Erro na sincronização de Centros de Custo. Verifique ligação e permissões");
      this.onFilter(this.optCentrosCusto, false);
    });
  };

}

PadAdminController.$inject = ['$q', '$state', 'UIService', 'PadConfig', 'PadCentroDecisao', 'PadCentroCusto', 'Funcionario', 'AtvEntidadeProprietaria', 'PRIIEPPlanocentros', 'PRIAJLPlanocentros', 'PRIOBLERPlanocentros'];
