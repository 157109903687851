export default class FormacaoFormandoDetailsController {
  constructor($state, UIService, FormacaoFormando, FormacaoAreaCategoria, FormacaoFormandoAreaInteresse, FormacaoFormandoEmpresa, FormacaoTipoDocumento, CorePaises, CoreConcelho, CoreDistritos) {
    this.$state = $state;
    this.id = $state.params.id;
    this.loaded = false;
    this.UI = UIService;
    this.FormacaoFormando = FormacaoFormando;
    this.FormacaoAreaCategoria = FormacaoAreaCategoria;
    this.FormacaoFormandoEmpresa = FormacaoFormandoEmpresa;
    this.FormacaoFormandoAreaInteresse = FormacaoFormandoAreaInteresse;
    this.FormacaoTipoDocumento = FormacaoTipoDocumento;
    this.CorePaises = CorePaises;
    this.CoreConcelho = CoreConcelho;
    this.CoreDistritos = CoreDistritos;

    // Redirect if not defined
    if (!this.id)
      this.$state.go('app.formacao.formandos.list');
    this.loadData();
  }

  editDetails = () => {
    let o = angular.copy(this.formando);
    this.UI.showDialog({
      size: 'lg',
      template: require('./details.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.formando = o;
        $scope.tipos = this.tipoDocumento;
        $scope.subTipos = this.subTipoDocumento;
        $scope.paises = this.paisOrigem;
        $scope.nacionalidade = this.paisOrigem;
        $scope.concelho = this.concelho;
        $scope.distrito = this.distrito;
        $scope.emitidoPorAuResidencia = this.emitidoPorAuResidencia;
        $scope.emitidoPorIdMilitar = this.emitidoPorIdMilitar;

        $scope.auxTipo = {
          selected: {}
        };

        $scope.auxSubTipo = {
          selected: {}
        };

        $scope.auxPaises = {
          selected: {}
        };

        $scope.auxNacionalidade = {
          selected: {}
        };

        $scope.auxConcelho = {
          selected: {}
        };

        $scope.auxDistrito = {
          selected: {}
        };

        $scope.auxEmitido = {
          selected: {}
        };

        $scope.auxTipo.selected = this.tipoDocumento.find(f => {
          return f.id === o.documentoTipo;
        });

        $scope.auxSubTipo.selected = this.subTipoDocumento.find(f => {
          return f.id === o.documentoSubTipo;
        });

        $scope.auxPaises.selected = this.paisOrigem.find(f => {
          return f.id === o.paisOrigem;
        });

        $scope.auxNacionalidade.selected = this.paisOrigem.find(f => {
          return f.id === o.nacionalidade;
        });
        $scope.auxConcelho.selected = this.concelho.find(f => {
          return f.id === o.concelho;
        });

        $scope.auxDistrito.selected = this.distrito.find(f => {
          if (o.relConcelho)
            return f.id === o.relConcelho.distritoId;
          else
            return false;
        });

        $scope.auxEmitido.selected = this.emitidoPorIdMilitar.find(f => {
          return f.id === o.documentoEmitidoPor;
        });

        $scope.auxEmitido.selected = this.emitidoPorAuResidencia.find(f => {
          return f.id === o.documentoEmitidoPor;
        });

        $scope.ok = () => {
          let documentoEmitidoPor;
          if ($scope.auxTipo.selected && $scope.auxTipo.selected.id === 3) {
            documentoEmitidoPor = $scope.auxEmitido.selected.id;
          } else if ($scope.auxTipo.selected && $scope.auxTipo.selected.id === 2) {
            documentoEmitidoPor = $scope.auxEmitido.selected.id;
          } else {
            documentoEmitidoPor = null;
          }

          let concelho;
          if($scope.auxPaises.selected && $scope.auxPaises.selected.id === 1) {
            concelho = $scope.auxConcelho.selected.id;
          } else {
            concelho = null;
          }

          if ($scope.auxTipo.selected) {
            $scope.formando.documentoTipo = $scope.auxTipo.selected.id;
            if ($scope.formando.tipoDocumento) {
              $scope.formando.tipoDocumento.id = $scope.auxTipo.selected.id;
            }
          }
          if ($scope.auxSubTipo.selected) {
            $scope.formando.documentoSubTipo = $scope.auxSubTipo.selected.id;
            if ($scope.formando.subtipoDocumento) {
              $scope.formando.subtipoDocumento.id = $scope.auxSubTipo.selected.id;
            }
          } else { // It could have been selected before, but now becomes deselected, need to explicitly nullify it
            $scope.formando.documentoSubTipo = null;
            if ($scope.formando.subtipoDocumento) {
              $scope.formando.subtipoDocumento.id = null;
            }
          }
          if ($scope.auxPaises.selected) {
            $scope.formando.paisOrigem = $scope.auxPaises.selected.id;
            if ($scope.formando.relPaisOrigem) {
              $scope.formando.relPaisOrigem.id = $scope.auxPaises.selected.id;
            }
          }
          if ($scope.auxNacionalidade.selected) {
            $scope.formando.nacionalidade = $scope.auxNacionalidade.selected.id;
            if ($scope.formando.relNacionalidade) {
              $scope.formando.relNacionalidade.id = $scope.auxNacionalidade.selected.id;
            }
          }

          if ($scope.formando.relConcelho) {
            $scope.formando.relConcelho.id = concelho ? concelho : null;
          } else if (concelho != null){
            $scope.formando.concelho = concelho;
          }
          $scope.formando.documentoEmitidoPor = documentoEmitidoPor ? documentoEmitidoPor : null;
          $scope.$close($scope.formando);
        };
        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    }).then(res => {
      if (res) {
        res.dataNascimento = res.dataNascimento.format('YYYY-MM-DD');
        res.dataInscricao = res.dataInscricao.format('YYYY-MM-DD');
        res.documentoValidade = res.documentoValidade.format('YYYY-MM-DD');
        this.formando = res;
        this.formando.$save().then(() => {
          this.loadData();
          this.UI.addToast('Formando atualizado com sucesso');
        }).catch(() => {
          this.loadData();
          this.UI.addToast('Ocorreu um erro ao atualizar formando');
        })
      }
    });
  };

  removeEmpr = (f) => {
    this.UI.showConfirm('Deseja eliminar registo?').then(ok => {
      if (ok) {
        this.FormacaoFormandoEmpresa.delete(f).$promise.then(r => {
          this.UI.addToast('Registo eliminado com sucesso');
          this.loadData();
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao eliminar registo!');
        })
      }
    });
  };

  addEmpresa = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./company.dialog.html'),
      controller: ['$scope', 'FormacaoEmpresa', ($scope, FormacaoEmpresa) => {
        $scope.ok = () => {
          $scope.$close($scope.auxEmpresa);
        };

        $scope.auxEmpresa = {
          selected: {}
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };

        $scope.hasLoaded = false;

        FormacaoEmpresa.find({filter: {where: {active: true}}}).$promise.then(a => {
          $scope.empresas = a;
          $scope.hasLoaded = true;
        })
      }]
    }).then(res => {
      if (res) {
        this.FormacaoFormandoEmpresa.find({
          filter: {
            where: {
              formandoId: this.id
            }
          }
        }).$promise.then(a => {
          a.forEach(f => {
            f.active = false;
            f.$save();
          });
          this.FormacaoFormandoEmpresa.create({
            id: 0,
            formandoId: this.id,
            empresaId: res.selected.id,
            active: true
          }).$promise.then(() => {
            this.loadData();
            this.UI.addToast('Empresa associada com sucesso');
          }).catch(() => {
            this.UI.addToast('Ocorreu um erro ao associar empresa');
          });
        });
      }
    });
  };

  loadData = () => {
    this.loaded = false;
    this.FormacaoFormando.findOne({
      filter: {
        where: {
          id: this.id
        },
        include: ['relNacionalidade', 'relPaisOrigem',
          {
            relation: 'relConcelho',
            scope: {
              include: 'distrito'
            }
          },
          {
            relation: 'formandoEmpresa',
            scope: {
              order: 'id desc',
              include: 'empresa'
            }
          },
          {
            relation: 'inscricoes',
            scope: {
              include: ['estado', {
                relation: 'edicao',
                scope: {include: ['tipo', 'empresa', 'curso']}
              }]
            }
          }, {
            relation: 'interesse',
            scope: {
              include: 'area'
            }
          },
          {
            relation: 'tipoDocumento'
          }
        ]
      }
    }).$promise.then(res => {
      this.FormacaoAreaCategoria.find({
        filter: {
          where: {
            active: true
          }
        }
      }).$promise.then((areas) => {
        this.areas = areas;
      }).catch(() => {
      });

      this.FormacaoTipoDocumento.find({
        filter: {
          where: {
            active: true,
            pai: 0
          }
        }
      }).$promise.then(res => {
        this.tipoDocumento = res;
      }).catch(err => {
        console.log(err);
      });

      this.FormacaoTipoDocumento.find({
        filter: {
          where: {
            active: 1,
            pai: 3
          }
        }
      }).$promise.then(res => {
        this.subTipoDocumento = res;
      }).catch(err => {
        console.log(err);
      });

      this.FormacaoTipoDocumento.find({
        filter: {
          where: {
            active: 1,
            pai: 4
          }
        }
      }).$promise.then(res => {
        this.emitidoPorIdMilitar = res;
      }).catch(err => {
        console.log(err);
      });

      this.FormacaoTipoDocumento.find({
        filter: {
          where: {
            active: 1,
            pai: 5
          }
        }
      }).$promise.then(res => {
        this.emitidoPorAuResidencia = res;
      }).catch(err => {
        console.log(err);
      });

      this.CorePaises.find({
        filter: {
          where: {
            active: 1
          }
        }
      }).$promise.then(res => {
        this.paisOrigem = res;
      }).catch(err => {
        console.log(err);
      });

      this.CoreConcelho.find({
        filter: {
          where: {
            active: 1
          },
          order: 'designacao ASC'
        }
      }).$promise.then(res => {
        this.concelho = res;
      }).catch(err => {
        console.log(err);
      });

      this.CoreDistritos.find({
        filter: {
          where: {
            active: 1
          }
        }
      }).$promise.then(res => {
        this.distrito = res;
      }).catch(err => {
        console.log(err);
      });

      this.formando = res;
      this.loaded = true;
    });
  };
  //
  // getTipoDocumento = () => {
  //   if (!this.loaded)
  //     return null;
  //   return this.tipoDocumento.find((e) => {
  //     return e.id === this.formando.documentoTipo;
  //   });
  // };

  editObservacoes = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.observacoes = angular.copy(this.formando.observacoes);

        $scope.ok = () => {
          $scope.$close($scope.observacoes);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        }
      }]
    }).then(res => {
      this.formando.observacoes = res;
      this.formando.$save().then(() => {
        this.loadData();
        this.UI.addToast('Observações editado com sucesso');
      }).catch(() => {
        this.UI.addToast('Erro ao editar observações');
      });
    }).catch(() => {
    });
  };

  editAreasInteresse = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./interest.dialog.html'),
      controller: ['$scope', ($scope) => {

        $scope.data = {
          selected: []
        };

        $scope.ok = () => {
          $scope.$close($scope.data.selected);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };

        $scope.areasInteresse = this.areas;

        this.formando.interesse.forEach((i) => {
          this.areas.forEach(a => {
            if (a.id === i.areacategoriaId) {
              $scope.data.selected.push(a);
            }
          });
        });
      }]
    }).then(res => {
      this.FormacaoFormandoAreaInteresse.delete({
        where: {
          formandoId: this.id
        }
      }).$promise.then(() => {
          res.forEach(r => {
            this.FormacaoFormandoAreaInteresse.create({
              id: 0,
              formandoId: this.id,
              areacategoriaId: r.id
            });
          });
          this.loadData();
          this.UI.addToast('Áreas de interesse atualizadas');
        }
      );
    }).catch(() => {
    });
  };
}

FormacaoFormandoDetailsController.$inject = ['$state', 'UIService', 'FormacaoFormando', 'FormacaoAreaCategoria', 'FormacaoFormandoAreasInteresse', 'FormacaoFormandoEmpresa', 'FormacaoTipoDocumento', 'CorePaises', 'CoreConcelho', 'CoreDistritos'];
