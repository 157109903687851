export default class SusEneDirectorySchedulingController {
  constructor($filter, $state, AuthenticationService, Group, SusEneProcesso, SusEneProcessoCaracteristica, SusEneLaboratorio, SusEneLaboratorioSubarea, Funcionario, SusEneCliente, SusEneEstadoProcesso, UIService, SusEneProcessoService) {
    this.$filter = $filter;
    this.$state = $state;
    this.user = AuthenticationService.getUser();
    this.Group = Group;
    this.SusEneProcesso = SusEneProcesso;
    this.SusEneProcessoCaracteristica = SusEneProcessoCaracteristica;
    this.SusEneLaboratorio = SusEneLaboratorio;
    this.SusEneLaboratorioSubarea = SusEneLaboratorioSubarea;
    this.Funcionario = Funcionario;
    this.SusEneCliente = SusEneCliente;
    this.SusEneEstadoProcesso = SusEneEstadoProcesso;
    this.UI = UIService;
    this.SusEneProcessoService = SusEneProcessoService;

    // If loadData() finished or not
    this.dataLoaded = false;

    this.certificados = [{name: "Sim", value: 1}, {name: "Não", value: 0}];
    this.laboratorios = [];
    this.subareas = [];
    this.clientes = [];
    this.tecnicosSUSENE = [];
    this.caracteristicas = [];
    this.estados = [];
    this.acreditados = [{name: "Sim", value: 1}, {name: "Não", value: 0}];
    this.inSitus = [{name: "Sim", value: 1}, {name: "Não", value: 0}];
    this.subcontratacoes = [{name: "Sim", value: 1}, {name: "Não", value: 0}];

    this.filtersLoaded = [1, 0, 0, 0, 0, 0, 0, 1, 1, 1]; // Certificados already loaded

    this.opt = $state.params;

    // Total processes for currently being displayed
    this.total = 0;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [
      {
        displayName: 'ID SGI',
        name: 'id',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Nº Processo',
        name: 'processo',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Estado',
        name: 'estado',
        visible: true,
        sortable: true
      },
      // {
      //   displayName: 'Natureza',
      //   name: 'natureza',
      //   visible: false,
      //   sortable: true
      // },
      {
        displayName: 'Área',
        name: 'laboratorio',
        visible: true,
        sortable: true
      },
      // {
      //   displayName: 'Subárea',
      //   name: 'subarea',
      //   visible: true,
      //   sortable: true
      // },
      // {
      //   displayName: 'Característica',
      //   name: 'caracteristica',
      //   visible: false,
      //   sortable: true
      // },
      {
        displayName: 'Cliente',
        name: 'cliente',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Artigo/Descrição',
        name: 'artigo',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Norma',
        name: 'norma',
        visible: false,
        sortable: true
      },
      // {
      //   displayName: 'Acreditado?',
      //   name: 'acreditado',
      //   visible: false,
      //   sortable: true
      // },
      // {
      //   displayName: 'Certificado?',
      //   name: 'certificado',
      //   visible: false,
      //   sortable: true
      // },
      {
        displayName: 'In Situ?',
        name: 'inSitu',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Subcontratação?',
        name: 'subcontratacao',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Atribuído a',
        name: 'atribuidoa',
        visible: true,
        sortable: false
      },
      {
        displayName: 'Últ. Alteração',
        name: 'ultimaAlteracao',
        exportDate: 'DD-MM-YYYY',
        visible: true
      },
      {
        displayName: '% Execução',
        name: 'execucao',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Email',
        name: 'email',
        visible: false,
        sortable: true
      }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: 'SusEneProcesso.estadoId',
        name: 'Estado',
        type: 's',
        list: this.estados
      },
      {
        id: 'SusEneOrdemintervencao.clienteId',
        name: 'Cliente',
        type: 's',
        list: this.clientes
      },
      {
        id: 'SusEneProcesso.iidAno',
        name: 'Ano',
        type: 'o'
      },
      {
        id: 'SusEneProcesso.numeroProcesso',
        name: 'Nº Processo',
        type: 'o'
      },
      // {
      //   id: 'SusEneProcesso.natureza',
      //   name: 'Natureza',
      //   type: 'o'
      // },
      {
        id: 'SusEneProcesso.laboratorioId',
        name: 'Laboratório',
        type: 's',
        list: this.laboratorios
      },
      // {
      //   id: 'SusEneProcesso.subareaId',
      //   name: 'Subárea',
      //   type: 's',
      //   list: this.subareas
      // },
      {
        id: 'SusEneProcesso.funcionarioId',
        name: 'Atribuído a',
        type: 's',
        list: this.tecnicosSUSENE
      },
      {
        id: 'SusEneProcesso.artigo',
        name: 'Artigo/Descrição',
        type: 'o'
      },
      {
        id: 'SusEneProcesso.norma',
        name: 'Norma',
        type: 'o'
      },
      // {
      //   id: 'SusEneProcesso.caracteristicaId',
      //   name: 'Característica',
      //   type: 's',
      //   list: this.caracteristicas
      // },
      // {
      //   id: 'SusEneProcesso.acreditado',
      //   name: 'Acreditado?',
      //   type: 's',
      //   list: this.acreditados
      // },
      // {
      //   id: 'SusEneProcesso.certificado',
      //   name: 'Certificado?',
      //   type: 's',
      //   list: this.certificados
      // },
      {
        id: 'SusEneProcesso.inSitu',
        name: 'In Situ?',
        type: 's',
        list: this.inSitus
      },
      {
        id: 'SusEneProcesso.subcontratacao',
        name: 'Subcontratação?',
        type: 's',
        list: this.subcontratacoes
      },
      {
        id: 'SusEneProcesso.ultimaAlteracao',
        name: 'Últ. Alteração',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'SusEneProcesso.email',
        name: 'Email',
        type: 'o'
      }
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('SUSENEGestaoProcessosDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('SUSENEGestaoProcessosDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('SUSENEGestaoProcessosFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('SUSENEGestaoProcessosFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('SUSENEGestaoProcessosFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'SusEneProcesso.laboratorioId')
          f.column.selected.list = this.laboratorios;
        if (f.column.selected.id === 'SusEneProcesso.subareaId')
          f.column.selected.list = this.subareas;
        if (f.column.selected.id === 'SusEneOrdemintervencao.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'SusEneProcesso.funcionarioId')
          f.column.selected.list = this.tecnicosSUSENE;
        if (f.column.selected.id === 'SusEneProcesso.caracteristicaId')
          f.column.selected.list = this.caracteristicas;
        if (f.column.selected.id === 'SusEneProcesso.estadoId')
          f.column.selected.list = this.estados;
        if (f.column.selected.id === 'SusEneProcesso.acreditado')
          f.column.selected.list = this.acreditados;
        if (f.column.selected.id === 'SusEneProcesso.certificado')
          f.column.selected.list = this.certificados;
        if (f.column.selected.id === 'SusEneProcesso.inSitu')
          f.column.selected.list = this.inSitus;
        if (f.column.selected.id === 'SusEneProcesso.subcontratacao')
          f.column.selected.list = this.subcontratacoes;

      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getProcessos();
  };

  clearFilter = () => {
    localStorage.removeItem('SUSENEGestaoProcessosFilter');
    this.customFilters = [];
    this.getProcessos();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  getProcessos = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {
      'SusEneProcesso.active': 1
    });

    this.SusEneProcesso.table({
      params: {
        fields: [
          'SusEneProcesso.id as id',
          'SusEneProcesso.numeroProcesso as processo',
          'SusEneProcesso.iidAno as iidAno',
          'SusEneProcesso.iidProc as iidProc',
          'SusEneLaboratorio.designacao as laboratorio',
          // 'SusEneLaboratorioSubarea.designacao as subarea',
          'SusEneCliente.nome as cliente',
          'SusEneProcesso.percExecucao as execucao',
          'SusEneProcesso.ultimaAlteracao as ultimaAlteracao',
          'Funcionario.name as atribuidoa',
          'SusEneProcesso.artigo as artigo',
          'SusEneProcesso.norma as norma',
          // 'SusEneProcesso.natureza as natureza',
          // 'SusEneProcessoCaracteristica.value as caracteristica',
          'SusEneEstadoProcesso.descricao as estado',
          // 'SusEneProcesso.acreditado as acreditado',
          // 'SusEneProcesso.certificado as certificado',
          'SusEneProcesso.inSitu as inSitu',
          'SusEneProcesso.subcontratacao as subcontratacao',
          'SusEneProcesso.email as email',
          'SusEneProcesso.estadoId as estadoId',
          'SusEneProcesso.empresaId as empresaId',
          'SusEneProcesso.tipoId as tipoId'
        ],
        from: ['SusEneProcesso', 'SusEneLaboratorio', 'SusEneOrdemintervencao', 'SusEneCliente', 'SusEneLaboratorioSubarea', 'Funcionario', 'SusEneProcessoCaracteristica', 'SusEneEstadoProcesso'],
        referencesOrigin: [],
        references: [undefined, 'SusEneProcesso.laboratorioId', 'SusEneProcesso.oiId', 'SusEneOrdemintervencao.clienteId', 'SusEneProcesso.subareaId', 'SusEneProcesso.funcionarioId', 'SusEneProcesso.caracteristicaId', 'SusEneProcesso.estadoId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      // res.data.forEach(r => {
      //   let tmp = r.tipo === "Metrologia" ? 'M' : 'E';
      //   r.processo = `${tmp}-${r.iidAno}-${("0000" + r.iidProc).slice(-4)}`;
      // });

      this.processos = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível obter processos. Verifique a ligação");
    })
  };

  exportProcessos = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, { 'SusEneProcesso.active': 1 });
    this.SusEneProcesso.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'SusEneProcesso.id as id',
          'SusEneProcesso.numeroProcesso as processo',
          // 'SusEneProcesso.iidAno as iidAno',
          // 'SusEneProcesso.iidProc as iidProc',
          'SusEneLaboratorio.designacao as laboratorio',
          // 'SusEneLaboratorioSubarea.designacao as subarea',
          'SusEneCliente.nome as cliente',
          'SusEneProcesso.percExecucao as execucao',
          'SusEneProcesso.ultimaAlteracao as ultimaAlteracao',
          'Funcionario.name as atribuidoa',
          'SusEneProcesso.artigo as artigo',
          'SusEneProcesso.norma as norma',
          // 'SusEneProcesso.natureza as natureza',
          // 'SusEneProcessoCaracteristica.value as caracteristica',
          'SusEneEstadoProcesso.descricao as estado',
          // 'SusEneProcesso.acreditado as acreditado',
          // 'SusEneProcesso.certificado as certificado',
          'SusEneProcesso.inSitu as inSitu',
          'SusEneProcesso.subcontratacao as subcontratacao',
          'SusEneProcesso.email as email'
        ],
        from: ['SusEneProcesso', 'SusEneLaboratorio', 'SusEneOrdemintervencao', 'SusEneCliente', 'SusEneLaboratorioSubarea', 'Funcionario', 'SusEneProcessoCaracteristica', 'SusEneEstadoProcesso'],
        referencesOrigin: [],
        references: [undefined, 'SusEneProcesso.laboratorioId', 'SusEneProcesso.oiId', 'SusEneOrdemintervencao.clienteId', 'SusEneProcesso.subareaId', 'SusEneProcesso.funcionarioId', 'SusEneProcesso.caracteristicaId', 'SusEneProcesso.estadoId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'processosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
    });
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.processos, x => x.selected), x => {
        return { key: 'SusEneProcesso.id', value: x.id };
      });
    let whereFields = this.setWhereFields(this.customFilters, { 'SusEneProcesso.active': 1 }, orsLiteral);
    this.SusEneProcesso.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'SusEneProcesso.id as id',
          'SusEneProcesso.numeroProcesso as processo',
          // 'SusEneProcesso.iidAno as iidAno',
          // 'SusEneProcesso.iidProc as iidProc',
          'SusEneLaboratorio.designacao as laboratorio',
          // 'SusEneLaboratorioSubarea.designacao as subarea',
          'SusEneCliente.nome as cliente',
          'SusEneProcesso.percExecucao as execucao',
          'SusEneProcesso.ultimaAlteracao as ultimaAlteracao',
          'Funcionario.name as atribuidoa',
          'SusEneProcesso.artigo as artigo',
          'SusEneProcesso.norma as norma',
          // 'SusEneProcesso.natureza as natureza',
          // 'SusEneProcessoCaracteristica.value as caracteristica',
          'SusEneEstadoProcesso.descricao as estado',
          // 'SusEneProcesso.acreditado as acreditado',
          // 'SusEneProcesso.certificado as certificado',
          'SusEneProcesso.inSitu as inSitu',
          'SusEneProcesso.subcontratacao as subcontratacao',
          'SusEneProcesso.email as email'
        ],
        from: ['SusEneProcesso', 'SusEneLaboratorio', 'SusEneOrdemintervencao', 'SusEneCliente', 'SusEneLaboratorioSubarea', 'Funcionario', 'SusEneProcessoCaracteristica', 'SusEneEstadoProcesso'],
        referencesOrigin: [],
        references: [undefined, 'SusEneProcesso.laboratorioId', 'SusEneProcesso.oiId', 'SusEneOrdemintervencao.clienteId', 'SusEneProcesso.subareaId', 'SusEneProcesso.funcionarioId', 'SusEneProcesso.caracteristicaId', 'SusEneProcesso.estadoId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'processosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
    });
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('SUSENEGestaoProcessosDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  loadData = () => {
    this.SusEneLaboratorio.find({filter: {where: {active: true}, order: 'designacao ASC'}}).$promise.then(labs => {
      labs.forEach(t => {
        t.name = t.designacao;
      });
      this.laboratorios = labs;
      this.filtersLoaded[1] = 1;
    }).catch(e => {
      console.log(e);
      this.laboratorios = [];
      this.filtersLoaded[1] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Laboratórios).");
    });

    // this.SusEneLaboratorioSubarea.find({filter: {where: {active: true}, order: 'designacao ASC'}}).$promise.then(subareas => {
    //   subareas.forEach(sb => {
    //     sb.name = sb.designacao;
    //   });
    //   this.subareas = subareas;
      this.filtersLoaded[2] = 1;
    // }).catch(e => {
    //   console.log(e);
    //   this.subareas = [];
    //   this.filtersLoaded[2] = 1;
    //   this.UI.addToast("Erro de carregamento de dados para filtragem (Subáreas).");
    // });

    this.SusEneCliente.find({filter: {where: {active: true}, order: 'nome ASC'}}).$promise.then(clientes => {
      clientes.forEach(t => {
        t.name = t.nome;
      });
      this.clientes = clientes;
      this.filtersLoaded[3] = 1;
    }).catch(e => {
      console.log(e);
      this.clientes = [];
      this.filtersLoaded[3] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Clientes).");
    });

    this.SusEneProcesso.getColaboradoresProcessos().$promise.then((tecnicosSUSENE) => {
      this.tecnicosSUSENE = tecnicosSUSENE;
      this.filtersLoaded[4] = 1;
    }).catch(e => {
      console.log(e);
      this.tecnicosSUSENE = [];
      this.filtersLoaded[4] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Técnicos).");
    });

    // this.SusEneProcessoCaracteristica.find({filter: {where: {active: true}, order: 'value ASC'}}).$promise.then(caracteristicas => {
    //   caracteristicas.forEach(c => {
    //     c.name = c.value;
    //   });
    //   this.caracteristicas = caracteristicas;
      this.filtersLoaded[5] = 1;
    // }).catch(e => {
    //   console.log(e);
    //   this.caracteristicas = [];
    //   this.filtersLoaded[5] = 1;
    //   this.UI.addToast("Erro de carregamento de dados para filtragem (Características).");
    // });

    this.SusEneEstadoProcesso.find({filter: {where: {active: true}}}).$promise.then((estados) => {
      estados.forEach(e => {
        e.name = e.descricao;
      });
      this.estados = estados;
      this.filtersLoaded[6] = 1;
    }).catch(e => {
      console.log(e);
      this.estados = [];
      this.filtersLoaded[6] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Estados).");
    });
  };


  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.susene.processes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.susene.processes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.susene.processes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.laboratorios.length && !this.subareas.length &&
      !this.clientes.length && !this.tecnicosSUSENE.length && !this.caracteristicas.length &&
      !this.estados.length && !this.certificados.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'SusEneProcesso.laboratorioId')
        f.list = this.laboratorios;
      if (f.id === 'SusEneProcesso.subareaId')
        f.list = this.subareas;
      if (f.id === 'SusEneOrdemintervencao.clienteId')
        f.list = this.clientes;
      if (f.id === 'SusEneProcesso.funcionarioId')
        f.list = this.tecnicosSUSENE;
      if (f.id === 'SusEneProcesso.caracteristicaId')
        f.list = this.caracteristicas;
      if (f.id === 'SusEneProcesso.estadoId')
        f.list = this.estados;
      if (f.id === 'SusEneProcesso.acreditado')
        f.list = this.acreditados;
      if (f.id === 'SusEneProcesso.certificado')
        f.list = this.certificados;
      if (f.id === 'SusEneProcesso.inSitu')
        f.list = this.inSitus;
      if (f.id === 'SusEneProcesso.subcontratacao')
        f.list = this.subcontratacoes;

    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'SusEneProcesso.laboratorioId')
          f.column.selected.list = this.laboratorios;
        if (f.column.selected.id === 'SusEneProcesso.subareaId')
          f.column.selected.list = this.subareas;
        if (f.column.selected.id === 'SusEneOrdemintervencao.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'SusEneProcesso.funcionarioId')
          f.column.selected.list = this.tecnicosSUSENE;
        if (f.column.selected.id === 'SusEneProcesso.caracteristicaId')
          f.column.selected.list = this.caracteristicas;
        if (f.column.selected.id === 'SusEneProcesso.estadoId')
          f.column.selected.list = this.estados;
        if (f.column.selected.id === 'SusEneProcesso.acreditado')
          f.column.selected.list = this.acreditados;
        if (f.column.selected.id === 'SusEneProcesso.certificado')
          f.column.selected.list = this.certificados;
        if (f.column.selected.id === 'SusEneProcesso.inSitu')
          f.column.selected.list = this.inSitus;
        if (f.column.selected.id === 'SusEneProcesso.subcontratacao')
          f.column.selected.list = this.subcontratacoes;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('SUSENEGestaoProcessosFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.susene.processes.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getProcessos();
  };

  hasSelect = () => {
    return _.some(this.processos, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.processos.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.processos.length;
    } else { // Remove all selections
      this.processos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.processos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.processos, r => r.selected).length;
    this.allSelected = this.nSelected >= this.processos.length;
    this.everythingSelected = false;
  };

  viewProcess = r => {
    this.$state.go('app.susene.processes.details', {
      id: this.$filter('suseneFormat')(r)
    });
  };

  // Change state for amostras
  changeStateSelected = () => {
    let processoIds = [];
    if (this.everythingSelected) {
      this.UI.addToast("Não é possível aplicar alterações a todos os processos");
      return;
    }
    // if a few processes selected only
    if (this.hasSelect()) {
      processoIds = this.processos.filter(x => x.selected).map(x => { return { id: x.id, tipoId: x.tipoId, estadoId: x.estadoId, empresaId: x.empresaId } });
      // Check if all have the same Estado
      if (processoIds.some(x => x.estadoId !== processoIds[0].estadoId)) { // Reject changing different origins
        this.UI.showAlert("Alteração em massa de estado obriga a que todas os processos selecionados tenham que ter o mesmo estado inicial. Por favor escolha processos com o mesmo estado inicial.");
      } else {
        this.SusEneProcessoService.changeStateMultiple(processoIds, {tipoId: processoIds[0].tipoId, estadoId: processoIds[0].estadoId, empresaId: processoIds[0].empresaId}).then(() => {
          this.getProcessos();
        }).catch(() => {
          this.getProcessos();
        });
      }
    } else {
      this.UI.addToast("É necessário selecionar alguns processos para alterar estado");
    }
  };

  // If id, it's single, if nothing it's multiple and go through "selected"
  confirmRemoveProcessos = (id) => {
    let ids;
    if (id) ids = [id];
    else ids = this.processos.map(x => {if (x.selected) return x.id}).filter(x => x);
    if (ids.length === 0) return;
    if (ids.length > 20) {
      this.UI.showAlert("Não é possível remover mais de 20 processos ao mesmo tempo.");
    } else {
      let plural = ids.length === 1 ? ` processo?` : `s ${ids.length} processos?`;
      let confirm = this.UI.showConfirm("Tem a certeza que pretende remover este" + plural);
      confirm.then(() => {
        let wait = this.UI.showWaiting();
        this.SusEneProcessoService.removeProcessos(ids).then(status => {
          wait.close();

          let errors = status.filter(x => x.error);
          if (errors.length > 0) {
            let title = "Erros de Remoção";
            let introText = "Ocorreram os seguintes erros na remoção de processos:";
            let instance = this.UI.showDialog({
              size: 'md',
              template: require('../../../interface/modals/show-list.html'),
              controller: ['$scope', ($scope) => {
                $scope.title = title;
                $scope.introText = introText;
                $scope.list = errors;
                $scope.ok = function () {
                  $scope.$close();
                };
              }]
            });
            instance.finally(() => {
              this.getProcessos();
            });
          } else {
            if (ids.length === 1)
              this.UI.addToast("Processo removido com sucesso");
            else
              this.UI.addToast("Processos removidos com sucesso");
            this.getProcessos();
          }
        }).catch(error => {
          wait.close();
          console.log(error);
          this.UI.showAlert("Erro na eliminação de processos. Verifique a ligação");
        });
      });
    }
  };
}

SusEneDirectorySchedulingController.$inject = ['$filter', '$state', 'AuthenticationService', 'Group', 'SusEneProcesso', 'SusEneProcessoCaracteristica', 'SusEneLaboratorio', 'SusEneLaboratorioSubarea', 'Funcionario', 'SusEneCliente', 'SusEneEstadoProcesso', 'UIService', 'SusEneProcessoService'];
