import UIService from "../../interface/UI.service";

export default class AtvAdminController {
  constructor($q, AuthenticationService, AtvPartesCorpo, AtvTamanhoTipo, AtvTamanho, AtvEstadoCompra, AtvResultado, AtvInteracaoTipo, AtvDocumentoTipo, AtvAtivoPrefixo, AtvAtivoPartesCorpo, AtvAtivo, AtvConfig, UIService) {
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.AtvPartesCorpo = AtvPartesCorpo;
    this.AtvTamanhoTipo = AtvTamanhoTipo;
    this.AtvEstadoCompra = AtvEstadoCompra;
    // this.AtvEstadoAtivo = AtvEstadoAtivo;
    this.AtvResultado = AtvResultado;
    this.AtvInteracaoTipo = AtvInteracaoTipo;
    this.AtvDocumentoTipo = AtvDocumentoTipo;
    // this.AtvAtivoPrefixo = AtvAtivoPrefixo;
    this.AtvAtivoPartesCorpo = AtvAtivoPartesCorpo;
    this.AtvAtivo = AtvAtivo;
    this.AtvConfig = AtvConfig;
    this.AtvTamanho = AtvTamanho;
    this.partesCorpoLoading = true;
    this.tamanhosLoading = false;
    this.estadosLoading = false;
    this.resultadosLoading = false;
    this.documentosTipoLoading = false;
    // this.prefixosLoading = false;
    this.configLoading = false; // Empréstimos
    this.UI = UIService;
    this.partesCorpo = null;
    this.tamanhos = null;
    this.estados = null;
    this.resultados = null;
    this.documentosTipo = null;
    // this.prefixos = null;
    this.config = null; // Empréstimos
    this.showPartesCorpoFab = false;
    this.showTamanhosFab = false;
    this.showEstadosFab = false;
    this.showResultadosFab = false;
    this.showDocumentosTipoFab = false;
    // this.showPrefixosFab = false;
    this.showConfigFab = false;
    this.getPartesCorpo();
    this.getTamanhos();
    this.getEstados();
    this.getResultados();
    this.getDocumentosTipo();
    // this.getPrefixos();
    this.getConfig();

    this.partesCorpoOptions = {
      containment: 'parent',
      tolerance: 'pointer',
      handle: '.pmd-move-handle-partes-corpo',
      'ui-floating': true,
      stop: (e, ui) => {
        for (let i = 0; i < this.partesCorpo.length; i++) {
          this.partesCorpo[i].ordem = i + 1;
          this.AtvPartesCorpo.upsert(this.partesCorpo[i]).$promise.then((res) => {
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não foi possível reordenar partes do corpo. Por favor tente mais tarde");
            this.getPartesCorpo();
          });
        }
      }
    };
    this.documentosTipoOptions = {
      containment: 'parent',
      tolerance: 'pointer',
      handle: '.pmd-move-handle-documento-tipo',
      'ui-floating': true,
      stop: (e, ui) => {
        for (let i = 0; i < this.documentosTipo.length; i++) {
          this.documentosTipo[i].ordem = i + 1;
          this.AtvDocumentoTipo.upsert(this.documentosTipo[i]).$promise.then((res) => {
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não foi possível reordenar tipos de documentos. Por favor tente mais tarde");
            this.getDocumentosTipo();
          });
        }
      }
    };
    // this.prefixosOptions = {
    //   containment: 'parent',
    //   tolerance: 'pointer',
    //   handle: '.pmd-move-handle-prefixos',
    //   'ui-floating': true,
    //   stop: (e, ui) => {
    //     for (let i = 0; i < this.prefixos.length; i++) {
    //       this.prefixos[i].ordem = i + 1;
    //       this.AtvAtivoPrefixo.upsert(this.prefixos[i]).$promise.then((res) => {
    //       }).catch((error) => {
    //         console.log(error);
    //         this.UI.addToast("Não foi possível reordenar prefixos. Por favor tente mais tarde");
    //         this.getPrefixos();
    //       });
    //     }
    //   }
    // };
  }

  updateFab = (f) => {
    if (f === 'partesCorpo') { // Partes Corpo Fab
      this.showPartesCorpoFab = true;
      this.showTamanhosFab = false;
      this.showEstadosFab = false;
      this.showResultadosFab = false;
      this.showDocumentosTipoFab = false;
      // this.showPrefixosFab = false;
      this.showConfigFab = false;
    } else if (f === 'tamanhos') { // Tamanhos Fab
      this.showPartesCorpoFab = false;
      this.showTamanhosFab = true;
      this.showEstadosFab = false;
      this.showResultadosFab = false;
      this.showDocumentosTipoFab = false;
      // this.showPrefixosFab = false;
      this.showConfigFab = false;
    } else if (f === 'estados') { // Estados tab
      this.showPartesCorpoFab = false;
      this.showTamanhosFab = false;
      this.showEstadosFab = true;
      this.showResultadosFab = false;
      this.showDocumentosTipoFab = false;
      // this.showPrefixosFab = false;
      this.showConfigFab = false;
    } else if (f === 'resultados') { // Resultados tab
      this.showPartesCorpoFab = false;
      this.showTamanhosFab = false;
      this.showEstadosFab = false;
      this.showResultadosFab = true;
      this.showDocumentosTipoFab = false;
      // this.showPrefixosFab = false;
      this.showConfigFab = false;
    } else if (f === 'documentosTipo') { // DocumentosTipo tab
      this.showPartesCorpoFab = false;
      this.showTamanhosFab = false;
      this.showEstadosFab = false;
      this.showResultadosFab = false;
      this.showDocumentosTipoFab = true;
      // this.showPrefixosFab = false;
      this.showConfigFab = false;
    // } else if (f === 'prefixos') { // Prefixos tab
    //   this.showPartesCorpoFab = false;
    //   this.showTamanhosFab = false;
    //   this.showEstadosFab = false;
    //   this.showResultadosFab = false;
    //   this.showDocumentosTipoFab = false;
    //   this.showPrefixosFab = true;
    //   this.showConfigFab = false;
    } else if (f === 'emprestimos') { // Emprestimos tab
      this.showPartesCorpoFab = false;
      this.showTamanhosFab = false;
      this.showEstadosFab = false;
      this.showResultadosFab = false;
      this.showDocumentosTipoFab = false;
      // this.showPrefixosFab = false;
      this.showConfigFab = true;
    }
  };

  getPartesCorpo = () => {
    this.partesCorpoLoading = true;
    this.AtvPartesCorpo.find({
      filter: {
        where: {
          active: 1
        },
        order: 'ordem ASC'
      }
    }).$promise.then((partes) => {
      this.partesCorpo = partes;
      this.partesCorpoLoading = false;
    }).catch((error) => {
      console.log(error);
    })
  };

  addParteCorpo = () => {
    let instance = this.UI.showDialog({
      size: 'md',
      template: require('./add.partescorpo.dialog.html'),
      controller: ['$scope', function ($scope) {
        $scope.parteCorpo = {};
        $scope.parteCorpo.id = 0;
        $scope.label = "Adicionar Parte de Corpo";

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.partesCorpoLoading = true;
      // Check if part already exists with that designacao
      this.AtvPartesCorpo.find({
        filter: {
          where: {
            designacao: ok.parteCorpo.designacao,
            active: 1
          }
        }
      }).$promise.then((res) => {
        if (res && res.length > 0) {
          this.getPartesCorpo();
          this.UI.addToast("Parte do corpo já existe.");
        } else {
          this.AtvPartesCorpo.count({
            where: {
              active: 1
            }
          }).$promise.then((n) => {
            ok.parteCorpo.ordem = n.count + 1;
            this.AtvPartesCorpo.create(ok.parteCorpo).$promise.then(r => {
              this.getPartesCorpo();
              this.UI.addToast("Parte do corpo inserida com sucesso.");
            }).catch(error => {
              console.log(error);
              this.partesCorpoLoading = false;
              this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
            });
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro na criação. Por favor tente mais tarde");
          })
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível inserir parte do corpo");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editParteCorpo = (a) => {
    let options = {
      size: 'md',
      template: require('./add.partescorpo.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.parteCorpo = angular.copy(a);
        $scope.label = "Editar Parte do Corpo";

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((ok) => {
      this.partesCorpoLoading = true;
      this.AtvPartesCorpo.findOne({
        filter: {
          where: {
            id: ok.parteCorpo.id,
            active: 1
          }
        }
      }).$promise.then((pc) => {
        this.AtvPartesCorpo.find({
          filter: {
            where: {
              designacao: ok.parteCorpo.designacao,
              active: 1
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            this.UI.addToast("Parte do corpo já existe.");
            this.getPartesCorpo();
          } else {
            pc.designacao = ok.parteCorpo.designacao;
            pc.$save().then((res) => {
              this.UI.addToast('Parte do corpo alterada com sucesso');
              this.getPartesCorpo();
            }).catch((error) => {
              console.log(error);
              this.UI.addToast('Ocorreu um erro ao editar parte do corpo');
              this.getPartesCorpo();
            });
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro ao editar parte do corpo');
          this.getPartesCorpo();
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast('Parte do corpo não encontrada');
        this.getPartesCorpo();
      });
    })
  };

  checkDeleteParteCorpo = (f) => {
    let cenas = this.UI.showWaiting();
    this.AtvPartesCorpo.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((parteCorpoToRemove) => {
      // Find if we have something that might block deletion
      this.AtvAtivoPartesCorpo.count({
        fields: {id: true, ativoId: true, partesCorpoId: true, active: true},
        where: {
          partesCorpoId: parteCorpoToRemove.id,
          active: 1
        }
      }).$promise.then((ativos) => {
        cenas.close();
        if (ativos.count > 0) {
          let msg = "Não é possível remover parte do corpo.\nExiste" + ((ativos.count > 1) ? "m " : " ");
          msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")) : "";
          msg += "de proteção desta parte do corpo.";
          this.UI.showAlert(msg);
        } else {
          this.confirmDeleteParteCorpo(f);
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("De momento não é possível remover parte do corpo. Por favor tente mais tarde");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("De momento não é possível remover parte do corpo. Por favor tente mais tarde");
    });
  };

  confirmDeleteParteCorpo = (i) => {
    let title = "Eliminar Parte do Corpo";
    let warning = "Tem a certeza que pretende remover esta parte do corpo?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeParteCorpo(i);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeParteCorpo = (s) => {
    this.partesCorpoLoading = true;
    // Find again parte do corpo to delete so we can update it
    this.AtvPartesCorpo.findOne({
      filter: {
        where: {
          id: s.id,
          active: 1
        }
      }
    }).$promise.then((parteCorpoToRemove) => {
      parteCorpoToRemove.active = 0;
      parteCorpoToRemove.$save().then((res) => {
        this.AtvPartesCorpo.find({
          filter: {
            where: {
              active: 1
            },
            order: 'ordem ASC'
          }
        }).$promise.then((partes) => {
          let tasks = [];
          for (let i = 0; i < partes.length; i++) {
            partes[i].ordem = i + 1;
            let defer = this.AtvPartesCorpo.upsert(partes[i]).$promise;
            tasks.push(defer.promise);
          }
          this.$q.all(tasks).then((res) => {
            this.UI.addToast("Parte do corpo eliminada com sucesso!");
            this.getPartesCorpo();
          }).catch(err => {
            console.log(err);
          });
        }).catch((error) => {
          console.log(error);
        });
      }, (error) => {
        console.log(error);
        this.getPartesCorpo();
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.getPartesCorpo();
      this.UI.addToast("Não foi possível eliminar a parte do corpo. Verifique se ainda existe.");
    });
  };

  getTamanhos = () => {
    this.tamanhosLoading = true;
    this.AtvTamanhoTipo.find({
      filter: {
        where: {
          active: 1
        },
        order: 'id ASC'
      }
    }).$promise.then((tipos) => {
      this.AtvTamanho.find({
        filter: {
          where: {
            active: 1
          },
          order: 'tamanhoTipoId ASC, ordem ASC'
        }
      }).$promise.then((res) => {
        this.tamanhos = _.chain(res).groupBy(t => t.tamanhoTipoId).map((g,d) => {
          return {
            options: {
              containment: 'parent',
              tolerance: 'pointer',
              handle: '.pmd-move-handle-tamanho-' + d,
              'ui-floating': true,
              stop: (e, ui) => {
                let t = this.tamanhos.find(t => t.tipoId == d);
                for (let i = 0; i < t.values.length; i++) {
                  t.values[i].ordem = i + 1;
                  this.AtvTamanho.upsert(t.values[i]).$promise.then((res) => {
                  }).catch((error) => {
                    console.log(error);
                    this.UI.addToast("Não foi possível reordenar tamanhos. Por favor tente mais tarde");
                    this.getTamanhos();
                  });
                }
              }
            },
            tipoId: d,
            title: tipos.find(tipo => tipo.id == d).designacao, // Do not change == to ===
            values: g
          };
        }).value();
        this.tamanhosLoading = false;
      }).catch((error) => {
        console.log(error);
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível carregar os tamanhos. Por favor tente mais tarde");
    });
  };

  addTamanho = (tipoId) => {
    let cenas = this.UI.showWaiting();
    let instance = this.UI.showDialog({
      size: 'md',
      template: require('./add.tamanho.dialog.html'),
      controller: ['$scope', 'AtvTamanhoTipo', function ($scope, AtvTamanhoTipo) {
        $scope.tamanho = {};
        $scope.tamanho.id = 0;
        $scope.label = "Adicionar Tamanho";
        $scope.tipos = [];
        $scope.auxTipo = {selected: null};

        AtvTamanhoTipo.find({
          filter: {
            where: {
              active: 1
            }
          }
        }).$promise.then((t) => {
          $scope.tipos = t;
          $scope.chosenType = $scope.tipos.find(t => t.id == tipoId);
          $scope.chooseType = $scope.chosenType === undefined;
          if (!$scope.chooseType) {
            $scope.label += " - " + $scope.chosenType.designacao;
          }
          cenas.close();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não é possível adicionar tamanhos. Por favor tente mais tarde");
        });

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.tamanhosLoading = true;
      ok.tamanho.tamanhoTipoId = ok.chooseType ? ok.auxTipo.selected.id : ok.chosenType.id;

      // Check if part already exists with that designacao
      this.AtvTamanho.find({
        filter: {
          where: {
            tamanho: ok.tamanho.tamanho,
            tamanhoTipoId: ok.tamanho.tamanhoTipoId,
            active: 1
          }
        }
      }).$promise.then((res) => {
        if (res && res.length > 0) {
          this.getTamanhos();
          this.UI.addToast("Tamanho já existe.");
        } else {
          this.AtvTamanho.count({
            where: {
              tamanhoTipoId: ok.tamanho.tamanhoTipoId,
              active: 1
            }
          }).$promise.then((n) => {
            ok.tamanho.ordem = n.count + 1;
            this.AtvTamanho.create(ok.tamanho).$promise.then(r => {
              this.getTamanhos();
              this.UI.addToast("Tamanho inserido com sucesso.");
            }).catch(error => {
              console.log(error);
              this.tamanhosLoading = false;
              this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
            });
          }).catch((error) => {
            this.tamanhosLoading = false;
            console.log(error);
            this.UI.addToast("Erro na criação. Por favor tente mais tarde");
          })
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível inserir tamanho");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editTamanho = (a) => {
    let options = {
      size: 'md',
      template: require('./add.tamanho.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.tamanho = angular.copy(a);
        $scope.chooseType = false;
        $scope.label = "Editar Tamanho";

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((ok) => {
      this.tamanhosLoading = true;
      this.AtvTamanho.findOne({
        filter: {
          where: {
            id: ok.tamanho.id,
            active: 1
          }
        }
      }).$promise.then((t) => {
        this.AtvTamanho.find({
          filter: {
            where: {
              tamanho: ok.tamanho.tamanho,
              tamanhoTipoId: ok.tamanho.tamanhoTipoId,
              active: 1
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            this.UI.addToast("Tamanho já existe.");
            this.getTamanhos();
          } else {
            t.tamanho = ok.tamanho.tamanho;
            t.$save().then((res) => {
              this.UI.addToast('Tamanho alterado com sucesso');
              this.getTamanhos();
            }).catch((error) => {
              console.log(error);
              this.UI.addToast('Ocorreu um erro ao editar tamanho');
              this.getTamanhos();
            });
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro ao editar tamanho');
          this.getTamanhos();
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast('Tamanho não encontrado');
        this.getTamanhos();
      });
    })
  };

  checkDeleteTamanho = (f) => {
    let cenas = this.UI.showWaiting();
    this.AtvTamanho.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((tamanhoToRemove) => {
      // Find if we have something that might block deletion
      this.AtvAtivo.count({
        fields: {id: true, tamanhoId: true, active: true},
        where: {
          tamanhoId: tamanhoToRemove.id,
          active: 1
        }
      }).$promise.then((ativos) => {
        cenas.close();
        if (ativos.count > 0) {
          let msg = "Não é possível remover tamanho.\nExiste" + ((ativos.count > 1) ? "m " : " ");
          msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")) : "";
          msg += "deste tamanho.";
          this.UI.showAlert(msg);
        } else {
          this.confirmDeleteTamanho(f);
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("De momento não é possível remover tamanho. Por favor tente mais tarde");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("De momento não é possível remover tamanho. Por favor tente mais tarde");
    });
  };

  confirmDeleteTamanho = (f) => {
    let title = "Eliminar Tamanho";
    let warning = "Tem a certeza que pretende remover este tamanho?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeTamanho(f);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeTamanho = (f) => {
    this.tamanhosLoading = true;
    // Find again tamanho to delete so we can update it
    this.AtvTamanho.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((tamanhoToRemove) => {
      tamanhoToRemove.active = 0;
      tamanhoToRemove.$save().then((res) => {
        this.AtvTamanho.find({
          filter: {
            where: {
              tamanhoTipoId: f.tamanhoTipoId,
              active: 1
            },
            order: 'ordem ASC'
          }
        }).$promise.then((tams) => {
          let tasks = [];
          for (let i = 0; i < tams.length; i++) {
            tams[i].ordem = i + 1;
            let defer = this.AtvTamanho.upsert(tams[i]).$promise;
            tasks.push(defer.promise);
          }
          this.$q.all(tasks).then((res) => {
            this.UI.addToast("Tamanho eliminado com sucesso!");
            this.getTamanhos();
          }).catch(err => {
            console.log(err);
            this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
            this.getTamanhos();
          });
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
          this.getTamanhos();
        });
      }, (error) => {
        console.log(error);
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
        this.getTamanhos();
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível eliminar o tamanho. Verifique se ainda existe.");
      this.getTamanhos();
    });
  };

  // Adapted from Tamanhos, but using different tables instead of one with foreignKey.
  // To copy and use elsewhere, probably use Tamanhos instead.
  getEstados = () => {
    this.estadosLoading = true;
    // this.AtvEstadoAtivo.find({
    //   filter: {
    //     where: {
    //       active: 1
    //     },
    //     order: 'ordem ASC'
    //   }
    // }).$promise.then((estadosAtivo) => {
      this.AtvEstadoCompra.find({
        filter: {
          where: {
            active: 1
          },
          order: 'ordem ASC'
        }
      }).$promise.then((estadosCompra) => {
        this.estados = [];

        // Add Estados Ativos
        // this.estados.push({
        //   options: {
        //     containment: 'parent',
        //     tolerance: 'pointer',
        //     handle: '.pmd-move-handle-estado-ativos',
        //     'ui-floating': true,
        //     stop: (e, ui) => {
        //       let t = this.estados[0];
        //       for (let i = 0; i < t.values.length; i++) {
        //         t.values[i].ordem = i + 1;
        //         this.AtvEstadoAtivo.upsert(t.values[i]).$promise.then((res) => {
        //         }).catch((error) => {
        //           console.log(error);
        //           this.UI.addToast("Não foi possível reordenar estados. Por favor tente mais tarde");
        //           this.getEstados();
        //         });
        //       }
        //     }
        //   },
        //   tipoId: 'ativos',
        //   title: 'Estados de Ativo',
        //   values: estadosAtivo
        // });
        // Add Estados Compra
        this.estados.push({
          options: {
            containment: 'parent',
            tolerance: 'pointer',
            handle: '.pmd-move-handle-estado-compra',
            'ui-floating': true,
            stop: (e, ui) => {
              let t = this.estados[1];
              for (let i = 0; i < t.values.length; i++) {
                t.values[i].ordem = i + 1;
                this.AtvEstadoCompra.upsert(t.values[i]).$promise.then((res) => {
                }).catch((error) => {
                  console.log(error);
                  this.UI.addToast("Não foi possível reordenar estados. Por favor tente mais tarde");
                  this.getEstados();
                });
              }
            }
          },
          tipoId: 'compra',
          title: 'Estados de Compra',
          values: estadosCompra
        });
        this.estadosLoading = false;
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível carregar os estados de compra. Por favor tente mais tarde");
      });
    // }).catch((error) => {
    //   console.log(error);
    //   this.UI.addToast("Não foi possível carregar os estados de ativos. Por favor tente mais tarde");
    // });
  };

  addEstado = (tipoId) => {
    let instance = this.UI.showDialog({
      size: 'md',
      template: require('./add.estado.dialog.html'),
      controller: ['$scope', function ($scope) {
        $scope.estado = {};
        $scope.estado.id = 0;
        $scope.label = "Adicionar Estado";
        $scope.auxTipo = {selected: null};

        $scope.outputEstado = function (tipo) {
          switch (tipo) {
            // case 'ativos':
            //   return ' de Ativos';
            case 'compra':
              return ' de Compra';
          }
        };
        $scope.tipos = [{
        //   id: 1,
        //   tipoId: 'ativos',
        //   designacao: 'Estado de Ativos'
        // }, {
          id: 2,
          tipoId: 'compra',
          designacao: 'Estado de Compra'
        }];

        $scope.chooseType = tipoId === undefined;
        if (!$scope.chooseType) {
          $scope.label += $scope.outputEstado(tipoId);
        }

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.estadosLoading = true;
      let Estado;
      if (tipoId === 'compra' || (ok.auxTipo.selected && ok.auxTipo.selected.tipoId === 'compra')) {
        Estado = this.AtvEstadoCompra;
      } else {
        // if (tipoId === 'ativos' || (ok.auxTipo.selected && ok.auxTipo.selected.tipoId === 'ativos')) {
        //   Estado = this.AtvEstadoAtivo;
        // }
      }
      // If Estado is undefined, don't add
      if (!Estado) {
        this.UI.addToast("Não foi possível adicionar estado.");
        this.getEstados();
        return;
      }

      // Check if part already exists with that designacao
      Estado.find({
        filter: {
          where: {
            designacao: ok.estado.designacao,
            active: 1
          }
        }
      }).$promise.then((res) => {
        if (res && res.length > 0) {
          this.getEstados();
          this.UI.addToast("Estado já existe.");
        } else {
          Estado.count({
            where: {
              active: 1
            }
          }).$promise.then((n) => {
            ok.estado.ordem = n.count + 1;
            Estado.create(ok.estado).$promise.then(r => {
              this.getEstados();
              this.UI.addToast("Estado inserido com sucesso.");
            }).catch(error => {
              console.log(error);
              this.estadosLoading = false;
              this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
            });
          }).catch((error) => {
            this.estadosLoading = false;
            console.log(error);
            this.UI.addToast("Erro na criação. Por favor tente mais tarde");
          })
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível inserir estado");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editEstado = (a, tipoId) => {
    let options = {
      size: 'md',
      template: require('./add.estado.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.estado = angular.copy(a);
        $scope.chooseType = false;
        $scope.label = "Editar Estado";

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((ok) => {
      this.estadosLoading = true;
      let Estado;
      if (tipoId === 'compra') {
        Estado = this.AtvEstadoCompra;
      } else {
        // if (tipoId === 'ativos') {
        //   Estado = this.AtvEstadoAtivo;
        // }
      }
      // If Estado is undefined, don't edit
      if (!Estado) {
        this.UI.addToast("Não foi possível editar estado.");
        this.getEstados();
        return;
      }

      Estado.findOne({
        filter: {
          where: {
            id: ok.estado.id,
            active: 1
          }
        }
      }).$promise.then((t) => {
        Estado.find({
          filter: {
            where: {
              designacao: ok.estado.designacao,
              active: 1
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            this.UI.addToast("Estado já existe.");
            this.getEstados();
          } else {
            t.designacao = ok.estado.designacao;
            t.$save().then((res) => {
              this.UI.addToast('Estado alterado com sucesso');
              this.getEstados();
            }).catch((error) => {
              console.log(error);
              this.UI.addToast('Ocorreu um erro ao editar estado');
              this.getEstados();
            });
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro ao editar estado');
          this.getEstados();
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast('Estado não encontrado');
        this.getEstados();
      });
    })
  };

  checkDeleteEstado = (f, tipoId) => {
    let cenas = this.UI.showWaiting();
    let Estado;
    if (tipoId === 'compra') {
      Estado = this.AtvEstadoCompra;
    } else {
      // if (tipoId === 'ativos') {
      //   Estado = this.AtvEstadoAtivo;
      // }
    }
    Estado.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((estadoToRemove) => {
      // Find if we have something that might block deletion
      let whereCount = {active: 1};
      if (tipoId === 'compra') whereCount['estadoCompraId'] = estadoToRemove.id;
      // if (tipoId === 'ativos') whereCount['estadoAtivoId'] = estadoToRemove.id;
      this.AtvAtivo.count({
        fields: {id: true, estadoCompraId: true, active: true},
        where: whereCount
      }).$promise.then((ativos) => {
        cenas.close();
        if (ativos.count > 0) {
          let msg = "Não é possível remover estado.\nExiste" + ((ativos.count > 1) ? "m " : " ");
          msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")) : "";
          msg += "com este estado.";
          this.UI.showAlert(msg);
        } else {
          this.confirmDeleteEstado(f, tipoId);
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("De momento não é possível remover estado. Por favor tente mais tarde");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("De momento não é possível remover estado. Por favor tente mais tarde");
    });
  };

  confirmDeleteEstado = (f, tipoId) => {
    let title = "Eliminar Estado";
    let warning = "Tem a certeza que pretende remover este estado?\n Podem existir ativos com este estado.";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeEstado(f, tipoId);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  removeEstado = (f, tipoId) => {
    this.estadosLoading = true;
    let Estado;
    if (tipoId === 'compra') {
      Estado = this.AtvEstadoCompra;
    } else {
      // if (tipoId === 'ativos') {
      //   Estado = this.AtvEstadoAtivo;
      // }
    }
    // If Estado is undefined, don't add
    if (!Estado) {
      this.UI.addToast("Não foi possível remover estado.");
      this.getEstados();
      return;
    }

    // Find again estado to delete so we can update it
    Estado.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((estadoToRemove) => {
      estadoToRemove.active = 0;
      estadoToRemove.$save().then((res) => {
        Estado.find({
          filter: {
            where: {
              active: 1
            },
            order: 'ordem ASC'
          }
        }).$promise.then((ests) => {
          let tasks = [];
          for (let i = 0; i < ests.length; i++) {
            ests[i].ordem = i + 1;
            let defer = Estado.upsert(ests[i]).$promise;
            tasks.push(defer.promise);
          }
          this.$q.all(tasks).then((res) => {
            this.UI.addToast("Estado eliminado com sucesso!");
            this.getEstados();
          }).catch(err => {
            console.log(err);
            this.UI.addToast('Ocorreu um erro ao eliminar estado');
            this.getEstados();
          });
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro ao editar estado');
          this.getEstados();
        });
      }, (error) => {
        console.log(error);
        this.loaded = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível eliminar o estado. Verifique se ainda existe.");
      this.getEstados();
    });
  };

  // Imported from Tamanhos
  getResultados = () => {
    this.resultadosLoading = true;
    this.AtvInteracaoTipo.find({
      filter: {
        where: {
          active: 1
        },
        order: 'id ASC'
      }
    }).$promise.then((tipos) => {
      this.AtvResultado.find({
        filter: {
          where: {
            active: 1
          },
          order: 'interacaoTipoId ASC, ordem ASC'
        }
      }).$promise.then((res) => {
        this.resultados = _.chain(res).groupBy(t => t.interacaoTipoId).map((g,d) => {
          return {
            options: {
              containment: 'parent',
              tolerance: 'pointer',
              handle: '.pmd-move-handle-resultado-' + d,
              'ui-floating': true,
              stop: (e, ui) => {
                let t = this.resultados.find(t => t.tipoId == d);
                for (let i = 0; i < t.values.length; i++) {
                  t.values[i].ordem = i + 1;
                  this.AtvResultado.upsert(t.values[i]).$promise.then((res) => {
                  }).catch((error) => {
                    console.log(error);
                    this.UI.addToast("Não foi possível reordenar resultados. Por favor tente mais tarde");
                    this.getResultados();
                  });
                }
              }
            },
            tipoId: d,
            title: tipos.find(tipo => tipo.id == d).designacao, // Do not change == to ===
            values: g
          };
        }).value();
        this.resultadosLoading = false;
      }).catch((error) => {
        console.log(error);
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível carregar os resultados. Por favor tente mais tarde");
    });
  };

  addResultado = (tipoId) => {
    let cenas = this.UI.showWaiting();
    let instance = this.UI.showDialog({
      size: 'md',
      template: require('./add.resultado.dialog.html'),
      controller: ['$scope', 'AtvInteracaoTipo', function ($scope, AtvInteracaoTipo) {
        $scope.resultado = {};
        $scope.resultado.id = 0;
        $scope.label = "Adicionar Resultado";
        $scope.tipos = [];
        $scope.auxTipo = {selected: null};

        AtvInteracaoTipo.find({
          filter: {
            where: {
              active: 1
            }
          }
        }).$promise.then((t) => {
          $scope.tipos = t;
          $scope.chosenType = $scope.tipos.find(t => t.id == tipoId);
          $scope.chooseType = $scope.chosenType === undefined;
          if (!$scope.chooseType) {
            $scope.label += " - " + $scope.chosenType.designacao;
          }
          cenas.close();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não é possível adicionar resultados. Por favor tente mais tarde");
        });

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.resultadosLoading = true;
      ok.resultado.interacaoTipoId = ok.chooseType ? ok.auxTipo.selected.id : ok.chosenType.id;

      // Check if part already exists with that designacao
      this.AtvResultado.find({
        filter: {
          where: {
            resultado: ok.resultado.resultado,
            interacaoTipoId: ok.resultado.interacaoTipoId,
            active: 1
          }
        }
      }).$promise.then((res) => {
        if (res && res.length > 0) {
          this.getResultados();
          this.UI.addToast("Resultado já existe.");
        } else {
          this.AtvResultado.count({
            where: {
              interacaoTipoId: ok.resultado.interacaoTipoId,
              active: 1
            }
          }).$promise.then((n) => {
            ok.resultado.ordem = n.count + 1;
            this.AtvResultado.create(ok.resultado).$promise.then(r => {
              this.getResultados();
              this.UI.addToast("Resultado inserido com sucesso.");
            }).catch(error => {
              console.log(error);
              this.resultadosLoading = false;
              this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
            });
          }).catch((error) => {
            this.resultadosLoading = false;
            console.log(error);
            this.UI.addToast("Erro na criação. Por favor tente mais tarde");
          })
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível inserir resultado");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editResultado = (a) => {
    let options = {
      size: 'md',
      template: require('./add.resultado.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.resultado = angular.copy(a);
        $scope.chooseType = false;
        $scope.label = "Editar Resultado";

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((ok) => {
      this.resultadosLoading = true;
      this.AtvResultado.findOne({
        filter: {
          where: {
            id: ok.resultado.id,
            active: 1
          }
        }
      }).$promise.then((t) => {
        this.AtvResultado.find({
          filter: {
            where: {
              resultado: ok.resultado.resultado,
              interacaoTipoId: ok.resultado.interacaoTipoId,
              active: 1
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            this.UI.addToast("Resultado já existe.");
            this.getResultados();
          } else {
            t.resultado = ok.resultado.resultado;
            t.$save().then((res) => {
              this.UI.addToast('Resultado alterado com sucesso');
              this.getResultados();
            }).catch((error) => {
              console.log(error);
              this.UI.addToast('Ocorreu um erro ao editar resultado');
              this.getResultados();
            });
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro ao editar resultado');
          this.getResultados();
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast('Resultado não encontrado');
        this.getResultados();
      });
    })
  };

  confirmDeleteResultado = (f) => {
    let title = "Eliminar Resultado";
    let warning = "Tem a certeza que pretende remover este resultado?\n Podem existir interações com este resultado.";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeResultado(f);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeResultado = (f) => {
    this.resultadosLoading = true;
    // Find again resultado to delete so we can update it
    this.AtvResultado.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((resultadoToRemove) => {
      resultadoToRemove.active = 0;
      resultadoToRemove.$save().then((res) => {
        this.AtvResultado.find({
          filter: {
            where: {
              interacaoTipoId: f.interacaoTipoId,
              active: 1
            },
            order: 'ordem ASC'
          }
        }).$promise.then((rs) => {
          let tasks = [];
          for (let i = 0; i < rs.length; i++) {
            rs[i].ordem = i + 1;
            let defer = this.AtvResultado.upsert(rs[i]).$promise;
            tasks.push(defer.promise);
          }
          this.$q.all(tasks).then((res) => {
            this.UI.addToast("Resultado eliminado com sucesso!");
            this.getResultados();
          }).catch(err => {
            console.log(err);
            this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
            this.getResultados();
          });
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
          this.getResultados();
        });
      }, (error) => {
        console.log(error);
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
        this.getResultados();
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível eliminar o resultado. Verifique se ainda existe.");
      this.getResultados();
    });
  };

  getDocumentosTipo = () => {
    this.documentosTipoLoading = true;
    this.AtvDocumentoTipo.find({
      filter: {
        where: {
          active: 1
        },
        order: 'ordem ASC'
      }
    }).$promise.then((tipos) => {
      this.documentosTipo = tipos;
      this.documentosTipoLoading = false;
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível ler tipos de documento. Por favor tente mais tarde");
    })
  };

  addDocumentoTipo = () => {
    let instance = this.UI.showDialog({
      size: 'md',
      template: require('./add.documentotipo.dialog.html'),
      controller: ['$scope', function ($scope) {
        $scope.documentoTipo = {};
        $scope.documentoTipo.id = 0;
        $scope.label = "Adicionar Tipo de Documento";

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.documentosTipoLoading = true;
      // Check if part already exists with that designacao
      this.AtvDocumentoTipo.find({
        filter: {
          where: {
            designacao: ok.documentoTipo.designacao,
            active: 1
          }
        }
      }).$promise.then((res) => {
        if (res && res.length > 0) {
          this.getDocumentosTipo();
          this.UI.addToast("Tipo de documento já existe.");
        } else {
          this.AtvDocumentoTipo.count({
            where: {
              active: 1
            }
          }).$promise.then((n) => {
            ok.documentoTipo.ordem = n.count + 1;
            this.AtvDocumentoTipo.create(ok.documentoTipo).$promise.then(r => {
              this.getDocumentosTipo();
              this.UI.addToast("Tipo de documento inserido com sucesso.");
            }).catch(error => {
              console.log(error);
              this.documentosTipoLoading = false;
              this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
            });
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro na criação. Por favor tente mais tarde");
          })
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível inserir tipo de documento");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editDocumentoTipo = (a) => {
    let options = {
      size: 'md',
      template: require('./add.documentotipo.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.documentoTipo = angular.copy(a);
        $scope.label = "Editar Tipo de Documento";

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((ok) => {
      this.documentosTipoLoading = true;
      this.AtvDocumentoTipo.findOne({
        filter: {
          where: {
            id: ok.documentoTipo.id,
            active: 1
          }
        }
      }).$promise.then((pc) => {
        this.AtvDocumentoTipo.find({
          filter: {
            where: {
              id: {neq: pc.id},
              designacao: ok.documentoTipo.designacao,
              active: 1
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            this.UI.addToast("Tipo de documento já existe.");
            this.getDocumentosTipo();
          } else {
            pc.designacao = ok.documentoTipo.designacao;
            pc.$save().then((res) => {
              this.UI.addToast('Tipo de documento alterado com sucesso');
              this.getDocumentosTipo();
            }).catch((error) => {
              console.log(error);
              this.UI.addToast('Ocorreu um erro ao editar parte do corpo');
              this.getDocumentosTipo();
            });
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Ocorreu um erro ao editar parte do corpo');
          this.getDocumentosTipo();
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast('Tipo de documento não encontrado');
        this.getDocumentosTipo();
      });
    })
  };

  confirmDeleteDocumentoTipo = (i) => {
    let title = "Eliminar Tipo de Documento";
    let warning = "Tem a certeza que pretende remover este tipo de documento?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeDocumentoTipo(i);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeDocumentoTipo = (s) => {
    this.documentosTipoLoading = true;
    // Find again parte do corpo to delete so we can update it
    this.AtvDocumentoTipo.findOne({
      filter: {
        where: {
          id: s.id,
          active: 1
        }
      }
    }).$promise.then((tipoToRemove) => {
      tipoToRemove.active = 0;
      tipoToRemove.$save().then((res) => {
        this.AtvDocumentoTipo.find({
          filter: {
            where: {
              active: 1
            },
            order: 'ordem ASC'
          }
        }).$promise.then((tps) => {
          let tasks = [];
          for (let i = 0; i < tps.length; i++) {
            tps[i].ordem = i + 1;
            let defer = this.AtvDocumentoTipo.upsert(tps[i]).$promise;
            tasks.push(defer.promise);
          }
          this.$q.all(tasks).then((res) => {
            this.UI.addToast("Tipo de documento eliminado com sucesso!");
            this.getDocumentosTipo();
          }).catch(err => {
            console.log(err);
          });
        }).catch((error) => {
          console.log(error);
        });
      }, (error) => {
        console.log(error);
        this.getDocumentosTipo();
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.getDocumentosTipo();
      this.UI.addToast("Não foi possível eliminar tipo de documento. Verifique se ainda existe.");
    });
  };

  // Prefixos
  // getPrefixos = () => {
  //   this.prefixosLoading = true;
  //   this.AtvAtivoPrefixo.find({
  //     filter: {
  //       where: {
  //         active: 1
  //       },
  //       order: 'ordem ASC'
  //     }
  //   }).$promise.then((prefixos) => {
  //     this.prefixos = prefixos;
  //     this.prefixosLoading = false;
  //   }).catch((error) => {
  //     console.log(error);
  //     this.UI.addToast("Não foi possível ler prefixos. Por favor tente mais tarde");
  //   })
  // };
  //
  // addPrefixo = () => {
  //   let instance = this.UI.showDialog({
  //     size: 'md',
  //     template: require('./add.prefixo.dialog.html'),
  //     controller: ['$scope', function ($scope) {
  //       $scope.prefixo = {};
  //       $scope.prefixo.id = 0;
  //       $scope.label = "Adicionar Prefixo";
  //
  //       $scope.ok = function () {
  //         // Send scope values for processing
  //         $scope.$close($scope);
  //       };
  //
  //       $scope.cancel = function () {
  //         $scope.$dismiss('cancel');
  //       };
  //     }]
  //   });
  //
  //   instance.then((ok) => {
  //     this.prefixosLoading = true;
  //     // Check if part already exists with that prefixo
  //     this.AtvAtivoPrefixo.find({
  //       filter: {
  //         where: {
  //           prefixo: ok.prefixo.prefixo,
  //           active: 1
  //         }
  //       }
  //     }).$promise.then((res) => {
  //       if (res && res.length > 0) {
  //         this.getPrefixos();
  //         this.UI.addToast("Prefixo já existe.");
  //       } else {
  //         this.AtvAtivoPrefixo.count({
  //           where: {
  //             active: 1
  //           }
  //         }).$promise.then((n) => {
  //           ok.prefixo.ordem = n.count + 1;
  //           this.AtvAtivoPrefixo.create(ok.prefixo).$promise.then(r => {
  //             this.getPrefixos();
  //             this.UI.addToast("Prefixo inserido com sucesso.");
  //           }).catch(error => {
  //             console.log(error);
  //             this.documentosTipoLoading = false;
  //             this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
  //           });
  //         }).catch((error) => {
  //           console.log(error);
  //           this.UI.addToast("Erro na criação. Por favor tente mais tarde");
  //         })
  //       }
  //     }).catch((error) => {
  //       console.log(error);
  //       this.UI.addToast("Não foi possível inserir prefixo");
  //     });
  //   }, (err) => {
  //     // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
  //     if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
  //       console.log(err);
  //   });
  // };
  //
  // editPrefixo = (a) => {
  //   let options = {
  //     size: 'md',
  //     template: require('./add.prefixo.dialog.html'),
  //     controller: ['$scope', '$dialog', function ($scope, $dialog) {
  //       $scope.prefixo = angular.copy(a);
  //       $scope.label = "Editar Prefixo";
  //
  //       $scope.ok = () => {
  //         $scope.$close($scope);
  //       };
  //
  //       $scope.cancel = () => {
  //         $scope.$dismiss('cancel');
  //       };
  //     }]
  //   };
  //   let dialogEdit = this.UI.showDialog(options);
  //
  //   dialogEdit.then((ok) => {
  //     this.prefixosLoading = true;
  //     this.AtvAtivoPrefixo.findOne({
  //       filter: {
  //         where: {
  //           id: ok.prefixo.id,
  //           active: 1
  //         }
  //       }
  //     }).$promise.then((p) => {
  //       this.AtvAtivoPrefixo.find({
  //         filter: {
  //           where: {
  //             id: {neq: p.id},
  //             prefixo: ok.prefixo.prefixo,
  //             active: 1
  //           }
  //         }
  //       }).$promise.then((res) => {
  //         if (res && res.length > 0) {
  //           this.UI.addToast("Prefixo já existe.");
  //           this.getPrefixos();
  //         } else {
  //           p.prefixo = ok.prefixo.prefixo;
  //           p.observacoes = ok.prefixo.observacoes;
  //           p.$save().then((res) => {
  //             this.UI.addToast('Prefixo alterado com sucesso');
  //             this.getPrefixos();
  //           }).catch((error) => {
  //             console.log(error);
  //             this.UI.addToast('Ocorreu um erro ao editar prefixo');
  //             this.getPrefixos();
  //           });
  //         }
  //       }).catch((error) => {
  //         console.log(error);
  //         this.UI.addToast('Ocorreu um erro ao editar prefixo');
  //         this.getPrefixos();
  //       });
  //     }).catch((error) => {
  //       console.log(error);
  //       this.UI.addToast('Prefixo não encontrado');
  //       this.getPrefixos();
  //     });
  //   })
  // };
  //
  // checkDeletePrefixo = (i) => {
  //   let cenas = this.UI.showWaiting();
  //   this.AtvAtivoPrefixo.findOne({
  //     filter: {
  //       where: {
  //         id: i.id,
  //         active: 1
  //       }
  //     }
  //   }).$promise.then((prefixoToRemove) => {
  //     // Find if we have something that might block deletion
  //     this.AtvAtivo.count({
  //       fields: {id: true, ativoPrefixoId: true, active: true},
  //       where: {
  //         ativoPrefixoId: prefixoToRemove.id,
  //         active: 1
  //       }
  //     }).$promise.then((ativos) => {
  //       cenas.close();
  //       if (ativos.count > 0) {
  //         let msg = "Não é possível remover prefixo.\nExiste" + ((ativos.count > 1) ? "m " : " ");
  //         msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")) : "";
  //         msg += "com este prefixo.";
  //         this.UI.showAlert(msg);
  //       } else {
  //         this.confirmDeletePrefixo(i);
  //       }
  //     }).catch((error) => {
  //       console.log(error);
  //       this.UI.addToast("De momento não é possível remover prefixo. Por favor tente mais tarde");
  //     });
  //   }).catch(error => {
  //     console.log(error);
  //     this.UI.addToast("De momento não é possível remover prefixo. Por favor tente mais tarde");
  //   });
  // };
  //
  // confirmDeletePrefixo = (i) => {
  //   let title = "Eliminar Prefixo";
  //   let warning = "Tem a certeza que pretende remover este prefixo?";
  //   let instance = this.UI.showDialog({
  //     size: 'lg',
  //     template: require('../../interface/modals/delete-selected.html'),
  //     controller: ['$scope', function ($scope) {
  //       $scope.title = title;
  //       $scope.message = warning;
  //       $scope.ok = function () {
  //         $scope.$close();
  //       };
  //       $scope.cancel = function () {
  //         $scope.$dismiss('cancel');
  //       };
  //     }]
  //   });
  //
  //   instance.then(() => {
  //     this.removePrefixo(i);
  //   }, (err) => {
  //     if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
  //       console.log(err);
  //   })
  // };
  //
  // removePrefixo = (s) => {
  //   this.prefixosLoading = true;
  //   // Find again parte do corpo to delete so we can update it
  //   this.AtvAtivoPrefixo.findOne({
  //     filter: {
  //       where: {
  //         id: s.id,
  //         active: 1
  //       }
  //     }
  //   }).$promise.then((prefixo) => {
  //     prefixo.active = 0;
  //     prefixo.$save().then((res) => {
  //       this.AtvAtivoPrefixo.find({
  //         filter: {
  //           where: {
  //             active: 1
  //           },
  //           order: 'ordem ASC'
  //         }
  //       }).$promise.then((pre) => {
  //         let tasks = [];
  //         for (let i = 0; i < pre.length; i++) {
  //           pre[i].ordem = i + 1;
  //           let defer = this.AtvAtivoPrefixo.upsert(pre[i]).$promise;
  //           tasks.push(defer.promise);
  //         }
  //         this.$q.all(tasks).then((res) => {
  //           this.UI.addToast("Prefixo eliminado com sucesso!");
  //           this.getPrefixos();
  //         }).catch(err => {
  //           console.log(err);
  //         });
  //       }).catch((error) => {
  //         console.log(error);
  //       });
  //     }, (error) => {
  //       console.log(error);
  //       this.getPrefixos();
  //       this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
  //     });
  //   }).catch((error) => {
  //     console.log(error);
  //     this.getPrefixos();
  //     this.UI.addToast("Não foi possível eliminar prefixo. Verifique se ainda existe.");
  //   });
  // };

  // Config - Empréstimos
  getConfig = () => {
    this.configLoading = true;
    this.AtvConfig.findOne({
      filter: {
        where: {
          id: 1
        },
        include: {
          relation: 'funcionario',
          scope: {
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((config) => {
      this.config = config;
      this.config.dataAlteracaoLayout = this.config.dataAlteracao ? moment(this.config.dataAlteracao).utc().format("DD/MM/YYYY HH:mm:ss") : null;
      this.configLoading = false;
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível ler configuração. Por favor tente mais tarde");
    })
  };

  editConfig = () => {
    let c = this.config;
    let options = {
      size: 'md',
      template: require('./edit.config.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.config = angular.copy(c);
        $scope.config.emprestimoActive = $scope.config.emprestimoActive === 1;
        $scope.config.emprestimoHora = moment($scope.config.emprestimoHora, 'HH:mm:ss').utc();

        // Config Empréstimos Options
        $scope.emprestimoHoraOptions = {
          format: 'HH:mm',
          defaultDate: moment().isDST() ? moment().add(1, 'h') : moment()
        };

        $scope.label = "Editar Configuração";

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((ok) => {
      this.configLoading = true;
      this.AtvConfig.findOne({
        filter: {
          where: {
            id: 1
          }
        }
      }).$promise.then((cfg) => {
        cfg.emprestimoActive = ok.config.emprestimoActive;
        cfg.emprestimoHora = cfg.emprestimoHora ? ok.config.emprestimoHora.utc().format("HH:mm:ss") : null;
        cfg.funcionarioId = this.Auth.getId();
        cfg.dataAlteracao = moment().isDST() ? moment().add(1, 'h') : moment();
        cfg.$save().then((res) => {
          this.AtvConfig.scheduleTask().$promise.then(r => {
            this.UI.addToast("Configuração guardada com sucesso");
            this.getConfig();
          }).catch(err => {
            console.log(err);
          });
        }, (error) => {
          console.log(error);
          this.UI.addToast("Não foi possível guardar configuração. Por favor tente mais tarde");
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast('Erro na procura de configuração');
        this.getConfig();
      });
    })
  };
}

AtvAdminController.$inject = ['$q', 'AuthenticationService', 'AtvPartesCorpo', 'AtvTamanhoTipo', 'AtvTamanho', 'AtvEstadoCompra', 'AtvResultado', 'AtvInteracaoTipo', 'AtvDocumentoTipo', 'AtvAtivoPrefixo', 'AtvAtivoPartesCorpo', 'AtvAtivo', 'AtvConfig', 'UIService'];
