import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Box, Chip, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import { MaterialReactTable, MRT_ActionMenuItem } from "material-react-table";
import { useMemo } from "react";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import AddIcon from '@mui/icons-material/Add';
import DetailsBox from "../../../interface/components/DetailsBox";
import { Search } from "@mui/icons-material";
import moment from "moment/moment";
import DialogOrd from "./dialog";
import { MdiIcon } from "@components";
import { mdiPencil } from "@mdi/js";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#049a9b" : "#fff",
    // ...theme.typography.body2,
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    height: "100%",
}));
const getDetailsEntryList = (ord) => (ord == null) ? [] :
    [
        { title: "Designação", content: ord.designacao },
        { title: "NIF", content: ord.nif },
        { title: "Contacto", content: ord.contacto },
        { title: "Email", content: ord.email },
        { title: "Email Alternativo", content: ord.email2 || 'N/D' },
        { title: "Email Alternativo 2", content: ord.email3 || 'N/D' },
        { title: "Observações", content: ord.observacoes }
    ];
const GasOrdDetailsView = ({ $state, GasService, UIService }) => {
    const [id, setId] = useState($state.params.id);
    const [isLoading, setIsLoading] = useState(false);
    const [ord, setOrd] = useState(null);
    const [detalhesEntryList, setDetalhesEntryList] = useState([]);
    // Dialog edit ord
    const [editDialog, setEditDialog] = useState({
        open: false
    });
    const onDialogClose = async (item) => {
        setEditDialog({ open: false });
        if (item) {
            setIsLoading(true);
            // Update data
            let d;
            try {
                d = await GasService.updateOrd(item);
                setOrd(d);
            }
            catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }
    };
    const fetchData = async () => {
        setIsLoading(true);
        let d;
        try {
            d = await findOrd(id);
        }
        catch (error) {
            setOrd(null);
            setIsLoading(false);
            UIService.addToast("Erro ao consultar ORD. Verifique a ligação");
            $state.go('app.gas.ords.list');
            throw error;
        }
        setOrd(d);
        setDetalhesEntryList(getDetailsEntryList(d));
        setIsLoading(false);
    };
    useEffect(() => {
        if (!isLoading)
            fetchData();
    }, [ord]);
    const findOrd = async (id) => {
        let data;
        try {
            data = await GasService.findOrd(id);
        }
        catch (error) {
            console.log(error);
            throw error;
        }
        return data;
    };
    const columns = useMemo(() => [
        {
            accessorKey: "id",
            header: "ID",
            size: 50,
        },
        {
            accessorKey: "numeroProcesso",
            header: "Processo",
            size: 50,
        },
        {
            accessorKey: "agendamento.data",
            header: "Data de Inspeção",
            size: 200,
            Cell: ({ renderedCellValue, row }) => ((row.original.agendamento && moment(row.original.agendamento.data).isValid()) ? moment(row.original.agendamento.data).format("DD/MM/YYYY") : 'N/D'),
        },
        {
            accessorKey: "agendamento.hora",
            header: "Hora",
            size: 50,
            Cell: ({ renderedCellValue, row }) => ((row.original.agendamento && row.original.agendamento.hora) ? row.original.agendamento.hora.substring(0, 5) : 'N/D'),
        },
        {
            accessorKey: "agendamento.duracao",
            header: "Duração (min)",
            size: 80,
        },
        {
            accessorKey: "agendamento.tecnico.name",
            header: "Técnico",
            size: 140,
        },
        {
            accessorKey: "estado.designacao",
            header: "Estado",
            size: 140,
        },
        {
            accessorKey: "tipoGas.designacao",
            header: "Tipo de Gás",
            size: 140,
        },
        {
            accessorKey: "tipoInstalacao.designacao",
            header: "Tipo de Instalação",
            size: 140,
        },
        {
            accessorKey: "tipoInspecao.designacao",
            header: "Tipo de Inspeção",
            size: 140,
        },
    ], []);
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = "Não há registos a serem exibidos";
    // Icon to edit
    const editOrdIcon = <Tooltip title='Editar'>
      <IconButton onClick={() => {
            setEditDialog({
                open: true,
                initial: ord
            });
        }}>
        <MdiIcon path={mdiPencil}/>
      </IconButton>
    </Tooltip>;
    return (<Fragment>
      <DialogOrd open={editDialog.open} initial={editDialog.initial} onClose={onDialogClose}/>
      <h1 className="section-title mt-2" id="services">
        <span>{ord && ord.designacao}</span>
      </h1>
      <ol className="breadcrumb text-left">
        <li>Gás</li>
        <li className="active">Ord</li>
      </ol>
      {isLoading ?
            (<Box sx={{ display: "flex", justifyContent: "center", height: "2vh", }}>
          <CircularProgress size={60} color="primary"/>
        </Box>) :
            <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 6 } }}>
            <Item>
              {ord != null &&
                    <DetailsBox title="Detalhes" entries={detalhesEntryList} action={editOrdIcon}></DetailsBox>}
            </Item>
          </Grid>
          {/*<Grid item xs={12} md={6} sx={{marginBottom: {xs: 6}}}>*/}
          {/*  <Item>*/}
          {/*  </Item>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <Item>
              <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
                <Grid item>
                  <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>
                    Processos <Chip label={(ord && ord.processos) ? ord.processos.length : 0} color="primary" size="small"/>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>
                    <AddIcon />
                  </Typography>
                </Grid>
              </Grid>
              {(ord && ord.processos && ord.processos.length > 0) ? <MaterialReactTable columns={columns} data={ord.processos} enableTopToolbar={false} // Removes the tollbar from the table
                 initialState={{
                        showGlobalFilter: true,
                        density: "compact",
                        pagination: {
                            pageIndex: 0,
                            pageSize: 20,
                        },
                    }} localization={localization} muiPaginationProps={{
                        rowsPerPageOptions: [20, 50, 100],
                    }} enableRowActions={true} positionActionsColumn={'last'} renderRowActionMenuItems={({ row, table }) => [
                        <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
                         icon={<Search />} key="search" label="Ver detalhes" onClick={() => $state.go('app.gas.processos.details', { id: row.original.id })} table={table}/>,
                    ]}/> : ''}
            </Item>
          </Grid>
        </Grid>}
    </Fragment>);
};
export default GasOrdDetailsView;
