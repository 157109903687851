export function routes($stateProvider) {
  $stateProvider
    .state('app.susene.interventions', {
      url: '/ordens-intervencao',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.susene.interventions.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Ordens de Intervenção',
      template: require('./directory/directory.view.html'),
      controller: 'SusEneListInterventionController',
      controllerAs: 'vm',
      role: 'suseneListarOIs',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.susene.interventions.new', {
      url: '/nova',
      manutencao: false,
      controller: 'SusEneNewInterventionController',
      title: 'Adicionar OI',
      controllerAs: 'vm',
      role: ['suseneListarOIs','suseneImportarOIs', 'suseneEditarOIs'],
      template: require('./new/new.view.html'),
    })
    .state('app.susene.interventions.detail', {
      title: 'Detalhes da OI',
      url: '/{id}',
      controller: 'SusEneDetailInterventionController',
      controllerAs: 'vm',
      role: 'suseneListarOIs',
      template: require('./details/details.view.html')
    })
  ;
}

routes.$inject = ['$stateProvider'];
