import { Box, Typography, Breadcrumbs, Link, Card, CardHeader, CardContent, Skeleton, Table, TableCell, TableHead, TableRow, TableBody, IconButton, Fab, Tooltip, ButtonGroup, Button, Snackbar } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Grid from '@mui/system/Unstable_Grid';
import moment from 'moment';
import { MdiIcon } from '@components';
import { mdiCheckBold, mdiContentSave, mdiFileFind, mdiPencil, mdiPlus } from '@mdi/js';
import { DynamicCard } from '@components';
import DialogMaterial from './dialog';
import DialogSchedule from './schedule';
import DialogObs from './observacoes';
const DetailsOrcamento = ({ $state, OblerRevampService, OblerPendentesService, UIService }) => {
    const [obsDialog, setObsDialog] = useState({
        open: false,
    });
    const [observationsSnackbar, setObservationsSnackbar] = useState({
        open: false,
    });
    const [dMaterial, setDialogMaterial] = useState({
        open: false
    });
    const [scheduleDialog, setScheduleDialog] = useState({
        open: false
    });
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [data, setData] = useState();
    const [materiais, setMateriais] = useState([]);
    const [fotos, setFotos] = useState([]);
    const id = $state.params.id;
    const fetchMateriais = async () => {
        setLoading(true);
        try {
            const mat = await OblerRevampService.getOrcamentoMateriais(id);
            setMateriais(mat);
        }
        catch (e) { }
        setLoading(false);
    };
    const fetchData = async () => {
        try {
            const aux = await OblerRevampService.getOrcamento(id);
            const mat = await OblerRevampService.getOrcamentoMateriais(id);
            const f = await OblerRevampService.getFotografias(id);
            setData(aux);
            setMateriais(mat);
            setFotos(f);
        }
        catch (e) {
            UIService.addToast('Erro ao carregar orçamento');
            $state.go('app.obler.orcamentos.list');
        }
        setLoading(false);
    };
    const confirmMaterial = async (row) => {
        UIService.showConfirm(`Confirma receção da peça ${row.designacao}? Esta ação não pode ser anulada!`).then(async (r) => {
            if (r) {
                try {
                    await OblerPendentesService.validate(row.id);
                }
                catch (e) {
                    console.log();
                }
                setLoading(true);
            }
        });
    };
    const arePecasValidated = () => {
        if (!data)
            return false;
        return materiais.filter(r => r.rececionado == 1).length == materiais.length;
    };
    const arePecasIncomplete = () => {
        if (!data)
            return false;
        return materiais.filter(r => r.compra == null || r.prazo == null).length > 0;
    };
    const isValid = () => {
        if (!data)
            return false;
        return data.dataSubmissao != undefined && data.validado == 1 && data.aceite != 3;
    };
    const isEditable = () => {
        if (!data)
            return false;
        return data.dataSubmissao == undefined || (data.dataSubmissao != undefined && data.aceite == 3);
    };
    const needsToBeValidated = () => {
        if (!data)
            return false;
        return data.validado != 1;
    };
    const needsToBeFinished = () => {
        if (!data)
            return false;
        return data.dataSubmissao != undefined && data.aceite == 3;
    };
    const getCP = () => {
        if (!data)
            return '';
        return `${data.Ordemintervencao.cp4}-${data.Ordemintervencao.cp3} ${data.Ordemintervencao.localidade}`;
    };
    const getOrcamentoEstado = () => {
        if (!data || data.dataSubmissao == undefined)
            return '-';
        switch (data.aceite) {
            case 0:
                return 'Não aprovado';
            case 1:
                return 'Aprovado';
            case 2:
                return 'Aguarda resposta do cliente';
            case 3:
                return 'Pendente de fecho';
        }
    };
    useEffect(() => {
        if (loading) {
            fetchData();
        }
    }, [loading]);
    return (<Box>
      <Snackbar open={observationsSnackbar.open} message={observationsSnackbar.message} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => setObservationsSnackbar({ open: false })}/>
      <DialogObs fullWidth maxWidth='lg' open={obsDialog.open} initial={obsDialog.initial} onClose={async (response) => {
            setObsDialog({ open: false });
            if (response != null) {
                try {
                    await OblerRevampService.editObservations(id, response);
                    setObservationsSnackbar({ open: true, message: "Observações Atualizadas" });
                    setData({ ...data, observacoes: response });
                }
                catch (error) {
                    console.log(error);
                    UIService.addToast('Erro na atualização de observações');
                }
            }
        }}/>
      <DialogMaterial open={dMaterial.open} initial={dMaterial.initial} fullWidth maxWidth='lg' onClose={async (response) => {
            setDialogMaterial({ open: false });
            if (response) {
                if (response.id == 0) {
                    await OblerPendentesService.create(response);
                    UIService.addToast('Material adicionado');
                }
                else {
                    await OblerPendentesService.patch(response.id, response);
                    UIService.addToast('Material atualizado');
                }
                fetchMateriais();
            }
        }} OblerRevampService={OblerRevampService}/>

      <DialogSchedule open={scheduleDialog.open} initial={scheduleDialog.initial} fullWidth maxWidth='lg' onClose={async (response) => {
            setScheduleDialog({ open: false });
            if (response) {
                // Cria nova processo
                let p = await OblerRevampService.createProcessoWithAgendamento({
                    id: 0,
                    tipoId: 3,
                    extra: 0,
                    oiId: data.oiId,
                    data: moment().format(),
                    criadoPor: 0,
                    active: 1
                }, response.data, response.tecnicoId);
                OblerRevampService.patch(id, {
                    filhoId: p.processoId
                });
                fetchData();
            }
        }} OblerRevampService={OblerRevampService}/>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>{loading ? <Skeleton width={320}/> : 'Orçamento'}</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Obler
          </Link>
          <Link underline='hover' color='text.primary'>
            Orçamentos
          </Link>
          <Link underline='hover' color='text.primary' aria-current='page'>
            {id}
          </Link>
        </Breadcrumbs>
      </Box>
      <Grid container spacing={2}>
        <Grid xs={6}>
          <DynamicCard title='Detalhes' loading={loading} content={[
            {
                label: 'Número Processo',
                value: (<Link onClick={() => {
                        $state.go('app.obler.oi.details', { id: data?.oiId });
                    }}>
                    {data?.Ordemintervencao?.numeroProcesso}
                  </Link>)
            },
            {
                label: 'Valência',
                value: data?.Ordemintervencao?.Valencia?.designacao
            },
            {
                label: 'Origem',
                value: data?.Ordemintervencao?.origem == 1 ? 'Funciona' : 'Ondemand'
            },
            {
                label: 'Parceiro',
                value: data?.Ordemintervencao?.Cliente?.nome
            },
            {
                label: 'Número Parceiro',
                value: data?.Ordemintervencao?.parceiro
            },
            {
                label: 'Cliente',
                value: data?.Ordemintervencao?.nomeCliente
            },
            {
                label: 'Contacto',
                value: data?.Ordemintervencao?.tlmCliente
            },
            {
                label: 'Morada',
                value: data?.Ordemintervencao.morada
            },
            {
                label: 'Código Postal',
                value: getCP()
            }
        ]}/>

          <DynamicCard sx={{ mt: 2 }} title='Estado' loading={loading} content={[
            {
                label: 'Data de submissão',
                value: data?.dataSubmissao ? `${moment(data?.dataSubmissao).format('DD/MM/YYYY HH:mm')}` : 'Aguarda execução'
            },
            {
                label: 'Visita realizada?',
                value: data?.dataSubmissao ? (data?.realizada ? 'Sim' : 'Não') : '-'
            },
            {
                label: 'Estado',
                value: getOrcamentoEstado()
            },
            data && data?.aceite == 2
                ? {
                    label: '',
                    value: (<ButtonGroup size='small' variant='outlined'>
                        <Tooltip title='Marcar orçamento como cliente aceitou'>
                          <Button onClick={() => {
                            UIService.showConfirm("Deseja marcar orçamento como 'aceite'?").then(async (r) => {
                                if (r) {
                                    setLoading(true);
                                    const r = await OblerRevampService.patch(id, {
                                        aceite: 1,
                                        validado: 1
                                    });
                                    // Se todas as peças estão rececionadas mas ainda está pendente, então muda estado, caso contrário é despoletado automaticamente ao rececionar todas as peças
                                    if (materiais.length == materiais.filter(r => r.rececionado).length) {
                                        await OblerRevampService.changeState(data.oiId, data.Ordemintervencao.estadoId, 7);
                                    }
                                    fetchData();
                                    UIService.addToast('Orçamento marcado como aceite');
                                }
                            });
                        }}>
                            Aceite
                          </Button>
                        </Tooltip>
                        <Tooltip title='Marcar orçamento como cliente não aceitou'>
                          <Button onClick={() => {
                            UIService.showConfirm("Deseja marcar orçamento como 'não aceite'?").then(async (r) => {
                                if (r) {
                                    setLoading(true);
                                    const r = await OblerRevampService.patch(id, {
                                        aceite: 0,
                                        validado: 1
                                    });
                                    await OblerRevampService.changeState(data.oiId, data.Ordemintervencao.estadoId, 10, 'Cliente rejeitou orçamento');
                                    fetchData();
                                    UIService.addToast('Orçamento marcado como não aceite');
                                }
                            });
                        }}>
                            Não aceite
                          </Button>
                        </Tooltip>
                      </ButtonGroup>)
                }
                : undefined,
            data && data?.filhoId == undefined && data?.aceite == 1
                ? {
                    label: '',
                    value: (<Tooltip title='Agendar visita técnica'>
                        <Button variant='outlined' size='small' onClick={() => {
                            // Dialog...
                            setScheduleDialog({
                                open: true,
                                initial: undefined
                            });
                        }}>
                          Agendar visita
                        </Button>
                      </Tooltip>)
                }
                : undefined,
            data && data?.filhoId != undefined && data?.aceite == 1
                ? {
                    label: '',
                    value: (<Tooltip title='Ir para visita técnica'>
                        <Button variant='outlined' size='small' onClick={() => {
                            // Dialog...
                            $state.go('app.obler.oi.folha', {
                                id: data.oiId,
                                ficha: data.filhoId
                            });
                        }}>
                          Ir para visita
                        </Button>
                      </Tooltip>)
                }
                : undefined
        ]}/>
          <DynamicCard sx={{ mt: 2 }} actions={[
            {
                label: 'Editar',
                icon: mdiPencil,
                callback: () => {
                    setObsDialog({ open: true, initial: data?.observacoes });
                }
            }
        ]} title='Observações' loading={loading} content={[data?.observacoes]}/>
        </Grid>
        <Grid xs={6}>
          <DynamicCard title='Agendamento' loading={loading} content={[
            {
                label: 'Data',
                value: moment(data?.Agendamento?.data).format('DD/MM/YYYY')
            },
            {
                label: 'Hora',
                value: data?.Agendamento?.hora.substring(0, 5)
            },
            {
                label: 'Técnico',
                value: data?.Agendamento?.Funcionario?.name
            }
        ]}/>
          <DynamicCard sx={{ mt: 2 }} title='Fotografias' subheader='Pode adicionar até 6 fotografias ao orçamento' variant='gallery' loading={loading} content={fotos.map(r => `/api/Upload/obler-photos/download/${r.imagem}`)}/>
        </Grid>
        <Grid xs={12}>
          <Card>
            <CardHeader title={loading ? <Skeleton width={320}/> : 'Materiais'} action={editing ? (<IconButton onClick={() => {
                setDialogMaterial({
                    open: true,
                    initial: {
                        id: 0,
                        active: 1,
                        processoId: id
                    }
                });
            }}>
                    <MdiIcon path={mdiPlus}/>
                  </IconButton>) : undefined}/>
            <CardContent>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>{loading ? <Skeleton /> : 'Cód. Compra'}</TableCell>
                    <TableCell>{loading ? <Skeleton /> : 'Cód. Artigo'}</TableCell>
                    <TableCell>{loading ? <Skeleton /> : 'Designação'}</TableCell>
                    <TableCell>{loading ? <Skeleton /> : 'Fornecedor'}</TableCell>
                    <TableCell align='right'>{loading ? <Skeleton /> : 'Quantidade'}</TableCell>
                    <TableCell align='right'>{loading ? <Skeleton /> : 'Preço (€)'}</TableCell>
                    <TableCell align='right'>{loading ? <Skeleton /> : 'Prazo (dias)'}</TableCell>
                    <TableCell>{loading ? <Skeleton /> : 'Fotografia'}</TableCell>
                    <TableCell>{loading ? <Skeleton /> : 'Estado'}</TableCell>
                    <TableCell align='right' sx={{ width: loading ? '50px' : '120px' }}>
                      {loading ? <Skeleton variant='rounded'/> : ''}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
            ? [1, 2, 3].map((r, i, a) => {
                return (<TableRow key={`skeleton${i}`}>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell align='right'>
                              <Skeleton />
                            </TableCell>
                            <TableCell align='right'>
                              <Skeleton />
                            </TableCell>
                            <TableCell align='right'>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell align='right'>
                              <Skeleton variant='rounded'/>
                            </TableCell>
                          </TableRow>);
            })
            : materiais.map((r, i, a) => {
                return (<TableRow key={`materiais${r.id}-${i}`}>
                            <TableCell>{r.compra}</TableCell>
                            <TableCell>{r.codigo}</TableCell>
                            <TableCell>{r.designacao}</TableCell>
                            <TableCell>{r.fornecedor}</TableCell>
                            <TableCell align='right'>{r.quantidade}</TableCell>
                            <TableCell align='right'>{r.preco?.toLocaleString('pt-PT', { style: 'decimal', maximumFractionDigits: 2 })}</TableCell>
                            <TableCell align='right'>{r.prazo ? r.prazo : '-'}</TableCell>
                            <TableCell>
                              {r.fotografia != undefined ? (<Link onClick={() => {
                            const url = `/api/Upload/obler-photos/download/${r.fotografia}`;
                            window.open(url, '_blank', 'noreferrer');
                        }}>
                                  Visualizar
                                </Link>) : ('')}
                            </TableCell>
                            <TableCell>
                              {r.rececionadoId != undefined
                        ? `Rececionada a ${moment(r.dataRececao).format('DD/MM/YYYY HH:mm')} por ${r.RececionadoPor?.name}`
                        : 'Aguarda receção'}
                            </TableCell>
                            <TableCell align='right'>
                              {editing && r.rececionadoId == undefined ? (<IconButton onClick={() => {
                            setDialogMaterial({ open: true, initial: r });
                        }}>
                                  <MdiIcon path={mdiPencil}/>
                                </IconButton>) : undefined}
                              {r.rececionadoId == undefined ? (<IconButton onClick={() => confirmMaterial(r)}>
                                  <MdiIcon path={mdiCheckBold}/>
                                </IconButton>) : undefined}
                            </TableCell>
                          </TableRow>);
            })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            mr: 1,
            mb: 1
        }}>
        {isEditable() && !editing && (<Tooltip title='Editar'>
            <Fab size='small' color='error' aria-label='add' onClick={() => {
                setEditing(true);
            }}>
              <MdiIcon path={mdiPencil}/>
            </Fab>
          </Tooltip>)}
        {editing && (<Tooltip title='Guardar alterações'>
            <Fab size='small' color='error' aria-label='add' onClick={() => {
                setEditing(false);
            }}>
              <MdiIcon path={mdiContentSave}/>
            </Fab>
          </Tooltip>)}
        {!editing && needsToBeFinished() && (<Tooltip title='Finalizar orçamento'>
            <Fab size='small' color='success' onClick={() => {
                UIService.showConfirm('Finalizar orçamento? Não vai poder efetuar mais alterações e será enviado o orçamento ao cliente. Deseja continuar?').then(async (r) => {
                    if (r) {
                        if (materiais.length == 0) {
                            UIService.showConfirm('Vai finalizar um orçamento sem material associado. Deseja continuar?').then(async (o) => {
                                if (o) {
                                    setLoading(true);
                                    await OblerRevampService.patch(id, {
                                        aceite: 2,
                                        validado: 1
                                    });
                                    fetchData();
                                }
                            });
                        }
                        else {
                            setLoading(true);
                            if (arePecasIncomplete()) {
                                UIService.showConfirm('Existem peças sem prazo/cód. compra definido! Preencha-os primeiro!');
                                setLoading(false);
                                return;
                            }
                            // Validar orçamento
                            if (arePecasValidated()) {
                                await OblerRevampService.patch(id, {
                                    aceite: 2,
                                    validado: 1
                                });
                                fetchData();
                                UIService.addToast('Orçamento finalizado');
                            }
                            else {
                                UIService.showConfirm('Existem peças por confirmar receção. Deseja continuar?').then(async (o) => {
                                    if (o) {
                                        await OblerRevampService.patch(id, {
                                            aceite: 2,
                                            validado: 1
                                        });
                                        fetchData();
                                    }
                                });
                            }
                        }
                    }
                });
            }}>
              <MdiIcon path={mdiCheckBold}/>
            </Fab>
          </Tooltip>)}
        {!editing && needsToBeValidated() && (<Tooltip title='Validar orçamento'>
            <Fab size='small' color='success'>
              <MdiIcon path={mdiCheckBold}/>
            </Fab>
          </Tooltip>)}
        {isValid() && (<Tooltip title='Pré-visualizar ficheiro'>
            <Fab size='small' onClick={() => {
                const url = `/api/oblprocessos/preview?id=${id}`;
                window.open(url, '_blank', 'noreferrer');
            }}>
              <MdiIcon path={mdiFileFind}/>
            </Fab>
          </Tooltip>)}
      </Box>
    </Box>);
};
export default DetailsOrcamento;
