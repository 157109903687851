export default class TecnicoElevadoresController {
  constructor($state, $q, UIService, AuthenticationService, AuthorizationService, $http, Funcionario, NgTableParams) {
    this.UI = UIService;
    this.$q = $q;
    this.AuthenticationService = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.$http = $http;
    this.id = $state.params.id;
    this.NgTableParams = NgTableParams;
    this.Funcionario = Funcionario;
    this.loaded = false;
    this.hasPerms().then(x => {
      this.loadData();
    }).catch(() => {
      this.UI.addToast("Não é possível consultar calendarização");
      $state.go($state.previous.name, $state.previous.params);
    });
  }

  hasPerms = () => {
    let defer = this.$q.defer();
    if (this.AuthorizationService.canPerform('elvListarTodosAgendamentos')) {
      defer.resolve(true);
    } else {
      if (this.AuthenticationService.getId() == this.id) { // Relaxed == comparison is required
        defer.resolve(true);
      } else {
        defer.reject(false);
      }
    }
    return defer.promise;
  };

  loadData = () => {
    this.loaded = false;
    this.Funcionario.findOne({
      filter: {
        where: {
          active: true,
          id: this.id,
        },
        include:
          {
            relation: 'Agendamentos',
            scope: {
              where: {
                active: true
              },
              include: {
                relation: 'Pedido',
                scope: {
                  where: {
                    active: true,
                    estadoId: 2
                  },
                  include: [{
                    relation: 'elevadorPed',
                    scope: {
                      where: {
                        active: true
                      },
                      include: 'emie'
                    }
                  }]
                }
              },
            }
          },
      }
    }).$promise.then(res => {
      this.data = res;
      let NgTableParams = this.NgTableParams;
      this.tableData = new NgTableParams({
        sorting: {
          data: 'asc'
        }
      }, {
        dataset: res.Agendamentos,
      });
      this.loaded = true;
    })
  };
}

TecnicoElevadoresController.$inject = ['$state', '$q', 'UIService', 'AuthenticationService', 'AuthorizationService', '$http', 'Funcionario', 'NgTableParams'];
