export default class DetalhesRelatorioController {
  constructor($state, $q, AuthorizationService, AuthenticationService, UIService, ElvCliente, ElvRelatorioJson) {
    this.$state = $state;
    this.AuthorizationService = AuthorizationService;
    this.Auth = AuthenticationService;
    this.UI = UIService;
    this.relatorio = null;
    this.loaded = false;
    this.ListLoaded = false;
    this.ElvRelatorioJson = ElvRelatorioJson;
    this.ElvCliente = ElvCliente;
    this.$q = $q;

    this.reportId = this.$state.params.rep;
    this.wasValidado = false;
    this.recomendacoes = null;
    this.clausulas = null;
    this.pontos = null;
    this.lista = null;

    this.resultados = [
      { name: "Aprovado (com C3/C2*)", value: 6},
      { name: "Aprovado (com C2*)", value: 5},
      { name: "Não realizada", value: 4},
      { name: "Reprovado", value: 3},
      { name: "Aprovado (com C3)", value: 2},
      { name: "Aprovado", value: 1},
      { name: "Aguarda submissão", value: 0}
    ];
    this.hasPerms().then(() => {
      this.loadData();
    }).catch(() => {
      $state.go($state.previous.name, $state.previous.params);
    });
  }

  hasPerms = () => {
    let defer = this.$q.defer();
    if (this.AuthorizationService.canPerform('elvListarTodosRelatorios')) {
      defer.resolve(true);
    } else {
      this.ElvRelatorioJson.findOne({
        filter: {
          fields: { id: true, tecnicoId: true },
          where: {
            id: this.reportId
          }
        }
      }).$promise.then((r) => {
        if (r && r.tecnicoId == this.Auth.getId()) {
          defer.resolve(true);
        } else {
          defer.reject(false);
          this.UI.addToast("Sem permissões para ver o relatório");
        }
      }).catch(() => {
        defer.reject(false);
        this.UI.addToast("Relatório não encontrado");
      });
    }

    return defer.promise;
  };

  loadData = () => {
    this.loaded = false;
    this.ElvRelatorioJson.findOne({
      filter: {
        where: {
          id: this.reportId,
        },
        include: [
          {
            relation: 'lista',
            scope: {
              include: {
                relation: 'norma',
                scope: {
                  where: {
                    active: true
                  }
                }
              }
            }
          },
          {
            relation: "processo",
            scope: {
              include: [
                "cliente",
                {
                  relation: "elevadorPed",
                  scope: {
                    include: ["equipamento", "tipo"],
                  },
                },
              ],
            },
          },
        ]
      },
    }).$promise.then((r) => {
      r.jsonData = JSON.parse(r.json);
      // 2022 e ainda se encontra problemas da Tixa :)
      // Um ponto de verificação consegue ter um código de tópico e pertencer a outro :)
      let pontos = [];
      r.jsonData.topicos.forEach((topico) => {
        topico.pontos.forEach((ponto) => {
          ponto.fields = this.formField(ponto);
          if (ponto.clausulas.length > 0) {
            ponto.expanded = true;
          } else {
            ponto.expanded = false;
          }
          pontos.push(ponto);
        });
        topico.pontos = [];
      });
      pontos.forEach(p => {
        p.aux = Number(p.codigo);
      });
      r.jsonData.topicos.forEach((topico) => {
        let list = pontos.filter(p => {
          if ((p.aux % 1) == 0) {
            return p;
          } else {
            return (Math.trunc(p.aux) == topico.ordem);
          }
        });
        topico.pontos = list.sort((a, b) => a.ordem - b.ordem);
      });

      this.data = r;
      this.wasValidado = angular.copy(this.data.validado) == 1;
      if (this.data.resultado == 0) {
        this.reportStyle = {};
      } else {
        if (this.data.resultado == 1 || this.data.resultado == 2 || this.data.resultado == 5 || this.data.resultado == 6) {
          this.reportStyle = {
            fontWeight: 500,
            color: "#388e3c",
          };
        } else {
          this.reportStyle = {
            fontWeight: 500,
            color: "#d32f2f",
          };
        }
      }
      this.ElvRelatorioJson.find({
        filter: {
          where: {
            processoId: this.data.processoId,
            versao: {
              gt: this.data.versao
            }
          },
          order: "versao DESC",
          limit: 1,
        },
      }).$promise.then((r) => {
        this.hasRecentVersion = !!(r && r.length === 1 && r[0].id != this.reportId);
      }).catch(() => {
        this.hasRecentVersion = false;
      });

      this.ElvRelatorioJson.find({
        filter: {
          where: {
            processoId: this.data.processoId,
            versao: {
              lt: this.data.versao,
            },
          },
          order: "versao ASC",
          limit: 1,
        },
      }).$promise.then((r) => {
        this.hasPreviousVersion = !!(r && r.length === 1 && r[0].id != this.reportId);
      }).catch((err) => {
        console.log(err);
        this.hasPreviousVersion = false;
      });
      this.loaded = true;
    });
  };

  showImages = () => {
    this.UI.showDialog({
      template: require('./images.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.idx = 0;

        $scope.set = (i) => {
          $scope.idx = i;
        }

        $scope.isAPIImage = () => {
          let img = $scope.list[$scope.idx].base64;
          return img.includes("api");
        }

        $scope.open = () => {
          let img = $scope.list[$scope.idx].base64;
          if (img.includes("api")) {
            window.open(`${img}`, '_blank');
          } else {
            let data = `data:image/jpeg;base64,${$scope.list[$scope.idx].base64}`;
            let w = window.open('about:blank');
            let image = new Image();
            image.src = data;
            setTimeout(function () {
              w.document.write(image.outerHTML);
            }, 0);
          }
        }

        $scope.list = this.data.jsonData.imagens;

        $scope.ok = () => {
          $scope.$close();
        }
      }]
    });
  }

  formField = ponto => {
    let texto = ponto.nome.split(/[_]{3,}/);
    let inputs = [];
    let fields = [];
    let j = 0;
    let c = 0;
    for (let i = 0; i < ponto.campos.length; i++) {
      inputs.push({
        input: true,
        index: i,
        id: c++
      });
    }
    for (let i = 0; i < texto.length; i++) {
      let text = texto[i].split(" ");
      text.forEach(w => {
        fields.push({
          text: true,
          value: w,
          id: c++
        });
        fields.push({
          text: true,
          value: "&nbsp;",
          id: c++
        });
      });
      if (j < inputs.length) {
        fields.push(inputs[j++]);
      }
    }
    return fields;
  }

  showModalForInspecao = () => {
    let options = {
      size: "lg",
      template: require("./inspecao.dialog.html"),
      controller: [
        "$scope",
        "$dialog",
        ($scope, $dialog) => {
          $scope.data = {
            pisos: angular.copy(this.data.jsonData.pisos),
            velocidade: angular.copy(this.data.jsonData.velocidade),
            carga: angular.copy(this.data.jsonData.carga),
            realizada: this.data.jsonData.realizada === 1,
          };

          $scope.isElevador = () => {
            return this.isElevador();
          };

          $scope.cancel = () => {
            $dialog.dismiss();
          };

          $scope.ok = () => {
            $dialog.close($scope.data);
          };
        },
      ],
    };
    this.UI.showDialog(options).then((res) => {
      this.data.jsonData.pisos = res.pisos;
      this.data.jsonData.velocidade = res.velocidade;
      this.data.jsonData.carga = res.carga;
      this.data.jsonData.realizada = res.realizada ? 1 : 0;
    });
  };

  assignClausulas = (clausulas) => {
    let copiaClausulas = angular.copy(clausulas);
    let dialog = this.UI.showDialog({
      template: require("./conformidade.dialog.html"),
      controller: ["$scope", "$timeout", "ElvClausulaTipo", ($scope, $timeout, ElvClausulaTipo) => {
        $scope.label = "Cláusulas";

        $scope.idx = 0;

        ElvClausulaTipo.find({
          filter: {
            where: {
              active: true,
            },
            order: 'id asc',
            include: {
              relation: 'clausulas',
              scope: {
                where: {
                  active: 1
                }
              }
            }
          },
        }).$promise.then((res) => {
          $scope.hasLoaded = true;
          $scope.clausulasTipo = res;
          $scope.clausulas = [];
          $scope.clausulasTipo.forEach(ct => {
            $scope.clausulas.push(ct.clausulas);
          });
        });

        $scope.data = [
          copiaClausulas.filter((r) => r.clausula.tipoId === 1) || [],
          copiaClausulas.filter((r) => r.clausula.tipoId === 2) || [],
          copiaClausulas.filter((r) => r.clausula.tipoId === 3) || [],
          copiaClausulas.filter((r) => r.clausula.tipoId === 4) || [],
        ];

        $scope.ok = () => {
          let toReturn = [];
          $scope.data.forEach((e) => {
            toReturn.push(...e);
          });

          if (toReturn.length > 0) {
            $scope.$close(toReturn);
          }
        };

        $scope.isInvalid = () => {
          let count = 0;
          $scope.data.forEach((r) => {
            count += r.length;
          });

          return count == 0;
        };

        $scope.select = (a) => {
          $scope.idx = a;
        };

        $scope.remove = (idx) => {
          $scope.data[$scope.idx].splice(idx, 1);
        };

        $scope.add = (idx) => {
          let item = $scope.data[$scope.idx][idx] || null;
          this.addClausula($scope.clausulas[$scope.idx], item).then(
            (clausula) => {
              if (clausula) {
                if (item != null) {
                  $timeout(() => {
                    $scope.data[$scope.idx][idx] = clausula;
                  });
                } else {
                  $timeout(() => {
                    $scope.data[$scope.idx].push(clausula);
                  });
                }
              }
            }
          );
        };

        $scope.cancel = () => {
          $scope.$dismiss("cancel");
        };
      }]
    });

    return dialog;
  };

  addClausula = (list, item) => {
    let defer = this.$q.defer();
    this.UI.showDialog({
      template: require("./clausula.dialog.html"),
      controller: [
        "$scope",
        ($scope) => {
          $scope.title = "Cláusula";
          $scope.item = angular.copy(item) || {};
          $scope.list = list;

          $scope.ok = () => {
            $scope.$close($scope.item);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    })
      .then((r) => {
        defer.resolve(r);
      })
      .catch(() => {
        defer.resolve(null);
      });
    return defer.promise;
  };

  getClausulasFromTipo = (clausulas, tipo) => {
    return clausulas.filter((r) => r.clausula.tipoId == tipo);
  };

  tipoConformidade = (ponto, id) => {
    if (ponto.resultado == null) {
      return {};
    } else if (ponto.resultado == id && id == 1) {
      // Conforme
      return {
        "background-color": "#4CAF50",
      };
    } else if (ponto.resultado == id && id == 2) {
      if (!ponto.clausulas || ponto.clausulas.length === 0) return;
      // Se tem clasusulas C1 / C2 => vermelho, se apenas C3 => azul
      // Não conforme
      return ponto.clausulas.filter((r) => r.clausula.tipoId == 1 || r.clausula.tipoId == 2).length > 0 ? { "background-color": "#F44336" } : { "background-color": "#FF9800" };
    } else if (ponto.resultado == id && id == 3) {
      // Não aplicável
      return { "background-color": "#2196F3" };
    }
  };

  hasDeficiencias = (clausulas, tipo) => {
    return clausulas.find((r) => r.clausula.tipoId == tipo) != null;
  };

  setTipoConformidade = (ponto, id) => {
    if (!this.editingReport) {
      return;
    }
    if (ponto.resultado != id) {
      ponto.clausulas = [];
    }
    if (id == 2) {
      // Abrir modal de deficiencias
      this.assignClausulas(ponto.clausulas)
        .then((r) => {
          if (r.length > 0) {
            ponto.clausulas = r;
            ponto.resultado = id;
            ponto.expanded = true;
          } else {
            this.UI.addToast("Adicione pelo menos 1 cláusula");
          }
        })
        .catch(() => {
          this.UI.addToast("Adicione pelo menos 1 cláusula");
        });
    } else {
      ponto.resultado = id;
      ponto.expanded = false;
    }
  };

  getCamposForPonto = (ponto) => {
    let array = ponto.nome.split(/[_]{3,}/);
    return array;
  };

  addPontoObservacoes = (ponto) => {
    if (!this.editingReport) {
      return;
    }
    let dialog = this.UI.showDialog({
      size: "lg",
      template: require("./observations.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.o = {
          obsInternas: ponto.obsInternas
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };

        $scope.ok = () => {
          $dialog.close($scope.o);
        };
      }]
    });
    dialog.then((res) => {
      ponto.obsInternas = res.obsInternas;
    });
  };

  generateReport = () => {
    // Check if valid
    let result = this.isValid();

    if (!result.ok) {
      this.UI.showDialog({
        template: require("./erros.dialog.html"),
        controller: ["$scope", ($scope) => {
          $scope.erros = result.errors;
          $scope.ok = () => {
            $scope.$close();
          };
        }],
      });
    } else {
      this.save().then((r) => {
        window.open(`/api/elvrelatoriojsons/preview?id=${this.reportId}`, "_blank").focus();
      });
    }
  };

  save = () => {
    // Remove "expanded"
    let o = angular.copy(this.data.jsonData);
    o.topicos.forEach((topico) => {
      topico.pontos.forEach((ponto) => {
        delete ponto.expanded;
      });
    });
    console.log(o);
    this.data.json = JSON.stringify(o);
    if (this.data.submissao == undefined) {
      this.data.submissao = moment().isDST()
        ? moment.utc().add(1, "h")
        : moment.utc();
    }
    return this.ElvRelatorioJson.upsert(this.data).$promise;
  };

  editObservacoes = () => {
    let c = angular.copy(this.data);
    let dialog = this.UI.showDialog({
      size: "lg",
      template: require("./observationsGerais.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.observacoes = c.observacoes;

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };

        $scope.ok = () => {
          $dialog.close($scope.observacoes);
        };
      }],
    });
    dialog.then((res) => {
      c.observacoes = res;
    });
  };

  isValid = () => {
    // Valida dados gerais
    let canProceed = true;
    let erros = [];
    let data = this.data.jsonData;
    if (data.realizada) {
      if (!data.velocidade || data.velocidade == 0) {
        canProceed = false;
        erros.push("Indique a velocidade do equipamento");
      }
      if (this.isElevador() && (!data.carga || data.carga == 0)) {
        canProceed = false;
        erros.push("Indique a carga do equipamento");
      }
      if (this.isElevador() && (!data.pisos || data.pisos == 0)) {
        canProceed = false;
        erros.push("Indique o número de pisos");
      }
      data.topicos.forEach((topico) => {
        topico.pontos.forEach((ponto) => {
          if (ponto.resultado == null || ponto.resultado == 0) {
            canProceed = false;
            erros.push(
              `Indique o resultado do ponto ${topico.ordem}.${ponto.ordem}`
            );
          } else {
            if (ponto.resultado == 1) {
              if (ponto.campos.filter((r) => r == null).length > 0) {
                canProceed = false;
                erros.push(
                  `Preencha os campos do ponto ${topico.ordem}.${ponto.ordem}`
                );
              }
            } else if (ponto.resultado == 2) {
              if (ponto.clausulas.length == 0) {
                canProceed = false;
                erros.push(
                  `O ponto ${topico.ordem}.${ponto.ordem} não tem cláusulas associadas`
                );
              }
              if (ponto.campos.filter((r) => r == null).length > 0) {
                canProceed = false;
                erros.push(
                  `Preencha os campos do ponto ${topico.ordem}.${ponto.ordem}`
                );
              }
            }
          }
        });
      });
    }
    return {
      ok: canProceed,
      errors: erros,
    };
  };

  editReport = () => {
    if (this.data.validado) {
      this.UI.showConfirm("A edição do relatório implica a criação de uma nova versão do mesmo. Tem a certeza?").then((res) => {
        if (res) {
          this.editingReport = true;
        }
      }).catch((err) => {});
    } else {
      this.editingReport = true;
    }
  };

  saveReport = () => {
    let result = this.isValid();

    if (!result.ok) {
      this.UI.showDialog({
        template: require("./erros.dialog.html"),
        controller: ["$scope", ($scope) => {
          $scope.erros = result.errors;
          $scope.ok = () => {
            $scope.$close();
          };
        },
        ],
      });
    } else {
      this.UI.showConfirm("Tem a certeza que pretende finalizar a edição do relatório?").then((res) => {
        if (res) {
          let aprovado = this.calculaResultado();
          if (aprovado) {
            // Pede periodicidade
            this.askPeriodicidade().then((r) => {
              if (r != null) {
                this.data.jsonData.periodicidade = r;
                this.saveData();
              } else {
                return;
              }
            });
          } else {
            this.saveData();
          }
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  askPeriodicidade = () => {
    return this.UI.showDialog({
      template: require("./periodicidade.dialog.html"),
      controller: ["$scope", ($scope) => {
        $scope.periodicidade = 24;

        $scope.cancel = () => {
          $scope.$close(null);
        };
        $scope.ok = () => {
          $scope.$close($scope.periodicidade);
        };
      }],
    });
  };

  calculaResultado = () => {
    if (!this.loaded) return false;
    if (this.data.jsonData.realizada) {
      let toR = true;
      let totalPontos = 0, pontosNA = 0;
      this.data.jsonData.topicos.forEach((topico) => {
        topico.pontos.forEach((ponto) => {
          totalPontos++;
          let hasC1C2 = ponto.clausulas.some((c) => c.clausula.tipoId == 1 || c.clausula.tipoId == 2);
          if (hasC1C2) {
            toR = false;
          }
          if (ponto.resultado === 3)
            pontosNA++;
        });
      });
      if (pontosNA == totalPontos)
        toR = false;
      return toR;
    } else {
      return false;
    }
  };

  saveData = () => {
    this.editingReport = false;
    this.loaded = false;
    // TODO: Verifica se antes já foi gerado
    // Save data to new instance of report and open that one.
    if (this.wasValidado) {
      this.data.id = 0;
      this.data.validado = false;
      this.data.validadoporId = null;
      this.data.validadoa = null;
      this.data.submissao = moment().isDST() ? moment.utc().add(1, "h") : moment.utc();
    }
    // Preenche informação do validado
    this.save().then((r) => {
      this.UI.addToast(`Relatório ${this.wasValidado == false ? "atualizado" : "criado"} com sucesso.`);
      if (this.wasValidado == true) {
        this.$state.go("app.elv.relatorios.details", { rep: r.id });
      }
      this.loadData();
    }).catch((err) => {
      this.UI.addToast("Erro na gravação de relatório. Por favor recarregue a página.");
      this.loaded = true;
      this.editingReport = true;
    });
  };

  validateReport = () => {
    if (this.data.validado) {
      this.UI.addToast("Relatório já se encontra validado.");
      return;
    }
    this.UI.showConfirm("Tem a certeza que pretende validar este relatório?").then((res) => {
      if (res) {
        this.loaded = false;
        this.ElvRelatorioJson.validate({id: this.data.id}).$promise.then((r) => {
          this.UI.addToast("Relatório validado com sucesso.");
          this.loadData();
        }).catch((err) => {
          console.log(err);
          this.UI.addToast("Erro na validação de relatório. Por favor recarregue a página.");
          this.loaded = true;
        });
      }
    }).catch((err) => {
    });
  };

  isElevador = () => {
    if (!this.loaded) return false;
    return this.data.processo.elevadorPed.equipamentoId == 2;
  };

  getResultado = (valor) => {
    return this.resultados.find((r) => r.value == valor);
  };

  showResult = () => {
    let options = {
      size: "lg",
      template: require("./result.dialog.html"),
      controller: ["$scope", ($scope) => {
        $scope.c1 = 0;
        $scope.c2 = 0;
        $scope.c3 = 0;
        $scope.c2_ = 0;
        $scope.realizada = this.data.jsonData.realizada;
        this.data.jsonData.topicos.forEach((topico) => {
          topico.pontos.forEach((ponto) => {
            ponto.clausulas.forEach((clausula) => {
              if (clausula.clausula.tipoId == 1) {
                $scope.c1 += 1;
              } else if (clausula.clausula.tipoId == 2) {
                $scope.c2 += 1;
              } else if (clausula.clausula.tipoId == 3) {
                $scope.c3 += 1;
              } else if (clausula.clausula.tipoId == 4) {
                $scope.c2_ += 1;
              }
            });
          });
        });
      }],
    };
    this.UI.showDialog(options);
  };

  previousVersion = () => {
    this.ElvRelatorioJson.findOne({
      filter: {
        where: {
          processoId: this.data.processoId,
          versao: {
            lt: this.data.versao,
          },
        },
        order: "versao DESC",
        limit: 1,
      },
    })
      .$promise.then((r) => {
        if (r) {
          this.$state.go("app.elv.relatorios.details", {
            rep: r.id,
          });
        } else {
          this.UI.addToast("Não existe uma versão mais antiga a esta");
        }
      })
      .catch(() => { });
  };

  nextVersion = () => {
    this.ElvRelatorioJson.findOne({
      filter: {
        where: {
          processoId: this.data.processoId,
          versao: {
            gt: this.data.versao
          },
        },
        order: "versao ASC",
        limit: 1
      }
    }).$promise.then((r) => {
      if (r) {
        this.$state.go("app.elv.relatorios.details", {rep: r.id});
      } else {
        this.UI.addToast("Não existe uma versão mais recente a esta");
      }
    }).catch(() => {
    });
  };

  recentVersion = () => {
    this.ElvRelatorioJson.findOne({
      filter: {
        where: {
          processoId: this.data.processoId
        },
        order: "versao DESC",
        limit: 1
      }
    }).$promise.then((r) => {
      if (r) {
        this.$state.go("app.elv.relatorios.details", {rep: r.id});
      } else {
        this.UI.addToast("Já se encontra na versão mais recente");
      }
    }).catch(() => {
    });
  };
}

DetalhesRelatorioController.$inject = ["$state", "$q", "AuthorizationService", "AuthenticationService", "UIService", "ElvCliente", "ElvRelatorioJson"];
