import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
export function DialogValidacao({ onClose, ...props }) {
    const handleOk = () => {
        onClose?.(true);
    };
    const handleCancel = () => {
        onClose?.();
    };
    return (<Dialog {...props} maxWidth={'lg'} fullWidth>
      <DialogTitle>Validar relatório</DialogTitle>
      <DialogContent>
        Tem a certeza que pretende validar este relatório?
      </DialogContent>
      <DialogActions>
        <Button type='button' onClick={handleCancel}>
          Cancelar
        </Button>
        <Button type='button' variant='contained' onClick={handleOk}>
          OK
        </Button>
      </DialogActions>
    </Dialog>);
}
