import React, { useMemo } from 'react';
import { useMaterialReactTable, MRT_Table } from 'material-react-table';
import { Box } from '@mui/material';
const SimpleTable = ({ colunas, dados }) => {
    const columns = useMemo(() => {
        return colunas.map((col, index) => ({
            accessorKey: `capacity_${index}`,
            header: col,
            size: 10
        }));
    }, [colunas]);
    const data = useMemo(() => {
        const aggregatedData = {};
        dados.forEach((d, index) => {
            aggregatedData[`capacity_${index}`] = d;
        });
        return [aggregatedData];
    }, [dados]);
    // Configuração da tabela
    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { density: 'compact' },
        enableColumnActions: false,
        enableColumnFilters: false,
        enablePagination: false,
        enableSorting: false,
        muiTableBodyRowProps: { hover: false },
        muiTableHeadCellProps: {
            align: 'center',
            sx: {
                border: '1px groove #e0e0e0', // Bordas para cabeçalho
            }
        },
        muiTableBodyCellProps: {
            align: 'center',
            sx: {
                border: '1px groove #e0e0e0' // Bordas para as células do corpo
            }
        }
    });
    return (<Box sx={{
            mt: 2,
            borderRadius: '15px',
            border: '1px solid #e0e0e0',
            overflow: 'hidden',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            bgcolor: '#F5F5F5'
        }}>
      <MRT_Table table={table}/>
    </Box>);
};
export default SimpleTable;
