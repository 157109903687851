export default class LmeService {
  constructor($q, UIService, Group, LmeEstadoProcesso, PRIClientes, PRIAJLClientes, PRICabecdoc, PRIAJLCabecdoc) {
    this.$q = $q;
    this.UI = UIService;
    this.Group = Group;
    this.LmeEstadoProcesso = LmeEstadoProcesso;
    this.PRIClientes = PRIClientes;
    this.PRIAJLClientes = PRIAJLClientes;
    this.PRICabecdoc = PRICabecdoc;
    this.PRIAJLCabecdoc = PRIAJLCabecdoc;
  }

  openProjectFolder = (data, empresaId) => {
    // Path to network for ENSAIOS and METROLOGIA
    if (!data)
      return "";
    let type, folder;
    if (data.Tipo && data.Tipo.descricao === "Metrologia") {
      type = "metrologia";
      folder = "CertificadosEmitidos";
    } else {
      type = "ensaios";
      folder = "Relatorios"
    }
    let network_path;
    let network_path_lme = "servernas\\comum\ lme";
    let network_path_ajl = "servernas\\comum\ ajl";

    // By default use shared folder LME unless specified by empresaId in processo
    if (!empresaId || empresaId === 1) // IEP
      network_path = network_path_lme;
    if (empresaId === 2) // AJL
      network_path = network_path_ajl;

    if (data.Raiz) {
      return `\\\\${network_path}\\${type}\\${folder}\\${data.Raiz.iidAno}\\${('0000' + data.Raiz.iidProc).slice(-4)}`;
    } else {
      return `\\\\${network_path}\\${type}\\${folder}\\${data.iidAno}\\${('0000' + data.iidProc).slice(-4)}`;
    }
  };

  // Return empresaId from tag (has to be sync)
  getEmpresa = (tag) => {
    switch(tag) {
      case 'IEP': return 1;
      case 'AJL': return 2;
      default: return 1;
    }
  }

  // Returns tipoId (metrologia, ensaio) for empresaId
  // centroCusto is used for IEP only
  getTipoId = (empresaId, centroCusto) => {
    if (!centroCusto) centroCusto = '';
    switch(empresaId) {
      case 1: return (centroCusto.indexOf('LME_CL') >= 0 ? 1: 2);
      case 2: return 2;
      default:
        if (centroCusto.length > 0) {
          return (centroCusto.indexOf('LME_CL') >= 0 ? 1: 2);
        } else {
          return 2;
        }
    }
  };

  // Get the right table for PRICabecdoc, depending on empresa.
  // The second argument is the field name (empresaId by default)
  getPRICabecdoc = (data, field) => {
    field = field || "empresaId"; // if field is filled use it, otherwise use empresaId
    switch(data[field]) {
      case 1: return this.PRICabecdoc;
      case 2: return this.PRIAJLCabecdoc;
      default: return this.PRICabecdoc;
    }
  };

  // Get the right table for PRIClientes, depending on empresa.
  // The second argument is the field name (empresaId by default)
  getPRIClientes = (data, field) => {
    field = field || "empresaId"; // if field is filled use it, otherwise use empresaId
    switch(data[field]) {
      case 1: return this.PRIClientes;
      case 2: return this.PRIAJLClientes;
      default: return this.PRIClientes;
    }
  };


  // Subroutine to get new estados for Metrologia
  getNewEstadosMetrologia = (estadoId) => {
    // return [1, 2, 3, 4, 5, 6, 7, 9, 10];
    // Discussed state transition for Metrologia
    switch(estadoId) {
      case 1: // Aguarda amostra(s)
        return [2, 10]; // Em agendamento, Cancelado
      case 2: // Em agendamento
        return [3, 10]; // Agendado, Cancelado
      case 3: // Agendado
        return [3, 4, 5, 10]; // Em execução, Cancelado
      case 4: // Em execução
        return [5, 6, 10]; // Aguarda emissão de relatório/certificado, Cancelado
      case 5: // Suspenso
        return [3, 4, 10]; // Em execução, Cancelado (this state should never happen in Metrologia)
      case 6: // Aguarda emissão de relatório/certificado
        return []; // Em execução(?) (this state should never happen in Metrologia)
      case 7: // Relatório/Certificado emitido
        return []; // Should never happen, so don't allow anything here
      case 8: // Em emissão de fatura
        return []; // Should never happen, so don't allow anything here
      case 9: // Concluido
        return []; // Should never happen, so don't allow anything here
      case 10: // Cancelado
        return []; // Should never happen, so don't allow anything here
      case 11: // Em validação
        return [9];
      default:
        return [1, 2, 3, 4, 5, 6, 7, 9, 10, 11];
    }
  };

  // Subroutine to get new estados for Ensaios
  getNewEstadosEnsaios = (estadoId) => {
    // return [1, 2, 3, 4, 5, 6, 7, 9, 10];
    // Discussed state transition for Metrologia
    switch(estadoId) {
      case 1: // Aguarda amostra(s)
        return [2, 10]; // Em agendamento, Cancelado
      case 2: // Em agendamento
        return [1, 3, 5, 10]; // Aguarda amostra(s), Agendado, Suspenso, Cancelado
      case 3: // Agendado
        return [3, 4, 5, 10]; // Em execução, Suspenso, Cancelado
      case 4: // Em execução
        return [3, 5, 6, 10, 11]; // Suspenso, Aguarda emissão de relatório/certificado, Cancelado
      case 5: // Suspenso
        return [2, 3, 4, 6, 10]; // Em agendamento, Agendado, Em execução, Aguarda emissão relatório, Cancelado
      case 6: // Aguarda emissão de relatório/certificado
        return []; // Cancelado, Em execução(?) (this state should never happen in Metrologia)
      case 7: // Relatório/Certificado emitido
        return [11]; // For cases when you can't know if another is needed
      case 8: // Em emissão de fatura
        return []; // Should never happen, so don't allow anything here
      case 9: // Concluido
        return []; // Should never happen, so don't allow anything here
      case 10: // Cancelado
        return []; // Should never happen, so don't allow anything here
      case 11: // Em validação
        return [4, 6, 9];
      default:
        return [1, 2, 3, 4, 5, 6, 7, 9, 10, 11];
    }
  };

  // Subroutine to get new estados for AJL
  getNewEstadosAJL = (estadoId) => {
    // return [1, 2, 3, 4, 5, 6, 7, 9, 10];
    // Discussed state transition for Metrologia
    switch(estadoId) {
      case 1: // Aguarda amostra(s)
        return [2, 10]; // Em agendamento, Cancelado
      case 2: // Em agendamento
        return [1, 3, 5, 10]; // Aguarda amostra(s), Agendado, Suspenso, Cancelado
      case 3: // Agendado
        return [3, 4, 5, 10]; // Em execução, Suspenso, Cancelado
      case 4: // Em execução
        return [3, 5, 6, 10, 11]; // Suspenso, Aguarda emissão de relatório/certificado, Cancelado
      case 5: // Suspenso
        return [2, 3, 4, 6, 10]; // Em agendamento, Agendado, Em execução, Aguarda emissão relatório, Cancelado
      case 6: // Aguarda emissão de relatório/certificado
        return []; // Em execução(?) (this state should never happen in Metrologia)
      case 7: // Relatório/Certificado emitido
        return [11]; // For cases when you can't know if another is needed
      case 8: // Em emissão de fatura
        return []; // Should never happen, so don't allow anything here
      case 9: // Concluido
        return []; // Should never happen, so don't allow anything here
      case 10: // Cancelado
        return []; // Should never happen, so don't allow anything here
      case 11: // Em validação
        return [4, 6, 9];
      default:
        return [1, 2, 3, 4, 5, 6, 7, 9, 10, 11];
    }
  };


  // Get new estados for processo, depending on type of processo, current state and empresa
  getNewEstadosProcesso = (tipoId, estadoId, empresaId) => {
    let defer = this.$q.defer();

    // Array with ids of possible next states
    let whereStates;

    if (empresaId === 1) { // IEP
      if (tipoId === 1) { // Metrologia
        whereStates = this.getNewEstadosMetrologia(estadoId);
      } else { // Ensaio
        whereStates = this.getNewEstadosEnsaios(estadoId);
      }
    } else { // AJL
      whereStates = this.getNewEstadosAJL(estadoId);
    }
    this.LmeEstadoProcesso.find({
      filter: {
        where: {
          and: [{active: 1}, {id: {inq: whereStates}}]
        }
      }
    }).$promise.then((estados) => {
      defer.resolve(estados);
    }).catch((error) => {
      console.log(error);
      defer.reject(error);
    });
    return defer.promise;
  };

  getFuncionariosWithGroups = (groupList) => {
    let defer = this.$q.defer();
    if (!groupList || groupList.length === 0) defer.resolve([]);

    const whereFilter = {
      or: groupList.map(name => ({name: {like: name}}))
    };

    this.Group.find({
      filter: {
        where: whereFilter,
        include: {
          relation: 'usergroup',
          scope: {
            include: 'user'
          }
        }
      }
    }).$promise.then((groups) => {
      let tecnicosLME = [];
      groups.forEach(g => {
        if (g.usergroup) {
          g.usergroup.forEach(u => {
            if (!tecnicosLME.find(x => x.id === u.user.id))
              tecnicosLME.push(u.user);
          });
        }
      });
      tecnicosLME.sort((a, b) => a.name.localeCompare(b.name));
      defer.resolve(tecnicosLME);
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };
}
LmeService.$inject = ['$q', 'UIService', 'Group', 'LmeEstadoProcesso', 'PRIClientes', 'PRIAJLClientes', 'PRICabecdoc', 'PRIAJLCabecdoc'];
