export default class DetalhesTopicosVerificacaoController {
  constructor($state, $q, UIService, $http, ElvPontoVerificacao, ElvListaTopicoPonto, ElvListaVerificacao, ElvListaRelatorio, ElvTopicoVerificacao) {
    this.UI = UIService;
    this.$http = $http;
    this.$state = $state;
    this.$q = $q;
    this.id = $state.params.id;
    this.td = $state.params.td;
    this.ElvPontoVerificacao = ElvPontoVerificacao;
    this.ElvListaTopicoPonto = ElvListaTopicoPonto;
    this.ElvListaVerificacao = ElvListaVerificacao;
    this.ElvListaTopicoPonto = ElvListaTopicoPonto;
    this.ElvTopicoVerificacao = ElvTopicoVerificacao;
    this.ElvListaRelatorio = ElvListaRelatorio;
    this.loaded = false;
    this.data = null;
    this.loadData();

    this.pontoOptions = {
      containment: 'parent',
      tolerance: 'pointer',
      handle: '.pmd-move-handle',
      'ui-floating': true,
      stop: (e, ui) => {
        for (let i in this.data.pontoRel) {
          this.data.pontoRel[i].ordemPonto = Number(i) + 1;
          ElvListaTopicoPonto.upsert(this.data.pontoRel[i]);
        }
      }
    };
  }

  remove = point => {
    this.UI.showConfirm(`Deseja removar o ponto "${point.ponto.designacao}"?`).then(r => {
      if (r) {
        point.active = 0;
        this.ElvListaTopicoPonto.upsert(point).$promise.then(r => {
          this.UI.addToast("Ponto removido");
          this.loadData();
        });
      }
    })
  };

  loadData = () => {
    this.loaded = false;
    this.ElvTopicoVerificacao.findOne({
      filter: {
        where: {
          active: true,
          id: this.td,
        },
        include: [{
            relation: 'pontoRel',
            scope: {
              where: {
                active: true,
                listaId: this.id,
                // Considerar todos os pontos menos os que têm id = 0 porque teve que se criar na tabela ElvListaTopicoPonto ainda sem nenhum ponto associado
                pontoId: {
                  neq: 0
                }
              },
              include: {
                relation: 'ponto',
                scope: {
                  where: {
                    active: 1
                  }
                }
              }
            }
          },
          {
            relation: 'topicoRel',
            scope: {
              where: {
                active: 1,
                listaId: this.id,
                // Considerar todos os tópicos
                pontoId: 0
              }
            }
          }
        ]
      }
    }).$promise.then(res => {
      this.data = res;
      this.loaded = true;
    })
  };

  addPonto = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./ponto.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.label = "Adicionar Ponto de Verificação";

        $scope.ponto = {
          id: 0,
          active: true,
          designacao: "",
          codigo: ""
        };

        $scope.ok = () => {
          $scope.$close($scope.ponto);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    }).then(res => {
      if (res) {
        this.ElvPontoVerificacao.create(res).$promise.then((ponto) => {
          this.ElvListaTopicoPonto.find({
            filter: {
              where: {
                listaId: this.id,
                topicoId: this.td.id,
                active: true,
              },
              order: 'id DESC',
              limit: 1
            }
          }).$promise.then((res) => {
            let ordemPonto = 1;
            if (res.length === 1) {
              ordemPonto = res[0].ordemPonto + 1;
            }
            let p = {
              ordemTopico: res[0].ordemTopico,
              listaId: this.id,
              active: true,
              pontoId: ponto.id,
              id: 0,
              topicoId: res[0].id,
              ordemPonto: ordemPonto
            };
            this.ElvListaTopicoPonto.create(p).$promise.then(() => {
              this.loadData();
              this.UI.addToast('Ponto adicionado com sucesso');
            }).catch((error) => {
              console.log(error);
              this.UI.addToast('Erro ao adicionar ponto');
            })
          });
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Erro ao adicionar tópico');
        })
      }
    });
  };

  assignPontos = () => {
    let a = angular.copy(this.data.pontoRel);
    let nin = [];
    a.forEach(f => {
      nin.push(f.pontoId);
    });
    let dialog = this.UI.showDialog({
      size: 'lg',
      controller: ['$scope', 'ElvPontoVerificacao', ($scope, ElvPontoVerificacao) => {
        $scope.label = "Adicionar Ponto de Verificação";

        $scope.data = {
          selected: []
        };

        ElvPontoVerificacao.find({
          filter: {
            where: {
              id: {
                nin: nin
              },
              active: true
            }
          }
        }).$promise.then(res => {
          $scope.hasLoaded = true;
          $scope.pontos = res;
        });
        $scope.cancel = () => $scope.$dismiss('cancel');

        $scope.ok = () => {
          $scope.$close($scope.data);
        };

        //Infinite Scroll Magic
        $scope.infiniteScroll = {};
        $scope.infiniteScroll.numToAdd = 20;
        $scope.infiniteScroll.currentItems = 20;

        $scope.addMoreItems = function () {
          $scope.infiniteScroll.currentItems += $scope.infiniteScroll.numToAdd;
        };
      }],
      template: require('./ponto.dialog.html')
    });

    dialog.then(res => {
      this.ElvListaVerificacao.findOne({
        filter: {
          where: {
            id: this.id,
            active: true,
          },
          include: {
            relation: 'topicoRel',
            scope: {
              where: {
                active: 1
              },
              order: 'ordemPonto ASC',
              include: {
                relation: 'ponto',
                scope: {
                  where: {
                    active: 1
                  }
                }
              }
            }
          }
        }
      }).$promise.then((listaVerificacaoAtual) => {
        this.ElvListaRelatorio.find({
          filter: {
            where: {
              listaId: listaVerificacaoAtual.id,
              active: true,
            }
          }
        }).$promise.then((listaRelatorios) => {
          //Se ja existe relatorio cria lista nova com nova versão superior à anterior
          if (listaRelatorios && listaRelatorios.length > 0) {
            let listaNova = {
              id: 0,
              normaId: listaVerificacaoAtual.normaId,
              observacoes: listaVerificacaoAtual.observacoes,
              numeroVersao: listaVerificacaoAtual.numeroVersao + 1,
              active: true,
            };
            this.ElvListaVerificacao.create(listaNova).$promise.then((li) => {
              listaVerificacaoAtual.topicoRel.forEach(t => {
                let data = {
                  id: 0,
                  topicoId: t.topicoId,
                  listaId: li.id,
                  active: true,
                  pontoId: t.pontoId,
                  ordemTopico: t.ordemTopico,
                  ordemPonto: t.ordemPonto
                };
                this.ElvListaTopicoPonto.create(data).$promise.then((listaTopicoPonto) => {}).catch((error) => {
                  console.log(error);
                  this.UI.addToast('Erro ao adicionar tópico de verificação.');
                });
              });
              if (res.selected.length > 0) {
                let tasks = [];
                res.selected.forEach((s, i) => {
                  let defer = this.$q.defer();
                  let data = {
                    id: 0,
                    listaId: li.id,
                    topicoId: this.td,
                    pontoId: s.id,
                    ordemTopico: this.data.topicoRel[0].ordemTopico,
                    ordemPonto: this.data.pontoRel.length + i + 1,
                    active: true
                  };
                  this.ElvListaTopicoPonto.create(data).$promise.then((res) => {
                    defer.resolve(res);
                  }).catch((error) => {
                    console.log(error);
                    defer.reject(error);
                    this.UI.addToast('Erro ao adicionar ponto de verificação');
                  });
                  tasks.push(defer.promise);
                });
                this.$q.all(tasks).then((res) => {
                  this.$state.go('app.elv.listasverificacao.details', {
                    id: li.id
                  });
                }).catch(e => {
                  // Do something when at least one count fails
                });
              }
            }).catch((error) => {
              console.log(error);
              this.UI.addToast('Erro ao criar lista de verificação.');
            });
          } else { //Se não existir relatório adicionam-se tópicos e pontos à versão atual
            if (res.selected.length > 0) {
              let tasks = [];
              res.selected.forEach((s, i, t) => {
                let defer = this.$q.defer();
                let data = {
                  id: 0,
                  listaId: listaVerificacaoAtual.id,
                  topicoId: this.td,
                  pontoId: s.id,
                  ordemTopico: this.data.topicoRel[0].ordemTopico,
                  ordemPonto: this.data.pontoRel.length + i + 1,
                  active: true
                };
                this.ElvListaTopicoPonto.create(data).$promise.then((res) => {
                  defer.resolve(res);
                }).catch((error) => {
                  console.log(error);
                  defer.reject(error);
                  this.UI.addToast('Erro ao adicionar ponto de verificação');
                });
                tasks.push(defer.promise);
              });
              this.$q.all(tasks).then((res) => {
                this.loadData();
              }).catch(e => {
                // Do something when at least one count fails
              });
            }
          }
        });
      });
    });
  };

  editPonto = (rr) => {
    let cenas = this.UI.showWaiting();
    this.ElvPontoVerificacao.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((rcm) => {
      cenas.close();
      this.UI.showDialog({
        size: 'lg',
        template: require('./ponto.dialog.html'),
        controller: ['$scope', '$dialog', function ($scope, $dialog) {
          $scope.label = "Editar Ponto de Verificação";

          $scope.ponto = {
            id: rr.id,
            designacao: rr.designacao,
            active: true,
            codigo: rr.codigo
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      }).then(r => {
        if (r) {
          this.loaded = false;
          this.ElvPontoVerificacao.findOne({
            filter: {
              where: {
                id: rr.id,
                active: 1
              }
            }
          }).$promise.then((ponto) => {
            ponto.designacao = r.ponto.designacao;
            ponto.codigo = r.ponto.codigo;
            ponto.$save().then((res) => {
              this.loadData();
              this.UI.addToast('Ponto atualizado com sucesso');
            }, (error) => {
              console.log(error);
              this.UI.addToast('Erro ao atualizar ponto');
              this.loadData();
            });
          }).catch((error) => {
            console.log(error);
            this.UI.addToast('Não foi possível encontrar este ponto neste tópico de verificação.');
            this.loadData();
          });
        }
      })
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar pontos de verificação. Por favor tente mais tarde.");
    });
  };
};

DetalhesTopicosVerificacaoController.$inject = ['$state', '$q', 'UIService', '$http', 'ElvPontoVerificacao', 'ElvListaTopicoPonto', 'ElvListaVerificacao', 'ElvListaRelatorio', 'ElvTopicoVerificacao'];
