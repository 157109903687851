export default class AtvSubfamiliaDetailsController {
  constructor($state, $filter, UIService, AuthorizationService, AtvSubfamilia, AtvFamilia, AtvTipoAtivo, AtvAtivo, AtvAtivosService) {
    this.state = $state;
    this.filter = $filter;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.AtvSubfamilia = AtvSubfamilia;
    this.AtvFamilia = AtvFamilia;
    // this.AtvTipoAtivo = AtvTipoAtivo;
    this.AtvAtivo = AtvAtivo;
    this.AtvAtivosService = AtvAtivosService;
    this.sf = $state.params.sf;
    this.loaded = false;
    this.subfamilia = null;

    // Default opt state - populate later when we have all states
    this.optAtivos = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "id",
      sort: "desc",
      filter: {}
    };
    this.ativos = null;
    this.ativosLoading = true;

    this.id = $state.params.id;
    if (angular.isUndefined($state.params.id) || isNaN($state.params.id)) {
      this.state.go('app.ativos.familias.list');
      this.UI.addToast('Família desconhecida/inválida');
    } else {
      if (angular.isUndefined($state.params.sf) || isNaN($state.params.sf)) {
        this.state.go('app.ativos.familias.details', {id: this.id});
        this.UI.addToast('Subfamília desconhecida/inválida');
      } else {
        this.loadData();
      }
    }
  }

  loadData = () => {
    this.loaded = false;
    this.AtvSubfamilia.findOne({
      filter: {
        where: {
          id: this.sf,
          familiaId: this.id,
          active: true
        },
        include: [{
          relation: 'familia',
          scope: {
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then((subfamilia) => {
      this.subfamilia = subfamilia;
      this.loaded = true;
    }).catch(() => {
      this.state.go('app.ativos.familias.details', {id: this.id});
      this.UI.addToast('Erro a carregar dados de familia.');
    });

    this.getAtivosForSubfamilia();

  };

  editObservations = () => {
    let obs = angular.copy(this.subfamilia.observacoes);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = obs;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.loaded = false;
      this.subfamilia.observacoes = res;
      this.subfamilia.$save().then(() => {
        this.UI.addToast('Observações guardadas com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro a guardar as observações. Por favor tente mais tarde.');
        this.loadData();
      })
    })
  };

  editSubfamilia = () => {
    let subfamilia = angular.copy(this.subfamilia);
    let options = {
      size: 'lg',
      template: require('./subfamilia.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Subfamília";
        scope.subfamilia = subfamilia;

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      this.loaded = false;
      if (res && res.subfamilia) {
        this.AtvSubfamilia.upsert(res.subfamilia).$promise.then((res) => {
          this.UI.addToast("Subfamília editada com sucesso");
          this.loadData();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na edição de subfamília. Por favor tente mais tarde.");
        });
      }
    });
  };

  getAtivosForSubfamilia = () => {
    this.ativosLoading = true;
    let whereLiteral = {
      'AtvAtivo.familiaId': this.id,
      'AtvAtivo.subfamiliaId': this.sf,
      'AtvAtivo.active': 1
    };
    let where = {};
    Object.keys(this.optAtivos.filter).forEach(f => {
      if (this.optAtivos.filter[f])
        where[f] = this.optAtivos.filter[f];
    });

    this.AtvAtivosService.setWherePermFields({}, 'AtvAtivo.entidadeProprietariaId').then(w => {
      let wherePerms = w.wherePerms;
      this.AtvAtivo.table({
        params: {
          fields: [
            'AtvAtivo.id as id',
            'AtvAtivo.iidDisplay as iidDisplay',
            'AtvAtivo.designacao as designacao',
            'AtvAtivo.referencia as referencia',
            'AtvMarca.designacao as marca',
            'AtvFornecedorPrimavera.refFornecedorPrimavera as refFornecedorPrimavera',
            'AtvEntidadeProprietaria.designacao as entidadeProprietaria',
            'AtvEP2.sigla as sigla'
          ],
          from: ['AtvAtivo', 'AtvMarca', 'AtvFornecedorPrimavera', 'AtvEntidadeProprietaria', 'AtvEntidadeProprietaria'],
          references: [undefined, 'AtvAtivo.marcaId', 'AtvAtivo.fornecedorPrimaveraId', 'AtvAtivo.entidadeProprietariaId', 'AtvFornecedorPrimavera.entidadeProprietariaId'],
          aliases: [null, null, null, null, 'AtvEP2'],
          where: where,
          whereLiteral: whereLiteral,
          wherePerms: wherePerms,
          order: this.optAtivos.order,
          sort: this.optAtivos.sort,
          limit: this.optAtivos.items,
          skip: (this.optAtivos.page - 1) * this.optAtivos.items,
        }
      }).$promise.then(res => {
        let page = this.optAtivos.page;
        let items = this.optAtivos.items;

        let total = res.count;

        this.optAtivos.start = total > 0 ? (page - 1) * items + 1 : 0;
        if ((this.optAtivos.start - 1 + items) >= total) {
          this.optAtivos.end = total;
        } else {
          this.optAtivos.end = Number.parseInt(this.optAtivos.start - 1 + items);
        }

        this.ativos = res.ativos;
        this.optAtivos.total = total;
        this.ativosLoading = false;
      }).catch((e) => {
        console.log(e);
        this.UI.addToast("Não foi possível ler ativos. Por favor tente mais tarde");
      });
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler permissões. Por favor tente mais tarde");
    });
  };

  applyFilterAtivos = () => {
    this.optAtivos.page = 1;
    this.getAtivosForSubfamilia();
  };

  ativosSort = keyname => {
    if (this.optAtivos.order === keyname)
      this.optAtivos.page = 1;
    this.optAtivos.order = keyname;
    this.optAtivos.sort = this.optAtivos.sort === 'asc' ? 'desc' : 'asc';
    this.getAtivosForSubfamilia();
  };

  ativosItem = val => {
    this.optAtivos.items = val;
    this.optAtivos.page = 1;
    this.getAtivosForSubfamilia();
  };

  ativosPage = sum => {
    this.optAtivos.page += sum;
    if (this.optAtivos.page < 1)
      this.optAtivos.page = 1;
    if (this.optAtivos.page > Math.ceil(this.optAtivos.total / this.optAtivos.items))
      this.optAtivos.page = Math.ceil(this.optAtivos.total / this.optAtivos.items);
    this.getAtivosForSubfamilia();
  };

  viewAtivo = r => {
    this.state.go('app.ativos.ativos.details', {id: r.id});
  };

}
AtvSubfamiliaDetailsController.$inject = ['$state', '$filter', 'UIService', 'AuthorizationService', 'AtvSubfamilia', 'AtvFamilia', 'AtvTipoAtivo', 'AtvAtivo', 'AtvAtivosService'];

// CONTROLLER BEFORE TIPOS ATIVOS WERE REMOVED
// export default class AtvSubfamiliaDetailsController {
//   constructor($state, $filter, UIService, AuthorizationService, AtvSubfamilia, AtvFamilia, AtvTipoAtivo, AtvAtivo) {
//     this.state = $state;
//     this.filter = $filter;
//     this.UI = UIService;
//     this.AuthorizationService = AuthorizationService;
//     this.AtvSubfamilia = AtvSubfamilia;
//     this.AtvFamilia = AtvFamilia;
//     this.AtvTipoAtivo = AtvTipoAtivo;
//     this.AtvAtivo = AtvAtivo;
//     this.sf = $state.params.sf;
//     this.loaded = false;
//     this.subfamilia = null;
//
//     // Default opt state - populate later when we have all states
//     this.optTiposAtivo = {
//       total: 0,
//       start: 0,
//       end: 0,
//       page: 1,
//       items: 10,
//       order: "designacao",
//       sort: "asc",
//       filter: {}
//     };
//     this.tiposAtivo = null;
//     this.tiposAtivoLoading = true;
//
//     this.id = $state.params.id;
//     if (angular.isUndefined($state.params.id) || isNaN($state.params.id)) {
//       this.state.go('app.ativos.familias.list');
//       this.UI.addToast('Família desconhecida/inválida');
//     } else {
//       if (angular.isUndefined($state.params.sf) || isNaN($state.params.sf)) {
//         this.state.go('app.ativos.familias.details', {id: this.id});
//         this.UI.addToast('Subfamília desconhecida/inválida');
//       } else {
//         this.loadData();
//       }
//     }
//   }
//
//   loadData = () => {
//     this.loaded = false;
//     this.AtvSubfamilia.findOne({
//       filter: {
//         where: {
//           id: this.sf,
//           familiaId: this.id,
//           active: true
//         },
//         include: [{
//           relation: 'familia',
//           scope: {
//             where: {
//               active: 1
//             }
//           }
//         }]
//       }
//     }).$promise.then((subfamilia) => {
//       this.subfamilia = subfamilia;
//       this.loaded = true;
//     }).catch(() => {
//       this.state.go('app.ativos.familias.details', {id: this.id});
//       this.UI.addToast('Erro a carregar dados de familia.');
//     });
//
//     this.getTiposAtivoForSubfamilia();
//
//   };
//
//   editObservations = () => {
//     let obs = angular.copy(this.subfamilia.observacoes);
//     let dialog = this.UI.showDialog({
//       size: 'lg',
//       template: require('./observations.dialog.html'),
//       controller: ['$scope', $scope => {
//         $scope.observacoes = obs;
//
//         $scope.ok = () => $scope.$close($scope.observacoes);
//
//         $scope.cancel = () => $scope.$dismiss('cancel');
//       }]
//     });
//     dialog.then(res => {
//       this.loaded = false;
//       this.subfamilia.observacoes = res;
//       this.subfamilia.$save().then(() => {
//         this.UI.addToast('Observações guardadas com sucesso');
//         this.loadData();
//       }).catch(() => {
//         this.UI.addToast('Ocorreu um erro a guardar as observações. Por favor tente mais tarde.');
//         this.loadData();
//       })
//     })
//   };
//
//   editSubfamilia = () => {
//     let subfamilia = angular.copy(this.subfamilia);
//     let options = {
//       size: 'lg',
//       template: require('./subfamilia.dialog.html'),
//       controller: ['$dialog', '$scope', (dialog, scope) => {
//         scope.title = "Editar Subfamília";
//         scope.subfamilia = subfamilia;
//
//         scope.ok = () => {
//           dialog.close(scope);
//         };
//
//         scope.cancel = () => {
//           dialog.dismiss('cancel');
//         };
//       }]
//     };
//
//     let modal = this.UI.showDialog(options);
//
//     modal.then((res) => {
//       this.loaded = false;
//       if (res && res.subfamilia) {
//         this.AtvSubfamilia.upsert(res.subfamilia).$promise.then((res) => {
//           this.UI.addToast("Subfamília editada com sucesso");
//           this.loadData();
//         }).catch((error) => {
//           console.log(error);
//           this.UI.addToast("Erro na edição de subfamília. Por favor tente mais tarde.");
//         });
//       }
//     });
//   };
//
//   addTipoAtivo = () => {
//     let cenas = this.UI.showWaiting();
//     let self = this;
//     let familiaId = this.id;
//     this.AtvSubfamilia.find({
//       filter: {
//         where: {
//           familiaId: familiaId,
//           active: 1
//         }
//       }
//     }).$promise.then((s) => {
//       cenas.close();
//       let options = {
//         size: 'lg',
//         template: require('./tipoativo.new.dialog.html'),
//         controller: ['$dialog', '$scope', (dialog, scope) => {
//           scope.title = "Novo Tipo de Ativo";
//           scope.newAtivo = true;
//           scope.tipoAtivo = {};
//           scope.auxFamilia = {selected: self.subfamilia.familia};
//           scope.subfamilias = s;
//           scope.auxSubfamilia = {selected: self.subfamilia};
//
//           scope.ok = () => {
//             dialog.close(scope);
//           };
//
//           scope.cancel = () => {
//             dialog.dismiss('cancel');
//           };
//         }]
//       };
//
//       let modal = this.UI.showDialog(options);
//
//       modal.then((res) => {
//         if (res && res.tipoAtivo) {
//           res.tipoAtivo.id = 0;
//           res.tipoAtivo.familiaId = this.id;
//           if (res.auxSubfamilia.selected && res.auxSubfamilia.selected.id) {
//             res.tipoAtivo.subfamiliaId = res.auxSubfamilia.selected.id;
//           }
//           res.tipoAtivo.active = 1;
//           this.AtvTipoAtivo.create(res.tipoAtivo).$promise.then((res) => {
//             this.UI.addToast("Tipo de Ativo criado com sucesso");
//             this.loadData();
//           }).catch((error) => {
//             console.log(error);
//             this.UI.addToast("Erro na criação de tipo de ativo. Por favor tente mais tarde.");
//           });
//         }
//       });
//     }).catch((error) => {
//       console.log(error);
//       cenas.close();
//     });
//   };
//
//   editTipoAtivo = (r) => {
//     let cenas = this.UI.showWaiting();
//     this.AtvTipoAtivo.findOne({
//       filter: {
//         where: {
//           id: r.id,
//           active: 1
//         },
//         include: [{
//           relation: 'familia',
//           scope: {
//             where: {
//               active: 1
//             }
//           }
//         }, {
//           relation: 'subfamilia',
//           scope: {
//             where: {
//               active: 1
//             }
//           }
//         }]
//       }
//     }).$promise.then((tipoAtivo) => {
//       this.AtvFamilia.find({
//         filter: {
//           where: {
//             active: 1
//           },
//           order: 'ordem ASC',
//           include: {
//             relation: 'subfamilias',
//             scope: {
//               where: {
//                 active: 1
//               },
//               order: 'ordem ASC'
//             }
//           }
//         }
//       }).$promise.then((s) => {
//         cenas.close();
//         let options = {
//           size: 'lg',
//           template: require('./tipoativo.new.dialog.html'),
//           controller: ['$dialog', '$scope', (dialog, scope) => {
//             scope.title = "Editar Tipo de Ativo";
//             scope.newAtivo = false;
//             scope.tipoAtivo = angular.copy(tipoAtivo);
//             scope.familias = s;
//             scope.subfamilias = _.find(scope.familias, {id: scope.tipoAtivo.familiaId}).subfamilias;
//             scope.auxFamilia = {selected: scope.tipoAtivo.familia};
//             scope.auxSubfamilia = {selected: scope.tipoAtivo.subfamilia};
//
//             scope.onFamiliaSelected = (f) => {
//               scope.auxSubfamilia.selected = null;
//               scope.subfamilias = f.subfamilias;
//             };
//
//             scope.ok = () => {
//               dialog.close(scope);
//             };
//
//             scope.cancel = () => {
//               dialog.dismiss('cancel');
//             };
//           }]
//         };
//
//         let modal = this.UI.showDialog(options);
//         modal.then((res) => {
//           if (res && res.tipoAtivo) {
//             res.tipoAtivo.familiaId = res.auxFamilia.selected.id;
//             res.tipoAtivo.familia = res.auxFamilia.selected;
//             if (res.auxSubfamilia.selected && res.auxSubfamilia.selected.id) {
//               res.tipoAtivo.subfamiliaId = res.auxSubfamilia.selected.id;
//               res.tipoAtivo.subfamilia = res.auxSubfamilia.selected;
//             } else {
//               res.tipoAtivo.subfamiliaId = null;
//               res.tipoAtivo.subfamilia = null;
//             }
//             res.tipoAtivo.active = 1;
//             this.AtvTipoAtivo.upsert(res.tipoAtivo).$promise.then((res) => {
//               this.UI.addToast("Tipo de Ativo editado com sucesso");
//               this.loadData();
//             }).catch((error) => {
//               console.log(error);
//               this.UI.addToast("Erro na edição de tipo de ativo. Por favor tente mais tarde.");
//             });
//           }
//         });
//       }).catch((error) => {
//         console.log(error);
//         this.UI.addToast("Não foi possível editar tipo de ativo. Por favor tente mais tarde.");
//         cenas.close();
//       });
//     }).catch((error) => {
//       console.log(error);
//       this.UI.addToast("Tipo de Ativo não encontrado. Por favor recarregue a página");
//       cenas.close();
//     });
//   };
//
//   checkDeleteTipoAtivo = (a) => {
//     let cenas = this.UI.showWaiting();
//     this.AtvTipoAtivo.findOne({
//       filter: {
//         where: {
//           id: a.id,
//           active: 1
//         }
//       }
//     }).$promise.then((tipoAtivoToRemove) => {
//       // Find if we have something that might block deletion
//       this.AtvAtivo.count({
//         fields: {id: true},
//         where: {
//           active: 1,
//           tipoAtivoId: tipoAtivoToRemove.id
//         }
//       }).$promise.then((ativos) => {
//         cenas.close();
//         if (ativos.count > 0) {
//           let msg = "Não é possível remover tipo de ativo.\nExiste" + (ativos.count > 1 ? "m " : " ");
//           msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")) : "";
//           msg += "associado" + (ativos.count > 1 ? "s" : "") + " a este tipo de ativo.";
//           this.UI.showAlert(msg);
//         } else {
//           this.confirmDeleteTipoAtivo(a);
//         }
//       }).catch((error) => {
//         console.log(error);
//         this.UI.addToast("De momento não é possível remover tipo de ativo. Por favor tente mais tarde");
//       });
//     }).catch(error => {
//       console.log(error);
//       this.UI.addToast("De momento não é possível remover tipo de ativo. Por favor tente mais tarde");
//     });
//   };
//
//   confirmDeleteTipoAtivo = (a) => {
//     let title = "Eliminar Tipo de Ativo";
//     let warning = "Tem a certeza que pretende remover este tipo de ativo?";
//     let instance = this.UI.showDialog({
//       size: 'lg',
//       template: require('../../../interface/modals/delete-selected.html'),
//       controller: ['$scope', function ($scope) {
//         $scope.title = title;
//         $scope.message = warning;
//         $scope.ok = function () {
//           $scope.$close();
//         };
//         $scope.cancel = function () {
//           $scope.$dismiss('cancel');
//         };
//       }]
//     });
//
//     instance.then(() => {
//       this.removeTipoAtivo(a);
//     }, (err) => {
//       if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
//         console.log(err);
//     })
//   };
//
//   removeTipoAtivo = (a) => {
//     this.loaded = false;
//     // Find again familia to delete so we can update it
//     this.AtvTipoAtivo.findOne({
//       filter: {
//         where: {
//           id: a.id,
//           active: 1
//         }
//       }
//     }).$promise.then((tipoAtivoToRemove) => {
//       tipoAtivoToRemove.active = 0;
//       tipoAtivoToRemove.$save().then((res) => {
//         this.UI.addToast("Tipo de Ativo eliminado com sucesso!");
//         this.loadData();
//       }, (error) => {
//         console.log(error);
//         this.loaded = false;
//         this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
//       });
//     }).catch((error) => {
//       console.log(error);
//       this.UI.addToast("Não foi possível eliminar o tipo de ativo. Verifique se ainda existe.");
//       this.loadData();
//     });
//   };
//
//   getTiposAtivoForSubfamilia = () => {
//     this.tiposAtivoLoading = true;
//     let whereLiteral = {
//       'AtvTipoAtivo.familiaId': this.id,
//       'AtvTipoAtivo.subfamiliaId': this.sf,
//       'AtvTipoAtivo.active': 1
//     };
//     let where = {};
//     Object.keys(this.optTiposAtivo.filter).forEach(f => {
//       if (this.optTiposAtivo.filter[f])
//         where[f] = this.optTiposAtivo.filter[f];
//     });
//     this.AtvTipoAtivo.table({
//       params: {
//         fields: [
//           'AtvTipoAtivo.id as id',
//           'AtvTipoAtivo.designacao as designacao'
//         ],
//         from: ['AtvTipoAtivo'],
//         references: [undefined],
//         where: where,
//         whereLiteral: whereLiteral,
//         order: this.optTiposAtivo.order,
//         sort: this.optTiposAtivo.sort,
//         limit: this.optTiposAtivo.items,
//         skip: (this.optTiposAtivo.page - 1) * this.optTiposAtivo.items,
//       }
//     }).$promise.then(res => {
//       let page = this.optTiposAtivo.page;
//       let items = this.optTiposAtivo.items;
//
//       let total = res.count;
//
//       this.optTiposAtivo.start = total > 0 ? (page - 1) * items + 1 : 0;
//       if ((this.optTiposAtivo.start - 1 + items) >= total) {
//         this.optTiposAtivo.end = total;
//       } else {
//         this.optTiposAtivo.end = Number.parseInt(this.optTiposAtivo.start - 1 + items);
//       }
//
//       this.tiposAtivo = res.tiposAtivo;
//       this.optTiposAtivo.total = total;
//       this.tiposAtivoLoading = false;
//     }).catch((e) => {
//       console.log(e);
//       this.UI.addToast("Não foi possível ler tipos de ativo. Por favor tente mais tarde");
//     });
//   };
//
//   applyFilterTiposAtivo = () => {
//     this.optTiposAtivo.page = 1;
//     this.getTiposAtivoForSubfamilia();
//   };
//
//   tiposAtivoSort = keyname => {
//     if (this.optTiposAtivo.order === keyname)
//       this.optTiposAtivo.page = 1;
//     this.optTiposAtivo.order = keyname;
//     this.optTiposAtivo.sort = this.optTiposAtivo.sort === 'asc' ? 'desc' : 'asc';
//     this.getTiposAtivoForSubfamilia();
//   };
//
//   tiposAtivoItem = val => {
//     this.optTiposAtivo.items = val;
//     this.optTiposAtivo.page = 1;
//     this.getTiposAtivoForSubfamilia();
//   };
//
//   tiposAtivoPage = sum => {
//     this.optTiposAtivo.page += sum;
//     if (this.optTiposAtivo.page < 1)
//       this.optTiposAtivo.page = 1;
//     if (this.optTiposAtivo.page > Math.ceil(this.optTiposAtivo.total / this.optTiposAtivo.items))
//       this.optTiposAtivo.page = Math.ceil(this.optTiposAtivo.total / this.optTiposAtivo.items);
//     this.getTiposAtivoForSubfamilia();
//   };
//
//   viewTipoAtivo = r => {
//     this.state.go('app.ativos.tipos.details', {id: r.id});
//   };
//
// }
// AtvSubfamiliaDetailsController.$inject = ['$state', '$filter', 'UIService', 'AuthorizationService', 'AtvSubfamilia', 'AtvFamilia', 'AtvTipoAtivo', 'AtvAtivo'];
