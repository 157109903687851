import React, { useEffect, useState } from 'react';
import { Alert, Box, Breadcrumbs, Card, CardContent, CardHeader, IconButton, Link, ListItemAvatar, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import { mdiPlus, mdiPencil, mdiDelete } from '@mdi/js';
import DialogEnsaio from './dialog';
import { DraggableList, MdiIcon } from '@components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { reorder } from '../../../util';
const DetailsRelatorioEnsaio = ({ $state, ListaRelatorioService }) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState($state.params.editable);
    const [pontos, setPontos] = useState([]);
    // Dialog ponto de verificacao
    const [pDialog, setPDialog] = useState({
        open: false
    });
    // Detalhes
    const validationSchema = yup.object({
        codigo: yup.string().required('O campo é obrigatório'),
        designacao: yup.string().required('O campo é obrigatório'),
        observacoes: yup.string()
    });
    const formik = useFormik({
        initialValues: data,
        enableReinitialize: true,
        validateOnMount: false,
        onSubmit: async (values) => { },
        validationSchema
    });
    const fetchData = async () => {
        setLoading(true);
        try {
            const aux = await ListaRelatorioService.findEnsaio($state.params.id);
            setPontos(aux.Pontos);
            formik.setValues(aux);
            setData(aux);
            setLoading(false);
        }
        catch (e) {
            console.log(e);
        }
    };
    const onDialogClose = (item) => {
        setPDialog({ open: false });
    };
    const onDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination)
            return;
        // Altera ordem
        const newItems = reorder(pontos, source.index, destination.index);
        pontos.forEach((r, i, a) => {
            r.ordem = i + 1;
        });
        setPontos(newItems);
    };
    useEffect(() => {
        if (!loading)
            fetchData();
    }, [data]);
    return (<Box>
      <DialogEnsaio open={pDialog.open} initial={pDialog.initial} onClose={onDialogClose}/>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>{loading ? 'A carregar...' : data && data.designacao}</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Motor de relatórios
          </Link>
          <Link underline='hover' onClick={() => $state.go('app.administration.lists.list')} color='text.primary' aria-current='page'>
            Listas de relatórios
          </Link>
          {/* <Link underline='hover' onClick={() => $state.go('app.administration.lists.view', { id: $state.params.lista })}>
          {$state.params.lista}
        </Link> */}
          <Link underline='none' color='inherit'>
            Ensaios
          </Link>
          {/* <Link underline='hover' color='text.primary' aria-current='page'>
          {loading ? <CircularProgress size={16} /> : $state.params.id}
        </Link> */}
        </Breadcrumbs>
      </Box>
      <Box>
        {editing ? (<Alert sx={{ mb: 1, mt: 1 }} severity='error'>
            A editar ensaio, utilize o botão no canto inferior direito para guardar alterações
          </Alert>) : undefined}

        {!loading ? (<Box>
            <Box>
              <Grid container spacing={1}>
                <Grid lg={12} xs={12}>
                  <form>
                    <Card sx={{ mb: 1 }}>
                      <CardHeader title='Detalhes'/>
                      <CardContent>
                        <Grid container spacing={2} sx={{ mb: 1 }}>
                          <Grid lg={2} md={3} xs={8}>
                            <TextField label='Código' InputProps={{
                readOnly: !editing /*  */
            }} fullWidth defaultValue={formik.values?.codigo ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.codigo && Boolean(formik.errors.codigo)} helperText={formik.touched.codigo && formik.errors.codigo}/>
                          </Grid>
                          <Grid lg={10} md={8} xs={8}>
                            <TextField label='Designação' InputProps={{
                readOnly: !editing
            }} fullWidth defaultValue={formik.values?.designacao ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
                          </Grid>
                          <Grid lg={12} md={12} xs={12}>
                            <TextField label='Informação Adicional' InputProps={{
                readOnly: !editing
            }} onChange={(event) => { }} fullWidth value={''}/>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </form>
                </Grid>
              </Grid>
            </Box>
            <Card sx={{ mb: 1 }}>
              <CardHeader title='Pontos de verificação' subheader='Pode alterar a ordem dos pontos, arrastando o item para cima ou para baixo' action={editing && (<Tooltip title='Adicionar novo ponto'>
                      <IconButton aria-label='settings' onClick={() => {
                    setPDialog({
                        open: true,
                        initial: {
                            id: 0,
                            coreListaRelatorioEnsaioId: $state.params.id
                        }
                    });
                }}>
                        <MdiIcon path={mdiPlus}/>
                      </IconButton>
                    </Tooltip>)}/>

              <DraggableList disabled={!editing} itemKey={'id'} items={pontos.filter(r => r.active == 1)} render={item => {
                return [
                    <ListItemAvatar>
                      <Typography>{item.codigo}</Typography>
                    </ListItemAvatar>,
                    <ListItemText primary={item.designacao} /*  *//>
                ];
            }} action={item => {
                return (editing && (<Box sx={{ display: 'flex' }}>
                        <Tooltip title='Editar ponto'>
                          <IconButton onClick={() => {
                        setPDialog({
                            open: true,
                            initial: item
                        });
                    }}>
                            <MdiIcon path={mdiPencil}/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Eliminar ponto'>
                          <IconButton onClick={() => { }}>
                            <MdiIcon path={mdiDelete}/>
                          </IconButton>
                        </Tooltip>
                      </Box>));
            }} onDragEnd={onDragEnd}/>
            </Card>
          </Box>) : undefined}
      </Box>
    </Box>);
};
export default DetailsRelatorioEnsaio;
