import { mdiClose, mdiHistory, mdiPencil } from '@mdi/js';
import { Box, Typography, Badge, Tooltip, IconButton, Drawer, List, ListItemAvatar, ListItem, ListItemText, Divider, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useEffect } from 'react';
import { MdiIcon } from '../MdiIcon';
import { StateChangeDialog } from './state';
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
}));
export function HistoryTimeline({ atual, historico, estados, onEstadoChange }) {
    historico = historico ?? [];
    atual = atual ?? 0;
    estados = estados ?? [];
    const [open, setOpen] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [lista, setLista] = React.useState([]);
    const [estado, setEstado] = React.useState();
    function stringToColor(string) {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name)
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        };
    }
    useEffect(() => {
        const h = historico.sort((a, b) => {
            const cmp = moment(b.data).valueOf() - moment(a.data).valueOf();
            if (cmp !== 0)
                return cmp;
            return b.id - a.id;
        });
        setLista(h);
        const c = estados.find(r => r.id === atual);
        if (c) {
            setEstado(c.label);
        }
    }, [atual, estados, historico]);
    return (<React.Fragment>
      <StateChangeDialog open={dialog} title='Alterar estado' atual={atual} lista={estados} onClose={data => {
            if (data) {
                onEstadoChange(data.id, data.observacoes);
            }
            setDialog(false);
        }}/>
      <Drawer PaperProps={{
            sx: { width: '360px' }
        }} variant='temporary' anchor='right' open={open}>
        <DrawerHeader>
          <IconButton onClick={() => {
            setOpen(false);
        }}>
            <MdiIcon path={mdiClose} size={1}/>
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {lista.length > 0 &&
            lista.map((r, i) => {
                return (<ListItem key={`drawer-li-${i}`}>
                  <ListItemAvatar>
                    <Avatar {...stringAvatar(r.user)}/>
                  </ListItemAvatar>
                  <ListItemText primary={r.estado} secondary={`${r.user}, ${moment(r.data).format('DD/MM/YYYY [às] HH:mm')}`}/>
                </ListItem>);
            })}
          {lista.length === 0 && (<ListItem>
              <ListItemText primary='Sem histórico!'/>
            </ListItem>)}
        </List>
      </Drawer>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box>
          {estado ? estado : 'Sem estado'}
          {historico.length > 0 && (<Typography variant='caption' gutterBottom sx={{ display: 'block' }}>
              {historico.at(0).user}, {moment(historico.at(0).data).format('DD/MM/YYYY [às] HH:mm')}
            </Typography>)}
        </Box>

        <Box sx={{ ml: 1 }}>
          <Badge color='primary' badgeContent={historico.length}>
            <Tooltip title='Histórico de alterações'>
              <IconButton onClick={() => {
            setOpen(true);
        }}>
                <MdiIcon path={mdiHistory} size={0.7}/>
              </IconButton>
            </Tooltip>
          </Badge>
          <Tooltip title='Alterar estado'>
            <IconButton onClick={() => {
            setDialog(true);
        }}>
              <MdiIcon path={mdiPencil} size={0.7}/>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </React.Fragment>);
}
