export default class AtvLocaisDetailsController {
  constructor($q, $state, $filter, UIService, AuthorizationService, AtvAtivosService, AtvLocal, AtvEntidadeProprietaria, AtvAtivo) {
    this.$q = $q;
    this.state = $state;
    this.filter = $filter;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.AtvAtivosService = AtvAtivosService;
    this.AtvLocal = AtvLocal;
    this.AtvAtivo = AtvAtivo;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.loaded = false;
    this.tipo = null;

    // Default opt state - populate later when we have all states
    this.optAtivos = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "id",
      sort: "desc",
      filter: {}
    };
    this.ativos = null;
    this.ativosLoading = true;

    this.id = $state.params.id;
    if (angular.isUndefined($state.params.id) || isNaN($state.params.id)) {
      this.state.go('app.ativos.locais.list');
      this.UI.addToast('Local desconhecido/inválido');
    } else {
      this.loadData();
    }
  }

  loadData = () => {
    this.loaded = false;
    this.AtvLocal.findOne({
      filter: {
        where: {
          id: this.id,
          active: true
        },
        include: [{
          relation: 'entidadeproprietaria',
          scope: {
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then((loc) => {
      this.local = loc;
      this.loaded = true;
    }).catch(() => {
      this.state.go('app.ativos.locais.list');
      this.UI.addToast('Erro a carregar dados de local.');
    });

    this.getAtivosForLocal();
  };

  editObservations = () => {
    let obs = angular.copy(this.local.observacoes);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = obs;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.loaded = false;
      this.local.observacoes = res;
      this.local.$save().then(() => {
        this.UI.addToast('Observações guardadas com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro a guardar as observações. Por favor tente mais tarde.');
        this.loadData();
      })
    })
  };

  editLocal = () => {
    let cenas = this.UI.showWaiting();
    let local = angular.copy(this.local);

    this.AtvEntidadeProprietaria.find({
      filter: {
        where: {
          active: 1
        },
        order: 'ordem ASC'
      }
    }).$promise.then((eps) => {
      cenas.close();
      let options = {
        size: 'lg',
        template: require('./local.dialog.html'),
        controller: ['$dialog', '$scope', (dialog, scope) => {
          scope.title = "Editar Local";
          scope.local = local;
          scope.eps = eps;

          scope.auxEntidadeProprietaria = {selected: scope.local.entidadeproprietaria};

          scope.ok = () => {
            dialog.close(scope);
          };

          scope.cancel = () => {
            dialog.dismiss('cancel');
          };
        }]
      };

      let modal = this.UI.showDialog(options);

      modal.then((res) => {
        if (res && res.local) {
          res.local.entidadeProprietariaId = res.auxEntidadeProprietaria.selected.id;
          res.local.entidadeproprietaria = res.auxEntidadeProprietaria.selected;
          res.local.active = 1;
          this.AtvLocal.upsert(res.local).$promise.then((res) => {
            this.UI.addToast("Local editado com sucesso");
            this.loadData();
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro na edição de local. Por favor tente mais tarde.");
          });
        }
      });
    }).catch((error) => {
      console.log(error);
      cenas.close();
    });
  };

  getAtivosForLocal = () => {
    this.ativosLoading = true;
    let whereLiteral = {
      'AtvAtivo.localId': this.id,
      'AtvAtivo.active': 1
    };
    let where = {};
    Object.keys(this.optAtivos.filter).forEach(f => {
      if (this.optAtivos.filter[f])
        where[f] = this.optAtivos.filter[f];
    });

    this.AtvAtivosService.setWherePermFields({}, 'AtvAtivo.entidadeProprietariaId').then(w => {
      let wherePerms = w.wherePerms;
      this.AtvAtivo.table({
        params: {
          fields: [
            'AtvAtivo.id as id',
            'AtvAtivo.iidDisplay as iidDisplay',
            'AtvAtivo.designacao as designacao',
            'AtvAtivo.referencia as referencia',
            'AtvMarca.designacao as marca',
            'AtvFornecedorPrimavera.refFornecedorPrimavera as refFornecedorPrimavera',
            'AtvEntidadeProprietaria.designacao as entidadeProprietaria',
            'AtvEP2.sigla as sigla'
          ],
          from: ['AtvAtivo', 'AtvMarca', 'AtvFornecedorPrimavera', 'AtvEntidadeProprietaria', 'AtvEntidadeProprietaria'],
          references: [undefined, 'AtvAtivo.marcaId', 'AtvAtivo.fornecedorPrimaveraId', 'AtvAtivo.entidadeProprietariaId', 'AtvFornecedorPrimavera.entidadeProprietariaId'],
          aliases: [null, null, null, null, 'AtvEP2'],
          where: where,
          whereLiteral: whereLiteral,
          wherePerms: wherePerms,
          order: this.optAtivos.order,
          sort: this.optAtivos.sort,
          limit: this.optAtivos.items,
          skip: (this.optAtivos.page - 1) * this.optAtivos.items,
        }
      }).$promise.then(res => {
        let page = this.optAtivos.page;
        let items = this.optAtivos.items;

        let total = res.count;

        this.optAtivos.start = total > 0 ? (page - 1) * items + 1 : 0;
        if ((this.optAtivos.start - 1 + items) >= total) {
          this.optAtivos.end = total;
        } else {
          this.optAtivos.end = Number.parseInt(this.optAtivos.start - 1 + items);
        }

        this.ativos = res.ativos;
        this.optAtivos.total = total;
        this.ativosLoading = false;
      }).catch((e) => {
        console.log(e);
        this.UI.addToast("Não foi possível ler ativos. Por favor tente mais tarde");
      });
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler permissões. Por favor tente mais tarde");
    });
  };

  applyFilterAtivos = () => {
    this.optAtivos.page = 1;
    this.getAtivosForLocal();
  };

  ativosSort = keyname => {
    if (this.optAtivos.order === keyname)
      this.optAtivos.page = 1;
    this.optAtivos.order = keyname;
    this.optAtivos.sort = this.optAtivos.sort === 'asc' ? 'desc' : 'asc';
    this.getAtivosForLocal();
  };

  ativosItem = val => {
    this.optAtivos.items = val;
    this.optAtivos.page = 1;
    this.getAtivosForLocal();
  };

  ativosPage = sum => {
    this.optAtivos.page += sum;
    if (this.optAtivos.page < 1)
      this.optAtivos.page = 1;
    if (this.optAtivos.page > Math.ceil(this.optAtivos.total / this.optAtivos.items))
      this.optAtivos.page = Math.ceil(this.optAtivos.total / this.optAtivos.items);
    this.getAtivosForLocal();
  };

  viewAtivo = r => {
    this.state.go('app.ativos.ativos.details', {id: r.id});
  };
}
AtvLocaisDetailsController.$inject = ['$q', '$state', '$filter', 'UIService', 'AuthorizationService', 'AtvAtivosService', 'AtvLocal', 'AtvEntidadeProprietaria', 'AtvAtivo'];
