export default class CodigosArtigoAdminController {
  constructor(LmeCodigoArtigo, AjlLmeCodigoArtigo, LmeLaboratorio, AjlLmeLaboratorio, UIService) {
    this.UI = UIService;
    this.LmeCodigoArtigo = LmeCodigoArtigo;
    this.AjlLmeCodigoArtigo = AjlLmeCodigoArtigo;
    this.LmeLaboratorio = LmeLaboratorio;
    this.AjlLmeLaboratorio = AjlLmeLaboratorio;
    this.codArtigosLmeLoading = true;
    this.codArtigosAjlLoading = true;
    this.codArtigosLme = null;
    this.codArtigosAjl = null;
    this.showCodArtigoLmeFab = false;
    this.showCodArtigoAjlFab = false;
    this.getCodArtigosLme();
    this.getCodArtigosAjl();
  }

  updateFab = (f) => {
    if (f === 'codArtigoLme') { // Códigos de Artigo LME Fab
      this.showCodArtigoLmeFab = true;
      this.showCodArtigoAjlFab = false;
    } else if (f === 'codArtigoAjl') { // Códigos de Artigo AJL Fab
      this.showCodArtigoLmeFab = false;
      this.showCodArtigoAjlFab = true;
    }
  };

  // IEP
  getCodArtigosLme = () => {
    this.codArtigosLmeLoading = true;
    this.LmeCodigoArtigo.find({
      filter: {
        where: {
          active: 1
        },
        order: 'codigo ASC',
        include: {
          relation: 'laboratorio',
          scope: {
            where: {active: 1}
          }
        }
      }
    }).$promise.then((res) => {
      this.codArtigosLme = res;
      this.codArtigosLmeLoading = false;
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro ao carregar Códigos de Artigo LME. Verifique a ligação");
    })
  };

  confirmDeleteCodArtigoLme = (i) => {
    let title = "Eliminar Cód. Artigo LME";
    let warning = "Tem a certeza que pretende remover este código de artigo?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeCodArtigoLme(i);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeCodArtigoLme = (s) => {
    this.codArtigoLmeLoading = true;
    // Find again codigo artigo to delete so we can update it
    this.LmeCodigoArtigo.findOne({
      filter: {
        where: {
          id: s.id,
          active: 1
        }
      }
    }).$promise.then((codToRemove) => {
      codToRemove.active = 0;
      codToRemove.$save().then((res) => {
        this.UI.addToast("Cód. Artigo eliminado com sucesso!");
        this.getCodArtigosLme();
      }, (error) => {
        console.log(error);
        this.codArtigosLmeLoading = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.codArtigoLmeLoading = false;
      this.UI.addToast("Não foi possível eliminar o cód. artigo. Verifique se ainda existe.");
    });
  };

  addCodArtigoLme = () => {
    let cenas = this.UI.showWaiting();
    this.LmeLaboratorio.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((labs) => {
      cenas.close();
      let instance = this.UI.showDialog({
        size: 'md',
        template: require('./add.codartigo.dialog.html'),
        controller: ['$scope', function ($scope) {
          $scope.label = "Adicionar Código de Artigo LME";
          $scope.blockCodigoEdit = false;
          $scope.labs = labs;

          $scope.codArtigo = {};
          $scope.codArtigo.id = 0;
          $scope.codArtigo.descricao = null;

          $scope.auxLaboratorio = {selected: null};

          $scope.ok = function () {
            // Send scope values for processing
            $scope.$close($scope);
          };

          $scope.cancel = function () {
            $scope.$dismiss('cancel');
          };
        }]
      });

      instance.then((ok) => {
        this.codArtigoLmeLoading = true;
        ok.codArtigo.codigo = ok.codArtigo.codigo.toUpperCase();
        this.LmeCodigoArtigo.find({
          filter: {
            where: {
              codigo: ok.codArtigo.codigo
            }
          }
        }).$promise.then((c) => {
          if (c && c.length > 0) { // One already exists
            if (c[0].active) { // And is active
              this.UI.addToast("Esse código já existe na lista de códigos.");
              this.getCodArtigosLme();
            } else { // It is not active. Re-use it.
              c[0].laboratorioId = ok.auxLaboratorio.selected.id;
              c[0].descricao = ok.codArtigo.descricao;
              c[0].active = 1;
              c[0].$save().then((res) => {
                this.UI.addToast("Código de artigo adicionado com sucesso");
                this.getCodArtigosLme();
              }, (error) => {
                console.log(error);
                this.UI.addToast("Não foi possível adicionar cód. artigos. Por favor tente mais tarde.");
              });
            }
          } else { // Just create it.
            ok.codArtigo.laboratorioId = ok.auxLaboratorio.selected.id;
            ok.codArtigo.active = 1;
            this.LmeCodigoArtigo.create(ok.codArtigo).$promise.then(r => {
              this.getCodArtigosLme();
              this.UI.addToast("Cód. Artigo inserido com sucesso.");
            }).catch(error => {
              console.log(error);
              this.codArtigoLmeLoading = false;
              this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
            });
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível adicionar cód. artigos. Por favor tente mais tarde.");
        });

      }, (err) => {
        // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
        if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
          console.log(err);
      });
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar laboratórios para adicionar cód. artigos. Por favor tente mais tarde.");
    });
  };

  editCodArtigoLme = (c) => {
    let cenas = this.UI.showWaiting();
    this.LmeLaboratorio.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((labs) => {
      cenas.close();
      let instance = this.UI.showDialog({
        size: 'md',
        template: require('./add.codartigo.dialog.html'),
        controller: ['$scope', function ($scope) {
          $scope.label = "Editar Código de Artigo LME";
          $scope.blockCodigoEdit = true;
          $scope.labs = labs;

          $scope.codArtigo = c;

          $scope.auxLaboratorio = {selected: c.laboratorio};

          $scope.ok = function () {
            // Send scope values for processing
            $scope.$close($scope);
          };

          $scope.cancel = function () {
            $scope.$dismiss('cancel');
          };
        }]
      });

      instance.then((ok) => {
        this.codArtigoLmeLoading = true;
        ok.codArtigo.codigo = ok.codArtigo.codigo.toUpperCase();
        this.LmeCodigoArtigo.find({
          filter: {
            where: {
              codigo: ok.codArtigo.codigo
            }
          }
        }).$promise.then((c) => {
          if (c && c.length > 0) { // One already exists
            if (!c[0].active) { // And is active
              this.UI.addToast("Este código foi eliminado. Por favor volte a criá-lo, se aplicável.");
              this.getCodArtigosLme();
            } else { // It is active. Update it.
              c[0].laboratorioId = ok.auxLaboratorio.selected.id;
              c[0].descricao = ok.codArtigo.descricao;
              c[0].$save().then((res) => {
                this.UI.addToast("Dados editados com sucesso");
                this.getCodArtigosLme();
              }, (error) => {
                console.log(error);
                this.UI.addToast("Não foi possível editar dados. Por favor tente mais tarde.");
              });
            }
          } else { // Code does not exist to edit.
            this.UI.addToast("Este código não existe. Por favor crie-o, se aplicável.");
            this.getCodArtigosLme();
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível adicionar cód. artigos. Por favor tente mais tarde.");
        });

      }, (err) => {
        // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
        if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
          console.log(err);
      });
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar laboratórios para adicionar cód. artigos. Por favor tente mais tarde.");
    });
  };

  // AJL
  getCodArtigosAjl = () => {
    this.codArtigosAjlLoading = true;
    this.AjlLmeCodigoArtigo.find({
      filter: {
        where: {
          active: 1
        },
        order: 'codigo ASC',
        include: {
          relation: 'laboratorio',
          scope: {
            where: {active: 1}
          }
        }
      }
    }).$promise.then((res) => {
      this.codArtigosAjl = res;
      this.codArtigosAjlLoading = false;
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro ao carregar Códigos de Artigo AJL. Verifique a ligação");
    })
  };

  confirmDeleteCodArtigoAjl = (i) => {
    let title = "Eliminar Cód. Artigo AJL";
    let warning = "Tem a certeza que pretende remover este código de artigo?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeCodArtigoAjl(i);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeCodArtigoAjl = (s) => {
    this.codArtigoAjlLoading = true;
    // Find again codigo artigo to delete so we can update it
    this.AjlLmeCodigoArtigo.findOne({
      filter: {
        where: {
          id: s.id,
          active: 1
        }
      }
    }).$promise.then((codToRemove) => {
      codToRemove.active = 0;
      codToRemove.$save().then((res) => {
        this.UI.addToast("Cód. Artigo eliminado com sucesso!");
        this.getCodArtigosAjl();
      }, (error) => {
        console.log(error);
        this.codArtigosAjlLoading = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.codArtigoAjlLoading = false;
      this.UI.addToast("Não foi possível eliminar o cód. artigo. Verifique se ainda existe.");
    });
  };

  addCodArtigoAjl = () => {
    let cenas = this.UI.showWaiting();
    this.AjlLmeLaboratorio.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((labs) => {
      cenas.close();
      let instance = this.UI.showDialog({
        size: 'md',
        template: require('./add.codartigo.dialog.html'),
        controller: ['$scope', function ($scope) {
          $scope.label = "Adicionar Código de Artigo AJL";
          $scope.blockCodigoEdit = false;
          $scope.labs = labs;

          $scope.codArtigo = {};
          $scope.codArtigo.id = 0;
          $scope.codArtigo.descricao = null;

          $scope.auxLaboratorio = {selected: null};

          $scope.ok = function () {
            // Send scope values for processing
            $scope.$close($scope);
          };

          $scope.cancel = function () {
            $scope.$dismiss('cancel');
          };
        }]
      });

      instance.then((ok) => {
        this.codArtigoAjlLoading = true;
        ok.codArtigo.codigo = ok.codArtigo.codigo.toUpperCase();
        this.AjlLmeCodigoArtigo.find({
          filter: {
            where: {
              codigo: ok.codArtigo.codigo
            }
          }
        }).$promise.then((c) => {
          if (c && c.length > 0) { // One already exists
            if (c[0].active) { // And is active
              this.UI.addToast("Esse código já existe na lista de códigos.");
              this.getCodArtigosAjl();
            } else { // It is not active. Re-use it.
              c[0].laboratorioId = ok.auxLaboratorio.selected.id;
              c[0].descricao = ok.codArtigo.descricao;
              c[0].active = 1;
              c[0].$save().then((res) => {
                this.UI.addToast("Código de artigo adicionado com sucesso");
                this.getCodArtigosAjl();
              }, (error) => {
                console.log(error);
                this.UI.addToast("Não foi possível adicionar cód. artigos. Por favor tente mais tarde.");
              });
            }
          } else { // Just create it.
            ok.codArtigo.laboratorioId = ok.auxLaboratorio.selected.id;
            ok.codArtigo.active = 1;
            this.AjlLmeCodigoArtigo.create(ok.codArtigo).$promise.then(r => {
              this.getCodArtigosAjl();
              this.UI.addToast("Cód. Artigo inserido com sucesso.");
            }).catch(error => {
              console.log(error);
              this.codArtigoAjlLoading = false;
              this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
            });
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível adicionar cód. artigos. Por favor tente mais tarde.");
        });

      }, (err) => {
        // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
        if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
          console.log(err);
      });
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar laboratórios para adicionar cód. artigos. Por favor tente mais tarde.");
    });
  };

  editCodArtigoAjl = (c) => {
    let cenas = this.UI.showWaiting();
    this.AjlLmeLaboratorio.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((labs) => {
      cenas.close();
      let instance = this.UI.showDialog({
        size: 'md',
        template: require('./add.codartigo.dialog.html'),
        controller: ['$scope', function ($scope) {
          $scope.label = "Editar Código de Artigo AJL";
          $scope.blockCodigoEdit = true;
          $scope.labs = labs;

          $scope.codArtigo = c;

          $scope.auxLaboratorio = {selected: c.laboratorio};

          $scope.ok = function () {
            // Send scope values for processing
            $scope.$close($scope);
          };

          $scope.cancel = function () {
            $scope.$dismiss('cancel');
          };
        }]
      });

      instance.then((ok) => {
        this.codArtigoAjlLoading = true;
        ok.codArtigo.codigo = ok.codArtigo.codigo.toUpperCase();
        this.AjlLmeCodigoArtigo.find({
          filter: {
            where: {
              codigo: ok.codArtigo.codigo
            }
          }
        }).$promise.then((c) => {
          if (c && c.length > 0) { // One already exists
            if (!c[0].active) { // And is active
              this.UI.addToast("Este código foi eliminado. Por favor volte a criá-lo, se aplicável.");
              this.getCodArtigosAjl();
            } else { // It is active. Update it.
              c[0].laboratorioId = ok.auxLaboratorio.selected.id;
              c[0].descricao = ok.codArtigo.descricao;
              c[0].$save().then((res) => {
                this.UI.addToast("Dados editados com sucesso");
                this.getCodArtigosAjl();
              }, (error) => {
                console.log(error);
                this.UI.addToast("Não foi possível editar dados. Por favor tente mais tarde.");
              });
            }
          } else { // Code does not exist to edit.
            this.UI.addToast("Este código não existe. Por favor crie-o, se aplicável.");
            this.getCodArtigosAjl();
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível adicionar cód. artigos. Por favor tente mais tarde.");
        });

      }, (err) => {
        // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
        if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
          console.log(err);
      });
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar laboratórios para adicionar cód. artigos. Por favor tente mais tarde.");
    });
  };
}

CodigosArtigoAdminController.$inject = ['LmeCodigoArtigo', 'AjlLmeCodigoArtigo', 'LmeLaboratorio', 'AjlLmeLaboratorio', 'UIService'];
