import React, { useEffect, useState, useCallback } from 'react';
import { Autocomplete, Box, Button, Chip, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputLabel, ListItem, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { MdiIcon } from '@components';
import { mdiHistory } from '@mdi/js';
import moment from 'moment';
import { Stack } from '@mui/system';
import History from './history';
import { useModal } from 'mui-modal-provider';
import { AlertDialog } from '../../../interface/components/dialogs/alert';
const RhAvaliacaoForm = ({ $state, RhService, AuthorizationService }) => {
    const [avaliacaoOriginal, setAvaliacaoOriginal] = useState(null); // original to compare changes
    const [avaliacao, setAvaliacao] = useState(null);
    const [classificacoes, setClassificacoes] = useState([]);
    const [estados, setEstados] = useState([]);
    const [hist, setHist] = useState([]);
    const [openHistory, setOpenHistory] = useState(false);
    const [showHistoryButton, setShowHistoryButton] = useState(false);
    const [pontos, setPontos] = useState('');
    const [ciclo, setCiclo] = useState(''); // Avaliação de Desempenho Anual {Ciclo}
    const [loading, setLoading] = useState(true);
    const [disable, setDisable] = useState(false); // disable all textfields when saving
    const [hasPermission, setHasPermission] = useState(true);
    const [disableComentarioAvaliado, setDisableComentarioAvaliado] = useState(false);
    const [showComentarioAvaliado, setShowComentarioAvaliado] = useState(false);
    const [disableComentarioAvaliador, setDisableComentarioAvaliador] = useState(false);
    const [showComentarioAvaliador, setShowComentarioAvaliador] = useState(false);
    const [disableComentarioRH, setDisableComentarioRH] = useState(false);
    const [showComentarioRh, setShowComentarioRh] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [showValidationBottons, setShowValidationBottons] = useState(false);
    const [disableInputAvaliado, setDisableInputAvaliado] = useState(true);
    const [showCompetencias, setShowCompetencias] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [saveButtonText, setSaveButtonText] = useState('');
    const [user, setUser] = useState({ id: 0, nome: '' });
    // autocomplete
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [autocompleteLoad, setAutocompleteLoad] = useState(false);
    const limit = 10; // load 10 users at a time
    const { showModal } = useModal();
    const permissionByStateAndUser = (estado, avaliador, avaliado) => {
        switch (estado) {
            case 10:
                {
                    if (avaliado) {
                        setDisable(true);
                    }
                    if (avaliador) {
                        setDisableComentarioAvaliador(false); // mostrar comentario Avaliador
                        setShowComentarioAvaliador(true); // disable comentario Avaliador
                        setShowSaveButton(true); // mostrar botão save
                        setSaveButtonText('Submeter Proposta de Avaliação');
                        setWarningMessage('Os campos assinalados com * serão visíveis para a Gestão de Recursos Humanos e para o Avaliado');
                        setShowWarning(true);
                    }
                    // gestor
                    if (AuthorizationService.canPerform('rhGestorAvaliacao') && !avaliador) {
                        setDisable(true);
                    }
                }
                break;
            case 15:
                {
                    if (avaliado) {
                        setDisable(true);
                    }
                    // gestor
                    if (AuthorizationService.canPerform('rhGestorAvaliacao')) {
                        if (!avaliador) {
                            setDisable(true);
                        }
                        setDisableComentarioAvaliador(true); // mostrar comentario Avaliador
                        setShowComentarioAvaliador(true); // disable comentario Avaliador
                        setShowComentarioRh(true); // mostrar comentario RH
                        setDisableComentarioRH(true); // disable comentario RH
                    }
                    if (avaliador) {
                        setShowComentarioAvaliador(true); // mostrar comentario Avaliador
                        setDisableComentarioAvaliador(false); // disable comentario Avaliador
                        setShowComentarioRh(true); // mostrar comentario RH
                        setDisableComentarioRH(true); // disable comentario RH
                        setShowSaveButton(true); // mostrar botão save
                        setSaveButtonText('Submeter proposta de avaliação revista');
                        setWarningMessage('Os campos assinalados com * serão visíveis para a Gestão de Recursos Humanos e para o Avaliado');
                        setShowWarning(true);
                    }
                }
                break;
            case 20:
                {
                    if (avaliado) {
                        setDisable(true);
                    }
                    if (avaliador) {
                        setDisable(true);
                        setShowComentarioAvaliador(true); // mostrar comentario Avaliador
                        setDisableComentarioAvaliador(true); // disable comentario Avaliador
                        setShowComentarioRh(true); // mostrar comentario RH
                        setDisableComentarioRH(true); // disable comentario RH
                    }
                    // gestor
                    if (AuthorizationService.canPerform('rhGestorAvaliacao')) {
                        setDisable(true);
                        setShowComentarioAvaliador(true); // mostrar comentario Avaliador
                        setDisableComentarioAvaliador(true); // disable comentario Avaliador
                        setShowComentarioRh(true); // mostrar comentario RH
                        setDisableComentarioRH(false); // disable comentario RH
                        setShowValidationBottons(true); // mostrar botões de validação
                    }
                }
                break;
            case 30:
                {
                    if (avaliado) {
                        setDisable(true);
                    }
                    // gestor
                    if (AuthorizationService.canPerform('rhGestorAvaliacao')) {
                        setDisable(true);
                        setShowComentarioAvaliador(true); // mostrar comentario Avaliador
                        setDisableComentarioAvaliador(true); // disable comentario Avaliador
                        setShowComentarioRh(true); // mostrar comentario RH
                        setDisableComentarioRH(true); // disable comentario RH
                    }
                    if (avaliador) {
                        setDisable(false);
                        setShowComentarioAvaliador(true); // mostrar comentario Avaliador
                        setDisableComentarioAvaliador(false); // disable comentario Avaliador
                        setShowComentarioRh(true); // mostrar comentario RH
                        setDisableComentarioRH(true); // disable comentario RH
                        setShowSaveButton(true); // mostrar botão save
                        setSaveButtonText('Finalizar Entrevista');
                        setWarningMessage('Os campos assinalados com * serão visíveis para a Gestão de Recursos Humanos e para o Avaliado');
                        setShowWarning(true);
                    }
                }
                break;
            case 40:
                {
                    if (avaliado) {
                        setDisable(true); // disable Form (except observations)
                        setDisableComentarioAvaliado(false); // disable comentario Avaliado
                        setShowComentarioAvaliado(true); // mostrar comentario Avaliado
                        setShowComentarioAvaliador(true); // mostrar comentario Avaliador
                        setDisableComentarioAvaliador(true); // disable comentario Avaliador
                        setShowSaveButton(true); // mostrar botão save
                        setSaveButtonText('Submeter Observações de avaliação');
                        setWarningMessage('Os campos assinalados com * serão visíveis para a Gestão de Recursos Humanos e para o Avaliador');
                        setShowWarning(true);
                    }
                    if (avaliador) {
                        setDisable(true);
                        setShowComentarioAvaliador(true); // mostrar comentario Avaliador
                        setDisableComentarioAvaliador(true); // disable comentario Avaliador
                        setShowComentarioRh(true); // mostrar comentario RH
                        setDisableComentarioRH(true); // disable comentario RH
                    }
                    // gestor
                    if (AuthorizationService.canPerform('rhGestorAvaliacao') && !avaliador) {
                        setDisable(true);
                        setShowComentarioAvaliador(true); // mostrar comentario Avaliador
                        setDisableComentarioAvaliador(true); // disable comentario Avaliador
                        setShowComentarioRh(true); // mostrar comentario RH
                        setDisableComentarioRH(true); // disable comentario RH
                    }
                }
                break;
            case 50:
                {
                    setDisable(true); // disable form
                    setShowComentarioAvaliado(true); // mostrar comentario Avaliado
                    setDisableComentarioAvaliado(true); // disable comentario Avaliado
                    setShowComentarioAvaliador(true); // mostrar comentario Avaliador
                    setDisableComentarioAvaliador(true); // disable comentario Avaliador
                    setDisableComentarioRH(true); // disable comentario RH
                    if (avaliado) {
                        setShowComentarioRh(false); // mostrar comentario RH
                    }
                    else {
                        setShowComentarioRh(true); // mostrar comentario RH
                    }
                }
                break;
            default:
                break;
        }
    };
    const fetchData = async () => {
        let idUser;
        let idFunc;
        let idCiclo;
        if ($state.params.id !== undefined && avaliacao === null) {
            try {
                const getUser = await RhService.getUserId();
                idUser = getUser;
                setUser(getUser);
            }
            catch (error) {
                console.error('Erro ao obter Ciclo: ', error);
            }
            try {
                const getAvaliacao = await RhService.getAvaliacaoById($state.params.id);
                idFunc = getAvaliacao.funcionarioId;
                idCiclo = getAvaliacao.cicloId;
                if (getAvaliacao.avaliadorId === idUser) {
                    // avaliador
                    permissionByStateAndUser(getAvaliacao.estadoId, true, false);
                }
                else if (getAvaliacao.funcionarioId === idUser) {
                    // avaliado
                    permissionByStateAndUser(getAvaliacao.estadoId, false, true);
                }
                else {
                    permissionByStateAndUser(getAvaliacao.estadoId);
                }
                setAvaliacaoOriginal(JSON.parse(JSON.stringify(getAvaliacao)));
                setAvaliacao(getAvaliacao);
            }
            catch (error) {
                console.error('Erro ao obter Avaliação: ', error);
                if (error.status === 403 || error.status === 401) {
                    setHasPermission(false);
                    showModal(AlertDialog, {
                        title: 'Sem Acesso',
                        onClose: () => {
                            $state.go('app.rh.avaliacoes.list');
                        }
                    });
                }
                setHasPermission(false);
                showModal(AlertDialog, {
                    title: 'Sem Acesso',
                    onClose: () => {
                        $state.go('app.rh.avaliacoes.list');
                    }
                });
            }
            // get Ciclo
            try {
                const getCiclo = await RhService.getCicloById(idCiclo);
                setCiclo(getCiclo.designacao);
            }
            catch (error) {
                console.error('Erro ao obter Ciclo: ', error);
            }
            try {
                const getColaborador = await RhService.getFuncionarioById(idFunc);
                setOptions(prev => {
                    const exists = prev.find(option => option.id === getColaborador.id);
                    return exists ? prev : [...prev, getColaborador];
                });
                // Define o valor selecionado como o técnico retornado
                setSelectedValue({
                    id: getColaborador.id,
                    name: getColaborador.name,
                    numeroMecanografico: getColaborador.numeroMecanografico
                });
                formik.setFieldValue('funcionarioId', getColaborador.id);
            }
            catch (error) {
                console.error('Erro ao obter Colaborador: ', error);
            }
        }
        if ($state.params.id === undefined) {
            // nova avaliação
            setDisableInputAvaliado(false);
            setDisableComentarioAvaliador(false); // disable comentario Avaliador
            setShowComentarioAvaliador(true); // mostrar comentario Avaliador
            formik.setFieldValue('estadoId', 20);
            setShowSaveButton(true);
        }
        // get Classificações
        try {
            const getClassificacoes = await RhService.getClassificacoes();
            if ($state.params.id !== undefined && avaliacao) {
                const classificacaoAtual = getClassificacoes.find(c => c.id === avaliacao.avaliacaoQualitativaId);
                setPontos(classificacaoAtual?.pontos?.toString() || '');
            }
            setClassificacoes(getClassificacoes);
        }
        catch (error) {
            console.error('Erro ao obter Classificações: ', error);
        }
        setLoading(false);
    };
    // get Historico
    const getHistorico = async () => {
        try {
            const alt = await RhService.getAlteracoesById($state.params.id);
            const historicoArray = alt.map(h => ({
                id: h.id,
                estado: estados.find(e => e.id === h.estadoFinal)?.designacao,
                user: h.Funcionario?.name,
                data: h.dataAlteracao,
                observacoes: h.observacoes
            }));
            setHist(historicoArray);
            setShowHistoryButton(true);
        }
        catch (error) {
            console.error('Erro ao obter Histórico de alterações: ', error);
        }
    };
    const fetchOptions = useCallback(debounce(async (query, currentPage) => {
        if (!query || !hasMore)
            return;
        setAutocompleteLoad(true);
        try {
            const response = await RhService.getFuncionarios(query, currentPage, limit);
            if (response.length < limit) {
                setHasMore(false); // Não há mais dados
            }
            setOptions(prev => [...prev, ...response]); // Concatena os dados
        }
        catch (error) {
            console.error('Erro ao obter lista de técnicos: ', error);
        }
        finally {
            setAutocompleteLoad(false);
        }
    }, 500), [hasMore] // Atualiza se `hasMore` mudar
    );
    const handleScroll = event => {
        const listboxNode = event.currentTarget;
        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 1 && !autocompleteLoad && hasMore) {
            setPage(prev => prev + 1); // Incrementa a página
        }
    };
    const validationSchema = yup.object().shape({
        funcionarioId: yup.number().required('Preenchimento obrigatório'),
        pontosFortes: yup.string().required('Preenchimento obrigatório'),
        pontosFracos: yup.string().required('Preenchimento obrigatório'),
        observacoesAvaliador: yup.string().required('Preenchimento obrigatório'),
        avaliacaoQualitativaId: yup.number().required('Preenchimento obrigatório'),
        observacoesRh: yup.string().when('estadoId', { is: 20, then: schema => ($state.params.id !== undefined ? schema.required('Preenchimento obrigatório') : schema.notRequired()), otherwise: schema => schema.notRequired() })
    });
    const formik = useFormik({
        initialValues: avaliacao || {
            id: '',
            funcionarioId: null,
            avaliadorId: null,
            cicloId: null,
            estadoId: null,
            pontosFortes: null,
            pontosFracos: null,
            observacoesAvaliador: null,
            avaliacaoQualitativaId: null
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let currentDate = moment().format();
            if (avaliacaoOriginal) {
                if (avaliacaoOriginal.estadoId === 10 || avaliacaoOriginal.estadoId === 30 || avaliacaoOriginal.estadoId === 40) {
                    values.estadoId += 10;
                }
                else if (avaliacaoOriginal.estadoId === 15) {
                    values.estadoId += 5;
                }
            }
            if (avaliacaoOriginal.estadoId === 10) {
                values.dataAvaliacao = currentDate;
            }
            else if (avaliacaoOriginal.estadoId === 15) {
                values.dataPedidoRevisao = currentDate;
            }
            else if (avaliacaoOriginal.estadoId === 20) {
                values.dataValidacao = currentDate;
            }
            else if (avaliacaoOriginal.estadoId === 30) {
                values.dataEntrevista = currentDate;
            }
            else if (avaliacaoOriginal.estadoId === 40) {
                values.dataTomadaConhecimento = currentDate;
            }
            else if (avaliacaoOriginal.estadoId === 50) {
                values.dataConclusao = currentDate;
            }
            // Disable form
            setDisable(true);
            setDisableComentarioAvaliador(true);
            setDisableComentarioAvaliado(true);
            setDisableComentarioRH(true);
            let alt = await historicoAlteracoes(values);
            if (alt !== null) {
                try {
                    if (avaliacaoOriginal.estadoId === 40) {
                        await RhService.patchAvaliacao(values.id, values.observacoesAvaliado, values.estadoId);
                    }
                    else {
                        await RhService.saveAvaliacao(values, alt);
                    }
                }
                catch (error) {
                    console.error('Erro ao guardar avaliação: ', error);
                }
            }
            $state.go('app.rh.avaliacoes.list');
        }
    });
    // Função para verificar alterações
    const changes = (original, updated) => {
        const changedFields = {};
        for (const key in original) {
            if (original[key] !== updated[key]) {
                changedFields[key] = {
                    original: original[key],
                    updated: updated[key]
                };
            }
        }
        return changedFields;
    };
    const cleanObject = (obj, keys) => {
        const cleaned = {};
        for (const key of keys) {
            if (obj.hasOwnProperty(key)) {
                cleaned[key] = obj[key];
            }
        }
        return cleaned;
    };
    const historicoAlteracoes = async (av) => {
        const observacoesList = []; // armazenar alterações
        let count = 0; // nº de alterações
        // Campos a considerar nas alterações
        const relevantFields = ['estadoId', 'pontosFortes', 'pontosFracos', 'formacao1', 'formacao2', 'formacao3', 'observacoesAvaliador', 'observacoesRh', 'observacoesAvaliado', 'avaliacaoQualitativaId'];
        const avOriginal = cleanObject(avaliacaoOriginal, relevantFields);
        const avUpdated = cleanObject(av, relevantFields);
        const alteracoes = changes(avOriginal, avUpdated);
        if (Object.keys(alteracoes).length > 0) {
            for (const campo in alteracoes) {
                const alteracao = alteracoes[campo];
                switch (campo) {
                    case 'estadoId':
                        count++;
                        /* if (alteracao.original === null) {
                          observacoesList.push(`Adicionado Estado: ${estados.find(c => c.id === alteracao.updated).designacao}`);
                        } else {
                          observacoesList.push(`Alteração de Estado de ${estados.find(c => c.id === alteracao.original).designacao} para ${estados.find(c => c.id === alteracao.updated).designacao}`);
                        } */
                        break;
                    case 'pontosFortes':
                        count++;
                        if (alteracao.original === null) {
                            observacoesList.push(`Adicionado campo Aspetos Positivos: \n${alteracao.updated}\n\n`);
                        }
                        else {
                            observacoesList.push(`Alteração do campo Aspetos Positivos de \n"${alteracao.original}"\n\n para \n\n"${alteracao.updated}"\n\n`);
                        }
                        break;
                    case 'pontosFracos':
                        count++;
                        if (alteracao.original === null) {
                            observacoesList.push(`Adicionado campo Aspetos a Melhorar: ${alteracao.updated}`);
                        }
                        else {
                            observacoesList.push(`Alteração do campo Aspetos a Melhorar de \n"${alteracao.original}"\n\n para \n\n"${alteracao.updated}"\n\n`);
                        }
                        break;
                    case 'formacao1':
                        count++;
                        if (alteracao.original === null) {
                            observacoesList.push(`Adicionado Curso 1: ${alteracao.updated}`);
                        }
                        else {
                            observacoesList.push(`Alteração do campo Curso 1 de \n"${alteracao.original}"\n\n para \n\n"${alteracao.updated}"\n\n`);
                        }
                        break;
                    case 'formacao2':
                        count++;
                        if (alteracao.original === null) {
                            observacoesList.push(`Adicionado Curso 2: ${alteracao.updated}`);
                        }
                        else {
                            observacoesList.push(`Alteração do campo Curso 2 de \n"${alteracao.original}"\n\n para \n\n"${alteracao.updated}"\n\n`);
                        }
                        break;
                    case 'formacao3':
                        count++;
                        if (alteracao.original === null) {
                            observacoesList.push(`Adicionado Curso 3: ${alteracao.updated}`);
                        }
                        else {
                            observacoesList.push(`Alteração do campo Curso 3 de \n"${alteracao.original}"\n\n para \n\n"${alteracao.updated}"\n\n`);
                        }
                        break;
                    case 'observacoesAvaliador':
                        count++;
                        if (alteracao.original === null) {
                            observacoesList.push(`Adicionado Comentarios do Avaliador: ${alteracao.updated}`);
                        }
                        else {
                            observacoesList.push(`Alteração do campo Comentarios do Avaliador de \n"${alteracao.original}"\n\n para \n\n"${alteracao.updated}"\n\n`);
                        }
                        break;
                    case 'observacoesRh':
                        count++;
                        if (alteracao.original === null) {
                            observacoesList.push(`Adicionado Comentarios dos RH: ${alteracao.updated}`);
                        }
                        else {
                            observacoesList.push(`Alteração do campo Comentarios dos RH de \n"${alteracao.original}"\n\n para \n\n"${alteracao.updated}"\n\n`);
                        }
                        break;
                    case 'observacoesAvaliado':
                        count++;
                        if (alteracao.original === null) {
                            observacoesList.push(`Adicionado Comentarios do Avaliado: ${alteracao.updated}`);
                        }
                        else {
                            observacoesList.push(`Alteração do campo Comentarios do Avaliado de \n"${alteracao.original}"\n\n para \n\n"${alteracao.updated}"\n\n`);
                        }
                        break;
                    case 'avaliacaoQualitativaId':
                        count++;
                        if (alteracao.original === null) {
                            observacoesList.push(`Adicionado Classificação: ${classificacoes.find(c => c.id === alteracao.updated).designacao}`);
                        }
                        else {
                            observacoesList.push(`Alteração do campo Classificação de \n"${classificacoes.find(c => c.id === alteracao.original).designacao}"\n\n para \n\n"${classificacoes.find(c => c.id === alteracao.updated).designacao}"\n\n`);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        if (count > 0) {
            let obs = '';
            observacoesList.map((x, index) => {
                if (index + 1 === observacoesList.length) {
                    obs += x;
                }
                else {
                    obs += x + '\n';
                }
            });
            const x = {
                id: 0,
                avaliacaoId: av.id,
                dataAlteracao: moment().format(),
                funcionarioId: user.id,
                estadoInicial: avaliacaoOriginal.estadoId,
                estadoFinal: av.estadoId,
                observacoes: obs,
                active: 1
            };
            return x;
            /*
            try {
              await RhService.saveAlteracoes(x);
            } catch (error) {
              console.error('Erro ao guardar histórico de alterações: ', error);
            }
            */
        }
        return null;
    };
    const handleClassificacao = (value) => {
        setPontos(value);
        let x = classificacoes.find(c => c.pontos === Number(value));
        formik.setFieldValue('avaliacaoQualitativaId', x.id);
    };
    const competenciasComponent = () => {
        if (avaliacao?.CompetenciasAtribuidas?.length > 0) {
            return (<Box>
          <Typography variant='h6' sx={{ mt: 2, mb: 1 }}>
            Competências a considerar
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {avaliacao?.CompetenciasAtribuidas?.map(c => (<Grid item key={c.CompetenciaProfissional.id}>
                <Chip label={c.CompetenciaProfissional.designacao}/>
              </Grid>))}
          </Grid>
        </Box>);
        }
    };
    const warningComponent = () => {
        return (<Typography variant='subtitle2' color='#db4437'>
        {warningMessage}
      </Typography>);
    };
    useEffect(() => {
        fetchData();
    }, [avaliacao]);
    useEffect(() => {
        const fetchEstados = async () => {
            try {
                const getEstados = await RhService.getEstados();
                setEstados(getEstados);
            }
            catch (error) {
                console.error('Erro ao obter Classificações: ', error);
            }
        };
        const fetchUser = async () => {
            try {
                const u = await RhService.getNomeFuncionarioById();
                setUser(u);
                if ($state.params.id === undefined) {
                    formik.setFieldValue('avaliadorId', u.id);
                    formik.setFieldValue('cicloId', 1);
                }
            }
            catch (error) {
                console.error('Erro ao obter utilizador: ', error);
            }
        };
        fetchEstados();
        fetchUser();
    }, []);
    useEffect(() => {
        if (estados.length > 0 && avaliacao !== null) {
            if (AuthorizationService.canPerform('rhGestorAvaliacao') || avaliacao.avaliadorId == user.id) {
                getHistorico();
                setShowCompetencias(true);
            }
        }
    }, [estados, avaliacao, user]);
    useEffect(() => {
        if (inputValue) {
            fetchOptions(inputValue, page);
        }
    }, [inputValue, page]);
    return (<Box>
      {loading ? (<Box sx={{ display: 'flex', justifyContent: 'center', height: '2vh', mt: 4 }}>
          <CircularProgress size={60} color='primary'/>
        </Box>) : hasPermission ? (<Box sx={{ mt: 2 }}>
          <Stack direction='row' spacing={2} alignItems='center' justifyContent='flex-start'>
            <Typography variant='h5' sx={{ mb: 1 }}>
              Avaliação de Desempenho Anual {ciclo}
            </Typography>
            <Typography variant='h6' sx={{ mb: 1 }}>
              - {estados.find(e => e.id === avaliacao?.estadoId)?.designacao}
            </Typography>

            {showHistoryButton ? (<Tooltip title='Histórico da Avaliação' placement='right'>
                <IconButton color='primary' onClick={() => setOpenHistory(true)}>
                  <MdiIcon path={mdiHistory}/>
                </IconButton>
              </Tooltip>) : (<></>)}
          </Stack>
          {showWarning ? warningComponent() : <></>}
          <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField fullWidth name='avaliador' label='Avaliador' color='info' size='small' multiline value={avaliacao?.Avaliador?.name} disabled={true}/>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Autocomplete disabled={disableInputAvaliado} disablePortal options={options} getOptionLabel={option => (option.name ? `${option.name}` : '')} value={selectedValue} // O valor completo da opção selecionada
         isOptionEqualToValue={(option, value) => option.id === value.id} onChange={(event, newValue) => {
                if (newValue == null) {
                    setSelectedValue(null);
                }
                else {
                    setSelectedValue(newValue); // Armazena o objeto completo
                    formik.setFieldValue('funcionarioId', newValue.id);
                }
                formik.setFieldTouched('funcionarioId', true);
            }} filterOptions={x => x} // Evita o filtro padrão
         onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setOptions([]); // Reseta opções ao mudar busca
                setPage(0); // Reinicia a paginação
                setHasMore(true);
            }} ListboxProps={{
                onScroll: handleScroll // Adiciona evento de scroll
            }} renderOption={(props, option) => (<ListItem {...props} key={option.id} dense>
                    <ListItemText primary={option.name} secondary={option.numeroMecanografico}/>
                  </ListItem>)} renderInput={params => (<TextField {...params} label='Avaliado' variant='outlined' size='small' fullWidth sx={{ bgcolor: '#F5F5F5' }} error={formik.touched.funcionarioId && Boolean(formik.errors.funcionarioId)} helperText={formik.touched.funcionarioId && formik.errors.funcionarioId} InputProps={{
                    ...params.InputProps,
                    endAdornment: (<>
                          {autocompleteLoad ? <CircularProgress color='inherit' size={20}/> : null}
                          {params.InputProps.endAdornment}
                        </>)
                }}/>)}/>
            </Grid>
          </Grid>
          {showCompetencias ? competenciasComponent() : <></>}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField fullWidth name='pontosFortes' label='Aspetos positivos' color='info' size='small' multiline minRows={3} onChange={formik.handleChange} value={formik.values.pontosFortes} error={formik.touched.pontosFortes && Boolean(formik.errors.pontosFortes)} helperText={formik.touched.pontosFortes && formik.errors.pontosFortes} disabled={disable}/>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField fullWidth name='pontosFracos' label='Aspetos a melhorar' color='info' size='small' multiline minRows={3} onChange={formik.handleChange} value={formik.values.pontosFracos} error={formik.touched.pontosFracos && Boolean(formik.errors.pontosFracos)} helperText={formik.touched.pontosFracos && formik.errors.pontosFracos} disabled={disable}/>
            </Grid>
          </Grid>
          <Typography variant='h6' sx={{ mt: 2 }}>
            Necessidades de Formação
          </Typography>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>
            (Considerar apenas formações técnicas)
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField fullWidth name='formacao1' label='Nome do curso (1)' color='info' size='small' multiline onChange={formik.handleChange} value={formik.values.formacao1} error={formik.touched.formacao1 && Boolean(formik.errors.formacao1)} helperText={formik.touched.formacao1 && formik.errors.formacao1} disabled={disable}/>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField fullWidth name='formacao2' label='Nome do curso (2)' color='info' size='small' multiline onChange={formik.handleChange} value={formik.values.formacao2} error={formik.touched.formacao2 && Boolean(formik.errors.formacao2)} helperText={formik.touched.formacao2 && formik.errors.formacao2} disabled={disable}/>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField fullWidth name='formacao3' label='Nome do curso (3)' color='info' size='small' multiline onChange={formik.handleChange} value={formik.values.formacao3} error={formik.touched.formacao3 && Boolean(formik.errors.formacao3)} helperText={formik.touched.formacao3 && formik.errors.formacao3} disabled={disable}/>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <Typography variant='h6' sx={{ mt: 2, mb: 1 }}>
                Classificação
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <FormControl fullWidth error={formik.touched.avaliacaoQualitativaId && Boolean(formik.errors.avaliacaoQualitativaId)}>
                <InputLabel id='classificacoes' size='small'>
                  Classificação
                </InputLabel>
                <Select labelId='classificacoes' id='classificacoes' label='Classificação' size='small' value={pontos !== '' ? pontos : ''} // Evita erro quando pontos é null
         onChange={event => handleClassificacao(event.target.value)} disabled={disable}>
                  {classificacoes?.map(c => (<MenuItem key={c.id} value={c.pontos}>
                      {c.designacao}
                    </MenuItem>))}
                </Select>
                {formik.touched.avaliacaoQualitativaId && formik.errors.avaliacaoQualitativaId && <FormHelperText>{formik.errors.avaliacaoQualitativaId}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>

          {avaliacaoOriginal?.estadoId === 10 && avaliacaoOriginal?.avaliadorId !== user.id ? (<></>) : (<Typography variant='h6' sx={{ mt: 2, mb: 1 }}>
              Observações
            </Typography>)}

          <Grid container spacing={2}>
            {showComentarioAvaliador ? (<Grid item xs={12} md={12} lg={12} xl={12}>
                <TextField fullWidth name='observacoesAvaliador' label='Comentários do Avaliador' required={avaliacaoOriginal?.estadoId === 40 || avaliacaoOriginal?.estadoId === 50 ? false : true} color='info' size='small' multiline minRows={3} onChange={formik.handleChange} value={formik.values.observacoesAvaliador} error={formik.touched.observacoesAvaliador && Boolean(formik.errors.observacoesAvaliador)} helperText={formik.touched.observacoesAvaliador && formik.errors.observacoesAvaliador} disabled={disableComentarioAvaliador}/>
              </Grid>) : (<></>)}

            {showComentarioRh ? (<Grid item xs={12} md={12} lg={12} xl={12}>
                <TextField fullWidth name='observacoesRh' label='Comentários dos RH' color='info' size='small' multiline minRows={3} onChange={formik.handleChange} value={formik.values.observacoesRh} error={formik.touched.observacoesRh && Boolean(formik.errors.observacoesRh)} helperText={formik.touched.observacoesRh && formik.errors.observacoesRh} disabled={disableComentarioRH}/>
              </Grid>) : (<></>)}

            {showComentarioAvaliado ? (<Grid item xs={12} md={12} lg={12} xl={12}>
                {avaliacaoOriginal.estadoId === 40 ? showWarning ? warningComponent() : <></> : <></>}
                <TextField fullWidth name='observacoesAvaliado' label='Comentários do Avaliado' required={avaliacaoOriginal?.estadoId === 40 ? true : false} color='info' size='small' multiline minRows={3} onChange={formik.handleChange} value={formik.values.observacoesAvaliado} error={formik.touched.observacoesAvaliado && Boolean(formik.errors.observacoesAvaliado)} helperText={formik.touched.observacoesAvaliado && formik.errors.observacoesAvaliado} disabled={disableComentarioAvaliado}/>
              </Grid>) : (<></>)}
          </Grid>

          <History lista={hist} openHistory={openHistory} onClose={() => setOpenHistory(false)}></History>

          <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} sx={{ mt: 2 }}>
            {showSaveButton ? (<Button variant='contained' onClick={() => formik.handleSubmit()}>
                {saveButtonText}
              </Button>) : (<></>)}
            {showValidationBottons ? (<Stack direction='row' spacing={2}>
                <Button variant='contained' color='warning' onClick={() => {
                    formik.setFieldValue('estadoId', 15);
                    formik.handleSubmit();
                }}>
                  Requerer Revisão
                </Button>
                <Button variant='contained' color='success' onClick={() => {
                    formik.setFieldValue('estadoId', 30);
                    formik.handleSubmit();
                }}>
                  Validar Avaliação
                </Button>
              </Stack>) : (<></>)}
          </Stack>
        </Box>) : (<></>)}
    </Box>);
};
export default RhAvaliacaoForm;
