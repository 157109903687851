import angular from 'angular';

import {routes} from "./elevadores.routes";
import DirectoryElevadorController from "./directory/directory.controller";
import DetalhesElevadorController from "./details/details.controller";
import ELVElevadorFileUploaderController from "./details/files.dialog.controller";

export default angular.module('app.elv.elevadores', [])
  .config(routes)
  .controller('DirectoryElevadorController', DirectoryElevadorController)
  .controller('DetalhesElevadorController', DetalhesElevadorController)
  .controller('ELVElevadorFileUploaderController', ELVElevadorFileUploaderController)
  .name;
