import angular from 'angular';
import {routes} from './ativos.routes'

import Fornecedores from "./fornecedores";
import Familias from "./familias";
// import Tipos from "./tipos";
import EntidadesProprietarias from "./entidadesproprietarias";
import Locais from "./locais";
import Ativos from "./ativos";
import Interacoes from "./interacoes";
import Colaboradores from "./colaboradores";
import AtvAtivosService from "./ativos.service";
import AtvInteracoesService from "./interacoes/interacoes.service"

export default angular.module('app.ativos', [Fornecedores, Familias, /*Tipos,*/ EntidadesProprietarias, Locais, Ativos, Interacoes, Colaboradores])
  .config(routes)
  .service('AtvAtivosService', AtvAtivosService)
  .service('AtvInteracoesService', AtvInteracoesService)
  .name;
