export default class EditRequisitantesController {

    constructor($rootScope, $state, $stateParams, Requisitante, TipoRequisitante, UIService) {
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.Requisitante = Requisitante;
        this.UI = UIService;
        this.TipoRequisitante = TipoRequisitante;
        this.requisitante = {};
        this.tiposrequisitante = [];
        this.showNome = '';
        this.success = 0;
        this.requisitanteId = $stateParams.id;

        this.Requisitante.findOne({
            filter: {
                where: {
                    id: this.requisitanteId,
                    active: 1
                }
            },
            include: 'TipoRequisitante'
        }).$promise.then((requisitante) => {
            this.requisitante = requisitante;
            this.showNome = requisitante.nome;
        }).catch((error) => {
           console.log(error);
        });

        this.TipoRequisitante.find({
            filter: {
                where: {
                    active: 1
                }
            }
        }).$promise.then((tipos) => {
            this.tiposrequisitante = tipos;
        }).catch((err) => {
           console.log(err);
        });
    }

    editar = () => {
        if(this.isEntidade()) {
            this.requisitante.nTecnico = null;
        }
        this.requisitante.$save().then(() => {
            this.UI.addToast("Requisitante atualizado com sucesso");
        }).catch(() => {
          this.UI.addToast("Ocorreu um erro a atualizar dados do requisitante");
        });
    };

    isEntidade = () => {
        return (this.requisitante.tiporequisitanteId === 3) || false;
    };
}

EditRequisitantesController.$inject = ['$rootScope', '$state', '$stateParams', 'Requisitante', 'TipoRequisitante', 'UIService'];
