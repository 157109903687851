export default class FormacaoCursoDetailsController {
  constructor($state, $http, $filter, AuthenticationService, $sce, $stateParams, $timeout, FormacaoCurso, FormacaoModulo, UIService, FormacaoEdicao) {
    this.$state = $state;
    this.$http = $http;
    this.$filter = $filter;
    this.$sce = $sce;
    this.id = $stateParams.id;
    this.$timeout = $timeout;
    this.FormacaoCurso = FormacaoCurso;
    this.FormacaoModulo = FormacaoModulo;
    this.FormacaoEdicao = FormacaoEdicao;
    this.UI = UIService;
    this.hasLoaded = false;
    // Redirect if not defined
    if (!this.id)
      this.$state.go('app.formacao.cursos.list');

    this.moduleOptions = {
      containment: 'parent',
      tolerance: 'pointer',
      handle: '.pmd-move-handle',
      'ui-floating': true,
      stop: (e, ui) => {
        for (let i in this.curso.modulos) {
          this.curso.modulos[i].order = Number(i) + 1;
          FormacaoModulo.upsert(this.curso.modulos[i]);
        }
      }
    };

    this.loadData();
  }

  generateCodigo = () => {
    return "C" + ("0000000" + this.id).slice(-7);
  };

  generateCodeForModule = m => {
    return "M" + ("0000000" + m.id).slice(-7);
  };

  editModule = (module) => {
    let total = angular.copy(this.curso.duracao);
    this.curso.modulos.forEach(m => {
      if (m.id !== module.id)
        total -= m.horas;
    });
    this.UI.showDialog({
      size: 'lg',
      template: require('./module.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.horasDisponiveis = total;


        $scope.modulo = angular.copy(module);

        $scope.ok = () => {
          $scope.$close($scope.modulo);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    }).then(res => {
      if (res) {
        this.FormacaoModulo.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Módulo editado com sucesso');
        }).catch(() => {
          this.UI.addToast('Erro ao editar módulo');
        });
      }
    }).catch(() => {
    });
  };

  addModule = () => {
    let total = angular.copy(this.curso.duracao);
    this.curso.modulos.forEach(m => {
      total -= m.horas;
    });
    this.UI.showDialog({
      size: 'lg',
      template: require('./module.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.horasDisponiveis = total;

        $scope.modulo = {
          id: 0,
          active: true,
          cursoId: this.id
        };

        $scope.ok = () => {
          $scope.$close($scope.modulo);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    }).then(res => {
      if (res) {
        this.FormacaoModulo.find({
          filter: {
            where: {
              cursoId: this.id,
              active: true,
            },
            order: 'order DESC',
            limit: 1
          }
        }).$promise.then((a) => {
          let order = 1;
          if (a.length === 1) {
            order = a[0].order + 1;
          }
          res.order = order;
          this.FormacaoModulo.create(res).$promise.then(() => {
            this.loadData();
            this.UI.addToast('Módulo adicionado com sucesso');
          }).catch(() => {
            this.UI.addToast('Erro ao adicionar módulo');
          })
        });

      }
    });
  };

  editDetails = () => {
    let curso = angular.copy(this.curso);
    this.UI.showDialog({
      size: 'lg',
      template: require('./course.dialog.html'),
      controller: ['$scope', 'FormacaoAreaCategoria', ($scope, FormacaoAreaCategoria) => {
        FormacaoAreaCategoria.find({
          filter: {
            where: {
              active: true
            },
            include: 'area'
          }
        }).$promise.then((areas) => {
          $scope.areas = areas;
        });

        $scope.curso = {
          id: curso.id,
          duracao: curso.duracao,
          designacao: curso.designacao,
          conteudos: curso.conteudos,
          datacriacao: curso.datacriacao,
          active: true
        };

        $scope.auxArea = {
          selected: curso.areaCategoria
        };

        $scope.ok = () => {
          $scope.curso.areaCategoriaId = $scope.auxArea.selected.id;
          $scope.$close($scope.curso);
        };

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    }).then(res => {
      if (res) {
        this.curso.id = res.id;
        this.curso.duracao = res.duracao;
        this.curso.designacao = res.designacao;
        this.curso.conteudos = res.conteudos;
        this.curso.datacriacao = res.datacriacao;
        this.curso.areaCategoriaId = res.areaCategoriaId;
        if (this.curso.areaCategoria)
          this.curso.areaCategoria.id = res.areaCategoriaId;
        this.curso.$save().then(() => {
          this.loadData();
          this.UI.addToast('Curso atualizado com sucesso');
        }).catch(() => {
          this.UI.addToast('Erro ao atualizar curso');
        })
      }
    })
  };

  editObservacoes = () => {
    let c = angular.copy(this.curso);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {
        $scope.observacoes = c.observacoes;

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.ok = () => {
          $dialog.close($scope.observacoes);
        }
      }]
    });

    dialog.then((res) => {
      c.observacoes = res;
      c.$save().then(() => {
        this.loadData();
        this.UI.addToast('Curso atualizado com sucesso');
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro ao atualizar curso');
      });
    }
    )
      ;
  };

  editConteudoProgramatico = (m) => {
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./content.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.modulo = angular.copy(m);
        $scope.programa = $scope.modulo.conteudo;
        $scope.ok = () => $scope.$close($scope.programa);
        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });

    dialog.then(res => {
      if (res) {
        m.conteudo = res;
        this.FormacaoModulo.upsert(m).$promise.then(() => {
          this.UI.addToast('Conteúdo programático atualizado com sucesso');
        }).catch(() => {
          this.UI.addToast('Erro ao atualizar conteúdo programático');
        });
      }
    }).catch(() => {
    });
  };

  deleteModulo = (m) => {
    this.UI.showConfirm(`Deseja remover o módulo ${m.order} - ${m.designacao}?`).then(res => {
      if (res) {
        //TODO: Delete modulo
        m.active = false;
        this.FormacaoModulo.upsert(m).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Módulo removido com sucesso');
        });
      }
    });
  };

  downloadEdicao = (e) => {
    if (e.tipoId !== 3 && (angular.isUndefined(e.morada) || angular.isUndefined(e.cp4) || angular.isUndefined(e.cp4) || angular.isUndefined(localidade))) {
      this.UI.showAlert("Não é possível exportar edição para SIGO.\nPor favor verifique se os seguintes campos estão preenchidos na empresa.\n(Morada, Código-Postal, Localidade)");
      return;
    }
    let wait = this.UI.showWaiting();
    this.FormacaoCurso.exportEdition({ id: e.id }).$promise.then(result => {
      let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + result.fileData;
      let b = document.createElement('a');
      b.href = url;
      b.download = `Acao_Formacao_A${("0000000" + e.id).slice(-7)}.xlsx`;
      b.click();
      wait.close();
    }).catch(() => {
      this.UI.addToast('Ocorreu um erro ao gerar ficheiro!');
    });
  };

  downloadModules = () => {
    let wait = this.UI.showWaiting();
    this.FormacaoCurso.exportModules({ id: this.id }).$promise.then(result => {
      let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + result.fileData;
      let b = document.createElement('a');
      b.href = url;
      b.download = `Modulos_${this.generateCodigo()}.xlsx`;
      b.click();
      wait.close();
    }).catch(() => {
      this.UI.addToast('Ocorreu um erro ao gerar ficheiro!');
    });
  };

  loadData = () => {
    this.hasLoaded = false;
    this.FormacaoCurso.findOne({
      filter: {
        where: {
          id: this.id,
          active: 1
        },
        include: [
          {
            relation: 'areaCategoria',
            scope: {
              include: 'area'
            }
          },
          {
            relation: 'modulos',
            scope: {
              where: {
                active: true
              }
            }
          },
          {
            relation: 'edicoes',
            scope: {
              where: {
                active: true
              },
              include: ['tipo', 'estado', 'empresa']
            }
          }
        ]
      }
    }).$promise.then((res) => {
      if (res) {
        this.hasLoaded = true;
        this.curso = res;
      } else {
        this.UI.addToast('Ocorreu um erro ao carregar o curso');
        this.$state.go('app.formacao.cursos.list');
      }
    });
  };

  addEdicao = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./edition.dialog.html'),
      controller: ['$scope', '$dialog', 'FormacaoEdicao', 'FormacaoEdicaoTipo', 'FormacaoEmpresa', 'FormacaoLocal', ($scope, $dialog, FormacaoEdicao, FormacaoEdicaoTipo, FormacaoEmpresa, FormacaoLocal) => {
        $scope.auxTipo = {};
        $scope.auxEmpresa = {};
        $scope.auxLocal = {};
        $scope.edicao = {};

        $scope.getNoEdicao = () => {
          FormacaoEdicao.find({
            filter: {
              where: {
                active: true,
                ano: $scope.edicao.ano.format('YYYY'),
                cursoId: this.id,
              },
              limit: 1,
              order: 'no DESC'
            }
          }).$promise.then(r => {
            if (r.length > 0) {
              $scope.edicao.no = r[0].no + 1;
            } else {
              $scope.edicao.no = 1;
            }
          });
        };

        FormacaoEdicaoTipo.find({
          where: {
            active: true
          }
        }).$promise.then(tipos => {
          $scope.tipos = tipos;
        });

        $scope.updateModal = (item) => {
          if (item.id === 2)
            FormacaoEmpresa.find({ filter: { where: { active: true } } }).$promise.then(empresas => $scope.empresas = empresas);
          else
            FormacaoLocal.find({ filter: { where: { active: true } } }).$promise.then(locais => $scope.locais = locais);
        };

        $scope.ok = () => {
          $scope.edicao.ano = moment($scope.edicao.ano).format("YYYY");
          $scope.edicao.id = 0;
          $scope.edicao.active = true;
          $scope.edicao.estadoId = 2; // Programada
          $scope.edicao.tipoId = $scope.auxTipo.selected.id;
          if ($scope.edicao.tipoId === 2) {
            $scope.edicao.empresaId = $scope.auxEmpresa.selected.id;
          } else {
            $scope.edicao.localId = $scope.auxLocal.selected.id;
          }
          $scope.$close($scope.edicao);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }]
    }).then(res => {
      if (res) {
        res.cursoId = this.id;
        this.FormacaoEdicao.create(res).$promise.then(r => {
          this.loadData();
          this.UI.addToast("Edição adicionada com sucesso");
        }).catch(() => {
          this.UI.addToast("Ocorreu um erro ao adicionar edição!");
        });
      }
    });
  };

  getAreaFormacao = () => {
    if (this.hasLoaded) {
      if (angular.isDefined(this.curso.areaCategoria)) {
        return "<p>" + this.curso.areaCategoria.designacao + "</p><p class='hidden-sm'>(" + this.curso.areaCategoria.area.codigo + ") " + this.curso.areaCategoria.area.designacao + "</p>";
      } else {
        return "<p>N/D</p>";
      }
    } else {
      return "<p>N/D</p>";
    }
  }
}

FormacaoCursoDetailsController
  .$inject = ['$state', '$http', '$filter', 'AuthenticationService', '$sce', '$stateParams', '$timeout', 'FormacaoCurso', 'FormacaoModulo', 'UIService', 'FormacaoEdicao'];
