import 'regenerator-runtime/runtime'

export default class LmeDetailInterventionController {
  constructor($state, $q, UIService, LmeService, $filter, LmeOrdemintervencao, LmeAmostra, LmeProcesso, LmeLaboratorioSubarea, LmeCliente, LmeCodigoArtigo, AjlLmeCodigoArtigo, LmeProcessoAmostra) {
    this.id = $state.params.id;
    this.$q = $q;
    this.$state = $state;
    this.$filter = $filter;
    this.UI = UIService;
    this.LmeService = LmeService;
    this.state = $state;
    this.LmeOrdemintervencao = LmeOrdemintervencao;
    this.LmeAmostra = LmeAmostra;
    this.OI = LmeOrdemintervencao;
    this.LmeProcesso = LmeProcesso;
    this.LmeLaboratorioSubarea = LmeLaboratorioSubarea;
    this.LmeCliente = LmeCliente;
    this.LmeCodigoArtigo = LmeCodigoArtigo;
    this.AjlLmeCodigoArtigo = AjlLmeCodigoArtigo;
    this.LmeProcessoAmostra = LmeProcessoAmostra;
    this.loaded = false;
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.OI.findOne({
      filter: {
        where: {
          id: this.id,
          active: true
        },
        include: [
          {
            relation: 'cliente',
            scope: {
              where: {
                active: true
              }
            }
          },
          {
            relation: 'processos',
            scope: {
              where: {
                active: true
              },
              include: ['Pai', 'Estado', 'Subarea', 'Laboratorio', 'Tipo']
            }
          },
          {
            relation: 'origem',
            scope: {
              include: {
                relation: 'linhas',
                scope: {
                  order: 'numlinha ASC'
                }
              }
            }
          },
          {
            relation: 'origemAjl',
            scope: {
              include: {
                relation: 'linhas',
                scope: {
                  order: 'numlinha ASC'
                }
              }
            }
          },
          {
            relation: 'empresa',
            scope: {
              where: {
                active: 1
              }
            }
          }
        ]
      }
    }).$promise.then(res => {

      // Check which origem is the correct one, discard the other
      if (!res.empresaId || res.empresaId === 1) {
        // Nothing to do, origem is ok
      } else {
        // Origem should be origemAjl relation
        res.origem = res.origemAjl;
      }
      delete res.origemAjl;

      this.data = res;
      this.loaded = true;
    }).catch((error) => {
      console.log(error);
      this.state.go('app.lab.interventions.list');
      this.UI.addToast('Erro ao carregar ordem de intervenção');
    });
  };

  viewProcess = r => {
    this.$state.go('app.lab.processes.details', {
      id: this.$filter('lmeFormat')(r)
    });
  };

  canAdd = (line) => {
    let qty = Number(line.quantidade) || 0;
    let existingProcessos = this.data.processos.filter(p => p.primaveraId === line.id);
    return existingProcessos.length < qty;
  };

  editObservations = () => {
    let data = angular.copy(this.data.observacoes);
    let o = {
      template: require('./observations.dialog.html'),
      controller: ['$dialog', '$scope', function ($dialog, $scope) {
        $scope.data = data;
        $scope.ok = () => {
          $dialog.close($scope.data);
        };
        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }]
    };

    let modal = this.UI.showDialog(o);
    modal.then(res => {
      this.data.observacoes = res;
      this.data.$save().then(() => {
        this.UI.addToast('Alterações guardadas com sucesso');
      }).catch(err => {
        this.UI.addToast('Erro ao guardar alterações');
      });
    })
  };

  OIisEnsaio = () => {
    if (!this.data) {
      return false;
    }
    if (this.data.processos.length > 0) {
      return this.data.processos[0].tipoId === 2;
    }
    return false;
  };

  validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  editDetalhes = () => {
    let data = angular.copy(this.data);
    let options = {
      size: 'lg',
      template: require('./details.dialog.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {
        $scope.o = data;
        $scope.o.contrato = !!$scope.o.contrato;

        $scope.ok = () => {
          // Check valid email (if exists)
          if ($scope.o.email != null) {
            $scope.o.email = $scope.o.email.toString().trim();
            if ($scope.o.email.length === 0)
              $scope.o.email = null;
          }

          if ($scope.o.email !== null && !this.validEmail($scope.o.email)) {
            this.UI.addToast("Email de OI inválido. Por favor corrija-o ou apague-o.");
            return;
          }
          $dialog.close($scope.o);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);
    modal.then(res => {
      if (res) {
        this.OI.upsert(res).$promise.then(() => {
          this.UI.addToast('Ordem de Intervenção atualizada');
          this.loadData();
        }).catch(() => {
          this.UI.addToast('Erro ao atualizar Ordem de Intervenção');
        });
      }
    });
  };

  cloneProcess = r => {
    let options = {
      size: 'md',
      template: require('./clone.dialog.html'),
      controller: ['$scope', '$dialog', ($scope, $dialog) => {
        $scope.nvezes = 1;

        $scope.ok = () => {
          $dialog.close($scope.nvezes);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.isOk = () => {
          return angular.isDefined($scope.nvezes) && $scope.nvezes > 0;
        }
      }]
    };

    let modal = this.UI.showDialog(options);
    modal.then(res => {
      if (res) {
        this.loaded = false;
        (async () => {
          let clonagens = 0;
          for (let i = 0; i < res; i++) {
            try {
              await this.LmeProcesso.clone({id: r.id}).$promise;
              clonagens++;
            } catch (e) {
              this.UI.addToast("Ocorreu um erro a clonar o processo");
              console.log(e);
            }
          }
          let msgClone = "Processo clonado " + clonagens + " vez";
          if (clonagens !== 1)
            msgClone += "es";
          this.UI.addToast(msgClone);
          this.loadData();
        })();
      }
    });
  };

  addProcess = () => {
    let empresaId = !this.data.empresaId ? 1 : this.data.empresaId; // If no empresaId, assume IEP
    let options = {
      size: 'lg',
      template: require('./process.dialog.html'),
      controller: ['$scope', 'LmeTipo', 'LmeProcessoCaracteristica', 'LmeLaboratorio', 'LmeLaboratorioSubarea', ($scope, LmeTipo, LmeProcessoCaracteristica, LmeLaboratorio, LmeLaboratorioSubarea) => {

        $scope.auxLaboratorio = {};
        $scope.auxCaracteristica = {};
        $scope.auxSubarea = {};

        LmeTipo.find({
          filter: {
            where: {active: true}
          }
        }).$promise.then(tipos => {
          $scope.tipos = tipos;
          $scope.auxTipo = {
            selected: undefined
          };
          LmeLaboratorio.find({
            filter: {
              where: {active: true}
            }
          }).$promise.then(labs => {
            $scope.laboratorios = labs;
            if ($scope.auxTipo.selected != null) {
              $scope.labs = labs.filter(lab => {
                return lab.tipoId === $scope.auxTipo.selected.id;
              });
              $scope.disableLab = false;
            } else {
              $scope.disableLab = true;
            }
            LmeProcessoCaracteristica.find({
              filter: {
                where: {active: true}
              }
            }).$promise.then(c => {
              $scope.caracteristicas = c;
              LmeLaboratorioSubarea.find({
                filter: {
                  where: {active: true}
                }
              }).$promise.then(subareas => {
                $scope.subareas = subareas;
                if ($scope.auxLaboratorio.selected)
                  $scope.usableSubareas = subareas.filter(x => x.laboratorioId === $scope.auxLaboratorio.selected.id);
                else
                  $scope.usableSubareas = [];
                $scope.loaded = true;
              });
            });
          });
        });

        $scope.data = {
          id: 0,
          empresaId: empresaId,
          active: true
        };

        $scope.yesNo = [{
          id: 0,
          value: 'Não'
        }, {
          id: 1,
          value: 'Sim'
        }];

        $scope.auxAcreditado = {
          selected: $scope.yesNo[1]
        };
        $scope.auxCertificado = {};

        $scope.auxInSitu = {
          selected: $scope.yesNo[0]
        };

        $scope.auxSubcontratacao = {
          selected: $scope.yesNo[0]
        };

        $scope.onLabSelected = (item) => {
          $scope.auxSubarea = {selected: undefined};
          $scope.usableSubareas = $scope.subareas.filter(x => x.laboratorioId === item.id);
        };

        $scope.onTipoSelected = (item) => {
          $scope.labs = $scope.laboratorios.filter(lab => {
            return lab.tipoId === item.id;
          });
          $scope.disableLab = false;
        };

        $scope.ok = () => {
          if ($scope.loaded) {
            $scope.data.tipoId = $scope.auxTipo.selected.id;
            $scope.data.laboratorioId = $scope.auxLaboratorio.selected.id;
            if ($scope.auxAcreditado && $scope.auxAcreditado.selected)
              $scope.data.acreditado = $scope.auxAcreditado.selected.id;
            if ($scope.auxCertificado && $scope.auxCertificado.selected)
              $scope.data.certificado = $scope.auxCertificado.selected ? $scope.auxCertificado.selected.id : 0;
            if ($scope.auxInSitu && $scope.auxInSitu.selected)
              $scope.data.inSitu = $scope.auxInSitu.selected.id;
            if ($scope.data.tipoId === 2) {
              if ($scope.data.laboratorioId === 1 || $scope.data.laboratorioId === 30) {
                $scope.data.subareaId = $scope.auxSubarea.selected ? $scope.auxSubarea.selected.id : null;
              }
              if ($scope.auxCaracteristica.hasOwnProperty('selected'))
                $scope.data.caracteristicaId = $scope.auxCaracteristica.selected.id || null;
            }
            $scope.$close($scope.data);
          } else
            $scope.$dismiss('cancel');
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }],
    };

    let modal = this.UI.showDialog(options);
    modal.then((res) => {
      if (res) {
        // Save new process
        res.oiId = this.data.id;
        res.subcontratacao = 0;
        res.estadoId = 1; // Aguarda amostra
        this.LmeProcesso.create(res).$promise.then((p) => {
          this.UI.addToast('Processo adicionado');
          this.loadData();
        }).catch(err => this.UI.addToast('Ocorreu um erro ao adicionar novo processo'));
      }
    })
  };

  importFrom = (linha) => {
    let CodigoArtigoModel;
    let empresaId;
    if (!this.data.empresaId || this.data.empresaId === 1) {
      CodigoArtigoModel = this.LmeCodigoArtigo;
      empresaId = 1;
    } else {
      CodigoArtigoModel = this.AjlLmeCodigoArtigo;
      empresaId = 2;
    }
    CodigoArtigoModel.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((codArtigos) => {
      if (angular.isDefined(linha.artigo)) {
        // Choose tipo processo
        let tipoId = this.LmeService.getTipoId(this.data.empresaId, linha.ccustocbl);
        let tmp = codArtigos.find(c => c.codigo === linha.artigo);
        let data = ({
          id: 0,
          empresaId: empresaId,
          tipoId: tipoId,
          artigo: linha.descricao,
          laboratorioId: tmp ? tmp.laboratorioId : null,
          primaveraId: linha.id,
          valor: linha.quantidade ? linha.precoliquido / linha.quantidade : linha.precoliquido,
          active: true
        });
        let options = {
          size: 'lg',
          template: require('./process.dialog.html'),
          controller: ['$scope', 'LmeTipo', 'LmeProcessoCaracteristica', 'LmeLaboratorio', 'LmeLaboratorioSubarea', ($scope, LmeTipo, LmeProcessoCaracteristica, LmeLaboratorio, LmeLaboratorioSubarea) => {

            $scope.auxCaracteristica = {};
            $scope.auxSubarea = {};
            $scope.data = data;

            LmeTipo.find({
              filter: {
                where: {active: true}
              }
            }).$promise.then(tipos => {
              $scope.tipos = tipos;
              $scope.auxTipo = {
                selected: tipos.find(f => f.id === tipoId)
              };
              LmeLaboratorio.find({
                filter: {
                  where: {active: true}
                }
              }).$promise.then(labs => {
                $scope.laboratorios = labs;
                if (data.tipoId != null) {
                  $scope.labs = labs.filter(lab => {
                    return lab.tipoId === data.tipoId;
                  });
                  $scope.auxLaboratorio = {
                    selected: labs.find(lab => {
                      return lab.id === data.laboratorioId;
                    })
                  };
                  $scope.disableLab = false;
                } else {
                  $scope.disableLab = true;
                }
                LmeProcessoCaracteristica.find({
                  filter: {
                    where: {active: true}
                  }
                }).$promise.then(c => {
                  $scope.caracteristicas = c;
                  LmeLaboratorioSubarea.find({
                    filter: {
                      where: {active: true}
                    }
                  }).$promise.then(subareas => {
                    $scope.subareas = subareas;
                    // If we already have laboratorio, just use the ones for it
                    $scope.usableSubareas = subareas.filter(x => x.laboratorioId === $scope.auxLaboratorio.selected.id);
                    $scope.loaded = true;
                  });
                });
              });
            });


            $scope.yesNo = [{
              id: 0,
              value: 'Não'
            }, {
              id: 1,
              value: 'Sim'
            }];

            $scope.auxAcreditado = {
              selected: $scope.yesNo[1]
            };
            $scope.auxCertificado = {};

            $scope.auxInSitu = {
              selected: $scope.yesNo[0]
            };

            $scope.auxSubcontratacao = {
              selected: $scope.yesNo[0]
            };

            $scope.onLabSelected = (item) => {
              $scope.subarea = {selected: undefined};
              $scope.usableSubareas = $scope.subareas.filter(x => x.laboratorioId === item.id);
            };

            $scope.onTipoSelected = (item) => {
              $scope.labs = $scope.laboratorios.filter(lab => {
                return lab.tipoId === item.id;
              });
            };

            $scope.ok = () => {
              if ($scope.loaded) {
                $scope.data.tipoId = $scope.auxTipo.selected.id;
                $scope.data.laboratorioId = $scope.auxLaboratorio.selected.id;
                $scope.data.acreditado = $scope.auxAcreditado.selected.id;
                $scope.data.certificado = $scope.auxCertificado.selected ? $scope.auxCertificado.selected.id : 0;
                $scope.data.inSitu = $scope.auxInSitu.selected.id;
                if ($scope.data.tipoId === 2) {
                  if ($scope.data.laboratorioId === 1 || $scope.data.laboratorioId === 30) {
                    $scope.data.subareaId = $scope.auxSubarea.selected ? $scope.auxSubarea.selected.id : null;
                  }
                  if ($scope.auxCaracteristica.hasOwnProperty('selected'))
                    $scope.data.caracteristicaId = $scope.auxCaracteristica.selected.id || null;
                }
                $scope.$close($scope.data);
              } else
                $scope.$dismiss('cancel');
            };

            $scope.cancel = () => {
              $scope.$dismiss('cancel');
            };
          }],
        };

        let modal = this.UI.showDialog(options);
        modal.then((res) => {
          if (res) {
            // Save new process
            res.oiId = this.data.id;
            res.subcontratacao = 0;
            res.estadoId = 1; // Aguarda amostra
            this.LmeProcesso.create(res).$promise.then((p) => {
              this.UI.addToast('Processo adicionado');
              this.loadData();
            }).catch(err => this.UI.addToast('Ocorreu um erro ao adicionar novo processo'));
          }
        });
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível obter códigos de artigos para validação.");
    });
  };

  deleteProcess = r => {
    let result = this.UI.showConfirm('Deseja remover o processo?');
    result.then(res => {
      if (res) {
        let wait = this.UI.showWaiting();
        // Check if amostras are connected
        this.LmeProcessoAmostra.find({
          filter: {
            where: {
              and: [{active: 1}, {processoId: r.id}]
            }
          }
        }).$promise.then((processoAmostras) => {
          // Amostras assigned were found
          if (processoAmostras && processoAmostras.length > 0) {
            wait.close();
            let alert = this.UI.showAlert("Este processo está ligado a pelo menos uma amostra. Verifique esta ligação antes de eliminar.");
            alert.finally(() => {});
          } else { // No amostras set
            this.LmeProcesso.find({
              filter: {
                where: {
                  or: [{paiId: r.id}, {raizId: r.id}]
                }
              }
            }).$promise.then((paisRaizes) => {
              wait.close();
              if (paisRaizes && paisRaizes.length > 0) {
                let quantidadeString;

                if (paisRaizes.length === 1)
                  quantidadeString = "1 processo que depende";
                else
                  quantidadeString = `${paisRaizes.length} processos que dependem`;

                let alert = this.UI.showAlert(`Há ${quantidadeString} deste processo (ID SGI: ${paisRaizes.map(x => x.id).toString()}). Verifique as ligações ao processo antes de eliminar.`);
                alert.finally(() => {});
              } else {
                // Remove process (active: false)
                r.active = false;
                this.LmeProcesso.upsert(r).$promise.then(() => {
                  this.UI.addToast('Processo removido com sucesso');
                  this.loadData();
                }).catch(() => {
                  this.UI.addToast('Erro ao remover processo. Verifique a ligação');
                  this.loadData();
                });
              }
            }).catch((error) => {
              wait.close();
              console.log(error);
              this.UI.addToast("Não foi possível consultar processos. Verifique a ligação.");
            });
          }
        }).catch((error) => {
          wait.close();
          console.log(error);
          this.UI.addToast("Não foi possível consultar ligações de amostras. Verifique a ligação.");
        });
      }
    })
  };

  // Copy URL to clipboard on click
  copyProjectFolder = (f) => {
    if (f && this.data.empresaId) {
      let url = this.LmeService.getProjectFolderPath(f, this.data.empresaId);

      if (!navigator.clipboard) { // Alternative solution
        let tempInput = document.createElement('input');
        tempInput.type = 'text';
        tempInput.value = url;
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // For mobile devices
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        this.UI.addToast("Caminho copiado para Área de Transferência");
      } else { // Navigator API solution
        navigator.clipboard.writeText(url).then(() => {
          this.UI.addToast("Caminho copiado para Área de Transferência");
        }).catch(err => {
          this.UI.addToast("Erro na cópia para Área de Transferência");
        });
      }
    } else this.UI.addToast("Sem informação a copiar");
  };

  validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  resyncClient = (id) => {
    this.LmeCliente.findOne({
      filter: {
        where: {
          id: id
        }
      }
    }).$promise.then((c) => {
      if (!c.refClientePrimavera) {
        this.UI.addToast("Não foi possível atualizar cliente.");
      } else {
        let PRIClientes = this.LmeService.getPRIClientes(c);
        PRIClientes.find({
          filter: {
            where: {
              cliente: c.refClientePrimavera
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            c.nome = res[0].nome;
            c.nif = res[0].numcontrib;
            c.cp4 = res[0].facCp.split("-")[0];
            c.cp3 = res[0].facCp.split("-")[1];
            c.localidade = res[0].facCploc;
            c.contactoTelefonico = res[0].facTel;
            c.morada = res[0].facMor;
            c.observacoes = res[0].notas;
            c.atualizadoa = res[0].dataultimaactualizacao;
            c.refClientePrimavera = res[0].cliente;
            c.email = this.validEmail(res[0].cduEmail) ? res[0].cduEmail : null;
            c.$save().then((updatedCliente) => {
              this.loadData();
              this.UI.addToast('Sincronização realizada com sucesso.');
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Falha na gravação do cliente. Por favor tente mais tarde.");
            });
          } else {
            this.UI.addToast("Não foi possível atualizar cliente.");
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar cliente.");
        });
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar cliente.");
    });
  };

  resyncOI = (id) => {
    this.LmeOrdemintervencao.findOne({
      filter: {
        where: {

          id: id
        }
      }
    }).$promise.then((c) => {
      if (!c.primaveraId) {
        this.UI.addToast("Não é possível atualizar OI.");
      } else {
        let PRICabecdoc = this.LmeService.getPRICabecdoc(c);
        PRICabecdoc.find({
          filter: {
            where: {
              id: c.primaveraId
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            c.email = this.validEmail(res[0].cduEmail) ? res[0].cduEmail : null;
            c.nomeContactoPri = res[0].cduContactonome;
            c.telefonePri = res[0].cduTelefone;
            c.telemovelPri = res[0].cduTelemovel;
            c.$save().then((updatedOI) => {
              this.loadData();
              this.UI.addToast('Sincronização realizada com sucesso.');
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Falha na gravação da OI. Por favor tente mais tarde.");
            });
          } else {
            this.UI.addToast("Não foi possível atualizar OI.");
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar OI.");
        });
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar cliente.");
    });
  };
}

LmeDetailInterventionController.$inject = ['$state', '$q', 'UIService', 'LmeService', '$filter', 'LmeOrdemintervencao', 'LmeAmostra', 'LmeProcesso', 'LmeLaboratorioSubarea', 'LmeCliente', 'LmeCodigoArtigo', 'AjlLmeCodigoArtigo', 'LmeProcessoAmostra'];
