import { routes } from './routes'

import FunOrdemIntervencao from './ordensintervencao/index';
import FunProcessos from './processos/index';

import FunCalendarizacaoController from './calendarizacao/controller';
import FunClientesListController from './clientes/list/controller';
import FunClientesDetailsController from './clientes/details/controller';

export default angular.module('app.funciona', [FunOrdemIntervencao, FunProcessos])
  .config(routes)
  .controller('FunClientesListController', FunClientesListController)
  .controller('FunCalendarizacaoController', FunCalendarizacaoController)
  .controller('FunClientesDetailsController', FunClientesDetailsController)
  .name;
