export default class AtvInteracoesDetailsController {
  constructor($http, $state, UIService, AuthenticationService, AtvAtivosService, AtvInteracao, AtvInteracaoDocumento, AtvInteracaoAlteracaoEstado) {
    this.$http = $http;
    this.state = $state;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.AtvAtivosService = AtvAtivosService;
    this.AtvInteracao = AtvInteracao;
    this.AtvInteracaoDocumento = AtvInteracaoDocumento;
    this.AtvInteracaoAlteracaoEstado = AtvInteracaoAlteracaoEstado;
    this.id = $state.params.id;
    this.loaded = false;
    this.interacao = null;
    this.designacaoIntervencao = null;
    this.showEstadosPanel = false;

    this.id = $state.params.id;
    if (angular.isUndefined($state.params.id)) {
      this.state.go('app.ativos.interacoes.list');
      this.UI.addToast('Interação desconhecida/inválida');
    } else {
      this.loadData();
    }
  }

  toggleEstadosPanel = () => {
    this.showEstadosPanel = !this.showEstadosPanel;
  };

  setTimelineColor = (h) => {
    switch (h.estado.designacao) {
      case "Criado":
        return "project-info";
      case "Alteração de dados":
        return "project-notification";
      default:
        return "project-standby";
    }
  };

  loadData = () => {
    this.loaded = false;
    this.AtvInteracao.findOne({
      filter: {
        where: {
          id: this.id,
          active: true
        },
        include: [{
          relation: 'ativo',
          scope: {
            where: {
              active: 1
            },
            include: [{
              relation: 'entidadeProprietaria',
            }, {
              relation: 'propriedade',
            // }, {
            //   relation: 'tipoAtivo',
            //   scope: {
            //     where: {
            //       active: 1
            //     }
            //   }
            }]
          }
        }, {
          relation: 'atribuidoa',
        }, {
          relation: 'funcionario',
        }, {
          relation: 'local',
        }, {
          relation: 'resultado',
        }, {
          relation: 'tipo',
        }, {
          relation: 'documentos',
          scope: {
            where: {
              active: 1
            },
            order: 'id desc',
            include: {
              relation: 'documentoTipo',
              scope: {
                where: {
                  active: 1
                }
              }
            }
          }
        }, {
          relation: 'fornecedorPrimavera',
          scope: {
            include: [{
              relation: 'entidadeProprietaria',
            }, {
              relation: 'fornecedor',
            }]
          }
        }, {
          relation: 'alteracoesEstado',
          scope: {
            where: {
              active: 1
            },
            order: 'dataAlteracao desc, id desc',
            include: [{
              relation: 'estado',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'funcionario',
            }]
          }
        }]
      }
    }).$promise.then((interacao) => {
      // Fix dates for display
      interacao.dataRegistoLayout = moment(interacao.dataRegisto).utc().format("DD/MM/YYYY HH:mm:ss");
      interacao.dataInteracaoLayout = moment(interacao.dataInteracao).utc().format("DD/MM/YYYY HH:mm:ss");
      // Only show data Intervencao if it exists
      if (interacao.dataIntervencao) {
        interacao.dataIntervencaoLayout = moment(interacao.dataIntervencao).utc().format("DD/MM/YYYY");
        this.designacaoIntervencao = interacao.tipo.designacao.split(" ")[interacao.tipo.designacao.split(" ").length - 1];
      }

      this.data = interacao;
      this.data.documentos.forEach(d => {
        this.$http.get('/api/Upload/atv/files/' + d.nome).then(file => {
          if (file.data != null && !file.data.hasOwnProperty('error')) {
            d.atime = file.data.atime;
            d.ctime = file.data.ctime;
            d.fileSize = file.data.size;
          }
        }, (err) => {
          console.log(err);
        });
      });

      this.data.alteracoesEstado.forEach(a => {
        a.dataAlteracao = moment(a.dataAlteracao).utc();
      });

      this.loaded = true;
    }).catch(() => {
      this.state.go('app.ativos.interacoes.list');
      this.UI.addToast('Erro a carregar dados de interação.');
    });
  };

  // differences - differences in editions
  updateInteracao = (differences) => {
    this.data.$save().then(() => {
      // See if there's differences and save it
      if (differences && differences.length > 0) {
        this.AtvInteracaoAlteracaoEstado.create({
          id: 0,
          interacaoEstadoId: 2, // Alteração de dados
          dataAlteracao: moment().isDST() ? moment().add(1, 'h') : moment(),
          interacaoId: this.data.id,
          funcionarioId: this.Auth.getId(),
          alteracoes: differences,
          active: true
        }).$promise.then((res) => {
          this.loadData();
          this.UI.addToast("Interação editada com sucesso");
        }).catch((error) => {
          console.log(error);
          this.loadData();
          this.UI.addToast("Interação editada com sucesso. Erro ao guardar alteração de estado.");
        });
      } else {
        this.loadData();
        this.UI.addToast("Interação editada com sucesso");
      }
    }).catch((error) => {
      console.log(error);
      this.loadData();
      this.UI.addToast("Ocorreu um erro ao editar interação");
    });
  };

  editObservations = () => {
    let obs = angular.copy(this.data.observacoes);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = obs;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.loaded = false;
      let differences = this.AtvAtivosService.interacaoDifferences({observacoes: obs}, {observacoes: res});
      this.data.observacoes = res;
      this.updateInteracao(differences);
    }).catch(() => {});
  };

  viewAtivo = r => {
    this.state.go('app.ativos.ativos.details', {id: r.id});
  };

  // Format bytes into correct units
  formatBytes = (bytes, decimals) => {
    if (bytes == null) return "N/D";
    if (bytes === 0) return '0 Bytes';
    let k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  addAnexo = () => {
    let options = {
      size: 'lg',
      controller: 'AtvInteracoesFileUploaderController',
      controllerAs: 'm',
      template: require('./files.dialog.html'),
      resolve: {
        interacaoId: () => {
          return this.data.id;
        },
        ativoId: () => {
          return this.data.ativo.id;
        },
        documento: () => {
          return undefined;
        }
      }
    };

    let dialog = this.UI.showDialog(options);
    dialog.then(res => {
      if (res) {
        // Update state with file added
        this.AtvInteracaoAlteracaoEstado.create({
          id: 0,
          interacaoEstadoId: 2, // Alteração de dados
          dataAlteracao: moment().isDST() ? moment().add(1, 'h') : moment(),
          interacaoId: this.data.id,
          funcionarioId: this.Auth.getId(),
          alteracoes: `Adição de anexo ${res.res.nomeOriginal}`,
          active: true
        }).$promise.then((s) => {
          this.loadData();
          this.UI.addToast('Documento carregado com sucesso');
        }).catch(error => {
          console.log(error);
          this.loadData();
          this.UI.addToast('Documento carregado com sucesso');
        });
      }
    });
  };

  editAnexo = (f) => {
    this.AtvInteracaoDocumento.find({
      filter: {
        where: {
          id: f.id,
          active: 1
        },
        include: {
          relation: 'documentoTipo',
          scope: {
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((documento) => {
      if (documento && documento.length > 0) {
        let options = {
          size: 'lg',
          controller: 'AtvInteracoesFileUploaderController',
          controllerAs: 'm',
          template: require('./files.dialog.html'),
          resolve: {
            interacaoId: () => {
              return this.data.id;
            },
            ativoId: () => {
              return this.data.ativo.id;
            },
            documento: () => {
              return documento[0];
            }
          }
        };

        let dialog = this.UI.showDialog(options);
        dialog.then(res => {
          if (res) {
            // Update state with file edited
            this.AtvInteracaoAlteracaoEstado.create({
              id: 0,
              interacaoEstadoId: 2, // Alteração de dados
              dataAlteracao: moment().isDST() ? moment().add(1, 'h') : moment(),
              interacaoId: this.data.id,
              funcionarioId: this.Auth.getId(),
              alteracoes: `Edição de anexo ${documento[0].nomeOriginal} para ${res.res.nomeOriginal}`,
              active: true
            }).$promise.then((s) => {
              this.loadData();
              this.UI.addToast('Documento atualizado com sucesso');
            }).catch(error => {
              console.log(error);
              this.loadData();
              this.UI.addToast('Documento atualizado com sucesso');
            });
          }
        });
      } else {
        this.UI.addToast("Anexo não encontrado. Por favor recarregue a página");
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar documento. Por favor recarregue a página");
    });
  };

  confirmRemoveAnexo = (f) => {
    let title = "Remover Anexo";
    let warning = `Tem a certeza que pretende remover o documento ${f.nomeOriginal}?`;
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.loaded = false;
      this.removeAnexo(f);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  removeAnexo = (f) => {
    this.AtvInteracaoDocumento.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((documento) => {
      documento.active = 0;
      documento.$save().then((res) => {
        // Update state with file removed
        this.AtvInteracaoAlteracaoEstado.create({
          id: 0,
          interacaoEstadoId: 2, // Alteração de dados
          dataAlteracao: moment().isDST() ? moment().add(1, 'h') : moment(),
          interacaoId: this.data.id,
          funcionarioId: this.Auth.getId(),
          alteracoes: `Removido anexo ${documento.nomeOriginal}`,
          active: true
        }).$promise.then((s) => {
          this.loadData();
          this.UI.addToast("Documento removido com sucesso");
        }).catch(error => {
          console.log(error);
          this.loadData();
          this.UI.addToast('Documento removido com sucesso');
        });
      }, (error) => {
        console.log(error);
        this.UI.addToast("Não foi possível remover documento. Por favor recarregue a página");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar documento. Por favor recarregue a página");
    });
  };
}

AtvInteracoesDetailsController.$inject = ['$http', '$state', 'UIService', 'AuthenticationService', 'AtvAtivosService', 'AtvInteracao', 'AtvInteracaoDocumento', 'AtvInteracaoAlteracaoEstado'];
