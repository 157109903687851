export function routes($stateProvider) {
  $stateProvider
    .state('app.gas.processos', {
      url: '/processos',
      abstract: true
    })
    .state('app.gas.processos.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Processos',
      template: '<gas-processos-list-view></gas-processos-list-view>',
      role: ['gasProcessoList'],
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: 'id',
          dynamic: true
        },
        sort: {
          value: 'desc',
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.gas.processos.details', {
      url: '/{id:int}',
      title: 'Detalhes do Processo',
      template: '<gas-processo-details-view></gas-processo-details-view>',
      role: ['gasProcessoList']
    })
    .state('app.gas.processos.add', {
      url: '/novo',
      title: 'Novo Processo',
      template: '<gas-processo-new-view></gas-processo-new-view>',
      role: ['gasProcessoList']
    });
}

routes.$inject = ['$stateProvider'];
