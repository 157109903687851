export default class DirectoryElevadorController {
  constructor($q, $state, UIService, AuthenticationService, ElevadorService, ElvElevador, ElvElevadorTipo, ElvEquipamentoTipo, ElvCliente, ElvEmie, ElvCondominio) {
    this.$q = $q;
    this.$state = $state;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.ElevadorService = ElevadorService;
    this.ElvElevador = ElvElevador;
    this.ElvElevadorTipo = ElvElevadorTipo;
    this.ElvEquipamentoTipo = ElvEquipamentoTipo;
    this.ElvCliente = ElvCliente;
    this.ElvEmie = ElvEmie;
    this.ElvCondominio = ElvCondominio;

    this.tableLoading = true;

    this.tipos = [];
    this.equipamentos = [];
    this.clientes = [];
    this.emies = [];
    this.condominios = [];
    this.filtersLoaded = [0, 0, 0, 0, 0];

    this.opt = $state.params;

    this.opt = this.opt || this.defaultOpt;

    // Total processes for currently being displayed
    this.total = 0;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [
      {
        displayName: "ID SGI",
        name: "id",
        visible: false,
        sortable: true
      },
      {
        displayName: "Nº Camarário",
        name: "numeroCamarario",
        visible: true,
        sortable: true
      },
      {
        displayName: "Nº Série",
        name: "numeroSerie",
        visible: false,
        sortable: true
      },
      {
        displayName: "Tipo",
        name: "tipo",
        visible: false,
        sortable: true
      },
      {
        displayName: "Equipamento",
        name: "equipamento",
        visible: true,
        sortable: true
      },
      {
        displayName: "Morada",
        name: "morada",
        visible: true,
        sortable: false
      },
      {
        displayName: "CP4",
        name: "cp4",
        visible: false,
        sortable: true
      },
      {
        displayName: "CP3",
        name: "cp3",
        visible: false,
        sortable: true
      },
      {
        displayName: "Localidade",
        name: "localidade",
        visible: true,
        sortable: true
      },
      {
        displayName: "Cliente",
        name: "cliente",
        visible: true,
        sortable: true
      },
      {
        displayName: "EMIE",
        name: "emie",
        visible: false,
        sortable: true
      },
      {
        displayName: "Condomínio",
        name: "condominio",
        visible: false,
        sortable: true
      },
      {
        displayName: "Última Inspeção",
        name: "ultimaInspecao",
        visible: false,
        exportDate: 'DD-MM-YYYY',
        sortable: true
      },
      {
        displayName: "Próxima Inspeção",
        name: "proximaInspecao",
        visible: false,
        exportDate: 'DD-MM-YYYY',
        sortable: true
      },
      {
        displayName: "Próxima Inspeção Limite",
        name: "proximaInspecaoLimite",
        visible: false,
        exportDate: 'DD-MM-YYYY',
        sortable: true
      }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: "ElvElevador.id",
        name: "ID SGI",
        type: "o"
      },
      {
        id: "ElvElevador.numeroCamarario",
        name: "Nº Camarário",
        type: "o"
      },
      {
        id: "ElvElevador.numeroSerie",
        name: "Nº Série",
        type: "o"
      },
      {
        id: "ElvElevador.tipoId",
        name: "Tipo",
        type: "s",
        list: this.tipos
      },
      {
        id: "ElvElevador.equipamentoId",
        name: "Equipamento",
        type: "s",
        list: this.equipamentos
      },
      {
        id: "ElvElevador.morada",
        name: "Morada",
        type: "o"
      },
      {
        id: "ElvElevador.cp4",
        name: "CP4",
        type: "o"
      },
      {
        id: "ElvElevador.cp3",
        name: "CP3",
        type: "o"
      },
      {
        id: "ElvElevador.localidade",
        name: "Localidade",
        type: "o"
      },
      {
        id: "ElvElevador.clienteId",
        name: "Cliente",
        type: "s",
        list: this.clientes
      },
      {
        id: "ElvElevador.emieId",
        name: "EMIE",
        type: "s",
        list: this.emies
      },
      {
        id: "ElvElevador.condominioId",
        name: "Condomínio",
        type: "s",
        list: this.condominios
      },
      {
        id: "ElvElevador.ultimaInspecao",
        name: "Última Inspeção",
        type: "d",
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: "ElvElevador.proximaInspecao",
        name: "Próxima Inspeção",
        type: "d",
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: "ElvElevador.proximaInspecaoLimite",
        name: "Pŕox. Insp. Limite",
        type: "d",
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      }
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem("ElvElevadorDisplayColumns")) {
      let cols = JSON.parse(localStorage.getItem("ElvElevadorDisplayColumns"));
      if (cols && cols.length > 0) {
        cols.forEach((c) => {
          let i = this.displayColumns.findIndex((x) => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem("ElvElevadorFilter")) {
      this.customFilters = JSON.parse(localStorage.getItem("ElvElevadorFilter"));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach((filter) => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {},
            };
            data.column.selected = this.columns.find((f) => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast("Não foi possível carregar filtros");
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem("ElvElevadorFilter", JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === "ElvElevador.tipoId")
          f.column.selected.list = this.tipos;
        if (f.column.selected.id === "ElvElevador.equipamentoId")
          f.column.selected.list = this.equipamentos;
        if (f.column.selected.id === "ElvElevador.clienteId")
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === "ElvElevador.emieId")
          f.column.selected.list = this.emies;
        if (f.column.selected.id === "ElvElevador.condominioId")
          f.column.selected.list = this.condominios;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === "d") {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    this.loadData();

    this.getElevadores();
  }

  isColumnVisible = (column) => {
    let f = this.displayColumns.find((x) => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  clearFilter = () => {
    localStorage.removeItem('ElvElevadorFilter');
    this.customFilters = [];
    this.getElevadores();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find((x) => x.key === data.key)) {
      // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value,
      });
    } else {
      // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) {
        // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key],
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value,
        });
      } else {
        // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach((f) => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split(".").filter(Boolean).length === 1 && f.column.selected.type === "d") {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format,
        });
      } else {
        // All other cases where fields exist in database
        if (f.column.selected.type === "o") {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === "d") {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format,
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id) data.value = f.value.selected.id;
            else data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral,
    };
  };

  loadData = () => {
    // Tipos de Elevadores
    this.ElvElevadorTipo.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((tipos) => {
      tipos.forEach(n => {
        n.name = n.designacao;
      });
      this.tipos = tipos;
      this.filtersLoaded[0] = 1;
    }).catch((error) => {
      this.tipos = [];
      this.filtersLoaded[0] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Tipos)");
    });

    // Equipamentos
    this.ElvEquipamentoTipo.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((equipamentos) => {
      equipamentos.forEach(n => {
        n.name = n.designacao;
      });
      this.equipamentos = equipamentos;
      this.filtersLoaded[1] = 1;
    }).catch((error) => {
      this.equipamentos = [];
      this.filtersLoaded[1] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Equipamentos)");
    });

    // Clientes
    this.ElvCliente.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((clientes) => {
      clientes.forEach(n => {
        n.name = n.nome;
      });
      this.clientes = clientes;
      this.filtersLoaded[2] = 1;
    }).catch((error) => {
      this.clientes = [];
      this.filtersLoaded[2] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Clientes)");
    });

    // EMIEs
    this.ElvEmie.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((emies) => {
      emies.forEach(n => {
        n.name = n.nome;
      });
      this.emies = emies;
      this.filtersLoaded[3] = 1;
    }).catch((error) => {
      this.emies = [];
      this.filtersLoaded[3] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (EMIEs)");
    });

    // Condomínios
    this.ElvCondominio.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((condominios) => {
      condominios.forEach(n => {
        n.name = n.nome;
      });
      this.condominios = condominios;
      this.filtersLoaded[4] = 1;
    }).catch((error) => {
      this.tipos = [];
      this.filtersLoaded[4] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Condomínios)");
    });
  };

  isDateValid = (date) => {
    return moment(date).isValid();
  };

  getElevadores = () => {
    this.tableLoading = true;
    let whereFields = this.setWhereFields(this.customFilters, {'ElvElevador.active' : 1});

    this.ElvElevador.table({
      params: {
        fields: [
          "ElvElevador.id as id",
          "ElvElevador.numeroCamarario as numeroCamarario",
          "ElvElevador.numeroSerie as numeroSerie",
          "ElvElevador.morada as morada",
          "ElvElevador.cp4 as cp4",
          "ElvElevador.cp3 as cp3",
          "ElvElevador.localidade as localidade",
          "ElvElevador.ultimaInspecao as ultimaInspecao",
          "ElvElevador.proximaInspecao as proximaInspecao",
          "ElvElevador.proximaInspecaoLimite as proximaInspecaoLimite",
          "ElvElevadorTipo.designacao as tipo",
          "ElvEquipamentoTipo.designacao as equipamento",
          "ElvCliente.nome as cliente",
          "ElvEmie.nome as emie",
          "ElvCondominio.nome as condominio"
        ],
        from: ["ElvElevador", "ElvElevadorTipo", "ElvEquipamentoTipo", "ElvCliente", "ElvEmie", "ElvCondominio"],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined, undefined],
        references: [undefined, "ElvElevador.tipoId", "ElvElevador.equipamentoId", "ElvElevador.clienteId", "ElvElevador.emieId", "ElvElevador.condominioId"],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      },
    })
      .$promise.then((res) => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if (this.start - 1 + items >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      this.elevadores = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((e) => {
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast(
        "Não foi possível carregar elevadores com os parâmetros fornecidos."
      );
    });
  };

  hasSelect = () => {
    return _.some(this.elevadores, (a) => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) {
      // Not all are selected, select all
      this.elevadores.forEach((a) => {
        a.selected = true;
      });
      this.nSelected = this.elevadores.filter((a) => a.selected).length;
    } else {
      // Remove all selections
      this.elevadores.forEach((a) => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    // this.everythingSelected = false;
  };

  // NOT USED IN THIS VIEW
  selectEverything = (type) => {
    //   if (!type) {
    //     this.elevadores.forEach((a) => {
    //       a.selected = false;
    //     });
    //     this.nSelected = 0;
    //     this.allSelected = false;
    //   }
    //   this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.elevadores, (r) => r.selected).length;
    this.allSelected = this.nSelected >= this.elevadores.length;
    // this.everythingSelected = false;
  };

  sort = (key) => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname) this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === "asc" ? "desc" : "asc";
    this.$state.go("app.elv.elevadores.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getElevadores();
  };

  item = (val) => {
    this.opt.items = val;
    this.$state.go("app.elv.elevadores.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getElevadores();
  };

  page = (sum) => {
    this.opt.page += sum;
    if (this.opt.page < 1) this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go("app.elv.elevadores.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getElevadores();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.tipos.length && !this.equipamentos.length && !this.clientes.length && !this.emies.length && !this.condominios.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach((f) => {
      if (f.id === "ElvElevador.tipoId")
        f.list = this.tipos;
      if (f.id === "ElvElevador.equipamentoId")
        f.list = this.equipamentos;
      if (f.id === "ElvElevador.clienteId")
        f.list = this.clientes;
      if (f.id === "ElvElevador.emieId")
        f.list = this.emies;
      if (f.id === "ElvElevador.condominioId")
        f.list = this.condominios;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === "ElvElevador.tipoId")
          f.column.selected.list = this.tipos;
        if (f.column.selected.id === "ElvElevador.equipamentoId")
          f.column.selected.list = this.equipamentos;
        if (f.column.selected.id === "ElvElevador.clienteId")
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === "ElvElevador.emieId")
          f.column.selected.list = this.emies;
        if (f.column.selected.id === "ElvElevador.condominioId")
          f.column.selected.list = this.condominios;      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === "s") i.value = {};
    else i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, (f) => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === "d") {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem("ElvElevadorFilter", JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go("app.elv.elevadores.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });

    this.getElevadores();
  };

  selectVisibleColumns = () => {
    let options = {
      size: "md",
      template: require("./columns.dialog.html"),
      controller: ["$dialog", "$scope", (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss("cancel");
        };
      },
      ],
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem("ElvElevadorDisplayColumns", JSON.stringify(this.displayColumns));
      }
    });
  };

  // Add new elevador
  addElevador = () => {
    // Load stuff on the fly
    let wait = this.UI.showWaiting();
    // Tipos
    this.ElvElevadorTipo.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((tipos) => {
      // Equipamentos
      this.ElvEquipamentoTipo.find({
        filter: {
          where: {
            active: 1
          }
        }
      }).$promise.then((equipamentos) => {
        // Clientes
        this.ElvCliente.find({
          filter: {
            where: {
              active: 1
            }
          }
        }).$promise.then((clientes) => {
          this.ElvEmie.find({
            filter: {
              where: {
                active: 1
              }
            }
          }).$promise.then((emies) => {
            // Condomínios
            this.ElvCondominio.find({
              filter: {
                where: {
                  active: 1
                }
              }
            }).$promise.then((condominios) => {
              wait.close();

              let options = {
                size: 'lg',
                template: require('./addElevador.dialog.html'),
                controller: ['$dialog', '$scope', '$http', ($dialog, $scope, $http) => {

                  $scope.elevadoresEquipamento = equipamentos;
                  $scope.elevadorEquipamento = {selected: null};

                  $scope.elevadoresTipo = tipos;
                  $scope.elevadorTipo = {selected: null};

                  $scope.elevadoresCliente = clientes;
                  $scope.elevadorCliente = {
                    selected: undefined,
                    infiniteScroll: {numToAdd: 20, currentItems: 20}
                  };

                  $scope.elevadoresEmie = emies;
                  $scope.elevadorEmie = {
                    selected: undefined,
                    infiniteScroll: {numToAdd: 20, currentItems: 20}
                  };

                  $scope.elevadoresCondominio = condominios;
                  $scope.elevadorCondominio = {
                    selected: undefined,
                    infiniteScroll: {numToAdd: 20, currentItems: 20}
                  };

                  $scope.o = {
                    id: 0,
                    numeroCamarario: "",
                    morada: "",
                    codigoPostal: 1,
                    observacoes: "",
                    active: true
                  };

                  $scope.error = {};

                  // Infinite Scroll magic
                  $scope.addMoreItems = (infiniteScroll) => {
                    infiniteScroll.currentItems += infiniteScroll.numToAdd;
                  };

                  $scope.hasErrors = () => {
                    return Object.keys($scope.error).some(e => $scope.error[e]);
                  };

                  $scope.checkNumeroCamarario = this.checkNumeroCamarario;

                  $scope.updatedClienteNumeroCamarario = () => {
                    if ($scope.elevadorCliente.selected && $scope.o.numeroCamarario && $scope.o.numeroCamarario.length > 0) {
                      $scope.checkNumeroCamarario($scope.o.numeroCamarario, $scope.elevadorCliente.selected.id).then(elevadores => {
                        $scope.error.connectionError = false;
                        $scope.error.exists = elevadores && elevadores.length > 0;
                      }).catch((error) => {
                        console.log(error);
                        $scope.error.exists = false;
                        $scope.error.connectionError = true;
                      });
                    } else {
                      delete $scope.error.exists;
                      delete $scope.error.connectionError;
                    }
                  };

                  $scope.getLocalidade = () => {
                    $scope.o.localidade = "";
                    let cp4 = parseInt($scope.o.cp4);
                    let cp3 = parseInt($scope.o.cp3);
                    if (!Number.isInteger(cp4)) {
                      $scope.o.cp3 = "";
                    } else if (cp4.toString().length !== 4) {
                      $scope.o.cp3 = "";
                    } else if (cp4.toString().length === 4) {
                      if (Number.isInteger(cp3)) {
                        if ($scope.o.cp3.toString().length === 3) {
                          $http.post('/api/public/getLocalidade', {
                            cp4: $scope.o.cp4,
                            cp3: $scope.o.cp3
                          }).then(response => {
                            $scope.o.localidade = response.data.result;
                          });
                        }
                      } else {
                        $http.post('/api/public/getLocalidade', {
                          cp4: $scope.o.cp4,
                          cp3: null
                        }).then(response => {
                          $scope.o.localidade = response.data.result;
                        });
                      }
                    }
                  };

                  // $scope.isOk = () => {
                  //   let o = $scope.o;
                  //   return o.numeroCamarario !== "" && o.cp3 !== "" && o.cp4 !== "" && o.localidade !== "";
                  // };

                  $scope.ok = () => {
                    $scope.o.equipamentoId = $scope.elevadorEquipamento.selected.id;
                    $scope.o.tipoId = $scope.elevadorTipo.selected ? $scope.elevadorTipo.selected.id : null;
                    $scope.o.emieId = $scope.elevadorEmie.selected.id;
                    $scope.o.condominioId = $scope.elevadorCondominio.selected ? $scope.elevadorCondominio.selected.id : null;
                    $scope.o.clienteId = $scope.elevadorCliente.selected.id;
                    $dialog.close($scope.o);
                  };
                  $scope.cancel = () => {
                    $dialog.dismiss('cancel');
                  }
                }],
              };
              let modal = this.UI.showDialog(options);
              modal.then(res => {

                if (res) {
                  let cp4 = parseInt(res.cp4);
                  let cp3 = parseInt(res.cp3);
                  if (Number.isNaN(cp4)) {
                    res.cp4 = null;
                    res.cp3 = null;
                  } else {
                    res.cp4 = cp4;
                  }
                  if (Number.isNaN(cp3)) {
                    res.cp3 = null;
                  } else {
                    res.cp3 = cp3;
                  }
                  this.ElvElevador.create(res).$promise.then((result) => {
                    result.codigoPostal = `${result.cp4}-${result.cp3}`;
                    this.ElvElevador.upsert(result).$promise.then(() => {
                      this.getElevadores();
                      this.UI.addToast('Elevador inserido com sucesso!');
                    }).catch((error) => {
                      console.log(error);
                      this.UI.addToast('Erro ao inserir elevador.')
                    });
                  }).catch((err) => {
                    console.log(err);
                    this.UI.addToast('Erro ao inserir Elevador.');
                  });
                }
              });

            }).catch((error) => {
              console.log(error);
              wait.close();
              this.UI.addToast("Erro de carregamento de dados (Condomínios). Verifique a ligação");
            });
          }).catch((error) => {
            console.log(error);
            wait.close();
            this.UI.addToast("Erro de carregamento de dados (EMIEs). Verifique a ligação");
          });
        }).catch((error) => {
          console.log(error);
          wait.close();
          this.UI.addToast("Erro de carregamento de dados (Clientes). Verifique a ligação");
        });
      }).catch((error) => {
        console.log(error);
        wait.close();
        this.UI.addToast("Erro de carregamento de dados (Equipamentos). Verifique a ligação");
      });
    }).catch((error) => {
      console.log(error);
      wait.close();
      this.UI.addToast("Erro de carregamento de dados (Tipos). Verifique a ligação");
    });
  };

  // Checks if numero camarario is already used for cliente
  checkNumeroCamarario = (numeroCamarario, clienteId) => {
    let defer = this.$q.defer();

    this.ElvElevador.find({
      filter: {
        fields: {id: true},
        where: {
          and: [{active: 1}, {numeroCamarario: {like: numeroCamarario, options: "i"}}, {clienteId: clienteId}]
        }
      }
    }).$promise.then((elevadores) => {
        defer.resolve(elevadores);
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };

  confirmRemoveElevador = (elevador) => {
    let title = "Eliminar Elevador";
    let warning = "Tem a certeza que pretende remover este elevador?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeElevador(elevador);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeElevador = (elevador) => {
    let wait = this.UI.showWaiting();
    this.ElevadorService.removeElevador(elevador).then(() => {
      wait.close();
      this.getElevadores();
    }).catch(error => {
      if (error) console.log(error);
      wait.close();
      this.getElevadores();
    });
  };
}

DirectoryElevadorController.$inject = ['$q', '$state', 'UIService', 'AuthenticationService', 'ElevadorService', 'ElvElevador', 'ElvElevadorTipo', 'ElvEquipamentoTipo', 'ElvCliente', 'ElvEmie', 'ElvCondominio'];
