export default class CrmClienteCreateController {
  constructor($rootScope, $q, $state, $scope, AuthenticationService, UIService, CrmLegacyService, CoreCliente, CoreContacto, CorePaises, Requisitante, ElvCliente, LmeCliente) {
    this.$q = $q;
    this.state = $state;
    this.$scope = $scope;
    this.UI = UIService;
    this.user = AuthenticationService.getUser();
    this.CrmLegacyService = CrmLegacyService;
    this.loaded = false;
    this.resultadosLoaded = true;

    this.CoreCliente = CoreCliente;
    this.CoreContacto = CoreContacto;
    this.CorePaises = CorePaises;
    this.Requisitante = Requisitante;
    this.ElvCliente = ElvCliente;
    this.LmeCliente = LmeCliente;

    this.resultadosPesquisa = [];

    this.simnao = [{ name: "Sim", value: 1 }, { name: "Não", value: 0 }];

    // this.entidadesProprietarias = [];
    // this.centrosDecisaoGlobal = []; // Used to contain everything
    // this.centrosDecisao = []; // Subset for EP
    // this.urgencias = [];
    // this.tiposDespesas = [];
    this.paises = [];

    this.emptyData = {
      form: 1,
      termoPesquisa: '',
      details: {},

      contactos: [],
      // fornecedores: [],
      auxPais: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } },
      // auxEntidadeProprietariaForm: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } },
      // auxEntidadeProprietaria: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } },
      // auxCentroDecisao: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } },
      // auxTipoDespesa: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } },
      // auxTipoDespesaForm: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } }
    };

    // Load data to fill ui-selects
    this.loadAuxData();

    $scope.$watch(() => {
      return this.data;
    }, (val) => {
      if (angular.isDefined(val)) {
        localStorage.setItem('CRMClienteNewForm', JSON.stringify(val));
      }
    }, true);
  }

  loadAuxData = () => {
    // this.PadCentroDecisao.find({ filter: { where: { active: 1 }, order: 'designacao ASC, entidadeProprietariaId ASC' } }).$promise.then((centrosDecisaoGlobal) => {
    //   this.centrosDecisaoGlobal = centrosDecisaoGlobal;
    //   this.PadUrgencia.find({}).$promise.then((urgencias) => {
    //     this.urgencias = urgencias;
    this.CorePaises.find({ filter: { where: { active: 1 }, order: 'id ASC' } }).$promise.then((paises) => {
      this.paises = paises;
      this.loadDataFromStorage();
      // We are going to assume cliente is Portugal, so automatically assign the variable to it if not previously set
      if (!this.data.auxPais.selected) {
        this.data.auxPais.selected = this.paises.find(x => x.name === "Portugal");
      }

      if (this.data.termoPesquisa && this.data.termoPesquisa.length > 0) { // If termoPesquisa is filled, search it
        this.searchClientes();
      }
      this.loaded = true;
    }).catch(err => {
      console.log(err);
      this.UI.addToast("Erro ao carregar Países. Verifique a sua ligação");
      this.state.go('app.pad.processo.list');
    });
    //   }).catch(err => {
    //     console.log(err);
    //     this.UI.addToast("Erro ao carregar tipos de urgências. Verifique a sua ligação");
    //     this.state.go('app.pad.processo.list');
    //   });
    // }).catch(err => {
    //   console.log(err);
    //   this.UI.addToast("Erro ao carregar centros de decisão. Verifique a sua ligação");
    //   this.state.go('app.pad.processo.list');
    // });
  };


  // Infinite Scroll magic
  addMoreItems = (infiniteScroll) => {
    infiniteScroll.currentItems += infiniteScroll.numToAdd;
  };

  loadDataFromStorage = () => {
    if (localStorage.getItem('CRMClienteNewForm')) {
      let storageData = JSON.parse(localStorage.getItem('CRMClienteNewForm'));
      // Only use what is required
      this.data = {};
      if (storageData.form) this.data.form = storageData.form; else this.data.form = angular.copy(this.emptyData.form);
      if (storageData.termoPesquisa) this.data.termoPesquisa = storageData.termoPesquisa; else this.data.termoPesquisa = angular.copy(this.emptyData.termoPesquisa);
      if (storageData.details) this.data.details = storageData.details; else this.data.details = angular.copy(this.emptyData.details);

      if (storageData.contactos) this.data.contactos = storageData.contactos; else this.data.contactos = angular.copy(this.emptyData.contactos);
      // if (storageData.fornecedores) this.data.fornecedores = storageData.fornecedores; else this.data.fornecedores = angular.copy(this.emptyData.fornecedores);

      // if (storageData.auxEntidadeProprietariaForm) this.data.auxEntidadeProprietariaForm = storageData.auxEntidadeProprietariaForm; else this.data.auxEntidadeProprietariaForm = angular.copy(this.emptyData.auxEntidadeProprietariaForm);
      // if (storageData.auxEntidadeProprietaria) this.data.auxEntidadeProprietaria = storageData.auxEntidadeProprietaria; else this.data.auxEntidadeProprietaria = angular.copy(this.emptyData.auxEntidadeProprietaria);
      // if (storageData.auxCentroDecisao) this.data.auxCentroDecisao = storageData.auxCentroDecisao; else this.data.auxCentroDecisao = angular.copy(this.emptyData.auxCentroDecisao);
      // if (storageData.auxUrgencia) this.data.auxUrgencia = storageData.auxUrgencia; else this.data.auxUrgencia = angular.copy(this.emptyData.auxUrgencia);
      // if (storageData.auxTipoDespesa) this.data.auxTipoDespesa = storageData.auxTipoDespesa; else this.data.auxTipoDespesa = angular.copy(this.emptyData.auxTipoDespesa);
      if (storageData.auxPais) this.data.auxPais = storageData.auxPais; else this.data.auxPais = angular.copy(this.emptyData.auxPais);

      // If EP was already filled in, we need to use the correct centrosDecisao list
      // if (this.data.auxEntidadeProprietaria.selected) {
      //   this.centrosDecisao = this.centrosDecisaoGlobal.filter(x => x.entidadeProprietariaId === this.data.auxEntidadeProprietaria.selected.id); // Select proper centros
      // }

    } else {
      this.data = angular.copy(this.emptyData);
    }
    this.state.transitionTo('app.crm.clientes.new.' + this.data.form);
  };

  setForm = num => {
    this.data.form = num;
    this.state.transitionTo('app.crm.clientes.new.' + this.data.form);
  };

  cleanForm = () => {
    // Clean filled data
    this.data = angular.copy(this.emptyData);
    this.state.transitionTo('app.crm.clientes.new.' + this.data.form);
  };

  // Create processo and initial state
  createCliente = (details) => {
    let defer = this.$q.defer();

    // Find if it already exists
    this.CoreCliente.find({
      filter: {
        where: {
          and: [
            {active: 1},
            {nif: details.nif}
          ]
        }
      }
    }).$promise.then((res) => {
      if (res.length > 0) {
        this.UI.addToast("Cliente já existente");
        defer.reject();
      } else {
        details.uuid = this.CrmLegacyService.generateUUID();
        console.log(details);
        this.CoreCliente.create(details).$promise.then((p) => {
          // this.PadProcessoEstado.create({
          //   id: 0,
          //   processoId: p.id,
          //   estadoId: 1,
          //   data: moment().format(),
          //   criadoPorId: this.user.id,
          //   observacoes: 'Criado'
          // }).$promise.then((estado) => {
          //   defer.resolve(p);
          // }).catch(error => {
          //   defer.reject(error);
          // });
          defer.resolve(p);
        }).catch((error) => {
          defer.reject(error);
        });
      }
    }).catch((error) => {
      console.log(error);
      defer.reject(error);
    });

    return defer.promise;
  };

  submit = () => {
    this.isValid().then(() => {
      let confirmationMessage = "Tem a certeza que pretende criar este cliente?\n";
      if (this.data.contactos && this.data.contactos.length > 0)
        confirmationMessage += (this.data.contactos.length === 1 ? 'Irá ser enviado 1 email para finalização de registo para o contacto selecionado.' : `Irão ser enviados emails para finalização de registo para os ${this.data.contactos.length} contactos selecionados.`);
      this.UI.showConfirm(confirmationMessage).then(() => {
        this.loaded = false;
        // Create CoreCliente and related data
        this.data.details.id = 0;
        this.data.details.paisId = this.data.auxPais.selected.id;
        // this.data.details.centroDecisaoId = this.data.auxCentroDecisao.selected.id;
        // this.data.details.tipoDespesaId = this.data.auxTipoDespesa.selected.id;
        // this.data.details.urgenciaId = this.data.auxUrgencia.selected.id;

        this.data.details.criadoPorId = this.user.id;
        this.data.details.dataCriacao = moment().format();
        this.data.details.refClientePrimavera = '';

        // Create Processo and initial state
        this.createCliente(this.data.details).then((cliente) => {

          // Create all Contactos
          let deferContactos = this.$q.defer();
          this.CrmLegacyService.createContactosCliente(this.data.contactos, cliente.id).then((x) => {
            deferContactos.resolve({ok: true});
          }).catch(err => {
            deferContactos.resolve({ok: false, output: 'Contactos'});
          });

          let promises = [deferContactos.promise];

          // After all multiple data information is created/settled
          this.$q.all(promises).then((res) => {
            this.UI.addToast("Cliente criado com sucesso");

            this.data = angular.copy(this.emptyData);
            localStorage.removeItem('CRMClienteNewForm');

            let error = this.createdMultipleDataOk(res);
            if (error) {
              console.log(error);
              let errorMessage = "Cliente criado, mas ocorreram erros a inserir os seguintes dados: " + error + ".";
              alert = this.UI.showAlert(errorMessage);
              alert.finally(() => {
                // Redirect to details view so it can be decided what to do
                this.state.go('app.crm.clientes.details', {id: cliente.id});
              });
            } else {
              // Everything was created ok, go to details

              this.state.go('app.crm.empresas.list');

            }
          }).catch(err => { // Should never happen, since resolving everything...
            console.log(err);
            this.data = angular.copy(this.emptyData);
            localStorage.removeItem('CRMClienteNewForm');
            alert = this.UI.showAlert("Cliente criado, mas ocorreram erros a inserir alguns dados. Por favor verifique.");
            alert.finally(() => {
              // Redirect to details view so it can be filled
              this.state.go('app.crm.clientes.details', {id: cliente.id});
            });
          });
        }).catch((error) => {
          console.log(error);
          this.UI.showAlert("Erro ao criar cliente. Verifique a ligação");
          this.loaded = true;
        });
      }).catch(() => {
        // Nothing to do.
      });
    }).catch(() => {
      // Nothing to do. Just fix values and resubmit...
    });
  };


  // Check if there was an error adding multiple data, and return a string with the bad inserted data
  createdMultipleDataOk = (res) => {
    let output = '';
    if (res && res.length > 0) {
      res.forEach(r => {
        if (!r.ok) output += r.output + ", ";
      });
      // If there's data, remove trailing ", "
      if (output.length > 0)
        return output.slice(0, -2);
    }
    return output.length === 0 ? null : output;
  };


  isValid = () => {
    let validDefer = this.$q.defer();
    let v = true;

    // Check if processo data is well defined
    // Nome
    if (v && (!this.data.details.nome || this.data.details.nome.length === 0)) {
      this.UI.addToast("Nome de Cliente não preenchido");
      v = false;
      validDefer.reject();
    }

    // NIF
    if (v && (!this.data.details.nif || !this.CrmLegacyService.validNIF(this.data.details.nif))) {
      this.UI.addToast("NIF inválido");
      v = false;
      validDefer.reject();
    }

    // Email
    if (v && (!this.data.details.email || !this.CrmLegacyService.validEmail(this.data.details.email))) {
      this.UI.addToast("Email inválido");
      v = false;
      validDefer.reject();
    }

    // País
    if (v && (!this.data.auxPais || !this.data.auxPais.selected)) {
      this.UI.addToast("País inválido");
      v = false;
      validDefer.reject();
    }


    // Mandatory multiple data
    if (v && (this.data.contactos.length === 0)) {
      this.UI.addToast("Cliente sem contactos definidos");
      v = false;
      validDefer.reject();
    }

    // Validate mandatory fields in dependent data
    if (v && !this.CrmLegacyService.validateMandatoryData(this.data.contactos, ['nome', 'nif', 'email'])) {
      this.UI.addToast("Pelo menos um dos contactos não tem dados preenchidos corretamente");
      v = false;
      validDefer.reject();
    }

    // Only validate multidata if all static data is valid, otherwise no point first
    if (v) {
      // VALIDATE ASYNC DATA
      let idData = [];

      // Add multi async data to verify
      // Verify Entidade Proprietaria, Centro de Decisão
      idData.push({
        field: 'id',
        value: this.data.auxPais.selected.id,
        model: this.CorePaises,
        resolveOnExist: true, // It's resolved if it finds one
        error: "País inexistente"
      });

      this.data.contactos.forEach(x => {
        idData.push({
          field: 'email',
          value: x.email,
          model: this.CoreContacto,
          resolveOnExist: false, // Resolves if it doesn't find one
        });
        if (x.ieClienteId) {
          idData.push({
            field: 'id',
            value: x.ieClienteId,
            model: this.Requisitante,
            resolveOnExist: true, // It's resolved if it finds one
          });
        }
        if (x.elvClienteId) {
          idData.push({
            field: 'id',
            value: x.elvClienteId,
            model: this.ElvCliente,
            resolveOnExist: true, // It's resolved if it finds one
          });
        }
        if (x.forClienteId) {
          idData.push({
            field: 'id',
            value: x.ieClienteId,
            model: this.Requisitante,
            resolveOnExist: true, // It's resolved if it finds one
          });
        }
        if (x.labClienteId) {
          idData.push({
            field: 'id',
            value: x.labClienteId,
            model: this.LmeCliente,
            resolveOnExist: true, // It's resolved if it finds one
          });
        }
      });


      let wait = this.UI.showWaiting();
      // Verify all the async data
      // this.PadService.validateActiveCurrentYearCentrosCusto(this.data.linhas).then(x => {
        this.CrmLegacyService.validateMultiData(idData).then(x => {
          wait.close();
          validDefer.resolve();
        }).catch(() => {
          wait.close();
          validDefer.reject();
        });
      // }).catch(() => {
      //   wait.close();
      //   validDefer.reject();
      // });
    }
    return validDefer.promise;
  };

  searchClientes = () => {
    if (this.data && this.data.termoPesquisa && this.data.termoPesquisa.length > 2) {
      this.resultadosLoaded = false;
      this.CrmLegacyService.searchClientes(this.data.termoPesquisa).then(resultadosPesquisa => {
        this.resultadosPesquisa = resultadosPesquisa;
        this.resultadosLoaded = true;
      }).catch(error => {
        console.log(error);
        this.UI.showAlert("Erro na consulta de Clientes. Verifique a ligação");
        this.resultadosPesquisa = [];
      });
    } else {
      this.resultadosPesquisa = [];
    }
  };


  // Executed when you select cliente to continue
  escolherCliente = (x) => {
    // Discard previous data (if exists) and select new one
    this.data.details = x;
    this.data.contactos = [];
    let wait = this.UI.showWaiting();
    this.CoreContacto.searchContactosForClient({
      params: {
        nif: x.nif
      }
    }).$promise.then((data) => {
      wait.close();
      this.data.contactos = data.results;

      // If pais wasn't selected for some reason, select Portugal by default
      if (!this.data.auxPais.selected) {
        this.data.auxPais.selected = this.paises.find(x => x.name === "Portugal");
      }

      this.setForm(2); // Change to second panel

    }).catch(error => {
      wait.close();
      console.log(error);
      this.UI.addToast("Erro na seleção de cliente. Verifique a ligação.");
    });
  };


  // MULTIPLE DATA CRUDs

  // Adicionar um novo contacto para cliente
  // addContacto = () => {
  //   if (!this.data.auxEntidadeProprietaria.selected || !this.data.auxEntidadeProprietaria.selected.id) {
  //     this.UI.addToast("Entidade Proprietária precisa de estar definida antes de adicionar linhas");
  //     return;
  //   }
  //   let wait = this.UI.showWaiting();
  //   // Get the right table to fetch artigos, planocontas from EP
  //   let PRIArtigo = this.PadService.getPrimaveraArtigo(this.data.auxEntidadeProprietaria.selected.id);
  //   let PRIPlanocontas = this.PadService.getPrimaveraProjeto(this.data.auxEntidadeProprietaria.selected.id);
  //   let currentYear = Number(moment().format('YYYY'));
  //
  //   this.PadCentroCusto.find({
  //     filter: {
  //       where: {
  //         and: [{ active: 1 }, { ano: currentYear }, { tipoConta: 'M' }, { entidadeProprietariaId: this.data.auxEntidadeProprietaria.selected.id }]
  //       },
  //       order: 'ano DESC, centro ASC'
  //     }
  //   }).$promise.then((centros) => {
  //     PRIArtigo.find({
  //       filter: {
  //         where: {
  //           artigo: { like: 'Z%' }
  //         },
  //         order: 'artigo ASC'
  //       }
  //     }).$promise.then((artigos) => {
  //       PRIPlanocontas.find({
  //         filter: {
  //           fields: { conta: true, ano: true, descricao: true },
  //           where: {
  //             conta: { like: '91%' }
  //           },
  //           order: 'descricao ASC, ano DESC'
  //         }
  //       }).$promise.then((projetos) => {
  //         wait.close();
  //         let options = {
  //           size: 'lg',
  //           template: require('./edit.linha.dialog.html'),
  //           controller: ['$dialog', '$scope', (dialog, scope) => {
  //             scope.label = "Adicionar Linha de Processo";
  //             scope.centros = centros;
  //             scope.artigos = artigos;
  //             scope.projetos = projetos;
  //
  //             scope.auxCentro = {
  //               selected: undefined,
  //               infiniteScroll: { numToAdd: 20, currentItems: 20 }
  //             };
  //
  //             scope.auxArtigo = {
  //               selected: undefined,
  //               infiniteScroll: { numToAdd: 20, currentItems: 20 }
  //             };
  //
  //             scope.auxProjeto = {
  //               selected: undefined,
  //               infiniteScroll: { numToAdd: 20, currentItems: 20 }
  //             };
  //
  //             scope.linha = {
  //               id: 0,
  //               quantidade: undefined,
  //               caracteristicas: undefined,
  //               precoUnitarioOrcamentado: undefined,
  //               precoTotalOrcamentado: undefined,
  //               observacoes: undefined
  //             };
  //
  //             scope.updateTotal = () => {
  //               if (scope.linha.precoUnitarioOrcamentado === undefined || scope.linha.quantidade === undefined) return;
  //               if (scope.linha.precoUnitarioOrcamentado >= 0 && scope.linha.quantidade >= 0)
  //                 scope.linha.precoTotalOrcamentado = Math.round(scope.linha.precoUnitarioOrcamentado * scope.linha.quantidade * 100) / 100;
  //               else
  //                 scope.linha.precoTotalOrcamentado = 0;
  //             };
  //
  //             //Infinite Scroll Magic
  //             scope.addMoreItems = (infiniteScroll) => {
  //               infiniteScroll.currentItems += infiniteScroll.numToAdd;
  //             };
  //
  //             scope.ok = () => {
  //               dialog.close(scope);
  //             };
  //
  //             scope.cancel = () => {
  //               dialog.dismiss('cancel');
  //             };
  //           }]
  //         };
  //
  //         let modal = this.UI.showDialog(options);
  //
  //         modal.then((ok) => {
  //           // Check if Cliente exists. If not, create it, if exists, update it.
  //           if (ok.auxCentro.selected && ok.auxArtigo.selected) {
  //             this.data.linhas.push({
  //               centroCustoId: ok.auxCentro.selected.id,
  //               centroCusto: ok.auxCentro.selected,
  //               artigo: ok.auxArtigo.selected,
  //               projeto: ok.auxProjeto.selected ? ok.auxProjeto.selected : null,
  //               quantidade: ok.linha.quantidade,
  //               precoUnitarioOrcamentado: ok.linha.precoUnitarioOrcamentado,
  //               precoTotalOrcamentado: ok.linha.precoTotalOrcamentado,
  //               caracteristicas: ok.linha.caracteristicas,
  //               observacoes: ok.linha.observacoes
  //             });
  //           }
  //         }).catch(() => {
  //         });
  //       }).catch(err => {
  //         console.log(err);
  //         this.UI.addToast("Erro ao carregar Projetos. Verifique a sua ligação");
  //       });
  //     }).catch((error) => {
  //       console.log(error);
  //       this.UI.addToast("Erro no carregamento de Artigos. Verifique a ligação");
  //     });
  //   }).catch((error) => {
  //     console.log(error);
  //     this.UI.addToast("Erro no carregamento de Centro de Custos. Verifique a ligação");
  //   });
  // };

  // Edita linha do processo
  // editLinha = (x) => {
  //   let wait = this.UI.showWaiting();
  //   if (!this.data.auxEntidadeProprietaria.selected || !this.data.auxEntidadeProprietaria.selected.id) {
  //     this.UI.addToast("Entidade Proprietária precisa de estar definida antes de editar linha");
  //     return;
  //   }
  //   // Get the right table to fetch artigos, planocontas from EP
  //   let PRIArtigo = this.PadService.getPrimaveraArtigo(this.data.auxEntidadeProprietaria.selected.id);
  //   let PRIPlanocontas = this.PadService.getPrimaveraProjeto(this.data.auxEntidadeProprietaria.selected.id);
  //   let currentYear = Number(moment().format('YYYY'));
  //
  //   this.PadCentroCusto.find({
  //     filter: {
  //       where: {
  //         and: [{ active: 1 }, { ano: currentYear }, { tipoConta: 'M' }, { entidadeProprietariaId: this.data.auxEntidadeProprietaria.selected.id }]
  //       },
  //       order: 'ano DESC, centro ASC'
  //     }
  //   }).$promise.then((centros) => {
  //     PRIArtigo.find({
  //       filter: {
  //         where: {
  //           artigo: { like: 'Z%' }
  //         },
  //         order: 'artigo ASC'
  //       }
  //     }).$promise.then((artigos) => {
  //       PRIPlanocontas.find({
  //         filter: {
  //           fields: { conta: true, ano: true, descricao: true },
  //           where: {
  //             conta: { like: '91%' }
  //           },
  //           order: 'descricao ASC, ano DESC'
  //         }
  //       }).$promise.then((projetos) => {
  //         wait.close();
  //         let options = {
  //           size: 'lg',
  //           template: require('./edit.linha.dialog.html'),
  //           controller: ['$dialog', '$scope', (dialog, scope) => {
  //             scope.label = "Editar Linha de Processo";
  //             scope.centros = centros;
  //             scope.artigos = artigos;
  //             scope.projetos = projetos;
  //
  //             scope.auxCentro = {
  //               selected: scope.centros.find(c => c.id === x.centroCustoId),
  //               infiniteScroll: { numToAdd: 20, currentItems: 20 }
  //             };
  //
  //             scope.auxArtigo = {
  //               selected: scope.artigos.find(a => a.artigo === x.artigo.artigo),
  //               infiniteScroll: { numToAdd: 20, currentItems: 20 }
  //             };
  //
  //             scope.auxProjeto = {
  //               selected: x.projeto ? (scope.projetos.find(a => (a.conta === x.projeto.conta) && (a.ano === x.projeto.ano))) : undefined,
  //               infiniteScroll: { numToAdd: 20, currentItems: 20 }
  //             };
  //
  //             scope.linha = angular.copy(x);
  //
  //             scope.updateTotal = () => {
  //               if (scope.linha.precoUnitarioOrcamentado === undefined || scope.linha.quantidade === undefined) return;
  //               if (scope.linha.precoUnitarioOrcamentado >= 0 && scope.linha.quantidade >= 0)
  //                 scope.linha.precoTotalOrcamentado = Math.round(scope.linha.precoUnitarioOrcamentado * scope.linha.quantidade * 100) / 100;
  //               else
  //                 scope.linha.precoTotalOrcamentado = 0;
  //             };
  //
  //             //Infinite Scroll Magic
  //             scope.addMoreItems = (infiniteScroll) => {
  //               infiniteScroll.currentItems += infiniteScroll.numToAdd;
  //             };
  //
  //             scope.ok = () => {
  //               dialog.close(scope);
  //             };
  //
  //             scope.cancel = () => {
  //               dialog.dismiss('cancel');
  //             };
  //           }]
  //         };
  //
  //         let modal = this.UI.showDialog(options);
  //
  //         modal.then((ok) => {
  //           // Check if Cliente exists. If not, create it, if exists, update it.
  //           if (ok.auxCentro.selected && ok.auxArtigo.selected) {
  //             x.centroCustoId = ok.auxCentro.selected.id;
  //             x.centroCusto = ok.auxCentro.selected;
  //             x.artigo = ok.auxArtigo.selected;
  //             x.projeto = ok.auxProjeto.selected ? ok.auxProjeto.selected : null,
  //               x.quantidade = ok.linha.quantidade;
  //             x.precoUnitarioOrcamentado = ok.linha.precoUnitarioOrcamentado;
  //             x.precoTotalOrcamentado = ok.linha.precoTotalOrcamentado;
  //             x.caracteristicas = ok.linha.caracteristicas;
  //             x.observacoes = ok.linha.observacoes;
  //           }
  //         }).catch(() => {
  //         });
  //       }).catch(err => {
  //         console.log(err);
  //         this.UI.addToast("Erro ao carregar Projetos. Verifique a sua ligação");
  //       });
  //     }).catch((error) => {
  //       console.log(error);
  //       this.UI.addToast("Erro no carregamento de Artigos. Verifique a ligação");
  //     });
  //   }).catch((error) => {
  //     console.log(error);
  //     this.UI.addToast("Erro no carregamento de Centro de Custos. Verifique a ligação");
  //   });
  // };

  // Remove Contacto com índice index
  removerContacto = (index) => {
    if (this.data.contactos.length === 1) {
      this.UI.showAlert("Tem que ser criado pelo menos um contacto para o cliente");
      return;
    }
    if (index < this.data.contactos.length) {
      let confirm = this.UI.showConfirm("Tem a certeza que pretende remover a criação deste contacto?");
      confirm.then(() => {
        this.data.contactos.splice(index, 1);
      }).catch(() => { });
    }
  };


}

CrmClienteCreateController.$inject = ['$rootScope', '$q', '$state', '$scope', 'AuthenticationService', 'UIService', 'CrmLegacyService', 'CoreCliente', 'CoreContacto', 'CorePaises', 'Requisitante', 'ElvCliente', 'LmeCliente'];
