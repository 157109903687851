export function routes($stateProvider) {
  $stateProvider
    .state('app.lab', {
      url: '/laboratorio',
      abstract: true,
      template: '<div ui-view></div>'
    });
}

routes.$inject = ['$stateProvider'];
