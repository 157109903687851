import React from 'react';
import { MdiIcon } from '@components';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT } from 'material-react-table/locales/pt';
import { mdiFolderNetwork, mdiMagnify, mdiPlus } from '@mdi/js';
import { useModal } from 'mui-modal-provider';
const TableData = ({ title, columns, data, addButton, folder }) => {
    const { showModal } = useModal();
    const onDialogFormClose = () => {
        console.log('ASDASDSA');
    };
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = 'Não há registos a serem exibidos';
    return (<Box>
      <Stack direction='row' spacing={2} justifyContent='flex-start' alignItems='center'>
        <Typography variant='h6' sx={{ color: 'black', mb: 1 }}>
          {title}
        </Typography>
        {addButton ? (<IconButton color='primary' onClick={() => {
                /* showModal(DialogForm, {
                  title: 'Teste',
                  onClose: onDialogFormClose
                });
  
                console.log(data); */
            }}>
            <MdiIcon path={mdiPlus} sx={{ color: '#1976D2' }}></MdiIcon>
          </IconButton>) : (<Box></Box>)}
      </Stack>
      <Box sx={{
            borderRadius: '15px',
            border: '1px solid #e0e0e0',
            overflow: 'hidden',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' // Adiciona uma leve sombra
        }}>
        <MaterialReactTable columns={columns} data={data} 
    //enableTopToolbar={false} // Removes the tollbar from the table
    enableFullScreenToggle={false} enableDensityToggle={false} initialState={{
            showGlobalFilter: true,
            density: 'compact',
            pagination: {
                pageIndex: 0,
                pageSize: 3
            }
        }} localization={localization} muiPaginationProps={{
            rowsPerPageOptions: [3, 5, 10, 20, 50, 100]
        }} 
    // Ações da tabela
    enableRowActions={true} positionActionsColumn={'last'} renderRowActions={({ row }) => (<Stack direction='row'>
              {folder ? (<Box>
                  <Tooltip title='Abrir pasta local'>
                    <IconButton color='primary' onClick={() => alert(`Abrir pasta`)}>
                      <MdiIcon path={mdiFolderNetwork} sx={{ color: '#1976D2' }}></MdiIcon>
                    </IconButton>
                  </Tooltip>
                  <IconButton color='primary' onClick={() => alert(`Visualizar detalhes`)}>
                    <MdiIcon path={mdiMagnify} sx={{ color: '#1976D2' }}></MdiIcon>
                  </IconButton>
                </Box>) : (<IconButton color='primary' onClick={() => alert(`Visualizar detalhes`)}>
                  <MdiIcon path={mdiMagnify} sx={{ color: '#1976D2' }}></MdiIcon>
                </IconButton>)}
            </Stack>)}/>
      </Box>
    </Box>);
};
export default TableData;
