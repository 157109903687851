import { defer } from "lodash";

export default class AuthenticationService {
  constructor($rootScope, Users, LoopBackAuth, $q, $state) {
    this.$rootScope = $rootScope;
    this.Users = Users;
    this.LoopBackAuth = LoopBackAuth;
    this.$q = $q;
    this.user = {};
    this.$state = $state;

    if (localStorage.getItem('user') && this.isAuthenticated()) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.getUserInfo();
    }
  }

  login = (email, password) => {
    let deferred = this.$q.defer();
    this.Users.login({ rememberMe: true }, { email, password }).$promise.then((res) => {
      this.Users.findById({
        id: res.user.id, filter: {
          include: ['ausencias', {
            relation: 'groups',
            scope: {
              include: {
                relation: 'rolegroup',
                scope: {
                  where: {
                    active: true
                  },
                  include: {
                    relation: 'role'
                  }
                }
              }
            }
          }]
        }
      }).$promise.then(user => {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
        deferred.resolve();
      });
    }).catch((err) => {
      deferred.reject(err);
    });
    return deferred.promise;
  };

  logout = () => {
    localStorage.removeItem('user');
    this.Users.logout().$promise.then((res) => {
      this.clearUser();
      // Fix to F5 bug, taken from Sl-Template
      location.reload(true);
    }).catch((err) => {
      // In case of error ignore, since it will be catched by the Authentication Handler
    });
  };

  clearUser = () => {
    this.LoopBackAuth.clearUser();
    this.LoopBackAuth.clearStorage();
  };

  getToken = () => {
    return this.LoopBackAuth.accessTokenId;
  };

  getId = () => {
    return this.user.id;
  };

  getUser = () => {
    return this.user;
  };

  getUserInfo = () => {
    this.Users.findById({
      id: this.user.id, filter: {
        include: ['ausencias', {
          relation: 'groups',
          scope: {
            include: {
              relation: 'rolegroup',
              scope: {
                where: {
                  active: true
                },
                include: {
                  relation: 'role'
                }
              }
            }
          }
        }]
      }
    }).$promise.then(user => {
      this.user = user;
      localStorage.setItem('user', JSON.stringify(this.user));
    }).catch(err => {
      this.logout();
    });
  };

  update = (key, value) => {
    let defer = this.$q.defer();
    let obj = {};
    obj[key] = value;
    this.Users.prototype$updateAttributes(
      { id: this.getId() },
      obj
    ).$promise.then(r => {
      this.getUserPromise().then(r => { defer.resolve(r) }).catch(e => defer.reject(e));
    }).catch(e => defer.reject(e));
    return defer.promise;
  }

  getUserPromise = () => {
    let defer = this.$q.defer();
    this.Users.findById({
      id: this.user.id, filter: {
        include: ['ausencias', {
          relation: 'groups',
          scope: {
            include: {
              relation: 'rolegroup',
              scope: {
                where: {
                  active: true
                },
                include: {
                  relation: 'role'
                }
              }
            }
          }
        }]
      }
    }).$promise.then(user => {
      this.user = user;
      localStorage.setItem('user', JSON.stringify(this.user));
      defer.resolve(user);
    }).catch(err => {
      this.logout();
      defer.reject();
    });
    return defer.promise;
  };

  isAuthenticated = () => {
    return this.Users.isAuthenticated();
  };

  changePassword = (oldPassword, newPassword) => {
    let defer = this.$q.defer();

    this.Users.changePassword({
      id: this.getId(),
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
      .$promise.then((res) => {
        defer.resolve(res);
      })
      .catch((err) => {
        defer.reject(err);
      });

    return defer.promise;
  };

}

AuthenticationService.$inject = ['$rootScope', 'Funcionario', 'LoopBackAuth', '$q', '$state'];
