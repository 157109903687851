export default class SystemController {
  constructor($rootScope, $http, $interval, $timeout) {
    this.date = moment.utc();
    this.loaded = false;

    this.ui = {
      base: $rootScope.uiBase,
      modules: _.sortBy($rootScope.uiModules, 'name')
    };

    $http.get('/api/status').then(res => {
      this.serverInfo = res.data;
      this.loaded = true;
      $interval(() => {
        this.serverInfo.uptime++;
      }, 1000);
    });
  }
}

SystemController.$inject = ['$rootScope', '$http', '$interval', '$timeout'];
