export default class CreateIntervencaoController {
  constructor($scope, $state, UIService, AuthenticationService, OIService, ElvCliente, $http, PRICabecdoc, ElvOrdemintervencao, ElvProcessoArtigo) {
    this.state = $state;
    this.UI = UIService;
    // State control
    this.$http = $http;
    this.AuthenticationService = AuthenticationService;
    this.submitting = false;
    this.loaded = false;
    this.PRICabecdoc = PRICabecdoc;
    this.Service = OIService;
    this.ElvCliente = ElvCliente;
    this.ElvOrdemintervencao = ElvOrdemintervencao;
    this.ElvProcessoArtigo = ElvProcessoArtigo;
    this.form = {
      details: {
        id: 0,
        ano: moment().format('YYYY')
      },
      client: {},
      processes: []
    };
    this.askForOI();
  }

  askForOI = () => {
    let dialog = this.UI.showDialog({
      template: require('./primavera.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.detail = {};

        $scope.data = {
          ano: moment(),
        };

        $scope.ok = () => {
          $scope.$close($scope.data);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
          this.state.go('app.elv.oi.list');
        }
      }]
    });
    dialog.then(res => {
      if (res) {
        this.getPrimaveraData(res);
      }
    })
  };

  // Returns true if there's at least one linha that has a valid Artigo
  hasValidCodigosArtigo = (codigos, linhas) => {
    for (let i = 0; i < linhas.length; i++) {
      if (codigos.find(x => x.codigo === linhas[i].artigo)) {
        return true;
      }
    }
    return false;
  };

  getPrimaveraData = (data) => {
    this.ElvProcessoArtigo.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((codigos) => {
      let wait = this.UI.showWaiting();
      this.PRICabecdoc.find({
        filter: {
          where: {
            tipodoc: 'OI',
            serie: moment(data.ano).format('YY'),
            numdoc: data.no
          },
          include: [{
            relation: 'linhas',
            scope: {
              order: 'numlinha ASC'
            }
          }, 'cliente']
        }
      }).$promise.then(res => {
        if (res.length === 0) {
          wait.close();
          this.state.go('app.elv.oi.list');
          this.UI.addToast('Nenhuma OI registada no Primavera com os dados fornecidos!');
        } else {
          if (res[0].linhas.length === 0 || !this.hasValidCodigosArtigo(codigos, res[0].linhas)) {
            wait.close();
            this.UI.showAlert('A Referência da OI introduzida não tem pelo menos um Código de Artigo válido pertencente ao módulo de Inspeção de Elevadores. Verifique a referência.');
            this.state.go('app.elv.oi.list');
          } else {
            this.ElvOrdemintervencao.find({
              filter: {
                fields: {primaveraId: true},
                where: {
                  primaveraId: res[0].id
                }
              }
            }).$promise.then(exists => {
              if (!exists.length) {
                this.form.tipo = null;
                this.form.details = {
                  ano: moment(data.ano).format('YYYY'),
                  num: data.no,
                  primaveraId: res[0].id,
                  dataRececao: moment(res[0].data).format('YYYY-MM-DD')
                };
                this.form.primavera = res[0];
                this.form.client = {
                  nif: res[0].cliente.numcontrib,
                  nome: res[0].cliente.nome,
                  morada: res[0].cliente.facMor,
                  cp4: res[0].cliente.facCp ? res[0].cliente.facCp.split("-")[0] : null,
                  cp3: res[0].cliente.facCp ? res[0].cliente.facCp.split("-")[1] : null,
                  localidade: res[0].cliente.facCploc,
                  contactoTelefonico: res[0].cliente.facTel,
                  observacoes: res[0].cliente.notas,
                  atualizadoa: res[0].cliente.dataultimaactualizacao,
                  refClientePrimavera: res[0].cliente.cliente,
                  email: res[0].cduEmail
                };
                res[0].linhas.forEach(linha => {
                  if (linha.artigo !== null) {
                    let codigoFound = codigos.find(c => c.codigo === linha.artigo);
                    if (codigoFound) { // If we find a match for codigo, use it
                      this.form.processes.push({
                        artigoId: codigoFound.id,
                        codArtigoDisplay: codigoFound.codigo, // For display only
                        descricaoDisplay: codigoFound.descricao, // For display only
                        quantidade: linha.quantidade,
                        maxQuantidade: linha.quantidade, // For the form input max
                        valor: linha.quantidade ? linha.precoliquido / linha.quantidade : linha.precoliquido,
                        criadoPorId: this.AuthenticationService.getId()
                      });
                    }
                  }
                });
                this.UI.addToast('OI carregada com sucesso!');
              } else {
                this.state.go('app.elv.oi.list');
                this.UI.addToast('A OI introduzida já existe no sistema.');
              }
              wait.close();
              this.loaded = true;
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Não foi possível verificar existência de OI. Por favor tente mais tarde.");
            });
          }
        }
      }).catch(() => {
        wait.close();
        this.UI.addToast('Erro ao carregar dados do Primavera!');
      })
    }).catch((error) => {
      console.log(error);
      this.UI.addToast('Erro ao carregar dados de Códigos de Artigo');
    });
  };

  registerOI = clienteId => {
    // Regista dados da OI
    let oiData = {
      id: 0,
      clienteId: clienteId,
      ano: this.form.details.ano,
      no: this.form.details.num,
      primaveraId: this.form.details.primaveraId,
      observacoes: this.form.details.observacoes,
      dataRececao: this.form.details.dataRececao,
      active: true,
      funcionarioId: this.AuthenticationService.getId()
    };
    this.Service.register(oiData, this.form.processes).then(res => {
      this.isWaiting.close();
      this.state.go('app.elv.oi.list');
      this.UI.addToast('Ordem de intervenção adicionada');
    }).catch((error) => {
      console.log(error);
      this.UI.addToast('Erro na importação da OI. Falha na criação de intervencoes');
    });
  };

  register = () => {
    let confirm = this.UI.showConfirm('Concluir criação de Ordem de Intervenção?');
    confirm.then(res => {
      if (res) {
        if (this.form.processes.length === 0) {
          this.UI.addToast('A OI não tem processos associados!');
        } else {
          this.isWaiting = this.UI.showWaiting();
          this.ElvCliente.find({
            filter: {
              where: {
                or: [{nif: this.form.client.nif}, {refClientePrimavera: this.form.client.refClientePrimavera}]
              }
            }
          }).$promise.then(client => {
            if (client.length === 0) {
              this.form.client.id = 0;
              this.form.client.active = true;
              this.ElvCliente.create(this.form.client).$promise.then(c => {
                this.UI.addToast('Novo cliente registado!');
                this.registerOI(c.id);
              }).catch(error => {
                console.log(error);
                this.UI.addToast("Não foi possível criar novo cliente. Verifique ligação");
              });
            } else {
              this.registerOI(client[0].id);
            }
          });
        }
      }
    });
  };
}

CreateIntervencaoController.$inject = ['$scope', '$state', 'UIService', 'AuthenticationService', 'OIService', 'ElvCliente', '$http', 'PRICabecdoc', 'ElvOrdemintervencao', 'ElvProcessoArtigo'];
