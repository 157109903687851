export default class SusEneDetailsClientsController {
  constructor(SusEneService, SusEneCliente, AuthenticationService, $state, UIService, Table) {
    this.SusEneService = SusEneService;
    this.UI = UIService;
    this.$state = $state;
    this.id = $state.params.id;
    this.user = AuthenticationService.getUser();
    this.SusEneCliente = SusEneCliente;
    this.Table = Table;
    this.loaded = false;
    if (angular.isUndefined($state.params.id) || isNaN($state.params.id) || $state.params.id.toString().length === 0) {
      $state.go('app.susene.clients.list');
    } else {
      this.loadData();
    }
  }

  loadData = () => {
    this.loaded = false;
    this.SusEneCliente.findOne({
      filter: {
        where: {
          active: true,
          id: this.id,
        },
        include: ['ois', 'bloqueadoPor', {
          relation: 'amostras',
          scope: {
            include: [{
              relation: 'estado',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'marca',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'local',
              scope: {
                where: {
                  active: 1
                }
              }
            }
            ]
          }
        }]
      }
    }).$promise.then(res => {
      this.data = res;
      this.oiTable = new this.Table({
        sorting: {
          ano: 'desc',
          no: 'desc'
        }
      }, {
        dataset: res.ois,
      });
      this.sampleTable = new this.Table({
        sorting: {
          ano: 'desc',
          no: 'desc'
        }
      }, {
        dataset: res.amostras,
      });
      this.loaded = true;
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Erro na obtenção de dados de cliente. Verifique a ligação");
      this.$state.go('app.susene.clients.list');
    });
  };

  validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  resyncClient = (id) => {
    this.SusEneCliente.findOne({
      filter: {
        where: {
          id: id
        }
      }
    }).$promise.then((c) => {
      if (!c.refClientePrimavera) {
        this.UI.addToast("Não foi possível atualizar cliente.");
      } else {
        let PRIClientes = this.SusEneService.getPRIClientes(c);
        PRIClientes.find({
          filter: {
            where: {
              cliente: c.refClientePrimavera
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            c.nome = res[0].nome;
            c.nif = res[0].numcontrib;
            c.cp4 = res[0].facCp.split("-")[0];
            c.cp3 = res[0].facCp.split("-")[1];
            c.localidade = res[0].facCploc;
            c.contactoTelefonico = res[0].facTel;
            c.morada = res[0].facMor;
            c.observacoes = res[0].notas;
            c.atualizadoa = res[0].dataultimaactualizacao;
            c.refClientePrimavera = res[0].cliente;
            c.email = this.validEmail(res[0].cduEmail) ? res[0].cduEmail : null;
            c.$save().then(() => {
                this.loadData();
                this.UI.addToast('Sincronização realizada com sucesso.');
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Falha na gravação do fabricante. Por favor tente mais tarde.");
            });
          } else {
            this.UI.addToast("Não foi possível atualizar cliente.");
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar cliente.");
        });
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar cliente.");
    });
  };

  toggleClientLock = () => {
    // If it's locked, unlock it
    if (this.data.bloqueado) {
      let confirm = this.UI.showConfirm("Cliente está bloqueado. Tem a certeza que pretende desbloquear?");
      confirm.then(() => {
        this.SusEneCliente.prototype$updateAttributes(
          {id: this.data.id},
          {
            bloqueado: false,
            bloqueadoa: null,
            bloqueadoPorId: null,
            bloqueadoMotivo: null,
            desbloqueadoa: moment.utc(),
            desbloqueadoPorId: this.user.id
          }
        ).$promise.then(() =>{
          this.UI.addToast("Cliente desbloqueado com sucesso");
          this.loadData();
        }).catch(e => {
          let alert = this.UI.showAlert("Ocorreu um erro ao desbloquear o cliente. Verifique a ligação");
          alert.finally(() => {
            this.loadData();
          });
        });
      }).catch(() => {}); // Nothing happens here
    } else { // Lock it
      let confirm = this.UI.showConfirm("Cliente está desbloqueado. Tem a certeza que o pretende bloquear?");
      confirm.then(() => {
        this.lockCliente(); // Open modal and deal from there
      }).catch(() => {}); // Nothing happens here
    }
  };


  // Lock and ask for observações
  lockCliente = () => {
    this.UI.showDialog({
      template: require('./lock.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.data = null;

        $scope.ok = () => {
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(r => {
      this.SusEneCliente.prototype$updateAttributes(
        {id: this.data.id},
        {
          bloqueado: true,
          bloqueadoa: moment.utc(),
          bloqueadoPorId: this.user.id,
          bloqueadoMotivo: r || null,
          desbloqueadoa: null,
          desbloqueadoPorId: null
        }
      ).$promise.then((_) => {
        this.UI.addToast("Cliente bloqueado com sucesso");
        this.loadData();
      }).catch(error => {
        console.log(error);
        let alert = this.UI.showAlert("Ocorreu um erro ao bloquear o cliente. Verifique a ligação");
        alert.finally(() => {
          this.loadData();
        });
      });
    }).catch(() => {});
  };
}

SusEneDetailsClientsController.$inject = ['SusEneService', 'SusEneCliente', 'AuthenticationService', '$state', 'UIService', 'NgTableParams'];
