export default class CrmClientesDetailsController {
  constructor($state, UIService, AuthorizationService, CrmLegacyService, CoreCliente, CoreContacto) {
    this.state = $state;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.CrmLegacyService = CrmLegacyService;
    this.CoreCliente = CoreCliente;
    this.CoreContacto = CoreContacto;

    this.loaded = false;

    // Default opt state - populate later when we have all states
    this.optContactos = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "id",
      sort: "desc",
      filter: {}
    };
    this.contactos = null;
    this.contactosLoading = true;

    this.id = $state.params.id;
    if (angular.isUndefined($state.params.id)) {
      $state.go('app.crm.clientes.list');
      UIService.addToast('Não foi possível carregar cliente');
    }
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.CoreCliente.findOne({
      filter: {
        where: {
          and: [{active: true}, {id: this.id}]
        },
        //include: ['pais']
      }
    }).$promise.then(res => {
      if (res) {
        res.cPostal = res.cp4;
        if (res.cp3) res.cPostal += "-" + ("000" + res.cp3).slice(-3);
        if (res.localidade) res.cPostal += " " + res.localidade;
      }

      this.data = res;
      this.loaded = true;
    });
    this.getContactosForCliente();
  };

  editObservations = () => {
    let obs = angular.copy(this.data.observacoes);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = obs;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.loaded = false;
      this.data.observacoes = res;
      this.CoreCliente.prototype$updateAttributes({id: this.data.id}, {observacoes: res}).$promise.then(() => {
        this.UI.addToast('Observações guardadas com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro a guardar as observações. Por favor tente mais tarde.');
        this.loadData();
      })
    })
  };

  getContactosForCliente = () => {
    // If no permissions, return
    // if (!this.AuthorizationService.canPerform('crmVerClientes')) {
    //  return;
    // }
    this.contactosLoading = true;
    let whereLiteral = {
      'CoreContacto.clienteId': this.id,
      'CoreContacto.active': 1,
    };
    let where = {};
    Object.keys(this.optContactos.filter).forEach(f => {
      if (this.optContactos.filter[f])
        where[f] = this.optContactos.filter[f];
    });

    this.CoreContacto.tableLegacy({
      params: {
        fields: [
          'CoreContacto.id as id',
          'CoreContacto.nome as nome',
          'CoreContacto.email as email',
          'CoreContacto.contactoTelefonico as contactoTelefonico',
          'CoreContacto.blocked as blocked',
          'CoreContacto.observacoes as observacoes',
          'CoreContacto.elvClienteId as elvClienteId',
          'CoreContacto.blockedElv as blockedElv',
          'CoreContacto.ieClienteId as ieClienteId',
          'CoreContacto.blockedIe as blockedIe',
          'CoreContacto.forClienteId as forClienteId',
          'CoreContacto.blockedFor as blockedFor',
          'CoreContacto.forClienteId as labClienteId',
          'CoreContacto.blockedFor as blockedLab'
        ],
        from: ['CoreContacto'],
        referencesOrigin: [undefined],
        references: [undefined],
        aliases: [undefined],
        where: where,
        whereLiteral: whereLiteral,
        order: this.optContactos.order,
        sort: this.optContactos.sort,
        limit: this.optContactos.items,
        skip: (this.optContactos.page - 1) * this.optContactos.items,
      }
    }).$promise.then(res => {
      let page = this.optContactos.page;
      let items = this.optContactos.items;

      let total = res.count;

      this.optContactos.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.optContactos.start - 1 + items) >= total) {
        this.optContactos.end = total;
      } else {
        this.optContactos.end = Number.parseInt(this.optContactos.start - 1 + items);
      }

      this.contactos = res.data;
      this.optContactos.total = total;
      this.contactosLoading = false;
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler Contactos. Por favor tente mais tarde");
    });
  };

  applyFilterContactos = () => {
    this.optContactos.page = 1;
    this.getContactosForCliente();
  };

  contactosSort = keyname => {
    if (this.optContactos.order === keyname)
      this.optContactos.page = 1;
    this.optContactos.order = keyname;
    this.optContactos.sort = this.optContactos.sort === 'asc' ? 'desc' : 'asc';
    this.getContactosForCliente();
  };

  contactosItem = val => {
    this.optContactos.items = val;
    this.optContactos.page = 1;
    this.getContactosForCliente();
  };

  contactosPage = sum => {
    this.optContactos.page += sum;
    if (this.optContactos.page < 1)
      this.optContactos.page = 1;
    if (this.optContactos.page > Math.ceil(this.optContactos.total / this.optContactos.items))
      this.optContactos.page = Math.ceil(this.optContactos.total / this.optContactos.items);
    this.getContactosForCliente();
  };

  viewContacto = r => {
    this.state.go('app.crm.contactos.details', {id: r.id});
  };

  addContacto = () => {
    let wait = this.UI.showWaiting();
    this.CoreContacto.searchContactosForClient({
      params: {
        nif: this.data.nif
      }
    }).$promise.then((data) => {
      wait.close();
      let options = {
        template: require('./add.contacto.dialog.html'),
        controller: ['$scope', $scope => {
          $scope.label = `Novo Contacto de ${this.data.nome} (NIF: ${this.data.nif})`;
          // Data to change on the modal
          $scope.checkNoModules = this.CrmLegacyService.checkNoModules;
          $scope.contactos = data.results;
          $scope.allSelected = false;

          $scope.selectAll = () => {
            $scope.allSelected = !$scope.allSelected;
            if ($scope.allSelected) {
              for (let i = 0; i < $scope.contactos.length; i++) {
                $scope.contactos[i].importing = true;
              }
            } else {
              for (let i = 0; i < $scope.contactos.length; i++) {
                $scope.contactos[i].importing = false;
              }
            }
          };

          $scope.ok = function () {
            // Send contactos to import for processing
            $scope.$close($scope.contactos.filter(x => x.importing));
          };

          $scope.cancel = function () {
            $scope.$dismiss('cancel');
          };
        }]
      };

      let modal = this.UI.showDialog(options);

      modal.then((contactos) => {
        wait = this.UI.showWaiting();
        if (contactos && contactos.length > 0) {
          let s = (contactos.length === 1 ? '' : 's');
          this.CrmLegacyService.createContactosCliente(contactos, this.data.id).then((x) => {
            wait.close();
            this.UI.addToast(`Contacto${s} criado${s} com sucesso`);
            this.loadData();
          }).catch(err => {
            wait.close();
            console.log(err);
            this.UI.showAlert(`Ocorreu um erro ao criar contacto${s}. Verifique a ligação e tente novamente`);
          });
        }
      }).catch(() => {});
    }).catch(error => {
      wait.close();
      console.log(error);
      let alert = this.UI.showAlert("Ocorreu um erro ao adicionar contacto. Verifique a ligação");
      alert.finally(() => {
        this.loadData();
      });
    });
  };

  editContacto = (contacto) => {
    let options = {
      template: require('./edit.contacto.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.label = `Editar Permissões de Contacto - ${contacto.nome}`;
        // Data to change on the modal
        $scope.checkNoModules = this.CrmLegacyService.checkNoModules;
        $scope.iePermission = !contacto.blockedIe;
        $scope.elvPermission = !contacto.blockedElv;
        $scope.forPermission = !contacto.blockedFor;
        $scope.labPermission = !contacto.blockedLab;

        $scope.ok = function () {
          // Send data for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((ok) => {
      let wait = this.UI.showWaiting();
      this.CoreContacto.prototype$updateAttributes(
        {id: contacto.id},
        {
          blockedIe:!ok.iePermission,
          blockedElv:!ok.elvPermission,
          blockedFor:!ok.forPermission,
          blockedLab:!ok.labPermission,
        }
      ).$promise.then((c) => {
        wait.close();
        this.UI.addToast("Permissões atualizadas com sucesso");
        this.loadData();
      }).catch(error => {
        wait.close();
        console.log(error);
        let alert = this.UI.showAlert("Ocorreu um erro ao alterar permissões de contacto. Verifique a ligação");
        alert.finally(() => {
          this.loadData();
        });
      });
    }).catch(() => {});
  };

}
CrmClientesDetailsController.$inject = ['$state', 'UIService', 'AuthorizationService', 'CrmLegacyService', 'CoreCliente', 'CoreContacto'];
