export default class FormacaoAreasController {
  constructor($state, NgTableParams, FormacaoArea, FormacaoAreaEstudo, UIService) {
    this.$state = $state;
    this.UI = UIService;
    this.NgTableParams = NgTableParams;
    this.FormacaoArea = FormacaoArea;
    this.FormacaoAreaEstudo = FormacaoAreaEstudo;

    this.areasLoading = true;
    this.getAreas();
  };

  getAreas = () => {
    this.areasLoading = true;
    this.FormacaoArea.find({
      filter: {
        where: {active: 1},
        include: {
          relation: 'areaEstudo',
          scope: {
            where: {active: 1}
          }
        }
      }
    }).$promise.then(res => {
      this.areas = res;
      this.tableData = new this.NgTableParams({
        sorting: {
          codigo: 'asc'
        }
      }, {
        dataset: res,
      });
      this.getCategorias();
    }).catch(err => {
      this.UI.addToast('Erro de leitura de áreas de formação. Por favor tente mais tarde.');
      this.areasLoading = false;
    });
  };

  getCategorias = () => {
    this.FormacaoAreaEstudo.find({
      filter: {
        where: {active: 1}
      }
    }).$promise.then(res => {
      this.categorias = res;
      this.areasLoading = false;
    }).catch(err => {
      this.UI.addToast('Erro de leitura de áreas de formação. Por favor tente mais tarde.');
      this.areasLoading = false;
    });
  };

  addArea = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./create.dialog.html'),
      controller: ['$scope', ($scope) => {

        $scope.categorias = this.categorias;

        $scope.auxArea = {
          selected: {}
        };

        $scope.area = {
          id: 0,
          active: true
        };

        $scope.ok = () => {
          $scope.area.areaEstudoId = $scope.auxArea.selected.id;
          $scope.$close($scope.area);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    }).then((res) => {
      if (res) {
        this.FormacaoArea.create(res).$promise.then(() => {
          this.getAreas();
          this.UI.addToast('Área de formação inserida com sucesso');
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao inserir área de formação');
        })
      }
    }).catch(() => {
    });
  };
};

FormacaoAreasController.$inject = ['$state', 'NgTableParams', 'FormacaoArea', 'FormacaoAreaEstudo', 'UIService'];
