export default class OblOIListController {
  constructor($state, Group, OblOrdemintervencao, OblEstado, OblCliente, OblValencia, OblProcesso, OblAgendamento, OblAlteracaoestado, UIService, AuthenticationService) {
    this.$state = $state;
    this.Group = Group;
    this.Auth = AuthenticationService;
    this.OblOrdemintervencao = OblOrdemintervencao;
    this.OblAlteracaoestado = OblAlteracaoestado;
    this.OblEstado = OblEstado;
    this.OblCliente = OblCliente;
    this.OblValencia = OblValencia;
    this.OblProcesso = OblProcesso;
    this.OblAgendamento = OblAgendamento;
    this.UI = UIService;

    this.opt = $state.params;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.opt = this.opt || this.defaultOpt;

    this.displayColumns = [{
      displayName: 'ID SGI',
      name: 'id',
      visible: false,
      sortable: true
    },
    {
      displayName: 'Nº Processo',
      name: 'numProcesso',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Nº Parceiro',
      name: 'parceiro',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Valência',
      name: 'valencia',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Estado',
      name: 'estado',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Cliente',
      name: 'nomeCliente',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Proprietário',
      name: 'nomeClienteFinal',
      visible: true,
      sortable: true
    },
    {
      displayName: 'NIF Proprietário',
      name: 'nifClienteFinal',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Morada',
      name: 'morada',
      visible: true,
      sortable: true
    },
    {
      displayName: 'CP4',
      name: 'cp4',
      sortable: true,
      visible: true
    },
    {
      displayName: 'CP3',
      name: 'cp3',
      visible: true
    },
    {
      displayName: 'Localidade',
      name: 'localidade',
      sortable: true,
      visible: true
    },
    {
      displayName: 'Data de Pedido',
      name: 'data',
      exportDate: 'DD-MM-YYYY HH:mm',
      visible: true,
      sortable: true,
    }
    ];

    this.filtersLoaded = [0, 0, 0];

    this.columns = [
      {
        id: 'OblOrdemintervencao.id',
        name: 'ID SGI',
        type: 'o'
      },
      {
        id: 'OblOrdemintervencao.numeroProcesso',
        name: 'Nº Processo',
        type: 'o'
      },
      {
        id: 'OblOrdemintervencao.parceiro',
        name: 'Nº Parceiro',
        type: 'o'
      },
      {
        id: 'OblValencia.id',
        name: 'Valência',
        type: 's',
        list: this.valencias
      },
      {
        id: 'OblOrdemintervencao.cp4',
        name: 'Nº Cód. Postal',
        type: 'o'
      },
      {
        id: 'OblOrdemintervencao.cp3',
        name: 'Ext. Cód. Postal',
        type: 'o'
      },
      {
        id: 'OblOrdemintervencao.localidade',
        name: 'Localidade',
        type: 'o',
      },
      {
        id: 'OblOrdemintervencao.clienteId',
        name: 'Cliente',
        type: 's',
        list: this.clientes
      },
      {
        id: 'OblEstado.id',
        name: 'Estado',
        type: 's',
        list: this.estados
      },
      {
        id: 'OblOrdemintervencao.nomeCliente',
        name: 'Proprietário',
        type: 'o'
      },
      {
        id: 'OblOrdemintervencao.nifCliente',
        name: 'NIF Proprietário',
        type: 'o'
      },
    ]

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 'o'
    },
    {
      val: 'c',
      name: 'Começa por',
      type: 'o'
    },
    {
      val: 'd',
      name: 'Termina com',
      type: 'o'
    },
    {
      val: 'e',
      name: 'Contém',
      type: 'o'
    },
    {
      val: 'a',
      name: 'Igual (=)',
      type: 'd'
    },
    {
      val: 'b',
      name: 'Diferente de (≠)',
      type: 'd'
    },
    {
      val: 'c',
      name: 'Posterior a (>)',
      type: 'd'
    },
    {
      val: 'd',
      name: 'Anterior a (<)',
      type: 'd'
    },
    {
      val: 'e',
      name: 'Posterior ou igual (≥)',
      type: 'd'
    },
    {
      val: 'f',
      name: 'Anterior ou igual (≤)',
      type: 'd'
    },
    {
      val: 'a',
      name: 'Igual',
      type: 's'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 's'
    }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('OblOrdemintervencaoDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('OblOrdemintervencaoDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('OblOrdemintervencaoFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('OblOrdemintervencaoFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('OblOrdemintervencaoFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'OblValencia.id')
          f.column.selected.list = this.valencias;
        if (f.column.selected.id === 'OblEstado.id')
          f.column.selected.list = this.estados;
        if (f.column.selected.id === 'OblOrdemintervencao.clienteId')
          f.column.selected.list = this.clientes;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getProcessos();
  }

  clearFilter = () => {
    localStorage.removeItem('OblOrdemintervencaosFilter');
    this.customFilters = [];
    this.getProcessos();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  loadData = () => {

    this.OblEstado.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then(r => {
      this.estados = [];
      r.forEach(a => {
        this.estados.push({
          id: a.id,
          name: a.designacao
        });
      });
      this.filtersLoaded[0] = 1;
    }).catch(e => {
      this.estados = [];
      this.filtersLoaded[0] = 0;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Estados).");
    });

    this.OblValencia.find({
      filter: {
        where: {
          active: 1
        },
        include: 'categoria'
      }
    }).$promise.then(r => {
      this.valencias = [];
      r.forEach(a => {
        this.valencias.push({
          id: a.id,
          name: `${a.categoria.designacao} - ${a.designacao}`
        });
      });
      this.filtersLoaded[1] = 1;
    }).catch(e => {
      this.servicos = [];
      this.filtersLoaded[1] = 0;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Valências).");
    });

    this.OblCliente.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then(r => {
      this.clientes = [];
      r.forEach(a => {
        this.clientes.push({
          id: a.id,
          name: `${a.nome} (${a.nif})`
        });
      });
      this.filtersLoaded[2] = 1;
    }).catch(e => {
      this.clientes = [];
      this.filtersLoaded[2] = 0;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Clientes).");
    });
  }

  getProcessos = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {
      'OblOrdemintervencao.active': 1
    });

    this.OblOrdemintervencao.table2({
      params: {
        fields: [
          'OblOrdemintervencao.id as id',
          'OblOrdemintervencao.parceiro as parceiro',
          'OblOrdemintervencao.numeroProcesso as numProcesso',
          'OblOrdemintervencao.iidAno as iidAno',
          'OblOrdemintervencao.iidOi as iidOi',
          'OblOrdemintervencao.origem as origem',
          'OblOrdemintervencao.morada as morada',
          'OblOrdemintervencao.cp4 as cp4',
          'OblOrdemintervencao.cp3 as cp3',
          'OblOrdemintervencao.localidade as localidade',
          'OblOrdemintervencao.data as data',
          'OblOrdemintervencao.valenciaId as valenciaId',
          'OblCategoriaValencia.designacao as categoria',
          'OblValencia.designacao as valencia',
          'OblOrdemintervencao.estadoId as estadoId',
          'OblEstado.designacao as estado',
          'OblOrdemintervencao.clienteId as clienteId',
          'OblCliente.nome as nomeCliente',
          'OblOrdemintervencao.nomeCliente as nomeClienteFinal',
          'OblOrdemintervencao.nifCliente as nifClienteFinal',
        ],
        aliases: [undefined, undefined, undefined, undefined, undefined],
        from: ['OblOrdemintervencao', 'OblValencia', 'OblEstado', 'OblCliente', 'OblCategoriaValencia'],
        referencesOrigin: [undefined, 'OblOrdemintervencao.valenciaId', 'OblOrdemintervencao.estadoId', 'OblOrdemintervencao.clienteId', 'OblValencia.categoriaId'],
        references: [undefined, 'OblValencia.id', 'OblEstado.id', 'OblCliente.id', 'OblCategoriaValencia.id'],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      res.data.forEach(i => {
        if (i.cp4 && !i.cp3) {
          i.codigoPostal = i.cp4;
        }
        if (i.cp4 && i.cp3) {
          i.codigoPostal = `${('0000' + i.cp4).slice(-4)}-${('000' + i.cp3).slice(-3)}`;
        }
        if (i.cp3 != null && !isNaN(i.cp3))
          i.cp3 = ('000' + i.cp3).slice(-3);
      });

      this.pedidos = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((error) => {
      console.log(error);
    });
  };

  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.obler.oi.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.obler.oi.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.obler.oi.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.estados.length && !this.valencias.length && !this.clientes.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'OblValencia.id')
        f.list = this.valencias;
      if (f.id === 'OblEstado.id')
        f.list = this.estados;
      if (f.id === 'OblOrdemintervencao.clienteId')
        f.list = this.clientes;
      if (f.id === 'OblOrdemintervencao.clienteFinalId')
        f.list = this.clientes;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'OblValencia.id')
          f.column.selected.list = this.valencias;
        if (f.column.selected.id === 'OblEstado.id')
          f.column.selected.list = this.estados;
        if (f.column.selected.id === 'OblOrdemintervencao.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'OblOrdemintervencao.clienteFinalId')
          f.column.selected.list = this.clientes;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('OblOrdemintervencaosFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.obler.oi.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getProcessos();
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('OblOrdemintervencaosDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  hasSelect = () => {
    return _.some(this.pedidos, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.pedidos.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.pedidos.length;
    } else { // Remove all selections
      this.pedidos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.pedidos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.pedidos, r => r.selected).length;
    this.allSelected = this.nSelected >= this.pedidos.length;
    this.everythingSelected = false;
  };

  schedule = (x) => {
    this.UI.showDialog({
      template: require('./../details/processo.dialog.html'),
      controller: ['$scope', 'OblTipoProcesso', 'FuncionarioEntidadeProprietaria', ($scope, OblTipoProcesso, FuncionarioEntidadeProprietaria) => {

        $scope.aux = {}

        $scope.processoLoaded = false;
        $scope.tecnicosLoaded = false;

        OblTipoProcesso.find({
          filter: {
            where: {
              active: true,
              id: {
                gt: 1
              },
              state: x.estadoId
            }
          }
        }).$promise.then(r => {
          $scope.tipos = r;
          $scope.processoLoaded = true;
        });

        $scope.$watch(() => {
          return $scope.data.agData
        }, (val) => {
          $scope.tecnicosLoaded = false;
          FuncionarioEntidadeProprietaria.find({
            filter: {
              where: {
                entidadeProprietariaId: 3,
                active: true
              },
              include: {
                relation: 'funcionario',
                scope: {
                  include: {
                    relation: 'ausencias',
                    scope: {
                      where: {
                        dataInicio: {
                          lte: $scope.data.agData
                        },
                        dataFim: {
                          gte: $scope.data.agData
                        }
                      }
                    }
                  }
                }
              }
            }
          }).$promise.then(f => {
            $scope.users = [];
            f.forEach(r => {
              if (r.funcionario) {
                if (r.funcionario.ausencias.length == 0) {
                  r.funcionario.disabled = false;
                  $scope.users.push(r.funcionario);
                }
              }
            });
            $scope.users = $scope.users.sort((a, b) => a.name.localeCompare(b.name));
            $scope.tecnicosLoaded = true;
          });
        });


        $scope.data = {
          duracao:60
        }


        $scope.dateOptions = {
          minDate: moment(),
          format: 'YYYY-MM-DD'
        }

        $scope.hourOptions = {
          format: 'HH:mm'
        }

        $scope.ok = () => {
          $scope.data.tipoId = $scope.aux.tipo.id;
          $scope.data.funcionarioId = $scope.aux.user.id;
          $scope.$close($scope.data);
        };
        $scope.cancel = () => { $scope.$dismiss(); };
      }]
    }).then(r => {
      if (r) {
        this.loaded = false;
        let userId = this.Auth.getId();
        // Cria processo e agenda
        this.OblProcesso.create({
          id: 0,
          oiId: x.id,
          validado: false,
          active: true,
          criadoPor: userId,
          data: moment.utc(),
          funcionarioId: r.funcionarioId,
          tipoId: r.tipoId
        }).$promise.then(processo => {
          this.OblAgendamento.create({
            id: 0,
            duracao: r.duracao,
            agendadoa: moment.utc(),
            data: r.agData.format("YYYY-MM-DD"),
            hora: r.agHora.format("HH:mm:00"),
            tecnicoId: r.funcionarioId,
            agendadoporId: userId,
            processoId: processo.id,
            active: true
          }).$promise.then(agendamento => {
            if (r.tipoId == 2) {
              this.changeState(x, 4);
            } else if (r.tipoId == 3) {
              this.changeState(x, 9);
            } else {
              this.UI.addToast("Agendamento efetuado");
              this.loadData();
            }

          }).catch(e => {
            console.log(e);
            this.UI.addToast('Ocorreu um erro ao agendar processo');
            this.loadData();
          });
        }).catch(e => {
          this.UI.addToast('Ocorreu um erro ao adicionar processo');
          this.loadData();
        });
      }
    });
  }

  changeState = (x, state, notes) => {
    let user = this.Auth.getUser();
    this.OblAlteracaoestado.create({
      id: 0,
      oiId: x.id,
      estadoInicial: x.estadoId,
      estadoFinal: state,
      dataAlteracao: moment.utc(),
      funcionarioId: user.id,
      observacoes: notes || ""
    }).$promise.then(r => {
      this.UI.addToast("Estado atualizado com sucesso");
      this.getProcessos();
    }).catch(e => {
      this.UI.addToast("Ocorreu um erro ao alterar estado");
      this.getProcessos();
    });
  }

  import = () => {
    this.UI.showDialog({
      template: require('./import/dialog.html'),
      controller: 'OblImporterController',
      controllerAs: 'vm'
    }).then(r => {
      this.UI.addToast("Ficheiro importado com sucesso");
      this.getProcessos();
    }).catch(e => {
      if (e === 'error') {
        this.UI.addToast("Não foi possível importar ficheiro");
        this.getProcessos();
      }
      this.getProcessos();
    });
  }

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.pedidos, x => x.selected), x => { return { key: 'OblOrdemintervencao.id', value: x.id }; });
    let whereFields = this.setWhereFields(this.customFilters, { 'OblOrdemintervencao.active': 1 }, orsLiteral);
    this.OblOrdemintervencao.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'OblOrdemintervencao.id as id',
          'OblOrdemintervencao.parceiro as parceiro',
          'OblOrdemintervencao.numeroProcesso as numProcesso',
          'OblOrdemintervencao.iidAno as iidAno',
          'OblOrdemintervencao.iidOi as iidOi',
          'OblOrdemintervencao.origem as origem',
          'OblOrdemintervencao.morada as morada',
          'OblOrdemintervencao.cp4 as cp4',
          'OblOrdemintervencao.cp3 as cp3',
          'OblOrdemintervencao.localidade as localidade',
          'OblOrdemintervencao.data as data',
          'OblOrdemintervencao.valenciaId as valenciaId',
          'OblCategoriaValencia.designacao as categoria',
          'OblValencia.designacao as valencia',
          'OblOrdemintervencao.estadoId as estadoId',
          'OblEstado.designacao as estado',
          'OblOrdemintervencao.clienteId as clienteId',
          'OblCliente.nome as nomeCliente',
          'OblOrdemintervencao.nomeCliente as nomeClienteFinal',
        ],
        aliases: [undefined, undefined, undefined, undefined, undefined],
        from: ['OblOrdemintervencao', 'OblValencia', 'OblEstado', 'OblCliente', 'OblCategoriaValencia'],
        referencesOrigin: [undefined, 'OblOrdemintervencao.valenciaId', 'OblOrdemintervencao.estadoId', 'OblOrdemintervencao.clienteId', 'OblValencia.categoriaId'],
        references: [undefined, 'OblValencia.id', 'OblEstado.id', 'OblCliente.id', 'OblCategoriaValencia.id'],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'export.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.UI.addToast("Não foi possível exportar ordens de intervenção. Por favor tente mais tarde.");
    });
  };

  exportProcessos = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, { 'OblOrdemintervencao.active': 1 });
    this.OblOrdemintervencao.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'OblOrdemintervencao.id as id',
          'OblOrdemintervencao.parceiro as parceiro',
          'OblOrdemintervencao.numeroProcesso as numProcesso',
          'OblOrdemintervencao.iidAno as iidAno',
          'OblOrdemintervencao.iidOi as iidOi',
          'OblOrdemintervencao.origem as origem',
          'OblOrdemintervencao.morada as morada',
          'OblOrdemintervencao.cp4 as cp4',
          'OblOrdemintervencao.cp3 as cp3',
          'OblOrdemintervencao.localidade as localidade',
          'OblOrdemintervencao.data as data',
          'OblOrdemintervencao.valenciaId as valenciaId',
          'OblValencia.designacao as valencia',
          'OblOrdemintervencao.estadoId as estadoId',
          'OblEstado.designacao as estado',
          'OblOrdemintervencao.clienteId as clienteId',
          'OblCliente.nome as nomeCliente',
          'OblOrdemintervencao.clienteFinalId as clienteFinalcId',
          'ClienteFinal.nome as nomeClienteFinal',
          'OblOrdemintervencao.nifCliente as nifClienteFinal',
        ],
        aliases: [undefined, undefined, undefined, undefined, 'ClienteFinal'],
        from: ['OblOrdemintervencao', 'OblValencia', 'OblEstado', 'OblCliente', 'OblCliente'],
        referencesOrigin: [undefined, 'OblOrdemintervencao.valenciaId', 'OblOrdemintervencao.estadoId', 'OblOrdemintervencao.clienteId', 'OblOrdemintervencao.clienteFinalId'],
        references: [undefined, 'OblValencia.id', 'OblEstado.id', 'OblCliente.id', 'ClienteFinal.id'],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'export.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar ordens de intervenção. Por favor tente mais tarde.");
    });
  };

  adminExport = () => {
    this.UI.pickDateRange({
      format: "YYYY-MM-DD",
      maxDate: moment().add(15,'d')
    }, "Informação exportada relativa ao período escolhido").then(r => {
      if (r) {
        let dialog = this.UI.showWaiting();
        this.OblProcesso.downloader({ from: r.from.format("YYYY-MM-DD"), to: r.to.format("YYYY-MM-DD") }).$promise.then(res => {
          if (res.count > 0) {
            let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
            let b = document.createElement('a');
            b.href = url;
            b.download = 'export.xlsx';
            b.click();
            dialog.close();
            this.UI.addToast(`Exportação efetuada com informação relativa a ${res.count} processos`);
          } else {
            dialog.close();
            this.UI.addToast("Não existe informação relativa à semana escolhida");
          }
        }).catch(e => {
          dialog.close();
          this.UI.addToast("Não foi possível exportar informação");
        });
      }
    }).catch();
  }
}

OblOIListController.$inject = ['$state', 'Group', 'OblOrdemintervencao', 'OblEstado', 'OblCliente', 'OblValencia', 'OblProcesso', 'OblAgendamento', 'OblAlteracaoestado', 'UIService', 'AuthenticationService']
