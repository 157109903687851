let XLSX = require('xlsx');
import _ from 'lodash';
import moment from 'moment';
import 'regenerator-runtime/runtime'

export default class FunImporterController {
  constructor($q, $http, $scope, $dialog, UIService, FileUploader, Funcionario, AuthenticationService, FunProcesso, FunTecnico, FunAgendamento, FunTipoServico, FunTipoInstalacao, FunAlteracaoestadoProcesso, FunLote) {
    this.$q = $q;
    this.$http = $http;
    this.$scope = $scope;
    this.$dialog = $dialog;
    this.UI = UIService;
    this.Funcionario = Funcionario;
    this.Auth = AuthenticationService;
    this.FunProcesso = FunProcesso;
    this.FunTecnico = FunTecnico;
    this.FunAgendamento = FunAgendamento;
    this.FunTipoServico = FunTipoServico;
    this.FunTipoInstalacao = FunTipoInstalacao;
    this.FunAlteracaoestadoProcesso = FunAlteracaoestadoProcesso;
    this.FunLote = FunLote;
    this.uploader = new FileUploader({
      url: '/',
      queueLimit: 1
    });

    // Variáveis de controlo
    this.step = 1; // 1 - Carreg. ficheiro, 2 - Validar dados, 3 - Submissão de dados, 4 - Concluído, 5 - Erros encontrados
    this.messages = []; // Caso haja algum problema, serão adicionadas mensagens com o erro
    this.ignored = 0; // Numero de linhas ignoradas (é ignorado quando a data de agendamento < data de hoje)
    this.data = [];

    // Fields required in cabeçalho
    this.requiredFields = [
      "ID Ordem Serviço",
      "Tipo de Instalação",
      "Data de Atribuição",
      "Ano de contrato",
      "Rua",
      "Concelho",
      "Distrito",
      "Cliente",
      "NIF",
      "CEP",
      "Início Execução",
      "Fim Execução",
      "ID Tarefa",
      "ID Técnico Atribuído",
      "Primeiro Agendamento Efetuado em",
      "Serviço a Realizar"
    ];

    this.index = []; // Cabeçalho do ficheiro

    this.uploader.filters.push({
      name: 'isSheet',
      fn: function (item, options) {
        return '|application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|'.indexOf(item.type) !== -1;
      }
    });

    this.uploader.onAfterAddingFile = file => {
      let reader = new FileReader();
      reader.onload = e => {
        let data = e.target.result;
        let wb = XLSX.read(data, {
          type: 'binary',
          cellDates: true
        });
        if (wb.SheetNames.length > 0) {
          let rowObj = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });
          // Remove linhas vazias
          _.remove(rowObj, c => c.length == 0);
          // Remove primeira linha (cabeçalho)
          this.index = angular.copy(rowObj[0]);

          let resultCabecalho = this.checkCabecalho();

          if (resultCabecalho.ok) {
            rowObj.shift();
            let data = [];
            rowObj.forEach((row, i) => {
              // Valida a linha (Verifica se os dados existem)
              let result = this.validate(row, i);
              if (result.success) {
                this.data.push(result.row);
              } else {
                if (this.messages.find(r => r === result.message) === undefined) {
                  this.messages.push(result.message);
                }
              }
            });

            if (this.messages.length > 0) {
              this.changeStep(5);
            } else {
              // console.log("Linhas encontradas:" + this.data.length);
              // console.log("Nenhum problema encontrado na leitura do ficheiro");
              this.changeStep(2);
            }
          } else {
            if (resultCabecalho.missing.length > 0) {
              let errorMessage = "O ficheiro submetido tem cabeçalho com os seguintes campos obrigatórios em falta: ";
              resultCabecalho.missing.forEach(m => errorMessage += ` "${m}" `);
              this.messages.push(errorMessage);
            } else {
              this.messages.push("Erro no cabeçalho do ficheiro");
            }
            this.changeStep(5);
          }
        } else {
          this.messages.push("O ficheiro submetido não tem folhas para importar");
          this.changeStep(5);
        }
      }
      reader.readAsBinaryString(file._file);
    }

    this.loadData();
  }

  // Returns the array index of cabeçalho for the value
  getIndex = (value) => this.index.indexOf(value);

  // Check if all cabecalho fields exist
  checkCabecalho = () => {
    let result = {ok: true, missing: []};
    this.requiredFields.forEach(f => {
      if (this.getIndex(f) === -1) {
        result.ok = false;
        result.missing.push(f);
      }
    });
    return result;
  };


  changeStep = val => {
    try {
      this.$scope.$apply(() => {
        this.step = val;
      });
    } catch (e) {
      this.step = val;
    }
  };

  ok = () => {
    if (this.step === 2) {
      // Se step = 2, então começa importação
      this.import();
    } else {
      this.cancel();
    }
  };

  // Carrega toda a informação necessária para registar o processo (Funcionários, Tipos de Servico, ...)
  loadData = () => {
    this.loaded = false;
    this.FunTecnico.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(tecnicos => {
      this.tecnicos = tecnicos;
      this.FunTipoServico.find({
        filter: {
          where: {
            active: 1
          }
        }
      }).$promise.then(tiposServico => {
        this.tiposServico = tiposServico;
        this.FunTipoInstalacao.find({
          filter: {
            where: {
              active: 1
            }
          }
        }).$promise.then(tiposInstalacao => {
          this.tiposInstalacao = tiposInstalacao;
          this.FunLote.find({
            filter: {
              where: {
                active: 1
              }
            }
          }).$promise.then(lotes => {
            this.lotes = lotes;
            this.loaded = true;
          }).catch(e => {
          });
        }).catch(e => {
        });
      }).catch(e => {
      });
    }).catch(e => {
    });
  };

  cancel = () => {
    this.$dialog.dismiss();
  };

  // Recebe linha e valida campos necessários e substitui os campos de relação por id's
  validate = (row, i) => {
    // Index for indexes
    let x;

    // Special case - Andar - if exists and value is date fix it the best it can
    x = this.getIndex("Andar");
    if (row[x] !== undefined && row[x] !== null && row[x].toString().length > 0) {
      let andar = moment(row[x]);
      if (andar.isValid()) { // There was an export error, attempt to fix it
        if (andar.year() < 1900) { // It is hour only, use AM/PM and cut the final M
          row[x] = andar.format('h a').slice(0, -1).toUpperCase();
        } else {
          // If current year, use d-m as andar (Ex. 4-5)
          if (andar.year() === new Date().getFullYear()) {
            row[x] = andar.format('D-M');
          } else { // Otherwise, use the last two numbers of year
            row[x] = andar.format('M-YY');
          }
        }
      }
    }

    // ID Ordem Serviço
    x = this.getIndex("ID Ordem Serviço");
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com ID Ordem Serviço vazia`
      };
    }

    // Tipo de Instalação
    x = this.getIndex("Tipo de Instalação");
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com Tipo de Instalação vazia`
      };
    }
    let tipoInstalacao = this.tiposInstalacao.find(z => z.designacao.toUpperCase() === row[x].toUpperCase());
    if (tipoInstalacao !== undefined) {
      row[x] = tipoInstalacao.id;
    } else {
      return {
        success: false,
        message: `Linha ${i + 2} com Tipo de Instalação desconhecida`
      };
    }

    // Data de Atribuição (se existir)
    x = this.getIndex("Data de Atribuição");
    let dataAtribuicao = moment(row[x]);
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      row[x] = null;
    } else {
      if (dataAtribuicao.isValid()) {
        row[x] = moment(dataAtribuicao).format("YYYY-MM-DD HH:mm:00");
      } else {
        return {
          success: false,
          message: `Linha ${i + 2} com Data de Atribuição com formato de data inválido!`
        };
      }
    }

    // Ano de contrato
    x = this.getIndex("Ano de contrato");
    if (row[x] === undefined || row[x] === null || isNaN(row[x])) {
      return {
        success: false,
        message: `Linha ${i + 2} com Ano de Contrato inválido`
      };
    }

    // NIF Cliente
    x = this.getIndex("NIF");
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com NIF de Cliente inválido`
      };
    } else {
      if (row[x].toUpperCase().startsWith('PT')) { // We don't need starting PT in NIF
        row[x] = row[x].substring(2);
      }
    }

    // Distrito
    x = this.getIndex("Distrito");
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com Distrito vazio`
      };
    } else {
      let lote = this.lotes.find(z => z.distrito.toUpperCase() === row[x].toUpperCase());
      if (lote === undefined) {
        return {
          success: false,
          message: `Linha ${i + 2} com Distrito não reconhecido (Lote)`
        };
      }
    }

    // Início Execução
    x = this.getIndex("Início Execução");
    let dataInicioExecucao = moment(row[x]);
    if (row[x] !== undefined && row[x] !== null && row[x].toString().length !== 0 && dataInicioExecucao.isValid()) {
      row[x] = moment(dataInicioExecucao).format("YYYY-MM-DD HH:mm:00");
    } else {
      return {
        success: false,
        message: `Linha ${i + 2} com Início Execução vazia ou com formato de data errado!`
      };
    }

    // Fim Execução
    x = this.getIndex("Fim Execução");
    let dataFimExecucao = moment(row[x]);
    if (row[x] !== undefined && row[x] !== null && row[x].toString().length !== 0 && dataFimExecucao.isValid()) {
      row[x] = moment(dataFimExecucao).format("YYYY-MM-DD HH:mm:00");
    } else {
      return {
        success: false,
        message: `Linha ${i + 2} com Fim Execução vazia ou com formato de data errado!`
      };
    }

    // ID Tarefa
    x = this.getIndex("ID Tarefa");
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com ID Tarefa vazio`
      };
    }

    // ID Técnico Atribuído
    x = this.getIndex("ID Técnico Atribuído");
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com ID Técnico Atribuído vazio`
      }
    } else { // Stop import if tecnico does not exist...
      let tecnico = this.tecnicos.find(z => z.codigo.toUpperCase() === row[x].toUpperCase());
      if (tecnico === undefined) {
        return {
          success: false,
          message: `Linha ${i + 2} com ID Técnico Atribuído desconhecido (${row[x]})`
        };
      }
    }

    // Primeiro Agendamento Efetuado em
    x = this.getIndex("Primeiro Agendamento Efetuado em");
    if (row[x] !== undefined && row[x] !== null && row[x].toString().length !== 0) {
      let dataPrimeiroAgendamento = moment(row[x]);
      if (dataPrimeiroAgendamento.isValid()) {
        row[x] = moment(dataPrimeiroAgendamento).format("YYYY-MM-DD HH:mm:00");
      } else {
        return {
          success: false,
          message: `Linha ${i + 2} com Data de Primeiro Agendamento com formato de data inválida!`
        };
      }
    } else {
      row[x] = null;
    }

    // Serviço a Realizar
    x = this.getIndex("Serviço a Realizar");
    if (row[x] !== undefined && row[x] !== null && row[x].toString().length !== 0) { // Accept empty tipo de serviço, but check if whatever is there is ok
      let tipoServico = this.tiposServico.find(z => z.designacao.toUpperCase() === row[x].toUpperCase());
      if (tipoServico !== undefined) {
        row[x] = tipoServico.id;
      } else {
        return {
          success: false,
          message: `Linha ${i + 2} com Serviço a Realizar desconhecido (${row[x]})`
        };
      }
    } else {
      row[x] = null;
    }

    return {
      success: true,
      row: row
    };
  };

  download = () => {
    let o = angular.copy(this.statusProcessos);
    o.forEach(r => {
      r.result = this.explainStat(r.result, r.extra);
      delete r.extra; // No point in showing this field in the export
    });
    let csv = "ID Ordem de Serviço,ID Tarefa,Resultado\n"; // Header
    csv += o.map(d => Object.values(d).join()).join("\n"); // Data

    this.UI.addToast("A iniciar download, por favor aguarde...");
    let csvContent = "data:text/csv;charset=utf-8," + csv;
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "resultado.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };


  // Parse row into a single object for creations later
  parse = (row, oiId) => {
    // Join Rua + Andar + Fracção
    let morada = row[this.getIndex("Rua")];
    if (row[this.getIndex("Andar")] !== undefined && row[this.getIndex("Andar")] !== null)
      morada += (" " + row[this.getIndex("Andar")]);
    if (row[this.getIndex("Fracção")] !== undefined && row[this.getIndex("Fracção")] !== null)
      morada += (" " + row[this.getIndex("Fracção")]);

    return {
      numeroProcesso: row[this.getIndex("ID Ordem Serviço")],
      tarefaId: row[this.getIndex("ID Tarefa")],
      tipoInstalacaoId: row[this.getIndex("Tipo de Instalação")],
      tipoServicoId: row[this.getIndex("Serviço a Realizar")],
      oiId: oiId,
      idTecnicoAtribuido: row[this.getIndex("ID Técnico Atribuído")],
      dataAtribuicao: row[this.getIndex("Data de Atribuição")],
      dataPrimeiroAgendamento: row[this.getIndex("Primeiro Agendamento Efetuado em")],
      anoContrato: row[this.getIndex("Ano de contrato")],
      nifCliente: row[this.getIndex("NIF")],
      nomeCliente: row[this.getIndex("Cliente")],
      moradaCliente: morada,
      localidadeCliente: row[this.getIndex("Cidade")],
      concelhoCliente: row[this.getIndex("Concelho")],
      distritoCliente: row[this.getIndex("Distrito")],
      codigoPostal: row[this.getIndex("CEP")],
      inicioExecucao: row[this.getIndex("Início Execução")],
      fimExecucao: row[this.getIndex("Fim Execução")]
    };
  };


  import = async () => {
    this.changeStep(3);
    this.done = 0;
    this.importedWithErrors = 0;
    this.statusProcessos = []; // {numeroProcesso: ...,

    // Create each process
    for (let row of this.data) {

      let status = {
        numeroProcesso: row[this.getIndex("ID Ordem Serviço")],
        tarefaId: row[this.getIndex("ID Tarefa")],
        result: null,
        extra: null
      };

      let data = this.parse(row);
      // Create processo - returns processo and result object
      let processoData;
      try {
        processoData = await this.FunProcesso.importProcesso({
          params: {
            data: data
          }
        }).$promise;
        status.result = processoData.result;
        status.extra = processoData.extra;
      } catch (error) {
        console.log(error);
        if (error.status === 401) {
          status.result = 0;
          status.extra = "Sem permissões";
        }
      }

      this.statusProcessos.push(status);
      try {
        this.$scope.$apply(() => {
          if (status.result !== 1 && status.result !== 2) this.importedWithErrors++;
          this.done++;
        });
      } catch (e) {
        if (status.result !== 1 && status.result !== 2) this.importedWithErrors++;
        this.done++;
      }
    }
    this.changeStep(4);
  };

  explainStat = (value, extra) => {
    switch(value) {
      case 0: return `Falha na importação${extra ? (" (" + extra + ")") : ''}`;
      case    10: return "Importação com falhas (Em falta: Atualização da OI)";
      case   100: return "Importação com falhas (Em falta: Registo de Alteração Estado de Processo - Importação)";
      case   110: return "Importação com falhas (Em falta: Atualização da OI + Registo de Alteração de Estado de Processo - Importação";
      case  1000: return "Importação com falhas (Em falta: Registo de Alteração Estado de Processo - Agendado)";
      case  1010: return "Importação com falhas (Em falta: Atualização da OI + Registo de Alteração Estado de Processo - Agendado)";
      case  1100: return "Importação com falhas (Em falta: Registo de Alterações de Estado de Processo: Importação, Agendado)";
      case  1110: return "Importação com falhas (Em falta: Atualização da OI + Registo de Alterações de Estado de Processo: Importação, Agendado)";
      case 10000: return "Importação com falhas (Em falta: Agendamento)";
      case 10010: return "Importação com falhas (Em falta: Agendamento + Atualização da OI)";
      case 10100: return "Importação com falhas (Em falta: Agendamento + Registo de Alteração Estado de Processo - Importação)";
      case 10110: return "Importação com falhas (Em falta: Agendamento + Atualização da OI + Registo de Alteração de Estado de Processo - Importação";
      case 11000: return "Importação com falhas (Em falta: Agendamento + Registo de Alteração Estado de Processo - Agendado)";
      case 11010: return "Importação com falhas (Em falta: Agendamento + Atualização da OI + Registo de Alteração Estado de Processo - Agendado)";
      case 11100: return "Importação com falhas (Em falta: Agendamento + Registo de Alterações de Estado de Processo: Importação, Agendado)";
      case 11110: return "Importação com falhas (Em falta: Agendamento + Atualização da OI + Registo de Alterações de Estado de Processo: Importação, Agendado)";

      case    20: return "Reimportação com falhas (Em falta: Atualização da OI)";
      case   200: return "Reimportação com falhas (Em falta: Alteração de Estado)";
      case   220: return "Reimportação com falhas (Em falta: Atualização da OI + Alteração de Estado)";
      case  2000: return "Reimportação com falhas (Em falta: Atualização de Agendamento)";
      case  2020: return "Reimportação com falhas (Em falta: Atualização de Agendamento + Atualização da OI)";
      case  2200: return "Reimportação com falhas (Em falta: Atualização de Agendamento + Alteração de Estado)";
      case  2220: return "Reimportação com falhas (Em falta: Atualização de Agendamento + Alteração de Estado + Atualização da OI)";
      case 20000: return "Reimportação com falhas (Em falta: Atualização de Cliente)";
      case 20020: return "Reimportação com falhas (Em falta: Atualização da OI + Atualização de Cliente)";
      case 20200: return "Reimportação com falhas (Em falta: Alteração de Estado + Atualização de Cliente)";
      case 20220: return "Reimportação com falhas (Em falta: Atualização da OI + Alteração de Estado + Atualização de Cliente)";
      case 22000: return "Reimportação com falhas (Em falta: Atualização de Agendamento + Atualização de Cliente)";
      case 22020: return "Reimportação com falhas (Em falta: Atualização de Agendamento + Atualização da OI + Atualização de Cliente)";
      case 22200: return "Reimportação com falhas (Em falta: Atualização de Agendamento + Alteração de Estado + Atualização de Cliente)";
      case 22220: return "Reimportação com falhas (Em falta: Atualização de Agendamento + Alteração de Estado + Atualização da OI + Atualização de Cliente)";

      case 1000000: return "Importação não realizada (Relatório já preenchido)";
      case 1: return "Importação bem sucedida";
      case 2: return "Reimportação bem sucedida";
      default: return "Erro de importação";
    }
  };
}

FunImporterController.$inject = ['$q', '$http', '$scope', '$dialog', 'UIService', 'FileUploader', 'Funcionario', 'AuthenticationService', 'FunProcesso', 'FunTecnico', 'FunAgendamento', 'FunTipoServico', 'FunTipoInstalacao', 'FunAlteracaoestadoProcesso', 'FunLote'];
