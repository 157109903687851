import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Autocomplete, Box, CircularProgress, Collapse, IconButton, ListItem, ListItemText, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { MdiIcon } from '@components';
import { mdiAccountRemove, mdiChevronUp, mdiContentSave, mdiPlus } from '@mdi/js';
import moment from 'moment';
import { debounce } from 'lodash';
import BannerPessoas from './bannerPessoas';
const PessoasAssociadas = ({ $state, CrmService, user }) => {
    const [loading, setLoading] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [expand, setExpand] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    //const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const limit = 20; // Número de itens por página
    const [selectedValue, setSelectedValue] = useState('');
    const [pessoaAssociada, setPessoaAssociada] = useState(null);
    const [pessoasAssociadas, setPessoasAssociadas] = useState([]);
    const [loadPessoas, setLoadPessoas] = useState(true);
    const [removePessoas, setRemovePessoas] = useState(false); // mostrar Checkboxs
    const [removeList, setRemoveList] = useState([]);
    const noFetchRef = useRef([]); // contactos já associados, não pesquisar os mesmos
    const fetchPeople = async () => {
        try {
            const x = await CrmService.getPessoasAssociadas(user.id);
            // cast para adicionar `contactoAssociado` e `cliente`
            setPessoasAssociadas(x);
            const newIds = x.map(element => element.idCoreContactoAssociado);
            noFetchRef.current = [...noFetchRef.current, ...newIds, user.id];
            setLoadPessoas(false);
        }
        catch (error) {
            console.error('Erro ao carregar pessoas associadas:', error);
        }
    };
    const fetchOptions = useCallback(debounce(async (query, currentPage) => {
        if (!query || !hasMore)
            return;
        setLoading(true);
        try {
            const response = await CrmService.findContactoByName(query, currentPage, limit, noFetchRef.current);
            if (response.length < limit) {
                setHasMore(false); // Não há mais dados
            }
            setOptions(prev => [...prev, ...response]); // Concatena os dados
        }
        catch (error) {
            console.error('Erro ao carregar opções:', error);
        }
        finally {
            setLoading(false);
        }
    }, 500), [hasMore] // Atualiza se `hasMore` mudar
    );
    const handleScroll = event => {
        const listboxNode = event.currentTarget;
        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 1 && !loading && hasMore) {
            setPage(prev => prev + 1); // Incrementa a página
        }
    };
    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    const saveAssociacao = async () => {
        try {
            const x = await CrmService.upsertPessoaAssociada(pessoaAssociada);
            setLoadPessoas(true);
        }
        catch (error) {
            console.log('Erro ao guardar associação: ', error);
        }
    };
    const handleRemove = (id) => {
        if (removeList.find(element => element == id)) {
            setRemoveList(c => c.filter(item => item != id));
        }
        else {
            setRemoveList(oldArray => [...oldArray, id]);
        }
    };
    const removePessoasAssociadas = async () => {
        try {
            const x = await CrmService.removePessoasAssociadas(removeList);
            setLoadPessoas(true);
        }
        catch (error) {
            console.log('Erro ao remover associação: ', error);
        }
    };
    useEffect(() => {
        if (loadPessoas) {
            fetchPeople();
        }
        if (inputValue) {
            fetchOptions(inputValue, page);
        }
    }, [inputValue, page, loadPessoas]);
    return (<Box>
      <Stack direction='column'>
        <Stack direction='row' spacing={1} justifyContent='flex-start' alignItems='center'>
          <Typography variant='h6' sx={{ color: 'black' }}>
            Pessoas associadas
          </Typography>
          {expand ? (<Box>
              <Tooltip title={'Cancelar'}>
                <IconButton color='primary' onClick={() => {
                setExpand(!expand);
                setPessoaAssociada(null);
            }}>
                  <MdiIcon path={mdiChevronUp} sx={{ color: '#1976D2' }}/>
                </IconButton>
              </Tooltip>
              <Tooltip title={'Adicionar associação'}>
                <span>
                  <IconButton disabled={pessoaAssociada === null ? true : false} color='primary' onClick={() => {
                if (expand) {
                    saveAssociacao();
                    setPessoaAssociada(null);
                    setExpand(!expand);
                }
                else {
                    setPessoaAssociada(null);
                    setExpand(!expand);
                }
            }}>
                    <MdiIcon path={mdiContentSave} sx={{ color: pessoaAssociada === null ? '#E0E0E0' : '#1976D2' }}/>
                  </IconButton>
                </span>
              </Tooltip>
            </Box>) : (<Box>
              <Tooltip title={removePessoas ? 'Cancelar' : 'Adicionar associação'}>
                <IconButton color='primary' onClick={() => {
                if (removePessoas) {
                    setRemoveList([]);
                    setRemovePessoas(!removePessoas);
                }
                else {
                    setExpand(!expand);
                }
            }}>
                  <MdiIcon path={removePessoas ? mdiChevronUp : mdiPlus} sx={{ color: '#1976D2' }}/>
                </IconButton>
              </Tooltip>
              {pessoasAssociadas.length > 0 ? (<Tooltip title={removePessoas ? 'Remover pessoas selecionadas' : 'Remover associação'}>
                  <span>
                    <IconButton disabled={removePessoas && removeList.length === 0 ? true : false} color='primary' onClick={() => {
                    if (removePessoas) {
                        if (removeList.length > 0) {
                            removePessoasAssociadas();
                        }
                        setRemovePessoas(!removePessoas);
                    }
                    else {
                        setRemoveList([]);
                        setRemovePessoas(!removePessoas);
                    }
                }}>
                      <MdiIcon path={mdiAccountRemove} sx={{ color: removePessoas && removeList.length === 0 ? '#E0E0E0' : removePessoas ? '#BE2626' : '#1976D2' }}/>
                    </IconButton>
                  </span>
                </Tooltip>) : (<></>)}
            </Box>)}
        </Stack>
      </Stack>
      <Collapse in={expand}>
        <Autocomplete disablePortal options={options} getOptionLabel={option => (option.name ? `${option.name}` : '')} value={selectedValue} // O valor completo da opção selecionada
     onChange={(event, newValue) => {
            if (newValue == null) {
                setSelectedValue(null);
                setPessoaAssociada(null);
            }
            else {
                setSelectedValue(newValue); // Armazena o objeto completo
                setPessoaAssociada({
                    id: 0,
                    dataRegisto: moment().format('DD-MM-YYYY'),
                    idCoreContacto: user.id,
                    idCoreContactoAssociado: newValue.id,
                    nomeCoreContactoAssociado: newValue.name
                });
            }
        }} filterOptions={x => x} // Evita o filtro padrão
     onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            setOptions([]); // Reseta opções ao mudar busca
            setPage(0); // Reinicia a paginação
            setHasMore(true);
        }} ListboxProps={{
            onScroll: handleScroll // Adiciona evento de scroll
        }} renderOption={(props, option) => (<ListItem {...props} key={option.id} dense>
              <ListItemText primary={option.name} secondary={`Empresa: ${option.empresa || 'Sem empresa'} | Email: ${option.email || 'Sem email'}`}/>
            </ListItem>)} renderInput={params => (<TextField {...params} label='Contacto' variant='outlined' size='small' fullWidth sx={{ bgcolor: '#F5F5F5' }} InputProps={{
                ...params.InputProps,
                endAdornment: (<>
                    {loading ? <CircularProgress color='inherit' size={20}/> : null}
                    {params.InputProps.endAdornment}
                  </>)
            }}/>)}/>
      </Collapse>
      {loadPessoas ? (<Stack justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Stack>) : pessoasAssociadas.length > 0 ? (<BannerPessoas pessoasAssociadas={pessoasAssociadas} handleRemove={handleRemove} removePessoas={removePessoas} $state={$state} checkedList={removeList}/>) : (<Typography sx={{ mt: 2 }}>Sem pessoas associadas</Typography>)}

      <Snackbar open={openSnackBar} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity='success' variant='filled' sx={{ width: '100%' }}>
          Dados alterados com sucesso!
        </Alert>
      </Snackbar>
    </Box>);
};
export default PessoasAssociadas;
