export default class FinanceiroService {
  constructor($q, $http, UIService, AuthenticationService, CoreFaturacao) {
    this.$q = $q;
    this.$http = $http;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.CoreFaturacao = CoreFaturacao;
    this.user = this.Auth.getUser();
  }

  // Faturar processos with id in array ids = [{id: ..., estadoId: ...}, ...]
  faturarMultiplePedidos = (Model, faturas) => {
    if (!Model || !faturas) return;
    let defer = this.$q.defer();
    let options = {
      size: "lg",
      template: require("./faturar.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.title = "Faturação de Pedido" + (faturas.length !== 1 ? "s" : "");
        $scope.faturas = angular.copy(faturas);
        $scope.sameNFatura = false;

        // Called after pressing checkbox "Usar o mesmo Nº Fatura em todos" to make all values the same
        $scope.sameNFaturaChanged = (x) => {
          $scope.sameNFatura = x; // Needed to update the scope variable, as it doesn't with the top ng-if length > 1
          if ($scope.sameNFatura === false) { // Same NFatura is off, do nothing
            // Do nothing as it's free to change now...
          } else { // Same NFatura is on, change all nFaturas to match the first one
            $scope.faturas.forEach((f, i) => {
              if (i > 0) f.numeroFatura = $scope.faturas[0].numeroFatura;
            });
          }
        };

        $scope.sameNFaturaOnChange = (index) => {
          if (index !== 0) return;
          if ($scope.sameNFatura) { // If we are to copy, change all the rest
            $scope.faturas.forEach((f,i) => {
              if (i > 0) f.numeroFatura = $scope.faturas[0].numeroFatura;
            });
          }
        }

        // Returns true if we pressed the checkbox to make all have the sameNFatura and it's not the first item
        $scope.isDisabled = (index) => index !== 0 && $scope.sameNFatura;

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };
      }]
    };
  //
    let modal = this.UI.showDialog(options);
    modal.then((ok) => {
      let wait = this.UI.showWaiting();
      let tasks = [];
      ok.faturas.forEach(fatura => {
        let deferFaturacao = this.$q.defer();
        // CoreFaturacao
        Model.findOne({
          filter: {
            where: {
              id: fatura.id,
              // active: 1
            }
          }
        }).$promise.then((f) => {
          if (!f.faturado) {
            f.dataFaturacao = new Date();
            f.faturadoPor = this.Auth.getId();
            f.faturadoPorName = this.Auth.getUser().name;
            f.numeroFatura = fatura.numeroFatura;
            f.faturado = 1;
            f.$save().then((fa) => {
              this.UI.addToast("Pedido marcado como faturado");
              deferFaturacao.resolve(fatura.id);
            }, (error) => {
              console.log(error);
              this.UI.addToast("De momento não é possível alterar o estado de faturação. Por favor tente mais tarde.");
              deferFaturacao.resolve(fatura.id);
            });
          } else {
            this.UI.addToast("Este Pedido já se encontra marcado como faturado.");
            deferFaturacao.resolve(fatura.id);
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("De momento não é possível alterar o estado. Tente novamente.");
          deferFaturacao.resolve(fatura.id);
        });
        tasks.push(deferFaturacao.promise);
      });
      this.$q.all(tasks).then((res) => {
        // Reload data
        wait.close();
        defer.resolve();
      }).catch(e => {
        console.log(e);
        this.UI.addToast("Ocorreu um erro ao alterar o estado de faturação.");
        wait.close();
        defer.resolve();
      });
    }).catch(() => {
      defer.reject();
    });
    return defer.promise;
  };

}

FinanceiroService.$inject = ['$q', '$http', 'UIService', 'AuthenticationService', 'CoreFaturacao'];
