export default class SusEneNewInterventionController {
  constructor($scope, $state, UIService, SusEneService, SusEneInterventionService, SusEneCliente, SusEneTipo, SusEneLaboratorio, SusEneProcessoCaracteristica, SusEneOrdemintervencao, SusEneLaboratorioSubarea, SusEneCodigoArtigo) {
    this.state = $state;
    this.UI = UIService;
    // State control
    this.loaded = false;
    this.SusEneService = SusEneService;
    this.Service = SusEneInterventionService;
    this.SusEneCliente = SusEneCliente;
    this.SusEneTipo = SusEneTipo;
    this.SusEneProcessoCaracteristica = SusEneProcessoCaracteristica;
    this.SusEneOrdemintervencao = SusEneOrdemintervencao;
    this.SusEneLaboratorio = SusEneLaboratorio;
    this.SusEneLaboratorioSubarea = SusEneLaboratorioSubarea;
    this.SusEneCodigoArtigo = SusEneCodigoArtigo;
    this.empresaId = null;
    this.loadData();
    this.form = {
      details: {
        id: 0,
        ano: moment().format('YYYY')
      },
      client: {},
      processes: []
    };
  }

  askForOI = () => {
    let dialog = this.UI.showDialog({
      template: require('./primavera.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.detail = {};

        $scope.data = {
          ano: moment(),
        };

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
          this.state.go('app.susene.interventions.list');
        }
      }]
    });
    dialog.then(res => {
      if (res) {
        // empresaId used for importFrom, empresa used for labels
        this.empresaId = 1; // IEP
        // put value of empresaId into data for getting right source
        res.data.empresaId = this.empresaId;
        this.getPrimaveraData(res.data);
      }
    })
  };

  validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // Returns true if there's at least one linha that has a valid Artigo
  hasValidCodigosArtigo = (codigos, linhas) => {
    for (let i = 0; i < linhas.length; i++) {
      if (codigos.find(x => x.codigo === linhas[i].artigo)) {
        return true;
      }
    }
    return false;
  };

  getPrimaveraData = (data) => {
    let wait = this.UI.showWaiting();
    let PRICabecdoc = this.SusEneService.getPRICabecdoc(data);
    PRICabecdoc.find({
      filter: {
        where: {
          tipodoc: 'OI',
          serie: moment(data.ano).format('YY'),
          numdoc: data.no
        },
        include: [{
          relation: 'linhas',
          scope: {
            order: 'numlinha ASC'
          }
        }, 'cliente']
      }
    }).$promise.then(res => {
      if (res.length === 0) {
        wait.close();
        this.state.go('app.susene.interventions.list');
        this.UI.addToast('Nenhuma OI registada no Primavera com os dados fornecidos!');
      } else {
        if (res[0].linhas.length === 0 || !this.hasValidCodigosArtigo(this.codArtigosSusEne, res[0].linhas)) {
          wait.close();
          this.UI.showAlert('A Referência da OI introduzida não tem pelo menos um Código de Artigo válido pertencente ao módulo de Sustentabilidade e Energia. Verifique a referência.');
          this.state.go('app.susene.interventions.list');
        } else {
          let whereObject = {
            and: [{primaveraId: res[0].id}]
          };
          // Find OI for proper empresa
          whereObject.and.push({empresaId: data.empresaId});

          this.SusEneOrdemintervencao.find({
            filter: {
              fields: {primaveraId: true, empresaId: true},
              where: whereObject
            }
          }).$promise.then(exists => {
            if (!exists.length) {
              this.form.tipo = null;
              this.form.details = {
                empresaId: data.empresaId,
                ano: moment(data.ano).format('YYYY'),
                num: data.no,
                email: this.validEmail(res[0].cduEmail) ? res[0].cduEmail : null,
                nomeContactoPri: res[0].cduContactonome,
                telefonePri: res[0].cduTelefone,
                telemovelPri: res[0].cduTelemovel,
                primaveraId: res[0].id,
                dataRececao: moment(res[0].data).format('YYYY-MM-DD')
              };
              this.form.primavera = res[0];
              this.form.client = {
                empresaId: data.empresaId,
                nif: res[0].cliente.numcontrib,
                nome: res[0].cliente.nome,
                morada: res[0].cliente.facMor,
                cp4: res[0].cliente.facCp ? res[0].cliente.facCp.split("-")[0] : null,
                cp3: res[0].cliente.facCp ? res[0].cliente.facCp.split("-")[1] : null,
                localidade: res[0].cliente.facCploc,
                contactoTelefonico: res[0].cliente.facTel,
                observacoes: res[0].cliente.notas,
                atualizadoa: res[0].cliente.dataultimaactualizacao,
                refClientePrimavera: res[0].cliente.cliente,
                email: this.validEmail(res[0].cliente.cduEmail) ? res[0].cliente.cduEmail : null
              };


              // Check if cliente exists and is bloqueado. If so, do not add OI
              this.SusEneCliente.find({
                filter: {
                  where: {
                    and: [
                      {active: 1},
                      {empresaId: data.empresaId},
                      {refClientePrimavera: res[0].cliente.cliente},
                      {bloqueado: 1}
                    ]
                  }
                }
              }).$promise.then((clienteBloqueado) => {
                if (clienteBloqueado.length === 0) { // It is not bloqueado (if exists, carry on)


                  res[0].linhas.forEach(linha => {
                    if (linha.artigo !== null) {
                      let codArtigos;
                      codArtigos = this.codArtigosSusEne;
                      let tmp = codArtigos.find(c => c.codigo === linha.artigo);
                      if (tmp) {
                        // Choose tipo processo
                        // If IEP, choose by ccustocbl
                        let tipoId = 1;

                        if (this.form.tipo === null) {
                          this.form.tipo = tipoId;
                        }
                        this.form.processes.push({
                          id: 0,
                          empresaId: data.empresaId,
                          tipoId: tipoId,
                          artigo: linha.descricao,
                          laboratorioId: tmp.laboratorioId,
                          quantidade: linha.quantidade,
                          maxQuantidade: linha.quantidade,
                          acreditado: 0,
                          inSitu: 1,
                          valor: linha.quantidade ? linha.precoliquido / linha.quantidade : linha.precoliquido,
                          email: this.form.details.email,
                          primaveraId: linha.id
                        });
                      }
                    }
                  });
                  this.UI.addToast('OI carregada com sucesso!');


                } else {
                  this.UI.showAlert("O Cliente associado a esta OI encontra-se de momento bloqueado, de forma que não é possível importar OIs suas de momento.");
                  this.state.go('app.susene.interventions.list');
                }
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("Não foi possível verificar validade do cliente. Por favor tente mais tarde.");
              });


            } else {
              this.state.go('app.susene.interventions.list');
              this.UI.addToast('A OI introduzida já existe no sistema');
            }
            wait.close();
            this.loaded = true;
          }).catch(err => {
            console.log(err);
            this.UI.addToast("Não foi possível verificar existência de OI. Por favor tente mais tarde.");
          });
        }
      }
    }).catch(() => {
      wait.close();
      this.UI.addToast('Erro ao carregar dados do Primavera!');
    })
  };

  getTipoOf = (num) => {
    return this.tipos.find(f => {
      return (f.id === num);
    });
  };

  getLabOf = (num) => {
    return this.laboratorios.find(f => {
      return (f.id === num);
    });
  };

  loadData = () => {
    this.loaded = false;
    // this.SusEneLaboratorioSubarea.find({
    //   filter: {
    //     where: {
    //       active: true
    //     }
    //   }
    // }).$promise.then(subareas => {
    //   this.subareas = subareas;
    //   this.SusEneProcessoCaracteristica.find({
    //     filter: {
    //       where: {
    //         active: true
    //       }
    //     }
    //   }).$promise.then(caracteristicas => {
    //     this.caracteristicas = caracteristicas;
        this.SusEneTipo.find({
          filter: {
            where: {
              active: true
            }
          }
        }).$promise.then(tipos => {
          this.tipos = tipos;
          this.SusEneLaboratorio.find({
            filter: {
              where: {
                active: true
              }
            }
          }).$promise.then(labs => {
            this.laboratorios = labs;
            this.SusEneCodigoArtigo.find({
              filter: {
                where: {
                  active: true
                }
              }
            }).$promise.then((codigosSusEne) => {
              this.codArtigosSusEne = codigosSusEne;
              this.askForOI();
            }).catch((error) => {
              console.log(error);
              this.state.go('app.susene.interventions.list');
              this.UI.addToast('Erro ao obter códigos de artigos (SE)');
            });
          }).catch((error) => {
            // Erros on laboratorios
            this.state.go('app.susene.interventions.list');
            this.UI.addToast('Erro ao obter áreas de processos');
          });
        }).catch(() => {
          // Error on tipos
          this.state.go('app.susene.interventions.list');
          this.UI.addToast('Erro ao obter características de processos');
        });
      // }).catch(() => {
      //   this.state.go('app.susene.interventions.list');
      //   this.UI.addToast('Erro ao obter características de processos');
      // });
    // }).catch(() => {
    //   this.state.go('app.susene.interventions.list');
    //   this.UI.addToast('Erro ao obter características de processos');
    // });
  };

  importFrom = (linha) => {
    if (angular.isDefined(linha.artigo)) {
      // Choose tipo processo
      let tipoId = 1;
      let codArtigos;
      codArtigos = this.codArtigosSusEne;

      let tmp = codArtigos.find(c => c.codigo === linha.artigo);
      let data = ({
        id: 0,
        empresaId: this.empresaId,
        tipoId: tipoId,
        artigo: linha.descricao,
        laboratorioId: tmp ? tmp.laboratorioId : null,
        primaveraId: linha.id,
        quantidade: linha.quantidade,
        maxQuantidade: linha.quantidade,
        valor: linha.precoliquido,
        email: this.form.details.email,
        imported: true
      });
      let options = {
        size: 'lg',
        template: require('./process.dialog.html'),
        controller: ['$scope', ($scope) => {
          $scope.modalTitle = 'Importar Processo';
          $scope.data = data;

          $scope.auxSubarea = {
            selected: null
          };

          $scope.auxLaboratorio = {
            selected: this.laboratorios.find(lab => {
              return lab.id === data.laboratorioId;
            })
          };
          $scope.auxCaracteristica = {selected: null};

          $scope.yesNo = [{
            id: 0,
            value: 'Não'
          }, {
            id: 1,
            value: 'Sim'
          }];

          $scope.auxAcreditado = {
            selected: $scope.yesNo[1]
          };

          $scope.auxInSitu = {
            selected: $scope.yesNo[1]
          };

          $scope.disableLab = false;
          $scope.caracteristicas = this.caracteristicas || [];
          $scope.tipos = this.tipos;
          $scope.subareas = this.subareas || [];
          $scope.usableSubareas = $scope.subareas.filter(x => x.laboratorioId === $scope.auxLaboratorio.selected.id);

          $scope.onLabSelected = (item) => {
            $scope.subarea = {selected: undefined};
            $scope.usableSubareas = $scope.subareas.filter(x => x.laboratorioId === item.id);
          };

          $scope.onTipoSelected = (item) => {
            $scope.labs = this.laboratorios.filter(lab => {
              return lab.tipoId === item.id;
            });
            $scope.auxLaboratorio.selected = null;
            $scope.disableLab = false;
          };

          $scope.auxTipo = {
            selected: this.tipos.find(tipo => {
              return tipo.id === data.tipoId;
            })
          };

          $scope.labs = this.laboratorios.filter(lab => {
            return lab.tipoId === data.tipoId;
          });

          $scope.loaded = true;

          $scope.ok = () => {
            if ($scope.loaded) {

              // Check valid email (if exists)
              if ($scope.data.email != null) {
                $scope.data.email = $scope.data.email.toString().trim();
                if ($scope.data.email.length === 0)
                  $scope.data.email = null;
              }

              if ($scope.data.email !== null && !this.validEmail($scope.data.email)) {
                this.UI.addToast("Email de Processo inválido. Por favor corrija-o ou apague-o.");
                return;
              }

              $scope.data.tipoId = $scope.auxTipo.selected.id;
              $scope.data.laboratorioId = $scope.auxLaboratorio.selected.id;
              if ($scope.auxAcreditado.selected)
                $scope.data.acreditado = $scope.auxAcreditado.selected.id || null;
              if ($scope.auxCaracteristica.selected)
                $scope.data.caracteristicaId = $scope.auxCaracteristica.selected.id || null;
              // Only use subarea if tipoId = 2 and Lab = 1 (Ensaios Elétricos)
              // if ($scope.data.tipoId === 2 && ($scope.auxLaboratorio.selected.id === 1 || $scope.auxLaboratorio.selected.id === 30) && $scope.auxSubarea.selected)
              //   $scope.data.subareaId = $scope.auxSubarea.selected.id || null;
              $scope.data.subareaId = null;
              $scope.$close($scope.data);
            } else
              $scope.$dismiss('cancel');
          };

          $scope.cancel = () => {
            $scope.$dismiss('cancel');
          };
        }]
      };
      this.UI.showDialog(options).then(res => {
        this.form.processes.push(res);
      });
    }
  };

  editProcess = (data) => {
    let options = {
      size: 'lg',
      template: require('./process.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.modalTitle = 'Editar Processo';
        $scope.data = data;
        $scope.yesNo = [{
          id: 0,
          value: 'Não'
        }, {
          id: 1,
          value: 'Sim'
        }];

        $scope.auxAcreditado = {
          selected: $scope.yesNo.find(f => f.id === data.acreditado)
        };

        $scope.auxInSitu = {
          selected: $scope.yesNo.find(f => f.id === data.inSitu)
        };

        $scope.auxTipo = {
          selected: this.tipos.find(tipo => {
            return tipo.id === data.tipoId;
          })
        };

        $scope.auxLaboratorio = {
          selected: this.laboratorios.find(lab => {
            return lab.id === data.laboratorioId;
          })
        };

        $scope.labs = this.laboratorios.filter(lab => {
          return lab.tipoId === data.tipoId;
        });

        $scope.disableLab = false;
        $scope.caracteristicas = this.caracteristicas || [];
        $scope.tipos = this.tipos;
        $scope.subareas = this.subareas || [];
        $scope.usableSubareas = $scope.subareas.filter(x => x.laboratorioId === data.laboratorioId);

        $scope.auxCaracteristica = {selected: null};
        $scope.auxSubarea = {selected: null};

        $scope.auxCaracteristica = {
          selected: this.caracteristicas.find(f => f.id === data.caracteristicaId)
        };

        $scope.auxSubarea = {
          selected: this.subareas.find(f => f.id === data.subareaId)
        }

        $scope.onLabSelected = (item) => {
          $scope.auxSubarea = {selected: undefined};
          $scope.usableSubareas = $scope.subareas.filter(x => x.laboratorioId === item.id);
        };

        $scope.onTipoSelected = (item) => {
          $scope.labs = this.laboratorios.filter(lab => {
            return lab.tipoId === item.id;
          });
          $scope.auxLaboratorio.selected = null;
          $scope.disableLab = false;
        };

        $scope.loaded = true;

        $scope.ok = () => {
          if ($scope.loaded) {

            // Check valid email (if exists)
            if ($scope.data.email != null) {
              $scope.data.email = $scope.data.email.toString().trim();
              if ($scope.data.email.length === 0)
                $scope.data.email = null;
            }

            if ($scope.data.email !== null && !this.validEmail($scope.data.email)) {
              this.UI.addToast("Email de Processo inválido. Por favor corrija-o ou apague-o.");
              return;
            }

            $scope.data.tipoId = $scope.auxTipo.selected.id;
            $scope.data.laboratorioId = $scope.auxLaboratorio.selected.id;
            if ($scope.auxAcreditado.selected)
              $scope.data.acreditado = $scope.auxAcreditado.selected.id || null;
            if ($scope.auxCaracteristica.selected)
              $scope.data.caracteristicaId = $scope.auxCaracteristica.selected.id || null;
            else
              $scope.data.caracteristicaId = null;
            // Only use subarea if tipoId = 2 and Lab = 1 (Ensaios Elétricos)
            if ($scope.auxSubarea.selected)
              $scope.data.subareaId = $scope.auxSubarea.selected.id || null;
            else
              $scope.data.subareaId = null;
            $scope.$close($scope.data);
          } else
            $scope.$dismiss('cancel');
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    this.UI.showDialog(options).then(res => {
      this.data = res;
    });
  };

  addProcess = () => {
    let options = {
      size: 'lg',
      template: require('./process.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.modalTitle = 'Adicionar Processo';
        $scope.auxTipo = {
          selected: this.tipos.find(f => f.id === this.form.tipo)
        };
        $scope.auxLaboratorio = {};
        $scope.labs = this.laboratorios.filter(lab => {
          return lab.tipoId === this.form.tipo;
        });
        $scope.auxLaboratorio = {selected: null};
        $scope.disableLab = false;
        $scope.auxCaracteristica = {selected: null};
        $scope.auxSubarea = {selected: null};
        $scope.subareas = this.subareas || [];
        if ($scope.auxLaboratorio.selected)
          $scope.usableSubareas = $scope.subareas.filter(x => x.laboratorioId === $scope.auxLaboratorio.selected.id);
        else
          $scope.usableSubareas = [];

        $scope.data = {
          id: 0,
          email: this.form.details.email,
          imported: false
        };

        $scope.yesNo = [{
          id: 0,
          value: 'Não'
        }, {
          id: 1,
          value: 'Sim'
        }];

        $scope.auxAcreditado = {
          selected: $scope.yesNo[1]
        };

        $scope.auxInSitu = {
          selected: $scope.yesNo[1]
        };

        $scope.caracteristicas = this.caracteristicas;
        $scope.tipos = this.tipos;

        $scope.onLabSelected = (item) => {
          $scope.auxSubarea = {selected: undefined};
          $scope.usableSubareas = $scope.subareas.filter(x => x.laboratorioId === item.id);
        };

        $scope.onTipoSelected = (item) => {
          $scope.labs = this.laboratorios.filter(lab => {
            return lab.tipoId === item.id;
          });
          $scope.auxLaboratorio.selected = null;
        };

        $scope.loaded = true;

        $scope.ok = () => {
          if ($scope.loaded) {

            // Check valid email (if exists)
            if ($scope.data.email != null) {
              $scope.data.email = $scope.data.email.toString().trim();
              if ($scope.data.email.length === 0)
                $scope.data.email = null;
            }

            if ($scope.data.email !== null && !this.validEmail($scope.data.email)) {
              this.UI.addToast("Email de Processo inválido. Por favor corrija-o ou apague-o.");
              return;
            }

            $scope.data.empresaId = this.empresaId;
            $scope.data.tipoId = $scope.auxTipo.selected.id;
            $scope.data.laboratorioId = $scope.auxLaboratorio.selected.id;
            if ($scope.auxAcreditado.selected)
              $scope.data.acreditado = $scope.auxAcreditado.selected.id || null;
            if ($scope.auxCaracteristica.selected)
              $scope.data.caracteristicaId = $scope.auxCaracteristica.selected.id || null;
            else
              $scope.data.caracteristicaId = null;
            if ($scope.auxSubarea.selected)
              $scope.data.subareaId = $scope.auxSubarea.selected.id || null;
            else
              $scope.data.subareaId = null;
            $scope.$close($scope.data);
          } else
            $scope.$dismiss('cancel');
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }],
    };

    let modal = this.UI.showDialog(options);
    modal.then((res) => {
      if (res) {
        this.form.processes.push(res);
      }
    })
  };

  removeProcess = r => {
    let confirm = this.UI.showConfirm('Deseja remover a criação deste' + (r.quantidade === 1 ? ' processo' : 's processos') + '?');
    confirm.then(res => {
      if (res) {
        this.form.processes.splice(this.form.processes.indexOf(r), 1);
      }
    })
  };

  registerOI = id => {
    let total = 0;
    this.form.primavera.linhas.forEach(l => {
      total += l.precoliquido;
    });
    // Regista dados da OI
    let oiData = {
      id: 0,
      clienteId: id,
      empresaId: this.form.details.empresaId,
      ano: this.form.details.ano,
      no: this.form.details.num,
      email: this.form.details.email,
      primaveraId: this.form.details.primaveraId,
      observacoes: this.form.details.observacoes,
      dataRececao: this.form.details.dataRececao,
      numeroEncomenda: this.form.details.numeroEncomenda,
      contrato: this.form.details.contrato,
      total: total,
      refClientePrimavera: this.form.client.refClientePrimavera,
      nomeContactoPri: this.form.details.nomeContactoPri,
      telefonePri: this.form.details.telefonePri,
      telemovelPri: this.form.details.telemovelPri,
      active: true
    };
    this.Service.register(oiData, this.form.processes).then(res => {
      this.isWaiting.close();
      this.state.go('app.susene.interventions.list');
      this.UI.addToast('Ordem de intervenção adicionada');
    }).catch(() => {
      this.UI.addToast('Erro na importação da OI. Falha na criação de processos');
    });
  };

  canAdd = (id) => {
    let exists = this.form.processes.find(p => p.primaveraId === id);
    return !exists;
  };

  register = () => {
    // Validate email
    if (this.form.details.email != null) {
      this.form.details.email = this.form.details.email.toString().trim();
      if (this.form.details.email.length === 0)
        this.form.details.email = null;
    }

    if (this.form.details.email !== null && !this.validEmail(this.form.details.email)) {
      this.UI.addToast("Email da OI inválido. Por favor corrija-o ou apague-o antes de submeter.");
      return;
    }

    let confirm = this.UI.showConfirm('Concluir criação de Ordem de Intervenção?');
    confirm.then(res => {
      if (res) {
        if (this.form.processes.length === 0) {
          this.UI.addToast('A OI não tem processos associados!');
        } else {
          this.isWaiting = this.UI.showWaiting();
          this.SusEneCliente.find({
            filter: {
              where: {
                and: [{empresaId: this.form.client.empresaId}, {or: [{nif: this.form.client.nif}, {refClientePrimavera: this.form.client.refClientePrimavera}]}]
              }
            }
          }).$promise.then(client => {
            if (client.length === 0) {
              this.form.client.id = 0;
              this.form.client.active = true;
              this.SusEneCliente.create(this.form.client).$promise.then(c => {
                this.UI.addToast('Novo cliente registado!');
                this.registerOI(c.id);
              });
            } else {
              this.registerOI(client[0].id);
            }
          });
        }
      }
    });
  };
}

SusEneNewInterventionController.$inject = ['$scope', '$state', 'UIService', 'SusEneService', 'SusEneInterventionService', 'SusEneCliente', 'SusEneTipo', 'SusEneLaboratorio', 'SusEneProcessoCaracteristica', 'SusEneOrdemintervencao', 'SusEneLaboratorioSubarea', 'SusEneCodigoArtigo'];
