export function routes($stateProvider) {
  $stateProvider
    .state('app.gas.relatorios', {
      url: '/relatorios',
      abstract: true
    })
    .state('app.gas.relatorios.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Relatórios',
      template: '<gas-relatorios-list-view></gas-relatorios-list-view>',
      // role: ['gasVerRelatorios'],
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    });
}

routes.$inject = ['$stateProvider'];
