export default class SusEneDirectoryRelatoriosController {
  constructor($q, $filter, $state, UIService, AuthenticationService, Group, SusEneProcessoRelatorioDraft, SusEneProcesso, SusEneLaboratorio, SusEneLaboratorioSubarea, Funcionario, SusEneCliente, SusEneEstadoRelatorio, SusEneTipoRelatorio) {
    this.$q = $q;
    this.$filter = $filter;
    this.$state = $state;
    this.UI = UIService;
    this.user = AuthenticationService.getUser();
    this.Group = Group;
    this.SusEneProcessoRelatorioDraft = SusEneProcessoRelatorioDraft;
    this.SusEneProcesso = SusEneProcesso;
    this.SusEneLaboratorio = SusEneLaboratorio;
    this.SusEneLaboratorioSubarea = SusEneLaboratorioSubarea;
    this.Funcionario = Funcionario;
    this.SusEneCliente = SusEneCliente;
    this.SusEneEstadoRelatorio = SusEneEstadoRelatorio;
    this.SusEneTipoRelatorio = SusEneTipoRelatorio;

    // If loadData() finished or not
    this.dataLoaded = false;

    this.laboratorios = [];
    this.subareas = [];
    this.tecnicosSUSENE = [];
    this.clientes = [];
    this.tipos = [];

    this.filtersLoaded = [0, 0, 0, 0, 0];

    this.opt = $state.params;
    // Prevent states other than 1 and 3 (would make no sense), default 1
    if (this.opt.state && this.opt.state !== 1 && this.opt.state !== 3) {
      this.opt.state = 1;
      this.$state.go('app.susene.relatorios.list', this.opt, {
        // prevent the events onStart and onSuccess from firing
        notify: false,
        // prevent reload of the current state?
        reload: true,
        // replace the last record when changing the params so you don't hit the back button and get old params
        location: 'replace',
        // inherit the current params on the url
        inherit: false
      });
    }
    this.stateId = this.opt.state;

    // Total processes for currently being displayed
    this.total = 0;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [
      {
        displayName: 'ID SGI',
        name: 'id',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Ref. Relatório',
        name: 'refRelatorio',
        visible: true,
        sortable: true,
        width: 140
      },
      {
        displayName: 'Tipo de Relatório',
        name: 'tipo',
        visible: true,
        sortable: true,
        width: 250
      },
      {
        displayName: 'Nº Processo',
        name: 'processo',
        visible: true,
        sortable: true,
        width: 140
      },
      {
        displayName: 'Versão',
        name: 'versao',
        visible: true,
        sortable: true,
        width: 90
      },
      {
        displayName: 'Área',
        name: 'laboratorio',
        visible: false,
        sortable: true
      },
      // {
      //   displayName: 'Subárea',
      //   name: 'subarea',
      //   visible: false,
      //   sortable: true
      // },
      {
        displayName: 'Cliente',
        name: 'cliente',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Submetido por',
        name: 'criadoPor',
        visible: true,
        sortable: true,
        width: 200
      },
      {
        displayName: 'Submetido a',
        name: 'criadoa',
        exportDate: 'DD-MM-YYYY',
        visible: true,
        width: 140
      }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: 'SusEneProcessoRelatorioDraft.id',
        name: 'ID SGI Relatório',
        type: 'o'
      },
      {
        id: 'SusEneProcessoRelatorioDraft.refRelatorio',
        name: 'Ref. Relatório',
        type: 'o'
      },
      {
        id: 'SusEneProcessoRelatorioDraft.tipoId',
        name: 'Tipo de Relatório',
        type: 's',
        list: this.tipos
      },
      {
        id: 'SusEneProcesso.numeroProcesso',
        name: 'Nº Processo',
        type: 'o'
      },
      {
        id: 'SusEneProcessoRelatorioDraft.versao',
        name: 'Versão',
        type: 'o'
      },
      {
        id: 'SusEneProcesso.laboratorioId',
        name: 'Área',
        type: 's',
        list: this.laboratorios
      },
      // {
      //   id: 'SusEneProcesso.subareaId',
      //   name: 'Subárea',
      //   type: 's',
      //   list: this.subareas
      // },
      {
        id: 'SusEneOrdemintervencao.clienteId',
        name: 'Cliente',
        type: 's',
        list: this.clientes
      },
      {
        id: 'SusEneProcessoRelatorioDraft.criadoPorId',
        name: 'Submetido por',
        type: 's',
        list: this.tecnicosSUSENE
      },
      {
        id: 'SusEneProcessoRelatorioDraft.criadoa',
        name: 'Submetido a',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      }
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('SUSENEGestaoRelatoriosDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('SUSENEGestaoRelatoriosDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('SUSENEGestaoRelatoriosFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('SUSENEGestaoRelatoriosFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('SUSENEGestaoRelatoriosFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'SusEneProcesso.laboratorioId')
          f.column.selected.list = this.laboratorios;
        if (f.column.selected.id === 'SusEneProcessoRelatorioDraft.tipoId')
          f.column.selected.list = this.tipos;
        if (f.column.selected.id === 'SusEneProcesso.subareaId')
          f.column.selected.list = this.subareas;
        if (f.column.selected.id === 'SusEneOrdemintervencao.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'SusEneProcesso.funcionarioId')
          f.column.selected.list = this.tecnicosSUSENE;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load state data and count for tabs
    this.listOpt = [];
    this.totalByState = [];
    this.statesLoading = true;
    this.tableLoading = true;
    this.getEstados();
  };

  clearFilter = () => {
    localStorage.removeItem('SUSENEGestaoRelatoriosFilter');
    this.customFilters = [];
    this.getDraftsRelatorios();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  getEstados = () => {
    this.statesLoading = true;
    this.SusEneEstadoRelatorio.find({
      filter: {
        where: {
          active: true,
          id: {
            inq: [1, 3] // Para aprovação, Requer Ajustes
          }
        }
      }
    }).$promise.then((res) => {
      this.estadosRelatorio = res;
      this.estadosRelatorio.forEach(e => {
        let o = angular.copy(this.defaultOpt);
        o.state = e.id;
        this.listOpt.push(o);
      });

      // stateIndex is the index of the state in the array
      this.stateIndex = this.estadosRelatorio.findIndex(e => e.id === this.stateId);
      // If we can't find the index, use the first one
      if (this.stateIndex === -1) {
        this.stateId = this.estadosRelatorio[0].id;
        this.stateIndex = 0;
      }
      this.getCountRelatoriosEstado();

    }).catch(error => {
      console.log(error);
      this.UI.addToast("De momento não é possível ver relatórios em aprovação. Verifique a ligação");
    });
  };

  // Initialize listOpt so it matches the correct states and options
  initializeListOpt = () => {
    this.listOpt = [];
    this.estadosRelatorio.forEach(e => {
      let aux = angular.copy(this.opt);
      aux.state = e.id;
      this.listOpt.push(aux);
    });
  }

  getCountRelatoriosEstado = () => {
    let tasks = [];
    this.estadosRelatorio.forEach(e => {
      let defer = this.$q.defer();
      this.SusEneProcessoRelatorioDraft.countRelatoriosEstado({
        params: {
          state: e.id
        }
      }).$promise.then((n) => {
        this.totalByState[e.id] = n.count;
        defer.resolve(e.id);
      }).catch(e => {
        defer.reject(e.id);
      });
      tasks.push(defer.promise);
    });
    this.$q.all(tasks).then((res) => {
      // If we are loading states still, we can now show the information
      if (this.statesLoading) {
        this.statesLoading = false;
        // Initialize listOpt with the different states
        this.initializeListOpt();
        this.selectTab(this.listOpt.findIndex(e => e.id === this.stateId));
      }
    }).catch(e => {
      // Do something when at least one count fails
      console.log(e);
      this.UI.addToast("De momento não é possível ver relatórios em aprovação. Verifique a ligação");
    });
  };

  selectTab = (i) => {
    if (i < 0) return;
    // this.listOpt[this.stateIndex] = angular.copy(this.opt);
    this.stateIndex = i;
    this.stateId = this.estadosRelatorio[i].id;
    this.opt = angular.copy(this.listOpt[this.stateIndex]);
    this.opt.page = 1;

    // Clear Filter when changing tab
    this.clearFilter();

    this.$state.go('app.susene.relatorios.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getDraftsRelatorios();
  };

  getDraftsRelatorios = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {
      'SusEneProcessoRelatorioDraft.active': 1,
      'SusEneProcessoRelatorioDraft.estadoId' : this.opt.state
    });

    this.SusEneProcessoRelatorioDraft.table({
      params: {
        fields: [
          'SusEneProcessoRelatorioDraft.id as id',
          'SusEneProcessoRelatorioDraft.refRelatorio as refRelatorio',
          'SusEneProcessoRelatorioDraft.criadoa as criadoa',
          // processo
          'SusEneProcessoRelatorioDraft.versao as versao',
          'SusEneLaboratorio.designacao as laboratorio',
          'SusEneLaboratorioSubarea.designacao as subarea',
          'SusEneCliente.nome as cliente',
          'Funcionario.name as criadoPor',
          'SusEneProcesso.empresaId as empresaId',
          // 'SusEneProcesso.tipoId as tipoId',
          'SusEneTipoRelatorio.designacao as tipo',
          'SusEneOrdemintervencao.clienteId as clienteId'
        ],
        from: ['SusEneProcessoRelatorioDraft', 'SusEneProcesso', 'Funcionario', 'SusEneLaboratorio', 'SusEneLaboratorioSubarea', 'SusEneOrdemintervencao', 'SusEneCliente', 'SusEneTipoRelatorio'],
        referencesOrigin: [undefined, undefined, undefined, 'SusEneLaboratorio.id', 'SusEneLaboratorioSubarea.id', 'SusEneOrdemintervencao.id', 'SusEneCliente.id', undefined],
        references: [undefined, 'SusEneProcessoRelatorioDraft.processoId', 'SusEneProcessoRelatorioDraft.criadoPorId', 'SusEneProcesso.laboratorioId', 'SusEneProcesso.subareaId', 'SusEneProcesso.oiId', 'SusEneOrdemintervencao.clienteId', 'SusEneProcessoRelatorioDraft.tipoId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      // res.data.forEach(r => {
      //   let tmp = r.tipo === "Metrologia" ? 'M' : 'E';
      //   r.processo = `${tmp}-${r.iidAno}-${("0000" + r.iidProc).slice(-4)}`;
      // });

      this.getCountRelatoriosEstado();
      this.draftsRelatorios = res.data;
      console.log(res.data);

      this.total = total;
      this.tableLoading = false;
    }).catch((e) => {
      this.tableLoading = false;
      console.log(e);
      this.UI.addToast("Não foi possível obter relatórios. Verifique a ligação e permissões");
    })
  };

  // exportProcessos = () => {
  //   let wait = this.UI.showWaiting();
  //   let whereFields = this.setWhereFields(this.customFilters, { 'SusEneProcesso.active': 1 });
  //   this.SusEneProcesso.exportProcessos({
  //     displayColumns: this.displayColumns,
  //     params: {
  //       fields: [
  //         'SusEneProcesso.id as id',
  //         'SusEneProcesso.iidAno as iidAno',
  //         'SusEneProcesso.iidProc as iidProc',
  //         'AtvEntidadeProprietaria.designacao as empresa',
  //         'SusEneTipo.descricao as tipo',
  //         'SusEneLaboratorio.designacao as laboratorio',
  //         'SusEneLaboratorioSubarea.designacao as subarea',
  //         'SusEneCliente.nome as cliente',
  //         'SusEneProcesso.percExecucao as execucao',
  //         'SusEneProcesso.ultimaAlteracao as ultimaAlteracao',
  //         'Funcionario.name as atribuidoa',
  //         'SusEneProcesso.artigo as artigo',
  //         'SusEneProcesso.norma as norma',
  //         'SusEneProcesso.natureza as natureza',
  //         'SusEneProcessoCaracteristica.value as caracteristica',
  //         'SusEneProcesso.acreditado as acreditado',
  //         'SusEneProcesso.certificado as certificado',
  //         'SusEneProcesso.inSitu as inSitu',
  //         'SusEneProcesso.subcontratacao as subcontratacao',
  //         'SusEneProcesso.email as email'
  //       ],
  //       from: ['SusEneProcesso', 'SusEneTipo', 'SusEneLaboratorio', 'SusEneOrdemintervencao', 'SusEneCliente', 'SusEneLaboratorioSubarea', 'Funcionario', 'SusEneProcessoCaracteristica', 'AtvEntidadeProprietaria'],
  //       referencesOrigin: [],
  //       references: [undefined, 'SusEneProcesso.tipoId', 'SusEneProcesso.laboratorioId', 'SusEneProcesso.oiId', 'SusEneOrdemintervencao.clienteId', 'SusEneProcesso.subareaId', 'SusEneProcesso.funcionarioId', 'SusEneProcesso.caracteristicaId', 'SusEneProcesso.empresaId'],
  //       aliases: [],
  //       where: whereFields.where,
  //       whereLiteral: whereFields.whereLiteral,
  //       whereDates: whereFields.whereDates,
  //       whereOr: whereFields.whereOr,
  //       whereOrLiteral: whereFields.whereOrLiteral,
  //       order: this.opt.order,
  //       sort: this.opt.sort,
  //       // limit: this.opt.items,
  //       // skip: (this.opt.page - 1) * this.opt.items,
  //     }
  //   }).$promise.then(res => {
  //     if (res.count) {
  //       wait.close();
  //       let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
  //       let b = document.createElement('a');
  //       b.href = url;
  //       b.download = 'processosExportados.xlsx';
  //       b.click();
  //     } else {
  //       this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
  //     }
  //   }).catch((e) => {
  //     wait.close();
  //     console.log(e);
  //     this.tableLoading = false;
  //     this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
  //   });
  // };
  //
  // exportSelected = () => {
  //   let wait = this.UI.showWaiting();
  //   let orsLiteral = [];
  //   // if a few processos selected only
  //   if (this.hasSelect() && !this.everythingSelected)
  //     orsLiteral = _.map(_.filter(this.draftsRelatorios, x => x.selected), x => {
  //       return { key: 'SusEneProcesso.id', value: x.id };
  //     });
  //   let whereFields = this.setWhereFields(this.customFilters, { 'SusEneProcesso.active': 1 }, orsLiteral);
  //   this.SusEneProcesso.exportProcessos({
  //     displayColumns: this.displayColumns,
  //     params: {
  //       fields: [
  //         'SusEneProcesso.id as id',
  //         'SusEneProcesso.iidAno as iidAno',
  //         'SusEneProcesso.iidProc as iidProc',
  //         'AtvEntidadeProprietaria.designacao as empresa',
  //         'SusEneTipo.descricao as tipo',
  //         'SusEneLaboratorio.designacao as laboratorio',
  //         'SusEneLaboratorioSubarea.designacao as subarea',
  //         'SusEneCliente.nome as cliente',
  //         'SusEneProcesso.percExecucao as execucao',
  //         'SusEneProcesso.ultimaAlteracao as ultimaAlteracao',
  //         'Funcionario.name as atribuidoa',
  //         'SusEneProcesso.artigo as artigo',
  //         'SusEneProcesso.norma as norma',
  //         'SusEneProcesso.natureza as natureza',
  //         'SusEneProcessoCaracteristica.value as caracteristica',
  //         'SusEneProcesso.acreditado as acreditado',
  //         'SusEneProcesso.certificado as certificado',
  //         'SusEneProcesso.inSitu as inSitu',
  //         'SusEneProcesso.subcontratacao as subcontratacao',
  //         'SusEneProcesso.email as email'
  //       ],
  //       from: ['SusEneProcesso', 'SusEneTipo', 'SusEneLaboratorio', 'SusEneOrdemintervencao', 'SusEneCliente', 'SusEneLaboratorioSubarea', 'Funcionario', 'SusEneProcessoCaracteristica', 'AtvEntidadeProprietaria'],
  //       referencesOrigin: [],
  //       references: [undefined, 'SusEneProcesso.tipoId', 'SusEneProcesso.laboratorioId', 'SusEneProcesso.oiId', 'SusEneOrdemintervencao.clienteId', 'SusEneProcesso.subareaId', 'SusEneProcesso.funcionarioId', 'SusEneProcesso.caracteristicaId', 'SusEneProcesso.empresaId'],
  //       aliases: [],
  //       where: whereFields.where,
  //       whereLiteral: whereFields.whereLiteral,
  //       whereDates: whereFields.whereDates,
  //       whereOr: whereFields.whereOr,
  //       whereOrLiteral: whereFields.whereOrLiteral,
  //       order: this.opt.order,
  //       sort: this.opt.sort,
  //       // limit: this.opt.items,
  //       // skip: (this.opt.page - 1) * this.opt.items,
  //     }
  //   }).$promise.then(res => {
  //     if (res.count) {
  //       wait.close();
  //       let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
  //       let b = document.createElement('a');
  //       b.href = url;
  //       b.download = 'processosExportados.xlsx';
  //       b.click();
  //     } else {
  //       this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
  //     }
  //   }).catch((e) => {
  //     wait.close();
  //     console.log(e);
  //     this.tableLoading = false;
  //     this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
  //   });
  // };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('SUSENEGestaoRelatoriosDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  loadData = () => {
    this.SusEneLaboratorio.find({filter: {where: {active: true}, order: 'designacao ASC'}}).$promise.then(labs => {
      labs.forEach(t => {
        t.name = t.designacao;
      });
      this.laboratorios = labs;
      this.filtersLoaded[0] = 1;
    }).catch(e => {
      console.log(e);
      this.laboratorios = [];
      this.filtersLoaded[0] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Laboratórios).");
    });

    this.SusEneLaboratorioSubarea.find({filter: {where: {active: true}, order: 'designacao ASC'}}).$promise.then(subareas => {
      subareas.forEach(sb => {
        sb.name = sb.designacao;
      });
      this.subareas = subareas;
      this.filtersLoaded[1] = 1;
    }).catch(e => {
      console.log(e);
      this.subareas = [];
      this.filtersLoaded[1] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Subáreas).");
    });

    this.SusEneCliente.find({filter: {where: {active: true}, order: 'nome ASC'}}).$promise.then(clientes => {
      clientes.forEach(t => {
        t.name = t.nome;
      });
      this.clientes = clientes;
      this.filtersLoaded[2] = 1;
    }).catch(e => {
      console.log(e);
      this.clientes = [];
      this.filtersLoaded[2] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Clientes).");
    });

    this.Group.findOne({filter: { where: { name: {like: 'Tecnico%SUSENE'}}, include: {relation: 'usergroup', scope: {include: 'user'}}}}).$promise.then((g) => {
      let tecnicosSUSENE = [];
      g.usergroup.forEach(u => {
        tecnicosSUSENE.push(u.user);
      });
      this.tecnicosSUSENE = _.orderBy(tecnicosSUSENE, 'name', 'asc');
      this.filtersLoaded[3] = 1;
    }).catch(e => {
      console.log(e);
      this.tecnicosSUSENE = [];
      this.filtersLoaded[3] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Técnicos).");
    });

    this.SusEneTipoRelatorio.find({filter: {where: {active: true}, order: 'designacao ASC'}}).$promise.then(tipos => {
      tipos.forEach(t => {
        t.name = t.designacao;
      });
      this.tipos = tipos;
      this.filtersLoaded[4] = 1;
    }).catch(e => {
      console.log(e);
      this.tipos = [];
      this.filtersLoaded[4] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Tipos de Relatório).");
    });
  };


  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.susene.relatorios.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getDraftsRelatorios();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.susene.relatorios.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getDraftsRelatorios();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.susene.relatorios.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getDraftsRelatorios();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.laboratorios.length && !this.subareas.length && !this.clientes.length &&
      !this.tecnicosSUSENE.length && !this.tipos.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'SusEneProcesso.laboratorioId')
        f.list = this.laboratorios;
      if (f.id === 'SusEneProcessoRelatorioDraft.tipoId')
        f.list = this.tipos;
      if (f.id === 'SusEneProcesso.subareaId')
        f.list = this.subareas;
      if (f.id === 'SusEneOrdemintervencao.clienteId')
        f.list = this.clientes;
      if (f.id === 'SusEneProcesso.funcionarioId')
        f.list = this.tecnicosSUSENE;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'SusEneProcesso.laboratorioId')
          f.column.selected.list = this.laboratorios;
        if (f.column.selected.id === 'SusEneProcessoRelatorioDraft.tipoId')
          f.column.selected.list = this.tipos;
        if (f.column.selected.id === 'SusEneProcesso.subareaId')
          f.column.selected.list = this.subareas;
        if (f.column.selected.id === 'SusEneOrdemintervencao.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'SusEneProcesso.funcionarioId')
          f.column.selected.list = this.tecnicosSUSENE;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('SUSENEGestaoRelatoriosFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.susene.relatorios.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getDraftsRelatorios();
  };

  // hasSelect = () => {
  //   return _.some(this.draftsRelatorios, a => a.selected === true);
  // };

  // selectAll = () => {
  //   if (this.allSelected) { // Not all are selected, select all
  //     this.draftsRelatorios.forEach(a => {
  //       a.selected = true;
  //     });
  //     this.nSelected = this.draftsRelatorios.length;
  //   } else { // Remove all selections
  //     this.draftsRelatorios.forEach(a => {
  //       a.selected = false;
  //     });
  //     this.nSelected = 0;
  //   }
  //   this.everythingSelected = false;
  // };

  // selectEverything = (type) => {
  //   if (!type) {
  //     this.draftsRelatorios.forEach(a => {
  //       a.selected = false;
  //     });
  //     this.nSelected = 0;
  //     this.allSelected = false;
  //   }
  //   this.everythingSelected = !!type;
  // };
  //
  // selectItem = () => {
  //   this.nSelected = _.filter(this.draftsRelatorios, r => r.selected).length;
  //   this.allSelected = this.nSelected >= this.draftsRelatorios.length;
  //   this.everythingSelected = false;
  // };

  // Go to evaluation details view
  evaluateReport = r => {

  };

  viewProcess = p => {
    this.$state.go('app.susene.processes.details', {
      id: this.$filter('suseneFormat')(p)
    });
  };

}

SusEneDirectoryRelatoriosController.$inject = ['$q', '$filter', '$state', 'UIService', 'AuthenticationService', 'Group', 'SusEneProcessoRelatorioDraft', 'SusEneProcesso', 'SusEneLaboratorio', 'SusEneLaboratorioSubarea', 'Funcionario', 'SusEneCliente', 'SusEneEstadoRelatorio', 'SusEneTipoRelatorio'];
