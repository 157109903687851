import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Checkbox, CircularProgress, Fab, Grid, IconButton, Link, Stack, TextField, Typography } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { MdiIcon } from '@components';
import { mdiContentSave } from '@mdi/js';
const CrmEmpresasAddView = ({ $state, CrmService }) => {
    const [diableForm, setDisableForm] = useState(true);
    const [nome, setNome] = useState('');
    const [empresa, setEmpresa] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [image, setImage] = useState(null);
    const [newImage, setNewImage] = useState(null);
    // redes sociais
    // Parse initial social media links from JSON if available
    const initialSocialMediaLinks = editing && empresa?.redesSociais ? JSON.parse(empresa.redesSociais) : {};
    const [socialMediaLinks, setSocialMediaLinks] = useState(initialSocialMediaLinks);
    const fetchData = async () => {
        try {
            const getEmpresa = await CrmService.findEmpresaById($state.params.id);
            setEmpresa(getEmpresa);
        }
        catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        if ($state.params.id != undefined) {
            if (empresa == null) {
                setEditing(true);
                fetchData();
            }
            if (empresa) {
                if (empresa.imagem) {
                    let imageUrl = `/api/Upload/crm/download/${empresa.imagem}`;
                    setImage(imageUrl); // Define a imagem existente
                }
                const parsedLinks = JSON.parse(empresa.redesSociais || '{}');
                setSocialMediaLinks(parsedLinks);
                setNome(empresa.nome);
                setIsLoading(false);
                setNome(empresa.nome);
                setIsLoading(false);
            }
        }
        else {
            setIsLoading(false);
        }
    }, [empresa]);
    const socialMediaIcons = [
        { src: 'assets/images/crm_facebook.png', alt: 'Facebook', key: 'facebook' },
        { src: 'assets/images/crm_linkedin.png', alt: 'LinkedIn', key: 'linkedin' },
        { src: 'assets/images/crm_twitter.png', alt: 'Twitter', key: 'twitter' },
        { src: 'assets/images/crm_instagram.png', alt: 'Instagram', key: 'instagram' },
        { src: 'assets/images/crm_youtube.png', alt: 'YouTube', key: 'youtube' }
    ];
    const validationSchema = yup.object().shape({
        nome: yup.string().required('Preenchimento obrigatório').min(2, 'O nome deve ter pelo menos 2 caracteres').max(255, 'O nome deve ter no máximo 255 caracteres'),
        nif: yup.string().required('Preenchimento obrigatório'),
        email: yup.string().required('Preenchimento obrigatório').email('Formato de email inválido'),
        cae: yup.string().matches(/^\d{5}$/, 'O CAE deve conter exatamente 5 dígitos'),
        contactoTelefonico: yup.string().matches(/^\d{9,15}$/, 'O contacto telefónico deve ter entre 9 e 15 dígitos'),
        saldo: yup
            .number()
            .typeError('O saldo deve ser um número') // Garante que o valor seja um número
            .test('max-decimals', 'O saldo deve ter no máximo 2 casas decimais', value => value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString())) // Valida até 2 casas decimais
            .test('max-digits', 'O saldo deve ter no máximo 11 números', value => value === undefined || value.toString().replace('.', '').length <= 11) // Valida até 11 dígitos no total
    });
    const formik = useFormik({
        initialValues: {
            nome: editing && empresa ? empresa.nome : '',
            nif: editing && empresa ? empresa.nif : '',
            email: editing && empresa ? empresa.email : '',
            contactoTelefonico: editing && empresa ? empresa.contactoTelefonico : '',
            morada: editing && empresa ? empresa.morada : '',
            codigoPostal: editing && empresa ? empresa.codigoPostal : '',
            localidade: editing && empresa ? empresa.localidade : '',
            observacoes: editing && empresa ? empresa.observacoes : '',
            cae: editing && empresa ? empresa.cae : '',
            id: editing && empresa ? empresa.id : 0,
            uuid: editing && empresa ? empresa.uuid : '',
            refClientePrimavera: editing && empresa ? empresa.refClientePrimavera : '',
            imagem: editing && empresa ? empresa.imagem : '',
            setor: editing && empresa ? empresa.setor : '',
            condicoesPagamento: editing && empresa ? empresa.condicoesPagamento : '',
            saldo: editing && empresa ? empresa.saldo : 0
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            values.redesSociais = JSON.stringify(socialMediaLinks);
            submit(values);
        }
    });
    const handleImage = async (values) => {
        const formData = new FormData();
        if (newImage.file) {
            const newFileName = values.imagem ? values.imagem : newImage.file.name;
            formData.append('file', newImage.file, newFileName);
            // Se a imagem for igual, não realiza upload
            if (newImage.dummy) {
                return values;
            }
            else {
                // Atualiza o valor da imagem no objeto `values`
                values.imagem = newFileName;
                try {
                    // Upload da nova imagem
                    const response = await fetch('/api/Upload/crm/upload', {
                        method: 'POST',
                        body: formData
                    });
                    if (response.ok) {
                        const content = await response.json();
                        return content.result;
                    }
                    else {
                        console.log(response);
                        console.error('Erro ao adicionar imagem');
                    }
                }
                catch (error) {
                    console.error('Error uploading file:', error);
                }
            }
        }
        else {
            return values;
        }
    };
    const handleSocialMediaChange = (key, value) => {
        setSocialMediaLinks(prev => {
            const updatedLinks = { ...prev };
            if (!value.trim()) {
                delete updatedLinks[key]; // Remove a rede social se o valor for vazio
            }
            else {
                updatedLinks[key] = value; // Atualiza ou adiciona o valor da rede social
            }
            return updatedLinks;
        });
    };
    const submit = async (newValues) => {
        if (newImage == null) {
            newValues.imagem = '';
        }
        else {
            handleImage(newValues);
        }
        try {
            const emp = await CrmService.upsertEmpresa(newValues);
            $state.go('app.crm.empresas.details', { id: emp.id });
        }
        catch (error) {
            console.log(error);
        }
    };
    const handleTextInput = (event) => {
        const { id, value } = event.target;
        if (event.target.id == 'nome') {
            setNome(event.target.value);
        }
        formik.setFieldValue(id, value);
    };
    return (<Box sx={{ pl: 1, pr: 1 }}>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mt: 2 }}>
        <Link underline='hover' color='inherit' href='/'>
          <Typography variant='body2'>CRM</Typography>
        </Link>
        <Link underline='hover' color='inherit' href={'/crm/empresas'}>
          <Typography variant='body2'>Clientes</Typography>
        </Link>
        <Link underline='hover' color='inherit'>
          <Typography variant='body2'>Adicionar Cliente</Typography>
        </Link>
      </Breadcrumbs>

      {isLoading ? (<Box sx={{ display: 'flex', justifyContent: 'center', height: '2vh' }}>
          <CircularProgress size={60} color='primary'/>
        </Box>) : (<Box>
          <form onSubmit={formik.handleSubmit}>
            <Typography variant='h4' sx={{ color: 'black', mt: 1 }}>
              {editing ? 'A editar ' + nome : nome === '' ? 'Criar novo Cliente' : 'A criar ' + nome}
            </Typography>

            <TextField fullWidth id='cliente' label='Cliente' color='info' size='small' sx={{ bgcolor: '#F5F5F5', mt: 1 }}/>

            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography variant='h6' sx={{ color: 'black', mb: 1 }}>
                    Informação base
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography variant='h6' sx={{ color: 'black', mb: 1, ml: 1 }}>
                    {editing ? (empresa.imagem ? 'Alterar' : 'Adicionar') : 'Adicionar'} imagem
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                  <Stack spacing={2}>
                    <TextField fullWidth disabled={diableForm} id='nome' label='Nome' required color='info' onChange={handleTextInput} size='small' value={formik.values.nome} error={Boolean(formik.errors.nome)} helperText={formik.touched.nome && formik.errors.nome} sx={{ bgcolor: '#F5F5F5' }}/>
                    <TextField fullWidth disabled={diableForm} id='morada' label='Morada' color='info' size='small' value={formik.values.morada} error={Boolean(formik.errors.morada)} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>
                    <TextField fullWidth disabled={diableForm} id='contactoTelefonico' label='Contacto Telefónico' color='info' size='small' value={formik.values.contactoTelefonico} error={Boolean(formik.errors.contactoTelefonico)} helperText={formik.touched.contactoTelefonico && formik.errors.contactoTelefonico} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>
                    <TextField fullWidth disabled={diableForm} required id='email' label='Email' color='info' size='small' value={formik.values.email} error={Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>
                    <TextField fullWidth disabled={diableForm} id='setor' label='setor' color='info' size='small' value={formik.values.setor} onChange={handleTextInput} error={Boolean(formik.errors.setor)} sx={{ bgcolor: '#F5F5F5' }}/>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Stack spacing={2}>
                    <TextField fullWidth disabled={diableForm} required id='nif' label='NIF' color='info' size='small' sx={{ bgcolor: '#F5F5F5' }} value={formik.values.nif} error={Boolean(formik.errors.nif)} helperText={formik.touched.nif && formik.errors.nif} onChange={handleTextInput}/>
                    <TextField fullWidth disabled={diableForm} id='cae' label='CAE' color='info' size='small' value={formik.values.cae} error={Boolean(formik.errors.cae)} helperText={formik.touched.cae && formik.errors.cae} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>
                    <TextField fullWidth disabled={diableForm} id='saldo' label='Saldo' color='info' size='small' value={formik.values.saldo} onChange={handleTextInput} error={Boolean(formik.errors.saldo)} helperText={formik.touched.saldo && formik.errors.saldo} sx={{ bgcolor: '#F5F5F5' }}/>
                    <TextField fullWidth disabled={diableForm} id='condicoesPagamento' label='Condições de pagamento' color='info' size='small' value={formik.values.condicoesPagamento} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>
                    <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                      <Typography sx={{ color: 'black' }}> Bloqueado </Typography>
                      <Checkbox disabled={diableForm}/>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box>
                    <DropzoneArea acceptedFiles={['image/jpeg', 'image/png']} initialFiles={image ? [image] : []} // Usa a imagem carregada da empresa ou deixa vazio
         onChange={files => {
                if (files.length > 0) {
                    let t = files.pop();
                    let f = new File([t], `${v4()}.${t.name.split('.').pop()}`, { type: t.type });
                    setNewImage({ file: f, dummy: false });
                    setImage(URL.createObjectURL(f));
                }
                else {
                    setNewImage(null);
                    setImage(null);
                }
            }} showFileNames={false} showAlerts={false} showPreviewsInDropzone={true} 
        //useChipsForPreview={true}
        dropzoneText='Arraste e solte a imagem aqui ou clique' filesLimit={1}/>
                  </Box>
                </Grid>

                <Grid item xs={12} md={12} sx={{ mb: 1 }}>
                  <TextField fullWidth disabled={diableForm} multiline minRows={2} maxRows={4} id='observacoes' label='Observações' color='info' size='small' value={formik.values.observacoes} onChange={handleTextInput} sx={{ bgcolor: '#F5F5F5' }}/>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography variant='h6' sx={{ color: 'black', mb: 2 }}>
                Redes sociais
              </Typography>

              <Grid container spacing={2}>
                {socialMediaIcons.map((icon, index) => (<Grid item xs={12} sm={6} md={4} key={index} display='flex' alignItems='center'>
                    <Box component='img' src={icon.src} alt={icon.alt} sx={{
                    maxWidth: '22px',
                    maxHeight: '22px',
                    width: 'auto',
                    height: 'auto'
                }}/>
                    <TextField disabled={diableForm} label={`Insira o link da página do ${icon.alt}`} variant='outlined' fullWidth size='small' sx={{ ml: 2, bgcolor: '#F5F5F5' }} value={socialMediaLinks[icon.key] || ''} onChange={e => handleSocialMediaChange(icon.key, e.target.value)}/>
                  </Grid>))}
              </Grid>
            </Box>
          </form>
          <Fab aria-label='add' color='error' sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                zIndex: 1000
            }}>
            <IconButton onClick={() => formik.handleSubmit()}>
              <MdiIcon path={mdiContentSave} sx={{ color: '#ffffff' }}/>
            </IconButton>
          </Fab>
        </Box>)}
    </Box>);
};
export default CrmEmpresasAddView;
