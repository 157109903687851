export default class PadProcessoCreateController {
  constructor($rootScope, $q, $state, $scope, AuthenticationService, UIService, PadService, PadConfig, FileUploader, AtvEntidadeProprietaria, PadCentroDecisao, PadProcesso, PadLinhaProcesso, PadUrgencia, PadTipoDespesa, PadProcessoFornecedor, PadProcessoAnexo, PadProcessoEstado, PadCentroCusto, PadLinhaProcessoEstado, PadProjeto, PadArtigo, PadCriterio, PadAnexoPendente, PRIIEPPlanocentros, PRIAJLPlanocentros, PRIOBLERPlanocentros, PRIIEPArtigo, PRIAJLArtigo, PRIOBLERArtigo, PRIIEPFornecedores, PRIAJLFornecedores, PRIOBLERFornecedores) {
    this.$q = $q;
    this.state = $state;
    this.$scope = $scope;
    this.UI = UIService;
    this.user = AuthenticationService.getUser();
    this.PadService = PadService;
    this.PadConfig = PadConfig;
    this.FileUploader = FileUploader;
    this.loaded = false;

    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.PadCentroDecisao = PadCentroDecisao;
    this.PadProcesso = PadProcesso;
    this.PadLinhaProcesso = PadLinhaProcesso;
    this.PadUrgencia = PadUrgencia;
    this.PadTipoDespesa = PadTipoDespesa;
    this.PadProcessoFornecedor = PadProcessoFornecedor;
    this.PadProcessoAnexo = PadProcessoAnexo;
    this.PadProcessoEstado = PadProcessoEstado;
    this.PadCentroCusto = PadCentroCusto;
    this.PadLinhaProcessoEstado = PadLinhaProcessoEstado;
    this.PadProjeto = PadProjeto;
    this.PadArtigo = PadArtigo;
    this.PadCriterio = PadCriterio;
    this.PadAnexoPendente = PadAnexoPendente;
    this.PRIIEPPlanocentros = PRIIEPPlanocentros;
    this.PRIAJLPlanocentros = PRIAJLPlanocentros;
    this.PRIOBLERPlanocentros = PRIOBLERPlanocentros;
    this.PRIIEPArtigo = PRIIEPArtigo;
    this.PRIAJLArtigo = PRIAJLArtigo;
    this.PRIOBLERArtigo = PRIOBLERArtigo;
    this.PRIIEPFornecedores = PRIIEPFornecedores;
    this.PRIAJLFornecedores = PRIAJLFornecedores;
    this.PRIOBLERFornecedores = PRIOBLERFornecedores;

    // By default, envURL is production (to account for fails)
    this.envURL = "https://inspecoeseletricas.pt";

    // Get environment for URL for files
    // Doing this here only because we only use this.envURL in the view
    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      if (data != null && data.environment != null) {
        let env = data.environment;
        if (env === 'staging') {
          this.envURL = "https://staging-ie.iep.pt"
        } else if (env === 'production') { // For completeness
          this.envURL = "https://inspecoeseletricas.pt";
        } else {
          this.envURL = "https://dev-iep-ie.streamline.pt";
        }
      }
    });


    this.simnao = [{ name: "Sim", value: 1 }, { name: "Não", value: 0 }];
    this.entidadesProprietarias = [];
    this.centrosDecisaoGlobal = []; // Used to contain everything
    this.centrosDecisao = []; // Subset for EP
    this.urgencias = [];
    this.tiposDespesas = [];
    this.fornecedores = [];

    this.emptyData = {
      form: 1,
      details: {},

      linhas: [],
      fornecedores: [],
      auxEntidadeProprietariaForm: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } },
      auxEntidadeProprietaria: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } },
      auxCentroDecisao: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } },
      auxUrgencia: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } },
      auxTipoDespesa: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } },
      auxTipoDespesaForm: { selected: undefined, infiniteScroll: { numToAdd: 20, currentItems: 20 } }
    };

    // Load data to fill ui-selects
    this.loadAuxData();

    $scope.$watch(() => {
      return this.data;
    }, (val) => {
      if (angular.isDefined(val)) {
        localStorage.setItem('PADProcessoNewForm', JSON.stringify(val));
      }
    }, true);
  }

  loadAuxData = () => {
    this.AtvEntidadeProprietaria.find({ filter: { where: { active: 1 }, order: 'id ASC' } }).$promise.then((entidadesProprietarias) => {
      this.entidadesProprietarias = entidadesProprietarias;
      this.PadCentroDecisao.find({ filter: { where: { active: 1 }, order: 'designacao ASC, entidadeProprietariaId ASC' } }).$promise.then((centrosDecisaoGlobal) => {
        this.centrosDecisaoGlobal = centrosDecisaoGlobal;
        this.PadUrgencia.find({}).$promise.then((urgencias) => {
          this.urgencias = urgencias;
          this.PadTipoDespesa.find({}).$promise.then((tiposDespesas) => {
            this.tiposDespesas = tiposDespesas;
            this.loadDataFromStorage();
            this.loaded = true;
          }).catch(err => {
            console.log(err);
            this.UI.addToast("Erro ao carregar tipos de despesas. Verifique a sua ligação");
            this.state.go('app.pad.processo.list');
          });
        }).catch(err => {
          console.log(err);
          this.UI.addToast("Erro ao carregar tipos de urgências. Verifique a sua ligação");
          this.state.go('app.pad.processo.list');
        });
      }).catch(err => {
        console.log(err);
        this.UI.addToast("Erro ao carregar centros de decisão. Verifique a sua ligação");
        this.state.go('app.pad.processo.list');
      });
    }).catch(err => {
      console.log(err);
      this.UI.addToast("Erro ao carregar entidades proprietárias. Verifique a sua ligação");
      this.state.go('app.pad.processo.list');
    });
  };


  // Infinite Scroll magic
  addMoreItems = (infiniteScroll) => {
    infiniteScroll.currentItems += infiniteScroll.numToAdd;
  };

  loadDataFromStorage = () => {
    if (localStorage.getItem('PADProcessoNewForm')) {
      let storageData = JSON.parse(localStorage.getItem('PADProcessoNewForm'));
      // Only use what is required
      this.data = {};
      if (storageData.form) this.data.form = storageData.form; else this.data.form = angular.copy(this.emptyData.form);
      if (storageData.details) this.data.details = storageData.details; else this.data.details = angular.copy(this.emptyData.details);

      if (storageData.linhas) this.data.linhas = storageData.linhas; else this.data.linhas = angular.copy(this.emptyData.linhas);
      if (storageData.fornecedores) this.data.fornecedores = storageData.fornecedores; else this.data.fornecedores = angular.copy(this.emptyData.fornecedores);

      if (storageData.auxEntidadeProprietariaForm) this.data.auxEntidadeProprietariaForm = storageData.auxEntidadeProprietariaForm; else this.data.auxEntidadeProprietariaForm = angular.copy(this.emptyData.auxEntidadeProprietariaForm);
      if (storageData.auxEntidadeProprietaria) this.data.auxEntidadeProprietaria = storageData.auxEntidadeProprietaria; else this.data.auxEntidadeProprietaria = angular.copy(this.emptyData.auxEntidadeProprietaria);
      if (storageData.auxCentroDecisao) this.data.auxCentroDecisao = storageData.auxCentroDecisao; else this.data.auxCentroDecisao = angular.copy(this.emptyData.auxCentroDecisao);
      if (storageData.auxUrgencia) this.data.auxUrgencia = storageData.auxUrgencia; else this.data.auxUrgencia = angular.copy(this.emptyData.auxUrgencia);
      if (storageData.auxTipoDespesa) this.data.auxTipoDespesa = storageData.auxTipoDespesa; else this.data.auxTipoDespesa = angular.copy(this.emptyData.auxTipoDespesa);
      if (storageData.auxTipoDespesaForm) this.data.auxTipoDespesaForm = storageData.auxTipoDespesaForm; else this.data.auxTipoDespesaForm = angular.copy(this.emptyData.auxTipoDespesaForm);

      // If EP was already filled in, we need to use the correct centrosDecisao list
      if (this.data.auxEntidadeProprietaria.selected) {
        this.centrosDecisao = this.centrosDecisaoGlobal.filter(x => x.entidadeProprietariaId === this.data.auxEntidadeProprietaria.selected.id); // Select proper centros
      }

    } else {
      this.data = angular.copy(this.emptyData);
    }
    this.state.transitionTo('app.pad.processo.new.' + this.data.form);
  };

  setForm = num => {
    this.data.form = num;
    this.state.transitionTo('app.pad.processo.new.' + this.data.form);
  };

  cleanForm = () => {
    // Clean filled data
    this.data = angular.copy(this.emptyData);
    this.state.transitionTo('app.pad.processo.new.' + this.data.form);
  };

  // Create processo and initial state
  createProcesso = (details) => {
    let defer = this.$q.defer();
    details.estadoId = 1; // Criação
    this.PadProcesso.create(details).$promise.then((p) => {
      this.PadProcessoEstado.create({
        id: 0,
        processoId: p.id,
        estadoId: 1,
        data: moment().format(),
        criadoPorId: this.user.id,
        observacoes: 'Criado'
      }).$promise.then((estado) => {
        defer.resolve(p);
      }).catch(error => {
        defer.reject(error);
      });
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };

  // Calculate total of linhas de processo to put in processo total
  getTotalLinhas = () => {
    let total = 0;
    this.data.linhas.forEach(linha => {
      if (!isNaN(linha.precoUnitarioOrcamentado) && !isNaN(linha.quantidade)) {
        total += Math.round(linha.precoUnitarioOrcamentado * linha.quantidade * 100) / 100;
      }
    });
    return total;
  };

  submit = () => {
    this.isValid().then(() => {
      this.loaded = false;
      // Create PadProcesso and related data
      this.data.details.id = 0;
      this.data.details.total = this.getTotalLinhas(); // Fill now because this is the total of linhas to be created
      this.data.details.entidadeProprietariaId = this.data.auxEntidadeProprietaria.selected.id;
      this.data.details.centroDecisaoId = this.data.auxCentroDecisao.selected.id;
      this.data.details.tipoDespesaId = this.data.auxTipoDespesa.selected.id;
      this.data.details.urgenciaId = this.data.auxUrgencia.selected.id;

      this.data.details.criadoPorId = this.user.id;
      this.data.details.data = moment().format('YYYY-MM-DD');

      // Create Processo and initial state
      this.createProcesso(this.data.details).then((processo) => {

        // Create all Linhas de Processo
        let deferLinhas = this.$q.defer();
        this.PadService.createLinhasProcesso(this.data.linhas, processo.id, processo.entidadeProprietariaId).then((x) => {
          deferLinhas.resolve({ ok: true });
        }).catch(err => {
          deferLinhas.resolve({ ok: false, output: 'Linhas de Processo' });
        });

        // Create all Fornecedores
        let deferFornecedores = this.$q.defer();
        this.PadService.createFornecedores(this.data.fornecedores, processo.id, processo.entidadeProprietariaId).then(x => {
          deferFornecedores.resolve({ ok: true });
        }).catch(err => {
          deferFornecedores.resolve({ ok: false, output: 'Fornecedores' }); // Resolve data is solved inside since it's multi-answer
        });

        let promises = [deferLinhas.promise, deferFornecedores.promise];

        // After all multiple data information is created/settled
        this.$q.all(promises).then((res) => {
          this.UI.addToast("Processo criado com sucesso");

          this.data = angular.copy(this.emptyData);
          localStorage.removeItem('PADProcessoNewForm');

          let error = this.createdMultipleDataOk(res);
          if (error) {
            console.log(error);
            let errorMessage = "Processo criado, mas ocorreram erros a inserir os seguintes dados: " + error + ".";
            alert = this.UI.showAlert(errorMessage);
            alert.finally(() => {
              // Redirect to details view so it can be decided what to do
              this.state.go('app.pad.processo.details', { id: processo.id });
            });
          } else {
            // Everything was created ok, change state to 2
            this.PadProcessoEstado.create({
              id: 0,
              processoId: processo.id,
              estadoId: 2,
              data: moment().format(),
              criadoPorId: this.user.id,
              observacoes: 'Aguarda aprovação Nível 1'
            }).$promise.then(() => {
              // Change state in processo object - We need to find it because it may have changed
              this.PadProcesso.findById({ id: processo.id }).$promise.then(r => {
                r.estadoId = 2;
                this.PadProcesso.upsert(r).$promise.then(() => {
                  // Notify Nível 1 of new processo to approve
                  this.PadProcesso.initialProcessNotification({ id: processo.id }).$promise.then(() => {
                    // Go to list after all is done
                    this.state.go('app.pad.processo.list');
                  }).catch(error => {
                    console.log(error);
                    this.UI.addToast("Não foi possível notificar avaliador Nível 1.");
                  });
                }).catch((error) => {
                  console.log(error);
                  alert = this.UI.showAlert("Processo criado, mas não foi possível alterar o seu estado inicial.");
                  alert.finally(() => {
                    // Redirect to details view so it can be filled
                    this.state.go('app.pad.processo.list');
                  });
                });
              }).catch((error) => {
                console.log(error);
                alert = this.UI.showAlert("Processo criado, mas não foi possível alterar o seu estado inicial.");
                alert.finally(() => {
                  // Redirect to details view so it can be filled
                  this.state.go('app.pad.processo.list');
                });
              });
            }).catch(error => {
              console.log(error);
              alert = this.UI.showAlert("Processo criado, mas não foi possível alterar o seu estado inicial.");
              alert.finally(() => {
                // Redirect to details view so it can be filled
                this.state.go('app.pad.processo.list');
              });
            });
          }
        }).catch(err => { // Should never happen, since resolving everything...
          console.log(err);
          this.data = angular.copy(this.emptyData);
          localStorage.removeItem('PADProcessoNewForm');
          alert = this.UI.showAlert("Processo criado, mas ocorreram erros a inserir alguns dados. Por favor verifique e adicione-os manualmente");
          alert.finally(() => {
            // Redirect to details view so it can be filled
            this.state.go('app.pad.processo.details', { id: processo.id });
          });
        });
      }).catch((error) => {
        console.log(error);
        this.UI.showAlert("Erro ao criar processo. Verifique a ligação");
        this.loaded = true;
      });
    }).catch((x) => {
      // Nothing to do. Just fix values and resubmit...
    });
  };


  // Check if there was an error adding multiple data, and return a string with the bad inserted data
  createdMultipleDataOk = (res) => {
    let output = '';
    if (res && res.length > 0) {
      res.forEach(r => {
        if (!r.ok) output += r.output + ", ";
      });
      // If there's data, remove trailing ", "
      if (output.length > 0)
        return output.slice(0, -2);
    }
    return output.length === 0 ? null : output;
  };


  isValid = () => {
    let validDefer = this.$q.defer();
    let v = true;

    // Check if processo data is well defined
    // Designação
    if (v && (!this.data.details.designacao || this.data.details.designacao.length === 0)) {
      this.UI.addToast("Designação não preenchida");
      v = false;
      validDefer.reject();
    }

    // Justificação
    if (v && (!this.data.details.justificacao || this.data.details.justificacao.length === 0)) {
      this.UI.addToast("Justificação não preenchida");
      v = false;
      validDefer.reject();
    }

    // Entidade Proprietária
    if (v && (!this.data.auxEntidadeProprietaria || !this.data.auxEntidadeProprietaria.selected)) {
      this.UI.addToast("Entidade Proprietária inválida");
      v = false;
      validDefer.reject();
    }

    // Centro de Decisão
    if (v && (!this.data.auxCentroDecisao || !this.data.auxCentroDecisao.selected)) {
      this.UI.addToast("Centro de Decisão inválido");
      v = false;
      validDefer.reject();
    }

    // Tipo de Despesa
    if (v && (!this.data.auxTipoDespesa || !this.data.auxTipoDespesa.selected)) {
      this.UI.addToast("Tipo de Despesa inválido");
      v = false;
      validDefer.reject();
    }

    // Urgência
    if (v && (!this.data.auxUrgencia || !this.data.auxUrgencia.selected)) {
      this.UI.addToast("Urgência inválida");
      v = false;
      validDefer.reject();
    }


    // Mandatory multiple data
    if (v && (this.data.linhas.length === 0)) {
      this.UI.addToast("Processo sem linhas preenchidas");
      v = false;
      validDefer.reject();
    }

    // Mandatory multiple data
    if (v && (this.data.fornecedores.length === 0)) {
      this.UI.addToast("Processo sem fornecedores preenchidos");
      v = false;
      validDefer.reject();
    }

    // Validate mandatory fields in dependent data
    if (v && !this.PadService.validateMandatoryData(this.data.linhas, ['centroCustoId', 'quantidade', 'precoUnitarioOrcamentado', 'precoTotalOrcamentado'])) {
      this.UI.addToast("Pelo menos uma das linhas não tem dados preenchidos corretamente");
      v = false;
      validDefer.reject();
    }

    // Validate mandatory fields in dependent data
    if (v && !this.PadService.validateMandatoryData(this.data.fornecedores, ['prazo', 'total'])) {
      this.UI.addToast("Pelo menos um dos fornecedores não tem dados preenchidos corretamente");
      v = false;
      validDefer.reject();
    }

    // Validate one and only one fornecedor selecionado
    let nSelecionados = this.data.fornecedores.filter(f => f.fornecedorSelecionado).length;
    if (v && nSelecionados !== 1) {
      this.UI.addToast("Tem que existir " + (nSelecionados > 1 ? "apenas" : "") + " um fornecedor selecionado");
      v = false;
      validDefer.reject();
    }

    // Validate criterio for the chosen fornecedor
    let fornecedorSelecionado = this.data.fornecedores.find(f => f.fornecedorSelecionado);
    if (v && (!fornecedorSelecionado || !fornecedorSelecionado.criterio || !fornecedorSelecionado.criterio.id)) {
      this.UI.addToast("Critério de Adjudicação não definido para fornecedor selecionado");
      v = false;
      validDefer.reject();
    }

    // Check if correct fornecedores prefix exist for selected tipo de despesa
    let validFornecedorPrefix = this.data.auxTipoDespesa.selected.id === 1 ? 'FX' : 'FI';
    for (let i = 0; i < this.data.fornecedores.length; i++) {
      if (!this.data.fornecedores[i].fornecedorPrimavera.fornecedor.startsWith(validFornecedorPrefix)) {
        this.UI.addToast("Fornecedor inválido para o Tipo de Despesa selecionado");
        v = false;
        validDefer.reject();
        break;
      }
    }

    // Only validate multidata if all static data is valid, otherwise no point first
    if (v) {
      // VALIDATE ASYNC DATA
      let idData = [];

      // Add multi async data to verify
      // Verify Entidade Proprietaria, Centro de Decisão
      idData.push({
        field: 'id',
        value: this.data.auxEntidadeProprietaria.selected.id,
        model: this.AtvEntidadeProprietaria,
        resolveOnExist: true, // It's resolved if it finds one
        error: "Entidade Proprietária inexistente"
      }, {
        field: 'id',
        value: this.data.auxCentroDecisao.selected.id,
        model: this.PadCentroDecisao,
        resolveOnExist: true, // It's resolved if it finds one
        error: "Centro de Decisão inexistente"
      });

      this.data.linhas.forEach(x => {
        if (x.projeto) {
          idData.push({
            field: 'conta',
            value: x.projeto.conta,
            model: this.PadService.getPrimaveraProjeto(this.data.auxEntidadeProprietaria.selected.id),
            resolveOnExist: true, // It's resolved if it finds one
          });
        }
        idData.push({
          field: 'id',
          value: x.centroCustoId,
          model: this.PadCentroCusto,
          resolveOnExist: true, // It's resolved if it finds one
          error: "Centro de Custo inexistente"
        });
        idData.push({
          field: 'artigo',
          value: x.artigo.artigo,
          model: this.PadService.getPrimaveraArtigo(this.data.auxEntidadeProprietaria.selected.id),
          resolveOnExist: true, // It's resolved if it finds one
          error: "Artigo inexistente"
        });
      });

      this.data.fornecedores.forEach(x => {
        idData.push({
          field: 'condpag',
          value: x.condicaoPagamento.condpag,
          model: this.PadService.getPrimaveraCondicaoPagamento(this.data.auxEntidadeProprietaria.selected.id),
          resolveOnExist: true, // It's resolved if it finds one
          error: "Condição de pagamento inexistente"
        });
        if (x.fornecedorSelecionado) {
          idData.push({
            field: 'id',
            value: x.criterio.id,
            model: this.PadCriterio,
            resolveOnExist: true, // It's resolved if it finds one
            error: "Critério de Adjudicação inexistente"
          });
        }
      });

      let wait = this.UI.showWaiting();
      // Verify all the async data
      this.PadService.validateActiveCurrentYearCentrosCusto(this.data.linhas).then(x => {
        this.PadService.validateMultiData(idData).then(x => {
          this.PadService.validateFornecedores(this.data.fornecedores).then(x => {
            wait.close();
            validDefer.resolve();
          }).catch(() => {
            wait.close();
            validDefer.reject();
          });
        }).catch(() => {
          wait.close();
          validDefer.reject();
        });
      }).catch(() => {
        wait.close();
        validDefer.reject();
      });
    }
    return validDefer.promise;
  };

  // Called when selecting Atividade Proprietaria
  onEntidadeProprietariaSelected = (item) => {
    if (!this.data.auxEntidadeProprietaria.selected) {
      this.data.auxEntidadeProprietaria.selected = angular.copy(this.data.auxEntidadeProprietariaForm.selected);
      this.centrosDecisao = this.centrosDecisaoGlobal.filter(x => x.entidadeProprietariaId === this.data.auxEntidadeProprietaria.selected.id);
      return;
    }
    if (item.id === this.data.auxEntidadeProprietaria.selected.id) {
      return;
    }
    if (this.data.auxCentroDecisao.selected || this.data.linhas.length > 0 || this.data.fornecedores.length > 0) { // Data already filled, ask for sure
      let msg = 'Alterar a Entidade Proprietária após ter preenchido outros dados invalida-os. Pretende continuar e removê-los?';
      let confirm = this.UI.showConfirm(msg);
      confirm.then(ok => {
        this.data.auxEntidadeProprietaria.selected = angular.copy(this.data.auxEntidadeProprietariaForm.selected);
        this.centrosDecisao = this.centrosDecisaoGlobal.filter(x => x.entidadeProprietariaId === this.data.auxEntidadeProprietaria.selected.id); // Select proper centros
        this.data.auxCentroDecisao.selected = undefined; // Clear filled centro decisão
        this.data.linhas = [];
        this.data.fornecedores = [];
      }).catch(() => {
        this.data.auxEntidadeProprietariaForm.selected = angular.copy(this.data.auxEntidadeProprietaria.selected);
        // Do nothing, since we didn't change
      });
    } else { // Safe to change
      this.data.auxEntidadeProprietaria.selected = angular.copy(this.data.auxEntidadeProprietariaForm.selected);
      this.centrosDecisao = this.centrosDecisaoGlobal.filter(x => x.entidadeProprietariaId === this.data.auxEntidadeProprietaria.selected.id); // Select proper centros
    }
  };

  // Called when selecting Atividade Proprietaria
  onTipoDespesaSelected = (item) => {
    if (!this.data.auxTipoDespesa.selected) {
      this.data.auxTipoDespesa.selected = angular.copy(this.data.auxTipoDespesaForm.selected);
      return;
    }
    if (item.id === this.data.auxTipoDespesa.selected.id) {
      return;
    }
    if (this.data.fornecedores.length > 0) { // Data already filled, ask for sure
      let msg = 'Alterar o Tipo de Despesa após ter preenchido fornecedores invalida-os. Pretende continuar e removê-los?';
      let confirm = this.UI.showConfirm(msg);
      confirm.then(ok => {
        this.data.auxTipoDespesa.selected = angular.copy(this.data.auxTipoDespesaForm.selected);
        this.data.fornecedores = [];
      }).catch(() => {
        this.data.auxTipoDespesaForm.selected = angular.copy(this.data.auxTipoDespesa.selected);
        // Do nothing, since we didn't change
      });
    } else { // Safe to change
      this.data.auxTipoDespesa.selected = angular.copy(this.data.auxTipoDespesaForm.selected);
    }
  };

  // MULTIPLE DATA CRUDs

  // Adicionar um novo investigador para processo
  addLinha = () => {
    if (!this.data.auxEntidadeProprietaria.selected || !this.data.auxEntidadeProprietaria.selected.id) {
      this.UI.addToast("Entidade Proprietária precisa de estar definida antes de adicionar linhas");
      return;
    }
    let wait = this.UI.showWaiting();
    // Get the right table to fetch artigos, planocontas from EP
    let PRIArtigo = this.PadService.getPrimaveraArtigo(this.data.auxEntidadeProprietaria.selected.id);
    let PRIPlanocontas = this.PadService.getPrimaveraProjeto(this.data.auxEntidadeProprietaria.selected.id);
    let currentYear = Number(moment().format('YYYY'));

    this.PadCentroCusto.find({
      filter: {
        where: {
          and: [{ active: 1 }, { ano: currentYear }, { tipoConta: 'M' }, { entidadeProprietariaId: this.data.auxEntidadeProprietaria.selected.id }]
        },
        order: 'ano DESC, centro ASC'
      }
    }).$promise.then((centros) => {
      PRIArtigo.find({
        filter: {
          where: {
            artigo: { like: 'Z%' }
          },
          order: 'artigo ASC'
        }
      }).$promise.then((artigos) => {
        PRIPlanocontas.find({
          filter: {
            fields: { conta: true, ano: true, descricao: true },
            where: {
              conta: { like: '91%' }
            },
            order: 'descricao ASC, ano DESC'
          }
        }).$promise.then((projetos) => {
          wait.close();
          let options = {
            size: 'lg',
            template: require('./edit.linha.dialog.html'),
            controller: ['$dialog', '$scope', (dialog, scope) => {
              scope.label = "Adicionar Linha de Processo";
              scope.centros = centros;
              scope.artigos = artigos;
              scope.projetos = projetos;

              scope.auxCentro = {
                selected: undefined,
                infiniteScroll: { numToAdd: 20, currentItems: 20 }
              };

              scope.auxArtigo = {
                selected: undefined,
                infiniteScroll: { numToAdd: 20, currentItems: 20 }
              };

              scope.auxProjeto = {
                selected: undefined,
                infiniteScroll: { numToAdd: 20, currentItems: 20 }
              };

              scope.linha = {
                id: 0,
                quantidade: undefined,
                caracteristicas: undefined,
                precoUnitarioOrcamentado: undefined,
                precoTotalOrcamentado: undefined,
                observacoes: undefined
              };

              scope.updateTotal = () => {
                if (scope.linha.precoUnitarioOrcamentado === undefined || scope.linha.quantidade === undefined) return;
                if (scope.linha.precoUnitarioOrcamentado >= 0 && scope.linha.quantidade >= 0)
                  scope.linha.precoTotalOrcamentado = Math.round(scope.linha.precoUnitarioOrcamentado * scope.linha.quantidade * 100) / 100;
                else
                  scope.linha.precoTotalOrcamentado = 0;
              };

              //Infinite Scroll Magic
              scope.addMoreItems = (infiniteScroll) => {
                infiniteScroll.currentItems += infiniteScroll.numToAdd;
              };

              scope.ok = () => {
                dialog.close(scope);
              };

              scope.cancel = () => {
                dialog.dismiss('cancel');
              };
            }]
          };

          let modal = this.UI.showDialog(options);

          modal.then((ok) => {
            // Check if Cliente exists. If not, create it, if exists, update it.
            if (ok.auxCentro.selected && ok.auxArtigo.selected) {
              this.data.linhas.push({
                centroCustoId: ok.auxCentro.selected.id,
                centroCusto: ok.auxCentro.selected,
                artigo: ok.auxArtigo.selected,
                projeto: ok.auxProjeto.selected ? ok.auxProjeto.selected : null,
                quantidade: ok.linha.quantidade,
                precoUnitarioOrcamentado: ok.linha.precoUnitarioOrcamentado,
                precoTotalOrcamentado: ok.linha.precoTotalOrcamentado,
                caracteristicas: ok.linha.caracteristicas,
                observacoes: ok.linha.observacoes
              });
            }
          }).catch(() => {
          });
        }).catch(err => {
          console.log(err);
          this.UI.addToast("Erro ao carregar Projetos. Verifique a sua ligação");
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro no carregamento de Artigos. Verifique a ligação");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro no carregamento de Centro de Custos. Verifique a ligação");
    });
  };

  // Edita linha do processo
  editLinha = (x) => {
    let wait = this.UI.showWaiting();
    if (!this.data.auxEntidadeProprietaria.selected || !this.data.auxEntidadeProprietaria.selected.id) {
      this.UI.addToast("Entidade Proprietária precisa de estar definida antes de editar linha");
      return;
    }
    // Get the right table to fetch artigos, planocontas from EP
    let PRIArtigo = this.PadService.getPrimaveraArtigo(this.data.auxEntidadeProprietaria.selected.id);
    let PRIPlanocontas = this.PadService.getPrimaveraProjeto(this.data.auxEntidadeProprietaria.selected.id);
    let currentYear = Number(moment().format('YYYY'));

    this.PadCentroCusto.find({
      filter: {
        where: {
          and: [{ active: 1 }, { ano: currentYear }, { tipoConta: 'M' }, { entidadeProprietariaId: this.data.auxEntidadeProprietaria.selected.id }]
        },
        order: 'ano DESC, centro ASC'
      }
    }).$promise.then((centros) => {
      PRIArtigo.find({
        filter: {
          where: {
            artigo: { like: 'Z%' }
          },
          order: 'artigo ASC'
        }
      }).$promise.then((artigos) => {
        PRIPlanocontas.find({
          filter: {
            fields: { conta: true, ano: true, descricao: true },
            where: {
              conta: { like: '91%' }
            },
            order: 'descricao ASC, ano DESC'
          }
        }).$promise.then((projetos) => {
          wait.close();
          let options = {
            size: 'lg',
            template: require('./edit.linha.dialog.html'),
            controller: ['$dialog', '$scope', (dialog, scope) => {
              scope.label = "Editar Linha de Processo";
              scope.centros = centros;
              scope.artigos = artigos;
              scope.projetos = projetos;

              scope.auxCentro = {
                selected: scope.centros.find(c => c.id === x.centroCustoId),
                infiniteScroll: { numToAdd: 20, currentItems: 20 }
              };

              scope.auxArtigo = {
                selected: scope.artigos.find(a => a.artigo === x.artigo.artigo),
                infiniteScroll: { numToAdd: 20, currentItems: 20 }
              };

              scope.auxProjeto = {
                selected: x.projeto ? (scope.projetos.find(a => (a.conta === x.projeto.conta) && (a.ano === x.projeto.ano))) : undefined,
                infiniteScroll: { numToAdd: 20, currentItems: 20 }
              };

              scope.linha = angular.copy(x);

              scope.updateTotal = () => {
                if (scope.linha.precoUnitarioOrcamentado === undefined || scope.linha.quantidade === undefined) return;
                if (scope.linha.precoUnitarioOrcamentado >= 0 && scope.linha.quantidade >= 0)
                  scope.linha.precoTotalOrcamentado = Math.round(scope.linha.precoUnitarioOrcamentado * scope.linha.quantidade * 100) / 100;
                else
                  scope.linha.precoTotalOrcamentado = 0;
              };

              //Infinite Scroll Magic
              scope.addMoreItems = (infiniteScroll) => {
                infiniteScroll.currentItems += infiniteScroll.numToAdd;
              };

              scope.ok = () => {
                dialog.close(scope);
              };

              scope.cancel = () => {
                dialog.dismiss('cancel');
              };
            }]
          };

          let modal = this.UI.showDialog(options);

          modal.then((ok) => {
            // Check if Cliente exists. If not, create it, if exists, update it.
            if (ok.auxCentro.selected && ok.auxArtigo.selected) {
              x.centroCustoId = ok.auxCentro.selected.id;
              x.centroCusto = ok.auxCentro.selected;
              x.artigo = ok.auxArtigo.selected;
              x.projeto = ok.auxProjeto.selected ? ok.auxProjeto.selected : null,
                x.quantidade = ok.linha.quantidade;
              x.precoUnitarioOrcamentado = ok.linha.precoUnitarioOrcamentado;
              x.precoTotalOrcamentado = ok.linha.precoTotalOrcamentado;
              x.caracteristicas = ok.linha.caracteristicas;
              x.observacoes = ok.linha.observacoes;
            }
          }).catch(() => {
          });
        }).catch(err => {
          console.log(err);
          this.UI.addToast("Erro ao carregar Projetos. Verifique a sua ligação");
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro no carregamento de Artigos. Verifique a ligação");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro no carregamento de Centro de Custos. Verifique a ligação");
    });
  };

  // Remove Organizador com índice index
  removeLinha = (index) => {
    if (index < this.data.linhas.length) {
      let confirm = this.UI.showConfirm("Tem a certeza que pretende eliminar esta linha?");
      confirm.then(() => {
        this.data.linhas.splice(index, 1);
      }).catch(() => { });
    }
  };


  // Adicionar um novo fornecedor para processo
  addFornecedor = () => {
    if (!this.data.auxEntidadeProprietaria.selected || !this.data.auxEntidadeProprietaria.selected.id) {
      this.UI.addToast("Entidade Proprietária precisa de estar definida antes de adicionar fornecedores");
      return;
    }
    if (!this.data.auxTipoDespesa.selected || !this.data.auxTipoDespesa.selected.id) {
      this.UI.addToast("Tipo de Despesa precisa de estar definido antes de adicionar fornecedores");
      return;
    }

    // If Tipo Despesa == 1 (Operacional), FX* , else FI*
    let tipoDespesaFornecedor = this.data.auxTipoDespesa.selected.id === 1 ? 'FX%' : 'FI%';

    let wait = this.UI.showWaiting();
    // Get the right table to fetch fornecedores from IEP, AJL, OBLER
    let PRIFornecedores = this.PadService.getPrimaveraFornecedor(this.data.auxEntidadeProprietaria.selected.id);
    let PRICondpag = this.PadService.getPrimaveraCondicaoPagamento(this.data.auxEntidadeProprietaria.selected.id);

    // Check if exists a fornecedor selecionado already
    let fornecedorSelecionado = this.data.fornecedores.find(x => x.fornecedorSelecionado);
    let existsFornecedorSelecionado = !!fornecedorSelecionado;

    this.PadConfig.findOne({
    }).$promise.then((config) => {
      PRIFornecedores.find({
        filter: {
          where: {
            fornecedor: { like: tipoDespesaFornecedor }
          },
          order: 'nome ASC'
        }
      }).$promise.then((fornecedores) => {
        fornecedores.sort((a, b) => a.nome.localeCompare(b.nome));
        PRICondpag.find({
          filter: {
            order: 'condpag ASC'
          }
        }).$promise.then((condicoesPagamento) => {
          this.PadCriterio.find({
            filter: {
              where: {
                active: 1
              }
            }
          }).$promise.then((criterios) => {
            wait.close();
            let options = {
              size: 'lg',
              template: require('./edit.fornecedor.dialog.html'),
              controller: ['$dialog', '$scope', 'AtvFornecedorPrimavera', (dialog, scope, AtvFornecedorPrimavera) => {
                scope.label = "Adicionar Fornecedor de Processo";
                scope.dragString = 'Arrastar o ficheiro para aqui';
                scope.isEditing = false;
                scope.fornecedores = fornecedores;
                scope.existsFornecedorSelecionado = existsFornecedorSelecionado;
                scope.condicoesPagamento = condicoesPagamento;
                scope.criterios = criterios;
                scope.newFileName = '';
                scope.finishedUploading = false;

                scope.anexos = []; // For editing only
                // Info to show when selecting fornecedor
                scope.avaliacaoFornecedor = null;

                scope.uuid = this.PadService.generateUUID();

                scope.uploader = new this.FileUploader({
                  url: this.envURL + '/api/Upload/pad/upload',
                  queueLimit: 1,
                  formData: [{ key: 'value' }]
                });

                // Filtro para verificar se é PDF ou ZIP
                let fileTypeFilter = {
                  name: 'verifyPDF_ZIP',
                  fn: (item, options) => {
                    let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                    let ok = '|pdf|zip|'.indexOf(type) !== -1;
                    if (!ok) this.UI.addToast("A proposta tem que ser no formato PDF ou ZIP");
                    return ok;
                  }
                };

                let fileSizeFilter = {
                  name: 'fileSizeFilter',
                  fn: (item) => {
                    if (item.size > 52428800) {
                      this.UI.addToast("Tamanho máximo para ficheiro de proposta é de 50MB");
                    }
                    return item.size <= 52428800; // 50 MiB to bytes
                  }
                };

                // Inserir filtro
                scope.uploader.filters.push(fileTypeFilter, fileSizeFilter);

                // Uploader functions
                scope.uploader.onAfterAddingFile = (item) => {
                  let partes = item.file.name.split(".");
                  scope.newFileName = scope.uuid + "." + partes[partes.length - 1];

                  if (scope.uploader.queue.length > 1)
                    scope.uploader.queue.splice(0, scope.uploader.queue.splice.length - 1);
                };

                scope.uploader.onWhenAddingFileFailed = (item) => {
                  // ... It's dealt in the filters already
                };

                scope.uploader.onErrorItem = (response, status, headers) => {
                  this.UI.addToast("Erro ao carregar ficheiro. Tente novamente ou verifique o ficheiro");
                };

                scope.uploader.onBeforeUploadItem = (item) => {
                  item.file.name = scope.newFileName;
                };

                // After file was correctly uploaded
                scope.uploader.onSuccessItem = (item) => {
                  this.PadAnexoPendente.create({
                    id: 0,
                    userId: this.user.id,
                    uuid: scope.newFileName,
                    date: moment().format(),
                    active: 1
                  }).$promise.then((f) => {
                    scope.finishedUploading = true;
                    dialog.close(scope);
                  }).catch((error) => {
                    console.log(error);
                    this.UI.addToast("Erro no carregamento do ficheiro. Tente novamente ou verifique ligação");
                  });
                };

                scope.auxFornecedor = {
                  selected: undefined,
                  infiniteScroll: { numToAdd: 20, currentItems: 20 }
                };

                scope.auxCondicaoPagamento = {
                  selected: undefined,
                  infiniteScroll: { numToAdd: 20, currentItems: 20 }
                };

                scope.auxCriterio = {
                  selected: undefined,
                  infiniteScroll: { numToAdd: 20, currentItems: 20 }
                };

                scope.fornecedor = {
                  id: 0,
                  total: undefined,
                  totalPreNegocial: undefined,
                  prazo: undefined,
                };

                // Only require anexo when proposta > 500
                scope.requiresProposta = () => scope.fornecedor && (scope.fornecedor.total > config.valorAprovacaoNivel3);

                //Infinite Scroll Magic
                scope.addMoreItems = (infiniteScroll) => {
                  infiniteScroll.currentItems += infiniteScroll.numToAdd;
                };

                scope.getAvaliacaoFornecedor = (item) => {
                  // Clear previous info
                  scope.avaliacaoFornecedor = null;
                  AtvFornecedorPrimavera.getAvaliacaoFornecedor({
                    params: {
                      entidadeProprietariaId: this.data.auxEntidadeProprietaria.selected.id,
                      refFornecedorPrimavera: item.fornecedor
                    }
                  }).$promise.then(avaliacaoFornecedor => {
                    scope.avaliacaoFornecedor = avaliacaoFornecedor;
                  }).catch(error => {
                    scope.avaliacaoFornecedor = null;
                    console.log(error);
                    this.UI.addToast("Não foi possível consultar avaliação de fornecedor");
                  });
                };

                scope.ok = () => {
                  // If totalPreNegocial is filled and is a number that is < total, show message
                  if (scope.fornecedor.totalPreNegocial && !isNaN(scope.fornecedor.totalPreNegocial) && scope.fornecedor.totalPreNegocial < scope.fornecedor.total) {
                    this.UI.addToast("Valor pré-negociação não deve ser inferior ao valor final da proposta");
                    return;
                  }
                  if (scope.uploader.queue.length > 0) {
                    scope.uploader.uploadAll();
                    // Closing the dialog is done on onSuccessItem()
                  } else {
                    dialog.close(scope);
                  }
                };

                scope.cancel = () => {
                  dialog.dismiss('cancel');
                };
              }]
            };

            let modal = this.UI.showDialog(options);

            modal.then((ok) => {
              // Check if Cliente exists. If not, create it, if exists, update it.
              if (ok.auxFornecedor.selected && ok.auxCondicaoPagamento.selected) {
                this.data.fornecedores.push({
                  fornecedorPrimavera: ok.auxFornecedor.selected,
                  totalPreNegocial: ok.fornecedor.totalPreNegocial,
                  total: ok.fornecedor.total,
                  condicaoPagamento: ok.auxCondicaoPagamento.selected,
                  prazo: ok.fornecedor.prazo,
                  email: ok.fornecedor.email || null,
                  refProposta: ok.fornecedor.refProposta || null,
                  uuid: ok.newFileName,
                  fornecedorSelecionado: ok.fornecedor.fornecedorSelecionado || false,
                  criterio: ok.fornecedor.fornecedorSelecionado ? ok.auxCriterio.selected : null,
                  criterioObservacoes: ok.fornecedor.fornecedorSelecionado ? ok.fornecedor.criterioObservacoes : null
                });
              }
            }).catch(() => { });
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro no carregamento de Critérios de Adjudicação. Verifique a ligação");
          });
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro no carregamento de Condições de Pagamento. Verifique a ligação");
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro no carregamento de Fornecedores. Verifique a ligação");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro no carregamento de Configuração. Verifique a ligação.");
    });

  };

  // Edita fornecedor do processo
  editFornecedor = (x) => {
    if (!this.data.auxEntidadeProprietaria.selected || !this.data.auxEntidadeProprietaria.selected.id) {
      this.UI.addToast("Entidade Proprietária precisa de estar definida antes de editar fornecedor");
      return;
    }
    let wait = this.UI.showWaiting();
    // Get the right table to fetch fornecedores from IEP, AJL, OBLER
    let PRIFornecedores = this.PadService.getPrimaveraFornecedor(this.data.auxEntidadeProprietaria.selected.id);
    let PRICondpag = this.PadService.getPrimaveraCondicaoPagamento(this.data.auxEntidadeProprietaria.selected.id);

    // Check if exists a fornecedor selecionado already
    let fornecedorSelecionado = this.data.fornecedores.find(x => x.fornecedorSelecionado);
    let existsFornecedorSelecionado = !!fornecedorSelecionado;

    this.PadConfig.findOne({}).$promise.then((config) => {
      PRIFornecedores.find({
        filter: {
          order: 'nome ASC'
        }
      }).$promise.then((fornecedores) => {
        fornecedores.sort((a, b) => a.nome.localeCompare(b.nome));
        PRICondpag.find({
          filter: {
            order: 'condpag ASC'
          }
        }).$promise.then((condicoesPagamento) => {
          this.PadAnexoPendente.find({
            filter: {
              where: {
                and: [{ userId: this.user.id }, { uuid: x.uuid }]
              }
            }
          }).$promise.then((anexos) => {
            this.PadCriterio.find({
              filter: {
                where: {
                  active: 1
                }
              }
            }).$promise.then((criterios) => {
              wait.close();
              let options = {
                size: 'lg',
                template: require('./edit.fornecedor.dialog.html'),
                controller: ['$dialog', '$scope', (dialog, scope) => {
                  scope.label = "Editar Fornecedor de Processo";
                  scope.isEditing = true;
                  scope.finishedUploading = false;
                  scope.fornecedores = fornecedores;
                  scope.existsFornecedorSelecionado = existsFornecedorSelecionado;
                  scope.condicoesPagamento = condicoesPagamento;
                  scope.criterios = criterios;
                  scope.newFileName = '';
                  scope.anexos = anexos;
                  scope.uuid = this.PadService.generateUUID();

                  if (scope.anexos.length === 0) {
                    scope.dragString = 'Arrastar o ficheiro da proposta para aqui';
                  } else {
                    scope.dragString = 'Se necessário substituir, arrastar o novo ficheiro da proposta para aqui';
                  }

                  scope.uploader = new this.FileUploader({
                    url: this.envURL + '/api/Upload/pad/upload',
                    queueLimit: 1,
                    formData: [{ key: 'value' }]
                  });

                  // Filtro para verificar se é PDF ou ZIP
                  let fileTypeFilter = {
                    name: 'verifyPDF_ZIP',
                    fn: (item, options) => {
                      let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                      let ok = '|pdf|zip|'.indexOf(type) !== -1;
                      if (!ok) this.UI.addToast("A proposta tem que ser no formato PDF ou ZIP");
                      return ok;
                    }
                  };

                  let fileSizeFilter = {
                    name: 'fileSizeFilter',
                    fn: (item) => {
                      if (item.size > 52428800) {
                        this.UI.addToast("Tamanho máximo para ficheiro de proposta é de 50MB");
                      }
                      return item.size <= 52428800; // 50 MiB to bytes
                    }
                  };

                  // Inserir filtro
                  scope.uploader.filters.push(fileTypeFilter, fileSizeFilter);

                  // Uploader functions
                  scope.uploader.onAfterAddingFile = (item) => {
                    let partes = item.file.name.split(".");
                    scope.newFileName = scope.uuid + "." + partes[partes.length - 1];

                    if (scope.uploader.queue.length > 1)
                      scope.uploader.queue.splice(0, scope.uploader.queue.splice.length - 1);
                  };

                  scope.uploader.onWhenAddingFileFailed = (item) => {
                    // ... It's dealt in the filters already
                  };

                  scope.uploader.onErrorItem = (response, status, headers) => {
                    this.UI.addToast("Erro ao carregar ficheiro. Tente novamente ou verifique o ficheiro");
                  };

                  scope.uploader.onBeforeUploadItem = (item) => {
                    item.file.name = scope.newFileName;
                  };

                  // After file was correctly uploaded
                  scope.uploader.onSuccessItem = (item) => {
                    this.PadAnexoPendente.create({
                      id: 0,
                      userId: this.user.id,
                      uuid: scope.newFileName,
                      date: moment().format(),
                      active: 1
                    }).$promise.then((f) => {
                      scope.finishedUploading = true;
                      dialog.close(scope);
                    }).catch((error) => {
                      console.log(error);
                      this.UI.addToast("Erro no carregamento do ficheiro. Tente novamente ou verifique ligação");
                    });
                  };

                  scope.auxFornecedor = {
                    selected: scope.fornecedores.find(f => f.fornecedor === x.fornecedorPrimavera.fornecedor),
                    infiniteScroll: { numToAdd: 20, currentItems: 20 }
                  };

                  scope.auxCondicaoPagamento = {
                    selected: scope.condicoesPagamento.find(c => c.condpag === x.condicaoPagamento.condpag),
                    infiniteScroll: { numToAdd: 20, currentItems: 20 }
                  };

                  scope.auxCriterio = {
                    selected: scope.criterios.find(c => x.criterio && c.id === x.criterio.id),
                    infiniteScroll: { numToAdd: 20, currentItems: 20 }
                  };

                  scope.fornecedor = angular.copy(x);

                  // Only require anexo when proposta > 500
                  scope.requiresProposta = () => scope.fornecedor && (scope.fornecedor.total > config.valorAprovacaoNivel3);

                  //Infinite Scroll Magic
                  scope.addMoreItems = (infiniteScroll) => {
                    infiniteScroll.currentItems += infiniteScroll.numToAdd;
                  };

                  scope.ok = () => {
                    // If totalPreNegocial is filled and is a number that is < total, show message
                    if (scope.fornecedor.totalPreNegocial && !isNaN(scope.fornecedor.totalPreNegocial) && scope.fornecedor.totalPreNegocial < scope.fornecedor.total) {
                      this.UI.addToast("Valor pré-negociação não deve ser inferior ao valor final da proposta");
                      return;
                    }
                    // If file was added, replace the old
                    if (scope.uploader.queue.length > 0) {
                      // If already exists, deactivate it
                      if (scope.anexos.length > 0) {
                        for (let i = 0; i < scope.anexos.length; i++) {
                          scope.anexos[i].active = 0;
                          this.PadAnexoPendente.upsert(scope.anexos[i]).$promise.then((res) => {
                          }).catch((error) => {
                            console.log(error);
                          });
                        }
                      }
                      scope.uploader.uploadAll();
                      // Closing the dialog is done on onSuccessItem()
                    } else {
                      // If no file was provided, just keep the old one
                      scope.newFileName = x.uuid;
                      dialog.close(scope);
                    }
                  };

                  scope.cancel = () => {
                    dialog.dismiss('cancel');
                  };
                }]
              };

              let modal = this.UI.showDialog(options);

              modal.then((ok) => {
                // Check if Cliente exists. If not, create it, if exists, update it.
                if (ok.auxFornecedor.selected && ok.auxCondicaoPagamento.selected) {
                  x.fornecedorPrimavera = ok.auxFornecedor.selected;
                  x.totalPreNegocial = ok.fornecedor.totalPreNegocial;
                  x.total = ok.fornecedor.total;
                  x.condicaoPagamento = ok.auxCondicaoPagamento.selected;
                  x.prazo = ok.fornecedor.prazo;
                  x.email = ok.fornecedor.email || null;
                  x.refProposta = ok.fornecedor.refProposta || null;
                  x.uuid = ok.newFileName;
                  x.fornecedorSelecionado = ok.fornecedor.fornecedorSelecionado || false;
                  x.criterio = x.fornecedorSelecionado ? ok.auxCriterio.selected : null;
                  x.criterioObservacoes = x.fornecedorSelecionado ? ok.fornecedor.criterioObservacoes : null;
                }
              }).catch(() => { });
            }).catch((error) => {
              console.log(error);
              this.UI.addToast("Erro na verificação de Critérios de Adjudicação. Verifique a ligação");
            });
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro na verificação do ficheiro de anexo. Verifique a ligação");
          });
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro no carregamento de Condições de Pagamento. Verifique a ligação");
        });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Erro no carregamento de Fornecedores. Verifique a ligação");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro no carregamento de Configuração. Verifique a ligação.");
    });
  };

  // Remove Organizador com índice index
  removeFornecedor = (index) => {
    if (index < this.data.fornecedores.length) {
      let confirm = this.UI.showConfirm("Tem a certeza que pretende eliminar este fornecedor?");
      confirm.then(() => {
        // We don't really care for sync, since this is housekeeping
        // If no uuid is set, there is no file
        if (this.data.fornecedores[index].uuid) {
          this.PadAnexoPendente.findOne({
            filter: {
              where: {
                and: [{ userId: this.user.id }, { active: 1 }, { uuid: this.data.fornecedores[index].uuid }]
              }
            }
          }).$promise.then((anexo) => {
            anexo.active = 0;
            this.PadAnexoPendente.upsert(anexo);
          }).catch((error) => {
            console.log(error);
          });
        }

        this.data.fornecedores.splice(index, 1);
      }).catch(() => { });
    }
  };

}

PadProcessoCreateController.$inject = ['$rootScope', '$q', '$state', '$scope', 'AuthenticationService', 'UIService', 'PadService', 'PadConfig', 'FileUploader', 'AtvEntidadeProprietaria', 'PadCentroDecisao', 'PadProcesso', 'PadLinhaProcesso', 'PadUrgencia', 'PadTipoDespesa', 'PadProcessoFornecedor', 'PadProcessoAnexo', 'PadProcessoEstado', 'PadCentroCusto', 'PadLinhaProcessoEstado', 'PadProjeto', 'PadArtigo', 'PadCriterio', 'PadAnexoPendente', 'PRIIEPPlanocentros', 'PRIAJLPlanocentros', 'PRIOBLERPlanocentros', 'PRIIEPArtigo', 'PRIAJLArtigo', 'PRIOBLERArtigo', 'PRIIEPFornecedores', 'PRIAJLFornecedores', 'PRIOBLERFornecedores'];
