import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete, Avatar, Box, CircularProgress, Collapse, IconButton, ListItem, ListItemText, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { MdiIcon, Table } from '@components';
import { mdiChevronUp, mdiContentSave, mdiMagnify, mdiPlus, mdiTrashCan } from '@mdi/js';
import { debounce } from 'lodash';
const PessoasAssociadasEmpresa = ({ $state, CrmService, client }) => {
    // Tabela
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const [data, setData] = useState(); // dados dos Contactos
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState([]); // filtros a aplicar na tabela
    // exemplo -> [{ id: 'nome', value: 'Pedro', type: 'contains' }, { id: 'email', value: 'gmail', type: 'contains' }]
    const [sort, setSort] = useState(() => loadParams()); // ordenação da tabela
    // exemplo -> [{id: 'ultimoContacto', desc: false}]
    const [options, setOptions] = useState({
        filter: [],
        sort: [] // exemplo -> ['id', 'nome', 'ultimoContacto', 'contactoTelefonico', 'nif', 'email', 'morada', 'localidade']
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    });
    // AutoComplete
    const [expand, setExpand] = useState(false);
    const [optionsAutocomplete, setOptionsAutocomplete] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const limit = 20; // Número de itens por página
    const [selectedValue, setSelectedValue] = useState(null);
    const [pessoaAssociada, setPessoaAssociada] = useState(null);
    const noFetchRef = useRef([]); // contactos já associados, não pesquisar os mesmos
    const handleScroll = event => {
        const listboxNode = event.currentTarget;
        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 1 && !loading && hasMore) {
            setPage(prev => prev + 1); // Incrementa a página
        }
    };
    const fetchOptions = useCallback(debounce(async (query, currentPage) => {
        if (!query || !hasMore)
            return;
        setLoading(true);
        try {
            const response = await CrmService.findContactoByName(query, currentPage, limit, noFetchRef.current);
            if (response.length < limit) {
                setHasMore(false); // Não há mais dados
            }
            setOptionsAutocomplete(prev => [...prev, ...response]); // Concatena os dados
        }
        catch (error) {
            console.error('Erro ao carregar opções:', error);
        }
        finally {
            setLoading(false);
        }
    }, 500), [hasMore] // Atualiza se `hasMore` mudar
    );
    const saveAssociation = (id, clientId) => {
        try {
            CrmService.alterarAssociacao(id, clientId); // id do contacto a alterar e id da empresa
            // update table
            setRefetching(true);
            fetchData();
        }
        catch (error) {
            console.log('Erro ao associar contacto:', error);
        }
    };
    function stringToColor(string) {
        let hash = 0;
        let i;
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        return color;
    }
    function stringAvatar(name) {
        // Verifica se o nome é válido e tem pelo menos uma palavra
        const nameParts = name.split(' ');
        const firstInitial = nameParts[0] ? nameParts[0][0] : '';
        const secondInitial = nameParts[1] ? nameParts[1][0] : '';
        return {
            sx: {
                bgcolor: stringToColor(name)
            },
            children: `${firstInitial}${secondInitial}`
        };
    }
    // Filtragem dentro de cada coluna
    // Modo de filtro (igual a, contém, começa com... )
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    // ir para detalhes da pessoa (envia id da empresa)
    const onCellView = (cell) => {
        $state.go('app.crm.pessoas.details', { id: cell.id });
    };
    const initialFetch = async () => {
        if (loading) {
            // opt -> vai buscar os campos filtraveis
            const opt = await CrmService.optionsContactos();
            setOptions(opt);
            setLoading(false);
        }
    };
    const fetchData = async () => {
        setError(false);
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            const aux = await CrmService.findAllContactos({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            }, client);
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            // Update URL
            setData(aux);
        }
        catch (e) {
            console.log(e);
            setError(true);
        }
        setRefetching(false);
    };
    // atualizações da tabela
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, filter, sort]);
    // atualizações do autocomplete
    useEffect(() => {
        if (inputValue) {
            fetchOptions(inputValue, page);
        }
    }, [inputValue, page]);
    return (<Box sx={{ mt: 2 }}>
      <Stack direction='column'>
        <Stack direction='row' spacing={1} justifyContent='flex-start' alignItems='center'>
          <Typography variant='h6' sx={{ color: 'black' }}>
            Pessoas associadas
          </Typography>
          {expand ? (<Box>
              <Tooltip title={'Cancelar'}>
                <IconButton color='primary' onClick={() => {
                setExpand(!expand);
                setPessoaAssociada(null);
            }}>
                  <MdiIcon path={mdiChevronUp} sx={{ color: '#1976D2' }}/>
                </IconButton>
              </Tooltip>
              <Tooltip title={'Associar contacto'}>
                <span>
                  <IconButton disabled={selectedValue === null ? true : false} color='primary' onClick={() => {
                saveAssociation(selectedValue.id, client);
                setExpand(!expand);
            }}>
                    <MdiIcon path={mdiContentSave} sx={{ color: selectedValue === null ? '#E0E0E0' : '#1976D2' }}/>
                  </IconButton>
                </span>
              </Tooltip>
            </Box>) : (<Tooltip title={'Adicionar associação'}>
              <IconButton color='primary' onClick={() => {
                setExpand(!expand);
            }}>
                <MdiIcon path={mdiPlus} sx={{ color: '#1976D2' }}/>
              </IconButton>
            </Tooltip>)}
        </Stack>

        <Collapse in={expand} sx={{ mb: 1 }}>
          <Autocomplete disablePortal options={optionsAutocomplete} getOptionLabel={option => (option.name ? `${option.name}` : '')} value={selectedValue} // O valor completo da opção selecionada
     onChange={(event, newValue) => {
            if (newValue === null) {
                setSelectedValue(null);
                setPessoaAssociada(null);
            }
            else {
                setSelectedValue(newValue); // Armazena o objeto completo
                /*setPessoaAssociada({
                idCoreContactoAssociado: newValue.id,
                nomeCoreContactoAssociado: newValue.name
              }); */
            }
        }} filterOptions={x => x} // Evita o filtro padrão
     onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            setOptionsAutocomplete([]); // Reseta opções ao mudar busca
            setPage(0); // Reinicia a paginação
            setHasMore(true);
        }} ListboxProps={{
            onScroll: handleScroll // Adiciona evento de scroll
        }} renderOption={(props, option) => (<ListItem {...props} key={option.id} dense>
                <ListItemText primary={option.name} secondary={`Empresa: ${option.empresa || 'Sem empresa'} | Email: ${option.email || 'Sem email'}`}/>
              </ListItem>)} renderInput={params => (<TextField {...params} label='Contacto' variant='outlined' size='small' fullWidth sx={{ bgcolor: '#F5F5F5' }} InputProps={{
                ...params.InputProps,
                endAdornment: (<>
                      {loading ? <CircularProgress color='inherit' size={20}/> : null}
                      {params.InputProps.endAdornment}
                    </>)
            }}/>)}/>
        </Collapse>

        <Box sx={{
            borderRadius: '15px',
            border: '1px solid #e0e0e0',
            overflow: 'hidden',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' // Adiciona uma leve sombra
        }}>
          <Table onDoubleClick={row => {
            onCellView(row);
        }} storageKey={$state.current.name} data={data} columns={[
            {
                accessorKey: 'avatar',
                header: '',
                size: 30,
                enableSorting: false,
                enableColumnActions: false,
                enableColumnDragging: false,
                Cell: ({ row }) => (<Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%' // Garante que ocupe toda a altura da célula
                    }}>
                    <Avatar alt={row.original.nome} {...stringAvatar(row.original.nome)}/>
                  </Box>)
            },
            {
                accessorKey: 'nome',
                header: 'Nome',
                size: 150
            },
            {
                accessorKey: 'email',
                header: 'Email',
                size: 150
            },
            {
                accessorKey: 'contactoTelefonico',
                header: 'Contacto',
                size: 90
            },
            {
                accessorKey: 'cargo',
                header: 'Cargo',
                size: 100
            }
        ]} initial={{
            sort: sort,
            filter: filter
        }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setFilter} onSort={setSort} rowActionSize={100} actionItems={({ row }) => [
            <Box key={`actions-${row.original.id}`} display={'flex'} gap={1}>
                <Tooltip title={'Ver detalhes'} placement='top'>
                  <IconButton color='secondary' onClick={() => {
                    window.open($state.href('app.crm.pessoas.details', { id: row.original.id }, { absolute: true }));
                }}>
                    <MdiIcon path={mdiMagnify} sx={{ color: '#1976D2' }}/>
                  </IconButton>
                </Tooltip>
                <Tooltip title={'Remover associação'} placement='top'>
                  <IconButton color='secondary' onClick={() => {
                    saveAssociation(row.original.id, 1);
                }}>
                    <MdiIcon path={mdiTrashCan} sx={{ color: '#D32F2F' }}/>
                  </IconButton>
                </Tooltip>
              </Box>
        ]}></Table>
        </Box>
      </Stack>
    </Box>);
};
export default PessoasAssociadasEmpresa;
