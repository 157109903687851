import moment from "moment";

export default class UserEditAdminController {
  constructor($rootScope, $state, $stateParams, AdministrationService, AuthenticationService, AtvEntidadeProprietaria, Funcionario, UIService, FuncionarioAusencia) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.Administration = AdministrationService;
    this.Authentication = AuthenticationService;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.Funcionario = Funcionario;
    this.FuncionarioAusencia = FuncionarioAusencia;
    this.UI = UIService;
    this.calendarEvents = [];
    this.user = {
      imap: false,
      blocked: false
    };
    this.id = $stateParams.id;
    this.loaded = false;
    if (angular.isUndefined(this.id)) {
      $state.go('app.administration.main');
    }
    this.calendarOptions = {
      dayClick: (start, end, jsEvent, view) => {
        this.addAusencia(start);
      },
      eventClick: (event) => {
        this.UI.showConfirm('Deseja eliminar o evento?').then(r => {
          if (r) {
            this.FuncionarioAusencia.prototype$updateAttributes({id: event.id}, {active: false}).$promise.then(() =>{
              this.UI.addToast("Evento eliminado com sucesso");
              this.loadUserInfo();
            }).catch(e => {
              this.UI.addToast("Ocorreu um erro ao eliminar evento");
            });
          }
        });
      },
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'month,listMonth'
      },
      locale: 'pt',
      themeSystem: 'bootstrap4',
      navLinks: true,
      defaultTimedEventDuration: '01:00:00',
      allDaySlot: false,
      displayEventEnd: true,
      timeFormat: "HH:mm",
      height: 'auto',
      firstDay: 1,
      nowIndicator: true,
      weekends: true,
      weekNumberTitle: 'Sem.',
      weekNumbers: true,
      eventLimit: true,
      buttonText: {
        today: 'Hoje',
        month: 'Mensal',
        week: 'Semanal',
        day: 'Diário',
        list: 'Lista'
      }
    };
    this.loadUserInfo(this.id);
  }

  isNotUser = () => {
    return (this.Authentication.getId() !== this.user.id);
  };

  blockUser = () => {
    let message = 'Deseja ' + ((this.user.blocked) ? 'des' : '') + 'bloquear utilizador?';
    let confirm = this.UI.showConfirm(message);
    confirm.then(() => {
      // OK
      this.user.blocked = !this.user.blocked;
      this.user.$save().then(() => {
        let messaging = 'Utilizador ' + ((this.user.blocked) ? 'des' : '') + 'bloqueado';
        this.UI.addToast(messaging);
      });
    }).catch(() => {
      // Cancel
    });
  };

  removeUser = () => {
    let dialog = this.UI.showConfirm('Deseja remover utilizador do sistema?');
    dialog.then(() => {
      this.Administration.removeUser(this.user).then((result) => {
        this.UI.addToast('Utilizador removido');
        this.$state.go('app.administration.main');
      });
    });
  };

  loadUserInfo = (id) => {
    this.loaded = false;
    this.calendarEvents = [];
    this.Administration.getUser(id).then((user) => {
      this.user = user;
      user.ausencias.forEach(ausencia => {
        let o = {
          title: ausencia.motivo,
          id: ausencia.id
        }
        if (moment(ausencia.dataInicio).isSame(ausencia.dataFim, 'day')) {
          if (ausencia.diaInteiro) {
            o.start = moment.utc(`${moment(ausencia.dataInicio).format("YYYY-MM-DD")}`).startOf('day');
            o.end = moment.utc(`${moment(ausencia.dataFim).format("YYYY-MM-DD")}`).endOf('day');
            o.className = "hideCalendarTime";
          } else {
            let start = moment(`${moment(ausencia.dataInicio).format("YYYY-MM-DD")} ${ausencia.horaInicio}`);
            let end = moment(`${moment(ausencia.dataFim).format("YYYY-MM-DD")} ${ausencia.horaFim}`);
            o.start = start;
            o.end = end;
          }
        } else {
          o.start = ausencia.dataInicio;
          o.end = moment.utc(ausencia.dataFim).add(1, 'day');
          o.className = "hideCalendarTime";
        }
        this.calendarEvents.push(o);
      });
      this.loaded = true;
    }).catch(err => {
      this.UI.addToast('Erro ao carregar dados');
      this.$state.go('app.administration.main');
    });
  };

  // Form for user password change
  changePassword = () => {
    let options = {
      template: require("./change-password.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.ok = () => {
          this.Authentication.changePassword($scope.oldPassword, $scope.newPassword).then(() => {
            $dialog.close();
          }).catch((err) => {
            this.UI.addToast("Erro na alteração da password.");
          });
        };

        $scope.cancel = () => {
          $dialog.dismiss();
        };
      }]
    };
    this.UI.showDialog(options).then(() => {
      this.UI.addToast("Password alterada");
    }).catch(() => { });
  };

  editUser = () => {
    let options = {
      size: 'lg',
      template: require('./properties.dialog.html'),
      controller: ['$scope', '$dialog', (scope, dialog) => {
        scope.isNotUser = () => {
          return this.isNotUser();
        };
        scope.user = angular.copy(this.user);
        scope.isInvalid = () => {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (scope.user.name.length === 0 || !re.test(scope.user.email.toLowerCase()));
        };
        scope.cancel = () => {
          scope.$dismiss();
        };
        scope.ok = () => {
          dialog.close(scope.user);
        };
      }]
    };

    let dialog = this.UI.showDialog(options);
    dialog.then(res => {
      if (res) {
        this.Funcionario.find({
          filter: {
            where: {
              and: [{ id: { neq: this.user.id } }, { email: res.email }]
            }
          }
        }).$promise.then((users) => {
          if (users && users.length > 0) { // Already exists user with email
            this.UI.addToast("Email já existente na base de dados.");
          } else { // No email found, change it
            this.Funcionario.upsert(res).$promise.then(user => {
              this.loadUserInfo(this.user.id);
              this.UI.addToast("Dados do utilizador atualizados");
            }).catch(() => {
              this.UI.addToast("Não foi possível encontrar utilizador");
            });
          }
        }).catch(() => {
          this.UI.addToast("Não foi possível encontrar utilizador");
        });
      }
    }).catch(() => { });
  };

  addAusencia = (date) => {
    this.UI.showDialog({
      template: require('./ausencia.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.items = ['Férias', 'Reunião', 'Consulta médica', 'Outros'];

        $scope.dateOptions = {
          format: 'YYYY-MM-DD',
        }

        $scope.hourOptions = {
          format: 'HH:mm:00',
        }

        $scope.data = {
          id: 0,
          active: true,
          diaInteiro: false,
          funcionarioId: this.user.id,
          dataInicio: moment(date),
          dataFim: moment(date),
        };

        $scope.cancel = () => { $scope.$dismiss() };
        $scope.ok = () => { $scope.$close($scope.data) };
      }]
    }).then(r => {
      if (r.motivo.includes("Outros")) {
        r.motivo = r.outro;
      }
      if (!r.diaInteiro) {
        if (r.horaInicio.isValid()) {
          r.horaInicio = r.horaInicio.format("HH:mm:00");
        }
        if (r.horaFim.isValid()) {
          r.horaFim = r.horaFim.format("HH:mm:00");
        }
      } else {
        delete r.horaInicio;
        delete r.horaFim;
      }
      this.FuncionarioAusencia.create(r).$promise.then(_ => {
        this.UI.addToast("Ausência adicionada");
        this.loadUserInfo(this.id);
      });
    }).catch(e => {
      console.log(e);
    });
  }

  // Also in main.controller.js
  editEntidadesProprietarias = () => {
    let cenas = this.UI.showWaiting();
    this.AtvEntidadeProprietaria.find({
      filter: {
        where: {
          active: 1
        },
        order: 'ordem ASC'
      }
    }).$promise.then((entidadesProprietarias) => {
      cenas.close();
      let options = this.Administration.afiliacaoOptions(this.user, entidadesProprietarias);

      this.UI.showDialog(options).then(res => {
        this.loaded = false;
        this.Funcionario.editEntidadesProprietarias({ id: Number(this.id), eps: res.auxEntidadesProprietarias.selected }).$promise.then(r => {
          this.loadUserInfo(this.id);
        }).catch(err => {
          console.log(err);
          this.loaded = true;
          this.UI.addToast('Erro ao alterar afiliação. Por favor tente mais tarde');
        });
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível ler Entidades Proprietárias. Por favor tente mais tarde");
    });
  };
}

UserEditAdminController.$inject = ['$rootScope', '$state', '$stateParams', 'AdministrationService', 'AuthenticationService', 'AtvEntidadeProprietaria', 'Funcionario', 'UIService', 'FuncionarioAusencia'];
