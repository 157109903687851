import angular from 'angular';
import pdfjsLib from 'pdfjs-dist';
require('./style.css');

export default angular.module('app.interface.pdfviewer', [])
  .directive('pmdPdfviewer', ['$compile', '$window', ($compile, $window) => {
    return {
      restrict: 'AE',
      //require: 'ngModel',
      scope: {
        download: "@?",
        url: "=",
        pageMode: "@?"
      },
      link: (scope, element, attrs, ngModel) => {
        if (angular.isUndefined(pdfjsLib)) {
          console.error("PDF.js is required. Please install it first using \"npm install pdfjs-dist\"");
        } else {

          scope.loaded = false;
          scope.download = scope.download || false;
          scope.pageMode = scope.pageMode || false;
          let pdfInstance = null;
          let cursorIndex = Math.floor(scope.currentPage / scope.mode);
          scope.currentPage = 0;
          scope.totalPages = 0;
          scope.mode = 1;

          scope.btnClick = function (event) {
            const action = event.target.getAttribute("data-pager");
            if (action === "prev") {
              if (scope.currentPage === 0) {
                return;
              }
              scope.currentPage -= scope.mode;
              if (scope.currentPage < 0) {
                scope.currentPage = 0;
              }
              render();
            }
            if (action === "next") {
              if (scope.currentPage === scope.totalPages - 1) {
                return;
              }
              scope.currentPage += scope.mode;
              if (scope.currentPage > scope.totalPages - 1) {
                scope.currentPage = scope.totalPages - 1;
              }
              render();
            }
            if (action === "download") {
              let save = document.createElement('a');
              save.href = scope.url;

              let pdfName = save.href.split("/");
              save.download = pdfName[pdfName.length - 1];

              save.target = '_blank';
              document.body.appendChild(save);
              save.click();
              document.body.removeChild(save);
            }
          };

          // container
          let container = require('./view.html');
          element.append(container);
          $compile(element.contents())(scope);
          const viewport = document.querySelector("#viewport");

          scope.$watch(() => {
            return scope.mode;
          }, () => {
            render();
          });

          angular.element($window).bind('resize', () => {
            render();
            scope.$digest();
          });

          function render() {
            cursorIndex = Math.floor(scope.currentPage / scope.mode);
            const startPageIndex = cursorIndex * scope.mode;
            const endPageIndex = startPageIndex + scope.mode < scope.totalPages ? startPageIndex + scope.mode - 1 : scope.totalPages - 1;
            const renderPagesPromises = [];
            for (let i = startPageIndex; i <= endPageIndex; i++) {
              renderPagesPromises.push(pdfInstance.getPage(i + 1));
            }
            Promise.all(renderPagesPromises).then(pages => {
              const pagesHTML = `<div style="width: ${
                scope.mode > 1 ? "50%" : "100%"
                }"><canvas></canvas></div>`.repeat(pages.length);
              $compile(pagesHTML)(scope);
              viewport.innerHTML = pagesHTML;
              pages.forEach(renderPage);
            });
          }

          function renderPage(page) {
            let pdfViewport = page.getViewport(1);

            const container =
              viewport.children[page.pageIndex - cursorIndex * scope.mode];
            pdfViewport = page.getViewport(container.offsetWidth / pdfViewport.width);
            const canvas = container.children[0];
            const context = canvas.getContext("2d");
            canvas.height = pdfViewport.height;
            canvas.width = pdfViewport.width;

            page.render({
              canvasContext: context,
              viewport: pdfViewport
            });
          }

          pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.js';

          function initPageMode() {
            const input = document.querySelector("#page-mode input");
            input.setAttribute("max", scope.totalPages);
          }

          // Asynchronous download of PDF
          pdfjsLib.getDocument(scope.url).promise.then((pdf) => {
            pdfInstance = pdf;
            scope.loaded = true;
            scope.totalPages = pdf.numPages;
            if (scope.pageMode)
              initPageMode();
            render();
            scope.$digest();
          }).catch(error => {
            console.log(error);
          });
        }
      }
    }
  }])
  .name;
