export default class DirectoryListasVerificacaoController {
  constructor(UIService, AuthorizationService, ElvListaVerificacao, ElvNorma, Table) {
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.ElvListaVerificacao = ElvListaVerificacao;
    this.ElvNorma = ElvNorma;
    this.Table = Table;
    this.loaded = false;
    this.loadData();
    this.listasverificacao = [];
  }

  loadData = () => {
    this.loaded = false;
    this.ElvListaVerificacao.find({
      filter: {
        where: {
          active: true
        },
        include: [{
            relation: 'norma',
            scope: {
              where: {
                active: 1
              }
            }
          },
          {
            relation: 'topico',
            scope: {
              where: {
                active: 1
              }
            }
          }
        ]
      }
    }).$promise.then(res => {
      this.listasverificacao = res;
      this.tableData = new this.Table({
        sorting: {
          id: 'desc'
        }
      }, {
        dataset: this.listasverificacao,
      });
      this.loaded = true;
    }).catch((error) => {
      console.log(error);
      this.loaded = true;
      this.UI.addToast('Erro ao carregar listas de verificação.');
    });
  };

  addLista = () => {
    let self = this;
    let norma = angular.copy(this.listasverificacao.norma);
    let options = {
      size: 'lg',
      template: require('./lista.dialog.html'),
      controller: ['$scope', '$dialog', 'ElvNorma', function ($scope, $dialog, ElvNorma) {
        $scope.lista = {};
        $scope.label = "Nova Lista de Verificação";

        $scope.auxNorma = {
          selected: angular.copy(norma)
        };

        ElvNorma.find({
          filter: {
            where: {
              active: true
            }
          }
        }).$promise.then(normas => {
          $scope.normas = normas;
        });

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {
      this.ElvListaVerificacao.create({
        id: 0,
        normaId: ok.auxNorma.selected.id,
        observacoes: ok.lista.observacoes,
        numeroVersao: 1,
        active: 1
      }).$promise.then((res) => {
        this.loadData();
        this.UI.addToast("Lista de Verificação adicionada com sucesso.");
      }).catch(err => {
        this.UI.addToast("Erro ao adicionar Lista de Verificação.");
        console.log(err);
      });
    }).catch((error) => {
      if (error !== 'cancel' && error !== 'escape key press' && error !== 'backdrop click')
        console.log(error);
    });
  };

  editLista = (rr) => {
    let cenas = this.UI.showWaiting();
    this.ElvNorma.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((normas) => {
      cenas.close();
      this.UI.showDialog({
        size: 'lg',
        template: require('./lista.dialog.html'),
        controller: ['$scope', '$dialog', function ($scope, $dialog) {
          $scope.label = "Editar Lista de Verificação";
          $scope.normas = normas;

          $scope.lista = {
            id: rr.id,
            observacoes: rr.observacoes,
            active: true
          };

          $scope.auxNorma = {
            selected: rr.norma
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      }).then(r => {
        if (r) {
          this.loaded = false;
          this.ElvListaVerificacao.findOne({
            filter: {
              where: {
                id: rr.id,
                active: 1
              }
            }
          }).$promise.then((listaVerificacao) => {
            listaVerificacao.normaId = r.auxNorma.selected.id;
            listaVerificacao.observacoes = r.lista.observacoes;
            listaVerificacao.$save().then((res) => {
              this.loadData();
              this.UI.addToast('Lista de Verificação atualizada com sucesso');
            }, (error) => {
              console.log(error);
              this.UI.addToast('Erro ao atualizar Lista de Verificação');
              this.loadData();
            });
          }).catch((error) => {
            console.log(error);
            this.UI.addToast('Não foi possível encontrar esta Lista de Verificação neste relatório.');
            this.loadData();
          });
        }
      })
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar Listas de Verificação. Por favor tente mais tarde.");
    });
  };

  remove = row => {
    this.UI.showConfirm("Deseja remover a lista de verificação?").then(r => {
      if (r) {
        row.active = false;
        row.$save().then(() => {
          this.UI.addToast('Lista removida com sucesso');
          this.loadData();
        }).catch(() => {
          this.UI.addToast('Não foi possível remover lista');
          this.loadData();
        });
      }
    })
  }
}

DirectoryListasVerificacaoController.$inject = ['UIService', 'AuthorizationService', 'ElvListaVerificacao', 'ElvNorma', 'NgTableParams'];
