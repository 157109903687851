import React, { useEffect, useMemo, useState } from 'react';
import { MdiIcon, Table, WaitDialog } from '@components';
import { Breadcrumbs, Link, Typography, Fab, Tooltip, Button } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { mdiUpload } from '@mdi/js';
import { useModal } from 'mui-modal-provider';
const ProcessosList = ({ $state, FuncionaService, UIService }) => {
    // ordenação das colunas
    // Ex: sortBy = ['morada:DESC']
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const { showModal } = useModal();
    const [tipoServico, setTipoServico] = useState([]);
    const [tipoInstalacao, setTipoInstalacao] = useState([]);
    const [estados, setEstados] = useState([]);
    const [tecnicos, setTecnicos] = useState([]);
    const [realizado, setRealizado] = useState([
        { id: 0, designacao: 'Não' },
        { id: 1, designacao: 'Sim' }
    ]);
    const [data, setData] = useState(); // dados dos clientes
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState([]); // filtros a aplicar na tabela
    // exemplo -> [{ id: 'nome', value: 'Pedro', type: 'contains' }, { id: 'email', value: 'gmail', type: 'contains' }]
    const [sort, setSort] = useState(() => loadParams()); // ordenação da tabela
    // exemplo -> [{id: 'ultimoContacto', desc: false}]
    const [options, setOptions] = useState({
        filter: [],
        sort: [] // exemplo -> ['id', 'nome', 'ultimoContacto', 'contactoTelefonico', 'nif', 'email', 'morada', 'localidade']
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    }); // paginação exemplo -> {pagIndex: 2, pageSize: 30}
    const importProcessos = () => {
        UIService.showDialog({
            template: require('./import/dialog.html'),
            controller: 'FunImporterController',
            controllerAs: 'vm'
        })
            .then(r => {
            UIService.addToast('Ficheiro importado com sucesso');
            fetchData();
        })
            .catch(e => {
            if (e === 'error') {
                UIService.addToast('Não foi possível importar ficheiro');
                fetchData();
            }
            else {
                fetchData();
            }
        });
    };
    // Filtragem dentro de cada coluna
    // Modo de filtro (igual a, contém, começa com... )
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    // ir para detalhes da empresa (envia id da empresa)
    const onCellView = (cell) => {
        $state.go('app.funciona.processos.details', { id: cell.id });
    };
    const initialFetch = async () => {
        if (loading) {
            // opt -> vai buscar os campos filtraveis
            const opt = await FuncionaService.options();
            //console.log(opt);
            // Tipos de serviço
            const tiposServicos = await FuncionaService.getTipoServico();
            //console.log(tiposServicos);
            setTipoServico(tiposServicos);
            // Tipos de instalação
            const tiposInstalacao = await FuncionaService.getTipoInstalacao();
            //console.log(tiposInstalacao);
            setTipoInstalacao(tiposInstalacao);
            // Técnincos
            const tecnicosList = await FuncionaService.getTecnicosFuncionario();
            //console.log(tecnicosList);
            setTecnicos(tecnicosList);
            // Tipos de instalação
            const estadosProc = await FuncionaService.getEstados();
            //console.log(estadosProc);
            setEstados(estadosProc);
            setOptions(opt);
            setLoading(false);
        }
    };
    const fetchData = async () => {
        setError(false);
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            // aux vai buscar os dados consoante os filtros
            // exemplo -> { limit: 0, page: 20, filter[{nome: '$ilike:Pedro' }, ...], sortBy:['ultimoContacto:ASC',...]}
            const aux = await FuncionaService.findAll({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            // Update URL
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            setData(aux);
        }
        catch (e) {
            console.log(e);
            setError(true);
        }
        setRefetching(false);
    };
    const columns = useMemo(() => [
        {
            accessorKey: 'numeroProcesso',
            header: 'Nº Processo',
            size: 150
        },
        {
            accessorKey: 'tarefaId',
            header: 'ID Tarefa',
            size: 150
        },
        {
            accessorKey: 'Agendamento.data',
            header: 'Data de Agendamento',
            size: 200,
            Cell: ({ renderedCellValue, row }) => (row.original.Agendamento && row.original.Agendamento.data && moment(row.original.Agendamento.data).isValid() ? moment(row.original.Agendamento.data).format('DD/MM/YYYY') : 'N/D')
        },
        {
            accessorKey: 'Agendamento.hora',
            header: 'Hora Agendada',
            size: 200,
            Cell: ({ renderedCellValue, row }) => (row.original.Agendamento && row.original.Agendamento.hora && moment(row.original.Agendamento.hora, 'HH:mm:ss').isValid() ? moment(row.original.Agendamento.hora, 'HH:mm:ss').format('HH:mm') : 'N/D')
        },
        {
            accessorKey: 'Agendamento.tecnicoId',
            exportKey: 'Agendamento.Funcionario.name',
            header: 'Técnico',
            filterSelectOptions: tecnicos.map(r => {
                return {
                    label: r.name,
                    value: r.id
                };
            }),
            size: 150,
            Cell: ({ row }) => row.original?.Agendamento?.Funcionario?.name
        },
        {
            accessorKey: 'tipoInstalacaoId',
            exportKey: 'TipoInstalacao.designacao',
            header: 'Tipo Instalação',
            filterSelectOptions: tipoInstalacao.map(r => {
                return {
                    label: r.designacao,
                    value: r.id
                };
            }),
            size: 200,
            Cell: ({ row }) => row.original?.TipoInstalacao?.designacao
        },
        {
            accessorKey: 'tipoServicoId',
            exportKey: 'TipoServico.designacao',
            header: 'Tipo Serviço',
            filterSelectOptions: tipoServico.map(r => {
                return {
                    label: r.designacao,
                    value: r.id
                };
            }),
            size: 200,
            Cell: ({ row }) => row.original?.TipoServico?.designacao ?? 'N/D'
        },
        {
            accessorKey: 'Ordemintervencao.codigo',
            header: 'OI',
            size: 100,
            Cell: ({ renderedCellValue, row }) => (<Link underline='none' color='default' onClick={() => {
                    $state.go('app.funciona.oi.details', { id: row.original.Ordemintervencao?.id });
                }}>
            {renderedCellValue}
          </Link>)
        },
        {
            accessorKey: 'realizado',
            exportKey: 'realizado',
            header: 'Realizado',
            Cell: ({ renderedCellValue, row }) => <Typography sx={{ color: row.original?.realizado === 0 ? 'red' : 'green' }}>{renderedCellValue === null ? '-' : renderedCellValue === 0 ? 'Não' : 'Sim'}</Typography>,
            size: 160
        },
        {
            accessorKey: 'estadoId',
            exportKey: 'Estado.designacao',
            header: 'Estado',
            filterSelectOptions: estados.map(r => {
                return {
                    label: r.designacao,
                    value: r.id
                };
            }),
            size: 150,
            Cell: ({ row }) => row.original?.Estado?.designacao ?? 'N/D'
        }
    ], [tipoServico, tipoInstalacao, tecnicos, estados, realizado]);
    const excelGen = async (id, array) => {
        const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
        let filename = `funciona-processos-${id ? 'selecao' : 'exportacao'}-${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
        let filters = {};
        filter.forEach(r => {
            filters[r.id] = `${getType(r.type)}:${r.value}`;
        });
        if (id) {
            filters['id'] = `$in:${array.join(',')}`;
        }
        else {
            // Aplica filtro temporal
        }
        let sorter = [];
        sort.forEach(r => {
            sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
        });
        try {
            let o = await FuncionaService.exportProcessos({
                columns: columns.map(r => {
                    return {
                        accessorKey: r.exportKey ?? r.accessorKey,
                        header: r.header
                    };
                }),
                filter: filters,
                sortBy: sorter
            });
            if (o) {
                // Decode Base64 and convert to a Blob
                const binary = atob(o.$data);
                const bytes = new Uint8Array(binary.length);
                for (let i = 0; i < binary.length; i++) {
                    bytes[i] = binary.charCodeAt(i);
                }
                // Create a Blob and save it as an Excel file
                const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }
        }
        catch (e) {
            if (e && e.data) {
                UIService.addToast(e.data.message);
            }
            else {
                UIService.addToast('Ocorreu um erro ao exportar dados!');
            }
        }
        wait.destroy();
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, filter, sort]);
    return (<Box sx={{ pl: 1, pr: 1 }}>
      <Typography variant='h5' sx={{ color: 'black', mt: 2, fontWeight: 500 }}>
        Listagem de Processos
      </Typography>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mt: 2 }}>
        <Link underline='hover' color='inherit' href='/'>
          <Typography variant='body2'>Funciona</Typography>
        </Link>
        <Typography variant='body2'>Processos</Typography>
      </Breadcrumbs>

      <Box sx={{
            mt: 2,
            borderRadius: '15px',
            border: '1px solid #e0e0e0',
            overflow: 'hidden',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
        }}>
        <Table onDoubleClick={row => {
            onCellView(row);
        }} storageKey={$state.current.name} data={data} columns={columns} initial={{
            sort: sort,
            filter: filter
        }} isLoading={refetching} options={options} onExport={async () => {
            excelGen(false, []);
        }} selectActions={selected => {
            return [
                <Button onClick={async () => {
                        if (selected.length > 0) {
                            excelGen(true, selected);
                        }
                    }}>
                Exportar
              </Button>
            ];
        }} selectable={row => {
            return true;
        }} onPaginate={setPagination} onFilter={setFilter} onSort={setSort}></Table>
      </Box>
      <Box sx={{
            position: 'fixed',
            margin: 2,
            bottom: 0,
            right: 0,
            display: 'flex',
            gap: 1
        }}>
        <Tooltip title={'Importação'}>
          <Fab color='error' onClick={async () => {
            importProcessos();
        }}>
            <MdiIcon path={mdiUpload}/>
          </Fab>
        </Tooltip>
      </Box>
    </Box>);
};
export default ProcessosList;
