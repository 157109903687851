import {routes} from './interacoes.routes';
import AtvInteracoesListController from "./directory/directory.controller";
import AtvInteracoesDetailsController from "./details/details.controller";
import AtvInteracoesFileUploaderController from "./details/files.dialog.controller";

export default angular.module('app.ativos.interacoes', [])
  .config(routes)
  .controller('AtvInteracoesListController', AtvInteracoesListController)
  .controller('AtvInteracoesDetailsController', AtvInteracoesDetailsController)
  .controller('AtvInteracoesFileUploaderController', AtvInteracoesFileUploaderController)
  .name;
