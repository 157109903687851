export default class AtvFornecedoresListController {
  constructor(UIService, AuthorizationService, AtvFornecedor, Table) {
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.AtvFornecedor = AtvFornecedor;
    this.Table = Table;
    this.loaded = false;
    this.loadData();
    this.fornecedores = [];
  }

  loadData = () => {
    this.loaded = false;
    this.AtvFornecedor.find({
      filter: {
        where: {
          active: true
        },
        include: [{
          relation: 'ativos',
          scope: {
            fields:{id: true},
            where: {
              active: 1
            }
          }
        }, {
          relation: 'fornecedoresPrimavera',
          scope: {
            fields: {id: true},
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then(f => {
      this.fornecedores = f;
      this.tableData = new this.Table({
        sorting: {
          id: 'desc'
        }
      }, {
        dataset: this.fornecedores,
      });
      this.loaded = true;
    }).catch((error) => {
      console.log(error);
      this.loaded = true;
      this.UI.addToast('Erro ao carregar fornecedores');
    });
  };
}

AtvFornecedoresListController.$inject = ['UIService', 'AuthorizationService', 'AtvFornecedor', 'NgTableParams'];
