import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import DetailsBox from "../../../interface/components/DetailsBox";
import { Box, CircularProgress } from "@mui/material";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#049a9b" : "#fff",
    // ...theme.typography.body2,
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    height: "100%",
}));
const showDate = (date, format = "DD/MM/YYYY") => {
    if (moment(date).isValid()) {
        return moment(date).format(format);
    }
    else
        return '-';
};
const getDetailsEntryList = (relatorio) => (relatorio == null) ? [] :
    [
        { title: "Nº Certificado", content: relatorio.numeroCertificado || 'N/D' },
        { title: "Data de Submissão", content: showDate(relatorio.dataSubmissao) },
        { title: "Versão", content: relatorio.versao },
        { title: "Técnico", content: relatorio.funcionario?.name || 'N/D' },
        { title: "Estado", content: relatorio.resultado || 'N/D' },
        { title: "Data de Validação", content: showDate(relatorio.dataValidacao) },
        { title: "Observações", content: relatorio.observacoes || 'N/D' },
    ];
const getProcessoDetailsEntryList = (processo) => (processo == null) ? [] :
    [
        { title: "Nº Processo", content: processo.numeroProcesso },
        { title: "Data de Importação", content: showDate(processo.dataImportacao) },
        { title: "Estado", content: processo.estado.designacao },
        { title: "Tipo de Gás", content: processo.tipoGas.designacao },
        { title: "Tipo de Instalação", content: processo.tipoInstalacao.designacao },
        { title: "Tipo de Inspeção", content: processo.tipoInspecao.designacao },
        { title: "Observações", content: processo.observacoes || '-' }
    ];
const getClienteDetailsEntryList = (processo) => (processo == null) ? [] :
    [
        { title: "Nome", content: processo.nomeCliente },
        { title: "NIF", content: processo.nifCliente },
        { title: "Email", content: processo.emailCliente },
        { title: "Morada", content: processo.moradaCliente },
        { title: "Código Postal", content: processo.cep },
        { title: "Concelho", content: processo.concelhoCliente },
        { title: "Distrito", content: processo.distritoCliente }
    ];
const getAgendamentoDetailsEntryList = (agendamento) => (agendamento == null) ? [] :
    [
        { title: "Data de Inspeção", content: showDate(agendamento.data, 'DD/MM/YYYY') },
        { title: "Hora", content: agendamento.hora.substring(0, 5) },
        { title: "Técnico", content: agendamento.tecnico.name }
    ];
const GasRelatorioDetailsView = ({ $state, GasService }) => {
    const [id, setId] = useState($state.params.id);
    const [isLoading, setIsLoading] = useState(true);
    const [relatorio, setRelatorio] = useState(null);
    const [detailsEntryList, setDetailsEntryList] = useState([]);
    const [processoDetailsEntryList, setProcessoDetailsEntryList] = useState([]);
    const [clienteDetailsEntryList, setClienteDetailsEntryList] = useState([]);
    const [agendamentoDetailsEntryList, setAgendamentoDetailsEntryList] = useState([]);
    const [emptyProcesso, setEmptyProcesso] = useState(true);
    const [emptyAgendamento, setEmptyAgendamento] = useState(true);
    const fetchData = async () => {
        setIsLoading(true);
        let r;
        try {
            r = await findRelatorio(id);
        }
        catch (error) {
            setRelatorio(null);
            setIsLoading(false);
            throw error;
        }
        setRelatorio(r);
        setDetailsEntryList(getDetailsEntryList(r));
        if (r.processo != null) {
            setProcessoDetailsEntryList(getProcessoDetailsEntryList(r.processo));
            setClienteDetailsEntryList(getClienteDetailsEntryList(r.processo));
            setEmptyProcesso(false);
        }
        else {
            setProcessoDetailsEntryList(getProcessoDetailsEntryList(null));
            setClienteDetailsEntryList(getClienteDetailsEntryList(null));
            setEmptyProcesso(true);
        }
        if (r.agendamento != null) {
            setAgendamentoDetailsEntryList(getAgendamentoDetailsEntryList(r.agendamento));
            setEmptyAgendamento(false);
        }
        else {
            setAgendamentoDetailsEntryList(getAgendamentoDetailsEntryList(null));
            setEmptyAgendamento(true);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    const findRelatorio = async (id) => {
        let data;
        try {
            data = await GasService.findRelatorio(id);
            console.log(data);
        }
        catch (error) {
            console.log(error);
            throw error;
        }
        return data;
    };
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = "Não há registos a serem exibidos";
    return (<Fragment>
      <h1 className="section-title mt-2" id="services">
        <span>Relatório de Processo {relatorio && relatorio.processo && relatorio.processo.numeroProcesso}</span>
      </h1>
      <ol className="breadcrumb text-left">
        <li>Gás</li>
        <li className="active">Relatórios</li>
      </ol>
      {isLoading ?
            (<Box sx={{ display: "flex", justifyContent: "center", height: "2vh", }}>
          <CircularProgress size={60} color="primary"/>
        </Box>) :
            <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 2 } }}>
            <Item>
              {<DetailsBox title="Detalhes" entries={detailsEntryList}></DetailsBox>}
            </Item>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 2 } }}>
            <Item>
              {<DetailsBox title="Processo" emptyDetails={emptyProcesso} emptyMessage={emptyProcesso ? "Sem processo definido" : null} entries={processoDetailsEntryList}></DetailsBox>}
            </Item>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 2 } }}>
            <Item>
              {<DetailsBox title="Cliente" emptyDetails={emptyProcesso} emptyMessage={emptyProcesso ? "Sem processo definido" : null} entries={clienteDetailsEntryList}></DetailsBox>}
            </Item>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 2 } }}>
            <Item>
              {<DetailsBox title="Agendamento" emptyDetails={emptyAgendamento} emptyMessage={emptyAgendamento ? "Sem agendamento definido" : null} entries={agendamentoDetailsEntryList}></DetailsBox>}
            </Item>
          </Grid>
        </Grid>}
    </Fragment>);
};
export default GasRelatorioDetailsView;
