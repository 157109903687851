import angular from 'angular';
import {routes} from './interventions.routes';

import SusEneNewInterventionController from "./new/new.controller";
import SusEneListInterventionController from "./directory/directory.controller";
import SusEneDetailInterventionController from "./details/details.controller";


export default angular.module('app.susene.interventions', [])
  .config(routes)
  .controller('SusEneNewInterventionController', SusEneNewInterventionController)
  .controller('SusEneListInterventionController', SusEneListInterventionController)
  .controller('SusEneDetailInterventionController', SusEneDetailInterventionController)
  .name;
