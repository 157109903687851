export default class OblProdutosListController {
  constructor($state, Group, OblProduto, OblFamilia, OblFornecedor, OblGrupo, UIService) {
    this.$state = $state;
    this.Group = Group;
    this.OblProduto = OblProduto;
    this.OblFamilia = OblFamilia;
    this.OblFornecedor = OblFornecedor;
    this.OblGrupo = OblGrupo;
    this.UI = UIService;

    this.opt = $state.params;
    this.filtersLoaded = [0, 0, 0];

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.opt = this.opt || this.defaultOpt;

    this.displayColumns = [{
      displayName: 'ID SGI',
      name: 'id',
      visible: false,
      sortable: true
    },
    {
      displayName: 'Cód. Artigo',
      name: 'primaveraId',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Designação',
      name: 'designacao',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Preço Compra',
      name: 'precoCompra',
      visible: true,
      sortable: false
    },
    {
      displayName: 'Preço Venda',
      name: 'precoVenda',
      visible: true,
      sortable: false
    },
    {
      displayName: 'Preço Venda (€+IVA)',
      name: 'valor',
      visible: true,
      sortable: false
    },
    {
      displayName: 'Fornecedor',
      name: 'fornecedor',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Família',
      name: 'familia',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Grupo',
      name: 'grupo',
      visible: true,
      sortable: true
    }
    ];

    this.columns = [
      {
        id: 'OblProduto.id',
        name: 'ID SGI',
        type: 'o'
      },
      {
        id: 'OblProduto.designacao',
        name: 'Designação',
        type: 'o'
      },
      {
        id: 'OblProduto.primaveraId',
        name: 'Cód. Artigo',
        type: 'o'
      },
      {
        id: 'OblFamilia.id',
        name: 'Família',
        type: 's',
        list: this.familias
      },
      {
        id: 'OblGrupo.id',
        name: 'Grupo',
        type: 's',
        list: this.grupos
      },
      {
        id: 'OblFornecedor.id',
        name: 'Fornecedor',
        type: 's',
        list: this.fornecedores
      },
    ]

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 'o'
    },
    {
      val: 'c',
      name: 'Começa por',
      type: 'o'
    },
    {
      val: 'd',
      name: 'Termina com',
      type: 'o'
    },
    {
      val: 'e',
      name: 'Contém',
      type: 'o'
    },
    {
      val: 'a',
      name: 'Igual (=)',
      type: 'd'
    },
    {
      val: 'b',
      name: 'Diferente de (≠)',
      type: 'd'
    },
    {
      val: 'c',
      name: 'Posterior a (>)',
      type: 'd'
    },
    {
      val: 'd',
      name: 'Anterior a (<)',
      type: 'd'
    },
    {
      val: 'e',
      name: 'Posterior ou igual (≥)',
      type: 'd'
    },
    {
      val: 'f',
      name: 'Anterior ou igual (≤)',
      type: 'd'
    },
    {
      val: 'a',
      name: 'Igual',
      type: 's'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 's'
    }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('OblProdutoDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('OblProdutoDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('OblProdutoFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('OblProdutoFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('OblProdutoFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'OblGrupo.id')
          f.column.selected.list = this.grupos;
        if (f.column.selected.id === 'OblFamilia.id')
          f.column.selected.list = this.familias;
        if (f.column.selected.id === 'OblFornecedor.id')
          f.column.selected.list = this.fornecedores;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getProcessos();
  }

  clearFilter = () => {
    localStorage.removeItem('OblProdutosFilter');
    this.customFilters = [];
    this.getProcessos();
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.pedidos, x => x.selected), x => { return { key: 'OblProduto.id', value: x.id }; });
    let whereFields = this.setWhereFields(this.customFilters, { 'OblProduto.active': 1 }, orsLiteral);
    this.OblProduto.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'OblProduto.id as id',
          'OblProduto.designacao as designacao',
          'OblProduto.primaveraId as primaveraId',
          'OblProduto.precoVenda as precoVenda',
          'OblProduto.precoCompra as precoCompra',
          'OblProduto.valor as valor',
          'OblProduto.fornecedorId as fornecedorId',
          'OblFornecedor.nome as fornecedor',
          'OblProduto.unidade as unidade',
          'OblProduto.familiaId as familiaId',
          'OblFamilia.designacao as familia',
          'OblProduto.grupoId as grupoId',
          'OblGrupo.designacao as grupo'
        ],
        aliases: [undefined, undefined, undefined, undefined],
        from: ['OblProduto', 'OblFornecedor', 'OblFamilia', 'OblGrupo'],
        referencesOrigin: [undefined, 'OblProduto.fornecedorId', 'OblProduto.familiaId', 'OblProduto.grupoId'],
        references: [undefined, 'OblFornecedor.id', 'OblFamilia.id', 'OblGrupo.id'],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'export.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.UI.addToast("Não foi possível exportar produtos. Por favor tente mais tarde.");
    });
  };

  exportProcessos = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, { 'OblProduto.active': 1 });
    this.OblProduto.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'OblProduto.id as id',
          'OblProduto.designacao as designacao',
          'OblProduto.primaveraId as primaveraId',
          'OblProduto.precoVenda as precoVenda',
          'OblProduto.precoCompra as precoCompra',
          'OblProduto.valor as valor',
          'OblProduto.fornecedorId as fornecedorId',
          'OblFornecedor.nome as fornecedor',
          'OblProduto.unidade as unidade',
          'OblProduto.familiaId as familiaId',
          'OblFamilia.designacao as familia',
          'OblProduto.grupoId as grupoId',
          'OblGrupo.designacao as grupo'
        ],
        aliases: [undefined, undefined, undefined, undefined],
        from: ['OblProduto', 'OblFornecedor', 'OblFamilia', 'OblGrupo'],
        referencesOrigin: [undefined, 'OblProduto.fornecedorId', 'OblProduto.familiaId', 'OblProduto.grupoId'],
        references: [undefined, 'OblFornecedor.id', 'OblFamilia.id', 'OblGrupo.id'],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'export.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar produtos. Por favor tente mais tarde.");
    });
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  addProduto = () => {
    this.UI.showDialog({
      template: require('./produto.dialog.html'),
      controller: ['$scope', 'PRIOBLERFornecedores', 'OblFornecedor', 'PRIOBLERArtigo', 'OblFamilia', 'OblGrupo', ($scope, PRIFornecedor, Fornecedor, PRIArtigo, Familia, Grupo) => {
        $scope.items = [];
        $scope.data = {};
        $scope.aux = {};
        $scope.loaded = false;
        $scope.itemBypass = false;

        $scope.itemLoaded = false;

        $scope.noPrimavera = () => {
          $scope.itemBypass = true;
          $scope.itemLoaded = true;
          $scope.data = {
            iva: 23
          }
        }

        $scope.addFornecedor = () => {
          this.UI.showDialog({
            template: require('./fornecedor.dialog.html'),
            controller: ['$scope', '$dialog', ($scopeF, $dialog) => {
              $scopeF.data = {
                id: 0,
                active: true
              };
              $scopeF.ok = () => {
                $dialog.close($scopeF.data);
              }
              $scopeF.cancel = () => {
                $dialog.dismiss();
              }
            }]
          }).then(r => {
            Fornecedor.create(r).$promise.then(_ => {
              $scope.fornecedores.push(_);
              $scope.aux.fornecedor = _;
              this.UI.addToast("Fornecedor adicionado");
            }).catch(e => {
              console.log(e);
            })
          });
        }

        Fornecedor.find({ filter: { where: { active: true } } }).$promise.then(i => {
          Familia.find({ filter: { where: { active: true } } }).$promise.then(f => {
            Grupo.find({ filter: { where: { active: true } } }).$promise.then(g => {
              $scope.fornecedores = i;
              $scope.familias = f;
              $scope.grupos = g;
              $scope.loaded = true;
            }).catch(e => { });
          }).catch(e => { });
        }).catch(e => { });


        $scope.$watch(() => $scope.data, (val) => {
          if (val.precoVenda && val.iva) {
            let number = val.precoVenda * ((100 + val.iva) / 100);
            val.valor = Math.round((number + Number.EPSILON) * Math.pow(10, 2)) / Math.pow(10, 2);
          }
        }, true);

        $scope.$watch(() => $scope.aux.artigo, (val) => {
          if (val) {
            this.OblProduto.find({
              filter: {
                where: {
                  primaveraId: val.artigo
                },
                fields: {
                  id: true,
                  primaveraId: true
                }
              }
            }).$promise.then(r => {
              if (r.length > 0) {
                this.UI.addToast("O produto já existe em sistema");
                $scope.$dismiss();
                return;
              }
              if (val.moeda) {
                $scope.data.precoVenda = Number(val.moeda.pvp1);
                $scope.data.precoCompra = Number(val.pcultimo);
              }
              $scope.data.iva = Number(val.iva || 23);
              $scope.data.primaveraId = angular.copy(val.artigo);
              $scope.data.designacao = angular.copy(val.descricao);
              $scope.data.unidade = angular.copy(val.unidadebase.toLowerCase());
              // Procura familia e grupo
              if (val.familia) {
                let f = $scope.familias.find(r => {
                  let a = r.designacao.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_") + "_";
                  let b = val.familia.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_") + "_";
                  if (a.includes(b)) {
                    return r;
                  }
                });
                if (f) {
                  $scope.aux.familia = f;
                }
              }
              if (val.subfamilia) {
                let f = $scope.grupos.find(r => {
                  let a = r.designacao.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_") + "_";
                  let b = val.subfamilia.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_") + "_";
                  if (b.includes(a)) {
                    return r;
                  }
                });
                if (f) {
                  $scope.aux.grupo = f;
                }
              }
              // Info fornecedor
              Fornecedor.find({
                filter: {
                  where: {
                    primaveraId: val.ultimofornecedor
                  }
                }
              }).$promise.then(r => {
                if (r.length > 0) {
                  $scope.aux.fornecedor = r[0];
                } else {
                  // Cria info a partir do primavera
                  PRIFornecedor.find({
                    filter: {
                      where: {
                        fornecedor: val.ultimofornecedor || "FX00001"
                      }
                    }
                  }).$promise.then(r => {
                    if (r.length > 0) {
                      let f = r[0];
                      Fornecedor.create({
                        id: 0,
                        nome: f.nome,
                        primaveraId: val.ultimofornecedor,
                        active: 1,
                        dataSincronizacao: moment()
                      }).$promise.then(c => {
                        $scope.aux.fornecedor = c;
                      })
                    } else {
                      // Erro?
                    }
                  });
                }
              })
              // Família
              // Grupo
              $scope.itemLoaded = true;
            });
          } else {
            $scope.itemLoaded = false;
            $scope.data = {};
          }
        }, true);

        $scope.fetch = ($select, $evt) => {
          if (!$evt) {
            $scope.items = [];
          } else {
            $evt.stopPropagation();
            $evt.preventDefault();
          }
          if ($select.search.length < 3) return;
          PRIArtigo.find({
            filter: {
              where: {
                artigo: {
                  like: `%${$select.search}%`
                },
                tipoartigo: "3"
              },
              limit: 100,
              include: 'moeda'
            }
          }).$promise.then(r => {
            $scope.items = r;
          });
        }

        $scope.loaded = true;

        $scope.ok = () => {
          let o = angular.copy($scope.data);
          o.iva = (100 + o.iva) / 100;
          o.descontoFornecedor /= 100;
          o.familiaId = $scope.aux.familia.id;
          o.grupoId = $scope.aux.grupo.id;
          o.fornecedorId = $scope.aux.fornecedor.id;
          $scope.$close(o);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(r => {
      if (r) {
        r.active = true;
        r.id = 0;
        this.OblProduto.create(r).$promise.then(_ => {
          this.UI.addToast("Produto adicionado com sucesso");
          this.getProcessos();
        }).catch(e => {
          console.log(e);
          this.UI.addToast("Ocorreu um eror ao adicionar produto");
          this.getProcessos();
        })
      }
    });
  }

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  loadData = () => {
    this.OblFamilia.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then(r => {
      this.familias = [];
      r.forEach(a => {
        this.familias.push({
          id: a.id,
          name: a.designacao
        });
      });
      this.filtersLoaded[0] = 1;
    }).catch(e => {
      this.familias = [];
      this.filtersLoaded[0] = 0;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Famílias).");
    });

    this.OblGrupo.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then(r => {
      this.grupos = [];
      r.forEach(a => {
        this.grupos.push({
          id: a.id,
          name: a.designacao
        });
      });
      this.filtersLoaded[1] = 1;
    }).catch(e => {
      this.grupos = [];
      this.filtersLoaded[1] = 0;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Grupos).");
    });

    this.OblFornecedor.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then((r) => {
      this.fornecedores = [];
      r.forEach(a => {
        this.fornecedores.push({
          id: a.id,
          name: a.nome
        });
      });
      this.filtersLoaded[2] = 1;
    }).catch(err => {
      this.fornecedores = [];
      this.filtersLoaded[2] = 0;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Fornecedores).");
    });
  }

  getProcessos = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {
      'OblProduto.active': 1
    });

    this.OblProduto.table2({
      params: {
        fields: [
          'OblProduto.id as id',
          'OblProduto.designacao as designacao',
          'OblProduto.primaveraId as primaveraId',
          'OblProduto.precoVenda as precoVenda',
          'OblProduto.precoCompra as precoCompra',
          'OblProduto.valor as valor',
          'OblProduto.fornecedorId as fornecedorId',
          'OblFornecedor.nome as fornecedor',
          'OblProduto.unidade as unidade',
          'OblProduto.familiaId as familiaId',
          'OblFamilia.designacao as familia',
          'OblProduto.grupoId as grupoId',
          'OblGrupo.designacao as grupo'
        ],
        aliases: [undefined, undefined, undefined, undefined],
        from: ['OblProduto', 'OblFornecedor', 'OblFamilia', 'OblGrupo'],
        referencesOrigin: [undefined, 'OblProduto.fornecedorId', 'OblProduto.familiaId', 'OblProduto.grupoId'],
        references: [undefined, 'OblFornecedor.id', 'OblFamilia.id', 'OblGrupo.id'],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      res.data.forEach(i => {
        if (i.cp4 && !i.cp3) {
          i.codigoPostal = i.cp4;
        }
        if (i.cp4 && i.cp3) {
          i.codigoPostal = `${('0000' + i.cp4).slice(-4)}-${('000' + i.cp3).slice(-3)}`;
        }
        if (i.cp3 != null && !isNaN(i.cp3))
          i.cp3 = ('000' + i.cp3).slice(-3);
      });

      this.pedidos = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((error) => {
      console.log(error);
    });
  };

  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.obler.produtos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.obler.produtos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.obler.produtos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);
    console.log(result);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.familias.length && !this.fornecedores.length && !this.grupos.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'OblFornecedor.id')
        f.list = this.fornecedores;
      if (f.id === 'OblGrupo.id')
        f.list = this.grupos;
      if (f.id === 'OblFamilia.id')
        f.list = this.familias;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'OblFornecedor.id')
          f.column.selected.list = this.fornecedores;
        if (f.column.selected.id === 'OblGrupo.id')
          f.column.selected.list = this.grupos;
        if (f.column.selected.id === 'OblFamilia.id')
          f.column.selected.list = this.familias;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('OblProdutosFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.obler.produtos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getProcessos();
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('OblProdutosDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  hasSelect = () => {
    return _.some(this.pedidos, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.pedidos.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.pedidos.length;
    } else { // Remove all selections
      this.pedidos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.pedidos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.pedidos, r => r.selected).length;
    this.allSelected = this.nSelected >= this.pedidos.length;
    this.everythingSelected = false;
  };
}

OblProdutosListController.$inject = ['$state', 'Group', 'OblProduto', 'OblFamilia', 'OblFornecedor', 'OblGrupo', 'UIService']
