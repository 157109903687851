export default class DirectoryNormasController {
  constructor(UIService, AuthorizationService, ElvNorma, Table) {
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.ElvNorma = ElvNorma;
    this.Table = Table;
    this.loaded = false;
    this.loadData();
    this.normas = [];
  }

  loadData = () => {
    this.loaded = false;
    this.ElvNorma.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(res => {
      this.normas = res;
      this.tableData = new this.Table({
        sorting: {
          id: 'desc'
        }
      }, {
        dataset: this.normas,
      });
      this.loaded = true;
    }).catch((error) => {
      console.log(error);
      this.loaded = true;
      this.UI.addToast('Erro ao carregar normas.');
    });
  };

  addNorma = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./norma.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.label = "Adicionar Norma";

        $scope.norma = {
          id: 0,
          active: true,
          designacao: ""
        };

        $scope.ok = () => {
          $scope.$close($scope.norma);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    }).then(res => {
      if (res) {
        this.ElvNorma.create(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Norma inserida com sucesso!');
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Erro ao adicionar norma.');
        })
      }
    });
  };

  editNorma = (rr) => {
    let cenas = this.UI.showWaiting();
    this.ElvNorma.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((rcm) => {
      cenas.close();
      this.UI.showDialog({
        size: 'lg',
        template: require('./norma.dialog.html'),
        controller: ['$scope', '$dialog', function ($scope, $dialog) {
          $scope.label = "Editar Norma";

          $scope.norma = {
            id: rr.id,
            designacao: rr.designacao,
            active: true
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      }).then(r => {
        if (r) {
          this.loaded = false;
          this.ElvNorma.findOne({
            filter: {
              where: {
                id: rr.id,
                active: 1
              }
            }
          }).$promise.then((norma) => {
            norma.designacao = r.norma.designacao;
            norma.$save().then((res) => {
              this.loadData();
              this.UI.addToast('Norma atualizada com sucesso!');
            }, (error) => {
              console.log(error);
              this.UI.addToast('Erro ao atualizar norma.');
              this.loadData();
            });
          }).catch((error) => {
            console.log(error);
            this.UI.addToast('Não foi possível encontrar esta norma.');
            this.loadData();
          });
        }
      })
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar normas. Por favor tente mais tarde.");
    });
  };

  removeNorma = (norma) => {
    let confirm = this.UI.showConfirm('Deseja remover esta norma?');
    confirm.then(res => {
      if (res) {
        norma.active = 0;
        this.ElvNorma.upsert(norma).$promise.then(() => {
          this.loadData();
          this.UI.addToast('A norma foi removida com sucesso.');
        });
      }
    })
  };

}

DirectoryNormasController.$inject = ['UIService', 'AuthorizationService', 'ElvNorma', 'NgTableParams'];
