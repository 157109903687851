export default class FunProcessosDetailsController {
  constructor($state, FunProcesso, FunAlteracaoestadoProcesso, FunOrdemintervencao, FunTipoServico, FunMotivo, UIService, AuthenticationService, AuthorizationService) {
    this.$state = $state;
    this.FunProcesso = FunProcesso;
    this.FunAlteracaoestadoProcesso = FunAlteracaoestadoProcesso;
    this.FunOrdemintervencao = FunOrdemintervencao;
    this.FunTipoServico = FunTipoServico;
    this.FunMotivo = FunMotivo;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.params = $state.params;
    this.showEstadosProcesso = false;
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.FunProcesso.getProcesso({id: this.params.id}).$promise.then(r => {
      this.data = r;
      this.loaded = true;
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Processo desconhecido / sem permissões");
      this.$state.go('app.funciona.processos.list');
    });
  };

  toggleEstadosProcesso = (x) => {
    this.showEstadosProcesso = !this.showEstadosProcesso;
  };

  canEditRelatorio = () => {
    if (!this.data)
      return false;

    if (this.AuthorizationService.canPerform('funcionaEditarTodosRelatorios'))
      return true;
    if (this.data.funcionarioId === this.Auth.getId() && this.AuthorizationService.canPerform('funcionaEditarRelatorios')) {
      if (this.data.estadoId === 10) { // Relatório Concluído - only allow to change if within 24 hours
        return moment().diff(moment(this.data.dataRelatorio), 'days') < 1;
      } else { // If relatorio not concluido, we can edit it
        return true;
      }
    } else {
      // Sem permissões para editar relatório
      return false;
    }
  };

  editRelatorio = () => {
    // Only admins and self can edit relatorio
    if (this.canEditRelatorio()) {
      let wait = this.UI.showWaiting();
      this.FunTipoServico.find({
        filter: {
          where: {
            active: 1
          },
        }
      }).$promise.then((tiposServico) => {
        this.FunMotivo.getMotivos().$promise.then((motivos) => {
          wait.close();
          let options = {
            size: 'lg',
            template: require('./relatorio.dialog.html'),
            controller: ['$dialog', '$scope', (dialog, scope) => {
              scope.label = `Editar Relatório ${this.data.tarefaId}`;
              scope.simnao = [{name: "Sim", value: 1}, {name: "Não", value: 0}];
              scope.tiposServico = tiposServico;
              scope.motivos = motivos;
              scope.motivosToSelect = (this.data.realizado != null) ? (scope.motivos.filter(x => x.realizada === this.data.realizado)) : []; // When the previous is selected, choose motivos that are related to it
              scope.justificacao = {text: (this.data.justificacao != null ? this.data.justificacao : undefined)};
              scope.observacoes = this.data.observacoes != null ? this.data.observacoes : undefined;
              scope.oldRealizado = this.data.realizado;

              scope.auxTipoServico = {
                selected: (this.data.tipoServicoId ? scope.tiposServico.find(x => x.id === this.data.tipoServicoId) : undefined),
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };

              scope.auxRenovacao = {
                selected: scope.simnao.find(x => x.value === this.data.renovacao),
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };

              scope.auxRealizado = {
                selected: scope.simnao.find(x => x.value === this.data.realizado),
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };

              scope.auxPediuReposicao = {
                selected: scope.simnao.find(x => x.value === this.data.pediuReposicao),
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };

              scope.auxMotivo = {
                selected: (this.data.motivoId ? scope.motivos.find(x => x.id === this.data.motivoId) : undefined),
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };

              // When Realizado is selected
              scope.onRealizadoSelected = (item) => {
                if (item.value !== scope.oldRealizado) { // Only reset when changing value
                  scope.oldRealizado = item.value;
                  // Clear previous motivo and justificacao choice
                  scope.auxMotivo.selected = undefined;
                  scope.auxPediuReposicao.selected = undefined;
                  scope.justificacao.text = undefined;
                  // Choose motivos for not realizada or realizada depending on what you chose
                  scope.motivosToSelect = scope.motivos.filter(x => x.realizada === item.value);
                }
              };

              //Infinite Scroll Magic
              scope.addMoreItems = (infiniteScroll) => {
                infiniteScroll.currentItems += infiniteScroll.numToAdd;
              };

              scope.ok = () => {
                dialog.close(scope);
              };

              scope.cancel = () => {
                dialog.dismiss('cancel');
              };
            }]
          };

          let modal = this.UI.showDialog(options);

          modal.then((ok) => {
            this.loaded = false;
            // Só verifica se pediu reposição caso não tenha sido realizada
            let pediuReposicao = (ok.auxRealizado.selected.value === 0 ? ok.auxPediuReposicao.selected.value : null);
            this.FunProcesso.createRelatorio({
              params: {
                id: this.data.id,
                realizado: ok.auxRealizado.selected.value,
                pediuReposicao: pediuReposicao,
                tipoServicoChanged: this.data.tipoServicoId !== ok.auxTipoServico.selected.id,
                tipoServicoId: ok.auxTipoServico.selected.id,
                motivoId: ok.auxMotivo.selected ? ok.auxMotivo.selected.id : null,
                justificacao: ok.justificacao.text || null,
                observacoes: ok.observacoes || null
              }
            }).$promise.then((r) => {
              if (r.result) {
                this.UI.addToast("Relatório guardado com sucesso");
                this.loadData();
              } else {
                let msg = r.extra || "Ocorreu um erro ao guardar relatório";
                let alert = this.UI.showAlert(msg);
                alert.finally(() => {
                  this.loadData();
                });
              }
            }).catch(error => {
              console.log(error);
              this.UI.addToast("Erro na edição de relatório. Verifique a ligação.");
              this.loadData();
            });
          }).catch(() => {
          });
        }).catch(err => {
          console.log(err);
          this.UI.addToast("Erro ao carregar Motivos. Verifique a sua ligação");
        });
      }).catch(err => {
        console.log(err);
        this.UI.addToast("Erro ao carregar Tipos de Serviço. Verifique a sua ligação");
      });
    } else {
      // Do nothing. This function shouldn't work here anyway
    }
  };

  setTimelineColor = (h) => {
    switch (h.EstadoFinal.id) {
      case 1: // Atribuição
        return "project-info";
      case 2: // Primeiro Agendamento
        return "project-standby";
      case 3: // Agendado
        return "project-standby";
      case 10: // Relatório Concluído
        return "project-info";
      default:
        return "project-info";
    }
  };


}

FunProcessosDetailsController.$inject = ['$state', 'FunProcesso', 'FunAlteracaoestadoProcesso', 'FunOrdemintervencao', 'FunTipoServico', 'FunMotivo', 'UIService', 'AuthenticationService', 'AuthorizationService'];
