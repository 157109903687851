export function AuthenticationHandler($q, $state, AuthenticationService) {
  return {
    // Intercept 401s and redirect's the user to login page
    responseError: (response) => {
      if (response.status === 401) {
        // Remove interception error if user is in the authentication routes so we can return him
        if (!$state.current.name.indexOf('auth') >= 0) {
          AuthenticationService.clearUser();
          $state.go('auth.login');
        }
      }
      return $q.reject(response);
    }
  };
};

AuthenticationHandler.$inject = ['$q', '$state', 'AuthenticationService'];
