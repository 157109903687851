import angular from 'angular';
import {routes} from './interventions.routes';

import NewInterventionController from "./new/new.controller";
import LmeListInterventionController from "./directory/directory.controller";
import LmeDetailInterventionController from "./details/details.controller";


export default angular.module('app.laboratory.interventions', [])
  .config(routes)
  .controller('NewInterventionController', NewInterventionController)
  .controller('LmeListInterventionController', LmeListInterventionController)
  .controller('LmeDetailInterventionController', LmeDetailInterventionController)
  .name;
