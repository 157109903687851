import angular from 'angular';
import { routes } from './routes';
import { react2angular } from 'react2angular';
import GasRelatorioDetailstView from './details';
import GasRelatoriosListView from './list';
import { useContext } from '@components';
export default angular
    .module('app.gas.relatorios', [])
    .config(routes)
    .component('gasRelatorioDetailsView', react2angular(GasRelatorioDetailstView, [], ['$state', 'GasService']))
    .component('gasRelatoriosListView', react2angular(useContext.use(GasRelatoriosListView), [], ['$state', 'GasService', 'UIService'])).name;
