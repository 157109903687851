let XLSX = require('xlsx');
import _ from 'lodash';
import moment from 'moment';
import 'regenerator-runtime/runtime';

export default class GasImporterController {
  constructor($q, $http, $scope, $dialog, UIService, FileUploader, Funcionario, AuthenticationService, GasProcesso, GasTecnico, GasAgendamento, GasTipoInstalacao, GasOrd, GasService) {
    this.$q = $q;
    this.$http = $http;
    this.$scope = $scope;
    this.$dialog = $dialog;
    this.UI = UIService;
    this.Funcionario = Funcionario;
    this.Auth = AuthenticationService;
    this.GasProcesso = GasProcesso;
    this.GasTecnico = GasTecnico;
    this.GasAgendamento = GasAgendamento;
    this.GasTipoInstalacao = GasTipoInstalacao;
    this.GasOrd = GasOrd;
    this.GasService = GasService;
    console.log(this.GasService);
    this.uploader = new FileUploader({
      url: '/',
      queueLimit: 1
    });

    // Variáveis de controlo
    this.step = 1; // 1 - Carreg. ficheiro, 2 - Validar dados, 3 - Submissão de dados, 4 - Concluído, 5 - Erros encontrados
    this.messages = []; // Caso haja algum problema, serão adicionadas mensagens com o erro
    this.ignored = 0; // Numero de linhas ignoradas (é ignorado quando a data de agendamento < data de hoje)
    this.data = [];

    // Fields required in cabeçalho
    this.requiredFields = [
      'ID Ordem Serviço',
      'Tipo de Instalação',
      'Data de Atribuição',
      'Rua',
      'Concelho',
      'Cidade',
      'Distrito',
      'Cliente',
      'NIF',
      'CEP',
      'Distribuidor vai acompanhar serviço?',
      'Início Agendamento',
      'Fim Agendamento',
      'ID Tarefa',
      'ID Técnico Atribuído',
      'Total na Factura c/IVA',
      'ORD',
      'Técnico Distribuidor'
    ];

    this.index = []; // Cabeçalho do ficheiro

    this.uploader.filters.push({
      name: 'isSheet',
      fn: function (item, options) {
        return '|application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|'.indexOf(item.type) !== -1;
      }
    });

    this.uploader.onAfterAddingFile = file => {
      let reader = new FileReader();
      reader.onload = e => {
        let data = e.target.result;
        let wb = XLSX.read(data, {
          type: 'binary',
          cellDates: true
        });
        if (wb.SheetNames.length > 0) {
          let rowObj = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });
          // Remove linhas vazias
          _.remove(rowObj, c => c.length == 0);
          // Remove primeira linha (cabeçalho)
          this.index = angular.copy(rowObj[0]);

          let resultCabecalho = this.checkCabecalho();

          if (resultCabecalho.ok) {
            rowObj.shift();
            let data = [];
            rowObj.forEach((row, i) => {
              // Valida a linha (Verifica se os dados existem)
              let result = this.validate(row, i);
              if (result.success) {
                this.data.push(result.row);
              } else {
                if (this.messages.find(r => r === result.message) === undefined) {
                  this.messages.push(result.message);
                }
              }
            });

            if (this.messages.length > 0) {
              this.changeStep(5);
            } else {
              // console.log("Linhas encontradas:" + this.data.length);
              // console.log("Nenhum problema encontrado na leitura do ficheiro");
              this.changeStep(2);
            }
          } else {
            if (resultCabecalho.missing.length > 0) {
              let errorMessage = 'O ficheiro submetido tem cabeçalho com os seguintes campos obrigatórios em falta: ';
              resultCabecalho.missing.forEach(m => (errorMessage += ` "${m}" `));
              this.messages.push(errorMessage);
            } else {
              this.messages.push('Erro no cabeçalho do ficheiro');
            }
            this.changeStep(5);
          }
        } else {
          this.messages.push('O ficheiro submetido não tem folhas para importar');
          this.changeStep(5);
        }
      };
      reader.readAsBinaryString(file._file);
    };

    this.loadData();
  }

  // Returns the array index of cabeçalho for the value
  getIndex = value => this.index.indexOf(value);

  // Check if all cabecalho fields exist
  checkCabecalho = () => {
    let result = { ok: true, missing: [] };
    this.requiredFields.forEach(f => {
      if (this.getIndex(f) === -1) {
        result.ok = false;
        result.missing.push(f);
      }
    });
    return result;
  };

  changeStep = val => {
    try {
      this.$scope.$apply(() => {
        this.step = val;
      });
    } catch (e) {
      this.step = val;
    }
  };

  ok = () => {
    if (this.step === 2) {
      // Se step = 2, então começa importação
      this.import();
    } else {
      this.cancel();
    }
  };

  // Carrega toda a informação necessária para registar o processo (Funcionários, Tipos de Instalação, ...)
  loadData = () => {
    this.loaded = false;
    this.GasTecnico.find({
      filter: {
        where: {
          active: true
        }
      }
    })
      .$promise.then(tecnicos => {
        this.tecnicos = tecnicos;
        this.GasTipoInstalacao.find({
          filter: {
            where: {
              active: 1
            }
          }
        })
          .$promise.then(tiposInstalacao => {
            this.tiposInstalacao = tiposInstalacao;
            this.GasOrd.find({
              filter: {
                where: {
                  active: 1
                }
              }
            })
              .$promise.then(ords => {
                this.ords = ords;
                this.loaded = true;
              })
              .catch(e => {
                console.log(e);
                this.UI.addToast('Erro a carregar ORDs. Recarregue a página');
              });
          })
          .catch(e => {
            console.log(e);
            this.UI.addToast('Erro a carregar Tipos de Instalação. Recarregue a página');
          });
      })
      .catch(e => {
        console.log(e);
        this.UI.addToast('Erro a carregar Técnicos. Recarregue a página');
      });
  };

  cancel = () => {
    this.$dialog.dismiss();
  };

  isValidEmail = mail => {
    if (mail == null) {
      return false;
    }
    return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail);
  };

  isValidPhone = number => {
    if (number == null) {
      return false;
    }
    return /^(?:\+351)?\s?9[1236]\d{7}$|^(?:\+351)?\s?2\d{8}$/.test(number);
  };

  isValidCUI = cui => {
    if (cui == null || cui.length !== 20) {
      return false;
    }
    return /^[a-zA-Z]{2}\d{16}[a-zA-Z]{2}$/.test(cui);
  };

  // Recebe linha e valida campos necessários e substitui os campos de relação por id's
  validate = (row, i) => {
    // Index for indexes
    let x;

    // TODO - Verify format of dates
    // Change if required
    let DATES_FORMAT_EXCEL = 'DD/MM/YYYY HH:mm';

    // Special case - Andar - if exists and value is date fix it the best it can
    x = this.getIndex('Andar');
    if (row[x] !== undefined && row[x] !== null && row[x].toString().length > 0) {
      let andar = moment(row[x]);
      if (andar.isValid()) {
        // There was an export error, attempt to fix it
        if (andar.year() < 1900) {
          // It is hour only, use AM/PM and cut the final M
          row[x] = andar.format('h a').slice(0, -1).toUpperCase();
        } else {
          // If current year, use d-m as andar (Ex. 4-5)
          if (andar.year() === new Date().getFullYear()) {
            row[x] = andar.format('D-M');
          } else {
            // Otherwise, use the last two numbers of year
            row[x] = andar.format('M-YY');
          }
        }
      }
    }

    // ID Ordem Serviço
    x = this.getIndex('ID Ordem Serviço');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com ID Ordem Serviço vazia`
      };
    }

    // Tipo de Instalação
    x = this.getIndex('Tipo de Instalação');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com Tipo de Instalação vazia`
      };
    }
    let tipoInstalacao = this.tiposInstalacao.find(z => z.alias.toUpperCase() === row[x].toUpperCase());
    if (tipoInstalacao !== undefined) {
      row[x] = tipoInstalacao.id;
    } else {
      return {
        success: false,
        message: `Linha ${i + 2} com Tipo de Instalação desconhecida`
      };
    }

    // Data de Atribuição (se existir)
    x = this.getIndex('Data de Atribuição');
    let dataAtribuicao = moment(row[x], DATES_FORMAT_EXCEL);
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      row[x] = null;
    } else {
      if (dataAtribuicao.isValid()) {
        row[x] = moment(dataAtribuicao).format('YYYY-MM-DD HH:mm:00');
      } else {
        return {
          success: false,
          message: `Linha ${i + 2} com Data de Atribuição com formato de data inválido!`
        };
      }
    }

    // NIF Cliente
    x = this.getIndex('NIF');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0 || !row[x].toString().toUpperCase().startsWith("PT")) {
      return {
        success: false,
        message: `Linha ${i + 2} com NIF de Cliente inválido`
      };
    } else {
      if (row[x].toUpperCase().startsWith('PT')) {
        // We don't need starting PT in NIF
        row[x] = row[x].substring(2);
      }
    }

    // Distrito
    x = this.getIndex('Distrito');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com Distrito vazio`
      };
    } else {
      // let lote = this.lotes.find(z => z.distrito.toUpperCase() === row[x].toUpperCase());
      // if (lote === undefined) {
      //   return {
      //     success: false,
      //     message: `Linha ${i + 2} com Distrito não reconhecido (Lote)`
      //   };
      // }
    }

    // Cliente
    x = this.getIndex('Cliente');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com Cliente vazio`
      };
    } else {
    }

    // Rua
    x = this.getIndex('Rua');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com Rua vazia`
      };
    } else {
    }

    // Concelho
    x = this.getIndex('Concelho');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com Concelho vazio`
      };
    } else {
    }

    // Cidade
    x = this.getIndex('Cidade');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com Cidade vazia`
      };
    } else {
    }

    // CEP
    x = this.getIndex('CEP');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com Código Postal (CEP) vazio`
      };
    } else {
    }

    // Início Agendamento
    x = this.getIndex('Início Agendamento');
    let dataInicioAgendamento = moment(row[x], DATES_FORMAT_EXCEL);
    if (row[x] !== undefined && row[x] !== null && row[x].toString().length !== 0 && dataInicioAgendamento.isValid()) {
      row[x] = moment(dataInicioAgendamento).format('YYYY-MM-DD HH:mm:00');
    } else {
      return {
        success: false,
        message: `Linha ${i + 2} com Início Agendamento vazio ou com formato de data errado!`
      };
    }

    // Fim de Agendamento
    x = this.getIndex('Fim Agendamento');
    let dataFimAgendamento = moment(row[x], DATES_FORMAT_EXCEL);
    if (row[x] !== undefined && row[x] !== null && row[x].toString().length !== 0 && dataFimAgendamento.isValid()) {
      row[x] = moment(dataFimAgendamento).format('YYYY-MM-DD HH:mm:00');
    } else {
      return {
        success: false,
        message: `Linha ${i + 2} com Fim Agendamento vazia ou com formato de data errado!`
      };
    }

    // ID Tarefa
    x = this.getIndex('ID Tarefa');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com ID Tarefa vazio`
      };
    }

    // ID Técnico Atribuído
    x = this.getIndex('ID Técnico Atribuído');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com ID Técnico Atribuído vazio`
      };
    } else {
      // Stop import if tecnico does not exist...
      let tecnico = this.tecnicos.find(z => z.codigo.toUpperCase() === row[x].toUpperCase());
      if (tecnico === undefined) {
        return {
          success: false,
          message: `Linha ${i + 2} com ID Técnico Atribuído desconhecido (${row[x]})`
        };
      }
    }

    // Primeiro Agendamento Efetuado em (se existir)
    x = this.getIndex('Primeiro Agendamento Efetuado em');
    if (row[x] !== undefined && row[x] !== null && row[x].toString().length !== 0) {
      let dataPrimeiroAgendamento = moment(row[x], DATES_FORMAT_EXCEL);
      if (dataPrimeiroAgendamento.isValid()) {
        row[x] = moment(dataPrimeiroAgendamento).format('YYYY-MM-DD HH:mm:00');
      } else {
        return {
          success: false,
          message: `Linha ${i + 2} com Data de Primeiro Agendamento com formato de data inválida!`
        };
      }
    } else {
      row[x] = null;
    }

    // Distribuidor vai acompanhar serviço?
    x = this.getIndex('Distribuidor vai acompanhar serviço?');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com informação se Distribuidor vai acompanhar serviço vazia`
      };
    } else {
      if (row[x].toString().toUpperCase() === 'TRUE' || row[x].toString().toUpperCase() === 'VERDADEIRO') {
        row[x] = true;
      } else {
        if (row[x].toString().toUpperCase() === 'FALSE' || row[x].toString().toUpperCase() === 'FALSO') {
          row[x] = false;
        } else {
          return {
            success: false,
            message: `Linha ${i + 2} com informação se Distribuidor vai acompanhar serviço mal preenchida`
          };
        }
      }
    }

    // E-mail cliente (se existir)
    x = this.getIndex('E-mail Cliente');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      row[x] = null;
    } else {
      row[x] = row[x].toUpperCase();
      if (!this.isValidEmail(row[x])) {
        return {
          success: false,
          message: `Linha ${i + 2} com E-mail com formato inválido`
        };
      }
    }

    // Contacto (se existir)
    x = this.getIndex('SMS');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      row[x] = null;
    } else {
      if (!this.isValidPhone(row[x])) {
        return {
          success: false,
          message: `Linha ${i + 2} com Contacto Telefone (SMS) inválido`
        };
      }
    }

    // CUI (se existir)
    x = this.getIndex('CUI');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      row[x] = null;
    } else {
      row[x] = row[x].toUpperCase();
      if (!this.isValidCUI(row[x])) {
        return {
          success: false,
          message: `Linha ${i + 2} com CUI com formato inválido`
        };
      }
    }

    // Total na Factura c/IVA
    x = this.getIndex('Total na Factura c/IVA');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      return {
        success: false,
        message: `Linha ${i + 2} com Total na factura c/IVA vazio`
      };
    } else {
      if (isNaN(row[x]) || Number(row[x]) < 0) {
        return {
          success: false,
          message: `Linha ${i + 2} com Total na factura c/IVA inválido`
        };
      } else {
        row[x] = Number(row[x]);
      }
    }

    // ORD
    x = this.getIndex('ORD');
    /**
     * ORD is not required, but if it exists, it must be valid
     * Care that IEP might to empty or FALSE, so we need to check how they filled it
     */
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0 || row[x].toString().toUpperCase() === 'FALSE' || row[x].toString().toUpperCase() === 'FALSO') {
      // Check if it's necessary
      if (row[this.getIndex('Distribuidor vai acompanhar serviço?')]) {
        return {
          success: false,
          message: `Linha ${i + 2} com ORD vazio`
        };
      } else {
        // We don't need ORD anyway
        row[x] = null;
      }
    } else {
      // Stop import if ORD does not exist... Using == because it can be text or number
      // Changed 28/10 - João Leite request to change from nif to designacao
      let ord = this.ords.find(z => z.designacao.toUpperCase() === row[x].trim().toUpperCase());
      if (ord === undefined) {
        return {
          success: false,
          message: `Linha ${i + 2} com Designação de ORD desconhecido (${row[x]})`
        };
      } else {
        row[x] = ord.id;
      }
    }

    // Técnico Distribuidor
    x = this.getIndex('Técnico Distribuidor');
    if (row[x] === undefined || row[x] === null || row[x].toString().length === 0) {
      // Check if it's necessary
      if (row[this.getIndex('Distribuidor vai acompanhar serviço?')]) {
        return {
          success: false,
          message: `Linha ${i + 2} com Técnico Distribuidor vazio`
        };
      } else {
        // We don't need Técnico Distribuidor anyway
        row[x] = null;
      }
    } else {
      // Do nothing as we are not validating tecnicos atm
    }

    return {
      success: true,
      row: row
    };
  };

  download = () => {
    let o = angular.copy(this.statusProcessos);
    o.forEach(r => {
      r.result = this.explainStat(r.result, r.extra);
      delete r.extra; // No point in showing this field in the export
    });
    let csv = 'ID Ordem de Serviço,ID Tarefa,Resultado\n'; // Header
    csv += o.map(d => Object.values(d).join()).join('\n'); // Data

    this.UI.addToast('A iniciar download, por favor aguarde...');
    let csvContent = 'data:text/csv;charset=utf-8,' + csv;
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'resultado.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };

  // Parse row into a single object for creations later
  parse = row => {
    // Join Rua + Andar + Fracção
    let morada = row[this.getIndex('Rua')];
    if (row[this.getIndex('Andar')] !== undefined && row[this.getIndex('Andar')] !== null) morada += ' ' + row[this.getIndex('Andar')];
    if (row[this.getIndex('Fracção')] !== undefined && row[this.getIndex('Fracção')] !== null) morada += ' ' + row[this.getIndex('Fracção')];

    let total = row[this.getIndex('Total na Factura c/IVA')];

    let tipoId;
    if (Number(total === 0)) tipoId = 1;
    else tipoId = 2;

    return {
      numeroParceiro: row[this.getIndex('ID Ordem Serviço')],
      tarefaId: row[this.getIndex('ID Tarefa')],
      tipoInstalacaoId: row[this.getIndex('Tipo de Instalação')],
      idTecnicoAtribuido: row[this.getIndex('ID Técnico Atribuído')],
      dataAtribuicao: row[this.getIndex('Data de Atribuição')],
      dataPrimeiroAgendamento: row[this.getIndex('Primeiro Agendamento Efetuado em')],
      nifCliente: row[this.getIndex('NIF')],
      contactoCliente: row[this.getIndex('SMS')],
      nomeCliente: row[this.getIndex('Cliente')],
      emailCliente: row[this.getIndex('E-mail Cliente')],
      moradaCliente: morada,
      localidadeCliente: row[this.getIndex('Cidade')],
      concelhoCliente: row[this.getIndex('Concelho')],
      distritoCliente: row[this.getIndex('Distrito')],
      cep: row[this.getIndex('CEP')],
      inicioAgendamento: row[this.getIndex('Início Agendamento')],
      fimAgendamento: row[this.getIndex('Fim Agendamento')],
      distribuidorPresente: row[this.getIndex('Distribuidor vai acompanhar serviço?')],
      cui: row[this.getIndex('CUI')],
      total: total,
      tipoId: tipoId, // 1 - EDP, 2 - EDP Clientes, 3 - Outros
      ordId: row[this.getIndex('ORD')],
      distribuidorTecnicoNome: row[this.getIndex('Técnico Distribuidor')]
    };
  };

  import = async () => {
    this.changeStep(3);
    this.done = 0;
    this.importedWithErrors = 0;
    this.statusProcessos = []; // {numeroProcesso: ...,

    // Create each process
    for (let row of this.data) {
      let status = {
        numeroProcesso: row[this.getIndex('ID Ordem Serviço')],
        tarefaId: row[this.getIndex('ID Tarefa')],
        result: null,
        extra: null
      };

      let data = this.parse(row);
      // Create processo - returns processo and result object
      let processoData;
      try {
        processoData = await this.GasProcesso.importProcesso({
          params: {
            data: data
          }
        }).$promise;
        status.result = processoData.result;
        status.extra = processoData.extra;
      } catch (error) {
        console.log(error);
        if (error.status === 401) {
          status.result = 0;
          status.extra = 'Sem permissões';
        }
      }

      this.statusProcessos.push(status);
      try {
        this.$scope.$apply(() => {
          if (status.result !== 1 && status.result !== 2) this.importedWithErrors++;
          this.done++;
        });
      } catch (e) {
        if (status.result !== 1 && status.result !== 2) this.importedWithErrors++;
        this.done++;
      }
    }
    this.changeStep(4);
  };

  // Explain import stat
  // Copied from gas.service.ts. If changing, change there as well
  explainStat = (value, extra) => this.GasService.explainStat(value, extra);
}

GasImporterController.$inject = ['$q', '$http', '$scope', '$dialog', 'UIService', 'FileUploader', 'Funcionario', 'AuthenticationService', 'GasProcesso', 'GasTecnico', 'GasAgendamento', 'GasTipoInstalacao', 'GasOrd', 'GasService'];
