import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
const DialogMaterial = ({ initial, pending = false, OblerRevampService, onClose, ...props }) => {
    const [prop, setProp] = useState('');
    const [options, setOptions] = useState([]);
    const validationSchema = yup.object({
        codigo: yup.string().required('O campo é obrigatório'),
        compra: yup.string().nullable(),
        designacao: yup.string().required('O campo é obrigatório'),
        fornecedor: yup.string().required('O campo é obrigatório'),
        quantidade: yup.number().required('O campo é obrigatório'),
        preco: yup.number().min(0, "O valor não pode ser menor que 0").nullable(),
        prazo: yup.number().min(0, "O valor não pode ser menor que 0").nullable()
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            console.log(values);
            onClose(values);
        }
    });
    useEffect(() => {
        let active = true;
        if (prop === '')
            return undefined;
        if (!formik.values?.codigo || formik.values?.codigo.length < 3)
            return undefined;
        (async () => {
            // Lógica...
            const data = await OblerRevampService.autoComplete(prop, formik.values.codigo);
            if (active) {
                setOptions([...data]);
            }
        })();
    });
    // Formik fix dialog reuse
    useEffect(() => {
        formik.resetForm();
        formik.setValues(initial);
    }, [initial]);
    return (<Dialog {...props}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Material</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={3} md={4} xs={12}>
              <TextField size='small' label='Cód. Artigo *' name='codigo' defaultValue={formik.values?.codigo} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.codigo && Boolean(formik.errors.codigo)} helperText={formik.touched.codigo && formik.errors.codigo}/>
            </Grid>
            <Grid lg={9} md={8} xs={12}>
              <TextField size='small' label='Designação *' name='designacao' defaultValue={formik.values?.designacao} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
            </Grid>
            <Grid lg={6} md={6} xs={12}>
              <TextField size='small' label='Fornecedor *' name='fornecedor' value={formik.values?.fornecedor} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.fornecedor && Boolean(formik.errors.fornecedor)} helperText={formik.touched.fornecedor && formik.errors.fornecedor}/>
            </Grid>
            <Grid lg={6} md={6} xs={12}>
              <TextField size='small' label='Cód. Compra' name='compra' value={formik.values?.compra} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.compra && Boolean(formik.errors.compra)} helperText={formik.touched.compra && formik.errors.compra}/>
            </Grid>
            <Grid lg={4} md={4} xs={4}>
              <TextField size='small' label='Quantidade *' type='number' name='quantidade' value={formik.values?.quantidade} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.quantidade && Boolean(formik.errors.quantidade)} helperText={formik.touched.quantidade && formik.errors.quantidade}/>
            </Grid>
            <Grid lg={4} md={4} xs={4}>
              <TextField size='small' label='Preço' InputProps={{
            endAdornment: <InputAdornment position='end'>€</InputAdornment>
        }} name='preco' type='number' value={formik.values?.preco} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.preco && Boolean(formik.errors.preco)} helperText={formik.touched.preco && formik.errors.preco}/>
            </Grid>
            <Grid lg={4} md={4} xs={4}>
              <TextField size='small' label='Prazo' InputProps={{
            endAdornment: <InputAdornment position='end'>dias</InputAdornment>
        }} type='number' name='prazo' value={formik.values?.prazo} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.prazo && Boolean(formik.errors.prazo)} helperText={formik.touched.prazo && formik.errors.prazo}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </form>
    </Dialog>);
};
export default DialogMaterial;
