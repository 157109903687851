import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Fab, IconButton, Link, MenuItem, Typography } from '@mui/material';
import { MdiIcon, Table } from '@components';
import { mdiPlus } from '@mdi/js';
import moment from 'moment';
const CrmEmpresasListView = ({ $state, CrmService }) => {
    // ordenação das colunas
    // Ex: sortBy = ['morada:DESC']
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState([]);
    const [sort, setSort] = useState(() => loadParams()); // só é chamado quando o componente é montado
    const [options, setOptions] = useState({
        filter: [],
        sort: []
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    });
    // Filtragem dentro de cada coluna
    // Modo de filtro (igual a, contém, começa com... )
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    // ir para detalhes da empresa (envia id da empresa)
    const onCellView = (cell) => {
        $state.go('app.crm.empresas.details', { id: cell.id });
    };
    const initialFetch = async () => {
        if (loading) {
            // opt -> vai buscar os campos filtraveis
            const opt = await CrmService.optionsClientes();
            setOptions(opt);
            setLoading(false);
        }
    };
    const fetchData = async () => {
        setError(false);
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            const aux = await CrmService.findAllClientes({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            // Update URL
            setData(aux);
        }
        catch (e) {
            console.log(e);
            setError(true);
        }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, filter, sort]);
    return (<Box sx={{ pl: 1, pr: 1 }}>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mt: 2 }}>
        <Link underline='hover' color='inherit' href='/'>
          <Typography variant='body2'>CRM</Typography>
        </Link>
        <Link underline='hover' color='inherit'>
          <Typography variant='body2'>Clientes</Typography>
        </Link>
      </Breadcrumbs>

      <Typography variant='h4' sx={{ color: 'black', mt: 2 }}>
        Clientes
      </Typography>

      <Box sx={{
            mt: 2,
            borderRadius: '15px',
            border: '1px solid #e0e0e0',
            overflow: 'hidden',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
        }}>
        <Table onDoubleClick={row => {
            onCellView(row);
        }} storageKey={$state.current.name} data={data} columns={[
            /* {
              accessorKey: 'id',
              header: 'ID',
              size: 20
            }, */
            {
                accessorKey: 'nome',
                header: 'Nome',
                size: 200
            },
            /* {
              accessorKey: 'area',
              header: 'Área',
              size: 150
            },
            {
              accessorKey: 'colaboradoresTotal',
              header: 'Colaboradores Total',
              size: 150
            },
            {
              accessorKey: 'colaboradoresConhecidos',
              header: 'Colaboradores Conhecidos',
              size: 150
            }, */
            {
                accessorKey: 'ultimoContacto',
                header: 'Último contacto',
                size: 120,
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    if (value != undefined)
                        return moment(value).format('DD-MM-YYYY');
                    return '-';
                }
            },
            {
                accessorKey: 'contactoTelefonico',
                header: 'Contacto',
                size: 100
            },
            {
                accessorKey: 'nif',
                header: 'NIF',
                size: 100
            },
            {
                accessorKey: 'email',
                header: 'Email',
                size: 150
            },
            {
                accessorKey: 'morada',
                header: 'Morada',
                size: 150
            },
            {
                accessorKey: 'localidade',
                header: 'Localidade',
                size: 150
            }
        ]} initial={{
            sort: sort,
            filter: filter
        }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setFilter} onSort={setSort} cellActionItems={({ row, table }) => {
            return [
                <MenuItem key={`row.view.${row.id}`} onClick={() => /* onCellView(row.original) */ console.log(row.original)}>
                Visualizar
              </MenuItem>
            ];
        }}></Table>
      </Box>
      <Fab aria-label='add' color='error' sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000
        }}>
        <IconButton onClick={() => $state.go('app.crm.empresas.formCreate')}>
          <MdiIcon path={mdiPlus} sx={{ color: '#ffffff' }}/>
        </IconButton>
      </Fab>
    </Box>);
};
export default CrmEmpresasListView;
