String.prototype.includes = function (str) {
  let returnValue = false;
  if (this.indexOf(str) !== -1) {
    returnValue = true;
  }
  return returnValue;
};

export function ApplicationInterceptor($rootScope, $state, $http, AuthenticationService, AuthorizationService, UIService) {
  $rootScope.$on('$stateChangeStart', (event, to, toParams, from, fromParams) => {
    // Add {fromParams} to {from}
    from.params = fromParams;
    // Assign {from} to {previous} in $state
    $state.previous = from;

    // Get Server Status - this is async, so no worries :)
    $http.get('/api/status').then(res => {
      $rootScope.$broadcast('$receivedServerStatus', res.data);
    });

    // User is authenticated? In case of an exception, set authenticated as off
    let loggedIn = AuthenticationService.isAuthenticated() || false;

    if (!loggedIn) {
      // If user not authenticated and trying to access routes that are not related to authentication, deny access
      if (!loggedIn && !to.name.includes('auth')) {
        AuthenticationService.clearUser();
        $state.go('auth.login');
        event.preventDefault(); // Denying access
      }
    } else {
      // If user is authenticated and tries to access routes that are authentication
      if (loggedIn && to.name.includes('auth')) {
        $state.go('app.dashboard');
        event.preventDefault(); // Denying access
      } else {
        let user = AuthenticationService.getUser();
        if (user.blocked) {
          UIService.addToast('A sua conta foi bloqueada');
          AuthenticationService.logout();
        }

        // Check if user has access to role of state (if exists)
        if (to.hasOwnProperty('role')) {
          if (!AuthorizationService.canPerform(to.role)) {
            $state.go('app.dashboard');
            event.preventDefault(); // Denying access
          }
        }
      }
    }
  });

  $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
    let loggedIn = AuthenticationService.isAuthenticated() || false;
    if (toState.hasOwnProperty('title')) {
      $rootScope.title = toState.title;
    }

    if (loggedIn) {
      // Write to backlog
    }
  });
}

ApplicationInterceptor.$inject = ['$rootScope', '$state', '$http', 'AuthenticationService', 'AuthorizationService', 'UIService'];
