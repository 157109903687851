export default class SusEneListClientsController {
  constructor($state, UIService, SusEneService, SusEneCliente, AtvEntidadeProprietaria) {
    this.$state = $state;
    this.UI = UIService;
    this.SusEneService = SusEneService;
    this.SusEneCliente = SusEneCliente;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;

    // If loadData() finished or not
    this.dataLoaded = false;

    this.empresas = [];
    this.filtersLoaded = [0];

    this.opt = $state.params;

    // Total processes for currently being displayed
    this.total = 0;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [
      {
        displayName: 'ID SGI',
        name: 'id',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Nº Cliente',
        name: 'refClientePrimavera',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Empresa',
        name: 'empresa',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Nome',
        name: 'nome',
        visible: true,
        sortable: true
      },
      {
        displayName: 'NIF',
        name: 'nif',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Email',
        name: 'email',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Contacto Telefónico',
        name: 'contactoTelefonico',
        visible: false,
        sortable: true
      },
      {
        displayName: 'Morada',
        name: 'morada',
        visible: true,
        sortable: true
      },
      {
        displayName: 'CP4',
        name: 'cp4',
        visible: true,
        sortable: true
      },
      {
        displayName: 'CP3',
        name: 'cp3',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Localidade',
        name: 'localidade',
        visible: true,
        sortable: true
      },
      {
        displayName: 'Observações',
        name: 'observacoes',
        visible: false,
        sortable: true
      }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: 'SusEneCliente.id',
        name: 'ID SGI',
        type: 'o'
      },
      {
        id: 'SusEneCliente.refClientePrimavera',
        name: 'Nº Cliente',
        type: 'o'
      },
      {
        id: 'SusEneCliente.empresaId',
        name: 'Empresa',
        type: 's',
        list: this.empresas
      },
      {
        id: 'SusEneCliente.nome',
        name: 'Nome',
        type: 'o'
      },
      {
        id: 'SusEneCliente.nif',
        name: 'NIF',
        type: 'o'
      },
      {
        id: 'SusEneCliente.email',
        name: 'Email',
        type: 'o'
      },
      {
        id: 'SusEneCliente.contactoTelefonico',
        name: 'Contacto Telefónico',
        type: 'o'
      },
      {
        id: 'SusEneCliente.morada',
        name: 'Morada',
        type: 'o'
      },
      {
        id: 'SusEneCliente.cp4',
        name: 'CP4',
        type: 'o'
      },
      {
        id: 'SusEneCliente.cp3',
        name: 'CP3',
        type: 'o'
      },
      {
        id: 'SusEneCliente.localidade',
        name: 'Localidade',
        type: 'o'
      },
      {
        id: 'SusEneCliente.observacoes',
        name: 'Observações',
        type: 'o'
      }
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('SUSENEClientesDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('SUSENEClientesDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('SUSENEClientesFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('SUSENEClientesFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('SUSENEClientesFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'SusEneCliente.empresaId')
          f.column.selected.list = this.empresas;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getClientes();
  }

  clearFilter = () => {
    localStorage.removeItem('SUSENEClientesFilter');
    this.customFilters = [];
    this.getClientes();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  getClientes = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {
      'SusEneCliente.active': 1
    });

    this.SusEneCliente.table({
      params: {
        fields: [
          'SusEneCliente.id as id',
          'SusEneCliente.refClientePrimavera as refClientePrimavera',
          'SusEneCliente.nome as nome',
          'SusEneCliente.nif as nif',
          'SusEneCliente.email as email',
          'SusEneCliente.contactoTelefonico as contactoTelefonico',
          'SusEneCliente.morada as morada',
          'SusEneCliente.cp4 as cp4',
          'SusEneCliente.cp3 as cp3',
          'SusEneCliente.localidade as localidade',
          'SusEneCliente.observacoes as observacoes',
          'SusEneCliente.empresaId as empresaId',
          'AtvEntidadeProprietaria.designacao as empresa'
        ],
        from: ['SusEneCliente', 'AtvEntidadeProprietaria'],
        referencesOrigin: [],
        references: [undefined, 'SusEneCliente.empresaId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      // res.data.forEach(r => {
      //   let tmp = r.tipo === "Metrologia" ? 'M' : 'E';
      //   r.processo = `${tmp}-${r.iidAno}-${("0000" + r.iidProc).slice(-4)}`;
      // });

      this.clientes = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível obter Clientes. Verifique a ligação");
    })
  };

  exportClientes = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, { 'SusEneCliente.active': 1 });
    this.SusEneCliente.exportClientes({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'SusEneCliente.id as id',
          'SusEneCliente.refClientePrimavera as refClientePrimavera',
          'SusEneCliente.nome as nome',
          'SusEneCliente.nif as nif',
          'SusEneCliente.email as email',
          'SusEneCliente.contactoTelefonico as contactoTelefonico',
          'SusEneCliente.morada as morada',
          'SusEneCliente.cp4 as cp4',
          'SusEneCliente.cp3 as cp3',
          'SusEneCliente.localidade as localidade',
          'SusEneCliente.observacoes as observacoes',
          'AtvEntidadeProprietaria.designacao as empresa'
        ],
        from: ['SusEneCliente', 'AtvEntidadeProprietaria'],
        referencesOrigin: [],
        references: [undefined, 'SusEneCliente.empresaId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'ClientesExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar Clientes. Por favor tente mais tarde.");
    });
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.clientes, x => x.selected), x => {
        return { key: 'SusEneCliente.id', value: x.id };
      });
    let whereFields = this.setWhereFields(this.customFilters, { 'SusEneCliente.active': 1 }, orsLiteral);
    this.SusEneCliente.exportClientes({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'SusEneCliente.id as id',
          'SusEneCliente.refClientePrimavera as refClientePrimavera',
          'SusEneCliente.nome as nome',
          'SusEneCliente.nif as nif',
          'SusEneCliente.email as email',
          'SusEneCliente.contactoTelefonico as contactoTelefonico',
          'SusEneCliente.morada as morada',
          'SusEneCliente.cp4 as cp4',
          'SusEneCliente.cp3 as cp3',
          'SusEneCliente.localidade as localidade',
          'SusEneCliente.observacoes as observacoes',
          'AtvEntidadeProprietaria.designacao as empresa'
        ],
        from: ['SusEneCliente', 'AtvEntidadeProprietaria'],
        referencesOrigin: [],
        references: [undefined, 'SusEneCliente.empresaId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'ClientesExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar Clientes. Por favor tente mais tarde.");
    });
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('SUSENEClientesDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  loadData = () => {
    this.AtvEntidadeProprietaria.find({
      filter: {
        where: {
          active: true,
          id: {lt: 3}
        }
      }
    }).$promise.then(empresas => {
      empresas.forEach(t => {
        t.name = t.designacao;
      });
      this.empresas = empresas;
      this.filtersLoaded[0] = 1;
    }).catch(e => {
      console.log(e);
      this.empresas = [];
      this.filtersLoaded[0] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Empresas).");
    });
  };


  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.susene.clients.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getClientes();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.susene.clients.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getClientes();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.susene.clients.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getClientes();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.empresas.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'SusEneCliente.empresaId')
        f.list = this.empresas;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'SusEneCliente.empresaId')
          f.column.selected.list = this.empresas;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('SUSENEClientesFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.susene.clients.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getClientes();
  };

  hasSelect = () => {
    return _.some(this.clientes, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.clientes.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.clientes.length;
    } else { // Remove all selections
      this.clientes.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.clientes.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.clientes, r => r.selected).length;
    this.allSelected = this.nSelected >= this.clientes.length;
    this.everythingSelected = false;
  };

  viewClient = r => {
    this.$state.go('app.susene.clients.details', {id: r.id});
  };

  validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  resyncClient = (id) => {
    this.SusEneCliente.findOne({
      filter: {
        where: {
          id: id
        }
      }
    }).$promise.then((c) => {
      if (!c.refClientePrimavera) {
        this.UI.addToast("Não foi possível atualizar cliente.");
      } else {
        let PRIClientes = this.SusEneService.getPRIClientes(c);
        PRIClientes.find({
          filter: {
            where: {
              cliente: c.refClientePrimavera
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            c.nome = res[0].nome;
            c.nif = res[0].numcontrib;
            c.cp4 = res[0].facCp.split("-")[0];
            c.cp3 = res[0].facCp.split("-")[1];
            c.localidade = res[0].facCploc;
            c.contactoTelefonico = res[0].facTel;
            c.morada = res[0].facMor;
            c.observacoes = res[0].notas;
            c.atualizadoa = res[0].dataultimaactualizacao;
            c.refClientePrimavera = res[0].cliente;
            c.email = this.validEmail(res[0].cduEmail) ? res[0].cduEmail : null;
            c.$save().then((updatedCliente) => {
              this.getClientes();
              this.UI.addToast('Sincronização realizada com sucesso.');
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Falha na gravação do fabricante. Por favor tente mais tarde.");
            });
          } else {
            this.UI.addToast("Não foi possível atualizar cliente.");
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar cliente.");
        });
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar cliente.");
    });
  };
}

SusEneListClientsController.$inject = ['$state', 'UIService', 'SusEneService', 'SusEneCliente', 'AtvEntidadeProprietaria'];
