import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_ActionMenuItem } from "material-react-table";
import { Box, Typography } from "@mui/material";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import { Search } from "@mui/icons-material";
const GasOrdsListView = ({ $state, GasService }) => {
    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [tableError, setTableError] = useState({ show: false, title: "", body: "" });
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20
    });
    const fetchData = async () => {
        if (!data.length) {
            setIsLoading(true);
        }
        else {
            setIsRefetching(true);
        }
        let tableData;
        try {
            tableData = await findOrds({
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                sorting: sorting,
                columnFilters: columnFilters,
                globalFilter: globalFilter || '',
            });
            setData(tableData.data);
            setRowCount(tableData.count);
        }
        catch (error) {
            console.log(error);
            setData([]);
            setRowCount(0);
            setIsLoading(false);
            setTableError({
                show: true,
                title: (error && error.response && error.response.data) ? error.response.data.error : "Erro",
                body: (error & error.response && error.response.data) ? error.response.data.message : "Ocorreu um erro na busca de dados. Verifique a ligação."
            });
            throw error;
        }
        setIsLoading(false);
        setIsRefetching(false);
    };
    useEffect(() => {
        fetchData();
    }, [columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);
    const findOrds = async (params) => {
        let tableData;
        try {
            tableData = await GasService.findOrds(params);
        }
        catch (error) {
            console.log(error);
            throw error;
        }
        return tableData;
    };
    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 150,
        },
        {
            accessorKey: 'designacao',
            header: 'Designação',
            size: 150,
        },
        {
            accessorKey: 'nif',
            header: 'NIF',
            size: 150,
        },
        {
            accessorKey: 'contacto',
            header: 'Contacto',
            size: 150,
        },
        {
            accessorKey: 'email',
            header: 'Email',
            size: 150,
        },
        {
            accessorKey: 'email2',
            header: 'Email Alternativo',
            size: 150,
        },
        {
            accessorKey: 'email3',
            header: 'Email Alternativo 2',
            size: 150,
        },
        {
            accessorKey: 'observacoes',
            header: 'Observações',
            size: 150,
        },
    ], []);
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = 'Não há registos a serem exibidos';
    return (<Box>
      <h1 className="section-title mt-2" id="services">
        <span>ORDs</span>
      </h1>
      <ol className="breadcrumb text-left">
        <li>Gás</li>
        <li className="active">Operadores da Rede de Distribuição</li>
      </ol>
      <MaterialReactTable 
    // table={table}
    columns={columns} data={data} initialState={{
            pagination: {
                pageIndex: 0,
                pageSize: 20
            },
            columnVisibility: { id: false }
        }} state={{
            density: 'compact',
            showAlertBanner: tableError.show,
            isLoading: isLoading,
            showProgressBars: isRefetching,
            columnFilters,
            globalFilter,
            pagination,
            sorting,
        }} muiPaginationProps={{
            rowsPerPageOptions: [20, 50, 100]
        }} muiCircularProgressProps={{
            color: 'secondary',
            thickness: 5,
            size: 55,
        }} muiToolbarAlertBannerProps={tableError.show ? {
            color: "error",
            children: (<div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {tableError.title}
                </Typography>
                <Typography>
                  {tableError.body}
                </Typography>
              </div>)
        } : undefined} muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
                $state.go('app.gas.ords.details', { id: row.original.id });
            },
            sx: {
                cursor: 'pointer'
            }
        })} localization={localization} enableFullScreenToggle={false} enableDensityToggle={false} rowCount={rowCount} manualPagination={true} manualFiltering={true} manualSorting={true} onColumnFiltersChange={setColumnFilters} onGlobalFilterChange={setGlobalFilter} onPaginationChange={setPagination} onSortingChange={setSorting} enableRowActions={true} positionActionsColumn={'last'} renderRowActionMenuItems={({ row, table }) => [
            <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
             icon={<Search />} key="search" label="Ver detalhes" onClick={() => $state.go('app.gas.ords.details', { id: row.original.id })} table={table}/>,
        ]}/>
    </Box>);
};
export default GasOrdsListView;
