import { Box, Breadcrumbs, Button, Link, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table, WaitDialog } from '@components';
import { useModal } from 'mui-modal-provider';
import { DialogValidacao } from './validacao';
const GasRelatoriosListView = ({ $state, GasService, UIService }) => {
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const { showModal } = useModal();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [options, setOptions] = useState({
        filter: [],
        sort: []
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    });
    const [filter, setFilter] = useState([]);
    const [sort, setSort] = useState(() => loadParams());
    const [resultados, setResultados] = useState([]);
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    const initialFetch = async () => {
        if (loading) {
            const opt = await GasService.options();
            setOptions(opt);
            const results = await GasService.getResultados();
            setResultados(results);
            setLoading(false);
        }
    };
    const fetchData = async () => {
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            const aux = await GasService.findAll({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            // Update URL
            setData(aux);
        }
        catch (e) { }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [setPagination, filter, sort]);
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>Listagem de relatórios</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Gás
          </Link>
          <Link underline='hover' color='text.primary' aria-current='page'>
            Relatórios
          </Link>
        </Breadcrumbs>
      </Box>

      <Box>
        <Table onDoubleClick={row => {
            $state.go('app.administration.relatorios.edit', { id: row.coreListaRelatorioRespostaId });
            //TODO: Ajuste para a rota decente
        }} storageKey={$state.current.name} data={data} columns={[
            {
                accessorKey: 'id',
                header: '#',
                size: 50
            },
            {
                accessorKey: 'Processo.numeroProcesso',
                header: 'Processo',
                size: 160
            },
            {
                accessorKey: 'CoreListaRelatorioResposta.resultadoId',
                header: 'Resultado',
                filterSelectOptions: resultados.map(r => {
                    return {
                        label: r.designacao,
                        value: r.id
                    };
                }),
                Cell: ({ row }) => {
                    return row.original.CoreListaRelatorioResposta.CoreListaRelatorioResultado?.designacao;
                }
            },
            {
                accessorKey: 'versao',
                header: 'Versão',
                size: 75
            },
            {
                accessorKey: 'CoreListaRelatorioResposta.dataSubmissao',
                header: 'Data de submissão',
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    if (value != undefined)
                        return moment(value).format('DD-MM-YYYY [às] HH:mm');
                    return '-';
                }
            },
            {
                accessorKey: 'CoreListaRelatorioResposta.tecnicoId',
                header: 'Submetido por',
                // TODO: Fix opções
                filterSelectOptions: [
                    { label: 'António Gomes', value: 114 },
                    { label: 'Tiago Ribeiro', value: 112 }
                ],
                Cell: ({ row }) => {
                    return row.original.CoreListaRelatorioResposta.Tecnico?.name;
                }
            },
            {
                accessorKey: 'CoreListaRelatorioResposta.validado',
                header: 'Validado?',
                Cell: ({ row }) => {
                    return row.original.CoreListaRelatorioResposta.validado == 1 ? 'Sim' : 'Não';
                }
            },
            {
                accessorKey: 'CoreListaRelatorioResposta.dataValidacao',
                header: 'Data de validação',
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    if (value != undefined)
                        return moment(value).format('DD-MM-YYYY [às] HH:mm');
                    return '-';
                }
            },
            {
                accessorKey: 'CoreListaRelatorioResposta.validadoPorId',
                header: 'Validado por',
                // TODO: Fix opções
                filterSelectOptions: [
                    { label: 'António Gomes', value: 114 },
                    { label: 'Tiago Ribeiro', value: 112 }
                ],
                Cell: ({ row }) => {
                    return row.original.CoreListaRelatorioResposta.ValidadoPor?.name;
                }
            }
        ]} initial={{
            sort: sort,
            filter: filter
        }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setFilter} onSort={setSort} selectable={row => {
            return row.original?.CoreListaRelatorioResposta?.validado == 0 && row.original?.CoreListaRelatorioResposta?.dataSubmissao != undefined;
        }} selectActions={selected => {
            return [
                <Button onClick={async () => {
                        console.log(selected);
                        if (selected.length > 0) {
                            showModal(DialogValidacao, {
                                multiple: true,
                                onClose: async (res) => {
                                    if (res) {
                                        const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                                        for (let i = 0; i < selected.length; i++) {
                                            try {
                                                await GasService.validateRelatorio(selected[i]);
                                            }
                                            catch (e) {
                                                UIService.addToast('Ocorreu um erro ao validar relatório');
                                            }
                                        }
                                        wait.destroy();
                                    }
                                    fetchData();
                                }
                            });
                        }
                    }}>
                Validar
              </Button>
            ];
        }} cellActionItems={({ row, table }) => {
            return row.original.CoreListaRelatorioResposta.validado == 0
                ? [
                    <MenuItem key={`row.view.${row.id}`} onClick={async () => {
                            showModal(DialogValidacao, {
                                multiple: false,
                                onClose: async (res) => {
                                    if (res) {
                                        try {
                                            const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                                            await GasService.validateRelatorio(row.id);
                                            wait.destroy();
                                        }
                                        catch (e) {
                                            UIService.addToast('Ocorreu um erro ao validar relatório');
                                        }
                                    }
                                    fetchData();
                                }
                            });
                        }}>
                    Validar relatório
                  </MenuItem>
                ]
                : undefined;
        }}/>
      </Box>
    </Box>);
};
export default GasRelatoriosListView;
