import { MdiIcon } from '@components';
import { mdiAlertOutline } from '@mdi/js';
import { Box, Typography } from '@mui/material';
import React from 'react';
const MaintenanceView = () => {
    return (<Box display='flex' justifyContent='center' alignItems='center' minHeight='80vh' flexDirection={'column'}>
      <MdiIcon path={mdiAlertOutline} size={6} color='primary'/>
      <Typography sx={{ mb: 2 }} variant='h2' align='center'>
        Em Manutenção!
      </Typography>
      <Typography variant='h5' align='center'>Por motivos de manutenção do software Primavera, a funcionalidade encontra-se atualmente indisponível.</Typography>
      <Typography variant='h5' align='center'>Por favor tente mais tarde.</Typography>
    </Box>);
};
export default MaintenanceView;
