import angular from 'angular';
import MultiClamp from 'multi-clamp';

import {routes} from "./formacao.routes";
import FormacaoCursosController from "./cursos/directory/directory.controller";
import FormacaoCursoDetailsController from "./cursos/details/details.controller";
import FormacaoLocaisController from "./locais/directory/directory.controller";
import FormacaoLocalDetailsController from "./locais/details/details.controller";
import FormacaoCursoEdicaoController from "./cursos/edicao/details/details.controller";
import FormacaoFormadoresController from "./formadores/directory/directory.controller";
import FormacaoFormadorDetailsController from "./formadores/details/details.controller";
import FormacaoFormandosController from "./formandos/directory/directory.controller";
import FormacaoFormandoDetailsController from "./formandos/details/details.controller";
import FormacaoCursoEdicaoModuloController from "./cursos/edicao/modulo/details/details.controller";
import FormacaoCursoEdicaoAgendamentoController from "./cursos/edicao/agendamento/agendamento.controller";
import FormacaoCursosCurrentEditionsController from "./cursos/currenteditions/currenteditions.controller";
import FormacaoAreasController from "./areas/directory/directory.controller";
import FormacaoEmpresasController from "./empresas/directory/directory.controller";
import FormacaoCreateFormandoController from "./formandos/new/new.controller";
import FormacaoAreaDetailsController from "./areas/details/details.controller";
import FormacaoEmpresaDetailsController from "./empresas/details/details.controller";


export default angular.module('app.formacao', [])
  .config(routes)
  .controller('FormacaoCreateFormandoController', FormacaoCreateFormandoController)
  .controller('FormacaoCursosController', FormacaoCursosController)
  .controller('FormacaoCursoDetailsController', FormacaoCursoDetailsController)
  .controller('FormacaoLocaisController', FormacaoLocaisController)
  .controller('FormacaoLocalDetailsController', FormacaoLocalDetailsController)
  .controller('FormacaoCursoEdicaoController', FormacaoCursoEdicaoController)
  .controller('FormacaoFormadoresController', FormacaoFormadoresController)
  .controller('FormacaoFormandoDetailsController', FormacaoFormandoDetailsController)
  .controller('FormacaoFormandosController', FormacaoFormandosController)
  .controller('FormacaoFormadorDetailsController', FormacaoFormadorDetailsController)
  .controller('FormacaoCursoEdicaoModuloController', FormacaoCursoEdicaoModuloController)
  .controller('FormacaoCursoEdicaoAgendamentoController', FormacaoCursoEdicaoAgendamentoController)
  .controller('FormacaoCursosCurrentEditionsController', FormacaoCursosCurrentEditionsController)
  .controller('FormacaoAreasController', FormacaoAreasController)
  .controller('FormacaoEmpresasController', FormacaoEmpresasController)
  .controller('FormacaoEmpresaDetailsController', FormacaoEmpresaDetailsController)
  .controller('FormacaoAreaDetailsController', FormacaoAreaDetailsController)
  .directive('clamp', () => {
    return ({
      restrict: 'A',
      link: (scope, el, att) => {
        new MultiClamp(el, {
            ellipsis: '...',
            clamp: att.clamp || 'auto'
          }
        );
      }
    });
  })
  .name;
