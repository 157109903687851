import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_ActionMenuItem } from "material-react-table";
import { Box, Card, Tab, Tabs, Typography } from "@mui/material";
import moment from "moment";
import { MRT_Localization_PT } from "material-react-table/locales/pt";
import { Search } from "@mui/icons-material";
import DialogAgendamentoProcesso from "../../processos/details/dialogAgendamento";
import { MdiIcon } from "@components";
import { mdiCalendarEdit } from "@mdi/js";
const CustomTabPanel = ({ children, value, index }) => {
    return (<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
      {value === index && (<Box>
          {children}
        </Box>)}
    </div>);
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const GasAgendamentosListView = ({ $state, GasService, UIService }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [tableError, setTableError] = useState({ show: false, title: "", body: "" });
    // Tab stuff
    const [value, setValue] = React.useState(0);
    // Em Agendamento
    const [dataEmAgendamento, setDataEmAgendamento] = useState([]);
    const [rowCountEmAgendamento, setRowCountEmAgendamento] = useState(0);
    const [columnFiltersEmAgendamento, setColumnFiltersEmAgendamento] = useState([]);
    const [globalFilterEmAgendamento, setGlobalFilterEmAgendamento] = useState('');
    const [sortingEmAgendamento, setSortingEmAgendamento] = useState([]);
    const [paginationEmAgendamento, setPaginationEmAgendamento] = useState({
        pageIndex: 0,
        pageSize: 20
    });
    // Agendado
    const [dataAgendado, setDataAgendado] = useState([]);
    const [rowCountAgendado, setRowCountAgendado] = useState(0);
    const [columnFiltersAgendado, setColumnFiltersAgendado] = useState([]);
    const [globalFilterAgendado, setGlobalFilterAgendado] = useState('');
    const [sortingAgendado, setSortingAgendado] = useState([]);
    const [paginationAgendado, setPaginationAgendado] = useState({
        pageIndex: 0,
        pageSize: 20
    });
    // Data for selects
    const [tecnicos, setTecnicos] = useState([]);
    // Edit Agendamento
    const [editAgendamentoProcessoDialog, setEditAgendamentoProcessoDialog] = useState({
        open: false
    });
    const onAgendamentoDialogClose = async (item) => {
        setEditAgendamentoProcessoDialog({ open: false });
        if (item) {
            console.log(item);
            setIsLoading(true);
            // Update data
            try {
                await GasService.updateAgendamento({
                    duracao: item.duracao,
                    data: item.dataAgendamento.format("YYYY-MM-DD"),
                    hora: item.dataAgendamento.format("HH:mm:00"),
                    tecnicoId: item.tecnicoId,
                    processoId: item.processoId,
                });
                await fetchData();
            }
            catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }
        else {
            // if Cancel was pressed
        }
    };
    const editAgendamento = async (processoId) => {
        let wait = UIService.showWaiting();
        // Fetch agendamento (if exists)
        let agendamento;
        try {
            agendamento = await GasService.getAgendamentoForProcesso(processoId);
        }
        catch (error) {
            console.log(error);
            UIService.addToast("Erro na procura de agendamento. Verifique a ligação");
            throw error;
        }
        // Fetch data for select
        // Técnicos
        let fetchedTecnicos;
        try {
            fetchedTecnicos = await GasService.getTecnicos();
        }
        catch (error) {
            console.log(error);
        }
        setTecnicos(fetchedTecnicos);
        if (fetchedTecnicos && fetchedTecnicos.length > 0) {
            // Fix data to contain right moment
            let dataAg;
            if (agendamento && agendamento.data && agendamento.hora) {
                let auxTime = moment(agendamento.hora, "HH:mm");
                dataAg = moment(agendamento.data);
                dataAg.set({
                    hour: auxTime.hour(),
                    minute: auxTime.minute(),
                });
                // Prevent cases where tecnico is no longer tecnico but you still can edit Agendamento without changing
                let foundTecnico = fetchedTecnicos.find(x => x.id === agendamento.tecnicoId);
                if (!foundTecnico) {
                    let oldTecnico;
                    try {
                        oldTecnico = await GasService.getTecnicoById(agendamento.tecnicoId);
                        setTecnicos([...fetchedTecnicos, oldTecnico]);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
            }
            wait.close();
            // Open modal after data was loaded
            setEditAgendamentoProcessoDialog({
                open: true,
                initial: {
                    dataAgendamento: dataAg,
                    duracao: agendamento?.duracao,
                    tecnicoId: agendamento?.tecnicoId,
                    processoId: processoId
                },
                tecnicos: tecnicos
            });
        }
        else {
            wait.close();
        }
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const fetchData = async () => {
        let tableData;
        switch (value) {
            case 0: // We fetch Em Agendamento
                if (!dataEmAgendamento.length) {
                    setIsLoading(true);
                }
                else {
                    setIsRefetching(true);
                }
                try {
                    tableData = await findAgendamentos({
                        start: paginationEmAgendamento.pageIndex * paginationEmAgendamento.pageSize,
                        size: paginationEmAgendamento.pageSize,
                        sorting: sortingEmAgendamento,
                        columnFilters: columnFiltersEmAgendamento,
                        globalFilter: globalFilterEmAgendamento || '',
                        type: 1 // Em Agendamento
                    });
                    setDataEmAgendamento(tableData.data);
                    setRowCountEmAgendamento(tableData.count[0]); // index 0 is always what's being fetched
                    setRowCountAgendado(tableData.count[1]);
                }
                catch (error) {
                    console.log(error);
                    setDataEmAgendamento([]);
                    setIsLoading(false);
                    setTableError({
                        show: true,
                        title: (error && error.response && error.response.data) ? error.response.data.error : "Erro",
                        body: (error & error.response && error.response.data) ? error.response.data.message : "Ocorreu um erro na busca de dados. Verifique a ligação."
                    });
                    throw error;
                }
                break;
            case 1: // We fetch Agendado
                if (!dataAgendado.length) {
                    setIsLoading(true);
                }
                else {
                    setIsRefetching(true);
                }
                try {
                    tableData = await findAgendamentos({
                        start: paginationAgendado.pageIndex * paginationAgendado.pageSize,
                        size: paginationAgendado.pageSize,
                        sorting: sortingAgendado,
                        columnFilters: columnFiltersAgendado,
                        globalFilter: globalFilterAgendado || '',
                        type: 2, // Agendado
                    });
                    setDataAgendado(tableData.data);
                    setRowCountAgendado(tableData.count[0]); // index 0 is always what's being fetched
                    setRowCountEmAgendamento(tableData.count[1]);
                }
                catch (error) {
                    console.log(error);
                    setDataAgendado([]);
                    setIsLoading(false);
                    setTableError({
                        show: true,
                        title: (error && error.response && error.response.data) ? error.response.data.error : "Erro",
                        body: (error & error.response && error.response.data) ? error.response.data.message : "Ocorreu um erro na busca de dados. Verifique a ligação."
                    });
                    throw error;
                }
                break;
        }
        setIsLoading(false);
        setIsRefetching(false);
    };
    useEffect(() => {
        fetchData();
    }, [value,
        columnFiltersEmAgendamento,
        globalFilterEmAgendamento,
        paginationEmAgendamento.pageIndex,
        paginationEmAgendamento.pageSize,
        sortingEmAgendamento,
        columnFiltersAgendado,
        globalFilterAgendado,
        paginationAgendado.pageIndex,
        paginationAgendado.pageSize,
        sortingAgendado]);
    const findAgendamentos = async (params) => {
        let tableData;
        try {
            tableData = await GasService.findAgendamentos(params);
        }
        catch (error) {
            console.log(error);
            throw error;
        }
        return tableData;
    };
    const columnsEmAgendamento = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 100,
        },
        {
            accessorKey: 'numeroProcesso',
            header: 'Nº Processo',
            size: 100,
        },
        {
            accessorKey: "tipoGas.designacao",
            header: "Tipo de Gás",
            size: 140,
        },
        {
            accessorKey: "tipoInstalacao.designacao",
            header: "Tipo de Instalação",
            size: 140,
        },
        {
            accessorKey: "tipoInspecao.designacao",
            header: "Tipo de Inspeção",
            size: 140,
        },
        {
            accessorKey: 'distribuidor.designacao',
            header: 'Distribuidor',
            size: 150,
        },
        {
            accessorKey: 'ord.designacao',
            header: 'ORD',
            size: 150,
        },
        {
            accessorKey: 'dataImportacao',
            header: 'Data de Importação',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (moment(row.original.dataImportacao).isValid() ? moment(row.original.dataImportacao).format("DD/MM/YYYY") : 'N/D'),
        },
    ], []);
    const columnsAgendado = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 100,
        },
        {
            accessorKey: 'numeroProcesso',
            header: 'Nº Processo',
            size: 100,
        },
        {
            accessorKey: 'agendamento.data',
            header: 'Data de Inspeção',
            size: 150,
            Cell: ({ renderedCellValue, row }) => ((row.original.agendamento && moment(row.original.agendamento.data).isValid()) ? moment(row.original.agendamento.data).format("DD/MM/YYYY") : 'N/D'),
        },
        {
            accessorKey: "agendamento.hora",
            header: "Hora",
            size: 50,
            Cell: ({ renderedCellValue, row }) => (row.original.agendamento.hora ? row.original.agendamento.hora.substring(0, 5) : 'N/D'),
        },
        {
            accessorKey: "agendamento.duracao",
            header: "Duração (min)",
            size: 80,
        },
        {
            accessorKey: "agendamento.tecnico.name",
            header: "Técnico",
            size: 140,
        },
        {
            accessorKey: "estado.designacao",
            header: "Estado",
            size: 140,
        },
        {
            accessorKey: "tipoGas.designacao",
            header: "Tipo de Gás",
            size: 140,
        },
        {
            accessorKey: "tipoInstalacao.designacao",
            header: "Tipo de Instalação",
            size: 140,
        },
        {
            accessorKey: "tipoInspecao.designacao",
            header: "Tipo de Inspeção",
            size: 140,
        },
        {
            accessorKey: 'distribuidor.designacao',
            header: 'Distribuidor',
            size: 150,
        },
        {
            accessorKey: 'ord.designacao',
            header: 'ORD',
            size: 150,
        },
        {
            accessorKey: 'dataImportacao',
            header: 'Data de Importação',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (moment(row.original.dataImportacao).isValid() ? moment(row.original.dataImportacao).format("DD/MM/YYYY") : 'N/D'),
        },
    ], []);
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = 'Não há registos a serem exibidos';
    return (<Box>
      <DialogAgendamentoProcesso open={editAgendamentoProcessoDialog.open} initial={editAgendamentoProcessoDialog.initial} onClose={onAgendamentoDialogClose} tecnicos={tecnicos}/>
      <h1 className="section-title mt-2" id="services">
        <span>Agendamentos</span>
      </h1>
      <ol className="breadcrumb text-left">
        <li>Gás</li>
        <li className="active">Agendamentos
        </li>
      </ol>

      <Box sx={{ width: '100%' }}>
        <Card>
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="Agendamento tabs">
              <Tab label={`Em Agendamento (${rowCountEmAgendamento})`} {...a11yProps(0)}/>
              <Tab label={`Agendados (${rowCountAgendado})`} {...a11yProps(1)}/>
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <MaterialReactTable 
    // table={table}
    columns={columnsEmAgendamento} data={dataEmAgendamento} initialState={{
            pagination: {
                pageIndex: 0,
                pageSize: 20
            },
            columnVisibility: { id: false, criadoPor: false, dataCriacao: false, ultimaAlteracao: false, observacoes: false }
        }} state={{
            density: 'compact',
            showAlertBanner: tableError.show,
            isLoading: isLoading,
            showProgressBars: isRefetching,
            columnFilters: columnFiltersEmAgendamento,
            globalFilter: globalFilterEmAgendamento,
            pagination: paginationEmAgendamento,
            sorting: sortingEmAgendamento,
        }} muiPaginationProps={{
            rowsPerPageOptions: [20, 50, 100]
        }} muiCircularProgressProps={{
            color: 'secondary',
            thickness: 5,
            size: 55,
        }} muiToolbarAlertBannerProps={tableError.show ? {
            color: "error",
            children: (<div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {tableError.title}
                      </Typography>
                      <Typography>
                        {tableError.body}
                      </Typography>
                    </div>)
        } : undefined} muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
                $state.go('app.gas.processos.details', { id: row.original.id });
            },
            sx: {
                cursor: 'pointer'
            }
        })} localization={localization} enableFullScreenToggle={false} enableDensityToggle={false} rowCount={rowCountEmAgendamento} manualPagination={true} manualFiltering={true} manualSorting={true} onColumnFiltersChange={setColumnFiltersEmAgendamento} onGlobalFilterChange={setGlobalFilterEmAgendamento} onPaginationChange={setPaginationEmAgendamento} onSortingChange={setSortingEmAgendamento} enableRowActions={true} positionActionsColumn={'last'} renderRowActionMenuItems={({ row, table }) => [
            <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
             icon={<Search />} key="search" label="Ver detalhes" onClick={() => $state.go('app.gas.processos.details', { id: row.original.id })} table={table}/>,
            <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
             icon={<MdiIcon path={mdiCalendarEdit}/>} key="edit" label="Agendar" onClick={() => editAgendamento(row.original.id)} table={table}/>,
        ]}/>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <MaterialReactTable 
    // table={table}
    columns={columnsAgendado} data={dataAgendado} initialState={{
            pagination: {
                pageIndex: 0,
                pageSize: 20
            },
            columnVisibility: { id: false, criadoPor: false, dataCriacao: false, ultimaAlteracao: false, observacoes: false }
        }} state={{
            density: 'compact',
            showAlertBanner: tableError.show,
            isLoading: isLoading,
            showProgressBars: isRefetching,
            columnFilters: columnFiltersAgendado,
            globalFilter: globalFilterAgendado,
            pagination: paginationAgendado,
            sorting: sortingAgendado,
        }} muiPaginationProps={{
            rowsPerPageOptions: [20, 50, 100]
        }} muiCircularProgressProps={{
            color: 'secondary',
            thickness: 5,
            size: 55,
        }} muiToolbarAlertBannerProps={tableError.show ? {
            color: "error",
            children: (<div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {tableError.title}
                      </Typography>
                      <Typography>
                        {tableError.body}
                      </Typography>
                    </div>)
        } : undefined} muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
                $state.go('app.gas.processos.details', { id: row.original.id });
            },
            sx: {
                cursor: 'pointer'
            }
        })} localization={localization} enableFullScreenToggle={false} enableDensityToggle={false} rowCount={rowCountAgendado} manualPagination={true} manualFiltering={true} manualSorting={true} onColumnFiltersChange={setColumnFiltersAgendado} onGlobalFilterChange={setGlobalFilterAgendado} onPaginationChange={setPaginationAgendado} onSortingChange={setSortingAgendado} enableRowActions={true} positionActionsColumn={'last'} renderRowActionMenuItems={({ row, table }) => [
            <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
             icon={<Search />} key="search" label="Ver detalhes" onClick={() => $state.go('app.gas.processos.details', { id: row.original.id })} table={table}/>,
            <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
             icon={<MdiIcon path={mdiCalendarEdit}/>} key="edit" label="Editar Agendamento" onClick={() => editAgendamento(row.original.id)} table={table}/>,
        ]}/>
          </CustomTabPanel>
        </Card>
      </Box>
    </Box>);
};
export default GasAgendamentosListView;
