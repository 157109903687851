export default class FinanceiroDirectoryController {
  constructor($q, $state, $filter, Group, UIService, AuthorizationService, FinanceiroService, CoreFaturacao, AtvEntidadeProprietaria, CoreDepartamento) {
    this.$q = $q;
    this.$state = $state;
    this.$filter = $filter;
    this.Group = Group;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.FinanceiroService = FinanceiroService;
    this.CoreFaturacao = CoreFaturacao;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.CoreDepartamento = CoreDepartamento;

    // If loadData() finished or not
    this.dataLoaded = false;

    this.simnao = [{name: "Sim", value: 1}, {name: "Não", value: 0}];
    this.empresas = [];
    this.departamentos = [];
    this.filtersLoaded = [1, 0, 0];

    this.opt = $state.params;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [
      { displayName: 'ID SGI', name: 'id', visible: false, sortable: true },
      { displayName: 'Data do Pedido', name: 'dataPedidoFaturacao', exportDate: 'DD-MM-YYYY', sortable: true, visible: false },
      { displayName: 'Pedido por', name: 'funcionarioName', visible: false, sortable: true },
      { displayName: 'Empresa', name: 'empresa', visible: true, sortable: true },
      { displayName: 'Dept.', name: 'departamento', visible: true, sortable: true },
      { displayName: 'OI', name: 'oi', visible: true, sortable: true },
      { displayName: 'Nº Processo', name: 'numeroProcessoLme', visible: true, sortable: true },
      { displayName: 'Descrição', name: 'descricao', visible: true, sortable: true },
      { displayName: 'Qtd.', name: 'quantidade', visible: true, sortable: true },
      { displayName: 'Preço Unitário', name: 'precoUnitario', visible: true, sortable: true },
      { displayName: 'Ref. Cliente', name: 'clientePrimaveraId', visible: false, sortable: true },
      { displayName: 'Cliente', name: 'clienteNome', visible: true, sortable: true },
      { displayName: 'Faturado?', name: 'faturado', visible: true, sortable: true },
      { displayName: 'Nº Fatura', name: 'numeroFatura', visible: true, sortable: true },
      { displayName: 'Data de Faturação', name: 'dataFaturacao', exportDate: 'DD-MM-YYYY', sortable: true, visible: false },
      { displayName: 'Faturado por', name: 'faturadoPorName', visible: false, sortable: true },
      { displayName: 'Observações', name: 'observacoes', visible: false, sortable: true }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: 'CoreFaturacao.dataPedidoFaturacao',
        name: 'Data do Pedido',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'CoreFaturacao.funcionarioName',
        name: 'Pedido por',
        type: 'o'
      },
      {
        id: 'CoreFaturacao.empresaId',
        name: 'Empresa',
        type: 's',
        list: this.empresas
      },
      {
        id: 'CoreFaturacao.departamentoId',
        name: 'Departamento',
        type: 's',
        list: this.departamentos
      },
      {
        id: 'CoreFaturacao.oi',
        name: 'OI',
        type: 'o'
      },
      {
        id: 'CoreFaturacao.numeroProcessoLme',
        name: 'Nº Processo',
        type: 'o'
      },
      {
        id: 'CoreFaturacao.descricao',
        name: 'Descrição',
        type: 'o'
      },
      {
        id: 'CoreFaturacao.clientePrimaveraId',
        name: 'Ref. Cliente',
        type: 'o'
      },
      {
        id: 'CoreFaturacao.clienteNome',
        name: 'Cliente',
        type: 'o'
      },
      {
        id: 'CoreFaturacao.faturado',
        name: 'Faturado?',
        type: 's',
        list: this.simnao
      },
      {
        id: 'CoreFaturacao.numeroFatura',
        name: 'Nº Fatura',
        type: 'o'
      },
      {
        id: 'CoreFaturacao.dataFaturacao',
        name: 'Data de Faturação',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'CoreFaturacao.faturadoPorName',
        name: 'Faturado por',
        type: 'o'
      },
      {
        id: 'CoreFaturacao.observacoes',
        name: 'Observações',
        type: 'o'
      },
      {
        id: 'CoreFaturacao.id',
        name: 'ID SGI',
        type: 'o'
      }
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem("IEPFaturacaoDisplayColumns")) {
      let cols = JSON.parse(localStorage.getItem("IEPFaturacaoDisplayColumns"));
      if (cols && cols.length > 0) {
        cols.forEach((c) => {
          let i = this.displayColumns.findIndex((x) => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('IEPFaturacaoFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('IEPFaturacaoFilter'));
    } else {
      // If no filter is in place, use this as default, select faturado "Não"
      let customFilterDefault = {
        column: {selected: _.find(this.columns, {id: 'CoreFaturacao.faturado'})},
        value: {selected: this.simnao[1]}
      };
      customFilterDefault.column.selected.list = [];
      this.customFilters = [customFilterDefault];
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('IEPFaturacaoFilter', JSON.stringify(this.customFilters));
    }

    // Prevent more items than supposed to
    if (this.opt && this.opt.items && this.opt.items > 100)
      this.opt.items = 100;

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'CoreFaturacao.faturado')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'CoreFaturacao.empresaId')
          f.column.selected.list = this.empresas;
        if (f.column.selected.id === 'CoreFaturacao.departamentoId')
          f.column.selected.list = this.departamentos;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === "d") {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false; // NOT USED IN THIS VIEW

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getFaturas();
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find((x) => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  canSelect = (row) => {
    return !row.faturado;
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find((x) => x.key === data.key)) {
      // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value,
      });
    } else {
      // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) {
        // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key],
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value,
        });
      } else {
        // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach((f) => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (
        f.column.selected.id.split(".").filter(Boolean).length === 1 && f.column.selected.type === "d") {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else {
        // All other cases where fields exist in database
        if (f.column.selected.type === "o") {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === "d") {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format,
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id) data.value = f.value.selected.id;
            else data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral,
    };
  };

  loadData = () => {
    this.AtvEntidadeProprietaria.find({
    }).$promise.then(empresas => {
      empresas.forEach(t => {
        t.name = t.designacao;
      });
      this.empresas = empresas;
      this.filtersLoaded[1] = 1;
    }).catch(e => {
      this.empresas = [];
      this.filtersLoaded[1] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Empresas).");
    });

    this.CoreDepartamento.find({
    }).$promise.then(departamentos => {
      departamentos.forEach(t => {
        t.name = t.designacao;
      });
      this.departamentos = departamentos;
      this.filtersLoaded[2] = 1;
    }).catch(e => {
      this.departamentos = [];
      this.filtersLoaded[2] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Departamentos).");
    });
  };

  clearFilter = () => {
    localStorage.removeItem('IEPFaturacaoFilter');
    this.customFilters = [];
    this.getFaturas();
  };


  getFaturas = () => {
    this.opt = this.opt || this.defaultOpt;
    this.tableLoading = true;
    let whereFields = this.setWhereFields(this.customFilters);

    this.CoreFaturacao.table({
      params: {
        fields: [
          'CoreFaturacao.id as id',
          'CoreFaturacao.dataPedidoFaturacao as dataPedidoFaturacao',
          'CoreFaturacao.funcionarioName as funcionarioName',
          'CoreFaturacao.oi as oi',
          'CoreFaturacao.numeroProcessoLme as numeroProcessoLme',
          'CoreFaturacao.descricao as descricao',
          'CoreFaturacao.quantidade as quantidade',
          'CoreFaturacao.precoUnitario as precoUnitario',
          'CoreFaturacao.clientePrimaveraId as clientePrimaveraId',
          'CoreFaturacao.clienteNome as clienteNome',
          'CoreFaturacao.faturado as faturado',
          'CoreFaturacao.numeroFatura as numeroFatura',
          'CoreFaturacao.dataFaturacao as dataFaturacao',
          'CoreFaturacao.faturadoPorName as faturadoPorName',
          'CoreFaturacao.observacoes as observacoes',
          'AtvEntidadeProprietaria.sigla as empresa',
          'CoreDepartamento.designacao as departamento',
          'LmeOrdemintervencao.id as oiId',
          'LmeProcesso.tipoId as tipoId',
          'LmeProcesso.empresaId as empresaId',
          'LmeProcesso.iidAno as iidAno',
          'LmeProcesso.iidProc as iidProc'
        ],
        from: ['CoreFaturacao', 'AtvEntidadeProprietaria', 'CoreDepartamento', 'LmeProcesso', 'LmeOrdemintervencao'],
        referencesOrigin: [undefined, undefined, undefined, undefined, 'LmeOrdemintervencao.id'],
        references: [undefined, 'CoreFaturacao.empresaId', 'CoreFaturacao.departamentoId', 'CoreFaturacao.lmeProcessoId', 'LmeProcesso.oiId'],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      this.faturas = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((e) => {
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível carregar pedidos com os parâmetros fornecidos.");
    });
  };

  exportFaturas = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters);
    this.CoreFaturacao.exportFaturas({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'CoreFaturacao.id as id',
          'CoreFaturacao.dataPedidoFaturacao as dataPedidoFaturacao',
          'CoreFaturacao.funcionarioName as funcionarioName',
          'CoreFaturacao.oi as oi',
          'CoreFaturacao.numeroProcessoLme as numeroProcessoLme',
          'CoreFaturacao.descricao as descricao',
          'CoreFaturacao.quantidade as quantidade',
          'CoreFaturacao.precoUnitario as precoUnitario',
          'CoreFaturacao.clientePrimaveraId as clientePrimaveraId',
          'CoreFaturacao.clienteNome as clienteNome',
          'CoreFaturacao.faturado as faturado',
          'CoreFaturacao.numeroFatura as numeroFatura',
          'CoreFaturacao.dataFaturacao as dataFaturacao',
          'CoreFaturacao.faturadoPorName as faturadoPorName',
          'CoreFaturacao.observacoes as observacoes',
          'AtvEntidadeProprietaria.sigla as empresa',
          'CoreDepartamento.designacao as departamento',
          'LmeOrdemintervencao.id as oiId',
          'LmeProcesso.tipoId as tipoId',
          'LmeProcesso.empresaId as empresaId',
          'LmeProcesso.iidAno as iidAno',
          'LmeProcesso.iidProc as iidProc'
        ],
        from: ['CoreFaturacao', 'AtvEntidadeProprietaria', 'CoreDepartamento', 'LmeProcesso', 'LmeOrdemintervencao'],
        referencesOrigin: [undefined, undefined, undefined, undefined, 'LmeOrdemintervencao.id'],
        references: [undefined, 'CoreFaturacao.empresaId', 'CoreFaturacao.departamentoId', 'CoreFaturacao.lmeProcessoId', 'LmeProcesso.oiId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'pedidosFaturaExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar pedidos de fatura. Por favor tente mais tarde.");
    });
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.faturas, x => x.selected), x => {
        return { key: 'CoreFaturacao.id', value: x.id };
      });
    let whereFields = this.setWhereFields(this.customFilters, {}, orsLiteral);
    this.CoreFaturacao.exportFaturas({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'CoreFaturacao.id as id',
          'CoreFaturacao.dataPedidoFaturacao as dataPedidoFaturacao',
          'CoreFaturacao.funcionarioName as funcionarioName',
          'CoreFaturacao.oi as oi',
          'CoreFaturacao.numeroProcessoLme as numeroProcessoLme',
          'CoreFaturacao.descricao as descricao',
          'CoreFaturacao.quantidade as quantidade',
          'CoreFaturacao.precoUnitario as precoUnitario',
          'CoreFaturacao.clientePrimaveraId as clientePrimaveraId',
          'CoreFaturacao.clienteNome as clienteNome',
          'CoreFaturacao.faturado as faturado',
          'CoreFaturacao.numeroFatura as numeroFatura',
          'CoreFaturacao.dataFaturacao as dataFaturacao',
          'CoreFaturacao.faturadoPorName as faturadoPorName',
          'CoreFaturacao.observacoes as observacoes',
          'AtvEntidadeProprietaria.sigla as empresa',
          'CoreDepartamento.designacao as departamento',
          'LmeOrdemintervencao.id as oiId',
          'LmeProcesso.tipoId as tipoId',
          'LmeProcesso.empresaId as empresaId',
          'LmeProcesso.iidAno as iidAno',
          'LmeProcesso.iidProc as iidProc'
        ],
        from: ['CoreFaturacao', 'AtvEntidadeProprietaria', 'CoreDepartamento', 'LmeProcesso', 'LmeOrdemintervencao'],
        referencesOrigin: [undefined, undefined, undefined, undefined, 'LmeOrdemintervencao.id'],
        references: [undefined, 'CoreFaturacao.empresaId', 'CoreFaturacao.departamentoId', 'CoreFaturacao.lmeProcessoId', 'LmeProcesso.oiId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'pedidosFaturaExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar pedidos de fatura. Por favor tente mais tarde.");
    });
  };

  hasSelect = () => {
    return _.some(this.faturas, (a) => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) {
      // Not all are selected, select all
      this.faturas.forEach((a) => {
        if (this.canSelect(a)) a.selected = true;
      });
      this.nSelected = this.faturas.filter((a) => a.selected).length;
    } else {
      // Remove all selections
      this.faturas.forEach((a) => {
        if (a.selected) a.selected = false;
      });
      this.nSelected = 0;
    }
    // this.everythingSelected = false;
  };

  // NOT USED IN THIS VIEW
  selectEverything = (type) => {
    //   if (!type) {
    //     this.relatorios.forEach((a) => {
    //       a.selected = false;
    //     });
    //     this.nSelected = 0;
    //     this.allSelected = false;
    //   }
    //   this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.faturas, (r) => r.selected).length;
    this.allSelected = this.nSelected >= this.faturas.length;
    // this.everythingSelected = false;
  };

  sort = (key) => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname) this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === "asc" ? "desc" : "asc";
    this.$state.go("app.financeiro.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getFaturas();
  };

  item = (val) => {
    this.opt.items = val;
    this.$state.go("app.financeiro.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getFaturas();
  };

  page = (sum) => {
    this.opt.page += sum;
    if (this.opt.page < 1) this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go("app.financeiro.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });
    this.getFaturas();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.simnao.length && !this.empresas.length && !this.departamentos.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach((f) => {
      if (f.id === "CoreFaturacao.faturado")
        f.list = this.simnao;
      if (f.id === "CoreFaturacao.empresaId")
        f.list = this.empresas;
      if (f.id === "CoreFaturacao.departamentoId")
        f.list = this.departamentos;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === "CoreFaturacao.faturado")
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === "CoreFaturacao.empresaId")
          f.column.selected.list = this.empresas;
        if (f.column.selected.id === "CoreFaturacao.departamentoId")
          f.column.selected.list = this.departamentos;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === "s") i.value = {};
    else i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, (f) => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach((f) => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === "d") {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
        f.value = moment().isDST() ? f.value.add(-1, 'h') : f.value; // Fix time for moment
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem("IEPFaturacaoFilter", JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go("app.financeiro.list", this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: "replace",
      // inherit the current params on the url
      inherit: true,
    });

    this.getFaturas();
  };

  selectVisibleColumns = () => {
    let options = {
      size: "md",
      template: require("./columns.dialog.html"),
      controller: ["$dialog", "$scope", (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss("cancel");
        };
      },
      ],
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem("IEPFaturacaoDisplayColumns", JSON.stringify(this.displayColumns));
      }
    });
  };

  // Faturar pedidos - accepts array of faturas
  faturarPedidos = () => {
    this.FinanceiroService.faturarMultiplePedidos(this.CoreFaturacao, this.faturas.filter(x => x.selected)).then((x) => {
      this.getFaturas();
    }).catch(() => {});
  };

  faturarPedido = (fatura) => {
    this.FinanceiroService.faturarMultiplePedidos(this.CoreFaturacao, [fatura]).then((x) => {
      this.getFaturas();
    }).catch(() => {});
  }

  viewPedido = x => {
    let options = {
      size: 'lg',
      template: require('./faturacao.info.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {

        $scope.data = angular.copy(x);

        // Default value for valorTotal
        $scope.valorTotal = Math.round($scope.data.precoUnitario * $scope.data.quantidade * 100) / 100;

        $scope.ok = () => {
          $dialog.close($scope.data);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);
    modal.then(fatura => {
      // If the modal is from faturado, just save (due to Nº Fatura being able to change)
      if (fatura.faturado) {
        // If we changed numeroFatura, save it
        if (x.numeroFatura !== fatura.numeroFatura) {
          let wait = this.UI.showWaiting();
          this.CoreFaturacao.findById({id: fatura.id}).$promise.then((faturaToUpdate) => {
            faturaToUpdate.numeroFatura = fatura.numeroFatura;
            this.CoreFaturacao.upsert(faturaToUpdate).$promise.then(() => {
              wait.close();
              this.UI.addToast("Nº Fatura editado com sucesso");
              this.getFaturas();
            }).catch((error) => {
              console.log(error);
              wait.close();
              alert = this.UI.showAlert("Ocorreu um erro na gravação de Nº Fatura. Verifique a ligação.");
              alert.finally(() => {
                // Redirect to details view so it can be filled
                this.getFaturas();
              });
            });
          }).catch((error) => {
            console.log(error);
            wait.close();
            alert = this.UI.showAlert("Não foi possível encontrar fatura. Verifique a ligação ou se a fatura existe.");
            alert.finally(() => {
              // Redirect to details view so it can be filled
              this.getFaturas();
            });
          });
        } else { // Do nothing, since we didn't change anything
        }
      } else { // Not faturado, faturar individual fatura
        this.faturarPedido(fatura);
      }
    }).catch(() => {});

    // Handle state change to Pedir Fatura and back to wherever it was before
  };

  // Construct the correct way to go to processo
  goToProcessoLme = (x) => {
    this.$state.go('app.lab.processes.details', {
      id: this.$filter('lmeFormat')(x)
    });
  };

}

FinanceiroDirectoryController.$inject = ['$q', '$state', '$filter', 'Group', 'UIService', 'AuthorizationService', 'FinanceiroService', 'CoreFaturacao', 'AtvEntidadeProprietaria', 'CoreDepartamento'];
