export function routes($stateProvider) {
  $stateProvider
    .state('app.crm.pessoas', {
      url: '/pessoas',
      abstract: true
    })
    .state('app.crm.pessoas.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'pessoas',
      template: '<crm-pessoas-list-view></crm-pessoas-list-view>',
      // role: ['gasVerClientes'],
      params: {
        page: {
          value: 1,
          dynamic: true
        },
        limit: {
          value: 20,
          dynamic: true
        },
        sortBy: {
          array: true,
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.crm.pessoas.details', {
      url: '/{id:int}',
      title: 'Detalhes da Pessoa',
      template: '<crm-pessoas-details-view></crm-pessoas-details-view>',
      params: {
        page: {
          value: 1,
          dynamic: true
        },
        limit: {
          value: 20,
          dynamic: true
        },
        sortBy: {
          array: true,
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.crm.pessoas.new', {
      url: '/{adicionarPessoa}',
      title: 'Adicionar pessoa',
      template: '<crm-pessoas-new-view></crm-pessoas-new-view>',
      // role: ['gasVerClientes'],
    })
    .state('app.crm.pessoas.formEdit', {
      url: '/formEdit/{id:int}',
      title: 'Formulário pessoa',
      template: '<crm-pessoas-add-view></crm-pessoas-add-view>',
      // role: ['gasVerClientes'],
    })
    .state('app.crm.pessoas.formCreate', {
      url: '/formCreate',
      title: 'Formulário pessoa',
      template: '<crm-pessoas-add-view></crm-pessoas-add-view>',
      // role: ['gasVerClientes'],
    });
}

routes.$inject = ['$stateProvider'];
