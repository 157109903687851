import moment from 'moment';

export default class FunOIDetailsController {
  constructor($state, FunOrdemintervencao, FunProcesso, FunAlteracaoestadoOrdemIntervencao, FunEstadoOrdemIntervencao, UIService, AuthenticationService) {
    this.$state = $state;
    this.id = $state.params.id;
    if (angular.isUndefined($state.params.id)) {
      $state.go('app.funciona.oi.list');
    }
    this.FunOrdemintervencao = FunOrdemintervencao;
    this.FunProcesso = FunProcesso;
    this.FunAlteracaoestadoOrdemIntervencao = FunAlteracaoestadoOrdemIntervencao;
    this.FunEstadoOrdemIntervencao = FunEstadoOrdemIntervencao;
    this.UI = UIService;
    this.Auth = AuthenticationService;

    this.showEstadosOI = false;

    this.optProcessos = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "id",
      sort: "desc",
      filter: {},
      filterLayout: {}
    };
    this.processos = null;
    this.processosLoading = true;

    this.loadData();
  }

  toggleEstadosOI = () => {
    this.showEstadosOI = !this.showEstadosOI;
  };

  // Processos For OI
  getProcessosForOI = () => {
    this.processosLoading = true;
    let whereLiteral = {
      'FunProcesso.oiId': this.id,
      'FunProcesso.active': 1
    };
    let where = {};
    Object.keys(this.optProcessos.filter).forEach(f => {
      if (this.optProcessos.filter[f])
        where[f] = this.optProcessos.filter[f];
    });
    this.FunProcesso.table2({
      params: {
        fields: [
          'FunProcesso.id as id',
          'FunProcesso.oiId as oiId',
          'FunProcesso.numeroProcesso as numeroProcesso',
          'FunProcesso.tarefaId as tarefaId',
          'FunProcesso.funcionarioId as funcionarioId',
          'FunProcesso.dataImportacao as dataImportacao',
          'FunTipoServico.designacao as tipoServico',
          'FunEstadoProcesso.designacao as estadoProcesso',
          'Funcionario.name as importadoPorNome',
          'Funcionario2.name as funcionarioNome'
        ],
        from: ['FunProcesso', 'Funcionario', 'Funcionario', 'FunTipoServico', 'FunEstadoProcesso'],
        references: [undefined, 'FunProcesso.importadoPorId', 'FunProcesso.funcionarioId', 'FunProcesso.tipoServicoId', 'FunProcesso.estadoId'],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined],
        aliases: [undefined, undefined, 'Funcionario2', undefined, undefined],
        where: where,
        whereLiteral: whereLiteral,
        order: this.optProcessos.order,
        sort: this.optProcessos.sort,
        limit: this.optProcessos.items,
        skip: (this.optProcessos.page - 1) * this.optProcessos.items,
      }
    }).$promise.then(res => {
      let page = this.optProcessos.page;
      let items = this.optProcessos.items;

      let total = res.count;

      this.optProcessos.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.optProcessos.start - 1 + items) >= total) {
        this.optProcessos.end = total;
      } else {
        this.optProcessos.end = Number.parseInt(this.optProcessos.start - 1 + items);
      }

      // Process sub-data
      res.data.forEach(x => {
        x.dataImportacao = moment(x.dataImportacao).format("DD/MM/YYYY HH:mm");
      });

      this.processos = res.data;
      this.optProcessos.total = total;
      this.processosLoading = false;

    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler processos. Verifique a ligação");
    });
  };

  processosSort = keyname => {
    if (this.optProcessos.order === keyname)
      this.optProcessos.page = 1;
    this.optProcessos.order = keyname;
    this.optProcessos.sort = this.optProcessos.sort === 'asc' ? 'desc' : 'asc';
    this.getProcessosForOI();
  };

  processosItem = val => {
    this.optProcessos.items = val;
    this.optProcessos.page = 1;
    this.getProcessosForOI();
  };

  processosPage = sum => {
    this.optProcessos.page += sum;
    if (this.optProcessos.page < 1)
      this.optProcessos.page = 1;
    if (this.optProcessos.page > Math.ceil(this.optProcessos.total / this.optProcessos.items))
      this.optProcessos.page = Math.ceil(this.optProcessos.total / this.optProcessos.items);
    this.getProcessosForOI();
  };

  exportProcessosForOI = () => {

  };

  editObservacoes = () => {
    let data = angular.copy(this.data.observacoes);
    this.UI.showDialog({
      template: require('./obs.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.data = data;

        $scope.ok = () => {
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(r => {
      if (r) {
        this.FunOrdemintervencao.prototype$updateAttributes(
          { id: this.id },
          {
            observacoes: r
          }
        ).$promise.then((_) => {
          this.UI.addToast("Observações alteradas com sucesso");
          this.data.observacoes = r;
        }).catch(e => {
          this.UI.addToast("Não foi possível alterar observações");
        })
      }
    }).catch(() => {});
  };

  editDetails = () => {
    let state = this.data.estadoId;
    this.UI.showDialog({
      template: require('./details.dialog.html'),
      controller: ['$scope', 'FunEstadoOrdemIntervencao', ($scope, FunEstadoOrdemIntervencao) => {
        $scope.title = 'Alteração de Estado da OI';
        $scope.statesLoaded = false;
        $scope.aux = {};

        FunEstadoOrdemIntervencao.find({
          filter: {
            where: {
              and: [{active: 1}, {id: {gt: 1}}, {id: {neq: state}}], // Not first and not the current
            }
          }
        }).$promise.then(r => {
          $scope.aux.estado = r.find(o => o.id === this.data.estadoId);
          $scope.states = r;
          $scope.statesLoaded = true;
        });

        $scope.cancel = () => {
          $scope.$dismiss();
        }

        $scope.ok = () => {
          $scope.data = {
            estadoId: $scope.aux.estado.id
          }
          $scope.$close($scope.data);
        }
      }]
    }).then(ok => {
      if (ok) {
        this.loaded = false;
        this.data.ultimaAlteracao = moment().format();
        delete this.data.Estado;
        this.data.estadoId = ok.estadoId;
        this.FunOrdemintervencao.upsert(this.data).$promise.then(s => {
          // Atualiza estado (se necessário)
          if (state !== this.data.estadoId) {
            let user = this.Auth.getUser();
            this.FunAlteracaoestadoOrdemIntervencao.create({
              id: 0,
              oiId: this.data.id,
              estadoInicial: state,
              estadoFinal: ok.estadoId,
              dataAlteracao: moment().format(),
              funcionarioId: user.id,
              observacoes: null
            }).$promise.then(r => {
              this.UI.addToast("Estado alterado com sucesso");
              this.loadData();
            }).catch(e => {
              this.UI.addToast("Erro ao efetuar alteração de estado. Verifique a ligação");
              this.loadData();
            });
          } else {
            // Should not get here anyway
            this.UI.addToast("OI já se encontra neste Estado");
            this.loadData();
          }
        }).catch(error => {
          console.log(error);
          this.UI.addToast("Ocorreu um erro ao alterar OI. Verifique a ligação");
          this.loadData();
        });
      }
    }).catch(() => {});
  };

  loadData = () => {
    this.loaded = false;
    this.FunOrdemintervencao.findOne({
      filter: {
        where: {
          id: this.id
        },
        include: [{
          relation: 'Estado',
          scope: {
            where: {
              active: true
            }
          }
        }, {
          relation: 'Cliente',
          scope: {
            where: {
              active: true
            }
          }
        }, {
          relation: 'Historico',
          scope: {
            where: {
              active: true
            },
            order: 'dataAlteracao DESC, id DESC',
            include: ['Funcionario', 'EstadoFinal']
          }
        }]
      }
    }).$promise.then(r => {
      this.data = r;

      this.getProcessosForOI();

      this.loaded = true;
    }).catch(error => {
      console.log(error);
      this.UI.addToast("OI não encontrada");
      this.$state.go('app.funciona.oi.list');
    });
  };

  // type: 0 - OI , 1 - Processo
  setTimelineColor = (h, type) => {
    if (type === 0) {
      switch (h.EstadoFinal.id) {
        case 1: // Criação
          return "project-info";
        case 2: // Em execução
          return "project-standby";
        case 3: // Concluída
          return "project-info";
        default:
          return "project-info";
      }
    } else { // Processo
      switch (h.EstadoFinal.id) {
        case 1: // Atribuição
          return "project-info";
        case 2: // Primeiro Agendamento
          return "project-standby";
        case 3: // Agendado
          return "project-standby";
        case 10: // Relatório Concluído
          return "project-info";
        default:
          return "project-info";
      }
    }
  };

}

FunOIDetailsController.$inject = ['$state', 'FunOrdemintervencao', 'FunProcesso', 'FunAlteracaoestadoOrdemIntervencao', 'FunEstadoOrdemIntervencao', 'UIService', 'AuthenticationService'];
