import angular from 'angular';

import {routes} from "./agendamentos.routes";
import DirectorySchedulingElevadoresController from "./directory/directory.controller";
import TecnicoElevadoresController from "./tecnico/tecnico.controller";

export default angular.module('app.elv.agendamentos', [])
  .config(routes)
  .controller('DirectorySchedulingElevadoresController', DirectorySchedulingElevadoresController)
  .controller('TecnicoElevadoresController', TecnicoElevadoresController)
  .name;
