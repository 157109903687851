import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
const DialogOrdemintervencao = ({ open, initial, onClose }) => {
    const validationSchema = yup.object({
        observacoes: yup.string().notRequired()
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            onClose(values);
        }
    });
    useEffect(() => {
        formik.setValues(initial);
    }, [initial]);
    return (<form>
      <Dialog open={open} maxWidth={'lg'} fullWidth>
        <DialogTitle>Editar Observações</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Observações' name='observacoes' defaultValue={formik.values?.observacoes} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.observacoes && Boolean(formik.errors.observacoes)} helperText={formik.touched.observacoes && formik.errors.observacoes}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogOrdemintervencao;
