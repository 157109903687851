import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { MRT_Localization_PT } from 'material-react-table/locales/pt';
import DetailsBox from '../../../interface/components/DetailsBox';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { DynamicCard, HistoryTimeline, MdiIcon, WaitDialog } from '@components';
import { mdiMagnify, mdiPencil, mdiPlaylistPlus } from '@mdi/js';
import DialogDetailsProcesso from './dialogDetails';
import DialogClienteProcesso from './dialogCliente';
import DialogFaturacaoProcesso from './dialogFaturacao';
import DialogAgendamentoProcesso from './dialogAgendamento';
import DialogRelatorioProcesso from './dialogRelatorio';
import { useModal } from 'mui-modal-provider';
import DialogEditRelatorio from './dialogEditRelatorio';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#049a9b' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    height: '100%'
}));
const showDate = (date, format = 'DD/MM/YYYY') => {
    if (moment(date).isValid()) {
        return moment(date).format(format);
    }
    else
        return '-';
};
const getClienteDetailsEntryList = (processo) => processo == null
    ? []
    : [
        { title: 'Nome', content: processo.nomeCliente },
        { title: 'NIF', content: processo.nifCliente },
        { title: 'Contacto', content: processo.contactoCliente ?? 'N/D' },
        { title: 'Email', content: processo.emailCliente ?? 'N/D' },
        { title: 'Morada', content: processo.moradaCliente },
        { title: 'Código Postal', content: processo.cep },
        { title: 'Localidade', content: processo.localidadeCliente },
        { title: 'Concelho', content: processo.concelhoCliente },
        { title: 'Distrito', content: processo.distritoCliente }
    ];
const getFaturacaoDetailsEntryList = (processo) => processo == null
    ? []
    : [
        { title: 'Nome', content: processo.faturaNome },
        { title: 'NIF', content: processo.faturaNif },
        { title: 'Contacto', content: processo.faturaContacto ?? 'N/D' },
        { title: 'Email', content: processo.faturaEmail ?? 'N/D' },
        { title: 'Morada', content: processo.faturaMorada },
        { title: 'Código Postal', content: processo.faturaCep },
        { title: 'Localidade', content: processo.faturaLocalidade },
        { title: 'Concelho', content: processo.faturaConcelho },
        { title: 'Distrito', content: processo.faturaDistrito }
    ];
const getAgendamentoDetailsEntryList = (agendamento) => agendamento == null
    ? []
    : [
        { title: 'Data de Inspeção', content: showDate(agendamento.data, 'DD/MM/YYYY') },
        { title: 'Hora', content: agendamento.hora.substring(0, 5) },
        { title: 'Duração (min)', content: agendamento.duracao.toString() },
        { title: 'Técnico', content: agendamento.tecnico.name }
    ];
const getRelatorioDetailsEntryList = (relatorio) => relatorio == null
    ? []
    : [
        { title: 'Versão', content: relatorio.CoreListaRelatorioResposta.versao.toString() },
        { title: 'Data de submissão', content: moment(relatorio.CoreListaRelatorioResposta.dataSubmissao).format('DD/MM/YYYY HH:mm') },
        { title: 'Validado', content: relatorio.CoreListaRelatorioResposta.validado ? 'Sim' : 'Não' }
    ];
const GasProcessoDetailsView = ({ $state, GasService, UIService }) => {
    const [id, setId] = useState($state.params.id);
    const [estados, setEstados] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [processo, setProcesso] = useState(null);
    const [clienteDetailsEntryList, setClienteDetailsEntryList] = useState([]);
    const [faturacaoDetailsEntryList, setFaturacaoDetailsEntryList] = useState([]);
    const [relatorioDetailsEntryList, setRelatorioDetailsEntryList] = useState([]);
    const [emptyRelatorio, setEmptyRelatorio] = useState(true);
    const [agendamentoDetailsEntryList, setAgendamentoDetailsEntryList] = useState([]);
    const [emptyAgendamento, setEmptyAgendamento] = useState(true);
    // Dialogs
    const { showModal } = useModal();
    const onProcessoDialogClose = async (item) => {
        if (item) {
            setIsLoading(true);
            // Update data
            try {
                await GasService.updateProcesso(item);
                await fetchData();
            }
            catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }
        else {
            // if Cancel was pressed
        }
    };
    const onClienteDialogClose = async (item) => {
        if (item) {
            setIsLoading(true);
            // Update data
            try {
                await GasService.updateProcesso(item);
                await fetchData();
            }
            catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }
        else {
            // if Cancel was pressed
        }
    };
    const onFaturacaoDialogClose = async (item) => {
        if (item) {
            setIsLoading(true);
            // Update data
            try {
                await GasService.updateProcesso(item);
                await fetchData();
            }
            catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }
        else {
            // if Cancel was pressed
        }
    };
    const onAgendamentoDialogClose = async (item) => {
        if (item) {
            setIsLoading(true);
            // Update data
            processo.agendamento.duracao = item.duracao;
            processo.agendamento.data = item.dataAgendamento.format('YYYY-MM-DD');
            processo.agendamento.hora = item.dataAgendamento.format('HH:mm:00');
            processo.agendamento.processoId = item.processoId;
            processo.agendamento.tecnicoId = item.tecnicoId;
            delete processo.agendamento.tecnico;
            try {
                await GasService.updateAgendamento(processo.agendamento);
                await fetchData();
            }
            catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }
        else {
            // if Cancel was pressed
        }
    };
    const onRelatorioDialogClose = async (item, id) => {
        if (item) {
            const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
            // se não existir um relatorio pendente (id == 0)
            if (id == 0) {
                const newRelatorio = await GasService.createRelatorio(item.id, $state.params.id);
                wait.destroy();
                $state.go('app.administration.relatorios.edit', { id: newRelatorio.id });
            }
            else {
                const resposta = await GasService.createResposta(item.id);
                // update attributes patchRelatorio
                await GasService.patchRelatorio(id, { coreListaRelatorioRespostaId: resposta.id });
                wait.destroy();
                $state.go('app.administration.relatorios.edit', { id: resposta.id });
            }
            // upsert gasRelatorio com o id do argumento
            wait.destroy();
        }
    };
    const onEditRelatorioDialogClose = async (item, coreListasRelatorio, id) => {
        if (item == 'edit') {
            $state.go('app.administration.relatorios.edit', { id });
        }
        else if (item == 'substituir') {
            showModal(DialogRelatorioProcesso, {
                coreListasRelatorio: coreListasRelatorio,
                onClose: data => {
                    onRelatorioDialogClose(data, id);
                }
            });
        }
    };
    const fetchData = async () => {
        setIsLoading(true);
        const e = await GasService.getEstados();
        setEstados(e);
        let p;
        try {
            p = await findProcesso(id);
        }
        catch (error) {
            setProcesso(null);
            setIsLoading(false);
            UIService.addToast('Erro ao consultar processo. Verifique a ligação');
            $state.go('app.gas.processos.list');
            throw error;
        }
        setProcesso(p);
        setClienteDetailsEntryList(getClienteDetailsEntryList(p));
        setFaturacaoDetailsEntryList(getFaturacaoDetailsEntryList(p));
        if (p.relatorios != null && p.relatorios.length > 0) {
            console.log(p.relatorios);
            setRelatorioDetailsEntryList(getRelatorioDetailsEntryList(p.relatorios[0]));
            setEmptyRelatorio(false);
        }
        else {
            setRelatorioDetailsEntryList(getRelatorioDetailsEntryList(null));
            setEmptyRelatorio(true);
        }
        if (p.agendamento != null) {
            setAgendamentoDetailsEntryList(getAgendamentoDetailsEntryList(p.agendamento));
            setEmptyAgendamento(false);
        }
        else {
            setAgendamentoDetailsEntryList(getAgendamentoDetailsEntryList(null));
            setEmptyAgendamento(true);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        if (!isLoading)
            fetchData();
    }, [processo]);
    const findProcesso = async (id) => {
        let data;
        try {
            data = await GasService.findProcesso(id);
        }
        catch (error) {
            console.log(error);
            throw error;
        }
        return data;
    };
    // Fix localization for PT
    const localization = MRT_Localization_PT;
    localization.noRecordsToDisplay = 'Não há registos a serem exibidos';
    const editDetails = async () => {
        let wait = UIService.showWaiting();
        // Fetch data for selects
        // ORDs
        let fetchedOrds;
        try {
            fetchedOrds = await GasService.getOrds();
        }
        catch (error) {
            console.log(error);
        }
        // Distribuidores
        let fetchedDistribuidores;
        try {
            fetchedDistribuidores = await GasService.getDistribuidores();
        }
        catch (error) {
            console.log(error);
        }
        // Tipos de Gás
        let fetchedTiposGas;
        try {
            fetchedTiposGas = await GasService.getTiposGas();
        }
        catch (error) {
            console.log(error);
        }
        // Tipos de Instalação
        let fetchedTiposInstalacao;
        try {
            fetchedTiposInstalacao = await GasService.getTiposInstalacao();
        }
        catch (error) {
            console.log(error);
        }
        // Tipos de Inspeção
        let fetchedTiposInspecao;
        try {
            fetchedTiposInspecao = await GasService.getTiposInspecao();
        }
        catch (error) {
            console.log(error);
        }
        wait.close();
        if (fetchedOrds &&
            fetchedOrds.length > 0 &&
            fetchedDistribuidores &&
            fetchedDistribuidores.length > 0 &&
            fetchedTiposGas &&
            fetchedTiposGas.length > 0 &&
            fetchedTiposInstalacao &&
            fetchedTiposInstalacao.length > 0 &&
            fetchedTiposInspecao &&
            fetchedTiposInspecao.length > 0) {
            // Open modal after data was loaded
            showModal(DialogDetailsProcesso, {
                initial: {
                    id: processo.id,
                    tipoInstalacaoId: processo.tipoInstalacaoId,
                    tipoInspecaoId: processo.tipoInspecaoId,
                    tipoGasId: processo.tipoGasId,
                    cui: processo.cui,
                    observacoes: processo.observacoes,
                    ordId: processo.ordId,
                    distribuidorId: processo.distribuidorId,
                    distribuidorPresente: processo.distribuidorPresente,
                    distribuidorTecnicoNome: processo.distribuidorTecnicoNome,
                    agendamentoNaHora: processo.agendamentoNaHora
                },
                ords: fetchedOrds,
                distribuidores: fetchedDistribuidores,
                tiposGas: fetchedTiposGas,
                tiposInspecao: fetchedTiposInspecao,
                tiposInstalacao: fetchedTiposInstalacao,
                onClose: onProcessoDialogClose
            });
        }
    };
    const editCliente = (<Tooltip title='Editar'>
      <IconButton onClick={async () => {
            // let wait = UIService.showWaiting();
            // Open modal after data was loaded
            showModal(DialogClienteProcesso, {
                initial: {
                    id: processo.id,
                    nifCliente: processo.nifCliente,
                    nomeCliente: processo.nomeCliente,
                    emailCliente: processo.emailCliente,
                    moradaCliente: processo.moradaCliente,
                    localidadeCliente: processo.localidadeCliente,
                    cep: processo.cep,
                    concelhoCliente: processo.concelhoCliente,
                    distritoCliente: processo.distritoCliente
                },
                onClose: onClienteDialogClose
            });
        }}>
        <MdiIcon path={mdiPencil}/>
      </IconButton>
    </Tooltip>);
    const editFaturacao = (<Tooltip title='Editar'>
      <IconButton onClick={async () => {
            // let wait = UIService.showWaiting();
            // Open modal after data was loaded
            showModal(DialogFaturacaoProcesso, {
                initial: {
                    id: processo.id,
                    faturaNif: processo.faturaNif,
                    faturaNome: processo.faturaNome,
                    faturaEmail: processo.faturaEmail,
                    faturaMorada: processo.faturaMorada,
                    faturaLocalidade: processo.faturaLocalidade,
                    faturaCep: processo.faturaCep,
                    faturaConcelho: processo.faturaConcelho,
                    faturaDistrito: processo.faturaDistrito
                },
                onClose: onFaturacaoDialogClose
            });
        }}>
        <MdiIcon path={mdiPencil}/>
      </IconButton>
    </Tooltip>);
    const editAgendamento = (<Tooltip title='Editar'>
      <IconButton onClick={async () => {
            let wait = UIService.showWaiting();
            // Fetch data for selects
            // Técnicos
            let fetchedTecnicos;
            try {
                fetchedTecnicos = await GasService.getTecnicos();
            }
            catch (error) {
                console.log(error);
            }
            if (fetchedTecnicos && fetchedTecnicos.length > 0) {
                // Fix data to contain right moment
                let dataAg;
                if (processo && processo.agendamento && processo.agendamento.data && processo.agendamento.hora) {
                    let auxTime = moment(processo.agendamento.hora, 'HH:mm');
                    dataAg = moment(processo.agendamento.data);
                    dataAg.set({
                        hour: auxTime.hour(),
                        minute: auxTime.minute()
                    });
                    // Prevent cases where tecnico is no longer tecnico but you still can edit Agendamento without changing
                    let foundTecnico = fetchedTecnicos.find(x => x.funcionarioId === processo.agendamento.tecnicoId);
                    if (!foundTecnico) {
                        let oldTecnico;
                        try {
                            oldTecnico = await GasService.getTecnicoById(processo.agendamento.tecnicoId);
                            fetchedTecnicos.push(oldTecnico);
                        }
                        catch (error) {
                            console.log(error);
                        }
                    }
                }
                wait.close();
                // Open modal after data was loaded
                showModal(DialogAgendamentoProcesso, {
                    initial: {
                        dataAgendamento: dataAg,
                        duracao: processo.agendamento?.duracao,
                        tecnicoId: processo.agendamento?.tecnicoId,
                        processoId: processo.id
                    },
                    tecnicos: fetchedTecnicos,
                    onClose: onAgendamentoDialogClose
                });
            }
            else {
                wait.close();
            }
        }}>
        <MdiIcon path={mdiPencil}/>
      </IconButton>
    </Tooltip>);
    const getActionsForRelatorio = () => {
        const actions = [];
        actions.push(<Tooltip title='Adicionar Relatório'>
        <IconButton onClick={async () => {
                const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                let gasIndex = await GasService.pendingRelatorio($state.params.id);
                let getListaRelatorios = await GasService.getCoreListasRelatorio(6);
                console.log(getListaRelatorios);
                wait.destroy();
                if (gasIndex.id > 0) {
                    // dialog editar ou substituir
                    // no onclose se editar salta -> editar relatorio (gasindex.id)
                    showModal(DialogEditRelatorio, {
                        coreListasRelatorio: getListaRelatorios,
                        onClose: data => {
                            onEditRelatorioDialogClose(data, getListaRelatorios, gasIndex.id);
                        }
                    });
                    // se substituir chamar DialogRelatorioProcesso -> onClose:(data)=> {onRelatorioDialogClose(data, gasIndex.id)}
                }
                else {
                    showModal(DialogRelatorioProcesso, {
                        coreListasRelatorio: getListaRelatorios,
                        onClose: data => {
                            onRelatorioDialogClose(data, gasIndex.id);
                        }
                    });
                }
            }}>
          <MdiIcon path={mdiPlaylistPlus}/>
        </IconButton>
      </Tooltip>);
        if (!processo || !processo.relatorios || processo.relatorios.length === 0) {
        }
        else {
            actions.unshift(<Tooltip title='Ver Relatório'>
          <IconButton onClick={async () => {
                    $state.go('app.administration.relatorios.edit', { id: processo.relatorios[0].coreListaRelatorioRespostaId });
                }}>
            <MdiIcon path={mdiMagnify}/>
          </IconButton>
        </Tooltip>);
        }
        return actions;
    };
    return (<Fragment>
      <h1 className='section-title mt-2' id='services'>
        <span>Processo {processo && processo.numeroProcesso}</span>
      </h1>
      <ol className='breadcrumb text-left'>
        <li>Gás</li>
        <li className='active'>Processos</li>
      </ol>
      {isLoading ? (<Box sx={{ display: 'flex', justifyContent: 'center', height: '2vh' }}>
          <CircularProgress size={60} color='primary'/>
        </Box>) : (<Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 2 } }}>
            <DynamicCard title='Detalhes' loading={isLoading} actions={[
                {
                    label: 'Editar',
                    icon: mdiPencil,
                    callback: async () => {
                        editDetails();
                    }
                }
            ]} content={[
                { label: 'Nº Processo', value: processo?.numeroProcesso },
                { label: 'Nº Parceiro', value: processo?.numeroParceiro ? processo.numeroParceiro : 'N/A' },
                { label: 'Data de Importação', value: showDate(processo?.dataImportacao) },
                {
                    label: 'Estado',
                    value: (<HistoryTimeline atual={processo?.estadoId} estados={estados.map(r => {
                            return {
                                id: r.id,
                                label: r.designacao
                            };
                        })} historico={processo?.alteracoesEstado.map(r => {
                            return {
                                id: r.id,
                                estado: r.final.designacao,
                                user: r.funcionario.name,
                                data: r.dataAlteracao,
                                observacoes: r.observacoes
                            };
                        })} onEstadoChange={async (id, observacoes) => {
                            try {
                                await GasService.alterarEstadoProcesso($state.params.id, id, observacoes);
                            }
                            catch (e) {
                                UIService.addToast('Ocorreu um erro ao alterar o estado do processo');
                            }
                            fetchData();
                        }}/>)
                },
                { label: 'Tipo de Gás', value: processo?.tipoGas ? processo.tipoGas.designacao : 'N/D' },
                { label: 'Tipo de Instalação', value: processo?.tipoInstalacao ? processo.tipoInstalacao.designacao : 'N/D' },
                { label: 'Tipo de Inspeção', value: processo?.tipoInspecao ? processo.tipoInspecao.designacao : 'N/D' },
                { label: 'CUI', value: processo?.cui },
                { label: 'Distribuidor', value: processo?.distribuidor ? processo.distribuidor.designacao : 'N/D' },
                { label: 'Distribuidor Presente', value: processo?.distribuidorPresente ? 'Sim' : 'Não' },
                { label: 'Nome do Técnico do Distribuidor', value: processo?.distribuidorPresente ? processo.distribuidorTecnicoNome : 'N/A' },
                { label: 'ORD', value: processo?.ord ? processo.ord.designacao : 'N/D' },
                { label: 'Agendamento na Hora', value: processo?.agendamentoNaHora ? 'Sim' : 'Não' },
                { label: 'Observações', value: processo?.observacoes || '-' }
            ]}/>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 2 } }}>
            <Item sx={{ mb: 2 }}>
              <DetailsBox title='Agendamento' emptyDetails={emptyAgendamento} emptyMessage={emptyAgendamento ? 'Sem agendamento definido' : null} entries={agendamentoDetailsEntryList} action={editAgendamento}></DetailsBox>
            </Item>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 2 } }}>
            <Item>{<DetailsBox title='Cliente' entries={clienteDetailsEntryList} action={editCliente}></DetailsBox>}</Item>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 2 } }}>
            <Item>{<DetailsBox title='Dados de Faturação' entries={faturacaoDetailsEntryList} action={editFaturacao}></DetailsBox>}</Item>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: { xs: 2 } }}>
            <Item>
              <DetailsBox title='Relatórios' emptyDetails={emptyRelatorio} emptyMessage={emptyRelatorio ? 'Sem relatório definido' : null} entries={relatorioDetailsEntryList} action={getActionsForRelatorio()}></DetailsBox>
            </Item>
          </Grid>
        </Grid>)}
    </Fragment>);
};
export default GasProcessoDetailsView;
