export default class DirectoryIntervencoesController {
  constructor($state, UIService, OIService, ElvOrdemintervencao, Table) {
    this.loaded = false;
    this.ElvOrdemintervencao = ElvOrdemintervencao;
    this.UI = UIService;
    this.OIService = OIService;
    this.data = null;
    this.Table = Table;
    this.states = [];
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.ElvOrdemintervencao.find({
      filter: {
        where: {
          active: true
        },
        include: [{
          relation: 'cliente',
          where: {
            active: 1
          }
        }]
      }
    }).$promise.then((res) => {
      this.data = res;
      this.tableData = new this.Table({
        sorting: {
          id: 'desc'
        }
      }, {
        dataset: res,
      });
      this.loaded = true;
    }).catch((error) => {
      console.log(error);
      this.loaded = true;
      this.UI.addToast('Erro ao carregar ordens de intervenção');
    })
  };

  confirmRemoveOi = (oi) => {
    let title = "Eliminar Ordem de Intervenção";
    let warning = "Tem a certeza que pretende remover esta ordem de intervenção?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeOi(oi);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };


  removeOi = (oi) => {
    let wait = this.UI.showWaiting();
    this.OIService.removeOi(oi).then(() => {
      wait.close();
      this.loadData();
    }).catch(error => {
      if (error) console.log(error);
      wait.close();
      this.loadData();
    });
  };
}

DirectoryIntervencoesController.$inject = ['$state', 'UIService', 'OIService', 'ElvOrdemintervencao', 'NgTableParams'];
