export function routes($stateProvider) {
  $stateProvider
    .state('app.lab.processes', {
      url: '/processos',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.lab.processes.details', {
      url: '/:id',
      template: require('./details/details.view.html'),
      controller: 'LmeDetailProcessController',
      controllerAs: 'vm',
      role: ['ensaioLMEList','calibLMEList'],
      title: 'Detalhes de Processo'
    })
    .state('app.lab.processes.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Gestão de Processos',
      template: require('./directory/directory.view.html'),
      controller: 'LmeDirectorySchedulingController',
      controllerAs: 'vm',
      role: ['ensaioLMEList','calibLMEList'],
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    });
}

routes.$inject = ['$stateProvider'];
