import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
const DialogTipoDefeito = ({ departamentos, onClose, ...props }) => {
    const validationSchema = yup.object({
        coreDepartamentoId: yup.number().required('O campo é obrigatório'),
        designacao: yup.string().required('O campo é obrigatório')
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            let a = {
                designacao: values.designacao,
                coreDepartamentoId: values.coreDepartamentoId
            };
            onClose(a);
        }
    });
    useEffect(() => {
        formik.resetForm();
        formik.setValues({ designacao: '' });
    }, [departamentos]);
    return (<form>
      <Dialog {...props} maxWidth={'lg'} fullWidth>
        <DialogTitle>Adicionar tipo de defeito/cláusula</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={2} md={4} xs={4}>
              <TextField label='Departamento' name='coreDepartamentoId' fullWidth value={formik.values?.coreDepartamentoId} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.coreDepartamentoId && Boolean(formik.errors.coreDepartamentoId)} helperText={formik.touched.coreDepartamentoId && formik.errors.coreDepartamentoId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {departamentos.map(x => (<MenuItem value={x.id} key={x.id}>
                    {x.designacao}
                  </MenuItem>))}
              </TextField>
            </Grid>
            <Grid lg={10} md={8} xs={8}>
              <TextField label='Designação' name='designacao' value={formik.values?.designacao} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogTipoDefeito;
