import OIService from "../ordensintervencao.service";

export default class CreateIntervencaoController {
  constructor($scope, $state, UIService, OIService, ElvCliente, ElvEmie, $http, PRICabecdoc, ElvOrdemintervencao, ElvCodigoArtigo) {
    this.state = $state;
    this.UI = UIService;
    // State control
    this.$http = $http;
    this.submitting = false;
    this.loaded = false;
    this.PRICabecdoc = PRICabecdoc;
    this.Service = OIService;
    this.ElvCliente = ElvCliente;
    this.ElvOrdemintervencao = ElvOrdemintervencao;
    this.ElvCodigoArtigo = ElvCodigoArtigo;
    this.ElvEmie = ElvEmie;
    this.loadData();
    this.form = {
      details: {
        id: 0,
        ano: moment().format('YYYY')
      },
      client: {},
      processes: []
    };
  }

  askForOI = () => {
    let dialog = this.UI.showDialog({
      template: require('./primavera.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.detail = {};

        $scope.data = {
          ano: moment(),
        };

        $scope.ok = () => {
          $scope.$close($scope.data);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
          this.state.go('app.elv.oi.list');
        }
      }]
    });
    dialog.then(res => {
      if (res) {
        this.getPrimaveraData(res);
      }
    })
  };

  getPrimaveraData = (data) => {
    let codigos = ['INS.IELV', 'INS.ATEV', 'INS.AELV', 'INS.EELV', 'INS.SELV', 'INS.RELV'];
    let wait = this.UI.showWaiting();
    this.PRICabecdoc.find({
      filter: {
        where: {
          tipodoc: 'OI',
          serie: moment(data.ano).format('YY'),
          numdoc: data.no
        },
        include: ['linhas', 'cliente']
      }
    }).$promise.then(res => {
      if (res.length === 0) {
        wait.close();
        this.state.go('app.elv.oi.list');
        this.UI.addToast('Nenhuma OI registada no Primavera com os dados fornecidos!');
      } else {
        if (res[0].linhas.length === 0) {
          this.state.go('app.elv.oi.list');
          this.UI.addToast('A OI inserida não pertence à Inspeção de Elevadores!');
        } else {
          this.ElvOrdemintervencao.find({
            filter: {
              fields: {primaveraId: true},
              where: {
                primaveraId: res[0].id
              }
            }
          }).$promise.then(exists => {
            if (!exists.length) {
              this.form.tipo = null;
              this.form.details = {
                ano: moment(data.ano).format('YYYY'),
                num: data.no,
                primaveraId: res[0].id,
                dataRececao: moment(res[0].data).format('YYYY-MM-DD')
              };
              this.form.primavera = res[0];
              this.form.client = {
                nif: res[0].cliente.numcontrib,
                nome: res[0].cliente.nome,
                morada: res[0].cliente.facMor,
                cp4: res[0].cliente.facCp ? res[0].cliente.facCp.split("-")[0] : null,
                cp3: res[0].cliente.facCp ? res[0].cliente.facCp.split("-")[1] : null,
                localidade: res[0].cliente.facCploc,
                contactoTelefonico: res[0].cliente.facTel,
                observacoes: res[0].cliente.notas,
                atualizadoa: res[0].cliente.dataultimaactualizacao,
                refClientePrimavera: res[0].cliente.cliente,
                email: res[0].cduEmail
              };
              res[0].linhas.forEach(linha => {
                if (linha.artigo !== null) {
                  if (linha.artigo.indexOf('INS') >= 0) {
                    let tmp = codigos.find(c => c === linha.artigo);
                    if (tmp) {
                      this.form.processes.push({
                        id: 0,
                        tipoId: 1,
                        artigo: linha.descricao,
                        codigo: linha.artigo,
                        laboratorioId: 1,
                        quantidade: linha.quantidade,
                        maxQuantidade: linha.quantidade,
                        acreditado: 1,
                        inSitu: 0,
                        valor: linha.quantidade ? linha.precoliquido / linha.quantidade : linha.precoliquido,
                        primaveraId: linha.id
                      });
                    }
                  }
                }
              });
              this.UI.addToast('OI carregada com sucesso!');
            } else {
              this.state.go('app.elv.oi.list');
              this.UI.addToast('A OI introduzida já existe no sistema.');
            }
            wait.close();
            this.loaded = true;
          }).catch(err => {
            console.log(err);
            this.UI.addToast("Não foi possível verificar existência de OI. Por favor tente mais tarde.");
          });
        }
      }
    }).catch(() => {
      wait.close();
      this.UI.addToast('Erro ao carregar dados do Primavera!');
    })
  };

  loadData = () => {
    this.loaded = false;
    this.ElvEmie.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(emies => {
      this.emies = emies;
      this.ElvCodigoArtigo.find({
        filter: {
          where: {
            active: true
          }
        }
      }).$promise.then((codigos) => {
        this.codArtigos = codigos;
        this.askForOI();
      }).catch((error) => {
        this.state.go('app.elv.oi.list');
        this.UI.addToast('Erro ao obter códigos de artigos');
      });
    }).catch((error) => {
      this.state.go('app.elv.oi.list');
      this.UI.addToast('Erro ao obter códigos de artigos');
    });
  };

  editProcess = (data) => {
    let options = {
      size: 'lg',
      template: require('./process.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.modalTitle = 'Editar Processo';
        $scope.data = data;
        $scope.yesNo = [{
          id: 0,
          value: 'Não'
        }, {
          id: 1,
          value: 'Sim'
        }];

        $scope.auxAcreditado = {
          selected: $scope.yesNo.find(f => f.id === data.acreditado)
        };

        $scope.auxInSitu = {
          selected: $scope.yesNo.find(f => f.id === data.inSitu)
        };

        $scope.disableLab = false;

        $scope.loaded = true;

        $scope.ok = () => {
          if ($scope.loaded) {
            $scope.$close($scope.data);
          } else
            $scope.$dismiss('cancel');
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    this.UI.showDialog(options).then(res => {
      this.data = res;
    });
  };

  addProcess = () => {
    let options = {
      size: 'lg',
      template: require('./process.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.modalTitle = 'Adicionar Processo';
        $scope.disableLab = false;

        $scope.data = {
          id: 0,
          imported: false
        };

        $scope.yesNo = [{
          id: 0,
          value: 'Não'
        }, {
          id: 1,
          value: 'Sim'
        }];

        $scope.auxAcreditado = {
          selected: $scope.yesNo[1]
        };

        $scope.auxInSitu = {
          selected: $scope.yesNo[0]
        };

        $scope.loaded = true;

        $scope.ok = () => {
          if ($scope.loaded) {
            $scope.$close($scope.data);
          } else
            $scope.$dismiss('cancel');
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }],
    };

    let modal = this.UI.showDialog(options);
    modal.then((res) => {
      if (res) {
        this.form.processes.push(res);
      }
    })
  };

  removeProcess = r => {
    let confirm = this.UI.showConfirm('Deseja remover processo?');
    confirm.then(res => {
      if (res) {
        this.form.processes.splice(this.form.processes.indexOf(r), 1);
      }
    })
  };

  registerOI = id => {
    let total = 0;
    this.form.primavera.linhas.forEach(l => {
      total += l.precoliquido;
    });
    // Regista dados da OI
    let oiData = {
      id: 0,
      clienteId: id,
      ano: this.form.details.ano,
      no: this.form.details.num,
      primaveraId: this.form.details.primaveraId,
      observacoes: this.form.details.observacoes,
      dataRececao: this.form.details.dataRececao,
      numeroEncomenda: this.form.details.numeroEncomenda,
      contrato: this.form.details.contrato,
      total: total,
      refClientePrimavera: this.form.client.refClientePrimavera,
      active: true
    };
    this.Service.register(oiData, this.form.processes).then(res => {
      this.isWaiting.close();
      this.state.go('app.elv.oi.list');
      this.UI.addToast('Ordem de intervenção adicionada');
    }).catch((error) => {
      console.log(error);
      this.UI.addToast('Erro na importação da OI. Falha na criação de intervencoes');
    });
  };

  canAdd = (id) => {
    let exists = this.form.processes.find(p => p.primaveraId === id);
    return !exists;
  };

  register = () => {
    let confirm = this.UI.showConfirm('Concluir criação de Ordem de Intervenção?');
    confirm.then(res => {
      if (res) {
        if (this.form.processes.length === 0) {
          this.UI.addToast('A OI não tem processos associados!');
        } else {
          this.isWaiting = this.UI.showWaiting();
          this.ElvCliente.find({
            filter: {
              where: {
                or: [{nif: this.form.client.nif}, {refClientePrimavera: this.form.client.refClientePrimavera}]
              }
            }
          }).$promise.then(client => {
            if (client.length === 0) {
              this.form.client.id = 0;
              this.form.client.active = true;
              this.ElvCliente.create(this.form.client).$promise.then(c => {
                this.UI.addToast('Novo cliente registado!');
                this.registerOI(c.id);
              });
            } else {
              this.registerOI(client[0].id);
            }
          });
        }
      }
    });
  };
}

CreateIntervencaoController.$inject = ['$scope', '$state', 'UIService', 'OIService', 'ElvCliente', 'ElvEmie', '$http', 'PRICabecdoc', 'ElvOrdemintervencao', 'ElvCodigoArtigo'];
