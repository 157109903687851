export function routes($stateProvider) {
  $stateProvider
    .state('app.pedidos.reports', {
      url: '/relatorios',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.pedidos.reports.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Relatórios',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryPedidosReportController',
      controllerAs: 'vm',
      role: 'reportsIeList',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.pedidos.reports.details', {
      url: '/{id:int}',
      title: 'Visualizar Relatório',
      template: require('./details/report.view.html'),
      controller: 'DetailPedidoReportController',
      role: 'reportsIeList',
      controllerAs: 'vm',
    });
}

routes.$inject = ['$stateProvider'];
