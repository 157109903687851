export default class OblCalendarizacaoController {
  constructor($state, OblAgendamento, OblProcesso, FuncionarioEntidadeProprietaria, Avatar, UIService, AuthenticationService, AuthorizationService) {
    this.$state = $state;
    this.OblAgendamento = OblAgendamento;
    this.OblProcesso = OblProcesso;
    this.FuncionarioEntidadeProprietaria = FuncionarioEntidadeProprietaria;
    this.Auth = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.api = undefined;
    this.Avatar = Avatar;
    this.UI = UIService;
    this.search = "";
    this.clients = [];
    this.selected = 0;
    this.select = false;

    this.completed = $state.params.completed == 1;
    this.open = $state.params.open == 1;

    this.calendarLoading = true;

    this.calendarOptions = {
      dayClick: (start, end, jsEvent, view) => {
        if (this.AuthorizationService.canPerform('oblerCriarProcessos') && this.AuthorizationService.canPerform('oblerVerAllCalendarizacao')) {
          this.addAgendamento(start);
        }
      },
      viewRender: () => {
        this.getAgendamentos();
      },
      eventRender: (event, element) => {
        if (this.api.view.name == "listMonth") {
          let target = angular.element(element[0].querySelector('.fc-list-item-title'));
          let child = document.createElement("span");
          let division = document.createTextNode(" - ");
          let text = document.createTextNode(event.user);
          child.appendChild(division);
          child.appendChild(text);
          target.append(child);
        } else if (this.api.view.name == "month") {
          let target = angular.element(element[0].querySelector('.fc-title'));
          let child = document.createElement("span");
          let division = document.createTextNode(" - ");
          let text = document.createTextNode(event.user);
          child.appendChild(division);
          child.appendChild(text);
          target.append(child);
        } else {
          let target = angular.element(element[0].querySelector('.fc-time'));
          let child = document.createElement("span");
          let division = document.createTextNode(" - ");
          let text = document.createTextNode(event.user);
          if (!event.isAllDay) {
            child.appendChild(division);
            child.appendChild(text);
            target.append(child);
          } else {
            target[0].innerHTML = `<span>${event.user}</span>`
            target[0].innerText = event.user;
          }
        }
      },
      eventClick: (event) => {
        if (event.extra && event.extra.tipoId) {
          if (event.extra.tipoId == 2) {
            this.$state.go(`app.obler.oi.folha`, { id: event.extra.oiId, ficha: event.extra.id })
          } else if (event.extra.tipoId == 3) {
            this.$state.go(`app.obler.oi.folha`, { id: event.extra.oiId, ficha: event.extra.id })
          } else {
            this.$state.go(`app.obler.oi.details`, { id: event.extra.oiId })
          }
        }
      },
      customButtons: {
        filter: {
          text: 'Filtrar',
          click: () => {
            this.$state.go('app.obler.calendario', {
              funcionario: this.funcionario == undefined ? undefined : this.funcionario.id,
              completed: +this.completed,
              open: +this.open
            }, {
              notify: false,
              reload: false,
              location: 'replace',
              inherit: true
            });
            this.getAgendamentos();
          }
        },
        clear: {
          text: 'LIMPAR',
          click: () => {
            this.$state.go('app.obler.calendario', {
              funcionario: undefined,
              completed: 1,
              open: 1
            }, {
              notify: false,
              reload: false,
              location: 'replace',
              inherit: true
            });
            this.getAgendamentos();
          }
        }
      },
      timeFormat: 'HH:mm',
      defaultView: 'agendaWeek',
      locale: 'pt',
      themeSystem: 'bootstrap4',
      header: {
        left: 'prev,next today filter clear',
        center: 'title',
        right: 'month,agendaWeek,agendaDay,listMonth'
      },
      navLinks: true,
      defaultTimedEventDuration: '01:00:00',
      allDaySlot: false,
      displayEventEnd: true,
      minTime: "08:00:00",
      maxTime: "22:00:00",
      height: 'auto',
      slotDuration: "00:15:00",
      slotLabelFormat: "HH:mm",
      views: {
        agendaWeek: {
          columnHeaderFormat: "DD/MM"
        }
      },
      firstDay: 1,
      nowIndicator: true,
      weekends: true,
      hiddenDays: [0],
      weekNumberTitle: 'Sem.',
      eventLimitText: 'agd.',
      noEventsMessage: 'Sem agendamentos a apresentar.',
      weekNumbers: true,
      buttonText: {
        today: 'Hoje',
        month: 'Mensal',
        week: 'Semanal',
        day: 'Diário',
        list: 'Lista'
      }
    };
    this.ausencias = [];
    this.agendamentos = [];
    this.calendarEvents = [];

    if (!this.AuthorizationService.canPerform('oblerVerAllCalendarizacao') && $state.params.funcionario == null) {
      $state.params.funcionario = this.Auth.getId();
      $state.go($state.current, $state.params);
    }

    this.getAgendamentos();
  };

  isCalendarAdmin = () => this.AuthorizationService.canPerform(['oblerVerAllCalendarizacao']);

  getAgendamentos = () => {
    // Filtra datas confome dos dados da API devolvida pelo FullCalendar, passa a ser carregado dinamicamente
    let from, to;

    if (this.api != undefined) {
      let date = this.api.getDate();
      let view = this.api.view.name;
      switch (view) {
        case 'month': {
          from = moment(date).startOf('month');
          to = moment(date).endOf('month');
          break;
        }
        case 'agendaWeek': {
          from = moment(date).startOf('week');
          to = moment(date).endOf('week');
          break;
        }
        case 'agendaDay': {
          from = moment(date).startOf('day');
          to = moment(date).endOf('day');
          break;
        }
        case 'listMonth': {
          from = moment(date).startOf('month');
          to = moment(date).endOf('month');
          break;
        }
      }
    } else {
      from = moment().startOf('week');
      to = moment().endOf('week');
    }

    if (this.AuthorizationService.canPerform('oblerVerAllCalendarizacao')) {
      this.FuncionarioEntidadeProprietaria.find({
        filter: {
          where: {
            entidadeProprietariaId: 3,
            active: true,
          },
          include: {
            relation: 'funcionario',
            scope: {
              include: {
                relation: 'ausencias',
                scope: {
                  where: {
                    dataInicio: {
                      gte: from
                    },
                    dataFim: {
                      lte: to
                    }
                  }
                }
              }
            }
          }
        }
      }).$promise.then(f => {
        this.funcionarios = [];
        this.ausencias = [];
        f.forEach(r => {
          this.funcionarios.push(r.funcionario);
          if (r.funcionario.ausencias.length > 1) {
            r.funcionario.ausencias.forEach(ausencia => {
              let o = {
                title: ausencia.motivo,
                user: r.funcionario.name,
                userId: r.funcionario.id
              }
              if (moment.utc(ausencia.dataInicio).isSame(ausencia.dataFim, 'day')) {
                if (ausencia.diaInteiro) {
                  o.start = moment.utc(`${moment(ausencia.dataInicio).format("YYYY-MM-DD")}`).startOf('day');
                  o.end = moment.utc(`${moment(ausencia.dataFim).format("YYYY-MM-DD")}`).endOf('day');
                  o.isAllDay = true;
                } else {
                  let start = moment.utc(`${moment(ausencia.dataInicio).format("YYYY-MM-DD")} ${ausencia.horaInicio}`);
                  let end = moment.utc(`${moment(ausencia.dataFim).format("YYYY-MM-DD")} ${ausencia.horaFim}`);
                  o.start = start;
                  o.end = end;
                }
              } else {
                o.start = moment.utc(ausencia.dataInicio);
                o.end = moment.utc(ausencia.dataFim).add(1, 'day');
                o.className = "hideCalendarTime";
              }
              this.ausencias.push(o);
            });
          }
        });
        this.funcionarios = this.funcionarios.sort((a, b) => a.name.localeCompare(b.name));
        if (this.$state.params.funcionario != undefined) {
          this.funcionario = this.funcionarios.find(r => r.id == this.$state.params.funcionario);
        }
        if (this.funcionario) {
          this.ausencias = this.ausencias.filter(r => r.userId == this.$state.params.funcionario);
          this.OblAgendamento.find({
            filter: {
              where: {
                active: true,
                tecnicoId: this.$state.params.funcionario
              },
              include: ['Funcionario',
                {
                  relation: 'Processo',
                  scope: {
                    include: ['TipoProcesso', {
                      relation: 'Ordemintervencao',
                      scope: {
                        include: {
                          relation: 'Valencia',
                          scope: {
                            include: 'categoria'
                          }
                        }
                      }
                    }]
                  }
                }]
            }
          }).$promise.then(r => {
            this.agendamentos = r;
            let data = [];
            r.forEach(event => {
              if (this.open == true && event.Processo.validado == 0) {
                let date = moment.utc(`${moment(event.data).format("YYYY/MM/DD")} ${event.hora}`);
                let title = `${event.Processo.Ordemintervencao.numeroProcesso} | ${event.Processo.Ordemintervencao.Valencia != null ? event.Processo.Ordemintervencao.Valencia.categoria.designacao + " - " + event.Processo.Ordemintervencao.Valencia.designacao : "S/ Valência"}\n${event.Processo.TipoProcesso.designacao}`;
                if (event.Processo.Ordemintervencao.origem == 2 && event.Processo.Ordemintervencao.parceiro && event.Processo.Ordemintervencao.parceiro.trim().length > 0) {
                  title += ` (${event.Processo.Ordemintervencao.parceiro})`;
                }
                data.push({
                  id: event.id,
                  start: date,
                  user: event.Funcionario.name,
                  allDay: false,
                  extra: event.Processo,
                  end: moment.utc(date).add(event.duracao, 'minute'),
                  title: title,
                  color: this.Avatar.getColor(this.Avatar.getInitials(event.Funcionario.name))
                });
              }
              if (this.completed == true && event.Processo.validado > 0) {
                let date = moment.utc(`${moment(event.data).format("YYYY/MM/DD")} ${event.hora}`);
                let title = `${event.Processo.Ordemintervencao.numeroProcesso} | ${event.Processo.Ordemintervencao.Valencia != null ? event.Processo.Ordemintervencao.Valencia.categoria.designacao + " - " + event.Processo.Ordemintervencao.Valencia.designacao : "S/ Valência"}\n${event.Processo.TipoProcesso.designacao}`;
                if (event.Processo.Ordemintervencao.origem == 2 && event.Processo.Ordemintervencao.parceiro && event.Processo.Ordemintervencao.parceiro.trim().length > 0) {
                  title += ` (${event.Processo.Ordemintervencao.parceiro})`;
                }
                data.push({
                  id: event.id,
                  start: date,
                  user: event.Funcionario.name,
                  allDay: false,
                  extra: event.Processo,
                  end: moment.utc(date).add(event.duracao, 'minute'),
                  title: title,
                  color: this.Avatar.getColor(this.Avatar.getInitials(event.Funcionario.name))
                });
              }
            });
            this.calendarEvents = data;
            this.calendarEvents.push(...this.ausencias);
            this.calendarLoading = false;
          });
        } else {
          this.calendarLoading = false;
        }
      });
    } else {
      this.OblAgendamento.find({
        filter: {
          where: {
            active: true,
            tecnicoId: this.$state.params.funcionario
          },
          include: ['Funcionario',
            {
              relation: 'Processo',
              scope: {
                include: ['TipoProcesso', {
                  relation: 'Ordemintervencao',
                  scope: {
                    include: {
                      relation: 'Valencia',
                      scope: {
                        include: 'categoria'
                      }
                    }
                  }
                }]
              }
            }]
        }
      }).$promise.then(r => {
        this.agendamentos = r;
        let data = [];
        r.forEach(event => {
          if (this.open == true && event.Processo.validado == 0) {
            let date = moment.utc(`${moment(event.data).format("YYYY/MM/DD")} ${event.hora}`);
            let title = `${event.Processo.Ordemintervencao.numeroProcesso} | ${event.Processo.Ordemintervencao.Valencia != null ? event.Processo.Ordemintervencao.Valencia.categoria.designacao + " - " + event.Processo.Ordemintervencao.Valencia.designacao : "S/ Valência"}\n${event.Processo.TipoProcesso.designacao}`;
            if (event.Processo.Ordemintervencao.origem == 2 && event.Processo.Ordemintervencao.parceiro && event.Processo.Ordemintervencao.parceiro.trim().length > 0) {
              title += ` (${event.Processo.Ordemintervencao.parceiro})`;
            }
            data.push({
              id: event.id,
              start: date,
              user: event.Funcionario.name,
              allDay: false,
              extra: event.Processo,
              end: moment.utc(date).add(event.duracao, 'minute'),
              title: title,
              color: this.Avatar.getColor(this.Avatar.getInitials(event.Funcionario.name))
            });
          }
          if (this.completed == true && event.Processo.validado > 0) {
            let date = moment.utc(`${moment(event.data).format("YYYY/MM/DD")} ${event.hora}`);
            let title = `${event.Processo.Ordemintervencao.numeroProcesso} | ${event.Processo.Ordemintervencao.Valencia != null ? event.Processo.Ordemintervencao.Valencia.categoria.designacao + " - " + event.Processo.Ordemintervencao.Valencia.designacao : "S/ Valência"}\n${event.Processo.TipoProcesso.designacao}`;
            if (event.Processo.Ordemintervencao.origem == 2 && event.Processo.Ordemintervencao.parceiro && event.Processo.Ordemintervencao.parceiro.trim().length > 0) {
              title += ` (${event.Processo.Ordemintervencao.parceiro})`;
            }
            data.push({
              id: event.id,
              start: date,
              user: event.Funcionario.name,
              allDay: false,
              extra: event.Processo,
              end: moment.utc(date).add(event.duracao, 'minute'),
              title: title,
              color: this.Avatar.getColor(this.Avatar.getInitials(event.Funcionario.name))
            });
          }
        });
        this.calendarEvents = data;
        this.calendarEvents.push(...this.ausencias);
        this.calendarLoading = false;
      });
    }

  };

  addAgendamento = (date) => {
    if (!this.AuthorizationService.canPerform('oblerVerAllCalendarizacao')) {
      return;
    }
    this.UI.showDialog({
      template: require('./dialog.html'),
      controller: ['$scope', 'OblOrdemintervencao', 'FuncionarioEntidadeProprietaria', ($scope, OblOrdemintervencao, FuncionarioEntidadeProprietaria) => {
        $scope.tecnicosLoaded = false;
        $scope.processosLoaded = false;
        $scope.aux = {};
        $scope.items = [];
        $scope.data = {
          duracao: 60,
          date: date
        };

        OblOrdemintervencao.find({
          filter: {
            where: {
              estadoId: {
                inq: [2, 7]
              },
              active: true
            }
          }
        }).$promise.then(r => {
          $scope.items = r;
          $scope.processosLoaded = true;
        }).catch(e => {
        });

        $scope.tipos = [{ id: 2, designacao: 'Orçamentação' }, { id: 3, designacao: 'Visita técnica' }];

        $scope.ok = () => {
          let o = {
            id: 0,
            data: $scope.data.date.format("YYYY-MM-DD"),
            hora: $scope.data.date.format("HH:mm"),
            tecnicoId: $scope.aux.user.id,
            agendandoa: moment.utc(),
            agendadoporId: this.Auth.getId(),
            duracao: $scope.data.duracao,
            oiId: $scope.aux.oi.id,
            tipoId: $scope.aux.tipo.id,
            active: true
          };
          $scope.$close(o);
        };

        $scope.cancel = () => { $scope.$dismiss() };

        FuncionarioEntidadeProprietaria.find({
          filter: {
            where: {
              entidadeProprietariaId: 3,
              active: true
            },
            include: {
              relation: 'funcionario',
              scope: {
                include: {
                  relation: 'ausencias',
                  scope: {
                    where: {
                      dataInicio: {
                        lte: date
                      },
                      dataFim: {
                        gte: date
                      }
                    }
                  }
                }
              }
            }
          }
        }).$promise.then(f => {
          $scope.users = [];
          f.forEach(r => {
            if (r.funcionario) {
              if (r.funcionario.ausencias.length == 0) {
                r.funcionario.disabled = false;
                $scope.users.push(r.funcionario);
              }
            }
          });
          $scope.users = $scope.users.sort((a, b) => a.name.localeCompare(b.name));
          $scope.tecnicosLoaded = true;
        });

        $scope.isUserDisabled = (item) => {
          return item.disabled;
        }
      }]
    }).then(r => {
      // Cria processo
      this.OblProcesso.create({
        id: 0,
        tipoId: r.tipoId,
        data: moment.utc(),
        funcionarioId: r.tecnicoId,
        criadoPor: r.agendadoporId,
        oiId: r.oiId,
        active: true
      }).$promise.then(p => {
        r.processoId = p.id;
        r.agendadoa = p.data;
        this.OblAgendamento.create(r).$promise.then(_ => {
          this.UI.addToast("Agendamento criado com sucesso");
          this.getAgendamentos();
        }).catch(e => {
          this.UI.addToast("Ocorreu um erro ao agendar processo");
        });
      }).catch(e => {
        this.UI.addToast("Ocorreu um erro ao agendar processo");
      });
    });
  }
}

OblCalendarizacaoController.$inject = ['$state', 'OblAgendamento', 'OblProcesso', 'FuncionarioEntidadeProprietaria', 'Avatar', 'UIService', 'AuthenticationService', 'AuthorizationService'];

