import React, { useEffect, useMemo, useState } from 'react';
import { Table, WaitDialog } from '@components';
import { useModal } from 'mui-modal-provider';
import { Typography, Breadcrumbs, Link, Card, Tab, Tabs, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment/moment';
import DialogAgendamentoProcesso from '../../processos/details/dialogAgendamento';
var TABS;
(function (TABS) {
    TABS[TABS["SCHEDULING"] = 0] = "SCHEDULING";
    TABS[TABS["SCHEDULED"] = 1] = "SCHEDULED";
})(TABS || (TABS = {}));
const CustomTabPanel = ({ children, value, index }) => {
    return (<div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
      {value === index && <Box>{children}</Box>}
    </div>);
};
const GasAgendamentosListView = ({ $state, GasService, UIService }) => {
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const { showModal } = useModal();
    // Tab stuff
    const [currentTab, setCurrentTab] = React.useState($state.params.tab || 0);
    const [scheduling, setScheduling] = useState();
    const [scheduled, setScheduled] = useState();
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [options, setOptions] = useState({
        filter: [],
        sort: []
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    });
    const [scheduledFilter, setScheduledFilter] = useState([]);
    const [schedulingFilter, setSchedulingFilter] = useState([]);
    const [sort, setSort] = useState(() => loadParams());
    const [ord, setORD] = useState([]);
    const [distribuidor, setDistribuidor] = useState([]);
    const [tipoGas, setTipoGas] = useState([]);
    const [tipoInstalacao, setTipoInstalacao] = useState([]);
    const [tipoInspecao, setTipoInspecao] = useState([]);
    const [tecnicos, setTecnicos] = useState([]);
    const columnsScheduling = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50,
            Cell: ({ renderedCellValue, row }) => (<Link underline='none' color='default' sx={{ fontWeight: 'bold' }} onClick={() => {
                    $state.go('app.gas.processos.details', { id: row.original.id });
                }}>
            {renderedCellValue}
          </Link>)
        },
        {
            accessorKey: 'numeroProcesso',
            header: 'Nº Processo',
            size: 100,
            Cell: ({ renderedCellValue, row }) => (<Link underline='none' color='default' sx={{ fontWeight: 'bold' }} onClick={() => {
                    $state.go('app.gas.processos.details', { id: row.original.id });
                }}>
            {renderedCellValue}
          </Link>)
        },
        {
            accessorKey: 'numeroParceiro',
            header: 'Nº Parceiro',
            size: 100
        },
        {
            accessorKey: 'tipoGasId',
            header: 'Tipo de Gás',
            size: 140,
            filterSelectOptions: tipoGas.map(r => {
                return {
                    value: r.id,
                    label: r.designacao
                };
            }),
            Cell: ({ row }) => row.original.tipoGas?.designacao
        },
        {
            accessorKey: 'tipoInstalacaoId',
            header: 'Tipo de Instalação',
            size: 140,
            filterSelectOptions: tipoInstalacao.map(r => {
                return {
                    value: r.id,
                    label: r.designacao
                };
            }),
            Cell: ({ row }) => row.original.tipoInstalacao?.designacao
        },
        {
            accessorKey: 'tipoInspecaoId',
            header: 'Tipo de Inspeção',
            size: 140,
            filterSelectOptions: tipoInspecao.map(r => {
                return {
                    value: r.id,
                    label: r.designacao
                };
            }),
            Cell: ({ row }) => row.original.tipoInspecao?.designacao
        },
        {
            accessorKey: 'distribuidorId',
            header: 'Distribuidor',
            size: 150,
            filterSelectOptions: distribuidor.map(r => {
                return {
                    value: r.id,
                    label: r.designacao
                };
            }),
            Cell: ({ row }) => row.original.distribuidor?.designacao
        },
        {
            accessorKey: 'ordId',
            header: 'ORD',
            size: 150,
            filterSelectOptions: ord.map(r => {
                return {
                    value: r.id,
                    label: r.designacao
                };
            }),
            Cell: ({ row }) => row.original.ord?.designacao
        },
        {
            accessorKey: 'dataImportacao',
            header: 'Data de Importação',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (moment(row.original.dataImportacao).isValid() ? moment(row.original.dataImportacao).format('DD/MM/YYYY') : 'N/D')
        }
    ], [ord, distribuidor, tipoGas, tipoInstalacao, tipoInspecao]);
    const columnsScheduled = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50,
            Cell: ({ renderedCellValue, row }) => (<Link underline='none' color='default' sx={{ fontWeight: 'bold' }} onClick={() => {
                    $state.go('app.gas.processos.details', { id: row.original.id });
                }}>
            {renderedCellValue}
          </Link>)
        },
        {
            accessorKey: 'numeroProcesso',
            header: 'Nº Processo',
            size: 100,
            Cell: ({ renderedCellValue, row }) => (<Link underline='none' color='default' sx={{ fontWeight: 'bold' }} onClick={() => {
                    $state.go('app.gas.processos.details', { id: row.original.id });
                }}>
            {renderedCellValue}
          </Link>)
        },
        {
            accessorKey: 'numeroParceiro',
            header: 'Nº Parceiro',
            size: 100
        },
        {
            accessorKey: 'agendamento.data',
            header: 'Data de Inspeção',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (moment(row.original.agendamento?.data).isValid() ? moment(row.original.agendamento?.data).format('DD/MM/YYYY') : 'N/D')
        },
        {
            accessorKey: 'agendamento.hora',
            header: 'Hora',
            size: 50,
            Cell: ({ renderedCellValue, row }) => (row.original.agendamento?.hora ? row.original.agendamento?.hora.substring(0, 5) : 'N/D')
        },
        {
            accessorKey: 'agendamento.duracao',
            header: 'Duração (min)',
            size: 80
        },
        {
            accessorKey: 'agendamento.tecnicoId',
            header: 'Técnico',
            size: 140,
            filterSelectOptions: tecnicos.map(r => {
                return {
                    value: r.funcionarioId,
                    label: r.funcionario.name
                };
            }),
            Cell: ({ row }) => row.original.agendamento?.tecnico?.name
        },
        {
            accessorKey: 'tipoGasId',
            header: 'Tipo de Gás',
            size: 140,
            filterSelectOptions: tipoGas.map(r => {
                return {
                    value: r.id,
                    label: r.designacao
                };
            }),
            Cell: ({ row }) => row.original.tipoGas?.designacao
        },
        {
            accessorKey: 'tipoInstalacaoId',
            header: 'Tipo de Instalação',
            size: 140,
            filterSelectOptions: tipoInstalacao.map(r => {
                return {
                    value: r.id,
                    label: r.designacao
                };
            }),
            Cell: ({ row }) => row.original.tipoInstalacao?.designacao
        },
        {
            accessorKey: 'tipoInspecaoId',
            header: 'Tipo de Inspeção',
            size: 140,
            filterSelectOptions: tipoInspecao.map(r => {
                return {
                    value: r.id,
                    label: r.designacao
                };
            }),
            Cell: ({ row }) => row.original.tipoInspecao?.designacao
        },
        {
            accessorKey: 'distribuidorId',
            header: 'Distribuidor',
            size: 150,
            filterSelectOptions: distribuidor.map(r => {
                return {
                    value: r.id,
                    label: r.designacao
                };
            }),
            Cell: ({ row }) => row.original.distribuidor?.designacao
        },
        {
            accessorKey: 'ordId',
            header: 'ORD',
            size: 150,
            filterSelectOptions: ord.map(r => {
                return {
                    value: r.id,
                    label: r.designacao
                };
            }),
            Cell: ({ row }) => row.original.ord?.designacao
        },
        {
            accessorKey: 'dataImportacao',
            header: 'Data de Importação',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (moment(row.original.dataImportacao).isValid() ? moment(row.original.dataImportacao).format('DD/MM/YYYY') : 'N/D')
        }
    ], [ord, distribuidor, tipoGas, tipoInstalacao, tipoInspecao, tecnicos]);
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    const editAgendamento = async (id) => {
        const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
        let agendamento = {
            id: 0,
            tecnicoId: undefined,
            duracao: undefined,
            dataAgendamento: undefined,
            processoId: undefined
        };
        try {
            const aux = await GasService.getAgendamentoForProcesso(id);
            let d = moment(aux.data);
            d.set({ hour: parseInt(aux.hora.substring(0, 2)), minute: parseInt(aux.hora.substring(3, 5)), second: 0 });
            agendamento = {
                id: aux.id,
                tecnicoId: aux.tecnicoId,
                duracao: aux.duracao,
                dataAgendamento: d,
                processoId: aux.processoId
            };
        }
        catch (e) { }
        wait.destroy();
        showModal(DialogAgendamentoProcesso, {
            initial: {
                tecnicoId: agendamento.tecnicoId,
                duracao: agendamento.duracao,
                dataAgendamento: agendamento.dataAgendamento,
                processoId: agendamento.processoId
            },
            tecnicos: tecnicos,
            onClose: async (data) => {
                if (data) {
                    const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                    try {
                        await GasService.updateAgendamento({
                            id: agendamento.id,
                            processoId: id,
                            data: data.dataAgendamento.format('YYYY-MM-DD'),
                            hora: data.dataAgendamento.format('HH:mm'),
                            duracao: data.duracao,
                            tecnicoId: data.tecnicoId
                        });
                        wait.destroy();
                    }
                    catch (e) {
                        wait.destroy();
                        UIService.addToast('Ocorreu um erro ao atualizar o agendamento');
                    }
                    fetchData();
                }
            }
        });
    };
    const initialFetch = async () => {
        if (loading) {
            setLoading(false);
            const opt = await GasService.agendamentosOptions();
            setOptions(opt);
            const ORDs = await GasService.getOrds();
            setORD(ORDs);
            const dist = await GasService.getDistribuidores();
            setDistribuidor(dist);
            const gases = await GasService.getTiposGas();
            setTipoGas(gases);
            const inst = await GasService.getTiposInstalacao();
            setTipoInstalacao(inst);
            const insp = await GasService.getTiposInspecao();
            setTipoInspecao(insp);
            const tec = await GasService.getTecnicos();
            setTecnicos(tec);
        }
    };
    const fetchData = async () => {
        try {
            let schedulingfilters = {};
            let scheduledfilters = {};
            schedulingFilter.forEach(r => {
                schedulingfilters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            scheduledFilter.forEach(r => {
                scheduledfilters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            if (currentTab === TABS.SCHEDULING) {
                $state.go($state.current.name, {
                    tab: TABS.SCHEDULING,
                    limit: pagination.pageSize,
                    page: pagination.pageIndex + 1,
                    sortBy: sorter,
                    filter: Object.keys(schedulingFilter).length > 0 ? JSON.stringify(schedulingfilters) : undefined
                }, {
                    notify: false,
                    reload: false,
                    location: 'replace',
                    inherit: true
                });
            }
            else if (currentTab === TABS.SCHEDULED) {
                $state.go($state.current.name, {
                    tab: TABS.SCHEDULED,
                    limit: pagination.pageSize,
                    page: pagination.pageIndex + 1,
                    sortBy: sorter,
                    filter: Object.keys(scheduledFilter).length > 0 ? JSON.stringify(scheduledfilters) : undefined
                }, {
                    notify: false,
                    reload: false,
                    location: 'replace',
                    inherit: true
                });
            }
            // Update URL
            const aux1 = await GasService.inScheduling({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: schedulingfilters,
                sortBy: sorter
            });
            setScheduling(aux1);
            // Update URL
            const aux2 = await GasService.scheduled({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: scheduledfilters,
                sortBy: sorter
            });
            setScheduled(aux2);
        }
        catch (e) { }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, scheduledFilter, schedulingFilter, sort]);
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>Listagem de agendamentos</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Gás
          </Link>
          <Link underline='hover' color='text.primary' aria-current='page'>
            Agendamentos
          </Link>
        </Breadcrumbs>
      </Box>

      <Box>
        <Card>
          <Box>
            <Tabs value={currentTab} onChange={(evt, value) => {
            $state.go($state.current.name, {
                tab: TABS.SCHEDULED
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            setCurrentTab(value);
        }} aria-label='Agendamento tabs'>
              <Tab label={`Em Agendamento (${scheduling?.meta?.totalItems ?? '-'})`}/>
              <Tab label={`Agendados (${scheduled?.meta?.totalItems ?? '-'})`}/>
            </Tabs>
          </Box>
        </Card>
      </Box>
      <Box>
        {currentTab === TABS.SCHEDULING && (<Table onDoubleClick={row => {
                $state.go('app.gas.processos.details', { id: row.id });
            }} storageKey={`${$state.current.name}-scheduling`} data={scheduling} columns={columnsScheduling} initial={{
                sort: sort,
                filter: schedulingFilter
            }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setSchedulingFilter} onSort={setSort} cellActionItems={({ row, table }) => {
                return [
                    <MenuItem key={`row.edit.${row.id}`} onClick={() => editAgendamento(row.original.id)}>
                  Editar agendamento
                </MenuItem>
                ];
            }}/>)}
        {currentTab === TABS.SCHEDULED && (<Table onDoubleClick={row => {
                $state.go('app.gas.processos.details', { id: row.id });
            }} storageKey={`${$state.current.name}-scheduled`} data={scheduled} columns={columnsScheduled} initial={{
                sort: sort,
                filter: scheduledFilter
            }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setScheduledFilter} onSort={setSort} cellActionItems={({ row, table }) => {
                return [
                    <MenuItem key={`row.edit.${row.id}`} onClick={() => editAgendamento(row.original.id)}>
                  Editar agendamento
                </MenuItem>
                ];
            }}/>)}
      </Box>
    </Box>);
};
export default GasAgendamentosListView;
