export default class AtvFamiliasListController {
  constructor($q, $state, UIService, AuthorizationService, AtvFamilia, AtvSubfamilia, AtvTipoAtivo, AtvAtivo) {
    this.$q = $q;
    this.state = $state;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.states = [];
    this.local = [];
    this.AtvFamilia = AtvFamilia;
    this.AtvSubfamilia = AtvSubfamilia;
    // this.AtvTipoAtivo = AtvTipoAtivo;
    this.AtvAtivo = AtvAtivo;
    this.loaded = false;
    this.familias = [];
    this.loadData();

    this.familiasOptions = {
      containment: 'parent',
      tolerance: 'pointer',
      handle: '.pmd-move-handle',
      'ui-floating': true,
      stop: (e, ui) => {
        for (let i = 0; i < this.familias.length; i++) {
          this.familias[i].ordem = i + 1;
          this.AtvFamilia.upsert(this.familias[i]).$promise.then((res) => {
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não foi possível reordenar famílias. Por favor tente mais tarde");
            this.loadData();
          });
        }
      }
    }
  }

  loadData = () => {
    this.loaded = false;
    this.AtvFamilia.find({
      filter: {
        where: {
          active: 1
        },
        order: 'ordem ASC',
        include: [{
          relation: 'subfamilias',
          scope: {
            where: {
              active: 1
            }
          }
        // }, {
        //   relation: 'tiposAtivo',
        //   scope: {
        //     fields: {id: true},
        //     where: {
        //       active: 1
        //     }
        //   }
        }, {
          relation: 'ativos',
          scope: {
            fields: {id: true},
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then(f => {
      this.familias = f;
      this.loaded = true;
    }).catch(() => {
      this.loaded = true;
      this.UI.addToast('Erro ao carregar famílias');
    });
  };

  addFamilia = () => {
    let options = {
      size: 'lg',
      template: require('./new.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Nova Família";
        scope.familia = {};

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.familia) {
        res.familia.id = 0;
        this.AtvFamilia.count({
          where: {
            active: 1
          }
        }).$promise.then((n) => {
          res.familia.ordem = n.count + 1;
          this.AtvFamilia.create(res.familia).$promise.then((res) => {
            this.UI.addToast("Família criada com sucesso");
            this.loadData();
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro na criação de família. Por favor tente mais tarde.");
          });
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na criação de família. Por favor tente mais tarde");
        });
      }
    });
  };

  editFamilia = (r) => {
    let options = {
      size: 'lg',
      template: require('./new.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Família";
        scope.familia = angular.copy(r);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.familia) {
        this.AtvFamilia.upsert(res.familia).$promise.then((res) => {
          this.UI.addToast("Família editada com sucesso");
          this.loadData();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na edição de família. Por favor tente mais tarde.");
        });
      }
    });
  };

  checkDeleteFamilia = (f) => {
    let cenas = this.UI.showWaiting();
    this.AtvFamilia.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((familiaToRemove) => {
      // Find if we have something that might block deletion
      this.AtvSubfamilia.count({
        fields: {id: true},
        where: {
          familiaId: familiaToRemove.id,
          active: 1
        }
      }).$promise.then((subfamilias) => {
      //   this.AtvTipoAtivo.count({
      //     fields: {id: true},
      //     where: {
      //       active: 1,
      //       familiaId: familiaToRemove.id
      //     }
      //   }).$promise.then((tiposAtivo) => {
        this.AtvAtivo.count({
          fields: {id: true},
          where: {
            active: 1,
            familiaId: familiaToRemove.id
          }
        }).$promise.then((ativos) => {
          cenas.close();
          // if (subfamilias.count + tiposAtivo.count > 0) {
          if (subfamilias.count + ativos.count > 0) {
            // let msg = "Não é possível remover família.\nExiste" + ((subfamilias.count + tiposAtivo.count > 1) ? "m " : " ");
            // msg += subfamilias.count > 0 ? (subfamilias.count + " subfamília" + (subfamilias.count !== 1 ? "s " : " ")) : "";
            // msg += tiposAtivo.count > 0 && subfamilias.count > 0 ? "e " : "";
            // msg += tiposAtivo.count > 0 ? (tiposAtivo.count + " tipo" + (tiposAtivo.count !== 1 ? "s " : " ") + "de ativo " ): "";
            // msg += "associado" + (subfamilias.count + tiposAtivo.count > 1 ? "s" : "") + " a esta família.";
            let msg = "Não é possível remover família.\nExiste" + ((subfamilias.count + ativos.count > 1) ? "m " : " ");
            msg += subfamilias.count > 0 ? (subfamilias.count + " subfamília" + (subfamilias.count !== 1 ? "s " : " ")) : "";
            msg += ativos.count > 0 && subfamilias.count > 0 ? "e " : "";
            msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")): "";
            msg += "associado" + (subfamilias.count + ativos.count > 1 ? "s" : "") + " a esta família.";
            this.UI.showAlert(msg);
          } else {
            this.confirmDeleteFamilia(f);
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("De momento não é possível consultar ativos de família. Por favor tente mais tarde");
        });
        // }).catch((error) => {
        //   console.log(error);
        //   this.UI.addToast("De momento não é possível consultar subfamílias. Por favor, tente mais tarde");
        // });
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("De momento não é possível consultar subfamílias. Por favor, tente mais tarde");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("De momento não é possível remover família. Verifique a ligação");
    });
  };

  confirmDeleteFamilia = (f) => {
    let title = "Eliminar Família";
    let warning = "Tem a certeza que pretende remover esta família?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeFamilia(f);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeFamilia = (f) => {
    this.loaded = false;
    // Find again familia to delete so we can update it
    this.AtvFamilia.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((familiaToRemove) => {
      familiaToRemove.active = 0;
      familiaToRemove.$save().then((res) => {
        this.AtvFamilia.find({
          filter: {
            where: {
              active: 1
            },
            order: 'ordem ASC'
          }
        }).$promise.then((fams) => {
          let tasks = [];
          for (let i = 0; i < fams.length; i++) {
            fams[i].ordem = i + 1;
            let defer = this.AtvFamilia.upsert(fams[i]).$promise;
            tasks.push(defer.promise);
          }
          this.$q.all(tasks).then((res) => {
            this.UI.addToast("Família eliminada com sucesso!");
            this.loadData();
          }).catch(err => {
            console.log(err);
          });
        }).catch((error) => {
          console.log(error);
        });
      }, (error) => {
        console.log(error);
        this.loaded = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível eliminar a família. Verifique se ainda existe.");
      this.loadData();
    });
  };
}

AtvFamiliasListController.$inject = ['$q', '$state', 'UIService', 'AuthorizationService', 'AtvFamilia', 'AtvSubfamilia', 'AtvTipoAtivo', 'AtvAtivo'];
