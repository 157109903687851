export function routes($stateProvider) {
  $stateProvider
    .state('app.crm.empresas', {
      url: '/empresas',
      abstract: true
    })
    .state('app.crm.empresas.list', {
      url: '?{page:int}&{limit:int}&sortBy&filter',
      title: 'Empresas',
      template: '<crm-empresas-list-view></crm-empresas-list-view>',
      params: {
        page: {
          value: 1,
          dynamic: true
        },
        limit: {
          value: 20,
          dynamic: true
        },
        sortBy: {
          array: true,
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.crm.empresas.details', {
      url: '/{id:int}',
      title: 'Detalhes da Empresa',
      template: '<crm-empresas-details-view></crm-empresas-details-view>',
      params: {
        id: {
          dynamic: true
        }
      }
    })
    .state('app.crm.empresas.details2', {
      url: '/novo/{id:int}',
      title: 'Detalhes da Empresa',
      template: '<crm-empresas-details-view2></crm-empresas-details-view2>',
      // role: ['gasVerClientes'],
    })
    .state('app.crm.empresas.formEdit', {
      url: '/formEdit/{id:int}',
      title: 'Formulário empresa',
      template: '<crm-empresas-add-view></crm-empresas-add-view>',
      // role: ['gasVerClientes'],
    })
    .state('app.crm.empresas.formCreate', {
      url: '/formCreate',
      title: 'Formulário empresa',
      template: '<crm-empresas-add-view></crm-empresas-add-view>',
      // role: ['gasVerClientes'],
    });
}

routes.$inject = ['$stateProvider'];
