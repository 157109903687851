export function routes($stateProvider) {
  $stateProvider
    .state('app.elv', {
      url: '/elevadores',
      abstract: true,
      template: '<div ui-view></div>'
    });
}

routes.$inject = ['$stateProvider'];
