export default class LmeProcessoService {
  constructor($q, UIService, AuthenticationService, LmeService, LmeProcesso, LmeEstadoProcesso, LmeProcessoAlteracaoEstado, LmeSuspensaoMotivos, LmeProcessoAmostra, Group) {
    this.$q = $q;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.LmeService = LmeService;
    this.LmeProcesso = LmeProcesso;
    this.LmeEstadoProcesso = LmeEstadoProcesso;
    this.LmeProcessoAlteracaoEstado = LmeProcessoAlteracaoEstado;
    this.LmeSuspensaoMotivos = LmeSuspensaoMotivos;
    this.LmeProcessoAmostra = LmeProcessoAmostra;
    this.Group = Group;
  }




  // Change the state for amostras with id in array ids = [{id: ..., estadoId: ...}, ...]
  // original is an object with {tipoId: ..., estadoId: ..., empresaId: ...}
  changeStateMultiple = (ids, original) => {
    let defer = this.$q.defer();
    let wait = this.UI.showWaiting();
    // Open modal to pick state and obs
    this.LmeService.getNewEstadosProcesso(original.tipoId, original.estadoId, original.empresaId).then((estados) => {
      this.LmeSuspensaoMotivos.find({
        filter: {
          where: {
            active: 1
          }
        }
      }).$promise.then((motivosSuspensao) => {
        this.Group.findOne({
          filter: {
            where: {
              name: {
                like: 'Tecnico%LME'
              }
            },
            include: {
              relation: 'usergroup',
              scope: {
                include: 'user'
              }
            }
          }
        }).$promise.then((g) => {
          let tecnicosLME = [];
          g.usergroup.forEach(u => {
            tecnicosLME.push(u.user);
          });
          tecnicosLME = _.orderBy(tecnicosLME, 'name', 'asc');

          wait.close();
          let options = {
            size: "lg",
            template: require("./directory/state.dialog.html"),
            controller: ["$scope", "$dialog", ($scope, $dialog) => {
              $scope.estados = estados;
              $scope.motivosSuspensao = motivosSuspensao;
              $scope.tecnicosLME = tecnicosLME;
              $scope.dataInicioOptions = {format: 'YYYY-MM-DD'};
              $scope.dataFimOptions = {format: 'YYYY-MM-DD'};
              $scope.proximaCalibracaoOptions = {format: 'YYYY-MM-DD', minDate: moment()};

              $scope.changeDataFimOptions = () => {
                if ($scope.data.dataInicio)
                  $scope.dataFimOptions.minDate = $scope.data.dataInicio;
              };

              $scope.changeDataInicioOptions = () => {
                if ($scope.data.dataFim)
                  $scope.dataInicioOptions.maxDate = $scope.data.dataFim;
              };

              $scope.data = {
                tecnicoLME: {selected: undefined}, // Can't have a atribuidoa to a general process
                estado: {selected: undefined},
                motivoSuspensao: null,
                dataInicio: null, // Can't have dates to general process
                dataFim: null,
                proximaCalibracao: null,
                observacoes: null
              };

              // Infinite Scroll magic
              $scope.addMoreItems = (infiniteScroll) => {
                infiniteScroll.currentItems += infiniteScroll.numToAdd;
              };

              $scope.ok = () => {
                if (!moment($scope.data.proximaCalibracao).isValid())
                  $scope.data.proximaCalibracao = null;

                $dialog.close($scope.data);
              };

              $scope.cancel = () => {
                $dialog.dismiss("cancel");
              };
            }]
          };

          let modal = this.UI.showDialog(options);

          modal.then((ok) => {
            let processCountString = ids.length > 1 ? `destes ${ids.length} processos` : `deste processo`;
            let warning = `Tem a certeza que pretende alterar o estado ${processCountString} para: ${ok.estado.selected.descricao}?`;
            let result = this.UI.showConfirm(warning);
            result.then(() => {
              // Recheck if all processos have the same state
              this.LmeProcesso.find({
                filter: {
                  fields: {id: true, tipoId: true, estadoId: true, empresaId: true},
                  where: {
                    and: [{active: 1}, {id: {inq: ids.map(x => x.id)}}]
                  }
                }
              }).$promise.then((procs) => {
                // Check if all procs exist and tipos and estados and empresas are the same
                if (procs.length === ids.length && !procs.some(x => x.tipoId !== ids[0].tipoId) && !procs.some(x => x.estadoId !== ids[0].estadoId) && !procs.some(x => x.empresaId !== ids[0].empresaId)) {
                  let wait = this.UI.showWaiting();

                  let observacoes = ok.estado.selected.id === 3 ? ("Atribuído a " + ok.tecnicoLME.selected.name) : ok.observacoes;
                  let tecnicoId = ok.estado.selected.id === 3 ? ok.tecnicoLME.selected.id : null;
                  this.LmeProcessoAlteracaoEstado.to({
                    params: {
                      ids: ids.map(x => x.id),
                      state: ok.estado.selected.id,
                      userId: this.Auth.getId(),
                      tecnicoId: tecnicoId,
                      dataInicio: ok.dataInicio ? ok.dataInicio : null,
                      dataFim: ok.dataFim ? ok.dataFim : null,
                      motivoId: ok.motivoSuspensao ? ok.motivoSuspensao.id : null,
                      proximaCalibracao: ok.proximaCalibracao ? ok.proximaCalibracao : null,
                      observacoes: observacoes || null
                    }
                  }).$promise.then(res => {
                    wait.close();
                    let errors = res.status.filter(x => x.error);
                    if (errors.length > 0) {
                      let title = "Erros de Alteração de Estado";
                      let introText = "Ocorreram os seguintes erros na alteração de estado:";
                      let instance = this.UI.showDialog({
                        size: 'md',
                        template: require('../../interface/modals/show-list.html'),
                        controller: ['$scope', ($scope) => {
                          $scope.title = title;
                          $scope.introText = introText;
                          $scope.list = errors;
                          $scope.ok = function () {
                            $scope.$close();
                          };
                        }]
                      });
                      instance.finally(() => {
                        defer.resolve();
                      });
                    } else {
                      if (ids.length === 1)
                        this.UI.addToast("Estado de processo alterado com sucesso");
                      else
                        this.UI.addToast("Estados de processos alterados com sucesso");
                      defer.resolve();
                    }
                  }).catch(error => {
                    console.log(error);
                    wait.close();
                    let alert = this.UI.showAlert("Não foi possível encontrar estado. Verifique a ligação.");
                    alert.finally(() => {
                      defer.reject();
                    });
                  });
                } else {
                  let alert = this.UI.showAlert("Houve pelo menos uma alteração de estado/tipo/empresa nos processos selecionados. Por favor tente novamente.");
                  alert.finally(() => {
                    defer.reject();
                  });
                }
              }).catch((error) => {
                console.log(error);
                let alert = this.UI.showAlert("Não foi possível consultar informação de processos. Verifique a ligação");
                alert.finally(() => {
                  defer.reject();
                });
              });
            }).catch(() => {
              defer.reject();
            });
          }).catch(() => {
            defer.reject();
          });
        }).catch((error) => {
          wait.close();
          console.log(error);
          let alert = this.UI.showAlert("Erro de carregamento de técnicos LME.");
          alert.finally(() => {
            defer.reject();
          });
        });
      }).catch((error) => {
        wait.close();
        console.log(error);
        let alert = this.UI.showAlert("Erro de carregamento de dados de Motivos de Suspensão.");
        alert.finally(() => {
          defer.reject();
        });
      });
    }).catch(e => {
      wait.close();
      console.log(e);
      let alert = this.UI.showAlert("Erro de carregamento de dados de Estados.");
      alert.finally(() => {
        defer.reject();
      });
    });
    return defer.promise;
  };

  removeProcessos = (ids) => {
    let defer = this.$q.defer();
    if (!ids || ids.length === 0) defer.reject();

    this.LmeProcessoAmostra.find({
      filter: {
        where: {
          and: [{processoId: {inq: ids}}, {active: 1}]
        }
      }
    }).$promise.then((processosAmostras) => {
      this.LmeProcesso.find({
        filter: {
          where: {
            and: [{active: 1}, {or: [{paiId: {inq: ids}}, {raizId: {inq: ids}}]}]
          }
        }
      }).$promise.then((paisRaizes) => {
        let status = [];
        let tasks = [];
        ids.forEach(id => {
          let deferDelete = this.$q.defer();
          let amostrasForProcesso = processosAmostras.filter(x => x.processoId === id);
          let processesPaisRaizes = paisRaizes.filter(x => x.paiId === id || x.raizId === id);

          if (amostrasForProcesso.length === 0 && processesPaisRaizes.length === 0) { // Safe to delete, find and update it
            this.LmeProcesso.findById({id: id}).$promise.then((processo) => {
              processo.active = 0;
              this.LmeProcesso.upsert(processo).$promise.then(() => {
                status.push({
                  error: false,
                  processoId: id
                });
                deferDelete.resolve(id);
              }).catch(error => {
                console.log(error);
                status.push({
                  error: true,
                  processoId: id,
                  message: `Erro de remoção de processo com ID SGI ${id}`
                });
                deferDelete.resolve(id);
              });
            }).catch((error) => {
              let message;
              if (error.status === 404) message = `Processo com ID SGI ${id} não encontrado`;
              else message = `Erro de procura de processo com ID SGI ${id}`;
              status.push({
                error: true,
                processoId: id,
                message: message
              });
              deferDelete.resolve(id);
            });
          } else { // Can't delete, process exists
            let message;
            if (amostrasForProcesso.length > 0) {
              message = amostrasForProcesso.length === 1 ? `Existe 1 amostra associada ao processo com ID SGI ${id}` : `Existem ${amostrasForProcesso.length} amostras associadas ao processo com ID SGI ${id}`;
            } else if (processesPaisRaizes.length > 0) {
              let quantidadeString;
              if (processesPaisRaizes.length === 1) quantidadeString = "1 processo que depende";
              else quantidadeString = `${processesPaisRaizes.length} processos que dependem`;
              message = `Processo ID SGI ${id}: Há ${quantidadeString} deste processo (ID SGI: ${processesPaisRaizes.map(x => x.id).toString()}). Verifique as ligações ao processo antes de eliminar.`;
            }
            status.push({
              error: true,
              processoId: id,
              message: message
            });
            deferDelete.resolve(id);
          }
          tasks.push(deferDelete.promise);
        });

        this.$q.all(tasks).then((res) => {
          defer.resolve(status);
        }).catch(error => {
          defer.reject(error);
        });
      }).catch((error) => {
        defer.reject(error);
      });
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };
}
LmeProcessoService.$inject = ['$q', 'UIService', 'AuthenticationService', 'LmeService', 'LmeProcesso', 'LmeEstadoProcesso', 'LmeProcessoAlteracaoEstado', 'LmeSuspensaoMotivos', 'LmeProcessoAmostra', 'Group'];
