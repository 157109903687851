export default class FormacaoLocalDetailsController {
  constructor($state, $sce, FormacaoLocal, FormacaoEspaco, UIService) {
    this.$state = $state;
    this.$sce = $sce;
    this.id = $state.params.id;
    this.espaco = null;
    this.UI = UIService;
    this.FormacaoLocal = FormacaoLocal;
    this.FormacaoEspaco = FormacaoEspaco;

    // Redirect if not defined
    if (!this.id)
      this.$state.go('app.formacao.locais.list');
    this.hasLoaded = false;
    this.loadData();
    this.getEspaco();
  }

  getEspaco = () => {
    this.hasLoaded = false;
    this.FormacaoEspaco.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.espaco = res;
      this.hasLoaded = true;
    }).catch((error) => {
      console.log(error);
    })
  };

  loadData = () => {
    this.hasLoaded = false;
    this.FormacaoLocal.findOne({
      filter: {
        where: {
          id: this.id,
          active: 1
        },
        include: {
          relation: 'espaco',
          scope: {
            where: {active: 1}
          }
        }
      }
    }).$promise.then((result) => {
      // Move to list if no result is found
      if (!result) {
        this.UI.addToast('Ocorreu um erro ao carregar detalhes do local');
        this.$state.go('app.formacao.locais.list');
      }
      this.local = result;
      this.hasLoaded = true;
    }).catch((error) => {
      this.UI.addToast('Ocorreu um erro ao carregar detalhes do local');
      this.$state.go('app.formacao.locais.list');
    });
  };

  addEspaco = () => {
    let self = this;

    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./modal-edit-espaco.html'),
      controller: ['$scope', '$state', function ($scope, $state) {
        $scope.espaco = {};
        $scope.espaco.id = 0;
        $scope.espaco.designacao = '';
        $scope.espaco.observacoes = '';
        $scope.espaco.active = 1;
        $scope.espaco.localId = $state.params.id;
        $scope.label = 'Adicionar espaço';

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.hasLoaded = true;
      this.FormacaoEspaco.create(ok.espaco).$promise.then(r => {
        this.loadData();
        this.UI.addToast("Espaço inserido com sucesso!");
      }).catch(error => {
        console.log(error);
        this.hasLoaded = false;
        this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  editEspaco = (a) => {
    let self = this;
    let options = {
      size: 'md',
      template: require('./modal-edit-espaco.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.espaco = {};
        $scope.espaco.id = a.id;
        $scope.espaco.designacao = a.designacao;
        $scope.espaco.observacoes = a.observacoes;
        $scope.label = "Editar espaço";

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((ok) => {
      this.FormacaoEspaco.findOne({
        filter: {
          where: {
            id: ok.espaco.id,
            active: 1
          }
        }
      }).$promise.then((res) => {
        res.id = ok.espaco.id;
        res.designacao = ok.espaco.designacao;
        res.observacoes = ok.espaco.observacoes;
        res.$save().then((r) => {
          this.UI.addToast('Dados alterados com sucesso');
          this.loadData();
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao editar espaço');
          this.loadData();
        })
      })
    })
  };
  editDetails = () => {
    let a = angular.copy(this.local);
    let edit = this.UI.showDialog({
      size: 'lg',
      template: require('./modal-edit-detalhes.html'),
      controller: ['$scope', $scope => {

        $scope.local = {
          designacao: a.designacao,
        };

        $scope.ok = () => {
          $scope.$close($scope.local);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    });

    edit.then(res => {
      console.log(res);
      this.local.designacao = res.designacao;
      this.local.$save().then(() => {
        this.UI.addToast('Dados alterados com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro ao editar empresa');
        this.loadData();
      })
    })
  };

  editObservacoes = () => {
    let a = angular.copy(this.local);
    let edit = this.UI.showDialog({
      size: 'lg',
      template: require('./modal-edit-observacoes.html'),
      controller: ['$scope', $scope => {

        $scope.local = {
          observacoes: a.observacoes,
        };

        $scope.ok = () => {
          $scope.$close($scope.local);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    });

    edit.then(res => {
      this.local.observacoes = res.observacoes;
      this.local.$save().then(() => {
        this.UI.addToast('Dados alterados com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro ao editar empresa');
        this.loadData();
      })
    })
  };

  confirmDeleteEspaco = (i) => {
    let title = "Eliminar Espaço";
    let warning = "Tem a certeza que pretende remover este espaço?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeEspaco(i);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeEspaco = (s) => {
    this.hasLoaded = true;
    this.FormacaoEspaco.findOne({
      filter: {
        where: {
          id: s.id,
          active: 1
        }
      }
    }).$promise.then((espacoToRemove) => {

      console.log(espacoToRemove);

      espacoToRemove.active = 0;
      espacoToRemove.$save().then((res) => {
        this.UI.addToast("Espaço eliminado com sucesso!");
        this.loadData();
      }, (error) => {
        console.log(error);
        this.hasLoaded = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.hasLoaded = false;
      this.UI.addToast("Não foi possível eliminar o espaço. Verifique se ainda existe.");
    });
  };
}

FormacaoLocalDetailsController.$inject = ['$state', '$sce', 'FormacaoLocal', 'FormacaoEspaco', 'UIService'];
