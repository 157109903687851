import angular from 'angular';

import {routes} from "./relatorios.routes";
import DetailPedidoReportController from "./details/report.controller";
import DirectoryPedidosReportController from "./directory/directory.controller";

export default angular.module('app.pedidos.reports', [])
  .config(routes)
  .controller('DetailPedidoReportController', DetailPedidoReportController)
  .controller('DirectoryPedidosReportController', DirectoryPedidosReportController)
  .name;
