import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, Fab, IconButton, Link, ListItemAvatar, ListItemText, Stack, TextField, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import { mdiPlus, mdiPencil, mdiDelete, mdiContentSave } from '@mdi/js';
import DialogPonto from './dialog';
import { ConfirmDialog, DraggableList, MdiIcon, WaitDialog } from '@components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { reorder } from '../../../util';
import { useModal } from 'mui-modal-provider';
import { v4 } from 'uuid';
const DetailsRelatorioParametro = ({ $state, ListaRelatorioService, UIService }) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const { showModal } = useModal();
    const [pontos, setPontos] = useState([]);
    // Detalhes
    const validationSchema = yup.object({
        codigo: yup.string().required('O campo é obrigatório'),
        designacao: yup.string().required('O campo é obrigatório')
    });
    const formik = useFormik({
        initialValues: {},
        validateOnMount: false,
        onSubmit: async (values) => {
            const o = await ListaRelatorioService.saveParametro($state.params.id, {
                codigo: values.codigo,
                designacao: values.designacao
            });
        },
        validationSchema
    });
    const fetchData = async () => {
        setLoading(true);
        try {
            const aux = await ListaRelatorioService.findParametro($state.params.id);
            setEditing(aux.CoreListaRelatorio.dataPublicacao == null);
            let o = [];
            aux.Pontos.forEach(r => {
                o.push({
                    ...r,
                    _i: v4()
                });
            });
            setPontos(o);
            formik.resetForm();
            formik.setValues(aux);
            setData(aux);
            setLoading(false);
        }
        catch (e) {
            console.log(e);
        }
    };
    const onDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination)
            return;
        // Altera ordem
        const newItems = reorder(pontos, source.index, destination.index);
        pontos.forEach((r, i, a) => {
            r.ordem = i + 1;
        });
        setPontos(newItems);
    };
    useEffect(() => {
        if (!loading)
            fetchData();
    }, [data]);
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>{loading ? 'A carregar...' : data && data.designacao}</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Administração
          </Link>
          <Link underline='hover' onClick={() => $state.go('app.administration.lists.list')} color='text.primary' aria-current='page'>
            Modelo de relatórios
          </Link>
          <Link underline='hover' onClick={() => $state.go('app.administration.lists.view', { id: $state.params.lista })}>
            {$state.params.lista}
          </Link>
          <Link underline='none' color='inherit'>
            Parâmetros
          </Link>
        </Breadcrumbs>
      </Box>
      <Box>
        {!loading ? (<Box>
            <Box>
              <Grid container spacing={1}>
                <Grid lg={12} xs={12}>
                  <form>
                    <Card sx={{ mb: 1 }}>
                      <CardHeader title='Detalhes'/>
                      <CardContent>
                        <Grid container spacing={2} sx={{ mb: 1 }}>
                          <Grid lg={2} md={3} xs={8}>
                            <TextField label='Código' InputProps={{
                readOnly: !editing /*  */
            }} name='codigo' fullWidth value={formik.values?.codigo} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.codigo && Boolean(formik.errors.codigo)} helperText={formik.touched.codigo && formik.errors.codigo}/>
                          </Grid>
                          <Grid lg={10} md={8} xs={8}>
                            <TextField label='Designação' name='designacao' InputProps={{
                readOnly: !editing
            }} fullWidth value={formik.values?.designacao} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </form>
                </Grid>
              </Grid>
            </Box>
            <Card sx={{ mb: 1 }}>
              <CardHeader title='Pontos de verificação' subheader='Pode alterar a ordem dos pontos, arrastando o item para cima ou para baixo' action={editing && (<Tooltip title='Adicionar novo ponto'>
                      <IconButton onClick={async () => {
                    const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                    let defeitos = await ListaRelatorioService.findDefeitosByTipo(data.CoreListaRelatorio.coreDepartamentoId);
                    wait.destroy();
                    showModal(DialogPonto, {
                        initial: {},
                        defeitos: defeitos,
                        onClose: (data) => {
                            if (data) {
                                setPontos([
                                    ...pontos,
                                    {
                                        id: 0,
                                        codigo: data.codigo,
                                        defeitos: data.defeitos,
                                        designacao: data.designacao,
                                        active: 1,
                                        coreListaRelatorioParametroId: $state.params.id,
                                        defaultValue: data.defaultValue,
                                        ordem: pontos.length,
                                        na: data.na,
                                        _i: v4()
                                    }
                                ]);
                            }
                        }
                    });
                }}>
                        <MdiIcon path={mdiPlus}/>
                      </IconButton>
                    </Tooltip>)}/>

              <DraggableList disabled={!editing} itemKey={'_i'} items={pontos.filter(r => r.active == 1).sort((a, b) => a.ordem - b.ordem)} render={item => {
                return [
                    <ListItemAvatar sx={{ mr: 2 }}>
                      <Typography>{item.codigo}</Typography>
                    </ListItemAvatar>,
                    <ListItemText primary={<Stack direction={'row'} spacing={0} alignItems={'center'}>
                          {item.designacao.split(/\s+/).map((r, index) => {
                                /// REGEX -> verificar de r tem [___Texto]
                                if (/Texto\]/.test(r)) {
                                    return <TextField sx={{ mr: 0.5 }} disabled variant='outlined' size='small'/>;
                                }
                                if (/Numero\]/.test(r)) {
                                    return <TextField sx={{ mr: 0.5 }} disabled variant='outlined' type='number' size='small'/>;
                                }
                                /// REGEX -> verificar de r tem [___Imagem]
                                if (/Imagem\]/.test(r)) {
                                    return (<Button variant='outlined' size='small' disabled>
                                  Imagem
                                </Button>);
                                }
                                return <Typography>{r}&nbsp;</Typography>;
                            })}
                        </Stack>}/>
                ];
            }} action={item => {
                return (editing && (<Box sx={{ display: 'flex' }}>
                        <Tooltip title='Editar ponto'>
                          <IconButton onClick={async () => {
                        const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                        let defeitos = await ListaRelatorioService.findDefeitosByTipo(data.CoreListaRelatorio.coreDepartamentoId);
                        wait.destroy();
                        showModal(DialogPonto, {
                            initial: item,
                            defeitos: defeitos,
                            onClose: (data) => {
                                if (data) {
                                    item.codigo = data.codigo;
                                    item.designacao = data.designacao;
                                    item.defeitos = data.defeitos;
                                    item.defaultValue = data.defaultValue;
                                    item.na = data.na;
                                }
                            }
                        });
                    }}>
                            <MdiIcon path={mdiPencil}/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Eliminar ponto'>
                          <IconButton onClick={() => {
                        showModal(ConfirmDialog, {
                            message: `Tem a certeza que pretende eliminar o ponto ${item.codigo}?`,
                            onClose: r => {
                                if (r) {
                                    if (item.id == 0) {
                                        let i = pontos.indexOf(item);
                                        if (i >= 0) {
                                            pontos.splice(i, 1);
                                            const newItems = reorder(pontos, 0, pontos.length);
                                            pontos.forEach((r, i, a) => {
                                                r.ordem = i + 1;
                                            });
                                            setPontos(newItems);
                                        }
                                    }
                                    else {
                                        item.active = 0;
                                        const newItems = reorder(pontos, 0, pontos.length);
                                        pontos.forEach((r, i, a) => {
                                            r.ordem = i + 1;
                                        });
                                        setPontos(newItems);
                                    }
                                }
                            }
                        });
                    }}>
                            <MdiIcon path={mdiDelete}/>
                          </IconButton>
                        </Tooltip>
                      </Box>));
            }} onDragEnd={onDragEnd}/>
            </Card>
          </Box>) : undefined}
      </Box>
      {!loading && (<Box sx={{
                position: 'fixed',
                margin: 2,
                bottom: 0,
                right: 0,
                display: 'flex',
                gap: 1
            }}>
          {editing && (<Tooltip title={'Guardar alterações'}>
              <Fab color={'error'} onClick={async () => {
                    if (formik.isValid) {
                        const wait = showModal(WaitDialog, { title: 'Por favor aguarde...' });
                        await formik.submitForm();
                        for (let i = 0; i < pontos.length; i++) {
                            await ListaRelatorioService.saveParametroPonto(pontos[i]);
                        }
                        wait.destroy();
                        UIService.addToast('Parâmetro atualizado com sucesso');
                        fetchData();
                    }
                    else {
                        UIService.addToast('Corrija os erros apresentados no ecrã');
                    }
                }}>
                <MdiIcon path={mdiContentSave}/>
              </Fab>
            </Tooltip>)}
        </Box>)}
    </Box>);
};
export default DetailsRelatorioParametro;
