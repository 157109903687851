import {routes} from './pendentes.routes';
import PadPendentesListController from "./directory/directory.controller";
import PadPendentesDetailsController from "./details/details.controller";
import PadProcessoPendentesFileUploaderController from "./details/files.dialog.controller";

export default angular.module('app.pad.pendentes', [])
  .config(routes)
  .controller('PadPendentesListController', PadPendentesListController)
  .controller('PadPendentesDetailsController', PadPendentesDetailsController)
  .controller('PadProcessoPendentesFileUploaderController', PadProcessoPendentesFileUploaderController)
  .name;
