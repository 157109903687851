export default class AtvColaboradoresListController {
  constructor($filter, $q, $state, AuthenticationService, Group, AtvAtivosService, AtvEntidadeProprietaria, Funcionario, UIService) {
    this.$filter = $filter;
    this.$q = $q;
    this.$state = $state;
    this.Auth = AuthenticationService;
    this.Group = Group;
    this.AtvAtivosService = AtvAtivosService;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.Funcionario = Funcionario;
    this.UI = UIService;
    this.opt = $state.params;
    this.entidadesProprietarias = [];
    this.colaboradores = [];

    // this.certificado = [{name: "Sim", value: 1}, {name: "Não", value: 0}];

    // If loadData() finished or not
    this.dataLoaded = false;
    // If loadData() fails for some reason
    this.errorLoadingData = false;

    this.listOpt = [];
    // Total processes for currently being displayed
    this.total = 0;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "name",
      sort: "asc",
      filter: undefined
    };

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [
      {val: 'a', name: 'Igual', type: 'o'},
      {val: 'b', name: 'Diferente', type: 'o'},
      {val: 'c', name: 'Começa por', type: 'o'},
      {val: 'd', name: 'Termina com', type: 'o'},
      {val: 'e', name: 'Contém', type: 'o'},
      {val: 'a', name: 'Igual (=)', type: 'd'},
      {val: 'b', name: 'Diferente de (≠)', type: 'd'},
      {val: 'c', name: 'Posterior a (>)', type: 'd'},
      {val: 'd', name: 'Anterior a (<)', type: 'd'},
      {val: 'e', name: 'Posterior ou igual (≥)', type: 'd'},
      {val: 'f', name: 'Anterior ou igual (≤)', type: 'd'},
      {val: 'a', name: 'Igual', type: 's'},
      {val: 'b', name: 'Diferente', type: 's'}
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: 'Funcionario.name',
        name: 'Nome do Colaborador',
        type: 'o'
      },
      {
        id: 'Funcionario.email',
        name: 'Email do Colaborador',
        type: 'o'
      }
    ];

    this.displayColumns = [
      {displayName: 'Nome do Colaborador', name: 'colaborador', visible: true},
      {displayName: 'Email do Colaborador', name: 'email', visible: true},
      {displayName: 'Nº Ativos', name: 'nAtivos', visible: true},
      {displayName: 'Nº Interações', name: 'nInteracoes', visible: false}
    ];

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('ATVGestaoColaboradoresDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('ATVGestaoColaboradoresDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('ATVGestaoColaboradoresFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('ATVGestaoColaboradoresFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('ATVGestaoColaboradoresFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        // if (f.column.selected.id === 'AtvAtivo.entidadeProprietariaId')
        //   f.column.selected.list = this.entidadesProprietarias;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.newGetColaboradores();
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('ATVGestaoColaboradoresDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  newGetColaboradores = () => {
    this.tableLoading = true;
    let whereFields = this.AtvAtivosService.setWhereFields(this.customFilters, {'Funcionario.active': 1});
    this.Funcionario.colaboradoresAtivos({
      params: {
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      this.colaboradores = res.colaboradores;
      this.total = total;
      this.tableLoading = false;

    }).catch((e) => {
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível carregar colaboradores com os parâmetros fornecidos.");
    });
  };

  exportColaboradores = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.AtvAtivosService.setWhereFields(this.customFilters, {'Funcionario.active': 1});
    this.Funcionario.exportColaboradores({
      displayColumns: this.displayColumns,
      params: {
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'colaboradoresExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.UI.addToast("Não foi possível exportar colaboradores. Por favor tente mais tarde.");
    });
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few ativos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.colaboradores, x => x.selected), x => {
        return {key: 'Funcionario.id', value: x.id};
      });
    let whereFields = this.AtvAtivosService.setWhereFields(this.customFilters, {'Funcionario.active': 1}, orsLiteral);
    this.Funcionario.exportColaboradores({
      displayColumns: this.displayColumns,
      params: {
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'colaboradoresExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.UI.addToast("Não foi possível exportar colaboradores. Por favor tente mais tarde.");
    });
  };

  loadData = () => {
    // Nothing to do here at the moment
    this.dataLoaded = true;

    // this.AtvAtivoPrefixo.find({
    //   filter: {
    //     where: {active: true},
    //     order: 'ordem ASC'
    //   }
    // }).$promise.then((prefixos) => {
    //   prefixos.forEach(e => {
    //     e.name = e.prefixo;
    //   });
    //   this.prefixos = prefixos;
    //
    //   this.columns.forEach(f => {
    //     // if (f.id === 'AtvAtivo.entidadeProprietariaId')
    //     //   f.list = this.entidadesProprietarias;
    //   });
    //   // Everything is loaded
    //   this.dataLoaded = true;
    // }).catch(e => {
    //   console.log(e);
    //   this.errorLoadingData = true;
    //   this.UI.addToast("Erro de carregamento de dados para filtragem (Prefixos). Recarregue a página.");
    // });
  };

  openFilter = () => {
    // Check if we can open
    if (this.errorLoadingData) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    if (!this.dataLoaded) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
      //   if (f.column.selected.id === 'AtvAtivo.entidadeProprietariaId')
      //     f.column.selected.list = this.entidadesProprietarias;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('ATVGestaoColaboradoresFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.ativos.colaboradores.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.newGetColaboradores();
  };

  hasSelect = () => {
    return _.some(this.colaboradores, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.colaboradores.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.colaboradores.length;
    } else { // Remove all selections
      this.colaboradores.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.colaboradores.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.colaboradores, r => r.selected).length;
    this.allSelected = this.nSelected >= this.colaboradores.length;
    this.everythingSelected = false;
  };

  sort = keyname => {
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.ativos.colaboradores.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.newGetColaboradores();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.ativos.colaboradores.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.newGetColaboradores();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.ativos.colaboradores.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.newGetColaboradores();
  };

  viewColaborador = r => {
    this.$state.go('app.ativos.colaboradores.details', {id: r.id});
  };
}

AtvColaboradoresListController.$inject = ['$filter', '$q', '$state', 'AuthenticationService', 'Group', 'AtvAtivosService', 'AtvEntidadeProprietaria', 'Funcionario', 'UIService'];
