export default class IeDefinicoesController {
  constructor($q, $http, $filter, $state, Parametro, AuthenticationService, UIService, $scope, IeFaq, IeDeficiencia, IeDeficienciaTipo, CoreResponsavel, CoreAprovacaoVersao) {
    this.$q = $q;
    this.$http = $http;
    this.$filter = $filter;
    this.$state = $state;
    this.user = AuthenticationService.getUser();
    this.Parametro = Parametro;
    this.IeDeficiencia = IeDeficiencia;
    this.IeDeficienciaTipo = IeDeficienciaTipo;
    this.UI = UIService;
    this.IeFaq = IeFaq;
    this.CoreResponsavel = CoreResponsavel;
    this.CoreAprovacaoVersao = CoreAprovacaoVersao;

    this.faqOptions = {
      containment: 'parent',
      tolerance: 'pointer',
      handle: '.pmd-move-handle',
      'ui-floating': true,
      stop: (e, ui) => {
        for (let i = 0; i < this.faqs.length; i++) {
          this.faqs[i].ordem = i + 1;
          this.IeFaq.upsert(this.faqs[i]).$promise.then(() => {}).catch(error => {
            console.log(error);
            this.UI.addToast("Erro na alteração de ordem");
          });
        }
      }
    };

    this.responsavelLoading = false;
    this.errorResponsavel = false;
    this.versioningLoading = true;
    this.isResponsavel = false;
    this.auxResponsavel = {
      selected: undefined,
      infiniteScroll: {numToAdd: 20, currentItems: 20}
    };

    this.getResponsaveis();

    // Should probably be done after getting responsavel, but it doesn't matter anyway because view is blocked
    this.versionsSgi = [];
    this.versionsApp = [];
    this.moduleName = 'IE'; // Used throughout the code for this controller, do not change
    this.getAprovacaoVersoes();

    this.switchTab($state.params.tab || 'faq');
  }

  getAprovacaoVersoesSgi = () => {
    let defer = this.$q.defer();
    this.versionsSgi = [];
    this.CoreAprovacaoVersao.getVersions({params: {app: 'SGI', modulo: this.moduleName}}).$promise.then((data) => {
      this.versionsSgi = data.versions;
      if (this.versionsSgi && data.moduleId && this.versionsSgi.length > 0) {
        this.CoreAprovacaoVersao.find({
          filter: {
            where: {
              and: [{active: 1}, {moduleId: data.moduleId}] // Search by moduleId and not by "modulo" because both APP+SGI have the same "modulo" (same CoreResponsavel)
            },
            include: "funcionario"
          }
        }).$promise.then((aprovacoes) => {
          if (aprovacoes.length > 0) {
            // Match aprovacoes with versions
            this.versionsSgi.forEach(v => {
              v.aprovacao = aprovacoes.find(a => (v.appId === a.appId) && (v.moduleId === a.moduleId) && (v.id === a.versioningId));
            });
          }
          defer.resolve();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível obter informação de aprovação de versões SGI. Verifique a ligação.");
          defer.reject();
        });
      } else {
        this.versioningLoading = false;
        defer.resolve();
      }
    }).catch(error => {
      this.versioningLoading = false;
      console.log(error);
      this.UI.addToast("Não foi possível obter lista de aprovação de versões SGI. Verifique a ligação.");
      defer.reject();
    });
    return defer.promise;
  };

  getAprovacaoVersoesApp = () => {
    let defer = this.$q.defer();
    this.versionsApp = [];
    this.CoreAprovacaoVersao.getVersions({params: {app: 'APP', modulo: this.moduleName}}).$promise.then((data) => {
      this.versionsApp = data.versions;
      if (this.versionsApp && data.moduleId && this.versionsApp.length > 0) {
        this.CoreAprovacaoVersao.find({
          filter: {
            where: {
              and: [{active: 1}, {moduleId: data.moduleId}] // Search by moduleId and not by "modulo" because both APP+SGI have the same "modulo" (same CoreResponsavel)
            },
            include: "funcionario"
          }
        }).$promise.then((aprovacoes) => {
          if (aprovacoes.length > 0) {
            // Match aprovacoes with versions
            this.versionsApp.forEach(v => {
              v.aprovacao = aprovacoes.find(a => (v.appId === a.appId) && (v.moduleId === a.moduleId) && (v.id === a.versioningId));
            });
          }
          defer.resolve();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível obter informação de aprovação de versões APP. Verifique a ligação.");
          defer.reject();
        });
      } else {
        this.versioningLoading = false;
        defer.resolve();
      }
    }).catch(error => {
      this.versioningLoading = false;
      console.log(error);
      this.UI.addToast("Não foi possível obter lista de aprovação de versões APP. Verifique a ligação.");
      defer.reject();
    });
    return defer.promise;
  };


  getAprovacaoVersoes = () => {
    this.versioningLoading = true;

    let deferSgi = this.getAprovacaoVersoesSgi();
    let deferApp = this.getAprovacaoVersoesApp();

    this.$q.all([deferSgi, deferApp]).then(() => {
      this.versioningLoading = false;

    }).catch(error => {
      console.log(error);
      this.loaded = true;
      this.UI.showAlert("Ocorreu um erro ao obter versões. Por favor verifique ligação.");
    });
  };

  showVersao = (versao) => {
    let options = {
      template: require('./show.version.dialog.html'),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.label = `Detalhes da Versão ${this.$filter('semantic')(versao.version)}`;
        $scope.versao = versao;
        $scope.moduleName = "Inspeções Elétricas";

        $scope.fechar = () => {
          $dialog.dismiss('cancel');
        };

        $scope.aprovar = () => {
          $dialog.close($scope.versao);
        };

      }]
    };
    let modal = this.UI.showDialog(options);

    modal.then((versao) => {
      if (versao) this.aprovarVersao(versao);
    }).catch(() => {
    });
  };

  aprovarVersao = (versao) => {
    let confirm = this.UI.showConfirm(`Tem a certeza que pretende aprovar a versão ${this.$filter('semantic')(versao.version)}?`);
    confirm.then(() => {
      let aprovacao = {
        id : 0,
        appId: versao.appId,
        moduleId: versao.moduleId,
        modulo: this.moduleName, // Used to search for responsavel
        versioningId: versao.id,
        version: versao.version
      };
      this.CoreAprovacaoVersao.aprovar({versao: aprovacao}).$promise.then(() => {
        this.UI.addToast("Versão aprovada");
        this.getAprovacaoVersoes();
      }).catch(error => {
        console.log(error);
        this.UI.showAlert("Ocorreu um erro ao aprovar versão. Verifique a ligação ou permissões.");
      });
    }).catch(() => {});
  };

  getResponsavel = () => {
    this.responsavelLoading = true;
    this.CoreResponsavel.findOne({
      filter: {
        where: {
          modulo: 'IE'
        }
      }
    }).$promise.then((responsavel) => {
      if (responsavel) {
        this.responsavel = responsavel;
        if (this.responsavel.funcionarioId === this.user.id)
          this.isResponsavel = true; // To show approval tab only to them
        this.auxResponsavel.selected = this.responsaveis.find(x => x.id === responsavel.funcionarioId);
        this.errorResponsavel = false;
      } else
        this.errorResponsavel = true;

      this.responsavelLoading = false;
    }).catch((error) => {
      console.log(error);
      this.errorResponsavel = true;
      this.responsavelLoading = false;
    });
  };

  getResponsaveis = () => {
    this.CoreResponsavel.getResponsavelChoice({params: {modulo: 'IE'}}).$promise.then((responsaveis) => {
      this.responsaveis = responsaveis.list;
      this.getResponsavel();

    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível ler lista de responsáveis técnicos");
    });
  };

  // When selecting, change nomeExtenso
  selectResponsavel = (item) => {
    this.responsavel.nomeExtenso = item.name;
    this.responsavel.funcionarioId = item.id;
  };

  saveResponsavel = () => {
    this.responsavelLoading = true;
    this.CoreResponsavel.upsert(this.responsavel).$promise.then((resp) => {
      this.UI.addToast("Responsável atualizado com sucesso");
      this.getResponsavel();
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar responsável");
      this.responsavelLoading = false;
    });
  };

  // Infinite Scroll magic
  addMoreItems = (infiniteScroll) => {
    infiniteScroll.currentItems += infiniteScroll.numToAdd;
  };

  editar = () => {
    let warning = "A alteração destes valores alteram significativamente o funcionamento do SGI. Tem a certeza que pretende guardar estes dados?";
    let title = "Alterar parâmetro";
    let Parametro = this.Parametro;
    let instance = this.UI.showDialog({
      template: require('./modal-warning-message.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.parametro.iva = this.iva / 100;
      Parametro.upsert(this.parametro).$promise.then((dados) => {
        this.UI.addToast("Dados alterados com sucesso.");
        this.success = true;
      }).catch((error) => {
        this.UI.addToast("Erro de atualização de parâmetro. Verifique a ligação");
        this.success = false;
        console.log(error);
      });
    }, () => {});
  };

  addDeficiency = () => {
    let deficiency = {
      id: 0,
      active: true
    };
    this.showModalForDeficiency(deficiency);
  };

  editDeficiency = (deficiency) => {
    this.showModalForDeficiency(deficiency);
  };

  showModalForDeficiency = data => {
      this.tipos  = this.tipos || [];
      if (!this.tipos.length) {
          this.IeDeficienciaTipo.find({filter: {where: {active: true}}}).$promise.then(r => {
              this.tipos = r;
          })
      }
    this.UI.showDialog({
      size: 'lg',
      template: require('./deficiency-dialog.html'),
      controller: ['$scope', ($scope) => {

        $scope.tipos = this.tipos;

        $scope.def = data;
        $scope.ok = () => {
            $scope.$close($scope.def);
        };
        $scope.cancel = () => {
            $scope.$dismiss('cancel');
        };
      }]
    }).then(r => {
      console.log(r);
    })
  };

  switchTab = (tab) => {
    this.params = {
      tab: tab
    };
    switch (tab) {
      case 'faq':
        this.tab = 0;
        break;
      case 'payment':
        this.tab = 1;
        break;
      case 'deficiencies':
        this.tab = 2;
        this.params['page'] = this.$state.params.page || 1;
        if (this.term && this.term !== "")
          this.params['term'] = this.term;
        break;
      default:
        params['tab'] = 'faq';
        this.tab = 0;
        break;
    }
    this.$state.go('app.administration.adminIE', this.params, {
      notify: false,
      reload: false,
      location: 'replace',
      inherit: false
    });
    this.loadData();
  };

  loadData = (tab) => {
    switch (this.params.tab) {
      case 'faq':
        this.loadFaq();
        break;
      case 'payment':
        this.loadPaymentConfig();
        break;
      case 'deficiencies':
        this.loadDeficiencies();
        break;
      default:
        break;
    }
  };

  loadPaymentConfig = () => {
    this.loaded = false;
    this.Parametro.findOne({
      filter: {
        where: {
          id: 1
        }
      }
    }).$promise.then((parametro) => {
      this.parametro = parametro;
      this.iva = parametro.iva * 100;
      this.loaded = true;
    }).catch(() => {
      this.loaded = true;
      this.UI.addToast("Ocorreu um erro a carregar parâmetros de pagamento.");
    });
  };

  loadFaq = () => {
    this.loaded = false;
    this.IeFaq.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(r => {
      this.loaded = true;
      this.faqs = r;
    }).catch(() => {
      this.loaded = true;
      this.UI.addToast("Ocorreu um erro a carregar perguntas frequentes.");
    });
  };

  editFaq = (f) => {
    let faq = angular.copy(f);
    let options = {
      size: 'lg',
      template: require('./modal-edit-faq.html'),
      controller: ['$scope', $scope => {
        $scope.title = "Editar Pergunta Frequente";
        $scope.faq = faq;

        $scope.ok = () => {
          $scope.$close($scope.faq);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    this.UI.showDialog(options).then(res => {
      if (res) {
        res.dataAlteracao = moment().utc();
        this.IeFaq.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Pergunta frequente editada com sucesso');
        }).catch(() => {
          this.UI.addToast('Erro ao editar pergunta frequente');
        });
      }
    }).catch(() => {
    });
  };

  addFaq = () => {
    let options = {
      size: 'lg',
      template: require('./modal-edit-faq.html'),
      controller: ['$scope', $scope => {
        $scope.title = "Adicionar Pergunta Frequente";
        $scope.faq = {id: 0};

        $scope.ok = () => {
          $scope.$close($scope.faq);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    this.UI.showDialog(options).then(res => {
      if (res) {
        res.ordem = this.faqs.length + 1; // Last by default
        res.dataAlteracao = moment().utc();
        this.IeFaq.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Pergunta frequente adicionada com sucesso');
        }).catch(() => {
          this.UI.addToast('Erro ao criar pergunta frequente');
        });
      }
    }).catch(() => {
    });
  };

  confirmDeleteFaq = (f) => {
    let title = "Eliminar Pergunta Frequente";
    let warning = "Tem a certeza que pretende remover esta pergunta?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeFaq(f);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeFaq = (f) => {
    this.loaded = false;
    // Find faq
    this.IeFaq.findOne({
      filter: {
        where: {
          and: [{id: f.id}, {active: 1}]
        }
      }
    }).$promise.then((faq) => {
      faq.active = 0;
      faq.$save().then((res) => {
        this.UI.addToast("Pergunta frequente eliminada com sucesso");
        this.loadFaq();
      }, (error) => {
        console.log(error);
        this.UI.addToast("Erro de eliminação. Verifique a ligação.");
        this.loadFaq();
      });
    }).catch((error) => {
      console.log(error);
      this.loadFaq();
      this.UI.addToast("Não foi possível eliminar pergunta. Verifique se ainda existe.");
    });
  };


  loadDeficiencies = () => {
    this.loaded = false;
    this.IeDeficiencia.find({
      filter: {
        where: {
          active: true,
        },
        limit: 50,
        skip: (this.params.page - 1) * 50,
        include: 'tipo',
      }
    }).$promise.then(r => {
      this.loaded = true;
      this.deficiencies = r;
    }).catch(() => {
      this.loaded = true;
      this.UI.addToast("Ocorreu um erro a carregar deficiências.");
    });
  };
}

IeDefinicoesController.$inject = ['$q', '$http', '$filter', '$state', 'Parametro', 'AuthenticationService', 'UIService', '$scope', 'IeFaq', 'IeDeficiencia', 'IeDeficienciaTipo', 'CoreResponsavel', 'CoreAprovacaoVersao'];
