import React, { useEffect, useMemo, useState } from 'react';
import { Box, Breadcrumbs, CircularProgress, Grid, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import TableData from '../../components/table';
import { MdiIcon } from '@components';
import { mdiPencil } from '@mdi/js';
import moment from 'moment';
import Interacoes from '../../components/interacoes';
import EmpresasAssociadas from '../../components/empresaAssociada';
import DocumentosAssociados from '../../components/documentosAssociados';
import PessoasAssociadas from '../../components/pessoasAssociadas';
const CrmPessoasDetailsView = ({ $state, CrmService }) => {
    const [pessoa, setPessoa] = useState(null);
    const [social, setSocial] = useState(null);
    //const [isLoading, setIsLoading] = useState<boolean>(true);
    const fetchData = async () => {
        try {
            const getPessoa = await CrmService.findPessoaById($state.params.id);
            console.log(getPessoa);
            if (getPessoa?.redesSociais) {
                const parsedSocial = JSON.parse(getPessoa.redesSociais);
                console.log(parsedSocial);
                setSocial(parsedSocial);
            }
            else {
                setSocial([]);
            }
            setPessoa(getPessoa);
        }
        catch (e) {
            console.log(e);
        }
        //setIsLoading(false);
    };
    const dadosPedidosCotacao = [
        { id: 1, area: 'LME', vendedor: 'Pedro', descricao: 'Calibração', data: '04/02/2024' },
        { id: 2, area: 'IE', vendedor: 'Arlindo', descricao: 'Inspeção Elétrica', data: '03/05/2023' },
        { id: 3, area: 'LME', vendedor: 'Andreia', descricao: 'Ensaio', data: '05/03/2022' }
    ];
    const colunasPedidosCotacao = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50
        },
        {
            accessorKey: 'area',
            header: 'Área',
            size: 100
        },
        {
            accessorKey: 'vendedor',
            header: 'Vendedor',
            size: 150
        },
        {
            accessorKey: 'descricao',
            header: 'Descrição',
            size: 200
        },
        {
            accessorKey: 'data',
            header: 'Data',
            size: 100
        }
    ], []);
    useEffect(() => {
        if (!pessoa) {
            fetchData();
        }
    }, []);
    return (<Box sx={{ mt: 2, pl: 1, pr: 1 }}>
      {pessoa ? (<Box>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <Typography variant='body2'>CRM</Typography>
            </Link>
            <Link underline='hover' color='inherit' href='/material-ui/getting-started/installation/'>
              <Typography variant='body2'>Pessoas</Typography>
            </Link>
            <Typography variant='body2'>{pessoa.nome}</Typography>
          </Breadcrumbs>

          <Grid container spacing={2} justifyContent='flex-start' alignItems='center' sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={4} lg={2.5}>
              <Box component='img' src={pessoa.userImage ? `/api/Upload/crm/download/${pessoa.userImage}` : '/api/Upload/photos/download/defaultAvatar.png'} alt='Uploaded' onError={e => {
                e.currentTarget.onerror = null; // Previne loop infinito caso a imagem default falhe também
                e.currentTarget.src = '/api/Upload/photos/download/defaultAvatar.png'; // Fallback para imagem default
            }} sx={{
                maxWidth: '200px',
                maxHeight: '200px',
                width: 'auto',
                height: 'auto',
                borderRadius: '50%'
            }}></Box>
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={9.5}>
              <Grid container sx={{ height: '100%' }} spacing={2}>
                <Grid item xs={10} sm={10} md={10}>
                  <Typography variant='h4' sx={{ color: 'black' }}>
                    {pessoa.nome}
                  </Typography>
                </Grid>
                
                <Grid item xs={2} sm={2} md={2}>
                  <Stack justifyContent='flex-end' alignItems='center' direction='row'>
                    <Tooltip title={'Editar dados'} placement='top'>
                      <IconButton color='primary' onClick={() => $state.go('app.crm.pessoas.formEdit', { id: $state.params.id })}>
                        <MdiIcon path={mdiPencil} sx={{ color: '#1976D2' }}/>
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Stack direction='row' justifyContent='flex-start' spacing={2} sx={{ ml: -1 }}>
                    {social &&
                Object.entries(social).map(([key, value]) => (<IconButton key={key} component='a' href={value} target='_blank' rel='noopener noreferrer'>
                          <Box component='img' src={`assets/images/crm_${key}.png`} alt={key} sx={{
                        maxWidth: '22px',
                        maxHeight: '22px',
                        width: 'auto',
                        height: 'auto'
                    }}/>
                        </IconButton>))}
                  </Stack>
                </Grid>
                

                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant='caption'>Contacto</Typography>
                  <Typography sx={{ color: 'black' }}>{pessoa.contactoTelefonico == null ? 'Desconhecido' : pessoa.contactoTelefonico}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant='caption'>NIF</Typography>
                  <Typography sx={{ color: 'black' }}>{pessoa.nif == null ? 'Desconhecido' : pessoa.nif}</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant='caption'>Último contacto</Typography>
                  <Typography sx={{ color: 'black' }}>{pessoa.dataUltimoContacto == null ? 'Desconhecido' : moment(pessoa.dataUltimoContacto, 'DD-MM-YYYY').format('DD-MM-YYYY')}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant='caption'>Email</Typography>
                  <Typography sx={{ color: 'black' }}>{pessoa.email == null ? 'Desconhecido' : pessoa.email}</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant='caption'>Data de Nascimento</Typography>
                  <Typography sx={{ color: 'black' }}>{pessoa.dataNascimento == null ? 'Desconhecido' : moment(pessoa.dataNascimento, 'DD-MM-YYYY').format('DD-MM-YYYY')}</Typography>
                </Grid>
                {/* <Grid item xs={6} sm={6} md={3}>
              <Typography variant='caption'>Cargo</Typography>
              <Typography sx={{ color: 'black' }}>{pessoa.cargo == null ? 'Desconhecido' : pessoa.cargo}</Typography>
            </Grid> */}
                <Grid item xs={6} sm={6} md={3}>
                  <Typography variant='caption'>Área de Formação</Typography>
                  <Typography sx={{ color: 'black' }}>{pessoa.profissao == null ? 'Desconhecido' : pessoa.profissao}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={3}></Grid>

                {pessoa.interessesPessoais == null && pessoa.interessesProfissionais == null ? (<></>) : (<>
                    <Grid item xs={6} sm={6} md={3}>
                      <Typography variant='caption'>Interesses pessoais</Typography>
                      <Typography sx={{ color: 'black' }}>{pessoa.interessesPessoais == null ? 'Desconhecido' : pessoa.interessesPessoais}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <Typography variant='caption'>Interesses profissionais</Typography>
                      <Typography sx={{ color: 'black' }}>{pessoa.interessesProfissionais == null ? 'Desconhecido' : pessoa.interessesProfissionais}</Typography>
                    </Grid>
                  </>)}
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Stack spacing={4}>
                <Interacoes $state={$state} CrmService={CrmService} user={pessoa}/>

                <TableData title={'Pedidos de cotação'} columns={colunasPedidosCotacao} data={dadosPedidosCotacao} addButton={true}></TableData>

                <EmpresasAssociadas $state={$state} CrmService={CrmService} user={pessoa}/>

                <PessoasAssociadas $state={$state} CrmService={CrmService} user={pessoa}/>

                <DocumentosAssociados $state={$state} CrmService={CrmService} user={pessoa}/>
              </Stack>
            </Grid>
          </Grid>
        </Box>) : (<Stack direction='row' justifyContent='center' alignItems='flex-start' sx={{ mt: 4 }}>
          <CircularProgress size={50}/>
        </Stack>)}
    </Box>);
};
export default CrmPessoasDetailsView;
