import angular from 'angular';

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();

}

export default angular.module('app.interface.alert', [])
  .factory('Toast', ['$rootScope', '$timeout', ($rootScope, $timeout) => {
    let toastService;
    $rootScope.alerts = $rootScope.alerts || [];
    return toastService = {
      LENGTH_LONG: 3500,
      LENGTH_SHORT: 2000,
      add: (message, length) => {
        let toast = {
          id: new guid(),
          message: message,
          snackbar: false,
          onIgnore: () => {
            defer.resolve(false);
            let index = $rootScope.alerts.indexOf(toast);
            if (index !== -1)
              $rootScope.alerts.splice(index, 1);
          },
          onAction: () => {

          }
        };
        $rootScope.alerts.push(toast);

        $timeout(() => {
          let index = $rootScope.alerts.indexOf(toast);
          if (index !== -1)
            $rootScope.alerts.splice(index, 1);
        }, length);
      },
      clear: () => {
        $rootScope.alerts = [];
      }
    }
  }])
  .factory('Snackbar', ['$rootScope', '$timeout', '$q', ($rootScope, $timeout, $q) => {
    let snackbarService;
    $rootScope.alerts = $rootScope.alerts || [];
    return snackbarService = {
      add: (message, action, dismissable) => {
        action = action || '×';
        if (typeof dismissable === 'undefined') dismissable = true;
        dismissable = dismissable === true;

        let defer = $q.defer();

        let snackbar = {
          id: new guid(),
          message: message,
          snackbar: true,
          action: action.toUpperCase(),
          onAction: () => {
            defer.resolve(true);
            let index = $rootScope.alerts.indexOf(snackbar);
            if (index !== -1)
              $rootScope.alerts.splice(index, 1);
          },
          onIgnore: () => {
            defer.resolve(false);
            let index = $rootScope.alerts.indexOf(snackbar);
            if (index !== -1)
              $rootScope.alerts.splice(index, 1);
          }
        };

        if (dismissable) {
          $timeout(() => {
            snackbar.onIgnore();
          }, 5000);
        }

        $rootScope.alerts.push(snackbar);

        return defer.promise;
      },
      clear: () => {
        $rootScope.alerts = [];
      }
    }
  }])
  .controller('pmdToastController', ['$rootScope', function ($rootScope) {
    var ctrl = this;
    ctrl.alerts = $rootScope.alerts;
  }])
  .directive('pmdAlertArea', () => ({
    replace: true,
    scope: {},
    controller: 'pmdToastController',
    controllerAs: 'pmd',
    template: () => "" +
      "<div class='pmd-alert-container center bottom'>\n" +
      "  <div class='pmd-alert visible fadeInDown' data-action='{{alert.snackbar}}' ng-repeat='alert in pmd.alerts' ng-click='alert.onIgnore()'>\n" +
      "    {{alert.message}}\n" +
      "    <a ng-if='alert.snackbar' ng-click='alert.onAction()'>{{alert.action}}</a>" +
      "  </div>\n" +
      "</div>\n",
  }))
  .run(['$rootScope', ($rootScope) => {
    $rootScope.uiModules = $rootScope.uiModules || [];
    let properties = {
      name: 'Alert',
      description: 'Toasts & Snackbars',
      version: '1.0.2'
    };
    if ($rootScope.uiModules.indexOf(properties) === -1) {
      $rootScope.uiModules.push(properties);
    }
  }])

  .name;
