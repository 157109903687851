export default class AtvEntidadesProprietariasDetailsController {
  constructor($q, $state, $filter, UIService, AuthorizationService, AtvAtivosService, AtvEntidadeProprietaria, AtvPropriedade, AtvLocal, AtvAtivo) {
    this.$q = $q;
    this.state = $state;
    this.filter = $filter;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.AtvAtivosService = AtvAtivosService;
    this.AtvEntidadeProprietaria = AtvEntidadeProprietaria;
    this.AtvPropriedade = AtvPropriedade;
    this.AtvLocal = AtvLocal;
    this.AtvAtivo = AtvAtivo;
    this.loaded = false;
    this.ep = null;

    // Default opt state - populate later when we have all states
    this.optAtivos = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "id",
      sort: "desc",
      filter: {}
    };
    this.ativos = null;
    this.ativosLoading = true;

    // Default opt state - populate later when we have all states
    this.optLocais = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "designacao",
      sort: "asc",
      filter: {}
    };
    this.locais = null;
    this.locaisLoading = true;

    this.id = $state.params.id;
    if (angular.isUndefined($state.params.id) || isNaN($state.params.id)) {
      this.state.go('app.ativos.entidadesproprietarias.list');
      this.UI.addToast('Entidade Proprietária desconhecida/inválida');
    } else {
      this.loadData();
    }

    this.propriedadesOptions = {
      containment: 'parent',
      tolerance: 'pointer',
      handle: '.pmd-move-handle',
      'ui-floating': true,
      stop: (e, ui) => {
        for (let i = 0; i < this.ep.propriedades.length; i++) {
          this.ep.propriedades[i].ordem = i + 1;
          this.AtvPropriedade.upsert(this.ep.propriedades[i]).$promise.then((res) => {
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não foi possível reordenar propriedades. Por favor tente mais tarde");
            this.loadData();
          });
        }
      }
    }
  }

  loadData = () => {
    this.loaded = false;
    this.AtvEntidadeProprietaria.findOne({
      filter: {
        where: {
          id: this.id,
          active: true
        },
        include: [{
          relation: 'propriedades',
          scope: {
            where: {
              active: 1
            }
          }
        }, {
          relation: 'locais',
          scope: {
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then((ep) => {
      this.ep = ep;
      this.loaded = true;
    }).catch(() => {
      this.state.go('app.ativos.entidadesproprietarias.list');
      this.UI.addToast('Erro a carregar dados de entidade proprietária.');
    });
    this.getAtivosForEntidadeProprietaria();
    this.getLocaisForEntidadeProprietaria();
  };

  editEntidadeProprietaria = () => {
    let options = {
      size: 'lg',
      template: require('./details.dialog.html'),
      controller: ['$dialog', '$scope', 'AtvFamilia', (dialog, scope, AtvFamilia) => {
        scope.title = "Editar Entidade Proprietária";
        scope.ep = angular.copy(this.ep);

        scope.ok = () => {
          AtvFamilia.find({
            filter: {
              where: {
                prefixo: scope.ep.sigla.toUpperCase(),
                active: 1
              }
            }
          }).$promise.then((siglas) => {
            if (siglas && siglas.length > 0) {
              this.UI.addToast("Sigla já está a ser usada como prefixo de família.");
            } else {
              dialog.close(scope);
            }
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não é possível verificar existência de sigla. Por favor tente mais tarde");
          });
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.ep) {
        this.AtvEntidadeProprietaria.upsert(res.ep).$promise.then((res) => {
          this.UI.addToast("Entidade Proprietária editada com sucesso");
          this.loadData();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na edição de entidade proprietária. Por favor tente mais tarde.");
        });
      }
    });
  };

  editObservations = () => {
    let obs = angular.copy(this.ep.observacoes);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = obs;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.loaded = false;
      this.ep.observacoes = res;
      this.ep.$save().then(() => {
        this.UI.addToast('Observações guardadas com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro a guardar as observações. Por favor tente mais tarde.');
        this.loadData();
      })
    })
  };

  addPropriedade = () => {
    let options = {
      size: 'lg',
      template: require('./propriedade.new.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Nova Área Organizacional";
        scope.propriedade = {};

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.propriedade) {
        res.propriedade.id = 0;
        res.propriedade.entidadeProprietariaId = this.ep.id;
        res.propriedade.active = 1;
        this.AtvPropriedade.count({
          where: {
            entidadeProprietariaId: this.ep.id,
            active: 1
          }
        }).$promise.then((n) => {
          res.propriedade.ordem = n.count + 1;
          this.AtvPropriedade.create(res.propriedade).$promise.then((res) => {
            this.UI.addToast("Área Organizacional criada com sucesso");
            this.loadData();
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro na criação de AOA. Por favor tente mais tarde.");
          });
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na criação de AOA. Por favor tente mais tarde");
        });
      }
    });
  };

  editPropriedade = (r) => {
    let options = {
      size: 'lg',
      template: require('./propriedade.new.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Área Organizacional";
        scope.propriedade = angular.copy(r);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.propriedade) {
        this.AtvPropriedade.upsert(res.propriedade).$promise.then((res) => {
          this.UI.addToast("Área Organizacional editada com sucesso");
          this.loadData();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na edição de AOA. Por favor tente mais tarde.");
        });
      }
    });
  };

  checkDeletePropriedade = (f) => {
    let cenas = this.UI.showWaiting();
    this.AtvPropriedade.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((propriedadeToRemove) => {
      // Find if we have something that might block deletion
      this.AtvAtivo.count({
        fields: {id: true},
        where: {
          active: 1,
          propriedadeId: propriedadeToRemove.id
        }
      }).$promise.then((ativos) => {
        cenas.close();
        if (ativos.count > 0) {
          let msg = "Não é possível remover esta área organizacional.\nExiste" + (ativos.count > 1 ? "m " : " ");
          msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")) : "";
          msg += "associado" + (ativos.count > 1 ? "s" : "") + " a esta AOA.";
          this.UI.showAlert(msg);
        } else {
          this.confirmDeletePropriedade(f);
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("De momento não é possível remover AOA. Verifique a ligação");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar AOA. Verifique a ligação");
    });
  };

  confirmDeletePropriedade = (f) => {
    let title = "Eliminar Área Organizacional";
    let warning = "Tem a certeza que pretende remover esta área organizacional?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removePropriedade(f);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removePropriedade = (f) => {
    this.loaded = false;
    // Find again propriedade to delete so we can update it
    this.AtvPropriedade.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((propriedadeToRemove) => {
      propriedadeToRemove.active = 0;
      propriedadeToRemove.$save().then((res) => {
        this.AtvPropriedade.find({
          filter: {
            where: {
              entidadeProprietariaId: this.ep.id,
              active: 1
            },
            order: 'ordem ASC'
          }
        }).$promise.then((props) => {
          let tasks = [];
          for (let i = 0; i < props.length; i++) {
            props[i].ordem = i + 1;
            let defer = this.AtvPropriedade.upsert(props[i]).$promise;
            tasks.push(defer.promise);
          }
          this.$q.all(tasks).then((res) => {
            this.UI.addToast("Área Organizacional eliminada com sucesso!");
            this.loadData();
          }).catch(err => {
            console.log(err);
          });
        }).catch((error) => {
          console.log(error);
        });
      }, (error) => {
        console.log(error);
        this.loaded = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível eliminar AOA. Verifique se ainda existe.");
      this.loadData();
    });
  };

  addLocal = () => {
    let options = {
      size: 'lg',
      template: require('./local.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Novo Local";
        scope.local = {};

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.local) {
        res.local.id = 0;
        res.local.entidadeProprietariaId = this.ep.id;
        res.local.active = 1;
        this.AtvLocal.create(res.local).$promise.then((res) => {
          this.UI.addToast("Local criado com sucesso");
          this.loadData();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na criação de local. Por favor tente mais tarde.");
        });
      }
    });
  };

  editLocal = (r) => {
    let options = {
      size: 'lg',
      template: require('./local.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Local";
        scope.local = angular.copy(r);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.local) {
        res.local.active = 1;
        this.AtvLocal.upsert(res.local).$promise.then((res) => {
          this.UI.addToast("Local editado com sucesso");
          this.loadData();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na edição de local. Por favor tente mais tarde.");
        });
      }
    });
  };

  checkDeleteLocal = (a) => {
    let cenas = this.UI.showWaiting();
    this.AtvLocal.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((localToRemove) => {
      // Find if we have something that might block deletion
      this.AtvAtivo.count({
        fields: {id: true},
        where: {
          active: 1,
          localId: localToRemove.id
        }
      }).$promise.then((ativos) => {
        cenas.close();
        if (ativos.count > 0) {
          let msg = "Não é possível remover este local.\nExiste" + (ativos.count > 1 ? "m " : " ");
          msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")) : "";
          msg += "associado" + (ativos.count > 1 ? "s" : "") + " a este local.";
          this.UI.showAlert(msg);
        } else {
          this.confirmDeleteLocal(a);
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("De momento não é possível remover locais. Por favor tente mais tarde");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("De momento não é possível remover locais. Por favor tente mais tarde");
    });
  };

  confirmDeleteLocal = (a) => {
    let title = "Eliminar Local";
    let warning = "Tem a certeza que pretende remover este local?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeLocal(a);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeLocal = (a) => {
    this.loaded = false;
    // Find again familia to delete so we can update it
    this.AtvLocal.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((localToRemove) => {
      localToRemove.active = 0;
      localToRemove.$save().then((res) => {
        this.UI.addToast("Local eliminado com sucesso!");
        this.loadData();
      }, (error) => {
        console.log(error);
        this.loaded = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível eliminar o local. Verifique se ainda existe.");
      this.loadData();
    });
  };

  getAtivosForEntidadeProprietaria = () => {
    this.ativosLoading = true;
    let whereLiteral = {
      'AtvAtivo.entidadeProprietariaId': this.id,
      'AtvAtivo.active': 1
    };
    let where = {};
    Object.keys(this.optAtivos.filter).forEach(f => {
      if (this.optAtivos.filter[f])
        where[f] = this.optAtivos.filter[f];
    });

    this.AtvAtivosService.setWherePermFields({}, 'AtvAtivo.entidadeProprietariaId').then(w => {
      let wherePerms = w.wherePerms;
      this.AtvAtivo.table({
        params: {
          fields: [
            'AtvAtivo.id as id',
            'AtvAtivo.iidDisplay as iidDisplay',
            'AtvAtivo.designacao as designacao',
            'AtvAtivo.referencia as referencia',
            'AtvMarca.designacao as marca',
            'AtvFornecedorPrimavera.refFornecedorPrimavera as refFornecedorPrimavera',
            'AtvEntidadeProprietaria.designacao as entidadeProprietaria',
            'AtvEP2.sigla as sigla'
          ],
          from: ['AtvAtivo', 'AtvMarca', 'AtvFornecedorPrimavera', 'AtvEntidadeProprietaria', 'AtvEntidadeProprietaria'],
          references: [undefined, 'AtvAtivo.marcaId', 'AtvAtivo.fornecedorPrimaveraId', 'AtvAtivo.entidadeProprietariaId', 'AtvFornecedorPrimavera.entidadeProprietariaId'],
          aliases: [null, null, null, null, 'AtvEP2'],
          where: where,
          whereLiteral: whereLiteral,
          wherePerms: wherePerms,
          order: this.optAtivos.order,
          sort: this.optAtivos.sort,
          limit: this.optAtivos.items,
          skip: (this.optAtivos.page - 1) * this.optAtivos.items,
        }
      }).$promise.then(res => {
        let page = this.optAtivos.page;
        let items = this.optAtivos.items;

        let total = res.count;

        this.optAtivos.start = total > 0 ? (page - 1) * items + 1 : 0;
        if ((this.optAtivos.start - 1 + items) >= total) {
          this.optAtivos.end = total;
        } else {
          this.optAtivos.end = Number.parseInt(this.optAtivos.start - 1 + items);
        }

        this.ativos = res.ativos;
        this.optAtivos.total = total;
        this.ativosLoading = false;
      }).catch((e) => {
        console.log(e);
        this.UI.addToast("Não foi possível ler ativos. Por favor tente mais tarde");
      });
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler permissões. Por favor tente mais tarde");
    });
  };

  applyFilterAtivos = () => {
    this.optAtivos.page = 1;
    this.getAtivosForEntidadeProprietaria();
  };

  ativosSort = keyname => {
    if (this.optAtivos.order === keyname)
      this.optAtivos.page = 1;
    this.optAtivos.order = keyname;
    this.optAtivos.sort = this.optAtivos.sort === 'asc' ? 'desc' : 'asc';
    this.getAtivosForEntidadeProprietaria();
  };

  ativosItem = val => {
    this.optAtivos.items = val;
    this.optAtivos.page = 1;
    this.getAtivosForEntidadeProprietaria();
  };

  ativosPage = sum => {
    this.optAtivos.page += sum;
    if (this.optAtivos.page < 1)
      this.optAtivos.page = 1;
    if (this.optAtivos.page > Math.ceil(this.optAtivos.total / this.optAtivos.items))
      this.optAtivos.page = Math.ceil(this.optAtivos.total / this.optAtivos.items);
    this.getAtivosForEntidadeProprietaria();
  };

  viewAtivo = r => {
    this.state.go('app.ativos.ativos.details', {id: r.id});
  };


  // Locais
  getLocaisForEntidadeProprietaria = () => {
    this.locaisLoading = true;
    let whereLiteral = {
      'AtvLocal.entidadeProprietariaId': this.id,
      'AtvLocal.active': 1
    };
    let where = {};
    Object.keys(this.optLocais.filter).forEach(f => {
      if (this.optLocais.filter[f])
        where[f] = this.optLocais.filter[f];
    });

    this.AtvLocal.table({
      params: {
        fields: [
          'AtvLocal.id as id',
          'AtvLocal.designacao as designacao',
          'AtvLocal.piso as piso',
          'AtvLocal.numeracao as numeracao'
        ],
        from: ['AtvLocal'],
        references: [undefined],
        where: where,
        whereLiteral: whereLiteral,
        order: this.optLocais.order,
        sort: this.optLocais.sort,
        limit: this.optLocais.items,
        skip: (this.optLocais.page - 1) * this.optLocais.items,
      }
    }).$promise.then(res => {
      let page = this.optLocais.page;
      let items = this.optLocais.items;

      let total = res.count;

      this.optLocais.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.optLocais.start - 1 + items) >= total) {
        this.optLocais.end = total;
      } else {
        this.optLocais.end = Number.parseInt(this.optLocais.start - 1 + items);
      }

      this.locais = res.locais;
      this.optLocais.total = total;
      this.locaisLoading = false;
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler locais. Por favor tente mais tarde");
    });
  };

  applyFilterLocais = () => {
    this.optLocais.page = 1;
    this.getLocaisForEntidadeProprietaria();
  };

  locaisSort = keyname => {
    if (this.optLocais.order === keyname)
      this.optLocais.page = 1;
    this.optLocais.order = keyname;
    this.optLocais.sort = this.optLocais.sort === 'asc' ? 'desc' : 'asc';
    this.getLocaisForEntidadeProprietaria();
  };

  locaisItem = val => {
    this.optLocais.items = val;
    this.optLocais.page = 1;
    this.getLocaisForEntidadeProprietaria();
  };

  locaisPage = sum => {
    this.optLocais.page += sum;
    if (this.optLocais.page < 1)
      this.optLocais.page = 1;
    if (this.optLocais.page > Math.ceil(this.optLocais.total / this.optLocais.items))
      this.optLocais.page = Math.ceil(this.optLocais.total / this.optLocais.items);
    this.getLocaisForEntidadeProprietaria();
  };

  viewLocal = r => {
    this.state.go('app.ativos.locais.details', {id: r.id});
  };
}
AtvEntidadesProprietariasDetailsController.$inject = ['$q', '$state', '$filter', 'UIService', 'AuthorizationService', 'AtvAtivosService', 'AtvEntidadeProprietaria', 'AtvPropriedade', 'AtvLocal', 'AtvAtivo'];
