import { routes } from './routes'

import OblOIListController from './list/controller';
import OblOIDetailsController from './details/controller';
import OblOrcamentacaoController  from './folha/controller';
import OblImporterController  from './list/import/controller';
import OblOINewController from './new/controller'

export default angular.module('app.obler.oi', [])
  .config(routes)
  .controller('OblOIListController', OblOIListController)
  .controller('OblOINewController', OblOINewController)
  .controller('OblOIDetailsController', OblOIDetailsController)
  .controller('OblOrcamentacaoController', OblOrcamentacaoController)
  .controller('OblImporterController', OblImporterController)
  .name;
