import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
export const InputComponent = ({ tipo, formato, inputChange, value, index, sx, inputProps }) => {
    const handleChange = (newValue) => {
        if (newValue !== null) {
            if (index !== undefined) {
                inputChange(newValue, index);
            }
            else {
                inputChange(newValue);
            }
        }
    };
    switch (tipo) {
        case 'TEXTO':
            return (<TextField disabled={inputProps?.disable} maxRows={inputProps?.maxRows} multiline={inputProps?.multiline} rows={inputProps?.rows} required={inputProps?.required} sx={{ ...sx }} value={value} fullWidth={inputProps?.fullWidth} onChange={event => {
                    handleChange(event.target.value);
                }} error={inputProps?.helperText === 'Preenchimento Obrigatório' ? true : false} helperText={inputProps?.helperText} label={inputProps?.label} size={inputProps?.size === 'small' || inputProps?.size === 'medium' ? inputProps.size : undefined}/>);
        case 'DATA':
            if (formato === 'HH:mm') {
                // Renderiza TimePicker para formato de hora
                return (<LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker ampm={false} slotProps={{
                        textField: {
                            size: inputProps?.size === 'small' || inputProps?.size === 'medium' ? inputProps.size : undefined,
                            fullWidth: inputProps.fullWidth,
                            label: inputProps.label,
                            required: inputProps?.required,
                            helperText: inputProps?.helperText,
                            error: inputProps?.helperText === 'Preenchimento Obrigatório' ? true : false
                        }
                    }} defaultValue={value ? moment(value, 'HH:mm') : null} onChange={newValue => handleChange(newValue?.format('HH:mm') || '')} disabled={inputProps?.disable} sx={{ ...sx }}/>
          </LocalizationProvider>);
            }
            else if (formato === 'DD/MM/YYYY') {
                // Renderiza DatePicker para formato de data
                return (<LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker slotProps={{
                        textField: {
                            size: inputProps?.size === 'small' || inputProps?.size === 'medium' ? inputProps.size : undefined,
                            fullWidth: inputProps.fullWidth,
                            label: inputProps.label,
                            required: inputProps?.required,
                            helperText: inputProps?.helperText,
                            error: inputProps?.helperText === 'Preenchimento Obrigatório' || inputProps?.helperText === 'Data de saída deve ser superior à data de entrada' ? true : false
                        }
                    }} defaultValue={value ? moment(value, 'DD/MM/YYYY') : null} onChange={newValue => handleChange(newValue?.format('DD/MM/YYYY') || '')} disabled={inputProps?.disable} sx={{ ...sx }}/>
          </LocalizationProvider>);
            }
            else if (formato === 'DD/MM/YYYY HH:mm') {
                // Renderiza DateTimePicker para data e hora
                return (<LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker ampm={false} slotProps={{
                        textField: {
                            size: inputProps?.size === 'small' || inputProps?.size === 'medium' ? inputProps.size : undefined,
                            fullWidth: inputProps.fullWidth,
                            label: inputProps.label,
                            required: inputProps?.required,
                            helperText: inputProps?.helperText,
                            error: inputProps?.helperText === 'Preenchimento Obrigatório' ? true : false
                        }
                    }} defaultValue={moment(value, 'DD/MM/YYYY HH:mm')} onChange={newValue => handleChange(newValue?.format('DD/MM/YYYY HH:mm') || '')} disabled={inputProps?.disable} sx={{ ...sx }}/>
          </LocalizationProvider>);
            }
        case 'NUMERO':
            return (<TextField variant='outlined' required={inputProps?.required} onChange={event => handleChange(event.target.value)} value={value} inputProps={{ type: 'number' }} disabled={inputProps?.disable} sx={{ ...sx }} fullWidth={inputProps?.fullWidth}/>);
        default:
            return null;
    }
};
//export default InputComponent;
