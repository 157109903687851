export default class SusEneService {
  constructor($q, UIService, Group, SusEneEstadoProcesso, PRIClientes, PRICabecdoc) {
    this.$q = $q;
    this.UI = UIService;
    this.Group = Group;
    this.SusEneEstadoProcesso = SusEneEstadoProcesso;
    this.PRIClientes = PRIClientes;
    this.PRICabecdoc = PRICabecdoc;
  }

  getProjectFolderPath = (data, empresaId) => {
    // Path to network for ENSAIOS and METROLOGIA
    if (!data)
      return "";
    let folder;
    folder = "relatorios"

    let network_path;
    // By default use shared folder SUSENE unless specified by empresaId in processo
    network_path = "servernas\\comum\ iid";

    return `\\\\${network_path}\\${folder}\\${data.iidAno}\\${('0000' + data.iidProc).slice(-4)}`;
  };

  // Return empresaId from tag (has to be sync)
  getEmpresa = (tag) => {
    switch(tag) {
      case 'IEP': return 1;
      default: return 1;
    }
  }

  // Returns tipoId (always 1)
  // centroCusto is used for IEP only
  getTipoId = (empresaId, centroCusto) => {
    switch(empresaId) {
      case 1: return 1;
      default: return 1;
    }
  };

  // Get the right table for PRICabecdoc, depending on empresa.
  // The second argument is the field name (empresaId by default)
  getPRICabecdoc = (data, field) => {
    field = field || "empresaId"; // if field is filled use it, otherwise use empresaId
    switch(data[field]) {
      case 1: return this.PRICabecdoc;
      default: return this.PRICabecdoc;
    }
  };

  // Get the right table for PRIClientes, depending on empresa.
  // The second argument is the field name (empresaId by default)
  getPRIClientes = (data, field) => {
    field = field || "empresaId"; // if field is filled use it, otherwise use empresaId
    switch(data[field]) {
      case 1: return this.PRIClientes;
      default: return this.PRIClientes;
    }
  };

  // Subroutine to get new estados for SEs - Disregard estado 1 (Aguarda Amostra)
  getNewEstadosSusEne = (estadoId) => {
    // return [1, 2, 3, 4, 5, 6, 7, 9, 10];
    // Discussed state transition for Metrologia
    switch(estadoId) {
      case 1: // Aguarda amostra(s)
        return [2, 10]; // Em agendamento, Cancelado
      case 2: // Em agendamento
        return [3, 5, 10]; // Aguarda amostra(s), Agendado, Suspenso, Cancelado
      case 3: // Agendado
        return [3, 4, 5, 10]; // Em execução, Suspenso, Cancelado
      case 4: // Em execução
        return [3, 5, 6, 10, 11]; // Suspenso, Aguarda emissão de relatório/certificado, Cancelado
      case 5: // Suspenso
        return [2, 3, 4, 6, 10]; // Em agendamento, Agendado, Em execução, Aguarda emissão relatório, Cancelado
      case 6: // Aguarda emissão de relatório/certificado
        return []; // Cancelado, Em execução(?) (this state should never happen in Metrologia)
      case 7: // Relatório/Certificado emitido
        return [11]; // For cases when you can't know if another is needed
      case 8: // Em emissão de fatura
        return []; // Should never happen, so don't allow anything here
      case 9: // Concluido
        return []; // Should never happen, so don't allow anything here
      case 10: // Cancelado
        return []; // Should never happen, so don't allow anything here
      case 11: // Em validação
        return [4, 6, 9];
      default:
        return [2, 3, 4, 5, 6, 7, 9, 10, 11];
    }
  };

  // Get new estados for processo, depending on type of processo, current state and empresa
  getNewEstadosProcesso = (tipoId, estadoId, empresaId) => {
    let defer = this.$q.defer();

    // Array with ids of possible next states
    let whereStates = this.getNewEstadosSusEne(estadoId);

    this.SusEneEstadoProcesso.find({
      filter: {
        where: {
          and: [{active: 1}, {id: {inq: whereStates}}]
        }
      }
    }).$promise.then((estados) => {
      defer.resolve(estados);
    }).catch((error) => {
      console.log(error);
      defer.reject(error);
    });
    return defer.promise;
  };

  getFuncionariosWithGroups = (groupList) => {
    let defer = this.$q.defer();
    if (!groupList || groupList.length === 0) defer.resolve([]);

    const whereFilter = {
      or: groupList.map(name => ({name: {like: name}}))
    };

    this.Group.find({
      filter: {
        where: whereFilter,
        include: {
          relation: 'usergroup',
          scope: {
            include: 'user'
          }
        }
      }
    }).$promise.then((groups) => {
      let tecnicosSUSENE = [];
      groups.forEach(g => {
        if (g.usergroup) {
          g.usergroup.forEach(u => {
            if (!tecnicosSUSENE.find(x => x.id === u.user.id))
              tecnicosSUSENE.push(u.user);
          });
        }
      });
      tecnicosSUSENE.sort((a, b) => a.name.localeCompare(b.name));
      defer.resolve(tecnicosSUSENE);
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };
}
SusEneService.$inject = ['$q', 'UIService', 'Group', 'SusEneEstadoProcesso', 'PRIClientes', 'PRICabecdoc'];
