export function routes($stateProvider) {
  $stateProvider
    .state('app.obler.armazem', {
      url: '/armazens',
      abstract: true
    })
    .state('app.obler.armazem.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Armazéns',
      role: ['oblerVerArmazens'],
      template: require('./list/view.html'),
      controller: 'OblArmazemListController',
      controllerAs: 'vm',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: 'id',
          dynamic: true
        },
        sort: {
          value: 'desc',
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.obler.armazem.details', {
      url: '/{id}',
      title: 'Detalhes do armazém',
      role: ['oblerVerArmazens'],
      template: require('./details/view.html'),
      controller: 'OblArmazemDetailsController',
      controllerAs: 'vm'
    });
}

routes.$inject = ['$stateProvider'];
