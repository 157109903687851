import React from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, CircularProgress } from '@mui/material';
export const AutoComplete = ({ selectList, id, onSearch, onChange, label, fullWidth = true, disable = false, size = 'medium', sx, loading, required, helperText, error }) => {
    return (<Autocomplete options={selectList || []} // Recebe as opções via props
     value={selectList.find(option => option.id === id) || null} // Define o valor inicial baseado no ID
     getOptionLabel={(option) => option?.name || ''} // Define o campo de exibição
     isOptionEqualToValue={(option, value) => option.id === value?.id} // Compara opções pelo ID
     onInputChange={(event, newInputValue) => {
            if (event?.type === 'click')
                return;
            if (onSearch) {
                onSearch(newInputValue); // Chama a função de busca quando o usuário digita
            }
        }} onChange={(event, newValue) => {
            if (newValue) {
                onChange(newValue.id, newValue.name); // Retorna ID e nome da opção selecionada
            }
            else {
                onChange(null); // Retorna null se o valor for limpo
            }
        }} noOptionsText={label} renderInput={(params) => (<TextField {...params} label={label} fullWidth={fullWidth} disabled={disable} size={size} required={required} sx={{ ...sx }} helperText={helperText} error={error} InputProps={{
                ...params.InputProps,
                endAdornment: (<React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>),
            }}/>)} loading={loading} sx={{ ...sx }}/>);
};
