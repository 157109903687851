import _ from 'lodash';

export default class DirectoryMessagesController {
  constructor($rootScope, $state, $scope, NgTableParams, Mensagem, $filter, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$scope = $scope;
    this.UI = UIService;
    this.NgTableParams = NgTableParams;
    this.Mensagem = Mensagem;
    this.$filter = $filter;
    this.search = "";
    this.mensagens = [];

    this.getMensagens();
  };

  hasSelect = () => {
    return _.some(this.mensagens, r => r.selected);
  };

  countSelected = () => {
    return (_.filter(this.mensagens, r => r.selected)).length;
  };

  getMensagens = () => {
    this.mensagensLoading = true;
    this.Mensagem.find({
      filter: {
        order: 'dataMensagem ASC'
      }
    }).$promise.then((res) => {

      // TODO
      // res.forEach(function(r) {
      //     r.selected = false;
      // });

      this.mensagens = res;
      this.mensagens.forEach((m) => {
        if (m.estado == 1) {
          m.estadoLong = 'Resolvida';
        } else {
          m.estadoLong = 'Por resolver';
        }
      });

      let NgTableParams = this.NgTableParams;

      this.tableData = new NgTableParams({
        sorting: {
          id: 'desc'
        }
      }, {
        dataset: res,
      });

      this.mensagensLoading = false;
    }).catch((err) => {
      console.log(err);
    });
  };

  viewMessage = (r) => {
    let instance = this.UI.showDialog({
      template: require('./modal-view-message.html'),
      controller: ['$scope', function ($scope) {
        $scope.message = r.conteudo;
        $scope.nome = r.nome;
        $scope.dataResolucao = r.dataResolucao;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });
  };

  deleteMensagem = (r) => {
    let instance = this.UI.showDialog({
      template: require('./modal-delete-message.html'),
      controller: ['$scope', function ($scope) {
        $scope.ok = function () {
          $scope.$close();
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((result) => {
      this.Mensagem.deleteById({
        id: r.id,
      }).$promise.then(() => {
        this.getMensagens();
      }).catch((error) => {
        console.log(error);
      });
    }, () => {
    });
  };

  deleteSelected = () => {
    let instance = this.UI.showDialog({
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = 'Eliminar mensagens';
        $scope.message = 'Deseja eliminar as mensagens seleccionadas? Esta acção não é reversível!';

        $scope.ok = function () {
          $scope.$close();
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      //Confirm
      let toDelete = _.filter(this.mensagens, function (r) {
        return r.selected == true;
      });

      toDelete.forEach((r) => {
        this.Mensagem.deleteById({
          id: r.id,
        }).$promise.then(() => {
          this.getMensagens();
        }).catch((error) => {
          console.log(error);
        });
      });
      this.getMensagens();
    }, () => {
      // Cancel
    });
  };

  hasMensagens = () => {
    return this.mensagens.length > 0;
  };

  changeEstado = (r) => {
    if (r.estado) {
      r.estadoLong = 'Por resolver';
    } else {
      r.dataResolucao = new Date();
      r.estadoLong = 'Resolvida';
    }
    r.estado = !r.estado;
    r.$save();
  };

  getEstado = (r) => {
    if (r.active == 1) {
      return 'visibility';
    } else {
      return 'visibility_off';
    }
  };
}

DirectoryMessagesController.$inject = ['$rootScope', '$state', '$scope', 'NgTableParams', 'Mensagem', '$filter', 'UIService'];
