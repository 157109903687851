export function routes($stateProvider) {
  $stateProvider
    .state('app.gas.agendamentos', {
      url: '/agendamentos',
      abstract: true
    })
    .state('app.gas.agendamentos.list', {
      url: '?{tab:int}&{page:int}&{limit:int}&sortBy&filter',
      title: 'Agendamentos',
      template: '<gas-agendamentos-list-view></gas-agendamentos-list-view>',
      role: ['gasListarAgendamento'],
      params: {
        tab: {
          value: 0,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        limit: {
          value: 20,
          dynamic: true
        },
        sortBy: {
          array: true,
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    });
}

routes.$inject = ['$stateProvider'];
