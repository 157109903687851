export default class FormacaoFormadorDetailsController {
  constructor($state, FormacaoFormador, UIService, FormacaoFormadorAreasInteresse, FormacaoAreaCategoria) {
    this.$state = $state;
    this.UI = UIService;
    this.id = $state.params.id;
    this.FormacaoFormador = FormacaoFormador;
    this.FormacaoFormadorAreasInteresse = FormacaoFormadorAreasInteresse;
    FormacaoAreaCategoria.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(r => {
      this.areas = r;
    });
    this.hasLoaded = false;
    // Redirect if not defined
    if (!this.id)
      this.$state.go('app.formacao.formadores.list');

    this.loadData();
  }

  editObservacoes = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.observacoes = angular.copy(this.formador.observacoes);

        $scope.ok = () => {
          $scope.$close($scope.observacoes);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        }
      }]
    }).then(res => {
      this.formador.observacoes = res;
      this.formador.$save().then(() => {
        this.loadData();
        this.UI.addToast('Observações editado com sucesso');
      }).catch(() => {
        this.UI.addToast('Erro ao editar observações');
      });
    }).catch(() => {
    });
  };

  editDetails = () => {
    let o = angular.copy(this.formador);
    this.UI.showDialog({
      size: 'lg',
      template: require('./details.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.ok = () => $scope.$close($scope.formador);

        $scope.cancel = () => $scope.$dismiss('cancel');

        $scope.formador = {
          nome: o.nome,
          email: o.email,
          contacto: o.contacto,
          morada: o.morada,
          dataNascimento: o.dataNascimento ? moment(o.dataNascimento) : null
        }
      }]
    }).then(res => {
      if (res) {
        console.log(res);
        this.formador.nome = res.nome;
        this.formador.contacto = res.contacto;
        this.formador.email = res.email;
        this.formador.morada = res.morada;
        this.formador.dataNascimento = res.dataNascimento ? moment(res.dataNascimento).format('YYYY-MM-DD') : null;
        this.formador.$save().then(() => {
          this.loadData();
          this.UI.addToast('Formador atualizado com sucesso');
        }).catch(() => {
          this.loadData();
          this.UI.addToast('Erro ao atualizar formador');
        })
      }
    });
  };

  editGeneral = () => {
    let o = angular.copy(this.formador);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./general.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.f = {
          cap: o.cap === 1,
          superior: o.superior === 1
        };

        $scope.data = {
          selected: []
        };

        $scope.ok = () => {
          $scope.$close({
            list: $scope.data.selected,
            cap: $scope.f.cap,
            superior: $scope.f.superior
          });
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };

        $scope.areasInteresse = this.areas;

        this.formador.areasInteresse.forEach((i) => {
          this.areas.forEach(a => {
            if (a.id === i.areacategoriaId) {
              $scope.data.selected.push(a);
            }
          });
        });
      }]
    }).then(res => {
      this.formador.cap = res.cap;
      this.formador.superior = res.superior;
      this.FormacaoFormador.upsert(this.formador);
      this.FormacaoFormadorAreasInteresse.delete({
        where: {
          formadorId: this.id
        }
      }).$promise.then(() => {
        res.list.forEach(r => {
          this.FormacaoFormadorAreasInteresse.create({
            id: 0,
            formadorId: this.id,
            areacategoriaId: r.id
          });
        });
        this.loadData();
        this.UI.addToast('Dados atualizados com sucesso');
      });
    }).catch(() => {});
  };

  editInfo = (num) => {
    let o = angular.copy(this.formador);
    let prop = "";
    let data = "";
    switch (num) {
      default:
        // CV
        data = "CV Resumido";
        prop = "cv";
        break;
      case 2:
        // habilitacoes
        data = "Habilitações";
        prop = "habilitacoes";
        break;
      case 3:
        // atividade profissional
        data = "Atividade profissional";
        prop = "atividadeProfissional";
        break;
    }
    this.UI.showDialog({
      size: 'lg',
      template: require('./info.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.data = data;
        $scope.prop = prop;
        $scope.ok = () => $scope.$close($scope.formador);
        $scope.cancel = () => $scope.$dismiss('cancel');
        $scope.formador = {};
        $scope.formador[prop] = o[prop];
      }]
    }).then(res => {
      if (res) {
        this.formador[prop] = res[prop];
        this.formador.$save().then(() => {
          this.loadData();
          this.UI.addToast('Formador atualizado com sucesso');
        }).catch(() => {
          this.loadData();
          this.UI.addToast('Erro ao atualizar formador');
        })
      }
    });
  };

  loadData = () => {
    this.hasLoaded = false;
    this.FormacaoFormador.findOne({
      filter: {
        where: {
          id: this.id,
          active: 1
        },
        include: [{
          relation: 'areasInteresse',
          scope: {
            include: 'area'
          }
        }, {
          relation: 'avaliacoes',
          scope: {
            where: {
              active: 1
            },
            include: {
              relation: 'edicao',
              scope: {
                where: {
                  active: 1
                },
                include: {
                  relation: 'curso',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }
              }
            }
          }
        }, {
          relation: 'leccionacoes',
          scope: {
            include: ['modulo', {
              relation: 'edicao',
              scope: {
                include: 'curso'
              }
            }]
          }
        }]
      }
    }).$promise.then((result) => {// Move to list if no result is found
      if (!result) {
        this.UI.addToast('Ocorreu um erro ao ler formador');
        this.$state.go('app.formacao.locais.list');
      }
      this.formador = result;
      this.hasLoaded = true;
    }).catch(() => {
      this.UI.addToast('Ocorreu um erro ao ler formador');
      this.$state.go('app.formacao.formadores.list');
    });
  };

  filterLeccionacao = (edicaoId) => {
    return _.filter(this.formador.leccionacoes, l => l.edicaoId === edicaoId);
  };
}

FormacaoFormadorDetailsController.$inject = ['$state', 'FormacaoFormador', 'UIService', 'FormacaoFormadorAreasInteresse', 'FormacaoAreaCategoria'];
