let XLSX = require("xlsx");

export default class ElvAdminController {
  constructor($q, $http, $filter, $state, FileUploader, AuthenticationService, UIService, ElvClausula, ElvCondominio, CoreResponsavel, CoreAprovacaoVersao, CoreSincronizacao) {
    this.$q = $q;
    this.$http = $http;
    this.$filter = $filter;
    this.$state = $state;
    this.user = AuthenticationService.getUser();
    this.UI = UIService;
    this.ElvClausula = ElvClausula;
    this.ElvCondominio = ElvCondominio;
    this.CoreResponsavel = CoreResponsavel;
    this.CoreAprovacaoVersao = CoreAprovacaoVersao;
    this.CoreSincronizacao = CoreSincronizacao;

    this.clausulasLoading = true;
    this.condominiosLoading = true;
    this.responsavelLoading = false;
    this.versioningLoading = true;
    this.sincronizacaoLoading = true;
    this.errorResponsavel = false;

    this.showClausulasC1C2Fab = true;
    this.showClausulasC3Fab = true;
    this.showClausulasC2_Fab = true;
    this.showCondominiosFab = true;
    this.condominiosCount = null;

    this.optC1C2 = {
      model: this.ElvClausula,
      type: "C1C2",
      // Total from the db for the query
      total: 0,
      // Order of the first item shown
      start: 0,
      // Order of the last item shown
      end: 0,
      // Current "page" to view
      currentPage: 0,
      // Number of items to show at the same time. Return this value from db query
      perPage: 10,
      // Choices for number of items per page
      numberItemChoices: [10, 50, 100],
      // While data from db is loading
      loaded: true,
      // Default order of data
      order: [{ id: "DESC" }],
      // Filters always applied to data (ex. active:1) Always assumes that we are using AND fields
      defaultFilter: [{ active: 1 }, { or: [{ tipoId: 1 }, { tipoId: 2 }] }],
      // Applied filters to data (filterLayout, what the user types, filter what we actually search for
      filterLayout: {},
      // Error
      error: null,
    };

    this.optC3 = {
      model: this.ElvClausula,
      type: "C3",
      // Total from the db for the query
      total: 0,
      // Order of the first item shown
      start: 0,
      // Order of the last item shown
      end: 0,
      // Current "page" to view
      currentPage: 0,
      // Number of items to show at the same time. Return this value from db query
      perPage: 10,
      // Choices for number of items per page
      numberItemChoices: [10, 50, 100],
      // While data from db is loading
      loaded: true,
      // Default order of data
      order: [{ tipoId: "ASC" }, { id: "DESC" }],
      // Filters always applied to data (ex. active:1) Always assumes that we are using AND fields
      defaultFilter: [{ active: 1 }, { or: [{ tipoId: 3 }] }],
      // Applied filters to data (filterLayout, what the user types, filter what we actually search for
      filterLayout: {},
      // Error
      error: null,
    };

    this.optC2_ = {
      model: this.ElvClausula,
      type: "C2*",
      // Total from the db for the query
      total: 0,
      // Order of the first item shown
      start: 0,
      // Order of the last item shown
      end: 0,
      // Current "page" to view
      currentPage: 0,
      // Number of items to show at the same time. Return this value from db query
      perPage: 10,
      // Choices for number of items per page
      numberItemChoices: [10, 50, 100],
      // While data from db is loading
      loaded: true,
      // Default order of data
      order: [{ tipoId: "ASC" }, { id: "DESC" }],
      // Filters always applied to data (ex. active:1) Always assumes that we are using AND fields
      defaultFilter: [{ active: 1 }, { or: [{ tipoId: 4 }] }],
      // Applied filters to data (filterLayout, what the user types, filter what we actually search for
      filterLayout: {},
      // Error
      error: null,
    };

    this.onFilter(this.optC1C2, false);
    this.onFilter(this.optC3, false);
    this.onFilter(this.optC2_, false);
    this.getCondominios(); // Only gets count() for now
    this.responsavel = null;
    this.responsaveis = null;
    this.sincronizacoes = null;
    this.isResponsavel = false;
    this.auxResponsavel = {
      selected: undefined,
      infiniteScroll: { numToAdd: 20, currentItems: 20 },
    };
    // Setup file uploader
    this.uploader = new FileUploader({
      url: "/api/Upload/assinaturas/upload",
      removeAfterUpload: true,
    });
    this.newImageSelected = false;

    this.initializeUploader();
    this.getResponsaveis(); // getResponsavel() is called inside

    // Should probably be done after getting responsavel, but it doesn't matter anyway because view is blocked
    this.versionsSgi = [];
    this.versionsApp = [];
    this.moduleName = 'ELV'; // Used throughout the code for this controller, do not change
    this.getAprovacaoVersoes();
    this.getSincronizacao();
  }

  getSincronizacao = () => {
    this.sincronizacaoLoading = true;
    this.CoreSincronizacao.find({
      filter: {
        where: {
          modulo: {like: 'elv'}
        },
      }
    }).$promise.then((sincronizacoes) => {
      // TODO - Change this after confirmation
      sincronizacoes.forEach(s => {
        if (s.nome.startsWith('Cláusulas')) s.nome = 'Cláusulas';
      });
      this.sincronizacoes = sincronizacoes;
      this.sincronizacaoLoading = false;
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível consultar últimas sincronizações. Verifique a ligação");
      this.sincronizacaoLoading = false;
    });
  };

  getAprovacaoVersoesSgi = () => {
    let defer = this.$q.defer();
    this.versionsSgi = [];
    this.CoreAprovacaoVersao.getVersions({params: {app: 'SGI', modulo: this.moduleName}}).$promise.then((data) => {
      this.versionsSgi = data.versions;
      if (this.versionsSgi && data.moduleId && this.versionsSgi.length > 0) {
        this.CoreAprovacaoVersao.find({
          filter: {
            where: {
              and: [{active: 1}, {moduleId: data.moduleId}] // Search by moduleId and not by "modulo" because both APP+SGI have the same "modulo" (same CoreResponsavel)
            },
            include: "funcionario"
          }
        }).$promise.then((aprovacoes) => {
          if (aprovacoes.length > 0) {
            // Match aprovacoes with versions
            this.versionsSgi.forEach(v => {
              v.aprovacao = aprovacoes.find(a => (v.appId === a.appId) && (v.moduleId === a.moduleId) && (v.id === a.versioningId));
            });
          }
          defer.resolve();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível obter informação de aprovação de versões SGI. Verifique a ligação.");
          defer.reject();
        });
      } else {
        this.versioningLoading = false;
        defer.resolve();
      }
    }).catch(error => {
      this.versioningLoading = false;
      console.log(error);
      this.UI.addToast("Não foi possível obter lista de aprovação de versões SGI. Verifique a ligação.");
      defer.reject();
    });
    return defer.promise;
  };

  getAprovacaoVersoesApp = () => {
    let defer = this.$q.defer();
    this.versionsApp = [];
    this.CoreAprovacaoVersao.getVersions({params: {app: 'APP', modulo: this.moduleName}}).$promise.then((data) => {
      this.versionsApp = data.versions;
      if (this.versionsApp && data.moduleId && this.versionsApp.length > 0) {
        this.CoreAprovacaoVersao.find({
          filter: {
            where: {
              and: [{active: 1}, {moduleId: data.moduleId}] // Search by moduleId and not by "modulo" because both APP+SGI have the same "modulo" (same CoreResponsavel)
            },
            include: "funcionario"
          }
        }).$promise.then((aprovacoes) => {
          if (aprovacoes.length > 0) {
            // Match aprovacoes with versions
            this.versionsApp.forEach(v => {
              v.aprovacao = aprovacoes.find(a => (v.appId === a.appId) && (v.moduleId === a.moduleId) && (v.id === a.versioningId));
            });
          }
          defer.resolve();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível obter informação de aprovação de versões APP. Verifique a ligação.");
          defer.reject();
        });
      } else {
        this.versioningLoading = false;
        defer.resolve();
      }
    }).catch(error => {
      this.versioningLoading = false;
      console.log(error);
      this.UI.addToast("Não foi possível obter lista de aprovação de versões APP. Verifique a ligação.");
      defer.reject();
    });
    return defer.promise;
  };


  getAprovacaoVersoes = () => {
    this.versioningLoading = true;

    let deferSgi = this.getAprovacaoVersoesSgi();
    let deferApp = this.getAprovacaoVersoesApp();

    this.$q.all([deferSgi, deferApp]).then(() => {
      this.versioningLoading = false;

    }).catch(error => {
      console.log(error);
      this.loaded = true;
      this.UI.showAlert("Ocorreu um erro ao obter versões. Por favor verifique ligação.");
    });
  };

  showVersao = (versao) => {
    let options = {
      template: require('./show.version.dialog.html'),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.label = `Detalhes da Versão ${this.$filter('semantic')(versao.version)}`;
        $scope.versao = versao;
        $scope.moduleName = "Elevadores";

        $scope.fechar = () => {
          $dialog.dismiss('cancel');
        };

        $scope.aprovar = () => {
          $dialog.close($scope.versao);
        };

      }]
    };
    let modal = this.UI.showDialog(options);

    modal.then((versao) => {
      if (versao) this.aprovarVersao(versao);
    }).catch(() => {
    });
  };

  aprovarVersao = (versao) => {
    let confirm = this.UI.showConfirm(`Tem a certeza que pretende aprovar a versão ${this.$filter('semantic')(versao.version)}?`);
    confirm.then(() => {
      let aprovacao = {
        id : 0,
        appId: versao.appId,
        moduleId: versao.moduleId,
        modulo: this.moduleName, // Used to search for responsavel
        versioningId: versao.id,
        version: versao.version
      };
      this.CoreAprovacaoVersao.aprovar({versao: aprovacao}).$promise.then(() => {
        this.UI.addToast("Versão aprovada");
        this.getAprovacaoVersoes();
      }).catch(error => {
        console.log(error);
        this.UI.showAlert("Ocorreu um erro ao aprovar versão. Verifique a ligação ou permissões.");
      });
    }).catch(() => {});
  };

  getResponsavel = () => {
    this.responsavelLoading = true;
    this.CoreResponsavel.findOne({
      filter: {
        where: {
          modulo: "ELV",
        },
      },
    })
      .$promise.then((responsavel) => {
        if (responsavel) {
          this.responsavel = responsavel;
          if (this.responsavel.funcionarioId === this.user.id)
            this.isResponsavel = true; // To show approval tab only to them
          this.auxResponsavel.selected = this.responsaveis.find(
            (x) => x.id === responsavel.funcionarioId
          );
          if (this.responsavel.imagem) {
            this.responsavel.imagemUrl =
              "api/Upload/assinaturas/download/" + this.responsavel.imagem;
          }
          this.errorResponsavel = false;
        } else this.errorResponsavel = true;

        this.responsavelLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.errorResponsavel = true;
        this.responsavelLoading = false;
      });
  };

  getResponsaveis = () => {
    this.CoreResponsavel.getResponsavelChoice({ params: { modulo: "ELV" } })
      .$promise.then((responsaveis) => {
        this.responsaveis = responsaveis.list;
        this.getResponsavel();
      })
      .catch((error) => {
        console.log(error);
        this.UI.addToast("Não foi possível ler lista de responsáveis técnicos");
      });
  };

  // When selecting, change nomeExtenso
  selectResponsavel = (item) => {
    this.responsavel.nomeExtenso = item.name;
    this.responsavel.funcionarioId = item.id;
  };

  saveResponsavel = () => {
    this.responsavelLoading = true;
    if (this.newImageSelected) {
      // Upload file then update Responsavel
      this.uploader.uploadAll();
    } else {
      // No new image, just update the object file
      this.CoreResponsavel.upsert(this.responsavel)
        .$promise.then((resp) => {
          this.UI.addToast("Responsável atualizado com sucesso");
          this.getResponsavel();
        })
        .catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar responsável");
          this.responsavelLoading = false;
        });
    }
  };

  // Infinite Scroll magic
  addMoreItems = (infiniteScroll) => {
    infiniteScroll.currentItems += infiniteScroll.numToAdd;
  };

  // Image stuff
  initializeUploader = () => {
    // Enforce PDF Files
    this.uploader.filters.push({
      name: "verifyPNG",
      fn: function (item, options) {
        return item.type.indexOf("image/png") !== -1;
      },
    });

    // Force queue limit = 1
    this.uploader.onAfterAddingFile = () => {
      if (this.uploader.queue.length > 1) {
        this.uploader.queue.splice(0, this.uploader.queue.length - 1);
      }
      this.newImageSelected = true;
      this.newImageName = this.uploader.queue[0].file.name;
    };

    this.uploader.onBeforeUploadItem = (item) => {
      item.file.name = this.generateUUID() + ".png";
      this.responsavel.imagem = item.file.name;
    };

    this.uploader.onWhenAddingFileFailed = () => {
      this.UI.addToast("Erro ao adicionar ficheiro");
    };

    this.uploader.onSuccessItem = () => {
      this.newImageSelected = false;
      this.newImageName = null;
      this.CoreResponsavel.upsert(this.responsavel)
        .$promise.then((resp) => {
          this.UI.addToast("Responsável atualizado com sucesso");
          this.getResponsavel();
        })
        .catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar responsável");
          this.responsavelLoading = false;
        });
    };

    this.uploader.onErrorItem = () => {
      this.UI.addToast("Erro ao carregar imagem. Tente novamente");
      this.responsavelLoading = false;
    };
  };

  generateUUID = () => {
    let uuid = "",
      i,
      random;
    for (i = 0; i < 32; i++) {
      random = (Math.random() * 16) | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
    }
    return uuid;
  };

  // Get clausulas of type
  getClausulas = (type) => {
    if (type === "C1C2") {
      this.onFilter(this.optC1C2, false);
    } else if (type === "C3") {
      this.onFilter(this.optC3, false);
    } else if (type === "C2*") {
      this.onFilter(this.optC2_, false);
    }
  };

  // When user chooses number of items to see in the list
  onNumberItems = (opt, items) => {
    opt.perPage = items;
    this.onFilter(opt, false);
  };

  // Returns an object with the data for filter to be applied for fields to search
  parseFilterLayout = (opt) => {
    let filter = {};

    ///////////////////////////////////////////////////
    // Has to be adapted case to case on copy elsewhere. Probably only one type is necessary
    ///////////////////////////////////////////////////
    switch (opt.type) {
      case "C1C2":
        Object.keys(opt.filterLayout).forEach((k) => {
          if (!_.isEmpty(opt.filterLayout[k])) {
            switch (k) {
              case "tipoId": // Field that you type something that isn't exactly what you search for
                let matching = opt.filterLayout[k].match(/^C([1-2])$/i);
                if (matching) {
                  filter[k] = matching[1];
                } else {
                  filter[k] = null; // Make sure the search fails as data is not supposed to appear
                }
                break;
              default:
                filter[k] = {
                  like:
                    "%" +
                    opt.filterLayout[k].replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                    "%",
                };
            }
          }
        });
        break;
      case "C3":
        Object.keys(opt.filterLayout).forEach((k) => {
          if (!_.isEmpty(opt.filterLayout[k])) {
            switch (k) {
              default:
                filter[k] = {
                  like:
                    "%" +
                    opt.filterLayout[k].replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                    "%",
                };
            }
          }
        });
        break;
      case "C2*":
        Object.keys(opt.filterLayout).forEach((k) => {
          if (!_.isEmpty(opt.filterLayout[k])) {
            switch (k) {
              default:
                filter[k] = {
                  like:
                    "%" +
                    opt.filterLayout[k].replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                    "%",
                };
            }
          }
        });
        break;
      default:
        Object.keys(opt.filterLayout).forEach((k) => {
          if (!_.isEmpty(opt.filterLayout[k])) {
            filter[k] = {
              like:
                "%" +
                opt.filterLayout[k].replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
                "%",
            };
          }
        });
        break;
    }
    return filter;
  };

  // When user clicks on column header to sort
  onSorting = (opt, field) => {
    // If not sorting by the field
    let f = opt.order.find((x) => Object.keys(x)[0] === field);
    if (!f) {
      f = {};
      opt.order = [];
      f[field] = "ASC";
      opt.order.push(f);
    } else {
      // If sorting by, change order
      f[field] = f[field] === "ASC" ? "DESC" : "ASC";
    }
    this.onFilter(opt, false);
  };

  // Returns true/false if sorting by field
  isSortingBy = (opt, field) => {
    return opt.order.find((x) => Object.keys(x)[0] === field);
  };

  // Returns true/false if it's sorting by type ('ASC'/'DESC')
  isSortingType = (opt, field, type) => {
    let f = opt.order.find((x) => Object.keys(x)[0] === field);
    if (!f) return false;
    else return f[field] === type;
  };

  onFilter = (opt, pagination) => {
    opt.loaded = false;
    // So we always keep a copy of the original for next time
    let defaultFilter = angular.copy(opt.defaultFilter);

    // By default only search active data
    let searchObject = { and: defaultFilter };
    // Add filters to search
    Object.keys(opt.filterLayout).forEach((k) => {
      if (k.value) {
        searchObject.and.push(k);
      }
    });

    // Parse filters done by the user
    let userFilters = this.parseFilterLayout(opt);
    Object.keys(userFilters).forEach((k) => {
      let newFilter = {};
      newFilter[k] = userFilters[k];
      searchObject.and.push(newFilter);
    });

    let itemsToSkip = 0;
    // If we have pagination, we have to skip appropriately
    if (pagination) {
      itemsToSkip = opt.currentPage * opt.perPage; //
    } else {
      opt.currentPage = 0; // Reset to 1st page
    }

    // Deal with order
    let order = "";
    opt.order.forEach((o, i) => {
      let key = Object.keys(o)[0]; // The key to sort by
      if (i === 0) order += key + " " + o[key];
      else order += ", " + key + " " + o[key];
    });

    opt.model
      .count({
        fields: { id: true },
        where: searchObject,
      })
      .$promise.then((total) => {
        opt.total = total.count;

        // Do the search
        opt.model
          .find({
            filter: {
              where: searchObject,
              order: order,
              limit: opt.perPage,
              skip: itemsToSkip,
            },
          })
          .$promise.then((res) => {
            opt.data = res;
            opt.start = opt.total === 0 ? 0 : opt.currentPage * opt.perPage + 1;
            opt.end =
              opt.total === 0
                ? 0
                : opt.currentPage * opt.perPage + opt.data.length;
            opt.loaded = true;
          })
          .catch((error) => {
            console.log(error);
            opt.error = error;
            opt.currentPage = 0;
            opt.start = 0;
            opt.end = 0;
            opt.data = null;
            opt.total = 0;
            opt.loaded = true;
          });
      })
      .catch((error) => {
        console.log(error);
        opt.error = error;
        opt.currentPage = 0;
        opt.start = 0;
        opt.end = 0;
        opt.data = null;
        opt.total = 0;
        opt.loaded = true;
      });
  };

  previous = (opt) => {
    if (opt.currentPage > 0) {
      opt.currentPage--;
      this.onFilter(opt, true);
    }
  };

  next = (opt) => {
    if (opt.currentPage < this.totalPages(opt) - 1) {
      opt.currentPage++;
      this.onFilter(opt, true);
    }
  };

  totalPages = (opt) => {
    return Math.ceil(opt.total / opt.perPage);
  };

  updateFab = (f) => {
    if (f === "C1C2") {
      // Cláusulas C1+C2 fab
      this.showClausulasC1C2Fab = true;
      this.showClausulasC3Fab = false;
      this.showClausulasC2_Fab = false;
      this.showCondominiosFab = false;
    } else if (f === "C3") {
      // Cláusulas C3 tab
      this.showClausulasC1C2Fab = false;
      this.showClausulasC3Fab = true;
      this.showClausulasC2_Fab = false;
      this.showCondominiosFab = false;
    } else if (f === "C2*") {
      // Cláusulas C2* tab
      this.showClausulasC1C2Fab = false;
      this.showClausulasC3Fab = false;
      this.showClausulasC2_Fab = true;
      this.showCondominiosFab = false;
    } else if (f === "Condominios") {
      // Condominios
      this.showClausulasC1C2Fab = false;
      this.showClausulasC3Fab = false;
      this.showClausulasC2_Fab = false;
      this.showCondominiosFab = true;
    } else if (f === "Responsável Técnico") {
      // Responsável Técnico
      this.showClausulasC1C2Fab = false;
      this.showClausulasC3Fab = false;
      this.showClausulasC2_Fab = false;
      this.showCondominiosFab = false;
    } else if (f === "Aprovação de Versões") {
      this.showClausulasC1C2Fab = false;
      this.showClausulasC3Fab = false;
      this.showClausulasC2_Fab = false;
      this.showCondominiosFab = false;
    } else if (f === "Sincronização") {
      this.showClausulasC1C2Fab = false;
      this.showClausulasC3Fab = false;
      this.showClausulasC2_Fab = false;
      this.showCondominiosFab = false;
    }
  };

  configLoading = () => this.condominiosLoading && this.sincronizacaoLoading;

  // Parse the XLSX file with clausulas
  parseClausulasFile = (type, file) => {
    return new Promise((resolve, reject) => {
      // Get valid regex for clausula triggering
      let clausulaRegex = /^C[1-2]$/i;
      switch (type) {
        case "C1C2":
          clausulaRegex = /^C[1-2]$/i;
          break;
        case "C3":
          clausulaRegex = /^C[3]$/i;
          break;
        case "C2*":
          clausulaRegex = /^C2\*$/i;
          break;
      }

      // Parse file from modal
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = e.target.result;
        let wb = XLSX.read(data, {
          type: "binary",
        });
        // Use the first worksheet only if exists
        if (wb.SheetNames.length > 0) {
          let rowObj = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
            header: 1,
          }); // Use all data from first sheet, including header

          // Remove bad lines
          _.remove(rowObj, (c) => {
            return !(
              c.length === 3 &&
              c[0] != null &&
              c[1] != null &&
              c[2] != null &&
              !!c[1].replace(/\s/g, '').match(clausulaRegex)
            );
          });

          resolve(rowObj);
        } else {
          reject(); // Should never happen
        }
      };
      reader.onerror = (e) => {
        reject(e);
      };
      // reader.readAsArrayBuffer(file);
      reader.readAsBinaryString(file);
    });
  };

  // Get tipoId from the string with 'C1', 'C2', 'C3', 'C2*'
  getTipoIdFromClausula = (clausulaString) => {
    switch (clausulaString) {
      case "C1":
      case "c1":
        return 1;
      case "C2":
      case "c2":
        return 2;
      case "C3":
      case "c3":
        return 3;
      case "C2*":
      case "c2*":
        return 4;
    }
  };

  getClausulaFromTipoId = (tipoId) => {
    switch (tipoId) {
      case 1:
        return "C1";
      case 2:
        return "C2";
      case 3:
        return "C3";
      case 4:
        return "C2*";
    }
  };

  // Returns object with differences between old and new clausulas
  getDifferencesClausulas = (type, clausulasFromFile) => {
    return new Promise((resolve, reject) => {
      let whereObject = {};
      switch (type) {
        case "C1C2":
          whereObject.and = [
            { or: [{ tipoId: 1 }, { tipoId: 2 }] },
            { active: 1 },
          ];
          break;
        case "C3":
          whereObject.tipoId = 3;
          whereObject.active = 1;
          break;
        case "C2*":
          whereObject.tipoId = 4;
          whereObject.active = 1;
          break;
      }
      // Get clausulas of type and compare them
      this.ElvClausula.find({
        filter: {
          where: whereObject,
        },
      })
        .$promise.then((clausulas) => {
          let newClausulas = [];
          let changedClausulas = [];
          let removedClausulas = [];
          let indexesFromFile = []; // Contains codigos of new clausulas to compare with old

          clausulasFromFile.forEach((clausula) => {
            indexesFromFile.push(clausula[0]);
            let foundClausula = clausulas.find((c) => c.codigo == clausula[0]); // == is necessary
            if (foundClausula) {
              // We found clausula, we need to check if it's an actual change
              if (
                foundClausula.descricao !== clausula[2] ||
                foundClausula.tipoId != this.getTipoIdFromClausula(clausula[1])
              ) {
                changedClausulas.push({
                  id: foundClausula.id,
                  codigo: clausula[0],
                  tipoId: this.getTipoIdFromClausula(clausula[1]),
                  descricao: clausula[2].trim(),
                  active: 1,
                });
              }
            } else {
              // If clausula was not found, we have a new clausula to add
              newClausulas.push({
                id: 0,
                codigo: clausula[0],
                tipoId: this.getTipoIdFromClausula(clausula[1]),
                descricao: clausula[2].trim(),
                active: 1,
              });
            }
          });

          clausulas.forEach((c) => {
            if (indexesFromFile.find((i) => i == c.codigo) === undefined) {
              // == is necessary
              c.active = 0;
              removedClausulas.push(c);
            }
          });

          resolve({
            new: newClausulas,
            changed: changedClausulas,
            removed: removedClausulas,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // Get proper text for clausulas type
  getClausulaTextFromType = (type) => {
    switch (type) {
      case "C1C2":
        return "Cláusulas C1+C2";
      case "C3":
        return "Cláusulas C3";
      case "C2*":
        return "Cláusulas C2*";
    }
    return "";
  };

  // Show modal with confirmation of changes to clausulas
  showConfirmationClausulasModal = async (type, diffs) => {
    let clausulasTitle = "Confirmação de Alterações - ";
    clausulasTitle += this.getClausulaTextFromType(type);
    let getClausulaFromTipoId = this.getClausulaFromTipoId;
    let options = {
      size: "lg",
      template: require("./clausulas.confirm.dialog.html"),
      controller: [
        "$scope",
        "$dialog",
        function ($scope, $dialog) {
          $scope.diffs = diffs;
          $scope.label = clausulasTitle;
          $scope.showNew = false;
          $scope.showChanged = false;
          $scope.showRemoved = false;

          $scope.getClausulaFromTipoId = getClausulaFromTipoId;

          // Called when clicking chevron
          $scope.expand = (x) => {
            switch (x) {
              case "new":
                if ($scope.showNew) {
                  $scope.showNew = !$scope.showNew;
                } else {
                  $scope.showNew = true;
                  $scope.showChanged = false;
                  $scope.showRemoved = false;
                }
                break;
              case "changed":
                if ($scope.showChanged) {
                  $scope.showChanged = !$scope.showChanged;
                } else {
                  $scope.showNew = false;
                  $scope.showChanged = true;
                  $scope.showRemoved = false;
                }
                break;
              case "removed":
                if ($scope.showRemoved) {
                  $scope.showRemoved = !$scope.showRemoved;
                } else {
                  $scope.showNew = false;
                  $scope.showChanged = false;
                  $scope.showRemoved = true;
                }
                break;
            }
          };

          $scope.ok = () => {
            $scope.$close(null);
          };

          $scope.cancel = () => {
            $scope.$dismiss("cancel");
          };
        },
      ],
    };

    let dialogConfirm = this.UI.showDialog(options);
    dialogConfirm.then(
      async (ok) => {
        // Do the changes to database
        let wait = this.UI.showWaiting();
        let newClausulasPromise = this.createClausulas(diffs.new);
        let changedClausulasPromise = this.changeOrRemoveClausulas(
          diffs.changed
        );
        let removedClausulasPromise = this.changeOrRemoveClausulas(
          diffs.removed
        );

        // Wait until all promises resolve or reject
        Promise.allSettled([
          newClausulasPromise,
          changedClausulasPromise,
          removedClausulasPromise,
        ])
          .then((counts) => {
            wait.close();
            let alertString = "";
            let countAdded = counts[0].value ? counts[0].value.count : 0;
            let errorAdded =
              !counts[0].status.includes("fulfilled") || counts[0].value.error;
            let countChanged = counts[1].value ? counts[1].value.count : 0;
            let errorChanged =
              !counts[1].status.includes("fulfilled") || counts[1].value.error;
            let countRemoved = counts[2].value ? counts[2].value.count : 0;
            let errorRemoved =
              !counts[2].status.includes("fulfilled") || counts[2].value.error;

            alertString += "Cláusulas adicionadas: " + countAdded + "\n";
            if (errorAdded)
              alertString +=
                "Erro detetado na criação de cláusulas. Tente novamente\n";
            alertString += "Cláusulas alteradas: " + countChanged + "\n";
            if (errorChanged)
              alertString +=
                "Erro detetado na alteração de cláusulas. Tente novamente\n";
            alertString += "Cláusulas removidas: " + countRemoved + "\n";
            if (errorRemoved)
              alertString +=
                "Erro detetado na remoção de cláusulas. Tente novamente\n";

            this.UI.showAlert(alertString);
            this.getClausulas(type);
            if (
              !errorAdded &&
              !errorChanged &&
              !errorRemoved &&
              countAdded + countChanged + countRemoved > 0
            )
              this.UI.addToast("Todas as alterações realizadas com sucesso");
          })
          .catch((err) => {
            console.log(err);
          });
      },
      (err) => {
        if (
          err !== "cancel" &&
          err !== "escape key press" &&
          err !== "backdrop click"
        )
          console.log(err);
      }
    );
  };

  // Create Clausulas
  createClausulas = async (newClausulas) => {
    let newC = 0;
    for (let i = 0; i < newClausulas.length; i++) {
      try {
        await this.ElvClausula.create(newClausulas[i]).$promise;
        newC += 1;
      } catch (e) {
        console.log(e);
        return { count: newC, error: e };
      }
    }
    return { count: newC, error: null };
  };

  // Change/Remove Clausulas
  changeOrRemoveClausulas = async (alteredClausulas) => {
    let altered = 0;
    for (let i = 0; i < alteredClausulas.length; i++) {
      try {

        await this.ElvClausula.prototype$updateAttributes(
          { id: alteredClausulas[i].id },
          {
            active: alteredClausulas[i].active,
            tipoId: alteredClausulas[i].tipoId,
            descricao: alteredClausulas[i].descricao
          }
        ).$promise;
        altered += 1;
      } catch (e) {
        console.log(e);
        return { count: altered, error: e };
      }
    }
    return { count: altered, error: null };
  };

  // Calls modal to upload XLSX and deals with the differences
  importClausulas = (type) => {
    let options = {
      size: "lg",
      controller: "ElvAdminFileUploaderController",
      controllerAs: "m",
      template: require("./files.dialog.html"),
      resolve: {
        type: () => {
          return type;
        },
      },
    };

    let dialog = this.UI.showDialog(options);
    dialog.then((res) => {
      if (res) {
        let wait = this.UI.showWaiting();
        this.parseClausulasFile(type, res.item._file)
          .then((clausulasFromFile) => {
            // If we have empty clausulasFromFile, deny going forward
            if (clausulasFromFile.length === 0) {
              wait.close();
              this.UI.showAlert(
                "O ficheiro fornecido não contém " +
                  this.getClausulaTextFromType(type) +
                  "."
              );
            } else {
              this.getDifferencesClausulas(type, clausulasFromFile)
                .then((diffs) => {
                  wait.close();

                  this.showConfirmationClausulasModal(type, diffs);
                })
                .catch((err) => {
                  console.log(err);
                  this.UI.addToast(
                    "Não foi possível atualizar cláusulas. Por favor recarregue a página"
                  );
                });
            }
          })
          .catch((err) => {
            wait.close();
            console.log(err);
            this.UI.showAlert(
              "Erro ao ler ficheiro de importação.\nVerifique se o ficheiro tem o formato apropriado."
            );
          });
      }
    });
  };

  // Condominios

  // For now get only the count. The list of condominios is on a view.
  getCondominios = () => {
    this.condominiosLoading = true;
    this.ElvCondominio.count({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((condominios) => {
      this.condominiosCount = condominios.count;
      this.condominiosLoading = false;
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível ler Nº de condomínios. Por favor tente mais tarde");
      this.condominiosLoading = false;
    });
  };

  // Parse the XLSX file with clausulas
  parseCondominiosFile = (type, file) => {
    return new Promise((resolve, reject) => {
      // Parse file from modal
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = e.target.result;
        let wb = XLSX.read(data, {
          type: "binary",
        });
        // Use the first worksheet only if exists
        if (wb.SheetNames.length > 0) {
          let rowObj = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
            header: 1,
          }); // Use all data from first sheet, including header

          // Remove empty lines
          _.remove(rowObj, (c) => c.length === 0);

          // Assume first line of data will be the title
          let title = rowObj.shift();

          // startIndexes will contain the first column index of each type
          let startIndexes = [];
          for (let i = 0; i < title.length; i++) {
            if (title[i] != null) startIndexes.push(i);
          }

          // Assume second line is the headers
          let headers = rowObj.shift();

          // Assume we have 2 types of data (elevadores, condominios)
          if (startIndexes.length === 2) {
            let elevadores = [];
            let condominios = [];
            let headerElevadores, headerCondominios;

            rowObj.forEach((r) => {
              elevadores.push(r.slice(startIndexes[0], startIndexes[1]));
              condominios.push(r.slice(startIndexes[1]));
            });
            headerElevadores = headers.slice(startIndexes[0], startIndexes[1]);
            headerCondominios = headers.slice(startIndexes[1]);

            // We need to make sure there's NIF header in Condominio (for later)
            if (
              headerCondominios.findIndex((x) => x.toUpperCase() === "NIF") < 0
            ) {
              reject();
            } else {
              // Resolves to:
              // Array of headers for Elevadores:
              // ['Nº de processo camarário','Cliente','Morada','Tipo de equipamento','EMIE','Condominio','Código Postal']
              // Array of headers for Condominios:
              // ['Nome','Nif','Contacto telefónico','Email','Morada','Código Postal']
              // Array of arrays for elevadores and condominios for headers
              resolve({
                headerElevadores: headerElevadores,
                headerCondominios: headerCondominios,
                elevadores: elevadores,
                condominios: condominios,
              });
            }
          } else {
            // Invalid file because there are not two columns of data
            reject();
          }
        } else {
          reject(); // Should never happen
        }
      };
      reader.onerror = (e) => {
        reject(e);
      };
      // reader.readAsArrayBuffer(file);
      reader.readAsBinaryString(file);
    });
  };

  // Creates auxCondominio object to check how it compares to the original or a brand new one
  // Since we are only comparing
  createAuxCondominio = (nifIndex, cond, id, observacoes) => {
    let codigoPostal = cond[5]
      .toString()
      .match(/^(\d{4})([ -]?\d{1,3})? ?(.*)?$/); // Codigo postal match
    if (codigoPostal && codigoPostal[2] && isNaN(codigoPostal[2][0]))
      codigoPostal[2] = codigoPostal[2].slice(1);

    // This should be improved by making this independent - Rushed for time
    let auxCondominio = {
      id: id,
      nome: cond[0],
      nif: Number(cond[nifIndex]),
      telemovel: !isNaN(cond[2]) ? Number(cond[2]) : null, // If telemovel changes to string, should change this too
      email: cond[3] || null,
      morada: cond[4] || null,
      observacoes: observacoes,
      active: 1,
    };
    if (codigoPostal) {
      auxCondominio.cp4 = !isNaN(codigoPostal[1])
        ? Number(codigoPostal[1])
        : null;
      auxCondominio.cp3 = !isNaN(codigoPostal[2])
        ? Number(codigoPostal[2])
        : null;
      auxCondominio.localidade = codigoPostal[3] || null;
    } else {
      auxCondominio.cp4 = null;
      auxCondominio.cp3 = null;
      auxCondominio.localidade = null;
    }
    return auxCondominio;
  };

  // Returns object with differences for changed or new condominios
  getDifferencesCondominios = (headerCondominios, condominiosFromFile) => {
    return new Promise((resolve, reject) => {
      // Find index of NIF - We can assume it's always there because we tested before
      let nifIndex = headerCondominios.findIndex(
        (x) => x.toUpperCase() === "NIF"
      );
      if (nifIndex < 0) {
        return reject();
      }

      // Go through the array and see if we have already have the NIF in the file. If so, add to an exclude array
      let usedNifs = [];
      let excludeCondominiosIndexes = [];

      condominiosFromFile.forEach((c, i) => {
        if (!c[nifIndex] || usedNifs.find((n) => n === c[nifIndex])) {
          // Doesn't exist or already added, exclude
          excludeCondominiosIndexes.push(i);
        } else {
          // Haven't found it yet, add it to the list and move on.
          usedNifs.push(c[nifIndex]);
        }
      });

      // Get the count for excluding condominios
      let countExcludedCondominios = excludeCondominiosIndexes.length;

      // Remove bad nifs or repeated nifs
      // Reverse to preserve indexes while removing
      excludeCondominiosIndexes.reverse();
      for (let i = 0; i < excludeCondominiosIndexes.length; i++) {
        condominiosFromFile.splice(excludeCondominiosIndexes[i], 1);
      }

      // We only need to care for Condominios with the exact NIF. If there's another NIF, means it's new Condominio
      let orObject = [];
      condominiosFromFile.forEach((c) => {
        if (c && !isNaN(c[nifIndex])) orObject.push({ nif: c[nifIndex] });
      });

      this.ElvCondominio.find({
        filter: {
          where: {
            and: [{ active: 1 }, { or: orObject }],
          },
        },
      })
        .$promise.then((condominios) => {
          // Detect which were changes made and which are new condominios

          let changes = []; // Contains condominios that have changed and require upsert()

          // Go through the ones that have been found with the same nif and compare
          condominios.forEach((c) => {
            let cond = condominiosFromFile.find((x) => x[nifIndex] === c.nif);
            if (cond) {
              // Should always find it, because we used find by nif

              let auxCondominio = this.createAuxCondominio(
                nifIndex,
                cond,
                c.id,
                c.observacoes
              );

              if (!angular.equals(c, auxCondominio)) {
                // Changes detected - Same type also checked
                changes.push(auxCondominio);
              }
            }
          });

          // Find which ones are new condominios
          let newCondominios = [];

          // Go through condominiosFromFile and create objects for those that don't have NIFs found
          condominiosFromFile.forEach((c) => {
            let cond = condominios.find((x) => x.nif == c[nifIndex]); // == is ok here
            if (!cond) {
              // Couldn't find NIF, add new
              let auxCondominio = this.createAuxCondominio(
                nifIndex,
                c,
                0,
                null
              );
              newCondominios.push(auxCondominio);
            }
          });

          resolve({
            new: newCondominios,
            changed: changes,
            excluded: countExcludedCondominios,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // Show modal with confirmation of changes to clausulas
  showConfirmationCondominiosModal = async (diffs) => {
    let condominiosTitle = "Confirmação de Alterações - Condomínios";
    let options = {
      size: "lg",
      template: require("./condominios.confirm.dialog.html"),
      controller: [
        "$scope",
        "$dialog",
        function ($scope, $dialog) {
          $scope.diffs = diffs;
          $scope.label = condominiosTitle;
          $scope.showNew = false;
          $scope.showChanged = false;
          if (diffs.excluded > 0) {
            // If we have exclusions, construct the message
            if (diffs.excluded === 1) {
              $scope.excludedMessage =
                "NOTA: Foi excluído da importação 1 condomínio com NIF repetido ou não preenchido.";
            } else {
              $scope.excludedMessage = `NOTA: Foram excluídos da importação ${diffs.excluded} condomínios com NIF repetido ou não preenchido.`;
            }
          } else {
            $scope.excludedMessage = null;
          }

          // Called when clicking chevron
          $scope.expand = (x) => {
            switch (x) {
              case "new":
                if ($scope.showNew) {
                  $scope.showNew = !$scope.showNew;
                } else {
                  $scope.showNew = true;
                  $scope.showChanged = false;
                  $scope.showRemoved = false;
                }
                break;
              case "changed":
                if ($scope.showChanged) {
                  $scope.showChanged = !$scope.showChanged;
                } else {
                  $scope.showNew = false;
                  $scope.showChanged = true;
                  $scope.showRemoved = false;
                }
                break;
            }
          };

          $scope.ok = () => {
            $scope.$close(null);
          };

          $scope.cancel = () => {
            $scope.$dismiss("cancel");
          };
        },
      ],
    };

    let dialogConfirm = this.UI.showDialog(options);
    dialogConfirm.then(
      async (ok) => {
        // Do the changes to database
        let wait = this.UI.showWaiting();
        let newCondominiosPromise = this.createCondominios(diffs.new);
        let changedCondominiosPromise = this.changeOrRemoveCondominios(
          diffs.changed
        );

        // Wait until all promises resolve or reject
        Promise.allSettled([newCondominiosPromise, changedCondominiosPromise])
          .then((counts) => {
            wait.close();
            let alertString = "";
            let countAdded = counts[0].value ? counts[0].value.count : 0;
            let errorAdded =
              !counts[0].status.includes("fulfilled") || counts[0].value.error;
            let countChanged = counts[1].value ? counts[1].value.count : 0;
            let errorChanged =
              !counts[1].status.includes("fulfilled") || counts[1].value.error;

            alertString += "Condomínios adicionados: " + countAdded + "\n";
            if (errorAdded)
              alertString +=
                "Erro detetado na criação de condomínios. Tente novamente\n";
            alertString += "Condomínios alterados: " + countChanged + "\n";
            if (errorChanged)
              alertString +=
                "Erro detetado na criação de condomínios. Tente novamente\n";

            this.UI.showAlert(alertString);
            this.getCondominios();
            if (!errorAdded && !errorChanged && countAdded + countChanged > 0)
              this.UI.addToast("Todas as alterações realizadas com sucesso");
          })
          .catch((err) => {
            console.log(err);
          });
      },
      (err) => {
        if (
          err !== "cancel" &&
          err !== "escape key press" &&
          err !== "backdrop click"
        )
          console.log(err);
      }
    );
  };

  // Create Condominios
  createCondominios = async (newCondominios) => {
    let newC = 0;
    for (let i = 0; i < newCondominios.length; i++) {
      try {
        await this.ElvCondominio.create(newCondominios[i]).$promise;
        newC = newC + 1;
      } catch (e) {
        console.log(e);
        return { count: newC, error: e };
      }
    }
    return { count: newC, error: null };
  };

  // Change/Remove Condominios
  changeOrRemoveCondominios = async (alteredCondominios) => {
    let altered = 0;
    for (let i = 0; i < alteredCondominios.length; i++) {
      try {
        await this.ElvCondominio.upsert(alteredCondominios[i]).$promise;
        altered = altered + 1;
      } catch (e) {
        console.log(e);
        return { count: altered, error: e };
      }
    }
    return { count: altered, error: null };
  };

  // Calls modal to upload XLSX and import condominios
  importCondominios = () => {
    let type = "Condominios";
    let options = {
      size: "lg",
      controller: "ElvAdminFileUploaderController",
      controllerAs: "m",
      template: require("./files.dialog.html"),
      resolve: {
        type: () => {
          return type;
        },
      },
    };

    let dialog = this.UI.showDialog(options);
    dialog.then((res) => {
      if (res) {
        let wait = this.UI.showWaiting();
        this.parseCondominiosFile(type, res.item._file)
          .then((dataFromFile) => {
            // If we have empty condominiosFromFile, deny going forward
            if (dataFromFile.condominios.length === 0) {
              wait.close();
              this.UI.showAlert("O ficheiro fornecido não contém condomínios.");
            } else {
              this.getDifferencesCondominios(
                dataFromFile.headerCondominios,
                dataFromFile.condominios
              )
                .then((diffs) => {
                  wait.close();

                  this.showConfirmationCondominiosModal(diffs);
                })
                .catch((err) => {
                  console.log(err);
                  this.UI.addToast(
                    "Não foi possível atualizar condomínios. Por favor recarregue a página"
                  );
                });
            }
          })
          .catch((err) => {
            wait.close();
            console.log(err);
            this.UI.showAlert(
              "Erro ao ler ficheiro de importação.\nVerifique se o ficheiro tem o formato apropriado."
            );
          });
      }
    });
  };
}

ElvAdminController.$inject = ["$q", "$http", "$filter", "$state", "FileUploader", "AuthenticationService", "UIService", "ElvClausula", "ElvCondominio", "CoreResponsavel", "CoreAprovacaoVersao", "CoreSincronizacao"];
