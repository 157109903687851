import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/system/Unstable_Grid';
import { Alert, Autocomplete, Box, Breadcrumbs, Card, CardContent, CardHeader, CircularProgress, Fab, InputAdornment, Link, ListItem, ListItemText, MenuItem, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { MdiIcon } from '@components';
import { mdiContentSave } from '@mdi/js';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
const showDate = (date, format = 'DD/MM/YYYY') => {
    if (moment(date).isValid()) {
        return moment(date).format(format);
    }
    else
        return '-';
};
const GasProcessoNewView = ({ $state, GasService, UIService }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    // TODO: Um dia vão querer isto desativado (fica a nota)
    const [agendar, setAgendar] = useState(true);
    const [cp, setCP] = useState(false);
    // Data for selects
    const [ords, setOrds] = useState([]);
    const [distribuidores, setDistribuidores] = useState([]);
    const [tiposGas, setTiposGas] = useState([]);
    const [tiposInstalacao, setTiposInstalacao] = useState([]);
    const [tiposInspecao, setTiposInspecao] = useState([]);
    const [tecnicos, setTecnicos] = useState([]);
    // Autocomplete settings
    const [options, setOptions] = useState([]);
    const [autoLoad, setAutoLoad] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(undefined);
    // Loading NIF
    const [nifLoading, setNifLoading] = useState(false);
    const fetchData = async () => {
        setIsLoading(true);
        // Fetch data for selects
        // ORDs
        let fetchedOrds;
        try {
            fetchedOrds = await GasService.getOrds();
        }
        catch (error) {
            console.log(error);
            UIService.addToast('Erro na procura de ORDs');
        }
        // Distribuidores
        let fetchedDistribuidores;
        try {
            fetchedDistribuidores = await GasService.getDistribuidores();
        }
        catch (error) {
            console.log(error);
            UIService.addToast('Erro na procura de Distribuidores');
        }
        // Tipos de Gás
        let fetchedTiposGas;
        try {
            fetchedTiposGas = await GasService.getTiposGas();
        }
        catch (error) {
            console.log(error);
            UIService.addToast('Erro na procura de Tipos de Gás');
        }
        // Tipos de Instalação
        let fetchedTiposInstalacao;
        try {
            fetchedTiposInstalacao = await GasService.getTiposInstalacao();
        }
        catch (error) {
            console.log(error);
            UIService.addToast('Erro na procura de Tipos de Instalação');
        }
        // Tipos de Inspeção
        let fetchedTiposInspecao;
        try {
            fetchedTiposInspecao = await GasService.getTiposInspecao();
        }
        catch (error) {
            console.log(error);
            UIService.addToast('Erro na procura de Tipos de Inspeção');
        }
        // Técnicos
        let fetchedTecnicos;
        try {
            fetchedTecnicos = await GasService.getTecnicos();
        }
        catch (error) {
            console.log(error);
        }
        setOrds(fetchedOrds);
        setDistribuidores(fetchedDistribuidores);
        setTiposGas(fetchedTiposGas);
        setTiposInstalacao(fetchedTiposInstalacao);
        setTiposInspecao(fetchedTiposInspecao);
        setTecnicos(fetchedTecnicos);
        if (fetchedOrds &&
            fetchedOrds.length > 0 &&
            fetchedDistribuidores &&
            fetchedDistribuidores.length > 0 &&
            fetchedTiposGas &&
            fetchedTiposGas.length > 0 &&
            fetchedTiposInstalacao &&
            fetchedTiposInstalacao.length > 0 &&
            fetchedTiposInspecao &&
            fetchedTiposInspecao.length > 0 &&
            fetchedTecnicos &&
            fetchedTecnicos.length > 0) {
            setIsLoading(false);
        }
        else {
            // No point in continue, reroute to lista de processos
            UIService.addToast('De momento não é possível criar novo processo manualmente');
            $state.go('app.gas.processos.list');
        }
    };
    const validationSchema = yup.object({
        dataAgendamento: yup.date().when([], schema => {
            return agendar ? yup.date().required('O campo é obrigatório') : yup.date().nullable();
        }),
        agendamento: yup.object({
            duracao: yup.number().when([], schema => {
                return agendar ? yup.number().integer('Duração tem que ser um número inteiro').positive('Duração não pode ser negativa').required('O campo é obrigatório') : yup.number().nullable();
            }),
            tecnicoId: yup.number().when([], schema => {
                return agendar
                    ? yup
                        .number()
                        .oneOf(tecnicos.map(x => x.funcionario?.id), 'Preencha um técnico válido')
                        .required('O campo é obrigatório')
                    : yup.number().nullable();
            })
        }),
        processo: yup.object({
            tipoGasId: yup
                .number()
                .nullable()
                .oneOf(ords.map(x => x.id), 'Tipo de Gás inválido'),
            tipoInstalacaoId: yup
                .number()
                .nullable()
                .oneOf(tiposInstalacao.map(x => x.id), 'Tipo de Instalação inválido'),
            tipoInspecaoId: yup
                .number()
                .nullable()
                .oneOf(tiposInspecao.map(x => x.id), 'Tipo de Inspeção inválido'),
            distribuidorId: yup
                .number()
                .nullable()
                .oneOf(distribuidores.map(x => x.id), 'Distribuidor inválido'),
            ordId: yup
                .number()
                .nullable()
                .oneOf(ords.map(x => x.id), 'ORD inválido'),
            total: yup
                .number()
                .typeError('Total tem de ser um número')
                .test('is-decimal', 'Total tem de ter no máximo duas casas decimais', value => (Number(value.toFixed(2)) - value === 0))
                .required('O campo é obrigatório'),
            cui: yup
                .string()
                .nullable()
                .matches(/^[A-Za-z]{2}\d{16}[A-Za-z]{2}$/, 'CUI Inválido'),
            nomeCliente: yup.string().required('O campo é obrigatório').max(255, 'Limite de caracteres ultrapassado'),
            nifCliente: yup
                .string()
                .required('O campo é obrigatório')
                .matches(/^(1|2|3|5|6|8|9)\d{8}$/, 'NIF inválido'),
            emailCliente: yup
                .string()
                .max(255, 'Limite de caracteres ultrapassado')
                .email('Email inválido')
                .nullable()
                .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email inválido'),
            contactoCliente: yup
                .string()
                .nullable()
                .matches(/^(?:\+351)?\s?9[1236]\d{7}$|^(?:\+351)?\s?2\d{8}$/, 'Contacto inválido'),
            moradaCliente: yup.string().required('O campo é obrigatório').max(255, 'Limite de caracteres ultrapassado'),
            localidadeCliente: yup.string().required('O campo é obrigatório').max(255, 'Limite de caracteres ultrapassado'),
            cep: yup
                .string()
                .required('O campo é obrigatório')
                .matches(/^\d{4}-\d{3}$/, 'Código Postal deve estar no formato XXXX-YYY'),
            concelhoCliente: yup.string().required('O campo é obrigatório').max(255, 'Limite de caracteres ultrapassado'),
            distritoCliente: yup.string().required('O campo é obrigatório').max(255, 'Limite de caracteres ultrapassado')
        })
    });
    const formik = useFormik({
        initialValues: {
            processo: {
                distribuidorPresente: 0
            }
        },
        validationSchema: validationSchema,
        validateOnMount: false,
        validateOnChange: true,
        onSubmit: async (values) => {
            // Put values back to agendamento correctly
            if (agendar && moment(values.dataAgendamento).isValid()) {
                values.agendamento.data = values.dataAgendamento.format('YYYY-MM-DD');
                values.agendamento.hora = values.dataAgendamento.format('HH:mm:00');
            }
            // Value used to simplify date calculations
            let wait = UIService.showWaiting();
            GasService.createProcesso(values)
                .then(result => {
                wait.close();
                if (result.result !== 1) {
                    let alert = UIService.showAlert(GasService.explainStat(result.result, result.extra));
                }
                else {
                    UIService.addToast('Processo criado com sucesso');
                    $state.go('app.gas.processos.list');
                }
            })
                .catch(error => {
                console.log(error);
                wait.close();
            });
        }
    });
    useEffect(() => {
        if (!isLoading)
            fetchData();
    }, []);
    const handleCEPChange = async (event) => {
        formik.setFieldValue('processo.cep', event.target.value, true);
        const regex = /^\d{4}-\d{3}$/;
        setCP(true);
        if (event.target.value.length === 8 && regex.test(event.target.value)) {
            try {
                let cps = event.target.value.split('-');
                let cep = await GasService.getInfoCodigoPostal(cps[0], cps[1]);
                if (cep) {
                    formik.setFieldValue('processo.localidadeCliente', cep.result, true);
                    formik.setFieldValue('processo.concelhoCliente', cep.concelho, true);
                    formik.setFieldValue('processo.distritoCliente', cep.distrito, true);
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        setCP(false);
    };
    const handleNifChange = async (event) => {
        let cliente;
        // Only check if NIF has at least 9 chars defined
        if (event.target.value.length >= 9) {
            setNifLoading(true);
            try {
                cliente = await GasService.getGasClienteFinalForNif(event.target.value);
            }
            catch (error) {
                console.log(error);
            }
            if (cliente && cliente.nif) {
                formik.handleChange(event);
                await formik.setFieldValue('processo.emailCliente', cliente.email, true);
                await formik.setFieldValue('processo.contactoCliente', cliente.contacto, true);
                await formik.setFieldValue('processo.nomeCliente', cliente.nome, true);
                await formik.setFieldValue('processo.moradaCliente', cliente.morada, true);
                await formik.setFieldValue('processo.cep', cliente.cep, true);
                await formik.setFieldValue('processo.localidadeCliente', cliente.localidade, true);
                await formik.setFieldValue('processo.concelhoCliente', cliente.concelho, true);
                await formik.setFieldValue('processo.distritoCliente', cliente.distrito, true);
                await formik.setFieldTouched('processo.contactoCliente', true);
                await formik.setFieldTouched('processo.emailCliente', true);
                await formik.setFieldTouched('processo.nomeCliente', true);
                await formik.setFieldTouched('processo.moradaCliente', true);
                await formik.setFieldTouched('processo.cep', true);
                await formik.setFieldTouched('processo.localidadeCliente', true);
                await formik.setFieldTouched('processo.concelhoCliente', true);
                await formik.setFieldTouched('processo.distritoCliente', true);
            }
            else {
                // Just handle the change without anything else
                formik.handleChange(event);
            }
            setNifLoading(false);
        }
        else {
            // Just handle the change without anything else
            formik.handleChange(event);
        }
    };
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>Adicionar Processo</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Gás
          </Link>
          <Link underline='hover' onClick={() => $state.go('app.gas.processos.list')} color='text.primary' aria-current='page'>
            Processos
          </Link>
          <Link underline='none' color='inherit'>
            Novo
          </Link>
        </Breadcrumbs>
      </Box>
      {isLoading ? (<Box sx={{ display: 'flex', justifyContent: 'center', height: '2vh' }}>
          <CircularProgress size={60} color='primary'/>
        </Box>) : (<form>
          <Alert variant='filled' severity='info'>
            Todos os campos marcados com <b>*</b> são de preenchimento obrigatório
          </Alert>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <Card>
                <CardHeader title='Detalhes'/>
                <CardContent>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid lg={4} md={4} xs={12}>
                      <TextField label='Tipo de Gás' name='processo.tipoGasId' value={formik.values?.processo?.tipoGasId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.tipoGasId && Boolean(formik.errors.processo?.tipoGasId)} helperText={formik.touched.processo?.tipoGasId && formik.errors.processo?.tipoGasId} select>
                        <MenuItem value={undefined} disabled></MenuItem>
                        {tiposGas &&
                tiposGas.map(x => (<MenuItem value={x.id} key={x.id}>
                              {x.designacao}
                            </MenuItem>))}
                      </TextField>
                    </Grid>
                    <Grid lg={4} md={4} xs={12}>
                      <TextField label='Tipo de Instalação' name='processo.tipoInstalacaoId' value={formik.values?.processo?.tipoInstalacaoId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.tipoInstalacaoId && Boolean(formik.errors.processo?.tipoInstalacaoId)} helperText={formik.touched.processo?.tipoInstalacaoId && formik.errors.processo?.tipoInstalacaoId} select>
                        <MenuItem value={undefined} disabled></MenuItem>
                        {tiposInstalacao &&
                tiposInstalacao.map(x => (<MenuItem value={x.id} key={x.id}>
                              {x.designacao}
                            </MenuItem>))}
                      </TextField>
                    </Grid>
                    <Grid lg={4} md={4} xs={12}>
                      <TextField label='Tipo de Inspeção' name='processo.tipoInspecaoId' value={formik.values?.processo?.tipoInspecaoId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.tipoInspecaoId && Boolean(formik.errors.processo?.tipoInspecaoId)} helperText={formik.touched.processo?.tipoInspecaoId && formik.errors.processo?.tipoInspecaoId} select>
                        <MenuItem value={undefined} disabled></MenuItem>
                        {tiposInspecao &&
                tiposInspecao.map(x => (<MenuItem value={x.id} key={x.id}>
                              {x.designacao}
                            </MenuItem>))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid lg={3} md={4} xs={12}>
                      <TextField label='CUI' name='processo.cui' defaultValue={formik.values?.processo?.cui} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.cui && Boolean(formik.errors.processo?.cui)} helperText={formik.touched.processo?.cui && formik.errors.processo?.cui}/>
                    </Grid>
                    <Grid lg={3} md={4} xs={12}>
                      <TextField label='ORD' name='processo.ordId' value={formik.values?.processo?.ordId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.ordId && Boolean(formik.errors.processo?.ordId)} helperText={formik.touched.processo?.ordId && formik.errors.processo?.ordId} select>
                        <MenuItem value={undefined} disabled></MenuItem>
                        {ords &&
                ords.map(x => (<MenuItem value={x.id} key={x.id}>
                              {x.designacao}
                            </MenuItem>))}
                      </TextField>
                    </Grid>
                    <Grid lg={3} md={4} xs={12}>
                      <TextField label='Distribuidor' name='processo.distribuidorId' value={formik.values?.processo?.distribuidorId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.distribuidorId && Boolean(formik.errors.processo?.distribuidorId)} helperText={formik.touched.processo?.distribuidorId && formik.errors.processo?.distribuidorId} select>
                        <MenuItem value={undefined} disabled></MenuItem>
                        {distribuidores &&
                distribuidores.map(x => (<MenuItem value={x.id} key={x.id}>
                              {x.designacao}
                            </MenuItem>))}
                      </TextField>
                    </Grid>
                    <Grid lg={3} md={4} xs={12}>
                      <TextField label='Distribuidor Presente?' name='processo.distribuidorPresente' value={formik.values?.processo?.distribuidorPresente} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.distribuidorPresente && Boolean(formik.errors.processo?.distribuidorPresente)} helperText={formik.touched.processo?.distribuidorPresente && formik.errors.processo?.distribuidorPresente} select>
                        <MenuItem value={0}>Não</MenuItem>
                        <MenuItem value={1}>Sim</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid lg={2} md={2} xs={12}>
                      <TextField label='Número Parceiro' name='processo.numeroParceiro' value={formik.values?.processo?.numeroParceiro} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.numeroParceiro && Boolean(formik.errors.processo?.numeroParceiro)} helperText={formik.touched.processo?.numeroParceiro && formik.errors.processo?.numeroParceiro}/>
                    </Grid>
                    <Grid lg={2} md={2} xs={12}>
                      <TextField label='Total' name='processo.total' type='number' required defaultValue={formik.values?.processo?.total} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.total && Boolean(formik.errors.processo?.total)} helperText={formik.touched.processo?.total && formik.errors.processo?.total} InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}/>
                    </Grid>
                    <Grid lg={8} md={8} xs={12}>
                      <TextField label='Observações' name='processo.observacoes' defaultValue={formik.values?.processo?.observacoes} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.observacoes && Boolean(formik.errors.processo?.observacoes)} helperText={formik.touched.processo?.observacoes && formik.errors.processo?.observacoes}/>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid lg={12} md={12} xs={12}>
                      <TextField label='Morada' name='processo.moradaCliente' value={formik.values?.processo?.moradaCliente ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth required error={formik.touched.processo?.moradaCliente && Boolean(formik.errors.processo?.moradaCliente)} helperText={formik.touched.processo?.moradaCliente && formik.errors.processo?.moradaCliente} disabled={nifLoading} InputProps={{
                endAdornment: <React.Fragment>{nifLoading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid lg={3} md={2} xs={12}>
                      <TextField label='Código Postal' name='processo.cep' value={formik.values?.processo?.cep} onChange={handleCEPChange} onBlur={formik.handleBlur} fullWidth required error={formik.touched.processo?.cep && Boolean(formik.errors.processo?.cep)} helperText={formik.touched.processo?.cep && formik.errors.processo?.cep} disabled={nifLoading} InputProps={{
                endAdornment: <React.Fragment>{nifLoading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
                    </Grid>
                    <Grid lg={3} md={4} xs={12}>
                      <TextField label='Localidade' name='processo.localidadeCliente' value={formik.values?.processo?.localidadeCliente ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth required error={formik.touched.processo?.localidadeCliente && Boolean(formik.errors.processo?.localidadeCliente)} helperText={formik.touched.processo?.localidadeCliente && formik.errors.processo?.localidadeCliente} disabled={nifLoading || cp} InputProps={{
                endAdornment: <React.Fragment>{nifLoading || cp ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
                    </Grid>
                    <Grid lg={3} md={3} xs={12}>
                      <TextField label='Concelho' name='processo.concelhoCliente' value={formik.values?.processo?.concelhoCliente ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth required error={formik.touched.processo?.concelhoCliente && Boolean(formik.errors.processo?.concelhoCliente)} helperText={formik.touched.processo?.concelhoCliente && formik.errors.processo?.concelhoCliente} disabled={nifLoading || cp} InputProps={{
                endAdornment: <React.Fragment>{nifLoading || cp ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
                    </Grid>
                    <Grid lg={3} md={3} xs={12}>
                      <TextField label='Distrito' name='processo.distritoCliente' value={formik.values?.processo?.distritoCliente ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth required error={formik.touched.processo?.distritoCliente && Boolean(formik.errors.processo?.distritoCliente)} helperText={formik.touched.processo?.distritoCliente && formik.errors.processo?.distritoCliente} disabled={nifLoading || cp} InputProps={{
                endAdornment: <React.Fragment>{nifLoading || cp ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid lg={12} md={12} xs={12}>
              <Card>
                <CardHeader title='Agendamento'/>
                {false && (<CardHeader title='Agendamento' action={<Switch onChange={() => {
                        if (agendar) {
                            formik.setFieldValue('dataAgendamento', null);
                            formik.setFieldValue('agendamento.duracao', null);
                            formik.setFieldValue('agendamento.tecnicoId', null);
                            formik.setFieldTouched('dataAgendamento', false);
                            formik.setFieldTouched('agendamento.duracao', false);
                            formik.setFieldTouched('agendamento.tecnicoId', false);
                            formik.setFieldError('dataAgendamento', undefined);
                            formik.setFieldError('agendamento.duracao', undefined);
                            formik.setFieldError('agendamento.tecnicoId', undefined);
                        }
                        setAgendar(!agendar);
                    }}></Switch>}/>)}
                {agendar && (<CardContent>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid lg={4} md={3} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateTimePicker label='Data/Hora' format='DD/MM/YYYY HH:mm' value={formik.values?.dataAgendamento} minDate={moment().startOf('day')} onChange={value => {
                    // Fix helperText not showing cause component was not marked as touched!
                    formik.setFieldValue('dataAgendamento', value, true);
                }} slotProps={{
                    textField: {
                        required: agendar,
                        variant: 'outlined',
                        id: 'dataAgendamento',
                        name: 'dataAgendamento',
                        onBlur: formik.handleBlur,
                        error: formik.touched.dataAgendamento && Boolean(formik.errors.dataAgendamento),
                        fullWidth: true,
                        helperText: formik.touched.dataAgendamento && Boolean(formik.errors.dataAgendamento) && String(formik.errors.dataAgendamento) // Use boolean cause string([]) = "undefined"
                    }
                }}/>
                        </LocalizationProvider>
                      </Grid>
                      <Grid lg={3} md={2} xs={12}>
                        <TextField label='Duração' name='agendamento.duracao' type='number' required={agendar} defaultValue={formik.values?.agendamento?.duracao} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.agendamento?.duracao && Boolean(formik.errors.agendamento?.duracao)} helperText={formik.touched.agendamento?.duracao && formik.errors.agendamento?.duracao} InputProps={{ endAdornment: <InputAdornment position='end'>min</InputAdornment> }}/>
                      </Grid>
                      <Grid lg={5} md={5} xs={12}>
                        <Autocomplete disablePortal options={tecnicos.sort((a, b) => a.funcionario.name.localeCompare(b.funcionario.name))} getOptionLabel={option => option.funcionario.name} value={selectedValue} // O valor completo da opção selecionada
             onChange={(event, newValue) => {
                    if (newValue == null) {
                        formik.setFieldValue('agendamento.tecnicoId', null, true);
                        setSelectedValue(undefined);
                    }
                    else {
                        formik.setFieldValue('agendamento.tecnicoId', newValue.funcionarioId, true);
                        setSelectedValue(newValue);
                    }
                    formik.setFieldTouched('agendamento.tecnicoId', true, true);
                }} autoHighlight renderOption={(props, option) => (<ListItem {...props} key={option.funcionario.id} dense>
                              <ListItemText primary={option.funcionario.name}/>
                            </ListItem>)} renderInput={params => (<TextField {...params} name='agendamento.tecnicoId' label='Técnico' fullWidth onBlur={formik.handleBlur} error={formik.touched.agendamento?.tecnicoId && Boolean(formik.errors.agendamento?.tecnicoId)} helperText={formik.touched.agendamento?.tecnicoId && formik.errors.agendamento?.tecnicoId} InputProps={{
                        ...params.InputProps,
                        endAdornment: (<>
                                    {autoLoad ? <CircularProgress color='inherit' size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                  </>)
                    }}/>)}/>
                      </Grid>
                    </Grid>
                  </CardContent>)}
              </Card>
            </Grid>
            <Grid lg={12} md={12} xs={12}>
              <Card>
                <CardHeader title='Cliente'/>
                <CardContent>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid lg={8} md={8} xs={12}>
                      <TextField label='Nome' name='processo.nomeCliente' value={formik.values?.processo?.nomeCliente ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth required error={formik.touched.processo?.nomeCliente && Boolean(formik.errors.processo?.nomeCliente)} helperText={formik.touched.processo?.nomeCliente && formik.errors.processo?.nomeCliente} disabled={nifLoading} InputProps={{
                endAdornment: <React.Fragment>{nifLoading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
                    </Grid>
                    <Grid lg={4} md={4} xs={12}>
                      <TextField label='NIF' name='processo.nifCliente' defaultValue={formik.values?.processo?.nifCliente} required onChange={handleNifChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.nifCliente && Boolean(formik.errors.processo?.nifCliente)} helperText={formik.touched.processo?.nifCliente && formik.errors.processo?.nifCliente}/>
                    </Grid>
                    <Grid lg={6} md={6} xs={12}>
                      <TextField label='Contacto' name='processo.contactoCliente' defaultValue={formik.values?.processo?.contactoCliente} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.contactoCliente && Boolean(formik.errors.processo?.contactoCliente)} helperText={formik.touched.processo?.contactoCliente && formik.errors.processo?.contactoCliente}/>
                    </Grid>
                    <Grid lg={6} md={6} xs={12}>
                      <TextField label='Email' name='processo.emailCliente' value={formik.values?.processo?.emailCliente ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.processo?.emailCliente && Boolean(formik.errors.processo?.emailCliente)} helperText={formik.touched.processo?.emailCliente && formik.errors.processo?.emailCliente} disabled={nifLoading} InputProps={{
                endAdornment: <React.Fragment>{nifLoading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
            }}/>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Box sx={{
                position: 'fixed',
                margin: 2,
                bottom: 0,
                right: 0,
                display: 'flex',
                gap: 1
            }}>
            <Tooltip title='Adicionar'>
              <Fab color='primary' disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} onClick={() => {
                formik.handleSubmit();
            }}>
                <MdiIcon path={mdiContentSave}/>
              </Fab>
            </Tooltip>
          </Box>
        </form>)}
    </Box>);
};
export default GasProcessoNewView;
