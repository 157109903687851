import { getMimeTypeFromExtension } from '../../../../utils';
import angular from 'angular';
export default class ListaRelatorioService {
    $q;
    $http;
    CoreListaRelatorio;
    CoreListaRelatorioParametro;
    CoreListaRelatorioParametroPonto;
    CoreListaRelatorioEnsaio;
    CoreListaRelatorioEquipamento;
    CoreListaRelatorioFicheiro;
    CoreListaRelatorioResultado;
    CoreListaRelatorioResposta;
    CoreListaRelatorioValidacao;
    CoreListaRelatorioDefeitoTipo;
    CoreListaRelatorioEnsaioPonto;
    CoreListaRelatorioMotivo;
    CoreDepartamento;
    constructor($q, $http, CoreListaRelatorio, CoreListaRelatorioParametro, CoreListaRelatorioParametroPonto, CoreListaRelatorioEnsaio, CoreListaRelatorioEquipamento, CoreListaRelatorioFicheiro, CoreListaRelatorioResultado, CoreListaRelatorioResposta, CoreListaRelatorioValidacao, CoreListaRelatorioDefeitoTipo, CoreListaRelatorioEnsaioPonto, CoreListaRelatorioMotivo, CoreDepartamento) {
        this.$q = $q;
        this.$http = $http;
        this.CoreListaRelatorio = CoreListaRelatorio;
        this.CoreListaRelatorioParametro = CoreListaRelatorioParametro;
        this.CoreListaRelatorioParametroPonto = CoreListaRelatorioParametroPonto;
        this.CoreListaRelatorioEnsaio = CoreListaRelatorioEnsaio;
        this.CoreListaRelatorioEquipamento = CoreListaRelatorioEquipamento;
        this.CoreListaRelatorioFicheiro = CoreListaRelatorioFicheiro;
        this.CoreListaRelatorioResultado = CoreListaRelatorioResultado;
        this.CoreListaRelatorioResposta = CoreListaRelatorioResposta;
        this.CoreListaRelatorioValidacao = CoreListaRelatorioValidacao;
        this.CoreListaRelatorioDefeitoTipo = CoreListaRelatorioDefeitoTipo;
        this.CoreListaRelatorioEnsaioPonto = CoreListaRelatorioEnsaioPonto;
        this.CoreListaRelatorioMotivo = CoreListaRelatorioMotivo;
        this.CoreDepartamento = CoreDepartamento;
    }
    getFileDetails(main, folder, path) {
        return this.$http.get(`/api/${main}/${folder}/files/${path}`);
    }
    getType(path) {
        return getMimeTypeFromExtension(path);
    }
    uploadTemplate(main, folder, file) {
        const formData = new FormData();
        formData.append('file', file);
        return this.$http.post(`/api/${main}/${folder}/upload`, formData, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
        });
    }
    postFicheiro(data) {
        return this.CoreListaRelatorioFicheiro.create(data).$promise;
    }
    patchFicheiro(id, data) {
        return this.CoreListaRelatorioFicheiro.patch({ id: id }, data).$promise;
    }
    postValidacao(data) {
        return this.CoreListaRelatorioValidacao.create(data).$promise;
    }
    patchValidacao(id, data) {
        return this.CoreListaRelatorioValidacao.patch({ id: id }, data).$promise;
    }
    getUploadFolder(departamentoId) {
        let defer = this.$q.defer();
        this.CoreDepartamento.findById({ id: departamentoId })
            .$promise.then(r => {
            defer.resolve(r.designacao
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, ''));
        })
            .catch(e => defer.reject(e));
        return defer.promise;
    }
    findDepartamentos() {
        return this.CoreDepartamento.find({
            filter: {
                where: {
                    active: 1
                }
            }
        }).$promise;
    }
    findAll(query) {
        return this.CoreListaRelatorio.table(query).$promise;
    }
    options() {
        return this.CoreListaRelatorio.options().$promise;
    }
    patch(id, data) {
        return this.CoreListaRelatorio.patch({ id }, data).$promise;
    }
    publish(id) {
        return this.CoreListaRelatorio.publish({ id }).$promise;
    }
    findOne(id) {
        return this.CoreListaRelatorio.findOne({
            filter: {
                where: {
                    id
                },
                include: ['Funcionario', 'Departamento', 'Parametros', 'Ensaios', 'Equipamentos', 'Norma', 'Ficheiros', 'Validacoes']
            }
        }).$promise;
    }
    findResultados(departamento) {
        return this.CoreListaRelatorioResultado.find({
            filter: {
                where: {
                    coreDepartamentoId: departamento
                }
            }
        }).$promise;
    }
    findParametro(id) {
        return this.CoreListaRelatorioParametro.findOne({
            filter: {
                where: {
                    id
                },
                include: ['Pontos', 'CoreListaRelatorio']
            }
        }).$promise;
    }
    findEnsaio(id) {
        return this.CoreListaRelatorioEnsaio.findOne({
            filter: {
                where: {
                    id
                },
                include: ['Pontos']
            }
        }).$promise;
    }
    findEquipamento(id) {
        return this.CoreListaRelatorioEquipamento.findOne({
            filter: {
                where: {
                    id
                },
                include: ['Pontos']
            }
        }).$promise;
    }
    findPontosEnsaioOfList(id) {
        let defer = this.$q.defer();
        this.CoreListaRelatorioEnsaio.find({
            filter: {
                fields: {
                    id: true,
                    coreListaRelatorioId: true
                },
                where: {
                    coreListaRelatorioId: id
                }
            }
        })
            .$promise.then(r => {
            this.CoreListaRelatorioEnsaioPonto.find({
                filter: {
                    where: {
                        coreListaRelatorioEnsaioId: {
                            inq: r.map(i => i.id)
                        }
                    }
                }
            })
                .$promise.then(_ => {
                defer.resolve(_);
            })
                .catch(e => defer.reject(e));
        })
            .catch(e => defer.reject(e));
        return defer.promise;
    }
    findDefeitosByTipo(coreDepartamentoId) {
        return this.CoreListaRelatorioDefeitoTipo.find({
            filter: {
                where: {
                    coreDepartamentoId
                },
                include: {
                    relation: 'Defeitos',
                    scope: {
                        where: {
                            active: true
                        }
                    }
                }
            }
        }).$promise;
    }
    getMotivos() {
        // adicionar se user for admin vai buscar todos, se não for vai buscar com o campo admin = 0
        return this.CoreListaRelatorioMotivo.find({
            filter: {
                where: {
                    active: 1
                }
            }
        }).$promise;
    }
    getCoreListaRelatorioResposta(id) {
        return this.CoreListaRelatorioResposta.findOne({
            filter: {
                where: {
                    id
                },
                include: [
                    {
                        relation: 'Ficheiros',
                        scope: {
                            where: {
                                active: 1
                            },
                            include: 'RelatorioFicheiro'
                        }
                    },
                    {
                        relation: 'CoreListaRelatorio',
                        scope: {
                            include: [
                                'Funcionario',
                                'Departamento',
                                {
                                    relation: 'Parametros',
                                    scope: {
                                        where: {
                                            active: 1
                                        },
                                        order: 'ordem ASC',
                                        include: {
                                            relation: 'Pontos',
                                            scope: {
                                                where: {
                                                    active: 1
                                                },
                                                order: 'ordem ASC'
                                            }
                                        }
                                    }
                                },
                                {
                                    relation: 'Ensaios',
                                    scope: {
                                        where: {
                                            active: 1
                                        },
                                        order: 'ordem ASC',
                                        include: {
                                            relation: 'Pontos',
                                            scope: {
                                                where: {
                                                    active: 1
                                                },
                                                order: 'ordem ASC'
                                            }
                                        }
                                    }
                                },
                                {
                                    relation: 'Equipamentos',
                                    scope: {
                                        where: {
                                            active: 1
                                        },
                                        order: 'ordem ASC',
                                        include: {
                                            relation: 'Pontos',
                                            scope: {
                                                where: {
                                                    active: 1
                                                },
                                                order: 'ordem ASC'
                                            }
                                        }
                                    }
                                },
                                'Norma'
                            ]
                        }
                    },
                    'CoreListaRelatorioResultado',
                    'ValidadoPor',
                    'Tecnico'
                ]
            }
        }).$promise;
    }
    calcularResposta(id) {
        // structureData
        return this.CoreListaRelatorioResposta.complete({ id: id });
    }
    saveDataSubmissao(data, dataSubmissao) {
        data.dataSubmissao = dataSubmissao;
        return this.CoreListaRelatorioResposta.upsert(data).$promise;
    }
    saveResposta(resposta) {
        return this.CoreListaRelatorioResposta.patch(resposta).$promise;
    }
    validateResposta(id) {
        return this.CoreListaRelatorioResposta.validate({
            id
        }).$promise;
    }
    cloneResposta(resposta) {
        return this.CoreListaRelatorioResposta.cloneResposta({
            params: {
                resposta: resposta
            }
        }).$promise;
    }
    saveParametroPonto(data) {
        return this.CoreListaRelatorioParametroPonto.upsert(data).$promise;
    }
}
ListaRelatorioService.$inject = [
    '$q',
    '$http',
    'CoreListaRelatorio',
    'CoreListaRelatorioParametro',
    'CoreListaRelatorioParametroPonto',
    'CoreListaRelatorioEnsaio',
    'CoreListaRelatorioEquipamento',
    'CoreListaRelatorioFicheiro',
    'CoreListaRelatorioResultado',
    'CoreListaRelatorioResposta',
    'CoreListaRelatorioValidacao',
    'CoreListaRelatorioDefeitoTipo',
    'CoreListaRelatorioEnsaioPonto',
    'CoreListaRelatorioMotivo',
    'CoreDepartamento'
];
