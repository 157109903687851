export default class DirectoryPedidosElevadoresController {
  constructor($state, ElvProcesso, ElvEstado, ElvCliente, ElvEmie, ElvCondominio, ElvProcessoArtigo, ElvNorma, Group, UIService, ElvProcessoService) {
    this.$state = $state;
    this.ElvProcesso = ElvProcesso;
    this.ElvEstado = ElvEstado;
    this.ElvCliente = ElvCliente;
    this.ElvEmie = ElvEmie;
    this.ElvCondominio = ElvCondominio;
    this.ElvProcessoArtigo = ElvProcessoArtigo;
    this.ElvNorma = ElvNorma;
    this.Group = Group;
    this.UI = UIService;
    this.ElvProcessoService = ElvProcessoService;

    this.dataLoaded = false;

    this.resultados = [
      { name: "Aprovado (com C3/C2*)", value: 6},
      { name: "Aprovado (com C2*)", value: 5},
      { name: "Reprovado", value: 3},
      { name: "Aprovado (com C3)", value: 2},
      { name: "Aprovado", value: 1},
      { name: "Aguarda submissão", value: 0}
    ];
    this.faturasPedidas = [{name: "Não", value: 0}, {name: "Sim", value: 1}, {name: "N/A", value: -1}];
    this.estados = [];
    this.localidades = [];
    this.emies = [];
    this.clientes = [];
    this.condominios = [];
    this.inspetores = [];
    this.normas = [];
    this.filtersLoaded = [1, 1, 0, 0, 0, 0, 0, 0, 0]; // Resultados already loaded

    this.opt = $state.params;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.displayColumns = [{
      displayName: 'ID SGI',
      name: 'id',
      visible: false,
      sortable: true
    },
    {
      displayName: 'Nº Processo',
      name: 'numProcesso',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Artigo',
      name: 'artigo',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Nº Camarário',
      name: 'numCamarario',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Morada',
      name: 'morada',
      visible: true,
      sortable: true
    },
    {
      displayName: 'CP4',
      name: 'cp4',
      visible: true,
      sortable: true
    },
    {
      displayName: 'CP3',
      name: 'cp3',
      visible: true,
      sortable: false
    },
    {
      displayName: 'Localidade',
      name: 'localidade',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Observações',
      name: 'observacoes',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Cliente',
      name: 'cliente',
      visible: true,
      sortable: true
    },
    {
      displayName: 'EMIE',
      name: 'emie',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Condomínio',
      name: 'condominio',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Estado',
      name: 'estado',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Data de Inspeção',
      name: 'agendamentoData',
      exportDate: 'DD-MM-YYYY',
      visible: false
    },
    {
      displayName: 'Hora de Inspeção',
      name: 'agendamentoHora',
      // exportDate: 'HH:mm:ss', Cannot be used because we can't directly use it as a moment
      visible: false
    },
    {
      displayName: 'Data da Próxima Requisição',
      name: 'proximaInspecao',
      exportDate: 'DD-MM-YYYY',
      visible: false
    },
    {
      displayName: 'Data da Próxima Inspeção',
      name: 'proximaInspecaoLimite',
      exportDate: 'DD-MM-YYYY',
      visible: false
    },
    {
      displayName: 'Inspetor',
      name: 'inspetor',
      visible: false,
      sortable: true
    },
    {
      displayName: 'Resultado',
      name: 'resultado',
      visible: true,
      sortable: true
    },
    {
      displayName: 'Norma',
      name: 'norma',
      visible: false,
      sortable: true
    },
    {
      displayName: 'Fatura pedida?',
      name: 'faturaPedida',
      visible: false,
      sortable: true
    }
    ];

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 'o'
    },
    {
      val: 'c',
      name: 'Começa por',
      type: 'o'
    },
    {
      val: 'd',
      name: 'Termina com',
      type: 'o'
    },
    {
      val: 'e',
      name: 'Contém',
      type: 'o'
    },
    {
      val: 'a',
      name: 'Igual (=)',
      type: 'd'
    },
    {
      val: 'b',
      name: 'Diferente de (≠)',
      type: 'd'
    },
    {
      val: 'c',
      name: 'Posterior a (>)',
      type: 'd'
    },
    {
      val: 'd',
      name: 'Anterior a (<)',
      type: 'd'
    },
    {
      val: 'e',
      name: 'Posterior ou igual (≥)',
      type: 'd'
    },
    {
      val: 'f',
      name: 'Anterior ou igual (≤)',
      type: 'd'
    },
    {
      val: 'a',
      name: 'Igual',
      type: 's'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 's'
    }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [{
      id: 'ElvProcesso.id',
      name: 'ID SGI',
      type: 'o'
    },
    {
      id: 'ElvProcesso.numeroProcesso',
      name: 'Nº Processo',
      type: 'o'
    },
    {
      id: 'ElvProcesso.artigoId',
      name: 'Artigo',
      type: 's',
      list: this.codArtigos
    },
    {
      id: 'ElvElevador.numeroCamarario',
      name: 'Nº Camarário',
      type: 'o'
    },
    {
      id: 'ElvElevador.cp4',
      name: 'Nº Cód. Postal',
      type: 'o'
    },
    {
      id: 'ElvElevador.cp3',
      name: 'Ext. Cód. Postal',
      type: 'o'
    },
    {
      id: 'ElvElevador.localidade',
      name: 'Localidade',
      type: 'o',
    },
    {
      id: 'ElvProcesso.clienteId',
      name: 'Cliente',
      type: 's',
      list: this.clientes
    },
    {
      id: 'ElvProcesso.emieId',
      name: 'EMIE',
      type: 's',
      list: this.emies
    },
    {
      id: 'ElvProcesso.condominioId',
      name: 'Condomínio',
      type: 's',
      list: this.condominios
    },
    {
      id: 'ElvProcesso.estadoId',
      name: 'Estado',
      type: 's',
      list: this.estados
    },
    {
      id: 'ElvAgendamento.data',
      name: 'Data de Inspeção',
      type: 'd',
      format: "YYYY-MM-DD",
      displayFormat: "DD/MM/YYYY"
    },
    {
      id: 'ElvAgendamento.hora',
      name: 'Hora de Inspeção',
      type: 'd',
      format: "HH:mm",
      displayFormat: "HH:mm"
    },
    {
      id: 'ElvElevador.proximaInspecao',
      name: 'Data da Próxima Requisição',
      type: 'd',
      format: "YYYY-MM-DD",
      displayFormat: "DD/MM/YYYY"
    },
    {
      id: 'ElvElevador.proximaInspecaoLimite',
      name: 'Data da Próxima Inspeção',
      type: 'd',
      format: "YYYY-MM-DD",
      displayFormat: "DD/MM/YYYY"
    },
    {
      id: 'Funcionario.id',
      name: 'Inspetor',
      type: 's',
      list: this.inspetores
    },
    {
      id: 'ElvProcesso.resultado',
      name: 'Resultado',
      type: 's',
      list: this.resultados
    },
    {
      id: 'ElvElevador.observacoes',
      name: 'Observações',
      type: 'o',
    },
    {
      id: 'ElvProcesso.normaId',
      name: 'Norma',
      type: 's',
      list: this.normas
    },
    {
      id: 'ElvProcesso.faturaPedida',
      name: 'Fatura Pedida',
      type: 's',
      list: this.faturasPedidas
    }];


    // Find relevant displayColumns from local storage
    if (localStorage.getItem('ElvProcessoDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('ElvProcessoDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('ElvProcessoFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('ElvProcessoFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('ElvProcessoFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'ElvProcesso.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'ElvProcesso.emieId')
          f.column.selected.list = this.emies;
        if (f.column.selected.id === 'ElvProcesso.condominioId')
          f.column.selected.list = this.condominios;
        if (f.column.selected.id === 'ElvProcesso.estadoId')
          f.column.selected.list = this.estados;
        if (f.column.selected.id === 'ElvProcesso.artigoId')
          f.column.selected.list = this.codArtigos;
        if (f.column.selected.id === 'Funcionario.id')
          f.column.selected.list = this.inspetores;
        if (f.column.selected.id === 'ElvProcesso.resultado')
          f.column.selected.list = this.resultados;
        if (f.column.selected.id === 'ElvProcesso.normaId')
          f.column.selected.list = this.normas;
        if (f.column.selected.id === 'ElvProcesso.faturaPedida')
          f.column.selected.list = this.faturasPedidas;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getProcessos();
  }

  getResultado = (valor) => {
    return this.resultados.find((r) => r.value == valor);
  };

  getStyle = (valor) => {
    if (valor == 0) {
      return {
        "font-weight": 500,
        color: "#4d575d"
      };
    }
    if (valor == 1 || valor == 2 || valor == 5 || valor == 6) {
      return {
        "font-weight": 500,
        color: "#388e3c"
      };
    }
    if (valor == 3) {
      return {
        "font-weight": 500,
        color: "#d32f2f"
      };
    }
  };

  clearFilter = () => {
    localStorage.removeItem('ElvProcessoFilter');
    this.customFilters = [];
    this.getProcessos();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  getProcessos = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {
      'ElvProcesso.active': 1
    });

    this.ElvProcesso.table({
      params: {
        fields: [
          'ElvProcesso.id as id',
          'ElvProcesso.numeroProcesso as numProcesso',
          'ElvProcesso.iid_ano as iidAno',
          'ElvProcesso.iid_proc as iidProc',
          'ElvProcessoArtigo.codigo as artigo',
          'ElvElevador.numeroCamarario as numCamarario',
          'ElvElevador.morada as morada',
          'ElvElevador.cp_4 as cp4',
          'ElvElevador.cp_3 as cp3',
          'ElvElevador.localidade as localidade',
          'ElvElevador.observacoes',
          'ElvCliente.nome as cliente',
          'ElvEmie.nome as emie',
          'ElvCondominio.nome as condominio',
          'ElvEstado.designacao as estado',
          'ElvProcesso.estadoId as estadoId',
          'ElvAgendamento.data as agendamentoData',
          'ElvAgendamento.hora as agendamentoHora',
          'ElvElevador.proximaInspecao as proximaInspecao',
          'ElvElevador.proximaInspecaoLimite as proximaInspecaoLimite',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as inspetor',
          'ElvProcesso.resultado as resultado',
          'ElvProcesso.faturaPedida as faturaPedida',
          'ElvNorma.designacao as norma'
        ],
        from: ['ElvProcesso', 'ElvElevador', 'ElvCliente', 'ElvEmie', 'ElvCondominio', 'ElvEstado', 'ElvProcessoArtigo', 'ElvAgendamento', 'Funcionario', 'ElvNorma'],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'ElvAgendamento.processoId', 'ElvAgendamento.tecnicoId', undefined],
        references: [undefined, 'ElvProcesso.elevadorId', 'ElvProcesso.clienteId', 'ElvProcesso.emieId', 'ElvProcesso.condominioId', 'ElvProcesso.estadoId', 'ElvProcesso.artigoId', 'ElvProcesso.id', 'Funcionario.id', 'ElvProcesso.normaId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      res.data.forEach(i => {
        if (i.cp4 && !i.cp3) {
          i.codigoPostal = i.cp4;
        }
        if (i.cp4 && i.cp3) {
          i.codigoPostal = `${('0000' + i.cp4).slice(-4)}-${('000' + i.cp3).slice(-3)}`;
        }
        if (i.cp3 != null && !isNaN(i.cp3))
          i.cp3 = ('000' + i.cp3).slice(-3);
      });

      this.pedidos = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((error) => {
      console.log(error);
    });
  };

  exportProcessos = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, { 'ElvProcesso.active': 1 });
    this.ElvProcesso.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [ // edit getProcessos() too
          'ElvProcesso.id as id',
          'ElvProcesso.numeroProcesso as numProcesso',
          'ElvProcesso.iid_ano as iidAno',
          'ElvProcesso.iid_proc as iidProc',
          'ElvProcessoArtigo.codigo as artigo',
          'ElvElevador.numeroCamarario as numCamarario',
          'ElvElevador.morada as morada',
          'ElvElevador.cp_4 as cp4',
          'ElvElevador.cp_3 as cp3',
          'ElvElevador.localidade as localidade',
          'ElvElevador.observacoes',
          'ElvCliente.nome as cliente',
          'ElvEmie.nome as emie',
          'ElvCondominio.nome as condominio',
          'ElvEstado.designacao as estado',
          'ElvProcesso.estadoId as estadoId',
          'ElvAgendamento.data as agendamentoData',
          'ElvAgendamento.hora as agendamentoHora',
          'ElvElevador.proximaInspecao as proximaInspecao',
          'ElvElevador.proximaInspecaoLimite as proximaInspecaoLimite',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as inspetor',
          'ElvProcesso.resultado as resultado',
          'ElvProcesso.faturaPedida as faturaPedida',
          'ElvNorma.designacao as norma'
        ],
        from: ['ElvProcesso', 'ElvElevador', 'ElvCliente', 'ElvEmie', 'ElvCondominio', 'ElvEstado', 'ElvProcessoArtigo', 'ElvAgendamento', 'Funcionario', 'ElvNorma'],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'ElvAgendamento.processoId', 'ElvAgendamento.tecnicoId', undefined],
        references: [undefined, 'ElvProcesso.elevadorId', 'ElvProcesso.clienteId', 'ElvProcesso.emieId', 'ElvProcesso.condominioId', 'ElvProcesso.estadoId', 'ElvProcesso.artigoId', 'ElvProcesso.id', 'Funcionario.id', 'ElvProcesso.normaId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'processosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
    });
  };

  changeStateSelected = () => {
    let processIds = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected) {
      processIds = this.pedidos.filter(x => x.selected).map(x => { return { id: x.id, estadoId: x.estadoId } });

      // Check if all have the same Estado
      if (processIds.some(x => x.estadoId !== processIds[0].estadoId)) { // Reject changing different origins
        this.UI.showAlert("Alteração em massa de estado obriga a que todos os processos selecionados tenham que ter o mesmo estado inicial. Por favor escolha processos com o mesmo estado inicial.");
      } else {
        this.ElvProcessoService.changeStateMultiple(processIds, processIds[0].estadoId).then(() => {
          this.getProcessos();
        }).catch(() => {
          this.getProcessos();
        });
      }
    } else {
      if (!this.everythingSelected)
        this.UI.addToast("Não é possível selecionar todos os processos para alterar estado");
      else
        this.UI.addToast("É necessário selecionar alguns processos para alterar estado");
    }
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    // if a few processos selected only
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.pedidos, x => x.selected), x => {
        return { key: 'ElvProcesso.id', value: x.id };
      });
    let whereFields = this.setWhereFields(this.customFilters, { 'ElvProcesso.active': 1 }, orsLiteral);
    this.ElvProcesso.exportProcessos({
      displayColumns: this.displayColumns,
      params: {
        fields: [ // edit getProcessos() too
          'ElvProcesso.id as id',
          'ElvProcesso.numeroProcesso as numProcesso',
          'ElvProcesso.iid_ano as iidAno',
          'ElvProcesso.iid_proc as iidProc',
          'ElvProcessoArtigo.codigo as artigo',
          'ElvElevador.numeroCamarario as numCamarario',
          'ElvElevador.morada as morada',
          'ElvElevador.cp_4 as cp4',
          'ElvElevador.cp_3 as cp3',
          'ElvElevador.localidade as localidade',
          'ElvElevador.observacoes',
          'ElvCliente.nome as cliente',
          'ElvEmie.nome as emie',
          'ElvCondominio.nome as condominio',
          'ElvEstado.designacao as estado',
          'ElvProcesso.estadoId as estadoId',
          'ElvAgendamento.data as agendamentoData',
          'ElvAgendamento.hora as agendamentoHora',
          'ElvElevador.proximaInspecao as proximaInspecao',
          'ElvElevador.proximaInspecaoLimite as proximaInspecaoLimite',
          'Funcionario.id as funcionarioId',
          'Funcionario.name as inspetor',
          'ElvProcesso.resultado as resultado',
          'ElvProcesso.faturaPedida as faturaPedida',
          'ElvNorma.designacao as norma'
        ],
        from: ['ElvProcesso', 'ElvElevador', 'ElvCliente', 'ElvEmie', 'ElvCondominio', 'ElvEstado', 'ElvProcessoArtigo', 'ElvAgendamento', 'Funcionario', 'ElvNorma'],
        referencesOrigin: [undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'ElvAgendamento.processoId', 'ElvAgendamento.tecnicoId', undefined],
        references: [undefined, 'ElvProcesso.elevadorId', 'ElvProcesso.clienteId', 'ElvProcesso.emieId', 'ElvProcesso.condominioId', 'ElvProcesso.estadoId', 'ElvProcesso.artigoId', 'ElvProcesso.id', 'Funcionario.id', 'ElvProcesso.normaId'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        // limit: this.opt.items,
        // skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'processosExportados.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar processos. Por favor tente mais tarde.");
    });
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('ElvProcessoDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  loadData = () => {
    this.ElvEstado.find({
      filter: {
        where: {
          active: true
        },
      }
    }).$promise.then(estados => {
      estados.forEach(t => {
        t.name = t.designacao;
      });
      this.estados = estados;
      this.filtersLoaded[5] = 1;
    }).catch(e => {
      console.log(e);
      this.estados = [];
      this.filtersLoaded[5] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Estados).");
    });

    this.ElvCliente.find({
      filter: {
        fields: {
          id: true,
          nome: true,
        },
        where: {
          active: true
        },
      }
    }).$promise.then(clientes => {
      clientes.forEach(t => {
        t.name = t.nome;
      });
      this.clientes = clientes;
      this.filtersLoaded[2] = 1;
    }).catch(e => {
      this.clientes = [];
      this.filtersLoaded[2] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Clientes).");
    });

    this.ElvEmie.find({
      filter: {
        fields: {
          id: true,
          nome: true,
        },
        where: {
          active: true
        },
      }
    }).$promise.then(emies => {
      emies.forEach(t => {
        t.name = t.nome;
      });
      this.emies = emies;
      this.filtersLoaded[3] = 1;
    }).catch(e => {
      this.emies = [];
      this.filtersLoaded[3] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (EMIEs).");
    });

    this.ElvCondominio.find({
      filter: {
        fields: {
          id: true,
          nome: true,
        },
        where: {
          active: true
        },
      }
    }).$promise.then(condominios => {
      condominios.forEach(t => {
        t.name = t.nome;
      });
      this.condominios = condominios;
      this.filtersLoaded[4] = 1;
    }).catch(e => {
      this.condominios = [];
      this.filtersLoaded[4] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Condomínios).");
    });

    this.ElvProcessoArtigo.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(artigos => {
      artigos.forEach(a => {
        a.name = `${a.descricao} (${a.codigo})`;
      });
      this.codArtigos = artigos;
      this.filtersLoaded[6] = 1;
    }).catch(e => {
      this.codArtigos = [];
      this.filtersLoaded[6] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Artigos).");
    });

    this.Group.findOne({
      filter: {
        where: {
          name: {
            like: 'Inspetor%ELV'
          }
        },
        include: {
          relation: 'usergroup',
          scope: {
            include: 'user'
          }
        }
      }
    }).$promise.then((g) => {
      if (g && g.usergroup) {
        g.usergroup.forEach(u => {
          this.inspetores.push(u.user);
        });

        this.inspetores = _.orderBy(this.inspetores, 'name', 'asc');
        this.filtersLoaded[7] = 1;
      }
    }).catch(err => {
      console.log(err);
      this.inspetores = [];
      this.filtersLoaded[7] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Inspetores).");
    });

    this.ElvNorma.find({
      filter: {
        fields: {
          id: true,
          designacao: true,
        },
        where: {
          active: true
        },
      }
    }).$promise.then(normas => {
      normas.forEach(t => {
        t.name = t.designacao;
      });
      this.normas = normas;
      this.filtersLoaded[8] = 1;
    }).catch(e => {
      this.normas = [];
      this.filtersLoaded[8] = 1;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Normas).");
    });

  };

  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.elv.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.elv.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.elv.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getProcessos();
  };

  pedidoFaturaSelected = () => {
    if (this.everythingSelected) {
      this.UI.addToast("Não é possível pedir fatura quando todos os processos estão selecionados");
      return;
    }

    let faturadoCheckIds = this.pedidos.filter(x => x.selected && (x.faturaPedida || x.faturaPedida === -1)); // If fatura pedida or can't do pedido fatura

    if (faturadoCheckIds.length > 0) {
      let msg = `Não é possível fazer o pedido de emissão de fatura para os processos:\n${faturadoCheckIds.map(x => x.numProcesso).join(', ')}.\nAjuste a sua seleção e tente novamente.`;
      this.UI.showAlert(msg);
      return;
    }

    let ids = this.pedidos.filter((x) => x.selected).map((x) => ({id: x.id})); // () are necessary to create the right object

    this.ElvProcessoService.pedidoFaturaMultiple(ids).then((r) => {
      this.getProcessos();
    }).catch((e) => {
      console.log(e);
    });
  };

  getFaturaPedidaOutput = (f) => {
    switch (f) {
      case -1 : return "N/A";
      case 0: return "Não";
      case 1: return "Sim";
      default: return "-";
    }
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.condominios.length && !this.emies.length && !this.clientes.length && !this.estados.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'ElvProcesso.clienteId')
        f.list = this.clientes;
      if (f.id === 'ElvProcesso.emieId')
        f.list = this.emies;
      if (f.id === 'ElvProcesso.condominioId')
        f.list = this.condominios;
      if (f.id === 'ElvProcesso.estadoId')
        f.list = this.estados;
      if (f.id === 'ElvProcesso.artigoId')
        f.list = this.codArtigos;
      if (f.id === 'Funcionario.id')
        f.list = this.inspetores;
      if (f.id === 'ElvProcesso.resultado')
        f.list = this.resultados;
      if (f.id === 'ElvProcesso.normaId')
        f.list = this.normas;
      if (f.id === 'ElvProcesso.faturaPedida')
        f.list = this.faturasPedidas;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'ElvProcesso.clienteId')
          f.column.selected.list = this.clientes;
        if (f.column.selected.id === 'ElvProcesso.emieId')
          f.column.selected.list = this.emies;
        if (f.column.selected.id === 'ElvProcesso.condominioId')
          f.column.selected.list = this.condominios;
        if (f.column.selected.id === 'ElvProcesso.estadoId')
          f.column.selected.list = this.estados;
        if (f.column.selected.id === 'ElvProcesso.artigoId')
          f.column.selected.list = this.codArtigos;
        if (f.column.selected.id === 'Funcionario.id')
          f.column.selected.list = this.inspetores;
        if (f.column.selected.id === 'ElvProcesso.normaId')
          f.column.selected.list = this.normas;
        if (f.column.selected.id === 'ElvProcesso.faturaPedida')
          f.column.selected.list = this.faturasPedidas;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value).utc();
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('ElvProcessoFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.elv.processos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });

    this.getProcessos();
  };

  hasSelect = () => {
    return _.some(this.pedidos, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.pedidos.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.pedidos.length;
    } else { // Remove all selections
      this.pedidos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.pedidos.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.pedidos, r => r.selected).length;
    this.allSelected = this.nSelected >= this.pedidos.length;
    this.everythingSelected = false;
  };
}

DirectoryPedidosElevadoresController.$inject = ['$state', 'ElvProcesso', 'ElvEstado', 'ElvCliente', 'ElvEmie', 'ElvCondominio', 'ElvProcessoArtigo', 'ElvNorma', 'Group', 'UIService', 'ElvProcessoService'];
