export default class DirectoryPontosController {
  constructor(UIService, AuthorizationService, ElvPontoVerificacao, Table) {
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.ElvPontoVerificacao = ElvPontoVerificacao;
    this.Table = Table;
    this.loaded = false;
    this.loadData();
    this.pontos = [];
  }

  loadData = () => {
    this.loaded = false;
    this.ElvPontoVerificacao.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(res => {
      this.pontos = res;
      this.tableData = new this.Table({
        sorting: {
          id: 'desc'
        }
      }, {
        dataset: this.pontos,
      });
      this.loaded = true;
    }).catch((error) => {
      console.log(error);
      this.loaded = true;
      this.UI.addToast('Erro ao carregar pontos.');
    });
  };

  addPonto = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./ponto.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.label = "Adicionar Ponto de Verificação";

        $scope.ponto = {
          id: 0,
          active: true,
          designacao: "",
          codigo: ""
        };

        $scope.ok = () => {
          $scope.$close($scope.ponto);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    }).then(res => {
      if (res) {
        this.ElvPontoVerificacao.create(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Ponto de verificação inserido com sucesso!');
        }).catch((error) => {
          console.log(error);
          this.UI.addToast('Erro ao adicionar ponto de verificação.');
        })
      }
    });
  };

  editPonto = (rr) => {
    let cenas = this.UI.showWaiting();
    this.ElvPontoVerificacao.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((rcm) => {
      cenas.close();
      this.UI.showDialog({
        size: 'lg',
        template: require('./ponto.dialog.html'),
        controller: ['$scope', '$dialog', function ($scope, $dialog) {
          $scope.label = "Editar Ponto de Verificação";

          $scope.ponto = {
            id: rr.id,
            designacao: rr.designacao,
            codigo: rr.codigo,
            active: true
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      }).then(r => {
        if (r) {
          this.loaded = false;
          this.ElvPontoVerificacao.findOne({
            filter: {
              where: {
                id: rr.id,
                active: 1
              }
            }
          }).$promise.then((ponto) => {
            ponto.designacao = r.ponto.designacao;
            ponto.codigo = r.ponto.codigo;
            ponto.$save().then((res) => {
              this.loadData();
              this.UI.addToast('Ponto atualizado com sucesso');
            }, (error) => {
              console.log(error);
              this.UI.addToast('Erro ao atualizar ponto');
              this.loadData();
            });
          }).catch((error) => {
            console.log(error);
            this.UI.addToast('Não foi possível encontrar este ponto.');
            this.loadData();
          });
        }
      })
    }).catch((error) => {
      cenas.close();
      console.log(error);
      this.UI.addToast("Não foi possível carregar pontos de verificação. Por favor tente mais tarde.");
    });
  };

  removePonto = (ponto) => {
    let confirm = this.UI.showConfirm('Deseja remover o ponto de verificação?');
    confirm.then(res => {
      if (res) {
        this.ElvPontoVerificacao.find({
          filter: {
            where: {
              id: ponto.id,
              active: 1
            },
            include: {
              relation: 'topicos',
              scope: {
                where: {
                  active: 1
                }
              }
            }
          }
        }).$promise.then((res) => {
          if (res && res.length === 1) {
            let p = res[0];
            if (p.topicos && p.topicos.length > 0) { // Há tópicos, ponto não pode ser eliminado. Alertar.
              this.UI.showAlert("Não é possível remover ponto. Há  " + p.topicos.length + " tópico" + ((p.topicos.length === 1) ? "" : "s") + " de verificação a usar este ponto.");
            } else { // Não encontra tópicos, pode apagar
              p.active = 0;
              this.ElvPontoVerificacao.upsert(p).$promise.then(() => {
                this.loadData();
                this.UI.addToast('O ponto foi removido com sucesso.');
              }).catch(err => {
                console.log(err);
                this.UI.addToast("Erro a eliminar ponto. Por favor tente novamente");
              });
            }
          } else {
            this.UI.addToast("Não foi possível encontrar ponto. Por favor recarregue a página");
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível apagar o ponto. Recarregue a página.")
        });
      }
    })
  };

}

DirectoryPontosController.$inject = ['UIService', 'AuthorizationService', 'ElvPontoVerificacao', 'NgTableParams'];
