export default class RhEventService {
  constructor($q, Evento, EventoFuncionario, Funcionario) {
    this.$q = $q;
    this.Evento = Evento;
    this.EventoFuncionario = EventoFuncionario;
    this.Funcionario = Funcionario;
  }

  getEvento = id => {
    let defer = this.$q.defer();
    this.Evento.find({
      filter: {
        where: {
          id: id,
          active: true
        },
        limit: 1
      }
    }).$promise.then(r => {
      if (r.length == 0) {
        defer.reject();
      } else {
        defer.resolve(r[0]);
      }
    }).catch(e => defer.reject(e));
    return defer.promise;
  };

  /**
   *
   * @param {*} id - id do evento
   * @returns Lista de registos ao evento
   */
  getRegistosEvento = id => {
    let defer = this.$q.defer();
    this.EventoFuncionario.find({
      filter: {
        where: {
          eventoId: id,
          active: true
        },
        include: 'funcionario',
        order: 'data DESC, hora DESC'
      }
    }).$promise.then(r => defer.resolve(r)).catch(e => defer.reject(e));
    return defer.promise;
  };

  register = (eventId, identifier) => {
    let defer = this.$q.defer();
    this.Funcionario.find({
      filter: {
        where: {
          cartaoAcesso: identifier
        },
        limit: 1
      }
    }).$promise.then(r => {
      if (r.length > 0) { // Found funcionario for identifier, register it
        this.registerUserToEvent(eventId, r[0].id, identifier).then(_ => {
          if (_ == true) {
            defer.resolve("Colaborador registado com sucesso");
          } else {
            defer.reject("Colaborador já registado");
          }
        });
      } else { // Cartão not found for funcionario, register it anyway
        this.registerUnknownToEvent(eventId, identifier).then(_ => {
          if (_ == true) {
            defer.resolve("Cartão de acesso desconhecido registado com sucesso");
          } else {
            defer.reject("Cartão de acesso desconhecido já registado");
          }
        });
      }
    })
    return defer.promise;
  };

  unregisterUserOfEvent = (object) => {
    let defer = this.$q.defer();
    // Verifica se utilizador já existe, se sim, ignora
    object.active = false;
    this.EventoFuncionario.upsert(object).$promise.then(r => defer.resolve("Registo de colaborador eliminado!"))
      .catch(e => defer.reject("Erro de ligação à base de dados"));
    return defer.promise;
  };

  registerUserToEvent = (eventId, userId, identifier) => {
    let defer = this.$q.defer();
    // Verifica se utilizador já existe, se sim, ignora
    this.EventoFuncionario.find({
      filter: {
        where: {
          eventoId: eventId,
          funcionarioId: userId,
          active: true
        }
      }
    }).$promise.then(r => {
      if (r.length > 0) {
        defer.resolve(false);
      } else {
        this.EventoFuncionario.create({
          id: 0,
          active: true,
          eventoId: eventId,
          funcionarioId: userId,
          cartaoAcesso: identifier,
          response: true,
          data: moment.utc(),
          hora: moment.utc().format("HH:mm:ss")
        }).$promise.then(r => {
          defer.resolve(true);
        }).catch(e => defer.reject("Não foi possível registar colaborador no evento"));
      }
    }).catch(e => defer.reject("Erro de ligação à base de dados"));
    return defer.promise;
  };

  registerUnknownToEvent = (eventId, identifier) => {
    let defer = this.$q.defer();
    // Verifica se utilizador já existe, se sim, ignora
    this.EventoFuncionario.find({
      filter: {
        where: {
          and: [{eventoId: eventId}, {cartaoAcesso: identifier}, {active: true}]
        }
      }
    }).$promise.then(r => {
      if (r.length > 0) {
        defer.resolve(false);
      } else {
        this.EventoFuncionario.create({
          id: 0,
          active: true,
          eventoId: eventId,
          funcionarioId: null,
          cartaoAcesso: identifier,
          response: true,
          data: moment.utc(),
          hora: moment.utc().format("HH:mm:ss")
        }).$promise.then(r => {
          defer.resolve(true);
        }).catch(e => defer.reject("Não foi possível registar cartão de acesso no evento"));
      }
    }).catch(e => defer.reject("Erro de ligação à base de dados"));
    return defer.promise;
  };
}

RhEventService.$inject = ['$q', 'RhEvento', 'RhEventoFuncionario', 'Funcionario'];
