import React from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Typography, Grid, IconButton, Tooltip } from '@mui/material';
import { mdiMagnify } from '@mdi/js';
import { MdiIcon } from '@components';
export function InfoDialog({ data, onClose, title, ...props }) {
    return (<Dialog {...props} maxWidth='lg' onClose={onClose}>
      <DialogTitle>{title || 'Detalhes'}</DialogTitle>
      <DialogContent>
        {data && data.length > 0 ? (<Box>
            <Grid container spacing={2}>
              {data.map((item, index) => (<Grid item md={4} key={index}>
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography sx={{ fontWeight: 'bold' }} variant='subtitle1'>
                      {item.key}
                    </Typography>
                    {item.key == 'Anexo' || item.key == 'Ficheiro' && item.value != null ? (<Tooltip title={'Ver ficheiro'} placement='top'>
                        <IconButton color='primary' onClick={() => {
                        window.open(`/api/Upload/crm/download/${item.value}`, '_blank');
                    }}>
                          <MdiIcon path={mdiMagnify} sx={{ color: '#1976D2' }}/>
                        </IconButton>
                      </Tooltip>) : (<Typography variant='body2'>{item.value ? item.value.toString() : 'Não tem'}</Typography>)}
                  </Box>
                </Grid>))}
            </Grid>
          </Box>) : (<Typography>Não tem</Typography>)}
      </DialogContent>
    </Dialog>);
}
