export default class OblOrcamentacaoController {
  constructor($state, OblProdutoProcesso, OblProcessoFotografia, OblProcessoExtras, OblProcesso, OblMotivo, OblAlteracaoestado, OblOrdemintervencao, OblAgendamento, UIService, AuthenticationService) {
    this.OblProdutoProcesso = OblProdutoProcesso;
    this.OblProcessoFotografia = OblProcessoFotografia;
    this.OblProcessoExtras = OblProcessoExtras;
    this.OblProcesso = OblProcesso;
    this.OblMotivo = OblMotivo;
    this.OblAlteracaoestado = OblAlteracaoestado;
    this.OblOrdemintervencao = OblOrdemintervencao;
    this.OblAgendamento = OblAgendamento;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.params = $state.params;
    this.loadData();
  }

  filterPhotos = img => {
    return img.active;
  }

  loadData = () => {
    this.loaded = false;
    this.OblProcesso.findOne({
      filter: {
        where: {
          id: this.params.ficha,
          oiId: this.params.id
        },
        include: ['Motivo', {
          relation: "Fotografias",
          scope: {
            where: {
              active: true
            }
          }
        }, {
            relation: 'Agendamento',
            scope: {
              where: {
                active: true,
              },
              include: 'Funcionario'
            }
          }, {
            relation: 'Ordemintervencao',
            scope: {
              include: [{
                relation: 'Valencia',
                scope: {
                  include: 'categoria'
                }
              }]
            }
          }, {
            relation: 'ProdutoProcesso',
            scope: {
              where: {
                pre: false,
                active: true
              },
              include: 'Produto'
            }
          }, {
            relation: 'Extras',
            scope: {
              where: {
                active: true
              }
            }
          }, {
            relation: 'Anexo',
            scope: {
              where: {
                active: true
              },
              order: 'id DESC'
            }
          }]
      }
    }).$promise.then(r => {
      this.info = r.Ordemintervencao;
      if (r.fotografia != null) {
        r.Fotografias.push({
          id: 0,
          active: true,
          image: r.fotografia
        });
      }
      this.processo = r;
      this.UI.formatZipCode(this.info);
      this.data = r.ProdutoProcesso;
      this.extras = r.Extras;
      this.loaded = true;
    });
  }

  changeState = (state, notes) => {
    let user = this.Auth.getUser();
    this.OblAlteracaoestado.create({
      id: 0,
      oiId: this.info.id,
      estadoInicial: this.info.estadoId,
      estadoFinal: state,
      dataAlteracao: moment.utc(),
      funcionarioId: user.id,
      observacoes: notes || ""
    }).$promise.then(r => {
      this.UI.addToast("Estado atualizado com sucesso");
      this.loadData();
    }).catch(e => {
      this.UI.addToast("Ocorreu um erro ao alterar estado");
      this.loadData();
    });
  }

  showPic = img => {
    this.UI.showDialog({
      template: require('./photo.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.photo = img.imagem;

        $scope.ok = () => {
          $scope.$dismiss();
        }

        $scope.delete = () => {
          $scope.$close();
        }
      }]
    }).then(r => {
      img.active = false;
      if (img.id > 0) {
        this.OblProcessoFotografia.upsert(img).$promise.then(_ => {
          this.loadData();
          this.UI.addToast("Fotografia eliminada com sucesso");
        });
      } else {
        this.OblProcessoFotografia.create({
          id: 0,
          processoId: this.processoId,
          imagem: img,
          active: false
        });
        this.OblProcesso.prototype$updateAttributes({ id: this.processoId }, { fotografia: null }).$promise.then(r => {
          this.loadData();
          this.UI.addToast("Fotografia eliminada com sucesso");
        });
      }
    });
  }

  validate = () => {
    this.UI.showDialog({
      template: require('./validate.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.ok = (val) => {
          $scope.$close(val);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(result => {
      if (result) {
        this.OblProcesso.prototype$updateAttributes(
          { id: this.params.ficha },
          { validado: true, aceite: result }
        ).$promise.then((_) => {
          if (result) {
            // Altera estado para agendamento Visita técnica
            this.changeState(7);
          } else {
            // Altera estado para cancelado
            this.changeState(10, "Cliente rejeitou orçamento");
          }
          this.loadData();
        }).catch(e => {
          this.UI.addToast("Ocorreu um erro a validar orçamento");
          this.loadData();
        });
      }
    });
  }

  deleteOther = row => {
    this.UI.showConfirm(`Deseja eliminar ${row.designacao}?`).then(r => {
      if (r) {
        this.OblProcessoExtras.prototype$updateAttributes(
          { id: row.id },
          { active: false }
        ).$promise.then((_) => {
          this.UI.addToast("Produto eliminado com sucesso");
          row.active = false;
        }).catch(e => {
          this.UI.addToast("Não foi possível eliminar produto");
        })
      }
    });
  }

  showVAT = (data) => {
    let roundToNDigits = (number, p) => Math.round((number + Number.EPSILON) * Math.pow(10, p)) / Math.pow(10, p);
    return roundToNDigits((data - 1) * 100, 0);
  }

  setRealizada = val => {
    this.processo.realizada = val;
    this.pickMotivo();
  }

  addOther = (data, i) => {
    console.log(data);
    this.UI.showDialog({
      template: require('./extra.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.units = [{ unit: 'mts', description: 'Metros' }, { unit: 'un', description: 'Unidade' }];
        if (data) {
          $scope.data = angular.copy(data);
          if ($scope.data.temValor) {
            $scope.data.iva = ($scope.data.iva - 1) * 100;
          }
        } else {
          $scope.data = {
            id: 0,
            processoId: this.params.ficha,
            validado: false,
            active: true
          };
        }

        $scope.loaded = true;

        $scope.ok = () => {
          if ($scope.data.tipo == 2) {
            $scope.data.fabricante = "-";
            $scope.data.unidade = 1;
            $scope.data.quantidade = 1;
          }
          if ($scope.data.temValor) {
            $scope.data.iva = (1 + ($scope.data.iva / 100)) || 1.23;
          }
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(r => {
      if (r) {
        this.loaded = false;
        this.OblProcessoExtras.upsert(r).$promise.then(_ => {
          if (data) {
            this.extras[i] = _;
          } else {
            this.extras.push(_);
          }
          this.loaded = true;
        }).catch(e => {
          this.UI.addToast("Ocorreu um erro ao adicionar outros");
          this.loadData();
        })
      }
    })
  };

  add = (data, i) => {
    this.UI.showDialog({
      template: require('./produto.dialog.html'),
      controller: ['$scope', 'OblProduto', ($scope, OblProduto) => {

        $scope.isNew = data == undefined;

        $scope.aux = {};
        if (data) {
          $scope.data = {
            Produto: data.Produto,
            quantidadeEfetiva: data.quantidadeEfetiva,
            quantidadeFaturada: data.quantidadeFaturada != undefined ? data.quantidadeFaturada : angular.copy(data.quantidadeEfetiva)
          };
        } else {
          $scope.data = {};
        }

        $scope.items = [];

        $scope.fetch = ($select, $evt) => {
          if (!$evt) {
            $scope.items = [];
          } else {
            $evt.stopPropagation();
            $evt.preventDefault();
          }
          if ($select.search.length < 2) return;
          OblProduto.find({
            filter: {
              where: {
                or: [{
                  designacao: {
                    like: `%${$select.search}%`
                  }
                },
                {
                  referencia: {
                    like: `%${$select.search}%`
                  }
                },
                {
                  primaveraId: {
                    like: `%${$select.search}%`
                  }
                },
                ]

              }, limit: 100,
              include: 'Fornecedor'
            }
          }).$promise.then(r => {
            $scope.items = r;
          });
        }

        $scope.loaded = true;

        $scope.ok = () => {
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(r => {
      if (r) {
        this.loaded = false;
        this.OblProdutoProcesso.upsert({
          id: data ? data.id : 0,
          processoId: this.params.ficha,
          viaturaId: 0,
          tecnicoId: this.Auth.getId(),
          produtoId: r.Produto.id,
          quantidadeFaturada: r.quantidadeFaturada,
          quantidadeEfetiva: r.quantidadeEfetiva,
          pvp: r.Produto.valor || 0,
          data: moment.utc(),
          active: true
        }).$promise.then(_ => {
          _.Produto = r.Produto;
          if (data) {
            this.data[i] = _;
          } else {
            this.data.push(_);
          }
          this.UI.addToast("Produto adicionado com sucesso");
          this.loaded = true;
        }).catch(e => {
          this.UI.addToast("Não foi possível adicionar produto");
          this.loaded = true;
        });
      }
    });
  }

  changeResolved = () => {
    let value = !this.processo.extra || true;
    this.UI.showConfirm(`Deseja alterar a propriedade 'Ficou resolvido no ato da orçamentação' para ${value == true ? 'Sim' : 'Não'}?`).then(r => {
      if (r) {
        this.OblProcesso.prototype$updateAttributes(
          { id: this.params.ficha },
          {
            extra: value
          }
        ).$promise.then((_) => {
          this.UI.addToast("Resolvido no ato da orçamento alterado com sucesso");
          this.processo.extra = value;
        }).catch(e => {
          this.UI.addToast("Não foi possível alterar propriedade");
        })
      }
    });
  }

  values = () => {
    let data = angular.copy(this.processo);
    this.UI.showDialog({
      template: require('./values.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.data = {
          maoObra: data.maoObra,
          deslocacao: data.deslocacao
        }

        $scope.ok = () => {
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(r => {
      if (r) {
        this.OblProcesso.prototype$updateAttributes(
          { id: this.params.ficha },
          r
        ).$promise.then((_) => {
          this.UI.addToast("Valores alterados com sucesso");
          this.processo.deslocacao = r.deslocacao;
          this.processo.maoObra = r.maoObra;
        }).catch(e => {
          this.UI.addToast("Não foi possível alterar valores");
        })
      }
    }).catch(e => {
      console.log(e);
    });
  }

  motive = () => {
    let data = angular.copy(this.info.motivo);
    this.UI.showDialog({
      template: require('./motive.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.data = data;

        $scope.ok = () => {
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(r => {
      if (r) {
        this.OblOrdemintervencao.prototype$updateAttributes(
          { id: this.params.id },
          {
            motivo: r
          }
        ).$promise.then((_) => {
          this.UI.addToast("Motivo da intervenção alterado com sucesso");
          this.info.motivo = r;
        }).catch(e => {
          this.UI.addToast("Não foi possível alterar motivo da intervenção");
        })
      }
    }).catch(e => {
      console.log(e);
    })
  }

  schedule = () => {
    if (this.processo.validado) {
      this.UI.addToast("Folha de trabalho concluída, impossível alterar agendamento");
      return;
    }
    let o;
    if (this.processo.Agendamento == undefined) {
      o = {
        id: 0,
        processoId: this.processo.id,
        data: moment.utc(),
        hora: moment.utc(),
        agendadoa: moment.utc(),
        agendadoporId: this.Auth.getId(),
        active: true,
        duracao: 60
      }
    } else {
      o = {
        id: this.processo.Agendamento.id,
        processoId: this.processo.id,
        data: moment.utc(this.processo.Agendamento.data),
        hora: moment.utc(this.processo.Agendamento.hora, "HH:mm:00"),
        agendadoa: moment.utc(this.processo.Agendamento.agendadoa),
        agendadoporId: this.processo.Agendamento.agendadoporId,
        tecnicoId: this.processo.Agendamento.tecnicoId,
        active: true,
        duracao: this.processo.Agendamento.duracao
      }
    }
    this.UI.showDialog({
      template: require('./schedule.dialog.html'),
      controller: ['$scope', 'FuncionarioEntidadeProprietaria', ($scope, FuncionarioEntidadeProprietaria) => {

        $scope.dateOptions = {
          minDate: moment().subtract(1, 'w'),
          format: 'YYYY-MM-DD'
        }

        $scope.hourOptions = {
          format: 'HH:mm'
        }
        if (o.id > 0 && o.hora.isDST()) {
          o.hora.subtract(1, 'hour');
        }


        $scope.data = o;



        $scope.aux = {};

        FuncionarioEntidadeProprietaria.find({
          filter: {
            where: {
              entidadeProprietariaId: 3,
              active: true
            },
            include: {
              relation: 'funcionario'
            }
          }
        }).$promise.then(f => {
          $scope.users = [];
          f.forEach(r => {
            if (r.funcionario) {
              $scope.users.push(r.funcionario);
            }
          });
          $scope.users = $scope.users.sort((a, b) => a.name.localeCompare(b.name));

          $scope.aux.user = $scope.users.find(r => r.id == o.tecnicoId);

          $scope.loaded = true;
        });

        $scope.ok = () => {
          $scope.data.tecnicoId = $scope.aux.user.id;
          $scope.data.Funcionario = $scope.aux.user;
          $scope.data.hora = $scope.data.hora.format("HH:mm")
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(res => {
      if (res) {
        this.OblAgendamento.upsert(res).$promise.then(r => {
          r.Funcionario = res.Funcionario;
          this.UI.addToast("Agendamento alterado com sucesso");
          this.processo.Agendamento = r;
        });
      }
    })
  }

  pickMotivo = () => {
    let data = angular.copy(this.processo.Motivo);
    this.UI.showDialog({
      template: require('./pickMotivo.dialog.html'),
      controller: ['$scope', 'OblMotivo', ($scope, OblMotivo) => {
        $scope.data = data;
        $scope.loaded = false;
        $scope.list = [];

        OblMotivo.find({
          filter: {
            where: {
              realizada: this.processo.realizada,
              active: true
            }
          }
        }).$promise.then(r => {
          if (r.length == 0) {
            $scope.$close();
          }
          $scope.loaded = true;
          $scope.list = r;
        })

        $scope.ok = () => {
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(r => {
      if (r != null) {
        this.processo.motivoId = r.id;
        this.processo.Motivo = r;
        this.OblProcesso.prototype$updateAttributes(
          { id: this.params.ficha },
          {
            realizada: this.processo.realizada,
            motivoId: this.processo.motivoId
          }
        ).$promise.then((_) => {
          this.UI.addToast("Visita realizada alterado com sucesso");
        }).catch(e => {
          this.UI.addToast("Não foi possível alterar se visita foi realizada");
        })
      } else {
        this.OblProcesso.prototype$updateAttributes(
          { id: this.params.ficha },
          {
            realizada: this.processo.realizada,
          }
        ).$promise.then((_) => {
          this.UI.addToast("Visita realizada alterado com sucesso");
        }).catch(e => {
          this.UI.addToast("Não foi possível alterar se visita foi realizada");
        })
      }
    });
  }

  observations = () => {
    let data = angular.copy(this.processo.observacoes);
    this.UI.showDialog({
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.data = data;

        $scope.ok = () => {
          $scope.$close($scope.data);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }
      }]
    }).then(r => {
      if (r) {
        this.OblProcesso.prototype$updateAttributes(
          { id: this.params.ficha },
          {
            observacoes: r
          }
        ).$promise.then((_) => {
          this.UI.addToast("Observações alteradas com sucesso");
          this.processo.observacoes = r;
        }).catch(e => {
          this.UI.addToast("Não foi possível alterar observações");
        })
      }
    }).catch(e => {
      console.log(e);
    })
  }

  delete = (row) => {
    this.UI.showConfirm(`Deseja eliminar ${row.Produto.designacao}?`).then(r => {
      if (r) {
        this.OblProdutoProcesso.prototype$updateAttributes(
          { id: row.id },
          { active: false }
        ).$promise.then((_) => {
          this.UI.addToast("Produto eliminado com sucesso");
          row.active = false;
        }).catch(e => {
          this.UI.addToast("Não foi possível eliminar produto");
        })
      }
    });
  }

  editReport = () => {
    this.isEditing = true;
  }

  generateReport = () => {
    window
      .open(`/api/oblprocessos/preview?id=${this.params.ficha}`, "_blank")
      .focus();

  };

  viewPic = p => {
    if (!p || !p.fotografia)
      return;
    this.UI.showDialog({
      template: require('./photo.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.photo = p.fotografia;

        $scope.ok = () => { $scope.$close(); }
      }]
    });
  }

  label = () => {
    if (!this.loaded) {
      return "";
    }
    if (this.processo.tipoId == 2 && !this.processo.extra) {
      return "Orçamento";
    }
    return "Folha de Obra";

  }
}

OblOrcamentacaoController.$inject = ['$state', 'OblProdutoProcesso', 'OblProcessoFotografia', 'OblProcessoExtras', 'OblProcesso', 'OblMotivo', 'OblAlteracaoestado', 'OblOrdemintervencao', 'OblAgendamento', 'UIService', 'AuthenticationService'];
