export default class FormacaoCreateFormandoController {
  constructor(UIService, $http, $state, $scope, FormacaoAreaCategoria, FormacaoFormando, FormacaoEmpresa, FormacaoTipoDocumento, CorePaises, CoreDistritos, CoreConcelho, FormacaoFormandoAreasInteresse, FormacaoFormandoEmpresa) {
    this.UI = UIService;
    this.state = $state;
    this.loaded = false;
    this.Formando = FormacaoFormando;
    this.FormacaoTipoDocumento = FormacaoTipoDocumento;
    this.FormacaoFormandoAreasInteresse = FormacaoFormandoAreasInteresse;
    this.FormacaoFormandoEmpresa = FormacaoFormandoEmpresa;
    this.FormacaoEmpresa = FormacaoEmpresa;
    this.FormacaoAreaCategoria = FormacaoAreaCategoria;
    this.CorePaises = CorePaises;
    this.concelhos = null;
    this.CoreConcelho = CoreConcelho;
    this.CoreDistritos = CoreDistritos;
    this.countryfailed = false;

    this.getEmpresas();

    $scope.$watch(() => {
      return this.data;
    }, (val) => {
      if (angular.isDefined(val)) {
        localStorage.setItem('formandoForm', JSON.stringify(val));
      }
    }, true);
  }

  updateConcelhos = (item, clear) => {
    this.filteredConcelhos = this.concelhos.filter(r => r.distritoId === item.id);
    if (!clear)
      this.auxConcelho = null;
  };


  getEmpresas = () => {
    this.FormacaoEmpresa.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(empresas => {
      this.empresas = empresas;
      this.getTipoDocumento();
    }).catch(() => {
      this.UI.addToast('Ocorreu um erro ao carregar empresas, por favor atualize a página!');
    });
  };

  getPaises = () => {
    this.CorePaises.find({
      filter: {
        where: {
          active: 1
        },
      }
    }).$promise.then(res => {
      if (angular.isDefined(res) && res.length > 0) {
        this.paises = res;
      }
      this.getConcelhos();
    }).catch(err => {
      console.log(err);
    });
  };

  getConcelhos = () => {
    this.CoreConcelho.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then(res => {
      this.concelhos = res;
      this.getDistritos();
    }).catch(err => {
      console.log(err);
    });
  };

  getDistritos = () => {
    this.CoreDistritos.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then(res => {
      this.distritos = res;
      this.getAreasCategoria();
    }).catch(err => {
      console.log(err);
    });
  };

  //Pai = 0 -> Tipo do documento de identificação
  getTipoDocumento = () => {
    this.FormacaoTipoDocumento.find({
      filter: {
        where: {
          active: 1,
          pai: 0
        }
      }
    }).$promise.then(res => {
      if (angular.isDefined(res) && res.length > 0) {
        this.tipoDocumento = res;
      }
      this.getSubTipoDocumento();
    }).catch(err => {
      console.log(err);
    });
  };

  //Pai = 3 -> Sub-tipo do documento de identificação
  getSubTipoDocumento = () => {
    this.FormacaoTipoDocumento.find({
      filter: {
        where: {
          active: 1,
          pai: 3
        }
      }
    }).$promise.then(res => {
      if (angular.isDefined(res) && res.length > 0) {
        this.subTipoDocumento = res;
      }
      this.getEmitidoPorIdentificacaoMilitar();
    }).catch(err => {
      console.log(err);
    });
  };

  //Pai = 4 -> "Emitido por" quando o documento de identificação é "Identificação Militar"
  getEmitidoPorIdentificacaoMilitar = () => {
    this.FormacaoTipoDocumento.find({
      filter: {
        where: {
          active: 1,
          pai: 4
        }
      }
    }).$promise.then(res => {
      if (angular.isDefined(res) && res.length > 0) {
        this.emitidoPorIdMilitar = res;
      }
      this.getEmitidoPorAutorizacaoResidencia();
    }).catch(err => {
      console.log(err);
    });
  };

  //Pai = 5 -> "Emitido por" quando o documento de identificação é "Autorização de Residência"
  getEmitidoPorAutorizacaoResidencia = () => {
    this.FormacaoTipoDocumento.find({
      filter: {
        where: {
          active: 1,
          pai: 5
        }
      }
    }).$promise.then(res => {
      if (angular.isDefined(res) && res.length > 0) {
        this.emitidoPorAuResidencia = res;
      }
      this.getPaises();
    }).catch(err => {
      console.log(err);
    });
  };


  getAreasCategoria = () => {
    this.FormacaoAreaCategoria.find({filter: {where: {active: true}}}).$promise
      .then(res => {
        this.areas = res;
        this.loadDataFromStorage();
        this.loaded = true;
      })
      .catch(() => {
        this.error = true;
        this.UI.addToast('Ocorreu um erro a carregar áreas de interesse');
        this.loadDataFromStorage();
      });
  };

  loadDataFromStorage = () => {
    if (localStorage.getItem('formandoForm')) {
      this.data = JSON.parse(localStorage.getItem('formandoForm'));
      if (angular.isDefined(this.data.auxDistrito)) {
        this.updateConcelhos(this.data.auxDistrito, true);
      }
    } else {
      this.data = {
        form: 1,
        details: {},
        company: {}
      }
    }

    this.state.transitionTo('app.formacao.formandos.new.' + this.data.form);
  };

  onSelectPais = (item, prop) => {
    this.data.details[prop] = item.name;
  };

  setForm = num => {
    this.data.form = num;
    this.state.transitionTo('app.formacao.formandos.new.' + this.data.form);
  };

  submit = () => {
    if (this.isValid()) {
      // Ask for data treatment (GDPR)
      let dialog = this.UI.showDialog({
        size: 'lg',
        template: require('./gdpr.dialog.html'),
        controller: ['$scope', function ($scope) {

          $scope.data = {
            divulgacao: false,
            dgert: false
          };

          $scope.cancel = () => {
            $scope.$dismiss('cancel');
          };

          $scope.ok = () => {
            $scope.$close($scope.data);
          }
        }]
      });
      dialog.then(res => {
        // Register "formando"
        let documentoEmitidoPor;
        if (this.data.auxTipoDocumento.selected.id === 3) {
          documentoEmitidoPor = this.data.auxEmitido.selected.id;
        } else if (this.data.auxTipoDocumento.selected.id === 2) {
          documentoEmitidoPor = this.data.auxEmitido.selected.id;
        } else {
          documentoEmitidoPor = null;
        }

        this.Formando.create({
          id: 0,
          nome: this.data.details.nome,
          sexo: this.data.auxSexo.selected.id,
          dataNascimento: this.data.details.dataNascimento,
          nacionalidade: this.data.auxNacionalidade ? this.data.auxNacionalidade.selected.id : null,
          naturalidade: this.data.details.naturalidade,
          paisOrigem: this.data.auxPaisOrigem ? this.data.auxPaisOrigem.selected.id : null,
          concelho: this.data.auxConcelho ? this.data.auxConcelho.selected.id : null,
          documentoTipo: this.data.auxTipoDocumento.selected.id,
          documentoSubTipo: this.data.auxSubtipo ? this.data.auxSubtipo.selected.id : null,
          documentoEmitidoPor: documentoEmitidoPor ? documentoEmitidoPor : null,
          documentoId: this.data.details.bi,
          documentoValidade: this.data.details.validade,
          nif: this.data.details.nif || null,
          morada: this.data.details.morada,
          cursoHabilitacoes: this.data.details.habilitacoes,
          codigoIdentificacao: this.data.details.codigoIdentificacao,
          cp4: this.data.details.cp4,
          cp3: this.data.details.cp3,
          telefone: this.data.details.telefone,
          localidade: this.data.details.localidade,
          email: this.data.details.email,
          cursoConclusao: this.data.details.dataConclusao,
          observacoes: this.data.details.observacoes,
          dataInscricao: this.data.details.dataInscricao,
          divulgacao: res.divulgacao,
          dgert: res.dgert,
          active: true
        }).$promise.then(formando => {
          if (angular.isDefined(this.data.auxInterest)) {
            this.data.auxInterest.forEach(i => {
              this.FormacaoFormandoAreasInteresse.create({
                id: 0,
                formandoId: formando.id,
                areacategoriaId: i.id
              });
            });
          }
          if (this.data.isEmpregado === true) {
            this.FormacaoFormandoEmpresa.create({
              id: 0,
              formandoId: formando.id,
              empresaId: this.data.auxEmpresa.selected.id,
              active: true
            });
          }
          localStorage.removeItem('formandoForm');
          //TODO: Áreas de Interesse
          this.state.go('app.formacao.formandos.list');
          this.UI.addToast('Formando adicionado com sucesso');
        }).catch((err) => {
          console.error(err);
          this.UI.addToast("Ocorreu um erro ao submeter formando");
        });
      });
    } else {
      this.UI.addToast("Preencha todos os campos de carácter obrigatório do formulário");
    }
  };

  isValid = () => {
    return this.data.details && this.data.details.nome && this.data.details.dataInscricao;
  }
}

FormacaoCreateFormandoController.$inject = ['UIService', '$http', '$state', '$scope', 'FormacaoAreaCategoria', 'FormacaoFormando', 'FormacaoEmpresa', 'FormacaoTipoDocumento', 'CorePaises', 'CoreDistritos', 'CoreConcelho', 'FormacaoFormandoAreasInteresse', 'FormacaoFormandoEmpresa'];
