export default class FunOIListController {
  constructor($state, Group, FunOrdemintervencao, FunEstadoOrdemIntervencao, FunCliente, FunAlteracaoestadoOrdemIntervencao, UIService, AuthenticationService) {
    this.$state = $state;
    this.Group = Group;
    this.Auth = AuthenticationService;
    this.FunOrdemintervencao = FunOrdemintervencao;
    this.FunAlteracaoestadoOrdemIntervencao = FunAlteracaoestadoOrdemIntervencao;
    this.FunEstadoOrdemIntervencao = FunEstadoOrdemIntervencao;
    this.FunCliente = FunCliente;
    this.UI = UIService;

    this.estados = [];
    this.filtersLoaded = [0];

    this.opt = $state.params;

    // Default opt state - populate later when we have all states
    this.defaultOpt = {
      page: 1,
      items: 20,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    this.opt = this.opt || this.defaultOpt;

    this.displayColumns = [{
      displayName: 'ID SGI',
      name: 'id',
      visible: false,
      sortable: true,
      width: 80
    },
    {
      displayName: 'Nº OI',
      name: 'codigo',
      visible: true,
      sortable: true,
      width: 150
    },
    {
      displayName: 'Ano',
      name: 'iidAno',
      visible: false,
      sortable: true,
      width: 100
    },
    {
      displayName: 'Estado',
      name: 'estado',
      visible: true,
      sortable: true,
      width: 200
    },
    {
      displayName: 'Cliente',
      name: 'nomeCliente',
      visible: false,
      sortable: true,
      width: 200
    },
    {
      displayName: 'Data de Criação',
      name: 'dataCriacao',
      exportDate: 'DD-MM-YYYY HH:mm',
      visible: true,
      sortable: true,
      width: 200
    },
    {
      displayName: 'Última Alteração',
      name: 'ultimaAlteracao',
      exportDate: 'DD-MM-YYYY HH:mm',
      visible: true,
      sortable: true,
      width: 200
    },
    {
      displayName: 'Observações',
      name: 'observacoes',
      visible: true,
      sortable: true
    }
    ];

    this.columns = [
      {
        id: 'FunOrdemintervencao.id',
        name: 'ID SGI',
        type: 'o'
      },
      {
        id: 'FunOrdemintervencao.codigo',
        name: 'Nº OI',
        type: 'o'
      },
      {
        id: 'FunOrdemintervencao.iidAno',
        name: 'Ano',
        type: 'o'
      },
      {
        id: 'FunEstadoOrdemIntervencao.id',
        name: 'Estado',
        type: 's',
        list: this.estados
      },
      {
        id: 'FunOrdemIntervencao.dataCriacao',
        name: 'Data de Criação',
        type: 'd',
        format: "YYYY-MM-DD HH:mm",
        displayFormat: "DD/MM/YYYY HH:mm"
      },
      {
        id: 'FunOrdemIntervencao.ultimaAlteracao',
        name: 'Última Alteração',
        type: 'd',
        format: "YYYY-MM-DD HH:mm",
        displayFormat: "DD/MM/YYYY HH:mm"
      },
      {
        id: 'FunOrdemintervencao.observacoes',
        name: 'Observações',
        type: 'o'
      }
    ]

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 'o'
    },
    {
      val: 'c',
      name: 'Começa por',
      type: 'o'
    },
    {
      val: 'd',
      name: 'Termina com',
      type: 'o'
    },
    {
      val: 'e',
      name: 'Contém',
      type: 'o'
    },
    {
      val: 'a',
      name: 'Igual (=)',
      type: 'd'
    },
    {
      val: 'b',
      name: 'Diferente de (≠)',
      type: 'd'
    },
    {
      val: 'c',
      name: 'Posterior a (>)',
      type: 'd'
    },
    {
      val: 'd',
      name: 'Anterior a (<)',
      type: 'd'
    },
    {
      val: 'e',
      name: 'Posterior ou igual (≥)',
      type: 'd'
    },
    {
      val: 'f',
      name: 'Anterior ou igual (≤)',
      type: 'd'
    },
    {
      val: 'a',
      name: 'Igual',
      type: 's'
    },
    {
      val: 'b',
      name: 'Diferente',
      type: 's'
    }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    // Find relevant displayColumns from local storage
    if (localStorage.getItem('FunOrdemintervencaoDisplayColumns')) {
      let cols = JSON.parse(localStorage.getItem('FunOrdemintervencaoDisplayColumns'));
      if (cols && cols.length > 0) {
        cols.forEach(c => {
          let i = this.displayColumns.findIndex(x => x.name === c.name);
          if (i >= 0) this.displayColumns[i].visible = c.visible;
        });
      }
    }

    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('FunOrdemintervencaoFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('FunOrdemintervencaoFilter'));
    }

    // Check if something comes from the URL, replace the customFilters if so
    if (this.opt.filter) {
      this.customFilters = [];
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('FunOrdemintervencaoFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'FunOrdemintervencao.estadoId')
          f.column.selected.list = this.estados;
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value);
        f.value.second(0); // We don't care about seconds
      }
    });

    // Number of selected items
    this.nSelected = 0;
    this.everythingSelected = false;

    // Load the data for the filter panel (not mandatory just to look at the table)
    this.loadData();
    // Load the table data so we can see stuff even if nothing else works
    this.getOis();
  }

  clearFilter = () => {
    localStorage.removeItem('FunOrdemintervencaoFilter');
    this.customFilters = [];
    this.getOis();
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  loadData = () => {

    this.FunEstadoOrdemIntervencao.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then(r => {
      this.estados = [];
      r.forEach(a => {
        this.estados.push({
          id: a.id,
          name: a.designacao
        });
      });
      this.filtersLoaded[0] = 1;
    }).catch(e => {
      this.estados = [];
      this.filtersLoaded[0] = 0;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Estados).");
    });
  }

  getOis = () => {
    this.tableLoading = true;

    let whereFields = this.setWhereFields(this.customFilters, {
      'FunOrdemintervencao.active': 1
    });

    this.FunOrdemintervencao.table2({
      params: {
        fields: [
          'FunOrdemintervencao.id as id',
          'FunOrdemintervencao.codigo as codigo',
          'FunOrdemintervencao.iidAno as iidAno',
          'FunOrdemintervencao.dataCriacao as dataCriacao',
          'FunOrdemintervencao.ultimaAlteracao as ultimaAlteracao',
          'FunOrdemintervencao.estadoId as estadoId',
          'FunOrdemintervencao.observacoes as observacoes',
          'FunEstadoOrdemIntervencao.designacao as estado',
          'FunCliente.nome as nomeCliente'
        ],
        from: ['FunOrdemintervencao', 'FunEstadoOrdemIntervencao', 'FunCliente'],
        references: [undefined, 'FunOrdemintervencao.estadoId', 'FunOrdemintervencao.clienteId'],
        referencesOrigin: [undefined, undefined, undefined],
        aliases: [undefined, undefined, undefined],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      let total = res.count;

      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      // res.data.forEach(i => {
      // });

      this.ois = res.data;
      this.total = total;
      this.tableLoading = false;
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Erro na leitura de OIs. Verifique a ligação.");
    });
  };

  exportSelected = () => {
    let wait = this.UI.showWaiting();
    let orsLiteral = [];
    if (this.hasSelect() && !this.everythingSelected)
      orsLiteral = _.map(_.filter(this.ois, x => x.selected), x => { return { key: 'FunOrdemintervencao.id', value: x.id }; });
    let whereFields = this.setWhereFields(this.customFilters, { 'FunOrdemintervencao.active': 1 }, orsLiteral);
    this.FunOrdemintervencao.exportOis({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'FunOrdemintervencao.id as id',
          'FunOrdemintervencao.codigo as codigo',
          'FunOrdemintervencao.iidAno as iidAno',
          'FunOrdemintervencao.dataCriacao as dataCriacao',
          'FunOrdemintervencao.ultimaAlteracao as ultimaAlteracao',
          'FunOrdemintervencao.estadoId as estadoId',
          'FunOrdemintervencao.observacoes as observacoes',
          'FunEstadoOrdemIntervencao.designacao as estado',
          'FunCliente.nome as nomeCliente'
        ],
        from: ['FunOrdemintervencao', 'FunEstadoOrdemIntervencao', 'FunCliente'],
        references: [undefined, 'FunOrdemintervencao.estadoId', 'FunOrdemintervencao.clienteId'],
        referencesOrigin: [undefined, undefined, undefined],
        aliases: [undefined, undefined, undefined],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'OIsExportadas.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.UI.addToast("Não foi possível exportar ordens de intervenção. Por favor tente mais tarde.");
    });
  };

  exportOis = () => {
    let wait = this.UI.showWaiting();
    let whereFields = this.setWhereFields(this.customFilters, { 'FunOrdemintervencao.active': 1 });
    this.FunOrdemintervencao.exportOis({
      displayColumns: this.displayColumns,
      params: {
        fields: [
          'FunOrdemintervencao.id as id',
          'FunOrdemintervencao.codigo as codigo',
          'FunOrdemintervencao.iidAno as iidAno',
          'FunOrdemintervencao.dataCriacao as dataCriacao',
          'FunOrdemintervencao.ultimaAlteracao as ultimaAlteracao',
          'FunOrdemintervencao.estadoId as estadoId',
          'FunOrdemintervencao.observacoes as observacoes',
          'FunEstadoOrdemIntervencao.designacao as estado',
          'FunCliente.nome as nomeCliente'
        ],
        from: ['FunOrdemintervencao', 'FunEstadoOrdemIntervencao', 'FunCliente'],
        references: [undefined, 'FunOrdemintervencao.estadoId', 'FunOrdemintervencao.clienteId'],
        referencesOrigin: [undefined, undefined, undefined],
        aliases: [undefined, undefined, undefined],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      if (res.count) {
        wait.close();
        let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
        let b = document.createElement('a');
        b.href = url;
        b.download = 'OIsExportadas.xlsx';
        b.click();
      } else {
        this.UI.addToast("Nenhum resultado encontrado. A exportação foi cancelada.");
      }
    }).catch((e) => {
      wait.close();
      console.log(e);
      this.tableLoading = false;
      this.UI.addToast("Não foi possível exportar ordens de intervenção. Por favor tente mais tarde.");
    });
  };

  adminExport = () => {
    this.UI.pickDateRange({
      format: "YYYY-MM-DD"
    }, "Informação exportada relativa ao período escolhido").then(r => {
      if (r) {
        let dialog = this.UI.showWaiting();
        this.FunOrdemintervencao.downloader({ from: r.from.format("YYYY-MM-DD"), to: r.to.format("YYYY-MM-DD") }).$promise.then(res => {
          if (res.count > 0) {
            let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.fileData;
            let b = document.createElement('a');
            b.href = url;
            b.download = 'export.xlsx';
            b.click();
            dialog.close();
            this.UI.addToast(`Exportação efetuada com informação relativa a ${res.count} processos`);
          } else {
            dialog.close();
            this.UI.addToast("Não existe informação relativa à semana escolhida");
          }
        }).catch(e => {
          dialog.close();
          this.UI.addToast("Não foi possível exportar informação");
        });
      }
    }).catch();
  };

  sort = key => {
    if (!key.sortable) {
      return;
    }
    let keyname = key.name;
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.funciona.oi.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getOis();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.funciona.oi.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getOis();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.total / this.opt.items))
      this.opt.page = Math.ceil(this.total / this.opt.items);
    this.$state.go('app.funciona.oi.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getOis();
  };

  openFilter = () => {
    let result = this.filtersLoaded.reduce((a, b) => a + b, 0);

    if (result !== this.filtersLoaded.length) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    if (!this.estados.length) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    this.columns.forEach(f => {
      if (f.id === 'FunOrdemintervencao.estadoId')
        f.list = this.estados;
    });

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'FunOrdemintervencao.estadoId')
          f.column.selected.list = this.estados;
      }
    });
    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
      // Fix date filters to be moment()
      if (f.column.selected.type === 'd') {
        f.value = moment(f.value);
        f.value.second(0); // We don't care about seconds
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('FunOrdemintervencaoFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    // Go to first page for results
    this.opt.page = 1;
    this.$state.go('app.funciona.oi.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getOis();
  };

  isColumnVisible = (column) => {
    let f = this.displayColumns.find(x => x.name === column);
    return _.isEmpty(f) ? false : f.visible;
  };

  selectVisibleColumns = () => {
    let options = {
      size: 'md',
      template: require('./columns.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Campos Visíveis";
        scope.displayColumns = angular.copy(this.displayColumns);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.displayColumns) {
        this.displayColumns = angular.copy(res.displayColumns);
        // Save it to local storage
        localStorage.setItem('FunOrdemintervencaoDisplayColumns', JSON.stringify(this.displayColumns));
      }
    });
  };

  hasSelect = () => {
    return _.some(this.ois, a => a.selected === true);
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.ois.forEach(a => {
        a.selected = true;
      });
      this.nSelected = this.ois.length;
    } else { // Remove all selections
      this.ois.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
    }
    this.everythingSelected = false;
  };

  selectEverything = (type) => {
    if (!type) {
      this.ois.forEach(a => {
        a.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
    }
    this.everythingSelected = !!type;
  };

  selectItem = () => {
    this.nSelected = _.filter(this.ois, r => r.selected).length;
    this.allSelected = this.nSelected >= this.ois.length;
    this.everythingSelected = false;
  };

}

FunOIListController.$inject = ['$state', 'Group', 'FunOrdemintervencao', 'FunEstadoOrdemIntervencao', 'FunCliente', 'FunAlteracaoestadoOrdemIntervencao', 'UIService', 'AuthenticationService']
