import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { MdiIcon } from '@components';
import { mdiDelete, mdiPlus } from '@mdi/js';
import { v4 } from 'uuid';
const tipos = [
    { id: 'NUMERO', label: 'Número', formato: 'Número casas decimais' },
    { id: 'TEXTO', label: 'Texto', formato: false },
    { id: 'DATA', label: 'Data', formato: 'Formato de data' },
    { id: 'LISTA', label: 'Caixa de seleção', formato: false }
];
const DialogEnsaio = ({ initial, onClose, ...props }) => {
    const validationSchema = yup.object({
        codigo: yup.string().required('O campo é obrigatório'),
        designacao: yup.string().required('O campo é obrigatório'),
        na: yup.boolean()
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            if (values.tipo == 'LISTA') {
                let o = formikCampos.values.items.map(r => r.valor);
                values.formato = JSON.stringify([...new Set(o)]);
            }
            console.log(values);
            onClose(values);
        }
    });
    const formikCampos = useFormik({
        initialValues: {
            items: []
        },
        validationSchema: yup.array().of(yup.object({ valor: yup.string().required('O campo é obrigatório') })),
        validateOnMount: false,
        onSubmit: d => { }
    });
    useEffect(() => {
        if (initial.tipo == 'LISTA') {
            let o = JSON.parse(initial.formato ?? '[]');
            let aux = [];
            o.forEach(r => {
                aux.push({
                    i: v4(),
                    valor: r
                });
            });
            formikCampos.setFieldValue('items', aux);
        }
        formik.setValues(initial);
    }, [initial]);
    return (<form>
      <Dialog {...props} maxWidth={'lg'} fullWidth>
        <DialogTitle>Adicionar ponto de verificação</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={2} md={4} xs={4}>
              <TextField label='Código' name='codigo' fullWidth defaultValue={formik.values?.codigo} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.codigo && Boolean(formik.errors.codigo)} helperText={formik.touched.codigo && formik.errors.codigo}/>
            </Grid>
            <Grid lg={10} md={8} xs={8}>
              <TextField label='Designação' name='designacao' defaultValue={formik.values?.designacao} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
            </Grid>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Tipo de dados' name='tipo' defaultValue={formik.values?.tipo} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.tipo && Boolean(formik.errors.tipo)} helperText={formik.touched.tipo && formik.errors.tipo} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {tipos.map(x => (<MenuItem value={x.id} key={x.id}>
                    {x.label}
                  </MenuItem>))}
              </TextField>
            </Grid>
            {formik.values?.tipo && formik.values?.tipo != 'TEXTO' && formik.values?.tipo != 'LISTA' && (<Grid lg={12} md={12} xs={12}>
                <TextField label={tipos.find(r => r.id == formik.values?.tipo)?.formato || 'Formato'} name='formato' defaultValue={formik.values?.formato} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.formato && Boolean(formik.errors.formato)} helperText={formik.touched.formato && formik.errors.formato}/>
              </Grid>)}
            {formik.values?.tipo && formik.values?.tipo == 'LISTA' && (<Grid lg={12} md={12} xs={12}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Linha</TableCell>
                      <TableCell sx={{ width: '50px' }}>
                        <IconButton onClick={() => {
                formikCampos.setFieldValue('items', [
                    ...formikCampos.values.items,
                    {
                        i: v4(),
                        valor: ''
                    }
                ]);
            }}>
                          <MdiIcon path={mdiPlus}/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formikCampos.values.items?.map((r, index) => (<TableRow key={r.i}>
                        <TableCell>
                          <TextField size='small' name={`items.${index}.valor`} fullWidth defaultValue={r.valor} onChange={formikCampos.handleChange} onBlur={formikCampos.handleBlur} error={formikCampos.touched?.items?.[index]?.valor && Boolean(formikCampos.errors?.items?.[index])}/>
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => {
                    const l = [...formikCampos.values.items];
                    l.splice(index, 1);
                    formikCampos.setFieldValue('items', l);
                }}>
                            <MdiIcon path={mdiDelete}/>
                          </IconButton>
                        </TableCell>
                      </TableRow>))}
                  </TableBody>
                </Table>
              </Grid>)}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogEnsaio;
