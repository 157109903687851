export default class DetailPedidosElevadoresController {
  constructor($http, $state, $q, FileUploader, $timeout, $filter, AuthorizationService, AuthenticationService, ElvListaVerificacao, CoreConcelho, ElvElevadorTipo, ElvEquipamentoTipo, ElvRelatorio, PRIClientes, Funcionario, ElvDocumentoTipo, Group, ElvEstado, ElvProcesso, ElvEmie, ElvCondominio, ElvElevador, ElvCliente, ElvAlteracaoEstado, ElvAgendamento, ElvProcessoDocumento, $stateParams, UIService, ElvProcessoService) {
    this.$q = $q;
    this.ElvProcesso = ElvProcesso;
    this.ElvCondominio = ElvCondominio;
    this.ElvCliente = ElvCliente;
    this.ElvElevador = ElvElevador;
    this.ElvAlteracaoEstado = ElvAlteracaoEstado;
    this.Funcionario = Funcionario;
    this.ElvAgendamento = ElvAgendamento;
    this.ElvProcessoDocumento = ElvProcessoDocumento;
    this.ElvDocumentoTipo = ElvDocumentoTipo;
    this.ElvElevadorTipo = ElvElevadorTipo;
    this.ElvRelatorio = ElvRelatorio;
    this.ElvListaVerificacao = ElvListaVerificacao;
    this.ElvEquipamentoTipo = ElvEquipamentoTipo;
    this.CoreConcelho = CoreConcelho;
    this.AuthorizationService = AuthorizationService;
    this.user = AuthenticationService.getUser();
    this.Auth = AuthenticationService;
    this.ElvEmie = ElvEmie;
    this.file = null;
    this.FileUploader = FileUploader;
    this.ElvEstado = ElvEstado;
    this.Group = Group;
    this.PRIClientes = PRIClientes;
    this.UI = UIService;
    this.ElvProcessoService = ElvProcessoService;
    this.$timeout = $timeout;
    this.$filter = $filter;
    this.loaded = false;
    this.id = $stateParams.id;
    this.$http = $http;
    this.$state = $state;
    this.estados = null;
    this.images = null;
    this.limit = 3;
    this.filterRevisions = true;
    this.removableFileTypeIndexes = [1, 2, 3, 4, 5, 8]; // Ids de documentos editáveis + imagens

    // HereMap Stuff
    this.mapLoading = true;
    this.hereOptions = {
      width: 500,
      height: 300,
      draggable: true,
      resize: true,
      zoom: 18,
      maxZoom: 2,
      coords: {
        latitude: 0,
        longitude: 0,
      },
    };
    this.hereMarkers = [];

    // Variables to trigger map refresh when loading
    this.instalacaoMapLoaded = false;
    this.instalacaoMapDataLoaded = false;

    this.hereMap = null;
    //Infinite Scroll Magic
    this.infiniteScroll = {};
    this.infiniteScroll.numToAdd = 20;
    this.infiniteScroll.currentItems = 20;

    this.data = null;

    this.hasPerms().then(() => {
      this.getProcesso();
      this.getEstadosProcesso();
    }).catch(() => {
      $state.go($state.previous.name, $state.previous.params);
    });
  }

  hasPerms = () => {
    let defer = this.$q.defer();
    if (this.AuthorizationService.canPerform('elvListarTodosProcessos')) {
      defer.resolve(true);
    } else {
      this.ElvProcesso.findOne({
        filter: {
          fields: { id: true },
          where: {
            id: this.id
          },
          include: {
            relation: 'Agendamento',
            scope: {
              fields: { tecnicoId: true },
              where: {
                active: 1
              }
            }
          }
        }
      }).$promise.then((p) => {
        if (p && p.Agendamento && p.Agendamento.tecnicoId === this.user.id) {
          defer.resolve(true);
        } else {
          defer.reject(false);
          this.UI.addToast("Sem permissões para ver o processo");
        }
      }).catch(() => {
        defer.reject(false);
        this.UI.addToast("Processo não encontrado");
      });
    }
    return defer.promise;
  };

  // Infinite Scroll
  addMoreItems = () => {
    this.infiniteScroll.currentItems += this.infiniteScroll.numToAdd;
  };

  showRecentRevision = () => {
    if (this.relatorioIndex < this.data.relatorios.length - 1) {
      this.relatorioIndex++;
    }
  };

  showPreviousRevision = () => {
    if (this.relatorioIndex > 0) {
      this.relatorioIndex--;
    }
  };

  getProcesso = () => {
    this.loaded = false;
    if (this.id) {
      this.ElvProcesso.findOne({
        filter: {
          where: {
            active: 1,
            id: this.id
          },
          include: [
            {
              relation: "cliente",
              scope: {
                where: {
                  active: true
                },
                include: ["tipo"]
              }
            },
            {
              relation: "estado",
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: "artigo",
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: "Historico",
              scope: {
                where: {
                  active: true
                },
                order: "dataAlteracao desc",
                // limit: 3,
                include: ["EstadoInicial", "EstadoFinal", "Funcionario"]
              },
            },
            {
              relation: "emie",
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: "condominio",
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: "Agendamento",
              scope: {
                where: {
                  active: true
                },
                include: "Funcionario",
                scope: {
                  where: {
                    active: true
                  }
                }
              }
            },
            {
              relation: "elevadorPed",
              scope: {
                where: {
                  active: true
                },
                include: [
                  {
                    relation: "equipamento",
                    scope: {
                      where: {
                        active: true
                      }
                    }
                  },
                  {
                    relation: "tipo",
                    scope: {
                      where: {
                        active: true
                      }
                    }
                  },
                  {
                    relation: "concelho",
                    scope: {
                      where: {
                        active: true
                      }
                    }
                  }
                ]
              }
            },
            {
              relation: "Documentacao",
              scope: {
                where: {
                  active: true
                },
                include: "TipoDocumento"
              }
            },
            {
              relation: "Ordemintervencao",
              scope: {
                where: {
                  active: true
                }
              }
            },
            {
              relation: "relatorios",
              scope: {
                include: {
                  relation: 'lista',
                  scope: {
                    include: 'norma'
                  },
                  order: "versao ASC"
                }
              }
            }
          ]
        }
      }).$promise.then((res) => {
        this.data = res;

        // IF relatorios
        if (this.data.relatorios.length > 0) {
          this.relatorioIndex = this.data.relatorios.length - 1;
        }

        res.Documentacao.forEach((doc) => {
          this.$http.get("/api/upload/elv/files/" + doc.nome).then((result) => {
            if (result.data != null && !result.data.hasOwnProperty("error")) {
              doc.info = result.data;
            }
          }, (err) => {
            console.log(err);
          });
        });

        if (this.data.elevadorPed) {
          // We need to parse morada to exclude extraneous chars. Use global filter
          let parseMorada = this.$filter("removeAcentos")(
            this.data.elevadorPed.morada
          );
          parseMorada = parseMorada.replace(/[^a-zA-Z0-9]/g, " ");
          // Get map coordinates for CPs
          let mapsUrl =
            "/api/codigopostal?cp1=" + this.data.elevadorPed.cp4 +
            "&cp2=" + this.data.elevadorPed.cp3 +
            "&cp3=" + (this.data.elevadorPed.distrito ? this.data.elevadorPed.distrito : this.data.elevadorPed.localidade) +
            "&gps=1&morada=" + parseMorada;

          this.$http.get(mapsUrl).then((result) => {
            if (result.data != null && !result.data.hasOwnProperty("error")) {
              this.instalacaoLat = result.data.lat;
              this.instalacaoLng = result.data.lng;
              this.instalacaoRelevance = result.data.relevance;
              this.instalacaoMapDataLoaded = true;
              if (this.instalacaoMapLoaded) {
                this.onMapDataReady(this.instalacaoLat, this.instalacaoLng, this.instalacaoRelevance);
              }
            } else {
              // Error happened, don't show map...
              this.instalacaoMapDataLoaded = false;
            }
          }, (err) => {
            console.log(err);
          });
        }
        this.loaded = true;
      }).catch((err) => {
        console.log(err);
        this.$state.go("app.elv.processos.list");
        this.UI.addToast("Não foi possível obter esse processo. Verifique os dados");
      });
    } else {
      this.UI.addToast("Não foi possível obter esse processo. Verifique os dados");
      this.$state.go("app.elv.processos.list");
    }
  };

  onMapReady = (heremap) => {
    this.hereMap = heremap;
    this.instalacaoMapLoaded = true;
    if (this.instalacaoMapDataLoaded)
      this.onMapDataReady(
        this.instalacaoLat,
        this.instalacaoLng,
        this.instalacaoRelevance
      );
  };

  // Called when we have both a map and the data to show it on
  onMapDataReady = (lat, lng, relevance) => {
    if (this.hereMap != null) {
      this.mapLoading = false;
      let localMap = this.hereMap;

      // Using timeout so that we render it after it becomes visible...
      this.$timeout(() => {
        localMap.refresh();
        this.hereMap.setCenter({
          lat: lat,
          lng: lng,
        });
        // If we are not exactly sure, zoom out a little more
        if (relevance < 0.85) this.hereMap.setZoom(14);
        else {
          // Add marker and zoom if we are reasonably sure...
          this.hereMap.setZoom(18);
          this.hereMarkers = [];
          this.hereMarkers.push({
            pos: {
              lat: lat,
              lng: lng,
            },
          });
          this.hereMap.updateMarkers(this.hereMarkers);
        }
      }, 500);
    }
  };

  // Format bytes into correct units
  formatBytes = (bytes, decimals) => {
    if (bytes == null) return "N/D";
    if (bytes === 0) return "0 Bytes";
    let k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  limitTo = () => {
    if (angular.isDefined(this.limit)) {
      this.limit = undefined;
    } else {
      this.limit = 3;
    }
  };

  editarAgendamento = () => {
    let cenas = this.UI.showWaiting();
    this.Group.findOne({
      filter: {
        where: {
          name: {
            like: "Inspetor%ELV",
          },
        },
        include: {
          relation: "usergroup",
          scope: {
            include: "user",
          },
        },
      },
    }).$promise.then((g) => {
      let funcionarios = [];
      g.usergroup.forEach((u) => {
        funcionarios.push(u.user);
      });

      this.ElvProcesso.findOne({
        filter: {
          where: {
            id: this.id
          },
          include: [
            {
              relation: "Agendamento",
              scope: {
                where: {
                  active: 1
                },
                include: {
                  relation: 'Funcionario',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }
              }
            },
            {
              relation: "Funcionario",
              scope: {
                where: {
                  active: 1
                }
              }
            }
          ]
        }
      }).$promise.then((p) => {
        cenas.close();
        if (p.estadoId === 2 && p.Agendamento != null && p.Funcionario != null && p.bloqueadoA == null && p.bloqueadoPor == null) { // 2 - Agendado
          let auxFuncionario = angular.copy(p.Funcionario);
          let options = {
            size: "md",
            template: require("./modal-edit-agendamento.html"),
            controller: ["$scope", "$dialog", ($scope, $dialog) => {
              $scope.numeroProcesso = p.numeroProcesso;
              $scope.agendamento = {
                data: p.Agendamento.data,
                hora: p.Agendamento.hora.substring(0, 5)
              };

              $scope.funcionarios = funcionarios;

              $scope.auxFuncionario = {
                selected: auxFuncionario,
                infiniteScroll: {
                  numToAdd: 20,
                  currentItems: 20
                }
              };

              // Infinite Scroll magic
              $scope.addMoreItems = (infiniteScroll) => {
                infiniteScroll.currentItems += infiniteScroll.numToAdd;
              };

              $scope.ok = () => {
                $dialog.close($scope.auxFuncionario);
              };

              $scope.cancel = () => {
                $dialog.dismiss("cancel");
              };
            }]
          };
          let modal = this.UI.showDialog(options);
          modal.then(ok => {
            if (ok != null && ok.selected.id !== p.inspetorId) {
              // Change ElvAgendamento object
              p.Agendamento.Funcionario = ok.selected;
              p.Agendamento.tecnicoId = ok.selected.id;
              p.Agendamento.agendadoa = new Date();
              p.Agendamento.agendadoporId = this.user.id;
              p.Agendamento.isUpdating = true; // Only used to trigger not sending email with agendamento - isNewInstance is not working
              this.ElvAgendamento.upsert(p.Agendamento).$promise.then(a => {
                p.Funcionario = ok.selected;
                p.inspetorId = ok.selected.id;
                this.ElvProcesso.upsert(p).$promise.then(proc => {
                  this.ElvAlteracaoEstado.create({
                    id: 0,
                    estadoInicial: 2, // Agendado
                    estadoFinal: 2, // Agendado
                    dataAlteracao: new Date(),
                    processoId: proc.id,
                    active: true,
                    funcionarioId: this.Auth.getId(),
                    observacoes: 'Alteração de Inspetor'
                  }).$promise.then((alteracao) => {
                    this.UI.addToast("Alteração de inspetor realizada com sucesso");
                    this.getProcesso();
                  }).catch((err) => {
                    console.log(err);
                    this.UI.addToast("Erro a atualizar o estado.");
                  });
                }).catch(err => {
                  console.log(err);
                  this.UI.addToast("Erro na gravação do agendamento. Tente novamente");
                });

              }).catch(err => {
                console.log(err);
                this.UI.addToast("Erro na gravação da atualização do agendamento.");
              });
            } else {
              this.UI.addToast("Agendamento sem alterações");
            }
          }).catch((err) => {
            console.log(err);
          });
        } else {
          this.UI.addToast("Não é possível alterar o inspetor neste processo. Verifique o estado do processo.");
        }
      }).catch(error => {
        this.UI.addToast("Não foi possível encontrar processo. Por favor recarregue a página");
        console.log(error);
        cenas.close();
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Erro na leitura de dados. Por favor tente novamente");
      cenas.close();
    });
  };

  setTimelineColor = (h) => {
    switch (h.EstadoFinal.designacao) {
      case "Por agendar":
      case "Para faturar":
        return "project-standby";
      case "Concluído":
      case "Agendado":
      case "Faturado":
        return "project-info";
      case "Cancelado":
      case "Suspenso":
        return "project-notification";
      default:
        return "project-notification";
    }
  };

  getDataProcesso = (type) => {
    if (this.data && this.data.Historico && this.data.Historico.length > 0) {
      for (let i = 0; i < this.data.Historico.length; i++) {
        if (this.data.Historico[i]) {
          if (type === 0 && this.data.Historico[i].dataInicio)
            return this.data.Historico[i].dataInicio;
          if (type === 1 && this.data.Historico[i].dataFim)
            return this.data.Historico[i].dataFim;
        }
      }
    }
    return null;
  };

  resyncClient = (id) => {
    this.ElvCliente.findOne({
      filter: {
        where: {
          id: id,
        },
      },
    }).$promise.then((c) => {
      if (!c.refClientePrimavera) {
        this.UI.addToast("Não foi possível atualizar cliente.");
      } else {
        this.PRIClientes.find({
          filter: {
            where: {
              cliente: c.refClientePrimavera,
            },
          },
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            c.nome = res[0].nome;
            c.nif = res[0].numcontrib;
            c.cp4 = res[0].facCp.split("-")[0];
            c.cp3 = res[0].facCp.split("-")[1];
            c.localidade = res[0].facCploc;
            c.telemovel = res[0].facTel;
            c.morada = res[0].facMor;
            c.observacoes = res[0].notas;
            c.atualizadoa = res[0].dataultimaactualizacao;
            c.refClientePrimavera = res[0].cliente;
            c.email = res[0].cduEmail;
            c.$save()
              .then((updatedCliente) => {
                this.getProcesso();
                this.UI.addToast("Sincronização realizada com sucesso.");
              })
              .catch((err) => {
                console.log(err);
                this.UI.addToast(
                  "Falha na gravação do cliente. Por favor tente mais tarde."
                );
              });
          } else {
            this.UI.addToast("Não foi possível atualizar cliente.");
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar cliente.");
        });
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar cliente.");
    });
  };

  // Resync process data for emie and condominio with values from emie and condominio (data from their tables)
  resyncEmie = () => {
    this.loaded = false;
    this.ElvElevador.findOne({
      filter: {
        where: {
          id: this.data.elevadorId
        },
        include: {
          relation: "emie",
          scope: {
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((elevador) => {
      if (elevador && elevador.emie) {
        this.data.emieId = elevador.emie.id;
        this.data.emie = elevador.emie;
        this.data.emieNome = elevador.emie.nome;
        this.data.emieNif = elevador.emie.nif;
        this.data.emieEmail = elevador.emie.email;
        this.data.emieEmail2 = elevador.emie.email2;
        this.data.emieEmail3 = elevador.emie.email3;
        this.data.emieTelemovel = elevador.emie.telemovel;
        this.data.emieMorada = elevador.emie.morada;
        this.data.emieCp4 = elevador.emie.cp4;
        this.data.emieCp3 = elevador.emie.cp3;
        this.data.emieLocalidade = elevador.emie.localidade;
        this.data.emieObservacoes = elevador.emie.observacoes;

        this.ElvProcesso.upsert(this.data).$promise.then((r) => {
          this.UI.addToast("Dados de EMIE atualizados");
          this.getProcesso();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Falha na gravação de alteração de EMIE. Tente novamente");
          this.getProcesso();
        });
      } else {
        this.UI.addToast("Não foi possível encontrar o EMIE do Elevador. Verifique os dados");
        this.loaded = true;
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast(
        "Não foi possível encontrar o Elevador. Verifique os dados"
      );
      this.loaded = true;
    });
  };

  resyncCondominio = () => {
    this.loaded = false;
    this.ElvElevador.findOne({
      filter: {
        where: {
          id: this.data.elevadorId
        },
        include: {
          relation: "condominio",
          scope: {
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((elevador) => {
      if (elevador && elevador.condominio) {
        this.data.condominioId = elevador.condominio.id;
        this.data.condominio = elevador.condominio;
        this.data.condominioNome = elevador.condominio.nome;
        this.data.condominioNif = elevador.condominio.nif;
        this.data.condominioEmail = elevador.condominio.email;
        this.data.condominioTelemovel = elevador.condominio.telemovel;
        this.data.condominioMorada = elevador.condominio.morada;
        this.data.condominioCp4 = elevador.condominio.cp4;
        this.data.condominioCp3 = elevador.condominio.cp3;
        this.data.condominioLocalidade = elevador.condominio.localidade;
        this.data.condominioObservacoes = elevador.condominio.observacoes;
        this.ElvProcesso.upsert(this.data).$promise.then((r) => {
          this.UI.addToast("Dados do Condomínio atualizados");
          this.getProcesso();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Falha na gravação de alteração de Condomínio. Tente novamente");
          this.getProcesso();
        });
      } else {
        this.UI.addToast("Não foi possível encontrar Condomínio. Verifique os dados");
        this.loaded = true;
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível encontrar Elevador. Verifique os dados");
      this.loaded = true;
    });
  };

  addAnexo = () => {
    let options = {
      size: "lg",
      controller: "ELVProcessFileUploaderController",
      controllerAs: "m",
      template: require("./files.dialog.html"),
      resolve: {
        pID: () => {
          return this.data.id;
        },
        file: () => {
          return undefined;
        }
      }
    };
    let dialog = this.UI.showDialog(options);
    dialog.then((res) => {
      if (res) {
        this.getProcesso();
        this.UI.addToast("Ficheiro carregado com sucesso!");
      }
    });
  };

  removeAnexo = (file) => {
    if (!this.removableFileTypeIndexes.includes(file.tipoDocumento)) { //tipoDocumento is an id
      this.UI.showAlert("Não é possível remover documentos deste tipo.");
      return;
    }

    let confirm = this.UI.showConfirm("Deseja remover o ficheiro?");
    confirm.then((res) => {
      if (res) {
        file.active = 0;
        this.ElvProcessoDocumento.upsert(file).$promise.then(() => {
          this.getProcesso();
          this.UI.addToast("O ficheiro foi removido com sucesso.");
        }).catch(err => {
          console.log(err);
          this.UI.addToast("Não foi possível remover o ficheiro.");
        });
      }
    });
  };

  generateUUID = () => {
    let uuid = "",
      i,
      random;
    for (i = 0; i < 32; i++) {
      random = (Math.random() * 16) | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
    }
    return uuid;
  };

  addImage = () => {
    let self = this;
    let options = {
      size: "lg",
      template: require("./images.dialog.html"),
      controller: ["$scope", "$dialog", function ($scope, $dialog) {
        $scope.images = {};
        $scope.label = "Adicionar imagem";
        $scope.newFileName = "";
        $scope.oldFileName = "";

        $scope.uuid = self.generateUUID();

        $scope.uploader = new self.FileUploader({
          url: "/api/upload/elv/upload",
          queueLimit: 1,
          formData: [
            {
              key: "value"
            }
          ]
        });

        let filter = {
          name: "verifyIMG",
          fn: function (item, options) {
            return item.type.indexOf("image/") !== -1;
          }
        };
        $scope.uploader.filters.push(filter);

        $scope.uploader.onAfterAddingFile = (item) => {
          let partes = item.file.name.split(".");
          $scope.newFileName = $scope.uuid + "." + partes[partes.length - 1];

          if ($scope.uploader.queue.length > 1)
            $scope.uploader.queue.splice(
              0,
              $scope.uploader.queue.splice.length - 1
            );
          $scope.oldFileName = $scope.uploader.queue[0].file.name;
        };

        $scope.uploader.onWhenAddingFileFailed = (img) => {
          self.UI.addToast("Por favor, carregue uma imagem");
        };

        $scope.uploader.onErrorItem = (response, status, headers) => {
          self.UI.addToast("A imagem não foi carregada com sucesso");
        };

        $scope.uploader.onBeforeUploadItem = (item) => {
          item.file.name = $scope.newFileName;
        };

        $scope.ok = () => {
          $dialog.close($scope);
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };
      }]
    };

    let dialogCreate = this.UI.showDialog(options);

    dialogCreate.then((ok) => {
      this.ElvProcessoDocumento.create({
        id: 0,
        processoId: this.id,
        tipoDocumento: 3,
        nome: ok.newFileName,
        nomeAntigo: ok.oldFileName,
        active: 1,
      }).$promise.then((res) => {
        if (ok.newFileName) {
          // File exists, upload it
          ok.uploader.uploadAll();
          this.getProcesso();
        } else {
          // No file, just update the list
          this.getProcesso();
        }
        this.UI.addToast("Imagem adicionada com sucesso!");
      }).catch((err) => {
        this.UI.addToast("Erro a adicionar imagem.");
        console.log(err);
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  changeState = () => {
    this.ElvProcessoService.changeStateMultiple([{id: this.data.id, estadoId: this.data.estadoId}], this.data.estadoId).then(() => {
      this.getProcesso();
    }).catch(() => {
      this.getProcesso();
    });
  };

  getEstadosProcesso = () => {
    this.ElvEstado.find({
      filter: {
        where: {
          active: true,
          id: {
            neq: 2
          }
        }
      }
    }).$promise.then((estados) => {
      this.estados = estados;
    });
  };

  editDetails = () => {
    let data = angular.copy(this.data);
    let cenas = this.UI.showWaiting();
    this.UI.showDialog({
      size: "lg",
      template: require("./details.dialog.html"),
      controller: ["$scope", "$dialog", "ElvProcessoArtigo", ($scope, $dialog, ElvProcessoArtigo) => {
        $scope.processo = {
          id: this.id,
          dataProcesso: data.dataProcesso,
          nip: data.nip,
          numeroProcesso: data.numeroProcesso,
          iidAno: data.iidAno,
          iidProc: data.iidProc,
          active: true
        };

        ElvProcessoArtigo.find({
          filter: {
            where: {
              active: true
            }
          }
        }).$promise.then((artigos) => {
          $scope.artigos = artigos;
          cenas.close();
        });

        $scope.auxArtigo = {
          selected: angular.copy(this.data.artigo),
        };

        $scope.ok = () => {
          // $scope.processo.clienteId = $scope.auxCliente.selected.id;
          $scope.processo.artigoId = $scope.auxArtigo.selected.id;
          $dialog.close($scope.processo);
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };
      }]
    }).then((res) => {
      if (res) {
        this.ElvProcesso.upsert(res).$promise.then(() => {
          this.getProcesso();
          this.loaded = false;
          this.UI.addToast("Processo atualizado com sucesso!");
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Ocorreu um erro a atualizar o processo.");
        });
      }
    }).catch(() => {});
  };

  addElevador = () => {
    let wait = this.UI.showWaiting();
    this.ElvElevador.find({
      filter: {
        where: {
          active: 1,
          clienteId: {
            eq: this.data.clienteId
          }
        },
        include: {
          relation: 'pedElev',
          scope: {
            fields: { id: true, estadoId: true, active: true },
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((elevadores) => {
      if (!elevadores) elevadores = [];
      for (let i = elevadores.length - 1; i >= 0; i--) {
        if (elevadores[i].pedElev && elevadores[i].pedElev.some(p => p.estadoId !== 3 && p.estadoId !== 4 && p.estadoId !== 7)) { // Not Cancelado nor Suspenso nor Concluído
          elevadores.splice(i, 1);
        }
      }
      wait.close();
      if (elevadores.length > 0) {
        this.UI.showDialog({
          template: require("./choose-elevador.dialog.html"),
          controller: ["$scope", ($scope) => {
            $scope.label = "Escolher Elevador";
            $scope.list = elevadores;

            $scope.elevador = {
              selected: null,
              infiniteScroll: { numToAdd: 20, currentItems: 20 },
            };

            //Infinite Scroll Magic
            $scope.addMoreItems = (infiniteScroll) => {
              infiniteScroll.currentItems += infiniteScroll.numToAdd;
            };

            $scope.ok = () => {
              $scope.$close($scope.elevador);
            };

            $scope.cancel = () => {
              $scope.$dismiss("cancel");
            };
          },
          ],
        }).then((elv) => {
          if (elv.selected && elv.selected.id) {
            this.loaded = false;
            this.ElvElevador.findOne({
              filter: {
                where: {
                  id: elv.selected.id,
                  active: 1
                },
                include: [
                  {
                    relation: "emie",
                    scope: {
                      where: {
                        active: 1
                      }
                    }
                  },
                  {
                    relation: "condominio",
                    scope: {
                      where: {
                        active: 1
                      }
                    }
                  },
                  {
                    relation: 'pedElev',
                    scope: {
                      fields: { id: true, estadoId: true, active: true },
                      where: {
                        active: 1
                      }
                    }
                  }
                ]
              }
            }).$promise.then((elev) => {
              // Check if it's ok to add elevador (if there is no process already)
              if (elev.pedElev && elev.pedElev.some(p => p.estadoId !== 3 && p.estadoId !== 4 && p.estadoId !== 7)) { // Not Cancelado nor Suspenso nor Concluído
                this.UI.showAlert("Não é possível escolher o elevador.\nJá existe um processo não concluído ou suspenso atribuído a este elevador de momento");
                this.loaded = true;
              } else {
                this.data.elevadorId = elev.id;
                // Update EMIE & Condominio with this elevador
                if (elev.emie) {
                  this.data.emieId = elev.emie.id;
                  this.data.emieNome = elev.emie.nome;
                  this.data.emieNif = elev.emie.nif;
                  this.data.emieEmail = elev.emie.email;
                  this.data.emieEmail2 = elev.emie.email2;
                  this.data.emieEmail3 = elev.emie.email3;
                  this.data.emieTelemovel = elev.emie.telemovel;
                  this.data.emieMorada = elev.emie.morada;
                  this.data.emieCp4 = elev.emie.cp4;
                  this.data.emieCp3 = elev.emie.cp3;
                  this.data.emieLocalidade = elev.emie.localidade;
                  this.data.emieObservacoes = elev.emie.observacoes;
                }
                if (elev.condominio) {
                  this.data.condominioId = elev.condominio.id;
                  this.data.condominioNome = elev.condominio.nome;
                  this.data.condominioNif = elev.condominio.nif;
                  this.data.condominioEmail = elev.condominio.email;
                  this.data.condominioTelemovel = elev.condominio.telemovel;
                  this.data.condominioMorada = elev.condominio.morada;
                  this.data.condominioCp4 = elev.condominio.cp4;
                  this.data.condominioCp3 = elev.condominio.cp3;
                  this.data.condominioLocalidade = elev.condominio.localidade;
                  this.data.condominioObservacoes = elev.condominio.observacoes;
                }
                this.data.$save().then((r) => {
                  this.UI.addToast("Elevador selecionado");
                  this.getProcesso();
                }).catch((error) => {
                  console.log(error);
                  this.UI.addToast("Falha de gravação de associação com elevador. Tente novamente");
                });
              }
            }).catch((error) => {
              console.log(error);
              this.UI.addToast("Não foi possível associar elevador. Tente novamente");
            });
          } else {
            this.UI.addToast("Ocorreu um erro ao selecionar elevador, tente novamente");
          }
        });
      } else {
        this.UI.showAlert("Não existem elevadores disponíveis para este cliente.\nVerifique se existem elevadores para este cliente ou se os elevadores existentes se encontram em processos não concluídos ou suspensos");
      }
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível carregar lista de elevadores do cliente associado ao processo");
    });
  };

  editElevador = () => {
    let cenas = this.UI.showWaiting();
    let elevador = angular.copy(this.data.elevadorPed);
    this.ElvElevador.find({
      filter: {
        where: {
          active: true,
          clienteId: {
            eq: this.data.clienteId
          }
        },
        include: {
          relation: 'pedElev',
          scope: {
            fields: { id: true, estadoId: true, active: true },
            where: {
              active: 1
            }
          }
        }
      }
    }).$promise.then((elevadores) => {
      if (!elevadores) elevadores = [];
      for (let i = elevadores.length - 1; i >= 0; i--) {
        if (elevadores[i].pedElev && elevadores[i].pedElev.some(p => p.estadoId !== 3 && p.estadoId !== 4 && p.estadoId !== 7)) { // Not Cancelado nor Suspenso nor Concluído
          elevadores.splice(i, 1);
        }
      }
      cenas.close();
      if (elevadores.length > 0) {
        let options = {
          size: "lg",
          template: require("./choose-elevador.dialog.html"),
          controller: ["$scope", ($scope) => {
            $scope.label = "Editar Elevador";
            $scope.list = elevadores;

            $scope.elevador = {
              selected: elevador,
              infiniteScroll: { numToAdd: 20, currentItems: 20 }
            };

            //Infinite Scroll Magic
            $scope.addMoreItems = (infiniteScroll) => {
              infiniteScroll.currentItems += infiniteScroll.numToAdd;
            };

            $scope.ok = () => {
              $scope.$close($scope.elevador);
            };

            $scope.cancel = () => {
              $scope.$dismiss("cancel");
            };
          }
          ]
        };
        let modal = this.UI.showDialog(options);
        modal.then((ok) => {
          if (ok.selected && ok.selected.id) {
            this.loaded = false;
            this.ElvElevador.findOne({
              filter: {
                where: {
                  id: ok.selected.id,
                  active: 1
                },
                include: [
                  {
                    relation: "emie",
                    scope: {
                      where: {
                        active: 1
                      }
                    }
                  },
                  {
                    relation: "condominio",
                    scope: {
                      where: {
                        active: 1
                      }
                    }
                  },
                  {
                    relation: 'pedElev',
                    scope: {
                      fields: { id: true, estadoId: true, active: true },
                      where: {
                        active: 1
                      }
                    }
                  }
                ]
              }
            }).$promise.then((elev) => {
              // Check if it's ok to add elevador (if there is no process already)
              if (elev.pedElev && elev.pedElev.some(p => p.estadoId !== 3 && p.estadoId !== 4 && p.estadoId !== 7)) { // Not Cancelado nor Suspenso nor Concluído
                this.UI.showAlert("Não é possível escolher o elevador.\nJá existe um processo não concluído ou suspenso atribuído a este elevador de momento");
                this.loaded = true;
              } else {
                this.data.elevadorId = elev.id;
                this.data.elevadorPed = elev;
                if (elev.condominio) {
                  this.data.condominioId = elev.condominio.id;
                  this.data.condominio = elev.condominio;
                  this.data.condominioNome = elev.condominio.nome;
                  this.data.condominioNif = elev.condominio.nif;
                  this.data.condominioEmail = elev.condominio.email;
                  this.data.condominioTelemovel = elev.condominio.telemovel;
                  this.data.condominioMorada = elev.condominio.morada;
                  this.data.condominioCp4 = elev.condominio.cp4;
                  this.data.condominioCp3 = elev.condominio.cp3;
                  this.data.condominioLocalidade = elev.condominio.localidade;
                  this.data.condominioObservacoes = elev.condominio.observacoes;
                } else {
                  this.data.condominioId = null;
                  this.data.condominio = null;
                  this.data.condominioNome = null;
                  this.data.condominioNif = null;
                  this.data.condominioEmail = null;
                  this.data.condominioTelemovel = null;
                  this.data.condominioMorada = null;
                  this.data.condominioCp4 = null;
                  this.data.condominioCp3 = null;
                  this.data.condominioLocalidade = null;
                  this.data.condominioObservacoes = null;
                }
                if (elev.emie) {
                  this.data.emieId = elev.emie.id;
                  this.data.emie = elev.emie;
                  this.data.emieNome = elev.emie.nome;
                  this.data.emieNif = elev.emie.nif;
                  this.data.emieEmail = elev.emie.email;
                  this.data.emieEmail2 = elev.emie.email2;
                  this.data.emieEmail3 = elev.emie.email3;
                  this.data.emieTelemovel = elev.emie.telemovel;
                  this.data.emieMorada = elev.emie.morada;
                  this.data.emieCp4 = elev.emie.cp4;
                  this.data.emieCp3 = elev.emie.cp3;
                  this.data.emieLocalidade = elev.emie.localidade;
                  this.data.emieObservacoes = elev.emie.observacoes;
                } else {
                  this.data.emieId = null;
                  this.data.emie = null;
                  this.data.emieNome = null;
                  this.data.emieNif = null;
                  this.data.emieEmail = null;
                  this.data.emieEmail2 = null;
                  this.data.emieEmail3 = null;
                  this.data.emieTelemovel = null;
                  this.data.emieMorada = null;
                  this.data.emieCp4 = null;
                  this.data.emieCp3 = null;
                  this.data.emieLocalidade = null;
                  this.data.emieObservacoes = null;
                }
                this.data.$save().then((r) => {
                  this.UI.addToast("Elevador alterado");
                  this.getProcesso();
                }).catch((error) => {
                  console.log(error);
                  this.UI.addToast("Falha na gravação de alteração de elevador. Tente novamente");
                });
              }
            }).catch((error) => {
              console.log(error);
              this.UI.addToast("Não foi possível editar elevador. Tente novamente");
              this.loaded = true;
            });
          }
        });
      } else {
        this.UI.showAlert("Não existem elevadores disponíveis para este cliente.\nVerifique se existem elevadores para este cliente ou se os elevadores existentes se encontram em processos não concluídos ou suspensos");
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível carregar lista de elevadores do cliente associado ao processo");
    });
  };

  addReport = () => {
    this.UI.showDialog({
      template: require('./addNorma.dialog.html'),
      controller: ['$scope', $scope => {

        $scope.choice = {};

        $scope.loaded = false;

        $scope.ok = () => {
          $scope.$close($scope.choice);
        }

        $scope.cancel = () => {
          $scope.$dismiss();
        }

        this.ElvListaVerificacao.list().$promise.then(r => {
          $scope.list = r;
          $scope.loaded = true;
        });
      }]
    }).then(r => {
      if (r != undefined) {
        // Gera relatório com a norma selecionada
        if (r.selected) {
          this.ElvRelatorio.criarRelatorio({
            tecnicoId: this.Auth.getId(),
            processoId: parseInt(this.id),
            listaId: r.selected.id
          }).$promise.then(l => {
            this.UI.addToast("Relatório criado");
            this.getProcesso();
          });
        }
      }
    });
  }

  editObservacoes = () => {
    let c = angular.copy(this.data);
    let dialog = this.UI.showDialog({
      size: "lg",
      template: require("./observations.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.observacoes = c.observacoes;

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };

        $scope.ok = () => {
          $dialog.close($scope.observacoes);
        };
      }]
    });
    dialog.then((res) => {
      c.observacoes = res;
      c.$save().then(() => {
        this.getProcesso();
        this.loaded = false;
        this.UI.addToast("Processo atualizado com sucesso!");
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("Ocorreu um erro ao atualizar processo.");
      });
    });
  };

  sendToEmail = (tipoDocumento) => {
    if (tipoDocumento !== 6 && tipoDocumento !== 7) {
      this.UI.addToast("Não é possível enviar email deste tipo de documento");
      return;
    }

    let dialog = this.UI.showDialog({
      size: "lg",
      template: require("./email.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.label = "Emails para envio de " + ((tipoDocumento === 6) ? "Marcação" : "Resultado de inspeção");
        $scope.emails = [];

        $scope.validEmail = (email) => {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        };

        if ($scope.validEmail(this.data.emieEmail)) $scope.emails.push({id: 1, selected: false, description: 'EMIE', email: this.data.emieEmail});
        if ($scope.validEmail(this.data.emieEmail2)) $scope.emails.push({id: 2, selected: false, description: 'EMIE', email: this.data.emieEmail2});
        if ($scope.validEmail(this.data.emieEmail3)) $scope.emails.push({id: 3, selected: false, description: 'EMIE', email: this.data.emieEmail3});
        if ($scope.validEmail(this.data.cliente.email)) $scope.emails.push({id: 4, selected: false, description: 'Cliente', email: this.data.cliente.email});
        if ($scope.validEmail(this.data.condominioEmail)) $scope.emails.push({id: 5, selected: false, description: 'Condomínio', email: this.data.condominioEmail});
        $scope.emails.push({id: 6, selected: false, description: 'Outro', email: null});

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };

        $scope.hasSelection = () => $scope.emails.some(x => x.selected);

        $scope.ok = () => {
          // Check if Outro is selected, and if it is, if email is valid, otherwise return selection
          let outro = $scope.emails.find(x => x.id === 6);
          if (outro.selected && !$scope.validEmail(outro.email)) {
            this.UI.addToast("Outro email está selecionado mas é inválido");
          } else {
            $dialog.close($scope.emails.filter(x => x.selected));
          }
        };
      }]
    });
    dialog.then((emails) => {
      let wait = this.UI.showWaiting();
      let tasks = [];
      if (tipoDocumento === 6) { // Marcação
        emails.forEach(email => {
          let defer = this.$q.defer();
          this.ElvAgendamento.notifyAgendamento2({params: {processoId: this.id, submittedEmail: email.email}}).$promise.then(r => {
            this.UI.addToast("Email com documento de Marcação enviado para " + email.email);
            defer.resolve(r);
          }).catch(error => {
            console.log(error);
            this.UI.addToast("Erro no envio de email de Marcação para " + email.email + ". Verifique a ligação");
            defer.reject(error);
          });
          tasks.push(defer.promise);
        });
      } else if (tipoDocumento === 7) { // Resultado de inspeção
        emails.forEach(email => {
          let defer = this.$q.defer();
          this.ElvProcesso.notifyReport2({params: {processoId: this.id, submittedEmail: email.email}}).$promise.then(r => {
            this.UI.addToast("Email de Resultado enviado para " + email.email);
            defer.resolve(r);
          }).catch(error => {
            console.log(error);
            this.UI.addToast("Erro no envio de email de Resultado para " + email.email + ". Verifique a ligação");
            defer.reject(error);
          });
          tasks.push(defer.promise);
        });
      }
      this.$q.all(tasks).then((res) => {
        wait.close();
      }).catch(error => {
        wait.close();
        console.log(error);
      });
    });
  };
}

DetailPedidosElevadoresController.$inject = ["$http", "$state", "$q", "FileUploader", "$timeout", "$filter", "AuthorizationService", "AuthenticationService", "ElvListaVerificacao", "CoreConcelho", "ElvElevadorTipo", "ElvEquipamentoTipo", "ElvRelatorioJson", "PRIClientes", "Funcionario", "ElvDocumentoTipo", "Group", "ElvEstado", "ElvProcesso", "ElvEmie", "ElvCondominio", "ElvElevador", "ElvCliente", "ElvAlteracaoEstado", "ElvAgendamento", "ElvProcessoDocumento", "$stateParams", "UIService", "ElvProcessoService"];
