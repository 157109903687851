export function routes($stateProvider) {
  $stateProvider
    .state('app.elv.oi', {
      url: '/ordens-intervencao',
      abstract: true
    })
    .state('app.elv.oi.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Ordens de Intervenção',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryIntervencoesController',
      controllerAs: 'vm',
      role: 'elvListOI',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.elv.oi.details', {
      url: '/{id}',
      title: 'Detalhes da Ordem de Intervenção',
      template: require('./details/details.view.html'),
      controller: 'DetalhesIntervencoesController',
      controllerAs: 'vm',
      role: 'elvListOI'
    })
    .state('app.elv.oi.new', {
      url: '/nova',
      manutencao: false,
      title: 'Adicionar Ordem de Intervenção',
      template: require('./new/new.view.html'),
      controller: 'CreateIntervencaoController',
      controllerAs: 'vm',
      role: 'elvImportOI'
    })
}

routes.$inject = ['$stateProvider'];
