import _ from 'lodash';

export default class DirectoryNewsController {
  constructor($rootScope, $state, $scope, $sce, $filter, NgTableParams, Noticia) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$scope = $scope;
    this.$sce = $sce;
    this.$filter = $filter;
    this.Noticia = Noticia;
    this.NgTableParams = NgTableParams;

    this.search = "";
    this.news = [];

    this.getNews();
  };

  hasSelect = () => {
    return _.some(this.news, r => r.selected);
  };

  countSelected = () => {
    return (_.filter(this.news, r => r.selected)).length;
  };

  getNews = () => {
    this.noticiasLoad = true;
    this.Noticia.find().$promise.then((res) => {
      res.forEach(function (r) {
        r.selected = false;
      });
      this.news = res;
      let NgTableParams = this.NgTableParams;
      this.tableData = new NgTableParams({
        sorting: {
          id: 'desc'
        }
      }, {
        dataset: res,
      });

      this.noticiasLoad = false;
    }).catch((err) => {
      console.log(err);
    });
  };

  editNoticia = (r) => {
    this.$state.go('app.noticias.edit', {id: r.id});
  };

  deleteSelected = () => {
    let instance = this.UI.showDialog({
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = 'Eliminar notícias?';
        $scope.message = '  Deseja eliminar as notícias seleccionadas? Esta opção não pode ser anulada.';

        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      // Confirm
      let toDelete = _.filter(this.news, function (r) {
        return r.selected == true;
      });
      toDelete.forEach((r) => {
        this.Noticia.deleteById({
          id: r.id,
        }).$promise.then(() => {
          this.getNews();
        }).catch(() => {

        });
      });
      this.getNews();
    }, () => {
      // Cancel
    });
  };

  deleteNoticia = (r) => {
    let $scope = this;
    let Noticia = this.Noticia;
    let instance = this.UI.showDialog({
      template: require('./modal-delete-noticia.html'),
      controller: ['$scope', function ($scope) {
        $scope.ok = function () {
          $scope.$close();
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });
    instance.then((result) => {
      Noticia.deleteById({
        id: r.id,
      }).$promise.then(() => {
        this.getNews();
      }).catch(() => {

      });
    }, () => {

    });
  };

  hasNews = () => {
    return this.news.length > 0;
  };

  getEstado = (r) => {
    if (r.active == 1) {
      return 'visibility';
    } else {
      return 'visibility_off';
    }
  };

  changeEstado = (r) => {
    r.active = !r.active;
    r.$save();
  };
}

DirectoryNewsController.$inject = ['$rootScope', '$state', '$scope', '$sce', '$filter', 'NgTableParams', 'Noticia'];
