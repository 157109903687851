import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import { v4 } from 'uuid';
export function DialogImage({ image, index, onClose, ...props }) {
    const [newfile, setNewFile] = useState(null);
    const [file, setFile] = useState(null);
    const handleOk = () => {
        onClose?.(newfile, index);
    };
    const handleCancel = () => {
        onClose?.(file);
    };
    useEffect(() => {
        if (image != undefined) {
            let f = new File([], image);
            f.dummy = true;
            setFile(f);
        }
    }, [image]);
    return (<Dialog {...props} maxWidth={'lg'} fullWidth>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DropzoneArea initialFiles={[file]} dropzoneText='Arraste uma imagem para esta janela ou clique para adicionar' onChange={files => {
            if (files.length > 0) {
                let t = files.pop();
                let f = new File([t], `${v4()}.${t.extension()}`, { type: t.type });
                f.dummy = t.dummy || false;
                setNewFile(f);
            }
            else {
                setNewFile(null);
            }
        }} showFileNames={false} showAlerts={false} showPreviewsInDropzone={true} useChipsForPreview={true} previewChipProps={{
            color: 'default',
            variant: 'filled'
        }} acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']} filesLimit={1}/>
      </DialogContent>
      <DialogActions>
        <Button type='button' onClick={handleCancel}>
          Cancelar
        </Button>
        <Button variant='contained' onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>);
}
