export default class AtvFamiliasDetailsController {
  constructor($q, $state, $filter, UIService, AuthorizationService, AtvFamilia, AtvSubfamilia, AtvTipoAtivo, AtvAtivo) {
    this.$q = $q;
    this.state = $state;
    this.filter = $filter;
    this.UI = UIService;
    this.AuthorizationService = AuthorizationService;
    this.AtvFamilia = AtvFamilia;
    this.AtvSubfamilia = AtvSubfamilia;
    this.AtvTipoAtivo = AtvTipoAtivo;
    this.AtvAtivo = AtvAtivo;
    this.loaded = false;
    this.familia = null;

    // Default opt state - populate later when we have all states
    // this.optTiposAtivo = {
    //   total: 0,
    //   start: 0,
    //   end: 0,
    //   page: 1,
    //   items: 10,
    //   order: "designacao",
    //   sort: "asc",
    //   filter: {}
    // };
    // this.tiposAtivo = null;
    // this.tiposAtivoLoading = true;

    this.id = $state.params.id;
    if (angular.isUndefined($state.params.id) || isNaN($state.params.id)) {
      this.state.go('app.ativos.familias.list');
      this.UI.addToast('Familia desconhecida/inválida');
    } else {
      this.loadData();
    }

    this.subfamiliasOptions = {
      containment: 'parent',
      tolerance: 'pointer',
      handle: '.pmd-move-handle',
      'ui-floating': true,
      stop: (e, ui) => {
        for (let i = 0; i < this.familia.subfamilias.length; i++) {
          this.familia.subfamilias[i].ordem = i + 1;
          this.AtvSubfamilia.upsert(this.familia.subfamilias[i]).$promise.then((res) => {
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não foi possível reordenar subfamílias. Por favor tente mais tarde");
            this.loadData();
          });
        }
      }
    };
  }

  loadData = () => {
    this.loaded = false;
    this.AtvFamilia.findOne({
      filter: {
        where: {
          id: this.id,
          active: true
        },
        include: [{
          relation: 'subfamilias',
          scope: {
            where: {
              active: 1
            },
            order: 'ordem ASC',
            // include: {
            //   relation: 'tiposAtivo',
            //   scope: {
            //     fields: {id: true},
            //     where: {
            //       active: 1
            //     },
            //     order: 'designacao ASC',
            //   }
            // }
          }
        }]
      }
    }).$promise.then((familia) => {
      this.familia = familia;
      this.loaded = true;
    }).catch((error) => {
      console.log(error);
      this.state.go('app.ativos.familias.list');
      this.UI.addToast('Erro a carregar dados de família.');
    });

    // this.getTiposAtivoForFamilia();

  };

  editFamilia = () => {
    let options = {
      size: 'lg',
      template: require('./details.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Família";
        scope.familia = angular.copy(this.familia);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.familia) {
        this.AtvFamilia.upsert(res.familia).$promise.then((res) => {
          this.UI.addToast("Família editada com sucesso");
          this.loadData();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na edição de família. Por favor tente mais tarde.");
        });
      }
    });
  };

  editObservations = () => {
    let obs = angular.copy(this.familia.observacoes);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = obs;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.loaded = false;
      this.familia.observacoes = res;
      this.familia.$save().then(() => {
        this.UI.addToast('Observações guardadas com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro a guardar as observações. Por favor tente mais tarde.');
        this.loadData();
      })
    })
  };

  addSubfamilia = () => {
    let options = {
      size: 'lg',
      template: require('./subfamilia.new.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Nova Subfamília";
        scope.subfamilia = {};

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.subfamilia) {
        res.subfamilia.id = 0;
        res.subfamilia.familiaId = this.familia.id;
        res.subfamilia.active = 1;
        this.AtvSubfamilia.count({
          where: {
            familiaId: this.familia.id,
            active: 1
          }
        }).$promise.then((n) => {
          res.subfamilia.ordem = n.count + 1;
          this.AtvSubfamilia.create(res.subfamilia).$promise.then((res) => {
            this.UI.addToast("Subfamília criada com sucesso");
            this.loadData();
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Erro na criação de subfamília. Por favor tente mais tarde.");
          });
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na criação de subfamília. Por favor tente mais tarde");
        });
      }
    });
  };

  editSubfamilia = (r) => {
    let options = {
      size: 'lg',
      template: require('./subfamilia.new.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Subfamília";
        scope.subfamilia = angular.copy(r);

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      if (res && res.subfamilia) {
        this.AtvSubfamilia.upsert(res.subfamilia).$promise.then((res) => {
          this.UI.addToast("Subfamília editada com sucesso");
          this.loadData();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na edição de subfamília. Por favor tente mais tarde.");
        });
      }
    });
  };

  checkDeleteSubfamilia = (f) => {
    let cenas = this.UI.showWaiting();
    this.AtvSubfamilia.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((subfamiliaToRemove) => {
      // Find if we have something that might block deletion
      this.AtvAtivo.count({
        fields: {id: true},
        where: {
          active: 1,
          subfamiliaId: subfamiliaToRemove.id
        }
      }).$promise.then((ativos) => {
        cenas.close();
        if (ativos.count > 0) {
          let msg = "Não é possível remover subfamília.\nExiste" + (ativos.count > 1 ? "m " : " ");
          msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")) : "";
          msg += "associado" + (ativos.count > 1 ? "s" : "") + " a esta subfamília.";
          this.UI.showAlert(msg);
        } else {
          this.confirmDeleteSubfamilia(f);
        }
      }).catch((error) => {
        console.log(error);
        this.UI.addToast("De momento não é possível remover subfamília. Por favor tente mais tarde");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("De momento não é possível remover subfamília. Por favor tente mais tarde");
    });
  };

  confirmDeleteSubfamilia = (f) => {
    let title = "Eliminar Subfamília";
    let warning = "Tem a certeza que pretende remover esta subfamília?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeSubfamilia(f);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    })
  };

  removeSubfamilia = (f) => {
    this.loaded = false;
    // Find again familia to delete so we can update it
    this.AtvSubfamilia.findOne({
      filter: {
        where: {
          id: f.id,
          active: 1
        }
      }
    }).$promise.then((subfamiliaToRemove) => {
      subfamiliaToRemove.active = 0;
      subfamiliaToRemove.$save().then((res) => {
        this.AtvSubfamilia.find({
          filter: {
            where: {
              familiaId: this.familia.id,
              active: 1
            },
            order: 'ordem ASC'
          }
        }).$promise.then((subfams) => {
          let tasks = [];
          for (let i = 0; i < subfams.length; i++) {
            subfams[i].ordem = i + 1;
            let defer = this.AtvSubfamilia.upsert(subfams[i]).$promise;
            tasks.push(defer.promise);
          }
          this.$q.all(tasks).then((res) => {
            this.UI.addToast("Subfamília eliminada com sucesso!");
            this.loadData();
          }).catch(err => {
            console.log(err);
          });
        }).catch((error) => {
          console.log(error);
        });
      }, (error) => {
        console.log(error);
        this.loaded = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível eliminar a subfamília. Verifique se ainda existe.");
      this.loadData();
    });
  };

  // addTipoAtivo = () => {
  //   let cenas = this.UI.showWaiting();
  //   let self = this;
  //   let familiaId = this.familia.id;
  //   this.AtvSubfamilia.find({
  //     filter: {
  //       where: {
  //         familiaId: familiaId,
  //         active: 1
  //       }
  //     }
  //   }).$promise.then((s) => {
  //     cenas.close();
  //     let options = {
  //       size: 'lg',
  //       template: require('./tipoativo.new.dialog.html'),
  //       controller: ['$dialog', '$scope', (dialog, scope) => {
  //         scope.title = "Novo Tipo de Ativo";
  //         scope.newAtivo = true;
  //         scope.tipoAtivo = {};
  //         scope.auxFamilia = {selected: self.familia};
  //         scope.subfamilias = s;
  //         scope.auxSubfamilia = {selected: null};
  //
  //         scope.ok = () => {
  //           dialog.close(scope);
  //         };
  //
  //         scope.cancel = () => {
  //           dialog.dismiss('cancel');
  //         };
  //       }]
  //     };
  //
  //     let modal = this.UI.showDialog(options);
  //
  //     modal.then((res) => {
  //       if (res && res.tipoAtivo) {
  //         res.tipoAtivo.id = 0;
  //         res.tipoAtivo.familiaId = this.familia.id;
  //         if (res.auxSubfamilia.selected && res.auxSubfamilia.selected.id) {
  //           res.tipoAtivo.subfamiliaId = res.auxSubfamilia.selected.id;
  //         }
  //         res.tipoAtivo.active = 1;
  //         this.AtvTipoAtivo.create(res.tipoAtivo).$promise.then((res) => {
  //           this.UI.addToast("Tipo de Ativo criado com sucesso");
  //           this.loadData();
  //         }).catch((error) => {
  //           console.log(error);
  //           this.UI.addToast("Erro na criação de tipo de ativo. Por favor tente mais tarde.");
  //         });
  //       }
  //     });
  //   }).catch((error) => {
  //     console.log(error);
  //     cenas.close();
  //   });
  // };
  //
  // editTipoAtivo = (r) => {
  //   let cenas = this.UI.showWaiting();
  //   this.AtvTipoAtivo.findOne({
  //     filter: {
  //       where: {
  //         id: r.id,
  //         active: 1
  //       },
  //       include: [{
  //         relation: 'familia',
  //         scope: {
  //           where: {
  //             active: 1
  //           }
  //         }
  //       }, {
  //         relation: 'subfamilia',
  //         scope: {
  //           where: {
  //             active: 1
  //           }
  //         }
  //       }]
  //     }
  //   }).$promise.then((tipoAtivo) => {
  //     this.AtvFamilia.find({
  //       filter: {
  //         where: {
  //           active: 1
  //         },
  //         order: 'ordem ASC',
  //         include: {
  //           relation: 'subfamilias',
  //           scope: {
  //             where: {
  //               active: 1
  //             },
  //             order: 'ordem ASC'
  //           }
  //         }
  //       }
  //     }).$promise.then((s) => {
  //       cenas.close();
  //       let options = {
  //         size: 'lg',
  //         template: require('./tipoativo.new.dialog.html'),
  //         controller: ['$dialog', '$scope', (dialog, scope) => {
  //           scope.title = "Editar Tipo de Ativo";
  //           scope.newAtivo = false;
  //           scope.tipoAtivo = angular.copy(tipoAtivo);
  //           scope.familias = s;
  //           scope.subfamilias = _.find(scope.familias, {id: scope.tipoAtivo.familiaId}).subfamilias;
  //           scope.auxFamilia = {selected: scope.tipoAtivo.familia};
  //           scope.auxSubfamilia = {selected: scope.tipoAtivo.subfamilia};
  //
  //           scope.onFamiliaSelected = (f) => {
  //             scope.auxSubfamilia.selected = null;
  //             scope.subfamilias = f.subfamilias;
  //           };
  //
  //           scope.ok = () => {
  //             dialog.close(scope);
  //           };
  //
  //           scope.cancel = () => {
  //             dialog.dismiss('cancel');
  //           };
  //         }]
  //       };
  //
  //       let modal = this.UI.showDialog(options);
  //       modal.then((res) => {
  //         if (res && res.tipoAtivo) {
  //           res.tipoAtivo.familiaId = res.auxFamilia.selected.id;
  //           res.tipoAtivo.familia = res.auxFamilia.selected;
  //           if (res.auxSubfamilia.selected && res.auxSubfamilia.selected.id) {
  //             res.tipoAtivo.subfamiliaId = res.auxSubfamilia.selected.id;
  //             res.tipoAtivo.subfamilia = res.auxSubfamilia.selected;
  //           } else {
  //             res.tipoAtivo.subfamiliaId = null;
  //             res.tipoAtivo.subfamilia = null;
  //           }
  //           res.tipoAtivo.active = 1;
  //           this.AtvTipoAtivo.upsert(res.tipoAtivo).$promise.then((res) => {
  //             this.UI.addToast("Tipo de Ativo editado com sucesso");
  //             this.loadData();
  //           }).catch((error) => {
  //             console.log(error);
  //             this.UI.addToast("Erro na edição de tipo de ativo. Por favor tente mais tarde.");
  //           });
  //         }
  //       });
  //     }).catch((error) => {
  //       console.log(error);
  //       this.UI.addToast("Não foi possível editar tipo de ativo. Por favor tente mais tarde.");
  //       cenas.close();
  //     });
  //   }).catch((error) => {
  //     console.log(error);
  //     this.UI.addToast("Tipo de Ativo não encontrado. Por favor recarregue a página");
  //     cenas.close();
  //   });
  // };
  //
  // checkDeleteTipoAtivo = (a) => {
  //   let cenas = this.UI.showWaiting();
  //   this.AtvTipoAtivo.findOne({
  //     filter: {
  //       where: {
  //         id: a.id,
  //         active: 1
  //       }
  //     }
  //   }).$promise.then((tipoAtivoToRemove) => {
  //     // Find if we have something that might block deletion
  //     this.AtvAtivo.count({
  //       fields: {id: true},
  //       where: {
  //         active: 1,
  //         tipoAtivoId: tipoAtivoToRemove.id
  //       }
  //     }).$promise.then((ativos) => {
  //       cenas.close();
  //       if (ativos.count > 0) {
  //         let msg = "Não é possível remover tipo de ativo.\nExiste" + (ativos.count > 1 ? "m " : " ");
  //         msg += ativos.count > 0 ? (ativos.count + " ativo" + (ativos.count !== 1 ? "s " : " ")) : "";
  //         msg += "associado" + (ativos.count > 1 ? "s" : "") + " a este tipo de ativo.";
  //         this.UI.showAlert(msg);
  //       } else {
  //         this.confirmDeleteTipoAtivo(a);
  //       }
  //     }).catch((error) => {
  //       console.log(error);
  //       this.UI.addToast("De momento não é possível remover tipo de ativo. Por favor tente mais tarde");
  //     });
  //   }).catch(error => {
  //     console.log(error);
  //     this.UI.addToast("De momento não é possível remover tipo de ativo. Por favor tente mais tarde");
  //   });
  // };
  //
  // confirmDeleteTipoAtivo = (a) => {
  //   let title = "Eliminar Tipo de Ativo";
  //   let warning = "Tem a certeza que pretende remover este tipo de ativo?";
  //   let instance = this.UI.showDialog({
  //     size: 'lg',
  //     template: require('../../../interface/modals/delete-selected.html'),
  //     controller: ['$scope', function ($scope) {
  //       $scope.title = title;
  //       $scope.message = warning;
  //       $scope.ok = function () {
  //         $scope.$close();
  //       };
  //       $scope.cancel = function () {
  //         $scope.$dismiss('cancel');
  //       };
  //     }]
  //   });
  //
  //   instance.then(() => {
  //     this.removeTipoAtivo(a);
  //   }, (err) => {
  //     if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
  //       console.log(err);
  //   })
  // };
  //
  // removeTipoAtivo = (a) => {
  //   this.loaded = false;
  //   // Find again familia to delete so we can update it
  //   this.AtvTipoAtivo.findOne({
  //     filter: {
  //       where: {
  //         id: a.id,
  //         active: 1
  //       }
  //     }
  //   }).$promise.then((tipoAtivoToRemove) => {
  //     tipoAtivoToRemove.active = 0;
  //     tipoAtivoToRemove.$save().then((res) => {
  //       this.UI.addToast("Tipo de Ativo eliminado com sucesso!");
  //       this.loadData();
  //     }, (error) => {
  //       console.log(error);
  //       this.loaded = false;
  //       this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
  //     });
  //   }).catch((error) => {
  //     console.log(error);
  //     this.UI.addToast("Não foi possível eliminar o tipo de ativo. Verifique se ainda existe.");
  //     this.loadData();
  //   });
  // };
  //
  // getTiposAtivoForFamilia = () => {
  //   this.tiposAtivoLoading = true;
  //   let whereLiteral = {
  //     'AtvTipoAtivo.familiaId': this.id,
  //     'AtvTipoAtivo.active': 1
  //   };
  //   let where = {};
  //   Object.keys(this.optTiposAtivo.filter).forEach(f => {
  //     if (this.optTiposAtivo.filter[f])
  //       where[f] = this.optTiposAtivo.filter[f];
  //   });
  //   this.AtvTipoAtivo.table({
  //     params: {
  //       fields: [
  //         'AtvTipoAtivo.id as id',
  //         'AtvTipoAtivo.designacao as designacao'
  //       ],
  //       from: ['AtvTipoAtivo'],
  //       references: [undefined],
  //       where: where,
  //       whereLiteral: whereLiteral,
  //       order: this.optTiposAtivo.order,
  //       sort: this.optTiposAtivo.sort,
  //       limit: this.optTiposAtivo.items,
  //       skip: (this.optTiposAtivo.page - 1) * this.optTiposAtivo.items,
  //     }
  //   }).$promise.then(res => {
  //     let page = this.optTiposAtivo.page;
  //     let items = this.optTiposAtivo.items;
  //
  //     let total = res.count;
  //
  //     this.optTiposAtivo.start = total > 0 ? (page - 1) * items + 1 : 0;
  //     if ((this.optTiposAtivo.start - 1 + items) >= total) {
  //       this.optTiposAtivo.end = total;
  //     } else {
  //       this.optTiposAtivo.end = Number.parseInt(this.optTiposAtivo.start - 1 + items);
  //     }
  //
  //     this.tiposAtivo = res.tiposAtivo;
  //     this.optTiposAtivo.total = total;
  //     this.tiposAtivoLoading = false;
  //   }).catch((e) => {
  //     console.log(e);
  //     this.UI.addToast("Não foi possível ler tipos de ativo. Por favor tente mais tarde");
  //   });
  // };
  //
  // applyFilterTiposAtivo = () => {
  //   this.optTiposAtivo.page = 1;
  //   this.getTiposAtivoForFamilia();
  // };
  //
  // tiposAtivoSort = keyname => {
  //   if (this.optTiposAtivo.order === keyname)
  //     this.optTiposAtivo.page = 1;
  //   this.optTiposAtivo.order = keyname;
  //   this.optTiposAtivo.sort = this.optTiposAtivo.sort === 'asc' ? 'desc' : 'asc';
  //   this.getTiposAtivoForFamilia();
  // };
  //
  // tiposAtivoItem = val => {
  //   this.optTiposAtivo.items = val;
  //   this.optTiposAtivo.page = 1;
  //   this.getTiposAtivoForFamilia();
  // };
  //
  // tiposAtivoPage = sum => {
  //   this.optTiposAtivo.page += sum;
  //   if (this.optTiposAtivo.page < 1)
  //     this.optTiposAtivo.page = 1;
  //   if (this.optTiposAtivo.page > Math.ceil(this.optTiposAtivo.total / this.optTiposAtivo.items))
  //     this.optTiposAtivo.page = Math.ceil(this.optTiposAtivo.total / this.optTiposAtivo.items);
  //   this.getTiposAtivoForFamilia();
  // };
  //
  // viewTipoAtivo = r => {
  //   this.state.go('app.ativos.tipos.details', {id: r.id});
  // };
}
AtvFamiliasDetailsController.$inject = ['$q', '$state', '$filter', 'UIService', 'AuthorizationService', 'AtvFamilia', 'AtvSubfamilia', 'AtvTipoAtivo', 'AtvAtivo'];
