import angular from 'angular';
import { routes } from './routes';
import { react2angular } from 'react2angular';
import RhAvaliacaoListView from './list';
import RhAvaliacaoForm from './form';
import { useContext } from '@components';
export default angular
    .module('app.rh.avaliacoes', [])
    .config(routes)
    .component('rhAvaliacaoForm', react2angular(useContext.use(RhAvaliacaoForm), [], ['$state', 'RhService', 'AuthorizationService']))
    .component('rhAvaliacaoListView', react2angular(useContext.use(RhAvaliacaoListView), [], ['$state', 'RhService'])).name;
