import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
const DialogPonto = ({ initial, onClose, ...props }) => {
    const validationSchema = yup.object({
        codigo: yup.string().required('O campo é obrigatório'),
        designacao: yup.string().required('O campo é obrigatório'),
        obrigatorio: yup.boolean().nullable()
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            let a = {
                codigo: values.codigo,
                designacao: values.designacao,
                obrigatorio: values.obrigatorio
            };
            onClose(a);
        }
    });
    useEffect(() => {
        formik.resetForm();
        formik.setValues({ designacao: initial.designacao, codigo: initial.codigo, obrigatorio: initial.obrigatorio ?? 0 });
    }, [initial]);
    return (<form>
      <Dialog {...props} maxWidth={'lg'} fullWidth>
        <DialogTitle>Adicionar ponto de verificação</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={2} md={3} xs={3}>
              <TextField label='Código' name='codigo' fullWidth defaultValue={formik.values?.codigo} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.codigo && Boolean(formik.errors.codigo)} helperText={formik.touched.codigo && formik.errors.codigo}/>
            </Grid>
            <Grid lg={8} md={6} xs={6}>
              <TextField label='Designação' name='designacao' value={formik.values?.designacao} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
            </Grid>
            <Grid lg={2} md={3} xs={3}>
              <TextField label='Obrigatório' name='obrigatorio' fullWidth defaultValue={formik.values?.obrigatorio} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.obrigatorio && Boolean(formik.errors.obrigatorio)} helperText={formik.touched.obrigatorio && formik.errors.obrigatorio} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {[
            { id: 0, label: 'Não' },
            { id: 1, label: 'Sim' }
        ].map(x => (<MenuItem value={x.id} key={x.id}>
                    {x.label}
                  </MenuItem>))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogPonto;
