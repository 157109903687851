import angular from 'angular';

import {routes} from "./emie.routes";
import DirectoryEmieController from "./directory/directory.controller";
import DetalhesEmieController from "./details/details.controller";

export default angular.module('app.elv.emie', [])
  .config(routes)
  .controller('DirectoryEmieController', DirectoryEmieController)
  .controller('DetalhesEmieController', DetalhesEmieController)
  .name;
