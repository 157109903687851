import angular from 'angular';
import { routes } from './routes';
import GasImporterController from './list/import/controller';
import { react2angular } from "react2angular";
import GasProcessoDetailsView from "./details";
import GasProcessosListView from './list';
import GasProcessoNewView from './new';
import { useContext } from "@components";
export default angular.module('app.gas.processos', [])
    .config(routes)
    .component("gasProcessoDetailsView", react2angular(useContext.use(GasProcessoDetailsView), [], ["$state", "GasService", "UIService"]))
    .component("gasProcessosListView", react2angular(GasProcessosListView, [], ["$state", "GasService", "UIService"]))
    .component("gasProcessoNewView", react2angular(GasProcessoNewView, [], ["$state", "GasService", "UIService"]))
    .controller('GasImporterController', GasImporterController)
    .name;
