import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Breadcrumbs, CircularProgress, Fab, FormControl, FormHelperText, Grid, IconButton, InputLabel, Link, ListItem, ListItemText, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { LocalizationProvider, DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { debounce } from 'lodash';
import { MdiIcon } from '@components';
import { mdiCancel, mdiContentSave } from '@mdi/js';
const FunProcessoForm = ({ $state, FuncionaService }) => {
    const [processoOriginal, setProcessoOriginal] = useState(null);
    const [processo, setProcesso] = useState(null);
    const [agendamentoOriginal, setAgendamentoOriginal] = useState(null);
    const [agendamento, setAgendamento] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [tiposServico, setTiposServico] = useState([]);
    const [motivos, setMotivos] = useState([]);
    const [tiposInstalacao, setTiposInstalacao] = useState([]);
    const [nomeTecnico, setNomeTecnico] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [autocompleteLoad, setAutocompleteLoad] = useState(false);
    const [cancelAgend, setCancelAgend] = useState(false);
    const [disable, setDisable] = useState(false); // disable all textfields when saving
    const limit = 10;
    const fetchData = async () => {
        let tecId = 0;
        try {
            const getProcesso = await FuncionaService.findProcessoById($state.params.id);
            checkRelatorio(getProcesso); // Se tiver relatório não deixa cancelar agendamento
            if (getProcesso.realizado === 0) {
                setShowMore(true);
                getMotivos();
            }
            setProcessoOriginal(getProcesso);
            setProcesso(getProcesso);
        }
        catch (error) {
            console.log('Erro ao obter Processo');
            console.log(error);
        }
        try {
            const getAgendamento = await FuncionaService.findAgendamentoByProcessoId($state.params.id);
            tecId = getAgendamento.tecnicoId;
            setAgendamento(getAgendamento);
            setAgendamentoOriginal(getAgendamento);
        }
        catch (error) {
            console.log('Erro ao obter Agendamento');
            console.log(error);
        }
        try {
            const getTipos = await FuncionaService.getTipoServico();
            setTiposServico(getTipos);
        }
        catch (error) {
            console.log('Erro ao obter tipos de serviço');
            console.log(error);
        }
        try {
            const getTiposInstalacao = await FuncionaService.getTipoInstalacao();
            setTiposInstalacao(getTiposInstalacao);
        }
        catch (error) {
            console.log('Erro ao obter tipos de instalação');
            console.log(error);
        }
        try {
            const tec = await FuncionaService.getTecnicoById(tecId);
            // adicionar técnico no options
            setOptions(prev => {
                const exists = prev.find(option => option.id === tec.id);
                return exists ? prev : [...prev, tec];
            });
            // Define o valor selecionado como o técnico retornado
            setSelectedValue({
                id: tec.funcionario.id,
                name: tec.funcionario.name,
                codigo: tec.codigo
            });
            formikProc.setFieldValue('funcionarioId', tec.funcionario.id);
        }
        catch (error) {
            console.log('Erro ao obter Técnico');
            console.log(error);
        }
        setIsLoading(false);
    };
    const checkRelatorio = (proc) => {
        // Quando o processo tem relatório não mostra o botão de cancelar agendamento
        if (proc.renovacao == null && proc.tipoServicoId == null && proc.realizado == null && proc.preco == 0) {
            setCancelAgend(true);
        }
    };
    const fetchOptions = useCallback(debounce(async (query, currentPage) => {
        if (!query || !hasMore)
            return;
        setAutocompleteLoad(true);
        try {
            const response = await FuncionaService.getTecnicos(query, currentPage, limit);
            if (response.length < limit) {
                setHasMore(false); // Não há mais dados
            }
            setOptions(prev => [...prev, ...response]); // Concatena os dados
        }
        catch (error) {
            console.log('Erro ao obter lista de técnicos');
            console.log(error);
        }
        finally {
            setAutocompleteLoad(false);
        }
    }, 500), [hasMore] // Atualiza se `hasMore` mudar
    );
    const handleScroll = event => {
        const listboxNode = event.currentTarget;
        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 1 && !autocompleteLoad && hasMore) {
            setPage(prev => prev + 1); // Incrementa a página
        }
    };
    const validationSchemaProc = yup.object().shape({
        tipoInstalacaoId: yup.number().required('Preenchimento obrigatório'),
        dataAtribuicao: yup
            .mixed() // Aceita qualquer tipo inicialmente
            .test('is-valid-date', 'Data incompleta', function (value) {
            // Testa se o valor é nulo ou uma data válida no formato ISO 8601
            if (!value || moment(value, moment.ISO_8601, true).isValid()) {
                return true;
            }
            return false;
        })
            .required('Preenchimento obrigatório'),
        dataImportacao: yup
            .mixed() // Aceita qualquer tipo inicialmente
            .test('is-valid-date', 'Data incompleta', function (value) {
            // Testa se o valor é nulo ou uma data válida no formato ISO 8601
            if (!value || moment(value, moment.ISO_8601, true).isValid()) {
                return true;
            }
            return false;
        })
            .required('Preenchimento obrigatório'),
        dataPrimeiroAgendamento: yup.date().nullable(),
        nomeCliente: yup.string().required('Preenchimento obrigatório'),
        moradaCliente: yup.string().required('Preenchimento obrigatório'),
        concelhoCliente: yup.string().required('Preenchimento obrigatório'),
        distritoCliente: yup.string().required('Preenchimento obrigatório'),
        codigoPostal: yup.string().required('Preenchimento obrigatório'),
        renovacao: yup.number().required('Preenchimento obrigatório'),
        realizado: yup.number().nullable(),
        /* realizado: yup
          .number()
          .nullable()
          .when('tipoServicoId', (tipoServicoId, schema) => (tipoServicoId[0] !== null ? schema.required('Preenchimento obrigatório') : schema.nullable())), */
        pediuReposicao: yup
            .number()
            .nullable()
            .when('realizado', (realizado, schema) => (realizado[0] == 0 ? schema.required('Preenchimento obrigatório') : schema.nullable())),
        motivoId: yup
            .number()
            .nullable()
            .when('realizado', (realizado, schema) => (realizado[0] == 0 ? schema.required('Preenchimento obrigatório') : schema.nullable())),
        justificacao: yup
            .string()
            .nullable()
            .when('realizado', (realizado, schema) => (realizado[0] == 0 ? schema.required('Preenchimento obrigatório') : schema.nullable())),
        tipoServicoId: yup
            .number()
            .nullable()
            .when('realizado', (realizado, schema) => (realizado[0] !== null ? schema.required('Preenchimento obrigatório') : schema.nullable())),
        preco: yup.number().typeError('Deve ser um número válido').required('Preenchimento obrigatório'),
        observacoes: yup.string().nullable()
    });
    const formikProc = useFormik({
        initialValues: processo || {
            id: 0,
            numeroProcesso: '',
            tarefaId: '',
            tipoInstalacaoId: 0,
            oiId: 0,
            renovacao: 0,
            realizado: null,
            tipoServicoId: 0
        },
        validationSchema: validationSchemaProc,
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (typeof values.dataAtribuicao != 'string') {
                values.dataAtribuicao = moment(values.dataAtribuicao).utc().format('YYYY-MM-DDTHH:mm:ssZ');
            }
            if (typeof values.dataImportacao != 'string') {
                values.dataImportacao = moment(values.dataImportacao).utc().format('YYYY-MM-DDTHH:mm:ssZ');
            }
            if (typeof values.dataPrimeiroAgendamento != 'string' && values.dataPrimeiroAgendamento != null) {
                values.dataPrimeiroAgendamento = moment(values.dataPrimeiroAgendamento).utc().format('YYYY-MM-DDTHH:mm:ssZ');
            }
            setProcesso(values);
            formikAgend.handleSubmit();
        }
    });
    const validationSchemaAgend = yup.object().shape({
        data: yup
            .mixed()
            .test('is-valid-date', 'Data incompleta', function (value) {
            // Verifica se o valor é nulo ou uma data válida no formato ISO 8601
            if (!value || moment(value, moment.ISO_8601, true).isValid()) {
                return true;
            }
            return false;
        })
            .when([], {
            is: () => !cancelAgend,
            then: schema => schema.required('Preenchimento obrigatório'),
            otherwise: schema => schema.nullable()
        }),
        hora: yup.string().when((x, schema) => (!cancelAgend ? schema.required('Preenchimento obrigatório') : schema.nullable())),
        duracao: yup.number().when((x, schema) => (!cancelAgend ? schema.required('Preenchimento obrigatório') : schema.nullable())),
        tecnicoId: yup.number().when((x, schema) => (!cancelAgend ? schema.required('Preenchimento obrigatório') : schema.nullable()))
    });
    const formikAgend = useFormik({
        initialValues: agendamento || {
            id: 0,
            processoId: 0,
            agendadoa: '',
            agendadoporId: 0,
            data: null,
            hora: null,
            duracao: 0,
            tecnicoId: 0
        },
        validationSchema: validationSchemaAgend,
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (typeof values.data != 'string') {
                values.data = moment(values.data).utc().format('YYYY-MM-DDTHH:mm:ssZ');
            }
            submit(values);
        }
    });
    const cleanObject = (obj, keys) => {
        const cleaned = {};
        for (const key of keys) {
            if (obj.hasOwnProperty(key)) {
                cleaned[key] = obj[key];
            }
        }
        return cleaned;
    };
    // Função para verificar alterações
    const changes = (original, updated) => {
        const changedFields = {};
        for (const key in original) {
            if (original[key] !== updated[key]) {
                changedFields[key] = {
                    original: original[key],
                    updated: updated[key]
                };
            }
        }
        return changedFields;
    };
    const calculaEstado = () => {
        if (cancelAgend && processo.estadoId === 2) {
            return 1;
        }
        if (processo.estadoId === 1 && agendamento !== null) {
            return 2;
        }
        return processo.estadoId;
    };
    const submit = async (ag) => {
        setDisable(true);
        const relevantFields = ['id', 'data', 'hora', 'duracao', 'tecnicoId', 'agendadoa', 'agendadoporId'];
        const observacoesList = [];
        // Filtra os objetos para considerar apenas os campos relevantes
        const cleanedOriginal = cleanObject(agendamentoOriginal, relevantFields);
        const cleanedUpdated = cleanObject(ag, relevantFields);
        const alteracoesProcesso = changes(processoOriginal, processo);
        const alteracoesAgendamento = changes(cleanedOriginal, cleanedUpdated);
        if (Object.keys(alteracoesAgendamento).length == 0 && Object.keys(alteracoesProcesso).length == 0) {
            $state.go('app.funciona.processos.details', { id: processo.id });
        }
        else {
            if (agendamento == null) {
                processo.estadoId = 1;
                observacoesList.push('Agendamento cancelado');
            }
            if (Object.keys(alteracoesAgendamento).length > 0) {
                for (const campo in alteracoesAgendamento) {
                    const alteracao = alteracoesAgendamento[campo];
                    switch (campo) {
                        case 'data':
                            observacoesList.push(`Alteração da data de agendamento de ${moment(alteracao.original).format('DD/MM/YYYY')} para ${moment(alteracao.updated).format('DD/MM/YYYY')}`);
                            break;
                        case 'hora':
                            observacoesList.push(`Alteração da hora de agendamento de ${alteracao.original} para ${alteracao.updated}`);
                            break;
                        case 'duracao':
                            observacoesList.push(`Alteração da duração de ${alteracao.original} para ${alteracao.updated} minutos`);
                            break;
                        case 'tecnicoId':
                            observacoesList.push(`Alteração do técnico ${nomeTecnico} para ${selectedValue.name}`);
                            break;
                        default:
                            observacoesList.push(`Alteração em ${campo} de ${alteracao.original} para ${alteracao.updated}`);
                    }
                }
                try {
                    await FuncionaService.saveAgendamento(ag);
                }
                catch (error) {
                    console.log('Erro ao alterar dados do agendamento');
                    console.log(error);
                }
            }
            if (Object.keys(alteracoesProcesso).length > 0) {
                for (const campo in alteracoesProcesso) {
                    const alteracao = alteracoesProcesso[campo];
                    switch (campo) {
                        case 'dataAtribuicao':
                            observacoesList.push(`Alteração da data de atribuição de ${moment(alteracao.original).format('DD/MM/YYYY')} para ${moment(alteracao.updated).format('DD/MM/YYYY')}`);
                            break;
                        case 'dataPrimeiroAgendamento':
                            observacoesList.push(`Alteração da data do primeiro agendamento de ${moment(alteracao.original).format('DD/MM/YYYY')} para ${moment(alteracao.updated).format('DD/MM/YYYY')}`);
                            break;
                        case 'dataImportacao':
                            observacoesList.push(`Alteração da data de importação de ${moment(alteracao.original).format('DD/MM/YYYY')} para ${moment(alteracao.updated).format('DD/MM/YYYY')}`);
                            break;
                        case 'tipoInstalacaoId':
                            observacoesList.push(`Alteração do tipo de instalação de ${tiposInstalacao.find(x => x.id == alteracao.original).designacao} para ${tiposInstalacao.find(x => x.id == alteracao.updated).designacao}`);
                            break;
                        case 'nomeCliente':
                            observacoesList.push(`Alteração do nome do cliente de "${alteracao.original}" para "${alteracao.updated}"`);
                            break;
                        case 'moradaCliente':
                            observacoesList.push(`Alteração da morada do cliente de "${alteracao.original}" para "${alteracao.updated}"`);
                            break;
                        case 'concelhoCliente':
                            observacoesList.push(`Alteração do concelho do cliente de "${alteracao.original}" para "${alteracao.updated}"`);
                            break;
                        case 'distritoCliente':
                            observacoesList.push(`Alteração do distrito do cliente de "${alteracao.original}" para "${alteracao.updated}"`);
                            break;
                        case 'codigoPostal':
                            observacoesList.push(`Alteração do código postal de "${alteracao.original}" para "${alteracao.updated}"`);
                            break;
                        case 'realizado':
                            if (alteracao.original === null) {
                                observacoesList.push(`Adicionado campo realizado: ${alteracao.updated ? 'Sim' : 'Não'}`);
                            }
                            else {
                                observacoesList.push(`Alteração do campo "realizado" de "${alteracao.original ? 'Sim' : 'Não'}" para "${alteracao.updated ? 'Sim' : 'Não'}"`);
                            }
                            break;
                        case 'renovacao':
                            observacoesList.push(`Alteração do campo "renovação" de ${alteracao.original} para ${alteracao.updated}`);
                            break;
                        case 'preco':
                            observacoesList.push(`Alteração do preço de ${alteracao.original.toFixed(2)} para ${Number(alteracao.updated).toFixed(2)}€`);
                            break;
                        case 'tipoServicoId':
                            if (tiposServico.find(x => x.id == alteracao.original) === undefined) {
                                observacoesList.push(`Adicionado tipo de serviço ${tiposServico.find(x => x.id == alteracao.updated).designacao}`);
                            }
                            else {
                                observacoesList.push(`Alteração do tipo de serviço de ${tiposServico.find(x => x.id == alteracao.original).designacao} para ${tiposServico.find(x => x.id == alteracao.updated).designacao}`);
                            }
                            break;
                        case 'pediuReposicao':
                            if (alteracao.original === null) {
                                observacoesList.push(`Adicionado campo pediu reposição:  ${alteracao.updated ? 'Sim' : 'Não'}`);
                            }
                            else if (alteracao.updated === null) {
                                observacoesList.push(`Removido campo pediu reposição: ${alteracao.original ? 'Sim' : 'Não'}`);
                            }
                            else {
                                observacoesList.push(`Alteração do campo "pediu reposição" de ${alteracao.original ? 'Sim' : 'Não'} para ${alteracao.updated ? 'Sim' : 'Não'}`);
                            }
                            break;
                        case 'motivoId':
                            if (alteracao.original === null) {
                                observacoesList.push(`Adicionado campo motivo:  ${motivos.find(x => x.id == alteracao.updated).designacao}`);
                            }
                            else if (alteracao.updated === null) {
                                observacoesList.push(`Removido campo motivo:  ${motivos.find(x => x.id == alteracao.original).designacao}`);
                            }
                            else {
                                observacoesList.push(`Alteração do campo "motivo" de ${motivos.find(x => x.id == alteracao.original).designacao} para ${motivos.find(x => x.id == alteracao.updated).designacao}`);
                            }
                            break;
                        case 'justificacao':
                            if (alteracao.original === null) {
                                observacoesList.push(`Adicionado campo justificação: ${alteracao.updated}`);
                            }
                            else if (alteracao.updated === null) {
                                observacoesList.push(`Removido campo justificação:  ${alteracao.original}`);
                            }
                            else {
                                observacoesList.push(`Alteração do campo da justificação de ${alteracao.original} para ${alteracao.updated}`);
                            }
                            break;
                        case 'observacoes':
                            if (alteracao.original === null) {
                                observacoesList.push(`Adicionado campo de observações: ${alteracao.updated}`);
                            }
                            else {
                                observacoesList.push(`Alteração do campo das observações de ${alteracao.original} para ${alteracao.updated}`);
                            }
                            break;
                        default:
                            observacoesList.push(`Alteração de ${campo} de ${alteracao.original} para ${alteracao.updated}`);
                    }
                }
                try {
                    await FuncionaService.saveProcesso(processo);
                }
                catch (error) {
                    console.log('Erro ao alterar dados do processo');
                    console.log(error);
                }
            }
            if (observacoesList.length > 0) {
                try {
                    let obs = '';
                    observacoesList.map((x, index) => {
                        if (index + 1 === observacoesList.length) {
                            obs += x;
                        }
                        else {
                            obs += x + '\n';
                        }
                    });
                    let alteracao = {
                        id: 0,
                        dataAlteracao: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                        processoId: processo.id,
                        funcionarioId: 0,
                        estadoInicial: processo.estadoId,
                        estadoFinal: 100,
                        observacoes: obs
                    };
                    await FuncionaService.saveAlteracaoEstado(alteracao);
                    alteracao.estadoFinal = calculaEstado();
                    alteracao.observacoes = '';
                    await FuncionaService.saveAlteracaoEstado(alteracao);
                }
                catch (error) {
                    console.log('Erro ao registar alteração de estado');
                    console.log(error);
                }
            }
        }
        $state.go('app.funciona.processos.details', { id: processo.id });
    };
    const getImportadoPor = async () => {
        try {
            const tec = await FuncionaService.getFuncionarioById(processo.importadoPorId);
            setNomeTecnico(tec.name);
        }
        catch (error) {
            console.log('Erro ao obter colaborador (importado por)');
            console.log(error);
        }
    };
    const getMotivos = async () => {
        try {
            const m = await FuncionaService.getMotivos();
            setMotivos(m);
        }
        catch (error) {
            console.log('Erro ao obter motivos (razão de fecho)');
            console.log(error);
        }
    };
    useEffect(() => {
        if (formikProc.values.id == 0) {
            fetchData();
        }
        if (processo !== null && nomeTecnico == null) {
            getImportadoPor();
        }
        if (inputValue) {
            fetchOptions(inputValue, page);
        }
    }, [processo, agendamento, inputValue, page]);
    return (<Box sx={{ pl: 1, pr: 1 }}>
      {isLoading ? (<Box sx={{ display: 'flex', justifyContent: 'center', height: '2vh', mt: 4 }}>
          <CircularProgress size={60} color='primary'/>
        </Box>) : (<Box sx={{ mt: 2 }}>
          <Typography variant='h5'>Editar processo {processo.numeroProcesso}</Typography>

          <Breadcrumbs aria-label='breadcrumb' sx={{ mt: 2 }}>
            <Link underline='hover' color='inherit' href='/'>
              <Typography variant='body2'>Funciona</Typography>
            </Link>
            <Link underline='hover' color='inherit' href={'/funciona/processos'}>
              <Typography variant='body2'>Processos</Typography>
            </Link>
            <Typography variant='body2'>Editar {processo.numeroProcesso}</Typography>
          </Breadcrumbs>

          {/* Detalhes Processo */}
          <Box sx={{ mt: 1 }}>
            <Typography variant='h6' sx={{ mb: 1 }}>
              Detalhes
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextField fullWidth name='numeroProcesso' label='Número do processo' color='info' size='small' defaultValue={formikProc.values.numeroProcesso} disabled/>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextField fullWidth name='tarefaId' label='ID Tarefa' color='info' size='small' defaultValue={formikProc.values.tarefaId} disabled/>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <FormControl fullWidth size='small' disabled={disable}>
                  <InputLabel id='tipoInstalacaoId'>Tipo de instalação</InputLabel>
                  <Select name='tipoInstalacaoId' labelId='tipoInstalacaoId' value={formikProc.values.tipoInstalacaoId || ''} label='Tipo de instalação' onChange={formikProc.handleChange}>
                    {tiposInstalacao.map(tipo => (<MenuItem key={tipo.id} value={tipo.id}>
                        {tipo.designacao}
                      </MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <FormControl fullWidth disabled size='small'>
                  <InputLabel id='tipoServicoId'>Tipo de serviço</InputLabel>
                  <Select name='tipoServicoId' labelId='tipoServicoId' value={formikProc.values.tipoServicoId || ''} label='Tipo de serviço'>
                    {tiposServico.map(tipo => (<MenuItem key={tipo.id} value={tipo.id}>
                        {tipo.designacao}
                      </MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker label='Data de atribuição' name='dataAtribuicao' disabled={disable} slotProps={{ textField: { size: 'small', color: 'info', fullWidth: true, error: Boolean(formikProc.errors.dataAtribuicao), helperText: formikProc.touched.dataAtribuicao && formikProc.errors.dataAtribuicao } }} value={moment(formikProc.values.dataAtribuicao) || ''} onChange={value => {
                formikProc.setFieldValue('dataAtribuicao', moment(value));
            }}/>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker label='Data do primeiro contacto' name='dataPrimeiroAgendamento' disabled={disable} slotProps={{
                textField: { size: 'small', color: 'info', fullWidth: true, error: Boolean(formikProc.errors.dataPrimeiroAgendamento), helperText: formikProc.touched.dataPrimeiroAgendamento && formikProc.errors.dataPrimeiroAgendamento }
            }} value={moment(formikProc.values.dataPrimeiroAgendamento) || ''} onChange={value => {
                formikProc.setFieldValue('dataPrimeiroAgendamento', moment(value));
            }}/>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker label='Data de importação' name='dataImportacao' disabled={disable} slotProps={{ textField: { size: 'small', color: 'info', fullWidth: true, error: Boolean(formikProc.errors.dataImportacao), helperText: formikProc.touched.dataImportacao && formikProc.errors.dataImportacao } }} value={moment(formikProc.values.dataImportacao) || ''} onChange={value => {
                formikProc.setFieldValue('dataImportacao', moment(value));
            }}/>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextField fullWidth name='importadoPorId' label='Importado por' color='info' size='small' defaultValue={nomeTecnico} disabled/>
              </Grid>
            </Grid>
          </Box>

          {/* Detalhes Agendamento */}
          <Box sx={{ mt: 2 }}>
            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2} sx={{ mb: 1 }}>
              <Typography variant='h6'>Agendamento</Typography>
              {cancelAgend ? (<Tooltip title={'Cancelar agendamento'} placement='right'>
                  <IconButton color='primary' onClick={() => {
                    //formikAgend.resetForm();
                    setInputValue(null);
                    setSelectedValue(null);
                    setOptions([]);
                    setAgendamento(null);
                    formikAgend.setFieldValue('data', null);
                    formikAgend.setFieldValue('hora', null);
                    formikAgend.setFieldValue('duracao', '');
                    formikAgend.setFieldValue('tecnicoId', 0);
                }}>
                    <MdiIcon path={mdiCancel}></MdiIcon>
                  </IconButton>
                </Tooltip>) : (<></>)}
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6} xl={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker label='Data de agendamento' name='data' disabled={disable} slotProps={{ textField: { size: 'small', color: 'info', fullWidth: true, error: Boolean(formikAgend.errors.data), helperText: formikAgend.touched.data && formikAgend.errors.data } }} value={formikAgend.values.data ? moment(formikAgend.values.data) : ''} onChange={value => {
                formikAgend.setFieldValue('data', value); // Armazena como Moment/Date
            }}/>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={6} xl={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker label='Hora de agendamento' name='hora' disabled={disable} slotProps={{ textField: { size: 'small', color: 'info', fullWidth: true, error: Boolean(formikAgend.errors.hora), helperText: formikAgend.touched.hora && formikAgend.errors.hora } }} value={moment(formikAgend.values.hora, 'HH:mm:ss')} onChange={value => {
                formikAgend.setFieldValue('hora', moment(value).format('HH:mm:ss'));
            }}/>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={3}>
                <TextField fullWidth name='duracao' label='Duração (minutos)' color='info' onChange={formikAgend.handleChange} size='small' type='number' value={formikAgend.values.duracao} error={Boolean(formikAgend.errors.duracao)} helperText={formikAgend.touched.duracao && formikAgend.errors.duracao} disabled={disable}/>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={3}>
                <Autocomplete disabled={disable} disablePortal options={options} getOptionLabel={option => (option.name ? `${option.name}` : '')} value={selectedValue} // O valor completo da opção selecionada
         isOptionEqualToValue={(option, value) => option.id === value.id} onChange={(event, newValue) => {
                if (newValue == null) {
                    setSelectedValue(null);
                }
                else {
                    setSelectedValue(newValue); // Armazena o objeto completo
                    formikAgend.setFieldValue('tecnicoId', newValue.id);
                }
                formikAgend.setFieldTouched('tecnicoId', true);
            }} filterOptions={x => x} // Evita o filtro padrão
         onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setOptions([]); // Reseta opções ao mudar busca
                setPage(0); // Reinicia a paginação
                setHasMore(true);
            }} ListboxProps={{
                onScroll: handleScroll // Adiciona evento de scroll
            }} renderOption={(props, option) => (<ListItem {...props} key={option.id} dense>
                      <ListItemText primary={option.name} secondary={option.codigo}/>
                    </ListItem>)} renderInput={params => (<TextField {...params} label='Técnico' variant='outlined' size='small' fullWidth sx={{ bgcolor: '#F5F5F5' }} error={Boolean(formikAgend.errors.tecnicoId)} helperText={formikAgend.touched.tecnicoId && formikAgend.errors.tecnicoId} InputProps={{
                    ...params.InputProps,
                    endAdornment: (<>
                            {autocompleteLoad ? <CircularProgress color='inherit' size={20}/> : null}
                            {params.InputProps.endAdornment}
                          </>)
                }}/>)}/>
              </Grid>
            </Grid>
          </Box>

          {/* Detalhes Cliente */}
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6' sx={{ mb: 1 }}>
              Cliente
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextField fullWidth name='nomeCliente' label='Nome' color='info' onChange={formikProc.handleChange} size='small' value={formikProc.values.nomeCliente} error={Boolean(formikProc.errors.nomeCliente)} helperText={formikProc.touched.nomeCliente && formikProc.errors.nomeCliente} disabled={disable}/>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextField fullWidth name='moradaCliente' label='Morada' color='info' onChange={formikProc.handleChange} size='small' value={formikProc.values.moradaCliente} error={Boolean(formikProc.errors.moradaCliente)} helperText={formikProc.touched.moradaCliente && formikProc.errors.moradaCliente} disabled={disable}/>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextField fullWidth name='concelhoCliente' label='Concelho' color='info' onChange={formikProc.handleChange} size='small' value={formikProc.values.concelhoCliente} error={Boolean(formikProc.errors.concelhoCliente)} helperText={formikProc.touched.concelhoCliente && formikProc.errors.concelhoCliente} disabled={disable}/>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextField fullWidth name='distritoCliente' label='Distrito' color='info' onChange={formikProc.handleChange} size='small' value={formikProc.values.distritoCliente} error={Boolean(formikProc.errors.distritoCliente)} helperText={formikProc.touched.distritoCliente && formikProc.errors.distritoCliente} disabled={disable}/>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextField fullWidth name='codigoPostal' label='Código Postal' color='info' onChange={formikProc.handleChange} size='small' value={formikProc.values.codigoPostal} error={Boolean(formikProc.errors.codigoPostal)} helperText={formikProc.touched.codigoPostal && formikProc.errors.codigoPostal} disabled={disable}/>
              </Grid>
            </Grid>
          </Box>

          {/* Detalhes relatório */}
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6' sx={{ mb: 1 }}>
              Relatório
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <FormControl fullWidth size='small' disabled={disable} error={Boolean(formikProc.touched.realizado && formikProc.errors.realizado)}>
                  <InputLabel id='realizado'>Realizado?</InputLabel>
                  <Select name='realizado' labelId='realizado' value={formikProc.values.realizado} label='Realizado?' onChange={event => {
                const value = Number(event.target.value);
                formikProc.setFieldValue('realizado', value); // Aceita o valor 0 ou 1
                if (value === 0) {
                    setShowMore(true);
                    if (motivos.length === 0) {
                        getMotivos();
                    }
                }
                else {
                    setShowMore(false);
                    formikProc.setFieldValue('pediuReposicao', null);
                    formikProc.setFieldValue('motivoId', null);
                    formikProc.setFieldValue('justificacao', null);
                }
            }}>
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </Select>
                  {formikProc.touched.realizado && formikProc.errors.realizado && <FormHelperText>{formikProc.errors.realizado}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <FormControl fullWidth size='small' disabled={disable}>
                  <InputLabel id='renovacao'>Renovação?</InputLabel>
                  <Select name='renovacao' labelId='renovacao' value={formikProc.values.renovacao} label='Renovação?' onChange={formikProc.handleChange}>
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={0}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextField fullWidth name='preco' label='Preço (€)' color='info' onChange={e => {
                const value = e.target.value.replace(',', '.'); // Substitui vírgula por ponto
                formikProc.setFieldValue('preco', value); // Atualiza o valor no Formik
            }} size='small' type='number' value={formikProc.values.preco} disabled={disable}/>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <FormControl fullWidth size='small' disabled={disable} error={Boolean(formikProc.touched.tipoServicoId && formikProc.errors.tipoServicoId)}>
                  <InputLabel id='tipoServicoId'>Tipo de serviço</InputLabel>
                  <Select name='tipoServicoId' labelId='tipoServicoId' value={formikProc.values.tipoServicoId || ''} label='Tipo de serviço' onChange={event => {
                formikProc.setFieldValue('tipoServicoId', event.target.value);
                if (formikProc.values.realizado === null) {
                    formikProc.setFieldValue('realizado', 1);
                }
            }}>
                    {tiposServico.map(tipo => (<MenuItem key={tipo.id} value={tipo.id}>
                        {tipo.designacao}
                      </MenuItem>))}
                  </Select>
                  {formikProc.touched.tipoServicoId && formikProc.errors.tipoServicoId && <FormHelperText>{formikProc.errors.tipoServicoId}</FormHelperText>}
                </FormControl>
              </Grid>

              {showMore ? (<>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControl fullWidth size='small' disabled={disable} error={Boolean(formikProc.touched.pediuReposicao && formikProc.errors.pediuReposicao)}>
                      <InputLabel id='pediuReposicao'>Pediu reposicao? *</InputLabel>
                      <Select name='pediuReposicao' labelId='pediuReposicao' required value={formikProc.values.pediuReposicao} label='Pediu reposicao? *' onChange={formikProc.handleChange}>
                        <MenuItem value={1}>Sim</MenuItem>
                        <MenuItem value={0}>Não</MenuItem>
                      </Select>
                      {formikProc.touched.pediuReposicao && formikProc.errors.pediuReposicao && <FormHelperText>{formikProc.errors.pediuReposicao}</FormHelperText>}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControl fullWidth size='small' disabled={disable} error={Boolean(formikProc.touched.motivoId && formikProc.errors.motivoId)}>
                      <InputLabel id='motivoId'>Razão de fecho *</InputLabel>
                      <Select name='motivoId' labelId='motivoId' required value={formikProc.values.motivoId || ''} label='Razão de fecho *' onChange={formikProc.handleChange}>
                        {motivos.map(tipo => (<MenuItem key={tipo.id} value={tipo.id}>
                            {tipo.designacao}
                          </MenuItem>))}
                      </Select>
                      {formikProc.touched.motivoId && formikProc.errors.motivoId && <FormHelperText>{formikProc.errors.motivoId}</FormHelperText>}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12} lg={showMore ? 12 : 8} xl={6}>
                    <TextField fullWidth name='justificacao' label='Justificação *' color='info' multiline maxRows={2} onChange={formikProc.handleChange} size='small' value={formikProc.values.justificacao} error={Boolean(formikProc.touched.justificacao && formikProc.errors.justificacao)} helperText={formikProc.touched.justificacao && formikProc.errors.justificacao} disabled={disable}/>
                  </Grid>
                </>) : (<></>)}

              <Grid item xs={12} md={12} lg={showMore ? 12 : 8} xl={12}>
                <TextField fullWidth name='observacoes' disabled={disable} label='Observações' color='info' multiline maxRows={2} onChange={formikProc.handleChange} size='small' value={formikProc.values.observacoes}/>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{
                position: 'fixed',
                margin: 2,
                bottom: 0,
                right: 0,
                display: 'flex',
                gap: 1
            }}>
            <Tooltip title={'Guardar alterações'}>
              <Fab color='error' onClick={async () => {
                formikProc.handleSubmit();
            }}>
                <MdiIcon path={mdiContentSave}/>
              </Fab>
            </Tooltip>
          </Box>
        </Box>)}
    </Box>);
};
export default FunProcessoForm;
