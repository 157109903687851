import React, { useEffect, useState } from 'react';
import { Box, Typography, Breadcrumbs, Link, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { DynamicCard } from '@components';
const DetailsClausulas = ({ $state, ListaClausulaService }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [defeitos, setDefeitos] = useState([]);
    const fetchData = async () => {
        setLoading(true);
        try {
            const aux = await ListaClausulaService.findOne($state.params.id);
            setData(aux);
            setDefeitos(aux.Defeitos);
            setLoading(false);
        }
        catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>{loading ? 'A carregar...' : data && data.designacao}</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          {/* <Link underline='none' color='inherit'>
          Administração
        </Link> */}
          <Link underline='hover' onClick={() => $state.go('app.administration.lists.list')} color='text.primary' aria-current='page'>
            Listas de relatório
          </Link>
          <Link underline='hover' color='text.primary' aria-current='page'>
            {loading ? <CircularProgress size={16}/> : $state.params.id}
          </Link>
        </Breadcrumbs>
      </Box>
      <Box>
        <Grid container spacing={1}>
          <Grid md={12} xs={12}>
            <DynamicCard sx={{ mb: 1 }} loading={loading} title='Detalhes' content={[
            {
                label: 'Designação',
                value: data && data.designacao
            }
        ]}/>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid md={12} xs={12}>
            <DynamicCard sx={{ mb: 1 }} loading={loading} title='Cláusulas' variant='list' content={defeitos.map(r => {
            return {
                label: r.titulo,
                value: r.active == 1,
                sublabel: r.subtitulo
            };
        })}/>
          </Grid>
        </Grid>
      </Box>
    </Box>);
};
export default DetailsClausulas;
