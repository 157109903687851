import moment from "moment/moment";

export default class CrmLegacyService {
  constructor($rootScope, $q, $http, UIService, FileUploader, AuthenticationService, CoreCliente, CoreContacto) {
    this.$q = $q;
    this.$http = $http;
    this.UI = UIService;
    this.FileUploader = FileUploader;
    this.Auth = AuthenticationService;
    this.user = this.Auth.getUser();
    this.CoreCliente = CoreCliente;
    this.CoreContacto = CoreContacto;
  }

  generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({ key: data.key, value: data.value });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({ key: data.key, value: whereObject[data.key] });
        delete whereObject[data.key];
        whereOrObject.push({ key: data.key, value: data.value });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  // Create all contactos for processo, referenced by processoId
  createContactosCliente = (contactos, clienteId) => {
    let defer = this.$q.defer();
    let tasks = [];

    contactos.forEach(contacto => {
      let deferContacto = this.$q.defer();

      contacto.clienteId = clienteId; // The rest of the (optional) data is defined in the createContactoCliente

      // Criar contacto
      this.CoreContacto.createContacto({params: {contacto}}).$promise.then((createdContacto) => {
        deferContacto.resolve(createdContacto);
      }).catch(error => {
        console.log(error);
        defer.reject(error);
      });

      tasks.push(deferContacto.promise);
    });

    // After all data is updated
    this.$q.all(tasks).then((res) => {
      defer.resolve(tasks);
    }).catch(err => {
      console.log(err);
      defer.reject(err);
    });
    return defer.promise;
  };

  // Check if id exists for Model
  fieldValueExists = (field, value, Model) => {
    let defer = this.$q.defer();
    let where = {};
    where[field] = value;
    console.log(Model.name);
    Model.count({
      where: where
    }).$promise.then((data) => {
      defer.resolve(data.count > 0);
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };

  // Check if partialData - {key1: data1, key2: data2} is already used in Model, returns count
  // If exceptId, do not search for id: exceptId
  usedPartialDataForModel = (partialData, Model, exceptId) => {
    let defer = this.$q.defer();
    let and = [];
    Object.keys(partialData).forEach(k => {
      let o = {};
      o[k] = partialData[k];
      and.push(o);
    });
    // Exclude exceptId
    if (exceptId)
      and.push({ id: { neq: exceptId } });

    let where = { and: and };
    Model.count({
      where: where
    }).$promise.then((res) => {
      defer.resolve(res);
    }).catch((error) => {
      defer.reject(error);
    });
    return defer.promise;
  };

  // Go through an array of field, value and models, and resolve/reject if all fields and values exist/not exist in those tables
  validateMultiData = (data) => {
    let multiDefer = this.$q.defer();

    let tasks = [];
    for (let i = 0; i < data.length; i++) {
      let defer = this.$q.defer();

      console.log(data);

      this.fieldValueExists(data[i].field, data[i].value, data[i].model).then((exists) => {
        if (exists) {
          if (data[i].resolveOnExist) { // Check if it's a "Exists, so resolve" or "Exists, so reject"
            defer.resolve();
          }
          else {
            this.UI.addToast(data[i].error);
            defer.reject();
          }
        } else {
          if (data[i].resolveOnExist) {
            this.UI.addToast(data[i].error);
            defer.reject();
          } else {
            defer.resolve();
          }
        }
      }).catch(err => {
        this.UI.addToast("Não foi possível verificar dados. Verifique a ligação");
        defer.reject();
      });
      tasks.push(defer.promise);
    }

    this.$q.all(tasks).then(x => {
      multiDefer.resolve(tasks);
    }).catch(err => {
      multiDefer.reject(err);
    });

    return multiDefer.promise;
  };

  // Create all multiple data for processo, referenced by referenceId (ex. eventoId)
  createMultipleData = (Model, data, referenceId, reference) => {
    let multipleDataDefer = this.$q.defer();

    // Create multiple Data
    let tasks = [];
    for (let i = 0; i < data.length; i++) {
      let defer = this.$q.defer();
      let item = data[i];
      item.id = 0;
      item[reference] = referenceId;

      // item.criadoPorId = this.user.id;
      // item.dataCriacao = moment().format();

      Model.create(item).$promise.then((newItem) => {
        defer.resolve(newItem);
      }).catch((error) => {
        console.log(error);
        defer.reject(error);
      });
      tasks.push(defer.promise);
    }

    // After all data is created
    this.$q.all(tasks).then((res) => {
      multipleDataDefer.resolve(tasks);
    }).catch(err => {
      console.log(err);
      multipleDataDefer.reject(err);
    });

    return multipleDataDefer.promise;
  };

  // Update data with criadoPor, dataCriacao; data is array of objects
  updateMultipleData = (Model, data) => {
    let multipleDataDefer = this.$q.defer();

    // Create multiple Data
    let tasks = [];
    for (let i = 0; i < data.length; i++) {
      let defer = this.$q.defer();
      let item = data[i];

      // item.criadoPorId = this.user.id;
      // item.dataCriacao = moment().format();

      Model.upsert(item).$promise.then((newItem) => {
        defer.resolve(newItem);
      }).catch((error) => {
        console.log(error);
        defer.reject(error);
      });
      tasks.push(defer.promise);
    }

    // After all data is updated
    this.$q.all(tasks).then((res) => {
      multipleDataDefer.resolve(tasks);
    }).catch(err => {
      console.log(err);
      multipleDataDefer.reject(err);
    });

    return multipleDataDefer.promise;
  };

  // Check if values of fields exist in data and are not null
  validateMandatoryData = (data, fields) => {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < fields.length; j++) {
        if (data[i][fields[j]] == null)
          return false;
      }
    }
    return true;
  };

  // Returns true if contacto has no modules to show
  checkNoModules = (contacto) => (contacto.elvClienteId == null || contacto.blockedElv) &&
    (contacto.ieClienteId == null || contacto.blockedIe) &&
    (contacto.forClienteId == null || contacto.blockedFor) &&
    (contacto.labClienteId == null || contacto.blockedLab);

  // Returns only true or false depending on validity of NIF
  validNIF = (nif) => {
    // Returning true to hide message (there's no point of testing if nif is undefined or not with correct length)
    // WARNING: RELIES ON MINLENGTH to be defined and VERIFIED!!
    if (nif === undefined || nif.length < 9) {
      return false;
    }
    let comparador = 0;
    nif = nif.toString();
    // Alternative ECMA2016
    // if(!['1', '2', '3', '5', '6', '8'].includes(nif.substr(0,1)) &&
    // 	!['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0,2))) {
    // Using for IE compatibility
    if (!(['1', '2', '3', '5', '6', '8'].indexOf(nif.substr(0, 1)) > -1) &&
      !(['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].indexOf(nif.substr(0, 2)) > -1)) {
      return false;
    }
    let total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
    let modulo11 = total - parseInt(total / 11) * 11;
    if (modulo11 == 1 || modulo11 == 0)
      comparador = 0;
    else
      comparador = 11 - modulo11;
    return nif[8] == comparador;
  };

  validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // Returns an array of objects that can be clients
  // { module: "", ..... }
  searchClientes = (termoPesquisa) => {
    let defer = this.$q.defer();
    this.CoreCliente.searchClientes({params: {termoPesquisa: termoPesquisa}}).$promise.then((result) => {
      defer.resolve(result.results);
    }).catch(error => {
      defer.reject(error);
    });
    return defer.promise;
  };

}

CrmLegacyService.$inject = ['$rootScope', '$q', '$http', 'UIService', 'FileUploader', 'AuthenticationService', 'CoreCliente', 'CoreContacto'];
