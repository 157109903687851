export default class AtvPropriedadeDetailsController {
  constructor($state, $filter, UIService, AtvAtivosService, AtvPropriedade, AtvAtivo) {
    this.state = $state;
    this.filter = $filter;
    this.UI = UIService;
    this.AtvAtivosService = AtvAtivosService;
    this.AtvPropriedade = AtvPropriedade;
    this.AtvAtivo = AtvAtivo;
    this.pr = $state.params.pr;
    this.loaded = false;
    this.propriedade = null;

    // Default opt state - populate later when we have all states
    this.optAtivos = {
      total: 0,
      start: 0,
      end: 0,
      page: 1,
      items: 10,
      order: "id",
      sort: "desc",
      filter: {}
    };
    this.ativos = null;
    this.ativosLoading = true;

    this.id = $state.params.id;
    if (angular.isUndefined($state.params.id) || isNaN($state.params.id)) {
      this.state.go('app.ativos.entidadesproprietarias.list');
      this.UI.addToast('Entidade Proprietária desconhecida/inválida');
    } else {
      if (angular.isUndefined($state.params.pr) || isNaN($state.params.pr)) {
        this.state.go('app.ativos.entidadesproprietarias.details', {id: this.id});
        this.UI.addToast('Área Organizacional desconhecida/inválida');
      } else {
        this.loadData();
      }
    }
  }

  loadData = () => {
    this.loaded = false;
    this.AtvPropriedade.findOne({
      filter: {
        where: {
          id: this.pr,
          entidadeProprietariaId: this.id,
          active: true
        },
        include: [{
          relation: 'entidadeproprietaria',
          scope: {
            where: {
              active: 1
            }
          }
        }]
      }
    }).$promise.then((propriedade) => {
      this.propriedade = propriedade;
      this.loaded = true;
    }).catch(() => {
      this.state.go('app.ativos.entidadesproprietarias.details', {id: this.id});
      this.UI.addToast('Erro a carregar dados de área organizacional.');
    });
    this.getAtivosForPropriedade();
  };

  editObservations = () => {
    let obs = angular.copy(this.propriedade.observacoes);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = obs;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.loaded = false;
      this.propriedade.observacoes = res;
      this.propriedade.$save().then(() => {
        this.UI.addToast('Observações guardadas com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro a guardar as observações. Por favor tente mais tarde.');
        this.loadData();
      })
    })
  };

  editPropriedade = () => {
    let propriedade = angular.copy(this.propriedade);
    let options = {
      size: 'lg',
      template: require('./propriedade.dialog.html'),
      controller: ['$dialog', '$scope', (dialog, scope) => {
        scope.title = "Editar Área Organizacional";
        scope.propriedade = propriedade;

        scope.ok = () => {
          dialog.close(scope);
        };

        scope.cancel = () => {
          dialog.dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then((res) => {
      this.loaded = false;
      if (res && res.propriedade) {
        this.AtvPropriedade.upsert(res.propriedade).$promise.then((res) => {
          this.UI.addToast("Área Organizacional editada com sucesso");
          this.loadData();
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Erro na edição de AOA. Verifique a ligação");
        });
      }
    });
  };

  getAtivosForPropriedade = () => {
    this.ativosLoading = true;
    let whereLiteral = {
      'AtvAtivo.propriedadeId': this.pr,
      'AtvAtivo.active': 1
    };
    let where = {};
    Object.keys(this.optAtivos.filter).forEach(f => {
      if (this.optAtivos.filter[f])
        where[f] = this.optAtivos.filter[f];
    });

    this.AtvAtivosService.setWherePermFields({}, 'AtvAtivo.entidadeProprietariaId').then(w => {
      let wherePerms = w.wherePerms;
      this.AtvAtivo.table({
        params: {
          fields: [
            'AtvAtivo.id as id',
            'AtvAtivo.iidDisplay as iidDisplay',
            'AtvAtivo.designacao as designacao',
            'AtvAtivo.referencia as referencia',
            'AtvMarca.designacao as marca',
            'AtvFornecedorPrimavera.refFornecedorPrimavera as refFornecedorPrimavera',
            'AtvEntidadeProprietaria.designacao as entidadeProprietaria',
            'AtvEP2.sigla as sigla'
          ],
          from: ['AtvAtivo', 'AtvMarca', 'AtvFornecedorPrimavera', 'AtvEntidadeProprietaria', 'AtvEntidadeProprietaria'],
          references: [undefined, 'AtvAtivo.marcaId', 'AtvAtivo.fornecedorPrimaveraId', 'AtvAtivo.entidadeProprietariaId', 'AtvFornecedorPrimavera.entidadeProprietariaId'],
          aliases: [null, null, null, null, 'AtvEP2'],
          where: where,
          whereLiteral: whereLiteral,
          wherePerms: wherePerms,
          order: this.optAtivos.order,
          sort: this.optAtivos.sort,
          limit: this.optAtivos.items,
          skip: (this.optAtivos.page - 1) * this.optAtivos.items,
        }
      }).$promise.then(res => {
        let page = this.optAtivos.page;
        let items = this.optAtivos.items;

        let total = res.count;

        this.optAtivos.start = total > 0 ? (page - 1) * items + 1 : 0;
        if ((this.optAtivos.start - 1 + items) >= total) {
          this.optAtivos.end = total;
        } else {
          this.optAtivos.end = Number.parseInt(this.optAtivos.start - 1 + items);
        }

        this.ativos = res.ativos;
        this.optAtivos.total = total;
        this.ativosLoading = false;
      }).catch((e) => {
        console.log(e);
        this.UI.addToast("Não foi possível ler ativos. Por favor tente mais tarde");
      });
    }).catch((e) => {
      console.log(e);
      this.UI.addToast("Não foi possível ler permissões. Por favor tente mais tarde");
    });
  };

  applyFilterAtivos = () => {
    this.optAtivos.page = 1;
    this.getAtivosForPropriedade();
  };

  ativosSort = keyname => {
    if (this.optAtivos.order === keyname)
      this.optAtivos.page = 1;
    this.optAtivos.order = keyname;
    this.optAtivos.sort = this.optAtivos.sort === 'asc' ? 'desc' : 'asc';
    this.getAtivosForPropriedade();
  };

  ativosItem = val => {
    this.optAtivos.items = val;
    this.optAtivos.page = 1;
    this.getAtivosForPropriedade();
  };

  ativosPage = sum => {
    this.optAtivos.page += sum;
    if (this.optAtivos.page < 1)
      this.optAtivos.page = 1;
    if (this.optAtivos.page > Math.ceil(this.optAtivos.total / this.optAtivos.items))
      this.optAtivos.page = Math.ceil(this.optAtivos.total / this.optAtivos.items);
    this.getAtivosForPropriedade();
  };

  viewAtivo = r => {
    this.state.go('app.ativos.ativos.details', {id: r.id});
  };

}
AtvPropriedadeDetailsController.$inject = ['$state', '$filter', 'UIService', 'AtvAtivosService', 'AtvPropriedade', 'AtvAtivo'];
