window.$ = window.jQuery;

/* Angular Dependencies */
import angular from 'angular';
let uiRouter = require('@uirouter/angularjs').default;
import '@uirouter/angularjs/release/stateEvents';
import 'angular-file-upload';
import angularLocalePT from 'angular-i18n/pt-pt';
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';
import textAngular from 'textangular';
import lbServices from './modules/loopback/optimized-lbservices';
import ngChart from 'angular-chart.js';
require('chart.js');
require('angular-heremaps/dist/angular-heremaps');
let uiSelect = require('ui-select');
/* Drag and Drop stuff */
require('webpack-jquery-ui/sortable');
require('angular-ui-sortable');
/* Template modules */
import config from './app.config';
import { ApplicationInterceptor } from './modules/app.interceptor';
import { ngTableModule } from 'ng-table/bundles/ng-table';
/* Client modules */
import Interface from './modules/interface';
import Authentication from './modules/authentication';
import Authorization from './modules/authorization';
import Administration from './modules/administration';
import Formacao from './modules/formacao';
import Laboratory from './modules/lab';
import Susene from './modules/susene';
import Elevadores from './modules/elevadores';
import Electric from './modules/ie';
import Financeiro from './modules/financeiro';
import Ativos from './modules/ativos';
import Pad from './modules/pad';
import Obler from './modules/obler';
import RH from './modules/rh';
import Funciona from './modules/funciona';
import Consultas from './modules/consultas';
import Gas from './modules/gas'
import Crm from './modules/crm'

import { BacklogInterceptor } from './modules/authentication/backlog.interceptor';

/* Sentry */
/*
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { Angular as AngularIntegration } from "@sentry/integrations";

Sentry.init({
  dsn: "https://d921e300c73e4dd085c3c4f8a18e26ff@sentry.streamline.pt/7",
  integrations: [
    new AngularIntegration(),
    new BrowserTracing({
      tracingOrigins: ["localhost", "http://localhost:3000/api"],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});*/

// All CSS requirements by directives and others shall be imported HERE
require('textangular/dist/textAngular.css');
require('ng-table/bundles/ng-table.min.css');
require('material-icons/iconfont/material-icons.css');
require('@mdi/font/css/materialdesignicons.min.css');

// Set moment locale to Portugal globally. It works :)
moment.locale('pt');

let app = angular.module('app', [
  uiRouter,
  'ui.router.state.events',
  ngResource,
  ngSanitize,
  uiSelect,
  textAngular,
  //"ngSentry",
  angularLocalePT,
  'angularFileUpload',
  'heremaps',
  'ui.sortable',
  ngTableModule.name,
  ngChart,
  lbServices,
  Interface,
  Authentication,
  Authorization,
  Administration,
  Electric,
  Formacao,
  Elevadores,
  Laboratory,
  Susene,
  Financeiro,
  Ativos,
  Pad,
  Obler,
  RH,
  Funciona,
  Consultas,
  Gas,
  Crm
]);

app.config(config);
app.run(ApplicationInterceptor);
app.run(BacklogInterceptor);
// Informação template sistema
app.run([
  '$rootScope',
  $rootScope => {
    $rootScope.uiBase = {
      name: 'Børk',
      description: 'Streamline MD&B Base Template in AngularJS',
      version: '1.0.3'
    };
  }
]);
// Configurações selectors
app.config([
  'uiSelectConfig',
  function (uiSelectConfig) {
    uiSelectConfig.theme = 'bootstrap';
    uiSelectConfig.resetSearchInput = false;
    uiSelectConfig.skipFocusser = true;
  }
]);
// Configurações mapa
app.config([
  'HereMapsConfigProvider',
  function (HereMapsConfigProvider) {
    HereMapsConfigProvider.setOptions({
      app_id: 'sGamgCmoW3gDUKZM1KfH',
      app_code: 'Hd0epXJDIhr09dQLDwIcwA',
      useHTTPS: true
    });
  }
]);
// Configurações ng-table (TODO: Acabar com ng-table / melhorar)
app.run([
  'ngTableDefaults',
  function (ngTableDefaults) {
    ngTableDefaults.params.setPager = false;
    ngTableDefaults.settings.counts = [10, 25, 50, 100];
    ngTableDefaults.params.page = 1;
  }
]);
app.run([
  '$templateCache',
  function ($templateCache) {
    $templateCache.put('ng-table/filters/text.html', require('./modules/interface/templates/text.html'));
    $templateCache.put('ng-table/filters/number.html', require('./modules/interface/templates/number.html'));
    $templateCache.put('ng-table/filters/select.html', require('./modules/interface/templates/select.html'));
    $templateCache.put('ng-table/pager.html', require('./modules/interface/templates/pager.html'));
  }
]);
// Configurações WYSIWYG

app.config([
  '$provide',
  function ($provide) {
    $provide.decorator('taOptions', [
      'taRegisterTool',
      '$delegate',
      function (taRegisterTool, taOptions) {
        // override the default toolbars and classes specified in taOptions.
        taOptions.forceTextAngularSanitize = false; // set false to allow the textAngular-sanitize provider to be replaced
        taOptions.keyMappings = []; // allow customizable keyMappings for specialized key boards or languages
        taOptions.toolbar = [['bold', 'italics', 'underline'], ['ul', 'ol', 'outdent', 'indent'], ['redo', 'undo', 'clear'], ['html']];
        taOptions.classes = {
          focussed: '',
          toolbar: 'btn-toolbar',
          toolbarGroup: 'btn-group',
          toolbarButton: 'btn btn-xs btn-default pmd-btn-flat pmd-ripple-effect',
          toolbarButtonActive: 'active',
          disabled: 'disabled',
          textEditor: 'form-control',
          htmlEditor: 'form-control'
        };
        return taOptions; // whatever you return will be the taOptions
      }
    ]);
    // Replace css icons
    $provide.decorator('taTools', [
      '$delegate',
      function (taTools) {
        taTools.bold.iconclass = ' mdi mdi-format-bold';
        taTools.italics.iconclass = 'mdi mdi-format-italic';
        taTools.underline.iconclass = ' mdi mdi-format-underline';
        taTools.ul.iconclass = ' mdi mdi-format-list-bulleted';
        taTools.ol.iconclass = ' mdi mdi-format-list-numbered';
        taTools.indent.iconclass = ' mdi mdi-format-indent-increase';
        taTools.outdent.iconclass = ' mdi mdi-format-indent-decrease';
        taTools.undo.iconclass = ' mdi mdi-undo';
        taTools.redo.iconclass = ' mdi mdi-redo';
        taTools.clear.iconclass = ' mdi mdi-format-clear';
        taTools.html.iconclass = ' mdi mdi-code-tags';
        return taTools;
      }
    ]);
  }
]);
// TODO: Descobrir onde isto é chamado
app.filter('removeAcentos', function () {
  return function (source) {
    if (!angular.isDefined(source)) {
      return;
    }
    let accent = [
        /[\300-\306]/g,
        /[\340-\346]/g, // A, a
        /[\310-\313]/g,
        /[\350-\353]/g, // E, e
        /[\314-\317]/g,
        /[\354-\357]/g, // I, i
        /[\322-\330]/g,
        /[\362-\370]/g, // O, o
        /[\331-\334]/g,
        /[\371-\374]/g, // U, u
        /[\321]/g,
        /[\361]/g, // N, n
        /[\307]/g,
        /[\347]/g // C, c
      ],
      noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

    for (let i = 0; i < accent.length; i++) {
      source = source.replace(accent[i], noaccent[i]);
    }
    return source;
  };
});
// Add refresh shortcut to do an F5 via $state
app.config([
  '$provide',
  function ($provide) {
    $provide.decorator('$state', [
      '$delegate',
      '$stateParams',
      function ($delegate, $stateParams) {
        $delegate.refresh = function () {
          return location.reload(true);
        };
        return $delegate;
      }
    ]);
  }
]);

// Manutenção de módulos
app.run([
  '$rootScope',
  '$state',
  function ($rootScope, $state) {
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      let isManutencao = false;
      // Vê a rota
      toState.manutencao = toState.manutencao || false;
      isManutencao = toState.manutencao;
      // Vê as rota base / pais
      let o = toState.name.split('.');
      if (o.length > 2) {
        let pais = o.length - 2;
        for (let i = 0; i <= pais; i++) {
          let aux = [...o];
          for (let j = 0; j < i; j++) {
            aux.pop();
          }
          let pai = $state.get(aux.join('.'));
          if (pai.manutencao === true) {
            isManutencao = true;
            break;
          }
        }
      }
      // Se for manutenção, redireciona
      if (isManutencao === true) {
        event.preventDefault();
        $state.go('app.maintenance');
      }
    });
  }
]);
