import angular from 'angular';

import {routes} from './noticias.routes';
import DirectoryNewsController from "./directory/directory.controller";
import CreateNewsController from "./create/create.controller";
import EditNewsController from "./create/edit.controller";


export default angular.module('app.news', [])
        .config(routes)
        .controller('DirectoryNewsController', DirectoryNewsController)
        .controller('CreateNewsController', CreateNewsController)
        .controller('EditNewsController', EditNewsController)
        .name;
