import React, { useEffect, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
const CrmCotacaoForm = ({ $state, CrmService, AuthorizationService }) => {
    const [pageLoad, setPageLoad] = useState(true);
    const [cotacao, setCotacao] = useState(null);
    const fetchData = async () => {
        try {
            //let c = CrmService.getCotacaoByID();
            //setCotacao(c);
            setPageLoad(false);
        }
        catch (error) {
            console.log('Erro ao obter cotação: ', error);
        }
    };
    const handleState = (stateId) => {
        // recebe o estado da cotação e retorna o componente (a página) consoante o estado
        switch (stateId) {
            case 1:
                break;
            case 2:
                break;
            case 3:
                break;
            case 4:
                break;
            case 5:
                break;
            case 6:
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        console.log($state.params.id);
        console.log(!$state.params.id);
        if ($state.params.id !== undefined) {
            fetchData();
        }
        else {
            setPageLoad(false);
        }
    }, []);
    return (<>
      {!pageLoad ? (<> Cotações </>) : (<Stack direction='row' justifyContent='center' alignItems='flex-start' sx={{ mt: 4 }}>
          <CircularProgress size={50}/>
        </Stack>)}
    </>);
};
export default CrmCotacaoForm;
