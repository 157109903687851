import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Collapse, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { AutoComplete, MdiIcon, Table } from '@components';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DropzoneArea } from 'mui-file-dropzone';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { mdiChevronUp, mdiContentSave, mdiMagnify, mdiPencil, mdiPlus } from '@mdi/js';
import moment from 'moment';
import { InfoDialog } from './infoDialog';
import { useModal } from 'mui-modal-provider';
import { v4 } from 'uuid';
import { debounce } from 'lodash';
const Interacoes = ({ $state, CrmService, user, client }) => {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [dataInteracoes, setDataInteracoes] = useState(); // dados dos clientes
    const [loadingInteracoes, setLoadingInteracoes] = useState(true);
    const [refetchingInteracoes, setRefetchingInteracoes] = useState(false);
    const [errorInteracoes, setErrorInteracoes] = useState(false);
    const [filterInteracoes, setFilterInteracoes] = useState([]); // filtros a aplicar na tabela
    const [sortInteracoes, setSortInteracoes] = useState([]); // ordenação da tabela
    const [optionsInteracoes, setOptionsInteracoes] = useState({
        filter: [],
        sort: [] // exemplo -> ['id', 'nome', 'ultimoContacto', 'contactoTelefonico', 'nif', 'email', 'morada', 'localidade']
    });
    const [paginationInteracoes, setPaginationInteracoes] = useState({
        pageIndex: 0,
        pageSize: 5
    });
    const [interacao, setInteracao] = useState(null);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [autoCompleteLoad, setAutoCompleteLoad] = useState(false);
    const [expand, setExpand] = useState(false);
    const [anexo, setAnexo] = useState(null);
    const [newAnexo, setNewAnexo] = useState(null);
    const tipoList = ['Telefone', 'Mail', 'Pessoal', 'Mensagem'];
    const validationSchema = yup.object().shape({
        dataInteracao: yup.string().required('Preenchimento obrigatório'),
        objetivos: yup.string().required('Preenchimento obrigatório').min(5, 'Os objetivos devem ter pelo menos 5 caracteres'),
        tipo: yup.string().required('Preenchimento obrigatório').min(3, 'O tipo deve ter pelo menos 3 caracteres'),
        idColaborador: yup.number().required('Preenchimento obrigatório').min(1, 'Selecione um colaborador válido')
    });
    const { showModal } = useModal();
    const formik = useFormik({
        initialValues: {
            id: interacao ? interacao.id : 0,
            tipo: interacao ? interacao.tipo : '',
            objetivos: interacao ? interacao.objetivos || '' : '',
            active: interacao ? interacao.active || 1 : 1,
            dataInteracao: interacao ? interacao.dataInteracao : '',
            anexo: interacao ? interacao.anexo || '' : null,
            idCoreContacto: interacao ? interacao.idCoreContacto : user ? user.id : 0,
            idCoreCliente: interacao ? interacao.idCoreCliente : user ? user.clienteId : 0,
            nomeColaborador: interacao ? interacao.nomeColaborador : '',
            observacoes: interacao ? interacao.observacoes || '' : '',
            idColaborador: interacao ? interacao.idColaborador : 0,
            nomeCoreContacto: interacao ? interacao.nomeCoreContacto : user ? user.nome : ''
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            console.log(values);
            submit(values);
        }
    });
    const submit = async (newValues) => {
        if (newValues.anexo) {
            handleFile(newValues);
        }
        try {
            console.log(newValues);
            const p = await CrmService.upsertInteracao(newValues);
            setRefetchingInteracoes(true);
            fetchData();
            setExpand(false);
            setOpenSnackBar(true);
            formik.resetForm({
                values: {
                    id: 0,
                    tipo: '',
                    objetivos: '',
                    active: 1,
                    dataInteracao: '',
                    anexo: '',
                    idCoreContacto: 0,
                    idCoreCliente: 0,
                    nomeColaborador: '',
                    observacoes: '',
                    idColaborador: 0,
                    nomeCoreContacto: ''
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    };
    // Filtragem dentro de cada coluna
    // Modo de filtro (igual a, contém, começa com... )
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    const initialFetch = async () => {
        if (loadingInteracoes) {
            // opt -> vai buscar os campos filtraveis
            const opt = await CrmService.optionsInteracoes();
            setOptionsInteracoes(opt);
            setLoadingInteracoes(false);
        }
    };
    const fetchData = async () => {
        setErrorInteracoes(false);
        try {
            let filters = {};
            filterInteracoes.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sortInteracoes.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            if (client !== undefined) {
                filters['idCoreCliente'] = `$eq:${client}`;
            }
            else {
                filters['idCoreContacto'] = `$eq:${user.id}`;
            }
            // aux vai buscar os dados consoante os filtros
            // exemplo -> { limit: 0, page: 20, filter[{nome: '$ilike:Pedro' }, ...], sortBy:['ultimoContacto:ASC',...]}
            const aux = await CrmService.findAllInteracoes({
                limit: paginationInteracoes.pageSize,
                page: paginationInteracoes.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            // Update URL
            $state.go($state.current.name, {
                limit: paginationInteracoes.pageSize,
                page: paginationInteracoes.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filterInteracoes).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            setDataInteracoes(aux);
        }
        catch (e) {
            console.log(e);
            setErrorInteracoes(true);
        }
        setRefetchingInteracoes(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetchingInteracoes) {
            setRefetchingInteracoes(true);
            fetchData();
        }
    }, [paginationInteracoes, filterInteracoes, sortInteracoes]);
    const handleTextInput = (event) => {
        const { id, value } = event.target;
        formik.setFieldValue(id, value);
    };
    const handleAutoCompleteSearch = useCallback(debounce(async (newValue) => {
        if (!newValue)
            return;
        setAutoCompleteLoad(true);
        try {
            const data = await CrmService.findFuncionarioByName(newValue);
            // Supondo que o serviço retorne um array de objetos { id, name }
            setAutoCompleteOptions(data);
        }
        catch (error) {
            console.error('Erro ao procurar colaborador:', error);
        }
        finally {
            setAutoCompleteLoad(false);
        }
    }, 500), // O valor 500 é o tempo de delay em milissegundos
    []);
    const handleSelectChange = (event) => {
        formik.setFieldValue('tipo', event.target.value);
    };
    const handleAutoCompleteChange = (selectedId, selectedName) => {
        formik.setFieldValue('idColaborador', selectedId);
        formik.setFieldValue('nomeColaborador', selectedName || '');
    };
    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };
    const handleFile = async (values) => {
        console.log('Saving file');
        const formData = new FormData();
        if (newAnexo.file) {
            const newFileName = values.anexo ? values.anexo : newAnexo.file.name;
            formData.append('file', newAnexo.file, newFileName);
            // Se a imagem for igual, não realiza upload
            if (newAnexo.dummy) {
                console.log(values);
                return values;
            }
            else {
                // Atualiza o valor da imagem no objeto `values`
                values.anexo = newFileName;
                try {
                    // Upload da nova imagem
                    const response = await fetch('/api/Upload/crm/upload', {
                        method: 'POST',
                        body: formData
                    });
                    if (response.ok) {
                        const content = await response.json();
                        return content.result;
                    }
                    else {
                        console.log(response);
                        console.error('Erro ao adicionar ficheiro');
                    }
                }
                catch (error) {
                    console.error('Error uploading file:', error);
                }
            }
        }
        else {
            return values;
        }
    };
    return (<Box>
      <Stack direction='column'>
        <Stack direction='row' spacing={1} justifyContent='flex-start' alignItems='center'>
          <Typography variant='h6' sx={{ color: 'black', mb: 1 }}>
            Últimas interações
          </Typography>
          {client === undefined ? (<Box>
              {expand ? (<Tooltip title={'Cancelar'}>
                  <IconButton color='primary' onClick={() => setExpand(!expand)}>
                    <MdiIcon path={mdiChevronUp} sx={{ color: '#1976D2' }}/>
                  </IconButton>
                </Tooltip>) : (<Box></Box>)}
              <Tooltip title={'Adicionar interação'}>
                <IconButton color='primary' onClick={() => {
                if (expand) {
                    formik.handleSubmit();
                }
                else {
                    setAnexo(null);
                    formik.resetForm();
                    setExpand(!expand);
                }
            }}>
                  <MdiIcon path={!expand ? mdiPlus : mdiContentSave} sx={{ color: '#1976D2' }}></MdiIcon>
                </IconButton>
              </Tooltip>
            </Box>) : (<></>)}
        </Stack>
      </Stack>

      <Collapse in={expand}>
        <Grid container spacing={2}>
          {/* Área da esquerda */}
          <Grid item xs={8}>
            <Grid container spacing={2}>
              {/* Linha 1: Data e Objetivos */}
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker value={formik.values.dataInteracao ? moment(formik.values.dataInteracao, 'DD-MM-YYYY') : null} onChange={value => {
            const formattedDate = value ? moment(value).format('DD-MM-YYYY') : '';
            formik.setFieldValue('dataInteracao', formattedDate); // Atualiza o campo no Formik
        }} slotProps={{
            textField: {
                size: 'small',
                fullWidth: true,
                label: 'Data',
                required: true,
                helperText: formik.touched.dataInteracao && formik.errors.dataInteracao,
                error: Boolean(formik.touched.dataInteracao && formik.errors.dataInteracao),
                sx: { bgcolor: '#F5F5F5' }
            }
        }}/>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth id='objetivos' label='Objetivos' color='info' size='small' sx={{ bgcolor: '#F5F5F5' }} onChange={handleTextInput} value={formik.values.objetivos} error={formik.touched.objetivos && Boolean(formik.errors.objetivos)} helperText={formik.touched.objetivos && formik.errors.objetivos} required={true}/>
              </Grid>

              {/* Linha 2: AutoComplete e Tipo */}
              <Grid item xs={6}>
                <AutoComplete selectList={autoCompleteOptions} // Opções carregadas dinamicamente
     id={formik.values.idColaborador} // Valor atual do ID
     onSearch={handleAutoCompleteSearch} onChange={(selectedId, selectedName) => {
            handleAutoCompleteChange(selectedId, selectedName);
        }} label='Colaborador' size='small' sx={{ bgcolor: '#F5F5F5' }} loading={autoCompleteLoad} // loading durante a pesquisa
     required={true} value={{
            id: formik.values.idColaborador,
            name: formik.values.nomeColaborador
        }} helperText={formik.touched.idColaborador && formik.errors.idColaborador}/>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size='small' sx={{
            bgcolor: '#F5F5F5',
            '& .MuiOutlinedInput-root': {
                borderRadius: '4px'
            }
        }}>
                  <InputLabel id='demo-simple-select-label' color='info' required error={formik.touched.tipo && Boolean(formik.errors.tipo)}>
                    Tipo
                  </InputLabel>
                  <Select labelId='demo-simple-select-label' id='demo-simple-select' label='Tipo ' value={formik.values.tipo} onChange={handleSelectChange} color='info' error={formik.touched.tipo && Boolean(formik.errors.tipo)}>
                    {tipoList.map((item, index) => (<MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Linha 3: Observações */}
              <Grid item xs={12}>
                <TextField fullWidth id='observacoes' label='Observações' color='info' size='small' multiline={true} rows={5} sx={{ bgcolor: '#F5F5F5' }} onChange={handleTextInput} value={formik.values.observacoes} error={formik.touched.observacoes && Boolean(formik.errors.observacoes)} helperText={formik.touched.observacoes && formik.errors.observacoes}/>
              </Grid>
            </Grid>
          </Grid>

          {/* Área da direita: Dropzone */}
          <Grid item xs={4}>
            <DropzoneArea acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']} filesLimit={1} initialFiles={anexo ? [anexo] : []} onChange={files => {
            if (files.length > 0) {
                let t = files.pop();
                let f = new File([t], `${v4()}.${t.name.split('.').pop()}`, { type: t.type });
                setNewAnexo({ file: f, dummy: false });
                setAnexo(URL.createObjectURL(f));
                formik.setFieldValue('anexo', f.name);
            }
            else {
                formik.setFieldValue('anexo', null);
                setNewAnexo(null);
                setAnexo(null);
            }
        }} 
    //onChange={files => handleFileChange(files, input.field)}
    //dropzoneText={input.dropzoneText || 'Arraste e solte o ficheiro aqui ou clique'}
    showFileNamesInPreview={true} showAlerts={false} useChipsForPreview={true} dropzoneText='Arraste e solte o documento aqui ou clique'/>
          </Grid>
        </Grid>
      </Collapse>

      <Box sx={{
            mt: 1,
            borderRadius: '15px',
            border: '1px solid #e0e0e0',
            overflow: 'hidden',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
        }}>
        <Table storageKey={$state.current.name} data={dataInteracoes} columns={[
            {
                accessorKey: 'dataInteracao',
                header: 'Data',
                size: 100
            },
            {
                accessorKey: 'tipo',
                header: 'Tipo',
                size: 100
            },
            {
                accessorKey: 'nomeColaborador',
                header: 'Colaborador IEP',
                size: 100
            },
            {
                accessorKey: 'nomeCoreContacto',
                header: 'Colaborador externo',
                size: 100
            },
            {
                accessorKey: 'objetivos',
                header: 'Objetivos',
                size: 200
            }
        ]} initial={{
            sort: sortInteracoes,
            filter: filterInteracoes
        }} isLoading={refetchingInteracoes} options={optionsInteracoes} onPaginate={setPaginationInteracoes} onFilter={setFilterInteracoes} onSort={setSortInteracoes} rowActionSize={100} actionItems={({ row }) => [
            <Box key={`actions-${row.original.id}`} display='flex' gap={1}>
              <Tooltip title={'Editar dados'} placement='left'>
                <IconButton color='primary' onClick={() => {
                    console.log(row.original);
                    formik.setValues(row.original);
                    if (row.original.anexo) {
                        let fileUrl = `/api/Upload/crm/download/${row.original.anexo}`;
                        setAnexo(fileUrl);
                    }
                    else {
                        setAnexo(null);
                    }
                    // Adicionar colaborador à lista de opções, se necessário
                    const colaboradorSelecionado = {
                        id: row.original.idColaborador,
                        name: row.original.nomeColaborador
                    };
                    setAutoCompleteOptions(prevOptions => {
                        // Adiciona o colaborador à lista se ele não existir
                        if (!prevOptions.some(option => option.id === colaboradorSelecionado.id)) {
                            return [...prevOptions, colaboradorSelecionado];
                        }
                        return prevOptions;
                    });
                    formik.setTouched({}, false);
                    if (!expand)
                        setExpand(true);
                }}>
                  <MdiIcon path={mdiPencil} sx={{ color: '#1976D2' }}/>
                </IconButton>
              </Tooltip>
              <Tooltip title={'Ver detalhes'} placement='top'>
                <IconButton color='secondary' onClick={() => {
                    const showData = [
                        { key: 'Data da interação', value: row.original.dataInteracao },
                        { key: 'Colaborador IEP', value: row.original.nomeColaborador },
                        { key: 'Tipo', value: row.original.tipo },
                        { key: 'Objetivos', value: row.original.objetivos },
                        { key: 'Anexo', value: row.original.anexo },
                        { key: 'Observações', value: row.original.observacoes }
                    ];
                    showModal(InfoDialog, {
                        title: 'Detalhes interação',
                        data: showData
                    });
                }}>
                  <MdiIcon path={mdiMagnify} sx={{ color: '#1976D2' }}/>
                </IconButton>
              </Tooltip>
            </Box>
        ]}></Table>
      </Box>
      <Snackbar open={openSnackBar} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity='success' variant='filled' sx={{ width: '100%' }}>
          Dados alterados com sucesso!
        </Alert>
      </Snackbar>
    </Box>);
};
export default Interacoes;
