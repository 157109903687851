import moment from 'moment';

export default class OblOIDetailsController {
  constructor(
    $state,
    $http,
    $filter,
    OblOrdemintervencao,
    OblProcesso,
    OblAgendamento,
    OblAlteracaoestado,
    OblEstado,
    OblTipoProcesso,
    OblPagamento,
    UIService,
    AuthenticationService,
    OblMotivo
  ) {
    this.$state = $state;
    this.$http = $http;
    this.$filter = $filter;
    this.id = $state.params.id;
    this.OblOrdemintervencao = OblOrdemintervencao;
    this.OblProcesso = OblProcesso;
    this.OblAgendamento = OblAgendamento;
    this.OblAlteracaoestado = OblAlteracaoestado;
    this.OblEstado = OblEstado;
    this.OblTipoProcesso = OblTipoProcesso;
    this.OblPagamento = OblPagamento;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.OblMotivo = OblMotivo;

    this.limit = 3;
    this.loadData();
  }

  editClient = () => {
    this.UI.showDialog({
      template: require('./client.dialog.html'),
      controller: [
        '$scope',
        ($scope) => {
          $scope.data = {
            nome: angular.copy(this.data.nomeCliente),
            nif: angular.copy(this.data.nifCliente),
            telemovel: angular.copy(this.data.tlmCliente),
            email: angular.copy(this.data.emailCliente),
          };

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      this.OblOrdemintervencao.prototype$updateAttributes(
        { id: this.id },
        {
          nomeCliente: r.nome,
          nifCliente: r.nif,
          emailCliente: r.email,
          tlmCliente: r.telemovel,
        }
      )
        .$promise.then((_) => {
          this.UI.addToast('Cliente final alterado com sucesso');
          this.data.nomeCliente = r.nomeCliente;
          this.data.nifCliente = r.nifCliente;
          this.data.emailCliente = r.emailCliente;
          this.data.tlmCliente = r.tlmCliente;
        })
        .catch((e) => {
          this.UI.addToast('Não foi possível alterar cliente final');
        });
    });
  };

  motive = () => {
    let data = angular.copy(this.data.motivo);
    this.UI.showDialog({
      template: require('./motive.dialog.html'),
      controller: [
        '$scope',
        ($scope) => {
          $scope.data = data;

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    })
      .then((r) => {
        if (r) {
          this.OblOrdemintervencao.prototype$updateAttributes(
            { id: this.id },
            {
              motivo: r,
            }
          )
            .$promise.then((_) => {
              this.UI.addToast('Motivo da intervenção alterado com sucesso');
              this.data.motivo = r;
            })
            .catch((e) => {
              this.UI.addToast(
                'Não foi possível alterar motivo da intervenção'
              );
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getEstadoProcesso = (processo) => {
    if (processo.tipoId == 1) {
      if (processo.Pagamento && processo.Pagamento.dataPagamento) {
        return `Pago a ${moment
          .utc(processo.Pagamento.dataPagamento)
          .format('dd-MM-yyyy [às] HH:mm')}`;
      } else {
        return `Aguarda pagamento (Valor: ${this.$filter('currency')(
          processo.Pagamento.montante,
          '€',
          2
        )}`;
      }
    } else if (processo.tipoId == 2 && processo.extra == 0) {
      if (processo.validado == 0) {
        if (processo.Agendamento) {
          return `Agendado para ${moment(processo.Agendamento.data).format(
            'DD-MM-YYYY'
          )} às ${processo.Agendamento.hora.slice(0, 5)} para ${
            processo.Agendamento.Funcionario.name
          }`;
        } else {
          return 'Aguarda agendamento';
        }
      }
      if (processo.validado == 1 && processo.realizada == 1) {
        if (processo.aceite == 1) {
          return 'Orçamento aceite';
        } else if ((processo.aceite = 2)) {
          return 'Orçamento pendente de validação pelo cliente';
        } else {
          return 'Orçamento rejeitado';
        }
      } else {
        return (
          'Não Executado' +
          (processo.Motivo && processo.Motivo.designacao
            ? `(${processo.Motivo.designacao})`
            : '')
        );
      }
    } else {
      if (processo.validado == 0) {
        if (processo.Agendamento) {
          return `Agendado para ${moment(processo.Agendamento.data).format(
            'DD-MM-YYYY'
          )} às ${processo.Agendamento.hora.slice(0, 5)} para ${
            processo.Agendamento.Funcionario.name
          }`;
        } else {
          return 'Aguarda agendamento';
        }
      } else {
        if (
          processo.validado == 1 &&
          processo.tipoId == 2 &&
          processo.extra == 1
        ) {
          return 'Resolvido no ato da orçamentação';
        }
        if (processo.validado == 1 && processo.realizada == 1) {
          return (
            'Executado' +
            (processo.Motivo && processo.Motivo.designacao
              ? `(${processo.Motivo.designacao})`
              : '')
          );
        } else {
          return (
            'Não Executado' +
            (processo.Motivo && processo.Motivo.designacao
              ? `(${processo.Motivo.designacao})`
              : '')
          );
        }
      }
    }
  };

  obs = () => {
    let data = angular.copy(this.data.observacoes);
    this.UI.showDialog({
      template: require('./obs.dialog.html'),
      controller: [
        '$scope',
        ($scope) => {
          $scope.data = data;

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    })
      .then((r) => {
        if (r) {
          this.OblOrdemintervencao.prototype$updateAttributes(
            { id: this.id },
            {
              observacoes: r,
            }
          )
            .$promise.then((_) => {
              this.UI.addToast('Observações alteradas com sucesso');
              this.data.observacoes = r;
            })
            .catch((e) => {
              this.UI.addToast('Não foi possível alterar observações');
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  editDetails = () => {
    let state = this.data.estadoId;
    this.UI.showDialog({
      template: require('./details.dialog.html'),
      controller: [
        '$scope',
        'OblValencia',
        'OblEstado',
        ($scope, OblValencia, OblEstado) => {
          $scope.valenciasLoaded = false;
          $scope.statesLoaded = false;
          $scope.isParceiro = this.data.origem == 2;
          $scope.aux = {
            parceiro: angular.copy(this.data.parceiro),
            morada: angular.copy(this.data.morada),
            cp4: angular.copy(this.data.cp4),
            cp3: angular.copy(this.data.cp3),
            localidade: angular.copy(this.data.localidade),
            concelho: angular.copy(this.data.concelho),
            distrito: angular.copy(this.data.distrito)
          };
          $scope.valencias = [];

          $scope.showObs = () => {
            if (!$scope.statesLoaded) return false;
            return $scope.aux.estado.id != state;
          };

          //Infinite Scroll Magic
          $scope.infiniteScroll = {};
          $scope.infiniteScroll.numToAdd = 20;
          $scope.infiniteScroll.currentItems = 20;

          $scope.addMoreItems = function () {
            $scope.infiniteScroll.currentItems += $scope.infiniteScroll.numToAdd;
          };

          OblValencia.find({
            filter: { where: { active: true }, include: 'categoria' },
          }).$promise.then((r) => {
            r.forEach(a => {
              $scope.valencias.push({
                id: a.id,
                name: `${a.categoria.designacao} - ${a.designacao}`
              });
            });
            $scope.aux.valencia = $scope.valencias.find((o) => o.id == this.data.valenciaId);
            $scope.valenciasLoaded = true;
          });

          OblEstado.find({
            filter: { where: { active: true, id: { neq: 999 } } },
          }).$promise.then((r) => {
            $scope.aux.estado = r.find((o) => o.id == this.data.estadoId);
            $scope.states = r;
            $scope.statesLoaded = true;
          });

          $scope.cancel = () => {
            $scope.$dismiss();
          };

          $scope.getLocalidade = () => {
            if ($scope.aux.cp4 && $scope.aux.cp4.toString().length === 4) {
              this.$http.post('/api/public/getLocalidade', {
                cp4: $scope.aux.cp4,
                cp3: $scope.aux.cp3,
              }).then(response => {
                $scope.aux.localidade = response.data.result;
                $scope.aux.concelho = response.data.concelho;
                $scope.aux.distrito = response.data.distrito;
              });
            }
          };

          $scope.ok = () => {
            $scope.data = {
              parceiro: $scope.aux.parceiro,
              observacoes: $scope.aux.obs,
              estadoId: $scope.aux.estado.id,
              valenciaId: $scope.aux.valencia.id,
              morada: $scope.aux.morada,
              cp4: $scope.aux.cp4,
              cp3: $scope.aux.cp3,
              localidade: $scope.aux.localidade,
              concelho: $scope.aux.concelho,
              distrito: $scope.aux.distrito
            };
            $scope.$close($scope.data);
          };
        },
      ],
    }).then((r) => {
      if (r) {
        this.loaded = false;
        delete this.data.Servico;
        this.data.parceiro = r.parceiro;
        delete this.data.Valencia;
        this.data.valenciaId = r.valenciaId;
        this.data.morada = r.morada;
        this.data.cp4 = r.cp4;
        this.data.cp3 = r.cp3;
        this.data.localidade = r.localidade;
        this.data.concelho = r.concelho;
        this.data.distrito = r.distrito;
        this.OblOrdemintervencao.upsert(this.data)
          .$promise.then((s) => {
            // Atualiza estado
            if (r.estadoId != s.estadoId) {
              this.changeState(r.estadoId, r.observacoes);
            } else {
              this.UI.addToast('Detalhes alterados com sucesso');
              this.loadData();
            }
          })
          .catch((e) => {
            this.UI.addToast('Ocorreu um erro ao alterar detalhes');
            this.loadData();
          });
      }
    });
  };

  formatBytes = (bytes, decimals) => {
    if (bytes == null) return 'N/D';
    if (bytes === 0) return '0 Bytes';
    let k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  markAsPaid = (pagamento) => {
    this.loaded = false;
    pagamento.dataPagamento = moment.utc();
    this.OblPagamento.upsert(pagamento)
      .$promise.then((r) => {
        this.changeState(2, `Marcado como pago via SGI`);
      })
      .catch((e) => {
        this.UI.addToast('Ocorreu um erro ao marcar pagamento como Pago');
        this.loadData();
      });
  };

  giveIID = () => {
    this.UI.showConfirm('Gerar número de processo?').then((r) => {
      if (r) {
        this.OblOrdemintervencao.generateIdentifier({ id: this.id })
          .$promise.then((_) => {
            this.UI.addToast('Número de processo gerado');
            this.loadData();
          })
          .catch((e) => {
            this.UI.addToast('Não foi possível gerar número de processo');
            this.loadData();
          });
      }
    });
  };

  limitTo = () => {
    if (angular.isDefined(this.limit)) {
      this.limit = undefined;
    } else {
      this.limit = 3;
    }
  };

  schedule = (row) => {
    let o;
    if (row.Agendamento == undefined) {
      let m = moment();
      let [hours, minutes] = [m.hours(), m.minutes()]; // Attempt to fix bad data choices
      o = {
        id: 0,
        processoId: row.id,
        data: moment.utc(),
        hora: moment.utc().hours(hours).minutes(minutes).seconds(0).milliseconds(0),
        agendadoa: moment.utc().format('HH:mm:00'),
        agendadoporId: this.Auth.getId(),
        active: true,
        duracao: 60,
      };
    } else {
      let [hours, minutes] = row.Agendamento.hora.split(":").map(Number); // Attempt to fix bad data choices
      o = {
        id: row.Agendamento.id,
        processoId: row.id,
        data: moment.utc(row.Agendamento.data),
        hora: moment.utc(row.Agendamento.data).hours(hours).minutes(minutes).seconds(0).milliseconds(0),
        agendadoa: moment.utc(row.Agendamento.agendadoa),
        agendadoporId: row.Agendamento.agendadoporId,
        tecnicoId: row.Agendamento.tecnicoId,
        active: true,
        duracao: row.Agendamento.duracao,
      };
    }
    this.UI.showDialog({
      template: require('./schedule.dialog.html'),
      controller: [
        '$scope',
        'FuncionarioEntidadeProprietaria',
        ($scope, FuncionarioEntidadeProprietaria) => {
          $scope.dateOptions = {
            minDate: moment().subtract(1, 'w'),
            format: 'YYYY-MM-DD',
          };

          $scope.hourOptions = {
            format: 'HH:mm',
          };

          $scope.data = o;

          $scope.aux = {};

          FuncionarioEntidadeProprietaria.find({
            filter: {
              where: {
                entidadeProprietariaId: 3,
                active: true,
              },
              include: {
                relation: 'funcionario',
              },
            },
          }).$promise.then((f) => {
            $scope.users = [];
            f.forEach((r) => {
              if (r.funcionario) {
                $scope.users.push(r.funcionario);
              }
            });
            $scope.users = $scope.users.sort((a, b) =>
              a.name.localeCompare(b.name)
            );

            $scope.aux.user = $scope.users.find((r) => r.id == o.tecnicoId);

            $scope.loaded = true;
          });

          $scope.ok = () => {
            $scope.data.tecnicoId = $scope.aux.user.id;
            $scope.data.Funcionario = $scope.aux.user;
            $scope.data.hora = $scope.data.hora.format('HH:mm');
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((res) => {
      if (res) {
        this.OblAgendamento.upsert(res).$promise.then((r) => {
          r.Funcionario = res.Funcionario;
          this.UI.addToast('Agendamento alterado com sucesso');
          row.Agendamento = r;
        });
      }
    });
  };

  formatZipCode = () => {
    if (this.data.cp4 === null) {
      this.data.formatedZipCode = 'N/D';
    } else if (this.data.cp4 !== null && this.data.cp3 === null) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: '' + this.data.cp4,
          cp3: null,
        },
      })
        .then((response) => {
          this.data.localidade = response.data.result;
          this.data.formatedZipCode =
            this.data.cp4 + ' ' + this.data.localidade;
        })
        .catch((e) => {
          this.data.formatedZipCode =
            this.data.cp4 + '-' + this.data.cp3 + ' ' + this.data.localidade;
        });
    } else if (this.data.cp4 !== null && this.data.cp3 !== null) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: '' + this.data.cp4,
          cp3: ('000' + this.data.cp3).slice(-3),
        },
      })
        .then((response) => {
          this.data.cp3 = ('000' + this.data.cp3).slice(-3);
          this.data.localidade = response.data.result;
          this.data.formatedZipCode =
            this.data.cp4 + '-' + this.data.cp3 + ' ' + this.data.localidade;
        })
        .catch((e) => {
          this.data.formatedZipCode =
            this.data.cp4 + '-' + this.data.cp3 + ' ' + this.data.localidade;
        });
    }
  };

  loadData = () => {
    this.loaded = false;
    this.OblOrdemintervencao.findOne({
      filter: {
        where: {
          id: this.id,
        },
        include: [
          {
            relation: 'Valencia',
            scope: {
              where: {
                active: true,
              },
              include: 'categoria',
            },
          },
          {
            relation: 'Estado',
            scope: {
              where: {
                active: true,
              },
            },
          },
          {
            relation: 'Cliente',
            scope: {
              where: {
                active: true,
              },
            },
          },
          {
            relation: 'Anexos',
            scope: {
              where: {
                active: true,
              },
            },
          },
          {
            relation: 'Historico',
            scope: {
              where: {
                active: true,
              },
              order: 'dataAlteracao DESC, id DESC',
              include: ['Funcionario', 'EstadoFinal'],
            },
          },
          {
            relation: 'Processos',
            scope: {
              where: {
                active: true,
              },
              order: 'data DESC, id DESC',
              include: [
                'Motivo',
                'TipoProcesso',
                'Pagamento',
                {
                  relation: 'Agendamento',
                  scope: {
                    include: 'Funcionario',
                  },
                },
                {
                  relation: 'ProdutoProcesso',
                  scope: {
                    include: 'Produto',
                  },
                },
              ],
            },
          },
        ],
      },
    }).$promise.then((r) => {
      this.data = r;
      this.docs = angular.copy(this.data.Anexos);
      this.getAttachmentData();
      this.formatZipCode();
      this.loaded = true;
    });
  };

  getAttachmentData = () => {
    this.docs.forEach((d) => {
      let url = '/api/Upload/obler/files/' + d.nome;
      this.$http.get(url).then(
        (result) => {
          if (result.data != null && !result.data.hasOwnProperty('error')) {
            d.atime = result.data.atime;
            d.mtime = result.data.mtime;
            d.fileSize = result.data.size;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    });
  };

  getDocType = (f) => {
    if (f.tipodocumentoId == 2) {
      return 'Orçamento';
    }
    if (f.tipodocumentoId == 3) {
      return 'Folha de Obra';
    }
  };

  changeState = (state, notes) => {
    let user = this.Auth.getUser();
    this.OblAlteracaoestado.create({
      id: 0,
      oiId: this.data.id,
      estadoInicial: this.data.estadoId,
      estadoFinal: state,
      dataAlteracao: moment.utc(),
      funcionarioId: user.id,
      observacoes: notes || '',
    })
      .$promise.then((r) => {
        this.UI.addToast('Estado atualizado com sucesso');
        this.loadData();
      })
      .catch((e) => {
        this.UI.addToast('Ocorreu um erro ao alterar estado');
        this.loadData();
      });
  };

  report = () => {
    this.UI.showConfirm(
      `Vai gerar um relatório técnico para esta visita técnica, concluíndo assim a intervenção. Pretende continuar?`
    ).then((_) => {
      if (_) {
        // TODO: Gera relatório
      }
    });
  };

  budget = (processo, result) => {
    this.UI.showConfirm(
      `Vai marcar que o cliente ${
        result == true ? 'aceitou' : 'rejeitou'
      } este orçamento. Pretende continuar?`
    ).then((_) => {
      if (_) {
        this.loaded = false;
        // Valida processo
        processo.validado = true;
        processo.aceite = result;
        this.OblProcesso.upsert(processo)
          .$promise.then((r) => {
            if (result) {
              // Altera estado para agendamento Visita técnica
              this.changeState(7);
            } else {
              // Altera estado para cancelado
              this.changeState(10, 'Cliente rejeitou orçamento');
            }
          })
          .catch((e) => {
            this.UI.addToast('Ocorreu um erro a validar orçamento');
            this.loadData();
          });
      }
    });
  };

  add = () => {
    this.UI.showDialog({
      template: require('./processo.dialog.html'),
      controller: [
        '$scope',
        'OblTipoProcesso',
        'FuncionarioEntidadeProprietaria',
        ($scope, OblTipoProcesso, FuncionarioEntidadeProprietaria) => {
          $scope.aux = {};

          $scope.processoLoaded = false;
          $scope.tecnicosLoaded = false;

          OblTipoProcesso.find({
            filter: {
              where: {
                active: true,
                id: {
                  gt: 1,
                },
                state: this.data.estadoId,
              },
            },
          }).$promise.then((r) => {
            $scope.tipos = r;
            $scope.processoLoaded = true;
          });

          $scope.$watch(
            () => {
              return $scope.data.agData;
            },
            (val) => {
              if (!$scope.data.agData) {
                $scope.data.agData = moment.utc();
                if (moment.utc().isDST()) {
                  $scope.data.agHora = moment.utc().add(1,'hour');
                }

              }
              $scope.tecnicosLoaded = false;
              FuncionarioEntidadeProprietaria.find({
                filter: {
                  where: {
                    entidadeProprietariaId: 3,
                    active: true,
                  },
                  include: {
                    relation: 'funcionario',
                    scope: {
                      include: {
                        relation: 'ausencias',
                        scope: {
                          where: {
                            dataInicio: {
                              lte: $scope.data.agData,
                            },
                            dataFim: {
                              gte: $scope.data.agData,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              }).$promise.then((f) => {
                $scope.users = [];
                f.forEach((r) => {
                  if (r.funcionario) {
                    if (r.funcionario.ausencias.length == 0) {
                      r.funcionario.disabled = false;
                      $scope.users.push(r.funcionario);
                    }
                  }
                });
                $scope.users = $scope.users.sort((a, b) =>
                  a.name.localeCompare(b.name)
                );
                $scope.tecnicosLoaded = true;
              });
            }
          );

          $scope.data = {
            duracao: 60,
          };

          $scope.dateOptions = {
            minDate: moment(),
            format: 'YYYY-MM-DD',
          };

          $scope.hourOptions = {
            format: 'HH:mm',
          };

          $scope.ok = () => {
            $scope.data.tipoId = $scope.aux.tipo.id;
            $scope.data.funcionarioId = $scope.aux.user.id;
            $scope.$close($scope.data);
          };
          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      if (r) {
        this.loaded = false;
        let userId = this.Auth.getId();
        // Cria processo e agenda
        this.OblProcesso.create({
          id: 0,
          oiId: this.id,
          validado: false,
          active: true,
          criadoPor: userId,
          data: moment.utc(),
          funcionarioId: r.funcionarioId,
          tipoId: r.tipoId,
        })
          .$promise.then((processo) => {
            this.OblAgendamento.create({
              id: 0,
              duracao: r.duracao,
              agendadoa: moment.utc(),
              data: r.agData.format('YYYY-MM-DD'),
              hora: r.agHora.format('HH:mm:00'),
              tecnicoId: r.funcionarioId,
              agendadoporId: userId,
              processoId: processo.id,
              active: true,
            })
              .$promise.then((agendamento) => {
                if (r.tipoId == 2) {
                  this.changeState(4);
                } else if (r.tipoId == 3) {
                  this.changeState(9);
                } else {
                  this.UI.addToast('Agendamento efetuado');
                  this.loadData();
                }
              })
              .catch((e) => {
                this.UI.addToast('Ocorreu um erro ao agendar processo');
                this.loadData();
              });
          })
          .catch((e) => {
            this.UI.addToast('Ocorreu um erro ao adicionar processo');
            this.loadData();
          });
      }
    });
  };

  editInfo = () => {
    console.log(this.data);
    this.UI.showDialog({
      template: require('./information.dialog.html'),
      controller: [
        '$scope',
        'FileUploader',
        ($scope, FileUploader) => {
          $scope.data = {
            descricaoAvaria: this.data.descricaoAvaria,
            horario: this.data.horario,
          };

          $scope.uploader = new FileUploader({
            url: '/api/Upload/obler/upload',
            queueLimit: 1,
            autoUpload: true,
          });

          let maxSizeFilter = {
            name: 'maxFileSize',
            fn: (item, options) => {
              let sizeInMB = 50 * 1048576;
              if (item.size > sizeInMB) {
                this.UI.message(
                  'O tamanho do ficheiro ultrapassa o tamanho máximo permitido (50 MB)',
                  'Erro de ficheiro de anexo'
                );
              }
              return item.size <= sizeInMB; // 50 MB to bytes
            },
          };
          // Filtro para verificar tamanho min. ficheiro
          let minSizeFilter = {
            name: 'fileGreaterZero',
            fn: (item, options) => {
              if (item.size <= 0) {
                this.UI.message(
                  'Erro ao obter tamanho do ficheiro, verifique se o mesmo não está corrompido.',
                  'Erro de ficheiro de anexo'
                );
              }
              return item.size > 0;
            },
          };

          $scope.uploader.filters.push(maxSizeFilter);
          $scope.uploader.filters.push(minSizeFilter);

          $scope.uploader.onBeforeUploadItem = (item) => {
            let extension = item.file.name.split('.').pop();
            item.file.name = generateUUID() + `.${extension}`;
          };

          let generateUUID = () => {
            let uuid = '',
              i,
              random;
            for (i = 0; i < 32; i++) {
              random = (Math.random() * 16) | 0;
              if (i == 8 || i == 12 || i == 16 || i == 20) {
                uuid += '-';
              }
              uuid += (
                i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random
              ).toString(16);
            }
            return uuid;
          };

          $scope.uploader.onSuccessItem = (item) => {
            $scope.data.fotografiaAvaria = `/api/Upload/obler/download/${item.file.name}`;
          };

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        },
      ],
    }).then((r) => {
      if (r) {
        this.OblOrdemintervencao.prototype$updateAttributes({ id: this.id }, r)
          .$promise.then((_) => {
            this.UI.addToast('Informação adicional alterada com sucesso');
            Object.assign(this.data, r);
          })
          .catch((e) => {
            this.UI.addToast('Não foi possível alterar informação adicional');
          });
      }
    });
  };

  confirmCancelProcesso = (processoId) => {
    let wait = this.UI.showWaiting();
    this.OblMotivo.find({
      filter: {
        where: {
          and: [{active: true}, {realizada: 0}, {admin: 0}]
        }
      }
    }).$promise.then(motivos => {
      wait.close();
      this.UI.showDialog({
        template: require('./motivo.dialog.html'),
        controller: ['$scope', ($scope) => {
          $scope.label = "Cancelamento de Folha de Trabalho";
          $scope.motivos = motivos;
          $scope.auxMotivo = {
            selected: undefined,
            infiniteScroll: {numToAdd: 20, currentItems: 20}
          };

          //Infinite Scroll Magic
          $scope.addMoreItems = (infiniteScroll) => {
            infiniteScroll.currentItems += infiniteScroll.numToAdd;
          };

          $scope.ok = () => {
            $scope.$close($scope.auxMotivo.selected.id);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };

        }]
      }).then((motivoId) => {
        if (motivoId) {
          this.cancelProcesso(processoId, motivoId);
        }
      });
    }).catch(err => {
      wait.close();
      console.log(err);
      this.UI.addToast("Erro de carregamento de dados para cancelamento (Motivos).");
    });
  };

  cancelProcesso = (processoId, motivoId) => {
    let wait = this.UI.showWaiting();
    this.OblProcesso.findById({id: processoId}).$promise.then((p) => {
      if (p.validado) {
        this.UI.addToast("Folha de trabalho já se encontra validada, não é possível cancelar");
        wait.close();
      } else {
        this.OblProcesso.prototype$updateAttributes(
          { id: processoId },
          {
            realizada: 0,
            motivoId: motivoId,
            dataSubmissao: new Date(),
            aceite: 0,
            funcionarioId: this.Auth.getId(),
            validado: 1
          }
        ).$promise.then(_ => {
          this.UI.addToast('Folha cancelada com sucesso');
          wait.close();
          this.loadData();
        }).catch(e => {
            this.UI.addToast('Não foi possível cancelar folha. Verifique a ligação');
          wait.close();
          });
      }
    }).catch((error) => {
      console.log(error);
      wait.close();
      this.UI.addToast("Não foi possível encontrar documento. Por favor recarregue a página");
    });
  };
}

OblOIDetailsController.$inject = [
  '$state',
  '$http',
  '$filter',
  'OblOrdemintervencao',
  'OblProcesso',
  'OblAgendamento',
  'OblAlteracaoestado',
  'OblEstado',
  'OblTipoProcesso',
  'OblPagamento',
  'UIService',
  'AuthenticationService',
  'OblMotivo'
];
