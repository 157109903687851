export default class UIService {
  constructor($rootScope, $filter, $http, Toast, Snackbar, Dialog) {
    this.$rootScope = $rootScope;
    $rootScope.status = {};
    this.$filter = $filter;
    this.$http = $http;
    this.Toast = Toast;
    this.Snackbar = Snackbar;
    this.Dialog = Dialog;
    this.sidebar = false;

    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      this.$rootScope.status = data;
    });

    if ((navigator.userAgent.toLowerCase().indexOf('msie') !== -1)) {
      if(parseInt(navigator.userAgent.toLowerCase().split('msie')[1]) <= 10) {
        window.location.assign('/not-supported.html');
      }
    }
  }

  sidebarClick = () => {
    this.sidebar = !this.sidebar;
  };

  sidebarStatus = () => {
    return this.sidebar;
  };

  addToast = (message, length) => {
    this.Toast.add(message, length);
  };

  addToast = (message) => {
    this.Toast.add(message, this.Toast.LENGTH_SHORT);
  };

  addSnackbar = (message, action, dismissable) => {
    return this.Snackbar.add(message, action, dismissable);
  };

  showAlert = (message) => {
    return this.Dialog.alert(message);
  };

  showConfirm = (message) => {
    return this.Dialog.confirm(message);
  };

  showDialog = (options) => {
    return this.Dialog.dialog(options);
  };

  showWaiting = () => {
    return this.Dialog.waiting();
  };

  pickDate = (options, text, initialDate) => {
    return this.Dialog.date(options, text, initialDate);
  }

  pickDateRange = (options, text, initialTime) => {
    return this.Dialog.dateRange(options, text, initialTime);
  }

  showFormMessage = (message, text) => {
    console.log(message,text);
    return this.Dialog.message(message, text);
  };

  setTitle = title => {
    this.$rootScope.title = title;
  }

  formatZipCode = (data) => {
    if (data == undefined) return;
    if (data.cp4 === null) {
      data.formatedZipCode = "N/D";
    } else if (data.cp4 !== null && data.cp3 === null) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" + data.cp4,
          cp3: null
        }
      }).then(response => {
        data.localidade = response.data.result;
        data.formatedZipCode = data.cp4 + " " + data.localidade;
      }).catch(e => {
        data.formatedZipCode = data.cp4 + "-" + data.cp3 + " " + data.localidade;
      });

    } else if (data.cp4 !== null && data.cp3 !== null) {
      this.$http({
        url: '/api/public/getLocalidade',
        method: 'POST',
        async: true,
        data: {
          cp4: "" + data.cp4,
          cp3: ("000" + data.cp3).slice(-3)
        }
      }).then(response => {
        data.cp3 = ("000" + data.cp3).slice(-3);
        data.localidade = response.data.result;
        data.formatedZipCode = data.cp4 + "-" + data.cp3 + " " + data.localidade;
      }).catch(e => {
        data.formatedZipCode = data.cp4 + "-" + data.cp3 + " " + data.localidade;
      });;
    }
  };
}

UIService.$inject = ['$rootScope', '$filter', '$http', 'Toast', 'Snackbar', 'Dialog'];
