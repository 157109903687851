export default class CrmService {
    $rootScope;
    $q;
    CoreCliente;
    CoreContacto;
    CrmInteracao;
    Funcionario;
    CrmEmpresaAssociada;
    CrmDocumentoAssociado;
    CrmPessoaAssociada;
    envURL;
    user;
    constructor($rootScope, $q, AuthenticationService, CoreCliente, CoreContacto, CrmInteracao, Funcionario, CrmEmpresaAssociada, CrmDocumentoAssociado, CrmPessoaAssociada) {
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.CoreCliente = CoreCliente;
        this.CoreContacto = CoreContacto;
        this.CrmInteracao = CrmInteracao;
        this.Funcionario = Funcionario;
        this.CrmEmpresaAssociada = CrmEmpresaAssociada;
        this.CrmDocumentoAssociado = CrmDocumentoAssociado;
        this.CrmPessoaAssociada = CrmPessoaAssociada;
        this.user = AuthenticationService.getUser();
        // By default, envURL is production (to account for fails)
        this.envURL = 'https://inspecoeseletricas.pt';
        // Get environment for URL for files
        // Doing this here only because we only use this.envURL in the view
        this.$rootScope.$on('$receivedServerStatus', (evt, data) => {
            if (data != null && data.environment != null) {
                let env = data.environment;
                if (env === 'staging') {
                    this.envURL = 'https://staging-ie.iep.pt';
                }
                else if (env === 'production') {
                    // For completeness
                    this.envURL = 'https://inspecoeseletricas.pt';
                }
                else {
                    this.envURL = 'https://dev-iep-ie.streamline.pt';
                }
            }
        });
    }
    getUserId() {
        return this.user.id;
    }
    generateUUID = () => {
        let uuid = '', i, random;
        for (i = 0; i < 32; i++) {
            random = (Math.random() * 16) | 0;
            if (i == 8 || i == 12 || i == 16 || i == 20) {
                uuid += '-';
            }
            uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
        }
        return uuid;
    };
    // Pessoas
    findPessoaById = (id) => {
        let defer = this.$q.defer();
        this.CoreContacto.findOne({ filter: { where: { id: id } } })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    upsertPessoa = (pessoa) => {
        let defer = this.$q.defer();
        pessoa.password = this.generateUUID();
        if (pessoa.clienteId === null) {
            pessoa.clienteId = 1;
        }
        this.CoreContacto.upsert(pessoa)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // Emrpesas
    findEmpresas = () => {
        let defer = this.$q.defer();
        this.CoreCliente.find({ filter: { limit: 100 } })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    upsertEmpresa = (empresa) => {
        let defer = this.$q.defer();
        if (empresa.uuid == '') {
            empresa.uuid = this.generateUUID();
        }
        this.CoreCliente.upsert(empresa)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // tabelas
    // save and edit Interação
    upsertInteracao = (dadosInteracao) => {
        let defer = this.$q.defer();
        if (dadosInteracao.observacoes == '') {
            dadosInteracao.observacoes = null;
        }
        this.CrmInteracao.upsert(dadosInteracao)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // save and edit Empresa Associada
    upsertEmpresaAssociada = async (dadosEmp) => {
        let defer = this.$q.defer();
        if (dadosEmp.observacoes == '') {
            dadosEmp.observacoes = null;
        }
        console.log(dadosEmp);
        this.CrmEmpresaAssociada.upsert(dadosEmp)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // save and edit Documento Associado
    upsertDocumentoAssociado = (dadosDocumentoAssociado) => {
        let defer = this.$q.defer();
        if (dadosDocumentoAssociado.observacoes == '') {
            dadosDocumentoAssociado.observacoes = null;
        }
        this.CrmDocumentoAssociado.upsert(dadosDocumentoAssociado)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // save and edit Empresa Associada
    upsertPessoaAssociada = async (dados) => {
        let defer = this.$q.defer();
        if (dados.observacoes == '') {
            dados.observacoes = null;
        }
        console.log(dados);
        this.CrmPessoaAssociada.upsert(dados)
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // find empresa (Autocomplete)
    findEmpresaByName = (name) => {
        let defer = this.$q.defer();
        this.CoreCliente.find({
            filter: {
                where: { nome: { like: `%${name}%` } },
                limit: 10
            }
        })
            .$promise.then(data => {
            const formattedData = (data || []).map((cliente) => ({
                id: cliente.id,
                name: cliente.nome // Assume que o nome está disponível como `nome`
            }));
            defer.resolve(formattedData);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // find funcionário (Autocomplete)
    findFuncionarioByName = (name) => {
        let defer = this.$q.defer();
        this.Funcionario.find({
            filter: {
                where: { name: { like: `%${name}%` } },
                limit: 10
            }
        })
            .$promise.then(data => {
            const formattedData = (data || []).map((contacto) => ({
                id: contacto.id,
                name: contacto.name // Assume que o nome está disponível como `nome`
            }));
            defer.resolve(formattedData);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    findEmpresaById = (id) => {
        let defer = this.$q.defer();
        this.CoreCliente.findOne({ filter: { where: { id: id } } })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // find contacto (Autocomplete com paginação)
    findContactoByName = (name, page, limit, noFetch) => {
        let defer = this.$q.defer();
        this.CoreContacto.find({
            filter: {
                where: {
                    and: [
                        { nome: { like: `%${name}%` } },
                        { id: { nin: noFetch } } // Não incluir IDs do noFetch
                    ]
                },
                include: ['cliente'],
                skip: page * limit,
                limit: limit
            }
        })
            .$promise.then(data => {
            const formattedData = (data || []).map((contacto) => ({
                id: contacto.id,
                name: contacto.nome,
                email: contacto.email || '',
                empresa: contacto.cliente?.nome || 'Sem empresa associada'
            }));
            defer.resolve(formattedData);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // Pessoas Associadas
    getPessoasAssociadas = (id) => {
        let defer = this.$q.defer();
        this.CrmPessoaAssociada.find({
            filter: {
                where: { idCoreContacto: id, active: 1 },
                include: [
                    {
                        relation: 'contactoAssociado',
                        scope: {
                            include: [
                                {
                                    relation: 'cliente' // Relação para buscar os dados do cliente
                                }
                            ]
                        }
                    }
                ]
            }
        })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    alterarAssociacao = (idContacto, idNovaEmpresa) => {
        let defer = this.$q.defer();
        this.CoreContacto.patch({ id: idContacto }, { clienteId: idNovaEmpresa })
            .$promise.then(data => {
            defer.resolve(data);
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    removePessoasAssociadas = (ids) => {
        let defer = this.$q.defer();
        // Lista de promessas para atualizar o campo 'active' para 0
        const updatePromises = ids.map(id => this.CrmPessoaAssociada.patch({ id }, { active: 0 }).$promise);
        Promise.all(updatePromises)
            .then(() => {
            defer.resolve();
        })
            .catch(error => {
            defer.reject(error);
        });
        return defer.promise;
    };
    // tabela empresas
    findAllClientes(query) {
        return this.CoreCliente.table(query).$promise;
    }
    optionsClientes() {
        return this.CoreCliente.options().$promise;
    }
    // tabela clientes
    findAllContactos(query, client) {
        if (client) {
            query.filter = query.filter || {};
            query.filter['clienteId'] = `$raw:((\`core_contacto\`.cliente_id = ${client}))`;
        }
        return this.CoreContacto.table(query).$promise;
    }
    optionsContactos() {
        return this.CoreContacto.options().$promise;
    }
    // tabela interacoes
    findAllInteracoes(query) {
        return this.CrmInteracao.table(query).$promise;
    }
    optionsInteracoes() {
        return this.CrmInteracao.options().$promise;
    }
    // tabela empresas associadas
    findAllEmpresaAssociada(query) {
        return this.CrmEmpresaAssociada.table(query).$promise;
    }
    optionsEmpresaAssociada() {
        return this.CrmEmpresaAssociada.options().$promise;
    }
    // tabela documentos associados
    findAllDocumentosAssociados(query) {
        return this.CrmDocumentoAssociado.table(query).$promise;
    }
    optionsDocumentosAssociados() {
        return this.CrmDocumentoAssociado.options().$promise;
    }
    // tabela pessoas associados
    findAllPessoasAssociada(query) {
        return this.CrmPessoaAssociada.table(query).$promise;
    }
    optionsPessoasAssociada() {
        return this.CrmPessoaAssociada.options().$promise;
    }
}
CrmService.$inject = ['$rootScope', '$q', 'AuthenticationService', 'CoreCliente', 'CoreContacto', 'CrmInteracao', 'Funcionario', 'CrmEmpresaAssociada', 'CrmDocumentoAssociado', 'CrmPessoaAssociada'];
