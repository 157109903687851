import angular from 'angular';

import {routes} from "./listasverificacao.routes";
import DirectoryListasVerificacaoController from "./directory/directory.controller";
import DetalhesListasVerificacaoController from "./details/details.controller";
import DetalhesTopicosVerificacaoController from "./details/topicosverificacao/details/details.controller";

export default angular.module('app.elv.listasverificacao', [])
  .config(routes)
  .controller('DirectoryListasVerificacaoController', DirectoryListasVerificacaoController)
  .controller('DetalhesListasVerificacaoController', DetalhesListasVerificacaoController)
  .controller('DetalhesTopicosVerificacaoController', DetalhesTopicosVerificacaoController)
  .name;
