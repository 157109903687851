import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete, Stack } from '@mui/material';
export function DialogAdicionarDefeito({ initial, defeitos, selected, note, indexPosition, onClose, ...props }) {
    const [selectedDefeito, setSelectedDefeito] = useState();
    const [nota, setNota] = useState('');
    const handleOk = () => {
        if (selected == undefined) { /// Adicionar Defeito
            if (nota !== '' && nota !== undefined) {
                onClose(selectedDefeito, nota);
            }
            else {
                onClose(selectedDefeito);
            }
        }
        else { /// Editar Defeito
            onClose(selectedDefeito, nota, indexPosition);
        }
    };
    const handleAutoComplete = (_, newValue) => {
        setSelectedDefeito(newValue);
    };
    const handleNota = (newValue) => {
        setNota(newValue);
    };
    return (<Dialog {...props} maxWidth={'lg'} fullWidth sx={{ minHeight: '80%' }}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Autocomplete disablePortal id='combo-box-demo' options={defeitos} getOptionLabel={(option) => `${option.titulo} - ${option.subtitulo}`} defaultValue={selected} onChange={handleAutoComplete} fullWidth renderInput={params => <TextField {...params} label='Defeito'/>}/>

          <TextField id='standard-multiline-static' label='Nota' onChange={event => handleNota(event.target.value)} multiline defaultValue={note} rows={4} variant='standard' fullWidth/>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button type='button' onClick={() => onClose(undefined)}>
          Cancelar
        </Button>
        <Button variant='contained' onClick={handleOk}>
          OK
        </Button>
      </DialogActions>
    </Dialog>);
}
