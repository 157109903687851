import angular from 'angular';

import {routes} from "./ordensintervencao.routes";
import DirectoryIntervencoesController from "./directory/directory.controller";
import DetalhesIntervencoesController from "./details/details.controller";
import CreateIntervencaoController from "./new/new.controller";
import ProcessCreationController from "./newOI/processo.dialog.controller";

export default angular.module('app.elv.oi', [])
  .config(routes)
  .controller('DirectoryIntervencoesController', DirectoryIntervencoesController)
  .controller('DetalhesIntervencoesController', DetalhesIntervencoesController)
  .controller('CreateIntervencaoController', CreateIntervencaoController)
  .controller('ProcessCreationController', ProcessCreationController)
  .name;
