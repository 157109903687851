export default class DetalhesIntervencoesController {
  constructor($state, $q, $timeout, UIService, $filter, ElvElevador, ElvOrdemintervencao, ElvProcesso, ElvEquipamentoTipo, ElvEmie, ElvCondominio, ElvCliente, PRIClientes, $http, ElvProcessoService) {
    this.id = $state.params.id;
    this.$q = $q;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$filter = $filter;
    this.$http = $http;
    this.UI = UIService;
    this.state = $state;
    this.tipo = null;
    this.condominio = null;
    this.emie = null;
    this.ElvOrdemintervencao = ElvOrdemintervencao;
    this.ElvProcesso = ElvProcesso;
    this.ElvEquipamentoTipo = ElvEquipamentoTipo;
    this.ElvElevador = ElvElevador;
    this.ElvEmie = ElvEmie;
    this.ElvCondominio = ElvCondominio;
    this.ElvCliente = ElvCliente;
    this.PRIClientes = PRIClientes;
    this.ElvProcessoService = ElvProcessoService;
    this.loaded = false;
    this.allSelected = false;
    this.nSelected = 0;
    if (!this.id) {
      $state.go("app.elv.oi.list");}
    else {
      this.loadData();
    }
  }

  loadData = () => {
    this.loaded = false;
    this.ElvOrdemintervencao.findOne({
      filter: {
        where: {
          id: this.id,
          active: true
        },
        include: [
          {
            relation: 'cliente',
            scope: {
              where: {
                active: true
              }
            }
          },
          {
            relation: 'origem',
            scope: {
              include: {
                relation: 'linhas',
                scope: {
                  order: 'numlinha ASC'
                }
              }
            }
          },
          {
            relation: 'pedidos',
            scope: {
              where: {
                active: true
              },
              include:
                [{
                  relation: 'estado',
                  scope: {
                    where: {
                      active: true
                    }
                  }
                },
                  {
                    relation: 'artigo',
                    scope: {
                      where: {
                        active: true
                      }
                    }
                  },
                  {
                    relation: 'emie',
                    scope: {
                      where: {
                        active: true
                      }
                    }
                  },
                  {
                    relation: 'condominio',
                    scope: {
                      where: {
                        active: true
                      }
                    }
                  },
                  {
                    relation: 'elevadorPed',
                    scope: {
                      where: {
                        active: true
                      }
                    }
                  }
                ]
            }
          }
        ]
      }
    }).$promise.then(res => {
      this.data = res;
      this.loaded = true;
    }).catch(() => {
      this.UI.addToast('Erro ao carregar ordem de intervenção');
    });
  };

  autoFillProcessFromnumeroCamarario = () => {
    if (this.data.elevadorPed.numeroCamarario) {
      this.ElvElevador.find({
        filter: {
          where: {
            numeroCamarario: this.data.elevadorPed.numeroCamarario,
            active: true
          },
          include: {
            relation: 'tipo',
            scope: {
              where: {
                active: 1
              }
            }
          }
        }
      }).$promise.then(res => {
        if (res && res.length > 0) { // Found something
          this.data.elevadorPed.morada = res[0].morada;
          this.data.elevadorPed.cp4 = res[0].cp4;
          this.data.elevadorPed.cp3 = res[0].cp3;
          this.data.elevadorPed.localidade = res[0].localidade;
          this.data.elevadorPed.observacoes = res[0].observacoes;
        } else { // Found Nothing. Empty prefill
          this.data.elevadorPed.morada = null;
          this.data.elevadorPed.cp4 = null;
          this.data.elevadorPed.cp3 = null;
          this.data.elevadorPed.localidade = null;
          this.data.elevadorPed.observacoes = null;
        }
      }).catch((err) => { // Error occured. Empty prefill
        console.log(err);
        this.data.elevadorPed.morada = null;
        this.data.elevadorPed.cp4 = null;
        this.data.elevadorPed.cp3 = null;
        this.data.elevadorPed.localidade = null;
        this.data.elevadorPed.observacoes = null;
      });
    } else {
      this.data.elevadorPed.morada = null;
      this.data.elevadorPed.cp4 = null;
      this.data.elevadorPed.cp3 = null;
      this.data.elevadorPed.localidade = null;
      this.data.elevadorPed.observacoes = null;
    }
  };

  editDetails = () => {
    this.UI.showDialog({
      size: 'lg',
      template: require('./edit-oi.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.observacoes = angular.copy(this.data.observacoes);

        $scope.ok = () => {
          $scope.$close($scope.observacoes);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        }
      }]
    }).then(res => {
      this.data.observacoes = res;
      this.data.$save().then(() => {
        this.loadData();
        this.UI.addToast('Ordem de Intervenção editada com sucesso!');
      }).catch(() => {
        this.UI.addToast('Erro ao editar Ordem de Intervenção!');
      });
    }).catch(() => {
    });
  };

  resyncClient = (id) => {
    this.ElvCliente.findOne({
      filter: {
        where: {
          id: id
        }
      }
    }).$promise.then((c) => {
      if (!c.refClientePrimavera) {
        this.UI.addToast("Não foi possível atualizar cliente.");
      } else {
        this.PRIClientes.find({
          filter: {
            where: {
              cliente: c.refClientePrimavera
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            c.nome = res[0].nome;
            c.nif = res[0].numcontrib;
            c.cp4 = res[0].facCp.split("-")[0];
            c.cp3 = res[0].facCp.split("-")[1];
            c.localidade = res[0].facCploc;
            c.telemovel = res[0].facTel;
            c.morada = res[0].facMor;
            c.observacoes = res[0].notas;
            c.atualizadoa = res[0].dataultimaactualizacao;
            c.refClientePrimavera = res[0].cliente;
            c.email = res[0].cduEmail;
            c.$save().then((updatedCliente) => {
              this.loadData();
              this.UI.addToast('Sincronização realizada com sucesso.');
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Falha na gravação do cliente. Por favor tente mais tarde.");
            });
          } else {
            this.UI.addToast("Não foi possível atualizar cliente.");
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar cliente.");
        });
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar cliente.");
    });
  };

  deleteProcess = r => {
    let result = this.UI.showConfirm('Deseja remover o processo?');
    result.then(res => {
      if (res) {
        r.active = false;
        this.ElvProcesso.upsert(r).$promise.then(() => {
          this.UI.addToast('Processo removido');
          this.loadData();
        }).catch(() => {
          this.UI.addToast('Erro ao remover processo');
        });
      }
    })
  };

  addProcess = () => {
    let wait = this.UI.showWaiting();
    this.ElvEquipamentoTipo.find({
      filter: {
        where: {
          active: true
        }
      }
    }).$promise.then(tipos => {
      this.ElvCondominio.find({
        filter: {
          where: {
            active: true
          }
        }
      }).$promise.then(condominios => {
        this.ElvEmie.find({
          filter: {
            where: {
              active: true
            }
          }
        }).$promise.then(emies => {
          let options = {
            size: 'lg',
            template: require('./add-processo.dialog.html'),
            controllerAs: 'm',
            controller: ['$scope', '$dialog', ($scope, $dialog) => {
              $scope.processo = {};
              $scope.newFileName = '';

              $scope.tipos = tipos;
              $scope.tipo = {selected: null};
              $scope.condominios = condominios;
              $scope.condominio = {selected: null};
              $scope.emies = emies;
              $scope.emie = {selected: null};

              $scope.ok = () => {
                $dialog.close($scope);
              };

              $scope.cancel = () => {
                $dialog.dismiss('cancel');
              };
            }]
          };

          let dialogCreate = this.UI.showDialog(options);

          dialogCreate.then((ok) => {
            this.ElvElevador.find({
              filter: {
                where: {
                  numeroCamarario: ok.m.data.elevadorPed.numeroCamarario,
                  active: true
                }
              }
            }).$promise.then(res => {
              let p = {
                id: 0,
                numeroCamarario: ok.m.data.elevadorPed.numeroCamarario,
                equipamentoId: ok.equipamento.selected.id,
                morada: ok.m.data.elevadorPed.morada,
                cp4: ok.m.data.elevadorPed.cp4,
                cp3: ok.m.data.elevadorPed.cp3,
                codigoPostal: `${ok.m.data.elevadorPed.cp4}-${ok.m.data.elevadorPed.cp3}`,
                localidade: ok.m.data.elevadorPed.localidade,
                observacoes: ok.m.data.observacoes,
                active: 1
              };
              if (res && res.length === 0) { // No EMIE found, we need to create an entry for it
                p.id = 0; // We are creating a new one, use id = 0 to force creation
              } else { // EMIE already exists, we should update the information on it
                p.id = res[0].id; // Use existing id so we only update information
              }
              this.ElvElevador.upsert(p).$promise.then((res) => {
                let t = {
                  id: 0,
                  dataProcesso: moment(),
                  clienteId: this.data.cliente.id,
                  emieId: ok.emie.selected.id,
                  condominioId: ok.condominio.selected.id,
                  elevadorId: res.id,
                  numeroProcesso: res.numeroCamarario,
                  oiId: this.id,
                  estadoId: 1,
                  inspetorId: null,
                  criadoporId: null,
                  observacoes: ok.m.data.observacoes,
                  faturaPedida: 0,
                  active: 1
                };
                this.ElvProcesso.create(t).$promise.then((emie) => {
                  this.UI.addToast('Processo adicionado com sucesso!');
                  this.loadData();
                });
              });
            }).catch(err => {
              console.log(err);
              this.UI.addToast('Não foi possível adicionar o processo!');
            });
          }).catch(() => {});
        }).catch(error => {
          console.log(error);
          this.UI.addToast("Erro na obtenção de dados de EMIEs. Verifique a ligação");
        });
      }).catch(error => {
        console.log(error);
        this.UI.addToast("Erro na obtenção de dados de Condomínio. Verifique a ligação");
      });
    }).catch(error => {
      console.log(error);
      this.UI.addToast("Erro na obtenção de dados de Tipos de Equipamento. Verifique a ligação");
    });
  };

  canRequestFaturas = () => {
    if (!this.data || !this.data.pedidos) {
      return false;
    } else {
      return this.data.pedidos.some(x => x.faturaPedida === 0);
    }
  };

  selectAll = () => {
    if (!this.allSelected) { // Deselect everything
      this.data.pedidos.forEach(p => {
        if (p.faturaPedida === 0) // Only care about processos faturaveis
          p.selected = false;
      });
      this.nSelected = 0;
    } else { // Select everything
      this.data.pedidos.forEach(p => {
        if (p.faturaPedida === 0) // Only care about processos faturaveis
          p.selected = true;
      });
      this.nSelected = this.data.pedidos.filter((a) => a.selected).length;
    }
  };

  selectItem = () => {
    this.nSelected = _.filter(this.data.pedidos, (r) => r.selected).length;
    this.allSelected = this.nSelected >= this.data.pedidos.length;
  };

  pedidoFaturaSelected = () => {
    let faturadoCheckIds = this.data.pedidos.filter(x => x.selected && (x.faturaPedida || x.faturaPedida === -1)); // If fatura pedida or can't do pedido fatura

    if (faturadoCheckIds.length > 0) {
      let msg = `Não é possível fazer o pedido de emissão de fatura para os processos:\n${faturadoCheckIds.map(x => x.numProcesso).join(', ')}.\nAjuste a sua seleção e tente novamente.`;
      this.UI.showAlert(msg);
      return;
    }

    let ids = this.data.pedidos.filter((x) => x.selected).map((x) => ({id: x.id})); // () are necessary to create the right object

    this.ElvProcessoService.pedidoFaturaMultiple(ids).then((r) => {
      // We need to deselect everything that was selected
      this.data.pedidos.forEach(p => {
        if (p.selected) p.selected = false;
      });
      this.nSelected = 0;
      this.allSelected = false;
      this.loadData();
    }).catch((e) => {
      console.log(e);
      this.UI.showAlert("Ocorreu um erro na criação de pedido de fatura. Recarregue a página e tente novamente.");
    });
  };

  getFaturaPedidaOutput = (f) => {
    switch (f) {
      case -1 : return "N/A";
      case 0: return "Não";
      case 1: return "Sim";
      default: return "-";
    }
  };

  formatZipCode = (f) => {
    if (f.elevadorPed && f.elevadorPed.cp4 && f.elevadorPed.cp3)
      return `${f.elevadorPed.cp4}-${f.elevadorPed.cp3}`;
    return '-';
  }
}

DetalhesIntervencoesController.$inject = ['$state', '$q', '$timeout', 'UIService', '$filter', 'ElvElevador', 'ElvOrdemintervencao', 'ElvProcesso', 'ElvEquipamentoTipo', 'ElvEmie', 'ElvCondominio', 'ElvCliente', 'PRIClientes', '$http', 'ElvProcessoService'];
