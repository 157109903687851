export default class ElvProcessoService {
  constructor($q, UIService, AuthenticationService, ElvProcesso, ElvEstado, ElvAlteracaoEstado) {
    this.$q = $q;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.ElvProcesso = ElvProcesso;
    this.ElvEstado = ElvEstado;
    this.ElvAlteracaoEstado = ElvAlteracaoEstado;
  }

  // Change the state for processos with id in array ids = [{id: ..., estadoId: ...}, ...]
  changeStateMultiple = (ids, estadoOriginal) => {
    let defer = this.$q.defer();
    let wait = this.UI.showWaiting();
    // Open modal to pick state and obs
    this.ElvEstado.find({
      filter: {
        where: {
          and: [{active: true}, {id: {nin: [2, estadoOriginal]}}] // Can't change to Agendado nor the same state
        },
        order: 'ordem ASC'
      }
    }).$promise.then(estados => {
      wait.close();
      let options = {
        size: "lg",
        template: require("./directory/state.dialog.html"),
        controller: ["$scope", "$dialog", ($scope, $dialog) => {
          $scope.estados = estados;

          $scope.auxEstado = {
            selected: undefined,
            infiniteScroll: {numToAdd: 20, currentItems: 20}
          };

          // Infinite Scroll magic
          $scope.addMoreItems = (infiniteScroll) => {
            infiniteScroll.currentItems += infiniteScroll.numToAdd;
          };

          $scope.ok = () => {
            $dialog.close($scope);
          };

          $scope.cancel = () => {
            $dialog.dismiss("cancel");
          };
        }]
      };

      let modal = this.UI.showDialog(options);

      modal.then((ok) => {
        let processCountString = ids.length > 1 ? `destes ${ids.length} processos` : `deste processo`;
        let warning = `Tem a certeza que pretende alterar o estado ${processCountString} para ${ok.auxEstado.selected.designacao}?`;
        if (ok.auxEstado.selected.id === 1) // 'Em Agendamento'
          warning += ' Informação e documentos gerados de marcação e resultados (caso exista) irão ser eliminados.';
        let result = this.UI.showConfirm(warning);
        result.then(() => {
          // Recheck if all pedidos have the same state
          this.ElvProcesso.find({
            filter: {
              fields: {id: true, estadoId: true},
              where: {
                and: [{active: 1}, {id: {inq: ids.map(x => x.id)}}]
              }
            }
          }).$promise.then((procs) => {
            // Check if all procs exist and estados are the same
            if (procs.length === ids.length && !procs.some(x => x.estadoId !== ids[0].estadoId)) {
              let wait = this.UI.showWaiting();
              this.ElvAlteracaoEstado.to2({
                params: {
                  ids: ids.map(x => x.id),
                  state: ok.auxEstado.selected.id,
                  userId: this.Auth.getId(),
                  observacoes: ok.observacoes || null
                }
              }).$promise.then(res => {
                wait.close();
                let errors = res.status.filter(x => x.error);
                if (errors.length > 0) {
                  let title = "Erros de Alteração de estado";
                  let introText = "Ocorreram os seguintes erros na alteração de estado:";
                  let instance = this.UI.showDialog({
                    size: 'md',
                    template: require('../../interface/modals/show-list.html'),
                    controller: ['$scope', ($scope) => {
                      $scope.title = title;
                      $scope.introText = introText;
                      $scope.list = errors;
                      $scope.ok = function () {
                        $scope.$close();
                      };
                    }]
                  });
                  instance.finally(() => {defer.resolve();})
                } else {
                  if (ids.length === 1)
                    this.UI.addToast("Estado de processo alterado com sucesso");
                  else
                    this.UI.addToast("Estados de processos alterados com sucesso");
                  defer.resolve();
                }
              }).catch(error => {
                console.log(error);
                wait.close();
              });
            } else {
              let alert = this.UI.showAlert("Houve pelo menos uma alteração de estado nos processos selecionados. Por favor tente novamente.");
              alert.finally(() => {defer.reject();});
            }
          }).catch((error) => {
            console.log(error);
            let alert = this.UI.showAlert("Não foi possível consultar informação de processos. Verifique a ligação");
            alert.finally(() => {defer.reject();});
          });
        }).catch(() => {defer.reject();});
      }).catch(() => {defer.reject();});
    }).catch(e => {
      wait.close();
      console.log(e);
      let alert = this.UI.showAlert("Erro de carregamento de dados de Estados.");
      alert.finally(() => {defer.reject();});
    });
    return defer.promise;
  }
}
ElvProcessoService.$inject = ['$q', 'UIService', 'AuthenticationService', 'ElvProcesso', 'ElvEstado', 'ElvAlteracaoEstado'];
