export default class FormacaoEmpresasController{
  constructor($rootScope, $state, $filter,  AuthenticationService, NgTableParams, FormacaoEmpresa, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$filter = $filter;

    this.user = AuthenticationService.getUser();
    this.NgTableParams = NgTableParams;
    this.FormacaoEmpresa = FormacaoEmpresa;
    this.UI = UIService;
    this.empresas = [];
    this.empresasLoading = true;
    this.getEmpresas();
  }

  getEmpresas = () => {
    this.FormacaoEmpresa.find({
      filter: {
        where: { active: 1 }
      }
    }).$promise.then(res => {
      if(angular.isDefined(res) && res.length > 0) {
        this.empresas = res;

        let NgTableParams = this.NgTableParams;
        this.tableData = new NgTableParams({
          sorting: {
            id: 'asc'
          }
        }, {
          dataset: this.empresas
        });
        this.empresasLoading = false;

      } else {
        this.empresasLoading = false;
      }
    }).catch(err => {
      console.log(err);
      this.UI.addToast('Erro de leitura de empresas. Por favor tente mais tarde.');
      this.empresasLoading = false;
    });
  };

  addEmpresa = () => {
    let self = this;

    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('./add.dialog.html'),
      controller: ['$scope', function($scope) {
        $scope.empresa = {};
        $scope.empresa.id = 0;
        $scope.empresa.designacao = '';
        $scope.empresa.email = '';
        $scope.empresa.telefone = '';
        $scope.empresa.descricao = '';
        $scope.empresa.active = 1;

        $scope.ok = function () {
          // Send scope values for processing
          $scope.$close($scope);
        };

        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      this.empresasLoading = true;
      this.FormacaoEmpresa.create(ok.empresa).$promise.then(r => {
        this.getEmpresas();
        this.UI.addToast("Empresa inserida com sucesso.");
      }).catch(error => {
        console.log(error);
        this.empresasLoading = false;
        this.UI.addToast("Erro de criação. Por favor tente mais tarde.");
      });
    }, (err) => {
      // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };
}

FormacaoEmpresasController.$inject = ['$rootScope', '$state', '$filter', 'AuthenticationService', 'NgTableParams', 'FormacaoEmpresa', 'UIService'];
