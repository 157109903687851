export function routes($stateProvider) {
  $stateProvider
    .state('app.susene.clients', {
      url: '/clientes',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.susene.clients.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      controller: 'SusEneListClientsController',
      controllerAs: 'vm',
      template: require('./directory/directory.view.html'),
      role: 'suseneListarClientes',
      title: 'Clientes',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.susene.clients.details', {
      url: '/{id}',
      controller: 'SusEneDetailsClientsController',
      controllerAs: 'vm',
      template: require('./details/details.view.html'),
      role: 'suseneListarClientes',
      title: 'Detalhes de Cliente'
    })
}

routes.$inject = ['$stateProvider'];
