export function routes($stateProvider) {
    $stateProvider
        .state('app.administration.lists', {
        url: '/listas',
        abstract: true,
        template: '<ui-view></ui-view>'
    })
        .state('app.administration.lists.list', {
        url: '?{page:int}&{limit:int}&sortBy&filter',
        template: '<list-relatorio></list-relatorio>',
        title: 'Listagem de listas de relatório',
        params: {
            page: {
                value: 1,
                dynamic: true
            },
            limit: {
                value: 20,
                dynamic: true
            },
            sortBy: {
                array: true,
                dynamic: true
            },
            filter: {
                dynamic: true
            }
        }
    })
        .state('app.administration.lists.view', {
        url: '/{id:int}',
        template: '<details-relatorio></details-relatorio>',
        title: 'Detalhes de lista de relatório',
        params: {
            id: {
                dynamic: true
            }
        }
    })
        .state('app.administration.lists.view-parametro', {
        url: '/{lista:int}/parametro/{id: int}&editing={editable: bool}',
        template: '<details-relatorio-parametro></details-relatorio-parametro>',
        title: 'Detalhes de paramêtros de lista',
        params: {
            lista: {
                dynamic: true
            },
            id: {
                dynamic: true
            },
            editable: {
                dynamic: true,
                value: false
            }
        }
    })
        .state('app.administration.lists.view-ensaio', {
        url: '/{lista:int}/ensaio/{id: int}',
        template: '<details-relatorio-ensaio></details-relatorio-ensaio>',
        title: 'Detalhes de ensaios de lista',
        params: {
            lista: {
                dynamic: true
            },
            id: {
                dynamic: true
            },
            editable: {
                value: false
            }
        }
    })
        .state('app.administration.clausulas', {
        url: '/listas/clausulas',
        abstract: true,
        template: '<ui-view></ui-view>'
    })
        .state('app.administration.clausulas.list', {
        url: '?{page:int}&{limit:int}&sortBy&filter',
        template: '<list-clausulas></list-clausulas>',
        title: 'Listagem de cláusulas e defeitos',
        params: {
            page: {
                value: 1,
                dynamic: true
            },
            limit: {
                value: 20,
                dynamic: true
            },
            sortBy: {
                array: true,
                dynamic: true
            },
            filter: {
                dynamic: true
            }
        }
    })
        .state('app.administration.clausulas.view', {
        url: '/{id:int}',
        template: '<details-clausula></details-clausula>',
        title: 'Detalhes de lista de cláusulas',
        params: {
            id: {
                dynamic: true
            }
        }
    })
        .state('app.administration.relatorios', {
        url: '/listas/relatorios',
        abstract: true,
        template: '<ui-view></ui-view>'
    })
        .state('app.administration.relatorios.edit', {
        url: '/edit/{id:int}',
        template: '<edit-relatorio></edit-relatorio>',
        title: 'Edição de Relatório',
        params: {
            id: {
                dynamic: true
            }
        }
    });
}
routes.$inject = ['$stateProvider'];
