import {routes} from './clientes.routes';
import CrmClientesListController from "./directory/directory.controller";
import CrmClientesDetailsController from "./details/details.controller";
import CrmClienteCreateController from "./new/new.controller";

export default angular.module('app.crm.clientes', [])
  .config(routes)
  .controller('CrmClientesListController', CrmClientesListController)
  .controller('CrmClientesDetailsController', CrmClientesDetailsController)
  .controller('CrmClienteCreateController', CrmClienteCreateController)
  .name;
