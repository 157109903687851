import 'regenerator-runtime/runtime'
export default class SusEneInterventionService {
  constructor($q, SusEneOrdemintervencao, SusEneProcesso, UIService) {
    this.UI = UIService;
    this.SusEneOrdemIntervencao = SusEneOrdemintervencao;
    this.SusEneProcesso = SusEneProcesso;
    this.$q = $q;
  }

  async register(oi, processes) {
    let error = false;
    let defer = this.$q.defer();
    this.SusEneOrdemIntervencao.create(oi).$promise.then(async (res) => {
      for (let i = 0; i < processes.length; i++) {
        let p = processes[i];
        p.id = 0;
        p.oiId = res.id;
        p.active = true;
        p.estadoId = 2; // Em agendamento
        p.quantidade = p.quantidade || 1;
        p.subcontratacao = 0;
        try {
          await this.createProcess(p);
        } catch (error) {
          this.UI.addToast("Ocorreu um erro na criação do processo. Por favor tente mais tarde.");
          error = true;
          break;
        }
      }
      if (!error)
        defer.resolve();
      else
        defer.reject();
    }).catch(err => {
      console.log(err);
      defer.reject(err);
    });
    return defer.promise;
  };

  async createProcess(p) {
    let error = false;
    let prom = new Promise(async (resolve, reject) => {
      for (let j = 0; j < p.quantidade; j++) {
        try {
          await this.SusEneProcesso.create(p).$promise;
        } catch (error) {
          console.log(error);
          error = true;
        }
      }
      if (!error)
        resolve(p);
      else
        reject(p);
    });
    return prom;
  };
}

SusEneInterventionService.$inject = ['$q', 'SusEneOrdemintervencao', 'SusEneProcesso', 'UIService'];
