export function routes($stateProvider) {
  $stateProvider
    .state('app.lab.relatorios', {
      url: '/relatorios',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.lab.relatorios.details', {
      url: '/:id',
      template: require('./details/details.view.html'),
      controller: 'LmeDetailRelatoriosController',
      controllerAs: 'vm',
      role: ['lmeRelatoriosMenu'], // Aditional Role checking required in controller
      title: 'Avaliação de Relatório'
    })
    .state('app.lab.relatorios.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Avaliação de Relatórios',
      template: require('./directory/directory.view.html'),
      controller: 'LmeDirectoryRelatoriosController',
      controllerAs: 'vm',
      role: ['lmeRelatoriosMenu'], // Aditional Role checking required in controller
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    });
}

routes.$inject = ['$stateProvider'];
