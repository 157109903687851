export default class SusEneDetailProcessController {
  constructor($q, $filter, $state, $http, UIService, AuthenticationService, AuthorizationService, SusEneService, Group, CoreFaturacao, CoreDepartamento, SusEneProcesso, LmeCusto, SusEneCustoProcesso, SusEneProcessoAmostra, SusEneProcessoTarefa, SusEneEstadoProcesso, SusEneProcessoAlteracaoEstado, SusEneSuspensaoMotivos, SusEneProcessoDocumentos, SusEneSubcontratacao, SusEneSubentidade, SusEneCliente, SusEneProcessoRelatorio, SusEneProcessoRelatorioDraft, SusEneOrdemintervencao, SusEneTipoRelatorio, FileUploader) {
    this.$q = $q;
    this.$filter = $filter;
    this.$state = $state;
    this.$http = $http;
    this.UI = UIService;
    this.Auth = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.SusEneService = SusEneService;
    this.Group = Group;
    this.CoreFaturacao = CoreFaturacao;
    this.CoreDepartamento = CoreDepartamento;
    this.SusEneProcesso = SusEneProcesso;
    this.LmeCusto = LmeCusto;
    this.SusEneCustoProcesso = SusEneCustoProcesso;
    this.SusEneProcessoTarefa = SusEneProcessoTarefa;
    this.SusEneSubentidade = SusEneSubentidade;
    this.SusEneSubcontratacao = SusEneSubcontratacao;
    this.SusEneEstadoProcesso = SusEneEstadoProcesso;
    this.SusEneProcessoAmostra = SusEneProcessoAmostra;
    this.SusEneProcessoAlteracaoEstado = SusEneProcessoAlteracaoEstado;
    this.SusEneProcessoDocumentos = SusEneProcessoDocumentos;
    this.SusEneSuspensaoMotivos = SusEneSuspensaoMotivos;
    this.SusEneCliente = SusEneCliente;
    this.SusEneProcessoRelatorio = SusEneProcessoRelatorio;
    this.SusEneProcessoRelatorioDraft = SusEneProcessoRelatorioDraft;
    this.SusEneOrdemintervencao = SusEneOrdemintervencao;
    this.SusEneTipoRelatorio = SusEneTipoRelatorio;
    this.FileUploader = FileUploader;
    this.loaded = false;
    this.hasEtiquetas = false;
    this.hasImagensAmostras = false;
    this.id = $state.params.id;
    let ids = $state.params.id.split("-");
    this.ids = {
      empresa: this.SusEneService.getEmpresa(ids[0]),
      tipo: 1, // Processo
      ano: ids[2],
      proc: ids[3]
    };
    this.motivosSuspensao = null;
    this.loadMotivosSuspensao();
    this.loadData();
    this.limit = 3;

    // Dashboard constants
    this.chartColorDone = '#049a9b';
    this.chartColorPending = '#ffb300';
    this.chartColorTodo = '#db4437';
    this.chartLabelFaturadoTodo = 'Por Faturar';
    this.chartLabelRealizadoTodo = 'Por Realizar';

    this.chartOptions = {
      maintainAspectRatio: false,
      // legend: {display: true},
      tooltips: {enabled: true},
      cutoutPercentage: 75,
      circumference: 2 * Math.PI,
      rotation: -Math.PI / 2
    };

    this.resetChartData();
  }

  limitTo = () => {
    if (angular.isDefined(this.limit)) {
      this.limit = undefined;
    } else {
      this.limit = 3;
    }
  };

  // Copy URL to clipboard on click
  copyProjectFolder = () => {
    if (this.data && this.data.empresaId) {
      let url = this.SusEneService.getProjectFolderPath(this.data, this.data.empresaId);

      if (!navigator.clipboard) { // Alternative solution
        let tempInput = document.createElement('input');
        tempInput.type = 'text';
        tempInput.value = url;
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // For mobile devices
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        this.UI.addToast("Caminho copiado para Área de Transferência");
      } else { // Navigator API solution
        navigator.clipboard.writeText(url).then(() => {
          this.UI.addToast("Caminho copiado para Área de Transferência");
        }).catch(err => {
          this.UI.addToast("Erro na cópia para Área de Transferência");
        });
      }
    } else this.UI.addToast("Sem informação a copiar");
  };

  loadMotivosSuspensao = () => {
    this.SusEneSuspensaoMotivos.find({
      filter: {
        where: {
          active: 1
        }
      }
    }).$promise.then((res) => {
      this.motivosSuspensao = res;
    }).catch((error) => {
      console.log(error);
    });
  };

  // Retrieve list of amostras linked to process with id
  getAmostrasForProcesso = (id) => {
    return this.SusEneProcessoAmostra.find({
      filter: {
        where: {
          active: 1,
          processoId: id
        },
        include: {
          relation: 'Amostra',
          scope: {
            include: {
              relation: 'marca',
              scope: {
                where: {
                  active: 1
                }
              }
            }
          }
        }
      }
    }).$promise;
  };

  // add/edit amostra to processo
  addSample = () => {
    let self = this;
    let cenas = this.UI.showWaiting();
    // Load the existing amostras so we can have them pre-selected
    this.getAmostrasForProcesso(this.data.id).then((res) => {
      cenas.close();
      let selected = [];
      if (res.length > 0) {
        res.forEach(pa => {
          selected.push(pa.Amostra);
        });
      }
      // Show dialog
      let options = {
        size: 'lg',
        template: require('./sample2.dialog.html'),
        controller: ['$dialog', '$scope', 'SusEneAmostra', function ($dialog, $scope, SusEneAmostra) {

          $scope.selected = selected;
          $scope.selectedLoaded = true;

          // Total from the db for the query
          $scope.total = 0;
          // Current "page" to view
          $scope.current = 0;
          // Number of items to show at the same time. Return this value from db query
          $scope.perPage = 5;
          // While data from db is loading
          $scope.loaded = true;
          // Data loaded from the database
          $scope.available = [];

          $scope.filter = {};

          $scope.previous = () => {
            if ($scope.current > 0) {
              $scope.current--;
              $scope.onFilter(true);
            }
          };

          $scope.next = () => {
            if ($scope.current < $scope.totalPages() - 1) {
              $scope.current++;
              $scope.onFilter(true);
            }
          };

          $scope.onFilter = (pagination) => {
            $scope.loaded = false;
            // By default, only search for active amostras and amostras 'nas instalações'
            let searchObject = {active: 1, clienteId: [self.data.Ordemintervencao.clienteId]};
            if (angular.isDefined(self.data.fabricanteId)) {
              searchObject.clienteId.push(self.data.fabricanteId);
            }

            if ($scope.filter.id) searchObject.id = $scope.filter.id;
            if ($scope.filter.refCliente) searchObject.refCliente = $scope.filter.refCliente;
            if ($scope.filter.nSerie) searchObject.nSerie = $scope.filter.nSerie;
            if ($scope.filter.equipamento) searchObject.equipamento = $scope.filter.equipamento;
            if ($scope.filter.marca) searchObject.marca = $scope.filter.marca;
            if ($scope.filter.modelo) searchObject.modelo = $scope.filter.modelo;

            let itemsToSkip = 0;
            // If we have pagination, we have to skip appropriately
            if (pagination)
              itemsToSkip = $scope.current * $scope.perPage;

            SusEneAmostra.filteramostra({
              params: {
                exclude: JSON.stringify($scope.selected.map(m => m.id)),
                where: searchObject,
                perPage: $scope.perPage,
                itemsToSkip: itemsToSkip,
                order: 'id desc'
              }
            }).$promise.then(ams => {
              // Populate the new available with the new data
              $scope.available = [];
              ams.data.forEach(a => {
                $scope.available.push({
                  id: a.id,
                  nSerie: a.nSerie,
                  equipamento: a.equipamento,
                  refCliente: a.refCliente,
                  modelo: a.modelo,
                  marca: {
                    descricao: a.marca
                  },
                  cliente: {
                    nome: self.data.Ordemintervencao.cliente.nome
                  }
                });
              });
              $scope.total = ams.total;
              if (!pagination)
                $scope.current = 0;
              $scope.loaded = true;
            }).catch(e => {
              console.log(e);
              $scope.available = [];
              $scope.total = 0;
              $scope.current = 0;
              $scope.loaded = true;
              self.UI.addToast("Erro na procura de amostras. Por favor tente mais tarde.");
            });
          };

          // Total number of pages for the query
          $scope.totalPages = () => {
            return Math.ceil($scope.total / $scope.perPage);
          };

          $scope.showBadge = (amostra) => {
            let outputText = '';
            if (amostra && amostra.equipamento) {
              outputText += amostra.equipamento;
              if (amostra.marca && amostra.marca.descricao) {
                outputText += ' ' + amostra.marca.descricao;
              }
              if (amostra.modelo) {
                outputText += ' ' + amostra.modelo;
              }
            }
            return outputText;
          };

          $scope.select = (amostra) => {
            $scope.loaded = false;
            $scope.selectedLoaded = false;
            SusEneAmostra.findOne({
              filter: {
                where: {
                  active: 1,
                  id: amostra.id
                }
              }
            }).$promise.then((a) => {
              self.SusEneProcessoAmostra.find({
                filter: {
                  where: {
                    // Search either active or not active (reusing even if active : 0)
                    amostraId: a.id,
                    processoId: self.data.id
                  }
                }
              }).$promise.then((pa) => {
                if (pa && pa.length > 0) { // We found one, use the first (should be the only).
                  if (pa[0].active) { // Active link already exists, just update selected.
                    self.getAmostrasForProcesso(self.data.id).then(refreshedAmostras => {
                      $scope.selected = [];
                      if (refreshedAmostras.length > 0) {
                        refreshedAmostras.forEach(pa => {
                          $scope.selected.push(pa.Amostra);
                        });
                      }
                      $scope.selected = refreshedAmostras;
                      self.UI.addToast("Ligação já existente");
                      $scope.selectedLoaded = true;
                      $scope.loaded = true;
                    }).catch(err => {
                      console.log(err);
                      $scope.selected = [];
                      self.UI.addToast("Ligação já existente");
                      self.UI.addToast("Não foi possível atualizar lista de amostras associadas. Por favor recarregue a página");
                      $scope.selectedLoaded = true;
                      // Redo the search so it updates
                      $scope.onFilter(true);
                    });
                  } else { // Non active link existed, update it.
                    pa[0].active = 1;
                    pa[0].$save().then((newPa) => {
                      // Saved. Remove the choice from available
                      let removalIndex = $scope.available.indexOf(amostra);
                      $scope.available.splice(removalIndex, 1);
                      // Refresh list of selected amostras
                      self.getAmostrasForProcesso(self.data.id).then(refreshedAmostras => {
                        $scope.selected = [];
                        if (refreshedAmostras.length > 0) {
                          refreshedAmostras.forEach(pa => {
                            $scope.selected.push(pa.Amostra);
                          });
                        }
                        self.UI.addToast("Ligação realizada com sucesso");
                        $scope.selectedLoaded = true;
                        // Redo the search so it updates
                        $scope.onFilter(true);
                      }).catch(err => {
                        console.log(err);
                        $scope.selected = [];
                        self.UI.addToast("Não foi possível atualizar lista de amostras associadas. Por favor recarregue a página");
                        $scope.selectedLoaded = true;
                        $scope.loaded = true;
                      });
                    }, (error) => {
                      console.log(error);
                      self.UI.addToast("De momento não é possível adicionar amostra ao processo. Por favor tente mais tarde.");
                    });
                  }
                } else { // No link exists, we have to create it.
                  self.SusEneProcessoAmostra.create({
                    id: 0,
                    processoId: self.data.id,
                    amostraId: a.id,
                    active: 1
                  }).$promise.then((res) => {
                    self.getAmostrasForProcesso(self.data.id).then(refreshedAmostras => {
                      $scope.selected = [];
                      if (refreshedAmostras.length > 0) {
                        refreshedAmostras.forEach(pa => {
                          $scope.selected.push(pa.Amostra);
                        });
                      }
                      self.UI.addToast("Ligação realizada com sucesso");
                      $scope.selectedLoaded = true;
                      // Redo the search so it updates
                      $scope.onFilter(true);
                    }).catch(err => {
                      console.log(err);
                      $scope.selected = [];
                      self.UI.addToast("Não foi possível atualizar lista de amostras associadas. Por favor recarregue a página");
                      $scope.selectedLoaded = true;
                      $scope.onFilter(true);
                    });
                  }).catch((error) => {
                    console.log(error);
                    self.UI.addToast("Não foi possível associar a amostra ao processo. Por favor tente mais tarde");
                  });
                }
              }).catch((error) => {
                console.log(error);
                self.UI.addToast("Não foi possível encontrar a amostra. Por favor recarregue a página.");
              });
            }).catch(err => {
              console.log(err);
              self.UI.addToast("Não foi possível associar a amostra ao processo. Por favor tente mais tarde.");
            });
          };

          $scope.pop = (amostra) => {
            let confirm = self.UI.showConfirm("Tem a certeza que pretende remover a ligação da amostra a este processo?");
            confirm.then(() => {
              $scope.loaded = false;
              $scope.selectedLoaded = false;
              self.SusEneProcessoAmostra.find({
                filter: {
                  where: {
                    active: 1,
                    amostraId: amostra.id,
                    processoId: self.data.id
                  }
                }
              }).$promise.then((processoamostra) => {
                if (processoamostra && processoamostra.length > 0) { // We have the link to remove
                  // Remove it (active : 0)
                  let p = processoamostra[0];
                  p.active = 0;
                  p.$save().then((res) => {
                    // Reload selected
                    self.getAmostrasForProcesso(self.data.id).then((newProcessoamostras) => {
                      $scope.selected = [];
                      if (newProcessoamostras.length > 0) {
                        newProcessoamostras.forEach(pa => {
                          $scope.selected.push(pa.Amostra);
                        });
                      }
                      self.UI.addToast("Amostra retirada do processo com sucesso");
                      $scope.selectedLoaded = true;
                      $scope.onFilter(true);
                    }).catch(err => {
                      console.log(err);
                      self.UI.addToast("De momento não é possível ver amostras ligadas a este processo. Por favor tente mais tarde");
                      $scope.selected = [];
                      $scope.selectedLoaded = true;
                      $scope.onFilter(true);
                    });
                  }, (error) => {
                    console.log(error);
                    this.UI.addToast("De momento não é possível remover a amostra do processo. Por favor tente mais tarde.");
                    $scope.selectedLoaded = true;
                    $scope.loaded = true;
                  });
                } else { // No link exists (anymore?)
                  this.UI.addToast("Esta amostra já tinha sido removida deste processo.");
                  $scope.selectedLoaded = true;
                  $scope.loaded = true;
                }
              }).catch((error) => { // Error in fetching for links, don't do anything
                console.log(error);
                this.UI.addToast("De momento não é possível remover a amostra do processo. Por favor tente mais tarde.");
                $scope.selectedLoaded = true;
                $scope.loaded = true;
              });
            }).catch(() => {
              // Cancel
            });
          };

          // Call onFilter initially to populate something
          $scope.onFilter(false);

          $scope.ok = () => {
            $dialog.close($scope.selected);
          };

          $scope.cancel = () => {
            $dialog.dismiss('cancel');
          };
        }]
      };
      let modal = this.UI.showDialog(options);
      modal.then((results) => {
        if (results && results.length > 0 && this.data.estadoId === 1) {
          let confirm = this.UI.showConfirm('Deseja colocar já o Processo para agendamento?');
          confirm.then(res => {
            if (res) {
              this.loaded = false;
              this.SusEneProcesso.findOne({
                filter: {
                  where: {
                    id: this.data.id,
                    active: 1
                  },
                  include: {
                    relation: 'Estado',
                    scope: {
                      where: {
                        active: 1
                      }
                    }
                  }
                }
              }).$promise.then((p) => {
                if (p.estadoId === 1) {
                  // Change process state to Em Agendamento
                  // Create alteracaoestado for processo
                  let alteracaoestado = {
                    id: 0,
                    inicialId: 1,
                    finalId: 2,
                    dataAlteracao: new Date(),
                    processoId: this.data.id,
                    active: true,
                    funcionarioId: this.Auth.getId()
                  };

                  p.estadoId = 2;
                  p.Estado.id = 2;
                  p.$save().then((proc) => {
                    this.SusEneProcessoAlteracaoEstado.create(alteracaoestado).$promise.then((altEst) => {

                      this.UI.addToast("Alteração de estado realizada com sucesso.");
                      this.loadData();

                    }).catch((error) => {
                      console.log(error);
                      this.UI.addToast("Estado alterado, sem registo de alteração de estado.");
                      this.loadData();
                    });
                  }, (error) => {
                    console.log(error);
                    this.UI.addToast("De momento não é possível alterar o estado. Por favor tente mais tarde.");
                    this.loadData();
                  });
                } else {
                  this.UI.addToast("Este Processo já não está a Aguardar Amostra(s).");
                }
                this.loadData();
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("De momento não é possível alterar o estado. Por favor tente mais tarde.");
                this.loadData();
              });
            }
          }).catch(() => {
            this.loadData();
          });
        } else {
          // Do nothing really but update...
          this.loadData();
        }
      }).catch(err => {
        console.log(err);
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast('De momento não é possível adicionar amostras. Por favor tente mais tarde.');
    });
  };

  addTask = () => {
    // Get values that make sense for execution control (minDate, minPercentage)
    let minPercentage = 0;
    if (this.data && this.data.Historico && this.data.Historico.length > 0) {
      let minDate = this.data.Historico[0].dataAlteracao;
      this.SusEneProcessoTarefa.find({
        filter: {
          where: {
            processoId: this.data.id,
          },
          order: 'percentagemFinal DESC',
          limit: 1
        }
      }).$promise.then((res) => {
        if (res && res.length > 0) {
          minPercentage = res[0].percentagemFinal;
          minDate = res[0].dataFim;
        }

        let options = {
          size: 'lg',
          template: require('./task.dialog.html'),
          controller: ['$scope', '$dialog', function ($scope, $dialog) {
            $scope.taskType = "Controlo de Execução";

            $scope.minDate = minDate;
            $scope.minPercentage = minPercentage;

            $scope.data = {
              dataInicio: minDate,
              percentagemInicial: minPercentage
            };

            $scope.ok = () => {
              $dialog.close($scope.data);
            };

            $scope.cancel = () => {
              $dialog.dismiss('cancel');
            };
          }]
        };

        let modal = this.UI.showDialog(options);
        // Handle state change
        modal.then(res => {
          if (res) {
            this.loaded = false;
            // Add missing data to "data" to insert into table
            res.id = 0;
            res.funcionarioId = this.Auth.getId();
            res.processoId = this.data.id;
            res.active = 1;

            this.SusEneProcessoTarefa.create(res).$promise.then((result) => {
              this.loadData();
              this.UI.addToast("Tarefa registada com sucesso");
            }).catch((error) => {
              console.log(error);
              this.UI.addToast("Não foi possível criar tarefa. Por favor tente mais tarde.");
              this.loaded = true;
            });
          }
        });

      }).catch((error) => {
        console.log(error);
        this.UI.addToast("De momento não é possível editar o Controlo de Execução. Por favor tente mais tarde.");
      });
    } else {
      this.UI.addToast("De momento não é possível editar o Controlo de Execução. Por favor tente mais tarde.");
    }
  };

  editTask = () => {

  };

  removeTask = () => {

  };

  editCusto = (c) => {
    let cenas = this.UI.showWaiting();
    // Get Tecnicos
    this.SusEneService.getFuncionariosWithGroups(['Tecnico%SUSENE', 'Gestor%SUSENE']).then(tecnicosSUSENE => {
      // Get Custos
      this.LmeCusto.find({
        filter: {
          where: {
            active: 1
          }
        }
      }).$promise.then((custos) => {

        // Get Custos of OI
        this.SusEneOrdemintervencao.findOne({
          filter: {
            where: {
              id: this.data.Ordemintervencao.id
            },
            include: [{
              relation: 'origem',
              scope: {
                include: {
                  relation: 'linhas',
                  scope: {
                    order: 'numlinha ASC'
                  }
                }
              }
            }]
          }
        }).$promise.then((oi) => {

          if (oi && oi.origem && oi.origem.linhas) {
            for (let i = 0; i < oi.origem.linhas.length; i++) {
              let index = custos.findIndex(x => (x.codigo && x.codigo.length > 0 && x.codigo === oi.origem.linhas[i].artigo));
              if (index >= 0) {
                custos[index].designacao = oi.origem.linhas[i].descricao;
                custos[index].pvp = oi.origem.linhas[i].precoliquido;
              }
            }
          }
          cenas.close();

          let options = {
            size: 'lg',
            template: require('./custo.dialog.html'),
            controller: ['$scope', '$dialog', 'AuthorizationService', ($scope, $dialog, AuthorizationService) => {
              $scope.AuthorizationService = AuthorizationService;
              $scope.taskType = "Editar Custo";
              $scope.custos = custos;
              $scope.tecnicosSUSENE = tecnicosSUSENE;
              $scope.selectedOption = true;
              $scope.valorTotalUnitario = 0;
              $scope.valorTotalPVP = 0;

              $scope.c = {selected: c.Custo};

              $scope.auxTecnico = {
                selected: tecnicosSUSENE.find(x => x.id === c.tecnicoId),
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };

              // Infinite Scroll magic
              $scope.addMoreItems = (infiniteScroll) => {
                infiniteScroll.currentItems += infiniteScroll.numToAdd;
              };

              $scope.data = {
                quantidade: c.quantidade,
                dataCusto: c.dataCusto,
                custoUnitario: c.custoUnitario,
                pvp: c.pvp,
                designacao: c.designacao,
                custoId: c.custoId,
                tecnicoId: c.tecnicoId
              };

              // When choosing an option, reset all variables
              $scope.onSelect = (selected) => {
                $scope.selectedOption = true;

                $scope.data.custoId = selected.id;
                $scope.data.designacao = selected.designacao;
                $scope.data.custoUnitario = selected.custoUnitario;
                $scope.data.pvp = selected.pvp;
                $scope.data.unidade = selected.unidade;
                $scope.updateTotalUnitario();
                $scope.updateTotalPVP();
              };

              $scope.updateTotalUnitario = () => {
                if ($scope.data.custoUnitario >= 0 && $scope.data.quantidade >= 0)
                  $scope.valorTotalUnitario = Math.round($scope.data.custoUnitario * $scope.data.quantidade * 100) / 100;
                else
                  $scope.valorTotalUnitario = 0;
              };

              $scope.updateTotalPVP = () => {
                if ($scope.data.pvp >= 0 && $scope.data.quantidade >= 0)
                  $scope.valorTotalPVP = Math.round($scope.data.pvp * $scope.data.quantidade * 100) / 100;
                else
                  $scope.valorTotalPVP = 0;
              };

              // Call the functions to update the modal value
              $scope.updateTotalUnitario();
              $scope.updateTotalPVP();

              $scope.ok = () => {
                $scope.data.tecnicoId = $scope.auxTecnico.selected.id;
                $dialog.close($scope.data);
              };

              $scope.cancel = () => {
                $dialog.dismiss('cancel');
              };
            }]
          };

          let modal = this.UI.showDialog(options);
          // Handle state change
          modal.then(res => {
            if (res) {
              this.loaded = false;
              // Add missing data to "data" to insert into table
              res.id = c.id;
              res.funcionarioId = this.Auth.getId();
              // tecnicoId is defined in the modal
              res.processoId = this.data.id;
              res.active = 1;
              res.dataRegisto = new Date();

              this.SusEneCustoProcesso.upsert(res).$promise.then((result) => {
                this.loadData();
                this.UI.addToast("Custo editado com sucesso");
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("Não foi editar criar custo. Por favor tente mais tarde.");
                this.loaded = true;
              });
            }
          });
        }).catch((error) => {
          console.log(error);
          cenas.close();
          this.UI.addToast("Não foi possível verificar custos da OI no Primavera. Por favor tente mais tarde.");
        });
      }).catch((error) => {
        console.log(error);
        cenas.close();
        this.UI.addToast("Não foi possível obter custos. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      cenas.close();
      this.UI.addToast("Não foi possível carregar técnicos SUSENE. Por favor tente mais tarde.");
    });
  };

  addCusto = () => {
    let cenas = this.UI.showWaiting();
    // Get Tecnicos
    this.SusEneService.getFuncionariosWithGroups(['Tecnico%SUSENE', 'Gestor%SUSENE']).then(tecnicosSUSENE => {
      // Get Custos
      this.LmeCusto.find({
        filter: {
          where: {
            active: 1
          }
        }
      }).$promise.then((custos) => {
        // Get Custos of OI
        this.SusEneOrdemintervencao.findOne({
          filter: {
            where: {
              id: this.data.Ordemintervencao.id
            },
            include: [{
              relation: 'origem',
              scope: {
                include: {
                  relation: 'linhas',
                  scope: {
                    order: 'numlinha ASC'
                  }
                }
              }
            }]
          }
        }).$promise.then((oi) => {

          if (oi && oi.origem && oi.origem.linhas) {
            for (let i = 0; i < oi.origem.linhas.length; i++) {
              let index = custos.findIndex(x => (x.codigo && x.codigo.length > 0 && x.codigo === oi.origem.linhas[i].artigo));
              if (index >= 0) {
                custos[index].designacao = oi.origem.linhas[i].descricao;
                custos[index].pvp = oi.origem.linhas[i].precoliquido;
              }
            }
          }
          cenas.close();

          let options = {
            size: 'lg',
            template: require('./custo.dialog.html'),
            controller: ['$scope', '$dialog', 'AuthorizationService', ($scope, $dialog, AuthorizationService) => {
              $scope.AuthorizationService = AuthorizationService;
              $scope.taskType = "Adicionar Custo";
              $scope.custos = custos;
              $scope.tecnicosSUSENE = tecnicosSUSENE;
              $scope.selectedOption = false;
              $scope.valorTotalUnitario = 0;
              $scope.valorTotalPVP = 0;

              $scope.c = {selected: null};

              $scope.auxTecnico = {
                selected: tecnicosSUSENE.find(x => x.id === this.Auth.getId()), // By default the current user
                infiniteScroll: {numToAdd: 20, currentItems: 20}
              };

              // Infinite Scroll magic
              $scope.addMoreItems = (infiniteScroll) => {
                infiniteScroll.currentItems += infiniteScroll.numToAdd;
              };

              $scope.data = {
                dataCusto: null
              };

              // When choosing an option, reset all variables
              $scope.onSelect = (selected) => {
                $scope.selectedOption = true;

                // Keep original custo where it was derived from.
                $scope.data.custoId = selected.id;
                $scope.data.designacao = selected.designacao;
                $scope.data.custoUnitario = selected.custoUnitario;
                $scope.data.pvp = selected.pvp;
                $scope.data.unidade = selected.unidade;
                $scope.updateTotalUnitario();
                $scope.updateTotalPVP();
              };

              $scope.updateTotalUnitario = () => {
                if ($scope.data.custoUnitario >= 0 && $scope.data.quantidade >= 0)
                  $scope.valorTotalUnitario = Math.round($scope.data.custoUnitario * $scope.data.quantidade * 100) / 100;
                else
                  $scope.valorTotalUnitario = 0;
              };

              $scope.updateTotalPVP = () => {
                if ($scope.data.pvp >= 0 && $scope.data.quantidade >= 0)
                  $scope.valorTotalPVP = Math.round($scope.data.pvp * $scope.data.quantidade * 100) / 100;
                else
                  $scope.valorTotalPVP = 0;
              };

              $scope.ok = () => {
                $scope.data.tecnicoId = $scope.auxTecnico.selected.id; // Add value to data
                $dialog.close($scope.data);
              };

              $scope.cancel = () => {
                $dialog.dismiss('cancel');
              };
            }]
          };

          let modal = this.UI.showDialog(options);
          // Handle state change
          modal.then(res => {
            if (res) {
              this.loaded = false;
              // Add missing data to "data" to insert into table
              res.id = 0;
              res.funcionarioId = this.Auth.getId();
              // tecnicoId is defined in the modal
              res.processoId = this.data.id;
              res.active = 1;
              res.dataRegisto = new Date();

              this.SusEneCustoProcesso.create(res).$promise.then((result) => {
                this.loadData();
                this.UI.addToast("Custo adicionado com sucesso");
              }).catch((error) => {
                console.log(error);
                this.UI.addToast("Não foi possível criar custo. Por favor tente mais tarde.");
                this.loaded = true;
              });
            }
          });
        }).catch((error) => {
          console.log(error);
          cenas.close();
          this.UI.addToast("Não foi possível verificar custos da OI no Primavera. Por favor tente mais tarde.");
        });
      }).catch((error) => {
        console.log(error);
        cenas.close();
        this.UI.addToast("Não foi possível obter custos. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      cenas.close();
      this.UI.addToast("Não foi possível carregar técnicos SUSENE. Por favor tente mais tarde.");
    });
  };

  // Check if custo is faturavel, depending on empresa
  isCustoFaturavel = (c) => {
    if (!c) return false;
    return !!(c.Custo && c.Custo.faturavel);
  };

  pedirFaturaProcesso = () => {
    let self = this;
    let waiter = this.UI.showWaiting();
    let saldoDisponivel;
    // Find custos that haven't been faturados
    this.SusEneCustoProcesso.find({
      filter: {
        where: {
          and: [{active: 1}, {faturaPedida: 0}, {pvp: {gt: 0}}, {processoId: this.data.id}]
        },
        include: ['Funcionario', 'Custo']
      }
    }).$promise.then((custosAtivos) => {

      // Filter custos de processo faturaveis
      custosAtivos = custosAtivos.filter(c => this.isCustoFaturavel(c));

      custosAtivos.forEach(c => {
        c.valorTotalPVP = Math.round(c.quantidade * c.pvp * 100) / 100;
      });
      this.SusEneProcesso.saldoDisponivel({id: this.data.id}).$promise.then((res) => {
        saldoDisponivel = Math.round(res.valor * 100) / 100;
        waiter.close();

        let options = {
          size: 'lg',
          template: require('./faturacao.processo.dialog.html'),
          controller: ['$scope', '$dialog', function ($scope, $dialog) {
            $scope.saldoDisponivel = saldoDisponivel;

            let descricao, quantidade, valor;
            descricao = self.data.artigo;
            quantidade = 1;
            // Change for SUSENE
            // valor = (self.data.Tipo && self.data.Tipo.descricao === "Ensaio") ? null : (saldoDisponivel > 0 ? saldoDisponivel : 0);
            valor = null;

            $scope.data = {
              observacoes: null,
              descricao: descricao,
              quantidade: quantidade,
              valor: valor,
              custosAtivos: custosAtivos
            };
            // Default value for valorTotal
            if ($scope.data.valor != null)
              $scope.valorTotal = Math.round($scope.data.valor * $scope.data.quantidade * 100) / 100;
            else
              $scope.valorTotal = null;

            $scope.updateTotal = () => {
              if ($scope.data.valor != null && $scope.data.quantidade != null)
                $scope.valorTotal = Math.round($scope.data.valor * $scope.data.quantidade * 100) / 100;
            };

            $scope.ok = () => {
              $dialog.close($scope.data);
            };

            $scope.cancel = () => {
              $dialog.dismiss('cancel');
            };

            $scope.isDisabled = () => {
              return false;
            }
          }]
        };

        let modal = this.UI.showDialog(options);
        // Handle state change to Pedir Fatura and back to wherever it was before
        modal.then(res => {
          if (res) {
            this.loaded = false;
            // We need to verify if value is ok or extra
            this.SusEneProcesso.saldoDisponivel({id: this.data.id}).$promise.then((saldo) => {
              // Ask if value is defined and > allowed and we are in "Em emissão de fatura"
              if (saldo.valor < res.valor) {
                // Show modal with confirmation that you are sure
                this.UI.showConfirm("O valor introduzido implica sobrefaturação (por " + (res.valor - saldo.valor).toFixed(2) + "€). Tem a certeza que pretende continuar?").then(confirm => {
                  if (confirm) {
                    // Process Faturacao for Processo
                    this.processFaturacao(res, null).then(r => {
                      // Process Faturacao for Custos Associados
                      (async () => {
                        let custosFaturados = 0;
                        if (res.custosAtivos.length > 0) {
                          for (let i = 0; i < res.custosAtivos.length; i++) {
                            try {
                              await this.processFaturacao({
                                descricao: res.custosAtivos[i].designacao,
                                quantidade: res.custosAtivos[i].quantidade,
                                valor: res.custosAtivos[i].pvp,
                                observacoes: null
                              }, res.custosAtivos[i]);
                              custosFaturados++;
                            } catch (e) {
                              this.UI.addToast("Ocorreu um erro a faturar custos do processo");
                              console.log(e);
                            }
                          }
                        }
                        if (custosFaturados > 0) {
                          let msgClone = custosFaturados + " custo" + (custosFaturados > 1 ? "s" : "") + " de processo enviado" + (custosFaturados > 1 ? "s" : "") + " para faturação";
                          this.UI.addToast(msgClone);
                        }
                        if (res.valor > 0) {
                          this.UI.addToast('Pedido de fatura de processo efetuado com sucesso');
                        }
                        this.loadData();
                      })();
                    }).catch(error => {
                      console.log(error);
                      this.loadData();
                    });
                  }
                }).catch(() => {
                  // Reload just in case
                  this.loadData();
                });
              } else {
                this.processFaturacao(res, null).then(r => {
                  // Process Faturacao for Custos Associados
                  (async () => {
                    let custosFaturados = 0;
                    if (res.custosAtivos.length > 0) {
                      for (let i = 0; i < res.custosAtivos.length; i++) {
                        try {
                          await this.processFaturacao({
                            descricao: res.custosAtivos[i].designacao,
                            quantidade: res.custosAtivos[i].quantidade,
                            valor: res.custosAtivos[i].pvp,
                            observacoes: null
                          }, res.custosAtivos[i]);
                          custosFaturados++;
                        } catch (e) {
                          this.UI.addToast("Ocorreu um erro a faturar custos do processo");
                          console.log(e);
                        }
                      }
                    }
                    if (custosFaturados > 0) {
                      let msgClone = custosFaturados + " custo" + (custosFaturados > 1 ? "s" : "") + " de processo enviado" + (custosFaturados > 1 ? "s" : "") + " para faturação";
                      this.UI.addToast(msgClone);
                    }
                    if (res.valor > 0) {
                      this.UI.addToast('Pedido de fatura de processo efetuado com sucesso');
                    }
                    this.loadData();
                  })();
                }).catch(error => {
                  console.log(error);
                  this.loadData();
                });
              }
            }).catch(errSaldo => {
              console.log(errSaldo);
              this.UI.addToast('Erro ao alterar estado.');
            });
          }
        })
      }).catch((error) => {
        this.UI.addToast("De momento não é possível atualizar estado. Por favor tente mais tarde.");
        console.log(error);
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível pesquisar custos do processo. Por favor tente mais tarde");
    });
  };

  pedirFaturaCusto = (custo) => {
    let options = {
      size: 'lg',
      template: require('./faturacao.custo.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {

        let descricao, quantidade, valor;
        descricao = custo.designacao;
        quantidade = custo.quantidade;
        valor = custo.pvp;

        $scope.data = {
          observacoes: null,
          descricao: descricao,
          quantidade: quantidade,
          valor: valor
        };
        // Default value for valorTotal
        if ($scope.data.valor != null)
          $scope.valorTotal = Math.round($scope.data.valor * $scope.data.quantidade * 100) / 100;
        else
          $scope.valorTotal = null;

        $scope.ok = () => {
          $dialog.close($scope.data);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.isDisabled = () => {
          return false;
        }
      }]
    };

    let modal = this.UI.showDialog(options);
    // Handle state change to Pedir Fatura and back to wherever it was before
    modal.then(res => {
      if (res) {
        this.loaded = false;
        this.processFaturacao(res, custo).then(r => {
          this.UI.addToast('Pedido efetuado com sucesso');
          this.loadData();
        }).catch(error => {
          console.log(error);
          this.loadData();
        });
      }
    })
  };

  processFaturacao = (res, custo) => {
    return new Promise((resolve, reject) => {
      // Set dates for alteração de estado that work in historic sense (date1 < date2)
      let date1 = new Date();
      let date2 = new Date();
      date2.setSeconds(date2.getSeconds() + 1);
      if (res.valor > 0 ) {
        // Current State --> Pedido Fatura
        let alteracaoestado1 = {
          id: 0,
          inicialId: this.data.estadoId,
          finalId: 8, // Em emissão de fatura
          dataAlteracao: date1,
          observacoes: res.observacoes,
          active: true,
          processoId: this.data.id,
          funcionarioId: this.Auth.getId(),
          descricao: res.descricao,
          quantidade: res.quantidade,
          valor: Math.round(res.valor * res.quantidade * 100) / 100,
          isValorCusto: custo ? 1 : 0
        };
        // Pedido Fatura --> Current state
        let alteracaoestado2 = {
          id: 0,
          inicialId: 8, // Em emissão de fatura
          finalId: this.data.estadoId,
          dataAlteracao: date2,
          observacoes: null,
          active: true,
          processoId: this.data.id,
          funcionarioId: this.Auth.getId()
        };

        if (this.data.Ordemintervencao && this.data.Ordemintervencao.cliente && this.data.Ordemintervencao.cliente.refClientePrimavera) {
          // Create Faturação
          this.CoreFaturacao.create({
            id: 0,
            empresaId: this.data.empresaId || 1, // IEP if unspecified
            departamentoId: 7, // SUSENE
            descricao: res.descricao,
            quantidade: res.quantidade,
            precoUnitario: res.valor,
            observacoes: res.observacoes,
            dataPedidoFaturacao: new Date(),
            funcionarioId: this.Auth.getId(),
            clientePrimaveraId: this.data.Ordemintervencao.cliente.refClientePrimavera,
            faturado: 0,
            susEneProcessoId: this.data.id,
            susEneCustoProcessoId: custo ? custo.id : null,
            numeroProcesso: this.$filter('suseneFormat')(this.data),
            departamentoDesignacao: 'SUSENE',
            clienteNome: this.data.Ordemintervencao.cliente.nome,
            funcionarioName: this.Auth.getUser().name,
            oi: this.data.Ordemintervencao.no + '/' + this.data.Ordemintervencao.ano
          }).$promise.then((f) => {
            if (custo) {
              custo.faturaPedida = 1;
              // Save state of Custo so we don't do it again
              this.SusEneCustoProcesso.upsert(custo).$promise.then((cus) => {

              }).catch((error) => {
                console.log(error);
                this.UI.showAlert("Pedido de emissão de fatura efetuado.\nOcorreu um erro ao alterar o estado do custo\nPor favor ignore o estado deste custo para faturação.");
              });
            }
            this.SusEneProcessoAlteracaoEstado.create(alteracaoestado1).$promise.then((a1) => {
              this.SusEneProcessoAlteracaoEstado.create(alteracaoestado2).$promise.then((a2) => {

                resolve(f);

              }).catch((err) => {
                this.UI.showAlert("Pedido de emissão de fatura efetuado.\nOcorreu um erro ao alterar o estado do processo\nPor favor altere o estado manualmente para o correto.");
                reject(err);
              });
            }).catch((err) => {
              this.UI.addToast("Pedido de emissão de fatura efetuado. Ocorreu um erro ao alterar o estado do processo.");
              reject(err);
            });
          }).catch(err => {
            this.UI.showAlert("Erro no registo do pedido de emissão de fatura.\nPor favor tente mais tarde.");
            reject(err);
          });
        } else { // No cliente, we can't do fatura.
          this.UI.addToast("Não foi possível fazer pedido de emissão de fatura. Cliente inválido.");
          reject();
        }
      } else {
        this.UI.addToast("Pedido de Fatura com valor 0 ignorado");
        resolve();
      }
    });
  };

  editProcesso = () => {
    let options = {
      size: 'lg',
      template: require('./process.dialog.html'),
      controller: ['$scope', 'SusEneTipo', 'SusEneLaboratorio', 'SusEneLaboratorioSubarea', 'SusEneProcessoCaracteristica', ($scope, SusEneTipo, SusEneLaboratorio, SusEneLaboratorioSubarea, SusEneProcessoCaracteristica) => {

        $scope.auxLaboratorio = {};
        $scope.auxCaracteristica = {};
        $scope.auxSubarea = {};

        SusEneLaboratorio.find({
          filter: {
            where: {
              active: true,
              tipoId: this.data.tipoId
            }
          }
        }).$promise.then(labs => {
          $scope.labs = labs;
          $scope.auxLaboratorio = {
            selected: labs.find(l => l.id === this.data.laboratorioId)
          };
          // SusEneLaboratorioSubarea.find({filter: {where: {active: true}}}).$promise.then(subareas => {
          //   $scope.subareas = subareas;
          //   $scope.usableSubareas = subareas.filter(x => x.laboratorioId === $scope.auxLaboratorio.selected.id);
          //   $scope.auxSubarea = {
          //     selected: subareas.find(s => s.id === this.data.subareaId)
          //   };
          //   SusEneProcessoCaracteristica.find({filter: {where: {active: true}}}).$promise.then(cats => {
          //     $scope.caracteristicas = cats;
          //     $scope.auxCaracteristica = {
          //       selected: cats.find(c => c.id === this.data.caracteristicaId)
          //     };
          //   });
          // });
          $scope.loaded = true;
        });

        $scope.yesNo = [{
          id: 0,
          value: 'Não'
        }, {
          id: 1,
          value: 'Sim'
        }];

        $scope.data = {
          tipoId: this.data.tipoId,
          artigo: this.data.artigo,
          norma: this.data.norma,
          valor: this.data.valor,
          email: this.data.email,
          natureza: this.data.natureza
        };

        $scope.auxAcreditado = {
          selected: $scope.yesNo.find(f => {
            return f.id === this.data.acreditado
          })
        };

        $scope.auxSubcontratacao = {
          selected: $scope.yesNo.find(f => {
            return f.id === this.data.subcontratacao
          })
        };

        $scope.auxInSitu = {
          selected: $scope.yesNo.find(f => {
            return f.id === this.data.inSitu
          })
        };

        $scope.auxCertificado = {
          selected: $scope.yesNo.find(f => {
            return f.id === this.data.certificado
          })
        };

        $scope.onLabSelected = (item) => {
          $scope.auxSubarea = {selected: undefined};
          $scope.usableSubareas = $scope.subareas.filter(x => x.laboratorioId === item.id);
        };

        $scope.ok = () => {
          if ($scope.loaded) {

            // Check valid email (if exists)
            if ($scope.data.email != null) {
              $scope.data.email = $scope.data.email.toString().trim();
              if ($scope.data.email.length === 0)
                $scope.data.email = null;
            }

            if ($scope.data.email !== null && !this.validEmail($scope.data.email)) {
              this.UI.addToast("Email de Processo inválido. Por favor corrija-o ou apague-o.");
              return;
            }

            $scope.data.laboratorioId = $scope.auxLaboratorio.selected.id;
            if ($scope.auxCertificado && $scope.auxCertificado.selected)
              $scope.data.certificado = $scope.auxCertificado.selected.id;
            if ($scope.auxInSitu && $scope.auxInSitu.selected)
              $scope.data.inSitu = $scope.auxInSitu.selected.id;
            $scope.data.subcontratacao = $scope.auxSubcontratacao.selected.id;
            $scope.data.acreditado = $scope.auxAcreditado.selected.id;
            $scope.data.subareaId = $scope.auxSubarea.selected ? $scope.auxSubarea.selected.id : null;
            $scope.data.caracteristicaId = $scope.auxCaracteristica.selected ? $scope.auxCaracteristica.selected.id : null;

            $scope.$close($scope.data);
          } else {
            $scope.cancel();
          }
        };

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    };
    this.UI.showDialog(options).then(res => {
      if (res) {
        angular.extend(this.data, res);
        if (this.data.Laboratorio)
          this.data.Laboratorio.id = this.data.laboratorioId;
        if (this.data.Subarea)
          this.data.Subarea.id = this.data.subareaId;
        if (this.data.Caracteristica)
          this.data.Caracteristica.id = this.data.caracteristicaId;
        this.data.$save().then(() => {
          this.loadData();
          this.UI.addToast("Processo editado com sucesso");
        }).catch(() => {
          this.loadData();
          this.UI.addToast("Ocorreu um erro ao editar processo");
        })
      }
    });
  };

  editProcessoPai = () => {
    let self = this;
    let options = {
      size: 'md',
      template: require('./pai.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {
        $scope.processo = {};
        $scope.tipo = 'SE';
        $scope.processo.iidAno = null;
        $scope.processo.iidProc = null;

        $scope.label = "Editar Processo Pai";

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => {
          $scope.$dismiss('cancel');
        };
      }]
    };
    let dialogEdit = this.UI.showDialog(options);

    dialogEdit.then((ok) => {
      this.SusEneProcesso.find({
        filter: {
          where: {
            empresaId: this.data.empresaId,
            iidAno: ok.processo.iidAno,
            iidProc: ok.processo.iidProc,
            tipoId: this.data.tipoId,
            active: 1
          }
        }
      }).$promise.then((ps) => {
        if (ps && ps.length > 0) {
          let res = ps[0];
          this.data.paiId = res.id;
          if (res.raizId !== null) {
            this.data.raizId = res.raizId;
            if (this.data.Raiz)
              this.data.Raiz.id = res.raizId;
          } else {
            this.data.raizId = res.id;
          }
          if (this.data.Pai)
            this.data.Pai.id = res.id;
          this.data.$save().then((r) => {
            this.UI.addToast('O processo pai foi guardado com sucesso!');
            this.loadData();
          }).catch(() => {
            this.UI.addToast('Ocorreu um erro ao guardar o processo pai.');
            this.loadData();
          })
        } else {
          this.UI.showAlert('Processo pai indicado não existe no SGI!\nPor favor verifique a referência.');
        }
      })
    })
  };

  removeProcessoPai = () => {
    let confirm = this.UI.showConfirm("Tem a certeza que pretende remover a associação de processo pai?");
    confirm.then((res) => {
      if (this.data.Raiz && this.data.Pai) {
        this.data.Raiz.id = null;
        this.data.raizId = null;
        this.data.Pai.id = null;
        this.data.paiId = null;
        this.data.$save().then((res) => {
          this.UI.addToast('Ligação removida com sucesso!');
          this.loadData();
        }, (error) => {
          console.log(error);
          this.UI.addToast("Não foi possível alterar o processo pai.");
        });
      } else {
        this.UI.addToast("Este processo já não tem processo pai.");
      }
    });
  };

  changeState = () => {
    let waiter = this.UI.showWaiting();
    let atribuidoa = angular.copy(this.data.Funcionario);

    this.SusEneService.getNewEstadosProcesso(this.data.tipoId, this.data.estadoId, this.data.empresaId).then(estados => {
      this.Group.findOne({
        filter: {
          where: {
            name: {
              like: 'Tecnico%SUSENE'
            }
          },
          include: {
            relation: 'usergroup',
            scope: {
              include: 'user'
            }
          }
        }
      }).$promise.then((g) => {

        let tecnicosSUSENE = [];

        g.usergroup.forEach(u => {
          tecnicosSUSENE.push(u.user);
        });

        tecnicosSUSENE = _.orderBy(tecnicosSUSENE, 'name', 'asc');

        let saldoDisponivel;
        this.SusEneProcesso.saldoDisponivel({id: this.data.id}).$promise.then((res) => {
          saldoDisponivel = res.valor;
          waiter.close();

          if (this.motivosSuspensao == null) {
            this.UI.addToast("De momento não é possível alterar estado. Por favor tente mais tarde.");
            return;
          }

          let options = {
            size: 'lg',
            template: require('./../directory/state.dialog.html'),
            controller: ['$scope', '$dialog', ($scope, $dialog) => {
              $scope.estados = estados;
              $scope.motivosSuspensao = this.motivosSuspensao;
              $scope.tecnicosSUSENE = tecnicosSUSENE;
              $scope.dataInicioOptions = {format: 'YYYY-MM-DD'};
              $scope.dataFimOptions = {format: 'YYYY-MM-DD'};
              $scope.proximaCalibracaoOptions = {format: 'YYYY-MM-DD', minDate: moment()};

              $scope.changeDataFimOptions = () => {
                if ($scope.data.dataInicio)
                  $scope.dataFimOptions.minDate = $scope.data.dataInicio;
              };

              $scope.changeDataInicioOptions = () => {
                if ($scope.data.dataFim)
                  $scope.dataInicioOptions.maxDate = $scope.data.dataFim;
              };

              $scope.saldoDisponivel = saldoDisponivel;
              $scope.data = {
                tipoId: this.data.tipoId,
                tecnicoSUSENE: {selected: atribuidoa || null},
                estado: {selected: null},
                observacoes: "",
                motivoSuspensao: null,
                dataInicio: this.getDataProcesso(0),
                dataFim: this.getDataProcesso(1),
                proximaCalibracao: null,
                quantidade: 1,
                // Changed for SUSENE
                // valor: (this.data.Tipo && this.data.Tipo.descricao === "Ensaio") ? null : (saldoDisponivel > 0 ? saldoDisponivel : 0)
                valor: null
              };
              // Default value for valorTotal
              if ($scope.data.valor != null)
                $scope.valorTotal = Math.round($scope.data.valor * $scope.data.quantidade * 100) / 100;
              else
                $scope.valorTotal = null;

              $scope.updateTotal = () => {
                if ($scope.data.valor != null && $scope.data.quantidade != null)
                  $scope.valorTotal = Math.round($scope.data.valor * $scope.data.quantidade * 100) / 100;
              };

              $scope.ok = () => {
                if (!moment($scope.data.proximaCalibracao).isValid())
                  $scope.data.proximaCalibracao = null;

                $dialog.close($scope.data);
              };

              $scope.cancel = () => {
                $dialog.dismiss('cancel');
              };

              $scope.isDisabled = () => {
                return angular.isUndefined($scope.data.estado.selected);
              }
            }]
          };

          let modal = this.UI.showDialog(options);
          // Handle state change
          modal.then(res => {
            if (res) {
              let alteracaoestado = {
                id: 0,
                inicialId: this.data.estadoId,
                finalId: res.estado.selected.id,
                dataAlteracao: new Date(),
                observacoes: res.observacoes,
                active: true,
                processoId: this.data.id,
                funcionarioId: this.Auth.getId()
              };

              // Only set variables if we are in the right state to add them in the first place
              switch (res.estado.selected.id) {
                case 3: // Estado Agendado
                  alteracaoestado.dataInicio = res.dataInicio ? res.dataInicio : null;
                  alteracaoestado.dataFim = res.dataFim ? res.dataFim : null;
                  alteracaoestado.observacoes = "Atribuído a " + res.tecnicoSUSENE.selected.name;
                  if (res.observacoes != null && res.observacoes.length > 0)
                    alteracaoestado.observacoes += (". Notas adicionais: " + res.observacoes);
                  break;
                case 4: // Estado Em Execução
                  alteracaoestado.dataInicio = res.dataInicio ? res.dataInicio : null;
                  alteracaoestado.dataFim = res.dataFim ? res.dataFim : null;
                  break;
                case 5: // Estado Suspensão
                  alteracaoestado.motivoId = res.motivoSuspensao ? res.motivoSuspensao.id : null;
                  break;
                case 8: // Estado Em emissão de fatura
                  alteracaoestado.quantidade = res.quantidade;
                  alteracaoestado.valor = res.valor;
                  break;
              }

              // If we are in "Em emissão de fatura", we need to verify if value is ok or extra
              if (res.estado.selected.id === 8) {
                this.SusEneProcesso.saldoDisponivel({id: this.data.id}).$promise.then((saldo) => {
                  // Ask if value is defined and > allowed and we are in "Em emissão de fatura"
                  if (saldo.valor < alteracaoestado.valor) {
                    // Show modal with confirmation that you are sure
                    this.UI.showConfirm("O valor introduzido implica sobrefaturação (por " + (alteracaoestado.valor - saldo.valor).toFixed(2) + "€). Tem a certeza que pretende continuar?").then(confirm => {
                      if (confirm) {
                        this.saveProcessoAlteracaoEstadoAndUpdate(alteracaoestado, res);
                      }
                    }).catch(() => {
                      // Reload just in case
                      this.loadData();
                    });
                  } else {
                    this.saveProcessoAlteracaoEstadoAndUpdate(alteracaoestado, res);
                  }
                }).catch(errSaldo => {
                  console.log(errSaldo);
                  this.UI.addToast('Erro ao alterar estado.');
                });
              } else { // We are in a state where we can just save the data
                this.saveProcessoAlteracaoEstadoAndUpdate(alteracaoestado, res);
              }
            }
          })
        }).catch((error) => {
          this.UI.addToast("De momento não é possível atualizar estado. Por favor tente mais tarde.");
          console.log(error);
        });
      }).catch(err => {
        console.log(err);
        this.UI.addToast("De momento não é possível alterar estado. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      this.UI.addToast("De momento não é possível obter estados. Por favor tente mais tarde.");
      console.log(error);
    });
  };

  saveProcessoAlteracaoEstadoAndUpdate = (alteracaoestado, res) => {
    this.loaded = false;
    // Save SusEneProcesso relevant data
    this.data.estadoId = res.estado.selected.id;

    // If we have the relation, change the relation value for the $save()
    if (this.data.Estado)
      this.data.Estado.id = res.estado.selected.id;

    if (res.estado.selected.id === 3) {
      this.data.funcionarioId = res.tecnicoSUSENE.selected.id;
      // If we have the relation, change the relation value for the $save()
      if (this.data.Funcionario)
        this.data.Funcionario.id = res.tecnicoSUSENE.selected.id;
    }

    // Metrologia specific - Add Proxima Calibracao
    // if (res.estado.selected.id === 6 && this.data.tipoId === 1) {
    //   this.data.proximaCalibracao = res.proximaCalibracao ? res.proximaCalibracao : null;
    // }

    this.data.$save().then(() => {
      this.SusEneProcessoAlteracaoEstado.create(alteracaoestado).$promise.then(() => {
        this.UI.addToast('Alteração de estado efetuada');
        this.loadData();
      }).catch((err) => {
        console.log(err);
        this.UI.addToast("Alteração efetuada. Falha no registo de alteração de estado");
        this.loadData();
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast('Erro ao alterar estado');
    })
  };

  viewPai = () => {
    this.$state.go('app.susene.processes.details', {
      id: this.$filter('suseneFormat')(this.data.Pai)
    });
  };

  viewRaiz = () => {
    this.$state.go('app.susene.processes.details', {
      id: this.$filter('suseneFormat')(this.data.Raiz)
    });
  };

  // Format bytes into correct units
  formatBytes = (bytes, decimals) => {
    if (bytes == null) return "N/D";
    if (bytes === 0) return '0 Bytes';
    let k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  setTimelineColor = (h) => {
    switch (h.final.descricao) {
      case "Aguarda amostra(s)":
      case "Em agendamento":
      case "Agendado":
      case "Aguarda emissão de relatório":
      case "Em emissão de fatura":
        return "project-standby";
      case "Em execução":
      case "Relatório Emitido":
      case "Concluído":
        return "project-info";
      case "Suspenso":
      case "Anulado":
        return "project-notification";
      default:
        return "project-notification";
    }
  };

  loadData = () => {
    this.loaded = false;
    this.SusEneProcesso.findOne({
      filter: {
        where: {
          empresaId: this.ids.empresa,
          tipoId: this.ids.tipo,
          iidAno: this.ids.ano,
          iidProc: this.ids.proc,
          active: true
        },
        include: ['Fabricante', 'EnviadoPor', 'Laboratorio', 'Tipo', {
          relation: 'Documentacao',
          scope: {
            where: {
              active: true
            },
            include: 'TipoDocumento'
          }
        }, {
          relation: 'Pai',
          scope: {
            where: {
              active: true
            }
          }
        },
          {
            relation: 'Raiz',
            scope: {
              where: {
                active: true
              }
            }
          },
          {
            relation: 'Estado',
            scope: {
              where: {
                active: true
              }
            }
          }, {
            relation: 'Tipo',
            scope: {
              where: {
                active: true
              }
            }
          }, {
            relation: 'Ordemintervencao',
            scope: {
              where: {
                active: true
              },
              include: {
                relation: 'cliente',
                scope: {
                  where: {
                    active: 1
                  }
                }
              }
            }
          }, {
            relation: 'ProcessoAmostras',
            scope: {
              where: {
                active: true,
              },
              include: {
                relation: 'Amostra',
                scope: {
                  include: ['marca', 'estado', 'local', {
                    relation: 'Documentacao',
                    scope: {
                      where: {
                        and: [{active: 1}] // Get every anexo and sort it out later
                      },
                      order: 'id DESC'
                    }
                  }]
                }
              }
            }
          }, {
            relation: 'Historico',
            scope: {
              where: {active: true},
              order: 'dataAlteracao desc',
              include: ['inicial', 'final', 'funcionario', 'atribuidoa', 'suspensao']
            }
          }, {
            relation: 'Caracteristica',
            scope: {
              where: {
                active: true
              }
            }
          }, {
            relation: 'Subcontratacao',
            scope: {
              where: {
                active: true
              },
              include: ['subentidade', 'funcionario']
            }
          }, {
            relation: 'Tarefas',
            scope: {
              where: {
                active: true
              },
              order: 'percentagemFinal desc',
              include: {
                relation: 'funcionario',
              }
            }
          }, 'Subarea', {
            relation: 'Custos',
            scope: {
              where: {
                active: true
              },
              include: [{
                relation: 'Funcionario',
              }, {
                relation: 'Tecnico',
              }, {
                relation: 'Custo',
                scope: {
                  where: {
                    active: 1
                  }
                }
              }]
            }
          }, {
            relation: 'Faturacao',
            scope: {
              where: {
                active: 1
              },
              order: 'dataPedidoFaturacao desc',
              include: [{
                relation: 'faturadopor',
                scope: {
                  where: {
                    active: 1
                  }
                }
              }, {
                relation: 'funcionario',
              }]
            }
          }, {
            relation: 'Relatorios',
            scope: {
              where: {
                active: 1
              },
              include: ['Funcionario', 'Tipo']
            }
          }, {
            relation: 'DraftsRelatorios',
            scope: {
              where: {
                active: 1
              },
              include: ['estado', 'criadoPor', 'avaliadoPor', 'tipo']
            }
          }, {
            relation: 'Empresa',
            scope: {
              where: {
                active: 1
              }
            }
          }, 'Funcionario']
      }
    }).$promise.then(res => {
      // Parse custos
      res.Custos.forEach(c => {
        if (c.quantidade >= 0 && c.custoUnitario >= 0)
          c.valorTotalUnitario = Math.round(c.quantidade * c.custoUnitario * 100) / 100;
        else
          c.valorTotalUnitario = 0;
        if (c.quantidade >= 0 && c.pvp >= 0)
          c.valorTotalPVP = Math.round(c.quantidade * c.pvp * 100) / 100;
        else
          c.valorTotalPVP = 0;
      });

      // Get observacoes for drafts
      this.observacoesDrafts = this.getObservacoesFromDrafts(res.DraftsRelatorios);

      // Remove extra drafts
      res.DraftsRelatorios = this.latestDraftVersions(res.DraftsRelatorios);

      this.data = res;
      this.docs = [];

      // Parse data inicio/fim - use a variable to simplify
      this.data.dataInicioProcesso = this.getDataProcesso(0);
      this.data.dataFimProcesso = this.getDataProcesso(1);

      res.Documentacao.forEach(doc => {
        this.$http.get('/api/Upload/susene/files/' + doc.nome).then((result) => {
          if (result.data != null && !result.data.hasOwnProperty('error')) {
            this.docs.push({
              info: doc,
              nome: result.data.name,
              TipoDocumento: doc.TipoDocumento,
              atime: result.data.atime,
              mtime: result.data.mtime,
              fileSize: result.data.size
            });
          }
        }, (err) => {
          console.log(err);
        });
      });

      // Calculate how much was faturado already and tarefas realizadas for chart data
      // Clean the arrays
      this.resetChartData();
      // Do the calculations and the fill the data for the charts
      this.calcValorTotal();
      this.calcCustosMargem();
      this.calcFaturado();
      this.calcRealizado();
      this.checkEtiqueta();
      this.checkImagensAmostras();
      this.getNotificationsForReports();

      this.loaded = true;
    }).catch((error) => {
      console.log(error);
      this.$state.go('app.susene.processes.list');
      this.UI.addToast('Erro ao carregar processo');
    });
  };

  // Get Core Notification information for reports sent to the client and append it to reports object
  getNotificationsForReports = () => {
    if (this.data && this.data.Relatorios && this.data.Relatorios.length > 0) {
      for (let i = 0; i < this.data.Relatorios.length; i++) {
        if (this.data.Relatorios[i].uuid) {
          this.SusEneProcessoRelatorio.getCoreNotifications({
            params: {
              processoId: this.data.id,
              relatorioId: this.data.Relatorios[i].id,
              uuid: this.data.Relatorios[i].uuid
            }
          }).$promise.then((res) => {
            this.data.Relatorios[i].notifications = res.notifications;
            this.data.Relatorios[i].notificationsLoaded = true;
          }).catch(error => {
            console.log(error);
            this.data.Relatorios[i].notifications = null; // To show there's an error getting them.
            this.data.Relatorios[i].notificationsLoaded = true;
          });
        }
      }
    }
  };

  showEmailNotifications = (report) => {
    // If necessary, add more documento types
    if (!report || !report.notifications || report.notifications.length === 0) {
      this.UI.addToast("Sem notificações de envio");
      return;
    }

    let options = {
      size: "lg",
      template: require("./email_notifications.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.label = "Emails enviados de Relatório " + report.refRelatorio;
        $scope.report = report;

        // Date is stored as real date, so just output it directly
        $scope.realDateOutput = (timestamp) => {
          let validTimestamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

          if (!validTimestamp.test(timestamp)) {
            return '-';
          }

          // Split the date part into year, month, and day, and time
          let [datePart, timePart] = timestamp.split('T');
          let [year, month, day] = datePart.split('-');
          // Return the formatted timestamp string
          return `${day}/${month}/${year} ${timePart.substring(0, 5)}`;
        };

        $scope.ok = () => {
          $dialog.close();
        };

      }]
    };

    let dialog = this.UI.showDialog(options);
    dialog.then((ok) => {
      // Do nothing
    }).catch(() => {});

  };

  // return variable with dates from Historico dataInicio (0) or dataFim (1)
  // Assuming Historico is sorted by dataAlteracao!!
  getDataProcesso = (type) => {
    if (this.data && this.data.Historico && this.data.Historico.length > 0) {
      for (let i = 0; i < this.data.Historico.length; i++) {
        if (this.data.Historico[i]) {
          if (type === 0 && this.data.Historico[i].dataInicio)
            return this.data.Historico[i].dataInicio;
          if (type === 1 && this.data.Historico[i].dataFim)
            return this.data.Historico[i].dataFim;
        }
      }
    }
    return null;
  };

  /* Chart stuff */
  resetChartData = () => {
    this.faturadoChartLabels = [];
    this.faturadoChartColors = [];
    this.faturadoChartData = [];

    this.realizadoChartLabels = [];
    this.realizadoChartColors = [];
    this.realizadoChartData = [];
  };

  addFaturadoSlice = (value, label, color) => {
    this.faturadoChartData.push(value);
    this.faturadoChartLabels.push(label);
    this.faturadoChartColors.push(color);
  };

  addRealizadoSlice = (value, label, color) => {
    this.realizadoChartData.push(value);
    this.realizadoChartLabels.push(label);
    this.realizadoChartColors.push(color);
  };

  // Find total value of processo : valor processo + custos associados
  calcValorTotal = () => {
    this.data.valorTotal = this.data.valor;
    if (this.data.Custos && this.data.Custos.length > 0) {
      for (let i = 0; i < this.data.Custos.length; i++) {
        this.data.valorTotal += this.data.Custos[i].valorTotalPVP;
      }
    }
  };

  // Calculate total of custos (internal costs)
  calcCustosMargem = () => {
    this.data.custosTotal = 0;
    if (this.data.Custos && this.data.Custos.length > 0) {
      for (let i = 0; i < this.data.Custos.length; i++) {
        this.data.custosTotal += this.data.Custos[i].valorTotalUnitario; // valorTotalUnitario is custo total
      }
    }
    if (this.data.valorTotal > 0) { // Only makes sense to calculate if value exists
      this.data.valorMargem = this.data.valorTotal - this.data.custosTotal;
      this.data.valorMargemPercentagem = Math.round( ((this.data.valorMargem / this.data.valorTotal) * 100) * 10) / 10; // Round value to 1 decimal piece
    }
  };

  calcFaturado = () => {
    this.data.valorFaturado = 0;
    this.data.valorPendente = 0;

    // Take account for older processos that do not use core_faturacao
    if (this.data.Historico && this.data.Historico.length > 0) {
      for (let i = 0; i < this.data.Historico.length; i++) {
        let colorUsed, faturadoText;
        // If we have a faturacao and date is before the core_faturacao version was put in production, add it as faturado
        if (this.data.Historico[i].finalId === 8 && moment(this.data.Historico[i].dataAlteracao).isBefore("2020-03-21T00:00:00.000Z")) {
          colorUsed = this.chartColorDone;
          faturadoText = "Faturado";
          this.data.valorFaturado += this.data.Historico[i].valor;

          this.addFaturadoSlice(this.data.Historico[i].valor, faturadoText + " - Qtd: " + this.data.Historico[i].quantidade + " Valor (€)", colorUsed);
        }
      }
    }
    if (this.data.Faturacao && this.data.Faturacao.length > 0) {
      for (let i = this.data.Faturacao.length - 1; i >= 0; i--) {
        if (this.data.Faturacao[i].precoUnitario && this.data.Faturacao[i].precoUnitario > 0) {
          let valor = Math.round(this.data.Faturacao[i].precoUnitario * this.data.Faturacao[i].quantidade * 100) / 100;
          let colorUsed, faturadoText;
          if (this.data.Faturacao[i].faturado) {
            colorUsed = this.chartColorDone;
            faturadoText = "Faturado";
            this.data.valorFaturado += valor;
          } else {
            colorUsed = this.chartColorPending;
            faturadoText = "Pendente";
            this.data.valorPendente += valor;
          }

          this.addFaturadoSlice(valor, faturadoText + " - Qtd: " + this.data.Faturacao[i].quantidade + " Valor (€)", colorUsed);
        }
      }
    }
    if (this.data.valorTotal - this.data.valorFaturado > 0)
      this.addFaturadoSlice(this.data.valorTotal - this.data.valorFaturado - this.data.valorPendente, this.chartLabelFaturadoTodo + "(€)", this.chartColorTodo);
  };

  calcRealizado = () => {
    this.data.tarefasRealizadas = 0;
    if (this.data.Tarefas && this.data.Tarefas.length > 0) {
      this.data.tarefasRealizadas = this.data.Tarefas[0].percentagemFinal;
      // We should traverse in reverse order (to get the progress of it)
      for (let i = this.data.Tarefas.length - 1; i >= 0; i--) {
        if (angular.isNumber(this.data.Tarefas[i].percentagemFinal) && angular.isNumber(this.data.Tarefas[i].percentagemInicial))
          this.addRealizadoSlice(this.data.Tarefas[i].percentagemFinal - this.data.Tarefas[i].percentagemInicial, this.data.Tarefas[i].descricao + "(%)", this.chartColorDone)
      }
    }
    this.addRealizadoSlice(100 - this.data.tarefasRealizadas, this.chartLabelRealizadoTodo + "(%)", this.chartColorTodo);
  };

  showFaturacaoDetail = (f) => {
    let options = {
      size: 'md',
      template: require('./faturacao.info.dialog.html'),
      controller: ['$scope', '$dialog', function ($scope, $dialog) {

        $scope.data = f;

        // Default value for valorTotal
        $scope.valorTotal = Math.round($scope.data.precoUnitario * $scope.data.quantidade * 100) / 100;

        $scope.ok = () => {
          $dialog.close($scope.data);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        };

        $scope.isDisabled = () => {
          return false;
        }
      }]
    };

    let modal = this.UI.showDialog(options);
    // Handle state change to Pedir Fatura and back to wherever it was before

  };


  /* Subcontratacao */
  setSubcontratacao = () => {
    let self = this;
    let subDados = undefined;
    let cenas = this.UI.showWaiting();
    if (angular.isDefined(this.data.Subcontratacao)) {
      subDados = angular.copy(this.data.Subcontratacao);
      delete subDados.funcionario;
    }
    let options = {
      template: require('./sub.dialog.html'),
      controller: ['$dialog', '$scope', 'SusEneSubentidade', function ($dialog, $scope, SusEneSubentidade) {

        if (angular.isDefined(subDados)) {
          $scope.data = {
            id: subDados.id,
            dataSaida: moment(subDados.dataSaida).utc(),
            dataDevolucao: moment(subDados.dataDevolucao).utc(),
            prazo: subDados.prazo
          }

        } else {
          $scope.data = {
            id: 0
          };
        }


        $scope.canSubmit = () => {
          return angular.isDefined($scope.data.dataSaida) && angular.isDefined($scope.data.subentidade)
        };

        SusEneSubentidade.find({filter: {where: {active: true}}}).$promise.then(res => {
          if (angular.isDefined(subDados)) {
            res.forEach(s => {
              if (s.id === subDados.subentidadeId)
                $scope.data.subentidade = s;
            });
          }
          $scope.lista = res;
          cenas.close();
        });

        $scope.ok = () => {
          $scope.data.processoId = self.data.id;
          $scope.data.funcionarioId = self.Auth.getId();
          $dialog.close($scope.data);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }]
    };

    let dialog = this.UI.showDialog(options);
    dialog.then(res => {
      if (res) {
        if (res.subentidade.id === 0) {
          // Insert
          res.subentidade.active = true;
          this.SusEneSubentidade.create(res.subentidade).$promise.then((s) => {
            res.subentidadeId = s.id;
            res.email = s.email;
            this.SusEneSubcontratacao.upsert(res).$promise.then(() => {
              this.loadData();
              this.UI.addToast('Dados atualizados');
            });
          });
        } else {
          res.active = true;
          res.subentidadeId = res.subentidade.id;
          res.email = res.subentidade.email;
          this.SusEneSubcontratacao.upsert(res).$promise.then(() => {
            this.loadData();
            this.UI.addToast('Dados atualizados');
          });
        }
      }
    })
  };

  addAnexo = () => {
    let options = {
      size: 'lg',
      controller: 'SUSENEProcessFileUploaderController',
      controllerAs: 'm',
      template: require('./files.dialog.html'),
      resolve: {
        pID: () => {
          return this.data.id;
        },
        file: () => {
          return undefined;
        }
      }
    };

    let dialog = this.UI.showDialog(options);
    dialog.then(res => {
      if (res) {
        this.loadData();
        this.UI.addToast('Ficheiro carregado com sucesso');
      }
    });
  };

  editAnexo = (f) => {
    let options = {
      size: 'lg',
      controller: 'SUSENEProcessFileUploaderController',
      controllerAs: 'm',
      template: require('./files.dialog.html'),
      resolve: {
        pID: () => {
          return this.data.id;
        },
        file: () => {
          return f.info;
        }
      }
    };

    let dialog = this.UI.showDialog(options);
    dialog.then(res => {
      if (res) {
        this.loadData();
        this.UI.addToast('Ficheiro carregado com sucesso');
      }
    });
  };

  validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  setManufacturer = () => {
    let selectedId = null;
    let cenas = this.UI.showWaiting();
    // Load the existing clientes so we can have pre-selected
    let empresaId = !this.data.empresaId ? 1 : this.data.empresaId;
    let PRIClientes = this.SusEneService.getPRIClientes(this.data);

    PRIClientes.find({
      filter: {order: 'nome ASC'}
    }).$promise.then(clientes => {
      selectedId = clientes.find(c => c.cliente === this.data.Ordemintervencao.cliente.refClientePrimavera);
      cenas.close();

      // Now that we have the data, show the dialog
      this.UI.showDialog({
        size: 'lg',
        template: require('./manufacturer.dialog.html'),
        controller: ['$scope', ($scope) => {
          $scope.clientes = clientes;
          $scope.auxCliente = {
            selected: selectedId
          };

          //Infinite Scroll Magic
          $scope.infiniteScroll = {};
          $scope.infiniteScroll.numToAdd = 20;
          $scope.infiniteScroll.currentItems = 20;

          $scope.addMoreItems = function () {
            $scope.infiniteScroll.currentItems += $scope.infiniteScroll.numToAdd;
          };

          $scope.cancel = () => $scope.$dismiss('cancel');

          $scope.ok = () => {
            $scope.$close($scope.auxCliente.selected);
          };
        }]
      }).then(selected => {
        if (selected) {
          // Check if Cliente exists in SGI. If not, create it, if it does, update it.
          this.SusEneCliente.find({
            filter: {
              where: {
                empresaId: empresaId,
                refClientePrimavera: selected.cliente,
                active: 1
              }
            }
          }).$promise.then((res) => {
            if (res.length > 1) { // Multiple Clientes (???) Should never happen.
              this.UI.addToast("Ocorreu um erro ao guardar o fabricante. Por favor tente mais tarde.");
            } else {
              if (res.length === 0) { // Client not found. Import it to SusEneCliente
                this.SusEneCliente.create({
                  id: 0,
                  empresaId: empresaId,
                  nome: selected.nome,
                  nif: selected.numcontrib,
                  cp4: selected.facCp.split("-")[0],
                  cp3: selected.facCp.split("-")[1],
                  localidade: selected.facCploc,
                  contactoTelefonico: selected.facTel,
                  morada: selected.facMor,
                  observacoes: selected.notas,
                  atualizadoa: selected.dataultimaactualizacao,
                  refClientePrimavera: selected.cliente,
                  email: this.validEmail(selected.cduEmail) ? selected.cduEmail : null,
                  active: 1
                }).$promise.then((clienteCreated) => {
                  this.data.fabricanteId = clienteCreated.id;
                  this.data.$save().then(() => {

                    this.loadData();
                    this.UI.addToast('Fabricante alterado com sucesso');

                  }).catch(err => {
                    console.log(err);
                    this.UI.addToast("Falha na gravação do fabricante. Por favor tente mais tarde.");
                  });
                }).catch((error) => {
                  console.log(error);
                  this.UI.addToast("Não foi possível importar o fabricante para o SGI. Por favor tente mais tarde.");
                });
              } else { // Cliente found. Update the SusEneCliente entry with the data.
                res[0].nome = selected.nome;
                res[0].nif = selected.numcontrib;
                res[0].cp4 = selected.facCp.split("-")[0];
                res[0].cp3 = selected.facCp.split("-")[1];
                res[0].localidade = selected.facCploc;
                res[0].contactoTelefonico = selected.facTel;
                res[0].morada = selected.facMor;
                res[0].observacoes = selected.notas;
                res[0].atualizadoa = selected.dataultimaactualizacao;
                res[0].refClientePrimavera = selected.cliente;
                res[0].email = this.validEmail(selected.cduEmail) ? selected.cduEmail : null;
                res[0].$save().then((updatedCliente) => {
                  this.data.fabricanteId = updatedCliente.id;
                  this.data.$save().then(() => {

                    this.loadData();
                    this.UI.addToast('Fabricante alterado com sucesso');

                  }).catch(err => {
                    console.log(err);
                    this.UI.addToast("Falha na gravação do fabricante. Por favor tente mais tarde.");
                  });
                }).catch(err => {
                  console.log(err);
                  this.UI.addToast("Falha na gravação do fabricante. Por favor tente mais tarde.");
                });
              }
            }
          }).catch((error) => {
            console.log(error);
            this.UI.addToast("Não foi possível guardar o fabricante. Por favor tente mais tarde.");
          });
        }
      });
    }).catch(err => {
      cenas.close();
      console.log(err);
      this.UI.addToast("Não foi possível carregar fabricantes. Por favor tente mais tarde.");
    });

  };

  removeAnexo = (file) => {
    let confirm = this.UI.showConfirm('Deseja remover o ficheiro?');
    confirm.then(res => {
      if (res) {
        file.info.active = 0;
        this.SusEneProcessoDocumentos.upsert(file.info).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Ficheiro removido');
        });
      }
    })
  };

  // Return true if there are observacoes for drafts
  hasObservacoesForDraft = (draft) => this.observacoesDrafts && this.observacoesDrafts.find(x => x.refRelatorio === draft.refRelatorio);

  // Create structure to hold past observacoes and who created them for drafts
  getObservacoesFromDrafts = (drafts) => {
    return drafts.reduce((acc, item) => {
      const { refRelatorio, versao, observacoesAvaliacao, avaliadoPor, avaliadoa } = item;

      if (observacoesAvaliacao !== null) {
        // Find the refRelatorio in the accumulator array
        let group = acc.find(g => g.refRelatorio === refRelatorio);
        if (!group) {
          // If not found, create a new group
          group = { refRelatorio, observacoesAvaliacao: [] };
          acc.push(group);
        }
        // Add the current observacoes to the group
        group.observacoesAvaliacao.push({ versao, observacoesAvaliacao, avaliadoPor, avaliadoa });
      }
      return acc;
    }, []);
  };

  // From all reports, only maintain the latest versions
  latestDraftVersions = (drafts) => {
    if (!drafts) return [];
    return drafts
      .sort((a, b) => b.versao - a.versao) // Sort in descending order based on "versao"
      .reduce((result, obj) => {
        // Check if the current version is already in the result array
        let existingObj = result.find((o) => o.refRelatorio === obj.refRelatorio);
        // If there is no existing object or the existing object has a lower version, add the current object to the result array
        if (!existingObj || obj.versao > existingObj.versao) {
          result.push(obj);
        }
        return result;
      }, []);
  };

  // Adiciona novo relatorio a aprovar
  addDraftRelatorio = () => {
    let wait = this.UI.showWaiting();
    this.SusEneTipoRelatorio.find({
      filter: {
        where: {
          and: [{active: 1}, {laboratorioId: this.data.laboratorioId}]
        }
      }
    }).$promise.then((tiposRelatorio) => {
      wait.close();
      if (tiposRelatorio && tiposRelatorio.length > 0) {
        let options = {
          size: 'lg',
          template: require('./relatorio.dialog.html'),
          controller: ['$scope', '$dialog', (scope, dialog) => {
            scope.title = 'Novo Relatório';
            scope.isProcesso = true;
            // For the refRelatorio index
            scope.data = {
              // Suggest refRelatorio notation
              refRelatorio: `SE-${this.data.iidAno}-${("0000" + this.data.iidProc).slice(-4)}.${("00" + this.data.DraftsRelatorios.length).slice(-2)}`,
              hasMoreReports: false // false by default
            };
            scope.tiposRelatorio = tiposRelatorio;

            scope.auxTipoRelatorio = {
              selected: undefined,
              infiniteScroll: {numToAdd: 20, currentItems: 20}
            };

            // Infinite Scroll magic
            scope.addMoreItems = (infiniteScroll) => {
              infiniteScroll.currentItems += infiniteScroll.numToAdd;
            };

            scope.dragString = 'Arrastar o ficheiro para aqui';
            scope.sendingReportMessage = ''; // What is shown when uploading file
            scope.newFileName = '';
            scope.finishedUploading = false;
            scope.okPressed = false;

            scope.generateUUID = () => {
              let uuid = "", i, random;
              for (i = 0; i < 32; i++) {
                random = Math.random() * 16 | 0;
                if (i == 8 || i == 12 || i == 16 || i == 20) {
                  uuid += "-";
                }
                uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
              }
              return uuid;
            };

            scope.uuid = scope.generateUUID();

            scope.uploader = new this.FileUploader({
              url: '/api/Upload/susene/upload',
              queueLimit: 1,
              formData: [{key: 'value'}]
            });

            // Filtro para verificar se é PDF apenas
            let fileTypeFilter = {
              name: 'verifyPDF',
              fn: (item, options) => {
                let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                let ok = '|pdf|'.indexOf(type) !== -1;
                if (!ok) this.UI.addToast("O relatório tem que ser no formato PDF");
                return ok;
              }
            };

            let fileSizeFilter = {
              name: 'fileSizeFilter',
              fn: (item) => {
                if (item.size > 52428800) {
                  this.UI.addToast("Tamanho máximo para ficheiro de relatório é de 50MB");
                }
                return item.size <= 52428800; // 50 MiB to bytes
              }
            };

            // Inserir filtro
            scope.uploader.filters.push(fileTypeFilter, fileSizeFilter);

            // Uploader functions
            scope.uploader.onAfterAddingFile = (item) => {
              let partes = item.file.name.split(".");
              scope.newFileName = scope.uuid + "." + partes[partes.length - 1];

              if (scope.uploader.queue.length > 1)
                scope.uploader.queue.splice(0, scope.uploader.queue.splice.length - 1);
            };

            scope.uploader.onWhenAddingFileFailed = (item) => {
              // ... It's dealt in the filters already
            };

            scope.uploader.onErrorItem = (response, status, headers) => {
              this.UI.addToast("Erro ao carregar ficheiro. Tente novamente ou verifique o ficheiro");
            };

            scope.uploader.onBeforeUploadItem = (item) => {
              item.file.name = scope.newFileName;
            };

            // After file was correctly uploaded
            scope.uploader.onSuccessItem = (item) => {
              scope.sendingReportMessage = 'A criar relatório...';
              this.SusEneProcessoRelatorioDraft.createDraft({
                params: {
                  processoId: this.data.id,
                  refRelatorio: scope.data.refRelatorio,
                  criadoPorId: this.Auth.getId(),
                  criadoa: moment().format(),
                  descricao: scope.data.descricao,
                  uuid: item.file.name,
                  tipoId: scope.auxTipoRelatorio.selected.id,
                  hasMoreReports: scope.data.hasMoreReports
                }
              }).$promise.then((draftRelatorio) => {
                scope.finishedUploading = true;
                dialog.close(draftRelatorio);
                this.UI.addToast("Relatório criado com sucesso");
              }).catch((error) => {
                scope.finishedUploading = true;
                console.log(error);
                this.UI.addToast("Erro na criação de relatório. Tente novamente ou verifique a ligação");
                dialog.close(error);
              });
            };

            scope.ok = () => {
              scope.okPressed = true;
              if (scope.auxTipoRelatorio.selected) {
                scope.data.refRelatorio = scope.data.refRelatorio.trim();
                // Check if relatorio with same ref already exists. If so, do not let it be created (use the proper place)
                this.SusEneProcessoRelatorioDraft.find({
                  filter: {
                    where: {
                      and: [{processoId: this.data.id}, {refRelatorio: scope.data.refRelatorio}, {active: 1}]
                    }
                  }
                }).$promise.then((drafts) => {
                  if (drafts && drafts.length > 0) {
                    // dialog.close();
                    this.UI.showAlert("Já existe um relatório com esta referência de relatório. Se pretende adicionar uma nova versão deste relatório, por favor utilize a linha correspondente para carregar o novo ficheiro");
                  } else { // No refRelatorio exists, we can create a new one
                    if (scope.uploader.queue.length > 0) {
                      scope.sendingReportMessage = 'A carregar relatório...';
                      scope.uploader.uploadAll();
                      // Closing the dialog is done on onSuccessItem()
                    } else {
                      this.UI.addToast("Sem Ficheiro a enviar. Tente novamente.");
                      dialog.close(scope);
                    }
                  }
                }).catch((error) => {
                  console.log(error);
                  let c = this.UI.showAlert("Não foi possível verificar relatórios. Por favor recarregue a página").finally(() => {
                    dialog.close();
                  });
                });
              } else { // Should never reach here
                this.UI.addToast("Tipo de relatório não preenchido");
              }
            };

            scope.cancel = () => {
              scope.$dismiss('cancel');
            };
          }]
        };

        let modal = this.UI.showDialog(options);

        modal.then(res => {
          // Just reload the data
          this.loadData();
        }).catch(() => {
        });
      } else {
        this.UI.addToast("Sem tipos de relatório disponíveis para esta área");
      }
    }).catch((error) => {
      this.UI.addToast("Erro na procura de tipos de relatório. Verifique a ligação");
      console.log(error);
    });
  };

  // Add a new version to existing draft
  addDraftVersion = (draft) => {
    let options = {
      size: 'lg',
      template: require('./relatorio.version.dialog.html'),
      controller: ['$scope', '$dialog', (scope, dialog) => {
        scope.oldDraft = angular.copy(draft);
        scope.title = `Atualização de Relatório ${draft.refRelatorio}`;
        // For the refRelatorio index
        scope.data = {
          descricao: scope.oldDraft.descricao
        };

        scope.dragString = 'Arrastar o ficheiro para aqui';
        scope.sendingReportMessage = ''; // What is shown when uploading file
        scope.newFileName = '';
        scope.finishedUploading = false;
        scope.okPressed = false;

        scope.generateUUID = () => {
          let uuid = "", i, random;
          for (i = 0; i < 32; i++) {
            random = Math.random() * 16 | 0;
            if (i == 8 || i == 12 || i == 16 || i == 20) {
              uuid += "-";
            }
            uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
          }
          return uuid;
        };

        scope.uuid = scope.generateUUID();

        scope.uploader = new this.FileUploader({
          url: '/api/Upload/susene/upload',
          queueLimit: 1,
          formData: [{key: 'value'}]
        });

        // Filtro para verificar se é PDF apenas
        let fileTypeFilter = {
          name: 'verifyPDF',
          fn: (item, options) => {
            let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            let ok = '|pdf|'.indexOf(type) !== -1;
            if (!ok) this.UI.addToast("O relatório tem que ser no formato PDF");
            return ok;
          }
        };

        let fileSizeFilter = {
          name: 'fileSizeFilter',
          fn: (item) => {
            if (item.size > 52428800) {
              this.UI.addToast("Tamanho máximo para ficheiro de relatório é de 50MB");
            }
            return item.size <= 52428800; // 50 MiB to bytes
          }
        };

        // Inserir filtro
        scope.uploader.filters.push(fileTypeFilter, fileSizeFilter);

        // Uploader functions
        scope.uploader.onAfterAddingFile = (item) => {
          let partes = item.file.name.split(".");
          scope.newFileName = scope.uuid + "." + partes[partes.length - 1];

          if (scope.uploader.queue.length > 1)
            scope.uploader.queue.splice(0, scope.uploader.queue.splice.length - 1);
        };

        scope.uploader.onWhenAddingFileFailed = (item) => {
          // ... It's dealt in the filters already
        };

        scope.uploader.onErrorItem = (response, status, headers) => {
          this.UI.addToast("Erro ao carregar ficheiro. Tente novamente ou verifique o ficheiro");
        };

        scope.uploader.onBeforeUploadItem = (item) => {
          item.file.name = scope.newFileName;
        };

        // After file was correctly uploaded
        scope.uploader.onSuccessItem = (item) => {
          scope.sendingReportMessage = 'A criar nova versão...';

          this.SusEneProcessoRelatorioDraft.updateDraft({
            params: {
              processoId: draft.processoId,
              refRelatorio: draft.refRelatorio,
              descricao: scope.data.descricao,
              uuid: item.file.name
            }
          }).$promise.then((novoDraft) => {
              scope.finishedUploading = true;
              dialog.close(novoDraft);
              this.UI.addToast("Relatório criado com sucesso");
          }).catch((error) => {
            scope.finishedUploading = true;
            console.log(error);
            this.UI.addToast("Erro na criação de relatório. Tente novamente ou verifique a ligação");
            dialog.close(error);
          });
        };

        scope.ok = () => {
          scope.okPressed = true;

          // Check if relatorio with same ref already exists. If so, do not let it be created (use the proper place)
          this.SusEneProcessoRelatorioDraft.find({
            filter: {
              where: {
                and: [{processoId: this.data.id}, {refRelatorio: draft.refRelatorio}, {active: 1}]
              }
            }
          }).$promise.then((drafts) => {
            // Only create new version if already exists
            if (drafts && drafts.length > 0) {
              if (scope.uploader.queue.length > 0) {
                scope.sendingReportMessage = 'A carregar relatório...';
                scope.uploader.uploadAll();
                // Closing the dialog is done on onSuccessItem()
              } else {
                this.UI.addToast("Sem Ficheiro a enviar. Tente novamente.");
                dialog.close(scope);
              }
            } else { // No refRelatorio exists, we can't create a new one
              dialog.close();
              this.UI.showAlert("Não existe nenhuma versão anterior deste relatório. Recarregue a página.");
            }
          }).catch((error) => {
            console.log(error);
            let c = this.UI.showAlert("Não foi possível verificar relatórios. Por favor recarregue a página").finally(() => {
              dialog.close();
            });
          });
        };

        scope.cancel = () => {
          scope.$dismiss('cancel');
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then(res => {
      // Just reload the data
      this.loadData();
    }).catch(() => {});
  };

  // Open modal with observations
  showDraftObservations = (draft) => {
    if (!this.hasObservacoesForDraft(draft)) {
      this.UI.addToast("Sem observações a apresentar");
      return;
    }
    let obsData = this.observacoesDrafts.find(x => x.refRelatorio === draft.refRelatorio);

    let options = {
      size: 'lg',
      template: require('./observations.relatorios.dialog.html'),
      controller: ['$scope', '$dialog', (scope, dialog) => {
        scope.title = `Observações de Avaliação do Relatório ${draft.refRelatorio}`;
        scope.obsData = obsData;

        scope.formatDate = (date) => (date ? moment(date).format("DD/MM/YYYY HH:mm") : '&mdash;');

        scope.ok = () => {
          dialog.close();
        };
      }]
    };

    let modal = this.UI.showDialog(options);

    modal.then(res => {
      // Do nothing
    }).catch(() => {});
  };

  confirmDeleteRelatorio = (a) => {
    let title = "Eliminar Relatório";
    let warning = "Tem a certeza que pretende remover esta referência de relatório?";
    let instance = this.UI.showDialog({
      size: 'lg',
      template: require('../../../interface/modals/delete-selected.html'),
      controller: ['$scope', function ($scope) {
        $scope.title = title;
        $scope.message = warning;
        $scope.ok = function () {
          $scope.$close();
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then(() => {
      this.removeRelatorio(a);
    }, (err) => {
      if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
        console.log(err);
    });
  };

  removeRelatorio = (a) => {
    this.loaded = false;
    // Find again familia to delete so we can update it
    this.SusEneProcessoRelatorio.findOne({
      filter: {
        where: {
          id: a.id,
          active: 1
        }
      }
    }).$promise.then((relatorioToRemove) => {
      relatorioToRemove.active = 0;
      relatorioToRemove.$save().then((res) => {
        this.UI.addToast("Ref. Relatório eliminada com sucesso");
        this.loadData();
      }, (error) => {
        console.log(error);
        this.loaded = false;
        this.UI.addToast("Erro de eliminação. Por favor tente mais tarde.");
      });
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível eliminar a ref. relatório. Verifique se ainda existe.");
      this.loadData();
    });
  };

  // Returns the style for Estado do Relatório
  getDraftStyle = (estado) => {
    if (estado == null) {
          }
    switch(estado.id) {
      case 1: return {"font-weight": 700, color: "#4d575d"};
      case 2: return {"font-weight": 500, color: "#d32f2f"};
      case 3: return {"font-weight": 500, color: "#ffb300"};
      case 4: return {"font-weight": 500, color: "#388e3c"};
      default: return {"font-weight": 500, color: "#4d575d"};
    }
  };



  sendToEmail = (documento) => {
    // If necessary, add more documento types
    if (!documento.uuid || documento.uuid.length === 0) {
      this.UI.addToast("Não está definido um ficheiro para envio");
      return;
    }
    let reportType;
    if (documento.Tipo && documento.Tipo.designacao)
      reportType = documento.Tipo.designacao;
    else
      reportType = 'Relatório';

    let dialog = this.UI.showDialog({
      size: "lg",
      template: require("./email.dialog.html"),
      controller: ["$scope", "$dialog", ($scope, $dialog) => {
        $scope.label = `Emails para envio de ${reportType}`;
        $scope.defaultMessageLabel = 'Corpo da mensagem padrão';
        $scope.customMessageLabel = 'Corpo da mensagem personalizada';
        $scope.emails = [];
        $scope.defaultMessageSelected = true;
        $scope.customMessage = null;

        $scope.validEmail = (email) => {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        };

        if ($scope.validEmail(this.data.Ordemintervencao.cliente.email))
          $scope.emails.push({id: 1, selected: false, description: 'Email do Cliente no Primavera', email: this.data.Ordemintervencao.cliente.email});
        if ($scope.validEmail(this.data.Ordemintervencao.email))
          $scope.emails.push({id: 2, selected: false, description: 'Email da OI', email: this.data.Ordemintervencao.email});
        if ($scope.validEmail(this.data.email))
          $scope.emails.push({id: 3, selected: false, description: 'Email do Processo', email: this.data.email});
        $scope.emails.push({id: 10, selected: false, description: 'Outro(s) Email(s) para envio', email: null});

        // // When selecting default/custom message
        $scope.onSelectMessageType = () => {
          if ($scope.defaultMessageSelected) { // Selected
            $scope.customMessage = null;
          } else { // Deselected
            // Do nothing
          }
        };

        $scope.cancel = () => {
          $dialog.dismiss("cancel");
        };

        $scope.hasSelection = () => $scope.emails.some(x => x.selected);

        $scope.ok = () => {
          // Check if Outro is selected, and if it is, if email is valid, otherwise return selection
          let outro = $scope.emails.find(x => x.id === 10);
          if (outro.selected) {
            if (outro.email && outro.email.length > 0) {
              // Check if there are multiple emails by splitting the email string
              let emails = outro.email.split(/[,;]/).map(email => email.trim()).filter(email => email);

              // Validate each email in the list
              let invalidEmails = emails.filter(email => !$scope.validEmail(email));

              if (invalidEmails.length > 0) {
                // If any email is invalid, show a toast message
                this.UI.addToast("Um ou mais emails no campo Outro são inválidos");
              } else {
                // If all emails are valid, remove the original Outro email from $scope.emails
                $scope.emails = $scope.emails.filter(x => x.id !== 10);

                // Add valid emails to the list
                emails.forEach(email => {
                  // Check if email already exists in $scope.emails
                  if (!$scope.emails.some(x => x.selected && x.email === email)) {
                    $scope.emails.push({
                      id: null, // No need for ID
                      email: email,
                      selected: true
                    });
                  }
                });

                // Close the dialog with selected emails
                $dialog.close({
                  emails: $scope.emails.filter(x => x.selected),
                  message: $scope.customMessage
                });
              }
            } else {
              this.UI.addToast("Outro Email selecionado, mas não preenchido");
            }
          } else {
            $dialog.close({
              emails: $scope.emails.filter(x => x.selected),
              message: $scope.customMessage
            });
          }
        };
      }]
    });
    dialog.then((ok) => {
      if (ok.message)
        ok.message = ok.message.replace(/\n/g, '<br>');
      let wait = this.UI.showWaiting();
      let tasks = [];
      ok.emails.forEach(email => {
        let defer = this.$q.defer();
        this.SusEneProcessoRelatorio.notifyRelatorio({
          params: {
            relatorioId: documento.id,
            message: ok.message,
            submittedEmail: email.email
          }
        }).$promise.then(r => {
          this.UI.addToast(`Email com ${reportType} enviado para ${email.email}`);
          defer.resolve(r);
        }).catch(error => {
          console.log(error);
          this.UI.addToast(`Erro no envio de ${reportType} para ${email.email}. Verifique a ligação`);
          defer.reject(error);
        });
        tasks.push(defer.promise);
      });
      this.$q.all(tasks).then((res) => {
        wait.close();
        this.loadData();
      }).catch(error => {
        wait.close();
        console.log(error);
      });
    });
  };

  editExecution = () => {
    let cenas = this.UI.showWaiting();
    this.SusEneProcesso.findOne({
      filter: {
        where: {
          active: 1,
          id: this.data.id,
        },
        include: {
          relation: 'Tarefas',
          scope: {
            where: {
              active: true
            },
            order: 'percentagemFinal desc',
            include: {
              relation: 'funcionario',
              scope: {
                where: {
                  active: true
                }
              }
            }
          }
        }
      }
    }).$promise.then((p) => {
      if (p) {
        cenas.close();
        let minExec, editExec;
        if (p.Tarefas && p.Tarefas.length > 0) {
          minExec = p.Tarefas[0].percentagemFinal;
          editExec = p.Tarefas[0].percentagemFinal;
        } else {
          minExec = 0;
          editExec = 0;
        }

        let options = {
          size: 'md',
          template: require('./execution.dialog.html'),
          controller: ['$dialog', '$scope', function ($dialog, $scope) {
            $scope.editExec = editExec;
            $scope.minExec = minExec;

            $scope.ok = () => {
              $dialog.close($scope);
            };

            $scope.canSubmit = () => {
              return $scope.editExec > $scope.minExec && $scope.editExec <= 100;
            };

            $scope.cancel = () => {
              $dialog.dismiss('cancel');
            }
          }]
        };

        let dialog = this.UI.showDialog(options);
        dialog.then(res => {
          if (res && this.data.Historico && this.data.Historico.length > 0) {
            let minDate = this.data.Historico[0].dataAlteracao;
            let minPercentage = 0;
            this.SusEneProcessoTarefa.find({
              filter: {
                where: {
                  processoId: this.data.id,
                },
                order: 'percentagemFinal DESC',
                limit: 1
              }
            }).$promise.then((t) => {
              if (t && t.length > 0) {
                minPercentage = t[0].percentagemFinal;
                minDate = t[0].dataFim;

                if (minPercentage < res.editExec) {
                  this.SusEneProcessoTarefa.create({
                    id: 0,
                    descricao: 'Alteração de Execução',
                    percentagemInicial: minPercentage,
                    percentagemFinal: res.editExec,
                    dataInicio: minDate,
                    dataFim: moment(),
                    funcionarioId: this.Auth.getId(),
                    processoId: this.data.id,
                    active: 1
                  }).$promise.then((c) => {
                    this.loadData();
                    this.UI.addToast('Execução atualizada com sucesso');
                  }).catch((error) => {
                    console.log(error);
                    this.UI.addToast("Não foi possível atualizar execução. Por favor tente mais tarde.");
                  });
                } else {
                  this.UI.addToast("Não foi possível atualizar execução. Por favor tente mais tarde.");
                }
              } else { // Não existem tarefas ainda, cria uma e usa a data do histórico
                this.SusEneProcessoTarefa.create({
                  id: 0,
                  descricao: 'Alteração de Execução',
                  percentagemInicial: minPercentage,
                  percentagemFinal: res.editExec,
                  dataInicio: minDate,
                  dataFim: moment().format("YYYY-MM-DD"),
                  funcionarioId: this.Auth.getId(),
                  processoId: this.data.id,
                  active: 1
                }).$promise.then((res) => {
                  this.loadData();
                  this.UI.addToast('Execução atualizada com sucesso');
                }).catch((error) => {
                  console.log(error);
                  this.UI.addToast("Não foi possível atualizar execução. Por favor tente mais tarde.");
                });
              }
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Não foi possível atualizar execução. Por favor tente mais tarde.");
            });
          } else {
            this.UI.addToast('Não é possível editar execução. Por favor recarregue a página.');
          }
        });
      } else {
        this.UI.addToast('Não é possível editar execução. Por favor recarregue a página.');
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast('Não é possível editar execução. Por favor recarregue a página.');
    });
  };

  editObservations = () => {
    let a = angular.copy(this.data);
    let dialog = this.UI.showDialog({
      size: 'lg',
      template: require('./observations.dialog.html'),
      controller: ['$scope', $scope => {
        $scope.observacoes = a.observacoes;

        $scope.ok = () => $scope.$close($scope.observacoes);

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    });
    dialog.then(res => {
      this.data.observacoes = res;
      this.data.$save().then(() => {
        this.UI.addToast('Observações guardadas com sucesso');
        this.loadData();
      }).catch(() => {
        this.UI.addToast('Ocorreu um erro as observações. Por favor tente mais tarde.');
        this.loadData();
      })
    })
  };

  resyncClient = (id) => {
    this.SusEneCliente.findOne({
      filter: {
        where: {
          id: id
        }
      }
    }).$promise.then((c) => {
      if (!c.refClientePrimavera) {
        this.UI.addToast("Não foi possível atualizar cliente.");
      } else {
        let PRIClientes = this.SusEneService.getPRIClientes(c);
        PRIClientes.find({
          filter: {
            where: {
              cliente: c.refClientePrimavera
            }
          }
        }).$promise.then((res) => {
          if (res && res.length > 0) {
            c.nome = res[0].nome;
            c.nif = res[0].numcontrib;
            c.cp4 = res[0].facCp.split("-")[0];
            c.cp3 = res[0].facCp.split("-")[1];
            c.localidade = res[0].facCploc;
            c.contactoTelefonico = res[0].facTel;
            c.morada = res[0].facMor;
            c.observacoes = res[0].notas;
            c.atualizadoa = res[0].dataultimaactualizacao;
            c.refClientePrimavera = res[0].cliente;
            c.email = this.validEmail(res[0].cduEmail) ? res[0].cduEmail : null;
            c.$save().then((updatedCliente) => {
              this.loadData();
              this.UI.addToast('Sincronização realizada com sucesso.');
            }).catch(err => {
              console.log(err);
              this.UI.addToast("Falha na gravação do fabricante. Por favor tente mais tarde.");
            });
          } else {
            this.UI.addToast("Não foi possível atualizar cliente.");
          }
        }).catch((error) => {
          console.log(error);
          this.UI.addToast("Não foi possível atualizar cliente.");
        });
      }
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível atualizar cliente.");
    });
  };

  // Gerar Etiquetas
  checkGerarEtiqueta = (amostraProcesso) => {
    // Has etiqueta for this amostra in processo
    let alert;
    if (this.hasEtiquetas && this.existsAnexoTypeForAmostra(amostraProcesso.Amostra, 3)) {
      alert = this.UI.showConfirm("Já existe uma etiqueta de calibração para este processo e para esta amostra. Deseja criar e substituir?");
      alert.then(() => {
        this.gerarEtiqueta(amostraProcesso);
      }).catch(() => {});
    } else {
      alert = this.UI.showConfirm("Irá criar uma etiqueta de calibração para esta amostra e processo. Tem a certeza?");
      alert.then(() => {
        this.gerarEtiqueta(amostraProcesso);
      }).catch(() => {});
    }
  };

  gerarEtiqueta = (amostraProcesso) => {
    if (this.data.estadoId < 6 || this.data.estadoId > 9) {
      this.UI.addToast("Não é possível gerar etiqueta neste estado do processo");
    }
    let wait = this.UI.showWaiting();
    this.SusEneProcesso.gerarEtiqueta({
      amostraId: amostraProcesso.amostraId,
      processoId: amostraProcesso.processoId,
    }).$promise.then((ok) => {
      wait.close();
      if (ok.result) {
        this.UI.addToast("Etiqueta gerada com sucesso");
        this.loadData();
      } else {
        let alert = this.UI.showAlert(ok.extra);
        alert.finally(() => {
          this.loadData();
        });
      }
    }).catch(error => {
      wait.close();
      console.log(error);
      let alert;
      if (error && error.data && error.data.error) {
        alert = this.UI.showAlert(`Erro na criação de etiqueta (${error.data.error.message}).`);
      } else {
        alert = this.UI.showAlert(`Erro na criação de etiqueta. Verifique a ligação`);
      }
      alert.finally(() => {
        this.loadData();
      });
    });
  };

  // Check if amostras have etiquetas or not (to show it or not)
  checkEtiqueta = () => {
    // if (this.data.tipoId !== 1) { // Only for Metrologia
    //   this.hasEtiquetas = false;
    //   return;
    // }
    this.hasEtiquetas = this.existsAnexoType(4); // Etiqueta de calibração
  };

  // Check if amostras have etiquetas or not (to show it or not)
  checkImagensAmostras = () => {
    this.hasImagensAmostras = this.existsAnexoType(3); // Imagem
  };



  // Return true if at least one etiqueta/imagem exists in the list of amostras
  // type === 3 Imagem,
  // type === 4 Etiqueta de calibracao
  existsAnexoType = (type) => {
    if (!this.data.ProcessoAmostras || this.data.ProcessoAmostras.length === 0) {
      return false;
    }
    for (let i = 0; i < this.data.ProcessoAmostras.length; i++) {
      if (this.data.ProcessoAmostras[i].Amostra &&
        this.data.ProcessoAmostras[i].Amostra.Documentacao &&
        this.data.ProcessoAmostras[i].Amostra.Documentacao.length > 0 &&
        this.data.ProcessoAmostras[i].Amostra.Documentacao.find(x => x.documentoId === type) !== undefined)
          return true;
    }
    return false;
  };

  // Returns true if exists etiqueta/imagem for amostra, false otherwise
  // type === 3 Imagem,
  // type === 4 Etiqueta de calibracao
  existsAnexoTypeForAmostra = (amostra, type) => {
    return !!(amostra && amostra.Documentacao && amostra.Documentacao.length > 0 && amostra.Documentacao.find(x => x.documentoId === type));
  };

  // Return the name of the etiqueta or undefined otherwise
  // type === 3 Imagem,
  // type === 4 Etiqueta de calibracao
  getNameAnexoForAmostra = (amostra, type) => {
    if (amostra && amostra.Documentacao && amostra.Documentacao.length > 0) {
      let anexo = amostra.Documentacao.find(x => x.documentoId === type);
      if (anexo && anexo.nome) {
        return anexo.nome;
      }
    }
    return undefined;
  };

  // Only allow submitting new report if the state is ok for it
  // (not in 1 Aguarda Amostra 2 Em Agendamento 3 Agendado 9 Concluído or 10 Cancelado)
  canSubmitNewReport = () => {
    if (this.data) {
      // If estadoId is one of these values, return false
      if ([1, 2, 3, 10].includes(this.data.estadoId)) {
        return false;
      } else if (this.data.estadoId === 9) {
        // If estadoId is concluido, check conditions for each tipoId and empresaId
        return this.AuthorizationService.canPerform('suseneAprovarRelatorios');
      }
      return true;
    }
    return false;
  };
}

SusEneDetailProcessController.$inject = ['$q', '$filter', '$state', '$http', 'UIService', 'AuthenticationService', 'AuthorizationService', 'SusEneService', 'Group', 'CoreFaturacao', 'CoreDepartamento', 'SusEneProcesso', 'LmeCusto', 'SusEneCustoProcesso', 'SusEneProcessoAmostra', 'SusEneProcessoTarefa', 'SusEneEstadoProcesso', 'SusEneProcessoAlteracaoEstado', 'SusEneSuspensaoMotivos', 'SusEneProcessoDocumentos', 'SusEneSubcontratacao', 'SusEneSubentidade', 'SusEneCliente', 'SusEneProcessoRelatorio', 'SusEneProcessoRelatorioDraft', 'SusEneOrdemintervencao', 'SusEneTipoRelatorio', 'FileUploader'];
