export function routes($stateProvider) {
  $stateProvider
    .state('app.susene.entities', {
      url: '/subentidades',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.susene.entities.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      controller: 'SusEneListEntitiesController',
      controllerAs: 'vm',
      template: require('./directory/directory.view.html'),
      role: 'suseneListarEntidades',
      title: 'Subentidades',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.susene.entities.details', {
      url: '/{id}',
      controller: 'SusEneDetailsEntitiesController',
      controllerAs: 'vm',
      template: require('./details/details.view.html'),
      role: 'suseneListarEntidades',
      title: 'Detalhes da Subentidade'
    })
  ;
}

routes.$inject = ['$stateProvider'];
