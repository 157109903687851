import angular from 'angular';
import {routes} from './processes.routes';
import SusEneDetailProcessController from "./details/details.controller";
import SusEneProcessDetailEditController from "./details/details.dialog.controller";
import SUSENEProcessFileUploaderController from "./details/files.dialog.controller";
import SusEneDirectorySchedulingController from "./directory/directory.controller";
import SusEneProcessoService from "./processos.service";

export default angular.module('app.susene.processes', [])
  .config(routes)
  .controller('SusEneDetailProcessController', SusEneDetailProcessController)
  .controller('SusEneProcessDetailEditController', SusEneProcessDetailEditController)
  .controller('SUSENEProcessFileUploaderController', SUSENEProcessFileUploaderController)
  .controller('SusEneDirectorySchedulingController', SusEneDirectorySchedulingController)
  .service('SusEneProcessoService', SusEneProcessoService)
  .filter('suseneFormat', function() {
    return function(data) {
      let e = 'IEP';
      let t = 'SE';
      let ano = data.iidAno;
      let num = ("0000" + data.iidProc) .slice(-4);
      return `${e}-${t}-${ano}-${num}`;
    }
  })
  .name;
