import { routes } from './routes'

//import RhEventController from './events/controller';
//import RhEventService from './events/service';
import Avaliacoes from './avaliacoes'

import RhService from './rh.service';

export default angular.module('app.rh', [Avaliacoes])
  .config(routes)
  .service('RhService', RhService)
  .name;
  //.service('RhEventService', RhEventService)
  //.controller('RhEventController', RhEventController)
  /* .directive('focusMe', function () {
    return {
      scope: { trigger: '=focusIn' },
      link: function (scope, element) {
        scope.$watch('trigger', function (value) {
          if (value === true) {
            element[0].focus();
            scope.trigger = false;
          }
        });
      }
    };
  }) */
  
