import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Stack, Switch, Tooltip, Typography } from '@mui/material';
import GraficoExemplo from '../../components/grafico';
import TableData from '../../components/table';
import { MdiIcon } from '@components';
import { mdiTable } from '@mdi/js';
const CrmDashboardView = () => {
    const [showTables, setShowTables] = useState(false);
    const vendedor = {
        id: 1,
        nome: 'João Orvalho',
        cargo: 'CEO',
        ultimoContacto: '04/02/2024',
        colaboradorIEP: 'Arlindo',
        avatar: 'joaoOrvalho.png',
        nif: '252679970',
        dataNascimento: '28/09/2000',
        profissao: 'Consultor',
        interessesPessoais: 'Gases inertes',
        interessesProfissionais: 'Gases inertes',
        contacto: '969912345',
        email: 'jorvalho@streamline.pt',
        formacao: 'Web Designer'
    };
    const dadosPedidosCoatacao = [
        { id: 1, area: 'LME', empresa: 'Pingo Doce', idade: '5 dia(s)', dataUltimaAlteracao: '04/02/2024', estado: 'Contacto inicial' },
        { id: 2, area: 'IE', empresa: 'Ikea', idade: '1 dia(s)', dataUltimaAlteracao: '03/05/2023', estado: 'Componente Financeira' },
        { id: 3, area: 'LME', empresa: 'Fonseca & Filhos', idade: '12 dia(s)', dataUltimaAlteracao: '05/02/2022', estado: 'Componente técnica' }
    ];
    const colunasPedidosCoatacao = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50
        },
        {
            accessorKey: 'area',
            header: 'Área',
            size: 100
        },
        {
            accessorKey: 'empresa',
            header: 'Empresa',
            size: 150
        },
        {
            accessorKey: 'idade',
            header: 'Idade',
            size: 100
        },
        {
            accessorKey: 'dataUltimaAlteracao',
            header: 'Data Última Alteração',
            size: 150
        },
        {
            accessorKey: 'estado',
            header: 'Estado',
            size: 200
        }
    ], []);
    const dadosUltimasInteracoes = [
        { id: 1, data: '04/02/2021', tipo: 'Mail', empresa: 'Pingo Doce', pessoa: 'Francisco Maia', idade: '5 dia(s)' },
        { id: 2, data: '03/02/2024', tipo: 'Telefonema', empresa: 'Ikea', pessoa: 'João Orvalho', idade: '1 dia(s)' },
        { id: 3, data: '21/02/2024', tipo: 'Almoço', empresa: 'Fonseca & Filhos', pessoa: 'António Gomes', idade: '12 dia(s)' }
    ];
    const colunasUltimasInteracoes = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50
        },
        {
            accessorKey: 'data',
            header: 'Data',
            size: 100
        },
        {
            accessorKey: 'tipo',
            header: 'Tipo',
            size: 150
        },
        {
            accessorKey: 'empresa',
            header: 'Empresa',
            size: 150
        },
        {
            accessorKey: 'pessoa',
            header: 'Pessoa',
            size: 150
        },
        {
            accessorKey: 'idade',
            header: 'Idade',
            size: 100
        }
    ], []);
    const dadosOportunidades = [
        { id: 1, dataRegisto: '04/02/2021', empresa: 'Pingo Doce', pessoa: 'Francisco Maia', servico: 'Ensaio', area: 'LME', idade: '5 dia(s)' },
        { id: 2, dataRegisto: '03/02/2024', empresa: 'Ikea', pessoa: 'João Orvalho', servico: 'Calibração', area: 'LME', idade: '1 dia(s)' },
        { id: 3, dataRegisto: '21/02/2024', empresa: 'Fonseca & Filhos', pessoa: 'António Gomes', servico: 'Formação', area: 'FOR', idade: '12 dia(s)' }
    ];
    const colunasOportunidades = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50
        },
        {
            accessorKey: 'dataRegisto',
            header: 'Data Registo',
            size: 100
        },
        {
            accessorKey: 'empresa',
            header: 'Empresa',
            size: 150
        },
        {
            accessorKey: 'pessoa',
            header: 'Pessoa',
            size: 150
        },
        {
            accessorKey: 'servico',
            header: 'Serviço',
            size: 150
        },
        {
            accessorKey: 'area',
            header: 'Área',
            size: 100
        },
        {
            accessorKey: 'idade',
            header: 'Idade',
            size: 100
        }
    ], []);
    useEffect(() => { }, [showTables]);
    return (<Box sx={{ mt: 2, pl: 1, pr: 1 }}>
      <Typography variant='h4' sx={{ color: 'black' }}>
        Dashboard Vendedor
      </Typography>

      <Grid container spacing={2} justifyContent='center' alignItems='flex-start'>
        <Grid item xs={12} sm={12} md={4} lg={2.5} sx={{ mt: 2 }}>
          <Stack justifyContent='center' alignItems='flex-start' sx={{ height: '100%', width: '100%' }}>
            <Box>
              <Stack sx={{ mt: 2 }}>
                <Box component='img' src={vendedor.avatar ? `/api/Upload/photos/download/${vendedor.avatar}` : '/api/Upload/photos/download/defaultAvatar.png'} alt='Uploaded' onError={e => {
            e.currentTarget.onerror = null; // Previne loop infinito caso a imagem default falhe também
            e.currentTarget.src = '/api/Upload/photos/download/defaultAvatar.png'; // Fallback para imagem default
        }} sx={{
            maxWidth: '200px',
            maxHeight: '200px',
            width: 'auto',
            height: 'auto',
            borderRadius: '50%'
        }}></Box>
              </Stack>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={9.5}>
          <Grid container justifyContent='flex-start' alignItems='center' sx={{ height: '100%' }} spacing={2}>
            <Grid item xs={12} justifyContent='flex-start'>
              <Typography variant='h4' sx={{ color: 'black' }}>
                {vendedor.nome}
              </Typography>
              
            </Grid>

            <Grid item xs={6} sm={6} md={3}>
              <Typography variant='caption'>Contacto</Typography>
              <Typography sx={{ color: 'black' }}>{vendedor.contacto}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Typography variant='caption'>Email</Typography>
              <Typography sx={{ color: 'black' }}>{vendedor.email}</Typography>
            </Grid>
            <Grid item md={6}>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

      <Box key={'graficos'} sx={{ mt: 2 }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
          <Typography variant='h6' sx={{ color: 'black' }}>
            Indicadores
          </Typography>
          <Stack direction='row'>
            <Tooltip title={!showTables ? 'Mostrar tabelas' : 'Esconder tabelas'}>
              <Switch onChange={() => setShowTables(!showTables)}/>
            </Tooltip>
            <MdiIcon path={mdiTable} sx={{ color: showTables ? '#0288d1' : 'default' }}></MdiIcon>
          </Stack>
        </Stack>

        <Box>
          <Grid item xs={12}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <GraficoExemplo title={'Processos em aberto €'} bgColor={'rgba(250, 20, 20, 0.2)'} borderColor={'rgba(250, 20, 20, 1)'} type={'line'} showTable={showTables}/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <GraficoExemplo title={'Valor propostas emitidas €'} bgColor={'rgba(75, 192, 75, 0.2)'} borderColor={'rgba(75, 192, 75, 1)'} type={'line'} showTable={showTables}/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <GraficoExemplo title={'Volume faturado €'} bgColor={'rgba(20, 60, 245, 0.2)'} borderColor={'rgba(20, 60, 245, 1)'} type={'line'} showTable={showTables}/>
              </Grid>

              <Grid item xs={false} sm={false} md={2}></Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <GraficoExemplo title={'Propostas em aberto'} bgColor={'rgba(255, 165, 0, 0.2)'} borderColor={'rgba(255, 165, 0, 1)'} type={'bar'} showTable={showTables}/>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <GraficoExemplo title={'PCA'} bgColor={'rgba(150, 0, 255, 0.2)'} borderColor={'rgba(150, 0, 255, 1)'} type={'bar'} showTable={showTables}/>
              </Grid>
              <Grid item xs={false} sm={false} md={2}></Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Stack direction='column' spacing={4}>
          <TableData title={'Pedidos de cotação abertos'} columns={colunasPedidosCoatacao} data={dadosPedidosCoatacao}></TableData>

          <TableData title={'Pedidos de propostas abertos'} columns={colunasPedidosCoatacao} data={dadosPedidosCoatacao}></TableData>

          <TableData title={'Últimas interações'} columns={colunasUltimasInteracoes} data={dadosUltimasInteracoes}></TableData>

          <TableData title={'Oportunidades abertas (própria ou endossada)'} columns={colunasOportunidades} data={dadosOportunidades} addButton={true}></TableData>
        </Stack>
      </Grid>
    </Box>);
};
export default CrmDashboardView;
