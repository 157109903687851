export default class DirectorySchedulingController {
  constructor($state, $q, Requisitante, Pedidoinspeccao, Agendamento, Estado, Alteracaoestado, Funcionario, Group, AuthenticationService, UIService) {
    this.$state = $state;
    this.$q = $q;
    this.Auth = AuthenticationService;
    this.user = AuthenticationService.getUser();
    this.opt = $state.params;
    this.UI = UIService;
    this.Requisitante = Requisitante;
    this.Pedidoinspeccao = Pedidoinspeccao;
    this.Agendamento = Agendamento;
    this.Alteracaoestado = Alteracaoestado;
    this.Funcionario = Funcionario;
    this.Group = Group;
    this.Estado = Estado;
    this.totalByType = [];
    this.listOpt = [];
    this.selected = 0;
    this.select = false;
    this.allSelected = false;
    this.simnao = [{ name: "Sim", value: 1 }, { name: "Não", value: 0 }];
    this.requisitantes = [];
    this.inspetores = [];
    this.typeId = this.opt.type;
    this.typeIndex = -1;
    this.tableLoading = true;
    this.typesLoading = true;

    // If loadData() finished or not
    this.dataLoaded = false;
    // If loadData() fails for some reason
    this.errorLoadingData = false;

    this.defaultOpt = {
      page: 1,
      items: 20,
      type: 0,
      order: "id",
      sort: "desc",
      filter: undefined
    };

    // type 's': select, 'o': plain text, 'd': date
    this.equalityFilters = [{
      val: 'a',
      name: 'Igual',
      type: 'o'
    },
      {
        val: 'b',
        name: 'Diferente',
        type: 'o'
      },
      {
        val: 'c',
        name: 'Começa por',
        type: 'o'
      },
      {
        val: 'd',
        name: 'Termina com',
        type: 'o'
      },
      {
        val: 'e',
        name: 'Contém',
        type: 'o'
      },
      {
        val: 'a',
        name: 'Igual (=)',
        type: 'd'
      },
      {
        val: 'b',
        name: 'Diferente de (≠)',
        type: 'd'
      },
      {
        val: 'c',
        name: 'Posterior a (>)',
        type: 'd'
      },
      {
        val: 'd',
        name: 'Anterior a (<)',
        type: 'd'
      },
      {
        val: 'e',
        name: 'Posterior ou igual (≥)',
        type: 'd'
      },
      {
        val: 'f',
        name: 'Anterior ou igual (≤)',
        type: 'd'
      },
      {
        val: 'a',
        name: 'Igual',
        type: 's'
      },
      {
        val: 'b',
        name: 'Diferente',
        type: 's'
      }
    ];

    this.dateFilters = this.equalityFilters.filter(x => x.type === 'd');

    this.columns = [
      {
        id: 'Pedidoinspeccao.id',
        name: 'ID SGI',
        type: 'o'
      },
      {
        id: 'Pedidoinspeccao.visto',
        name: 'Visto',
        type: 's',
        list: this.simnao
      },
      {
        id: 'Pedidoinspeccao.identificacaoInterna',
        name: 'Nº Processo',
        type: 'o'
      },
      {
        id: 'Pedidoinspeccao.requisitanteId',
        name: 'Técnico Responsável',
        type: 's',
        list: this.requisitantes
      },
      {
        id: 'Pagamento.dataPagamento',
        name: 'Data de Pagamento',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'Dadosinstalacao.morada',
        name: 'Morada',
        type: 'o'
      },
      {
        id: 'Dadosinstalacao.codigoPostal1',
        name: 'Código Postal',
        type: 'o'
      },
      {
        id: 'Dadosinstalacao.noFraccoes',
        name: 'Nº Frações',
        type: 'o'
      },
      {
        id: 'Dadosinstalacao.potenciaTotalEdificio',
        name: 'Potência Total Edifício (kVA)',
        type: 'o'
      },
      {
        id: 'Agendamento.tecnicoId',
        name: 'Inspetor',
        type: 's',
        list: this.inspetores
      },
      {
        id: 'Agendamento.data',
        name: 'Data de Inspeção',
        type: 'd',
        format: "YYYY-MM-DD",
        displayFormat: "DD/MM/YYYY"
      },
      {
        id: 'Agendamento.hora',
        name: 'Hora de Inspeção',
        type: 'd',
        // format: "HH:mm",
        displayFormat: "HH:mm"
      },
    ];
    this.customFilters = [];

    // Find customFilters from local storage
    if (localStorage.getItem('IEAgendamentosFilter')) {
      this.customFilters = JSON.parse(localStorage.getItem('IEAgendamentosFilter'));
    }

    if (this.opt.filter) {
      let filters = this.opt.filter.split(":");
      filters.forEach(filter => {
        let a = filter.split("·");
        if (a.length === 3) {
          try {
            let data = {
              column: {},
              value: {}
            };
            data.column.selected = this.columns.find(f => {
              return f.id === a[0];
            });
            if (angular.isUndefined(data.column.selected)) {
              throw Error();
            }
            this.customFilters.push(data);
          } catch (e) {
            this.customFilters = [];
            this.UI.addToast('Não foi possível carregar filtros');
          }
        }
      });
      // If updated, save it to local storage
      localStorage.setItem('IEAgendamentosFilter', JSON.stringify(this.customFilters));
    }

    // Restore list to selected if exists
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'Pedidoinspeccao.visto')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'Pedidoinspeccao.requisitanteId')
          f.column.selected.list = this.requisitantes;
        if (f.column.selected.id === 'Agendamento.tecnicoId')
          f.column.selected.list = this.inspetores;
      }
    });

    this.pedidos = null;

    this.loadData();
    this.getEstados();
  }

  clearFilter = () => {
    localStorage.removeItem('IEAgendamentosFilter');
    this.customFilters = [];
    this.getPedidos();
  };

  // Returns true if anything is selected in Agendamentos of 'type'
  hasItemsSelected = () => {
    return _.some(this.pedidos, (r) => r.selected === true);
  };

  // Returns number of selected items from table of 'type'
  countSelected = (type) => {
    return _.filter(this.pedidos, (r) => r.selected === true).length;
  };

  // Returns what's selected in array of items
  getSelected = (items) => {
    return _.filter(items, (r) => {
      if (r.selected === true)
        return r;
    });
  };

  oldColumn = ($item, i) => {
    //Infinite Scroll Magic
    i.infiniteScroll = {};
    i.infiniteScroll.numToAdd = 20;
    i.infiniteScroll.currentItems = 20;

    if ($item.type === 's')
      i.value = {};
    else
      i.value = "";

    i.addMoreItems = function () {
      i.infiniteScroll.currentItems += i.infiniteScroll.numToAdd;
    };
  };

  applyFilter = () => {
    // Parse values from sidebar
    this.editCustomFilters = _.filter(this.editCustomFilters, f => f.column && !_.isEmpty(f.value));

    // Remove list for column, no need to save it
    this.editCustomFilters.forEach(f => {
      if (f.column && f.column.selected) {
        f.column.selected.list = [];
      }
    });

    this.customFilters = angular.copy(this.editCustomFilters);

    localStorage.setItem('IEAgendamentosFilter', JSON.stringify(this.customFilters));
    this.fs = false;
    this.getPedidos();
  };

  getEstados = () => {
    this.typesLoading = true;
    this.Estado.find({
      filter: {
        where: {
          active: true,
          id: {
            inq: [2, 3, 4]
          }
        },
        order: 'id ASC'
      }
    }).$promise.then((res) => {
      this.typesPedido = res;

      let emAtrasoType = angular.copy(this.typesPedido[0]);
      emAtrasoType.designacao = 'Em Atraso';

      this.typesPedido.unshift(emAtrasoType);

      this.typesPedido.forEach((e,i) => {
        let o = angular.copy(this.defaultOpt);
        o.type = i;
        this.listOpt.push(o);
        e.state = e.id;
        e.id = i; // id is type, state is state
      });

      // typeIndex is the index of the type in the array
      this.typeIndex = this.typesPedido.findIndex(e => e.id === this.typeId);
      // If we can't find the index, use the first one
      if (this.typeIndex === -1) {
        this.typeId = this.typesPedido[0].id;
        this.typeIndex = 0;
      }
      this.getCountPedidosType();

    }).catch((error) => {
      console.log(error);
      this.UI.addToast("De momento não é possível ver pedidos. Por favor tente mais tarde.");
    });
  };

  getCountPedidosType = () => {
    let tasks = [];
    this.typesPedido.forEach(t => {
      let defer = this.$q.defer();

      // NO custom fields for global count
      let whereFields = this.setWhereFields([], {
        'Pedidoinspeccao.active': 1,
        'Pagamento.pago': 1,
        'Pagamento.active': 1,
        'Pedidoinspeccao.estadoId': t.state
      });
      this.Agendamento.table({ // Special Agendamento table()
        params: {
          justCount: true,
          type: t.id,
          fields: [
            'Pedidoinspeccao.id as id',
          ],
          from: ['Pedidoinspeccao', 'Requisitante', 'Dadosinstalacao', 'Agendamento', 'Funcionario', 'Pagamento'],
          referencesOrigin: [undefined, undefined, 'Dadosinstalacao.pedidoinspeccaoId', 'Agendamento.pedidoId', 'Agendamento.tecnicoId', 'Pagamento.pedidoId'],
          references: [undefined, 'Pedidoinspeccao.requisitanteId', 'Pedidoinspeccao.id', 'Pedidoinspeccao.id', 'Funcionario.id', 'Pedidoinspeccao.id'],
          aliases: [],
          where: whereFields.where,
          whereLiteral: whereFields.whereLiteral,
          whereDates: whereFields.whereDates,
          whereOr: whereFields.whereOr,
          whereOrLiteral: whereFields.whereOrLiteral,
          order: this.opt.order,
          sort: this.opt.sort,
          limit: this.opt.items,
          skip: (this.opt.page - 1) * this.opt.items,
        }
      }).$promise.then(res => {
        this.totalByType[t.id] = res.count;
        defer.resolve(t.id);
      }).catch((error) => {
        console.log(error);
        defer.reject(t.id);
      });
      tasks.push(defer.promise);
    });
    this.$q.all(tasks).then((res) => {
      // If we are loading states still, we can now show the information
      if (this.typesLoading)
        this.typesLoading = false;
    }).catch(e => {
      // Do something when at least one count fails
      console.log(e);
      this.UI.addToast("Não foi possível contagem de agendamentos. Verifique a ligação.");
    });
  };

  agendarPedido = (pedido) => {
    let itens = [];

    if (_.isEmpty(pedido)) {
      itens = this.getSelected(this.pedidos);
    } else {
      itens = pedido;
    }
    if (itens.length === 0) {
      return;
    }

    if (itens.length > 20) {
      let alert = this.UI.showAlert("Por favor selecione no máximo 20 pedidos para agendar de cada vez para a mesma data para o mesmo inspetor.");
      alert.finally(() => {});
    } else {

      // Get good data for modal to be pre-filled if data is appropriate
      let modalData = this.preFillCorrectModalData(itens);
      let cenas = this.UI.showWaiting();
      this.Group.findOne({
        filter: {
          where: {
            name: {
              like: 'Inspetor%IE'
            }
          },
          include: {
            relation: 'usergroup',
            scope: {
              include: 'user'
            }
          }
        }
      }).$promise.then((g) => {
        let funcionarios = [];
        g.usergroup.forEach(u => {
          funcionarios.push(u.user);
        });

        funcionarios = _.orderBy(funcionarios, 'name', 'asc');

        // agenda is a variable to hold common data (like dataInspeccao)
        let agenda = {};
        agenda.dataInspeccao = modalData.data;
        // Format date for modal
        if (agenda.dataInspeccao != null)
          agenda.dataInspeccao = moment(agenda.dataInspeccao).format('YYYY-MM-DD');
        cenas.close();
        let instance = this.UI.showDialog({
          size: 'md',
          template: require('./modal-edit-agendamento.html'),
          controller: ['$scope', '$dialog', ($scope, $dialog) => {
            $scope.agenda = agenda;
            $scope.pedidos = itens;
            $scope.funcionarios = funcionarios || [];
            $scope.horaInspeccaoOptions = {format: 'HH:mm'};
            $scope.auxFuncionario = {
              selected: modalData.tecnico,
              infiniteScroll: {
                numToAdd: 20,
                currentItems: 20
              }
            };

            // Infinite Scroll magic
            $scope.addMoreItems = (infiniteScroll) => {
              infiniteScroll.currentItems += infiniteScroll.numToAdd;
            };

            $scope.ok = () => {
              // Check if it's valid dates
              let validDates = true;
              if (!$scope.agenda.dataInspeccao.isValid()) {
                validDates = false;
                $scope.agenda.dataInspeccao = null;
                this.UI.addToast("Data de Inspeção inválida. Preencha novamente");
              }
              for (let i = 0; i < $scope.pedidos.length; i++) {
                if (!$scope.pedidos[i].horaInspeccao.isValid()) {
                  $scope.pedidos[i].horaInspeccao = null;
                  validDates = false;
                  this.UI.addToast("Hora de Inspeção inválida. Preencha novamente");
                }
              }
              if (validDates) {
                $scope.tecnico = $scope.auxFuncionario.selected;
                $scope.$close({
                  tecnico: $scope.tecnico,
                  agenda: $scope.agenda,
                  pedidos: $scope.pedidos
                });
              }
            };

            $scope.cancel = () => {
              $scope.pedidos.forEach((p) => {
                p.horaInspeccao = null;
              });
              $dialog.dismiss('cancel');
            };

            $scope.checkDisabled = (a, t) => {
              if (a == null || t == null)
                return true;
              return !a.dataInspeccao;
            }
          }]
        });
        instance.then((agendamento) => {
          let wait = this.UI.showWaiting();
          let tasks = [];
          let status = [];
          agendamento.pedidos.forEach((pedido) => {
            let defer = this.$q.defer();
            this.Pedidoinspeccao.findOne({
              filter: {
                where: {
                  id: pedido.id
                },
                include: {
                  relation: 'Agendamento',
                  scope: {
                    where: {
                      active: 1
                    }
                  }
                }
              }
            }).$promise.then((p) => {
              if (p.estadoId !== 2) { // Pedido must be "Em Agendamento"
                status.push({
                  error: true,
                  message: "Pedido " + p.id + " não se encontra Em Agendamento"
                });
                defer.resolve(p.id);
              } else {
                if (p.Agendamento) { // Must not exist a valid Agendamento
                  status.push({
                    error: true,
                    message: "Pedido " + p.id + " já se encontra agendado"
                  });
                  defer.resolve(p.id);
                } else {
                  let newDate = new Date();
                  newDate.setHours(newDate.getHours() - newDate.getTimezoneOffset() / 60);
                  this.Agendamento.create({
                    id: 0,
                    pedidoId: p.id,
                    data: moment(agendamento.agenda.dataInspeccao).format('YYYY-MM-DD'),
                    hora: pedido.horaInspeccao ? pedido.horaInspeccao.format("HH:mm") : null,
                    tecnicoId: agendamento.tecnico.id,
                    agendadoa: newDate,
                    agendadoporId: this.user.id,
                    active: 1
                  }).$promise.then((res) => {
                    // Change request state to "Agendado"
                    this.setAgendamentoToPedidoAndUpdate(p.id, 4).then(() => {
                      status.push({
                        error: false,
                        message: 'Pedido ' + p.id + ' agendado com sucesso'
                      });
                      defer.resolve(p.id);
                    }).catch(err => {
                      console.log(err);
                      status.push({
                        error: true,
                        message: 'Erro na alteração de estado do Pedido ' + p.id + '. Por favor altere-o manualmente para Agendado.'
                      });
                      defer.resolve(p.id);
                    });
                  }).catch(err => {
                    console.log(err);
                    status.push({
                      error: true,
                      message: 'Erro na criação de agendamento do Pedido ' + p.id
                    });
                    defer.resolve(p.id);
                  });
                }
              }
            }).catch((error) => {
              console.log(error);
              status.push({
                error: true,
                message: "Pedido " + pedido.id + " não encontrado"
              });
              defer.resolve(pedido.id);
            });
            tasks.push(defer.promise);
          });

          // After all agendamentos are settled
          this.$q.all(tasks).then((res) => {
            wait.close();
            this.showAgendamentoErrorsAndUpdate(status);
          }).catch(e => {
            wait.close();
            // Do something when at least one count fails
            this.showAgendamentoErrorsAndUpdate(status);
          });
        }, (err) => {
          // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
          if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
            console.log(err);
        });
      }).catch(err => {
        console.log(err);
        this.UI.addToast("Não foi possível carregar inspetores. Tente novamente");
      });
    }
  };

  // Show success and error for agendamentos and update view
  showAgendamentoErrorsAndUpdate = (status) => {
    let successes = status.filter( x => !x.error);
    successes.forEach(s => {
      this.UI.addToast(s.message);
    });
    let errors = status.filter(x => x.error);
    if (errors.length > 0) {
      let title = "Erros de Agendamento";
      let introText = "Ocorreram os seguintes erros de agendamento:";
      let instance = this.UI.showDialog({
        size: 'md',
        template: require('../../../interface/modals/show-list.html'),
        controller: ['$scope', ($scope) => {
          $scope.title = title;
          $scope.introText = introText;
          $scope.list = errors;
          $scope.ok = function () {
            $scope.$close();
          };
        }]
      });
      instance.finally(() => {this.getPedidos();})
    } else {
      this.getPedidos();
    }
  }

  // Change request state to something else. Used observacoes for desmarcacoes
  setAgendamentoToPedidoAndUpdate = (pid, value, observacoes) => {
    let defer = this.$q.defer();

    this.Pedidoinspeccao.findOne({
      filter: {
        where: {
          id: pid,
          active: 1
        }
      }
    }).$promise.then((res) => {
      if (res != null) {
        let newDate = new Date();
        newDate.setHours(newDate.getHours() - newDate.getTimezoneOffset() / 60);
        this.Alteracaoestado.create({
          id: 0,
          dataAlteracao: newDate,
          pedidoId: res.id,
          estadoInicial: res.estadoId,
          estadoFinal: value,
          funcionarioId: this.user.id,
          observacoes: observacoes || "Agendamento",
          active: 1
        }).$promise.then((alteracao) => {

          // Change state of pedido
          res.estadoId = value;
          res.$save().then((r) => {
            defer.resolve();
          });
        }).catch((err) => {
          console.log(err);
          defer.reject(err);
        });
      } else
        defer.reject();
    }).catch(err => {
      console.log(err);
      defer.reject(err);
    });
    return defer.promise;
  };

  // Add entry to whereObject (where/whereLiteral) or to whereOrObject depending if there are multiple selections of the same entry
  setWhereField = (data, whereObject, whereOrObject) => {
    // Check if have this entry in whereOr already
    if (whereOrObject.find(x => x.key === data.key)) { // If so, add it here then
      whereOrObject.push({
        key: data.key,
        value: data.value
      });
    } else { // Not in OR, check if there's an entry of this key in whereObject already
      if (whereObject[data.key]) { // Already have an entry for this key, transform it into an OR and add it and the new one
        whereOrObject.push({
          key: data.key,
          value: whereObject[data.key]
        });
        delete whereObject[data.key];
        whereOrObject.push({
          key: data.key,
          value: data.value
        });
      } else { // It's the first entry of this key, use whereObject only
        whereObject[data.key] = data.value;
      }
    }
  };

  // Returns a whereFields object to use in table() remote methods (ativo, interacao)
  // WhereLiteral should be defined locally
  setWhereFields = (customFilters, literal, orsLiteral) => {
    let where = {};
    let whereDates = [];
    let whereNextDates = [];
    let whereLiteral = literal || {};
    let whereOrLiteral = orsLiteral || [];
    let whereOr = [];

    customFilters.forEach(f => {
      let data = {};
      data.key = f.column.selected.id;
      // Generated dates - Assuming if no . exists in field, it's always generated date
      if (f.column.selected.id.split('.').filter(Boolean).length === 1 && f.column.selected.type === 'd') {
        whereNextDates.push({
          key: data.key,
          comp: f.values.selected.val,
          value: f.value,
          format: f.column.selected.format
        });
      } else { // All other cases where fields exist in database
        if (f.column.selected.type === 'o') {
          data.value = f.value;

          this.setWhereField(data, where, whereOr);
        } else {
          if (f.column.selected.type === 'd') {
            whereDates.push({
              key: data.key,
              comp: f.values.selected.val,
              value: f.value,
              format: f.column.selected.format
            });
          } else {
            // if ID doesn't exist, use value (Yes/No cases where id doesn't exist but value does)
            if (f.value.selected.id)
              data.value = f.value.selected.id;
            else
              data.value = f.value.selected.value;

            this.setWhereField(data, whereLiteral, whereOrLiteral);
          }
        }
      }
    });
    return {
      where: where,
      whereDates: whereDates,
      whereNextDates: whereNextDates,
      whereLiteral: whereLiteral,
      whereOr: whereOr,
      whereOrLiteral: whereOrLiteral
    };
  };

  getPedidos = () => {
    this.tableLoading = true;
    let whereFields = this.setWhereFields(this.customFilters, {
      'Pedidoinspeccao.active': 1,
      'Pagamento.pago': 1,
      'Pagamento.active': 1,
      'Pedidoinspeccao.estadoId': this.typesPedido[this.typeId].state
    });
    this.Agendamento.table({ // Special Agendamento table()
      params: {
        type: this.typeId,
        justCount: false,
        fields: [
          'Pedidoinspeccao.id as id',
          'Pedidoinspeccao.identificacaoInterna as identificacaoInterna',
          'Pedidoinspeccao.estadoId as estadoId',
          'Pedidoinspeccao.visto as visto',
          'Requisitante.id as requisitanteId',
          'Requisitante.nome as requisitante',
          'Dadosinstalacao.morada as morada',
          'Pagamento.dataPagamento as dataPagamento',
          'Dadosinstalacao.codigoPostal1 as codigoPostal1',
          'Dadosinstalacao.codigoPostal2 as codigoPostal2',
          'Dadosinstalacao.codigoPostal3 as codigoPostal3',
          'Dadosinstalacao.noFraccoes as noFraccoes',
          'Dadosinstalacao.potenciaTotalEdificio as potenciaTotalEdificio',
          'Funcionario.name as inspetor',
          'Funcionario.id as inspetorId',
          'Agendamento.data as data',
          'Agendamento.hora as hora'
        ],
        from: ['Pedidoinspeccao', 'Requisitante', 'Dadosinstalacao', 'Agendamento', 'Funcionario', 'Pagamento'],
        referencesOrigin: [undefined, undefined, 'Dadosinstalacao.pedidoinspeccaoId', 'Agendamento.pedidoId', 'Agendamento.tecnicoId', 'Pagamento.pedidoId'],
        references: [undefined, 'Pedidoinspeccao.requisitanteId', 'Pedidoinspeccao.id', 'Pedidoinspeccao.id', 'Funcionario.id', 'Pedidoinspeccao.id'],
        aliases: [],
        where: whereFields.where,
        whereLiteral: whereFields.whereLiteral,
        whereDates: whereFields.whereDates,
        whereOr: whereFields.whereOr,
        whereOrLiteral: whereFields.whereOrLiteral,
        order: this.opt.order,
        sort: this.opt.sort,
        limit: this.opt.items,
        skip: (this.opt.page - 1) * this.opt.items,
      }
    }).$promise.then(res => {
      let page = this.opt.page;
      let items = this.opt.items;

      // let total = this.totalByType[this.opt.state];
      let total = res.count;
      this.start = total > 0 ? (page - 1) * items + 1 : 0;
      if ((this.start - 1 + items) >= total) {
        this.end = total;
      } else {
        this.end = Number.parseInt(this.start - 1 + items);
      }

      // Process output
      res.data.forEach(i => {
        if (i.codigoPostal1 && !i.codigoPostal2) {
          i.codigoPostal = i.codigoPostal1;
        }
        if (i.codigoPostal1 && i.codigoPostal2) {
          i.codigoPostal = `${('0000' + i.codigoPostal1).slice(-4)}-${('000' + i.codigoPostal2).slice(-3)}`;
        }
        if (i.codigoPostal2 !=  null && !isNaN(i.codigoPostal2)) {
          i.codigoPostal2 = ('000' + i.codigoPostal2).slice(-3);
          i.codigoPostal = `${('0000' + i.codigoPostal1).slice(-4)}-${('000' + i.codigoPostal2).slice(-3)}`;
        }
        i.codigoPostal += (' ' + i.codigoPostal3);
      });

      this.getCountPedidosType();
      this.pedidos = res.data;
      this.total = total;
      this.tableLoading = false;
      this.typesLoading = false;
      this.allSelected = false;
    }).catch((error) => {
      console.log(error);
      this.UI.addToast("Não foi possível obter agendamentos. Verifique a ligação.");
    });
  };

  loadData = () => {
    this.Requisitante.find({
      filter: {
        where: {
          active: true
        },
        order: 'nome ASC'
      }
    }).$promise.then(requisitantes => {
      requisitantes.forEach(t => {
        t.name = t.nome;
      });
      this.requisitantes = requisitantes;
      this.Group.findOne({
        filter: {
          where: {
            name: {
              like: 'Inspetor%IE'
            }
          },
          include: {
            relation: 'usergroup',
            scope: {
              include: 'user'
            }
          }
        }
      }).$promise.then((g) => {
        if (g && g.usergroup) {
          g.usergroup.forEach(u => {
            this.inspetores.push(u.user);
          });

          this.inspetores = _.orderBy(this.inspetores, 'name', 'asc');
        } else
          this.inspetores = [];

        this.columns.forEach(f => {
          if (f.id === 'Pedidoinspeccao.visto')
            f.list = this.simnao;
          if (f.id === 'Pedidoinspeccao.requisitanteId')
            f.list = this.requisitantes;
          if (f.id === 'Agendamento.tecnicoId')
            f.list = this.inspetores;
        });
        // Everything is loaded
        this.dataLoaded = true;
      }).catch(e => {
        console.log(e);
        this.errorLoadingData = true;
        this.UI.addToast("Erro de carregamento de dados para filtragem (técnicos). Recarregue a página.");
      });
    }).catch(e => {
      console.log(e);
      this.errorLoadingData = true;
      this.UI.addToast("Erro de carregamento de dados para filtragem (Clientes). Recarregue a página.");
    });
  };

  preFillCorrectModalData = (pedidos) => {
    let data = null;
    let hora = null;
    let tecnico = null;

    if (pedidos.length === 0)
      return {
        data: null,
        hora: null,
        tecnico: null
      };

    if (pedidos[0].Agendamento == null)
      return {
        data: null,
        hora: null,
        tecnico: null
      };

    data = pedidos[0].Agendamento.data;
    tecnico = pedidos[0].Agendamento.Funcionario;
    if (pedidos.length > 1)
      hora = null;
    else {
      hora = pedidos[0].Agendamento.hora || undefined;
    }

    for (let i = 1; i < pedidos.length; i++) {
      if (pedidos[i].Agendamento == null)
        return {
          data: null,
          hora: null,
          tecnico: null
        };
      if (data !== pedidos[i].Agendamento.data)
        data = null;
      if (tecnico != null && tecnico.id !== pedidos[i].Agendamento.Funcionario.id)
        tecnico = null;
    }
    return {
      data: data,
      hora: hora,
      tecnico: tecnico
    }
  };

  // // Export multiple agendamentos at once of 'type'
  // // (empty argument; via this.toSchedule) or only one (coming as argument item)
  exportAgendamentos = (pedido) => {
    let itens = [];
    // If item is empty, we are using selection
    if (_.isEmpty(pedido)) {
      itens = this.getSelected(this.pedidos);
    } else {
      itens = pedido;
    }

    // Send pedidos ID to remote method to get the export
    let postData = {
      pedidos: itens.map(x => x.id)
    };

    this.Agendamento.selectiveExport(postData).$promise.then(result => {
      let url = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + result.fileData;
      let b = document.createElement('a');
      b.href = url;
      b.download = 'agendamentosSelecionados.xlsx';
      b.click();
    });
  };

  // Reset agendamentos
  resetAgendamentos = (type, item) => {
    let itens = [];

    if (_.isEmpty(item)) {
      itens = this.getSelected(this.pedidos);
    } else {
      itens = item;
    }
    // No point in going if we have no data
    if (itens.length === 0)
      return;

    let instance = this.UI.showDialog({
      template: require('./modal-confirm-agendamento-reset.html'),
      controller: ['$scope', function ($scope) {
        if (itens.length > 1) {
          $scope.resetInfo = "dos " + itens.length + " agendamentos selecionados";
        } else {
          $scope.resetInfo = "do agendamento selecionado";
        }

        $scope.ok = function () {
          $scope.$close('ok');
        };
        $scope.cancel = function () {
          $scope.$dismiss('cancel');
        };
      }]
    });

    instance.then((ok) => {
      itens.forEach((pedido) => {
        this.Agendamento.findOne({
          filter: {
            where: {
              and: [{active: 1}, {pedidoId: pedido.id}]
            }
          }
        }).$promise.then((res) => {
          // If we have a result, "delete" it.
          if (res) {
            // res.active = 0;
            // res.agendadoporId = this.user.id;
            // res.$save().then((err) => {
            //   this.setAgendamentoToPedidoAndUpdate(pedido.id, 2);
            //   this.UI.addToast("Agendamento desmarcado");
            // });

            // We need to delete agendamento so we can have a consistent pedidos list (only 1 agendamento per pedido)
            this.Agendamento.deleteById({
              id: res.id
            }).$promise.then(() => {
              this.setAgendamentoToPedidoAndUpdate(pedido.id, 2, 'Agendamento desmarcado').then(() => {
                this.UI.addToast("Agendamento de pedido " + pedido.id + " desmarcado");
                this.getPedidos();
              }).catch(err => {
                console.log(err);
                this.UI.addToast("Não foi possível eliminar o agendamento do pedido ", pedido.id);
              });
            }).catch((err) => {
              console.log(err);
              this.UI.addToast("Não foi possível eliminar o agendamento do pedido ", pedido.id);
            });
          } else {
            // No Agendamento available. Do nothing(?)
          }
        });
      }, (err) => {
        // Error in opening modal or closing it with Esc or clicking outside. Do nothing.
        if (err !== 'cancel' && err !== 'escape key press' && err !== 'backdrop click')
          console.log(err);
      });
    });
  };

  openFilter = () => {
    // Check if we can open
    if (this.errorLoadingData) {
      this.UI.addToast("Erro ao carregar dados de filtragem. Por favor recarregue a página.");
      return;
    }

    if (!this.dataLoaded) {
      this.UI.addToast("A carregar dados para filtragem, por favor tente novamente");
      return;
    }

    // Copy column to be used in
    this.editColumns = angular.copy(this.columns);

    // Restore list to selected
    this.customFilters.forEach(f => {
      if (f.column && f.column.selected) {
        if (f.column.selected.id === 'Pedidoinspeccao.visto')
          f.column.selected.list = this.simnao;
        if (f.column.selected.id === 'Pedidoinspeccao.requisitanteId')
          f.column.selected.list = this.requisitantes;
        if (f.column.selected.id === 'Agendamento.tecnicoId')
          f.column.selected.list = this.inspetores;
      }
    });

    // Copy customFilter to another variable so we can edit it all we want
    this.editCustomFilters = angular.copy(this.customFilters);
    // Show side panel
    this.fs = true;
  };

  selectTab = (i) => {
    this.listOpt[this.typeIndex] = angular.copy(this.opt);
    this.typeIndex = i;
    this.typeId = this.typesPedido[i].id;
    this.opt = angular.copy(this.listOpt[this.typeIndex]);
    this.opt.page = 1;

    this.$state.go('app.agendamentos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getPedidos();
  };

  sort = keyname => {
    if (this.opt.order === keyname)
      this.opt.page = 1;
    this.opt.order = keyname;
    this.opt.sort = this.opt.sort === 'asc' ? 'desc' : 'asc';
    this.$state.go('app.agendamentos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getPedidos();
  };

  item = val => {
    this.opt.items = val;
    this.$state.go('app.agendamentos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getPedidos();
  };

  page = sum => {
    this.opt.page += sum;
    if (this.opt.page < 1)
      this.opt.page = 1;
    if (this.opt.page > Math.ceil(this.totalByType[this.typeId] / this.opt.items))
      this.opt.page = Math.ceil(this.totalByType[this.typeId] / this.opt.items);
    this.$state.go('app.agendamentos.list', this.opt, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
    this.getPedidos();
  };

  selectAll = () => {
    if (this.allSelected) { // Not all are selected, select all
      this.pedidos.forEach(a => {
        a.selected = true;
      });
    } else { // Remove all selections
      this.pedidos.forEach(a => {
        a.selected = false;
      });
    }
  };

  selectItem = () => {
    let nSelected = _.filter(this.pedidos, r => r.selected).length;
    this.allSelected = nSelected >= this.pedidos.length;
  };

};

DirectorySchedulingController.$inject = ['$state', '$q', 'Requisitante', 'Pedidoinspeccao', 'Agendamento', 'Estado', 'Alteracaoestado', 'Funcionario', 'Group', 'AuthenticationService', 'UIService'];
